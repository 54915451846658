import React, {useCallback, useEffect, useRef, useState, useMemo} from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Col, Row, Space } from "udesk-ui";
import { useDebounce, useLiveEffect, useRequest } from 'src/util/hook';
import { linkTo } from 'src/util/link';
import { isExercise, notifyASREmpty } from '../../../components/util';
import { getCustomMessageList, useRecord } from '../../../components/record/ppt/hook';
import { useFullscreen, usePPTData } from '../../../components/ppt/hook';
import { getDateTimeString } from 'src/util/moment';
import Page from "Component/common/udesk-qa-page-layout";
import Wave from '../../../components/wave';
import PPTThumbnailList from '../../../components/ppt/list';
import PPTRecordPanel from '../../../components/record/ppt';
import VideoRecord from '../../../components/record/video';
import TaskCue from '../../../components/cue';
import TaskMessageModal, { RefType } from '../../../components/task-message-modal';
import TaskSubmitButton from '../../../components/task-submit-button';
import './index.less';

const Template = React.memo((props: any) => {
    const {history, match} = props;
    const locales = Locales['current'];
    const taskId = match.params.id;
    const [taskDetail, setTaskDetail] = useState<any>(null);
    const request = useRequest();
    const [record, setRecord] = useState<any>(null); // 记录
    const [isLastPage, list, current, index, goIndex] = usePPTData(taskDetail?.pptImageList);
    const [fullscreenContentRef, isFullscreen, requestFullscreen, exitFullscreen] = useFullscreen();
    const {
        loadingForSendMessage, // 发送消息接口的loading
        loadingForTaskStart, // 任务开始接口的loading
        passThroughParams, // 消息透传参数
        isStartTask, // 是否开始任务
        isPauseRecord, // 录音是否暂停
        isOver, // 录音是否结束
        pause, // 录音暂停
        stop, // 录音停止
        startTaskHandle, // 开始任务
        list: messageList, // 消息列表
        recordList, // 录音文件缓存列表
        lastRecord, // 最后一条录音
        lastRecordText, // 最后一条录音文本
        startAudioRecord, // 开始录音

        dialogue,
        suspendDetail,
        isSuspend, isSuspending, isLoadSuspendInfo, 
        loadingForSuspend, 
        pauseTask, continueTask, restartTask,
    } = useRecord({
        request,
        task: taskDetail,
        record, // 记录
        setRecord,
        goIndex,
    });
    const taskMessageModalRef = useRef<RefType>(null);

    const nextPage = useDebounce(() => {
        if (isStartTask) {
            if (
                index - dialogue.length + 1 === recordList.length && 
                lastRecord?.text
            ) {
                stop();
                goIndex(index + 1);
            } else {
                notifyASREmpty();
            }
        } else {
            goIndex(index + 1);
        }
    }, [
        isStartTask, 
        index, 
        recordList.length, 
        lastRecord?.text, 
        goIndex,
        dialogue,
    ]);

    // 获取任务详情信息
    useLiveEffect((success) => {
        request(`/intelligentPartner/${taskId}/pageDetail`).then(
            resp => {
                success(() => {
                    const data = resp.data;
                    if (data) {
                        data.id = parseInt(taskId);
                        setTaskDetail(data);
                    }
                });
            }
        );

    }, [request, taskId]);

    useEffect(() => {
        if (isStartTask && current && !current.isCreateMessageData) {
            const isStartRecord = !isPauseRecord;
            startAudioRecord({
                isStart: isStartRecord,
                bindNodeId: current.nodeId,
                pptUrl: current.downloadPath,
            });
            // 这里使用一个临时创建的一个变量，区分是否已经创建了newMessage
            // 因为监听了isPauseRecord, 所以这里必须这样处理
            // 这里是为了解决点击下一页时保存暂停的状态
            current.isCreateMessageData = true;
            // 根据暂停状态，标记否是开始录音
            // 如果没有，在下一页第一次继续时将开启录音
            // 此为临时变量
            current.isStartRecord = isStartRecord;
        }
    }, [
        current,
        isStartTask,
        isPauseRecord,
        startAudioRecord
    ]);

    // 自动提交
    useEffect(() => {
        if (list) {
            const messages = getCustomMessageList(messageList);
            if (messages.length === list.length) {
                if (messages.every(item => item.isSync === 2)) {
                    taskMessageModalRef.current?.open(4);
                }
            }
        }
    }, [list, messageList]);

    const goBack = useCallback(() =>{
        isExercise(
            taskDetail?.taskType,
            () => linkTo(history, 'coachLearningCenterExerciseTask'),
            () => linkTo(history, 'coachLearningCenterTestTask')
        );
    }, [history, taskDetail?.taskType]);

    const goNext = useCallback(() => {
        const isOpen = taskDetail?.enableManualReview === Udesk.enums.enableManualReviewType.open.id;
        linkTo(
            history, 
            isOpen ? 'coachLearningCenterRecord' : 'coachLearningCenterRecordDetail', 
            {id: record?.id}, 
            {
                backRouteParams: [
                    isExercise(
                        taskDetail?.taskType, 'coachLearningCenterExerciseTask', 'coachLearningCenterTestTask'
                    )
                ] 
            }, 
            isOpen ? {taskId} : {}
        );
    }, [history, record?.id, taskDetail?.enableManualReview, taskDetail?.taskType, taskId]);


    const cancelTaskAjax = useCallback(() => {
        request(`/intelligentPartner/${record.id}/cancel`).then(
            resp => {
                goBack();
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [goBack, record?.id, request]);

    const cancelTaskHandle = useCallback(() => {
        if (isStartTask) {
            taskMessageModalRef.current?.open(taskDetail?.taskType);
        } else {
            goBack();
        }
    }, [goBack, isStartTask, taskDetail?.taskType]);

    const submitTaskHandle = useCallback(() => {
        request(`/intelligentPartner/${record.id}/submit`, {
            flowId: passThroughParams?.flowId,
            video: "",
            submitTime: getDateTimeString(),
            isReviewed: taskDetail?.enableManualReview
        }, 'put').then(
            resp => {
                Udesk.ui.notify.success(locales.business.info.saveSuccess);
                goNext();
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [
        goNext, 
        locales.business.info.saveSuccess, 
        passThroughParams?.flowId, 
        record?.id, 
        request, 
        taskDetail?.enableManualReview
    ]);

    const pauseTaskHandle = useCallback((callback) => {
        pauseTask(() => {
            taskMessageModalRef.current?.open(5);
        }, {
            flowId: passThroughParams.flowId,
            suspendTime: getDateTimeString(),
            video: ''
        });
    }, [passThroughParams.flowId, pauseTask]);

    const isPad = useMemo(() => window.innerWidth <= 1240, []);
    return (
        <Page
            pageClassName='learning-center-exercise'
            pageBodyClassName='learning-center-exercise-body ppt'
            backGroundGhost={true}
            onBack={cancelTaskHandle}
            title={taskDetail?.taskName}
            extra={
                <TaskSubmitButton 
                    {...{
                        pauseTask: pauseTaskHandle,
                        isSuspending,
                        loadingForSuspend,
                        taskMessageModalRef,
                        record,
                        taskDetail,
                        isLastNode: isOver && isLastPage,
                        isStartTask,
                        submitTaskHandle,
                    }}/>
            }
        >
            <div ref={fullscreenContentRef as any} className='fullscreen-content'>
                <Row  gutter={16} wrap={false}>
                    {
                        isFullscreen ? null : (
                            <Col flex={ isPad ? "200px" : "327px" }>
                                <PPTThumbnailList {...{
                                    list, current, index, goIndex, isStartTask, isPad
                                }}/>
                            </Col>
                        )
                    }
                    <Col flex="auto">
                        <PPTRecordPanel {...{
                            isFullscreen,
                            requestFullscreen,
                            exitFullscreen,
                            current,
                            nextPage,
                            goIndex,

                            request,
                            task: taskDetail,
                            recordText: lastRecordText,
                            isStartTask,
                            isPauseRecord,
                            isLastPage,
                            isOver,
                            pause,
                            stop,
                            startTaskHandle,
                            startAudioRecord,
                            loadingForTaskStart,
                            loadingForSendMessage,

                            taskDetail,
                            record,
                            submitTaskHandle,
                            taskMessageModalRef
                        }}/>
                    </Col>
                    <Col flex={ isPad ? "250px" : "327px" }>
                        <Space direction={'vertical'} className={'other-panel'}>
                            <VideoRecord />
                            <TaskCue referenceWords={current?.words} taskDetail={taskDetail} />
                            {
                                isFullscreen ? (
                                    <Wave {...{
                                        className: 'record-left-toolbar',
                                        direction: 'vertical',
                                        goIndex,
                                        current,
                                        task: taskDetail,
                                        recordText: lastRecordText,
                                        isStartTask,
                                        isPauseRecord,
                                        isLastPage,
                                        isOver,
                                        pause,
                                        stop,
                                        startTaskHandle,
                                        startAudioRecord,
                                        taskMessageModalRef,
                                        loadingForTaskStart,
                                        loadingForSendMessage,
                                    }}/>
                                ) : null
                            }

                        </Space>
                    </Col>
                    <Col flex="0" style={{padding: 0}}>
                        <TaskMessageModal
                            {...{
                                ref: taskMessageModalRef,
                                goBack,
                                cancelTaskAjax,
                                submitTaskAjax: submitTaskHandle,
                                taskDetail,
                                suspendDetail,
                                isSuspend, isLoadSuspendInfo, 
                                loadingForSuspend, 
                                continueTask, restartTask,
                            }}/>
                    </Col>
                </Row>
            </div>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
