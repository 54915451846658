import {
    getReviewIntelligentPartnerNodePptConfig,
    postReviewIntelligentPartnerNodePptConfig,
} from 'src/api/review/intelligentPartner/node/pptConfig';
import { putReviewIntelligentPartnerNodePptConfigById } from 'src/api/review/intelligentPartner/node/pptConfig/{id}';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

const fields = (props) => {
    const { flowId, form } = props;
    if (isPptConfigNode(props.node.data.configType)) {
        return [
            {
                label: /* 上传幻灯片 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.pptNode.uploadSlide,
                type: 'PptUpload',
                name: 'pptFile',
                props: {
                    flowId,
                    resetPptImageList: () => {
                        form.setFieldsValue({
                            pptImageList: undefined,
                        });
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: /* 考核方式 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.pptNode.assessmentMethod,
                type: 'RadioGroup',
                props: {
                    options: Udesk.enums.pptEvaluationMode.map((i) => ({
                        label: i.name,
                        value: i.id,
                    })),
                },
                name: 'evaluationMode',
            },
        ];
    }
    if (props.evaluationMode === Udesk.enums.pptEvaluationMode.whole.id) {
        return [
            {
                label: '',
                type: 'ScoringRules',
                name: 'scoreRuleList',
                props: {
                    onChange: (scoreRuleList) => {
                        props.saveFieldValues((prev) => {
                            prev.scoreRuleList = scoreRuleList;
                        });
                    },
                    configId: props.configId,
                    type: 2, // 1:学员节点  2:ppt
                    showWords: true,
                },
            },
        ];
    } else {
        return [
            {
                label: /* 关联幻灯片 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.pptNode.associateSlide,
                type: 'PptPreview',
                name: 'pptImageList',
            },
            {
                label: /* 标准话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.pptNode.standardScript,
                type: 'TextArea',
                name: 'words',
            },
            {
                label: '',
                type: 'ScoringRules',
                name: 'scoreRuleList',
                props: {
                    onChange: (scoreRuleList) => {
                        props.saveFieldValues((prev) => {
                            prev.scoreRuleList = scoreRuleList;
                        });
                    },
                    configId: props.configId,
                    type: 2, // 1:学员节点  2:ppt
                },
            },
        ];
    }
};

const request = ({ nodeId }) => {
    return getReviewIntelligentPartnerNodePptConfig({
        params: {
            nodeId,
        },
    }).then((resp) => {
        const res: any = resp.data || {};
        if (Array.isArray(res.scoreRuleList)) {
            res.scoreRuleList = res.scoreRuleList.map((i) => {
                return {
                    ...i,
                    rule: JSON.parse(i.rule || ''),
                };
            });
        }
        if (!res.evaluationMode) {
            res.evaluationMode = Udesk.enums.pptEvaluationMode.whole.id;
        }
        if (!res.configType) {
            res.configType = Udesk.enums.pptConfigType.pptConfig.id;
        }
        return res;
    });
};

const finish = ({ formInstanceCache, configId, nodeId, node, flowId }) => {
    const { configType } = node.data ?? {};
    return (values) => {
        const params = {
            ...values,
            nodeId,
            configType: node.data.configType,
            flowId,
            nodeLocation: JSON.stringify(node.position),
        };
        let submit: any = null;
        if (isPptConfigNode(configType)) {
            params.id = configId;
            submit = postReviewIntelligentPartnerNodePptConfig(params, {
                successMsg: /* 保存成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.pptNode.successfullySaved,
            });
        } else {
            if (!configId) {
                submit = postReviewIntelligentPartnerNodePptConfig(params, {
                    successMsg: /* 保存成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.pptNode.successfullySaved,
                });
            } else {
                submit = putReviewIntelligentPartnerNodePptConfigById(params, {
                    segments: {
                        id: configId,
                    },
                    successMsg: /* 保存成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.pptNode.successfullySaved,
                });
            }
        }
        return submit.then((resp) => {
            return resp;
        });
    };
};

export default {
    fields,
    request,
    finish,
};

function isPptConfigNode(configType) {
    return configType === Udesk.enums.pptConfigType.pptConfig.id;
}
