import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { SortableContainer as sortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Icon, Checkbox, Space, Row, Col, Modal, Table } from 'udesk-ui';
// import AutoSizeTable from 'Component/common/auto-size-table';
import { arrayMoveImmutable } from 'array-move';
import './style.scss';

const CheckboxGroup = Checkbox.Group;
const locales = Locales['current'];

const SPAN = 8;
const DragHandle = SortableHandle(() => (
    <Icon type='MenuOutlined' antdIcon={true} style={{ cursor: 'grab', color: '#999' }} />
));

interface Data {
    key: string;
    name: string;
    index: number;
}

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const Template = React.memo((props: any) => {
    const { sdkOptions } = props;
    const [allColumns, setAllColumns] = useState<Data[]>([]);
    const [selectedColumns, setSelectedColumns] = useState<typeof allColumns>([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const containerDomRef = useRef<HTMLDivElement>(null);
    const [isInit, setIsInit] = useState(true);
    const [inspectionTaskShowFieldsData, setInspectionTaskShowFieldsData] = useState<any>({});

    const allColumnsMap = useMemo(() => allColumns.reduce((prev, c) => ({ ...prev, [c.key]: c }), {}), [allColumns]);

    const onDelete = useCallback((record) => {
        setSelectedColumns((prev) => prev.filter((i) => i.key !== record.key));
    }, []);

    const onSelectCheckboxChange = (list) => {
        setCheckAll(list.length === allColumns.length);
        setSelectedColumns((prev) => {
            return list.map((i) => allColumnsMap[i]);
        });
    };

    const onCheckAllChange = (e) => {
        setSelectedColumns(e.target.checked ? allColumns : []);
        setCheckAll(e.target.checked);
    };

    useEffect(() => {
        setIndeterminate(!!selectedColumns.length && selectedColumns.length < allColumns.length);
    }, [selectedColumns, allColumns]);

    const checkedList = useMemo(() => selectedColumns.map((c) => c.key), [selectedColumns]);

    const columns = useMemo(() => {
        return [
            {
                title: locales.labels.sort,
                dataIndex: 'sort',
                width: 80,
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
            // {
            //     title: locales.labels.index,
            //     dataIndex: 'index',
            //     width: 50,
            //     className: 'drag-visible',
            //     render: (text, record, index) => `${index + 1}`,
            // },
            {
                title: locales.labels.column,
                dataIndex: 'name',
                className: 'drag-visible',
            },
            {
                title: locales.labels.action,
                dataIndex: 'actions',
                className: 'drag-visible',
                width: 80,
                render: (text, record) => (
                    <Button
                        onClick={onDelete.bind(null, record)}
                        tooltip={locales.labels.delete}
                        icon={
                            <Icon type='MinusCircleOutlined' antdIcon={true} style={{ color: 'rgb(153, 153, 153)' }} />
                        }
                    />
                ),
            },
        ];
    }, [onDelete]);

    useEffect(() => {
        if (!props.visible && !isInit) {
            props.onOriginColumnsBack?.();
            return;
        }
        setIsInit(false);
        refreshData();
    }, [props.visible]);

    const refreshData = () => {
        const { sourceListValue, taskId } = props;
        let fieldUrl = Udesk.business.apiPath.concatApiPath(`field/data/search-template`, sdkOptions);
        let taskUrl = Udesk.business.apiPath.concatApiPath(`field/data/task/${taskId}`, sdkOptions);
        let showListUrl = Udesk.business.apiPath.concatApiPath(`inspectionTaskShowFields/${taskId}`, sdkOptions);

        const requestQueue = [
            Udesk.ajax.post(fieldUrl, { sourceList: [sourceListValue] }),
            Udesk.ajax.get(taskUrl),
            Udesk.ajax.get(showListUrl),
        ];
        Promise.all(requestQueue).then(
            ([fieldRequest, taskRequest, showListRequest]) => {
                let columns: any[] = [];
                if (Array.isArray(fieldRequest?.data?.[0]?.typeList)) {
                    columns = fieldRequest.data[0].typeList.reduce((prev, type) => {
                        return [
                            ...prev,
                            ...(type.fieldList?.map?.((field) => ({ key: `${field.id}`, name: `${field.label}` })) ?? []),
                        ];
                    }, columns);
                }
                if (Array.isArray(taskRequest?.data)) {
                    columns = [
                        ...columns,
                        ...taskRequest.data.map((field) => ({ key: `${field.id}`, name: `${field.label}` })),
                    ];
                }
                const allCols = columns.map((item, index) => ({ ...item, index }));
                setAllColumns(allCols);
                if (showListRequest?.data) {
                    setInspectionTaskShowFieldsData(showListRequest.data);
                    if (showListRequest.data.showList === null) {
                        setSelectedColumns([]);
                    }
                    if (Array.isArray(showListRequest.data.showList)) {
                        setSelectedColumns(
                            showListRequest.data.showList.map((id) => allCols.find((c) => c.key === id)).filter(Boolean)
                        );
                    }
                }
                props.onOriginColumnsBack?.();
            },
            (reason) => {
                Udesk.ui.notify.error(
                    reason.errorMsg || locales.components.pages.components.listManage.getRecordsFailed
                );
                props.onOriginColumnsBack?.();
            }
        );
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([...selectedColumns], oldIndex, newIndex).filter((el) => !!el);
            setSelectedColumns(newData);
        }
    };
    const DraggableContainer = (props) => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass='columns-manage-page-row-dragging'
            helperContainer={containerDomRef.current}
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = selectedColumns.findIndex((x) => x.index === restProps['data-row-key']);

        return <SortableItem index={index} {...restProps} />;
    };

    const save = async () => {
        const { taskId } = props;
        let params = {
            taskId,
            showList: checkedList,
        };
        if (inspectionTaskShowFieldsData.id) {
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionTaskShowFields/${inspectionTaskShowFieldsData.id}`,
                sdkOptions
            );
            await Udesk.ajax.put(url, { ...params, id: inspectionTaskShowFieldsData.id }).then((resp) => {});
        } else {
            let url = Udesk.business.apiPath.concatApiPath(`inspectionTaskShowFields`, sdkOptions);
            await Udesk.ajax.post(url, params).then((resp) => {});
        }
        refreshData();
        // const key = computeStorageKey.call({props});
        // saveLocalStorage(key, checkedList);
        props.onCancel?.();
        props.onApply?.(checkedList);
    };

    return (
        <Modal
            visible={props.visible}
            width={800}
            okText={locales.labels.applyNow}
            cancelText={locales.labels.cancel}
            wrapClassName='columns-manage-page-index'
            title={locales.components.pages.columnsManage.title}
            onCancel={props.onCancel}
            onOk={save}
        >
            <div ref={containerDomRef}>
                <div className='columns-manage-page-left'>
                    <div>
                        <Space>
                            <div>{locales.labels.allColumns}</div>
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                {locales.labels.selectAll}
                            </Checkbox>
                        </Space>
                    </div>
                    <CheckboxGroup value={checkedList} onChange={onSelectCheckboxChange}>
                        <Row>
                            {allColumns.map((item, index) => (
                                <Col key={item.key} span={SPAN} style={{ marginBottom: 4 }}>
                                    <Checkbox key={item.key} value={item.key}>
                                        {item.name}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </CheckboxGroup>
                </div>
                <div className='columns-manage-page-right'>
                    <Space>
                        {`${locales.labels.selectedColumns} (${selectedColumns.length}${locales.labels.column})`}
                    </Space>
                    <div style={{ overflowY: 'auto' }}>
                        <Table
                            pagination={false}
                            dataSource={selectedColumns}
                            columns={columns}
                            size='small'
                            rowKey='index'
                            // scroll={{
                            //     x: 'max-content'
                            // }}
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: DraggableBodyRow,
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
