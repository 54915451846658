import React from 'react';
import Udesk from 'Udesk';
import AudioStickers from '../audio-stickers-talk';
import WaveAudio from '../../../common/wave-audio-talk';
import SmartTags from '../smart-tags';
import ChangeList from '../change-list';
// import SemanticMark from '../semantic-mark';
import AudioCallContentInfo from 'Component/pages/components/audio-call-content-info';
import ApplyTemplates from '../apply-templates';
import RelatedDetail from '../related-detail';
import { ReportEcharts } from 'udesk-react/src/components/report/report-echarts';
import CallTextView from '../call-text-view';
import EmotionsAnalysis from '../emotion-analysis';
import RecordDetailComponent from './component';
import RelatedRecordsList from '../related-records-list';
import './style.scss';
import _isEmpty from 'lodash-es/isEmpty';
import { Modal, Icon, Row, Col, Tabs, Spin, Alert } from 'udesk-ui';
import _map from 'lodash-es/map';
import ClassNames from 'classnames';
import Locales from 'UdeskLocales';
import AddToTask from './components/AddToTask';
import { isQaSubApp } from 'Udesk/system/subApp';

const { TabPane } = Tabs;

const TemplateTitle = (props) => {
    const { taskType, onTaskTypeChange, showCollection } = props;
    const locales = Locales['current'];

    return (
        <Tabs activeKey={`${taskType}`} size='small' onChange={onTaskTypeChange}>
            <TabPane tab={locales.fix.qualityInspectionTemplate} key='1'></TabPane>
            {showCollection && <TabPane tab={locales.fix.collectionTemplate} key='2'></TabPane>}
        </Tabs>
    );
};
export default class RecordDetailTemplate extends RecordDetailComponent {
    render() {
        let { sdkOptions, inspectDataSource, keywords, keywordType } = this.props;
        let {
            detail,
            smartTags,
            templates,
            selectedDatum,
            showEmotionAnalysis,
            // semanticMarkShow,
            // semanticContent,
            relatedDatas,
            relatedLoading,
            visible,
            labelRef,
            treeDataList,
            treeDataRef,
            labelParentModal,
            labelActiveModal,
            hitData,
            timeValue,
            waveAudio,
            waveAudioOptions,
            spinLoading,
            changeListLoading,
            changeList,
            taskType,
            // taskList,
        } = this.privates;
        let { relatedDatasPaging } = this.privates.storages;
        let { actions, locales, privates } = this;

        let keywordWordCloudUrl = Udesk.business.apiPath.concatApiPath(
            `data/${selectedDatum.dataId}/keywords`,
            sdkOptions
        );
        return (
            <div className='qa-react-record-detail-page qa-react-record-detail-page-info'>
                <div className='qa-react-record-detail-page-info '>
                    <Choose>
                        <When condition={detail}>
                            {!!detail.isHideText && <Alert message={/* 该数据已被定期清除 */Locales['current'].components.recordDetail.template.theDataHasBeenClearedPeriodically} type="info" showIcon style={{marginBottom: 8}}/>}
                            <If condition={detail.voiceUrl && !showEmotionAnalysis}>
                                <AudioStickers detail={detail} />
                                <WaveAudio
                                    reverse={this.actions.reverse}
                                    recover={this.actions.recover}
                                    reAsr={this.actions.reAsr}
                                    agentFlag={false}
                                    onWaveAudio={actions.onWaveAudio}
                                    options={waveAudioOptions}
                                    timeChaged={actions.timeChaged}
                                    detail={detail}
                                    isShowEmotionButton={true}
                                    src={detail.voiceUrl}
                                    onClickEmotionAnalysis={actions.changeShowEmotionAnalysis}
                                    ref={(instance) => {
                                        this.privates.waveAudioInstance = instance;
                                    }}
                                />
                            </If>
                            <If condition={showEmotionAnalysis}>
                                <div className='qa-react-record-detail-emotion-analysis-container'>
                                    <button
                                        type='button'
                                        className='btn btn-xs btn-default qa-react-record-detail-emotion-button'
                                        onClick={actions.changeShowEmotionAnalysis}
                                    >
                                        {locales.components.pages.components.recordDetail.showWaveAudio}
                                    </button>
                                    <EmotionsAnalysis
                                        detail={detail}
                                        classNames='qa-react-record-detail-emotion-analysis'
                                    />
                                </div>
                            </If>
                            <If condition={inspectDataSource === Udesk.enums.inspectDataSources.ticket.id}>
                                <div style={{ marginBottom: 8, padding: 16, backgroundColor: '#fff' }}>
                                    <div>{`${/* 主题： */ locales.components.recordDetail.template.subject}${
                                        detail.ticketSubject
                                    }`}</div>
                                    <div>{`${/* 描述： */ locales.components.recordDetail.template.description}${
                                        detail.ticketContentPlain
                                    }`}</div>
                                </div>
                            </If>
                            <If condition={inspectDataSource !== Udesk.enums.inspectDataSources.ticket.id}>
                                <SmartTags
                                    callId={detail.id}
                                    onSmartTagsChange={actions.onSmartTagsChange}
                                    smartTags={smartTags}
                                    smartTagsTitle={locales.components.pages.appealChekingTask.list.smartTags}
                                    treeListVisible={actions.treeListVisible}
                                />
                            </If>
                            <div
                                className={ClassNames('qa-react-record-detail-content row', {
                                    'with-audio': detail.voiceUrl,
                                })}
                            >
                                <Row gutter={16} style={{ height: '100%' }}>
                                    <Col className='gutter-row qa-react-record-detail-content-left-part' span={12}>
                                        <AudioCallContentInfo
                                            isRecordDetail={true}
                                            onAudioStop={actions.onAudioStop}
                                            waveAudio={waveAudio}
                                            onAudioPause={actions.onAudioPause}
                                            timeValue={timeValue}
                                            testSetListFlag={true}
                                            sentenceList={
                                                detail &&
                                                detail.dialogRecords &&
                                                detail.dialogRecords.dialogSentenceList
                                            }
                                            key={detail.id}
                                            detail={detail}
                                            keywords={keywords}
                                            keywordType={keywordType}
                                            inspectDataSource={inspectDataSource}
                                            onTimeRangeChanged={actions.sendCurrentTimeRange}
                                            onMarkSemanticClick={actions.markSemantic}
                                        />
                                    </Col>
                                    <Col
                                        className='gutter-row qa-react-record-detail-content-right-part'
                                        span={12}
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                        {isQaSubApp() && (
                                            <div
                                                className='qa-react-record-detail-content-templates'
                                                style={{ flex: 1, marginBottom: '6px' }}
                                            >
                                                <ApplyTemplates
                                                    title={
                                                        <TemplateTitle
                                                            taskType={taskType}
                                                            onTaskTypeChange={actions.onTaskTypeChange}
                                                            showCollection={
                                                                inspectDataSource !==
                                                                    Udesk.enums.inspectDataSources.ticket.id &&
                                                                inspectDataSource !==
                                                                    Udesk.enums.inspectDataSources.wechatRadio.id
                                                            }
                                                        />
                                                    }
                                                    extra={
                                                        Udesk.business.task.isVoiceCall(inspectDataSource) ? (
                                                            <AddToTask
                                                                onAdded={() => {
                                                                    this.actions.reloadAsyncModel();
                                                                }}
                                                                dataId={selectedDatum.dataId}
                                                                inspectDataSource={this.props.inspectDataSource}
                                                            />
                                                        ) : null
                                                    }
                                                    templates={templates.filter((i) => i.taskType === taskType)}
                                                    onClickTemplate={actions.transitionToTemplate}
                                                />
                                            </div>
                                        )}
                                        {inspectDataSource === Udesk.enums.inspectDataSources.ticket.id && (
                                            <RelatedDetail />
                                        )}
                                        <div className='qa-react-record-detail-content-word-cloud'>
                                            <Tabs defaultActiveKey='1'>
                                                <TabPane
                                                    tab={
                                                        inspectDataSource ===
                                                            Udesk.enums.inspectDataSources.textDialogue.id ||
                                                        inspectDataSource ===
                                                            Udesk.enums.inspectDataSources.realTimeTextDialogue.id
                                                            ? locales.components.pages.appealChekingTask.list.dialogInfo
                                                            : locales.components.pages.appealChekingTask.list.callInfo
                                                    }
                                                    key='2'
                                                >
                                                    <div style={{ height: 'calc(347px - 46px)', overflowY: 'auto' }}>
                                                        <If
                                                            condition={
                                                                detail &&
                                                                detail.fieldList &&
                                                                detail.fieldList.length > 0
                                                            }
                                                        >
                                                            <CallTextView
                                                                callInfoList={detail.fieldList}
                                                                fieldValueMap={detail.fieldValueMap}
                                                            />
                                                        </If>
                                                    </div>
                                                </TabPane>
                                                {inspectDataSource !== Udesk.enums.inspectDataSources.ticket.id && (
                                                    <TabPane tab={locales.fix.keywordsWordCloud} key='1'>
                                                        <div id='reacordDetailKeyWordsCloud'>
                                                            <ReportEcharts
                                                                ref={privates.wordCloudRef}
                                                                classNames='key-words-cloud'
                                                                chartType={Udesk.enums.echartTypes.wordCloud.id}
                                                                chartDataUrl={keywordWordCloudUrl}
                                                                sdkOptions={sdkOptions}
                                                                rerenderOptimization={true}
                                                                onSeriesClicked={actions.onSeriesClicked}
                                                            />
                                                        </div>
                                                    </TabPane>
                                                )}
                                            </Tabs>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ marginTop: '16px' }}>
                                <h2 style={{ fontWeight: 'bold' }}>
                                    {locales.components.pages.appealChekingTask.components.relatedRecord.title}
                                </h2>
                            </div>
                            {relatedDatas && !_isEmpty(relatedDatas) ? (
                                <div>
                                    <RelatedRecordsList
                                        relatedDatas={relatedDatas}
                                        relatedDatasPaging={relatedDatasPaging}
                                        parentLoadMore={actions.parentLoadMore}
                                        loading={relatedLoading}
                                        selectedDatum={selectedDatum && selectedDatum.dataId}
                                    />
                                </div>
                            ) : (
                                <Spin spinning={spinLoading}>
                                    {spinLoading ? (
                                        <div style={{ width: '100%', height: '30px' }}></div>
                                    ) : (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '30px',
                                                textAlign: 'center',
                                                lineHeight: '30px',
                                            }}
                                        >
                                            {locales.labels.dataNo}
                                        </div>
                                    )}
                                </Spin>
                            )}
                            <ChangeList
                                list={changeList}
                                pointCategoryList={detail.pointCategoryList || []}
                                loading={changeListLoading}
                            />
                        </When>
                        <Otherwise>
                            <div className='qa-react-record-detail-page-no-value'>{locales.labels.noValue}</div>
                        </Otherwise>
                    </Choose>
                    {/* <SemanticMark show={semanticMarkShow} content={semanticContent} onClose={actions.markSemanticClose} /> */}
                    <Modal
                        title={locales.fix.editCategoryLabel}
                        visible={visible}
                        onCancel={actions.modalCancel}
                        onOk={actions.modalOk}
                    >
                        <div className='label-tabs-list-page' ref={labelRef}>
                            {!_isEmpty(treeDataList) &&
                                _map(treeDataList, (item, index) => {
                                    if (item) {
                                        return (
                                            <div
                                                className='label-tabs-list-page-field'
                                                key={index}
                                                style={{ width: '190px' }}
                                                ref={treeDataRef[`tree-${index}`]}
                                                onMouseUp={actions.fieldUp.params(labelRef)}
                                            >
                                                <div
                                                    className='label-field-target-drag'
                                                    onMouseDown={actions.treeDrag.params(
                                                        treeDataRef[`tree-${index}`],
                                                        labelRef
                                                    )}
                                                >
                                                    <Icon type='ic-pause-zanting' />
                                                </div>
                                                <div className='label-tabs-list-page-field-drag'>
                                                    {!_isEmpty(item) &&
                                                        item.map((items) => {
                                                            return (
                                                                <div
                                                                    className={ClassNames(
                                                                        'label-field-info',
                                                                        {
                                                                            'label-field-info-active-parent':
                                                                                labelParentModal[items.tagLevel] ===
                                                                                    items.id ||
                                                                                hitData[items.tagLevel] === items.id,
                                                                        },
                                                                        {
                                                                            'label-field-info-active':
                                                                                items.id === labelActiveModal,
                                                                        }
                                                                    )}
                                                                    key={items.id}
                                                                    onClick={actions.activesLabelModal.params(
                                                                        items.id,
                                                                        items.tagLevel,
                                                                        items.leafFlag
                                                                    )}
                                                                >
                                                                    <span>{items.tagName}</span>
                                                                    {items.leafFlag !== 1 && (
                                                                        <Icon type='ic-play-line' />
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return;
                                    }
                                })}
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}
