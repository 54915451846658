import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import TasksIndexComponent from './component';
import { getInspectionTasksSourceBySource } from 'src/api/inspectionTasks/source/{source}';
import { getInspectionTasksExpandData } from 'src/api/inspectionTasks/expandData';

export default class TasksIndexRoute extends TasksIndexComponent {
    // model() {
    //     this.privates.loading = true;
    //     this.actions.update();
    //     let company = Udesk.data.init.company;
    //     let inspectDataSources = Udesk.enums.companyEnableInspect.no.id;

    //     // enabledCallInspect 0:未启用 1:启用语音质检 2:启用实时语音质检 3:都启用
    //     // 1、语音通话
    //     // enabledImInspect 0:未启用 1:启用IM质检 2:启用实时IM质检 3:都启用
    //     // 2、文本对话
    //     if (company.enabledCallInspect !== Udesk.enums.companyEnableInspect.no.id && company.enabledImInspect !== Udesk.enums.companyEnableInspect.no.id) {
    //         inspectDataSources = Udesk.enums.companyEnableInspect.no.id;
    //     }
    //     if (company.enabledCallInspect !== Udesk.enums.companyEnableInspect.no.id && company.enabledImInspect === Udesk.enums.companyEnableInspect.no.id) {
    //         inspectDataSources = Udesk.enums.companyEnableInspect.regular.id;
    //     }
    //     if (company.enabledCallInspect === Udesk.enums.companyEnableInspect.no.id && company.enabledImInspect !== Udesk.enums.companyEnableInspect.no.id) {
    //         inspectDataSources = Udesk.enums.companyEnableInspect.realTime.id;
    //     }
    //     let params = Object.assign(
    //         {},
    //         this.props.match.params,
    //         Object.fromEntries(new URLSearchParams(this.props.location.search))
    //     );
    //     let modelPromise = new Promise((resolve, reject) => {
    //         let url = Udesk.business.apiPath.concatApiPath(
    //             "/inspectionTasks",
    //             this.props.sdkOptions
    //         );
    //         Udesk.ajax.get(url, params).then(
    //             resp => {
    //                 resolve(resp.data);
    //             },
    //             reason => {
    //                 Udesk.ui.notify.error(
    //                     reason.errorMsg ||
    //                     Udesk.utils.string.format(
    //                         UdeskLocales.current.business.info.loadError,
    //                         UdeskLocales.current.pages.tasks.index.qualityCheckTasks
    //                     )
    //                 );
    //             }
    //         );
    //     });
    //     let modulePromise = new Promise((resolve, reject) => {
    //         let url = Udesk.business.apiPath.concatApiPath(
    //             `/inspectionModules/1/dataSource/${inspectDataSources}`,
    //             this.props.sdkOptions
    //         );
    //         Udesk.ajax.get(url, params).then(
    //             resp => {
    //                 resolve(resp.data);
    //             },
    //             reason => {
    //                 Udesk.ui.notify.error(
    //                     reason.errorMsg ||
    //                     Udesk.utils.string.format(
    //                         UdeskLocales.current.business.info.loadError,
    //                         UdeskLocales.current.pages.tasks.index.qualityCheckTasks
    //                     )
    //                 );
    //             }
    //         );
    //     });
    //     return {
    //         tasks: modelPromise,
    //         moduleData: modulePromise
    //     };
    // }

    asyncModel() {
        let company = Udesk.data.init.company;
        let inspectDataSources = Udesk.enums.companyEnableInspect.no.id;

        // enabledCallInspect 0:未启用 1:启用语音质检 2:启用实时语音质检 3:都启用
        // 1、语音通话
        // enabledImInspect 0:未启用 1:启用IM质检 2:启用实时IM质检 3:都启用
        // 2、文本对话
        if (
            company.enabledCallInspect !== Udesk.enums.companyEnableInspect.no.id &&
            company.enabledImInspect !== Udesk.enums.companyEnableInspect.no.id
        ) {
            inspectDataSources = Udesk.enums.companyEnableInspect.no.id;
            this.privates.moduleSourceType = Udesk.enums.inspectDataSources.voiceCall.id;
        }
        if (
            company.enabledCallInspect !== Udesk.enums.companyEnableInspect.no.id &&
            company.enabledImInspect === Udesk.enums.companyEnableInspect.no.id
        ) {
            inspectDataSources = Udesk.enums.inspectDataSources.voiceCall.id;
            this.privates.moduleSourceType = Udesk.enums.inspectDataSources.voiceCall.id;
        }
        if (
            company.enabledCallInspect === Udesk.enums.companyEnableInspect.no.id &&
            company.enabledImInspect !== Udesk.enums.companyEnableInspect.no.id
        ) {
            inspectDataSources = Udesk.enums.inspectDataSources.textDialogue.id;
            this.privates.moduleSourceType = Udesk.enums.inspectDataSources.textDialogue.id;
        }
        this.privates.inspectDataSources = inspectDataSources;
        let params = Object.assign(
            {},
            this.props.match.params,
            Object.fromEntries(new URLSearchParams(this.props.location.search))
        );
        let modelPromise = new Promise((resolve, reject) => {
            if (this.props.route.name === 'wechatQaTasksIndex') {
                Promise.all([
                    getInspectionTasksSourceBySource({
                        segments: { source: Udesk.enums.inspectDataSources.wechatDialogue.id },
                        useCache: true,
                    }),
                    getInspectionTasksSourceBySource({
                        segments: { source: Udesk.enums.inspectDataSources.wechatRadio.id },
                        useCache: true,
                    }),
                ]).then(
                    ([resp1, resp2]) => {
                        this.privates.loading = false;
                        this.actions.update();
                        resolve([...(resp1?.data ?? []), ...(resp2?.data ?? [])]);
                    },
                    (reason) => {
                        this.privates.loading = false;
                        this.actions.update();
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.index.qualityCheckTasks
                                )
                        );
                    }
                );
            } else {
                getInspectionTasksSourceBySource({
                    segments: {
                        source: 0,
                    },
                    params,
                    useCache: true,
                }).then(
                    (resp) => {
                        this.privates.loading = false;
                        this.actions.update();
                        resolve(resp.data);
                    },
                    (reason) => {
                        this.privates.loading = false;
                        this.actions.update();
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.index.qualityCheckTasks
                                )
                        );
                    }
                );
            }
        });

        let allModel = {
            tasks: modelPromise,
        };

        //企业微信
        if (this.props.route.name === 'wechatQaTasksIndex') {
            return allModel;
        }
        this.actions.getModuleData();
        // let modulePromise = new Promise((resolve, reject) => {
        //     let url = Udesk.business.apiPath.concatApiPath(
        //         `/inspectionModules/1/dataSource/${inspectDataSources}`,
        //         this.props.sdkOptions
        //     );
        //     Udesk.ajax.get(url, params).then(
        //         resp => {
        //             resolve(resp.data);
        //         },
        //         reason => {
        //             Udesk.ui.notify.error(
        //                 reason.errorMsg ||
        //                 Udesk.utils.string.format(
        //                     UdeskLocales.current.business.info.loadError,
        //                     UdeskLocales.current.pages.tasks.index.qualityCheckTasks
        //                 )
        //             );
        //         }
        //     );
        // });

        let chartPromise = new Promise((resolve, reject) => {
            // let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/expandData', this.props.sdkOptions);
            // this.privates.xhr = Udesk.ajax.get(url);
            const { signal } = this.privates.controller;
            this.privates.xhr = getInspectionTasksExpandData({
                useCache: true,
                signal,
            });
            this.privates.xhr.then(
                (resp) => {
                    this.privates.chartsFulfilled = true;
                    resolve(resp.data);
                },
                (reason) => {
                    this.privates.chartsFulfilled = true;
                    if (reason.statusText !== 'abort' && reason.status !== 504) {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.index.qualityCheckTasks
                                )
                        );
                    }
                }
            );
            // this.privates.charts = resp.data;
        });

        let sysConfigPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/companys/sys/config', this.props.sdkOptions);
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        return {
            ...allModel,
            charts: chartPromise,
            sysConfig: sysConfigPromise,
            // moduleData: modulePromise,
        };
    }
}
