import CoachHome from '../../pages/home/index';

import CourseManagementEntry from 'src/pages/coach/course-management/index';
import CourseManagementFlow from 'src/pages/coach/course-management/flow/index';
import CourseManagementBaseInfo from 'src/pages/coach/course-management/base-info/index';
import CustomerTagsManagement from 'src/pages/coach/customer-tags-management/index';
// 智能陪练-学习中心
import LearningCenter from '../../pages/coach/learning-center';
import LearningCenterTask from '../../pages/coach/learning-center/task';
import LearningCenterExerciseTask from '../../pages/coach/learning-center/task/exercise';
import LearningCenterExerciseTaskStart from '../../pages/coach/learning-center/task/start';
import LearningCenterExerciseTaskTextStart from '../../pages/coach/learning-center/task/start/text';
import LearningCenterExerciseTaskPPTStart from '../../pages/coach/learning-center/task/start/ppt';
import LearningCenterTestTask from '../../pages/coach/learning-center/task/test';
import LearningCenterTestTaskStart from '../../pages/coach/learning-center/task/start';
import LearningCenterTestTaskTextStart from '../../pages/coach/learning-center/task/start/text';
import LearningCenterTestTaskPPTStart from '../../pages/coach/learning-center/task/start/ppt';
import LearningCenterCourseLearn from '../../pages/coach/learning-center/task/learn';

import LearningCenterRecord from '../../pages/coach/learning-center/record';
import LearningCenterRecordDetail from '../../pages/coach/learning-center/record/detail';
import { LearningCenterCourse, LearningCenterCourseConfig } from '../../pages/coach/learning-center/course';
import LearningCenterCourseNew from '../../pages/coach/learning-center/course/edit';
import LearningCenterCourseEdit from '../../pages/coach/learning-center/course/edit';

// 任务中心
import TaskCenter from '../../pages/coach/task-center';
import TaskCenterList from '../../pages/coach/task-center/list';
import TaskCenterEdit from '../../pages/coach/task-center/edit';
import TaskCenterDetail from '../../pages/coach/task-center/detail';
import TaskCenterAudit from '../../pages/coach/task-center/audit';
import TaskCenterReview from '../../pages/coach/task-center/review';

// 系统管理
import AdminEntry from '../../pages/coach-admin-entry';
// 系统设置
import AdminSystemIndex from '../../pages/admin/system/coach-index';
//任务中心
import SystemTaskCenter from 'Component/pages/coach-system-task-center';
import SystemTaskCenterDownloadList from 'Component/pages/task-center/download-list/index';
import SystemTaskCenterUploadList from 'Component/pages/task-center/upload-list/index';
import SystemTaskCentertaskList from 'Component/pages/task-center/task-list/index';

import WrongQuestionSet from '../../pages/coach/learning-center/wrong-question-set/index';
import WrongQuestionSetList from '../../pages/coach/learning-center/wrong-question-set/list/index';
// 交互测试
import NodeConfigTest from 'src/pages/coach/learning-center/node-config-test';
import WrongQuestionSetExercise from '../../pages/coach/learning-center/wrong-question-set/exercise/index';
// 首页
import { AdminDashboard, StudentDashboard } from '../../pages/coach/dashboard/index';

//404
import PageNotFound from '../../pages/404';
import { getRouteNameWithCoach } from './const';


const coachRouteComponentByRouteNameMappings = {
    // 主界面
    coachHome: {
        component: CoachHome
    },
    coachLearningCenter: {
        component: LearningCenter,
    },
    coachLearningCenterTask: {
        component: LearningCenterTask,
    },
    coachLearningCenterRecord: {
        component: LearningCenterRecord,
    },
    coachLearningCenterRecordDetail: {
        component: LearningCenterRecordDetail,
    },
    coachLearningCenterCourse: {
        component: LearningCenterCourse,
    },
    coachLearningCenterCourseNew: {
        component: LearningCenterCourseNew,
    },
    coachLearningCenterCourseEdit: {
        component: LearningCenterCourseEdit,
    },
    coachLearningCenterCourseLearn: {
        component: LearningCenterCourseLearn,
    },
    coachLearningCenterExerciseTask: {
        component: LearningCenterExerciseTask,
    },
    coachLearningCenterExerciseTaskStart: {
        component: LearningCenterExerciseTaskStart,
    },
    coachLearningCenterExerciseTaskTextStart: {
        component: LearningCenterExerciseTaskTextStart,
    },
    coachLearningCenterExerciseTaskPPTStart: {
        component: LearningCenterExerciseTaskPPTStart,
    },
    coachLearningCenterTestTask: {
        component: LearningCenterTestTask,
    },
    coachLearningCenterTestTaskStart: {
        component: LearningCenterTestTaskStart,
    },
    coachLearningCenterTestTaskTextStart: {
        component: LearningCenterTestTaskTextStart,
    },
    coachLearningCenterTestTaskPPTStart: {
        component: LearningCenterTestTaskPPTStart,
    },
    coachLearningCenterCourseConfig: {
        component: LearningCenterCourseConfig,
    },
    courseManagementEntry: {
        component: CourseManagementEntry,
    },
    courseManagementDetail: {
        component: CourseManagementBaseInfo
    },
    courseManagementCreate: {
        component: CourseManagementBaseInfo
    },
    courseManagementFlow: {
        component: CourseManagementFlow
    },
    customerTagsManagement: {
        component: CustomerTagsManagement
    },
    courseManagementBaseInfo: {
        component: CourseManagementBaseInfo
    },
    coachTaskCenter: {
        component: TaskCenter,
    },
    coachTaskCenterList: {
        component: TaskCenterList,
    },
    coachTaskCenterEdit: {
        component: TaskCenterEdit,
    },
    coachTaskCenterDetail: {
        component: TaskCenterDetail,
    },
    coachTaskCenterAuditDetail: {
        component: LearningCenterRecordDetail,
    },
    coachTaskCenterReviewDetail: {
        component: LearningCenterRecordDetail,
    },
    coachTaskCenterAudit: {
        component: TaskCenterAudit,
    },
    coachTaskCenterReview: {
        component: TaskCenterReview,
    },
    // 系统管理
    coachAdminEntry: {
        component: AdminEntry,
    },
    // 系统设置
    coachAdminSystemIndex: {
        component: AdminSystemIndex,
    },
    // 任务中心
    coachSystemTaskCenter: {
        component: SystemTaskCenter,
    },
    coachSystemTaskCenterDownloadList: {
        component: SystemTaskCenterDownloadList,
    },
    coachSystemTaskCenterUploadList: {
        component: SystemTaskCenterUploadList,
    },
    coachSystemTaskCenterTaskList: {
        component: SystemTaskCentertaskList,
    },
    [getRouteNameWithCoach('wrongQuestionSet')]: {
        component: WrongQuestionSet
    },
    [getRouteNameWithCoach('wrongQuestionSetList')]: {
        component: WrongQuestionSetList
    },
    [getRouteNameWithCoach('wrongQuestionSetExercise')]: {
        component: WrongQuestionSetExercise
    },
    coachNodeConfigTest: {
        component: NodeConfigTest,
    },
    [getRouteNameWithCoach('dashboard')]: {
        component: AdminDashboard
    },
    [getRouteNameWithCoach('studDashboard')]: {
        component: StudentDashboard
    },
    coachPageNotFound: {
        component: PageNotFound,
    },
};

export {
    coachRouteComponentByRouteNameMappings
};
