// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostIntelligentPartnerTaskInfosStatisticsByIdExportParam,
    PostIntelligentPartnerTaskInfosStatisticsByIdExportBody,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 导出任务执行情况
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos/statistics/{id}/export
 * @param id
 */
export function postIntelligentPartnerTaskInfosStatisticsByIdExport(
    data: PostIntelligentPartnerTaskInfosStatisticsByIdExportBody,
    options: ApiOptions<PostIntelligentPartnerTaskInfosStatisticsByIdExportParam, never> & {
        segments: PostIntelligentPartnerTaskInfosStatisticsByIdExportParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PostIntelligentPartnerTaskInfosStatisticsByIdExportBody,
        PostIntelligentPartnerTaskInfosStatisticsByIdExportParam,
        never
    >('/intelligentPartnerTaskInfos/statistics/{id}/export', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentPartnerTaskInfosStatisticsByIdExport',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/statistics/{id}/export',
        method: 'post',
    },
];
