import React from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

class FastTrainingIndexComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {
    };
    privates = {
        //左侧智能标签
        semanticTagsInputVlaue: '',
        semanticTagsInputVlaueForSearch: '',
        semanticTagsList: [],
        semanticTagsTotal: 0,
        semanticTagsPageNum: 1,
        semanticTagsPageSize: 10,
        activeSemanticTag: null,
        //中间标准句
        standardSentence: {},//标准句
        standardSentenceEditFlag: false,
        standardSentenceEditInputShow: false,
        standardSentenceRef: React.createRef(),
        //中间相似句
        similarSentencesAddInputValue: '',
        similarSentencesModalVisible: false,
        // modalSearchType: 1,
        similarSentenceList: [],
        similarSentenceRefs: [],
        similarSentenceEditFlag: [],
        similarSentenceEditInputShow: [],
        excludeSentenceList: [],

        similarSearchCheckedList: [],
        similarSearchPlainOptions: [],
        similarSentencesModalSearchInputValue: '',
        similarSentencesModalSearchInputValueForSearch: '',
        similarSentenceSearchMoreFlag: [],
        similarSentenceSearchPageNum: 1,
        similarSentenceSearchPageSize: 10,
        similarSentenceSearchTotal: 0,
        showMoreVisity: false,
        showMoreList: [],
        customer: 0,
        loading: false,
        //右侧相似句推荐
        checkAllSimilarRecommend: [],
        similarRecommendCheckedList: [],
        indeterminate: true,
        similarRecommendPlainOptions: [],
        similarSentenceRecommendEditInputShow: [],
        similarSentenceRecommendEditFlag: [],
        similarSentenceRecommendRefs: [],
        similarRecommendPageNum: 1,
        similarRecommendTotal: 0,
        similarRecommendPageSize: 15,
        //公共的
        excludeIds:[],//移除的
        includeIds:[],//添加的
        detail:{},//上下文
        deleteSimilarIds: [],
        similarSentenceAddByHand: [],//手动输入添加的相似句list
        theChangingSentence: '',//正在修改的句子
        recommendIds: [],
        firstSearchFlag: true,
        similarSentenceCount: null,//用于提交前校验是否去重
    };

    actions = {
        save(){
            this.actions.postSubmitAjax();
        },
        cancel(){
            this.actions.selectSemanticTag(this.privates.activeSemanticTag);
        },
        postSubmitAjax(){
            let {
                sdkOptions,
                // match
            } = this.props;
            let params = {
                id: this.privates.activeSemanticTag.id,
                title: this.privates.activeSemanticTag.title,
                checkIds: this.privates.includeIds,
                deleteIds: this.privates.excludeIds,
                standardContent: this.privates.standardSentence.content,
                activeFlag: this.privates.activeSemanticTag.activeFlag,
                // tags: [this.privates.standardSentence, ...this.privates.similarSentenceAddByHand],
                tags: [this.privates.standardSentence, ...this.privates.similarSentenceList],
                deleteSimilarIds: this.privates.deleteSimilarIds
            };
            let url = Udesk.business.apiPath.concatApiPath(`semanticTag/training/save`, sdkOptions);
            Udesk.ajax.post(url, params).then(
                resp => {
                    Udesk.ui.notify.success(UdeskLocales.current.business.info.saveSuccess);
                    this.privates.similarSentenceCount = this.privates.similarSentenceList.length;
                    this.privates.excludeIds = [];
                    this.privates.includeIds = [];
                    this.privates.deleteSimilarIds = [];
                    this.privates.similarSentenceAddByHand = [];
                    this.actions.getSimilarSentenceAdded();
                    this.actions.reloadModel();
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getSimilarSentencesModalSearch(){
            let {
                sdkOptions,
                // match
            } = this.props;
            let params = {
                keywords: this.privates.similarSentencesModalSearchInputValueForSearch,
                pageNum: this.privates.similarSentenceSearchPageNum,
                pageSize: this.privates.similarSentenceSearchPageSize,
            };
            this.privates.loading = true;
            this.actions.update();
            let url = Udesk.business.apiPath.concatApiPath(`semanticTag/training/searchSentences`, sdkOptions);
            Udesk.ajax.post(url, params).then(
                resp => {
                    this.privates.similarSearchPlainOptions = resp.data && resp.data.length? convertKey(resp.data, {'sentence':'content'}):[];
                    this.privates.similarSentenceSearchTotal = resp.paging.total;
                    this.privates.loading = false;
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getSimilarSentenceRecommend(){
            //ajax
            let {
                sdkOptions,
                // match
            } = this.props;
            let excludeSentences =[];
            this.privates.similarSentenceList.length && this.privates.similarSentenceList.forEach(item => {
                excludeSentences.push(item.content);
            });
            this.privates.excludeSentenceList.length && this.privates.excludeSentenceList.forEach(item => {
                excludeSentences.push(item.content);
            });
            let params = {
                tagId: this.privates.standardSentence.id,
                groupId: this.privates.activeSemanticTag.id,
                pageNum: this.privates.similarRecommendPageNum,
                pageSize: this.privates.similarRecommendPageSize,
                excludeIds: [...this.privates.excludeIds, ...this.privates.includeIds],
                excludeSentences
            };
            let url = Udesk.business.apiPath.concatApiPath(`semanticTag/training/system-sentences`, sdkOptions);
            Udesk.ajax.post(url, params).then(
                resp => {
                    this.privates.similarRecommendPlainOptions = resp.data && resp.data.length?convertKey(resp.data, {'sentence':'content'}):[];
                    resp.paging && (this.privates.similarRecommendTotal = resp.paging.total);
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getSimilarSentenceAdded(){
            this.privates.standardSentence = [];
            this.privates.similarSentenceList = [];
            this.privates.excludeSentenceList = [];
            let {
                sdkOptions,
                // match
            } = this.props;
            if(this.privates.activeSemanticTag?.id == null) return;
            let url = Udesk.business.apiPath.concatApiPath(`/semantic-tag/${this.privates.activeSemanticTag.id}`, sdkOptions);
            Udesk.ajax.get(url).then(
                resp => {
                    resp.data.tags.forEach(tag => {
                        tag.standardFlag === 1 && (this.privates.standardSentence = tag);
                        tag.standardFlag === 0 && this.privates.similarSentenceList.push(tag);
                        tag.standardFlag === 2 && this.privates.excludeSentenceList.push(tag);
                    });
                    if(this.privates.similarSentenceCount !== null
                        && this.privates.similarSentenceCount !== this.privates.similarSentenceList.length){
                        Udesk.ui.notify.success(UdeskLocales.current.components.pages.trainingCenter.fastTraining.repetRemoveTip);
                    }
                    this.privates.similarSentenceCount = null;
                    this.actions.getSimilarSentenceRecommend();
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        semanticTagsInput(e){
            this.privates.semanticTagsInputVlaue = e.target.value;
            this.actions.update();
        },
        semanticTagsSearch(){
            this.privates.semanticTagsInputVlaueForSearch = this.privates.semanticTagsInputVlaue;
            this.privates.semanticTagsPageNum = 1;
            this.actions.reloadModel();
            this.privates.firstSearchFlag = false;
        },
        pageNumChange(type, page, pageSize){
            if(type === 'tags'){
                this.privates.semanticTagsPageNum = page;
                this.actions.reloadModel();
                this.actions.update();    
            }
            if(type === 'recommend'){
                this.privates.similarRecommendPageNum = page;
                this.privates.similarRecommendPageSize = pageSize;
                //发送ajax
                this.actions.getSimilarSentenceRecommend();
                this.actions.update();    
            }
            if(type === 'search'){
                this.privates.similarSentenceSearchPageNum = page;
                this.actions.getSimilarSentencesModalSearch();
                //发送ajax
                this.actions.update();    
            }
        },
        selectSemanticTag(item){
            this.privates.activeSemanticTag = item;
            this.privates.similarRecommendPageNum = 1;
            this.privates.similarSentencePageNum = 1;
            this.privates.similarSentenceList = [];
            this.privates.excludeIds = [];
            this.privates.includeIds = [];
            this.privates.similarRecommendCheckedList = [];
            this.privates.deleteSimilarIds = [];
            this.privates.similarSentenceAddByHand = [];
            this.actions.getSimilarSentenceAdded();
            this.privates.similarSentenceRefs = [];
            this.privates.similarSentenceRecommendEditInputShow = [];
            this.actions.update();
        },
        similarSentencesAdd(value){
            if(value === '') return;
            let index = this.privates.similarSentenceList.findIndex(item => item.content === value);
            if(index === -1){
                this.privates.similarSentenceList.unshift({
                    content: value
                });
                this.privates.similarSentenceAddByHand.push({
                    content: value
                });
                this.privates.similarSentencesAddInputValue = '';
                this.actions.update();
            }else {
                Udesk.ui.notify.error(UdeskLocales.current.components.pages.trainingCenter.fastTraining.repetWarning);
            }
        },
        similarSentencesAddByEnter(value){
            this.actions.similarSentencesAdd(value);
        },
        similarSentencesAddInputValueChange(e){
            this.privates.similarSentencesAddInputValue = e.target.value;
            this.actions.update();
        },
        similarSentencesSearch(){
            this.privates.similarSentencesModalVisible = true;
            this.privates.similarSentencesModalSearchInputValue = this.privates.similarSentencesAddInputValue;
            this.actions.similarSentencesModalSearch(this.privates.similarSentencesModalSearchInputValue);
            this.actions.update();
        },
        similarSentencesModalSearch(value){
            this.privates.similarSentencesModalSearchInputValueForSearch = this.privates.similarSentencesModalSearchInputValue;
            //发送ajax
            this.actions.getSimilarSentencesModalSearch();
        },
        similarSentencesModalSearchInputValueChange(e){
            this.privates.similarSentencesModalSearchInputValue = e.target.value;
            this.actions.update();
        },
        similarSentencesModalSearchByEnter(value){
            this.actions.similarSentencesModalSearch(value);
        },
        similarSearchCheckboxOnChange(pageNum, checkedList){
            this.privates.similarSearchCheckedList[pageNum] = checkedList;
            this.actions.update();
        },
        // modalSearchTypeChange(value){
        //     this.privates.modalSearchType = value;
        //     this.actions.update();
        // },
        similarSentencesModalOk(){
            this.privates.similarSentencesModalVisible = false;
            this.privates.showMoreVisity = false;
            let list = this.privates.similarSearchCheckedList.flat(1);
            let similarSearchCheckedList = [];
            list.forEach(item => {
                similarSearchCheckedList.push({
                    content: item
                });
            });
            this.privates.similarSentenceList = [...similarSearchCheckedList, ...this.privates.similarSentenceList];
            this.privates.similarSearchCheckedList = [];
            this.privates.similarSearchPlainOptions = [];
            this.privates.similarSentenceSearchPageNum = 1;
            this.actions.update();
        },
        similarSentencesModalCancel(){
            this.privates.similarSentencesModalVisible = false;
            this.privates.showMoreVisity = false;
            this.actions.update();
        },
        showEdit(flag, type, index){
            if(type === 'similar'){
                this.privates.similarSentenceEditFlag[index] = flag? true : false;
                this.actions.update();    
            }
            if(type === 'standard'){
                this.privates.standardSentenceEditFlag = flag? true : false;
                this.actions.update();    
            }
            if(type === 'recommend'){
                this.privates.similarSentenceRecommendEditFlag[index] = flag? true : false;
                this.actions.update();    
            }
            if(type === 'search'){
                this.privates.similarSentenceSearchMoreFlag[index] = flag? true : false;
                this.actions.update();    
            }
        },
        editSentence(flag, type, index){
            if(type === 'similar'){
                this.privates.similarSentenceEditInputShow[index] = flag;
                //点击编辑时
                if(flag === 1){
                    this.privates.theChangingSentence = this.privates.similarSentenceList[index];
                }
                //input失焦时
                if(flag === 0){
                    if(this.privates.similarSentenceList[index].content === ''){
                        this.actions.deleteSentence(this.privates.similarSentenceList[index], index);
                        return ;
                    }
                    let changeIndex = this.privates.similarSentenceAddByHand.findIndex(item => item.content === this.privates.theChangingSentence.content);
                    this.privates.similarSentenceAddByHand.splice(changeIndex, 1);
                    this.privates.similarSentenceAddByHand.push(this.privates.similarSentenceList[index]);
                    this.privates.similarSentenceList.forEach((item, itemIndex) => {
                        if(itemIndex !== index
                            && item.content === this.privates.similarSentenceList[index].content
                        ){
                            Udesk.ui.notify.error(UdeskLocales.current.components.pages.trainingCenter.fastTraining.repetWarning);
                        }
                    });
                }
                this.actions.update();
                let that = this;
                setTimeout(() => {
                    that.privates.similarSentenceRefs[index] && that.privates.similarSentenceRefs[index].input.focus();
                },100);    
            }
            if(type === 'standard'){
                this.privates.standardSentenceEditInputShow = flag;
                this.actions.update();
                let that = this;
                setTimeout(() => {
                    that.privates.standardSentenceRef.current && that.privates.standardSentenceRef.current.input.focus();
                },100);    
            }
            if(type === 'recommend'){
                this.privates.similarSentenceRecommendEditInputShow[index] = flag;
                this.actions.update();
                let that = this;
                setTimeout(() => {
                    that.privates.similarSentenceRecommendRefs[index] && that.privates.similarSentenceRecommendRefs[index].input.focus();
                },100);
            }
        },
        deleteSentence(similarSentence, index){
            //传参应该传key
            // let repetIndex = this.privates.similarSentenceList.findIndex(item => similarSentence.id && item.id === similarSentence.id);
            // this.privates.similarSentenceList[repetIndex].id && this.privates.deleteSimilarIds.push(this.privates.similarSentenceList[repetIndex].id);
            // this.privates.similarSentenceList.splice(repetIndex, 1);
            this.privates.similarSentenceList[index].id && this.privates.deleteSimilarIds.push(this.privates.similarSentenceList[index].id);
            this.privates.similarSentenceList.splice(index, 1);
            this.actions.update();
        },
        saveNewSentence(type, index = null, e){
            if(type === 'standard'){
                this.privates.standardSentence.content = e.target.value;
                this.actions.update();
            }
            if(type === 'similar'){
                this.privates.similarSentenceList[index].content = e.target.value;
                this.actions.update();
            }
            if(type === 'recommend'){
                this.privates.similarRecommendPlainOptions[index].content = e.target.value;
                this.actions.update();
            }
        },
        setSimilarSentenceRefs(index, ref){
            this.privates.similarSentenceRefs[index] = ref;
        },
        setSimilarSentenceRecommendRefs(index, ref){
            this.privates.similarSentenceRecommendRefs[index] = ref;
        },
        oncheckAllSimilarRecommendChange(similarRecommendPageNum, e){
            let optionsList = [];
            this.privates.similarRecommendPlainOptions.forEach(tiem => {
                optionsList.push(tiem.content);
            });
            this.privates.similarRecommendCheckedList[similarRecommendPageNum] = e.target.checked ? [...optionsList] : [];
            this.privates.indeterminate = false;
            this.privates.checkAllSimilarRecommend[similarRecommendPageNum] = e.target.checked? true: false;
            this.actions.update();
        },
        similarRecommendCheckboxOnChange(pageNum, checkedList){
            this.privates.similarRecommendCheckedList[pageNum] = checkedList;
            this.privates.indeterminate = !!checkedList.length && checkedList.length < this.privates.similarRecommendPlainOptions.length;
            this.privates.checkAllSimilarRecommend[pageNum] = this.privates.similarRecommendCheckedList[pageNum].length === this.privates.similarRecommendPlainOptions.length;
            this.actions.update();
            let idList = [];
            this.privates.similarRecommendPlainOptions.forEach(PlainOption => {
                checkedList.forEach(item => {
                    PlainOption.content === item && idList.push(PlainOption.id);
                });
            });
            this.privates.recommendIds[pageNum] = idList;
        },
        addSimilarFromRecommend(){
            let list = this.privates.similarRecommendCheckedList.flat(1);
            let similarSentenceListAdd = [];
            list.forEach(item => {
                similarSentenceListAdd.push({
                    content: item
                });
            });
            this.privates.showMoreVisity = false;
            this.privates.checkAllSimilarRecommend = [];
            this.privates.similarSentenceList = [...similarSentenceListAdd, ...this.privates.similarSentenceList];
            // this.privates.similarRecommendCheckedList.forEach(item => {
            //     this.privates.includeIds.push(item.id);
            //     this.privates.similarSentenceAddByHand.push({
            //         content: item
            //     });
            // });
            this.privates.includeIds= [...this.privates.includeIds, ...this.privates.recommendIds.flat(1)];
            this.privates.recommendIds = [];
            this.privates.similarRecommendCheckedList = [];
            this.privates.similarRecommendPageNum = 1;
            this.actions.getSimilarSentenceRecommend();
            this.actions.update();
        },
        removeSimilarFromRecommend(){
            this.privates.recommendIds.flat(1).forEach(item => {
                this.privates.excludeIds.push(item);
            });
            this.privates.recommendIds = [];
            this.privates.similarRecommendCheckedList = [];
            this.actions.getSimilarSentenceRecommend();
            this.actions.update();
        },
        showMore(item){
            this.actions.getSimilarSearchMore(item);
            this.privates.showMoreVisity = true;
            this.actions.update();
        },
        closeShowMore(){
            this.privates.showMoreVisity = false;
            this.actions.update();
        },
        getSimilarSearchMore(item){
            let {
                sdkOptions,
                // match
            } = this.props;
            let params = {
                callId: item.callId,
                sentences: item.content,
                sentenceIndex: item.sentenceIndex || null,
            };
            let url = Udesk.business.apiPath.concatApiPath(`semanticTag/training/context-sentences`, sdkOptions);
            Udesk.ajax.post(url, params).then(
                resp => {
                    this.privates.customer = resp.data.agentChannel;
                    this.privates.showMoreList = resp.data.sentenceResults;
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
    };
    //#region Life Cycle
    componentDidMount() {
        this.actions.getSimilarSentenceAdded();
        this.actions.update();
    }
    componentWillUnmount() {}
    //#endregion
    
}

function evil (fn) {
    let Fn = Function;
    return new Fn('return ' + fn)();
}

function convertKey(arr,keyMap){
    let tempString = JSON.stringify(arr);
    for(var key in keyMap){
        var reg = `/"${key}":/g`;
        tempString = tempString.replace(evil(reg),'"'+keyMap[key]+'":');
        // tempString = tempString.replace(eval(reg),'"'+keyMap[key]+'":');
    }
    return JSON.parse(tempString);
}


export default FastTrainingIndexComponent;