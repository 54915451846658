import Udesk from 'Udesk';
import AdminSystemIndexComponent from './component';

export default class AdminSystemIndexRoute extends AdminSystemIndexComponent {
    model() {
        const { props } = this;
        let params = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));
        let modelPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/companys/sys/config', props.sdkOptions);
            Udesk.ajax.get(url, params).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            sysConfig: modelPromise
        };
    }
}
