import React from "react";
import Udesk from 'Udesk';
import SubRoutes from "udesk-react/src/components/sub-routes";
import NavBar from "udesk-react/src/components/nav-bar";
import { Redirect } from "react-router-dom";
import "./task-center/style.scss";
import { PageHeader } from 'udesk-ui';

class taskCenter extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    privates = {
        childProps: {
            pageNum: Udesk.config.paging.defaultPageNumber,
            pageSize: Udesk.config.paging.defaultPageSize,
            total: null,
            selectedDatum: {},
            selectedFilter: {
                conditionList: [],
                customJudgeLogic: null,
                judgeStrategy: 1
            }
        }
    };
    init() {
        let that = this;
        this.privates.childProps.onPageChanged = function (
            pageNum,
            pageSize,
            total
        ) {
            that.privates.childProps.pageNum = pageNum;
            that.privates.childProps.pageSize = pageSize;
            that.privates.childProps.total = total;
        };
        this.privates.childProps.getPageConfig = function () {
            let { pageNum, pageSize, total } = that.privates.childProps;
            return {
                pageNum: pageNum,
                pageSize: pageSize,
                total: total
            };
        };
        this.privates.childProps.onSelectedDatumChanged = function (item) {
            that.privates.childProps.selectedDatum = item;
        };
        this.privates.childProps.onSelectedFilterChanged = function (filter) {
            that.privates.childProps.selectedFilter = filter;
        };
    }

    render() {
        let { routes, route, location, sdkOptions } = this.props;
        // let { childProps } = this.privates;
        let { locales } = this;
        let navLists = [
            {
                pathName: "coachSystemTaskCenterDownloadList",
                text: locales.components.pages.uploadDownloadList.tabs.download,
                isActive: (match, location) => {
                    return location.pathname.includes("/download-list");
                }
            },
            {
                pathName: "coachSystemTaskCenterUploadList",
                text: locales.components.pages.uploadDownloadList.tabs.upload,
                isActive: (match, location) => {
                    return location.pathname.includes("/upload-list");
                }
            },
        ];
        if (this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: route.name
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className="quality-checking-task" style={{ height: '100%' }}>
                    {/* <div className="row qa-react-page call-manage-page"> */}
                    <div className='udesk-qa-web-page'>
                        <PageHeader
                            className="udesk-qa-web-page-header"
                            title={locales.components.pages.uploadDownloadList.title}
                            subTitle={locales.fix.onlySave30Day}
                        />
                        <div className='udesk-qa-web-page-body'>
                            <div className='udesk-qa-web-page-body-root admin-user-detail' style={{ padding: 16 }}>
                                <div className="qa-react-list-page">
                                    <NavBar
                                        navLists={navLists}
                                        classNames="header-uploadDown"
                                        navItemClassNames="header-nav-item"
                                        activeClassNames="active-uploadDown"
                                    />
                                    <div>
                                        <For each="route" index="i" of={routes}>
                                            <SubRoutes key={i} route={route} />
                                        </For>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(taskCenter);
