import Udesk from 'Udesk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_DATE_TIME_FORMAT, getDateTimeString } from 'src/util/moment';
import { createMessage } from '../../../util';
import { usePassThroughParams } from '../../..';
import { usePPTRecorder } from '../..';
import { useDebounce } from 'src/util/hook';
import { fire, isBoolean } from 'src/util/core';
import { useAnswerTimeout, useStartSecond, useSuspend } from '../../hook';
import moment from 'moment';
import UdeskLocales from 'UdeskLocales';

export const isCustomMessage = item => {
    const isNumber = typeof item.isSync === 'number';
    if (isBoolean(item.isNotInList)) {
        return item.isNotInList === false;
    }
    return isNumber;
};
// 获取用户自己定义的消息，而非自动生成的消息
export const getCustomMessageList = (messageList: any[]) => {
    return messageList.filter(isCustomMessage);
};

export const useRecord = (props: any) => {
    const {request, task, record, setRecord, goIndex} = props;
    const {
        isPauseRecord, 
        recordList, 
        startRecord, pauseRecord, stopRecord, 
        uploadFile
    } = usePPTRecorder(task);
    const [isStartTask, setIsStartTask] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [list, setList] = useState<any[]>([]);
    const [dialogue, setDialogue] = useState<any[]>([]);
    const [getPassThroughParams, setPassThroughParams, passThroughParams] = usePassThroughParams();
    const [initStartSecond, newStartSecond] = useStartSecond();
    const [globalConfig, setGlobalConfig] = useState<any>(null);

    const [loadingForTaskStart, setLoadingForTaskStart] = useState(false);
    const [loadingForSendMessage, setLoadingForSendMessage] = useState(false);

    const {
        suspendDetail,
        isSuspend, isSuspending, isLoadSuspendInfo, 
        loadingForSuspend, loadingForRestart, 
        pauseTask, continueTask, restartTask,
    } = useSuspend(request, task, record, setRecord);

    useAnswerTimeout(globalConfig, data, isSuspending);

    const [lastRecord, lastRecordText] = useMemo(() => {
        if (recordList && recordList.length > 0) {
            const last = recordList[recordList.length - 1];
            return [
                last,
                last?.text
            ];
        }
        return [undefined, ''];
    }, [recordList]);

    const startAudioRecord = useDebounce((info) => {
        const {isStart, bindNodeId, pptUrl} = info;
        if (bindNodeId && pptUrl) {
            const newMessage = createMessage({
                bindNodeId,
                pptUrl,
                isSync: 0, // 0：未保存 1：正在保存 2：保存成功
                startSecond: newStartSecond(),
            });
            setData(newMessage);
        }
        if (isStart) {
            startRecord();
        }
    }, [startRecord]);

    const pauseAudioRecord = useCallback(() => {
        pauseRecord();
    }, [pauseRecord]);

    const stopAudioRecord = useCallback(() => {
        stopRecord();
        setLoadingForSendMessage(true);
    }, [stopRecord]);

    const updateDataInfo = useCallback((data, info) => {
        setList(list => {
            return list.map(item => {
                if (item.id === data.id) {
                    return $.extend(true, {}, item, info);
                }

                return item;
            });
        });
    }, []);

    const sendMessage = useDebounce((data: any, fileInfo?) => {
        const params = getPassThroughParams({
            bindNodeId: data.bindNodeId, // PPT图片对应的节点ID
            pptUrl: data.pptUrl, // PPT图片路径
            taskId: task?.id, // 任务ID
            dialogueMode: task?.dialogueMode, // 对话类型 1-语音 2-文本 3-ppt
            words: fileInfo?.text, // 用户说的话，如果是语音，需转化为文字
            speakDuration: fileInfo?.duration, // 说话时长 毫秒
            speakTime: data.createDate, // 说话时间
            ossKey: fileInfo?.url, // 语音上传的地址
        });
        request(`/intelligentPartner/${record?.id}/interactive`, params, 'post').then(
            resp => {
                const newPassThroughParams = {
                    flowId: passThroughParams.flowId, // 对话流程ID，后续交互需要作为参数传入
                };

                setPassThroughParams(newPassThroughParams);
                updateDataInfo(data, {
                    isSync: 2
                });
            },
            reason => {
                updateDataInfo(data, {
                    isSync: 0
                });
                Udesk.ui.notify.error(reason.errorMsg || /* 服务器错误 */UdeskLocales['current'].pages.coach.learningCenter.components.record.ppt.hook.index.serverError);
            }
        ).finally(() => {
            setLoadingForSendMessage(false);
        });
    }, [
        task?.id, 
        task?.dialogueMode, 
        request, 
        record?.id, 
    ]);

    useEffect(() => {
        if (data) {
            setList(list => {
                const newList = [...list];
                const index = list.findIndex(item => item.id === data.id);

                if (index > -1) {
                    newList.splice(index, 1, data);
                } else {
                    newList.push(data);
                }
                return newList;
            });
        }
    }, [data]);

    // 文件是否已经停止，并且已经完成了ASR转译及上传
    const isOver = useMemo(() => {
        return recordList.every(item => {
            if (item.isAsrOver) {
                if (item.text) {
                    return item.isFileUploadOver;
                }
                return true;
            }
            return false;
        });
    }, [recordList]);

    useEffect(() => {
        const isReady = (isSync, recorder) => {
            return isSync === 0 && recorder.text && recorder.isAsrOver && recorder.isFileUploadOver;
        };
        const isResetLoadingReady = (isSync, recorder) => {
            return isSync === 0 && recorder.text === '' && recorder.isAsrOver;
        };
        const isUploadReady = (isSync, recorder) => {
            return isSync === 0 && recorder.text && recorder.isAsrOver && recorder.isFileUploadOver === false;
        };
        if (recordList.length > 0) {
            setList((list) => {
                const newList = [...list];
                getCustomMessageList(list).forEach((item, index) => {
                    const recorder = recordList[index];
                    if (recorder) {
                        item.ossKey = recorder.url || '/';
                        item.content = recorder.text;

                        if (isUploadReady(item.isSync, recorder)) {
                            uploadFile(recorder, () => {
                                setLoadingForSendMessage(false); // 上传失败将接触按钮loading状态
                            });
                        }
                        if (isResetLoadingReady(item.isSync, recorder)) {
                            item.isSync = 2 ;// 这里将ASR转译为空的消息标记为已经完成
                            setLoadingForSendMessage(false);
                        }
                        if (isReady(item.isSync, recorder)) {
                            item.isSync = 1;
                            sendMessage(item, recorder);
                        }
                    }
                });

                return newList;
            });
        }
    }, [recordList, sendMessage, uploadFile]);

    // 开始任务
    const startTaskHandle= useCallback((callback) => {
        if (task) {
            setLoadingForTaskStart(true);
            if (isSuspend) {
                continueTask((data) => {
                    const {dialogue, lastNodeDetail, globalConfig} = data;
                    setDialogue(dialogue);
                    goIndex(dialogue.length);
                    if (lastNodeDetail) {
                        setPassThroughParams(lastNodeDetail);
                    }      
                    setIsStartTask(true); //这里标记开始录音
                    if (globalConfig) {
                        setGlobalConfig(globalConfig);
                    }
                    let start;
                    setRecord(record => {
                        const {id, costTime} = record;
                        start = moment().subtract('seconds', costTime);
                        return {
                            id, dialogueStartTime: start.format(DEFAULT_DATE_TIME_FORMAT)
                        };
                    });
                    if (start) {
                        initStartSecond(start.toDate().getTime()); // 初始化任务开始时间
                    }
                }).finally(() => {
                    setLoadingForTaskStart(false);
                });
            } else {
                request('/intelligentPartner/start', {
                    taskId: task.id,
                    taskType: task.taskType,
                    dialogueMode: Udesk.enums.learningTaskDialogueModeType.ppt.id,
                    time: getDateTimeString(),
                    lessonId: task.courseId
                }, 'post').then(
                    resp => {
                        const globalConfig = resp.data?.trainingWords?.globalConfig;
                        const passThroughParams = {
                            flowId: resp.data?.flowId, // 对话流程ID，后续交互需要作为参数传入
                        };
                        if (globalConfig) {
                            setGlobalConfig(globalConfig);
                        }
                        setIsStartTask(true); //这里标记开始录音
                        setPassThroughParams(passThroughParams);
                        setRecord(resp.data?.record);
                        initStartSecond(); // 初始化任务开始时间
                        fire(callback);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                ).finally(() => {
                    setLoadingForTaskStart(false);
                });
            }
        }
    }, [
        task, 
        isSuspend, 
        continueTask, 
        setRecord, 
        setPassThroughParams, 
        initStartSecond, 
        request,
        goIndex,
    ]);


    return {
        loadingForSendMessage,
        loadingForTaskStart, // 任务开始的loading
        passThroughParams,
        record,
        isStartTask,
        isPauseRecord,
        isOver,
        pause: pauseAudioRecord,
        stop: stopAudioRecord,
        list,
        recordList,
        lastRecord,
        lastRecordText,
        startTaskHandle,
        startAudioRecord,

        dialogue,
        suspendDetail,
        isSuspend, isSuspending, isLoadSuspendInfo, 
        loadingForSuspend, loadingForRestart, 
        pauseTask, continueTask, restartTask,
    };
};