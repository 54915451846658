import React from 'react';
import Udesk from 'Udesk';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import UdeskLocales from 'UdeskLocales';

class intelligentAnalysis extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let {
            route,
            location,
            sdkOptions,
        } = this.props as any;

        let {
            locales
        } = this as any;

        let navLists: any[] = [];
        // 根据当前用户权限，组装菜单列表
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature("smart:tag:root")) {
            let pathName = 'sessionLabelTagsIndex';
            navLists.push({
                text: locales.components.pages.intelligentTags.title,
                pathName,
                isActive: (match, location) => {
                    return location.pathname.includes("/intelligent-tags");
                }
            });
        }

        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature("smart:tag:test")) {
            navLists.push({
                text: /* 规则测试 */locales.pages.intelligentAnalysis.ruleTest,
                pathName: "supervisionTest",
                isActive: (match, location) => {
                    return location.pathname.includes("/supervision-test");
                }
            });
        }
        navLists.push({
            text: /* 销售阶段 */UdeskLocales['current'].pages.gong.salesBusinessConfigurationEntry.salesStage,
            pathName: 'salesStageIndex',
            isActive: (match, location) => {
                return location.pathname.includes('/sales-stage');
            },
        });
        navLists.push({
            text: /* 沟通策略 */UdeskLocales['current'].pages.gong.salesBusinessConfigurationEntry.communicationStrategy,
            pathName: 'communicationStrategyIndex',
            isActive: (match, location) => {
                return location.pathname.includes('/communication-strategy');
            },
        });
        navLists.push({
            text: /* 风险配置 */UdeskLocales['current'].pages.gong.salesBusinessConfigurationEntry.riskAllocation,
            pathName: 'riskStatementIndex',
            isActive: (match, location) => {
                return location.pathname.includes('/risk-statement');
            },
        });

        if (this.isRedirect(route.path, location.pathname) && sdkOptions.props.landingPath != null) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].pathName
            });
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else {
            return (
                <div className="semantic-intelligence">
                    {/* <div className="semantic-intelligence-nav col-md-2 col-sm-3 col-xs-12">
                        <NavBar navLists={navLists} classNames="semantic-intelligence-nav-container" navItemClassNames="semantic-intelligence-nav-item" activeClassNames="semantic-intelligence-nav-item-active" />
                    </div>
                    <div className="semantic-intelligence-content col-md-10 col-sm-9 col-xs-12">
                        <For each="route" index="i" of={routes}>
                            <If condition={!route.authCode || (route.authCode && Udesk.data.init.user.hasFeature(route.authCode))}>
                                <SubRoutes key={i} route={route} />
                            </If>
                        </For>
                    </div> */}
                    <LayoutMenu {...this.props} navLists={navLists} menuTitle={/* 业务配置 */UdeskLocales['current'].pages.gong.salesBusinessConfigurationEntry.businessConfiguration} />
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(intelligentAnalysis);