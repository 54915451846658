import Page from 'Component/common/udesk-qa-page-layout';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import hookProps from 'udesk-react/src/udesk/react/hook-props';
import moment from 'moment';
import Locales from 'UdeskLocales';
import ExecutionFilter from '../components/ExecutionFilter';
import LessonSelect, { useLessonSelect } from '../components/LessonSelect';
import { postIntelligentPartnerTaskInfosStatisticsFindAll } from 'src/api/intelligentPartnerTaskInfos/statistics/findAll';
import { Form, Typography } from 'udesk-ui';
import { deleteIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { useTaskCenterPageInfoCache } from '../components/cache';
import { CommonFilterProps } from 'udesk_gm_ui/es/common-filter';
import { useTableBtn } from 'src/util/hook/table';
import './index.less';

const DEFAULT_PAGE = {
    current: 1,
    pageSize: 20,
    total: 0,
};

const TaskCenter: React.FC<any> = (props) => {
    const locales = Locales['current'];
    const { history } = props;
    const [filterForm] = Form.useForm();
    const [cache, saveCache] = useTaskCenterPageInfoCache();
    const [pagination, setPagination] = useState<any>(cache?.pagination || DEFAULT_PAGE);
    const [dataSource, setDataSource] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const queryParams = new URLSearchParams(props.location.search);
    const lessonId = parseInt(queryParams.get('lessonId') || '') || undefined;
    const { allLessons } = useLessonSelect();
    const {submitFilterForm} = useTableBtn('task-center-list-body');

    useEffect(() => {
        const $body = $('.task-center-list-body .udesk-qa-ui-common-filter').on('click', '.udesk-qa-ui-btn-default', (event, isTree) => {
            saveCache({
                filter: {}, pagination: DEFAULT_PAGE
            });
            if (!isTree) {
                submitFilterForm();
            }
            return !!isTree;
        });

        return () => {
            $body.off('click');
        };
    }, [
        submitFilterForm, 
        saveCache
    ]);

    const TaskStatusOptions = useMemo(() => {
        return [
            // {
            //     label: locales.pages.gong.coachTaskCenter.list.taskTypeAll,
            //     value: 4,
            // },
            {
                label: locales.pages.gong.coachTaskCenter.list.taskStatusNotStarted,
                value: 1,
            },
            {
                label: locales.pages.gong.coachTaskCenter.list.taskStatusRunning,
                value: 2,
            },
            {
                label: locales.pages.gong.coachTaskCenter.list.taskStatusEnd,
                value: 3,
            },
            {
                label: locales.pages.gong.coachTaskCenter.list.taskStatusInvalid,
                value: 0,
            },
        ];
    }, []);

    const TaskTypeOptions = useMemo(() => {
        return [
            {
                label: locales.pages.gong.coachTaskCenter.list.taskTypePractice,
                value: 1,
            },
            {
                label: locales.pages.gong.coachTaskCenter.list.taskTypeTest,
                value: 2,
            },
            // {
            //     label: locales.pages.gong.coachTaskCenter.list.taskTypeAll,
            //     value: 3,
            // },
        ];
    }, []);

    const onEdit = useCallback(
        (id: any) => {
            const routeName = 'coachTaskCenterEdit';
            const pathParams = {
                id,
            };
            Udesk.ui.routing.transitionTo({
                history,
                routeName,
                pathParams,
            });
        },
        [history]
    );

    const onShow = useCallback(
        (id: any) => {
            const routeName = 'coachTaskCenterDetail';
            const pathParams = {
                id,
            };
            Udesk.ui.routing.transitionTo({
                history,
                routeName,
                pathParams,
            });
        },
        [history]
    );

    const onDelete = (id: any) => {
        deleteIntelligentPartnerTaskInfosById({
            segments: { id },
            successMsg: locales.pages.gong.coachTaskCenter.list.deleteSuccess,
        }).then(() => {
            (
                document.getElementsByClassName('udesk-qa-ui-btn udesk-qa-ui-btn-primary')[0] as any
            )?.click?.();
        });
    };

    const onTableRequest = (params) => {
        saveCache(params);
        setLoading(true);

        const reqData = {
            pageNum: params.pagination.current, // 页数
            pageSize: params.pagination.pageSize, // 每页条数
            taskName: params.filter?.taskName, // 任务名称
            lessonIds: params.filter?.lessonIds, // 课程对应的ID数组
            taskTypes: params.filter?.taskTypes, // 任务类型数组 1-练习 2-考试 3-全部
            taskStatuses: params.filter?.taskStatuses, // 任务状态数组 0-失效 1-未开始 2-进行中 3-已结束 4-全部
            executeType: params.filter?.execetionInfo?.executeType, // 执行情况筛选，执行类型 1-执行率 2-完成率 3-达标率 4-优秀率
            executeOperate: params.filter?.execetionInfo?.executeOperate, // 执行情况筛选 操作符 eq - 等于 more_than_eq - 大于等于 less_than_eq 小于等于
            executeValue: params.filter?.execetionInfo?.executeValue, // 执行情况筛选 达标率等数值
        };

        if (lessonId) {
            reqData.lessonIds = [lessonId];
        }

        postIntelligentPartnerTaskInfosStatisticsFindAll(reqData)
            .then((resp) => {
                setDataSource(
                    (resp?.data || []).map((item) => {
                        let taskTimeVal = locales.pages.gong.coachTaskCenter.list.unlimitedTime;
                        if (item.taskStartTime && item.taskEndTime) {
                            taskTimeVal = `${moment(item.taskStartTime).format(
                                'YYYY-MM-DD HH:mm'
                            )} ${locales.pages.gong.coachTaskCenter.list.to} ${moment(
                                item.taskEndTime
                            ).format('YYYY-MM-DD HH:mm')}`;
                        }
                        return {
                            ...item,
                            taskTime: taskTimeVal,
                        };
                    })
                );
                setPagination({
                    total: resp.paging?.total,
                    current: resp.paging?.pageNum || 1,
                    pageSize: resp.paging?.pageSize,
                });
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const filter: CommonFilterProps = useMemo(() => {
        return {
            form: filterForm,
            customComponents: {
                LessonSelect,
                ExecutionFilter,
            },
            fields: [
                {
                    name: 'taskName',
                    label: locales.pages.gong.coachTaskCenter.list.taskName,
                    type: 'Input',
                    props: {
                        placeholder: locales.labels.pleaseEnter,
                        style: { width: 200 },
                    },
                },
                {
                    name: 'lessonIds',
                    label: locales.pages.gong.coachTaskCenter.list.lessonName,
                    type: 'LessonSelect',
                    props: {
                        placeholder: locales.labels.pleaseSelect,
                        style: { width: 200 },
                        mode: 'multiple',
                        allowClear: true,
                        showArrow: true,
                        optionFilterProp: 'label',
                        showSearch: true,
                        maxTagCount: 2,
                        options: allLessons,
                    },
                },
                {
                    name: 'taskTypes',
                    label: locales.pages.gong.coachTaskCenter.list.taskType,
                    type: 'Select',
                    props: {
                        placeholder: locales.labels.pleaseSelect,
                        options: TaskTypeOptions,
                        style: { width: 200 },
                        mode: 'multiple',
                        // maxTagCount: 2,
                        allowClear: true,
                        showArrow: true,
                        // showSearch: true,
                    },
                },
                {
                    name: 'taskStatuses',
                    label: locales.pages.gong.coachTaskCenter.list.taskStatus,
                    type: 'Select',
                    props: {
                        options: TaskStatusOptions,
                        style: { width: 200 },
                        mode: 'multiple',
                        // maxTagCount: 2,
                        allowClear: true,
                        showArrow: true,
                    },
                },
                {
                    name: 'execetionInfo',
                    label: locales.pages.gong.coachTaskCenter.list.taskExecutionInfo,
                    type: 'ExecutionFilter',
                },
            ].filter((item) => {
                // 课程页面来的数据将不再显示前一个筛选项
                if (lessonId) {
                    return item.name !== 'lessonIds';
                }
                return true;
            })  as CommonFilterProps['fields'],
            initialValues: {
                ...(cache?.filter || {}),
            },
        };
    }, [
        TaskStatusOptions, 
        TaskTypeOptions, 
        cache?.filter, 
        filterForm, 
        lessonId, 
        locales.labels.pleaseEnter, 
        locales.labels.pleaseSelect, 
        locales.pages.gong.coachTaskCenter.list.lessonName, 
        locales.pages.gong.coachTaskCenter.list.taskExecutionInfo, 
        locales.pages.gong.coachTaskCenter.list.taskName, 
        locales.pages.gong.coachTaskCenter.list.taskStatus, 
        locales.pages.gong.coachTaskCenter.list.taskType,
        allLessons,
    ]);

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const render = num => `${num}%`;
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                key: 'taskName',
                dataIndex: 'taskName',
                title: locales.pages.gong.coachTaskCenter.list.taskName,
                width: 160,
                fixed: 'left',
                render: (text, _) => (
                    <Typography.Paragraph ellipsis={{ rows: 2, tooltip: text }}>
                        {text}
                    </Typography.Paragraph>
                ),
            },
            {
                key: 'totalCount',
                dataIndex: 'totalCount',
                title: locales.pages.gong.coachTaskCenter.list.totalCount,
            },
            {
                key: 'executeRate',
                dataIndex: 'executeRate',
                title: locales.pages.gong.coachTaskCenter.list.executeRate,
                render,
            },
            {
                key: 'completeRate',
                dataIndex: 'completeRate',
                title: locales.pages.gong.coachTaskCenter.list.completeRate,
                render,
            },
            {
                key: 'commitRate',
                dataIndex: 'commitRate',
                title: locales.pages.gong.coachTaskCenter.list.commitRate,
                render,
            },
            {
                key: 'excellentRate',
                dataIndex: 'excellentRate',
                title: locales.pages.gong.coachTaskCenter.list.excellentRate,
                render,
            },
            {
                key: 'taskType',
                dataIndex: 'taskType',
                title: locales.pages.gong.coachTaskCenter.list.taskType,
                render: (text) => TaskTypeOptions.find((item) => item.value === text)?.label,
            },
            {
                key: 'lessonName',
                dataIndex: 'lessonName',
                title: locales.pages.gong.coachTaskCenter.list.lessonName,
            },
            {
                key: 'taskTime',
                dataIndex: 'taskTime',
                title: locales.pages.gong.coachTaskCenter.list.taskTime,
                width: 170,
            },
            {
                key: 'taskStatus',
                dataIndex: 'taskStatus',
                title: locales.pages.gong.coachTaskCenter.list.taskStatus,
                render: (text, _) => TaskStatusOptions.find((item) => item.value === text)?.label,
            },
            {
                key: 'publishTime',
                dataIndex: 'publishTime',
                title: /* 发布时间 */Locales['current'].pages.coach.taskCenter.list.index.releaseTime,
                width: 170,
            },
            {
                key: 'actions',
                renderType: 'actions',
                title: locales.pages.gong.coachTaskCenter.list.option,
                buttons: [
                    {
                        label: locales.pages.gong.coachTaskCenter.list.edit,
                        onClick: (item) => onEdit(item.id),
                    },
                    {
                        label: locales.pages.gong.coachTaskCenter.list.taskDetail,
                        onClick: (item) => onShow(item.id),
                    },
                    {
                        label: locales.pages.gong.coachTaskCenter.list.delete,
                        danger: true,
                        onClick: (item) => onDelete(item.id),
                        confirm: {
                            title: locales.pages.gong.coachTaskCenter.list.deleteConfirm,
                        },
                    },
                ],
            },
        ];

        return {
            rowKey: 'id',
            columns,
            dataSource,
            pagination,
            loading,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource, pagination, loading, onEdit, onShow]);

    // useEffect(() => {
    //     // return () => {
    //     //     const detailUrl = '/coach/task-center/detail';
    //     //     if (history.location.pathname.indexOf(detailUrl) === -1) {
    //     //         clearCache();
    //     //     }
    //     // };
    // }, [cache, filterForm]);

    return (
        <Page
            pageBodyClassName={'task-center-list-body'}
            title={locales.pages.gong.coachTaskCenter.title}
            backGroundGhost={true}
        >
            <StandardizeTable.RequestTable
                onRequest={onTableRequest}
                filter={filter}
                table={tableProps}
                toolBarRightButtons={[
                    {
                        label: locales.pages.gong.konwledgeBase.index.newlyBuild,
                        onClick: () => onEdit(0),
                        mainBtn: true,
                    },
                ]}
            />
        </Page>
    );
};

export default hookProps(TaskCenter);
