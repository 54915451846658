import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Divider, Empty, Tag, Tooltip, Icon } from 'udesk-ui';
// import './style.scss';
import styled from 'styled-components';
import { getReviewCallKeyEventByIdRecommend } from 'src/api/review/call/keyEvent/{id}/recommend';
import { KnowledgePointDetail, LibraryCall, SmartTagDTO } from 'src/api/types';
import { goFavoriteLink } from 'Component/pages/semantic-intelligence/words-mining/favorite-view';

const DrawerWrapper = styled.div`
    /* position: relative; */
    /* overflow: hidden; */
    margin: 0px -16px 16px;
    padding: 0 16px;
    flex: 1;
    overflow: auto;
`;
const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 24px;
    margin: -16px -16px 16px -16px;
`;

const CardTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;
`;

export const Card: React.FC<any> = React.memo((props) => {
    return (
        <div>
            <CardTitle>{props.title}</CardTitle>
            <div>{props.children}</div>
        </div>
    );
});

const LibraryCallTagWrap = styled.div`
    min-width: 206px;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    justify-content: space-between;
    padding: 0 6px 0 8px;
    margin-bottom: ${(props) => props.marginBottom + 'px'};
    cursor: pointer;

    > div {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    > button {
        display: none !important;
    }

    &:hover {
        > button {
            display: inline-flex !important;
        }
    }
`;

export const LibraryCallTag: React.FC<any> = React.memo((props) => {
    const { recommendReason, backgroundColor, marginBottom = 8, onClick } = props;
    return (
        <LibraryCallTagWrap onClick={onClick} backgroundColor={backgroundColor} marginBottom={marginBottom}>
            <div>{props.title}</div>
            <Tooltip title={recommendReason}>
                <Button onClick={onClick} type='text' size='small' icon={<Icon type='LinkOutlined' antdIcon={true} />}></Button>
            </Tooltip>
        </LibraryCallTagWrap>
    );
});

const addNewItem = (list, addList) => {
    addList.forEach((newItem) => {
        if (!list.find((item) => newItem.id === item.id)) {
            list.push(newItem);
        }
    });
};

const delRepeatItem = (list) => {
    const cache = {};
    list.forEach(item => {
        cache[item.id] = item;
    });
    return Reflect.ownKeys(cache).map((key) => cache[key]);
};

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const { tags, keyEvents, knowledgePoint, recommendWordsFavoriteGroupList } = props;
    const [libraryCalls, setLibraryCalls] = useState<LibraryCall[]>([]);
    const [knowledgePoints, setKnowledgePoints] = useState<SmartTagDTO[]>([]);

    useEffect(() => {
        const libraryCallList: LibraryCall[] = [];
        const knowledgePointList: KnowledgePointDetail[] = [];

        Promise.all(
            ([] as any).concat(tags, keyEvents).map((keyEvent) => {
                return getReviewCallKeyEventByIdRecommend({
                    segments: {
                        id: keyEvent.id,
                    },
                });
            })
        )
            .then((responses) => {
                responses.forEach((res: any) => {
                    const { data } = res;
                    if (data) {
                        addNewItem(libraryCallList, data.libraryCallList || []);
                        addNewItem(knowledgePointList, data.knowledgePointDetailList || []);
                    }
                });
            })
            .finally(() => {
                setLibraryCalls(delRepeatItem(libraryCallList));
                setKnowledgePoints(delRepeatItem(
                    ([] as any).concat(
                        knowledgePointList.map(item => {
                            return {
                                categoryColor: null,
                                id: item.id,
                                sentencesIndices: null,
                                tagName: item.pointName,
                            };
                        }),
                        knowledgePoint || []
                    )
                ));
            });
    }, [keyEvents, knowledgePoint, tags]);

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Title>{/* 知识点推荐 */}{locales.components.gong.intelligentRecommend.index.knowledgePointRecommendation}</Title>
            <DrawerWrapper>
                <Card title={/* 关联知识点 */locales.components.gong.intelligentRecommend.index.relatedKnowledgePoints}>
                    {knowledgePoints.length ? (
                        knowledgePoints.map((tag) => (
                            <Tag
                                key={tag.id}
                                style={{
                                    marginBottom: 4,
                                    background: tag.categoryColor + '1a',
                                    color: tag.categoryColor,
                                    maxWidth: '100%',
                                    overflowX: 'hidden',
                                    textOverflow: 'ellipsis',
                                    cursor: 'pointer',
                                }}
                                color={tag.categoryColor}
                                title={tag.tagName}
                                onClick={() => {
                                    window.open(`/sales/learning-center/knowledge-base/view/${tag.id}`);
                                }}
                            >
                                {tag.tagName}
                            </Tag>
                        ))
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </Card>
                <Divider style={{ margin: '16px 0' }} />
                <Card title={/* 推荐案例 */locales.components.gong.intelligentRecommend.index.recommendedCases}>
                    {libraryCalls.length ? (
                        libraryCalls.map((recommend) => (
                            <LibraryCallTag
                                title={recommend.name}
                                recommendReason={recommend.recommendReason}
                                backgroundColor='rgba(197, 228, 255, .5)'
                                onClick={() => {
                                    window.open(`/site/call-analysis/call-detail/${recommend.callId}?libraryCallId=${recommend.id}`);
                                }}
                            />
                        ))
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </Card>
                <Divider style={{ margin: '16px 0' }} />
                <Card title={/* 话术推荐 */locales.components.gong.intelligentRecommend.index.scriptRecommendation}>
                    {recommendWordsFavoriteGroupList.length > 0 ? (
                        recommendWordsFavoriteGroupList.reduce(
                            (cache, item) => {
                                if (item) {
                                    cache[item.scenario - 1].children.push(item);
                                }
                                return cache;
                            }, 
                            [
                                {
                                    title: /* 异议处理话术 */locales.components.gong.intelligentRecommend.index.objectionHandlingScripts,
                                    children: []
                                },
                                {
                                    title: /* 产品卖点话术 */locales.components.gong.intelligentRecommend.index.productSellingPointScripts,
                                    children: []
                                }
                            ]
                        ).map((parent, index) => (
                            <Card title={parent.title}>
                                {
                                    parent.children.length === 0 ? (
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    ) : (
                                        parent.children.map(tag => (
                                            <Tag
                                                key={`id:${tag.id}`|| `clusterId:${tag.clusterId}`}
                                                style={{
                                                    marginBottom: 4,
                                                    background: '#1b6dff1a',
                                                    color: '#1b6dff',
                                                    maxWidth: '100%',
                                                    overflowX: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    cursor: 'pointer',
                                                }}
                                                title={tag.name}
                                                onClick={() => {
                                                    goFavoriteLink(tag.id || tag.clusterId, tag.scenario);
                                                }}
                                            >
                                                {tag.name}
                                            </Tag>
                                        ))
                                    )
                                }
                            </Card>
                        ))
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </Card>
            </DrawerWrapper>
        </div>
    );
});

class SessionInsights extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(SessionInsights);
