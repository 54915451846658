import React, { useState, useEffect, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';

import Page from 'Component/common/udesk-qa-page-layout';
import cusConfigMap from '../../../../../common/config/form-config/cus-component-map';
import _debounce from 'lodash-es/debounce';

import './style.scss';
import { Button, FormBuilder, Space, Select, InputNumber } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';

const DEFAULT_CONDITION = {
    conditionType: 1, //1话术2交互3特征4信息
    taskConditionId: null,
    orderId: 1,
    operatorType: 11,
    idCode: 'R1',
    operatorList: [
        {
            applyConditionHitType: 1,
            applyOperatorScope: {
                key: 'all',
                id: 1,
            },
            type: 11,
            operator: 'all',
            applyRole: 'all',
        },
    ],
    applyConditionId: null,
    applyConditionIdCode: null,
    operatorLogic: '1',
    operator: 'all',
    applyRole: 'all',
    applyScope: null,
    similarityScore: null,
    differWordNumber: null,
    wordPerMinite: null,
    maxWordPerMinite: null,
    grabIntervalTime: null,
    grabMaxSpeakTime: null,
    intervalSecond: null,
    keyword: '',
    semanticSentence: null,
    applyConditionScope: null,
    applyConditionHitType: 1,
    // applyOperatorScope: {
    //     key: 'all',
    //     id: 1
    // },
    param: {},
};
const LAYOUT = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 },
};

const Card = (props) => {
    const { title, children } = props;
    return (
        <div className='form-card'>
            <div className='form-card-title'>{title}</div>
            <div className='form-card-content'>{children}</div>
        </div>
    );
};

const POSITION_OPTIONS = Udesk.enums.applyConditionScopes
    .filter((i) => i.id !== 'around')
    .map((i) => ({
        label: i.name,
        value: i.numberValue,
    }));

const RangeSelect = (props) => {
    const locales = Locales['current'];

    const { onChange, value = {} } = props;
    const onPositionChange = (v) => {
        if (
            [
                Udesk.enums.applyConditionScopes.before.numberValue,
                Udesk.enums.applyConditionScopes.after.numberValue,
            ].includes(v)
        ) {
            onChange({
                before: 0,
                range: v,
            });
            return;
        }
        onChange({
            ...value,
            range: v,
        });
    };
    const onLengthChange = (v) => {
        onChange({
            ...value,
            before: v,
        });
    };
    return (
        <Space>
            <Select
                options={POSITION_OPTIONS}
                onSelect={onPositionChange}
                value={value.range}
                style={{ width: 100 }}
            ></Select>
            {[
                Udesk.enums.applyConditionScopes.before.numberValue,
                Udesk.enums.applyConditionScopes.after.numberValue,
            ].includes(value.range) && (
                <Space>
                    <InputNumber value={value.before} onChange={onLengthChange} min={0} />
                    <div>{locales.labels.sentenceUnit}</div>
                </Space>
            )}
        </Space>
    );
};

const MAX_WIDTH = {
    maxWidth: 350,
};

const collectionConfigMap = {
    ...cusConfigMap,
    RangeSelect: RangeSelect,
};
function IntelligentCollection(props) {
    const locales = Locales['current'];
    const intelligentCollection = locales.components.pages.intelligentCollection.detail;

    let hasFeature = Udesk.data.init?.user?.hasFeature??null;
    const isEdit = Boolean(props.match.params.id);
    const backToIndex = useCallback(() => {
        let routeOptions = {
            history: props.history,
            routeName: 'intelligentCollectionList',
            state: {
                pageNumber: props?.location?.state?.pageNumber ?? 1,
                pageSize: props?.location?.state?.pageSize ?? 20,
                selectedClassificationId: parseInt(props.match.params.groupId, 10),
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    }, [props.match.params.groupId, props?.location?.state?.pageNumber, props?.location?.state?.pageSize]);
    const [initialValuesBase, setInitialValuesBase] = useState({
        customField: 0,
        fieldType: Udesk.enums.valueTypes.text.id,
    });
    const [getEntityLoading, setGetEntityLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [conditions, setConditions] = useState([]);
    const [collectionForm] = FormBuilder.useForm();
    const [baseForm] = FormBuilder.useForm();
    const [initialValuesCollection, setInitialValuesCollection] = useState({
        conditionList: [DEFAULT_CONDITION],
        ruleType: Udesk.enums.collectionMethod.assignment.id,
        ruleList: {
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        },
    });
    const [collectionFormValue, setCollectionFormValue] = useState<any | null>(null);
    const [baseFormValue, setBaseFormValue] = useState<any | null>(null);
    const [customFieldIdList, setCustomFieldIdList] = useState([]);
    // const [isCustomField, setIsCustomField] = useState(0);
    const [entitylist, setEntitylist] = useState([]);
    const footerBtns = useCallback(() => {
        const submit = () => {
            setCollectionFormValue(null);
            setBaseFormValue(null);
            baseForm.submit();
            collectionForm.submit();
        };
        return (
            <Space>
                {(hasFeature?.('smart:information:edit') || hasFeature?.('smart:information:add')) && (
                    <Button type='primary' onClick={submit} loading={loading}>
                        {locales.labels.save}
                    </Button>
                )}
                <Button onClick={backToIndex}>{locales.labels.cancel}</Button>
            </Space>
        );
    }, []);
    const onBaseValuesChange = (a, b) => {};
    const onCollectionValuesChange = (_, allValues) => {
        setConditions(
            (allValues.conditionList ?? []).map((item, i) => ({
                ...item,
                order: i + 1,
            }))
        );
    };
    const onBaseFormSubmit = (allValues) => {
        setBaseFormValue(allValues);
    };
    const onCollectionFormSubmit = (allValues) => {
        setCollectionFormValue(allValues);
    };

    const getEntityList = (keyword?: string) => {
        setGetEntityLoading(true);
        let entityUrl = Udesk.business.apiPath.concatApiPath(`information-entity/list`, props.sdkOptions);
        let params: any = { activeFlag: 1, pageSize: 1000 };
        if (keyword) {
            params.keyword = keyword;
        }
        Udesk.ajax.post(entityUrl, params).then(
            (resp) => {
                setEntitylist(resp.data.map((i) => ({ label: i.name, value: i.id })));
                setGetEntityLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setGetEntityLoading(false);
            }
        );
    };
    const configBase: FormProps = {
        onValuesChange: onBaseValuesChange,
        initialValues: initialValuesBase,
        onFinish: onBaseFormSubmit,
        ...LAYOUT,
        fields: [
            {
                type: 'RadioGroup',
                name: 'customField',
                label: intelligentCollection.isCustomerProps,
                forceDisabled: isEdit,
                tooltip: intelligentCollection.customerPropsHelper,
                props: {
                    optionType: 'button',
                    options: [
                        {
                            label: locales.labels.yes,
                            value: 1,
                        },
                        {
                            label: locales.labels.no,
                            value: 0,
                        },
                    ],
                    // onChange: (e) => {
                    //     setIsCustomField(e.target.value);
                    // },
                },
                effect: {
                    visible: {
                        '1': ['customFieldId'],
                        '0': ['name'],
                    },
                    disable: {
                        '1': ['fieldType'],
                    },
                    value: {
                        '1': [
                            {
                                name: 'customFieldId' as any,
                                output: undefined,
                            },
                        ],
                        '0': [
                            {
                                name: 'customFieldId' as any,
                                output: undefined,
                            },
                        ],
                    },
                },
            },
            {
                type: 'Input',
                name: 'name',
                label: intelligentCollection.name,
                forceDisabled: isEdit,
                props: {
                    showCount: true,
                    maxCount: 40,
                    style: MAX_WIDTH,
                },
                rules: [
                    {
                        required: true,
                    },
                    {
                        max: 40,
                        type: 'string',
                    },
                ],
            },
            {
                type: 'Select',
                name: 'customFieldId',
                label: intelligentCollection.name,
                forceDisabled: isEdit,
                props: {
                    options: customFieldIdList,
                    style: MAX_WIDTH,
                    onChange: (value, option) => {
                        baseForm.setFieldsValue({
                            fieldType: option.valueType,
                        });
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'Select',
                name: 'fieldType',
                label: intelligentCollection.type,
                forceDisabled: isEdit,
                props: {
                    options: Udesk.enums.valueTypes.map((item) => ({ label: item.name, value: item.id })),
                    style: MAX_WIDTH,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'TextArea',
                name: 'describe',
                label: intelligentCollection.desc,
                props: {
                    showCount: true,
                    maxCount: 255,
                    style: {
                        maxWidth: 400,
                    },
                    placeHolder: intelligentCollection.descPlaceHolder,
                },
                rules: [
                    {
                        max: 255,
                        type: 'string',
                    },
                ],
            },
        ],
        footerRender: false,
    };
    const configCollection: FormProps<typeof collectionConfigMap> = {
        onValuesChange: onCollectionValuesChange,
        onFinish: onCollectionFormSubmit,
        initialValues: initialValuesCollection,
        customComponents: collectionConfigMap,
        ...LAYOUT,
        fields: [
            {
                type: 'ConditionListGather' as any,
                name: 'conditionList',
                label: intelligentCollection.condition,
                props: {
                    type: [0],
                    ruglar: true,
                    isCollection: true,
                },
            },
            {
                type: 'Logic',
                name: 'ruleList',
                label: intelligentCollection.logic,
                props: {
                    conditions: conditions,
                    isCondition: true,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'RadioGroup',
                name: 'ruleType',
                label: intelligentCollection.method,
                props: {
                    optionType: 'button',
                    options: Udesk.enums.collectionMethod.map((item) => ({
                        label: item.name,
                        value: item.id,
                    })),
                },
                rules: [
                    {
                        required: true,
                    },
                ],
                effect: {
                    visible: {
                        [Udesk.enums.collectionMethod.assignment.id]: ['ruleValue'],
                        [Udesk.enums.collectionMethod.entity.id]: ['rangeObj', 'entityId'],
                    },
                },
            },
            {
                type: 'TextArea',
                name: 'ruleValue',
                label: intelligentCollection.message,
                props: {
                    showCount: true,
                    maxCount: 100,
                    style: {
                        maxWidth: 400,
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                    {
                        max: 100,
                        type: 'string',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (
                                baseForm.getFieldValue('fieldType') === Udesk.enums.valueTypes.text.id ||
                                (baseForm.getFieldValue('fieldType') === Udesk.enums.valueTypes.number.id &&
                                    new RegExp('^[0-9.]*$').test(value))
                            ) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(locales.fix.NumberTypeErrorMessage));
                        },
                    }),
                ],
            },
            {
                type: 'RangeSelect',
                name: 'rangeObj',
                label: intelligentCollection.range,
                props: {
                    style: MAX_WIDTH,
                },
                rules: [
                    {
                        required: true,
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if ([1, 4, 5].includes(value.range) || typeof value.before === 'number') {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(' '));
                        },
                    }),
                ],
            },
            {
                type: 'Select',
                name: 'entityId',
                label: intelligentCollection.entity,
                props: {
                    style: MAX_WIDTH,
                    options: entitylist,
                    onSearch: _debounce(getEntityList, 500),
                    loading: getEntityLoading,
                    showSearch: true,
                    filterOption: false,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        ],
        footerRender: false,
    };
    useEffect(() => {
        if (!baseFormValue || !collectionFormValue) return;
        let url = Udesk.business.apiPath.concatApiPath(`informationAcquisitionRules`, props.sdkOptions);
        let ajax: Promise<any> | null = null;
        const { ruleList = {}, ...otherCollectionFormValue } = collectionFormValue as any;
        let params = {
            categoryId: props.match.params.groupId,
            ...otherCollectionFormValue,
            ...baseFormValue,
            ruleList: [
                {
                    judgeStrategy: ruleList.judgeStrategy,
                    formula: ruleList.customJudgeLogic,
                },
            ],
            relateConditionList:
                collectionFormValue?.conditionList?.map((i) => ({ idCode: i.idCode, highlight: 0 })) ?? [],
        };
        if (collectionFormValue.rangeObj) {
            Object.assign(params, collectionFormValue.rangeObj);
        }
        if (baseFormValue.customField) {
            params.name =
                (customFieldIdList.find((c: any) => c.value === baseFormValue.customFieldId) as any)?.label ?? '';
        }
        setLoading(true);
        if (isEdit) {
            url = Udesk.business.apiPath.concatApiPath(
                `informationAcquisitionRules/${props.match.params.id}`,
                props.sdkOptions
            );
            ajax = Udesk.ajax.put(url, params);
        } else {
            ajax = Udesk.ajax.post(url, params);
        }
        if (!ajax) {
            setLoading(false);
            return;
        }
        ajax.then(
            (resp) => {
                setLoading(false);
                backToIndex();
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    }, [collectionFormValue, baseFormValue]);
    useEffect(() => {
        let url = Udesk.business.apiPath.concatApiPath('/personaProps/propType/2', props.sdkOptions);
        Udesk.ajax.get(url).then(
            (resp) => {
                setCustomFieldIdList(
                    (resp?.data ?? [])
                        .filter((i) => Boolean(i.propStatus))
                        .map((i) => ({
                            label: i.propName,
                            value: i.id,
                            valueType: i.valueType,
                        }))
                );
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );

        getEntityList();

        //编辑时拉取详情值
        if (!isEdit) return;
        let detailUrl = Udesk.business.apiPath.concatApiPath(
            `informationAcquisitionRules/${props.match.params.id}`,
            props.sdkOptions
        );
        Udesk.ajax.get(detailUrl).then(
            (resp) => {
                let data = resp?.data ?? {};
                if (Array.isArray(data.ruleList) && data.ruleList.length > 0) {
                    data.ruleList = data.ruleList[0];
                    data.ruleList.customJudgeLogic = data.ruleList.formula;
                }
                data.rangeObj = {
                    before: data.before,
                    range: data.range,
                };
                setInitialValuesCollection(data);
                setInitialValuesBase(data);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, []);
    useEffect(() => {
        baseForm.resetFields();
        collectionForm.resetFields();
    }, [initialValuesCollection, initialValuesBase]);
    return (
        <Page
            onBack={backToIndex}
            title={isEdit ? locales.labels.edit : locales.labels.create}
            backGroundGhost={true}
            footerRender={footerBtns}
            pageBodyClassName='intelligent-collection-detail-page'
        >
            <Card title={intelligentCollection.base}>
                <FormBuilder {...configBase} form={baseForm} />
            </Card>
            <Card title={intelligentCollection.collect}>
                <FormBuilder {...configCollection} form={collectionForm} />
            </Card>
        </Page>
    );
}

class Component extends React.Component {
    render() {
        return <IntelligentCollection {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
