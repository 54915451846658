import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import Udesk from 'Udesk';
import { Button, Drawer, FormBuilder, Icon, Input, Space, Spin } from 'udesk-ui';
import type { Node } from '@reactflow/core';
import {
    nodeDetailOnFinishMap,
    nodeDetailRequestMap,
    nodeTypeFormMap,
} from './const/nodeTypeFormMap';
import { ScoringRules } from 'Component/pages/components/coach/ScoringRules';
import { DetailedInquiryConfig } from 'Component/pages/components/coach/DetailedInquiryConfig';
import { KnowledgeTreeSelect } from 'Component/pages/components/coach/KnowledgeTreeSelect';
import { UserTagsSelect } from 'Component/pages/components/coach/UserTagsSelect';
import { CustomerPortraitConfig } from 'Component/pages/components/coach/CustomerPortrait';
import { putReviewIntelligentPartnerNodeById } from 'src/api/review/intelligentPartner/node/{id}';
import { createBackEndNode, createNode } from '../utils/NodeBuilder';
import { Upload, PptPreview } from './ConfigDrawerComponents';
import { proxy, useSnapshot } from 'valtio';
import SemanticTagsSelect from 'Component/pages/components/semantic-tags-select';
import AuditionTextArea from './AuditionTextArea';
import './style.scss';
import UdeskLocales from 'UdeskLocales';
import { getReviewIntelligentPartnerTestByRecordIdClearCache } from 'src/api/review/intelligentPartner/test/{recordId}/clearCache';

const ConfigDrawerTitle = (props) => {
    const { title, onTitleSave, onClose, onSubmit, showNodeNameEdit } = props;
    const [isEdit, setIsEdit] = useState(false);
    const ref = useRef<any>(null);
    if (isEdit) {
        return (
            <Space>
                <Input defaultValue={title} size="small" ref={ref} />
                <Button
                    size="small"
                    type="link"
                    onClick={() => {
                        if (!ref.current) return;
                        onTitleSave(ref.current.state.value).then(() => {
                            setIsEdit(false);
                        });
                    }}
                >{/* 保存 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawer.preservation}</Button>
                <Button size="small" type="link" onClick={() => setIsEdit(false)}>{/* 取消 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawer.cancel}</Button>
            </Space>
        );
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space>
                <div>{title}</div>
                {showNodeNameEdit && (
                    <Button
                        size="small"
                        icon={<Icon type="EditTwoTone" antdIcon={true} />}
                        tooltip={/* 编辑节点名称 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawer.editNodeName}
                        type="text"
                        onClick={() => setIsEdit(true)}
                    />
                )}
            </Space>
            <Space>
                <Button onClick={onClose}>{/* 取消 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawer.cancel}</Button>
                <Button onClick={onSubmit} type="primary">{/* 确定 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawer.determine}</Button>
            </Space>
        </div>
    );
};

const InitializedConfigDrawer = (props) => {
    const {
        fields,
        node: currentNode,
        customComponents,
        // formInstanceCache,
        initialValues,
        form,
        loading,
        onTitleSave,
        onFinish,
        showNodeNameEdit = true, // 传false则是测试页面
        ...drawerProps
    } = props;
    
    const clearCache = useCallback(() => {
        if(showNodeNameEdit) return;
        getReviewIntelligentPartnerTestByRecordIdClearCache(
            {
                params: {
                    flowId: currentNode?.data?.flowId, 
                },
                segments: {
                    recordId: currentNode?.data?.recordId,
                },
            });
    }, [
        currentNode?.data?.flowId, 
        currentNode?.data?.recordId,
        showNodeNameEdit
    ]);

    return (
        <Drawer
            {...drawerProps}
            mask={false}
            closable={false}
            width={500}
            style={{ position: 'absolute' }}
            getContainer={document.getElementsByClassName('udesk-qa-web-page')[0]}
            className="udesk-coach-web-config-drawer"
            title={
                <ConfigDrawerTitle
                    showNodeNameEdit={showNodeNameEdit}
                    title={currentNode?.data?.label ?? ''}
                    onTitleSave={onTitleSave}
                    onClose={() => {
                        form.resetFields();
                        drawerProps.onClose();
                    }}
                    onSubmit={() => {
                        form.submit();
                    }}
                />
            }
        >
            <Spin spinning={loading}>
                <FormBuilder
                    fields={fields}
                    form={form}
                    layout="vertical"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    customComponents={customComponents}
                    initialValues={initialValues}
                    onFinish={(value) => {
                        onFinish?.(value).then(drawerProps.onClose).then(clearCache);
                    }}
                    footerRender={false}
                />
                {/* <Button
                    onClick={() => {
                        form.submit();
                    }}
                    type="primary"
                >
                    提交
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        drawerProps.onClose();
                    }}
                >
                    取消
                </Button> */}
            </Spin>
        </Drawer>
    );
};

export const fieldValues = proxy<any>({
    current: {},
});

export function useFieldValues() {
    const s = useSnapshot(fieldValues);
    return s;
}

const useConfigDrawer = ({ setNodes, flowId, requestFlowData }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [node, setNode] = useState<(Node & { parentNodeId?: string }) | null>(null);
    // const [fieldValues, saveFieldValues] = useState<any>({});
    const [initialValues, saveInitialValues] = useState<any>({});
    const [formInstanceCache, saveFormInstance] = useState<any>({});
    const [fields, setFields] = useState<any[]>([]);

    // const fieldValues = useRef({});

    const saveFieldValues = (action) => {
        action(fieldValues.current);
    };

    const [form] = FormBuilder.useForm();
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);

    const nodeId = useMemo(() => {
        return node?.id;
    }, [node]);

    const onNodeClick = (event, node) => {
        saveInitialValues({});
        setVisible(true);
        const detailRequest = nodeDetailRequestMap[node?.type];
        setLoading(true);
        detailRequest({ nodeId: node.id, saveFieldValues, node }).then((resp) => {
            setFields(
                (node?.type
                    ? nodeTypeFormMap[node?.type]?.({
                        form,
                        saveFieldValues,
                        // getFieldValues: (key) => {
                        //     return _fieldValues.current[key];
                        // },
                        saveFormInstance: (key, form) =>
                            saveFormInstance((prev) => ({
                                ...prev,
                                [key]: form,
                            })),
                        configId: resp.id,
                        node,
                        flowId,
                        evaluationMode:
                              node?.type === Udesk.enums.nodeTypes.pptNode.key
                                  ? resp.evaluationMode
                                  : undefined,
                    })
                    : []) || []
            );
            saveInitialValues(resp);
            setLoading(false);
        });
        setNode(node);
    };
    const onClose = () => setVisible(false);

    const customComponents = useMemo(() => {
        return {
            AuditionTextArea,
            ScoringRules,
            DetailedInquiryConfig,
            SemanticTagsSelect,
            KnowledgeTreeSelect,
            UserTagsSelect,
            CustomerPortraitConfigSelect: CustomerPortraitConfig.Select,
            PptUpload: Upload,
            PptPreview,
        };
    }, []);
    const onTitleSave = (nodeName) => {
        const newNodeValue = {
            ...node,
            nodeName,
        };
        return putReviewIntelligentPartnerNodeById(createBackEndNode(newNodeValue), {
            segments: {
                id: nodeId as any,
            },
        }).then((resp) => {
            setNodes?.((prev) => {
                const res = prev.map((item) => {
                    return item.id === `${nodeId}` ? createNode(resp.data) : item;
                });
                return res;
            });
            setNode((prev: any) => {
                return {
                    ...prev,
                    data: {
                        label: nodeName,
                    },
                };
            });
        });
    };
    const onFinish = useMemo(() => {
        return node?.type
            ? (values) => {
                return nodeDetailOnFinishMap[node?.type!]?.({
                    formInstanceCache,
                    configId: initialValues.id,
                    nodeId: nodeId ? parseInt(nodeId, 10) : undefined,
                    parentNodeId: node?.parentNodeId,
                    node,
                    flowId,
                })(values).then((resp) => {
                    if (node.type === Udesk.enums.nodeTypes.pptNode.key) {
                        requestFlowData?.();
                        return;
                    }
                    const words =
                          node?.type === Udesk.enums.nodeTypes.knowledgeNode.key
                              ? getKnowledgeNodeWord(values)
                              : resp?.data?.words;

                    setNodes?.((prev) => {
                        const res = prev.map((item) => {
                            return item.id === `${nodeId}`
                                ? {
                                    ...item,
                                    data: {
                                        ...item.data,
                                        words,
                                    },
                                }
                                : item;
                        });
                        return res;
                    });
                    function getKnowledgeNodeWord({
                        questionType,
                        customQuestionNum,
                        correctScore,
                        knowledgeIds,
                        questionNumType,
                    }) {
                        customQuestionNum = customQuestionNum ?? 0;
                        correctScore = correctScore ?? 0;
                        const allKnowledgeCount = knowledgeIds?.length ?? 0;

                        const count =
                              questionNumType === Udesk.enums.questionNumTypes.all.id
                                  ? allKnowledgeCount
                                  : customQuestionNum + /* 个 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawer.individual;
                        const totalScore =
                              questionNumType === Udesk.enums.questionNumTypes.all.id
                                  ? correctScore * (knowledgeIds?.length ?? 0)
                                  : customQuestionNum * correctScore;
                        return `${Udesk.enums.questionTypes.getName(
                            questionType
                        )} ${count}${/* , 共 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawer.of}${totalScore}${/* 分 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawer.branch}`;
                    }
                });
            }
            : undefined;
    }, [formInstanceCache, initialValues.id, nodeId, node?.type]);

    const clearSelectedNode = () => {
        setNode(null);
    };

    return {
        visible,
        onNodeClick,
        onClose,
        fields,
        node,
        customComponents,
        // formInstanceCache,
        initialValues,
        form,
        loading,
        onTitleSave,
        onFinish,
        currentSelectedNode: node,
        clearSelectedNode,
    };
};

type ConfigDrawerType = typeof InitializedConfigDrawer & {
    useConfigDrawer: (props: any) => any;
};
const ConfigDrawer: ConfigDrawerType = InitializedConfigDrawer as ConfigDrawerType;

ConfigDrawer.useConfigDrawer = useConfigDrawer;
export { ConfigDrawer };
