import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import DeviceModal, { RefType } from '../../components/device-modal';
import { Button, Rate, Tag } from "udesk-ui";
import { useFilterForm, usePagination } from "../../components";
import { createComponent } from 'src/util/component';
import { linkTo } from 'src/util/link';
import { linkToStart } from '../../components/util';
import { getDateTimeString } from 'src/util/moment';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { StandardizeTable } from 'udesk_gm_ui';
import { Cover } from '../../components/cover';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { useRequest } from 'src/util/hook/request';
import { getIntelligentPartnerStudyTaskList } from 'src/api/intelligentPartnerStudyTask/list';

const Template = React.memo((props: any) => {
    const {saveStorages} = props;
    const [pagination, setPagination] = usePagination(props);
    const [filterConfig, lessonId] = useFilterForm(props, 1);
    const modalRef = useRef<RefType>(null);
    const {
        loading,
        data: tableData, 
        run: request, 
    } = useRequest({
        onSuccess(resp) {
            setPagination({
                current: resp.paging.pageNum,
                total: resp.paging.total,
            });
        }
    });

    const modalSubmit = useCallback((task) => {
        linkToStart(props.history, task.taskType, task.dialogueMode, {
            id: task.id
        });
    }, [props.history]);

    const getTableSource = useCallback((data) => {
        const {
            filter, 
            pagination,
        } = data;
        const { time, ...otherFilterValue } = filter || {};
        const [ startTime, endTime ] = time || [];

        saveStorages((storages) => {
            return {
                ...storages,
                filterData: filter,
                pagination: pagination,
            };
        });

        const params = {
            ...otherFilterValue,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            taskType: 1 // 1练习，2考试
        };
        if (startTime) {
            params.startTime = getDateTimeString(startTime);
        }
        if (endTime) {
            params.endTime = getDateTimeString(endTime, 'YYYY-MM-DD HH:mm:59');
        }
        if (lessonId) {
            params.lessonId = lessonId;
        }

        request(getIntelligentPartnerStudyTaskList, {params});
    }, [
        lessonId, 
        request, 
        saveStorages
    ]);

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const isPad = window.innerWidth <= 1240;

        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 任务列表 */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.taskList,
                key: 'lessonName',
                dataIndex: 'lessonName',
                width: isPad ? 500 : undefined,
                ellipsis: isPad,
                render (text, record) {
                    return (
                        <div className='task-item'>
                            <div className={'course-logo'}>
                                <Cover src={record.cover} courseType={record.courseType} isPad={isPad}/>
                            </div>
                            <div className={'course-info'}>
                                <div>
                                    <Tag color={
                                        ['','blue', 'green', 'orange'][record.dialogueMode]
                                    }>
                                        {Udesk.enums.learningTaskDialogueModeType.getName(record.dialogueMode)}
                                    </Tag>
                                    <span>{record.taskName}</span>
                                </div>
                                <div>{/* 课程介绍： */}{UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.courseIntroduction}{record.introduce}</div>
                                <div>{/* 练习难度： */}{UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.exerciseDifficulty}<Rate allowHalf disabled defaultValue={record.difficultyLevel} /></div>
                                <div>{/* 练习完成要求： */}{UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.practiceCompletionRequirements}{[
                                    record.completeByTimes ? `${/* 练习次数≥ */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.practiceFrequency}${record.completeByTimes}${/* 次 */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.second}` : '',
                                    record.completeByScore ? `${/* 任意一次得分≥ */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.anyScore}${record.completeByScore}${/* 分 */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.branch}` : '',
                                ].filter(item => !!item).join(/* 或 */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.or)}
                                </div>
                                <div>
                                    {
                                        record.taskStartTime && record.taskEndTime ? (
                                            `${/* 练习时间： */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.practiceTime}${record.taskStartTime}${/* 至 */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.to}${record.taskEndTime}  `
                                        ): (
                                            /* 不限时间 */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.unlimitedTime
                                        )
                                    }
                                    <Button type='link'>{Udesk.enums.learningTaskStatusType.getName(record.taskStatus)}</Button></div>
                            </div>
                        </div>
                    );
                }
            },
            {
                title: /* 完成状态 */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.completionStatus,
                key: 'completeState',
                dataIndex: 'completeState',
                width: 100,
                render (text) {
                    return Udesk.enums.learningTaskScheduleStatusType.getName(text);
                }
            },
            {
                title: /* 练习次数 */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.numberOfExercises,
                key: 'exerciseTimes',
                dataIndex: 'exerciseTimes',
                width: 100,
            },
            {
                title: /* 最高分 */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.maximumScore,
                key: 'highestScore',
                dataIndex: 'highestScore',
                width: 100,
            },
            {
                title: /* 操作 */UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.operation,
                width: isPad ? 200 : 250,
                key: 'id',
                dataIndex: 'id',
                render: (name, record) => {
                    return (
                        <div className={'task-btns' + (isPad ? ' column' : '')}>
                            <Button 
                                onClick={
                                    () => {
                                        linkTo(props.history, 'coachLearningCenterCourseLearn', {
                                            id: record.lessonId
                                        }, {}, {
                                            taskId: record.id
                                        });
                                    }
                                }
                                type={'link'}>{/* 进入学习 */}{UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.enteringLearning}</Button>
                            <Button 
                                // “已结束”或“失效”的任务，“练习”和“立即考试”按钮置灰，点击不生效
                                disabled={
                                    [
                                        Udesk.enums.learningTaskStatusType.loseEfficacy.id,
                                        Udesk.enums.learningTaskStatusType.notStarted.id,
                                        Udesk.enums.learningTaskStatusType.finished.id,
                                    ].includes(record.taskStatus)
                                }
                                onClick={
                                    () => {
                                        modalRef.current?.open({
                                            task: record
                                        });
                                    }
                                } type={'link'}>{/* 开始练习 */}{UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.startPracticing}</Button>
                            <Button 
                                type={'link'} 
                                onClick={ 
                                    () => {
                                        linkTo(props.history, 'coachLearningCenterRecord', {}, {}, {
                                            taskId: record.id
                                        });
                                    }
                                }>{/* 历史数据 */}{UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.historicalData}</Button>
                            <Button 
                                type={'link'} 
                                onClick={ 
                                    () => {
                                        linkTo(props.history, getCurrentRouteName('wrongQuestionSetList'), {}, {}, {
                                            taskId: record.id
                                        });
                                    }
                                }>{/* 错题集 */}{UdeskLocales['current'].pages.coach.learningCenter.task.exercise.index.wrongQuestionSet}</Button>
                        </div>
                    );
                },
            },
        ];
        return {
            columns,
            dataSource: tableData,
            pagination,
            loading,
        };
    }, [tableData, pagination, props.history, loading]);

    useEffect(() => {
        $('.udesk-qa-ui-common-filter').on('click', '.udesk-qa-ui-btn-default', () => {
            return !saveStorages({
                filterData: {}
            });
        });
    }, [
        saveStorages
    ]);

    return (
        <div className='learning-center-tasks-table'>
            <StandardizeTable.RequestTable
                onRequest={getTableSource}
                table={tableProps}
                filter={filterConfig as any}
            />
            <DeviceModal ref={modalRef} modalSubmit={modalSubmit}></DeviceModal>
        </div>
    );
});

export default createComponent(
    Template,
    undefined,
    [
        /^\/coach\/learning-center\/task\/exercise\/*/i,
        /^\/coach\/learning-center\/task\/test\/*/i,
        /^\/coach\/learning-center\/course\/learn\/*/i,
        /^\/coach\/learning-center\/record\/*/i,
    ]
);