import UdeskLocales from 'UdeskLocales';

let weekDays = [
    { id: "1", name: () => UdeskLocales['current'].enums.weekDays.monday, key: "Monday" },
    { id: "2", name: () => UdeskLocales['current'].enums.weekDays.tuesday, key: "Tuesday" },
    { id: "3", name: () => UdeskLocales['current'].enums.weekDays.wednesday, key: "Wednesday" },
    { id: "4", name: () => UdeskLocales['current'].enums.weekDays.thursday, key: "Thursday" },
    { id: "5", name: () => UdeskLocales['current'].enums.weekDays.friday, key: "Friday" },
    { id: "6", name: () => UdeskLocales['current'].enums.weekDays.saturday, key: "Saturday" },
    { id: "7", name: () => UdeskLocales['current'].enums.weekDays.sunday, key: "Sunday" },
];

let reportDateTypes = [
    { id: "qc_whole_trend_day", name: () => UdeskLocales['current'].enums.reportDateTypes.dailyReport, key: "dailyReport" },
    { id: "qc_whole_trend_week", name: () => UdeskLocales['current'].enums.reportDateTypes.weeklyReport, key: "weeklyReport" },
];

let requestMethods = [
    { id: 1, key: "get", name: "GET" },
    { id: 2, key: "create", name: "POST" },
    { id: 3, key: "del", name: "DELETE" },
    { id: 4, key: "update", name: "PUT" }
];

let operatorRuleTypes = [
    { id: 1, key: "all", name: () => UdeskLocales['current'].enums.operatorRuleTypes.all },
    { id: 2, key: "any", name: () => UdeskLocales['current'].enums.operatorRuleTypes.any },
    { id: 3, key: "custom", name: () => UdeskLocales['current'].enums.operatorRuleTypes.custom }
];

let dialogueApplyScopes = [
    { id: 1, key: 'differentRoles', name: () => UdeskLocales['current'].enums.common.dialogueApplyScopes.differentRoles },
    { id: 2, key: 'agent', name: () => UdeskLocales['current'].enums.common.dialogueApplyScopes.agent },
    { id: 3, key: 'customer', name: () => UdeskLocales['current'].enums.common.dialogueApplyScopes.customer },
    { id: 4, key: 'agentResponse', name: () => UdeskLocales['current'].enums.common.dialogueApplyScopes.agentResponse },
    { id: 5, key: 'customerResponse', name: () => UdeskLocales['current'].enums.common.dialogueApplyScopes.customerResponse },
    { id: 6, key: 'customerNoResponse', name: () => UdeskLocales['current'].enums.common.dialogueApplyScopes.customerNoResponse },
];

let sentenceLength = [
    { id: 'less', key: 'less', name: () => UdeskLocales['current'].enums.common.sentenceLength.less },
    { id: 'greater', key: 'greater', name: () => UdeskLocales['current'].enums.common.sentenceLength.greater },
    { id: 'equal', key: 'equal', name: () => UdeskLocales['current'].enums.common.sentenceLength.equal },
    { id: 'greater_or_equal', key: 'greater_or_equal', name: () => UdeskLocales['current'].enums.common.sentenceLength.greater_or_equal },
    { id: 'less_or_equal', key: 'less_or_equal', name: () => UdeskLocales['current'].enums.common.sentenceLength.less_or_equal },
];

let timeIntervalCompareTypes = [
    { id: 3, key: 'greater_than', name: () => UdeskLocales['current'].enums.common.sentenceLength.greater },
    { id: 4, key: 'greater_than_eq', name: () => UdeskLocales['current'].enums.common.sentenceLength.greater_or_equal },
    { id: 5, key: 'less_than', name: () => UdeskLocales['current'].enums.common.sentenceLength.less },
    { id: 6, key: 'less_than_eq', name: () => UdeskLocales['current'].enums.common.sentenceLength.less_or_equal },
];

let volumeDetectionWays = [
    { id: 1, key: "loudness", name: () => UdeskLocales['current'].enums.common.volumeDetectionWays.loudness },
    { id: 2, key: "rangeAbilitySelf", name: () => UdeskLocales['current'].enums.common.volumeDetectionWays.rangeAbilitySelf },
    { id: 3, key: "rangeAbilityOth", name: () => UdeskLocales['current'].enums.common.volumeDetectionWays.rangeAbilityOth },
    // { id: 4, key: "rangeAbility3", name: () => UdeskLocales['current'].enums.common.volumeDetectionWays.rangeAbility3 },
];

let volumeDetectionOperators = [
    { id: 1, key: "is", name: () => UdeskLocales['current'].enums.common.volumeDetectionOperators.is },
    { id: 2, key: "not", name: () => UdeskLocales['current'].enums.common.volumeDetectionOperators.not },
    { id: 3, key: "greaterThan", name: () => UdeskLocales['current'].enums.common.volumeDetectionOperators.greaterThan },
    { id: 4, key: "greaterThanEq", name: () => UdeskLocales['current'].enums.common.volumeDetectionOperators.greaterThanEq },
    { id: 5, key: "lessThan", name: () => UdeskLocales['current'].enums.common.volumeDetectionOperators.lessThan },
    { id: 6, key: "lessThanEq", name: () => UdeskLocales['current'].enums.common.volumeDetectionOperators.lessThanEq },
];

let customerAddTimeCompareTypes = [
    { id: 1, key: "is", name: () => UdeskLocales['current'].enums.common.volumeDetectionOperators.is },
    { id: 3, key: 'greater_than', name: () => UdeskLocales['current'].enums.common.sentenceLength.greater },
    { id: 5, key: 'less_than', name: () => UdeskLocales['current'].enums.common.sentenceLength.less },
];

let sentimentTypes = [
    { id: 1, key: "positive", name: () => UdeskLocales['current'].enums.common.sentimentTypes.positive },
    { id: -1, key: "negative", name: () => UdeskLocales['current'].enums.common.sentimentTypes.negative },
    { id: 0, key: "neutral", name: () => UdeskLocales['current'].enums.common.sentimentTypes.neutral },
    { id: 2, key: "thankful", name: () => UdeskLocales['current'].enums.common.sentimentTypes.thankful },
    { id: 3, key: "happy", name: () => UdeskLocales['current'].enums.common.sentimentTypes.happy },
    { id: -2, key: "complaining", name: () => UdeskLocales['current'].enums.common.sentimentTypes.complaining },
    { id: -3, key: "angry", name: () => UdeskLocales['current'].enums.common.sentimentTypes.angry },
];

let emotionOperators = [
    { id: 1, key: "is", name: () => UdeskLocales['current'].enums.common.emotionOperators.is },
    { id: 2, key: "not", name: () => UdeskLocales['current'].enums.common.emotionOperators.not },
];

let entityOperators = [
    { id: 1, key: "equals", name: () => UdeskLocales['current'].enums.common.entityOperators.equals },
    { id: 2, key: "notEquals", name: () => UdeskLocales['current'].enums.common.entityOperators.notEquals },
    { id: 3, key: "contains", name: () => UdeskLocales['current'].enums.common.entityOperators.contains },
    { id: 4, key: "notContains", name: () => UdeskLocales['current'].enums.common.entityOperators.notContains },
    { id: 5, key: "equalsFix", name: () => UdeskLocales['current'].enums.common.entityOperators.equalsFix },
    { id: 6, key: "notEqualsFix", name: () => UdeskLocales['current'].enums.common.entityOperators.notEqualsFix },
    { id: 7, key: "containsFix", name: () => UdeskLocales['current'].enums.common.entityOperators.containsFix },
    { id: 8, key: "notContainsFix", name: () => UdeskLocales['current'].enums.common.entityOperators.notContainsFix }
];

let customFilterFieldList = [
    { id: 1, key: 'call', name: () => UdeskLocales['current'].enums.common.customFilter.call, value: 1 },
    { id: 2, key: 'text', name: () => UdeskLocales['current'].enums.common.customFilter.text, value: 1 },
    { id: 3, key: 'smartTags', name: () => UdeskLocales['current'].enums.common.customFilter.smartTags, value: 2 },
    { id: 4, key: 'pretreatment', name: () => UdeskLocales['current'].enums.common.customFilter.pretreatment, value: 3 },
    // { id: 5, key: 'smartTagGroup', name: () => UdeskLocales['current'].enums.common.customFilter.smartTagGroup, value: 4 },
    { id: 6, key: 'currency', name: () => UdeskLocales['current'].enums.common.customFilter.currency, value: 5 },
    { id: 7, key: 'wechat', name: () => UdeskLocales['current'].enums.common.customFilter.wechat, value: 1 },
    { id: 8, key: 'spotCheck', name: () => UdeskLocales['current'].enums.common.customFilter.spotCheck, value: 7 },
    { id: 13, key: 'reviewList', name: () => UdeskLocales['current'].enums.common.customFilter.reviewList, value: 8 },
    { id: 9, key: 'personaField', name: () => UdeskLocales['current'].enums.common.customFilter.personaField, value: 9 },
    { id: 10, key: 'ticket', name: () => UdeskLocales['current'].enums.common.customFilter.ticket, value: 1 },
    { id: 11, key: 'taobao', name: () => UdeskLocales['current'].enums.common.customFilter.taobao, value: 1 },
    { id: 12, key: 'wechatRadio', name: () => UdeskLocales['current'].enums.common.customFilter.wechatRadio, value: 1 },

    { id: 14, key: 'callRecord', name: () => UdeskLocales['current'].enums.common.callLog, value: 1 },
    { id: 15, key: 'intelligenceRule', name: () => UdeskLocales['current'].enums.common.intelligentRules, value: 13 },
    { id: 16, key: 'smartTags2', name: () => UdeskLocales['current'].enums.common.customFilter.smartTags, value: 12 },
    { id: 17, key: 'businessRecord', name: () => UdeskLocales['current'].enums.common.businessRecords, value: 6 },
    { id: 18, key: 'ticketData', name: () => /* 工单字段 */UdeskLocales['current'].enums.common.workOrderFields, value: 15 },
    { id: 19, key: 'customerFields', name: () => /* 客户信息 */UdeskLocales['current'].enums.common.customerInformation, value: 16 },
];

let talkerRecognitionType = [
    { id: 1, key: 'API', name: () => UdeskLocales['current'].enums.common.talkerRecognitionType.API },
    { id: 2, key: 'text', name: () => UdeskLocales['current'].enums.common.talkerRecognitionType.text }
];

let uploadFileTypes = [
    { id: "5", key: "image" },
];

let applyOptions = [
    { id: 0, key: 'notHit', name: () => UdeskLocales['current'].enums.tasks.applyOptions.notHit },
    { id: 1, key: 'hit', name: () => UdeskLocales['current'].enums.tasks.applyOptions.hit }
];

let applyConditionHitTypes = [
    { id: 1, key: 'first', name: () => UdeskLocales['current'].enums.common.applyConditionHitTypes.first },
    { id: 3, key: 'every', name: () => UdeskLocales['current'].enums.common.applyConditionHitTypes.every },
    { id: 2, key: 'last', name: () => UdeskLocales['current'].enums.common.applyConditionHitTypes.last }
];

let applyConditionScopes = [
    { id: "current", key: "current", name: () => UdeskLocales['current'].enums.common.applyConditionScopes.current, numberValue: 1 },
    { id: "before", key: "before", name: () => UdeskLocales['current'].enums.common.applyConditionScopes.before, numberValue: 2 },
    { id: "after", key: "after", name: () => UdeskLocales['current'].enums.common.applyConditionScopes.after, numberValue: 3 },
    { id: "around", key: "around", name: () => UdeskLocales['current'].enums.common.applyConditionScopes.around },
    { id: "beforeAll", key: "beforeAll", name: () => UdeskLocales['current'].enums.common.applyConditionScopes.beforeAll, numberValue: 4 },
    { id: "afterAll", key: "afterAll", name: () => UdeskLocales['current'].enums.common.applyConditionScopes.afterAll, numberValue: 5 }
];

let voiceDemoTypes = [
    { id: 1, key: "singleChannel", name: () => UdeskLocales['current'].enums.common.voiceDemoTypes.singleChannel },
    { id: 2, key: "dualChannel", name: () => UdeskLocales['current'].enums.common.voiceDemoTypes.dualChannel }
];

let sendStatus = [
    { id: "sending", key: "sending", name: () => UdeskLocales['current'].enums.common.sendStatus.sending, color: '#91d5ff' },
    { id: "arrive", key: "arrive", name: () => UdeskLocales['current'].enums.common.sendStatus.arrive, color: '#91d5ff' },
    { id: "fail", key: "fail", name: () => UdeskLocales['current'].enums.common.sendStatus.fail, color: '#cf1322' },
    { id: "off_sending", key: "off_sending", name: () => UdeskLocales['current'].enums.common.sendStatus.off_sending, color: 'rgba(0,0,0,.25)' },
    { id: "off_arrive", key: "off_arrive", name: () => UdeskLocales['current'].enums.common.sendStatus.off_arrive, color: 'rgba(0,0,0,.25)' },
    { id: "rollback", key: "rollback", name: () => UdeskLocales['current'].enums.common.sendStatus.rollback, color: '#cf1322' },
];

let collectionMethod = [
    { id: 1, key: "assignment", name: () => UdeskLocales['current'].enums.common.collectionMethod.assignment },
    { id: 2, key: "entity", name: () => UdeskLocales['current'].enums.common.collectionMethod.entity }
];

const systemTypes = [
    { id: 1, key: "qa" },
    { id: 4, key: "sale" },
    { id: 5, key: "saleAndQa" },
];
const sysModuleList = [
    { id: 1, key: "qa", name: () => /* 智能质检 */UdeskLocales['current'].enums.common.intelligentQualityInspection, icon: "/static/images/qa.svg", routeName: 'home', nameSpace: 'site', desc: /* 全量质检，及时高效，准确率85%以上 */UdeskLocales['current'].enums.common.fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove },
    { id: 2, key: "conversationAnalysis", name: () => /* 智能会话分析 */UdeskLocales['current'].enums.common.intelligentConversationAnalysis, icon: '/static/images/conversationAnalysis.svg', routeName: 'conversationAnalysisHome', nameSpace: 'conversation-analysis', desc: /* 洞察业务短板，聆听客户心声，提升营销转化 */UdeskLocales['current'].enums.common.insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation },
    { id: 3, key: "sales", name: () => /* 销售赋能 */UdeskLocales['current'].enums.common.salesEmpowerment, icon: "/static/images/sales.svg", routeName: 'salesHome', nameSpace: 'sales', desc: /* AI重塑销售管理，实现沟通过程数字化、可视化，透明管理销售每一环，智慧洞察沟通每一步，精细化诊断业务问题，完美复刻销冠经验 */UdeskLocales['current'].enums.common.aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience },
    { id: 4, key: "coach", name: () => /* 智能陪练 */UdeskLocales['current'].enums.common.intelligentAccompanyingPractice, icon: "/static/images/coach.svg", routeName: 'coachHome', nameSpace: 'coach', desc: /* 打造模拟业务真实场景的沉浸式AI陪练，实现金牌话术全员掌握，对话技巧快速提升 */UdeskLocales['current'].enums.common.createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills },
    { id: 5, key: "algorithmPlatform", name: () => /* 算法平台 */UdeskLocales['current'].enums.common.algorithmPlatform, icon: "/static/images/aiPass.svg", routeName: 'algorithmHome', nameSpace: 'algorithm', desc: /* 基于自研原心引擎语音语义，ASR、NLP模型定制，打通业务场景数据，实现系统自学习，达到越用越智能，越用越准确的目的 */UdeskLocales['current'].enums.common.basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed },
    { id: 6, key: "commonSetting", name: () => /* 通用设置 */UdeskLocales['current'].enums.common.generalSettings, icon: "/static/images/commonSetting.svg", routeName: 'generalSettingHome', nameSpace: 'general-setting', desc: /* 对数据权限、员工角色进行统一设置管理 */UdeskLocales['current'].enums.common.unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles },
];

let clusteringLabelingRoles = [
    { id: 'customer', key: "customer", name: () => /* 客户 */UdeskLocales['current'].enums.common.customer },
    { id: 'agent', key: "agent", name: () => /* 客服 */UdeskLocales['current'].enums.common.customerService },
    { id: 'robot', key: "robot", name: () => /* 机器人 */UdeskLocales['current'].enums.common.robot },
];

export default {
    weekDays,
    requestMethods,
    reportDateTypes,
    operatorRuleTypes,
    dialogueApplyScopes,
    sentenceLength,
    timeIntervalCompareTypes,
    volumeDetectionWays,
    volumeDetectionOperators,
    sentimentTypes,
    emotionOperators,
    entityOperators,
    customFilterFieldList,
    talkerRecognitionType,
    uploadFileTypes,
    applyOptions,
    applyConditionHitTypes,
    applyConditionScopes,
    voiceDemoTypes,
    sendStatus,
    collectionMethod,
    systemTypes,
    sysModuleList,
    customerAddTimeCompareTypes,
    clusteringLabelingRoles,
};
