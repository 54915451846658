import React, { useState, useMemo, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
// import { Button, Icon } from 'udesk-ui';
import './style.scss';
import { Button, FormBuilder, Icon, Modal } from 'udesk-ui';
import { getKnowledgeBase, postKnowledgeBase } from 'src/api/knowledgeBase';
import { deleteKnowledgeBaseById, getKnowledgeBaseById } from 'src/api/knowledgeBase/{id}';
import { deleteKnowledgeDetail } from 'src/api/knowledgeDetail';
import { postKnowledgeDetailExp } from 'src/api/knowledgeDetail/exp';
import ExportButton from 'Component/common/export-button';
import ImportModal from 'Component/common/import-modal';
// import { postKnowledgeDetailUploadByType } from 'src/api/knowledgeDetail/upload/{type}';
import importSuccessNotification from 'Component/common/import-notification';
import { postKnowledgeDetailImportData } from 'src/api/knowledgeDetail/importData';
import { getSystemModule } from 'Udesk/system/subApp';

type ImportType = 'knowledge' | 'process';

const default_pagination = {
    current: 1,
    pageSize: 20,
    total: 0,
};

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    const [tableData, setTableData] = useState<any[]>([]);
    const importType = useRef<ImportType | null>(null);
    const [classificationDetailVisible, setClassificationDetailVisible] = useState<boolean>(false);
    const [isUploadVisible, setIsUploadVisibleVisible] = useState<boolean>(false);
    const [classificationDetailValues, setClassificationDetailValues] = useState<any>({});
    const [currentEditClassificationId, setCurrentEditClassificationId] = useState<number | null>(null);
    const [file, setFile] = useState<any>(null);
    const [uploadData, setUploadData] = useState<any>(null);
    const [currentListClassificationId, setCurrentListClassificationId] = useState<number | null>(null);
    const [treeData, setTreeData] = useState<any[]>([]);
    const [selectedKey, setSelectedKey] = useState<any>(props.storages.selectedKey);
    const [pagination, setPagination] = useState<StandardizeTableProps['table']['pagination']>(
        props.storages.pagination
    );
    const [classificationDetailFormInstance] = FormBuilder.useForm();

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 名称 */locales.pages.gong.konwledgeBase.index.name,
                key: 'pointName',
                dataIndex: 'pointName',
            },
            {
                title: /* 描述 */locales.pages.gong.konwledgeBase.index.describe,
                key: 'description',
                dataIndex: 'description',
            },
            {
                title: /* 创建人 */locales.pages.gong.konwledgeBase.index.createdBy,
                key: 'createUser',
                dataIndex: 'createUser',
            },
            {
                title: /* 类型 */locales.pages.gong.konwledgeBase.index.type,
                key: 'type',
                dataIndex: 'type',
                render: (name, item, index) => {
                    return Udesk.enums.knowledgeBaseType.getName(name);
                },
            },
            {
                title: /* 最近更新时间 */locales.pages.gong.konwledgeBase.index.lastUpdated,
                key: 'updateTime',
                dataIndex: 'updateTime',
            },
            {
                title: /* 引用次数 */locales.pages.gong.konwledgeBase.index.numberOfReferences,
                key: 'referenceCnt',
                dataIndex: 'referenceCnt',
                render: (text) => <div>{text || 0}</div>,
            },
            {
                title: /* 操作 */locales.pages.gong.konwledgeBase.index.operation,
                key: 'actions',
                renderType: 'actions',
                buttons: [
                    hasFeature &&
                        hasFeature('sys:knowledgePoint:view') && {
                        label: /* 查看 */locales.pages.gong.konwledgeBase.index.see,
                        onClick: (item) => {
                            let routeOptions = {
                                history: props.history,
                                routeName: 'gongKnowledgeBaseView',
                                pathParams: { id: item.id },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                    },
                    hasFeature &&
                        hasFeature('sys:knowledgePoint:edit') && {
                        label: /* 编辑 */locales.pages.gong.konwledgeBase.index.edit,
                        onClick: (item) => {
                            let routeOptions = {
                                history: props.history,
                                routeName: 'gongKnowledgeBaseDetail',
                                pathParams: { id: item.id },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                    },
                    hasFeature &&
                        hasFeature('sys:knowledgePoint:delete') && {
                        label: /* 删除 */locales.pages.gong.konwledgeBase.index.delete,
                        danger: true,
                        onClick: (item) => {
                            return deleteKnowledgeDetail({
                                params: {
                                    ids: `${item.id}`,
                                },
                            });
                        },
                    },
                ].filter(Boolean),
            },
        ];
        return {
            columns,
            dataSource: tableData,
            pagination,
            rowSelectionEnhance: {},
        };
    }, [tableData, pagination]);

    const classificationProps: StandardizeTableProps['classification'] = useMemo(() => {
        return {
            treeData,
            onSelect: (key) => {
                setSelectedKey(key);
            },
            selectedKeys: [selectedKey],
            treeNodeActionMenu: [
                {
                    label: /* 编辑 */locales.pages.gong.konwledgeBase.index.edit,
                    onClick: (item) => {
                        setClassificationDetailValues({ categoryName: item.title });
                        setClassificationDetailVisible(true);
                        setCurrentEditClassificationId(item.itemKey);
                    },
                },
                {
                    label: /* 删除 */locales.pages.gong.konwledgeBase.index.delete,
                    danger: true,
                    onClick: (item) => {
                        deleteKnowledgeBaseById({
                            segments: {
                                id: item.itemKey,
                            },
                            successMsg: /* 分类删除成功 */locales.pages.gong.konwledgeBase.index.categoryDeletedSuccessfully,
                        }).then(() => {
                            requestKnowledgeBaseClassification().then((resp) => {
                                setSelectedKey(resp.data?.[0]?.id ?? null);
                            });
                        });
                    },
                },
            ],
            title: (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>{/* 知识点分类 */}{locales.pages.gong.konwledgeBase.index.classificationOfKnowledgePoints}</div>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            setClassificationDetailVisible(true);
                            setCurrentEditClassificationId(null);
                        }}
                    >{/* 新建 */}{locales.pages.gong.konwledgeBase.index.newlyBuild}</Button>
                </div>
            ),
        };
    }, [treeData, selectedKey]);

    useEffect(() => {
        if (classificationDetailVisible) {
            classificationDetailFormInstance?.resetFields();
        }
    }, [classificationDetailVisible]);

    const requestKnowledgeBaseClassification = () => {
        return getKnowledgeBase().then((resp) => {
            setTreeData(resp.data?.map((i) => ({ title: i.categoryName, key: i.id })) ?? []);
            return resp;
        });
    };
    useEffect(() => {
        requestKnowledgeBaseClassification().then((resp) => {
            selectedKey === null && setSelectedKey(resp.data?.[0]?.id ?? null);
        });
    }, []);

    const closeModal = () => {
        setClassificationDetailVisible(false);
        setCurrentEditClassificationId(null);
        setClassificationDetailValues({});
    };
    const uploadFile = (e) => {
        const uploadFileSuccess = (file, uploadData) => {
            setFile(file);
            setUploadData(uploadData);
        };
        const uploadFileError = (file, uploadData) => {};
        let files = e.target.files;
        if (files.length === 0) {
            return;
        }
        // 取消文件大小限制
        // if (files[0] && (files[0].size > 2 * 1024 * 1024)) {
        //     return Udesk.ui.notify.error(UDL.business.notifyMessage.overFileSize);
        // }
        let fileName = fileType(files[0].name);
        if (!fileName) {
            return;
        } else if (fileName !== 'xlsx') {
            return Udesk.ui.notify.error(Udesk.utils.string.format(locales.labels.fileTypeHolder, 'xlsx'));
        }
        //type 1语音,2导入,3导出,4语料数据
        Udesk.app.fileStorage.upload(files[0], {
            token: new Promise((resolve, reject) => {
                Udesk.business
                    .getServerAuthorization({
                        type: 2,
                    })
                    .then(
                        function (resp) {
                            resolve(resp);
                        },
                        function (reason) {
                            reject(reason);
                        }
                    );
            }),
            //progressCallback: progressCallback.bind(this),
            successCallback: uploadFileSuccess.bind(this),
            errorCallback: uploadFileError.bind(this),
        });
    };
    const importTamplate = () => {
        if (file == null || uploadData == null) {
            return Udesk.ui.notify.error(locales.business.notifyMessage.pleaseUploadFile);
        }
        postKnowledgeDetailImportData({
            keyName: uploadData.key,
            originalFileName: file.name,
            fileSize: uploadData.size,
            systemModule: getSystemModule(),
        } as any).then(
            (resp) => {
                Udesk.ui.notify.success(locales.business.notifyMessage.importFileSuccess);
                setIsUploadVisibleVisible(false);
                importSuccessNotification();
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg || locales.business.notifyMessage.importFildFailed);
                setIsUploadVisibleVisible(false);
            }
        );
    };
    const downloadTemplate = () => {
        let { sdkOptions } = props;
        if (!importType.current) return;

        let templateLocalUrl = `/import/gong/knowledge-base/knowledge-template.xlsx`;
        if (importType.current === 'process') {
            templateLocalUrl = `/import/gong/knowledge-base/workflow-template.xlsx`;
        }
        let templateUrl =
            Udesk.business.apiPath.getStaticBasePath(sdkOptions.props.domain, sdkOptions.props.pathPrefix) +
            templateLocalUrl;

        (window as any).location = templateUrl;
    };

    useEffect(() => {
        if (!isUploadVisible) {
            importType.current = null;
        }
    }, [isUploadVisible]);
    return (
        <Page pageBodyClassName='sale-knowledge-base-page-index' title={/* 知识库 */locales.pages.gong.konwledgeBase.index.knowledgeBase} backGroundGhost={true}>
            <StandardizeTable.RequestTable
                onRequest={(params) => {
                    props.saveStorages({
                        ...params.filter,
                        pagination: { ...params.pagination },
                        selectedKey: params.classificationId,
                    });
                    const pagination = {
                        pageNum: params.pagination.current,
                        pageSize: params.pagination.pageSize,
                    };
                    setCurrentListClassificationId(params.classificationId as number);
                    params.classificationId &&
                        getKnowledgeBaseById({
                            segments: {
                                id: params.classificationId as number,
                            },
                            params: {
                                ...params.filter,
                                ...pagination,
                            },
                        }).then((resp) => {
                            setTableData(resp.data ?? []);
                            setPagination({
                                total: resp.paging?.total,
                                current: resp.paging?.pageNum,
                                pageSize: resp.paging?.pageSize,
                            });
                        });
                }}
                table={tableProps}
                classification={classificationProps}
                // exportBtn={{
                //     onClick: () => {
                //         return Promise.resolve();
                //     },
                // }}
                importBtn={
                    hasFeature && hasFeature('sys:knowledgePoint:import')
                        ? {
                            dropdown: [
                                {
                                    label: /* 知识点 */locales.pages.gong.konwledgeBase.index.knowledgePoints,
                                    type: 'knowledge',
                                },
                                {
                                    label: /* 流程 */locales.pages.gong.konwledgeBase.index.technologicalProcess,
                                    type: 'process',
                                },
                            ],
                            onClick: (type) => {
                                importType.current = type as ImportType;
                                setIsUploadVisibleVisible(true);
                                return Promise.reject();
                            },
                        }
                        : undefined
                }
                filter={{
                    fields: [
                        {
                            type: 'Input',
                            name: 'keywords',
                            label: /* 关键字 */locales.pages.gong.konwledgeBase.index.keyword,
                        },
                    ],
                    initialValues: { keywords: props.storages.keywords },
                }}
                batchedActions={[
                    {
                        label: /* 批量导出 */locales.pages.gong.konwledgeBase.index.batchExport,
                        onClick: (items) => {
                            if (!items) return Promise.reject();

                            return new Promise((res, rej) => {
                                Modal.confirm({
                                    title: Udesk.utils.string.format(
                                        locales.labels.exportConfirmText.export,
                                        items?.length || 0
                                    ),
                                    icon: <Icon type='ExclamationCircleOutlined' antdIcon={true} />,
                                    maskClosable: true,
                                    okText: locales.labels.export,
                                    onOk() {
                                        postKnowledgeDetailExp(items.map((i) => i.id), {
                                            params: {
                                                systemModule: getSystemModule(),
                                            }
                                        } as any).then(() => {
                                            res(items);
                                            ExportButton.exportSuccessNotification();
                                        });
                                    },
                                    onCancel() {
                                        rej();
                                    },
                                });
                            });
                        },
                    },
                    hasFeature &&
                        hasFeature('sys:knowledgePoint:delete') && {
                        label: /* 批量删除 */locales.pages.gong.konwledgeBase.index.batchDelete,
                        danger: true,
                        onClick: (items) => {
                            if (!items) return Promise.reject();
                            return deleteKnowledgeDetail({
                                params: {
                                    ids: items.map((i) => i.id).join(','),
                                },
                            });
                        },
                    },
                ].filter(Boolean)}
                toolBarRightButtons={[
                    hasFeature &&
                        hasFeature('sys:knowledgePoint:add') && {
                        label: /* 新建 */locales.pages.gong.konwledgeBase.index.newlyBuild,
                        onClick: () => {
                            let routeOptions = {
                                history: props.history,
                                routeName: 'gongKnowledgeBaseCreate',
                                queryParams: {
                                    categoryId: currentListClassificationId,
                                },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                        mainBtn: true,
                    },
                ].filter(Boolean)}
            />
            <ImportModal
                onCancel={() => setIsUploadVisibleVisible(false)}
                onOk={importTamplate}
                visible={isUploadVisible}
                uploadFile={uploadFile}
                downloadTemplate={downloadTemplate}
                title={importType.current === 'process' ? /* 导入流程 */locales.pages.gong.konwledgeBase.index.importProcess : /* 导入知识点 */locales.pages.gong.konwledgeBase.index.importKnowledgePoints}
                file={file}
                tip3={/* 若知识点在知识库中不存在则直接新增知识点，若存在则跳过，不做更新 */locales.pages.gong.konwledgeBase.index.ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate}
            />
            <Modal
                visible={classificationDetailVisible}
                onOk={() => {
                    classificationDetailFormInstance.submit();
                }}
                onCancel={closeModal}
                title={currentEditClassificationId ? /* 编辑知识点分类 */locales.pages.gong.konwledgeBase.index.editKnowledgePointClassification : /* 新建知识点分类 */locales.pages.gong.konwledgeBase.index.newKnowledgePointClassification}
                // destroyOnClose={true}
            >
                <FormBuilder
                    initialValues={classificationDetailValues}
                    onFinish={(values) => {
                        postKnowledgeBase(
                            {
                                ...values,
                                id: currentEditClassificationId ?? undefined,
                            },
                            {
                                successMsg: currentEditClassificationId ? /* 分类编辑成功 */locales.pages.gong.konwledgeBase.index.categoryEditingSucceeded : /* 新建分类成功 */locales.pages.gong.konwledgeBase.index.newClassificationSucceeded,
                            }
                        ).then(() => {
                            closeModal();
                            requestKnowledgeBaseClassification();
                        });
                    }}
                    form={classificationDetailFormInstance}
                    footerRender={false}
                    fields={[
                        {
                            label: /* 分类名称 */locales.pages.gong.konwledgeBase.index.classificationName,
                            name: 'categoryName',
                            type: 'Input',
                            rules: [{ required: true }],
                        },
                    ]}
                />
            </Modal>
        </Page>
    );
});

class Component extends React.Component {
    privates = {
        storages: {
            pagination: default_pagination,
            keywords: undefined,
            selectedKey: null,
        },
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'memory',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/sale-knowledge-base\/detail\/\d+/i,
                /^\/site\/sale-knowledge-base\/view\/\d+/i,
                /^\/site\/sale-knowledge-base\/create/i,
            ],
        },
    };

    actions = {
        saveStorages(changedParams: any) {
            (this as any).privates.storages = { ...(this as any).privates.storages, ...changedParams };
            (this as any).actions.update();
        },
    };
    render() {
        return <Template {...this.props} storages={this.privates.storages} saveStorages={this.actions.saveStorages} />;
    }
}
export default Udesk.react.udeskify(Component);

function fileType(fileName) {
    if (!fileName || typeof fileName !== 'string') {
        return false;
    }
    let list = fileName.split('.');
    return list[list.length - 1];
}
