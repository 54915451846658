import React, { useRef, useState, useEffect } from 'react';
import Udesk from 'Udesk';
import {
    Radio,
    Card,
    Select,
    InputNumber,
    Checkbox,
    Popover,
    Icon,
    Spin,
    // Tag,
    Button,
} from 'udesk-ui';
// import './style.scss';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';
import ClassNames from 'classnames';
import UdeskLocales from 'UdeskLocales';
import { ComplainButton } from './ComplainButton';
import { CopyButton } from './CopyButton';
import { ReviewComponent } from './ReviewComponent';
import { useFlowPoint } from './hooks/useFlowPoint';
import { FlowNodeList } from './FlowNodeList';

const { Option } = Select;

const HitIcon = React.memo((props) => {
    const { isHit, tooltip = UdeskLocales.current.columns.tasks.manage.workbench.checkPointList.hit } =
        props;
    return isHit ? (
        <Button
            tooltip={tooltip}
            type="text"
            size="small"
            icon={<Icon type="CheckCircleFilled" antdIcon={true}></Icon>}
            style={{ color: '#0FBA26', flexShrink: 0 }}
        ></Button>
    ) : null;
});

const MyDropTarget = (props) => {
    const [isflag, setFlag] = useState(props.dropValue === props.ids);
    let ref = useRef(null);
    useEffect(() => {
        setFlag(props.dropValue === props.ids);
    }, [props.dropValue]);
    // const [, drop] = useDrop({
    //     accept: 'cards',
    //     drop: (item, monitor) => {
    //         props.collection && props.collection(props, item);
    //         props.dropChanged && props.dropChanged(null);

    //     },
    //     hover: (item, monitor) => {
    //         props.dropChanged && props.dropChanged(props.ids);
    //     }
    // });

    // drop(ref);
    return (
        <div
            key={props.ids}
            ref={ref}
            className={ClassNames('grad-crid-drop', { 'grad-crid-drop-active': isflag })}
            style={{ display: !props.dropChed ? 'none' : 'block' }}
        ></div>
    );
};

export function PointItem(cprops) {
    const {
        complainPointIdMap,
        testSetListFlag,
        dropChed,
        actions,
        dropValue,
        isTest,
        openDetail,
        locales,
        showAppealButton,
        task,
        items,
        testSetList,
        index,
        xhr,
        prointSet,
        props,
        isPopoverVisible,
        appealHistory,
        isReview,
        point,
    } = cprops;

    const { detailVisible, changeDetailVisible } = useFlowPoint(point);
    console.log('point.canvasNodeMatchResultList', point.canvasNodeMatchResultList);
    return (
        <Card.Grid
            className="quality-check-talk-point-with-appeal-drawer"
            style={{
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: complainPointIdMap[point.pointId]
                    ? 'rgba(15, 186, 38, .1)'
                    : '#fff',
                // scoreTalkRef.current &&
                // scoreTalkRef.current.clientWidth >
                //     312 &&
                // type ===
                //     Udesk.enums
                //         .qualityCheckDetailTypes
                //         .spotCheck.id &&
                // task.sampleStatus != null
                //     ? '50%'
                //     : '100%',
            }}
            // className={ClassNames({
            //     'grad-crid-drop-active':
            //         pointFlag === point.pointId,
            // })}
            key={point.pointId}
        >
            {showMyDropTarget(point, testSetListFlag) && (
                <MyDropTarget
                    key={point.pointId}
                    ids={point.pointId}
                    dropChed={dropChed}
                    dropChanged={actions.dropChanged}
                    name={point.name}
                    dropValue={dropValue}
                    collection={actions.collection}
                />
            )}
            <div
                id={`quality-check-talk-point-${point.pointId}`}
                className="quality-check-talk-point-wrap"
                style={{
                    display: 'flex',
                    // flexWrap: 'wrap',
                    // justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    padding: 8,
                }}
            >
                <div
                    className="quality-check-score-talk-field-proint"
                    onClick={isTest ? openDetail.bind(null, point) : undefined}
                    title={
                        isTest
                            ? /* 点击查看详细信息 */ locales.components.qualityScore.index
                                .clickToViewDetails
                            : undefined
                    }
                    style={{ overflowX: 'hidden' }}
                >
                    <span
                        style={{
                            display: 'flex',
                            width: '100%',
                            whiteSpace: 'nowrap',
                            alignItems: 'center',
                        }}
                    >
                        {hasHighlight(point) ? (
                            <Button
                                type="text"
                                size="small"
                                icon={<Icon type="daohang"></Icon>}
                                onClick={actions.hitCheckPointSelected.params(point)}
                            ></Button>
                        ) : (
                            ((point.gradeType === Udesk.enums.pointGradeTypes.radio.id &&
                                point.gradeChooseHit !== 1) ||
                                !point.gradeChooseOption ||
                                parseFloat(point.gradeChooseOption) === 0 ||
                                !point.gradeInput ||
                                parseFloat(point.gradeInput) === 0) && (
                                <div
                                    style={{
                                        width: 24,
                                        height: 1,
                                        display: 'block',
                                        flexShrink: 0,
                                    }}
                                ></div>
                            )
                        )}
                        <HitIcon
                            isHit={
                                (point.gradeChooseHit === 1 ||
                                    (Boolean(point.gradeChooseOption) &&
                                        parseFloat(point.gradeChooseOption) !== 0) ||
                                    (Boolean(point.gradeInput) &&
                                        parseFloat(point.gradeInput) !== 0)) &&
                                !isFlow(point.ruleType)
                            }
                        />
                        <div className="check-point-name" style={{ height: 22 }}>
                            <Button
                                onClick={(e) => e.stopPropagation()}
                                title={point.name}
                                size="small"
                                type="link"
                                href={showAppealButton
                                    ? undefined
                                    : (
                                        task.inspectDataSource ===
                                        Udesk.enums.inspectDataSources.wechatDialogue.id
                                            ? `/site/wechat-qa/setting/check-point/${point.groupId}/${point.pointId}/edit`
                                            : `/site/tasks/manage/${task.taskId}/template/check-point/${items.id}/edit/${point.pointId}`
                                    )
                                }
                                target={showAppealButton ? undefined : "_blank"}
                                style={{
                                    overflow: 'hidden',
                                    maxWidth: '100%',
                                }}
                            >
                                {point.name}
                            </Button>
                            {!showAppealButton && (
                                <div className="check-point-name-copy-btn" onClick={(e) => e.stopPropagation()}>
                                    <CopyButton key={point.pointId} text={point.name} />
                                </div>
                            )}
                            <If condition={point.predeterminedScore}>
                                <Choose>
                                    <When
                                        condition={
                                            testSetListFlag &&
                                            point.type !== Udesk.enums.pointTypes.manual.id
                                        }
                                    >
                                        <If condition={isgradeTypeIncludesRadioOrFoctors(point)}>
                                            <TestSetPointScore
                                                testSetList={testSetList}
                                                locales={locales}
                                                point={point}
                                                condition={(testSetPoint, point) =>
                                                    testSetPoint.gradeChooseHit === point.gradeChooseHit
                                                }
                                            />
                                        </If>
                                        <If
                                            condition={
                                                point.gradeType ===
                                                Udesk.enums.pointGradeTypes.select.id
                                            }
                                        >
                                            <TestSetPointScore
                                                testSetList={testSetList}
                                                locales={locales}
                                                point={point}
                                                condition={(testSetPoint, point) =>
                                                    testSetPoint.gradeChooseOption ===
                                                    point.gradeChooseOption
                                                }
                                            />
                                        </If>
                                        <If
                                            condition={
                                                point.gradeType === Udesk.enums.pointGradeTypes.input.id
                                            }
                                        >
                                            <span>
                                                {point.scoreType === 2 ? '-' : '+'}
                                                {point.predeterminedScore}
                                                {locales.labels.scoreUnit}
                                            </span>
                                        </If>
                                    </When>
                                    <Otherwise>
                                        {point.scoreType === 2 ? '-' : '+'}
                                        {point.predeterminedScore}
                                        {locales.labels.scoreUnit}
                                    </Otherwise>
                                </Choose>
                            </If>
                        </div>
                        <If condition={point.weight}>{point.weight}</If>
                        <If
                            condition={
                                (point.type === Udesk.enums.pointTypes.interactiveDetection.id &&
                                    point.gradeType !== Udesk.enums.pointGradeTypes.foctors.id) ||
                                (point.type === Udesk.enums.pointTypes.smartRules.id &&
                                    point.interactiveConditionFlag)
                            }
                        >
                            <Popover
                                content={
                                    <p
                                        class="interactive-point-result-container-call-info"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Choose>
                                            <When
                                                condition={
                                                    point.interactiveResult &&
                                                    point.interactiveResult.length > 0
                                                }
                                            >
                                                <For
                                                    each="interactiveItem"
                                                    index="index"
                                                    of={point.interactiveResult}
                                                >
                                                    <p
                                                        key={index}
                                                        className="interactive-point-result-item"
                                                    >
                                                        <p className="interactive-point-result-question">
                                                            <span className="interactive-point-result-question-title">
                                                                {locales.labels.question}
                                                                {locales.labels.colon}
                                                                {
                                                                    interactiveItem.mainQuestionContent
                                                                }
                                                            </span>
                                                            <span className="interactive-point-result-question-type pull-right">
                                                                <Choose>
                                                                    <When
                                                                        condition={
                                                                            interactiveItem.matchQuestionType ===
                                                                            Udesk.enums
                                                                                .interactiveMatchedQuestionTypes
                                                                                .question.id
                                                                        }
                                                                    >
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong interactive-point-result-icon-chenggong" />
                                                                    </When>
                                                                    <Otherwise>
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai interactive-point-result-icon-shibai" />
                                                                    </Otherwise>
                                                                </Choose>
                                                                {Udesk.enums.interactiveMatchedQuestionTypes.getName(
                                                                    interactiveItem.matchQuestionType
                                                                )}
                                                            </span>
                                                        </p>
                                                        <p className="interactive-point-result-answer">
                                                            <span className="interactive-point-result-answer-title">
                                                                {locales.labels.answer}
                                                                {locales.labels.colon}
                                                                {interactiveItem.mainAnswerContent}
                                                            </span>
                                                            <span className="interactive-point-result-answer-type pull-right">
                                                                <Choose>
                                                                    <When
                                                                        condition={
                                                                            interactiveItem.matchAnswerType ===
                                                                            Udesk.enums
                                                                                .interactiveAnswerTypes
                                                                                .answer.id
                                                                        }
                                                                    >
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong interactive-point-result-icon-chenggong" />
                                                                    </When>
                                                                    <Otherwise>
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai interactive-point-result-icon-shibai" />
                                                                    </Otherwise>
                                                                </Choose>
                                                                {Udesk.enums.interactiveAnswerTypes.getName(
                                                                    interactiveItem.matchAnswerType
                                                                )}
                                                            </span>
                                                        </p>
                                                    </p>
                                                </For>
                                            </When>
                                            <Otherwise>
                                                <Spin size="small" spinning={xhr[point.id]}>
                                                    <span>
                                                        {
                                                            locales.components.pages.components
                                                                .qualityCheckScore
                                                                .interactiveIsEmptyTip
                                                        }
                                                    </span>
                                                </Spin>
                                            </Otherwise>
                                        </Choose>
                                    </p>
                                }
                                trigger="click"
                                placement="bottomRight"
                                destroyTooltipOnHide={true}
                                // getPopupContainer={triggerNode => triggerNode.parentElement}
                                onVisibleChange={actions.interactivePoint.params(point)}
                            >
                                <Button
                                    onClick={(e) => e.stopPropagation()}
                                    style={{
                                        color: '#1A6EFF',
                                    }}
                                    size="small"
                                    icon={<Icon type="jiaohushibie"></Icon>}
                                    type="text"
                                ></Button>
                            </Popover>
                        </If>
                    </span>
                </div>
                {isFlow(point.ruleType) ? (
                    <div>
                        {`${canvasNodeMatchResultCount(point.canvasNodeMatchResultList)}${/* 项命中 */UdeskLocales['current'].components.qualityScore.components.pointItem.xiangHit}`}
                        <Button
                            onClick={changeDetailVisible}
                            type="text"
                            size="small"
                            icon={<Icon antdIcon={true} type="DownOutlined" rotate={detailVisible ? 0 : 180} />}
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            maxWidth: '52%',
                            // flex: '1',
                        }}
                    >
                        <div className="quality-check-score-talk-field-choose">
                            <If
                                condition={point.type !== Udesk.enums.pointTypes.machineLearning.id}
                            >
                                <If condition={point.naCheckedAvailable}>
                                    {testSetListFlag ? (
                                        testSetList &&
                                        !_isEmpty(testSetList.offsetDetails) &&
                                        _find(
                                            testSetList.offsetDetails,
                                            (current) => current.pointId === point.pointId
                                        ) ? (
                                                <div className="test-set-list-checkbox">
                                                    {point.naChecked === 1 &&
                                                _find(
                                                    testSetList.offsetDetails,
                                                    (current) => current.pointId === point.pointId
                                                ).naCheck !== point.naChecked ? (
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                        ) : point.naChecked === 0 &&
                                                  _find(
                                                      testSetList.offsetDetails,
                                                      (current) => current.pointId === point.pointId
                                                  ).naCheck === 1 ? (
                                                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                            ) : point.naChecked === 1 ? (
                                                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                            ) : (
                                                                <span className="test-set-list-checkbox-just"></span>
                                                            )}
                                                    <span>N/A</span>
                                                </div>
                                            ) : (
                                                <div className="test-set-list-checkbox">
                                                    {point.naChecked === 1 ? (
                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                    ) : (
                                                        <span className="test-set-list-checkbox-just"></span>
                                                    )}
                                                    <span>N/A</span>
                                                </div>
                                            )
                                    ) : (
                                        <span onClick={(e) => e.stopPropagation()}>
                                            <Checkbox
                                                onClick={(e) => e.stopPropagation()}
                                                checked={point.naChecked ? true : false}
                                                onChange={actions.changePointNaChecked.params(
                                                    point
                                                )}
                                            >
                                                N/A
                                            </Checkbox>
                                        </span>
                                    )}
                                </If>
                            </If>

                            <Choose>
                                <When
                                    condition={
                                        point.type === Udesk.enums.pointTypes.machineLearning.id
                                    }
                                >
                                    {testSetListFlag ? (
                                        testSetList &&
                                        !_isEmpty(testSetList.offsetDetails) &&
                                        _find(
                                            testSetList.offsetDetails,
                                            (current) => current.pointId === point.pointId
                                        ) ? (
                                                <div className="test-set-list-status-reluat">
                                                    <span className="test-set-list-radio">
                                                        {point.gradeChooseHit === 1 &&
                                                    _find(
                                                        testSetList.offsetDetails,
                                                        (current) =>
                                                            current.pointId === point.pointId
                                                    ).gradeChooseHit !== point.gradeChooseHit ? (
                                                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                            ) : point.gradeChooseHit !== 1 &&
                                                      _find(
                                                          testSetList.offsetDetails,
                                                          (current) =>
                                                              current.pointId === point.pointId
                                                      ).gradeChooseHit === 1 ? (
                                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                                ) : point.gradeChooseHit === 1 ? (
                                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                ) : (
                                                                    <span className="test-set-list-circle"></span>
                                                                )}
                                                        <span>
                                                            {
                                                                locales.components.pages.testSetList
                                                                    .reultList.ok
                                                            }
                                                        </span>
                                                    </span>
                                                    <span className="test-set-list-radio">
                                                        {point.gradeChooseHit === 0 &&
                                                    _find(
                                                        testSetList.offsetDetails,
                                                        (current) =>
                                                            current.pointId === point.pointId
                                                    ).gradeChooseHit !== point.gradeChooseHit ? (
                                                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                            ) : point.gradeChooseHit !== 0 &&
                                                      _find(
                                                          testSetList.offsetDetails,
                                                          (current) =>
                                                              current.pointId === point.pointId
                                                      ).gradeChooseHit === 0 ? (
                                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                                ) : point.gradeChooseHit === 0 ? (
                                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                ) : (
                                                                    <span className="test-set-list-circle"></span>
                                                                )}
                                                        <span>
                                                            {
                                                                locales.components.pages.testSetList
                                                                    .reultList.noCancel
                                                            }
                                                        </span>
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="test-set-list-status-reluat">
                                                    <span className="test-set-list-radio">
                                                        {point.gradeChooseHit === 1 ? (
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                        ) : (
                                                            <span className="test-set-list-circle"></span>
                                                        )}
                                                        <span>
                                                            {
                                                                locales.components.pages.testSetList
                                                                    .reultList.ok
                                                            }
                                                        </span>
                                                    </span>
                                                    <span className="test-set-list-radio">
                                                        {point.gradeChooseHit === 0 ? (
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                        ) : (
                                                            <span className="test-set-list-circle"></span>
                                                        )}
                                                        <span>
                                                            {
                                                                locales.components.pages.testSetList
                                                                    .reultList.noCancel
                                                            }
                                                        </span>
                                                    </span>
                                                </div>
                                            )
                                    ) : (
                                        <span onClick={(e) => e.stopPropagation()}>
                                            <Radio.Group
                                                value={point.gradeChooseHit}
                                                disabled={point.naChecked || showAppealButton}
                                                onChange={actions.changePointGradeChooseHit.params(
                                                    point
                                                )}
                                            >
                                                {Udesk.enums.gradeChooseHits.map((item) => {
                                                    return (
                                                        <Radio
                                                            onClick={(e) => e.stopPropagation()}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </Radio>
                                                    );
                                                })}
                                            </Radio.Group>
                                        </span>
                                    )}
                                    <If
                                        condition={
                                            (point.gradeType ===
                                                Udesk.enums.strategyTypes.hitAdd.id ||
                                                point.gradeType ===
                                                    Udesk.enums.strategyTypes.hitSub.id) &&
                                            point.gradeChooseHit === 1
                                        }
                                    >
                                        {(!prointSet[point.pointId] ||
                                            prointSet[point.pointId].length === 0) && (
                                            <span
                                                style={{
                                                    marginLeft: '10px',
                                                    color: 'rgba(224,32,32,1)',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {locales.fix.mustMarked}
                                            </span>
                                        )}
                                    </If>
                                    <If
                                        condition={
                                            (point.gradeType ===
                                                Udesk.enums.strategyTypes.hitNotAdd.id ||
                                                point.gradeType ===
                                                    Udesk.enums.strategyTypes.hitNotSub.id) &&
                                            point.gradeChooseHit === 0
                                        }
                                    >
                                        {(!prointSet[point.pointId] ||
                                            prointSet[point.pointId].length === 0) && (
                                            <span
                                                style={{
                                                    marginLeft: '10px',
                                                    color: 'rgba(224,32,32,1)',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {locales.fix.mustMarked}
                                            </span>
                                        )}
                                    </If>
                                </When>
                                <When
                                    condition={
                                        point.type === Udesk.enums.pointTypes.automatic.id ||
                                        point.type === Udesk.enums.pointTypes.smartRules.id
                                    }
                                >
                                    <Choose>
                                        <When
                                            condition={
                                                point.gradeType ===
                                                    Udesk.enums.pointGradeTypes.radio.id ||
                                                point.gradeType ===
                                                    Udesk.enums.pointGradeTypes.foctors.id
                                            }
                                        >
                                            {testSetListFlag ? (
                                                testSetList &&
                                                !_isEmpty(testSetList.offsetDetails) &&
                                                _find(
                                                    testSetList.offsetDetails,
                                                    (current) => current.pointId === point.pointId
                                                ) ? (
                                                        <div className="test-set-list-status-reluat">
                                                            <span className="test-set-list-radio">
                                                                {point.gradeChooseHit === 1 &&
                                                            _find(
                                                                testSetList.offsetDetails,
                                                                (current) =>
                                                                    current.pointId ===
                                                                    point.pointId
                                                            ).gradeChooseHit !==
                                                                point.gradeChooseHit ? (
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                    ) : point.gradeChooseHit !== 1 &&
                                                              _find(
                                                                  testSetList.offsetDetails,
                                                                  (current) =>
                                                                      current.pointId ===
                                                                      point.pointId
                                                              ).gradeChooseHit === 1 ? (
                                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                                        ) : point.gradeChooseHit === 1 ? (
                                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                        ) : (
                                                                            <span className="test-set-list-circle"></span>
                                                                        )}
                                                                <span>
                                                                    {
                                                                        locales.components.pages
                                                                            .testSetList.reultList.ok
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className="test-set-list-radio">
                                                                {point.gradeChooseHit === 0 &&
                                                            _find(
                                                                testSetList.offsetDetails,
                                                                (current) =>
                                                                    current.pointId ===
                                                                    point.pointId
                                                            ).gradeChooseHit !==
                                                                point.gradeChooseHit ? (
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                    ) : point.gradeChooseHit !== 0 &&
                                                              _find(
                                                                  testSetList.offsetDetails,
                                                                  (current) =>
                                                                      current.pointId ===
                                                                      point.pointId
                                                              ).gradeChooseHit === 0 ? (
                                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                                        ) : point.gradeChooseHit === 0 ? (
                                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                        ) : (
                                                                            <span className="test-set-list-circle"></span>
                                                                        )}
                                                                <span>
                                                                    {
                                                                        locales.components.pages
                                                                            .testSetList.reultList
                                                                            .noCancel
                                                                    }
                                                                </span>
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="test-set-list-status-reluat">
                                                            <span className="test-set-list-radio">
                                                                {point.gradeChooseHit === 1 ? (
                                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                ) : (
                                                                    <span className="test-set-list-circle"></span>
                                                                )}
                                                                <span>
                                                                    {
                                                                        locales.components.pages
                                                                            .testSetList.reultList.ok
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className="test-set-list-radio">
                                                                {point.gradeChooseHit === 0 ? (
                                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                ) : (
                                                                    <span className="test-set-list-circle"></span>
                                                                )}
                                                                <span>
                                                                    {
                                                                        locales.components.pages
                                                                            .testSetList.reultList
                                                                            .noCancel
                                                                    }
                                                                </span>
                                                            </span>
                                                        </div>
                                                    )
                                            ) : (
                                                <span onClick={(e) => e.stopPropagation()}>
                                                    <Radio.Group
                                                        value={point.gradeChooseHit}
                                                        disabled={
                                                            point.naChecked || showAppealButton
                                                        }
                                                        onChange={actions.changePointGradeChooseHit.params(
                                                            point
                                                        )}
                                                    >
                                                        {Udesk.enums.gradeChooseHits.map((item) => {
                                                            return (
                                                                <Radio
                                                                    onClick={(e) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                    value={item.id}
                                                                >
                                                                    {item.name}
                                                                </Radio>
                                                            );
                                                        })}
                                                    </Radio.Group>
                                                </span>
                                            )}
                                        </When>
                                        <When
                                            condition={
                                                point.gradeType ===
                                                Udesk.enums.pointGradeTypes.select.id
                                            }
                                        >
                                            {testSetListFlag ? (
                                                testSetList &&
                                                !_isEmpty(testSetList.offsetDetails) &&
                                                _find(
                                                    testSetList.offsetDetails,
                                                    (current) => current.pointId === point.pointId
                                                ) ? (
                                                        _find(
                                                            testSetList.offsetDetails,
                                                            (current) =>
                                                                current.pointId === point.pointId
                                                        ).pointHitValue !== point.pointHitValue ? (
                                                                <div className="test-set-list-select-ok">
                                                                    <Select
                                                                        value={
                                                                            point.pointHitValue !== '' &&
                                                                    point.pointHitValue
                                                                                ? point.pointHitValue
                                                                                : undefined
                                                                        }
                                                                        dataScource={point.gearOptionList}
                                                                        placeholder={
                                                                            locales.fix.pleaseChoose
                                                                        }
                                                                        onChange={actions.changeGradeChooseOption.params(
                                                                            point
                                                                        )}
                                                                        disabled="disbaled"
                                                                        style={{
                                                                            width: 130,
                                                                        }}
                                                                        dropdownStyle={{
                                                                            minWidth: 130,
                                                                            maxWidth: 170,
                                                                        }}
                                                                        dropdownMatchSelectWidth={false}
                                                                    >
                                                                        {!_isEmpty(point.gearOptionList) &&
                                                                    point.gearOptionList.map(
                                                                        (item) => {
                                                                            return (
                                                                                <Option
                                                                                    value={item.id}
                                                                                    title={
                                                                                        item.name
                                                                                    }
                                                                                >
                                                                                    {item.name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    )}
                                                                    </Select>

                                                                    <div className="test-set-list-select-cancel-option">
                                                                        {
                                                                            _find(
                                                                                testSetList.offsetDetails,
                                                                                (current) =>
                                                                                    current.pointId ===
                                                                            point.pointId
                                                                            ).pointHitValue
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="test-set-list-select-cancel">
                                                                    <Select
                                                                        value={
                                                                            point.pointHitValue !== '' &&
                                                                    point.pointHitValue
                                                                                ? point.pointHitValue
                                                                                : undefined
                                                                        }
                                                                        placeholder={
                                                                            locales.fix.pleaseChoose
                                                                        }
                                                                        onChange={actions.changeGradeChooseOption.params(
                                                                            point
                                                                        )}
                                                                        disabled="disbaled"
                                                                        style={{
                                                                            width: 130,
                                                                        }}
                                                                        dropdownStyle={{
                                                                            minWidth: 130,
                                                                            maxWidth: 170,
                                                                        }}
                                                                        dropdownMatchSelectWidth={false}
                                                                    >
                                                                        {!_isEmpty(point.gearOptionList) &&
                                                                    point.gearOptionList.map(
                                                                        (item) => {
                                                                            return (
                                                                                <Option
                                                                                    value={item.id}
                                                                                    title={
                                                                                        item.name
                                                                                    }
                                                                                >
                                                                                    {item.name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    )}
                                                                    </Select>
                                                                </div>
                                                            )
                                                    ) : (
                                                        <div className="test-set-list-select-ok">
                                                            <Select
                                                                value={
                                                                    point.pointHitValue !== '' &&
                                                                point.pointHitValue
                                                                        ? point.pointHitValue
                                                                        : undefined
                                                                }
                                                                placeholder={locales.fix.pleaseChoose}
                                                                onChange={actions.changeGradeChooseOption.params(
                                                                    point
                                                                )}
                                                                disabled="disbaled"
                                                                style={{
                                                                    width: 130,
                                                                }}
                                                                dropdownStyle={{
                                                                    minWidth: 130,
                                                                    maxWidth: 170,
                                                                }}
                                                                dropdownMatchSelectWidth={false}
                                                            >
                                                                {!_isEmpty(point.gearOptionList) &&
                                                                point.gearOptionList.map((item) => {
                                                                    return (
                                                                        <Option
                                                                            value={item.id}
                                                                            title={item.name}
                                                                        >
                                                                            {item.name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    )
                                            ) : (
                                                <Select
                                                    onClick={(e) => e.stopPropagation()}
                                                    value={
                                                        point.pointHitValue !== '' &&
                                                        point.pointHitValue
                                                            ? point.pointHitValue
                                                            : undefined
                                                    }
                                                    placeholder={locales.fix.pleaseChoose}
                                                    onChange={actions.changeGradeChooseOption.params(
                                                        point
                                                    )}
                                                    disabled={
                                                        point.naChecked ||
                                                        isTest ||
                                                        showAppealButton
                                                    }
                                                    style={{
                                                        width: 130,
                                                    }}
                                                    dropdownStyle={{
                                                        minWidth: 130,
                                                        maxWidth: 170,
                                                    }}
                                                    dropdownMatchSelectWidth={false}
                                                >
                                                    {!_isEmpty(point.gearOptionList) &&
                                                        point.gearOptionList.map((item) => {
                                                            return (
                                                                <Option
                                                                    value={item.id}
                                                                    title={item.name}
                                                                >
                                                                    {item.name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            )}
                                        </When>
                                    </Choose>
                                </When>
                                <When condition={point.type === Udesk.enums.pointTypes.manual.id}>
                                    <Choose>
                                        <When
                                            condition={
                                                point.gradeType ===
                                                    Udesk.enums.pointGradeTypes.radio.id ||
                                                point.gradeType ===
                                                    Udesk.enums.pointGradeTypes.foctors.id
                                            }
                                        >
                                            <span onClick={(e) => e.stopPropagation()}>
                                                <Radio.Group
                                                    value={point.gradeChooseHit}
                                                    disabled={point.naChecked || showAppealButton}
                                                    onChange={actions.changePointGradeChooseHit.params(
                                                        point
                                                    )}
                                                >
                                                    {Udesk.enums.gradeChooseHits.map((item) => {
                                                        return (
                                                            <Radio
                                                                onClick={(e) => e.stopPropagation()}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </Radio>
                                                        );
                                                    })}
                                                </Radio.Group>
                                            </span>
                                        </When>
                                        <When
                                            condition={
                                                point.gradeType ===
                                                Udesk.enums.pointGradeTypes.input.id
                                            }
                                        >
                                            <With min={0} max={point.predeterminedScore}>
                                                <span onClick={(e) => e.stopPropagation()}>
                                                    <span>
                                                        {point.scoreType === 2
                                                            ? locales.labels.subNumber
                                                            : locales.labels.addNumber}
                                                    </span>
                                                    <InputNumber
                                                        size="small"
                                                        onClick={(e) => e.stopPropagation()}
                                                        min={min}
                                                        max={max}
                                                        disabled={
                                                            point.naChecked || showAppealButton
                                                        }
                                                        value={point.gradeInput}
                                                        style={{
                                                            width: 60,
                                                        }}
                                                        onChange={actions.changePointGradeInput.params(
                                                            point
                                                        )}
                                                    />
                                                    <span>{locales.labels.scoreUnit}</span>
                                                </span>
                                            </With>
                                        </When>
                                        <When
                                            condition={
                                                point.gradeType ===
                                                Udesk.enums.pointGradeTypes.select.id
                                            }
                                        >
                                            <Select
                                                onClick={(e) => e.stopPropagation()}
                                                value={
                                                    point.pointHitValue !== '' &&
                                                    point.pointHitValue
                                                        ? point.pointHitValue
                                                        : undefined
                                                }
                                                dataScource={point.gearOptionList}
                                                placeholder={locales.fix.pleaseChoose}
                                                onChange={actions.changeGradeChooseOption.params(
                                                    point
                                                )}
                                                disabled={
                                                    point.naChecked || isTest || showAppealButton
                                                }
                                                style={{
                                                    width: 130,
                                                }}
                                                dropdownStyle={{
                                                    minWidth: 130,
                                                    maxWidth: 170,
                                                }}
                                                dropdownMatchSelectWidth={false}
                                            >
                                                {!_isEmpty(point.gearOptionList) &&
                                                    point.gearOptionList.map((item) => {
                                                        return (
                                                            <Option
                                                                title={item.name}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </When>
                                    </Choose>
                                </When>
                                <When
                                    condition={
                                        point.type ===
                                        Udesk.enums.pointTypes.interactiveDetection.id
                                    }
                                >
                                    <Choose>
                                        <When
                                            condition={
                                                point.gradeType ===
                                                    Udesk.enums.pointGradeTypes.radio.id ||
                                                point.gradeType ===
                                                    Udesk.enums.pointGradeTypes.foctors.id
                                            }
                                        >
                                            {testSetListFlag ? (
                                                testSetList &&
                                                !_isEmpty(testSetList.offsetDetails) &&
                                                _find(
                                                    testSetList.offsetDetails,
                                                    (current) => current.pointId === point.pointId
                                                ) ? (
                                                        <div className="test-set-list-status-reluat">
                                                            <span className="test-set-list-radio">
                                                                {point.gradeChooseHit === 1 &&
                                                            _find(
                                                                testSetList.offsetDetails,
                                                                (current) =>
                                                                    current.pointId ===
                                                                    point.pointId
                                                            ).gradeChooseHit !==
                                                                point.gradeChooseHit ? (
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                    ) : point.gradeChooseHit !== 1 &&
                                                              _find(
                                                                  testSetList.offsetDetails,
                                                                  (current) =>
                                                                      current.pointId ===
                                                                      point.pointId
                                                              ).gradeChooseHit === 1 ? (
                                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                                        ) : point.gradeChooseHit === 1 ? (
                                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                        ) : (
                                                                            <span className="test-set-list-circle"></span>
                                                                        )}
                                                                <span>
                                                                    {
                                                                        locales.components.pages
                                                                            .testSetList.reultList.ok
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className="test-set-list-radio">
                                                                {point.gradeChooseHit === 0 &&
                                                            _find(
                                                                testSetList.offsetDetails,
                                                                (current) =>
                                                                    current.pointId ===
                                                                    point.pointId
                                                            ).gradeChooseHit !==
                                                                point.gradeChooseHit ? (
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                    ) : point.gradeChooseHit !== 0 &&
                                                              _find(
                                                                  testSetList.offsetDetails,
                                                                  (current) =>
                                                                      current.pointId ===
                                                                      point.pointId
                                                              ).gradeChooseHit === 0 ? (
                                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                                        ) : point.gradeChooseHit === 0 ? (
                                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                        ) : (
                                                                            <span className="test-set-list-circle"></span>
                                                                        )}
                                                                <span>
                                                                    {
                                                                        locales.components.pages
                                                                            .testSetList.reultList
                                                                            .noCancel
                                                                    }
                                                                </span>
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="test-set-list-status-reluat">
                                                            <span className="test-set-list-radio">
                                                                {point.gradeChooseHit === 1 ? (
                                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                ) : (
                                                                    <span className="test-set-list-circle"></span>
                                                                )}
                                                                <span>
                                                                    {
                                                                        locales.components.pages
                                                                            .testSetList.reultList.ok
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className="test-set-list-radio">
                                                                {point.gradeChooseHit === 0 ? (
                                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong"></i>
                                                                ) : (
                                                                    <span className="test-set-list-circle"></span>
                                                                )}
                                                                <span>
                                                                    {
                                                                        locales.components.pages
                                                                            .testSetList.reultList
                                                                            .noCancel
                                                                    }
                                                                </span>
                                                            </span>
                                                        </div>
                                                    )
                                            ) : (
                                                <span onClick={(e) => e.stopPropagation()}>
                                                    <Radio.Group
                                                        value={point.gradeChooseHit}
                                                        disabled={
                                                            point.naChecked || showAppealButton
                                                        }
                                                        onChange={actions.changePointGradeChooseHit.params(
                                                            point
                                                        )}
                                                    >
                                                        {Udesk.enums.gradeChooseHits.map((item) => {
                                                            return (
                                                                <Radio
                                                                    onClick={(e) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                    value={item.id}
                                                                >
                                                                    {item.name}
                                                                </Radio>
                                                            );
                                                        })}
                                                    </Radio.Group>
                                                </span>
                                            )}
                                        </When>
                                    </Choose>
                                </When>
                            </Choose>
                        </div>
                    </div>
                )}
            </div>

            <If condition={point.remark || props.pointRemarksMap[point.pointId]}>
                <div
                    className="quality-check-score-talk-field-remark"
                    title={`${locales.components.pages.components.qualityCheckScore.remark}${
                        locales.labels.colon
                    }${props.pointRemarksMap[point.pointId] || point.remark}`}
                >
                    <div
                        style={{
                            width: 24,
                            height: 1,
                            display: 'block',
                            flexShrink: 0,
                        }}
                    ></div>
                    <div
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            flex: 1,
                            paddingLeft: 8,
                        }}
                    >
                        {props.pointRemarksMap[point.pointId] || point.remark}
                    </div>
                </div>
            </If>
            {detailVisible && isFlow(point.ruleType) && (
                <FlowNodeList
                    canvasNodeMatchResultList={point.canvasNodeMatchResultList}
                    hitCheckPointSelected={actions.hitCheckPointSelected}
                    onNodeChange={actions.changeFlowNodeGradeChooseHit.params(
                        point
                    )}
                    disabled={showAppealButton}
                />
            )}
            {actions.isComplain(
                <ComplainButton
                    btnText={
                        locales.components.pages.appealSdk[
                            complainPointIdMap[point.pointId] ? 'pendingSubmit' : 'complain'
                        ]
                    }
                    visible={showAppealButton}
                    pointId={point.pointId}
                    onSubmitClick={actions.onSubmitClick}
                    onCancelClick={actions.onCancelClick}
                    onVisibleChange={actions.onVisibleChange}
                    hasOtherPopoverOpen={isPopoverVisible}
                    originValue={complainPointIdMap[point.pointId]}
                    inspectDataSource={task.inspectDataSource}
                />
            )}
            {actions.isApprove(point, (point) => (
                <ReviewComponent
                    localesReview={appealHistory}
                    visible={isReview}
                    complaintInfo={point.pointComplaintInfo}
                    onReject={actions.changePointRejectReason.params(point)}
                    onResolve={actions.passComplain.params(point)}
                    showActionsBtn={
                        props.detail.reviewStatus !== Udesk.enums.sampleAndReviewStatus.reviewed.id
                    }
                />
            ))}
        </Card.Grid>
    );
}

function showMyDropTarget(point, testSetListFlag) {
    if (testSetListFlag) return false;
    if (point.type !== Udesk.enums.pointTypes.machineLearning.id) return true;

    return (
        ((point.gradeType === Udesk.enums.strategyTypes.hitAdd.id ||
            point.gradeType === Udesk.enums.strategyTypes.hitSub.id) &&
            point.gradeChooseHit === 1) ||
        ((point.gradeType === Udesk.enums.strategyTypes.hitNotAdd.id ||
            point.gradeType === Udesk.enums.strategyTypes.hitNotSub.id) &&
            point.gradeChooseHit === 0)
    );
}

function hasHighlight(point) {
    return (
        (point.type === Udesk.enums.pointTypes.smartRules.id &&
            point.highlightGroupList?.length > 0) ||
        (point.type !== Udesk.enums.pointTypes.smartRules.id && point.highlightSeqList?.length > 0)
    );
}

function isgradeTypeIncludesRadioOrFoctors(point) {
    return [Udesk.enums.pointGradeTypes.radio.id, Udesk.enums.pointGradeTypes.foctors.id].includes(
        point.gradeType
    );
}

function TestSetPointScore({ testSetList, locales, point, condition }) {
    const testSetPoint =
        testSetList &&
        !_isEmpty(testSetList.offsetDetails) &&
        _find(testSetList.offsetDetails, (current) => current.pointId === point.pointId);
    return testSetPoint ? (
        condition(testSetPoint, point) ? (
            <span
                style={{
                    color: '#36864A',
                    overflowX: 'unset',
                    width: 'unset',
                }}
            >
                {point.scoreType === 2 ? '-' : '+'}
                {point.predeterminedScore}
                {locales.labels.scoreUnit}
            </span>
        ) : (
            <span
                style={{
                    color: '#E03C32',
                    overflowX: 'unset',
                    width: 'unset',
                }}
            >
                {point.scoreType === 2 ? '-' : '+'}
                {point.predeterminedScore}
                {locales.labels.scoreUnit}
            </span>
        )
    ) : (
        <span
            style={{
                color: '#36864A',
                overflowX: 'unset',
                width: 'unset',
            }}
        >
            {point.scoreType === 2 ? '-' : '+'}
            {point.predeterminedScore}
            {locales.labels.scoreUnit}
        </span>
    );
}

function isFlow(ruleType) {
    return ruleType === Udesk.enums.ruleTypes.flow.id;
}

function canvasNodeMatchResultCount(canvasNodeMatchResultList) {
    return (canvasNodeMatchResultList ?? []).filter((c) => c.isHit).length;
}
