import React from 'react';
import Udesk from 'Udesk';
import config from '../../../../../common/config/index';
import Locales from "UdeskLocales";

class IndexManagementDetailComponent extends React.Component {
    static defaultProps = {
        sourceList: [
            {
                value: 0,
                text: Locales['current'].fix.callAndConversation
            },
            {
                value: 1,
                text: Locales['current'].fix.call
            },
            {
                value: 2,
                text: Locales['current'].fix.conversation
            }
        ],
        scoreRadioList: [
            {
                value: 1,
                text: Locales['current'].fix.cumulative
            },
            {
                value: 2,
                text: Locales['current'].fix.average
            },
            {
                value: 3,
                text: Locales['current'].fix.distribution
            }
        ],
        status: [
            {
                value: 1,
                text: Locales['current'].fix.invalid
            },
            {
                value: 0,
                text: Locales['current'].fix.effective
            }
        ],
        otherFactorsStatus: [
            {
                value: 1,
                text: Locales['current'].enums.tasks.calculateScoreTypes.add
            },
            {
                value: 2,
                text: Locales['current'].enums.tasks.calculateScoreTypes.sub
            }
        ]
    };
    privates = {
        columns: [],
        data: [],
        paging: {
            pageSize: 10,
            pageNum: 1
        },
        initData: [],
        sourceValue: 0,
        conditionJsonInfo: {
            positiveFactors: [],
            negativeFactors: []
        },
        inspectionData: [],
        judgeStrategy: {
            positiveFactors: 1,
            negativeFactors: 1
        },
        customJudgeLogic: {
            positiveFactors: '',
            negativeFactors: ''
        },
        otherFactorsCount: 1,
        chartFieldsList: [],
        otherFactorsList: [],
        detailData: {},
        calculateType: 1,
        sysType: 2,
        customFilterList: [],
        customeFilterField: null,
        customeFilterFieldKey: [],
        customDataSourceList: [],
        fieldList: [],
        judgeStrategyList: 1,
        customJudgeLogicList: '',
        conditionList: [],
        loading: false
    };
    static computes = {
        _fields: [
            'privates.inspectionData',
            function ({ props, privates, locales }) {
                let { inspectionData } = privates;
                let list = inspectionData.map(field => {
                    return field;
                });
                return Udesk.business.fields.buildConditionFields({
                    fields: list
                });
            }
        ],
        _fieldValueSettings: [
            'privates.inspectionData',
            function ({ props, privates, locales }) {
                return config.getFilterComponentSettings();
            }
        ]
    };
    actions = {
        pageChanged() { },
        onChangeGrounp() { },
        initDataMap(data) {
            if (data && data.length > 0) {
                data.forEach(item => {
                    item.title = item.agentGroupName;
                    item.value = item.id;
                    item.key = item.id;
                    if (item.children) {
                        this.actions.initDataMap(item.children);
                    }
                });
            }
        },
        changeJudgeStrategy(field, value) {
            let { judgeStrategy } = this.privates;
            for (let key in judgeStrategy) {
                if (key === field) {
                    judgeStrategy[key] = value;
                }
            }
            this.actions.update();
        },
        customJudgeLogic(field, value) {
            let { customJudgeLogic } = this.privates;
            for (let key in customJudgeLogic) {
                if (key === field) {
                    customJudgeLogic[key] = value.target.value;
                }
            }
            this.actions.update();
        },
        otherFactorsADD() {
            let { otherFactorsList, otherFactorsCount } = this.privates;
            let data = {
                key: otherFactorsCount + 1,
                type: 1,
                otherFactorSocre: null,
                otherFactor: {
                    judgeStrategy: 1,
                    customJudgeLogic: '',
                    conditionField: []
                }
            };
            let list = otherFactorsList.concat([data]);
            this.privates.otherFactorsList = list;
            this.privates.otherFactorsCount = otherFactorsCount + 1;
            this.actions.update();
        },
        otherSelelctChange(field, value) {
            let { otherFactorsList } = this.privates;
            let list = otherFactorsList.map(item => {
                if (item.key === field) {
                    item.type = value;
                }
                return item;
            });
            this.privates.otherFactorsList = list;
            this.actions.update();
        },
        otherInputChange(field, value) {
            let { otherFactorsList } = this.privates;
            let list = otherFactorsList.map(item => {
                if (item.key === field) {
                    item.otherFactorSocre = value;
                }
                return item;
            });
            this.privates.otherFactorsList = list;
            this.actions.update();
        },
        otherFocDel(field) {
            let { otherFactorsList } = this.privates;
            let list = otherFactorsList.filter(current => current.key !== field);
            this.privates.otherFactorsList = list;
            this.actions.update();
        },
        changeJudgeStrategyOther(field, value) {
            let { otherFactorsList } = this.privates;
            let list = otherFactorsList.map(item => {
                if (item.key === field) {
                    item.otherFactor.judgeStrategy = value;
                }
                return item;
            });
            this.privates.otherFactorsList = list;
            this.actions.update();
        },
        customJudgeLogicOther(field, value) {
            let { otherFactorsList } = this.privates;
            let list = otherFactorsList.map(item => {
                if (item.key === field) {
                    item.otherFactor.customJudgeLogic = value.target.value;
                }
                return item;
            });
            this.privates.otherFactorsList = list;
            this.actions.update();
        },
        typeChange(value) {
            this.privates.calculateType = value.target.value;
            this.actions.update();
        },
        inspactionChange(value) {
            this.privates.sourceValue = value;
            this.privates.judgeStrategy = {
                positiveFactors: 1,
                negativeFactors: 1
            };
            this.privates.customJudgeLogic = {
                positiveFactors: '',
                negativeFactors: ''
            };
            this.privates.conditionJsonInfo = {
                positiveFactors: [],
                negativeFactors: []
            };
            this.privates.otherFactorsList = [];
            this.props.form.setFieldsValue({
                positiveFactorsScore: 0,
                negativeFactorsScore: 0,
                agentChartFieldDto: undefined
            });
            this.actions.update();
        },
        cancel() {
            let routeOptions = {
                history: this.props.history,
                routeName: 'customerServiceIndexManagementIndex'
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        back() {
            let routeOptions = {
                history: this.props.history,
                routeName: 'customerServiceIndexManagementIndex'
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        save() {
            let {
                customJudgeLogic,
                conditionJsonInfo,
                judgeStrategy,
                otherFactorsList,
                chartFieldsList
            } = this.privates;
            let { sdkOptions, location } = this.props;
            let list = otherFactorsList.map(item => {
                return {
                    type: item.type,
                    otherFactorSocre: item.otherFactorSocre,
                    otherFactor: {
                        conditionList: item.otherFactor.conditionField,
                        judgeStrategy: item.otherFactor.judgeStrategy,
                        customJudgeLogic: item.otherFactor.customJudgeLogic
                    }
                };
            });
            this.props.form.validateFields((err, values) => {
                if (!err || this.privates.sysType === 1) {
                    this.privates.loading = true;
                    this.actions.update();
                    let data = {
                        indicatorName: values.indicatorName,
                        indicatorDesc: values.indicatorDesc,
                        activity: values.activity,
                        inspectDataSource: values.inspectDataSource,
                        groupIds: values.groupIds,
                        baseScore: values.baseScore,
                        calculateType: values.calculateType,
                        sysType: this.privates.sysType
                    };
                    if (this.privates.calculateType === 1 || this.privates.calculateType === 2) {
                        data = {
                            ...data,
                            positiveFactors: {
                                conditionList: conditionJsonInfo.positiveFactors,
                                judgeStrategy: judgeStrategy.positiveFactors,
                                customJudgeLogic: customJudgeLogic.positiveFactors
                            },
                            negativeFactors: {
                                conditionList: conditionJsonInfo.negativeFactors,
                                judgeStrategy: judgeStrategy.negativeFactors,
                                customJudgeLogic: customJudgeLogic.negativeFactors
                            },
                            positiveFactorsScore: values.positiveFactorsScore,
                            negativeFactorsScore: values.negativeFactorsScore,
                            otherFactors: list
                        };
                    } else {
                        data = {
                            ...data,
                            sectionCount: values.sectionCount,
                            stepLength: values.stepLength
                        };
                        if (chartFieldsList && chartFieldsList.length > 0) {
                            let obj = chartFieldsList.find(current => current.id === values.agentChartFieldDto);
                            if (obj) {
                                data.agentChartFieldDto = obj;
                            }
                        }
                    }

                    let url = Udesk.business.apiPath.concatApiPath(`agentPictureIndicators`, sdkOptions);
                    if (location.pathname && location.pathname.indexOf('edit/') !== -1) {
                        data.id = location.pathname.split('edit/')[1];
                        url = Udesk.business.apiPath.concatApiPath(`agentPictureIndicators/${data.id}`, sdkOptions);
                        new Promise((resolve, reject) => {
                            Udesk.ajax.put(url, data).then(
                                resp => {
                                    resolve(resp);
                                    this.privates.loading = false;
                                    this.actions.update();
                                    Udesk.ui.notify.success(this.locales.components.common.customFilter.updateSuccess);
                                    let routeOptions = {
                                        history: this.props.history,
                                        routeName: 'customerServiceIndexManagementIndex'
                                    };
                                    Udesk.ui.routing.transitionTo(routeOptions);
                                },
                                reason => {
                                    this.privates.loading = false;
                                    this.actions.update();
                                    Udesk.ui.notify.error(reason.errorMsg);
                                    reject(reason);
                                }
                            );
                        });
                    } else {
                        new Promise((resolve, reject) => {
                            Udesk.ajax.post(url, data).then(
                                resp => {
                                    resolve(resp);
                                    this.privates.loading = false;
                                    this.actions.update();
                                    Udesk.ui.notify.success(this.locales.components.common.customFilter.saveSuccess);
                                    let routeOptions = {
                                        history: this.props.history,
                                        routeName: 'customerServiceIndexManagementIndex'
                                    };
                                    Udesk.ui.routing.transitionTo(routeOptions);
                                },
                                reason => {
                                    this.privates.loading = false;
                                    this.actions.update();
                                    Udesk.ui.notify.error(reason.errorMsg);
                                    reject(reason);
                                }
                            );
                        });
                    }
                }
            });
        },
        // 组织发请求用的筛选数据
        getQueryConditionList(conditionList) {
            let allFieldList = getAllFieldList(this.props, this.privates);
            let queryConditionList = [];
            if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
                queryConditionList = Udesk.utils.object.deepCopy(conditionList);
                if (queryConditionList && queryConditionList.length > 0) {
                    queryConditionList.map(condition => {
                        allFieldList.forEach(field => {
                            if (condition.field === field.id) {
                                condition.field = field;
                            } else {
                                if (field.statusKey && condition.field === field.statusKey) {
                                    condition.field = field;
                                }
                            }
                        });
                        return condition;
                    });
                }
            }
            return queryConditionList;
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.conditionList.forEach(item => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            this.getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach(items => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label + ' ' + obj[item.operator] + ' ' + str.substring(0, str.length - 1)
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName || item.value.agentGroupName || item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <p style={{ marginBottom: '3px' }}>
                        {index + 1}. {item}
                    </p>
                );
            });
        },
        handleConditionCancel() {
            this.privates.isFilterVisible = false;
            this.actions.update();
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy, id, name) {
            let { editField, editKey, otherFactorsList } = this.privates;
            if (editField === 'up') {
                this.privates.conditionJsonInfo.positiveFactors = conditionList;
                this.privates.judgeStrategy.positiveFactors = judgeStrategy;
                this.privates.customJudgeLogic.positiveFactors = customJudgeLogic;
            } else if (editField === 'down') {
                this.privates.conditionJsonInfo.negativeFactors = conditionList;
                this.privates.judgeStrategy.negativeFactors = judgeStrategy;
                this.privates.customJudgeLogic.negativeFactors = customJudgeLogic;
            } else if (editField === 'other') {
                let list =
                    otherFactorsList.map(item => {
                        if (item.key === editKey) {
                            item.otherFactor.judgeStrategy = judgeStrategy;
                            item.otherFactor.customJudgeLogic = customJudgeLogic;
                            item.otherFactor.conditionField = conditionList;
                        }
                        return item;
                    }) || [];
                this.privates.otherFactorsList = list;
            }
            this.privates.isFilterVisible = false;
            this.privates.customName = null;
            this.privates.customId = null;
            if(id){
                this.privates.customName = name;
                this.privates.customId = id;    
            }
            this.actions.update();
        },
        onConditionFilterSaved(flag, data){
            if(data){
                this.privates.customId = data.id;
                this.privates.customName = data.name;
            }
        },
        editFilter(str, radioValue, customInfo, condition) {
            this.privates.isFilterVisible = true;
            this.privates.editField = str;
            this.privates.judgeStrategyList = radioValue || 1;
            this.privates.customJudgeLogicList = customInfo || '';
            this.privates.conditionList = condition || [];
            this.actions.update();
        },
        editFilterOhter(str, key, radioValue, customInfo, condition) {
            this.privates.isFilterVisible = true;
            this.privates.editField = str;
            this.privates.editKey = key;
            this.privates.judgeStrategyList = radioValue || 1;
            this.privates.customJudgeLogicList = customInfo || '';
            this.privates.conditionList = condition || [];
            this.actions.update();
        }
    };
    init() {
        const company = Udesk.data.init.company;
        if (company.enabledCallInspect !== 0 && company.enabledImInspect !== 0) {
            this.privates.detailData.inspectDataSource = 0;
            this.privates.sourceValue = 0;
        } else if (company.enabledCallInspect !== 0) {
            this.privates.detailData.inspectDataSource = 1;
            this.privates.sourceValue = 1;
        } else if (company.enabledImInspect !== 0) {
            this.privates.detailData.inspectDataSource = 2;
            this.privates.sourceValue = 2;
        }
    }
    componentDidMount() { }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncResult, asyncKey } = parseOptions;
        if (asyncKey === 'initTree') {
            if (asyncResult.data) {
                this.privates.initData = asyncResult.data;
                this.actions.initDataMap(this.privates.initData);
            }
        } else if (asyncKey === 'editDetailPromise') {
            if (asyncResult.data) {
                this.privates.detailData = asyncResult.data;
                this.privates.calculateType = asyncResult.data.calculateType;
                this.privates.sysType = asyncResult.data.sysType;
                if (asyncResult.data.positiveFactors) {
                    this.privates.judgeStrategy.positiveFactors = asyncResult.data.positiveFactors.judgeStrategy;
                    if (
                        asyncResult.data.positiveFactors.conditionList &&
                        asyncResult.data.positiveFactors.conditionList.length > 0
                    ) {
                        this.privates.conditionJsonInfo.positiveFactors = asyncResult.data.positiveFactors.conditionList || [];

                    }

                    this.privates.customJudgeLogic.positiveFactors = asyncResult.data.positiveFactors.customJudgeLogic;
                }
                if (asyncResult.data.negativeFactors) {
                    this.privates.judgeStrategy.negativeFactors = asyncResult.data.negativeFactors.judgeStrategy;
                    if (
                        asyncResult.data.negativeFactors.conditionList &&
                        asyncResult.data.negativeFactors.conditionList.length > 0
                    ) {
                        this.privates.conditionJsonInfo.negativeFactors = asyncResult.data.negativeFactors.conditionList || [];

                    }
                    this.privates.customJudgeLogic.negativeFactors = asyncResult.data.negativeFactors.customJudgeLogic;
                }
                if (asyncResult.data.otherFactors) {
                    this.privates.otherFactorsList = asyncResult.data.otherFactors.map((item, index) => {
                        return {
                            ...item,
                            otherFactor: {
                                ...item.otherFactor,
                                conditionField: item.otherFactor.conditionList
                            },
                            key: this.privates.otherFactorsCount + index
                        };
                    });
                    this.privates.otherFactorsCount = asyncResult.data.otherFactors.length + 1;
                }
            }
        }
        this.actions.update();
    }
    getOptionValue(id, optionValueList) {
        let value = optionValueList.filter(item => {
            if (Array.isArray(id)) {
                return id.includes(item.id);
            } else {
                return item.id === id;
            }
        });
        if (value.length > 0) {
            value = value.map(item => {
                return item.name;
            });
            return value.join(',');
        } else {
            return '';
        }
    }
}
function getAllFieldList(props, privates) {
    let { fieldList } = privates;
    let { sdkOptions } = props;

    let allFieldList = [];

    if (fieldList && fieldList.length > 0) {
        allFieldList = fieldList;
    } else if (sdkOptions.props.fields && sdkOptions.props.fields.length > 0) {
        allFieldList = sdkOptions.props.fields;
    }

    return allFieldList;
}
export default IndexManagementDetailComponent;
