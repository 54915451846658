import React, { useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import styled from 'styled-components';
import { Button, Empty, Space, Spin, Tag } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

type TaskCardProps = {
    taskName: string;
    courseType: 1 | 2 | 3 | 4;
    deadLineTime?: string;
    completeByTimes: number | null; // 达标按次数，为null则不已此为标准
    completeByScore: number | null; // 达标按得分,为null则不已此为标准
    highestScore: number | null; // 最高得分
    exerciseTimes: number | null; // 练习次数
    dialogueMode: number;
    taskId: number;
    onCardClick?: (dialogueMode: number, taskId: number) => void;
};

type TaskCardListProps = {
    tasks?: TaskCardProps[];
    title: string;
    style?: React.CSSProperties;
    loading?: boolean;
    onCardClick?: (dialogueMode: number, taskId: number) => void;
};

const Card = styled.div`
    /* border: 1px solid red; */
    /* height: 67px;
    width: 206px; */
    background: #f5f8fe;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 3px;
    overflow: hidden;
    user-select: none;
    cursor: pointer;

    .coach-task-card-content {
        padding: 8px 8px 4px;
        min-height: 76px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .coach-task-card-content-task-title {
            display: flex;
            justify-content: space-between;
        }
        .coach-task-card-content-task-name {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            width: 0;
            margin-right: 8px;
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .coach-task-card-content-task-deadline {
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.45);
            display: flex;
            justify-content: space-between;
        }
    }
`;

export const TaskCard: React.FC<TaskCardProps> = ({
    taskName,
    deadLineTime: deadline,
    courseType,
    completeByTimes, // 达标按次数，为null则不已此为标准
    completeByScore, // 达标按得分,为null则不已此为标准
    highestScore, // 最高得分
    exerciseTimes, // 练习次数
    onCardClick,
    dialogueMode,
    taskId,
}) => {
    const canvasInstance = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        const ctx = canvasInstance.current!.getContext('2d');
        if (ctx) {
            // 背景色
            ctx.beginPath();
            ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
            ctx.fillRect(0, 0, 200, 4);
            // 进度百分比
            ctx.beginPath();
            ctx.fillStyle = '#1A6EFF';
            const r =
                (completeByTimes
                    ? exerciseTimes! / completeByTimes
                    : highestScore! / completeByScore!) * 200;

            ctx.fillRect(0, 0, r, 4);
        }
    }, []);
    const _courseType = Udesk.enums.lessonType.find((t) => t.id === courseType);
    return (
        <Card className="coach-task-card">
            <div
                className="coach-task-card-content"
                title={taskName}
                onClick={onCardClick?.bind(null, dialogueMode, taskId)}
            >
                <div className="coach-task-card-content-task-title">
                    <div className="coach-task-card-content-task-name">{taskName}</div>
                    <Tag
                        color={_courseType.color}
                        style={{
                            color: _courseType.color,
                            backgroundColor: `${_courseType.color}1a`,
                        }}
                    >
                        {_courseType.name}
                    </Tag>
                </div>
                <div className="coach-task-card-content-task-deadline">
                    <div
                        style={{
                            flex: '1',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginRight: '4px',
                        }}
                    >
                        {deadline ? `${/* 截止： */UdeskLocales['current'].pages.coach.dashboard.components.taskCard.end}${deadline}` : /* 无截止日期 */UdeskLocales['current'].pages.coach.dashboard.components.taskCard.noDeadline}
                    </div>
                    <div>
                        {completeByTimes
                            ? `${exerciseTimes}/${completeByTimes}${/* 次 */UdeskLocales['current'].pages.coach.dashboard.components.taskCard.second}`
                            : `${highestScore}/${completeByScore}${/* 分 */UdeskLocales['current'].pages.coach.dashboard.components.taskCard.branch}`}
                    </div>
                </div>
                <canvas ref={canvasInstance} width="200px" height="4px"></canvas>
            </div>
        </Card>
    );
};

const Title = ({ title, nextPage, prevPage }) => {
    return (
        <div className="ongoing-tasks-title">
            <div>{title}</div>
            <Space>
                <Button size="small" onClick={prevPage}>
                    {'<'}
                </Button>
                <Button size="small" onClick={nextPage}>
                    {'>'}
                </Button>
            </Space>
        </div>
    );
};

const ListWrap = styled.div`
    overflow-x: hidden;
    scroll-behavior: smooth;

    .udesk-qa-ui-empty {
        height: 76px;
        margin: 0;
    }
`;

export const TaskCardList: React.FC<TaskCardListProps> = ({
    tasks,
    title,
    style,
    loading,
    onCardClick,
}) => {
    const { ref, ...titleProps } = useTaskCardListScroll();

    return (
        <div style={style}>
            <Title title={title} {...titleProps} />
            <Spin spinning={loading}>
                <ListWrap ref={ref}>
                    {Array.isArray(tasks) ? (
                        <Space>
                            {tasks.map((t) => (
                                <TaskCard {...t} onCardClick={onCardClick} />
                            ))}
                        </Space>
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </ListWrap>
            </Spin>
        </div>
    );
};

function useTaskCardListScroll(step = 224) {
    const listRef = useRef<HTMLDivElement>(null);
    const nextPage = () => {
        if (listRef.current) {
            listRef.current.scrollLeft += step;
        }
    };
    const prevPage = () => {
        if (listRef.current) {
            listRef.current.scrollLeft -= step;
        }
    };
    return {
        ref: listRef,
        nextPage,
        prevPage,
    };
}
