import Udesk from 'Udesk';
import React from 'react';
import './style.scss';
import ReactModal from 'udesk-react/src/components/react-modal';
import { Button, Input, Pagination, PageHeader, Select, Space } from 'udesk-ui';
import ConceptWordRoute from './route';
import AutoSizeTable from 'Component/common/auto-size-table';
import ExportButton from 'Component/common/export-button';
import BatchDeleteButton from 'Component/common/batch-delete-btn';

const { Search } = Input;
const { Option } = Select;

export default class ConceptWordTemplate extends ConceptWordRoute {
    render() {
        let { actions, locales } = this;
        let {
            searchInput,
            tableColumns,
            tableData,
            pageSize,
            pageNum,
            total,
            file,
            isUploadVisible,
            loading,
            selectedItems,
            moduleType,
        } = this.privates;
        return (
            // <div className="concept-word-index-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className='udesk-qa-web-page-header'
                    title={locales.components.pages.conceptWord.title}
                    extra={
                        Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:conceptWords:add')
                            ? [
                                <Button key={'conceptWords'} type='primary' onClick={actions.linkToNew}>
                                    {locales.components.pages.conceptWord.new}
                                </Button>,
                            ]
                            : []
                    }
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root concept-word-index-page'>
                        <div className='concept-word-index-page-content'>
                            <div className='concept-word-index-page-content-contol'>
                                <Space>
                                    <Search
                                        placeholder={locales.components.pages.conceptWord.control.searchPlaceholder}
                                        onSearch={actions.search}
                                        onChange={actions.searchInputChange}
                                        value={searchInput}
                                        style={{ width: 200, marginRight: '8px' }}
                                    />
                                    <div>
                                        {locales.fields.semanticTags.orgName}:
                                        <Select value={moduleType} onChange={actions.moduleTypeChange} style={{ width: 120 }}>
                                            <Option value={null}>{locales.labels.all}</Option>
                                            {Udesk.enums.organizationTypes.map(item => {
                                                return <Option value={item.id}>{item.name}</Option>;
                                            })}
                                        </Select>
                                    </div>
                                    <Button type='primary' onClick={actions.search}>
                                        {locales.components.pages.conceptWord.control.commit}
                                    </Button>
                                    <Button onClick={actions.reset}>
                                        {locales.components.pages.conceptWord.control.reset}
                                    </Button>
                                </Space>
                                <div className='concept-word-index-page-content-control-right'>
                                    <If
                                        condition={
                                            Udesk.data.init.user &&
                                            Udesk.data.init.user.hasFeature('semantic:conceptWords:delete')
                                        }
                                    >
                                        <BatchDeleteButton
                                            deleteItemsCount={selectedItems.length}
                                            // failedCount={0}
                                            onDelete={actions.batchDelete}
                                        />
                                    </If>
                                    <If
                                        condition={
                                            Udesk.data.init.user &&
                                            Udesk.data.init.user.hasFeature('semantic:conceptWords:import')
                                        }
                                    >
                                        <Button onClick={actions.import}>
                                            {locales.components.pages.conceptWord.control.import}
                                        </Button>
                                    </If>
                                    <If
                                        condition={
                                            Udesk.data.init.user &&
                                            Udesk.data.init.user.hasFeature('semantic:conceptWords:export')
                                        }
                                    >
                                        <ExportButton
                                            disabled={loading}
                                            exportDataCount={total}
                                            hasFilterCondition={true}
                                            onClick={actions.export}
                                        />
                                    </If>
                                </div>
                            </div>
                            <div className='concept-word-index-page-content-table'>
                                <AutoSizeTable
                                    loading={loading}
                                    subtractedHeight={144}
                                    columns={tableColumns}
                                    dataSource={tableData}
                                    pagination={false}
                                    onChange={actions.tableChangeHandle}
                                    rowSelection={{
                                        type: 'checkbox',
                                        onSelect: actions.onCheckChange,
                                        onSelectAll: actions.onSelectAll,
                                        selectedRowKeys: selectedItems.map((i) => i.id),
                                    }}
                                />
                                <div
                                    className='concept-word-index-page-table-size-changer'
                                    style={{ display: tableData.length === 0 ? 'none' : 'block' }}
                                >
                                    <div className='concept-word-index-page-table-size-changer-text'>
                                        {locales.components.pages.conceptWord.table.sizeChangeText}
                                    </div>
                                    <Pagination
                                        showSizeChanger
                                        current={pageNum}
                                        pageSize={pageSize}
                                        showTotal={actions.showTotal}
                                        pageSizeOptions={['5', '10', '20', '50', '100']}
                                        onShowSizeChange={actions.onShowSizeChange}
                                        defaultCurrent={1}
                                        total={total}
                                        onChange={actions.pageChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save}
                            visible={isUploadVisible}
                            title={locales.components.pages.conceptWord.modal.title}
                            closeIconClass='udesk-qc-iconfont icon-qc-quxiao pull-right'
                            onCancel={actions.changeUploadVisible}
                            onOk={actions.importConceptWords}
                            headerClassName='udesk-qa-modal-default-header'
                            footerClassName='udesk-qa-modal-default-footer'
                        >
                            <div className='udesk-qa-modal-body-content'>
                                <div className='form-group'>
                                    <label>
                                        {locales.labels.uploadFile}
                                        {locales.labels.colon}
                                    </label>
                                    <div className='upload-file-button-container'>
                                        <input
                                            type='file'
                                            className='upload-file-inpiut'
                                            onChange={actions.uploadFile}
                                        />
                                        <button type='button' className='upload-file-button btn btn-sm btn-primary'>
                                            {locales.labels.upload}
                                        </button>
                                    </div>
                                    <If condition={file && file.name}>
                                        <span className='upload-file-name'>{file.name}</span>
                                    </If>
                                </div>
                                <div className='form-group'>
                                    {locales.labels.numOne}
                                    {locales.labels.colon}&nbsp;&nbsp;
                                    {locales.labels.click}&nbsp;&nbsp;
                                    <span className='upload-file-template' onClick={actions.downloadTemplate}>
                                        {locales.labels.downloadTemplate}
                                    </span>
                                    &nbsp;&nbsp;
                                    {locales.labels.fillInTheForm}
                                </div>
                                <div className='form-group'>
                                    {locales.labels.numTwo}
                                    {locales.labels.colon}&nbsp;&nbsp;
                                    {locales.labels.UploadExcelfile}
                                </div>
                            </div>
                        </ReactModal>
                    </div>
                </div>
            </div>
        );
    }
}
