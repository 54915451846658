import React from 'react';
import Udesk from 'Udesk';
import './style.scss';
import { Form, Input, Select } from '@alifd/next';
import ReactSelect from 'udesk-react/src/components/react-select';
import IntelligentTagsDetailRoute from './route';
import TagsCategories from '../../../components/tag-categories';
import _isEmpty from 'lodash-es/isEmpty';
import _map from 'lodash-es/map';
import { Icon, Radio, Tabs, Input as InputUdesk, Button, Tooltip, TreeSelect } from 'udesk-ui';
import ClassNames from 'classnames';
import UdeskLocales from 'UdeskLocales';
import ConditionListGather from 'Component/pages/components/check-point-condition-list-gather';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Page from 'Component/common/udesk-qa-page-layout';
import Recommendation from '../../supervision/detail/recommendation';
import { isSalesSubApp } from 'Udesk/system/subApp';

const { TabPane } = Tabs;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        fixedSpan: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const grid = 8;
const getListStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});
const getListDropStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    width: '100%',
    border: '1px solid #e8e8e8',
    minHeight: '40px',
});
// const getListDropStyles = () => ({
//     background: '#fff',
//     display: 'flex',
//     padding: grid,
//     overflow: 'auto',
//     minWidth: "400px",
//     border: '1px solid #e8e8e8',
//     minHeight: '40px'
// });
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    width: '32px',
    height: '24px',
    color: 'rgba(0,0,0,0.65)',
    borderRadius: '3px',
    margin: `0 ${grid}px 0 0 `,
    textAlign: 'center',
    lineHeight: '24px',
    // 拖拽的时候背景变化
    background: isDragging ? 'lightgreen' : 'rgba(232,244,255,1)',
    marginBottom: '3px',
    ...draggableStyle,
});
export default class IntelligentTagsDetailTemplate extends IntelligentTagsDetailRoute {
    render() {
        let {
            // id,
            activeFlags,
            tagName,
            tagDesc,
            tagStatus,
            smartTagCategorys,
            categoryId,
            isClassificationVisible,
            labelTypes,
            tagType,
            // grounpValueList,
            // grounpValue,
            treeDataList,
            labelActive,
            addLevel,
            labelParent,
            hitData,
            idState,
            treeDataRef,
            labelRef,
            conditionList,
            loading,
            funcType,
            earlyWarning,
            earlyWarningPersonList,
            warningTreeData,
            smartRecommendStatus,
            knowledgeBases,
            libraryFiles,
        } = this.privates;
        let { actions, locales, privates } = this;

        return (
            // <div className="intelligent-tags-detail-page">
            <Page
                pageBodyClassName='intelligent-tags-detail-page-body'
                title={
                    privates.isEdit
                        ? locales.components.pages.intelligentTags.detail.edit
                        : locales.components.pages.intelligentTags.detail.new
                }
                // padding={true}
                onBack={actions.backToIndex}
            >
                <Form style={{ width: '100%' }} {...formItemLayout} onValuesChange={actions.onFormValuesChange}>
                    <FormItem
                        required
                        requiredMessage={/* 名称是必填字段 */locales.pages.intelligentAnalysis.intelligentTags.detail.template.nameIsARequiredField}
                        label={locales.components.pages.intelligentTags.detail.tagName}
                    >
                        <Input
                            style={{ width: 300 }}
                            name='name'
                            value={tagName}
                            maxLength={100}
                            maxCount={100}
                            showCount={true}
                            onChange={actions.changeTagName}
                        />
                    </FormItem>
                    <FormItem label={locales.components.pages.intelligentTags.detail.tagDesc}>
                        <Input
                            style={{ width: 300 }}
                            name='tagDesc'
                            value={tagDesc}
                            maxLength={100}
                            maxCount={100}
                            showCount={true}
                            onChange={actions.changeTagDesc}
                        />
                    </FormItem>
                    {['CUSTOMER_TAG', 'REVIEW_CALL', 'REVIEW_KEY_EVENT'].includes(funcType) ? null : (
                        <FormItem
                            label={
                                <div>
                                    {locales.components.pages.intelligentTags.detail.labelClassAtion}
                                    <Tooltip
                                        title={
                                            <div>
                                                <div>
                                                    {
                                                        locales.components.pages.intelligentTags.detail
                                                            .labelClassAtionTip1
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        locales.components.pages.intelligentTags.detail
                                                            .labelClassAtionTip2
                                                    }
                                                </div>
                                            </div>
                                        }
                                    >
                                        <Icon
                                            type='QuestionCircleOutlined'
                                            antdIcon={true}
                                            style={{ marginLeft: 4 }}
                                        ></Icon>
                                    </Tooltip>
                                </div>
                            }
                        >
                            <Select
                                name='labelClassAtion'
                                disabled={this.props.match.params.id}
                                value={tagType}
                                dataSource={labelTypes}
                                onChange={actions.labelChanged}
                            />
                        </FormItem>
                    )}
                    <FormItem label={locales.components.pages.intelligentTags.detail.tagStatus}>
                        <Select
                            name='tagStatus'
                            value={tagStatus}
                            dataSource={activeFlags}
                            onChange={actions.tagStatusChanged}
                        />
                    </FormItem>
                    <If condition={tagType === Udesk.enums.labelTypes.dimensionLabel.id}>
                        <FormItem label={locales.components.pages.intelligentTags.detail.category}>
                            <ReactSelect
                                classNames='intelligent-tags-detail-page-body-select udesk-qc-react-select'
                                value={categoryId}
                                dataScource={smartTagCategorys}
                                hasNullSelect={false}
                                nullSelectPlaceholder={
                                    locales.components.pages.intelligentTags.index.selectTagClassification
                                }
                                onChanged={actions.changeTagClassification}
                                nameField='categoryName'
                            />
                            <If
                                condition={
                                    Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:tag:editCategory')
                                }
                            >
                                <span
                                    className='intelligent-tags-detail-page-body-edit-action'
                                    onClick={actions.changeClassificationModalVisible}
                                >
                                    {locales.components.pages.intelligentTags.index.editTagGrounp}
                                </span>
                            </If>
                        </FormItem>

                        {/* <FormItem label={locales.components.pages.intelligentTags.detail.operator}>
                                    <OperatorListGather operatorList={operatorList} onChanged={actions.getOperatorList} createSemanticTag={actions.createSemanticTag} editSemanticTag={actions.editSemanticTag} />
                                </FormItem> */}
                        <FormItem label={locales.components.pages.intelligentTags.detail.operator}>
                            <ConditionListGather
                                {...this.props}
                                {
                                    ... {
                                        type: [0],
                                        ruglar: true,
                                        isNewAssociate: 1,
                                        isAutoAssociateDateSource: isSalesSubApp() ? 0 : 1,
                                        onChange: actions.getConditionList,
                                        replenishOperatorTypes(showList) {
                                            const index = showList.findIndex((item) => item.id === 13);
                                            if (index > -1) {
                                                showList.splice(index, 1, Udesk.enums.operatorTypes.associate);
                                            }
                                        },
                                        replenishConditionTypes(showList, list) {
                                            isSalesSubApp(() => {
                                                showList.push(list[3]); //添加信息模块
                                            });
                                        }
                                    }
                                }
                                conditionList={conditionList}
                                funcType={funcType}
                            ></ConditionListGather>
                        </FormItem>

                        <FormItem label={locales.components.pages.intelligentTags.detail.rule}>
                            {/* <RadioBtn classNames="intelligent-tags-detail-page-radios" itemClassNames="intelligent-tags-detail-page-radio-item" options={Udesk.enums.operatorRuleTypes} value={compositeType} onChanged={actions.changeCompositeType} />
                                    <If condition={compositeType === Udesk.enums.operatorRuleTypes.custom.id}>
                                        <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} />
                                    </If> */}
                            <If condition={tagType === Udesk.enums.labelTypes.dimensionLabel.id}>
                                <div className='tasks-manage-template-configuration-detail-logic-rule'>
                                    <Radio.Group onChange={actions.onGradeTypeChange} value={privates.compositeType}>
                                        <Radio value={1}>
                                            {
                                                UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                                    .configuration.logic.all
                                            }
                                        </Radio>
                                        <Radio value={2}>
                                            {
                                                UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                                    .configuration.logic.any
                                            }
                                        </Radio>
                                        <Radio value={3}>
                                            {
                                                UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                                    .configuration.logic.customize
                                            }
                                        </Radio>
                                    </Radio.Group>
                                    <If condition={privates.compositeType === 3}>
                                        {/* <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} /> */}
                                        <Tabs defaultActiveKey='2'>
                                            <TabPane
                                                tab={
                                                    UdeskLocales.current.components.common.customFilter
                                                        .manualConfiguration
                                                }
                                                key='1'
                                            >
                                                <InputUdesk
                                                    placeholder={
                                                        UdeskLocales.current.components.common.customFilter
                                                            .holderCustomRule
                                                    }
                                                    value={privates.operatorLogic}
                                                    onChange={actions.customJudgeLogicChange}
                                                />
                                                {/* <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} /> */}
                                            </TabPane>
                                            <TabPane
                                                tab={
                                                    UdeskLocales.current.components.common.customFilter
                                                        .dragConfiguration
                                                }
                                                key='2'
                                            >
                                                <DragDropContext
                                                    onDragEnd={actions.onDragEnd}
                                                    onDragStart={actions.onDragStart}
                                                >
                                                    <div className='data-preprocess-custom'>
                                                        <div className='data-preprocess-custom-drap-title'>
                                                            {
                                                                UdeskLocales.current.components.common.customFilter
                                                                    .condition
                                                            }
                                                            ：
                                                        </div>
                                                        <div className='data-preprocess-cutom-drap-info'>
                                                            <Droppable
                                                                droppableId='droppables'
                                                                direction='horizontal'
                                                                isDropDisabled={true}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                                    >
                                                                        {privates.conditionListFilter.map(
                                                                            (item, index) => (
                                                                                <Draggable
                                                                                    key={item.id}
                                                                                    draggableId={item.id}
                                                                                    index={index}
                                                                                >
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps
                                                                                                    .style
                                                                                            )}
                                                                                        >
                                                                                            {item.content}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            )
                                                                        )}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </div>
                                                    </div>
                                                    <div className='data-preprocess-custom'>
                                                        <div className='data-preprocess-custom-drap-title'>
                                                            {
                                                                UdeskLocales.current.components.common.customFilter
                                                                    .condition
                                                            }
                                                            ：
                                                        </div>
                                                        <div className='data-preprocess-cutom-drap-info'>
                                                            <Droppable
                                                                droppableId='droppabless'
                                                                direction='horizontal'
                                                                isDropDisabled={true}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDragging)}
                                                                    >
                                                                        {privates.logicList
                                                                            .filter((item) => item.flag)
                                                                            .map((item, index) => (
                                                                                <Draggable
                                                                                    key={item.id}
                                                                                    draggableId={item.id}
                                                                                    index={index}
                                                                                >
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps
                                                                                                    .style
                                                                                            )}
                                                                                        >
                                                                                            {item.content}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </div>
                                                    </div>
                                                    <div className='data-preprocess-custom'>
                                                        {privates.tarskDel && (
                                                            <div className='index-manmagement-custom-drag-tarsk'>
                                                                {
                                                                    UdeskLocales.current.components.common.customFilter
                                                                        .infoDel
                                                                }
                                                            </div>
                                                        )}
                                                        <div className='data-preprocess-custom-drap-title'>
                                                            {
                                                                UdeskLocales.current.components.common.customFilter
                                                                    .toConfigure
                                                            }
                                                            ：
                                                        </div>
                                                        <div className='data-preprocess-cutom-drap-info'>
                                                            <Droppable droppableId='droppable' direction='horizontal'>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        style={getListDropStyle(
                                                                            snapshot.isDraggingOver
                                                                        )}
                                                                    >
                                                                        {this.privates.items.map((item, index) => {
                                                                            return (
                                                                                <Draggable
                                                                                    key={item.id}
                                                                                    draggableId={item.id}
                                                                                    index={index}
                                                                                >
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps
                                                                                                    .style
                                                                                            )}
                                                                                        >
                                                                                            {item.content}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            );
                                                                        })}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </div>
                                                    </div>
                                                </DragDropContext>
                                            </TabPane>
                                        </Tabs>
                                    </If>
                                </div>
                            </If>
                        </FormItem>
                    </If>
                    <If condition={tagType === Udesk.enums.labelTypes.classificationLabel.id}>
                        {/* <FormItem label={locales.components.pages.intelligentTags.detail.group}>
                                    <Select name="grounp" value={grounpValue} dataSource={grounpValueList} />
                                </FormItem> */}
                        <FormItem label={locales.components.pages.intelligentTags.detail.group}>
                            <ReactSelect
                                classNames='intelligent-tags-detail-page-body-select udesk-qc-react-select'
                                value={categoryId}
                                dataScource={smartTagCategorys}
                                hasNullSelect={false}
                                nullSelectPlaceholder={
                                    locales.components.pages.intelligentTags.index.selectTagClassification
                                }
                                onChanged={actions.changeTagClassification}
                                nameField='categoryName'
                            />
                            <If
                                condition={
                                    Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:tag:editCategory')
                                }
                            >
                                <span
                                    className='intelligent-tags-detail-page-body-edit-action'
                                    onClick={actions.changeClassificationModalVisible}
                                >
                                    {locales.components.pages.intelligentTags.index.editTagClassification}
                                </span>
                            </If>
                        </FormItem>
                        <FormItem label={locales.components.pages.intelligentTags.detail.checkLabel}>
                            <div className='label-tabs-list-page' ref={labelRef}>
                                {!_isEmpty(treeDataList) &&
                                    _map(treeDataList, (item, index) => {
                                        if (item) {
                                            return (
                                                <div
                                                    className='label-tabs-list-page-field'
                                                    key={index}
                                                    style={{ width: '190px' }}
                                                    ref={treeDataRef[`tree-${index}`]}
                                                    onMouseUp={actions.fieldUp}
                                                >
                                                    <div
                                                        className='label-field-target-drag'
                                                        onMouseDown={actions.treeDrag.params(
                                                            treeDataRef[`tree-${index}`]
                                                        )}
                                                    >
                                                        <Icon type='ic-pause-zanting' />
                                                    </div>
                                                    <div className='label-tabs-list-page-field-drag'>
                                                        {!_isEmpty(item) &&
                                                            item.map((items) => {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                items.id === idState ? 'none' : 'flex',
                                                                        }}
                                                                        className={ClassNames(
                                                                            'label-field-info',
                                                                            {
                                                                                'label-field-info-active-parent':
                                                                                    labelParent[items.tagLevel] ===
                                                                                    items.id ||
                                                                                    hitData[items.tagLevel] ===
                                                                                    items.id,
                                                                            },
                                                                            {
                                                                                'label-field-info-active':
                                                                                    items.id === labelActive,
                                                                            }
                                                                        )}
                                                                        key={items.id}
                                                                        onClick={actions.activesLabel.params(
                                                                            items.id,
                                                                            items.tagLevel,
                                                                            items.leafFlag
                                                                        )}
                                                                    >
                                                                        <span
                                                                            className='label-field-info-field'
                                                                            title={items.tagName}
                                                                        >
                                                                            {items.tagName}
                                                                        </span>
                                                                        {items.leafFlag !== 1 && (
                                                                            <Icon type='ic-play-line' />
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        {index === addLevel && (
                                                            <div className='label-field-info' key={'add-tagId'}>
                                                                <span>{tagName}</span>
                                                                <Icon type='quxiao' onClick={actions.deleteLevel} />
                                                            </div>
                                                        )}
                                                        {item.length < 20 && index !== addLevel && (
                                                            <div
                                                                className='label-field-info-add'
                                                                onClick={actions.labelFieldAdd.params(index)}
                                                            >
                                                                {this.props.match.params.id ? (
                                                                    <span>
                                                                        {' '}
                                                                        + {locales.components.common.labelTabs.addThis}
                                                                    </span>
                                                                ) : (
                                                                    <span>
                                                                        {' '}
                                                                        + {locales.components.common.labelTabs.goThis}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return;
                                        }
                                    })}
                            </div>
                        </FormItem>
                    </If>

                    {/* <FormItem label=" ">
                                <Form.Reset type="normal" onClick={actions.backToIndex}>
                                    {locales.labels.cancel}
                                </Form.Reset>
                                <If condition={tagType === Udesk.enums.labelTypes.classificationLabel.id}>
                                    &nbsp;
                                    <Form.Submit type="primary" validate={["tagName"]} loading={loading} onClick={actions.saveIntelligentTagTo}>
                                        {locales.components.common.labelTabs.saveGo}
                                    </Form.Submit>
                                </If>
                                &nbsp;
                                <Form.Submit type="primary" validate={["tagName"]} loading={loading} onClick={actions.saveIntelligentTag}>
                                    {locales.labels.save}
                                </Form.Submit>
                            </FormItem> 
                    */}

                    {['REVIEW_KEY_EVENT'].includes(funcType) && (
                        <Form.Item
                            label={
                                <div>
                                    {locales.components.pages.intelligentTags.detail.warn}
                                    <Tooltip
                                        title={locales.components.pages.intelligentTags.detail.warnTips}
                                    >
                                        <Icon
                                            type='QuestionCircleOutlined'
                                            antdIcon={true}
                                            style={{ marginLeft: 4 }}
                                        ></Icon>
                                    </Tooltip>
                                </div>
                            }
                            name='earlyWarning'
                            className='warn-form-item-box'
                        >
                            <Radio.Group
                                size='small'
                                buttonStyle='solid'
                                value={earlyWarning}
                                onChange={actions.onEarlyWarningChange}
                            >
                                <Radio.Button value={1}>{locales.labels.switchOn}</Radio.Button>
                                <Radio.Button value={0}>{locales.labels.switchOff}</Radio.Button>
                            </Radio.Group>
                            {earlyWarning ?
                                <TreeSelect
                                    style={{ flex: 1, marginLeft: '8px' }}
                                    size='small'
                                    multiple
                                    onChange={actions.handlerEarlyWarningTreeChange}
                                    treeData={warningTreeData}
                                    value={earlyWarningPersonList}
                                    showSearch
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder={locales.labels.pleaseSelect}
                                    allowClear
                                    treeNodeFilterProp='title'
                                    treeDefaultExpandAll
                                    treeCheckable={true}
                                ></TreeSelect>
                                : null
                            }
                        </Form.Item>
                    )}

                    {['REVIEW_CALL', 'REVIEW_KEY_EVENT'].includes(funcType) && (
                        <Recommendation
                            type={1}
                            value={smartRecommendStatus}
                            knowledgeBases={knowledgeBases}
                            libraryFiles={libraryFiles}
                            formItemLayout={formItemLayout}
                            onChange={(value, attr) => {
                                this.privates[attr] = value;
                                this.actions.update();
                            }}
                        />
                    )}

                    <FormItem label=' '>
                        <Button type='normal' style={{ marginRight: 8 }} onClick={actions.backToIndex}>
                            {locales.labels.cancel}
                        </Button>
                        <If condition={tagType === Udesk.enums.labelTypes.classificationLabel.id}>
                            <Button
                                type='primary'
                                style={{ marginRight: 8 }}
                                validate={['tagName']}
                                loading={loading}
                                onClick={actions.saveIntelligentTagTo}
                            >
                                {locales.components.common.labelTabs.saveGo}
                            </Button>
                        </If>
                        <Button
                            type='primary'
                            validate={['tagName']}
                            loading={loading}
                            onClick={actions.saveIntelligentTag}
                        >
                            {locales.labels.save}
                        </Button>
                    </FormItem>
                </Form>

                <TagsCategories
                    funcType={funcType}
                    smartTagCategorys={smartTagCategorys}
                    isClassificationVisible={isClassificationVisible}
                    deleteItem={actions.deleteClassification}
                    onSave={actions.saveClassification}
                    onCancel={actions.changeClassificationModalVisible}
                />
            </Page>
        );
    }
}
