import Udesk from 'Udesk';
import IntelligentTagsDetailComponent from './component';
import _isEmpty from 'lodash-es/isEmpty';

class IntelligentTagsDetailRoute extends IntelligentTagsDetailComponent {
    asyncModel() {
        let { match, sdkOptions } = this.props;

        let smartTagPromise;
        if (match.params.id) {
            let url = Udesk.business.apiPath.concatApiPath(`smartTags/${match.params.id}?funcType=${this.privates.funcType}`, sdkOptions);
            smartTagPromise = new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        } else {
            smartTagPromise = new Promise((resolve, reject) => {
                resolve({});
            });
        }

        let url2 = Udesk.business.apiPath.concatApiPath(`smartTagCategorys?funcType=${this.privates.funcType}`, sdkOptions);
        let smartTagCategorysPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url2).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            smartTag: smartTagPromise,
            smartTagCategorys: smartTagCategorysPromise,
            // dataList
        };
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        if (asyncKey === 'smartTag') {
            if (!_isEmpty(asyncModel.smartTag)) {
                this.privates.id = asyncModel.smartTag.id;
                this.privates.tagName = asyncModel.smartTag.tagName;
                this.privates.tagDesc = asyncModel.smartTag.tagDesc;
                this.privates.categoryId = asyncModel.smartTag.categoryId;
                this.privates.addLevel = asyncModel.smartTag.tagLevel;
                this.privates.tagType = asyncModel.smartTag.tagType;

                this.privates.smartRecommendStatus = asyncModel.smartTag.smartRecommendStatus;
                this.privates.earlyWarning = asyncModel.smartTag.earlyWarning || 0;
                this.privates.earlyWarningPersonList = (asyncModel.smartTag.earlyWarningPersonList || []).map(id => id + '_userId');
                this.privates.libraryFiles = asyncModel.smartTag.libraryFiles 
                    ? asyncModel.smartTag.libraryFiles.split(',').map(id => +id)
                    : [];
                this.privates.knowledgeBases = asyncModel.smartTag.knowledgeBases
                    ? asyncModel.smartTag.knowledgeBases.split(',').map(id => +id) 
                    : [];

                if (this.privates.categoryId && asyncModel.smartTag.tagType === 2) {
                    this.actions.getTagTreeInit(this.privates.categoryId, 2, this.privates.id);
                }
                if (asyncModel.smartTag.tagStatus != null) this.privates.tagStatus = asyncModel.smartTag.tagStatus;
                if (asyncModel.smartTag.conditionList && asyncModel.smartTag.conditionList.length > 0) {
                    this.privates.conditionList = asyncModel.smartTag.conditionList;
                    this.privates.conditionList.forEach((condition, index) => {
                        if (condition.operatorList) {
                            this.privates.conditionList[index].operatorList = condition.operatorList;
                        } else if (condition.taskOperatorList) {
                            this.privates.conditionList[index].operatorList = condition.taskOperatorList;
                        }
                    });
                }
                if (asyncModel.smartTag.ruleList && asyncModel.smartTag.ruleList.length > 0) {
                    this.privates.compositeType = asyncModel.smartTag.ruleList[0].judgeStrategy;
                    this.privates.ruleList = asyncModel.smartTag.ruleList;
                    this.privates.ruleList && this.actions.getOperatorList(this.privates.ruleList);
                    this.privates.operatorLogic = asyncModel.smartTag.ruleList[0].formula;
                    if (this.privates.compositeType === 3) {
                        this.privates.operatorLogic = this.privates.ruleList[0].formula;
                        this.privates.items = [];
                        let list = this.privates.ruleList[0].formula.split(/(or|and|not|&&|\|\||!|\(|\))/);
                        this.privates.itemsCount = list.length;
                        list.forEach((item, index) => {
                            item = item.trim();
                            if (item && item.length > 0) {
                                let obj = this.privates.logicList.find((current) => current.value === item);
                                let objs = this.privates.conditionListFilter.find((current) => current.value === item);
                                if (obj) {
                                    this.privates.items = [
                                        ...this.privates.items,
                                        {
                                            id: `drag-${index}`,
                                            content: obj.content,
                                            value: obj.value,
                                            prevId: obj.id,
                                            prevClass: obj.id.split('-')[0],
                                        },
                                    ];
                                } else if (objs) {
                                    this.privates.items = [
                                        ...this.privates.items,
                                        {
                                            id: `drag-${index}`,
                                            content: objs.content,
                                            value: objs.value,
                                            prevId: objs.id,
                                            prevClass: objs.id.split('-')[0],
                                        },
                                    ];
                                }
                            }
                        });
                    }
                }
                this.actions.update();
            }
        } else if (asyncKey === 'smartTagCategorys') {
            if (
                asyncModel.smartTagCategorys &&
                asyncModel.smartTagCategorys.data &&
                asyncModel.smartTagCategorys.data.length > 0
            ) {
                this.privates.smartTagCategorys = asyncModel.smartTagCategorys.data;
                if (!this.privates.categoryId && !this.props.match.params.id) {
                    this.privates.categoryId = asyncModel.smartTagCategorys.data[0].id;
                    this.actions.getTagTreeInit(this.privates.categoryId, 1);
                }
            }
        }
    }
}

export default IntelligentTagsDetailRoute;
