import React, { ReactElement, useImperativeHandle, useRef } from 'react';
import { IntelligentPartnerSentence } from 'src/api/types';
import styled from 'styled-components';
import { Empty } from 'udesk-ui';
import { DialogueContextProvider } from './Context';
import { Item } from './Item';
import { useFormattedDialogueSentenceList } from './hooks/useFormattedDialogueSentenceList';
import { useAudio } from './hooks/useAudio';

const Div = styled.div`
    background: #f5f6f8;
    border-radius: 4px;
    height: 100%!important;
    overflow: auto!important;
    padding: 24px 16px;
`;

type DialogueProps = {
    dialogueSentenceList?: IntelligentPartnerSentence[];
    itemRender: (props: any, index: number) => ReactElement;
    formatItemRenderProps?: (props: any) => any;
};
export type RefType = {
    scrollTo: (index: number) => void;
}

const InternalDialogue = React.forwardRef<RefType, DialogueProps>((props, ref) => {
    const divRef = useRef<HTMLElement>(null);
    const { dialogueSentenceList, itemRender, formatItemRenderProps } = props;
    const dialogueSentenceListFormatted = useFormattedDialogueSentenceList(
        dialogueSentenceList,
        formatItemRenderProps
    );
    const audioInstance = useAudio();

    useImperativeHandle(ref, () => {
        return {
            /**
             * 这里可以滚动到index相应的对话，如果index大于当前列表长度，将滚动到底部
             * @param index 对话的index
             */
            scrollTo (index) {
                const $content = divRef.current;

                if ($content) {
                    let top = 0;
                    const $divList = $(`>div`, $content);
                    index = index > $divList.length ? $divList.length : index;
                    
                    $divList.filter(`:lt(${index})`).each((index, item) => {
                        top += $(item).height() ?? 0;
                        top += parseInt(window.getComputedStyle(item).marginBottom);
                    });
                    $content?.scrollTo({
                        top,
                        behavior: 'smooth',
                    });
                }
            }
        };
    }, []);


    return (
        <DialogueContextProvider
            value={{
                dialogueSentenceList,
                audioInstance,
            }}
        >
            <Div ref={divRef} className="udesk-coach-components-dialogue-wrap">
                {isNotEmptyArray(dialogueSentenceListFormatted) ? (
                    dialogueSentenceListFormatted?.map((sentence, index) => {
                        return itemRender(sentence, index);
                    })
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Div>
        </DialogueContextProvider>
    );
});

type InternalDialogueType = typeof InternalDialogue;

type DialogueType = InternalDialogueType & {
    Item: typeof Item;
};

const Dialogue: DialogueType = InternalDialogue as DialogueType;

Dialogue.Item = Item;

export { Dialogue };

function isNotEmptyArray(dialogueSentenceList) {
    return Array.isArray(dialogueSentenceList) && dialogueSentenceList.length > 0;
}
