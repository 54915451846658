import Udesk from 'Udesk';
import React from 'react';
import TasksIndexRoute from './route';
import TaskCard from 'Component/pages/components/task-card';
// import AutoComplete from 'udesk-react/src/components/auto-complete';
// import ReactModal from 'udesk-react/src/components/react-modal';
import {
    Space,
    Button,
    Icon,
    Modal,
    Spin,
    Empty,
    FormBuilder as CreateForm,
    Radio,
    Tabs,
    Checkbox,
    Row,
    Col,
} from 'udesk-ui';
// import CreateForm from 'Component/common/create-form';
import Page from 'Component/common/udesk-qa-page-layout';
import UdeskLocales from 'UdeskLocales';
import AutoApprove from './approve/components/autoApproveSwitch';
import ApproveTable from './approve/components/table/index.tsx';

import './style.scss';
const createFormCol = {};

const { TabPane } = Tabs;

const QualityTasksTabs = (props) => {
    const { locales, activeTabKey, onChange: propsOnChange, enabledInspectRuleApprove } = props;
    return (
        <Tabs size='small' activeKey={activeTabKey} onChange={propsOnChange}>
            <TabPane tab={locales.pages.tasks.index.qualityCheckTasks} key='qualityCheckTasks' />
            <TabPane tab={locales.pages.tasks.index.informationCollection} key='informationCollection' />
            <If condition={enabledInspectRuleApprove}>
                <TabPane tab={/* 规则审批 */locales.pages.tasks.template.ruleApproval} key='inspectRuleApprove' />
            </If>
        </Tabs>
    );
};
const WecomQualityTasksTabs = (props) => {
    const { activeTabKey, onChange: propsOnChange } = props;
    
    return (
        <Tabs size='small' activeKey={activeTabKey} onChange={propsOnChange}>
            <TabPane tab={/* 企微会话分析任务 */UdeskLocales.current.pages.tasks.template.enterpriseMicroConversationAnalysisTask} key='wechatDialogue' />
            <TabPane tab={/* 企微语音分析任务 */UdeskLocales.current.pages.tasks.template.enterpriseAndMicroVoiceAnalysisTask} key='wechatRadio' />
        </Tabs>
    );
};
export default class TasksIndexTemplate extends TasksIndexRoute {
    render() {
        let { actions, locales, privates } = this;
        let {
            computes,
            createModalVisible,
            isWechatQa,
            loading,
            moduleSourceType,
            inspectDataSources,
            storages,
            chartsFulfilled,
            saveAsModelVisible,
        } = privates;
        let { _tasks } = computes;
        const { activeTabKey } = storages;

        const isCollection = activeTabKey === 'informationCollection';
        return (
            <React.Fragment>
                {/* <div className="page tasks-index"> */}
                <Page
                    pageBodyClassName={
                        isWechatQa || isCollection ? 'udesk-wechat-qa-dashboard' : (
                            activeTabKey === 'inspectRuleApprove' ? 'tasks-approve-index' : 'tasks-index'
                        )
                    }
                    title={locales.pages.tasks.index[isWechatQa ? 'wechatQaTasks' : 'qualityCheckTasksTitle']}
                    extra={[
                        <div key='udesk-qa-web-page-header'>
                            {/* <If condition={Udesk.data.init.user.hasFeature("sys:universalReport:root")}>
                                <Button type="primary" className="btn btn-default pull-right" style={{ marginLeft: "10px" }} onClick={actions.generalChange}>
                                    {locales.pages.tasks.index.generalInspectionList}
                                </Button>
                            </If> */}
                            <Space>
                                <If condition={
                                    Udesk.data.init.user.hasFeature('task:add') &&
                                    activeTabKey !== 'inspectRuleApprove'
                                }>
                                    <Button type='primary' onClick={actions.creatTaskModelVisible}>
                                        <Icon type='tianjia1'></Icon>
                                        {locales.pages.tasks.index.creatNewQualityCheckTask}
                                    </Button>
                                </If>
                                <If
                                    condition={
                                        Udesk.data.init.user.hasFeature('sys:module:root') &&
                                        !isWechatQa &&
                                        activeTabKey === 'qualityCheckTasks'
                                    }
                                >
                                    <Button type='primary' onClick={actions.creatModule}>
                                        <Icon type='tianjia1'></Icon>
                                        {locales.pages.tasks.index.moduleName}
                                    </Button>
                                </If>
                                <If condition={
                                    Udesk.data.init.user.hasFeature?.('task:approve') &&
                                        !isWechatQa &&
                                        activeTabKey === 'inspectRuleApprove'
                                }>
                                    <AutoApprove value={privates.enabledInspectRuleApproveAuto} locales={locales} />
                                </If>
                            </Space>
                        </div>,
                    ]}
                    footer={
                        isWechatQa ? (
                            <WecomQualityTasksTabs
                                locales={locales}
                                activeTabKey={activeTabKey}
                                onChange={actions.onTabsChange}
                            />
                        ) : (
                            <QualityTasksTabs
                                enabledInspectRuleApprove={privates.enabledInspectRuleApprove}
                                locales={locales}
                                activeTabKey={activeTabKey}
                                onChange={actions.onTabsChange}
                            />
                        )
                    }
                    backGroundGhost={true}
                >
                    <div className='page-content'>
                        <div className='x_panel clearfix'>
                            <div className='x_content clearfix'>
                                {
                                    ['inspectRuleApprove'].includes(privates.storages.activeTabKey) ? (
                                        <ApproveTable {...this.props}/>
                                    ) : (
                                        <React.Fragment>
                                            {isWechatQa || (
                                                <div className='tasks-filter-control'>
                                                    <CreateForm
                                                        col={createFormCol}
                                                        footerRender={false}
                                                        layout='inline'
                                                        initialValues={privates.storages}
                                                        fields={privates.filterControlFormFields.filter(
                                                            (item, index) =>
                                                                activeTabKey !== 'informationCollection' ||
                                                            item.name !== 'inspectionType'
                                                        )}
                                                        onFinish={actions.filterControlSearch}
                                                        ref={privates.formRef}
                                                    />
                                                    <Space>
                                                        <Button onClick={actions.filterBtnReset}>{locales.labels.reset}</Button>
                                                        <Button type='primary' onClick={actions.filterBtnOncilck}>
                                                            {locales.labels.search}
                                                        </Button>
                                                    </Space>
                                                </div>
                                            )}
        
                                            <div className='tasks-container'>
                                                {_tasks.length > 0 ? (
                                                    _tasks.map((task, index) => (
                                                        <TaskCard
                                                        // cardIndex={index}
                                                            key={task.id}
                                                            task={task}
                                                            fulfilled={chartsFulfilled}
                                                            // editCallback={actions.editTaskModelVisible}
                                                            isWechatQa={isWechatQa}
                                                            isCollection={isCollection}
                                                            saveAsCallback={actions.saveAsTaskModelVisible}
                                                            openSaveAsModel={actions.openSaveAsModel}
                                                            {...this.props}
                                                        />
                                                    ))
                                                ) : (
                                                    <Spin style={{ marginTop: '20%' }} spinning={loading} size='large'>
                                                        {loading || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                                    </Spin>
                                                )}
                                                {/* <For each="task" index="index" of={_tasks}>
                                                        <TaskCard
                                                            key={task.id}
                                                            task={task}
                                                            fulfilled={_tasks.some(item => item.chart)}
                                                            // editCallback={actions.editTaskModelVisible}
                                                            isWechatQa={isWechatQa}
                                                            saveAsCallback={actions.saveAsTaskModelVisible}
                                                            {...this.props}
                                                        />
                                                    </For> */}
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <Modal
                        visible={createModalVisible}
                        title={locales.pages.tasks.manage.template.checkPoint.index.template}
                        onCancel={actions.closeCreateModal}
                        footer={null}
                        width={768}
                        wrapClassName='check-point-index-create-modal'
                    >
                        <div className='check-point-index-create-modal-body'>
                            <div className='check-point-index-create-modal-tabs'>
                                <Radio.Group
                                    value={moduleSourceType}
                                    onChange={actions.filterChange}
                                    optionType='button'
                                >
                                    <Radio.Button
                                        disabled={inspectDataSources === Udesk.enums.inspectDataSources.textDialogue.id}
                                        value={Udesk.enums.inspectDataSources.voiceCall.id}
                                    >
                                        {locales.pages.admin.templateComponent.list.call}
                                    </Radio.Button>
                                    <Radio.Button
                                        disabled={inspectDataSources === Udesk.enums.inspectDataSources.voiceCall.id}
                                        value={Udesk.enums.inspectDataSources.textDialogue.id}
                                    >
                                        {locales.pages.admin.templateComponent.list.dialogue}
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                            <div className='check-point-index-create-modal-templates'>
                                {privates.templateList.map((item, index) => {
                                    return (
                                        <div
                                            key={item.id}
                                            onClick={actions.tranToDetail.params(item)}
                                            className='check-point-index-create-modal-templates-item'
                                        >
                                            <div style={{ textAlign: 'center' }}>
                                                <span class='udesk-qa-react-web-iconfont'>&#xe6cd;</span>
                                            </div>
                                            <div className='check-point-index-create-modal-templates-item-name'>
                                                {item.name}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        title={/* 任务另存为 */locales.pages.tasks.template.saveTaskAs}
                        visible={saveAsModelVisible}
                        onOk={() => {
                            privates.saveAsFormRef?.current?.submit?.();
                        }}
                        // confirmLoading={confirmLoading}
                        width={660}
                        onCancel={() => {
                            this.privates.saveAsModelVisible = false;
                            this.privates.currentSaveAsTask = null;
                            this.actions.update();
                        }}
                    >
                        <CreateForm
                            footerRender={false}
                            fields={privates.saveAsFormFields}
                            initialValues={privates.saveAsFormInitValue}
                            customComponents={{ CheckBoxGroupCol }}
                            labelCol={{
                                xs: {
                                    span: 6,
                                },
                                sm: {
                                    span: 6,
                                },
                            }}
                            wrapperCol={{
                                xs: {
                                    span: 16,
                                },
                                sm: {
                                    span: 16,
                                },
                            }}
                            onFinish={actions.commitSaveAs}
                            ref={privates.saveAsFormRef}
                        />
                    </Modal>
                </Page>
            </React.Fragment>
        );
    }
}

function CheckBoxGroupCol(props) {
    const { options, ...rest } = props;
    return (
        <Checkbox.Group {...rest}>
            <Row>
                {options.map((i, index) => {
                    return (
                        <Col span={index === 1 ? 16 : 8} >
                            <Checkbox {...i}>{i.label}</Checkbox>
                        </Col>
                    );
                })}
            </Row>
        </Checkbox.Group>
    );
}
