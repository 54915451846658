import React, { useMemo } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import FilterModalEdit from '../../../../pages/tasks/manage/template/automatic-add/filter-modal';
import { Icon, Button } from 'udesk-ui';
import { getInfoList } from '../custom-filter-button';

const locales = Locales['current'];

const fieldsFilterChange = (data) => {
    const list = getInfoList(data);
    return list.map((item, index) => {
        return (
            <div
                style={{
                    color: 'rgba(0, 0, 0, 0.65)',
                    marginBottom: index + 1 === list.length ? '0px' : '8px',
                }}
            >
                {index + 1}. {item}
            </div>
        );
    });
};

const Template = React.memo((props: any) => {
    const {
        conditionList = [],
        customJudgeLogic,
        judgeStrategy,
        onClick: onPropsClick,
        cusBtnProps,
        ...args
    } = props;
    const getConfigJson = (conditions) => {
        let conditionJsonInfo = [];
        let conditionJson = [];
        conditionJsonInfo =
            conditions.map((item) => {
                if (item.field && item.field.statusKey) {
                    return {
                        ...item,
                        field: {
                            ...item.field,
                            id: `${item.field.id}-${item.field.inspectionTaskId}`,
                        },
                    };
                } else {
                    return item;
                }
            }) || [];
        conditionJson = conditions.map((item) => {
            if (item.field && item.field.statusKey) {
                return {
                    field: `${item.field.id}-${item.field.inspectionTaskId}`,
                    operator: item.operator,
                    value: item.value,
                    statusKey: `${item.field.id}-${item.field.inspectionTaskId}`,
                };
            } else {
                return {
                    field: item.field.id,
                    operator: item.operator,
                    value: item.value,
                };
            }
        });
        return {
            conditionJsonInfo,
            conditionJson,
        };
    };

    const onClick = () => {
        onPropsClick?.({
            conditionList,
            customJudgeLogic,
            judgeStrategy,
        });
    };
    const { conditionJson, conditionJsonInfo } = useMemo(() => {
        const configJson = getConfigJson(conditionList);
        return configJson;
    }, [conditionList]);

    return (
        <React.Fragment>
            {Array.isArray(conditionList) && conditionList.length ? (
                <FilterModalEdit
                    conditionJson={conditionJson}
                    judgeStrategy={judgeStrategy}
                    customJudgeLogic={customJudgeLogic}
                    conditionList={[...conditionList]}
                    conditionJsonInfo={conditionJsonInfo}
                    editFilterOhter={onClick}
                    fieldsFilterChange={(data) => fieldsFilterChange({
                        conditionList: data 
                    })}
                    {...args}
                />
            ) : (
                <Button
                    type="link"
                    icon={<Icon type="add1" antdIcon={true} />}
                    {...cusBtnProps}
                    onClick={onClick}
                >
                    {locales.components.ruleGather.screeningCondition}
                </Button>
            )}
        </React.Fragment>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);