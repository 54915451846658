import React, { useCallback, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import { Avatar, Space } from 'udesk-ui';
import CustomerTag from '../customer-tag';
import UserInfo from '../user-info';
import BusinessTitle from '../business-title';
import BusinessStage from '../business-stage';
import { useLiveEffect } from 'src/util/hook';
import moment from 'moment';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const getCurrentTime = () => {
    return moment().format('YYYY-MM-DD HH:mm:ss');
};

export default React.memo((props:any) => {
    const {
        request,
        locales,
        callDataList, // 通话列表
        customerId, // 客户ID
        customerName, // 客户名称
        setCustomerName,
        // followUpSuggestions, // 跟进建议
        followUpDays, // 跟进天数
        ownerName, // 负责人
        userName, // 最近跟进人
        customerTagList, // 客户标签
    } = props;
    const [list, setList] = useState<any[]>([]);
    const [stayDays, setStayDays] = useState(0);
    const [isRender, setIsRender] = useState(true);
    const [customerInfo, setCustomerInfo] = useState<any>();
    const [customerSaleFinish, setCustomerSaleFinish] = useState<any>(null);
    const update = useCallback(() => {
        setIsRender(isRender => !isRender);
    }, []);

    const callId = useMemo(() => {
        if (callDataList?.length > 0) {
            return callDataList[0].callId;
        }
    }, [callDataList]);

    const handleRemarkConfirm = useCallback((remark) => {
        const url = customerInfo.id ? `/review/spectaculars/customer/${customerId}`: '/review/spectaculars/customer';
        const method = customerInfo.id ? 'put' : 'post';

        return request(url, {...customerInfo, remark}, method).then(resp => {
            setCustomerInfo({
                customerId,
                id: resp.data.id,
                remark: resp.data.remark,
                phoneNumber:  resp.data.phoneNumber,
            });
        });
    }, [customerId, customerInfo, request]);

    const handleStageDelete = useCallback((id) => {
        return request(`/review/spectaculars/saleStage/finish/${id}`, {}, 'del').then(
            resp=> {
                Udesk.ui.notify.success(/* 重新启用商机 */UdeskLocales['current'].pages.gong.saleClientCenter.components.business.index.reEnableOpportunity);
                update();
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [request, update]);
    /**
     * 阶段如果为最后一个阶段，只要有开始事件，就代表当前单子为赢单
     * 所以需要根据当前修改的阶段序号进行判断修改逻辑
     */
    const handleStageChange = useCallback((activeIndex) => {
        const result = list.map((data, index) => {
            const item = Object.assign({}, data);
            const lastIndex = list.length -1;

            if (index < activeIndex) {
                if (!item.startTime) {
                    item.startTime = getCurrentTime();
                }
                if (!item.endTime) {
                    item.endTime = index === lastIndex ? null : getCurrentTime();
                }
                item.followUpStageStatus = index === lastIndex ? 2 : 1;
            }
            if (index === activeIndex) {
                item.startTime = getCurrentTime();
                item.endTime = null;
                item.followUpStageStatus = 2;
            }
            if (index > activeIndex) {
                item.startTime = null;
                item.endTime = null;
                item.followUpStageStatus = 3;
            }

            return {
                startTime: item.startTime,
                endTime: item.endTime,
                followUpStageStatus: item.followUpStageStatus,
                stageId: item.stageId,
                baseInfoId: item.baseInfoId,
            };
        });

        return request(`/review/spectaculars/saleStage/${customerId}`, result, 'post').then(() => {
            update();
        });
    }, [
        customerId, 
        list, 
        request, 
        update
    ]);

    useLiveEffect(() => {
        if (customerId) {
            request(`/review/spectaculars/saleStage/${customerId}`).then(
                resp => {
                    setList(resp.data.customerSaleSettingStageList);
                    setStayDays(resp.data.stayDays);
                    setCustomerSaleFinish(resp.data.customerSaleFinish);
                }
            );
        }

    }, [customerId, isRender]);

    useLiveEffect(() =>{
        if (customerId && callId) {
            request(`/review/spectaculars/customer/${customerId}`, {callId}).then(
                resp => {
                    setCustomerInfo({
                        customerId,
                        id: resp.data.id,
                        remark: resp.data.remark,
                        phoneNumber:  resp.data.phoneNumber,
                    });
                }
            );
        }
    }, [customerId, callId]);

    return (
        <>
            <Space className='sale-business'>
                <div className='avatar'>
                    <Avatar size={70} src={''}>
                        {customerName.slice(0, 1)}
                    </Avatar>
                </div>
                <Space direction='vertical'>
                    <BusinessTitle 
                        {...{
                            locales,
                            request,
                            className: 'sale-business-title',
                            customerId,
                            customerName,
                            setCustomerName,
                            ownerName,
                            userName,
                            stageList: list,
                            stayDays,
                            followUpDays,
                            handleStageChange,
                            customerInfo,
                            handleRemarkConfirm,
                        }}
                    />
                    <BusinessStage 
                        {...{
                            list,
                            customerSaleFinish,
                            setCustomerSaleFinish,
                            locales,
                            request,
                            className: 'sale-business-stage',
                            customerId,
                            handleStageChange,
                            handleStageDelete,
                        }}/>
                    <CustomerTag 
                        {...{
                            locales,
                            request,
                            customerId,
                            customerTagList: customerTagList,
                            className:'sale-business-customer-tag' 
                        }}/>
                    <UserInfo 
                        {...{
                            locales,
                            request,
                            customerId,
                            className: 'sale-business-user-info',
                            ownerName,
                            userName,
                            followUpDays,
                            customerInfo,
                            handleRemarkConfirm
                        }}
                    />
                </Space>
            </Space>
            <div className='sale-business-bottom'></div>
        </>

    );
});
