// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    PostReviewIntelligentPartnerNodePptConfigTransformPPTByFlowIdParam,
    PostReviewIntelligentPartnerNodePptConfigTransformPPTByFlowIdBody,
    BaseResponseListstring,
} from '../../../../../../types';

/**
 * findImageList
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [POST] /review/intelligentPartner/node/pptConfig/transformPPT/{flowId}
 * @param flowId
 */
export function postReviewIntelligentPartnerNodePptConfigTransformPPTByFlowId(
    data: PostReviewIntelligentPartnerNodePptConfigTransformPPTByFlowIdBody,
    options: ApiOptions<
        PostReviewIntelligentPartnerNodePptConfigTransformPPTByFlowIdParam,
        never
    > & { segments: PostReviewIntelligentPartnerNodePptConfigTransformPPTByFlowIdParam }
): Promise<BaseResponseListstring> {
    return request<
        PostReviewIntelligentPartnerNodePptConfigTransformPPTByFlowIdBody,
        PostReviewIntelligentPartnerNodePptConfigTransformPPTByFlowIdParam,
        never
    >('/review/intelligentPartner/node/pptConfig/transformPPT/{flowId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewIntelligentPartnerNodePptConfigTransformPPTByFlowId',
    });
}

export const meta = [
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig/transformPPT/{flowId}',
        method: 'post',
    },
];
