import { getRouteNameWithCoach } from "./const";

const coachRoutesGlobal = [
    // 智能陪练系统
    {
        path: '/coach',
        name: 'coachHome',
        routes: [
            {
                path: '/dashboard',
                authCode: 'intelligentpartner:homepage:root',
                name: getRouteNameWithCoach('dashboard'),
            },
            {
                path: '/stud-dashboard',
                authCode: 'intelligentpartner:homepage:root',
                name: getRouteNameWithCoach('studDashboard'),
            },
            //智能陪练 学习中心
            {
                path: '/learning-center',
                name: 'coachLearningCenter',
                routes: [
                    {
                        path: '/task',
                        name: 'coachLearningCenterTask',
                        routes: [
                            {
                                path: '/exercise', // 练习列表页面
                                name: 'coachLearningCenterExerciseTask',
                            },
                            {
                                path: '/test', // 测试列表页面
                                name: 'coachLearningCenterTestTask',
                            },
                            {
                                path: '/exercise/start/:id', // 语音练习页面
                                name: 'coachLearningCenterExerciseTaskStart',
                            },
                            {
                                path: '/test/start/:id', //语音测试页面
                                name: 'coachLearningCenterTestTaskStart',
                            },
                            {
                                path: '/exercise/text/start/:id', // 文本练习页面
                                name: 'coachLearningCenterExerciseTaskTextStart',
                            },
                            {
                                path: '/test/text/start/:id', // 文本测试页面
                                name: 'coachLearningCenterTestTaskTextStart',
                            },
                            {
                                path: '/exercise/ppt/start/:id', // PPT练习页面
                                name: 'coachLearningCenterExerciseTaskPPTStart',
                            },
                            {
                                path: '/test/ppt/start/:id', // PPT测试页面
                                name: 'coachLearningCenterTestTaskPPTStart',
                            },
                        ],
                    },
                    {
                        path: '/record/list',
                        name: 'coachLearningCenterRecord',
                    },
                    {
                        path: '/record/detail/:id',
                        name: 'coachLearningCenterRecordDetail',
                    },
                    {
                        path: '/course/view/list',
                        name: 'coachLearningCenterCourse',
                    },
                    {
                        path: '/course/create',
                        name: 'coachLearningCenterCourseNew',
                    },
                    {
                        path: '/course/detail/:id',
                        name: 'coachLearningCenterCourseEdit',
                    },
                    {
                        path: '/course/learn/:id',
                        name: 'coachLearningCenterCourseLearn',
                    },
                    // 错题集
                    {
                        path: '/wrong-question-set',
                        name: getRouteNameWithCoach('wrongQuestionSet'),
                        routes: [
                            {
                                path: '/index',
                                name: getRouteNameWithCoach('wrongQuestionSetList'),
                            },
                            {
                                path: '/exercise',
                                name: getRouteNameWithCoach('wrongQuestionSetExercise'),
                            },
                        ],
                    },
                ],
            },
            {
                path: '/course-management',
                name: 'courseManagementEntry',
                routes: [
                    {
                        path: '/list',
                        name: 'coachLearningCenterCourseConfig',
                    },
                    {
                        path: '/create',
                        name: 'courseManagementCreate',
                    },
                    {
                        path: '/detail/:id',
                        name: 'courseManagementDetail',
                    },
                    {
                        path: '/flow/:id',
                        name: 'courseManagementFlow',
                    },
                    {
                        path: '/test/:id',
                        name: 'coachNodeConfigTest',
                    },
                ]
            },
            {
                path: '/customer-tags-management/index',
                name: 'customerTagsManagement',
            },
            //#region  任务中心
            {
                path: '/task-center',
                name: 'coachTaskCenter',
                routes: [
                    {
                        path: '/audit',
                        name: getRouteNameWithCoach('taskCenterAudit'),
                    },
                    {
                        path: '/audit-detail/:id/:auditId',
                        name: getRouteNameWithCoach('taskCenterAuditDetail'),
                    },
                    {
                        path: '/review',
                        name: getRouteNameWithCoach('taskCenterReview'),
                    },
                    {
                        path: '/review-detail/:id/:reviewId',
                        name: getRouteNameWithCoach('taskCenterReviewDetail'),
                    },
                    {
                        path: '/list',
                        name: 'coachTaskCenterList',
                    },
                    {
                        path: '/edit/:id',
                        name: 'coachTaskCenterEdit',
                    },
                    {
                        path: '/detail/:id',
                        name: 'coachTaskCenterDetail',
                    },
                ]
            },
            //#endregion
            // 系统设置
            {
                path: '/admin',
                authCode: 'sys:setting:root',
                name: 'coachAdminEntry',
                routes: [
                    // 系统设置
                    {
                        path: '/system/index',
                        name: 'coachAdminSystemIndex',
                        authCode: 'sys:info:root',
                    },
                    // 任务中心
                    {
                        path: '/task-center',
                        name: 'coachSystemTaskCenter',
                        authCode: 'sys:impExp:root',
                        routes: [
                            {
                                path: '/download-list',
                                name: 'coachSystemTaskCenterDownloadList',
                            },
                            {
                                path: '/upload-list',
                                name: 'coachSystemTaskCenterUploadList',
                            },
                            {
                                path: '/task-list',
                                name: 'coachSystemTaskCenterTaskList',
                            },
                        ],
                    },
                ],
            },
            //404
            {
                path: '/404',
                name: 'coachPageNotFound',
            },
        ],
    },
];

export {
    coachRoutesGlobal
};
