import Udesk from 'Udesk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Form, Input, Row, Col, Space, Icon } from 'udesk-ui';
import { EnumSelect } from 'Component/common/enum-component';
import AutoItem from './autoItem';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const BaseFrom = React.memo((props: any) => {
    const {locales, stageOptions, data, handleStageChange, handleRemarkConfirm} = props;
    const [form] = Form.useForm();
    const [isRead, setIsRead] = useState(true);
    const showForm =  useCallback(() => { setIsRead(false); }, []);
    const hiddenForm =  useCallback(() => { setIsRead(true); }, []);
    const submitHandle = useCallback((values) => { 
        const {stageStatus, remark} = values;
        const {stageStatus: oldStageStatus, remark: oldRemark} = data;
        const list = [] as any[];

        if (oldStageStatus !== stageStatus) {
            const index = stageOptions.findIndex(item => item.value === stageStatus);
            if (index > -1) {
                list.push(handleStageChange(index));
            }
        }
        if (oldRemark !== remark) {
            list.push(handleRemarkConfirm(remark));
        }

        if (list.length > 0) {
            Promise.all(list).then(
                () => {
                    Udesk.ui.notify.success(locales.fix.saveSuccess);
                    setIsRead(true);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [
        data, 
        handleRemarkConfirm, 
        handleStageChange, 
        locales.fix.saveSuccess, 
        stageOptions
    ]);
    
    useEffect(() => {
        form.setFieldsValue(data);
    }, [data, form]);

    return (
        <Form
            form={form}
            style={{ width: '100%' }}
            onFinish={submitHandle}
        >
            <Form.Item>
                <div className='form-title'>
                    <h2>{/* 基本信息 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.essentialInformation}</h2>
                    {
                        isRead 
                            ? (
                                <Button 
                                    type='link' 
                                    icon={<Icon antdIcon={true} type='EditOutlined' />} 
                                    onClick={showForm} />                                ) : (
                                <Space>
                                    <Button onClick={hiddenForm}>{/* 取消 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.cancel}</Button>
                                    <Button htmlType="submit" type='primary'>{/* 确认 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.confirm}</Button>
                                </Space>
                            )
                    }
                </div>
            </Form.Item>
            <Row>
                <Col span={12}>
                    <AutoItem 
                        isRead={true}
                        config={{
                            name: 'customerName',
                            label: /* 客户 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.customer,
                            labelCol: {span: 8},
                            wrapperCol: {span: 16}
                        }}
                    />
                </Col>
                <Col span={12}>
                    <AutoItem 
                        type='Select'
                        isRead={isRead}
                        config={{
                            name: 'stageStatus',
                            label: /* 当前跟进阶段 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.currentFollowupStage,
                            labelCol: {span: 8},
                            wrapperCol: {span: 16}
                        }}
                        fieldProps={{
                            options: stageOptions
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <AutoItem 
                        isRead={true}
                        config={{
                            name: 'phoneNumber',
                            label: /* 电话 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.telephone,
                            labelCol: {span: 8},
                            wrapperCol: {span: 16}
                        }}
                    />
                </Col>
                <Col span={12}>
                    <AutoItem 
                        isRead={true}
                        config={{
                            name: 'followUpDays',
                            label: /* 跟进天数 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.followUpDays,
                            labelCol: {span: 8},
                            wrapperCol: {span: 16}
                        }}
                        fieldProps={{
                            suffix: /* 天 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.day
                        }}
                    />
                </Col>
            </Row>
            <AutoItem 
                type='TextArea'
                isRead={isRead}
                config={{
                    name: 'remark',
                    label: /* 备注 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.remarks,
                    labelCol: {span: 4},
                    wrapperCol: {span: 20}
                }}
                empty='--'
                fieldProps={{
                    rows: 4
                }}
            />
        </Form>
    );
});

const OtherForm = React.memo((props: any) => {
    const {isShow} = props;
    const [form] = Form.useForm();
    const [isRead, setIsRead] = useState(true);
    const showForm =  useCallback(() => { setIsRead(false); }, []);
    const hiddenForm =  useCallback(() => { setIsRead(true); }, []);
    const submitHandle = useCallback(() => { setIsRead(true); }, []);
    if(!isShow) return null;
    return (
        <Form
            form={form}
            style={{ width: '100%' }}
        >
            <Form.Item>
                <div className='form-title'>
                    <h2>{/* 基本信息 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.essentialInformation}</h2>
                    {
                        isRead 
                            ? (
                                <Button 
                                    type='link' 
                                    icon={<Icon antdIcon={true} type='EditOutlined' />} 
                                    onClick={showForm} />                               
                            ) : (
                                <Space>
                                    <Button onClick={hiddenForm}>{/* 取消 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.cancel}</Button>
                                    <Button onClick={submitHandle} type='primary'>{/* 确认 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.confirm}</Button>
                                </Space>
                            )
                    }

                </div>
            </Form.Item>
            <Row>
                <Col span={12}>
                    <AutoItem 
                        type='Select'
                        isRead={isRead}
                        config={{
                            name: 'userId',
                            label: /* 渠道偏好 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.channelPreference,
                            labelCol: {span: 8},
                            wrapperCol: {span: 16}
                        }}
                        fieldProps={{
                            options: [
                                {label: /* 你好 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.hello, value: 1},
                                {label: /* 你好1 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.hello, value: 3},
                                {label: /* 你好2 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.hello, value: 4}
                            ]
                        }}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item name='userId' label={/* 自定义字段 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.customFields} labelCol={{span: 8}}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='userId' label={/* 商机金额 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.opportunityAmount} labelCol={{span: 8}}>
                        <EnumSelect enumKey='monitorCriteria' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='userId' label={/* 自定义字段 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.customFields} labelCol={{span: 8}}>
                        <EnumSelect enumKey='monitorCriteria' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='userId' label={/* 关注点 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.focus} labelCol={{span: 8}}>
                        <EnumSelect enumKey='monitorCriteria' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='userId' label={/* 自定义字段 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.customFields} labelCol={{span: 8}}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    );
});

export default React.memo((props:any) => {
    const {
        locales,
        customerId,
        customerName,
        ownerName,
        userName,
        stageList,
        followUpDays,
        handleStageChange,
        customerInfo,
        handleRemarkConfirm,
    } = props;

    const [visible, setVisible] = useState(false);
    const closeHandle = useCallback(() => { setVisible(false); }, []);
    const openHandle = useCallback(() => { setVisible(true); }, []);

    const isWin = useMemo(() => {
        if (stageList?.length > 0) {
            return !!stageList[stageList.length - 1]['startTime'] && !!stageList[stageList.length - 1]['endTime'];
        }
        return false;
    }, [
        stageList
    ]);

    const winData = useMemo(() => {
        return stageList[stageList.length - 1];
    }, [
        stageList
    ]);

    const active = useMemo(() => {
        if (isWin) {
            return winData;
        }
        return stageList.find(item => !item.endTime);
    }, [
        isWin, 
        stageList, 
        winData
    ]);

    const stageStatus = useMemo(() => {
        if (active) {
            return active.stageId;
        }
    }, [active]);

    const stageOptions = useMemo(() => {
        return stageList.map(item => {
            return {
                value: item.stageId,
                label: item.stageName,
            };
        });
    }, [stageList]);

    return (
        <>
            <Drawer
                className='business-more-customer'
                placement='right'
                getContainer={false}
                onClose={closeHandle}
                title={/* 客户信息 */UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.customerInformation}
                mask={true}
                width={700}
                zIndex={1001}
                visible={visible}
            >
                <BaseFrom 
                    data={{
                        customerId,
                        customerName,
                        ownerName,
                        userName,
                        followUpDays,
                        remark: customerInfo?.remark,
                        phoneNumber:  customerInfo?.phoneNumber,
                        stageStatus,
                    }}
                    locales={locales}
                    stageOptions={stageOptions}
                    handleStageChange={handleStageChange}
                    handleRemarkConfirm={handleRemarkConfirm}
                />
                <OtherForm />
            </Drawer>
            <Button type='link' onClick={openHandle}>{/* 查看更多客户信息 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer.index.viewMoreCustomerInformation}</Button>
        </>
    );
});


