import React, { useState } from 'react';
// 引入编辑器组件
import BraftEditor from 'braft-editor';
// 引入编辑器样式
import 'braft-editor/dist/index.css';

const braftEditorStyle = {
    border: '1px solid #d9d9d9',
};

const RichText = (props) => {
    const { value: valueProps, onChange } = props;

    const [value, setValue] = useState(() => {
        return BraftEditor.createEditorState(valueProps || null);
    });

    return (
        <BraftEditor
            style={braftEditorStyle}
            value={value}
            onChange={(editorState) => {
                setValue(editorState);
                onChange(editorState.toHTML());
            }}
        />
    );
};

export { RichText };
