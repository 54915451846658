import React from 'react';
import Udesk from 'Udesk';
import _cloneDeep from 'lodash-es/cloneDeep';
// import { postInspectionDataDetailByIdRecheck } from 'src/api/inspectionData/detail/{id}/recheck';
import { Modal } from 'udesk-ui';
import { getSystemModule } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

const { confirm } = Modal;

let RelatedDatasPaging = {
    pageNum: 1,
    pageSize: 10,
};

let timer = null;

class SpotCheckListDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        detail: {},
        smartTags: [],
        changeList: [],
        qualityCheckScoreSaving: false,
        relatedDatas: [],
        storages: {
            relatedDatasPaging: {
                pageNum: 1,
                pageSize: 10,
            },
        },
        relatedLoading: false,
        pointCategoryList: [],
        handMarkMapList: {},
        pointChildrenPerson: {},
        pointChildrenComputer: {},
        type: Udesk.enums.qualityCheckDetailTypes.spotCheck.id,
        resetStatus: false,
        evaluateFlag: false,
        evaluteVisibe: false,
        enabledEvaluate: false,
        renderLoading: false,
        relatedRecordsLoading: false,
        wrapDom: React.createRef(),
        xhr: null,
        loadMoreXhr: null,
        showHit: 0,
        samplingTargetBannerKey: Math.random(),

        reInspectionConfigVisible: false,
        allPoints: true,
        reInspectionPoints: [],
        reInspectionType: Udesk.enums.reInspectionTypes.recalcScore.id,
        reCheckLoading: false,
        progressStatus: 1,
        reInspectionModalVisible: false,
        alreadyDoneCount: 0,
        progressValue: '0',
        recheckProcessTotal: 0,
        timerToken: null,
        inspectSearchDefaultShowFields: null,
        fastFilterConditions: [],
        stateParamsType: '',
        circlePercent: 0,
        isWechatQa: false,
    };
    static computes = {};

    actions = {
        fileSubmit() {
            let { task } = this.privates;
            let { sdkOptions, match } = this.props;
            let { locales } = this;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${task.id}/gradeFile?taskId=${match.params.taskId}`,
                sdkOptions
            );

            this.privates.qualityCheckScoreSaving = true;
            this.actions.update();
            Udesk.ajax.put(url).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.fileSuccessFormat,
                            locales.components.pages.components.qualityCheckScore.actualScore
                        )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    this.privates.resetStatus = !this.privates.resetStatus;
                    this.privates.handMarkMapList = {};
                    this.actions.update();
                    this.actions.reloadAsyncModel();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.fileFailedFormat,
                                locales.components.pages.components.qualityCheckScore.actualScore
                            )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    this.actions.update();
                }
            );
        },
        openSubmitModal() {
            confirm({
                title: this.locales.components.pages.qualityInspectionList.detail.fileConfirm,
                content: this.locales.components.pages.qualityInspectionList.detail.fileConfirmDesc,
                onOk: this.actions.fileSubmit,
            });
        },
        showHitChange(showHit) {
            this.privates.showHit = showHit ? 1 : 0;
            this.actions.update();
        },
        backToIndexPage() {
            let routeOptions = {
                history: this.props.history,
                routeName: this.privates.isWechatQa ? 'wechatSpotCheckListIndex' : 'spotCheckListIndex',
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        changePages(type, cb) {
            let { sdkOptions, match, history } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`sample/next/${match.params.id}/${type}`, sdkOptions);
            if (timer) {
                clearTimeout(timer);
            }
            this.privates.renderLoading = true;
            this.actions.update();
            timer = setTimeout(() => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        const { nextId, nextInspectionId } = resp.data;
                        if (nextInspectionId && nextId) {
                            let routeOptions = {
                                history: history,
                                routeName: this.privates.isWechatQa ? 'wechatSpotCheckListDetail' : 'spotCheckListDetail',
                                pathParams: {
                                    taskId: match.params.taskId,
                                    id: nextId,
                                },
                                queryParams: {
                                    inspectionId: nextInspectionId,
                                },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        this.privates.renderLoading = false;
                        this.actions.update();
                        typeof cb === 'function' && cb?.();
                    }
                );
            }, 300);
        },
        spotCheckScoreCanceled() {
            this.actions.reloadAsyncModel();
        },
        spotCheckScoreSubmitted() {
            let { pointCategoryList, task, handMarkMapList, evaluateFlag } = this.privates;
            let { locales } = this;
            let pointDataList = getPointList(pointCategoryList);
            let validateResult = validateItemScores(pointDataList);
            if (!validateResult) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.labels.saveSpotCheck
                    )
                );
            }
            if (!evaluateFlag && this.privates.enabledEvaluate) {
                this.privates.evaluteVisibe = true;
                this.actions.update();
                return;
            }
            let { sdkOptions, match } = this.props;
            let arr = _cloneDeep(pointDataList);
            arr.forEach((item) => {
                delete item.handMarkHighlightSeqMap;
            });
            let params = {
                flowId: match.params.id,
                taskId: match.params.taskId,
                inspectionId: task.id,
                itemScores: arr,
                richRemark: task.richRemark,
                remark: task.remark,
            };
            let list = pointDataList.map((item) => {
                if (handMarkMapList[item.pointId]) {
                    let markIndices = [];
                    if (handMarkMapList[item.pointId] && handMarkMapList[item.pointId].length > 0) {
                        markIndices = handMarkMapList[item.pointId].map((item) => item.id);
                    }
                    return {
                        pointId: item.pointId,
                        // flowId: resp.data.flowId,
                        // flowType: resp.data.flowType,
                        // callId: resp.data.callId,
                        // inspectId: resp.data.inspectionId,
                        pointType: item.type,
                        pointGradeType: item.gradeType,
                        gradeChooseHit: item.gradeChooseHit,
                        markType: 1,
                        markIndices,
                    };
                } else {
                    let markIndices = null;
                    if (item.handMarkHighlightSeqMap && item.handMarkHighlightSeqMap[1]) {
                        markIndices = item.handMarkHighlightSeqMap[1];
                    }
                    return {
                        pointId: item.pointId,
                        // flowId: resp.data.flowId,
                        // flowType: resp.data.flowType,
                        // callId: resp.data.callId,
                        // inspectId: resp.data.inspectionId,
                        gradeChooseHit: item.gradeChooseHit,
                        pointType: item.type,
                        pointGradeType: item.gradeType,
                        markType: 1,
                        markIndices,
                    };
                }
            });
            let pointFlag = true;
            for (let i = 0; i < list.length; i++) {
                if (list[i].pointType === Udesk.enums.pointTypes.machineLearning.id) {
                    if (
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitAdd.id ||
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitSub.id
                    ) {
                        if (list[i].gradeChooseHit === 1) {
                            if (!list[i].markIndices || list[i].markIndices.length === 0) {
                                pointFlag = false;
                                break;
                            }
                        } else if (list[i].gradeChooseHit === 0) {
                            list[i].markIndices = null;
                        }
                    } else if (
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitNotAdd.id ||
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitNotSub.id
                    ) {
                        if (list[i].gradeChooseHit === 0) {
                            if (!list[i].markIndices || list[i].markIndices.length === 0) {
                                pointFlag = false;
                                break;
                            }
                        } else if (list[i].gradeChooseHit === 1) {
                            list[i].markIndices = null;
                        }
                    }
                }
            }
            if (!pointFlag) {
                return Udesk.ui.notify.error(locales.fix.unmarkedSelfLearningPointsTip);
            }
            let url1 = Udesk.business.apiPath.concatApiPath(`smartPointMarkResults`, sdkOptions);
            let url = Udesk.business.apiPath.concatApiPath(`sample/${task.id}/submit`, sdkOptions);
            this.privates.qualityCheckScoreSaving = true;
            this.actions.update();
            new Promise((resolve, reject) => {
                Udesk.ajax.put(url, params, { headers: { uuId: uuid() } }).then(
                    (resp) => {
                        // saveRemark(task, this).then(() => {
                        //     resolve(resp);
                        // }).catch((reason) => {
                        //     reject(reason);
                        // });
                        resolve(resp);
                    },
                    (reason) => {
                        reject(reason);
                    }
                );
            })
                .then((resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            locales.labels.saveSpotCheck
                        )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    if (resp.data) {
                        let data = {
                            flowId: resp.data.id,
                            flowType: resp.data.flowType,
                            inspectId: resp.data.inspectionId,
                        };
                        let lists = list.map((item) => {
                            return {
                                ...item,
                                isHit: item.gradeChooseHit,
                                flowId: resp.data.id,
                                flowType: resp.data.flowType,
                                callId: resp.data.callId,
                                inspectId: resp.data.inspectionId,
                            };
                        });

                        data.smartPointMarks = lists;
                        new Promise((resolve, reject) => {
                            Udesk.ajax.post(url1, data).then(
                                (resp) => {
                                    resolve(resp);
                                    Udesk.ui.notify.success(locales.fix.markedSuccessfully);
                                    this.privates.pointCategoryList = [];
                                    this.privates.handMarkMapList = {};
                                    this.privates.pointChildrenPerson = {};
                                    this.privates.pointChildrenComputer = {};
                                },
                                (reason) => {
                                    reject(reason);
                                    Udesk.ui.notify.error(locales.fix.markingFailed);
                                }
                            );
                        }).then(() => {
                            Udesk.data.init.sampleNotToNext
                                ? this.actions.reloadAsyncModel()
                                : this.actions.changePages(2, this.actions.reloadAsyncModel);
                        });
                    }
                    this.privates.resetStatus = !this.privates.resetStatus;
                    this.privates.handMarkMapList = {};
                    this.actions.update();
                    // this.actions.reloadAsyncModel();
                })
                .catch((reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                locales.labels.saveSpotCheck
                            )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    this.privates.samplingTargetBannerKey = Math.random();
                    this.actions.update();
                });
            if (task.evaluateDto && task.evaluateDto.handValue) {
                let url1 = Udesk.business.apiPath.concatApiPath(
                    `call/goodOrBad/${task.callId}?goodBadFlag=${task.evaluateDto.handValue}`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.get(url1).then(
                        (resp) => {
                            resolve(resp);
                        },
                        (reason) => {
                            reject(reason);
                        }
                    );
                });
            }
        },
        onQualityCheckScoreRapidSampling() {
            let { pointCategoryList, task, handMarkMapList, type, evaluateFlag } = this.privates;
            let { locales } = this;
            let pointDataList = getPointList(pointCategoryList);
            let validateResult = validateItemScores(pointDataList);
            if (!validateResult) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.labels.saveSpotCheck
                    )
                );
            }
            if (!evaluateFlag && this.privates.enabledEvaluate) {
                this.privates.evaluteVisibe = true;
                this.actions.update();
                return;
            }
            let { sdkOptions, match } = this.props;
            let arr = _cloneDeep(pointDataList);
            arr.forEach((item) => {
                delete item.handMarkHighlightSeqMap;
            });
            let params = {
                taskId: match.params.taskId,
                flowType: type,
                inspectionId: task.id,
                itemScores: arr,
                richRemark: task.richRemark,
                remark: task.remark,
            };
            let list = pointDataList.map((item) => {
                if (handMarkMapList[item.pointId]) {
                    let markIndices = [];
                    if (handMarkMapList[item.pointId] && handMarkMapList[item.pointId].length > 0) {
                        markIndices = handMarkMapList[item.pointId].map((item) => item.id);
                    }
                    return {
                        pointId: item.pointId,
                        // flowId: resp.data.flowId,
                        // flowType: resp.data.flowType,
                        // callId: resp.data.callId,
                        // inspectId: resp.data.inspectionId,
                        pointType: item.type,
                        pointGradeType: item.gradeType,
                        gradeChooseHit: item.gradeChooseHit,
                        markType: 1,
                        markIndices,
                    };
                } else {
                    let markIndices = null;
                    if (item.handMarkHighlightSeqMap && item.handMarkHighlightSeqMap[1]) {
                        markIndices = item.handMarkHighlightSeqMap[1];
                    }
                    return {
                        pointId: item.pointId,
                        // flowId: resp.data.flowId,
                        // flowType: resp.data.flowType,
                        // callId: resp.data.callId,
                        // inspectId: resp.data.inspectionId,
                        gradeChooseHit: item.gradeChooseHit,
                        pointType: item.type,
                        pointGradeType: item.gradeType,
                        markType: 1,
                        markIndices,
                    };
                }
            });
            let pointFlag = true;
            for (let i = 0; i < list.length; i++) {
                if (list[i].pointType === Udesk.enums.pointTypes.machineLearning.id) {
                    if (
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitAdd.id ||
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitSub.id
                    ) {
                        if (list[i].gradeChooseHit === 1) {
                            if (!list[i].markIndices || list[i].markIndices.length === 0) {
                                pointFlag = false;
                                break;
                            }
                        } else if (list[i].gradeChooseHit === 0) {
                            list[i].markIndices = null;
                        }
                    } else if (
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitNotAdd.id ||
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitNotSub.id
                    ) {
                        if (list[i].gradeChooseHit === 0) {
                            if (!list[i].markIndices || list[i].markIndices.length === 0) {
                                pointFlag = false;
                                break;
                            }
                        } else if (list[i].gradeChooseHit === 1) {
                            list[i].markIndices = null;
                        }
                    }
                }
            }
            if (!pointFlag) {
                return Udesk.ui.notify.error(locales.fix.unmarkedSelfLearningPointsTip);
            }
            let url1 = Udesk.business.apiPath.concatApiPath(`smartPointMarkResults`, sdkOptions);
            let url = Udesk.business.apiPath.concatApiPath(`inspectionData/fastSample`, sdkOptions);
            this.privates.qualityCheckScoreSaving = true;
            this.actions.update();

            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params, { headers: { uuId: uuid() } }).then(
                    (resp) => {
                        // saveRemark(task, this).then(() => {
                        //     resolve(resp);
                        // }).catch((reason) => {
                        //     reject(reason);
                        // });
                        resolve(resp);
                    },
                    (reason) => {
                        reject(reason);
                    }
                );
            })
                .then((resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            locales.labels.saveSpotCheck
                        )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    if (resp.data) {
                        let data = {
                            flowId: resp.data.id,
                            flowType: resp.data.flowType,
                            inspectId: resp.data.inspectionId,
                        };
                        let lists = list.map((item) => {
                            return {
                                ...item,
                                flowId: resp.data.id,
                                flowType: resp.data.flowType,
                                callId: resp.data.callId,
                                inspectId: resp.data.inspectionId,
                            };
                        });

                        data.smartPointMarks = lists;
                        new Promise((resolve, reject) => {
                            Udesk.ajax.post(url1, data).then(
                                (resp) => {
                                    resolve(resp);
                                    Udesk.ui.notify.success(locales.fix.markedSuccessfully);
                                    this.privates.pointCategoryList = [];
                                    this.privates.handMarkMapList = {};
                                    this.privates.pointChildrenPerson = {};
                                    this.privates.pointChildrenComputer = {};
                                    this.actions.update();
                                },
                                (reason) => {
                                    reject(reason);
                                    Udesk.ui.notify.error(locales.fix.markingFailed);
                                }
                            );
                        }).then(() => {
                            Udesk.data.init.sampleNotToNext
                                ? this.actions.reloadAsyncModel()
                                : this.actions.changePages(2, this.actions.reloadAsyncModel);
                        });
                    }
                    this.privates.resetStatus = !this.privates.resetStatus;
                    this.privates.handMarkMapList = {};
                    this.actions.update();
                    // this.actions.reloadAsyncModel();
                })
                .catch((reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                locales.labels.saveSpotCheck
                            )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    this.actions.update();
                });
            if (task.evaluateDto && task.evaluateDto.handValue) {
                let url1 = Udesk.business.apiPath.concatApiPath(
                    `call/goodOrBad/${task.callId}?goodBadFlag=${task.evaluateDto.handValue}`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.get(url1).then(
                        (resp) => {
                            resolve(resp);
                        },
                        (reason) => {
                            reject(reason);
                        }
                    );
                });
            }
        },
        activeChange(flag) {
            let { sdkOptions } = this.props;
            let activeValue = null;
            let { task, detail } = this.privates;
            if (flag === 'true') {
                this.privates.activeFlag = 'true';
                activeValue = '1';
            } else if (flag === 'false') {
                this.privates.activeFlag = 'false';
                activeValue = '2';
            }

            let url1 = Udesk.business.apiPath.concatApiPath(
                `call/goodOrBad/${task.callId}?goodBadFlag=${activeValue}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url1).then(
                    (resp) => {
                        resolve(resp);
                        this.privates.evaluteVisibe = false;
                        this.privates.evaluateFlag = true;
                        if (detail.sampleStatus !== Udesk.enums.sampleAndReviewStatus.samplingCompleted.id) {
                            this.actions.spotCheckScoreSubmitted();
                        } else if (detail.sampleStatus === Udesk.enums.sampleAndReviewStatus.samplingCompleted.id) {
                            this.actions.onQualityCheckScoreRapidSampling();
                        }
                        this.actions.update();
                    },
                    (reason) => {
                        reject(reason);
                    }
                );
            });
        },
        evaluateChange(value) {
            this.privates.evaluateFlag = value;
            this.actions.update();
        },
        evaluateSave() {
            let { detail } = this.privates;
            this.privates.evaluteVisibe = false;
            this.privates.evaluateFlag = true;
            if (detail.sampleStatus !== Udesk.enums.sampleAndReviewStatus.samplingCompleted.id) {
                this.actions.spotCheckScoreSubmitted();
            } else if (detail.sampleStatus === Udesk.enums.sampleAndReviewStatus.samplingCompleted.id) {
                this.actions.onQualityCheckScoreRapidSampling();
            }
            this.actions.update();
        },
        cancelSave() {
            this.privates.evaluteVisibe = false;
            this.actions.update();
        },
        onUpdate() {
            this.privates.resetStatus = !this.privates.resetStatus;
            this.privates.handMarkMapList = {};
            this.actions.reloadAsyncModel({ id: this.props.match.params.id });
        },
        qualityCheckScoreSaved(pointCategoryList, task, type) {
            this.privates.pointCategoryList = pointCategoryList;
            this.privates.task = task;
            this.privates.type = type;
            this.actions.update();
        },
        //相关记录查看更多
        parentLoadMore(value) {
            let { relatedDatas, detail } = this.privates;
            let { sdkOptions } = this.props;
            let relatedData = Object.assign({ id: detail.callId, pageSize: 10 }, value);
            let relatedDataUrl = Udesk.business.apiPath.concatApiPath(`data/related-data`, sdkOptions);
            new Promise((resolve, reject) => {
                this.privates.loadMoreXhr = Udesk.ajax.post(relatedDataUrl, relatedData);
                this.privates.loadMoreXhr.then(
                    (resp) => {
                        resolve(resp);
                        let list = [...relatedDatas, ...resp.data];
                        this.privates.relatedDatas = list;
                        this.privates.storages.relatedDatasPaging = resp.paging;
                        this.privates.loadMoreXhr = null;
                        this.actions.update();
                    },
                    (reason) => {
                        if (reason.statusText !== 'abort') {
                            Udesk.ui.notify.error(reason.errorMsg);
                        }
                        this.privates.relatedLoading = false;
                        this.privates.loadMoreXhr = null;
                        reject(reason);
                    }
                );
            });
        },
        handMarkMap(value) {
            this.privates.handMarkMapList = value;
            this.actions.update();
        },
        cancel() {
            this.actions.reloadAsyncModel({ id: this.props.match.params.id });
        },
        changeDialogRemarkList(newRemark) {
            const changeDialogRemarkList = (newDialogRemarkList) => {
                this.privates.dialogRemarkList = newDialogRemarkList.filter((i) => i.remark);
                if (this.privates.related) {
                    this.privates.callDetailsList.some((detail) => {
                        if (detail.callId === parseInt(this.privates.activeCallId, 10)) {
                            detail.remarkList = this.privates.dialogRemarkList;
                        }
                        return detail.callId === parseInt(this.privates.activeCallId, 10);
                    });
                }
                this.actions.update();
            };
            let { index, remark, id, type } = newRemark;
            if (id) {
                //更改逻辑
                let url = Udesk.business.apiPath.concatApiPath(`inspectionRemarks/${id}`, this.props.sdkOptions);
                let params = {
                    id: parseInt(id, 10),
                    index,
                    remark,
                    type,
                };
                return Udesk.ajax.put(url, params).then(
                    (resp) => {
                        let { dialogRemarkList } = this.privates;
                        dialogRemarkList.forEach((item) => {
                            if (item.index === index) {
                                item.remark = remark;
                            }
                        });
                        changeDialogRemarkList(dialogRemarkList.filter((i) => i.remark));
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            } else {
                //新增备注
                let url = Udesk.business.apiPath.concatApiPath(`inspectionRemarks`, this.props.sdkOptions);
                let params = {
                    dataId: parseInt(this.props.match.params.id, 10),
                    index,
                    type,
                    remark,
                    callId: this.privates.detail.callId,
                };
                return Udesk.ajax.post(url, params).then(
                    (resp) => {
                        let { data } = resp;
                        let { dialogRemarkList } = this.privates;
                        changeDialogRemarkList([...dialogRemarkList, data]);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            }
        },
        querryChangeList(smartTags) {
            this.privates.smartTags = smartTags;
            this.actions.update();
            let { sdkOptions, match } = this.props;
            let { task } = this.privates;
            let id = task.id;
            let taskId = match.params.taskId;
            let changeListUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${id}/changeList?taskId=${taskId}`,
                sdkOptions
            );
            Udesk.ajax.get(changeListUrl).then(
                (resp) => {
                    this.privates.changeList = resp.data;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        requestRelatedRecords() {
            this.privates.relatedRecordsLoading = true;
            this.actions.update();
            let { relatedDatasPaging } = this.privates.storages;
            let { sdkOptions } = this.props;
            let relatedData = {
                id: this.privates.detail.callId,
                pageNum: relatedDatasPaging.pageNum,
                pageSize: relatedDatasPaging.pageSize,
            };
            let relatedDataUrl = Udesk.business.apiPath.concatApiPath(`data/related-data`, sdkOptions);
            this.privates.xhr = Udesk.ajax.post(relatedDataUrl, relatedData);
            this.privates.xhr.then(
                (resp) => {
                    this.privates.relatedDatas = resp.data;
                    this.privates.storages.relatedDatasPaging = resp.paging;
                    this.privates.relatedRecordsLoading = false;
                    this.privates.xhr = null;
                    this.actions.update();
                },
                (reason) => {
                    if (reason.statusText !== 'abort') {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                    this.privates.relatedRecordsLoading = false;
                    this.privates.xhr = null;
                    this.actions.update();
                }
            );
        },
        trainRecords(params) {
            const { sdkOptions } = this.props;
            let { task } = this.privates;
            const caseBaseUrl = Udesk.business.apiPath.concatApiPath(`trainRecords`, sdkOptions);
            return Udesk.ajax.post(caseBaseUrl, { ...params, callId: task.callId }).then(
                (resp) => {
                    Udesk.ui.notify.success(this.locales.business.notifyMessage.correctionSuccess);
                    let newDialogSentenceList = this.privates.detail.dialogRecords.dialogSentenceList.map(
                        (item, index) => {
                            if (index === params.sentenceIndex) {
                                return {
                                    ...item,
                                    text: params.newContent,
                                };
                            }
                            return item;
                        }
                    );
                    this.privates.detail = {
                        ...this.privates.detail,
                        dialogRecords: {
                            dialogSentenceList: newDialogSentenceList,
                        },
                    };
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        //#region 重新质检
        reInspectionTypeChanged(reInspectionType) {
            this.privates.reInspectionType = reInspectionType;
            this.actions.update();
        },
        onPointsCheckedChange(points, allPoints) {
            this.privates.reInspectionPoints = points;
            this.privates.allPoints = allPoints;
            this.actions.update();
        },
        // 重新质检设置窗口
        changeReInspectionConfigVisible() {
            let { reInspectionConfigVisible } = this.privates;
            // let { conditionList } = this.privates.storages;

            // if (!reInspectionConfigVisible && conditionList.length === 0) {
            //     Udesk.ui.notify.error(this.locales.labels.pleaseSetFilter);
            //     return;
            // }
            // if (!this.privates.taskStatus) {
            //     this.setState({
            //         showModel: true,
            //     });
            //     return;
            // }
            this.privates.reInspectionConfigVisible = !reInspectionConfigVisible;
            this.privates.circlePercent = 0;
            this.actions.update();
        },
        // 重新质检
        reInspection() {
            let { reInspectionConfigVisible } = this.privates;
            // let { conditionList, judgeStrategy, customJudgeLogic, keywords } = this.privates.storages;
            let { sdkOptions, match } = this.props;

            this.privates.reInspectionConfigVisible = !reInspectionConfigVisible;
            this.actions.update();
            this.privates.progressStatus = null;
            let taskId = match.params.taskId;
            let url = Udesk.business.apiPath.concatApiPath(`inspectionData/${taskId}/recheck`, sdkOptions);
            let reCheckCondition = getReCheckCondition(this);
            if (
                reCheckCondition.reCheckType === Udesk.enums.reInspectionTypes.recheckPoint.id &&
                (!reCheckCondition.inspectionPoints || reCheckCondition.inspectionPoints.length === 0)
            ) {
                Udesk.ui.notify.error(this.locales.components.pages.qualityInspectionList.pleaseSelectRecheckPoints);
                return;
            }
            let params = {
                taskId,
                reCheckCondition,
                conditionList: [
                    {
                        field: {
                            id: 'f_200',
                            name: 'call_id',
                            label: /* 通话ID */this.locales.pages.spotCheckList.detail.component.callID,
                            fieldName: 'call_id',
                            fieldType: 2,
                            defaultValue: null,
                            unit: null,
                            optionValueList: null,
                            slotFieldName: '',
                            inspectDataSourceType: 2,
                            inspectionTaskId: null,
                            operators: null,
                            bindFieldType: null,
                        },
                        operator: 'is',
                        value: this.privates.detail.callId,
                    },
                ],
                judgeStrategy: 1,
                customJudgeLogic: '',
                systemModule: getSystemModule(),
            };
            if(this.privates.reCheckLoading) return;
            this.privates.reCheckLoading = true;
            this.actions.update();
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    this.privates.reInspectionModalVisible = true;
                    this.privates.alreadyDoneCount = 0;
                    this.privates.progressValue = 0;
                    this.privates.recheckProcessTotal = resp.data.total;
                    this.privates.progressStatus = Udesk.enums.recheckStatus.waitForExecution.id;
                    // this.privates.timerToken = setInterval(() => {
                    //     this.actions.queryReInspectionProgress(resp.data);
                    // }, 5 * 1000);
                    Udesk.ui.notify.success(/* 开始执行重新质检 */UdeskLocales['current'].pages.spotCheckList.detail.component.startPerformingReQualityInspection);
                    this.privates.reCheckLoading = false;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    this.privates.reCheckLoading = false;
                    this.actions.update();
                }
            );
        },
        handlerModalProgressCancel() {
            this.privates.reInspectionModalVisible = false;
            this.actions.update();
        },
        // 结束重新质检
        endReQualityInspection() {
            let { locales } = this;
            let { sdkOptions, match } = this.props;
            let taskId = match.params.taskId;
            let url = Udesk.business.apiPath.concatApiPath(`inspectionData/${taskId}/recheck/stop`, sdkOptions);
            let params = {
                id: taskId,
            };
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    Udesk.ui.notify.success(locales.components.pages.qualityInspectionList.endReInspectionSuccess);
                    this.actions.closeReInspection();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        // 关闭重新质检窗口
        closeReInspection(bool) {
            this.privates.reInspectionModalVisible = false;
            this.actions.update();
            let timerToken = this.privates.timerToken;
            if (timerToken != null) {
                clearInterval(timerToken);
            }
            if (bool && bool === true) {
                this.actions.reloadAsyncModel();
            }
        },
        //#endregion
    };

    //#region Life Cycle
    init() {
        this.privates.isWechatQa = this.props.route.name === 'wechatSpotCheckListDetail';
    }
    componentDidUpdate(prevProps) {
        const { props } = this;
        if (props.match.params.id !== prevProps.match.params.id) {
            this.privates.storages.relatedDatasPaging = { ...RelatedDatasPaging };
            this.privates.relatedDatas = [];
            this.privates.wrapDom.current.scrollTop = 0;
            this.privates.xhr && this.privates.xhr.abort && this.privates.xhr.abort();
            this.privates.loadMoreXhr && this.privates.loadMoreXhr.abort && this.privates.loadMoreXhr.abort();

            let historySearch = props.history.location.search;
            let inspectionId = null;
            if (historySearch) {
                inspectionId = historySearch.split('=')[1];
            }
            this.actions.reloadAsyncModel({ id: props.match.params.id, inspectionId });
        }
    }
    // parseProps({ props, prevProps }) {
    //     if (props.match.params.id !== prevProps.match.params.id) {
    //         this.privates.storages.relatedDatasPaging = {...RelatedDatasPaging};
    //         this.privates.relatedDatas = [];
    //         this.privates.wrapDom.current.scrollTop = 0;
    //         this.privates.xhr && this.privates.xhr.abort && this.privates.xhr.abort();
    //         this.privates.loadMoreXhr && this.privates.loadMoreXhr.abort && this.privates.loadMoreXhr.abort();

    //         let historySearch = props.history.location.search;
    //         let inspectionId = null;
    //         if (historySearch) {
    //             inspectionId = historySearch.split("=")[1];
    //         }
    //         if(timer){
    //             clearTimeout(timer);
    //         }
    //         timer = setTimeout(() => {
    //             this.actions.reloadAsyncModel({ id: props.match.params.id, inspectionId });
    //         }, 300);

    //     }
    // }
    componentDidMount() {}
    componentWillUnmount() {}
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'detailWithRelateData') {
            this.privates.relatedLoading = false;
            this.privates.pointChildrenComputer = [];
            this.privates.pointChildrenPerson = [];
            if (asyncResult && asyncResult.detail && asyncResult.detail.data) {
                if (asyncResult.detail.data.evaluateDto && asyncResult.detail.data.evaluateDto.handValue) {
                    this.privates.evaluateFlag = true;
                }
                this.privates.dialogRemarkList = asyncResult.detail.data.remarkList || [];
                this.privates.detail = asyncResult.detail.data;
                this.privates.task = this.privates.detail;
                this.privates.pointCategoryList = this.privates.detail.pointCategoryList;
                if (this.privates.pointCategoryList && this.privates.pointCategoryList.length > 0) {
                    this.privates.pointCategoryList.forEach((item) => {
                        if (item.children) {
                            item.children.forEach((items) => {
                                if (items.pointDataList) {
                                    items.pointDataList.forEach((itemss) => {
                                        if (itemss.handMarkHighlightSeqMap) {
                                            if (itemss.handMarkHighlightSeqMap[1]) {
                                                itemss.handMarkHighlightSeqMap[1].forEach((itemsss) => {
                                                    if (this.privates.pointChildrenPerson[itemsss]) {
                                                        this.privates.pointChildrenPerson[itemsss].push({
                                                            id: itemss.pointId,
                                                            name: itemss.name,
                                                        });
                                                    } else {
                                                        this.privates.pointChildrenPerson = {
                                                            ...this.privates.pointChildrenPerson,
                                                            [itemsss]: [
                                                                {
                                                                    id: itemss.pointId,
                                                                    name: itemss.name,
                                                                },
                                                            ],
                                                        };
                                                    }
                                                });
                                            }
                                            if (itemss.handMarkHighlightSeqMap[2]) {
                                                itemss.handMarkHighlightSeqMap[2].forEach((itemsss) => {
                                                    if (this.privates.pointChildrenComputer[itemsss]) {
                                                        this.privates.pointChildrenComputer[itemsss].push({
                                                            id: itemss.pointId,
                                                            name: itemss.name,
                                                        });
                                                    } else {
                                                        this.privates.pointChildrenComputer = {
                                                            ...this.privates.pointChildrenComputer,
                                                            [itemsss]: [
                                                                {
                                                                    id: itemss.pointId,
                                                                    name: itemss.name,
                                                                },
                                                            ],
                                                        };
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                this.actions.update();
            }
            // if (asyncResult && asyncResult.relateData && asyncResult.relateData.data) {
            //     this.privates.relatedDatas = asyncResult.relateData.data;
            //     this.privates.storages.relatedDatasPaging = asyncResult.relateData.paging;
            //     this.actions.update();
            // }
        } else if (asyncKey === 'changeList') {
            if (asyncResult && asyncResult.data) {
                this.privates.changeList = asyncResult.data;
                this.actions.update();
            }
        } else if (asyncKey === 'smartTags') {
            if (asyncResult && asyncResult.data) {
                this.privates.smartTags = asyncResult.data;
                this.actions.update();
            }
        } else if (asyncKey === 'caseBase') {
            if (asyncResult && asyncResult.data) {
                this.privates.caseBase = asyncResult.data;
                this.actions.update();
            }
        } else if (asyncKey === 'checkPoints') {
            if (asyncResult) {
                let reInspectionPoints = asyncResult;
                reInspectionPoints = reInspectionPoints.filter(
                    (current) => current.children && current.children.length > 0
                );
                for (let i = 0; i < reInspectionPoints.length; i++) {
                    let firstCategory = reInspectionPoints[i];
                    if (firstCategory.children && firstCategory.children.length > 0) {
                        for (let j = 0; j < firstCategory.children.length; j++) {
                            let secondCategory = firstCategory.children[j];
                            secondCategory.points = secondCategory.points.filter(
                                (item) => item.type !== Udesk.enums.pointTypes.manual.id
                            );
                            for (let index = 0; index < secondCategory.points.length; index++) {
                                secondCategory.points[index]._checked = true;
                            }
                        }
                    }
                }
                this.privates.reInspectionPoints = reInspectionPoints;
                this.actions.update();
            }
        }
        if (asyncModel.sysConfig) {
            this.privates.enabledEvaluate = asyncModel.sysConfig.enabledEvaluate;
            this.actions.update();
        }
        this.privates.renderLoading = false;
        this.actions.update();
    }
    //#endregion
}

function getPointList(pointCategoryList) {
    let pointDataList = [];
    pointCategoryList.forEach((firstCategory) => {
        let secondCategorys = firstCategory.children || [];
        secondCategorys.forEach((secondCategory) => {
            if (secondCategory.pointDataList) {
                pointDataList = pointDataList.concat(secondCategory.pointDataList);
            }
        });
    });

    return pointDataList;
}

function scrollToPoint(pointId) {
    // quality-check-talk-point-5656
    const containers = document.getElementsByClassName('quality-check-score-talk-body-content');
    if (containers && containers[0]) {
        // const container = containers[0];

        const hitPoint = document.getElementById(`quality-check-talk-point-${pointId}`);
        hitPoint?.scrollIntoViewIfNeeded?.();
        hitPoint.classList.add('highlight');
        setTimeout(() => {
            hitPoint.classList.remove('highlight');
        }, 2000);
    }
}

function validateItemScores(items) {
    let validateResult = true;
    if (items) {
        if (items.length === 0) {
            validateResult = true;
        }
        if (items.length > 0) {
            items.forEach((item) => {
                if(item.ruleType === Udesk.enums.ruleTypes.flow.id) return;
                if (item.naChecked !== 1) {
                    if (item.type !== Udesk.enums.pointTypes.machineLearning.id) {
                        if (
                            item.gradeType === Udesk.enums.pointGradeTypes.radio.id ||
                            item.gradeType === Udesk.enums.pointGradeTypes.foctors.id
                        ) {
                            if (item.gradeChooseHit == null) {
                                validateResult = false;
                                scrollToPoint(item.pointId);
                            }
                        } else if (item.gradeType === Udesk.enums.pointGradeTypes.input.id) {
                            if (item.gradeInput == null || item.gradeInput === '') {
                                validateResult = false;
                                scrollToPoint(item.pointId);
                            }
                        } else if (item.gradeType === Udesk.enums.pointGradeTypes.select.id) {
                            if (item.pointHitValue == null || item.pointHitValue === '') {
                                validateResult = false;
                                scrollToPoint(item.pointId);
                            }
                        }
                    } else {
                        if (item.gradeChooseHit == null) {
                            validateResult = false;
                            scrollToPoint(item.pointId);
                        }
                    }
                }
            });
        }
        return validateResult;
    }
    return validateResult;
}

// function saveRemark(task, that) {
//     if (!task.remarkChanged) {
//         return Promise.resolve();
//     }
//     let {
//         sdkOptions
//     } = that.props;
//     let url = Udesk.business.apiPath.concatApiPath(`inspectionData/${task.id}/gradeRemark`, sdkOptions);
//     let params = { remark: task.remark, richRemark: task.richRemark };
//     return new Promise((resolve, reject) => {
//         Udesk.ajax.put(url, params)
//             .then(
//                 (resp) => {
//                     resolve(resp);
//                 },
//                 (reason) => {
//                     reject(reason);
//                 }
//             );
//     });
// }
function uuid() {
    var s = [];
    var hexDigits = '0123456789abcdef';
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';

    var uuid = s.join('');
    return uuid;
}
export default SpotCheckListDetailComponent;

function getReCheckCondition(that) {
    let { reInspectionType, reInspectionPoints } = that.privates;
    if (reInspectionType === Udesk.enums.reInspectionTypes.recalcScore.id) {
        return { reCheckType: reInspectionType };
    } else {
        let inspectionPointCategories = [];
        let inspectionPoints = [];
        for (let i = 0; i < reInspectionPoints.length; i++) {
            let firstCategory = reInspectionPoints[i];
            for (let j = 0; j < firstCategory.children.length; j++) {
                let secondCategory = firstCategory.children[j];
                for (let index = 0; index < secondCategory.points.length; index++) {
                    let point = secondCategory.points[index];
                    if (point._checked) {
                        inspectionPoints.push(point.id);
                        if (!inspectionPointCategories.includes(firstCategory.id))
                            inspectionPointCategories.push(firstCategory.id);
                        if (!inspectionPointCategories.includes(secondCategory.id))
                            inspectionPointCategories.push(secondCategory.id);
                    }
                }
            }
        }
        return {
            reCheckType: reInspectionType,
            inspectionPointCategories,
            inspectionPoints,
        };
    }
}