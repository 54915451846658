import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { FormBuilder, Space, Button, Upload as UdeskUpload, Avatar, Select, Input } from 'udesk-ui';
// import './style.scss';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import { getRoles } from 'src/api/roles';
import { postUsers } from 'src/api/users';
import { putUsersById } from 'src/api/users/{id}';
import { getUsersByUuid as getUsersById } from 'src/api/users/{uuid}';
import { BaseResponseUserFoundResponse } from 'src/api/types';
import { getAgentScreen } from 'src/api/agent/screen';
import UdeskList from 'Component/common/udesk-list';
import { getReviewWecomUser } from 'src/api/review/wecom/user';
import { RegExpPassword } from 'src/util/regExp';
import { encryptByRSA, PUBLICKEY } from 'src/util/crypt';

const locales = UdeskLocales['current'];
const MAX_PICTURE_SIZE = 500;
interface DataNode {
    title: string;
    key: string;
    isLeaf?: boolean;
    children?: DataNode[];
    icon?: any;
    userId?: string;
}

const Upload = (props) => {
    const { onChange, value, onUploadData } = props;
    const handlerUpload = (file) => {
        if (file.size / 1024 > MAX_PICTURE_SIZE) {
            return Udesk.ui.notify.error(locales.pages.gong.userManage.edit.avatarGreaterThan500kb);
        }
        Udesk.app.fileStorage.upload(file, {
            token: new Promise((resolve, reject) => {
                Udesk.business.getServerAuthorization(Udesk.enums.uploadFileTypes.image.id).then(
                    function (resp) {
                        resolve(resp);
                    },
                    function (reason) {
                        reject(reason);
                    }
                );
            }),
            //progressCallback: progressCallback.bind(this),
            successCallback: (file, uploadData) => {
                Udesk.ui.notify.success(locales.pages.gong.userManage.edit.uploadSuccessful);
                let fileUrl = `${uploadData.token.host}/${uploadData.key}`;
                onUploadData(uploadData.filename);
                onChange(fileUrl);
            },
            //errorCallback: uploadFileError.bind(this),
        });
        return false;
    };
    return (
        <Space direction="vertical">
            <Space>
                <UdeskUpload
                    accept=".jpg,.gif,.png"
                    showUploadList={false}
                    beforeUpload={handlerUpload}
                >
                    <Button type="link">{locales.pages.gong.userManage.edit.upload}</Button>
                </UdeskUpload>
                {!!value && <Avatar src={value} />}
            </Space>
            <p>{locales.pages.gong.userManage.edit.supportJPGGIFAndPNGUpTo500kb}</p>
        </Space>
    );
};

const AgentOptionSource = [
    {
        label: /* Udesk客服系统身份 */ locales.pages.gong.userManage.edit
            .udeskCustomerServiceSystemIdentity,
        value: 1,
    },
    {
        label: /* 企业微信身份 */ locales.pages.gong.userManage.edit.enterpriseWechatIdentity,
        value: 2,
    },
    { label: /* 输入标识 */ locales.pages.gong.userManage.edit.inputID, value: 3 },
];

type AgentItem = {
    type: 1 | 2 | 3 | undefined;
    value: string | undefined;
};

const AgentIdInput = (props: { value: AgentItem[]; onChange: (data: AgentItem[]) => void }) => {
    const { onChange, value: data = [] } = props;
    const [agentOptions, setAgentOptions] = useState<any[]>([]);
    const [weComUserOptions, setWeComUserOptions] = useState<any[]>([]);

    const addItem = useCallback(() => {
        const temp = [
            ...data,
            {
                type: undefined,
                value: undefined,
            },
        ];
        onChange(temp);
    }, [data, onChange]);

    const delItem = useCallback(
        (index: number) => {
            const temp = [...data];
            temp.splice(index, 1);
            onChange(temp);
        },
        [data, onChange]
    );

    const updateItem = useCallback(
        (item, index) => {
            const temp = [...data];
            temp.splice(index, 1, {
                ...temp[index],
                ...item,
            });
            onChange(temp);
        },
        [data, onChange]
    );

    const getSelectOptions = useCallback(
        (index: number) => {
            const list = new Array<number>();

            data.forEach((item, i) => {
                if (i !== index) {
                    if (item.type) {
                        list.push(item.type);
                    }
                }
            });

            return AgentOptionSource.filter((item) => {
                return !list.includes(item.value);
            });
        },
        [data]
    );

    useEffect(() => {
        Promise.all([
            getReviewWecomUser({
                params: {
                    keyword: '',
                },
            }),
            getAgentScreen({
                params: {
                    keyWord: '',
                },
            }),
        ]).then(([r0, r1]) => {
            setWeComUserOptions(
                (r0.data ?? []).map((i) => ({
                    label: `${i.nickname}(${i.userId})`,
                    value: i?.id?.toString(),
                }))
            );
            setAgentOptions((r1.data ?? []).map((i) => ({ label: i.agentName, value: i.agentNo })));
        });
    }, []);

    useEffect(() => {}, [data]);

    return (
        <UdeskList
            addBtnText={/* 新增身份绑定 */ locales.pages.gong.userManage.edit.newIdentityBinding}
            addItem={addItem}
            deleteItem={delItem}
            addBtnDisabled={data.length > 2}
        >
            {data.map(({ type, value }, index) => (
                <Space>
                    <Select
                        style={{ width: 150 }}
                        options={getSelectOptions(index)}
                        onChange={(type) => updateItem({ type, value: undefined }, index)}
                        value={type}
                    ></Select>
                    {(type || 3) < 3 ? (
                        <Select
                            style={{ width: 300 }}
                            showSearch
                            options={type === 1 ? agentOptions : weComUserOptions}
                            onSearch={(value) => {
                                getAgentScreen({
                                    params: {
                                        keyWord: value,
                                    },
                                }).then((resp) =>
                                    setAgentOptions(
                                        (resp.data ?? []).map((i) => ({
                                            label: i.agentName,
                                            value: i.agentNo,
                                        }))
                                    )
                                );
                            }}
                            value={value}
                            onChange={(value) => updateItem({ value }, index)}
                            filterOption={false}
                        ></Select>
                    ) : (
                        <Input
                            style={{ width: 300 }}
                            value={value}
                            onChange={(e) => updateItem({ value: e.target.value }, index)}
                        />
                    )}
                </Space>
            ))}
        </UdeskList>
    );
};

const AdminGongUserEdit = React.memo((props: any) => {
    const [form] = FormBuilder.useForm();
    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const [roleList, setRoleList] = useState<any[]>([]);
    const [currentId, setCurrentId] = useState<number | null>(null);
    const [headImgUrl, setHeadImgUrl] = useState('');
    let inspectDataSources = [
        { id: 1, key: 'yes', name: locales.pages.gong.userManage.edit.yes },
        { id: 0, key: 'no', name: locales.pages.gong.userManage.edit.no },
    ];
    const [currentRoleId, setCurrentRoleId] = useState<number | undefined>(undefined);
    const roleSysModuleHasSalesMap = useMemo(() => {
        return roleList.reduce((prev, cur) => {
            prev[cur.id] = cur.sysModuleList.find(
                (i) => i.type === Udesk.enums.sysModuleList.sales.id
            )?.status;
            return prev;
        }, {});
    }, [roleList]);
    const roleSysModuleHasQaMap = useMemo(() => {
        return roleList.reduce((prev, cur) => {
            prev[cur.id] = cur.sysModuleList.find(
                (i) => i.type === Udesk.enums.sysModuleList.qa.id
            )?.status;
            return prev;
        }, {});
    }, [roleList]);
    const isEdit = props.match.params.id !== '-1';
    const config: FormProps = {
        labelCol: {
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            sm: {
                span: 16,
            },
        },
        btnsCol: {
            span: 16,
            offset: 4,
        },
        customComponents: {
            Upload,
            AgentIdInput,
        },
        fields: [
            {
                name: 'email',
                label: locales.pages.gong.userManage.edit.accountNumber,
                type: 'Input',
                rules: [
                    {
                        required: true,
                        message: locales.pages.gong.userManage.edit.pleaseEnterTheAccountNumber,
                    },
                ],
            },
            {
                name: 'password',
                label: locales.pages.gong.userManage.edit.initialPassword,
                type: 'Password',
                forceHidden: isEdit,
                rules: [
                    {
                        required: true,
                        message: locales.pages.gong.userManage.edit.pleaseInputAPassword,
                    },
                    {
                        pattern: RegExpPassword,
                        message: '请输入8-16位大小写字母与数字和特殊字符组合的密码！',
                    },
                ],
            },
            {
                name: 'confirm',
                label: locales.pages.gong.userManage.edit.confirmPassword,
                type: 'Password',
                forceHidden: isEdit,
                rules: [
                    {
                        required: true,
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                locales.pages.gong.userManage.edit
                                    .thePasswordsEnteredTwiceAreDifferent
                            );
                        },
                    }),
                ],
            },
            {
                name: 'roleId',
                label: locales.pages.gong.userManage.edit.role,
                type: 'Select',
                rules: [
                    {
                        required: true,
                        message: locales.pages.gong.userManage.edit.pleaseSelectARole,
                    },
                ],
                props: {
                    options: roleList.map((i) => ({ label: i.name, value: i.id })),
                    onChange: setCurrentRoleId,
                },
            },
            {
                name: 'headPortraitUrl',
                label: locales.pages.gong.userManage.edit.headPortrait,
                type: 'Upload',
                props: {
                    onUploadData(data) {
                        setHeadImgUrl(data);
                    },
                },
            },
            {
                name: 'realname',
                label: locales.pages.gong.userManage.edit.fullName,
                type: 'Input',
                rules: [
                    {
                        required: true,
                        message: locales.pages.gong.userManage.edit.pleaseEnterYourName,
                    },
                ],
            },
            {
                name: 'nickname',
                label: locales.pages.gong.userManage.edit.nickname,
                type: 'Input',
            },
            {
                name: 'organizationId',
                label: locales.pages.gong.userManage.edit.department,
                type: 'TreeSelect',
                props: {
                    showSearch: treeData,
                    treeData: treeData,
                    treeNodeFilterProp: 'title',
                    treeDefaultExpandAll: true,
                },
            },
            {
                name: 'isExample',
                label: locales.pages.gong.userManage.edit.excellentStaff,
                type: 'Select',
                forceHidden: !currentRoleId || !roleSysModuleHasSalesMap[currentRoleId],
                props: {
                    options: inspectDataSources.map((i) => ({ label: i.name, value: i.id })),
                },
            },
            // {
            //     name: 'callIdentification',
            //     label: locales.pages.gong.userManage.edit.sessionIdentity,
            //     type: 'Input',
            //     tooltip: locales.pages.gong.userManage.edit.markTheSessionRecordAsBelongingToTheEmployee,
            // },
            {
                name: 'mobile',
                label: locales.pages.gong.userManage.edit.telephone,
                type: 'Input',
            },
            {
                name: 'agentId',
                label: /* 工号 */ UdeskLocales['current'].pages.gong.userManage.edit.jobNo,
                type: 'Input',
                forceHidden: !currentRoleId || !roleSysModuleHasQaMap[currentRoleId],
                rules: [{ required: true }],
            },
            {
                name: 'identityDtoList',
                label: /* 身份绑定 */ locales.pages.gong.userManage.edit.identityBinding,
                type: 'AgentIdInput',
                forceHidden: !currentRoleId || !roleSysModuleHasSalesMap[currentRoleId],
                props: {
                    isEdit,
                },
            },
        ],
        footerRender: () => (
            <Space>
                <Button type="primary" htmlType="submit">
                    {locales.pages.gong.userManage.edit.preservation}
                </Button>
                <Button onClick={() => window.history.back()}>
                    {locales.pages.gong.userManage.edit.cancel}
                </Button>
            </Space>
        ),
        onFinish: (values) => {
            let roleListData: any = [];
            const params: any = {
                ...values,
                headPortraitUrl: headImgUrl,
                roleList: [],
            };
            params.password = encryptByRSA(PUBLICKEY, params.password);
            params.confirm = encryptByRSA(PUBLICKEY, params.confirm);
            roleList.forEach((item) => {
                if (item.id === values.roleId) {
                    roleListData.push(item);
                }
            });
            params.roleList = roleListData;

            if (!isEdit) {
                postUsers(params, {
                    successMsg: locales.pages.gong.userManage.edit.addedSuccessfully,
                }).then((res) => {
                    window.history.back();
                });
            } else {
                currentId &&
                    putUsersById(params, {
                        segments: { id: currentId },
                        successMsg: locales.pages.gong.userManage.edit.modificationSucceeded,
                    }).then((res) => {
                        window.history.back();
                    });
            }
        },
    };
    const getRolesData = () => {
        getRoles().then((res) => {
            setRoleList(res.data ?? []);
        });
    };
    const queryTree = () => {
        let url = Udesk.business.apiPath.concatApiPath(`organizations`, props.sdkOptions);
        Udesk.ajax.get(url, {}).then(
            (resp) => {
                const newTreeData = getNode(resp.data);
                if (!isEdit) {
                    form.setFieldsValue({
                        organizationId: newTreeData[0]?.key,
                    });
                }

                setTreeData(newTreeData);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    useEffect(() => {
        getRolesData();
        queryTree();
        if (isEdit) {
            getUsersById({ segments: { uuid: props.match.params.id } }).then(
                (res: BaseResponseUserFoundResponse) => {
                    const { data } = res;
                    if (data) {
                        setCurrentId(data.id ?? null);
                        data.identityDtoList =
                            data?.identityDtoList?.filter(
                                ({ value }) => !['', null, undefined].includes(value)
                            ) || [];
                        setHeadImgUrl(data.headPortraitUrl ?? '');
                        const roleId = data.roleList?.[0]?.id;
                        form.setFieldsValue({
                            ...data,
                            roleId,
                        });
                        setCurrentRoleId(roleId);
                    }
                }
            );
        }
    }, []);
    return (
        <Page
            pageBodyClassName="page-gong-user-manage-edit"
            onBack={() => window.history.back()}
            title={
                isEdit
                    ? locales.pages.gong.userManage.edit.editEmployee
                    : locales.pages.gong.userManage.edit.newEmployees
            }
            padding={true}
        >
            <FormBuilder {...config} form={form}></FormBuilder>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <AdminGongUserEdit {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

const getNode = (arr = []) => {
    if (!arr || !arr.length) return [];
    const sortedArr = arr
        .map((i: any) => ({
            ...i,
            title: i.name,
            key: i.id,
            parentId: i.parentId * 1,
        }))
        .sort((a, b) => a.parentId - b.parentId);
    let root = sortedArr.filter((i) => i.parentId === sortedArr[0].parentId);
    let nodeMap = {};
    sortedArr.forEach((item) => {
        nodeMap[item.id] = item;
        if (nodeMap[item.parentId]) {
            if (Array.isArray(nodeMap[item.parentId].children)) {
                nodeMap[item.parentId].children.push(item);
            } else {
                nodeMap[item.parentId].children = [item];
            }
        }
    });
    return [...root];
};
