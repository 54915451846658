// 主界面
import Home from '../../pages/home/index';

// 语义智能-1、智能词库
import SmartWordLibrary from 'Component/pages/semantic-intelligence/smart-words-library';
import AttentionWords from 'Component/pages/semantic-intelligence/smart-words-library/attention-words';
import IgnoreWords from 'Component/pages/semantic-intelligence/smart-words-library/ignore-words';
import ProfessionalWords from 'Component/pages/semantic-intelligence/smart-words-library/professional-words';
import SynonymlWords from 'Component/pages/semantic-intelligence/smart-words-library/synonym-words';
import SensitiveWords from 'Component/pages/semantic-intelligence/smart-words-library/sensitive-words';
import WordsWeight from 'Component/pages/semantic-intelligence/smart-words-library/words-weight';
import CorpusData from 'Component/pages/semantic-intelligence/smart-words-library/corpus-data';
import TypoLibrary from 'Component/pages/semantic-intelligence/smart-words-library/typo-library';

// 语义智能-2、语义标签
import SemanticIntelligence from 'Component/pages/algorithm-semantic-intelligence';
import SemanticTags from 'Component/pages/semantic-intelligence/semantic-tags';
import SemanticTagsIndex from 'Component/pages/semantic-intelligence/semantic-tags/index';
import SemanticTagsDetail from 'Component/pages/semantic-intelligence/semantic-tags/detail';

// 语义智能-3、信息实体
import InformationEntities from 'Component/pages/semantic-intelligence/information-entities';
import InformationEntitiesIndex from 'Component/pages/semantic-intelligence/information-entities/index';
import InformationEntitiesDetail from 'Component/pages/semantic-intelligence/information-entities/detail';

// 语义智能-4、交互识别
import InteractiveRecognition from 'Component/pages/semantic-intelligence/interactive-recognition';
import InteractiveRecognitionIndex from 'Component/pages/semantic-intelligence/interactive-recognition/index';
import InteractiveRecognitionDetail from 'Component/pages/semantic-intelligence/interactive-recognition/detail';

// 知识库
import KnowledgeBase from 'Component/pages/semantic-intelligence/knowledge-base';
import KnowledgeBaseIndex from 'Component/pages/semantic-intelligence/knowledge-base/index';
import KnowledgeBaseDetail from 'Component/pages/semantic-intelligence/knowledge-base/detail';

//语义智能-6、概念词
import ConceptWord from 'Component/pages/semantic-intelligence/concept-word';
import ConceptWordIndex from 'Component/pages/semantic-intelligence/concept-word/index';
import ConceptWordDetail from 'Component/pages/semantic-intelligence/concept-word/detail';

import TopicList from 'Component/pages/gong/topic-list';
import TopicCallout from 'Component/pages/gong/topic-callout';

//训练中心
import TrainingCenter from 'Component/pages/algorithm-training-center';
import FastTrainingIndex from 'Component/pages/training-center/fast-training';
import TrainingHistoryIndex from 'Component/pages/training-center/training-history';
//分类训练
import ClassifiedTraining from 'Component/pages/training-center/classified-training';
import ClassifiedTrainingIndex from 'Component/pages/training-center/classified-training/index';
import ClassifiedTrainingDetail from 'Component/pages/training-center/classified-training/detail/index';
//分类训练-训练历史
import ClassifiedTrainingHistoryIndex from 'Component/pages/training-center/classified-training-history/index';
//聚类标注
import ClusteringAnnotationIndex from 'Component/pages/training-center/clustering-annotation';
// 语义智能-7、聚类分析
import ClusteringAnalysis from 'Component/pages/semantic-intelligence/clustering-analysis';
import ClusteringAnalysisIndex from 'Component/pages/semantic-intelligence/clustering-analysis/index';
import ClusteringAnalysisCreate from 'Component/pages/semantic-intelligence/clustering-analysis/add';
import ClusteringAnalysisDetail from 'Component/pages/semantic-intelligence/clustering-analysis/detail';

// 语义智能-8、话术挖掘
import WordsMining from 'Component/pages/semantic-intelligence/words-mining';
import DissentWordsMining from 'Component/pages/semantic-intelligence/words-mining/dissent';
import DissentFavoriteWordsMining from 'Component/pages/semantic-intelligence/words-mining/dissent/favorite';
import SellingPointFavoriteWordsMining from 'Component/pages/semantic-intelligence/words-mining/selling-point/favorite';
import SellingPointWordsMining from 'Component/pages/semantic-intelligence/words-mining/selling-point';

//数据预处理
import DataPreprocess from 'Component/pages/intelligent-analysis/data-preprocess';
import DataPreprocessIndex from 'Component/pages/intelligent-analysis/data-preprocess/index';
import DataPreprocessDetail from 'Component/pages/intelligent-analysis/data-preprocess/detail';



import IntelligentExtractors from 'Component/pages/intelligent-analysis/intelligent-extractors';
import IntelligentExtractorsIndex from 'Component/pages/intelligent-analysis/intelligent-extractors/index';

//404
import PageNotFound from '../../pages/404';


const algorithmRouteComponentByRouteNameMappings = {
    // 主界面
    algorithmHome: {
        component: Home,
    },
    // 语义智能-1、智能词库
    algorithmSmartWordsLibrary: {
        component: SmartWordLibrary,
    },
    algorithmSmartWordsLibraryAttentionWords: {
        component: AttentionWords,
    },
    algorithmSmartWordsLibraryProfessionalWords: {
        component: ProfessionalWords,
    },
    algorithmSmartWordsLibrarySynonymWords: {
        component: SynonymlWords,
    },
    algorithmSmartWordsLibrarySensitiveWords: {
        component: SensitiveWords,
    },
    algorithmSmartWordsLibraryWordsWeight: {
        component: WordsWeight,
    },
    algorithmSmartWordsLibraryIgnoreWords: {
        component: IgnoreWords,
    },
    algorithmSmartWordsLibraryCorpusData: {
        component: CorpusData,
    },
    algorithmSmartWordsLibraryTypoLibrary: {
        component: TypoLibrary,
    },
    // 语义智能-2、语义标签
    algorithmSemanticIntelligence: {
        component: SemanticIntelligence,
    },
    algorithmSemanticTags: {
        component: SemanticTags,
    },
    algorithmSemanticTagsIndex: {
        component: SemanticTagsIndex,
    },
    algorithmSemanticTagsNew: {
        component: SemanticTagsDetail,
    },
    algorithmSemanticTagsEdit: {
        component: SemanticTagsDetail,
    },
    //语义智能-3、信息实体
    algorithmInformationEntities: {
        component: InformationEntities,
    },
    algorithmInformationEntitiesIndex: {
        component: InformationEntitiesIndex,
    },
    algorithmInformationEntitiesNew: {
        component: InformationEntitiesDetail,
    },
    algorithmInformationEntitiesEdit: {
        component: InformationEntitiesDetail,
    },

    //语义智能-4、交互识别
    algorithmInteractiveRecognition: {
        component: InteractiveRecognition,
    },
    algorithmInteractiveRecognitionIndex: {
        component: InteractiveRecognitionIndex,
    },
    algorithmInteractiveRecognitionNew: {
        component: InteractiveRecognitionDetail,
    },
    algorithmInteractiveRecognitionEdit: {
        component: InteractiveRecognitionDetail,
    },
    algorithmKnowledgeBase: {
        component: KnowledgeBase,
    },
    algorithmKnowledgeBaseIndex: {
        component: KnowledgeBaseIndex,
    },
    algorithmKnowledgeBaseNew: {
        component: KnowledgeBaseDetail,
    },
    algorithmKnowledgeBaseEdit: {
        component: KnowledgeBaseDetail,
    },
    //语义智能-6、概念词
    algorithmConceptWord: {
        component: ConceptWord,
    },
    algorithmConceptWordIndex: {
        component: ConceptWordIndex,
    },
    algorithmConceptWordNew: {
        component: ConceptWordDetail,
    },
    algorithmConceptWordEdit: {
        component: ConceptWordDetail,
    },
    algorithmTrainingCenter: {
        component: TrainingCenter,
    },
    algorithmFastTrainingIndex: {
        component: FastTrainingIndex,
    },
    algorithmTrainingHistoryIndex: {
        component: TrainingHistoryIndex,
    },
    //分类训练
    algorithmClassifiedTraining: {
        component: ClassifiedTraining,
    },
    algorithmClassifiedTrainingIndex: {
        component: ClassifiedTrainingIndex,
    },
    algorithmClassifiedTrainingDetail: {
        component: ClassifiedTrainingDetail,
    },
    algorithmClassifiedTrainingHistoryIndex: {
        component: ClassifiedTrainingHistoryIndex,
    },
    algorithmClusteringAnnotationIndex: {
        component: ClusteringAnnotationIndex,
    },
    algorithmTopicList: {
        component: TopicList,
    },
    algorithmTopicCallout: {
        component: TopicCallout,
    },
    //语义智能-7、聚类分析
    algorithmClusteringAnalysis: {
        component: ClusteringAnalysis,
    },
    algorithmClusteringAnalysisIndex: {
        component: ClusteringAnalysisIndex,
    },
    algorithmClusteringAnalysisNew: {
        component: ClusteringAnalysisCreate,
    },
    algorithmClusteringAnalysisEdit: {
        component: ClusteringAnalysisCreate,
    },
    algorithmClusteringAnalysisDetail: {
        component: ClusteringAnalysisDetail,
    },
    //语义智能-8、话术挖掘
    algorithmWordsMining: {
        component: WordsMining,
    },
    algorithmWordsMiningView: {
        component: WordsMining,
    },
    algorithmDissentWordsMining: {
        component: DissentWordsMining,
    },
    algorithmDissentFavoriteWordsMining: {
        component: DissentFavoriteWordsMining
    },
    algorithmSellingPointFavoriteWordsMining: {
        component: SellingPointFavoriteWordsMining
    },
    algorithmSellingPointWordsMining: {
        component: SellingPointWordsMining,
    },
    // 数据预处理
    algorithmDataPreprocess: {
        component: DataPreprocess,
    },
    algorithmDataPreprocessIndex: {
        component: DataPreprocessIndex,
    },
    algorithmDataPreprocessNew: {
        component: DataPreprocessDetail,
    },
    algorithmDataPreprocessEdit: {
        component: DataPreprocessDetail,
    },
    salesPageNotFound: {
        component: PageNotFound,
    },
    // 智能抽取
    algorithmIntelligentExtractors: {
        component: IntelligentExtractors,
    },
    algorithmIntelligentExtractorsIndex: {
        component: IntelligentExtractorsIndex,
    },
};

export {
    algorithmRouteComponentByRouteNameMappings
};