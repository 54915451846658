function initAjaxPrefilter() {
    $.ajaxPrefilter('json', function (options, originalOptions, jsXHR) {
        // options.xhrFields = {
        //     withCredentials: true,
        // };
        jsXHR.fail(function (jsXhr, textStatus, errorThrown) {
            switch (jsXhr.status) {
                case 401:
                    if (window.location.pathname !== '/auth/login') {
                        let referUrl = encodeURIComponent(window.location.pathname + window.location.search);
                        window.location.href = `${window.location.origin}/auth/login?refer=${referUrl}`;
                    }
                    break;
                default:
                    break;
            }
        });
    });
}

export default initAjaxPrefilter;
