import React, { useEffect } from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
import './style.scss';

import 'reactflow/dist/style.css';
import { ConfigDrawer } from './components/ConfigDrawer';
// import { postReviewIntelligentPartnerCourse } from 'src/api/review/intelligentPartner/course';
import { Button, Icon } from 'udesk-ui';
import { CustomerPortraitConfig } from 'Component/pages/components/coach/CustomerPortrait';
import { CustomerPortraitConfigDrawer } from './components/CustomerPortraitConfigDrawer';
import { Flow } from './components/Flow/index';
import { GlobalConfigDrawer } from './components/GlobalConfigDrawer';
import { postReviewIntelligentPartnerDialogFlowPublishByCourseIdByFlowId } from 'src/api/review/intelligentPartner/dialogFlow/publish/{courseId}/{flowId}';
import UdeskLocales from 'UdeskLocales';

const noop = () => {};

const Template = React.memo((props: any) => {
    // const locales = Locales['current'];
    const courseId = props.match.params.id;

    const { requestFlowAndInit, setNodes, disabled, sideBarVisible, courseType, ...flowProps } =
        Flow.useFlow(courseId);
    const customerPortraitConfigContext = CustomerPortraitConfig.useCustomerPortraitConfig(
        flowProps.flowId
    );

    const requestFlowData = () => {
        requestFlowAndInit(courseId);
    };

    const { onNodeClick, currentSelectedNode, clearSelectedNode, ...nodeConfigDrawerProps } =
        ConfigDrawer.useConfigDrawer({
            setNodes,
            flowId: flowProps.flowId,
            requestFlowData,
        });

    const { onClose: closeNodeConfigDrawer } = nodeConfigDrawerProps;

    const { openDrawer: openCustomerPortraitConfigDrawer, ...customerPortraitConfigDrawerProps } =
        CustomerPortraitConfigDrawer.useDrawer();
    const { openDrawer: openGlobalConfigDrawer, ...globalConfigDrawerProps } =
        GlobalConfigDrawer.useDrawer();

    useEffect(() => {
        requestFlowData();
    }, []);
    const onBack = () => {
        let routeOptions = {
            history: props.history,
            routeName: 'coachLearningCenterCourseConfig',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    return (
        <Page
            pageBodyClassName="udesk-qa-web-course-management-detail"
            title={courseType ? Udesk.enums.lessonType.getName(courseType) : ' '}
            padding={false}
            backGroundGhost={true}
            onBack={onBack}
            extra={[
                <Button
                    onClick={() => {
                        let routeOptions = {
                            history: props.history,
                            routeName: 'courseManagementDetail',
                            pathParams: { id: courseId },
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    }}
                >{/* 编辑基本信息 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.index.editBasicInformation}</Button>,
                sideBarVisible && (
                    <Button
                        onClick={() => {
                            let routeOptions = {
                                history: props.history,
                                routeName: 'coachNodeConfigTest',
                                pathParams: { id: flowProps.flowId },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        }}
                    >{/* 测试 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.index.test}</Button>
                ),
                <Button
                    type="primary"
                    onClick={() => {
                        postReviewIntelligentPartnerDialogFlowPublishByCourseIdByFlowId({
                            segments: {
                                courseId,
                                flowId: flowProps.flowId,
                            },
                        }).then(onBack);
                    }}
                >{/* 发布 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.index.release}</Button>,
            ].filter(Boolean)}
        >
            <div className="dndflow">
                {sideBarVisible && <Flow.Sidebar courseType={courseType} />}
                <Flow
                    {...flowProps}
                    currentSelectedNode={currentSelectedNode}
                    onNodeClick={disabled ? noop : onNodeClick}
                    onNodesDelete={(...args: any) => {
                        flowProps.onNodesDelete.apply(null, args);
                        closeNodeConfigDrawer();
                        clearSelectedNode();
                    }}
                    onPaneClick={() => {
                        closeNodeConfigDrawer();
                        clearSelectedNode();
                    }}
                />
                <CustomerPortraitConfig.CustomerPortraitConfigProvider
                    value={customerPortraitConfigContext}
                >
                    <ConfigDrawer
                        {...nodeConfigDrawerProps}
                        // triggerFlowNodeNameChange={}
                    />
                    <CustomerPortraitConfigDrawer {...customerPortraitConfigDrawerProps} />
                    <GlobalConfigDrawer {...globalConfigDrawerProps} flowId={flowProps.flowId} />
                </CustomerPortraitConfig.CustomerPortraitConfigProvider>
                <div className="global-setting-btn" onClick={openCustomerPortraitConfigDrawer}>
                    <Icon type="UserOutlined" antdIcon={true} />
                </div>
                <div
                    className="global-setting-btn"
                    style={{ left: 40 }}
                    onClick={openGlobalConfigDrawer}
                >
                    <Icon type="SettingOutlined" antdIcon={true} />
                </div>
            </div>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
