import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import Udesk from 'Udesk';
import { Button, Slider, Popover, Space, Icon } from 'udesk-ui';
import './style.scss';

const AudioControl = (props) => {
    const { onTimeChange, duration, currentTime, hitPoints, disabled, onSelectTime, sessioLabel, detail, cRef } = props;
    const [marks, setMarks] = useState<any>({});
    const [_currentTime, setCurrentTime] = useState(formatCurrentTime(currentTime));
    const [fragmentTime, setFragmentTime] = useState<any[]>([]);
    const sliderRef = useRef<any>();
    const _onChange = (value) => {
        onSelectTime?.(value);
        setCurrentTime(value);
        onTimeChange(value);
        // if (timer) {
        //     clearTimeout(timer);
        // }
        // timer = setTimeout(() => {
        //     onTimeChange(value); // 把新的进度传给回调函数并执行
        //     timer = null;
        // }, 200);
    };

    const _tipFormatter = (value) => {
        return Udesk.utils.date.formatDuration(value);
    };

    useImperativeHandle(cRef, () => ({
        handlerTagsShow: (mouseIdArr, colorArr, defaultColor) => {
            const marksList:any = [];
            const marksWidth:any = [];
            let marks:any = {};
            sessioLabel.forEach(item => {
                let startTime = detail?.dialogSentenceList[item.startIndex]?.fromSec;
                let endTime = detail?.dialogSentenceList[item.endIndex]?.endSec;
                marksList.push({
                    [startTime]: startTime,
                    // [endTime]: endTime,
                });
                marksWidth.push((((endTime - startTime) / detail?.voiceLength) * 100));
            });
            marksList.forEach(item => {
                marks = { ...marks, ...item};
            });
            setMarks(marks);
            setTimeout(() => {
                let domDataList:any = Array.from(sliderRef.current?.getElementsByClassName('udesk-qa-ui-slider-dot'));
                if (domDataList.length) {
                    domDataList.forEach(record => {
                        record.style.width = 0;
                        record.style.display = 'none';
                    });
                    sessioLabel.forEach((item, idx) => {
                        // if (colorArr[item.topicTagType]) {
                        //     domDataList[idx].style.background = colorArr[item.topicTagType];
                        // }
                        domDataList[idx].style.background = colorArr[item.topicTagType] || defaultColor;
                    });
                    mouseIdArr.forEach(item => {
                        domDataList[item].style.width = marksWidth[item] + '%';
                        domDataList[item].style.display = 'block';
                    });
                }
            });
        }
    }));
    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return '';
    };
    useEffect(() => {
        setCurrentTime(formatCurrentTime(currentTime));
    }, [currentTime]);
    useEffect(() => {
        let libraryCallId = getQueryVariable('libraryCallId');
        if (libraryCallId && detail?.fragmentStartTime && detail?.fragmentEndTime) {
            setFragmentTime(
                [
                    {
                        value: detail?.fragmentStartTime || 0,
                        label: Udesk.utils.date.formatDuration(detail?.fragmentStartTime || 0),
                    }, {
                        value: detail?.fragmentEndTime || detail?.voiceLength,
                        label: Udesk.utils.date.formatDuration(detail?.fragmentEndTime || detail?.voiceLength),
                    },
                ]
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);
    return (
        <div className='audio-control-view' style={{ zIndex: 2, position: 'relative' }}>
            <div className='audio-control-view-hit-point-list'>
                {hitPoints.map((hitPoint, idx) => {
                    const content = (
                        <Space>
                            <Button
                                onClick={_onChange.bind(null, hitPoint.fromSec)}
                                type='link'
                                prefixCls='udesk-qa-ui-btn'
                                size='small'
                            >
                                {_tipFormatter(hitPoint.fromSec)}
                            </Button>
                            <div>{hitPoint.text}</div>
                        </Space>
                    );
                    return (
                        <Popover key={idx} content={content} placement='topLeft' arrowPointAtCenter={true}>
                            <div
                                className='audio-control-view-hit-point'
                                style={{ left: `${(hitPoint.fromSec / duration) * 100}%` }}
                                onClick={_onChange.bind(null, hitPoint.fromSec)}
                            >
                                {/* <div className='audio-control-view-hit-point-header'></div> */}
                                <div className='audio-control-view-hit-point-body'>
                                    <Icon
                                        type='EnvironmentFilled'
                                        antdIcon={true}
                                        style={{ transform: 'translateX(-50%)' }}
                                    />
                                </div>
                            </div>
                        </Popover>
                    );
                })}
            </div>
            <div className='audio-control-view-hit-point-list'>
                {!!fragmentTime.length && fragmentTime.map((hitPoint, idx) => {
                    return (
                        <div
                            key={idx}
                            className='audio-control-view-hit-point'
                            style={{ left: `${(hitPoint.value / duration) * 100 <= 0 ? 0 : (hitPoint.value / duration) * 100 >= 100 ? 100 : (hitPoint.value / duration) * 100}%` }}
                        >
                            <div className='audio-control-view-hit-point-body' style={{color: 'green'}}>
                                <Icon
                                    type='ic_dingwei'
                                    style={{ transform: 'translateX(-50%)' }}
                                />
                                <p style={{fontSize: 12, marginTop: 10, marginLeft: (hitPoint.value / duration) * 100 <= 0 ? -6 : (hitPoint.value / duration) * 100 >= 100 ? -40 : -24}}>{hitPoint.label}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div ref={sliderRef}>
                <Slider
                    disabled={disabled}
                    onChange={_onChange}
                    value={_currentTime}
                    max={duration}
                    marks={marks}
                    tipFormatter={_tipFormatter}
                />
            </div>
        </div>
    );
};

export default React.memo(AudioControl);

function formatCurrentTime(currentTime) {
    return typeof currentTime === 'number' ? currentTime : 0;
}
