import React, { useState, createContext } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Container, Item } from './components/Container';
import { TaskProgress } from './components/TaskProgress';
import styled from 'styled-components';
import './style.scss';
import { TaskCardList } from './components/TaskCard';
import { RankingList } from './components/RankingList';
import { PracticeSummary } from './components/PracticeSummary';
import { Avatar } from 'udesk-ui';
import { useTasks } from './hook/useTasks';
import { AdminDataCards } from './components/AdminDataCards';
import { linkToStart } from '../learning-center/components/util';
import UdeskLocales from 'UdeskLocales';

const Wrap = styled.div`
    padding: 12px 16px 16px;
    background-color: #fff;
    display: flex;
    align-items: ${(props) => props.align};
    justify-content: ${(props) => props.align};
    flex-direction: column;
    height: 100%;
    .ongoing-tasks-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
    }
    .display-center {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const UserContext = createContext<[any, React.Dispatch<React.SetStateAction<any>>]>([] as any);

const Template = React.memo((props: any) => {
    const { isAdmin } = props;
    return (
        <UserContext.Provider value={useState()}>
            <Page
                pageBodyClassName="udesk-qa-web-coach-dashboard-body"
                pageClassName="udesk-qa-web-coach-dashboard"
                title={isAdmin ? /* 总览 */UdeskLocales['current'].pages.coach.dashboard.index.overview : /* 个人看板 */UdeskLocales['current'].pages.coach.dashboard.index.personalSignage}
                padding={false}
                backGroundGhost={true}
            >
                <Container>
                    {props.children}
                    <Item width="auto">
                        <PracticeSummary isAdmin={isAdmin} />
                    </Item>
                    <Item width={600}>
                        <RankingList isAdmin={isAdmin} />
                    </Item>
                </Container>
            </Page>
        </UserContext.Provider>
    );
});

const AdminPage = React.memo((props) => {
    return (
        <Template {...props} isAdmin={true}>
            <AdminDataCards />
        </Template>
    );
});

const StudentPage = React.memo((props: any) => {
    const { company, user } = Udesk.data.init;

    const {
        practiseTasks,
        examinationTasks,
        practiseProgress,
        examinationProgress,
        loading: taskLoading,
    } = useTasks();

    return (
        <Template {...props}>
            <Item widthRatio={1}>
                <Wrap alig="center" style={{ padding: 16, flexDirection: 'row' }}>
                    <Avatar
                        size={48}
                        gap={4}
                        src={user.headPortraitUrl}
                        style={{ marginRight: 12 }}
                    >
                        {user.realname}
                    </Avatar>
                    <div className="header-title">
                        <div className="title">{`hi,${user.realname}`}</div>
                        <div className="company">{`@${company.sysName}`}</div>
                    </div>
                </Wrap>
            </Item>
            <Item widthRatio={1 / 2}>
                <Wrap>
                    <TaskCardList
                        title={/* 进行中的练习 */UdeskLocales['current'].pages.coach.dashboard.index.ongoingExercises}
                        tasks={practiseTasks}
                        loading={taskLoading}
                        onCardClick={(dialogueMode, taskId) => {
                            linkToStart(props.history, Udesk.enums.learningTaskType.notStarted.id, dialogueMode, {
                                id: taskId,
                            });
                        }}
                    />
                    <TaskCardList
                        style={{ marginTop: 12 }}
                        title={/* 进行中的考试 */UdeskLocales['current'].pages.coach.dashboard.index.ongoingExams}
                        tasks={examinationTasks}
                        loading={taskLoading}
                        onCardClick={(dialogueMode, taskId) => {
                            linkToStart(props.history, Udesk.enums.learningTaskType.underWay.id, dialogueMode, {
                                id: taskId,
                            });
                        }}
                    />
                </Wrap>
            </Item>
            <Item widthRatio={1 / 4}>
                <Wrap>
                    <div className="task-progress-title">{/* 练习任务进度 */}{UdeskLocales['current'].pages.coach.dashboard.index.practiceTaskProgress}</div>
                    <div className="display-center">
                        <TaskProgress {...practiseProgress} loading={taskLoading} />
                    </div>
                </Wrap>
            </Item>
            <Item widthRatio={1 / 4}>
                <Wrap>
                    <div className="task-progress-title">{/* 考试任务进度 */}{UdeskLocales['current'].pages.coach.dashboard.index.examTaskProgress}</div>
                    <div className="display-center">
                        <TaskProgress {...examinationProgress} loading={taskLoading} />
                    </div>
                </Wrap>
            </Item>
        </Template>
    );
});

function udeskify(Template) {
    class Component extends React.Component {
        render() {
            return <Template {...this.props} />;
        }
    }
    return Udesk.react.udeskify(Component);
}

export const AdminDashboard = udeskify(AdminPage);
export const StudentDashboard = udeskify(StudentPage);
