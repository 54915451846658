import React, { useState, useCallback, useEffect } from 'react';
import Udesk from 'Udesk';
import { Button, FormBuilder, Space, Select, TimePicker } from 'udesk-ui';
import Locales from 'UdeskLocales';
import FilterModalEdit from '../../../../pages/tasks/manage/template/automatic-add/filter-modal';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import moment from 'moment';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';


const Hours = Array.from(Array(24), (v, k) => k);
let getFiltedHours = () => Hours.filter((i) => !(i > 1 && i < 8));
const MONTH_DAYS = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
];

const selectStyles: React.CSSProperties = { width: 200 };
const AddFrequency = React.memo((props: any) => {
    const locales = Locales['current'];

    const { value = {}, onChange, onAddFrequencyLoadCycleChange } = props;
    const { loadCycle, dayOfWeek, dayOfMonth, executeTime } = value || {};
    const onSelectChange = (type, v) => {
        let newValue = { ...value };
        newValue[type] = v;
        onChange(newValue);
        onAddFrequencyLoadCycleChange(newValue);
    };
    const onTimePickerChange = (time, timeString) => {
        let newValue = { ...value };
        newValue.executeTime = timeString + ':00';
        onChange(newValue);
    };
    return (
        <Space>
            <Select value={loadCycle} onChange={onSelectChange.bind(null, 'loadCycle')} style={selectStyles}>
                {Udesk.enums.samplingFrequencyTypes.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
            {loadCycle === Udesk.enums.samplingFrequencyTypes.everyWeek.id && (
                <Select value={dayOfWeek} onChange={onSelectChange.bind(null, 'dayOfWeek')} style={selectStyles}>
                    {Udesk.enums.samplingFrequencyWeekDays.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
            )}
            {loadCycle === Udesk.enums.samplingFrequencyTypes.everyMonth.id && (
                <Select value={dayOfMonth} onChange={onSelectChange.bind(null, 'dayOfMonth')} style={selectStyles}>
                    {MONTH_DAYS.map((item) => (
                        <Select.Option key={item} value={item}>
                            {`${item}${locales.components.pushRulesGather.dateUnit}`}
                        </Select.Option>
                    ))}
                </Select>
            )}
            {loadCycle && (
                <TimePicker
                    disabledHours={getFiltedHours}
                    defaultValue={executeTime ? moment(executeTime, 'HH:mm:ss') : undefined}
                    format={'HH:mm'}
                    allowClear={false}
                    onChange={onTimePickerChange}
                    showNow={false}
                />
            )}
        </Space>
    );
});

function AutoAddComponent(props) {
    const locales = Locales['current'];
    const formLabels = locales.pages.tasks.manage.template.automaticAdd.index.autoAdd;

    const {
        isEdit,
        // conditionJson,
        judgeStrategy,
        customJudgeLogic,
        conditionList,
        // conditionJsonInfo,
        editFilterOhter: editFilterOhterProps,
        fieldsFilterChange,
        applyAction: handleConditionApplyProps,
        customName,
        customId,
        type,
        taskName,
        autoMateLoadConf = {},
        isAutoExecute,
        onTimingAddChange,
        isCollection,
    } = props;
    const [isFilterVisible, setFilterVisible] = useState(false); //控制高级筛选器显示隐藏
    const [conditionJsonInfo, setConditionJsonInfo] = useState([]);
    const [conditionJson, setConditionJson] = useState([]);
    const [wrapForm] = FormBuilder.useForm();
    const [initialValues, setInitialValues] = useState({isAutoExecute: Udesk.enums.autoAddTypes.realTimeAdd.id});
    const [loadScopeOptions, setLoadScopeOptions] = useState<{ id: number; name: string }[]>([]);
    const openCustomFilterModal = useCallback(() => {
        setFilterVisible(true);
    }, []);
    const handleConditionCancel = useCallback(() => {
        setFilterVisible(false);
    }, []);
    const editFilterOhter = (list, radioValue, customInfo) => {
        setFilterVisible(true);
        editFilterOhterProps(list, radioValue, customInfo);
    };
    const handleConditionApply = (conditionList, customJudgeLogic, judgeStrategy) => {
        handleConditionApplyProps(conditionList, customJudgeLogic, judgeStrategy);
        setFilterVisible(false);
    };

    const onFinish = (values) => {};
    const onAddFrequencyLoadCycleChange = ({ loadCycle }) => {
        if (loadCycle === Udesk.enums.samplingFrequencyTypes.everyWeek.id) {
            setLoadScopeOptions(Udesk.enums.samplingEveryWeekTimeTypes.filter((i) => i.id > 1));
        } else if (loadCycle === Udesk.enums.samplingFrequencyTypes.everyMonth.id) {
            setLoadScopeOptions(Udesk.enums.samplingEveryMonthTimeTypes.filter((i) => i.id > 1));
        } else if (loadCycle === Udesk.enums.samplingFrequencyTypes.everyDay.id) {
            setLoadScopeOptions(Udesk.enums.samplingEveryDayTimeTypes.filter((i) => i.id > 1));
        }
    };
    const onValuesChange = (_, values) => {
        const {isAutoExecute, ...others} = values;
        onTimingAddChange?.(isAutoExecute, {...(others.addFrequency??{}), ...others}, props.isAutoExecute);
    };

    useEffect(() => {
        let newConditionJsonInfo =
            conditionList.map((item) => {
                if (item.field && item.field.statusKey) {
                    return {
                        ...item,
                        field: {
                            ...item.field,
                            id: `${item.field.id}-${item.field.inspectionTaskId}`,
                        },
                    };
                } else {
                    return item;
                }
            }) || [];
        let newConditionJson =
            conditionList.map((item) => {
                if (item.field && item.field.statusKey) {
                    return {
                        field: `${item.field.id}-${item.field.inspectionTaskId}`,
                        operator: item.operator,
                        value: item.value,
                        statusKey: `${item.field.id}-${item.field.inspectionTaskId}`,
                    };
                } else {
                    return {
                        field: item.field ? item.field.id : {},
                        operator: item.operator,
                        value: item.value,
                    };
                }
            }) || [];
        setConditionJsonInfo(newConditionJsonInfo);
        setConditionJson(newConditionJson);
    }, [conditionList]);
    const config: FormProps = {
        initialValues: initialValues,
        onFinish: onFinish,
        onValuesChange: onValuesChange,
        customComponents: { AddFrequency: AddFrequency },
        fields: [
            {
                name: 'isAutoExecute',
                label: formLabels.autoAddType,
                type: 'RadioGroup',
                props: {
                    optionType: 'button',
                    options: Udesk.enums.autoAddTypes.map((i) => ({ label: i.name, value: i.id })),
                },
                forceDisabled: isCollection,
                tooltip: (
                    <div>
                        <div>{locales.fix.realTimeAdditionTip1}</div>
                        <div>{locales.fix.realTimeAdditionTip2}</div>
                    </div>
                ),
                rules: [
                    {
                        required: true,
                    },
                ],
                effect: {
                    visible: {
                        [Udesk.enums.autoAddTypes.timingAdd.id]: ['addFrequency', 'loadScope'],
                    },
                },
            },
            {
                name: 'addFrequency',
                label: formLabels.addFrequency,
                type: 'AddFrequency',
                props: {
                    onAddFrequencyLoadCycleChange: onAddFrequencyLoadCycleChange,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'loadScope',
                label: formLabels.add,
                type: 'Select',
                props: {
                    options: loadScopeOptions.map((i) => ({ label: i.name, value: i.id })),
                    style: selectStyles,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        ],
        footerRender: false,
        labelCol: { span: 0 },
        wrapperCol: { span: 24 },
    };
    useEffect(() => {
        setInitialValues({...autoMateLoadConf, isAutoExecute, addFrequency: autoMateLoadConf, loadScope: autoMateLoadConf.loadScope});
        onAddFrequencyLoadCycleChange(autoMateLoadConf);
    }, [autoMateLoadConf, isAutoExecute]);
    useEffect(() => {
        wrapForm.resetFields();
    }, [initialValues]);
    return (
        <React.Fragment>
            <FormBuilder {...config} form={wrapForm}></FormBuilder>
            {isEdit ? (
                <FilterModalEdit
                    conditionJson={conditionJson}
                    judgeStrategy={judgeStrategy}
                    customJudgeLogic={customJudgeLogic}
                    conditionList={conditionList}
                    conditionJsonInfo={conditionJsonInfo}
                    editFilterOhter={editFilterOhter}
                    fieldsFilterChange={fieldsFilterChange}
                    disabled={props.disabled}
                />
            ) : (
                <Button onClick={openCustomFilterModal}>{locales.labels.addFilterConditions}</Button>
            )}
            <CustomFilterModal
                querryType='autoAdd'
                visible={isFilterVisible}
                cancel={handleConditionCancel}
                applyAction={handleConditionApply}
                //条件规则
                judgeStrategy={judgeStrategy}
                //自定义规则
                customJudgeLogic={customJudgeLogic}
                conditionList={conditionList}
                customName={customName}
                customId={customId}
                type={type}
                // taskId={this.props.match.params.taskId}
                taskName={taskName}
                filterList={['f_8', 'f_4', 'f_6', 'f_7', 'r_1', 'r_2', 'r_3']}
            />
        </React.Fragment>
    );
}

export default React.memo(AutoAddComponent);
