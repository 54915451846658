import React, { useMemo, useEffect, useRef } from 'react';
import { getIntelligentPartnerByRecordIdEvaluation } from 'src/api/intelligentPartner/{recordId}/evaluation';
import styled from 'styled-components';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import { Empty } from 'udesk-ui';
import { useImmer } from 'use-immer';
import useResizeObserver from 'use-resize-observer';
import { legend, radarOptionLegend, radarOptionTitle, seriesItemStyle } from './const';
import UdeskLocales from 'UdeskLocales';

const Wrap = styled.div`
    margin-top: 24px;
    overflow: hidden;

    > div {
        height: 300px;
    }
`;

const ComprehensiveEvaluation = React.memo((props: any) => {
    const { recordId } = props;
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    const echartsRef = useRef<any>(null);

    const [data] = useImmer<any>({
        radarLegend: [],
        indicator: [],
        series: [],
    });
    const [radarOption, setRadarOption] = useImmer<any>(null);
    const radarSeriesColumns = useMemo(() => {
        return [
            {
                name: '',
                type: 'radar',
                data,
            },
        ];
    }, [data]);

    useEffect(() => {
        const token = requestIdleCallback(() => {
            echartsRef.current?.resize?.();
        });
        return () => {
            cancelIdleCallback(token);
        };
    }, [width, height]);
    useEffect(() => {
        Promise.all([
            getIntelligentPartnerByRecordIdEvaluation({
                segments: {
                    recordId,
                },
                params: {
                    isLastTime: 0,
                },
            }),
            getIntelligentPartnerByRecordIdEvaluation({
                segments: {
                    recordId,
                },
                params: {
                    isLastTime: 1,
                },
            }),
        ]).then(([thisTimeData, lastTimeData]) => {
            const [getThisTime, getLastTime] = legend.map((l) => getItem(l.areaStyleColor, l.name));

            const thisTimeEchartData = getEchartDataFromData(thisTimeData.data);
            const lastTimeEchartData = getEchartDataFromData(lastTimeData.data);
            const indicator = [
                {
                    // 雷达图的指示器，用来指定雷达图中的多个变量（维度）,跟data中 value 对应
                    name: /* 内容完整 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.complete,
                    // 指示器名称
                    max: 100,
                    // 指示器的最大值，可选，建议设置
                    //color: '#fff' // 标签特定的颜色。
                },
                {
                    name: /* 要点命中 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.keyHit,
                    max: 100,
                },
                {
                    name: /* 语言规范 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.languageNorm,
                    max: 100,
                },
                {
                    name: /* 表达流畅 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.fluentExpression,
                    max: 100,
                },
                {
                    name: /* 情感积极 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.emotionallyPositive,
                    max: 100,
                },
            ];

            let radarOption = {
                title: radarOptionTitle,
                color: legend.map((i) => i.color),
                legend: radarOptionLegend,
                tooltip: {
                    confine: true,
                    trigger: 'item',
                    formatter: function (params) {
                        let relVal = params.name;
                        let detail = thisTimeEchartData.detail;
                        if (relVal === legend[1].name) {
                            detail = lastTimeEchartData.detail;
                        }
                        relVal += indicator
                            .map((i, index) => {
                                const main =
                                    '<br/><strong>' +
                                    i.name +
                                    ': ' +
                                    params.data.value[index] +
                                    '%</strong>';
                                const sub = detail[index];
                                return main + '<br />' + sub;
                            })
                            .join('');
                        return relVal;
                    },
                },
                radar: [
                    {
                        name: {
                            // (圆外的标签)雷达图每个指示器名称的配置项。
                            formatter: '{value}',
                            textStyle: {
                                fontSize: 14,
                                color: 'rgba(0, 0, 0, .65)',
                            },
                        },
                        nameGap: 15,
                        // 指示器名称和指示器轴的距离。[ default: 15 ]
                        splitNumber: 5,
                        // (这里是圆的环数)指示器轴的分割段数。[ default: 5 ]
                        shape: 'polygon',
                        // 雷达图绘制类型，支持 'polygon'(多边形) 和 'circle'(圆)。[ default: 'polygon' ]
                        indicator,
                    },
                ],
                series: [
                    {
                        name: /* 雷达图 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.radarChart,
                        // 系列名称,用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
                        type: 'radar',
                        // 系列类型: 雷达图
                        itemStyle: seriesItemStyle,
                        data: [
                            getThisTime(thisTimeEchartData.res),
                        ],
                        zlevel: 2,
                    },
                    {
                        name: /* 雷达图 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.radarChart,
                        // 系列名称,用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
                        type: 'radar',
                        // 系列类型: 雷达图
                        itemStyle: seriesItemStyle,
                        data: [
                            getLastTime(lastTimeEchartData.res),
                        ],
                    },
                ],
            };
            setRadarOption(radarOption);
        });
    }, [recordId]);
    return (
        <Wrap ref={ref}>
            {radarOption ? (
                <ReactEcharts
                    ref={echartsRef}
                    type="radar"
                    // areaStyle={areaStyle}
                    seriesColumns={radarSeriesColumns}
                    top={100}
                    rerenderOptimization={true}
                    finalizeChartOptions={() => radarOption}
                />
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </Wrap>
    );
});

export { ComprehensiveEvaluation };

function getEchartDataFromData(data) {
    const res: any[] = [];
    
    if(!data) return {
        res,
        detail: [],
    };
    res.push(data.finishNodes / data.totalNodes);
    res.push(data.commitPoints / data.totalPoints);
    res.push((10 - data.violations) / 10);
    res.push(data.expressionScore / data.expressionTotalScore);
    res.push(data.emotionScore / data.emotionTotalScore);

    return {
        res: res.map((i) => (isNaN(i) ? 0 : (i * 100) >>> 0)),
        detail: [
            `${/* <span style="margin-left: 8px">· 完整流程数 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.spanStylemarginLeftpxNumberOfCompleteProcesses}${data.finishNodes}/${data.totalNodes}</span>`,
            `${/* <span style="margin-left: 8px">· 命中要点数 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.spanStylemarginLeftpxNumberOfHitPoints}${data.commitPoints}/${data.totalPoints}</span>`,
            `${/* <span style="margin-left: 8px">· 有 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.spanStylemarginLeftpxYes}${data.violations}${/* 处违规词</span> */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.dealingWithIllegalWordsspan}`,
            `${/* <span style="margin-left: 8px">· 语速过快 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.spanStylemarginLeftpxSpeakTooFast}${data.speedFastTimes}${/* 次</span><br /><span style="margin-left: 8px">· 语气词过多 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.spanbrspanStylemarginLeftpxTooManyModalParticles}${data.modalParticleTooMuchTimes}${/* 次</span> */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.timesspan}`,
            `${/* <span style="margin-left: 8px">· 负面情绪 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.spanStylemarginLeftpxNegativeEmotions}${data.negativeEmotionTimes}${/* 次</span> */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.timesspan}`,
        ],
    };
}

function getItem(color, name) {
    return (value) => {
        return {
            // name: '本次',
            name,
            // value: [100, 80, 60, 50, 30],
            value,
            symbol: 'circle',
            symbolSize: 5,
            label: {
                normal: {
                    show: true,
                    position: 'top',
                    distance: 5,
                    fontSize: 14,
                    formatter: function (params) {
                        return params.value;
                    },
                },
            },
            lineStyle: {
                normal: {
                    opacity: 0.5,
                },
            },
            areaStyle: {
                // color: 'rgba(51,0,255,0.6)',
                color,
            },
        };
    };
}
