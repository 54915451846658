import Udesk from 'Udesk';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Icon, Space, Input } from 'udesk-ui';
import MoreCustomer from '../more-customer';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props:any) => {
    const {
        request, 
        locales, 
        className, 
        customerId, 
        customerName, 
        setCustomerName,
        ownerName,
        userName,
        stageList,
        stayDays,
        followUpDays,
        handleStageChange,
        customerInfo,
        handleRemarkConfirm,
    } = props;
    const inputRef = useRef<any>();
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState(customerName);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        setInputValue(customerName);
    }, [customerName]);

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        setInputVisible(false);
        
        request(`review/call/customer/message/customerName/${customerId}`, {
            customerName: inputValue
        }, 'put').then(
            resp => {
                setCustomerName(inputValue);
                Udesk.ui.notify.success(locales.fix.saveSuccess);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };

    return (
        <div className={className}>
            <Space>
                {inputVisible && (
                    <Input
                        ref={inputRef}
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <>
                        <span>{inputValue}</span>
                        <Button
                            onClick={showInput}
                            icon={<Icon antdIcon={true} type='EditOutlined' />}
                            type='link' 
                        />
                    </>
                )}
                <MoreCustomer  
                    {...{
                        locales,
                        customerId,
                        customerName,
                        ownerName,
                        userName,
                        stageList,
                        followUpDays,
                        handleStageChange,
                        customerInfo,
                        handleRemarkConfirm,
                    }}
                />
            </Space>
            {
                stayDays !== null ? (
                    <div>{/* 当前阶段已停留 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.businessTitle.index.theCurrentStageHasStayed}<span className='count'>{stayDays}</span>{/* 天 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.businessTitle.index.day}</div>
                ) : null
            }
        </div>
    );
});
