import React from 'react';
import Udesk from 'Udesk';
import { isCustomMessage } from 'src/pages/coach/learning-center/components/record/ppt/hook';
import { isExercise } from 'src/pages/coach/learning-center/components/util';
import { Divider, Icon, Space } from 'udesk-ui';
import { inspect, isDefined, createClassName } from 'src/util/core';
import UdeskLocales from 'UdeskLocales';
import useResizeObserver from 'use-resize-observer';
import './index.less';

type RtaPropsType = {
    isRta: boolean;
    traineeWordsResult: any,
   
    enableScorePointDisplay: boolean;
    enableSpeedWarn: boolean;
    enableWordsViolationWarn: boolean;
    enableEmotionDisplay: boolean;
}

const RtaItem = React.memo((props: any) => {
    const {children, title, color = ''} = props;
    return (
        <Space className='udesk-qa-course-real-time-analyzer-item'>
            <div className={createClassName('udesk-qa-course-real-time-analyzer-item-title', color)}>{title}</div>
            <Space 
                split={
                    <Divider type="vertical" />
                }>
                {children}
            </Space>
        </Space>
    );
});

const RtaItemHit = React.memo((props: any) => {
    const {content, isHit} =props;
    return (
        <span title={content} className='udesk-qa-course-real-time-analyzer-hit-item'>
            <span>{content}</span>
            {
                isDefined(isHit, () => (
                    <span className={isHit ? 'svg-green' : 'svg-red' }>
                        <Icon antdIcon={true} type={isHit ? 'CheckOutlined' : 'CloseOutlined'}></Icon>
                    </span>
                ))
            }
        </span>
    );
});

function format (list, length = 4) {
    const result = new Array<any>();
    list.forEach((item, index) => {
        const resultIndex = Math.floor(index / length);
        result[resultIndex] = result[resultIndex] || [];
        result[resultIndex].push(item);
    });
    return result;
}

const Rta = React.memo<RtaPropsType>((props) => {
    const { ref, width = 1} = useResizeObserver<HTMLDivElement>();
    const {
        isRta,
        traineeWordsResult,
        
        enableScorePointDisplay,
        enableSpeedWarn,
        enableWordsViolationWarn,
        enableEmotionDisplay,
    } = props;

    if (!isRta || !traineeWordsResult) {
        return null;
    }

    const {
        scoreRuleResultList, 
        knowLedgeScoreResult,
        wordsViolationScoreResultList, 
        sentimentValue,
        speakSpeed,
    } = traineeWordsResult?.resultAnalysis || {};

    return (
        <div className='udesk-qa-course-real-time-analyzer'>
            <div ref={ref}>
                {
                    inspect(enableScorePointDisplay, () => (
                        <>
                            {
                                inspect(scoreRuleResultList?.length > 0, () => (
                                    format(scoreRuleResultList, Math.floor(width / 165) || 1).map(list => (
                                        <RtaItem title={/* 话术 */UdeskLocales['current'].components.coach.dialogue.components.realTimeAnalyzer.index.script}>
                                            {
                                                list.map(item => (
                                                    <RtaItemHit content={item.scoreRuleName} isHit={item.hit}/>
                                                ))
                                            }
                                        </RtaItem>
                                    ))
                                ))
                            }
                            {
                                isDefined(knowLedgeScoreResult, () => (
                                    <RtaItem title={/* 语义 */UdeskLocales['current'].components.coach.dialogue.components.realTimeAnalyzer.index.semantics}>
                                        <RtaItemHit content={knowLedgeScoreResult.title} isHit={knowLedgeScoreResult.isHit}/>
                                    </RtaItem>
                                ))
                            }
                        </>
                    ))
                }
                {
                    inspect(enableWordsViolationWarn && wordsViolationScoreResultList?.length > 0, () => (
                        <RtaItem title={/* 违规词 */UdeskLocales['current'].components.coach.dialogue.components.realTimeAnalyzer.index.violatingWords} color='red'>
                            {
                                wordsViolationScoreResultList.map(item => (
                                    <RtaItemHit content={item.violationWords}/>
                                ))
                            }
                        </RtaItem>
                    ))
                }
                {
                    inspect(enableSpeedWarn && isDefined(speakSpeed), () => (
                        <RtaItem title={/* 语速 */UdeskLocales['current'].components.coach.dialogue.components.realTimeAnalyzer.index.speechSpeed} color='gold'>
                            <RtaItemHit content={Udesk.enums.speakSpeeds.getName(speakSpeed)} isHit={speakSpeed === Udesk.enums.speakSpeeds.neutral.id}/>
                        </RtaItem>
                    ))
                }
                {
                    inspect(enableEmotionDisplay && isDefined(sentimentValue), () => (
                        <RtaItem title={/* 情绪 */UdeskLocales['current'].components.coach.dialogue.components.realTimeAnalyzer.index.emotion} color='cyan'>
                            <RtaItemHit content={Udesk.enums.sentimentValues.getName(sentimentValue)} isHit={sentimentValue !== Udesk.enums.sentimentValues.negative.id}/>
                        </RtaItem>
                    ))
                }
            </div>
        </div>
    );
});

export const getRtaProps = (task:any, globalConfig:any) => {
    const {
        enableEmotionDetection,
        enableWordsViolationDetection,
        
        enableScorePointDisplay,
        enableSpeedWarn,
        enableWordsViolationWarn,
        enableEmotionDisplay,
    } = globalConfig || {};

    return {
        
        enableScorePointDisplay: !!enableScorePointDisplay,
        enableSpeedWarn: !!(
            task?.dialogueMode !== Udesk.enums.learningTaskDialogueModeType.text.id && 
            enableSpeedWarn
        ),
        enableWordsViolationWarn: !!(enableWordsViolationWarn && enableWordsViolationDetection),  
        enableEmotionDisplay: !!(enableEmotionDetection && enableEmotionDisplay),
    };
};

export const isRta = (task, data) => isExercise(task?.taskType, true, false) && isCustomMessage(data);

export default React.memo((props: any) => {
    const {
        isRta,
        traineeWordsResult,

        enableScorePointDisplay,
        enableSpeedWarn,
        enableWordsViolationWarn,
        enableEmotionDisplay,
    } = props;
    
    return (
        <Rta {...{
            isRta,
            traineeWordsResult,

            enableScorePointDisplay,
            enableSpeedWarn,
            enableWordsViolationWarn,
            enableEmotionDisplay,
        }}/>
    );
});