import Locales from '../locales/index';
import UdeskLocales from 'UdeskLocales';

let appealCheckCallTypes = [
    { id: 1, key: 'callIn', name: () => Locales.current.enums.appealCheckCallTypes.callIn },
    { id: 2, key: 'callBack', name: () => Locales.current.enums.appealCheckCallTypes.callBack },
    { id: 3, key: 'callOut', name: () => Locales.current.enums.appealCheckCallTypes.callOut },
    { id: 4, key: 'autoCall', name: () => Locales.current.enums.appealCheckCallTypes.autoCall },
];

let appealCheckCallStatus = [
    { id: 1, key: 'waitReview', name: () => Locales.current.enums.appealCheckCallStatus.waitReview },
    { id: 2, key: 'reviewed', name: () => Locales.current.enums.appealCheckCallStatus.reviewed },
    { id: 3, key: 'filed', name: () => Locales.current.enums.appealCheckCallStatus.filed },
];

let gradeChooseHits = [
    { id: 1, key: 'yes', name: () => Locales.current.enums.gradeChooseHits.yes },
    { id: 0, key: 'no', name: () => Locales.current.enums.gradeChooseHits.no },
];

let appealCheckStatus = [
    { id: 0, key: 'all', name: () => Locales.current.enums.appealCheckStatus.all },
    { id: 1, key: 'unread', name: () => Locales.current.enums.appealCheckStatus.unread },
    { id: 2, key: 'reviewed', name: () => Locales.current.enums.appealCheckStatus.reviewed },
    // { id: 3, key: "filed", name: () => Locales.current.enums.appealCheckStatus.filed },
    { id: 4, key: 'inComplaint', name: () => Locales.current.enums.appealCheckStatus.inComplaint },
    { id: 5, key: 'readed', name: () => Locales.current.enums.appealCheckStatus.readed },
    { id: 6, key: 'spotchecked', name: () => Locales.current.enums.appealCheckStatus.spotchecked },
];

let gradeChangeTypes = [
    { id: 1, key: 'edit', name: () => Locales.current.enums.gradeChangeTypes.edit },
    { id: 2, key: 'check', name: () => Locales.current.enums.gradeChangeTypes.check },
    { id: 3, key: 'file', name: () => Locales.current.enums.gradeChangeTypes.file },
    { id: 4, key: 'remark', name: () => Locales.current.enums.gradeChangeTypes.remark },
    { id: 5, key: 'complain', name: () => Locales.current.enums.gradeChangeTypes.complain },
    { id: 6, key: 'review', name: () => Locales.current.enums.gradeChangeTypes.review },
    { id: 7, key: 'reviewEvaluation', name: () => Locales.current.enums.gradeChangeTypes.reviewEvaluation },
    { id: 8, key: 'sample', name: () => Locales.current.enums.gradeChangeTypes.sample },
    { id: 9, key: 'sampleEvaluation', name: () => Locales.current.enums.gradeChangeTypes.sampleEvaluation },
    { id: 10, key: 'sampleSubmit', name: () => Locales.current.enums.gradeChangeTypes.sampleSubmit },
    // { id: 11, key: 'assign', name: () => Locales.current.enums.gradeChangeTypes.assign },
    { id: 11, key: 'sampleAssign', name: () => Locales.current.enums.gradeChangeTypes.sampleAssign },
    { id: 12, key: 'reviewAssign', name: () => Locales.current.enums.gradeChangeTypes.reviewAssign },
    { id: 13, key: 'read', name: () => Locales.current.enums.gradeChangeTypes.read },
    { id: 14, key: 'reviewConfirm', name: () => Locales.current.enums.gradeChangeTypes.reviewConfirm },
    { id: 15, key: 'sampleConfirm', name: () => Locales.current.enums.gradeChangeTypes.sampleConfirm },
    { id: 16, key: 'caseBase', name: () => Locales.current.enums.gradeChangeTypes.caseBase },
    { id: 17, key: 'sampleCancel', name: () => Locales.current.enums.gradeChangeTypes.sampleCancel },
    { id: 18, key: 'reviewCancel', name: () => Locales.current.enums.gradeChangeTypes.reviewCancel },
    { id: 19, key: 'sampleAgain', name: () => Locales.current.enums.gradeChangeTypes.sampleAgain },
    { id: 20, key: 'reviewAgain', name: () => Locales.current.enums.gradeChangeTypes.reviewAgain },
    { id: 21, key: 'btachDelete', name: () => Locales.current.enums.gradeChangeTypes.btachDelete },
    { id: 22, key: 'rapidSampling', name: () => Locales.current.enums.gradeChangeTypes.rapidSampling },
    { id: 23, key: 'reInspection', name: () => Locales.current.enums.gradeChangeTypes.reInspection },
    { id: 24, key: 'smartTagsChange', name: () => Locales.current.enums.gradeChangeTypes.smartTagsChange },
    { id: 25, key: 'cancelAppeal', name: () => Locales.current.enums.gradeChangeTypes.cancelAppeal },
];

let appealTypes = [
    { id: 1, key: 'transliterationError', name: () => Locales.current.enums.appealTypes.transliterationError },
    { id: 2, key: 'discriminationError', name: () => Locales.current.enums.appealTypes.discriminationError },
    { id: 3, key: 'otherError', name: () => Locales.current.enums.appealTypes.otherError },
];

let inspectDataSources = [
    { id: 1, key: 'voiceCall', name: () => Locales.current.enums.inspectDataSources.voiceCall },
    { id: 2, key: 'textDialogue', name: () => Locales.current.enums.inspectDataSources.textDialogue },
    { id: 3, key: 'realTimeVoiceCall', name: () => Locales.current.enums.inspectDataSources.realTimeVoiceCall },
    { id: 4, key: 'realTimeTextDialogue', name: () => Locales.current.enums.inspectDataSources.realTimeTextDialogue },
    { id: 5, key: 'wechatDialogue', name: () => Locales.current.enums.inspectDataSources.wechatDialogue },
    { id: 8, key: 'taobao', name: () => Locales.current.enums.inspectDataSources.taobao },
    { id: 9, key: 'reviewCall', name: () => /* 语音复盘 */Locales.current.enums.appealCheckingTask.voiceCopy },
    { id: 10, key: 'ticket', name: () => Locales.current.enums.inspectDataSources.ticket },
    { id: 12, key: 'wechatRadio', name: () => Locales.current.enums.inspectDataSources.wechatRadio },
    { id: 13, key: 'salesWechatRadio', name: () => Locales.current.enums.inspectDataSources.wechatRadio },
    { id: 14, key: 'salesWechatText', name: () => /* 企微文本 */UdeskLocales['current'].enums.appealCheckingTask.enterpriseWeChatText },
];

let inspectTaskType = [
    { id: null, key: 'all', name: () => Locales.current.enums.inspectTaskType.all },
    { id: 0, key: 'common', name: () => Locales.current.enums.inspectTaskType.common },
    { id: 1, key: 'relate', name: () => Locales.current.enums.inspectTaskType.relate },
];

let inspectApproveType = [
    { id: null, key: 'all', name: () => Locales.current.enums.inspectApproveType.all },
    { id: 2, key: 'resolve', name: () => Locales.current.enums.inspectApproveType.resolve },
    { id: 1, key: 'resolving', name: ()=> Locales.current.enums.inspectApproveType.resolving},
    { id: 3, key: 'reject', name: () => Locales.current.enums.inspectApproveType.reject },
];

let pointTypes = [
    { id: 1, key: 'automatic', name: () => Locales.current.enums.pointTypes.automatic },
    { id: 2, key: 'manual', name: () => Locales.current.enums.pointTypes.manual },
    { id: 3, key: 'interactiveDetection', name: () => Locales.current.enums.pointTypes.interactiveDetection },
    { id: 4, key: 'smartRules', name: () => Locales.current.enums.pointTypes.smartRules },
    { id: 5, key: 'machineLearning', name: () => Locales.current.enums.pointTypes.machineLearning },
    { id: 6, key: 'intelligentModel', name: () => Locales.current.enums.pointTypes.intelligentModel },
];

let ruleTypes = [
    { id: 0, key: 'common', name: () => /* 普通质检 */UdeskLocales['current'].enums.appealCheckingTask.generalQualityInspection },
    { id: 1, key: 'flow', name: () => /* 流程质检 */UdeskLocales['current'].enums.appealCheckingTask.processQualityInspection },
];

let pointGradeTypes = [
    { id: 1, key: 'radio', name: () => Locales.current.enums.pointGradeTypes.radio },
    { id: 2, key: 'input', name: () => Locales.current.enums.pointGradeTypes.input },
    { id: 3, key: 'select', name: () => Locales.current.enums.pointGradeTypes.select },
    { id: 4, key: 'foctors', name: () => Locales.current.enums.pointGradeTypes.radio },
    // { id: 11, key: "all", name: () => Locales.current.enums.pointGradeTypes.all },
    // { id: 12, key: "any", name: () => Locales.current.enums.pointGradeTypes.any },
    // { id: 13, key: "customize", name: () => Locales.current.enums.pointGradeTypes.customize },
];

let pointNaChecked = [{ id: 1, key: 'check', name: () => Locales.current.enums.pointNaChecked.check }];

let interactiveAnswerTypes = [
    { id: 0, key: 'noAnswer', name: () => Locales.current.enums.interactiveAnswerTypes.noAnswer },
    { id: 1, key: 'answer', name: () => Locales.current.enums.interactiveAnswerTypes.answer },
    { id: 2, key: 'wrongAnswer', name: () => Locales.current.enums.interactiveAnswerTypes.wrongAnswer },
];

let interactiveMatchedQuestionTypes = [
    { id: 0, key: 'noQuestion', name: () => Locales.current.enums.interactiveMatchedQuestionTypes.noQuestion },
    { id: 1, key: 'question', name: () => Locales.current.enums.interactiveMatchedQuestionTypes.question },
    {
        id: 2,
        key: 'eliminateQuestion',
        name: () => Locales.current.enums.interactiveMatchedQuestionTypes.eliminateQuestion,
    },
];
let isManual = [
    { id: '0', key: 'no', name: () => Locales.current.enums.isManual.no },
    { id: '1', key: 'yes', name: () => Locales.current.enums.isManual.yes },
];

const flowStatus = [
    { id: 1, key: 'unread', name: () => Locales.current.enums.flowStatus.unread },
    { id: 2, key: 'alreadyRead', name: () => Locales.current.enums.flowStatus.alreadyRead },
    { id: 3, key: 'complaining', name: () => Locales.current.enums.flowStatus.complaining },
    { id: 4, key: 'reviewed', name: () => Locales.current.enums.flowStatus.reviewed },
    { id: 5, key: 'sampling', name: () => Locales.current.enums.flowStatus.sampling },
    { id: 6, key: 'samplingCompleted', name: () => Locales.current.enums.flowStatus.samplingCompleted },
    { id: 7, key: 'complainCancel', name: () => Locales.current.enums.flowStatus.complainCancel },
];

const readStatus = [
    { id: 1, key: 'no', name: () => Locales.current.enums.readStatus.no },
    { id: 0, key: 'yes', name: () => Locales.current.enums.readStatus.yes },
];

const complainStatus = [
    { id: 3, key: 'success', name: () => Locales.current.enums.complainStatus.success },
    { id: 4, key: 'fail', name: () => Locales.current.enums.complainStatus.fail },
    { id: 1, key: 'default', name: () => Locales.current.enums.complainStatus.default },
    { id: 7, key: 'canceled', name: () => Locales.current.enums.complainStatus.canceled },
];

const gradedOptions = [
    { id: 'less_than', key: 'less_than', name: () => Locales.current.enums.common.sentenceLength.less },
    { id: 'greater_than', key: 'greater_than', name: () => Locales.current.enums.common.sentenceLength.greater },
    { id: 'is', key: 'is', name: () => Locales.current.enums.common.sentenceLength.equal },
];

const ratingOptions = [
    { id: 'is', key: 'is', name: () => Locales.current.enums.appealCheckingTask.beEqualTo },
    { id: "not", key: "not", name: () => Locales.current.enums.appealCheckingTask.notEqualTo },
];

const canAppealOptions = [
    { id: 1, key: 'yes', name: () => Locales.current.enums.appealCheckingTask.canAppeal },
    { id: 0, key: "not", name: () => Locales.current.enums.appealCheckingTask.noAppeal },
];

export default {
    appealCheckCallTypes,
    appealCheckCallStatus,
    gradeChooseHits,
    appealCheckStatus,
    gradeChangeTypes,
    appealTypes,
    inspectDataSources,
    pointTypes,
    pointGradeTypes,
    pointNaChecked,
    interactiveAnswerTypes,
    interactiveMatchedQuestionTypes,
    inspectTaskType,
    inspectApproveType,
    isManual,
    flowStatus,
    readStatus,
    complainStatus,
    gradedOptions,
    ratingOptions,
    canAppealOptions,
    ruleTypes,
};
