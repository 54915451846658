import Udesk from 'Udesk';

function getInspectDataSources(isWechatQa, company, isCollect = false) {
    let inspectDataSources = [];
    if (isWechatQa) {
        // 3、企业微信
        inspectDataSources.push(Udesk.enums.inspectDataSources.wechatDialogue);
        if (company.enabledWeComVoiceInspect) {
            inspectDataSources.push(Udesk.enums.inspectDataSources.wechatRadio);
        }
    } else {
        // enabledCallInspect 0:未启用 1:启用语音质检 2:启用实时语音质检 3:都启用
        // 1、语音通话
        switch (company.enabledCallInspect) {
            case Udesk.enums.companyEnableInspect.regular.id:
                inspectDataSources.push(Udesk.enums.inspectDataSources.voiceCall);
                break;
            case Udesk.enums.companyEnableInspect.realTime.id:
                inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeVoiceCall);
                break;
            case Udesk.enums.companyEnableInspect.all.id:
                inspectDataSources.push(Udesk.enums.inspectDataSources.voiceCall);
                inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeVoiceCall);
                break;
            default:
                break;
        }
        // enabledImInspect 0:未启用 1:启用IM质检 2:启用实时IM质检 3:都启用
        // 2、文本对话
        switch (company.enabledImInspect) {
            case Udesk.enums.companyEnableInspect.regular.id:
                inspectDataSources.push(Udesk.enums.inspectDataSources.textDialogue);
                break;
            case Udesk.enums.companyEnableInspect.realTime.id:
                inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeTextDialogue);
                break;
            case Udesk.enums.companyEnableInspect.all.id:
                inspectDataSources.push(Udesk.enums.inspectDataSources.textDialogue);
                inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeTextDialogue);
                break;
            default:
                break;
        }
    }

    if (company.enabledMallInspect && !isCollect && !isWechatQa) {
        inspectDataSources.push(Udesk.enums.inspectDataSources.taobao);
    }

    if (company.enabledTicket && !isCollect && !isWechatQa) {
        inspectDataSources.push(Udesk.enums.inspectDataSources.ticket);
    }

    return inspectDataSources;
}

function filterEnabledtInspectDataSources() {
    const company = Udesk.data.init.company;
    const inspectDataSources = [];
    // enabledCallInspect 0:未启用 1:启用语音质检 2:启用实时语音质检 3:都启用
    // 1、语音通话
    switch (company.enabledCallInspect) {
        case Udesk.enums.companyEnableInspect.regular.id:
            inspectDataSources.push(Udesk.enums.inspectDataSources.voiceCall);
            break;
        case Udesk.enums.companyEnableInspect.realTime.id:
            inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeVoiceCall);
            break;
        case Udesk.enums.companyEnableInspect.all.id:
            inspectDataSources.push(Udesk.enums.inspectDataSources.voiceCall);
            inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeVoiceCall);
            break;
        default:
            break;
    }
    // enabledImInspect 0:未启用 1:启用IM质检 2:启用实时IM质检 3:都启用
    // 2、文本对话
    switch (company.enabledImInspect) {
        case Udesk.enums.companyEnableInspect.regular.id:
            inspectDataSources.push(Udesk.enums.inspectDataSources.textDialogue);
            break;
        case Udesk.enums.companyEnableInspect.realTime.id:
            inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeTextDialogue);
            break;
        case Udesk.enums.companyEnableInspect.all.id:
            inspectDataSources.push(Udesk.enums.inspectDataSources.textDialogue);
            inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeTextDialogue);
            break;
        default:
            break;
    }
    if (company.enabledWeComInspect) {
        inspectDataSources.push(Udesk.enums.inspectDataSources.wechatDialogue);
    }
    if (company.enabledWeComVoiceInspect) {
        inspectDataSources.push(Udesk.enums.inspectDataSources.wechatRadio);
    }
    if (company.enabledMallInspect) {
        inspectDataSources.push(Udesk.enums.inspectDataSources.taobao);
    }

    if (company.enabledTicket) {
        inspectDataSources.push(Udesk.enums.inspectDataSources.ticket);
    }
    return inspectDataSources;

}

function isTextDialogue(inspectDataSource) {
    const { realTimeTextDialogue, textDialogue } = Udesk.enums.inspectDataSources;
    return [textDialogue.id, realTimeTextDialogue.id].includes(inspectDataSource);
}

function isVoiceCall(inspectDataSource) {
    const { voiceCall, realTimeVoiceCall } = Udesk.enums.inspectDataSources;
    return [voiceCall.id, realTimeVoiceCall.id].includes(inspectDataSource);
}

function isTaobao(inspectDataSource) {
    const { taobao } = Udesk.enums.inspectDataSources;
    return [taobao.id].includes(inspectDataSource);
}

function formatInspectDataSource(inspectDataSource) {
    let type = Udesk.enums.inspectDataSources.voiceCall.id;
    switch (inspectDataSource) {
        case Udesk.enums.inspectDataSources.voiceCall.id:
        case Udesk.enums.inspectDataSources.realTimeVoiceCall.id:
            type = Udesk.enums.inspectDataSources.voiceCall.id;
            break;
        case Udesk.enums.inspectDataSources.textDialogue.id:
        case Udesk.enums.inspectDataSources.realTimeTextDialogue.id:
            type = Udesk.enums.inspectDataSources.textDialogue.id;
            break;
        case Udesk.enums.inspectDataSources.wechatDialogue.id:
            type = Udesk.enums.inspectDataSources.wechatDialogue.id;
            break;
        case Udesk.enums.inspectDataSources.wechatRadio.id:
            type = Udesk.enums.inspectDataSources.wechatRadio.id;
            break;
        case Udesk.enums.inspectDataSources.taobao.id:
            type = Udesk.enums.inspectDataSources.taobao.id;
            break;
        case Udesk.enums.inspectDataSources.ticket.id:
            type = Udesk.enums.inspectDataSources.ticket.id;
            break;
        default:
            break;
    }
    return type;
}

function inspectDataSourcesIncludedIn(targetDataSourceKeys, currentDataSource) {
    return targetDataSourceKeys.some(key => Udesk.enums.inspectDataSources[key]?.id === currentDataSource);
}

export default {
    getInspectDataSources,
    isTextDialogue,
    isVoiceCall,
    isTaobao,
    formatInspectDataSource,
    inspectDataSourcesIncludedIn,
    filterEnabledtInspectDataSources,
};
