import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { Button, notification } from 'udesk-ui';
import { postAutoMateTasksCaseByTaskId } from 'src/api/autoMateTasks/case/{taskId}';
import { postInspectionRemarkModelConfigs } from 'src/api/inspectionRemarkModelConfigs';
import {
    getInspectionRemarkModelConfigsById,
    putInspectionRemarkModelConfigsById,
} from 'src/api/inspectionRemarkModelConfigs/{id}';

export default class TasksAutomaticAddComponent extends React.Component {
    //#region privates
    privates = {
        testList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
        conditionList: [],
        automaticArchiving: false,
        conditions: [],
        reviewOptions: [],
        spoktOptions: [],
        spoketOptionsExport: [],
        inspectionOptionsExport: [],
        reviewOptionsExport: [],
        appealOptionsList: [
            {
                label: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.appealOptionsList
                    .hiddenInspector,
                key: 'complainInspectorHidden',
            },
            {
                label: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.appealOptionsList.hiddenHit,
                key: 'complainHighLightShow',
            },
        ],
        appealOptionsExport: {
            complainInspectorHidden: 0,
            complainHighLightShow: 1,
        },
        reviewOptionsList: [],
        spoktOptionsList: [],
        inspectionOptionsList: [],
        fastSampleInDetailList: [],
        permitComplainCount: 1,
        complainPeriod: 3,
        exportNumLimit: 10000,
        permitCustomReasonList: [],
        pushRules: [],
        assessRules: [],
        sampleAssign: {
            taskType: 2,
            assignModel: Udesk.enums.distributeTypes.average.id,
            assignConfList: [],
        },
        defaultSampleAssignConf: {
            assignType: 1,
            inspectorList: [],
        },
        reviewAssign: {
            taskType: 3,
            assignModel: Udesk.enums.distributeTypes.average.id,
            assignConfList: [],
        },
        defaultReviewAssignConf: {
            assignType: 1,
            inspectorList: [],
        },
        isFilterVisible: false,
        // conditionJson: [],
        // conditionJsonInfo: [],
        // conditionJsonInfoMap: {},
        // judgeStrategyMap: {},
        //自动推送
        automaticPushConfigList: [],
        everyAgentCheckLimit: {
            status: 0,
            cycle: Udesk.enums.samplingFrequencyTypes.everyWeek.id,
            numLimit: 0,
        },
        // activeKey: 'samplingTarget',
        activeKey: 'automaticAddition',
        assignShowType: 'sampleAssign',
        pushConfigTab: '',
        pushTypeRadioValue: Udesk.enums.messageType.filter((messageType) => messageType > 1)[0].id,
        //案例库同步
        caseConfigList: [],
        remarkModel: '',
        remarkModelId: null,
        samplingTargetRef: React.createRef(),
    };
    //#endregion
    //#region actions
    actions = {
        onEdit(messageType, targetKey, action) {
            this.actions[action](messageType, targetKey);
        },
        add(messageType) {
            this.privates.automaticPushConfigList.push(
                createAutomaticPushConfig(messageType, this.privates.model.taskId)
            );
            this.actions.update();
        },
        remove(messageType, targetKey) {
            // let {configuration} = item;
            let id = targetKey;
            let { automaticPushConfigList } = this.privates;
            this.privates.automaticPushConfigList = automaticPushConfigList.filter((item) => item._id !== id);
            this.actions.update();
        },
        onPushConfigTabsChange(activeKey) {
            this.privates.pushConfigTab = activeKey;
            this.actions.update();
        },
        onPushTypeRadioChange(e) {
            this.privates.pushTypeRadioValue = e.target.value;

            this.actions.update();
        },
        changeActiveTab(activeKey) {
            this.privates.activeKey = activeKey;
            this.actions.update();
        },
        onAppealValueChanged(checkedValues = []) {
            this.privates.appealOptionsExport = checkedValues.reduce((prev, cur) => ({ ...prev, [cur]: 1 }), {
                complainInspectorHidden: 0,
                complainHighLightShow: 0,
            });
            this.actions.update();
        },
        onValueChanged(obj, key, e) {
            let value = e;
            if (e.target) {
                value = e.target.value;
            }
            obj[key] = value;
            this.actions.update();
        },

        operatorGatherChanged(val) {
            this.privates.conditionList = val;
        },

        changeStatus(type) {
            let status = this.privates.model.processStatus[type];
            let params = {
                taskId: this.privates.model.taskId,
            };

            if (status) {
                params.taskStatus = 0;
            } else {
                params.taskStatus = 1;
            }

            if (type === Udesk.enums.autoMateTasks.autoLoad.id) {
                params.taskTypes = [6];
            } else if (type === Udesk.enums.autoMateTasks.sample.id) {
                params.taskTypes = [1];
            } else if (type === Udesk.enums.autoMateTasks.assign.id) {
                params.taskTypes = [2, 3];
                // } else if (type === Udesk.enums.autoMateTasks.pushes.id) {
                //     params.taskTypes = [4];
            } else if (type === Udesk.enums.autoMateTasks.monitor.id) {
                params.taskTypes = [8];
            } else if (type === Udesk.enums.autoMateTasks.check.id) {
                params.taskTypes = [5];
            } else if (type === Udesk.enums.autoMateTasks.assess.id) {
                params.taskTypes = [7];
            } else if (type === Udesk.enums.autoMateTasks.case.id) {
                params.taskTypes = [9];
            }

            let url = Udesk.business.apiPath.concatApiPath(`/autoMateTasks`, this.props.sdkOptions);
            Udesk.ajax.put(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.updateStatusSuccess
                    );
                    this.privates.model.processStatus = resp.data;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.updateStatusFailed
                    );
                }
            );
        },

        // 1、自动添加
        saveAutomaticAddition() {
            const { privates, props } = this;
            const automaticAdd = privates.model.automaticAdd;
            const fieldList = privates.model.customFields;
            const conditions = getQueryConditionList(fieldList, privates.conditionList);
            if (!this.privates.model.automaticAdd.isAutoExecute) {
                if (conditions == null || conditions.length === 0) {
                    return Udesk.ui.notify.error(
                        Udesk.utils.string.format(
                            UdeskLocales.current.business.info.cannotBeNull,
                            UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.screeningCondition
                        )
                    );
                }

                if (
                    (automaticAdd && automaticAdd.baseFilter == null) ||
                    automaticAdd.baseFilter.judgeStrategy == null
                ) {
                    return Udesk.ui.notify.error(
                        Udesk.utils.string.format(
                            UdeskLocales.current.business.info.cannotBeNull,
                            UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.conditionalRules
                        )
                    );
                }
            }
            let params = {
                ...automaticAdd,
                baseFilter: {
                    conditionList: conditions,
                    judgeStrategy: automaticAdd.baseFilter.judgeStrategy,
                    customJudgeLogic: automaticAdd.baseFilter.customJudgeLogic,
                },
                intelligentCondition: automaticAdd.intelligentCondition,
            };
            let url = Udesk.business.apiPath.concatApiPath(
                '/autoMateTasks/autoLoad-plus/' + privates.model.taskId,
                props.sdkOptions
            );
            Udesk.ajax.post(url, [params]).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        this.props.route.name === 'informationCollectDataAddAuto'
                            ? `${UdeskLocales.current.components.pages.dataAdd.types.auto}${UdeskLocales.current.business.info.editSuccess}`
                            : `${UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.name}${UdeskLocales.current.business.info.editSuccess}`
                    );
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            `${UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.name}${UdeskLocales.current.business.info.editError}`
                    );
                }
            );
        },

        // 2、自动抽检
        saveAutomaticSamplingInspection() {
            let conditions = Udesk.utils.object.deepCopy(this.privates.conditions);
            const fieldList = this.privates.model.customFields;

            if (conditions instanceof Array && conditions.length < 1) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.automaticSamplingInspection
                    )
                );
            }

            if (conditions instanceof Array && conditions.length > 0) {
                conditions = conditions.map((condition) => {
                    if (
                        condition.baseFilter &&
                        condition.baseFilter.conditionList &&
                        condition.baseFilter.conditionList.length > 0
                    ) {
                        condition.baseFilter.conditionList = getQueryConditionList(
                            fieldList,
                            condition.baseFilter.conditionList
                        );
                    }
                    if (condition.sampleConf.sampleAvg) {
                        condition.sampleConf.sampleAvg = 1;
                    } else {
                        condition.sampleConf.sampleAvg = 0;
                    }
                    if (condition.sampleConf.sampleReCheck) {
                        condition.sampleConf.sampleReCheck = 1;
                    } else {
                        condition.sampleConf.sampleReCheck = 0;
                    }
                    return condition;
                });
            }

            let sampleNumberOrPercentIsNull = false;
            conditions.forEach((condition) => {
                if (condition.sampleConf.sampleForm === Udesk.enums.samplingTypes.random.id) {
                    if (condition.sampleConf.random == null || condition.sampleConf.random.trim === '') {
                        sampleNumberOrPercentIsNull = true;
                    }
                } else if (condition.sampleConf.sampleForm === Udesk.enums.samplingTypes.proportional.id) {
                    if (condition.sampleConf.percent == null || condition.sampleConf.percent.toString().trim() === '') {
                        sampleNumberOrPercentIsNull = true;
                    }
                }
            });

            if (sampleNumberOrPercentIsNull) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.sampleNumberOrPercent
                    )
                );
            }

            let promiseList = [];
            let url = Udesk.business.apiPath.concatApiPath(`/autoMateTasks/samples`, this.props.sdkOptions);
            promiseList.push(Udesk.ajax.post(url, conditions));
            const everyAgentCheckLimitId = this.privates.everyAgentCheckLimit.id;
            let url2 = Udesk.business.apiPath.concatApiPath(
                everyAgentCheckLimitId ? `/flowConfigs/${everyAgentCheckLimitId}` : `/flowConfigs`,
                this.props.sdkOptions
            );
            promiseList.push(
                Udesk.ajax[everyAgentCheckLimitId ? 'put' : 'post'](url2, {
                    ...this.privates.everyAgentCheckLimit,
                    taskId: this.props.match.params.taskId,
                    type: 0,
                })
            );

            Promise.all(promiseList).then(
                ([a, everyAgentCheckLimit]) => {
                    if (everyAgentCheckLimit?.data) {
                        this.privates.everyAgentCheckLimit = everyAgentCheckLimit?.data;
                        this.actions.update();
                    }
                    Udesk.ui.notify.success(UdeskLocales.current.business.info.saveSuccess);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.info.saveError);
                }
            );
            // everyAgentCheckLimit
        },
        conditionsChanged(conditions) {
            if (conditions instanceof Array) {
                this.privates.conditions = conditions;
                this.actions.update();
            }
        },
        onEveryAgentCheckLimitStatusChange(e) {
            this.privates.everyAgentCheckLimit.status = e.target.checked ? 1 : 0;
            this.actions.update();
        },
        onEveryAgentCheckLimitChange(type, value) {
            this.privates.everyAgentCheckLimit[type] = value;
            this.actions.update();
        },
        // 3、自动分配
        saveAutomaticAllocation() {
            const fieldList = this.privates.model.customFields;
            let taskId = this.privates.model.taskId;
            let inspectorListisNull = false;

            let sampleAssign = Udesk.utils.object.deepCopy(this.privates.sampleAssign);
            sampleAssign.taskId = taskId;
            if (sampleAssign.assignModel === Udesk.enums.distributeTypes.average.id) {
                sampleAssign.assignConfList = [];
                if (
                    !Array.isArray(this.privates.model.processCreateStatus.inspectorList) ||
                    !this.privates.model.processCreateStatus.inspectorList.length
                ) {
                    Udesk.ui.notify.error(
                        UdeskLocales.current.business.notifyMessage.AutomaticAllocationInspectorsIsNull
                    );
                    return;
                }
            } else {
                let assignConfList = sampleAssign.assignConfList;
                if (assignConfList && assignConfList.length > 0) {
                    assignConfList.forEach((rule) => {
                        if (rule.assignConf.assignType === Udesk.enums.inspectorTypes.some.id) {
                            if (rule.assignConf.inspectors == null || rule.assignConf.inspectors.trim() === '') {
                                inspectorListisNull = true;
                            }
                        }
                        if (
                            rule.baseFilter &&
                            rule.baseFilter.conditionList &&
                            rule.baseFilter.conditionList.length > 0
                        ) {
                            rule.baseFilter.conditionList = getQueryConditionList(
                                fieldList,
                                rule.baseFilter.conditionList
                            );
                        }
                    });
                }
            }

            let reviewAssign = Udesk.utils.object.deepCopy(this.privates.reviewAssign);
            reviewAssign.taskId = taskId;
            if (reviewAssign.assignModel === Udesk.enums.distributeTypes.average.id) {
                reviewAssign.assignConfList = [];
                if (
                    !Array.isArray(this.privates.model.processCreateStatus.inspectorList) ||
                    !this.privates.model.processCreateStatus.inspectorList.length
                ) {
                    Udesk.ui.notify.error(
                        UdeskLocales.current.business.notifyMessage.AutomaticAllocationInspectorsIsNull
                    );
                    return;
                }
            } else {
                let assignConfList = reviewAssign.assignConfList;
                if (assignConfList && assignConfList.length > 0) {
                    assignConfList.forEach((rule) => {
                        if (rule.assignConf.assignType === Udesk.enums.inspectorTypes.some.id) {
                            if (rule.assignConf.inspectors == null || rule.assignConf.inspectors.trim() === '') {
                                inspectorListisNull = true;
                            }
                        }
                        if (
                            rule.baseFilter &&
                            rule.baseFilter.conditionList &&
                            rule.baseFilter.conditionList.length > 0
                        ) {
                            rule.baseFilter.conditionList = getQueryConditionList(
                                fieldList,
                                rule.baseFilter.conditionList
                            );
                        }
                    });
                }
            }
            const defaultSampleAssignConf = {
                ...this.privates.defaultSampleAssignConf,
                inspectors: (this.privates.defaultSampleAssignConf.inspectorList || []).join(','),
                defaultFlag: 1,
            };
            const defaultReviewAssignConf = {
                ...this.privates.defaultReviewAssignConf,
                inspectors: (this.privates.defaultReviewAssignConf.inspectorList || []).join(','),
                defaultFlag: 1,
            };
            if (
                defaultSampleAssignConf.assignType === Udesk.enums.inspectorTypes.some.id &&
                !defaultSampleAssignConf.inspectorList?.length
            ) {
                inspectorListisNull = true;
            }
            if (
                defaultReviewAssignConf.assignType === Udesk.enums.inspectorTypes.some.id &&
                !defaultReviewAssignConf.inspectorList?.length
            ) {
                inspectorListisNull = true;
            }

            reviewAssign.enabledSecondLevelRecheck = reviewAssign.enabledSecondLevelRecheck ? 1 : 0;
            if (reviewAssign.enabledSecondLevelRecheck && reviewAssign.secondLevelInspectors === undefined) {
                Udesk.ui.notify.error(/* 请添加二级质检员 */UdeskLocales.current.pages.tasks.manage.template.automaticAdd.component.pleaseAddASecondLevelQualityInspector);
                return;
            }
            if (inspectorListisNull) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.inspectorList
                    )
                );
            }
            sampleAssign.assignConfList.push({ assignConf: defaultSampleAssignConf });
            reviewAssign.assignConfList.push({ assignConf: defaultReviewAssignConf });
            let params = [sampleAssign, reviewAssign];
            let url = Udesk.business.apiPath.concatApiPath(`/autoMateTasks/assigns`, this.props.sdkOptions);
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(UdeskLocales.current.business.info.saveSuccess);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.info.saveError);
                }
            );
        },

        sampleAssignListChanged(list) {
            const sampleAssign = this.privates.sampleAssign;
            sampleAssign.assignConfList = list;
            this.actions.update();
        },

        reviewAssignListChanged(list) {
            const reviewAssign = this.privates.reviewAssign;
            reviewAssign.assignConfList = list;
            this.actions.update();
        },

        // 4、自动推送
        saveAutomaticPush() {
            // let pushRules = Udesk.utils.object.deepCopy(this.privates.pushRules);
            // const fieldList = this.privates.model.customFields;

            // if (pushRules instanceof Array && pushRules.length < 1) {
            //     return Udesk.ui.notify.error(
            //         Udesk.utils.string.format(
            //             UdeskLocales.current.business.info.cannotBeNull,
            //             UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.automaticPush
            //         )
            //     );
            // }

            // pushRules.forEach(rule => {
            //     if (rule.baseFilter && rule.baseFilter.conditionList && rule.baseFilter.conditionList.length > 0) {
            //         rule.baseFilter.conditionList = getQueryConditionList(fieldList, rule.baseFilter.conditionList);
            //     }
            // });
            let { automaticPushConfigList } = this.privates;
            if (automaticPushConfigList instanceof Array && automaticPushConfigList.length < 1) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.automaticPush
                    )
                );
            }

            let url = Udesk.business.apiPath.concatApiPath(`/autoMateTasks/monitor`, this.props.sdkOptions);
            let isWechatQa = [
                Udesk.enums.inspectDataSources.wechatDialogue.id,
                Udesk.enums.inspectDataSources.wechatRadio.id,
            ].includes(this.privates.type);
            if (isWechatQa) {
                url = Udesk.business.apiPath.concatApiPath(`/autoMateTasks/wecom/monitor`, this.props.sdkOptions);
            }
            Udesk.ajax.post(url, automaticPushConfigList).then(
                (resp) => {
                    Udesk.ui.notify.success(UdeskLocales.current.business.info.saveSuccess);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.info.saveError);
                }
            );
        },

        pushRulesChanged(rules) {
            this.privates.pushRules = rules;
            this.actions.update();
        },

        deleteItem(index, item) {
            let { configuration } = item;
            let id = configuration._id;
            let { automaticPushConfigList } = this.privates;
            this.privates.automaticPushConfigList = automaticPushConfigList.filter((item) => item._id !== id);
            this.actions.update();
        },

        addItem(messageType) {
            this.privates.automaticPushConfigList.push(
                createAutomaticPushConfig(messageType, this.privates.model.taskId)
            );
            this.actions.update();
        },
        addCaseItem() {
            this.privates.caseConfigList.push({
                _id: Udesk.utils.string.uuid(),
            });
            this.actions.update();
        },
        deleteCaseItem(index) {
            this.privates.caseConfigList = this.privates.caseConfigList.filter((item, i) => index !== i);
            this.actions.update();
        },
        onCaseItemChange(index, newValues) {
            if (this.privates.caseConfigList[index]) {
                this.privates.caseConfigList[index] = { ...this.privates.caseConfigList[index], ...newValues };
                this.actions.update();
            }
        },
        onMassageConfigurationChange(condition, newCheckedTypes, id) {
            // condition = newConfiguration;

            this.privates.automaticPushConfigList.forEach((automaticPushConfig) => {
                if (
                    automaticPushConfig._id === id &&
                    automaticPushConfig.monitorConf &&
                    Array.isArray(automaticPushConfig.monitorConf.pushConf)
                ) {
                    for (let i = 0; i < automaticPushConfig.monitorConf.pushConf.length; i++) {
                        let item = automaticPushConfig.monitorConf.pushConf[i];
                        if (newCheckedTypes.includes(item.actionType)) {
                            item.enabled = 1;
                        } else {
                            item.enabled = 0;
                        }
                    }
                }
            });
            this.actions.update();
        },
        onConfigurationChange(newConfiguration) {
            this.privates.automaticPushConfigList.forEach((automaticPushConfig) => {
                if (automaticPushConfig._id === newConfiguration._id) {
                    Object.assign(automaticPushConfig, newConfiguration);
                }
            });
            this.actions.update();
        },

        // 5、自动评估
        saveAutomaticAssess() {
            let assessRules = Udesk.utils.object.deepCopy(this.privates.assessRules);
            const fieldList = this.privates.model.customFields;

            if (assessRules instanceof Array && assessRules.length < 1) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.automaticAssess
                    )
                );
            }

            assessRules.forEach((rule) => {
                if (rule.baseFilter && rule.baseFilter.conditionList && rule.baseFilter.conditionList.length > 0) {
                    rule.baseFilter.conditionList = getQueryConditionList(fieldList, rule.baseFilter.conditionList);
                }
            });

            let url = Udesk.business.apiPath.concatApiPath(`/autoMateTasks/assessment`, this.props.sdkOptions);
            Udesk.ajax.post(url, assessRules).then(
                (resp) => {
                    Udesk.ui.notify.success(UdeskLocales.current.business.info.saveSuccess);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.info.saveError);
                }
            );
        },

        assessRulesChanged(rules) {
            this.privates.assessRules = rules;
            this.actions.update();
        },

        // 案例库
        saveCaseLibrary() {
            postAutoMateTasksCaseByTaskId(
                this.privates.caseConfigList.map((item) => {
                    return {
                        taskType: 9,
                        autoMateCaseConf: { ...item, syncHis: item.syncHis ? 1 : 0 },
                        taskId: this.privates.model.taskId,
                    };
                }),
                {
                    segments: {
                        taskId: this.privates.model.taskId,
                    },
                    successMsg: UdeskLocales.current.business.info.saveSuccess,
                }
            ).then((resp) => {});
        },
        //5、抽检目标
        samplingTargetSave() {
            this.privates.samplingTargetRef.current?.formInstance?.submit?.();
        },

        // 6、申诉复核
        saveReexaminationComplaint() {
            let reviewOptions = this.privates.reviewOptions;
            let reviewOptionsExport = this.privates.reviewOptionsExport;
            let spoktOptions = this.privates.spoktOptions;
            let spoketOptionsExport = this.privates.spoketOptionsExport;
            let inspectionOptionsExport = this.privates.inspectionOptionsExport;
            let fastSampleInDetailList = this.privates.fastSampleInDetailList;
            let permitCustomReasonList = this.privates.permitCustomReasonList;
            let permitComplainCount = this.privates.permitComplainCount;
            let complainPeriod = this.privates.complainPeriod;
            let checkConf = { ...this.privates.appealOptionsExport };
            if (reviewOptions && reviewOptions.length > 0) {
                reviewOptions.forEach((option) => {
                    if (option === Udesk.enums.reviewlistResultOptions.checkResult.id) {
                        checkConf.checkResult = 1;
                    } else if (option === Udesk.enums.reviewlistResultOptions.checkRecord.id) {
                        checkConf.checkRecord = 1;
                    } else if (option === Udesk.enums.reviewlistResultOptions.reviewAgentHidden.id) {
                        checkConf.reviewAgentHidden = 1;
                    }
                });
            }

            if (spoktOptions && spoktOptions.length > 0) {
                spoktOptions.forEach((option) => {
                    if (option === Udesk.enums.spoktListResultOptions.sampleResult.id) {
                        checkConf.sampleResult = 1;
                    } else if (option === Udesk.enums.spoktListResultOptions.sampleRecord.id) {
                        checkConf.sampleRecord = 1;
                    } else if (option === Udesk.enums.spoktListResultOptions.sampleAgentHidden.id) {
                        checkConf.sampleAgentHidden = 1;
                    }
                });
            }

            if (reviewOptionsExport && reviewOptionsExport.length > 0) {
                reviewOptionsExport.forEach((option) => {
                    if (option === Udesk.enums.reviewlistResultOptionsExportIphone.checkExportAddCallInfo.id) {
                        checkConf.checkExportAddCallInfo = 1;
                    }
                });
            }

            if (spoketOptionsExport && spoketOptionsExport.length > 0) {
                spoketOptionsExport.forEach((option) => {
                    if (option === Udesk.enums.spoktListResultOptionsExportIphone.sampleExportAddCallInfo.id) {
                        checkConf.sampleExportAddCallInfo = 1;
                    }
                });
            }
            if (inspectionOptionsExport && inspectionOptionsExport.length > 0) {
                inspectionOptionsExport.forEach((option) => {
                    if (option === Udesk.enums.inspectionListResultOptionsExportIphone.inspectionExportAddCallInfo.id) {
                        checkConf.inspectionExportAddCallInfo = 1;
                    }
                });
            }

            if (fastSampleInDetailList && fastSampleInDetailList.length > 0) {
                fastSampleInDetailList.forEach((option) => {
                    if (option === Udesk.enums.fastSampleInDetailList.fastSampleInInspectDetail.id) {
                        checkConf.fastSampleInInspectDetail = 1;
                    } else if (option === Udesk.enums.fastSampleInDetailList.fastSampleInSampleDetail.id) {
                        checkConf.fastSampleInSampleDetail = 1;
                    } else if (option === Udesk.enums.fastSampleInDetailList.fastSampleInReviewDetail.id) {
                        checkConf.fastSampleInReviewDetail = 1;
                    }
                });
            }

            if (permitCustomReasonList && permitCustomReasonList.length > 0) {
                permitCustomReasonList.forEach((option) => {
                    if (option === Udesk.enums.permitCustomReasonList.permitCustomReason.id) {
                        checkConf.permitCustomReason = 1;
                    }
                });
            }

            if (complainPeriod || complainPeriod === 0) {
                checkConf.complainPeriod = complainPeriod;
            }

            if (permitComplainCount || complainPeriod === 0) {
                checkConf.permitComplainCount = permitComplainCount;
            }

            let params = {
                taskId: this.privates.model.taskId,
                taskType: 5,
                checkConf: checkConf,
            };
            let url = Udesk.business.apiPath.concatApiPath(`/autoMateTasks/checks`, this.props.sdkOptions);
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    let data = {
                        taskId: this.privates.model.taskId,
                        remarkModel: this.privates.remarkModel,
                    };
                    if (this.privates.remarkModelId) {
                        putInspectionRemarkModelConfigsById(data, {
                            segments: {
                                id: this.privates.remarkModelId,
                            },
                            successMsg: UdeskLocales.current.business.info.saveSuccess,
                        }).then(() => {
                            this.actions.getRemark();
                        });
                    } else {
                        postInspectionRemarkModelConfigs(data, {
                            successMsg: UdeskLocales.current.business.info.saveSuccess,
                        }).then(() => {
                            this.actions.getRemark();
                        });
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.info.saveError);
                }
            );
        },
        getRemark() {
            getInspectionRemarkModelConfigsById({
                segments: {
                    id: this.privates.model.taskId,
                },
            }).then((resp) => {
                if (resp.data) {
                    this.privates.remarkModel = resp.data.remarkModel ?? '';
                    this.privates.remarkModelId = resp.data.id ?? null;
                }
            });
        },
        close() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'tasksIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        sendAjaxToNext() {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${this.privates.model.processCreateStatus.id}`,
                this.props.sdkOptions
            );
            const { props } = this;
            let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
            const openNotification = () => {
                const key = `open${Date.now()}`;
                const btn = (
                    <Button
                        type='primary'
                        prefixCls='udesk-qa-ui-btn'
                        size='small'
                        onClick={() => {
                            this.actions.close();
                            notification.close(key);
                        }}
                    >
                        {UdeskLocales.current.labels.ok}
                    </Button>
                );
                notification.open({
                    prefixCls: 'udesk-qa-ui-notification',
                    message: UdeskLocales.current.business.notifyMessage.illegalTitle,
                    description: UdeskLocales.current.business.notifyMessage.illegalMessage,
                    btn,
                    key,
                    onClose: this.actions.close,
                    duration: 0,
                    style: {
                        top: '60px',
                    },
                });
            };
            if (this.privates.model.processCreateStatus.inspectDataSource === 2) {
                if (
                    (hasFeature && !hasFeature('task:template:manual:view')) ||
                    (hasFeature && !hasFeature('task:template:manual:import')) ||
                    (hasFeature && !hasFeature('task:template:manual:history'))
                ) {
                    openNotification();
                } else {
                    Udesk.ajax.put(url, { processStatus: 6 }).then(
                        (resp) => {
                            let routeOptions = {
                                history: this.props.history,
                                routeName: 'tasksManualAddCreateIndex',
                                pathParams: {
                                    taskId: this.privates.model.processCreateStatus.id,
                                },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                    Udesk.utils.string.format(
                                        UdeskLocales.current.business.info.loadError,
                                        UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                                    )
                            );
                        }
                    );
                }
            }
            if (this.privates.model.processCreateStatus.inspectDataSource === 1) {
                if (
                    (hasFeature && !hasFeature('task:template:roleConfig:view')) ||
                    (hasFeature && !hasFeature('task:template:roleConfig:edit'))
                ) {
                    openNotification();
                } else {
                    Udesk.ajax.put(url, { processStatus: 5 }).then(
                        (resp) => {
                            let routeOptions = {
                                history: this.props.history,
                                routeName: 'tasksRoleConfigCreateIndex',
                                pathParams: {
                                    taskId: this.privates.model.taskId,
                                },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                        }
                    );
                }
            }
        },
        handleNext() {
            let url2 = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/nextCheck/${this.privates.model.taskId}`,
                this.props.sdkOptions
            );
            if (
                this.privates.model.processCreateStatus.related === 1 &&
                Udesk.business.task.isTextDialogue(this.privates.model.processCreateStatus.inspectDataSource)
            ) {
                this.actions.exitBoot();
                return;
            }
            Udesk.ajax.post(url2, { processStatus: 4 }).then(
                (resp) => {
                    if (resp.data) {
                        this.actions.sendAjaxToNext();
                    } else {
                        Udesk.ui.notify.error(UdeskLocales.current.business.notifyMessage.skipMessage);
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        exitBoot() {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${this.privates.model.taskId}`,
                this.props.sdkOptions
            );
            Udesk.ajax.put(url, { processStatus: -1 }).then(
                (resp) => {
                    const routeOptions = {
                        history: this.props.history,
                        routeName: 'tasksAutomaticAddIndex',
                        pathParams: { taskId: this.privates.model.taskId },
                    };
                    Udesk.ui.routing.transitionTo(routeOptions);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },

        //高级筛选器
        openCustomFilterModal() {
            this.privates.isFilterVisible = true;
            this.actions.update();
        },
        handleConditionCancel() {
            this.privates.isFilterVisible = false;
            this.actions.update();
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy) {
            // let { editKey } = this.privates;
            this.privates.conditionList = conditionList;
            this.privates.model.automaticAdd.baseFilter.judgeStrategy = judgeStrategy;
            if (judgeStrategy !== 3) {
                this.privates.model.automaticAdd.baseFilter.customJudgeLogic = '';
            } else {
                this.privates.model.automaticAdd.baseFilter.customJudgeLogic = customJudgeLogic;
            }
            this.privates.customId = null;
            this.privates.customName = null;

            this.actions.update();
        },
        onTimingAddChange(isAutoExecute, autoMateLoadConf, shouldUpdate) {
            this.privates.model.automaticAdd.isAutoExecute = isAutoExecute;
            if (shouldUpdate) {
                this.privates.model.automaticAdd.autoMateLoadConf = autoMateLoadConf;
            }
            this.actions.update();
        },
        editFilterOhter(list, radioValue, customInfo) {
            this.privates.isFilterVisible = true;
            this.privates.judgeStrategyList = radioValue || 1;
            // this.privates.customJudgeLogicList = customInfo || '';
            this.privates.conditionList = list || [];
            this.actions.update();
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.forEach((item) => {
                if (item.value) {
                    if (
                        item.field &&
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach((items) => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label + ' ' + obj[item.operator] + ' ' + str.substring(0, str.length - 1),
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName || item.value.agentGroupName || item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <div
                        style={{
                            color: 'rgba(0, 0, 0, 0.65)',
                            marginBottom: index + 1 === list.length ? '0px' : '8px',
                        }}
                    >
                        {index + 1}. {item}
                    </div>
                );
            });
        },
        onAssignTypeChange(e) {
            this.privates.assignShowType = e.target.value;
            this.actions.update();
        },
        onRemarkModelChange(e) {
            this.privates.remarkModel = e.target.value;
            this.actions.update();
        },
    };
    //#endregion

    //#region Life Cycle
    onModelResolved(model) {
        // 准备自动添加数据
        let automaticAdd = model.automaticAdd;
        if (automaticAdd.baseFilter) {
            this.actions.handleConditionApply(
                automaticAdd.baseFilter.conditionList,
                automaticAdd.baseFilter.customJudgeLogic,
                automaticAdd.baseFilter.judgeStrategy
            );
        }

        // 准备自动抽检数据
        let autoSample = model.autoSample;

        if (autoSample && autoSample instanceof Array && autoSample.length > 0) {
            this.privates.conditions = autoSample;
        }

        // 准备自动分配数据
        let autoAssign = model.autoAssign;
        if (autoAssign && autoAssign instanceof Array && autoAssign.length > 0) {
            autoAssign.forEach((item) => {
                let assignConfList = item.assignConfList || [];
                if (assignConfList && assignConfList instanceof Array && assignConfList.length > 0) {
                    assignConfList.forEach((option) => {
                        if (option.assignConf && option.assignConf.assignType === Udesk.enums.inspectorTypes.some.id) {
                            if (!option.assignConf.inspectorList) {
                                if (option.assignConf.inspectors) {
                                    option.assignConf.inspectorList = option.assignConf.inspectors
                                        .split(',')
                                        .map((item) => ({
                                            id: parseInt(item, 10),
                                            realname: '--', // 后端没有回传对象，回显会有问题
                                        }));
                                } else {
                                    option.assignConf.inspectorList = [];
                                }
                            }
                        }
                    });
                }
                if (item.taskType === 2) {
                    this.privates.sampleAssign = {
                        ...item,
                        assignConfList: item.assignConfList.filter((c) => !c.assignConf.defaultFlag),
                    };
                    const defaultSampleAssignConf = item.assignConfList.filter((c) => c.assignConf.defaultFlag === 1);
                    if (defaultSampleAssignConf?.length) {
                        let assignConf = defaultSampleAssignConf[0]?.assignConf ?? {};
                        assignConf.inspectorList = (assignConf.inspectorList || []).map((i) => i.id);
                        this.privates.defaultSampleAssignConf = assignConf;
                    }
                }
                if (item.taskType === 3) {
                    this.privates.reviewAssign = {
                        ...item,
                        assignConfList: item.assignConfList.filter((c) => !c.assignConf.defaultFlag),
                    };
                    const defaultReviewAssignConf = item.assignConfList.filter((c) => c.assignConf.defaultFlag === 1);
                    if (defaultReviewAssignConf?.length) {
                        let assignConf = defaultReviewAssignConf[0]?.assignConf ?? {};
                        assignConf.inspectorList = (assignConf.inspectorList || []).map((i) => i.id);
                        this.privates.defaultReviewAssignConf = assignConf;
                    }
                }
            });
        }

        // 准备自动推送数据
        let autoPush = model.autoPush;
        if (autoPush && autoPush instanceof Array && autoPush.length > 0) {
            autoPush.forEach((item) => {
                item._id = Udesk.utils.string.uuid();
                if (item.monitorConf && Array.isArray(item.monitorConf.pushConf)) {
                    item.monitorConf.pushConf.forEach((pushConf) => {
                        if (parseInt(pushConf.actionType, 10) === Udesk.enums.messagePushType.interfacePush.id) {
                            pushConf.actionDetails.additionalDataList.forEach((additionalData) => {
                                !additionalData._id && (additionalData._id = Udesk.utils.string.uuid());
                            });
                        }
                    });
                    item.monitorConf.pushConf = item.monitorConf.pushConf.filter((item) => {
                        if (item.actionType !== Udesk.enums.messagePushType.udesk.id) {
                            return true;
                        }
                        return Udesk.data.init.middleware === 'udesk';
                    });
                }
            });
            this.privates.pushRules = autoPush;
        }

        this.privates.automaticPushConfigList = autoPush;
        // this.privates.pushConfigTab = this.privates.automaticPushConfigList.filter(
        //     (item) =>
        //         item.monitorConf &&
        //         item.monitorConf.messageType === messageType.id
        // )
        // 准备自动评估数据
        let { autoAssess } = model;
        this.privates.assessRules = [...autoAssess];

        // 准备抽检配置与复核配置数据
        let autoCheck = model.autoCheck;
        let reviewOptions = [],
            spoktOptions = [],
            spoketOptionsExport = [],
            inspectionOptionsExport = [],
            reviewOptionsExport = [],
            fastSampleInDetailList = [],
            permitCustomReasonList = [];
        if (autoCheck && autoCheck instanceof Array && autoCheck.length > 0) {
            let checkConf = autoCheck[0].checkConf;
            if (checkConf) {
                if (checkConf.checkResult) {
                    reviewOptions.push(1);
                }
                if (checkConf.checkRecord) {
                    reviewOptions.push(2);
                }
                if (checkConf.reviewAgentHidden) {
                    reviewOptions.push(3);
                }

                if (checkConf.sampleResult) {
                    spoktOptions.push(1);
                }
                if (checkConf.sampleRecord) {
                    spoktOptions.push(2);
                }
                if (checkConf.sampleAgentHidden) {
                    spoktOptions.push(3);
                }

                if (checkConf.sampleExportAddCallInfo) {
                    spoketOptionsExport.push(1);
                }

                if (checkConf.inspectionExportAddCallInfo) {
                    inspectionOptionsExport.push(1);
                }

                if (checkConf.checkExportAddCallInfo) {
                    reviewOptionsExport.push(1);
                }

                if (checkConf.fastSampleInInspectDetail) {
                    fastSampleInDetailList.push(1);
                }
                if (checkConf.fastSampleInSampleDetail) {
                    fastSampleInDetailList.push(2);
                }
                if (checkConf.fastSampleInReviewDetail) {
                    fastSampleInDetailList.push(3);
                }

                if (checkConf.permitCustomReason) {
                    permitCustomReasonList.push(1);
                }

                if (checkConf.permitComplainCount) {
                    this.privates.permitComplainCount = checkConf.permitComplainCount;
                }

                if (checkConf.complainPeriod) {
                    this.privates.complainPeriod = checkConf.complainPeriod;
                }
                if (checkConf.exportNumLimit) {
                    this.privates.exportNumLimit = checkConf.exportNumLimit;
                }

                this.privates.appealOptionsExport.complainInspectorHidden = checkConf.complainInspectorHidden || 0;
                this.privates.appealOptionsExport.complainHighLightShow = checkConf.complainHighLightShow || 0;
            }
        }
        this.privates.reviewOptions = reviewOptions;
        this.privates.spoktOptions = spoktOptions;
        this.privates.spoketOptionsExport = spoketOptionsExport;
        this.privates.inspectionOptionsExport = inspectionOptionsExport;
        this.privates.reviewOptionsExport = reviewOptionsExport;
        this.privates.fastSampleInDetailList = fastSampleInDetailList;
        this.privates.permitCustomReasonList = permitCustomReasonList;

        let taskObj = model.task;
        let spoktOptionsList = [];
        let reviewOptionsList = [];
        let inspectionOptionsList = [];
        if (taskObj && (taskObj.inspectDataSource === 1 || taskObj.inspectDataSource === 3)) {
            spoktOptionsList = Udesk.enums.spoktListResultOptionsExportIphone;
            reviewOptionsList = Udesk.enums.reviewlistResultOptionsExportIphone;
            inspectionOptionsList = Udesk.enums.inspectionListResultOptionsExportIphone.map(i => ({...i, name: i.name.replace('10000', this.privates.exportNumLimit)}));
        } else if (
            [
                Udesk.enums.inspectDataSources.textDialogue.id,
                Udesk.enums.inspectDataSources.realTimeTextDialogue.id,
                Udesk.enums.inspectDataSources.taobao.id,
            ].includes(taskObj?.inspectDataSource)
        ) {
            spoktOptionsList = Udesk.enums.spoktListResultOptionsExportText;
            reviewOptionsList = Udesk.enums.reviewlistResultOptionsExportText;
            inspectionOptionsList = Udesk.enums.inspectionListResultOptionsExportText.map(i => ({...i, name: i.name.replace('10000', this.privates.exportNumLimit)}));
        }
        this.privates.spoktOptionsList = spoktOptionsList;
        this.privates.reviewOptionsList = reviewOptionsList;
        this.privates.inspectionOptionsList = inspectionOptionsList;

    }
    componentDidMount() {
        const { props } = this;
        let { sdkOptions } = this.props;
        let params = Object.assign(
            {},
            props.match.params,
            Object.fromEntries(new URLSearchParams(props.location.search))
        );
        let statusUrl = Udesk.business.apiPath.concatApiPath(`/flowConfigs/taskId/${params.taskId}`, sdkOptions);
        Udesk.ajax.get(statusUrl).then(
            (resp) => {
                if (resp.data) {
                    this.privates.everyAgentCheckLimit = resp.data;
                    this.actions.update();
                }
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }
    //#endregion
}
function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter((item) => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map((item) => {
            return item.name;
        });
        return value.join(',');
    } else {
        return '';
    }
}

function getQueryConditionList(allFieldList, conditionList) {
    let queryConditionList = [];
    if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
        queryConditionList = Udesk.utils.object.deepCopy(conditionList);
        if (queryConditionList && queryConditionList.length > 0) {
            queryConditionList.map((condition) => {
                allFieldList.forEach((field) => {
                    if (condition.field === field.id) {
                        condition.field = field;
                    }
                });
                return condition;
            });
        }
    }
    return queryConditionList;
}

function createAutomaticPushConfig(messageType, taskId) {
    let config = AutomaticPushConfig(messageType);
    config._id = Udesk.utils.string.uuid();
    config.taskId = taskId * 1;
    config.taskType = 8;
    return config;
}

function AutomaticPushConfig(messageType) {
    let config = {
        baseFilter: {
            conditionList: [],
            judgeStrategy: 1,
            customJudgeLogic: '',
        },
        monitorConf: {
            messageType: messageType,
            pushConf: [],
            pushCycle: 1,
            daysOfWeek: [],
            daysOfMonth: [],
            pushDataTypes: [], //传空list
            name: '',
        },
    };
    if (messageType === Udesk.enums.messageType.timing.id) {
        config.monitorConf.pushConf.push(PushConfig(Udesk.enums.messagePushType.email.id));
    } else {
        Udesk.enums.messagePushType
            .filter((item) => {
                if (item.id !== Udesk.enums.messagePushType.udesk.id) {
                    return true;
                }
                return Udesk.data.init.middleware === 'udesk';
            })
            .forEach((messagePushType) => {
                if (messagePushType.id) {
                    config.monitorConf &&
                        Array.isArray(config.monitorConf.pushConf) &&
                        config.monitorConf.pushConf.push(PushConfig(messagePushType.id));
                }
            });
    }

    return config;
}

function PushConfig(messagePushType) {
    let config = {
        pushCycle: 1,
        eventSources: [],
        executeTime: '',
        email: '',
        actionDetails: {
            actionType: messagePushType,
            pushTargetList: [],
            pushUserList: [],
            pushRoleList: [],
            content: '',
        },
        pushType: 1,
        actionType: messagePushType,
        enabled: 0, //是否生效 被选中
    };
    let extra = {};
    //接口推送有附加字段
    if (messagePushType === Udesk.enums.messagePushType.interfacePush.id) {
        extra = {
            additionalDataList: [
                {
                    _id: Udesk.utils.string.uuid(),
                    key: '',
                    value: '',
                },
            ],
            type: Udesk.enums.sdkPushType.webHook.id,
            contentType: 'json',
            pushDataTypes: [1, 2, 3],
            requestMethod: 'post',
            url: '',
        };
    }
    if (messagePushType === Udesk.enums.messagePushType.email.id) {
        extra = {
            pushTargetType: Udesk.enums.pushObjectTypes.staff.id,
            subject: '',
            targetEmails: '',
        };
    }
    if (messagePushType === Udesk.enums.messagePushType.udesk.id) {
        extra = {
            pushTargetType: Udesk.enums.pushObjectTypes.staff.id,
            targetEmails: '',
        };
    }
    if (messagePushType === Udesk.enums.messagePushType.sdk.id) {
        extra = {
            SDK: '',
        };
    }

    config.actionDetails = { ...config.actionDetails, ...extra };

    return config;
}
