import React, { useMemo, useRef, ReactElement } from 'react';
import Locales from 'UdeskLocales';
import './style.scss';
import { Button } from 'udesk-ui';
import { SortableContainer, SortableItemConstructor } from './components/DragWrap';

interface propsValue {
    deleteItem: (index, childProps) => void;
    addItem: () => void;
    onSort?: (oldIndex, newIndex) => void;
    addBtnText?: string | React.ReactDOM;
    deleteBtnText?: string | React.ReactDOM | ReactElement;
    deleteBtnClassName?: string;
    addBtnDisabled?: boolean;
    dragable?: boolean;
    wrapStyle?: React.CSSProperties;
}
const UdeskList: React.FC<propsValue> = (props) => {
    const locales = Locales['current'];
    const wrapInstance = useRef<HTMLDivElement>(null);

    const deleteItem = (index, childProps) => {
        props.deleteItem && props.deleteItem(index, childProps);
    };
    const addItem = () => {
        props.addItem && props.addItem();
        setTimeout(() => {
            wrapInstance.current?.scrollTo(0, wrapInstance.current?.scrollHeight ?? 0);
        }, 0);
    };

    const SortableItem = useMemo(() => {
        return SortableItemConstructor(props.dragable);
    }, [props.dragable]);
    const onSortEnd = ({ oldIndex, newIndex }) => {
        props.onSort?.(oldIndex, newIndex);
    };

    return (
        <div className="udesk-qa-list-container">
            <div style={props.wrapStyle} ref={wrapInstance}>
                <SortableContainer lockAxis="y" onSortEnd={onSortEnd} useDragHandle>
                    {React.Children.map(
                        props.children as any,
                        (child: React.ReactElement, index) => {
                            let childProps = {};
                            if (child && child.props) {
                                childProps = child.props;
                            }
                            return (
                                <SortableItem index={index}>
                                    <div className="udesk-qa-list-item">
                                        <React.Fragment>{child}</React.Fragment>
                                        <Button
                                            className={props.deleteBtnClassName}
                                            size="small"
                                            type="link"
                                            onClick={deleteItem.bind(null, index, childProps)}
                                        >
                                            {props.deleteBtnText || (
                                                <React.Fragment>
                                                    <i className="udesk-qa-list-btn-icon udesk-qa-react-web-iconfont icon-udesk-qa-react-web-delete_minus"></i>
                                                    {locales.components.conditionGather.delete}
                                                </React.Fragment>
                                            )}
                                        </Button>
                                    </div>
                                </SortableItem>
                            );
                        }
                    )}
                </SortableContainer>
            </div>
            <div className="udesk-qa-list-control-row">
                <Button size="small" type="link" onClick={addItem} disabled={props.addBtnDisabled}>
                    <i className="udesk-qa-list-btn-icon udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1"></i>
                    {props.addBtnText || locales.components.ruleGather.addRule}
                </Button>
            </div>
        </div>
    );
};

export default UdeskList;
