import React, { useContext, useEffect, useRef, useState } from 'react';
import { Avatar, Dropdown, Icon, Menu, Space, Table } from 'udesk-ui';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import { useUdeskDatePicker } from 'Component/common/udesk-date-picker/template';
import { postIntelligentPartnerHomePageTaskRank } from 'src/api/intelligentPartner/homePage/taskRank';
import { UserContext } from '..';

const ColorList = [
    'rgb(196, 161, 238)',
    'rgb(185, 204, 79)',
    'rgb(141, 200, 234)',
    'rgb(247, 156, 111)',
    'rgb(245, 177, 104)',
];
const columnsNames = {
    practiceCostTime: /* 练习时长 */UdeskLocales['current'].pages.coach.dashboard.components.rankingList.exerciseDuration,
    commitRate: /* 达标率 */UdeskLocales['current'].pages.coach.dashboard.components.rankingList.complianceRate,
    excellentRate: /* 达优率 */UdeskLocales['current'].pages.coach.dashboard.components.rankingList.excellentRate,
};
const types = [
    {
        key: 'diligence',
        id: 1,
        label: /* 勤奋榜 */UdeskLocales['current'].pages.coach.dashboard.components.rankingList.diligenceChart,
        columns: ['practiceCostTime'],
        defaultRank: [
            {
                field: 1, // 排序字段 1-时长 2-达标率 3-达优率
                order: 1, // 排序 0-升序 1-降序
            },
        ],
    },
    {
        key: 'outstanding',
        label: /* 优秀榜 */UdeskLocales['current'].pages.coach.dashboard.components.rankingList.excellentList,
        id: 2,
        columns: ['commitRate', 'excellentRate'],
        defaultRank: [
            {
                field: 3, // 排序字段 1-时长 2-达标率 3-达优率
                order: 1, // 排序 0-升序 1-降序
            },
            {
                field: 2,
                order: 1,
            },
        ],
    },
];

const TitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #d9d9d9;
    height: 56px;
    align-items: center;
    margin-bottom: 12px;
`;

const Title = (props) => {
    const { onClick, activeType, ...datePickerProps } = props;
    const menu = (
        <Menu
            onClick={({ item, key, keyPath, domEvent }) => {
                onClick?.(key);
            }}
        >
            {types.map((item) => {
                return <Menu.Item key={item.key}>{item.label}</Menu.Item>;
            })}
        </Menu>
    );
    return (
        <TitleWrap>
            <Dropdown overlay={menu}>
                <div onClick={(e) => e.preventDefault()}>
                    <Space style={{ fontSize: '14px' }}>
                        {activeType.label}
                        <Icon type="DownOutlined" antdIcon={true} />
                    </Space>
                </div>
            </Dropdown>
            <UdeskDatePicker style={{ marginLeft: 16 }} {...datePickerProps} />
        </TitleWrap>
    );
};

const RankingListWrap = styled.div`
    width: 100%;
    padding: 0 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .udesk-qa-ui-table-cell {
        border-bottom: none !important;
    }
    .udesk-qa-ui-table-thead {
        .udesk-qa-ui-table-cell {
            background-color: #fff !important;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            line-height: 20px;
            &::before {
                display: none;
            }
        }
    }
    .udesk-qa-ui-table-tbody {
        tr {
            &:first-child {
                background: #f5f8fe;
            }
        }
    }
    .udesk-qa-ui-table-row {
        border-radius: 4px;
        height: 56px;
    }
    .ranking-list-table-wrap {
        flex: 1;
        max-height: ${(props) => (props.isAdmin ? '570px' : '536px')};
        overflow-y: auto;
    }
`;

export const RankingList: React.FC<any> = ({ isAdmin }) => {
    const defaultType = types[0];
    const wrapDom = useRef<any>(null);
    const [organizationIds] = useContext(UserContext);
    const [activeType, setActiveType] = useState(defaultType);
    const [data, setData] = useState<any[]>([]);
    const [rank, setRank] = useState<any[]>(defaultType.defaultRank);
    const { startTime, endTime, ...datePickerProps } = useUdeskDatePicker();

    const getColumns = (active) => {
        const iconMap = {
            1: 'Frame16',
            2: 'a-Frame12',
            3: 'a-Frame21',
        };
        return [
            {
                title: /* 排名 */UdeskLocales['current'].pages.coach.dashboard.components.rankingList.ranking,
                dataIndex: 'rank',
                width: 60,
                align: 'center',
                render(text, item) {
                    if (text > 3) return text;
                    return <Icon type={iconMap[text]} style={{ fontSize: '24px' }} />;
                },
            },
            {
                title: '',
                dataIndex: 'avatar',
                width: 60,
                render(text, item) {
                    return (
                        <Avatar
                            style={{
                                backgroundColor: ColorList[item.rank % 4],
                                verticalAlign: 'middle',
                            }}
                            size={30}
                            gap={4}
                        >
                            {item.studentName}
                        </Avatar>
                    );
                },
            },
            {
                title: /* 姓名 */UdeskLocales['current'].pages.coach.dashboard.components.rankingList.fullName,
                dataIndex: 'studentName',
                ellipsis: true,
                render(text) {
                    return (
                        <div
                            style={{
                                color: '#000',
                                fontSize: '14px',
                            }}
                        >
                            {text}
                        </div>
                    );
                },
            },
        ].concat(
            active.columns.map((c, i) => {
                return {
                    title: columnsNames[c],
                    dataIndex: c,
                    defaultSortOrder: 'descend',
                    sorter: {
                        // compare: (a, b) => {
                        //     if (a.alwaysTop) return -1;
                        //     if (b.alwaysTop) return 1;
                        //     console.log('a, b', a, b);
                        //     return a[c] - b[c];
                        // },
                        multiple: 10 - i,
                    },
                    width: 100,
                    render(text, item) {
                        let count = text;
                        if ('diligence' === active.key) {
                            count = getTotalTime(item[c]);
                        }
                        return (
                            <div
                                style={{
                                    color: '#1A6EFF',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {count}
                            </div>
                        );
                    },
                };
            })
        );
    };

    const [columns, setColumns] = useState<any[]>(getColumns(defaultType));

    const onTypeChange = (key) => {
        const active = types.find((t) => t.key === key);
        if (active) {
            setActiveType(active);
            setColumns(getColumns(active));
            wrapDom.current.scrollTop = 0;
            setRank(active.defaultRank);
        }
    };
    useEffect(() => {
        // POST /intelligentPartner/homePage/taskRank
        const params = {
            endDate: endTime.split(' ')[0],
            startDate: startTime.split(' ')[0],
            listType: activeType.id,
            rank,
            organizationIds,
        };
        postIntelligentPartnerHomePageTaskRank(params).then((resp) => {
            const data = resp.data ?? [];
            const self: typeof data = data
                .filter((i) => i.isSelf)
                .map((i) => ({ ...i, studentName: /* 我 */UdeskLocales['current'].pages.coach.dashboard.components.rankingList.i, alwaysTop: true }));
            setData(self.concat(data));
        });
    }, [activeType.key, startTime, endTime, rank, organizationIds]);
    return (
        <RankingListWrap className="ranking-list-wrap" isAdmin={isAdmin}>
            <Title onClick={onTypeChange} activeType={activeType} {...datePickerProps} />
            <div className="ranking-list-table-wrap" ref={wrapDom}>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    key={activeType.key}
                    onChange={(pagination, filters, sorter) => {
                        console.log('sorter', sorter);
                        const fieldMap = {
                            commitRate: 2,
                            excellentRate: 3,
                            practiceCostTime: 1,
                        };

                        let sorterByOrder = Array.isArray(sorter) ? [...sorter] : [sorter];
                        sorterByOrder.sort(
                            (a: any, b: any) => b.column.sorter.multiple - a.column.sorter.multiple
                        );
                        setRank(
                            sorterByOrder.map((s: any) => ({
                                field: fieldMap[s.field],
                                order: s.order === 'ascend' ? 0 : 1,
                            }))
                        );
                    }}
                />
            </div>
        </RankingListWrap>
    );
};

function getTotalTime(practiceCostTime) {
    if (!practiceCostTime) return '-';
    const min = practiceCostTime / 60;
    const hour = min / 60;
    if (hour > 1) return `${hour.toFixed(2)}h`;
    if (min > 1) return `${min.toFixed(2)}min`;
    return `${practiceCostTime}s`;
}
