import React from 'react';
import { apiPrefix } from 'src/util/settings';
import Udesk from 'Udesk';

class SimilarDiscoverIndexComponent extends React.Component {
    static propTypes = {};
    static defaultProps = {};
    state = {};
    privates = {
        inputValue: '',
        similarWords: [],
        SimilarList: [],
        copyFlag: [],
        copyTimer: [],
        searchFlag: false,
    };

    actions = {
        inputValueChangeHandle(e) {
            this.privates.inputValue = e.target.value;
            this.actions.update();
        },
        inputValueFormatter() {
            this.privates.inputValue = this.privates.inputValue.trim();
            this.actions.update();
        },
        search() {
            //点击搜索按钮会失去焦点，自动调用formatter
            if (this.privates.inputValue === '') return;
            this.privates.copyTimer = [];
            this.actions.getSearchData();
        },
        keyupadditem(e) {
            if (e.which !== 13) return;
            this.actions.inputValueFormatter();
            this.actions.search();
        },
        getSearchData() {
            //发送ajax
            //resp.data => this.privates.similarWords
            let pamars = {
                keywords: this.privates.inputValue,
            };
            let url = `/${apiPrefix}/semantic-tag/similarWord`;
            Udesk.ajax.post(url, pamars).then(
                (resp) => {
                    this.privates.copyFlag = [];
                    this.privates.similarWords = resp.data;
                    for (let i = 0; i < this.privates.similarWords.length; i++) {
                        this.privates.copyFlag[i] = false;
                    }
                    this.privates.searchFlag = true;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        setSimilarListRefs(ref) {
            this.privates.SimilarList.push(ref);
            this.actions.update();
        },
        clickCopy(similarWord, index, event) {
            clearInterval(this.privates.copyTimer[index]);
            let transfer = document.createElement('input');
            document.body.appendChild(transfer);
            transfer.value = similarWord;
            transfer.focus();
            transfer.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
            }
            transfer.blur();
            document.body.removeChild(transfer);
            // e.currentTarget.style.backgroundColor='rgba(27,109,255,1)';
            // e.currentTarget.
            this.privates.copyFlag[index] = true;
            this.privates.copyTimer[index] = setInterval(() => {
                this.privates.copyFlag[index] = false;
                this.actions.update();
                clearInterval(this.privates.copyTimer[index]);
            }, 1000);
            this.actions.update();
            event.stopPropagation();
        },
        wordClicked(similarWord, index) {
            this.privates.inputValue = this.privates.inputValue + ' ' + similarWord;
            this.actions.search();
            this.actions.update();
        },
    };

    componentDidMount() {}
}

export default SimilarDiscoverIndexComponent;
