import React from 'react';
import Udesk from 'Udesk';
import { Redirect } from 'react-router-dom';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import NavBar from 'udesk-react/src/components/nav-bar';
import SmartWordsLibraryComponent from './component';
import { PageHeader, Tooltip, Icon } from 'udesk-ui';
import { getCurrentRouteName } from 'Udesk/system/subApp';
// 不引入udesk-ui全局样式后,需要单独引入某个组件样式
// import 'udesk-ui/lib/form/style/index.css';
// import 'udesk-ui/lib/grid/style/index.css';
// import 'udesk-ui/lib/input/style/index.css';

export default class SmartWordsLibraryTemplate extends SmartWordsLibraryComponent {
    render() {
        let { routes, route, location, sdkOptions } = this.props;
        let { locales } = this;
        const company = Udesk.data.init.company;
        let navLists = [
            {
                pathName: getCurrentRouteName('smartWordsLibrarySynonymWords'),
                text: locales.components.pages.smartWordsLibrary.navTabNames.synonymWords,
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            },
            {
                pathName: getCurrentRouteName('smartWordsLibrarySensitiveWords'),
                text: /* 敏感词 */ locales.pages.semanticIntelligence.smartWordsLibrary.template
                    .sensitiveWords,
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            },
            {
                pathName: getCurrentRouteName('smartWordsLibraryProfessionalWords'),
                text: locales.components.pages.smartWordsLibrary.navTabNames.professionalWords,
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            },
            {
                pathName: getCurrentRouteName('smartWordsLibraryAttentionWords'),
                text: locales.components.pages.smartWordsLibrary.navTabNames.attentionWords,
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            },
            {
                pathName: getCurrentRouteName('smartWordsLibraryIgnoreWords'),
                text: locales.components.pages.smartWordsLibrary.navTabNames.ignoreWords,
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            },
            {
                pathName: getCurrentRouteName('smartWordsLibraryWordsWeight'),
                text: locales.components.pages.smartWordsLibrary.navTabNames.wordsWeight,
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            },
            {
                pathName: getCurrentRouteName('smartWordsLibraryCorpusData'),
                text: locales.components.pages.smartWordsLibrary.navTabNames.corpusData,
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            },
        ];
        if (company.enabledImInspect || company.enabledWeComInspect) {
            navLists.push({
                pathName: getCurrentRouteName('smartWordsLibraryTypoLibrary'),
                text: locales.components.pages.smartWordsLibrary.navTabNames.typoLibrary,
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            });
        }

        if (this.isRedirect(route, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: route.name,
                pathParams: this.props.match.params,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                // <div className="smart-words-library">
                <div className="udesk-qa-web-page">
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        title={locales.components.pages.smartWordsLibrary.title}
                        subTitle={
                            <Tooltip
                                placement="bottomLeft"
                                overlayStyle={{ maxWidth: 'unset' }}
                                title={
                                    <div>
                                        <div>{locales.components.pages.smartWordsLibrary.tip1}</div>
                                        <div>
                                            {/* 敏感词：敏感词可配置业务关注词，用于舆情发现敏感词词云 */}
                                            {
                                                locales.pages.semanticIntelligence.smartWordsLibrary
                                                    .template
                                                    .sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds
                                            }
                                        </div>
                                        <div>{locales.components.pages.smartWordsLibrary.tip2}</div>
                                        <div>{locales.components.pages.smartWordsLibrary.tip3}</div>
                                        <div>{locales.components.pages.smartWordsLibrary.tip4}</div>
                                        <div>{locales.components.pages.smartWordsLibrary.tip5}</div>
                                        <div>{locales.components.pages.smartWordsLibrary.tip6}</div>
                                    </div>
                                }
                            >
                                <Icon type="QuestionCircleOutlined" antdIcon={true}></Icon>
                            </Tooltip>
                        }
                        extra={[
                            <NavBar
                                key={'udesk-qa-web-page-header'}
                                navLists={navLists}
                                classNames="smart-words-library-page-header-right"
                                navItemClassNames="smart-words-library-page-header-nav-item"
                                activeClassNames="smart-words-library-page-header-nav-item-active"
                            />,
                        ]}
                    />
                    <div className="udesk-qa-web-page-body">
                        <div className="udesk-qa-web-page-body-root smart-words-library-page-content">
                            <For each="route" index="i" of={routes}>
                                <SubRoutes key={i} route={route} />
                            </For>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
