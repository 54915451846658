import React from 'react';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';
import styled from 'styled-components';
import { useScoreDetailContext } from '../Context';
import { Space } from 'udesk-ui';
import { Text } from '../Text';
import { inspect } from 'src/util/core';
import UdeskLocales from 'UdeskLocales';

const Title = styled.div`
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    margin-top: 24px;

    > div {
        display: flex;
    }

    .over-view-title {
        margin-right: 12px;
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
    }
    .over-view-title-total {
        color: rgba(0, 0, 0, 0.45);
    }

    .over-view-title-grade {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;

        >span:nth-child(1) {
            >span {
                color: #1A6EFF;
                margin: 0 4px;
            }
        }
        >span:nth-child(2) {
            color: rgba(0,0,0,0.45);
            margin-left: 6px;
        }
    }
`;

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;

    .over-view-content-detail {
        flex: 1;
        margin-right: 16px;

        > div {
            padding: 12px;
            background: #F5F6F8;
            border-radius: 4px;
            word-break: keep-all;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;

            + div {
                margin-top: 12px;
            }
        }

        .over-view-content-detail-title {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;

            .score {
                color: #555;

                > span:nth-child(1) {
                    font-size: 18px;
                    color: #1A6EFF;
                }
            }
        }
    }
`;

export const OverView = React.memo((props: any) => {
    // const locales = Locales['current'];
    const { scoreOverView, rank, total } = useScoreDetailContext();
    const { speechCraftScore, emotionScore, speedScore} = scoreOverView || {};

    return (
        <div className="over-view">
            <Title>
                <div>
                    <div className="over-view-title">{/* 评分总览 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.ratingOverview}</div>
                    <div className="over-view-title-total">{`${/* 总分 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.totalScore}${
                        scoreOverView?.totalScore ?? '-'
                    }`}</div>
                </div>
                <div className="over-view-title-grade">
                    <span>{/* 第 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.section}<span>{rank}</span>{/* 名 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.name}</span>
                    <span>{/* (共 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.of}{total}{/* 人) */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.people}</span>
                </div>
            </Title>
            <Content>
                <div className="over-view-content-detail">
                    <div>
  
                        <ScoreTitle title={/* 话术得分 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.scriptScore} {...speechCraftScore} />
                        <Text>
                            {[
                                /* 共 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.common, {children: get(speechCraftScore?.totalMainPoints), className:'font-black'}, /* 个要点， */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.keyPoints,
                                {children: get(speechCraftScore?.notCommitMainPoints), className:'font-red'}, /* 个未达到， */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.notReached,
                                /* 有 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.have, {children: get(speechCraftScore?.violations), className: 'font-red'},/* 处违规 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.violationOfRegulations,
                            ]}
                        </Text>
                    </div>
                    <div>
                        <ScoreTitle title={/* 情绪得分 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.emotionalScore} {...emotionScore} />
                        <Text>
                            {[
                                /* 出现 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.appear, {children: get(emotionScore?.negativeEmotions), className:'font-red'}, /* 次负面情绪 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.secondaryNegativeEmotions,
                            ]}
                        </Text>
                    </div>
                    <div>
                        <ScoreTitle title={/* 表达得分 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.expressionScore} {...speedScore} />
                        <Text>
                            {[
                                /* 语速过快 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.speakTooFast, {children: get(speedScore?.speedFastTimes), className:'font-red'}, /* 次， */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.times,
                                /* 语速过慢 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.speakTooSlowly, {children: get(speedScore?.speedSlowTimes), className:'font-red'}, /* 次 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.second,
                                /* 平均 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.average, {children: get(speedScore?.averageSpeed), className: 'font-black'},/* 字/分钟 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.wordminute,
                                ...inspect((speedScore?.modalParticleTooMuchTimes || 0) > 0, () => (
                                    [/* ， 语气词过多 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.tooManyModalParticles, {children: get(speedScore?.modalParticleTooMuchTimes), className:'font-red'}, /* 次 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.overView.index.second]
                                ), () => [
                                    ''
                                ])
                            ]}
                        </Text>
                    </div>
                </div>
            </Content>
        </div>
    );
});

function get(value) {
    return value ?? '-';
}
export const ScoreTitle = (props) => {
    return (
        <Space style={{ display: 'flex' }} className={'over-view-content-detail-title'}>
            <div>{props.title}</div>
            <Score {...props} />
        </Space>
    );
};
const Score = (props) => {
    const { score, totalScore } = props;
    return (
        <span className='score'>
            <span>{get(score)}</span>/
            <span>{get(totalScore)}</span>
        </span>
    );
};
