import type { RequestOptionsInit, RequestInterceptor, ResponseInterceptor, OnionMiddleware } from 'umi-request';

import UdeskLocales from './common/locales';
// import { message } from 'udesk-ui';
import Udesk from 'udesk-react/src/udesk';

export function getLocales(errMsgKey) {
    return UdeskLocales['current'].api ? UdeskLocales['current'].api[errMsgKey] : null;
}

export interface DefaultRequestConfig extends RequestOptionsInit {
    middlewares?: OnionMiddleware[];
    requestInterceptors?: RequestInterceptor[];
    responseInterceptors?: ResponseInterceptor[];
}

/**
 * @description 控制是否在请求失败时默认弹出提示的开关, 可以通过api函数入参options的showError字段进行覆盖
 */
export const showErrorDefault = true;

/**
 * @description 控制是否强制每个api函数都必须对应一个自定义的兜底报错信息（会被options中的errorMsg覆盖），设置为true则会使用JS进行强制校验，优先级低于showError字段
 * @description 注意：开启此开关需确保对应的api.config中提供了localesDirPath配置字段
 */
export const requireCustomizedErrMsg = false;

//初始化Request实例的默认配置
export const defaultRequestConfig: DefaultRequestConfig = {
    // requestInterceptors: [
    //     (url, options) => {
    //         return {
    //             url: `${url}&interceptors=yes`,
    //             options: { ...options, interceptors: true },
    //         };
    //     },
    // ],
};

//错误提示的统一处理
export function onError(reason: any, errorMsg: string, showError: boolean = showErrorDefault) {
    const error = errorHandler(reason, errorMsg);
    // @ts-ignore
    showError && error && emitErrorToast(error.message);
    throw error; // If throw. The error will continue to be thrown.
}
//成功提示的统一处理
export function onSuccess(resp: any, successMsg?: string) {
    // @ts-ignore
    successMsg && emitSuccessToast(successMsg);
}

function errorHandler(error: any, errorMsg?: string) {
    console.log('error', error.toString());
    if(error?.toString?.()?.includes?.('The user aborted a request')){
        return false;
    }
    if (!isObject(error.data)) {
        error.data = {};
    }
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        switch (error.response.status) {
            case 401:
                if (window.location.pathname !== '/auth/login') {
                    let referUrl = encodeURIComponent(window.location.pathname + window.location.search);
                    window.location.href = `${window.location.origin}/auth/login?refer=${referUrl}`;
                }
                break;
            case 404:
                normalizeResponse(error.data);
                break;
            default:
                normalizeResponse(error.data);
                break;
        }
    } else {
        // The request was made but no response was received or error occurs when setting up the request.
        console.log(error.message);
    }
    if (isObject(error.data) && !error.data.message) {
        error.data.message = errorMsg;
    }
    if (process.env.NODE_ENV === 'development') {
        console.log(error.data);
    }
    return error.data;
}

function normalizeResponse(errorData: any) {
    if (errorData && isObject(errorData)) {
        // eslint-disable-next-line
        errorData._serverMsg = errorData.message;
        if (!errorData.visible) {
            errorData.message = '';
        }
    }
}

export function emitErrorToast(msg: string) {
    // errorUdeskUI(msg);
    // function errorUdeskUI(msg){
    //     message.error(msg);
    // }

    errorUdeskToolkits(msg);
    function errorUdeskToolkits(msg) {
        Udesk.ui.notify.error(msg);
    }
}

export function emitSuccessToast(msg: string) {
    // successUdeskUI(msg);
    // function successUdeskUI(msg){
    //     message.success(msg);
    // }

    successUdeskToolkits(msg);
    function successUdeskToolkits(msg) {
        Udesk.ui.notify.success(msg);
    }
}

function isObject(target: any) {
    return Object.prototype.toString.call(target) === '[object Object]';
}
