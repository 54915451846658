/*
 * @Author: 唐毓斌
 * @Description: 任务详情
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Page from 'Component/common/udesk-qa-page-layout';
import Udesk from 'Udesk';
import moment from 'moment';
import UdeskLocales from 'UdeskLocales';
import hookProps from 'udesk-react/src/udesk/react/hook-props';
import ResultAnalysis from './ResultAnalysis';
import ScoreDetailList from './ScoreDetailList';
import OrganizationMemberSelect from '../components/OrganizationMemberSelect';
import SimplePieChart from '../components/SimplePieChart';
import { Button, Drawer, Space, Spin, Tabs } from 'udesk-ui';
import { getIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';
import { CompleteStandartView } from '../components/CompleteStandart';
import { StandardizeTable } from 'udesk_gm_ui';
import { postIntelligentPartnerTaskInfosStatisticsById } from 'src/api/intelligentPartnerTaskInfos/statistics/{id}';
import { CommonFilterProps } from 'udesk_gm_ui/es/common-filter';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { postIntelligentPartnerTaskInfosStatisticsFindAll } from 'src/api/intelligentPartnerTaskInfos/statistics/findAll';
import { getIntelligentPartnerTaskInfosByLessonIdTaskScore } from 'src/api/intelligentPartnerTaskInfos/{lessonId}/task/score';
import { postIntelligentPartnerTaskInfosStatisticsByIdExport } from 'src/api/intelligentPartnerTaskInfos/statistics/{id}/export';
import { linkTo } from 'src/util/link';
import { getCurrentRouteName, getSystemModule } from 'Udesk/system/subApp';
import { useCoachUserTreeDataWithPermit } from 'src/util/hook/user';
import './index.less';

const DEFAULT_PAGE = {
    current: 1,
    pageSize: 20,
    total: 0,
};

function formatTableNulItem(originText: any, showText?: any) {
    if (originText == null) {
        return '-';
    }
    return showText || originText;
}

const TaskCenterDetail: React.FC<any> = (props) => {
    const locales = UdeskLocales['current'];
    const { history, match } = props;
    const [taskInfo, setTaskInfo] = useState<any>();
    const [taskInfoExtra, setTaskInfoExtra] = useState<any>({
        totalCount: 0, // 应该执行的总人数
        executeCount: 0, // 执行人数
        executeRate: 0, // 执行率
        completeCount: 0, // 完成人数
        completeRate: 0, // 完成率
        commitCount: 0, // 达标人数
        commitRate: 0, // 达标率
        excellentCount: 0, // 优秀人数
        excellentRate: 0, // 优秀率
    });
    const [pagination, setPagination] = useState<any>(DEFAULT_PAGE);
    const [dataSource, setDataSource] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [selectedSubItem, setSelectedSubItem] = useState<any>(null);
    const [lessonScore, setLessonScore] = useState<any>({
        total: '-',
        speechCraftScore: '-', // 话术得分
        emotionScore: '-', // 情绪得分
        speedScore: '-', // 语速得分
    });
    // const [tab, setTab] = useState('0');
    const [tempFilter, setTempFilter] = useState<any>({});
    const [currentTab, setCurrentTab] = useState('taskData');

    const {treeData} = useCoachUserTreeDataWithPermit();

    const onBack = () => {
        const routeName = 'coachTaskCenterList';
        Udesk.ui.routing.transitionTo({
            history,
            routeName,
        });
    };

    const isPPT = useCallback((wholeCallback, singleCallback, errorCallback?) => {
        // taskInfo?.evaluationMode 等于null:非ppt， 1：整体， 2：单页
        if (taskInfo?.evaluationMode === 1) {
            return wholeCallback.call(taskInfo);
        }
        if (taskInfo?.evaluationMode === 2) {
            return singleCallback.call(taskInfo);
        }
        return (errorCallback || singleCallback).call(taskInfo);
    }, [taskInfo]);

    const OutComeOptions = useMemo(() => {
        return [
            {
                label: /* 未达标 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.notMeetingStandards,
                value: 0,
            },
            {
                label: /* 达标 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.meetingStandards,
                value: 1,
            },
            {
                label: /* 优秀 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.excellent,
                value: 2,
            },
        ];
    }, []);

    const CompleteStateOptions = useMemo(() => {
        return [
            {
                label: /* 未开始 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.notStarted,
                value: 0,
            },
            {
                label: /* 进行中 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.haveInHand,
                value: 1,
            },
            {
                label: taskInfo?.taskType === 1 ? /* 已完成 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.completed : /* 已通过 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.passed,
                value: 2,
            },
            {
                label: taskInfo?.taskType === 1 ? /* 未完成 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.hangInTheAir : /* 未通过 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.failed,
                value: 3,
            },
        ];
    }, [taskInfo]);

    const getTaskScore = (taskInfo) => {
        if (taskInfo?.lessonId) {
            getIntelligentPartnerTaskInfosByLessonIdTaskScore({
                segments: { lessonId: taskInfo.lessonId },
                params: {taskId: taskInfo.id}
            }).then((resp) => {
                if (resp?.data) {
                    setLessonScore({
                        ...resp?.data,
                        total:
                            (resp?.data.speechCraftScore || 0) +
                            (resp?.data.emotionScore || 0) +
                            (resp?.data.speedScore || 0),
                    });
                }
            });
        }
    };

    const init = () => {
        const id = match?.params?.id || '0';
        if (id !== '0') {
            setLoading(true);
            getIntelligentPartnerTaskInfosById({
                segments: { id },
            })
                .then((resp) => {
                    let values: any = {
                        ...resp?.data,
                    };
                    //画像
                    values.imageIdInfo = {
                        status: 2,
                    };
                    if (values.imageId != null) {
                        values.imageIdInfo.status = 1;
                        values.imageIdInfo.selectedItemId = values.imageId;
                    }
                    // 时长限制
                    values.limitDurationInfo = {
                        status: 1,
                    };
                    if (values.limitDuration != null) {
                        values.limitDurationInfo.status = 2;
                        values.limitDurationInfo.inputValue = values.limitDuration;
                    }
                    // 时间
                    values.taskTimeInfo = {
                        status: 1,
                        viewText: /* 不限时间 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.unlimitedTime,
                    };
                    if (values.taskStartTime && values.taskStartTime) {
                        values.taskTimeInfo.status = 2;
                        values.taskTimeInfo.viewText =
                            moment(values.taskStartTime).format('YYYY-MM-DD HH:mm') +
                            /* 至 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.to +
                            moment(values.taskEndTime).format('YYYY-MM-DD HH:mm');
                    }
                    // 评分标准
                    values.gradingStandartInfo = {
                        commitScore: values.commitScore,
                        excellentScore: values.excellentScore,
                    };
                    // 完成要求
                    values.completeStandartInfo = {};
                    if (values.completeByTimes != null) {
                        values.completeStandartInfo.timesStatus = true;
                        values.completeStandartInfo.completeByTimes = values.completeByTimes;
                    }
                    if (values.completeByScore != null) {
                        values.completeStandartInfo.scoreStatus = true;
                        values.completeStandartInfo.completeByScore = values.completeByScore;
                    }
                    setTaskInfo(values);
                    getTaskScore(values);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });

            const reqData = {
                taskId: id,
            };
            postIntelligentPartnerTaskInfosStatisticsFindAll(reqData).then((resp) => {
                const currentItem = (resp?.data || []).find((item) => item.id + '' === id);
                if (currentItem) {
                    setTaskInfoExtra({
                        totalCount: currentItem.totalCount || 0, // 应该执行的总人数
                        executeCount: currentItem.executeCount || 0, // 执行人数
                        executeRate: currentItem.executeRate || 0, // 执行率
                        completeCount: currentItem.completeCount || 0, // 完成人数
                        completeRate: currentItem.completeRate || 0, // 完成率
                        commitCount: currentItem.commitCount || 0, // 达标人数
                        commitRate: currentItem.commitRate || 0, // 达标率
                        excellentCount: currentItem.excellentCount || 0, // 优秀人数
                        excellentRate: currentItem.excellentRate || 0, // 优秀率
                    });
                }
            });
        }
    };

    const onTableRequest = (params) => {
        setLoading(true);
        const reqData: any = {
            pageNum: params.pagination.current, // 页数
            pageSize: params.pagination.pageSize, // 每页条数
            userIds: params.filter?.taskTargetObj?.userIds || [], // 用户ID数组
            organizationIds: params.filter?.taskTargetObj?.organizationIds || [], //
            outComes: params.filter?.outComes, // 最高得分结果数组 0-未达标 1-达标 2-优秀
            completeStates: params.filter?.complteStates, // 完成状态 0-未开始 1-进行中 2-已完成/已通过 3-未完成/未通过
        };
        if (params.sorter?.order) {
            reqData.sortField = params.sorter.columnKey; // exercise_times 练习次数 , highest_score 最高得分, average_score 平均得分 ,average_take_time 平均耗时 , recent_exercise_time 最近练习时间
            reqData.sortOrder = params.sorter.order === 'ascend' ? 1 : 2; // 1-升序 2-降序
        }
        setTempFilter(reqData);
        postIntelligentPartnerTaskInfosStatisticsById(reqData, {
            segments: { id: match?.params?.id },
        })
            .then((resp) => {
                setDataSource(resp?.data || []);
                setPagination({
                    total: resp.paging?.total,
                    current: resp.paging?.pageNum || 1,
                    pageSize: resp.paging?.pageSize,
                });
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const onExport = () => {
        const reqData = {
            systemModule: getSystemModule(),
            ...tempFilter,
        };
        delete reqData?.pageNum;
        delete reqData?.pageSize;
        postIntelligentPartnerTaskInfosStatisticsByIdExport(reqData, {
            segments: { id: match?.params?.id },
        })
            .then(() => {
                Udesk.ui.notify.success(locales.fix.successfulExportAndToTaskCenter);
            })
            .catch((reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            });
    };

    const filter: CommonFilterProps = useMemo(() => {
        return {
            customComponents: {
                OrganizationMemberSelect,
            },
            fields: [
                {
                    name: 'taskTargetObj',
                    label: /* 员工 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.staff,
                    type: 'OrganizationMemberSelect',
                    props: {
                        placeholder: locales.labels.pleaseSelect,
                        width: 200,
                        treeData,
                    },
                },
                {
                    name: 'outComes',
                    label: /* 最高得分标准 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.maximumScoringCriteria,
                    type: 'Select',
                    props: {
                        placeholder: locales.labels.pleaseSelect,
                        options: OutComeOptions,
                        style: { width: 200 },
                        mode: 'multiple',
                        maxTagCount: 2,
                        allowClear: true,
                        showArrow: true,
                    },
                },
                {
                    name: 'complteStates',
                    label: /* 完成状态 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.completionStatus,
                    type: 'Select',
                    props: {
                        placeholder: locales.labels.pleaseSelect,
                        options: CompleteStateOptions,
                        style: { width: 200 },
                        mode: 'multiple',
                        maxTagCount: 2,
                        allowClear: true,
                        showArrow: true,
                    },
                },
            ] as CommonFilterProps['fields'],
        };
    }, [CompleteStateOptions, OutComeOptions, locales.labels.pleaseSelect, treeData]);

    const onShowItemDetail = (item) => {
        setSelectedSubItem(item);
    };

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                key: 'userName',
                dataIndex: 'userName',
                title: /* 员工姓名 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.employeeName,
                width: 160,
                fixed: 'left',
            },
            {
                key: 'exercise_times',
                dataIndex: 'exerciseTimes',
                title: `${taskInfo?.taskType === 1 ? /* 练习 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.practice : /* 考试 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.examination}${/* 次数 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.frequency}`,
                sorter: true,
            },
            {
                key: 'average_score',
                dataIndex: 'averageScore',
                title: /* 平均分 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.average,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'highest_score',
                dataIndex: 'highestScore',
                title: /* 最高分 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.maximumScore,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'outCome',
                dataIndex: 'outCome',
                title: /* 最高得分结果 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.highestScoreResult,
                render: (text) => OutComeOptions.find((item) => item.value === text)?.label,
            },
            {
                key: 'completeState',
                dataIndex: 'completeState',
                title: /* 状态 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.state,
                render: (text) => CompleteStateOptions.find((item) => item.value === text)?.label,
            },
            {
                key: 'average_take_time',
                dataIndex: 'averageTakeTime',
                title: /* 平均时长(min) */UdeskLocales['current'].pages.coach.taskCenter.detail.index.averageDurationmin,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'recent_exercise_time',
                dataIndex: 'recentExerciseTime',
                title: `${/* 最近 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.lately}${taskInfo?.taskType === 1 ? /* 练习 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.practice : /* 考试 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.examination}${/* 时间 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.time}`,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'actions',
                renderType: 'actions',
                title: /* 操作 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.operation,
                buttons: [
                    {
                        label: /* 查看详情 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.viewDetails,
                        onClick: (item) => onShowItemDetail(item),
                    },
                    {
                        label: /* 错题集 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.wrongQuestionSet,
                        onClick: (item) => {
                            linkTo(props.history, getCurrentRouteName('wrongQuestionSetList'), undefined, undefined, {
                                taskId: item.taskId,
                                userId: item.userId,
                            });
                        },
                    },
                ],
            },
        ];
        return {
            columns,
            dataSource,
            pagination,
            loading,
            scroll: { x: 'max-content', y: false },
        } as any;
    }, [CompleteStateOptions, OutComeOptions, dataSource, loading, pagination, props.history, taskInfo?.taskType]);

    // const onTabClick = (activeKey) => {
    //     setTab(activeKey);
    // };

    useEffect(() => {
        init();
    }, []);

    return (
        <Page
            pageBodyClassName="task-center-detail-container"
            backGroundGhost={true}
            title={`${taskInfo?.taskName || ''}-${taskInfo?.taskType === 1 ? /* 练习 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.practice : /* 考试 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.examination}`}
            // loading={loading}
            onBack={onBack}
        >
            <div className="tcd-header-wrapper">
                <Spin spinning={loading}>
                    <Space
                        style={{
                            whiteSpace: 'nowrap',
                            background: '#fff',
                            padding: '16px',
                            borderRadius: '3px',
                        }}
                        direction="vertical"
                    >
                        <Space
                            className="tcd-header-base-info-item"
                            direction="horizontal"
                            align="center"
                        >
                            <div className="tcd-header-base-info-item-title">{/* 任务名称 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.taskName}</div>
                            <div>{taskInfo?.taskName}</div>
                        </Space>
                        <Space
                            className="tcd-header-base-info-item"
                            direction="horizontal"
                            align="center"
                        >
                            <span className="tcd-header-base-info-item-title">{/* 完成要求 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.completeRequirements}</span>
                            <CompleteStandartView
                                taskType={taskInfo?.taskType}
                                completeStandartInfo={taskInfo?.completeStandartInfo}
                            />
                        </Space>
                        <Space
                            className="tcd-header-base-info-item"
                            direction="horizontal"
                            align="center"
                        >
                            <span className="tcd-header-base-info-item-title">{/* 发布对象 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.publishTo}</span>
                            <OrganizationMemberSelect
                                isView={true}
                                treeData={treeData}
                                value={taskInfo?.taskTargetObj}
                            />
                        </Space>
                        <Space
                            className="tcd-header-base-info-item"
                            direction="horizontal"
                            align="center"
                        >
                            <span className="tcd-header-base-info-item-title">{/* 任务时间 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.taskTime}</span>
                            <span>{taskInfo?.taskTimeInfo.viewText}</span>
                        </Space>
                        <Space
                            className="tcd-header-base-info-item"
                            direction="horizontal"
                            align="center"
                        >
                            <span className="tcd-header-base-info-item-title">{/* 分数构成 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.fractionalComposition}</span>
                            <span>
                                <span>{`${/* 总分 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.totalScore}${lessonScore.total}${/* 分 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.branch}`}</span>
                                <span style={{ color: '#585858' }}>
                                    {`${/* （话术 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.Script}${lessonScore.speechCraftScore}${/* 分+情绪 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.scoreEmotion}${lessonScore.emotionScore}${/* 分+表达 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.fractionExpression}${lessonScore.speedScore}${/* 分） */UdeskLocales['current'].pages.coach.taskCenter.detail.index.points}`}
                                </span>
                            </span>
                        </Space>
                    </Space>
                </Spin>
                <div className="tcd-header-echarts">
                    <SimplePieChart
                        id="executeRateChart"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={/* 执行率 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.implementationRate}
                        title={taskInfoExtra.executeRate + '%'}
                        data={[
                            { name: /* 执行人数 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.numberOfExecutors, value: taskInfoExtra.executeCount },
                            {
                                name: /* 未执行人数 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.numberOfPeopleNotExecuted,
                                value: taskInfoExtra.totalCount - taskInfoExtra.executeCount,
                            },
                        ]}
                    />
                    <SimplePieChart
                        id="completeRateChart"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={/* 完成率 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.completionRate}
                        title={taskInfoExtra.completeRate + '%'}
                        data={[
                            { name: /* 完成人数 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.numberOfCompletedPersonnel, value: taskInfoExtra.completeCount },
                            {
                                name: /* 未完成人数 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.numberOfUnfinishedPersonnel,
                                value: taskInfoExtra.totalCount - taskInfoExtra.completeCount,
                            },
                        ]}
                    />
                    <SimplePieChart
                        id="commitRateChart"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={/* 达标率 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.complianceRate}
                        title={taskInfoExtra.commitRate + '%'}
                        data={[
                            { name: /* 达标人数 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.numberOfQualifiedPersonnel, value: taskInfoExtra.commitCount },
                            {
                                name: /* 未达标人数 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.numberOfNonCompliantIndividuals,
                                value: taskInfoExtra.totalCount - taskInfoExtra.commitCount,
                            },
                        ]}
                    />
                    <SimplePieChart
                        id="excellentRateChart"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={/* 达优率 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.excellentRate}
                        title={taskInfoExtra.excellentRate + '%'}
                        data={[
                            { name: /* 优秀人数 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.numberOfOutstandingIndividuals, value: taskInfoExtra.excellentCount },
                            {
                                name: /* 非优秀人数 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.numberOfNonOutstandingIndividuals,
                                value: taskInfoExtra.totalCount - taskInfoExtra.excellentCount,
                            },
                        ]}
                    />
                </div>
            </div>
            <Tabs
                style={{ padding: '4px 16px', backgroundColor: '#fff' }}
                tabBarExtraContent={{
                    right:
                        currentTab === 'taskData' ? (
                            <Button style={{ marginRight: 16 }} type="primary" onClick={onExport}>
                                {/* 导出 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.export}
                            </Button>
                        ) : (
                            <span />
                        ),
                }}
                onChange={(activeKey) => setCurrentTab(activeKey)}
            >
                <Tabs.TabPane tab={/* 任务数据 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.taskData} key="taskData">
                    <StandardizeTable.RequestTable
                        onRequest={onTableRequest}
                        filter={filter}
                        table={tableProps}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={/* 结果分析 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.resultAnalysis} key="1">
                    <ResultAnalysis
                        taskId={match?.params?.id}
                        lessonId={taskInfo?.lessonId}
                        isPPT={isPPT}
                        history={props.history}
                    />
                </Tabs.TabPane>
            </Tabs>
            <Drawer
                title={`${/* 得分详情 - */UdeskLocales['current'].pages.coach.taskCenter.detail.index.scoreDetails}${selectedSubItem?.userName}`}
                width={500}
                visible={!!selectedSubItem}
                onClose={() => setSelectedSubItem(null)}
            >
                <ScoreDetailList
                    taskType={taskInfo?.taskType}
                    subTaskItem={selectedSubItem}
                    history={history}
                />
            </Drawer>
        </Page>
    );
};

export default hookProps(TaskCenterDetail);
