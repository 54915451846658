import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Divider, Empty, Form, Icon, Input, Space, Tag } from 'udesk-ui';
import { ClientAction, EmployeeAction, Card, CountTag, ListItem } from './components';
// import './style.scss';
import styled from 'styled-components';
import { inspect } from 'src/util/core';
import { putReviewCallSummaryByCallId } from 'src/api/review/call/summary/{callId}';

const DrawerWrapper = styled.div`
    /* position: relative; */
    /* overflow: hidden; */
    margin: 0px -16px 16px;
    padding: 0 16px;
    flex: 1;
    overflow: auto;
`;
const DrawerTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 16px;
    display: flex;
    align-items: center;
`;
const DrawerTotal = styled.div`
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
    span {
        color: #1a6eff;
    }
`;
const ListWrapper = styled.div`
    overflow-y: auto;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 24px;
    margin: -16px -16px 16px -16px;
`;

const Template = React.memo((props: any) => {
    const locales = UdeskLocales['current'];
    const {
        insightInfo,
        keyEvents,
        onClick,
        tags,
        customer,
        user,
        // knowledgePoint,
        /* onCaseFragment, */ keyEventTag,
        onKeyEventTagClick,
        sessionSummary, enableSessionSummary, callId,
    } = props;

    const [detailVisible, setDetailVisible] = useState<false | 'keyEvents' | 'agent' | 'customer'>(false);
    const [indices, setIndices] = useState<any[]>([]);

    const agentInfo = useMemo(() => {
        if (!insightInfo) return {};
        if (props.isWechatIm) {
            return {
                imCount: insightInfo.agentImCount,
                imPercentage: insightInfo.agentImPercentage,
            };
        }
        return {
            longestSpeechDuration: insightInfo.agentLongestSpeechDuration,
            questionFrequency: insightInfo.agentQuestionFrequency,
            speechPercentage: insightInfo.agentSpeechPercentage,
            interceptCount: insightInfo.agentInterceptCount
            // chanelType:1 //改字段为区分企业类型和回话类型的字段和后端约定的
        };
    }, [insightInfo]);
    const customerInfo = useMemo(() => {
        if (!insightInfo) return {};
        if (props.isWechatIm) {
            return {
                imCount: insightInfo.customerImCount,
                imPercentage: insightInfo.customerImPercentage,
            };
        }
        return {
            longestSpeechDuration: insightInfo.customerLongestSpeechDuration,
            questionFrequency: insightInfo.customerQuestionCount,
            speechPercentage: insightInfo.customerSpeechPercentage,
            // chanelType:1  //改字段为区分企业类型和回话类型的字段和后端约定的
        };
    }, [insightInfo]);
    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Title>{/* 会话洞察 */}{locales.components.sessionInsights.index.conversationalInsights}</Title>
            <DrawerWrapper>
                {detailVisible ? (
                    <>
                        <DrawerTitle>
                            <Button
                                type='text'
                                size='small'
                                onClick={() => setDetailVisible(false)}
                                icon={<Icon type='LeftOutlined' antdIcon={true} />}
                            ></Button>
                            {detailVisible === 'keyEvents' ? /* 关键事件 */locales.components.sessionInsights.index.keyEvents : /* 提问分析 */locales.components.sessionInsights.index.questionAnalysis}
                        </DrawerTitle>
                        <DrawerTotal>
                            {detailVisible === 'keyEvents' ? (
                                keyEvents?.length ? (
                                    <>{/* 发现 */}{locales.components.sessionInsights.index.find}<span>{keyEvents?.length}</span>{/* 个关键事件 */}{locales.components.sessionInsights.index.numKeyEvents}</>
                                ) : (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                )
                            ) : indices.length ? (
                                <>{/* 发现 */}{locales.components.sessionInsights.index.find}<span>{indices?.length}</span>{/* 次提问 */}{locales.components.sessionInsights.index.questions}</>
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </DrawerTotal>
                        <ListWrapper>
                            {detailVisible === 'keyEvents'
                                ? keyEvents?.map((keyEvent) => {
                                    return <ListItem {...keyEvent} backgroundColor='#F9F9F9' onClick={onClick} />;
                                })
                                : indices.map((indice) => {
                                    return <ListItem {...indice} onClick={onClick} bordered={true} />;
                                })}
                        </ListWrapper>
                    </>
                ) : (
                    <>
                        <Card title={/* 会话动作 */locales.components.sessionInsights.index.conversationAction}>
                            <ClientAction
                                info={customerInfo}
                                userName={customer.nickname ?? /* 客户 */locales.components.sessionInsights.index.customer}
                                avatarUrl={customer.headPortraitUrl}
                                isWechatIm={props.isWechatIm}
                            />
                            <Divider style={{ margin: '16px 0' }} />
                            <EmployeeAction
                                info={agentInfo}
                                userName={user.realname ?? /* 销售 */locales.components.sessionInsights.index.sale}
                                avatarUrl={user.headPortraitUrl}
                                isWechatIm={props.isWechatIm}
                            />
                            <Divider style={{ margin: '16px 0' }} />
                        </Card>
                        {
                            inspect(enableSessionSummary, () => (
                                <Card title={/* 会话小结 */UdeskLocales['current'].components.sessionInsights.index.conversationSummary}>
                                    <SessionSummary value={sessionSummary} callId={callId}/>
                                    <Divider style={{ margin: '16px 0' }} />
                                </Card>
                            ))
                        }
                        <Card title={/* 关键事件 */locales.components.sessionInsights.index.keyEvents}>
                            <CountTag
                                title={`${/* 关键事件 */locales.components.sessionInsights.index.keyEvents}`}
                                count={keyEvents?.length ?? 0}
                                backgroundColor='rgba(197, 228, 255, .5)'
                                onClick={() => setDetailVisible('keyEvents')}
                            />

                            {Boolean(keyEventTag.length) &&
                                keyEventTag.map((tag, idx) => {
                                    return (
                                        // <span
                                        //     style={{
                                        //         background: item.categoryColor + '1a',
                                        //         color: item.categoryColor,
                                        //         border: `1px solid ${item.categoryColor}`,
                                        //         whiteSpace: 'nowrap',
                                        //     }}
                                        //     key={item.id}
                                        //     onClick={onKeyEventTagClick.bind(null, item.sentencesIndices?.[0])}
                                        // >
                                        //     {item.categoryName} | {item.tagName}
                                        // </span>
                                        <Tag
                                            onClick={onKeyEventTagClick.bind(null, tag.sentencesIndices?.[0])}
                                            key={tag.id}
                                            style={{
                                                marginBottom: 4,
                                                background: tag.categoryColor + '1a',
                                                color: tag.categoryColor,
                                                maxWidth: '100%',
                                                overflowX: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            color={tag.categoryColor}
                                            title={`${tag.categoryName} | ${tag.tagName}`}
                                        >
                                            <span style={{ width: '100%', height: '100', cursor: 'pointer' }}>
                                                {tag.categoryName} | {tag.tagName}
                                            </span>
                                        </Tag>
                                    );
                                })}
                        </Card>
                        <Divider style={{ margin: '16px 0' }} />
                        <Card title={/* 会话标签 */locales.components.sessionInsights.index.sessionLabel}>
                            {tags.length ? (
                                tags.map((tag) => (
                                    <Tag
                                        key={tag.id}
                                        style={{
                                            marginBottom: 4,
                                            background: tag.categoryColor + '1a',
                                            color: tag.categoryColor,
                                            maxWidth: '100%',
                                            overflowX: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        color={tag.categoryColor}
                                        title={tag.tagName}
                                    >
                                        {tag.tagName}
                                    </Tag>
                                ))
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </Card>
                        <Divider style={{ margin: '16px 0' }} />
                        <Card title={/* 提问分析 */locales.components.sessionInsights.index.questionAnalysis}>
                            <CountTag
                                title={/* 员工提问 */locales.components.sessionInsights.index.employeeQuestions}
                                count={insightInfo.agentQuestionIndices?.length ?? 0}
                                backgroundColor='rgba(255, 211, 225, 0.5)'
                                onClick={() => {
                                    setDetailVisible('agent');
                                    setIndices(insightInfo.agentQuestionIndices ?? []);
                                }}
                            />
                            <CountTag
                                title={/* 客户提问 */locales.components.sessionInsights.index.customerQuestions}
                                count={insightInfo.customerQuestionIndices?.length ?? 0}
                                backgroundColor='rgba(246, 214, 255, 0.5)'
                                onClick={() => {
                                    setDetailVisible('customer');
                                    setIndices(insightInfo.customerQuestionIndices ?? []);
                                }}
                            />
                        </Card>
                        {/* <Divider style={{ margin: '16px 0' }} />
                        <Card title='关联知识点'>
                            {knowledgePoint.length ? (
                                knowledgePoint.map((tag) => (
                                    <Tag
                                        key={tag.id}
                                        style={{
                                            marginBottom: 4,
                                            background: tag.categoryColor + '1a',
                                            color: tag.categoryColor,
                                            maxWidth: '100%',
                                            overflowX: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        color={tag.categoryColor}
                                        title={tag.tagName}
                                        onClick={() => {
                                            window.open(`/site/sale-knowledge-base/view/${tag.id}`);
                                        }}
                                    >
                                        {tag.tagName}
                                    </Tag>
                                ))
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </Card>
                        <Divider style={{ margin: '16px 0' }} />
                        <Card title='案列片段'>
                            {tags.length ? (
                                tags.map((tag) => (
                                    <Tag
                                        onClick={(e) => onCaseFragment(e, tag)}
                                        key={tag.id}
                                        style={{ marginBottom: 4 }}
                                        color={tag.categoryColor}
                                    >
                                        <span style={{ width: '100%', height: '100', cursor: 'pointer' }}>
                                            {tag.tagName}
                                        </span>
                                    </Tag>
                                ))
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </Card> */}
                    </>
                )}
            </DrawerWrapper>
        </div>
    );
});

class SessionInsights extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(SessionInsights);


const SessionSummary = React.memo<any>(props => {
    const {value, onChange, callId } = props;
    const [sessionSummary, setSessionSummary] = useState<string>();
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();

    const onFinish = useCallback((values) => {
        if (onChange) {
            onChange(values.sessionSummary);
        } else {
            putReviewCallSummaryByCallId(values, {
                segments: { callId },
            }
            ).then(
                resp => {
                    setSessionSummary(values.sessionSummary);
                    setIsEdit(false);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [callId, onChange]);

    useEffect(() => {
        setSessionSummary(value);
    }, [value]);

    useEffect(() => {
        form.setFieldsValue({ sessionSummary });
    }, [
        form, 
        sessionSummary
    ]);

    return inspect(isEdit, () => {
        return (
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Form.Item name="sessionSummary">
                    <Input.TextArea rows={6} />
                </Form.Item>

                <Space style={{float: 'right', marginBottom: 16}}>
                    <Button size='small' onClick={() => setIsEdit(false)}>{/* 取消 */}{UdeskLocales['current'].components.sessionInsights.index.cancel}</Button>
                    <Button size='small' type="primary" htmlType="submit">{/* 确定 */}{UdeskLocales['current'].components.sessionInsights.index.determine}</Button>
                </Space>
            </Form>
        );
    }, () => {
        return inspect(sessionSummary, () => {
            return (
                <Space direction={'vertical'} style={{float: 'right', marginBottom: 16, width: '100%'}}>
                    <div>{sessionSummary}</div>
                    <Button 
                        onClick={() => setIsEdit(true)} 
                        size='small' 
                        type="primary"
                        style={{float: 'right'}}>{/* 编辑 */}{UdeskLocales['current'].components.sessionInsights.index.edit}</Button>
                </Space>
            );
        }, () => {
            return (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                    <Button onClick={() => setIsEdit(true)} type="primary">{/* 创建 */}{UdeskLocales['current'].components.sessionInsights.index.establish}</Button>
                </Empty>
            );
        });
    });
});