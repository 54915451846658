import React, { useState, useMemo, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { StandardizeTable, TreeMenu } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { Button, FormBuilder, Input, Modal } from 'udesk-ui';
import ImportModal from 'Component/common/import-modal';

// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
import './style.scss';
import { getSubjectMarkFiles, postSubjectMarkFiles } from 'src/api/subjectMarkFiles';
import { putSubjectMarkFilesMoveById } from 'src/api/subjectMarkFiles/move/{id}';
import { deleteSubjectMarkFilesById, putSubjectMarkFilesById } from 'src/api/subjectMarkFiles/{id}';
// import { getSubjectMarkFilesSearch } from 'src/api/subjectMarkFiles/search';
import { getSubjectMarkCalls } from 'src/api/subjectMarkCalls';
import { deleteSubjectMarkCallsByFileIdByCallId } from 'src/api/subjectMarkCalls/{fileId}/{callId}';
import { postSubjectMarkFilesImport } from 'src/api/subjectMarkFiles/import';
import { getSystemModule } from 'Udesk/system/subApp';

const DEFAULT_REQUEST_WITH_FILE_ID = false;
const DEFAULT_PAGINATION = {
    current: 1,
    pageSize: 20,
    total: 0,
};

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const [tableData, setTableData] = useState<any[]>([]);
    const [pagination, setPagination] = useState<StandardizeTableProps['table']['pagination']>(DEFAULT_PAGINATION);
    const [treeData, setTreeData] = useState<any[]>([]);
    const [selectedKey, setSelectedKey] = useState<any>(null);
    const [currentEditClassificationId, setCurrentEditClassificationId] = useState<number | null>(null);
    const [classificationDetailFormInstance] = FormBuilder.useForm();
    const [classificationDetailValues, setClassificationDetailValues] = useState<any>({});
    const [classificationDetailVisible, setClassificationDetailVisible] = useState<boolean>(false);
    const [isMove, setIsMove] = useState<boolean>(false);
    const moveId = useRef<null | number>(null);
    const [moveToId, setMoveToId] = useState<null | number>(null);
    const [moveTargetTreeData, setMoveTargetTreeData] = useState<any[]>([]);
    const [highlightKeyword, setHighlightKeyword] = useState<string>('');
    const fileIdMap = useRef<any>({});
    const requestWithFileId = useRef<boolean>(DEFAULT_REQUEST_WITH_FILE_ID);
    // const [currentListClassificationId, setCurrentListClassificationId] = useState<number | null>(null);

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 通话id */locales.pages.gong.topicList.index.callID,
                key: 'callId',
                dataIndex: 'callId',
            },
            {
                title: /* 最新标注时间 */locales.pages.gong.topicList.index.latestMarkedTime,
                key: 'markTime',
                dataIndex: 'markTime',
            },
            {
                title: /* 话题 */locales.pages.gong.topicList.index.topicOfConversation,
                key: 'fileId',
                dataIndex: 'fileId',
                render: (name) => {
                    return fileIdMap.current[name];
                },
            },
            {
                title: /* 句子数量 */locales.pages.gong.topicList.index.numberOfSentences,
                key: 'dialogNumber',
                dataIndex: 'dialogNumber',
            },
            {
                title: /* 句子 */locales.pages.gong.topicList.index.sentence,
                key: 'dialogFirstText',
                dataIndex: 'dialogFirstText',
            },
            {
                title: /* 操作 */locales.pages.gong.topicList.index.operation,
                key: 'actions',
                renderType: 'actions',
                buttons: [
                    {
                        label: /* 查看 */locales.pages.gong.topicList.index.see,
                        onClick: (item) => {
                            let routeOptions = {
                                history: props.history,
                                routeName: 'algorithmTopicCallout',
                                queryParams: { callId: item.callId, fileId: selectedKey },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                    },
                    {
                        label: /* 删除 */locales.pages.gong.topicList.index.delete,
                        danger: true,
                        onClick: (item) => {
                            return deleteSubjectMarkCallsByFileIdByCallId({
                                segments: {
                                    callId: item.callId,
                                    fileId: selectedKey,
                                },
                            });
                        },
                    },
                ].filter(Boolean),
            },
        ];
        return {
            columns,
            dataSource: tableData,
            pagination: {
                ...pagination,
                onChange: () => {
                    requestWithFileId.current = true;
                }
            },
            // rowSelectionEnhance: {},
        };
    }, [tableData, pagination, selectedKey]);
    const { handleOnCancel, handleOnOk, isUploadVisible, uploadFile, file, showImportModal } =
        ImportModal.useImportModal({
            formatParams(file, uploadData) {
                return {
                    fileName: file.name,
                    keyName: uploadData.key,
                    systemModule: getSystemModule(),
                };
            },
            request: postSubjectMarkFilesImport,
        });
    const requestClassification: (disableFunc?: (item: any) => boolean) => Promise<any[]> = (disableFunc) => {
        return getSubjectMarkFiles().then((resp) => {
            const { data: respData = [] } = resp;
            const data = TreeMenu.formatTreeDataFromArrayToTree(
                [...respData, { id: 0, parentIds: '' }].map((i) => ({
                    ...i,
                    title: i.name,
                    key: `${i.id}`,
                    disabled: disableFunc?.(i) ?? false,
                })),
                0,
                'parentId'
            );
            fileIdMap.current = respData.reduce((prev, cur) => {
                if (cur.id) {
                    prev[cur.id] = cur.name;
                }
                return prev;
            }, {});
            return data;
        });
    };
    const classificationProps: StandardizeTableProps['classification'] = useMemo(() => {
        return {
            treeData,
            onSelect: (key) => {
                requestWithFileId.current = true;
                setSelectedKey(key);
            },
            selectedKeys: [selectedKey],
            treeNodeActionMenu: [
                {
                    label: /* 编辑 */locales.pages.gong.topicList.index.edit,
                    onClick: (item) => {
                        setClassificationDetailValues({ name: item.title, id: item.id });
                        setClassificationDetailVisible(true);
                        setCurrentEditClassificationId(item.id);
                    },
                },
                {
                    label: /* 新建子话题 */locales.pages.gong.topicList.index.newSubTopic,
                    onClick: (item) => {
                        setClassificationDetailVisible(true);
                        setCurrentEditClassificationId(item.id);
                    },
                },
                {
                    label: /* 移动 */locales.pages.gong.topicList.index.move,
                    onClick: (item) => {
                        setIsMove(true);
                        moveId.current = item.id;
                        requestClassification((i) => {

                            return item.id === i.id || i.parentIds.split(',').includes(`${item.id}`);
                        }).then((data) => {
                            setMoveTargetTreeData([
                                {
                                    title: /* 全部 */locales.pages.gong.topicList.index.whole,
                                    key: '0',
                                    children: data,
                                },
                            ]);
                        });
                    },
                },
                {
                    label: /* 删除 */locales.pages.gong.topicList.index.delete,
                    danger: true,
                    onClick: (item) => {
                        return deleteSubjectMarkFilesById({
                            segments: {
                                id: item.itemKey,
                            },
                            successMsg: /* 删除话题成功 */locales.pages.gong.topicList.index.topicDeletedSuccessfully,
                        }).then(() => {
                            requestClassification().then((data) => {
                                setTreeData(data);
                                setSelectedKey(`${data?.[0]?.id}` ?? null);
                            });
                        });
                    },
                },
            ],
            highlightKeyword,
            title: (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 8,
                        }}
                    >
                        <div>{/* 话题 */}{locales.pages.gong.topicList.index.topicOfConversation}</div>
                        <Button
                            type='link'
                            size='small'
                            onClick={() => {
                                setClassificationDetailVisible(true);
                                setCurrentEditClassificationId(null);
                            }}
                        >{/* 新建 */}{locales.pages.gong.topicList.index.newlyBuild}</Button>
                    </div>
                    <Input.Search
                        onChange={(e) => {
                            if (!e.target.value) {
                                requestClassification().then((data) => {
                                    setHighlightKeyword('');
                                    setTreeData(data);
                                });
                            }
                        }}
                        onSearch={(value) => {
                            setHighlightKeyword(value);
                            // getSubjectMarkFilesSearch({
                            //     params: {
                            //         name: value,
                            //     },
                            // })
                            //     .then((resp) => {
                            //         const { data: respData = [] } = resp;
                            //         if(!respData.length) return respData;
                            //         const firstParentId = respData.reduce((prev, cur) => {
                            //             return Math.min(cur.parentId, prev);
                            //         }, respData[0].parentId);
                            //         const data = TreeMenu.formatTreeDataFromArrayToTree(
                            //             [...respData, { id: firstParentId, parentIds: '' }].map((i) => ({
                            //                 ...i,
                            //                 title: i.name,
                            //                 key: `${i.id}`,
                            //             })),
                            //             firstParentId,
                            //             'parentId'
                            //         );
                            //         return data;
                            //     })
                            //     .then((data) => {
                            //         setTreeData(data);
                            //         setSelectedKey(`${data?.[0]?.id}` ?? null);
                            //     });
                        }}
                    />
                </div>
            ),
        };
    }, [treeData, selectedKey, highlightKeyword]);
    const downloadTemplate = () => {
        let { sdkOptions } = props;
        let templateLocalUrl = `/import/gong/topic/topic_template.xlsx`;
        let templateUrl =
            Udesk.business.apiPath.getStaticBasePath(sdkOptions.props.domain, sdkOptions.props.pathPrefix) +
            templateLocalUrl;

        (window as any).location = templateUrl;
    };
    const closeModal = () => {
        setClassificationDetailVisible(false);
        setCurrentEditClassificationId(null);
        setClassificationDetailValues({});
    };
    const closeMoveModal = () => {
        setIsMove(false);
        moveId.current = null;
    };

    useEffect(() => {
        requestClassification().then((data) => {
            setTreeData(data);
            setSelectedKey(`${data?.[0]?.id}` ?? null);
        });
    }, []);
    useEffect(() => {
        if (classificationDetailVisible) {
            classificationDetailFormInstance.resetFields();
        }
    }, [classificationDetailVisible, classificationDetailValues]);
    return (
        <Page pageBodyClassName='sale-topic-page-index' title={/* 话题列表 */locales.pages.gong.topicList.index.topicList} backGroundGhost={true}>
            <StandardizeTable.RequestTable
                onRequest={(params) => {
                    const pagination = {
                        pageNum: params.pagination.current,
                        pageSize: params.pagination.pageSize,
                    };
                    getSubjectMarkCalls({
                        params: {
                            fileId: requestWithFileId.current ? Number(params.classificationId) : undefined,
                            callId: params.filter.callId ? Number(params.filter.callId) : undefined,
                            ...pagination,
                        } as any,
                    }).then((resp) => {
                        setTableData(resp.data ?? []);
                        !requestWithFileId.current && setSelectedKey(null);
                        requestWithFileId.current = DEFAULT_REQUEST_WITH_FILE_ID;
                        setPagination({
                            total: resp.paging?.total,
                            current: resp.paging?.pageNum,
                            pageSize: resp.paging?.pageSize,
                        });
                    });
                }}
                table={tableProps}
                classification={classificationProps}
                // exportBtn={{
                //     onClick: () => {
                //         return Promise.resolve();
                //     },
                // }}
                importBtn={{
                    onClick: (type) => {
                        showImportModal();
                        return Promise.reject();
                    },
                }}
                filter={{
                    fields: [
                        {
                            type: 'Input',
                            name: 'callId',
                            label: /* 通话ID */locales.pages.gong.topicList.index.callID,
                            rules: [
                                () => ({
                                    validator(_, value) {
                                        if (!value || !isNaN(Number(value))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(/* 通话ID必须为数字 */locales.pages.gong.topicList.index.callIDMustBeNumeric));
                                    },
                                }),
                            ],
                        },
                    ],
                    initialValues: { keywords: '' },
                }}
            />
            <ImportModal
                onCancel={handleOnCancel}
                onOk={handleOnOk}
                visible={isUploadVisible}
                uploadFile={uploadFile}
                downloadTemplate={downloadTemplate}
                title={/* 导入话题 */locales.pages.gong.topicList.index.leadInTopic}
                file={file}
                tip3={/* 分类名称重复时，不会覆盖原有的话题分类，该项会被直接跳过，不会导入 */locales.pages.gong.topicList.index.whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported}
            />
            <Modal
                visible={isMove}
                onOk={() => {
                    if (moveToId === null || !moveId.current) return;
                    putSubjectMarkFilesMoveById(
                        {
                            parentId: moveToId === 0 ? undefined : moveToId,
                        },
                        {
                            segments: {
                                id: moveId.current,
                            },
                        }
                    ).then(() => {
                        closeMoveModal();
                        requestClassification().then(setTreeData);
                    });
                }}
                onCancel={closeMoveModal}
                title={/* 移动分类 */locales.pages.gong.topicList.index.mobileClassification}
                // destroyOnClose={true}
            >
                <TreeMenu
                    treeData={moveTargetTreeData}
                    expandAction={false}
                    onSelect={(selectKey) => {
                        setMoveToId(Number(selectKey[0]));
                    }}
                />
            </Modal>
            <Modal
                visible={classificationDetailVisible}
                onOk={() => {
                    classificationDetailFormInstance.submit();
                }}
                onCancel={closeModal}
                title={classificationDetailValues.id ? /* 编辑话题 */locales.pages.gong.topicList.index.editTopic : /* 新建话题 */locales.pages.gong.topicList.index.newTopic}
                // destroyOnClose={true}
            >
                <FormBuilder
                    initialValues={classificationDetailValues}
                    onFinish={(values) => {
                        let params = {
                            ...values,
                        };
                        const trigger = (request) => {
                            request.then(() => {
                                requestClassification().then(setTreeData);
                                closeModal();
                            });
                        };
                        if (classificationDetailValues.id && currentEditClassificationId) {
                            trigger(
                                putSubjectMarkFilesById(params, {
                                    segments: {
                                        id: currentEditClassificationId,
                                    },
                                })
                            );
                            return;
                        }
                        params.parentId = currentEditClassificationId;
                        trigger(postSubjectMarkFiles(params));
                    }}
                    form={classificationDetailFormInstance}
                    footerRender={false}
                    fields={[
                        {
                            label: /* 话题名称 */locales.pages.gong.topicList.index.topicName,
                            name: 'name',
                            type: 'Input',
                            rules: [{ required: true }, { max: 40, type: 'string' }],
                            props: {
                                maxCount: 40,
                                showCount: true,
                            },
                        },
                    ]}
                />
            </Modal>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
