import UdeskLocales from 'UdeskLocales';
import type { FormProps } from 'udesk-ui/es/form-builder';
import {useCallback, useMemo, useRef, useState} from 'react';
import {EnumSelect} from "Component/common/enum-component";
import { useLiveEffect, useRequest } from 'src/util/hook';
import { array2tree } from 'Component/pages/intelligent-analysis/supervision/detail/recommendation';
import { isExercise } from './util';

export const usePassThroughParams = () => {
    const ref = useRef<any>({});

    const get = useCallback((data?: any) => {
        const params = ref.current;
        if (data) {
            $.extend(params, data);
        }
        return params;
    }, []);

    const set = useCallback((data?: any) => {
        const params = ref.current;
        for(let key in params) {
            delete params[key];
        }
        return $.extend(params, data);
    }, []);

    return [get, set, ref.current];
};

export const useTreeActiveKey = (props) => {
    const {
        storages: { selectedKey },
        saveStorages
    } = props;

    const setSelectedKey = useCallback((selectedKey) => {
        saveStorages((storages) => {
            if (selectedKey !== storages.selectedKey) {
                return {
                    ...storages,
                    pagination: {
                        ...storages.pagination,
                        current: 1,
                        total: 0,
                    },
                    selectedKey,
                };
            } else {
                return storages;
            }
        });
    }, [
        saveStorages,
    ]);

    return [selectedKey || null, setSelectedKey, saveStorages];
};

export const usePagination = (props) => {
    const {
        storages: { pagination },
        saveStorages
    } = props;

    const setPagination = useCallback((data) => {
        saveStorages((storages) => {
            return {
                ...storages,
                pagination: {
                    ...storages.pagination,
                    ...data,
                }
            };
        });
    }, [
        saveStorages,
    ]);

    return [pagination, setPagination, saveStorages];
};

export const useFilterData = (props) => {
    const {
        storages: { filterData },
        saveStorages
    } = props;

    const setFilterData = useCallback((data) => {
        saveStorages((storages) => {
            return {
                ...storages,
                filterData: {
                    ...storages.filterData,
                    ...data,
                }
            };
        });
    }, [
        saveStorages,
    ]);

    return [filterData, setFilterData, saveStorages];
};

export const useFilterForm = (props, taskType: 1 | 2) => {
    const request = useRequest();
    const [courseFolderList, setCourseFolderList] = useState<any[]>([]);
    const [filterData] = useFilterData(props);
    const queryParams = new URLSearchParams(props.location.search);
    const lessonId = queryParams.get('lessonId');

    const filterConfig: FormProps = useMemo(() => {
        return {
            customComponents: {
                EnumSelect
            },
            fields: [
                {
                    name: 'keyword',
                    label: /* 任务名称 */UdeskLocales['current'].pages.coach.learningCenter.components.index.taskName,
                    type: 'Input'
                },
                {
                    name: 'taskStatus',
                    label: /* 任务状态 */UdeskLocales['current'].pages.coach.learningCenter.components.index.taskStatus,
                    type: 'EnumSelect',
                    props: {
                        width: 120,
                        enumKey: 'learningTaskStatusType',
                        empty: {
                            label: /* 全部 */UdeskLocales['current'].pages.coach.learningCenter.components.index.whole
                        }
                    }
                },
                {
                    name: 'completeState',
                    label: /* 完成状态 */UdeskLocales['current'].pages.coach.learningCenter.components.index.completionStatus,
                    type: 'EnumSelect',
                    props: {
                        width: 120,
                        enumKey: 'learningTaskScheduleStatusType',
                        empty: {
                            label: /* 全部 */UdeskLocales['current'].pages.coach.learningCenter.components.index.whole
                        }
                    }
                },
                {
                    name: 'lessonCategoryId',
                    label: /* 课程分类 */UdeskLocales['current'].pages.coach.learningCenter.components.index.courseClassification,
                    type: 'TreeSelect',
                    props: {
                        treeData: courseFolderList,
                        style: {
                            width: 250,
                        },
                    },
                },
                {
                    name: 'dialogueMode',
                    label: isExercise(taskType, /* 练习模式 */UdeskLocales['current'].pages.coach.learningCenter.components.index.practiceMode, /* 考试模式 */UdeskLocales['current'].pages.coach.learningCenter.components.index.examMode),
                    type: 'EnumSelect',
                    props: {
                        width: 120,
                        enumKey: 'learningTaskDialogueModeType',
                        empty: {
                            label: /* 全部 */UdeskLocales['current'].pages.coach.learningCenter.components.index.whole
                        }
                    }
                },
                {
                    name: 'time',
                    label: /* 练习时间 */UdeskLocales['current'].pages.coach.learningCenter.components.index.practiceTime,
                    type: 'RangePicker',
                    props: {
                        format: 'YYYY-MM-DD HH:mm',
                        showTime: true,
                        style: {
                            width: 340,
                        }
                    }
                },
            ].filter(item => {
                if (lessonId) {
                    return item.name !== 'lessonCategoryId';
                }
                return true;
            }),
            initialValues: filterData,
        };
    }, [courseFolderList, filterData, lessonId, taskType]);

    useLiveEffect(() => {
        if (!lessonId) {
            request('/review/intelligentPartner/category').then(
                resp => {
                    setCourseFolderList(array2tree(resp.data ?? [], (node) => {
                        node.title = node.name;
                        node.value = node.id;
                        return node;
                    }) as any[]);
                }
            );
        }
    }, [lessonId]);

    return [
        filterConfig, 
        lessonId
    ];
};