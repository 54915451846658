import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
// import { Button } from 'udesk-ui';
// import { QA_SUB_APP, SALES_SUB_APP, setCurrentSubAppType } from 'Udesk/system/subApp';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
import './style.scss';
import { SubSystemEntryCard } from './components/sub-system-entry-card';
import { LoginUserAvatar } from 'Component/pages/components/login-user-avatar';
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';
import UdeskLocales from 'UdeskLocales';

const Template = React.memo((props: any) => {
    // const locales = Locales['current'];
    const [driver] = useState(() => {
        return new Driver({
            allowClose: false,
            closeBtnText: /* 关闭 */UdeskLocales['current'].pages.appEntry.index.close,
            onReset: () => {
                localStorage.setItem('hasShowedAppEntryDriver', '1');
            }
        });
    });

    // const goToSubApp = (routeName, subAppType) => {
    //     setCurrentSubAppType(subAppType).then(() => {
    //         const routeOptions = {
    //             history: props.history,
    //             routeName,
    //         };
    //         Udesk.ui.routing.transitionTo(routeOptions);
    //     });
    // };
    const goToCommonPage = (routeName) => {
        driver.reset();
        const routeOptions = {
            history: props.history,
            routeName,
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() => {
        if(localStorage.getItem('hasShowedAppEntryDriver')) return;

        driver.highlight({
            element: '#firstEnableSysModule',
            popover: {
                title: /* 进入系统应用 */UdeskLocales['current'].pages.appEntry.index.enterSystemApplication,
                description: /* 点击 “进入系统” 跳转至已启用的系统应用 */UdeskLocales['current'].pages.appEntry.index.clickEnterSystemToJumpToTheEnabledSystemApplication,
            },
        });
    }, []);
    return (
        <div className="udesk-qa-app-entry">
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 26px' }}>
                <LoginUserAvatar history={props.history} height={'100%'} />
            </div>
            <div className="udesk-qa-app-entry-sub-apps">
                {Udesk.enums.sysModuleList.map((sys) => {
                    return (
                        <SubSystemEntryCard
                            appName={sys.name}
                            desc={sys.desc}
                            icon={sys.icon}
                            onClick={goToCommonPage.bind(null, sys.routeName)}
                            sysModule={sys.id}
                        />
                    );
                })}
                {/* <SubSystemEntryCard
                    appName="智能质检"
                    desc="智能质检系统描述"
                    icon="/static/images/qa.svg"
                    onClick={goToSubApp.bind(null, 'home')}
                    sysModule={Udesk.enums.sysModuleList.qa.id}
                />
                <SubSystemEntryCard
                    appName="销售赋能"
                    desc="销售赋能系统描述"
                    icon="/static/images/sales.svg"
                    onClick={goToSubApp.bind(null, 'salesHome')}
                    sysModule={Udesk.enums.sysModuleList.sales.id}
                />
                <SubSystemEntryCard
                    appName="算法平台"
                    desc="算法平台系统描述"
                    icon="/static/images/aiPass.svg"
                    onClick={goToCommonPage.bind(null, 'algorithmHome')}
                    sysModule={Udesk.enums.sysModuleList.algorithmPlatform.id}
                />
                <SubSystemEntryCard
                    appName="通用设置"
                    desc="通用设置系统描述"
                    icon="/static/images/commonSetting.svg"
                    onClick={goToCommonPage.bind(null, 'generalSettingHome')}
                    sysModule={Udesk.enums.sysModuleList.commonSetting.id}
                /> */}
            </div>
        </div>
    );
});

export default Template;
