import React, { useCallback } from 'react';
import Udesk from 'Udesk';
import WaveAudioComponent from './component';
import { Divider, Icon, Dropdown, Space, Button, Modal } from 'udesk-ui';
import './style.scss';
import Locales from 'UdeskLocales';

const { confirm } = Modal;

const DownloadMenu = ({ downloadAudio, exportTxt /* exportWord */ }) => {
    const locales = Locales['current'];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}>
            {downloadAudio && (
                <Button type='text' onClick={downloadAudio}>
                    {locales.fix.voiceDownLoad}
                </Button>
            )}
            {exportTxt && (
                <Button type='text' onClick={exportTxt}>
                    {locales.fix.exportTxt}
                </Button>
            )}
            {/* {exportWord && (
                <Button type='text' onClick={exportWord}>
                    {locales.fix.exportWord}
                </Button>
            )} */}
        </div>
    );
};

const DownloadAstText = ({ downloadAudio, exportTxt /* exportWord */ }) => {
    return (
        <Dropdown
            overlay={<DownloadMenu downloadAudio={downloadAudio} exportTxt={exportTxt} />}
            placement='bottomCenter'
        >
            <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazai'></i>
        </Dropdown>
    );
};

const modalCallback = (callback, title) => {
    return () => {
        confirm({
            title: title,
            onOk: callback,
        });
    };
};

const SettingMenu = ({ reverse, recover, reAsr, isRelated }) => {
    const locales = Locales['current'];

    const reverseCallback = useCallback(modalCallback(reverse, locales.fix.sureReRole), [reverse]);
    const recoverCallback = useCallback(modalCallback(recover, locales.fix.sureRecover), [recover]);
    const reAsrCallback = useCallback(modalCallback(reAsr, locales.fix.sureReAsr), [reAsr]);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}>
            {!isRelated && (
                <Button type='text' onClick={reverseCallback}>
                    {locales.fix.reRole}
                </Button>
            )}
            <Button type='text' onClick={recoverCallback}>
                {locales.fix.recover}
            </Button>
            {reAsr && (
                <Button type='text' onClick={reAsrCallback}>
                    {locales.fix.reAsr}
                </Button>
            )}
        </div>
    );
};
export default class WaveAudioTemplate extends WaveAudioComponent {
    render() {
        let { locales } = this;
        let { rates, isShowEmotionButton, detail = {}, agentFlag = true, isRelated } = this.props;
        let { isShowPlayButton, isShowRateOptions, duration, currentTime, playbackRate, volume } = this.state;
        let { limitTimeOfWave, noWave, noWaveCursorPosition } = this.privates;
        return (
            <div className='wave-audio-component-talk' ref={this.privates.waveAudioRef}>
                <div className='wave-audio-container'>
                    <If condition={noWave}>
                        <Divider
                            type='vertical'
                            className='wave-audio-container-cursor'
                            style={{
                                left: noWaveCursorPosition + '%',
                                transform: 'translateX(' + (noWaveCursorPosition > 90 ? '-1' : '1') + 'px)',
                            }}
                        />
                        <Divider className='wave-audio-container-no-wave-message'>
                            {Udesk.utils.string.format(
                                locales.components.common.waveAudio.noWaveMessage,
                                limitTimeOfWave
                            )}
                        </Divider>
                    </If>
                </div>
                <div className='wave-audio-operation-panel'>
                    <div>
                        <If condition={isShowPlayButton}>
                            <span
                                className='wave-audio-button-box wave-audio-play-button'
                                onClick={this.actions.play}
                                title='play'
                            >
                                <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-bofang'></i>
                            </span>
                        </If>
                        <If condition={!isShowPlayButton}>
                            <span
                                className='wave-audio-button-box wave-audio-pause-button'
                                onClick={this.actions.pause}
                                title='pause'
                            >
                                <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-icon--1'></i>
                            </span>
                        </If>
                        <span className='wave-audio-time-box'>
                            {currentTime} / {duration}
                        </span>
                        <span className='wave-audio-button-box wave-audio-rate-button'>
                            <span className='wave-audio-rate-selected-option' onClick={this.actions.showRateOptions}>
                                X {playbackRate}
                            </span>
                            <If condition={isShowRateOptions}>
                                <ul className='wave-audio-rate-options'>
                                    <For each='rate' index='index' of={rates}>
                                        <li
                                            className='wave-audio-rate-option'
                                            key={index}
                                            onClick={this.actions.changePlaybackRate.params(rate)}
                                        >
                                            {rate}
                                        </li>
                                    </For>
                                </ul>
                            </If>
                        </span>
                        <span className='wave-audio-button-box wave-audio-volumn-button'>
                            <i
                                className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yinliang'
                                onClick={this.actions.showVolumeRange}
                            ></i>

                            <span className='wave-audio-volumn-input-box'>
                                <input
                                    type='range'
                                    id='wave-audio-volume'
                                    min='0'
                                    max='1'
                                    step='0.05'
                                    value={volume}
                                    onChange={this.actions.changeVolume}
                                />
                            </span>
                        </span>
                    </div>
                    <Space>
                        <If condition={agentFlag}>
                            <span className='wave-audio-button-box'>
                                <span className='wave-audio-custom-button'>
                                    <span className='wave-audio-custom-button-show'></span>
                                    <i class='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-customer-service-kefu'></i>
                                    <span className='wave-audio-custom-button-num'>
                                        {(detail && detail.agentSpeakRate && `${detail.agentSpeakRate}%`) ||
                                            locales.labels.none}
                                    </span>
                                </span>

                                <span className='wave-audio-agent-button'>
                                    <span className='wave-audio-agent-button-show'></span>
                                    <Icon type='ic-user-ren' />
                                    <span className='wave-audio-agent-button-num'>
                                        {(detail && detail.customerSpeakRate && `${detail.customerSpeakRate}%`) ||
                                            locales.labels.none}
                                    </span>
                                </span>
                            </span>
                        </If>
                        <If condition={isShowEmotionButton}>
                            <span onClick={this.actions.showEmotion} title='Emotion Analysis'>
                                <i
                                    className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xindiantu'
                                    style={{ color: 'lor:rgba(0, 0, 0, 0.65)' }}
                                ></i>
                            </span>
                        </If>
                        <Space>
                            {Udesk.data.init?.user && Udesk.data.init.user.hasFeature('call:root:download') && (
                                <DownloadAstText
                                    downloadAudio={this.actions.downloadVoice}
                                    exportTxt={this.actions.exportTxt}
                                />
                            )}

                            <Dropdown
                                overlay={
                                    <SettingMenu
                                        reverse={this.props.reverse}
                                        recover={this.props.recover}
                                        reAsr={this.props.reAsr}
                                        isRelated={isRelated}
                                    />
                                }
                                placement='bottomCenter'
                            >
                                <Icon type='ic-set-shezhi1' style={{ fontSize: '16px' }} />
                            </Dropdown>
                        </Space>
                    </Space>
                </div>
                {this.privates.downUrl && (
                    <iframe src={this.privates.downUrl} className='demo-inspection-iframe' title='url'></iframe>
                )}
            </div>
        );
    }
}
