import React from 'react';
import WordsWeightComponent from './component';
import ReactModal from 'udesk-react/src/components/react-modal';
import ReactSelect from 'udesk-react/src/components/react-select';
import Udesk from 'Udesk';
import SmartWordsLibraryCommonList from '../smart-words-library-common-list';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Icon, Input } from 'udesk-ui';

export default class WordsWeightTemplate extends WordsWeightComponent {
    render() {

        let {
            locales,
            actions
        } = this;

        let {
            columns,
            isWordsWeightModalShow,
            wordsWeightModalTitle,
            weightWords,
            weight,
            effectiveness,
        } = this.privates;

        let { getFieldDecorator } = this.props.form;

        let corporaType = Udesk.enums.smartWordsLibraryMenus.wordsWeight.id;
        return (
            <div>
                <SmartWordsLibraryCommonList
                    columns={columns}
                    navItemType={corporaType}
                    getlistDataPath='corpora/weightList'
                    openAddModal={actions.createWordsWeight}
                    uploadModalTitle={locales.components.pages.smartWordsLibrary.upload.uploadWordsWeight}
                    onCreated={(instance) => { this.privates.smartWordsLibraryListInstance = instance; }}
                    boardName={Udesk.enums.smartWordsLibraryBoard.wordsWeight.id}
                />

                <ReactModal cancelText={locales.labels.cancel}
                    visible={isWordsWeightModalShow}
                    title={wordsWeightModalTitle}
                    headerClassName="udesk-qa-smart-words-library-modal-header"
                    footerClassName="udesk-qa-smart-words-library-modal-footer"
                    okText={locales.labels.save}
                    onOk={actions.saveWordsWeight}
                    onCancel={actions.closeWordsWeightModal}
                >

                    <Form layout="horizontal" className="smart-words-library-nav-item-form" onSubmit={actions.saveWordsWeight.preventDefault()}>

                        <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} label={locales.fields.smartWordsLibrary.wordsWeight.weightWords}>
                            {getFieldDecorator('weightWords', {
                                initialValue: weightWords,
                                rules: [{
                                    required: true,
                                    message: locales.components.pages.smartWordsLibrary.wordsWeight.pleaseEnterWeightWords
                                }]
                            })(
                                <Input
                                    placeholder={locales.components.pages.smartWordsLibrary.wordsWeight.pleaseEnterWeightWords}
                                />
                            )}
                        </Form.Item>

                        <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} label={locales.fields.smartWordsLibrary.wordsWeight.weight}>
                            {getFieldDecorator('weight', {
                                initialValue: weight,
                                rules: [
                                    {
                                        required: true,
                                        message: locales.components.pages.smartWordsLibrary.wordsWeight.pleaseEnterWeight
                                    },
                                    {
                                        pattern: /^\d+$/,
                                        message: locales.components.pages.smartWordsLibrary.wordsWeight.weightNeedNumber
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (/^\d+$/.test(value) && (value > 100 || value < 0)) {
                                                callback(locales.components.pages.smartWordsLibrary.wordsWeight.weightNeedInZeroToHundred);
                                            } else {
                                                callback();
                                            }
                                        }
                                    }]

                            })(
                                // <div>
                                <Input
                                    className="edit-weight-input"
                                    placeholder={locales.components.pages.smartWordsLibrary.wordsWeight.pleaseEnterWeight}
                                />


                            )}
                            <span className="standard-weight-information">
                                <Icon type="ic-warn-jinggao" className="exclamation" /> {locales.components.pages.smartWordsLibrary.wordsWeight.standardWeightInformation}
                            </span>
                        </Form.Item>

                        <Form.Item
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            label={locales.fields.smartWordsLibrary.wordsWeight.effectiveness}
                        >
                            {getFieldDecorator('effectiveness', {
                                initialValue: effectiveness.toString(),
                            })(
                                <ReactSelect
                                    classNames="udesk-qa-smart-words-library-react-select"
                                    hasNullSelect={false}
                                    dataScource={Udesk.enums.wordsEffectiveness}
                                    onChanged={actions.changeWordsWeightEffectiveness}
                                ></ReactSelect>
                            )}
                        </Form.Item>

                    </Form>

                </ReactModal>
            </div>
        );
    }
}
