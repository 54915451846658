import Udesk from 'Udesk';
import React from 'react';
import MainMenuComponent from './component';
import UploadDownModel from 'Component/pages/components/uploadDownModel';
import { Dropdown, Icon, Menu, Tooltip, Space, Badge } from 'udesk-ui';
import ProductMatrix from '../product-matrix';
import './style.scss';
import { isQaSubApp, isSalesSubApp, isCoach, isConversationAnalysis } from 'Udesk/system/subApp';
import { LoginUserAvatar } from '../login-user-avatar';

export default class MainMenuTemplate extends MainMenuComponent {
    render() {
        let { actions, locales, state, props } = this;
        const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
        const menu = (
            <Menu onClick={actions.messageMenuNotice}>
                <Menu.Item key="reply">
                    {locales.components.pages.messageCenter.replyTitle}
                    <Badge count={props.REPLY} size="small"></Badge>
                </Menu.Item>
                <Menu.Item key="answer">
                    {locales.components.pages.messageCenter.answerTitle}
                    <Badge count={props.AT_ME} size="small"></Badge>
                </Menu.Item>
                <Menu.Item key="share">
                    {locales.components.pages.messageCenter.shareTitle}
                    <Badge count={props.SHARE} size="small"></Badge>
                </Menu.Item>
                <Menu.Item key="subscription">
                    {locales.components.mainMenu.template.subscriptionDynamics}
                    <Badge count={props.SUBSCRIPTION} size="small"></Badge>
                </Menu.Item>
                <Menu.Item key="study">
                    {/* 学习提醒 */ locales.components.mainMenu.template.learningReminder}
                    <Badge count={props.STUDY} size="small"></Badge>
                </Menu.Item>
            </Menu>
        );
        return (
            <div className="main-menu-component">
                <If condition={Udesk.data.init.user}>
                    <ul className="main-menu-component-navbar-nav pull-right">
                        <li className="main-menu-component-nav-item">
                            {/* 用户信息 */}
                            <LoginUserAvatar history={this.props.history} />
                        </li>
                    </ul>
                    <ul className="main-menu-component-navbar-nav pull-right">
                        <Space size={24}>
                            {Boolean(Udesk.data.init.company.enabledWhiteSystem) || (
                                <ProductMatrix
                                    // width={500}
                                    domain={window.location.origin}
                                />
                            )}
                            {/* <If condition={props.showWarning}> */}
                            {/* 不展示警告信息 */}
                            <If condition={false}>
                                <li className="main-menu-component-nav-item">
                                    {/* 费用告警信息 */}
                                    <span
                                        className="main-menu-component-nav-link clear"
                                        onClick={actions.menuClick.params({ key: 'billingCenter' })}
                                    >
                                        <Icon
                                            type="ic-notice-line"
                                            style={{
                                                paddingRight: '10px',
                                                color: 'rgba(251,81,39,1)',
                                                fontSize: 20,
                                            }}
                                        />
                                        <span style={{ color: 'rgba(251,81,39,1)' }}>
                                            {locales.labels.warningMessage}
                                        </span>
                                    </span>
                                </li>
                            </If>
                            {/* 消息中心 */}
                            <If
                                condition={
                                    hasFeature &&
                                    hasFeature('sys:review:msgbox:root') &&
                                    isSalesSubApp()
                                }
                            >
                                <li
                                    className="main-menu-component-nav-item"
                                    style={{ lineHeight: '54px' }}
                                >
                                    <Badge count={props.totalNum} size="small">
                                        <Dropdown
                                            destroyPopupOnHide
                                            overlayClassName="main-menu-component-nav-item-messaage-center"
                                            overlay={menu}
                                            placement="bottomCenter"
                                        >
                                            <Icon
                                                style={{ fontSize: 20 }}
                                                type="ic-notice-tixing"
                                            ></Icon>
                                        </Dropdown>
                                    </Badge>
                                </li>
                            </If>
                            <If
                                condition={
                                    hasFeature &&
                                    hasFeature('hc:root') &&
                                    !Udesk.data.init.company.enabledWhiteSystem &&
                                    (isSalesSubApp() || isQaSubApp() || isConversationAnalysis())
                                }
                            >
                                <li className="main-menu-component-nav-item">
                                    {/* 帮助中心 */}
                                    {/* <Dropdown
                                        overlay={(
                                            <Menu onClick={actions.menuClick}>
                                                <Menu.Item key="systemInformation">
                                                    {locales.labels.systemInformation}
                                                </Menu.Item>
                                                <Menu.Item key="billingCenter">
                                                    {locales.labels.billingCenter}
                                                </Menu.Item>
                                            </Menu>
                                        )}
                                        trigger="click"
                                        placement="bottomCenter">
                                        <span className="main-menu-component-nav-link clear">
                                            <Tooltip
                                                title={locales.menu.navBar.billingCenter}
                                                placement="bottom">
                                                <Icon type="jifei" style={{ fontSize: 20 }} />
                                            </Tooltip>
                                        </span>
                                    </Dropdown> */}
                                    <span
                                        className="main-menu-component-nav-link clear"
                                        onClick={actions.menuClick.params({
                                            key: 'systemInformation',
                                        })}
                                    >
                                        <Tooltip
                                            title={locales.menu.navBar.billingCenter}
                                            placement="bottom"
                                        >
                                            <Icon type="jifei" style={{ fontSize: 20 }} />
                                        </Tooltip>
                                    </span>
                                </li>
                            </If>
                            <If
                                condition={
                                    hasFeature &&
                                    hasFeature('sys:impExp:root') &&
                                    (isSalesSubApp() || isQaSubApp() || isConversationAnalysis())
                                }
                            >
                                <li className="main-menu-component-nav-item">
                                    {/* 上传下载中心 */}
                                    <Dropdown
                                        overlay={
                                            <UploadDownModel
                                                {...this.props}
                                                show={state.showUploadDownModel}
                                            />
                                        }
                                        trigger="click"
                                        placement="bottomCenter"
                                        onVisibleChange={actions.uploadDownModelVisibleChanged}
                                    >
                                        <span className="main-menu-component-nav-link clear">
                                            <Tooltip
                                                title={locales.menu.navBar.uploadDownload}
                                                placement="bottom"
                                            >
                                                <Icon
                                                    type="shangchuanxiazai"
                                                    style={{ fontSize: 20 }}
                                                />
                                            </Tooltip>
                                        </span>
                                    </Dropdown>
                                </li>
                            </If>
                            <If
                                condition={
                                    hasFeature &&
                                    hasFeature('sys:setting:root') &&
                                    (isSalesSubApp() ||
                                        isQaSubApp() ||
                                        isCoach() ||
                                        isConversationAnalysis())
                                }
                            >
                                <li className="main-menu-component-nav-item">
                                    {/* 系统设置 */}
                                    <span
                                        className="main-menu-component-nav-link clear"
                                        onClick={actions.menuClick.params({ key: 'setting' })}
                                    >
                                        <Tooltip
                                            title={locales.menu.navBar.setting}
                                            placement="bottom"
                                        >
                                            <Icon type="shezhi1" style={{ fontSize: 20 }} />
                                        </Tooltip>
                                    </span>
                                </li>
                            </If>
                        </Space>
                    </ul>
                </If>
            </div>
        );
    }
}
