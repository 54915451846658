import React, { useCallback, useMemo, useRef, useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import PageRightFrom, { RefType } from './pageRightFrom';
import { useStorageProps } from 'src/util/component';
import { useRequest } from 'src/util/hook/request';
import { Icon } from 'udesk-ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { StandardizeTable } from 'udesk_gm_ui';
import { useLiveEffect } from 'src/util/hook';
import { getIntelligentExtractTasksResult } from 'src/api/intelligentExtractTasks/result';
import { putIntelligentExtractTasksResultBulkDelete } from 'src/api/intelligentExtractTasks/result/bulkDelete';
import { deleteIntelligentExtractTasksResultById } from 'src/api/intelligentExtractTasks/result/{id}';
import { getDeletedPagination } from 'src/util/hook/table';
import './index.scss';

export default React.memo((props: any) => {
    const locales = UdeskLocales['current'];
    const [selectedRowForLeftPage] = useStorageProps(props, 'leftPageSelectedRow');
    const [pagination, setPagination] = useStorageProps(props, 'rightPagePagination');
    const [selectRows, setSelectRows] = useState<any[]>();

    const modalRef = useRef<RefType>(null);

    const selectRowIds = useMemo(() => selectRows?.map(row => row.id), [selectRows]);

    const {loading, data: tableData, run, refresh } = useRequest({
        onSuccess: useCallback((resp) => {
            resp.data.forEach((item) => {
                item.disabled = [1, 2].includes(item.addKnowledgeLibraryStatus);
            });
            setPagination({
                current: resp.paging?.pageNum,
                total: resp.paging?.total
            });
        }, [setPagination]),
    });

    const ajaxTableData=  useCallback((params) => {
        return run(getIntelligentExtractTasksResult, {
            params
        });
    }, [run]);

    const requestTableSource = useCallback((params) => {
        ajaxTableData({
            taskId: selectedRowForLeftPage?.id,
            pageNum: params.pagination.current,
            pageSize: params.pagination.pageSize,
        });
    }, [ajaxTableData, selectedRowForLeftPage?.id]);

    const deleteCallback = useCallback((delNum?) => {
        requestTableSource({
            pagination: getDeletedPagination(pagination, delNum)
        });
        Udesk.ui.notify.success(/* 删除成功 */locales.business.info.deleteSuccess);
    }, [pagination, requestTableSource]);

    const {run: runForBatchDelete} = useRequest({
        onSuccess: useCallback(() => deleteCallback(selectRows?.length), [deleteCallback, selectRows?.length])
    });

    const {run: runForDelete} = useRequest({
        onSuccess: useCallback(() => deleteCallback(1), [deleteCallback])
    });

    const openModel = useCallback((data, isBatch) => {
        modalRef.current?.open(data, isBatch);
    }, []);

    const successCallback = useCallback((data) => {
        Udesk.ui.notify.success(/* 添加成功 */locales.components.addToTaskModal.index.successfullyAdded);
        setSelectRows(
            list => list?.filter(row => !data.ids.includes(row.id))
        );
        refresh();
    }, [
        refresh
    ]);

    useLiveEffect(() => {
        if (selectedRowForLeftPage?.id) {
            const params = {
                pageSize: pagination.pageSize,
                current: 1,
                total: 0,
            };
            setSelectRows([]);
            setPagination(params);
            requestTableSource({
                pagination: params
            });
        }
    }, [
        selectedRowForLeftPage?.id
    ]);
    
    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 问题 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.components.pageRightTable.index.problem,
                key: 'question',
                width: 200,
                dataIndex: 'question'
            },
            {
                title: /* 答案 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.components.pageRightTable.index.answer,
                key: 'answer',
                width: 300,
                dataIndex: 'answer'
            },
            {
                title: /* 操作 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.operation,
                key: 'actions',
                renderType: 'actions',
                width: 175,
                buttons: [
                    {
                        label: /* 添加到知识库 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.components.pageRightTable.index.addToKnowledgeBase,
                        disabled: (item) => item.disabled,
                        onClick: (item) => openModel([item.id], false),
                    },
                    {
                        label: /* 删除 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.delete,
                        danger: true,
                        onClick: (item) => {
                            setSelectRows(selectRows?.filter(row => row.id !== item.id));
                            runForDelete(deleteIntelligentExtractTasksResultById, {segments: item});
                        },
                    },
                ].filter(Boolean),
            },
        ];
        return {
            loading,
            columns,
            dataSource: tableData,
            pagination,
            rowSelectionEnhance: {
                selectedRowKeys: selectRowIds,
                onSelectedRowKeysChange(keys: any[], items: any[]) {
                    setSelectRows(items);
                },
            },
        };
    }, [
        selectRows, selectRowIds,
        openModel, 
        loading, 
        tableData, 
        pagination, 
        runForDelete
    ]);

    return (
        <div className='intelligent-extractors-page-right'>
            <PageRightFrom ref={modalRef} successCallback={successCallback}/>
            <StandardizeTable.RequestTable
                autoRequestOnMount={false}
                onRequest={requestTableSource}
                table={tableProps}
                batchedActions={[
                    {
                        label: locales.labels.batchDel,
                        disabled: (selectRows?.length || 0 ) < 1,
                        confirm: {
                            title: /* 请确定是否删除 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.components.pageRightTable.index.pleaseConfirmWhetherToDeleteIt
                        },
                        onClick () {
                            return runForBatchDelete(putIntelligentExtractTasksResultBulkDelete, selectRowIds);
                        },
                    },
                ]}
                toolBarRightButtons={[
                    {
                        disabled: (selectRows?.filter(row => !row.disabled)?.length || 0) === 0,
                        mainBtn: true,
                        label: /* 添加到知识库 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.components.pageRightTable.index.addToKnowledgeBase,
                        icon: <Icon type='PlusOutlined' antdIcon={true} />,
                        onClick() {
                            openModel(selectRows?.filter(row => !row.disabled)?.map(row => row.id), true);
                        },
                    },
                ].filter(Boolean)}
            />
        </div>
    );
});
