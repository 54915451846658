import React, { useState } from 'react';
import Udesk from 'Udesk';
import styled from 'styled-components';
import { Button, Icon, Space } from 'udesk-ui';
import { NodeTypes } from '../../utils/NodeBuilder';
import UdeskLocales from 'UdeskLocales';

const Aside = styled.aside`
    padding: 12px 16px 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 100;
    position: absolute;
    background-color: #fff;

    .dndnode {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 8px;
        padding: 8px 12px;
        cursor: grab;
        display: flex;
        align-items: center;
    }
    .icon {
        color: #fff;
        margin-right: 12px;
        width: 22px;
        height: 22px;
        border-radius: 4px;
        text-align: center;
    }
    .startNode {
        .icon {
            background: linear-gradient(90deg, #1a6eff 0%, rgba(26, 110, 255, 0.45) 100%);
        }
    }
    .userNode {
        .icon {
            background: linear-gradient(90deg, #1acc8c 0%, rgba(24, 204, 138, 0.45) 100%);
        }
    }
    .robotNode {
        .icon {
            background: linear-gradient(90deg, #7963e0 0%, rgba(121, 99, 224, 0.45) 100%);
        }
    }
    .knowledgeNode {
        .icon {
            background: linear-gradient(90deg, #f3aa18 0.01%, rgba(242, 170, 24, 0.45) 100%);
        }
    }
`;

export const Sidebar = (props) => {
    const { courseType } = props;
    const [visible, setVisible] = useState(true);
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <Aside>
            <Space
                style={{
                    marginBottom: visible ? 12 : 8,
                }}
            >
                <div
                    style={{
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '22px',
                        color: 'rgba(0, 0, 0, 0.85)',
                    }}
                >{/* 节点 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.flow.sidebar.node}</div>
                <Button
                    type="text"
                    size="small"
                    icon={
                        <Icon
                            antdIcon={true}
                            type="UpOutlined"
                            style={{
                                color: 'rgba(0, 0, 0, 0.45)',
                                transform: visible ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                        />
                    }
                    onClick={() => {
                        setVisible((p) => !p);
                    }}
                ></Button>
            </Space>
            {visible &&
                NodeTypes.allTypes
                    .filter((t) => t.key !== Udesk.enums.nodeTypes.pptNode.key)
                    .filter((t) => {
                        if (courseType === Udesk.enums.lessonType.question.id) {
                            return [
                                Udesk.enums.nodeTypes.startNode.id,
                                Udesk.enums.nodeTypes.knowledgeNode.id,
                            ].includes(t.id);
                        }
                        return true;
                    })
                    .map((type) => {
                        return (
                            <div
                                className={`dndnode ${type.key}`}
                                onDragStart={(event) => onDragStart(event, JSON.stringify(type))}
                                draggable
                            >
                                <div className="icon">
                                    <Icon type={type.icon} />
                                </div>
                                <div>{type.label}</div>
                            </div>
                        );
                    })}
        </Aside>
    );
};
