import React from 'react';
import Udesk from 'Udesk';
import TasksCheckPointIndexRoute from './route';
import TaskConditionDictionaries from 'Component/pages/components/task-condition-dictionaries';
// import { NavLink } from 'react-router-dom';
import { PageHeader, Button, Alert, Modal, Radio, Collapse, Table, Space, Input, InputNumber, Anchor, Icon, Popconfirm, Tag, TreeSelect, Spin } from 'udesk-ui';
import './style.scss';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const { Panel } = Collapse;
const { Link } = Anchor;
const borderStyle = 'none';
const defaultStyle = {
    background: "#fff",
    borderRadius: '2px',
    lineHeight: '32px',
    transition: 'boxShadow 0.5s,border 0.7s',
};
const getFirstStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 4px)",
    margin: "4px 2px",
    borderBottom: borderStyle,
    ...defaultStyle,
    // border: isDragging ? '1px solid #d9d9d9' : 'none',
    boxShadow: isDragging ? '2px 2px 11px 0px rgba(0,0,0,.3)' : 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
});
const getSecondStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 4px)",
    margin: "2px",
    ...defaultStyle,
    // border: isDragging ? '1px solid #d9d9d9' : 'none',
    boxShadow: isDragging ? '2px 2px 11px 0px rgba(0,0,0,.3)' : 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
});
const getPointsStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 4px)",
    margin: "2px",
    ...defaultStyle,
    // border: isDragging ? '1px solid #d9d9d9' : 'none',
    boxShadow: isDragging ? '2px 2px 11px 0px rgba(0,0,0,.3)' : 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({

    background: isDraggingOver ? "#e8f4ff" : "#fff",
    border: borderStyle,
    // marginBottom: '8px'
});
const getListInnerStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#e8f4ff" : "#fff",
    border: borderStyle,
    // marginBottom: '8px'
});
export default class TasksCheckPointIndexTemplate extends TasksCheckPointIndexRoute {
    render() {
        let titleNode = (item, style) => {
            return (
                <div
                    style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 16, ...style }}
                >
                    <div title={item.name} style={{ width: 120 }}>
                        {item.name}
                    </div>
                    <div>
                        {Udesk.business.scoreCompute.formatTotalScore(item)}
                    </div>
                </div>
            );
        };
        let classificationDetail = (type, currentClassification, classOrNew) => {
            let id = classOrNew === 'new' ? classOrNew : classOrNew.id;
            return (
                <If condition={currentClassification[id]}>
                    <div style={{ padding: 16, backgroundColor: 'rgba(0, 0, 0, 0.03)', borderTop: type === 'second' ? '1px solid #d9d9d9' : 'none', marginBottom: type === 'second' ? 8 : 0 }}>
                        <div className="check-point-value-list">
                            <div className="check-point-value-item">
                                <span className="check-point-value-item-label">
                                    {locales.components.tasks.checkPointClassifications.name}{locales.labels.colon}
                                </span>
                                <Input
                                    className="check-point-value-item-input"
                                    value={currentClassification[id].name}
                                    onChange={actions.onValueChanged.params(currentClassification, id, 'name')}
                                />
                            </div>
                        </div>
                        <div className="check-point-value-list">
                            <div className="check-point-value-item">
                                <span className="check-point-value-item-label">
                                    {locales.components.tasks.checkPointClassifications.highestScore}{locales.labels.colon}
                                </span>
                                <InputNumber
                                    className="check-point-value-item-input"
                                    value={currentClassification[id].scoreUpperLimit}
                                    onChange={actions.onValueChanged.params(currentClassification, id, 'scoreUpperLimit')}
                                />
                            </div>
                            <div className="check-point-value-item check-point-value-item-middle">
                                <span className="check-point-value-item-label">
                                    {locales.components.tasks.checkPointClassifications.minimumScore}{locales.labels.colon}
                                </span>
                                <InputNumber
                                    className="check-point-value-item-input"
                                    value={currentClassification[id].scoreLowerLimit || 0}
                                    onChange={actions.onValueChanged.params(currentClassification, id, 'scoreLowerLimit')}
                                />
                                <span style={{ color: 'rgba(0, 0, 0, 0.25)', marginLeft: 8, whiteSpace: 'nowrap' }}>
                                    {locales.components.tasks.checkPointClassifications.defaultMinScore}
                                </span>
                            </div>
                            <div className="check-point-value-item">
                                <span className="check-point-value-item-label">
                                    {locales.components.tasks.checkPointClassifications.beginScore}{locales.labels.colon}
                                </span>
                                <InputNumber
                                    className="check-point-value-item-input"
                                    value={currentClassification[id].initialScore || 0}
                                    onChange={actions.onValueChanged.params(currentClassification, id, 'initialScore')}
                                />
                            </div>
                        </div>
                        <div className="check-point-value-list">
                            <div className="check-point-value-item check-point-value-item-score-tip">
                                {locales.components.tasks.checkPointClassifications.scoreSetTip}
                            </div>
                            <div className="check-point-value-item">
                                <Radio.Group
                                    value={currentClassification[id].wayOverUpperLimit}
                                    onChange={actions.onValueChanged.params(currentClassification, id, 'wayOverUpperLimit')}>
                                    <For each="item" index="index" of={Udesk.enums.wayOverUpperLimits}>
                                        <Radio key={item.id} value={item.id}>{item.name}</Radio>
                                    </For>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="check-point-value-item">
                            <Space>
                                {
                                    actions.iFApproveWrap(<Button type='primary' onClick={actions.saveClassificationAdd.params(type, classOrNew)}>{locales.labels.save}</Button>)
                                }
                                <Button onClick={actions.closeClassificationDetail.params(type, currentClassification, classOrNew)}>{locales.labels.cancel}</Button>
                            </Space>
                        </div>
                    </div>
                </If>
            );
        };

        let { actions, locales, props, state, privates } = this;
        let { templateValue } = this.privates;
        let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
        return (
            <React.Fragment>
                <div className='udesk-qa-web-page'>
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        title={actions.getPageTitle()}
                        extra={actions.getPageHeaderExtra()}
                        onBack={actions.goBack()}
                    />
                    <div className='udesk-qa-web-page-body'>
                        <div className='udesk-qa-web-page-body-root tasks-manage-template-check-poibt-index'>
                            <div className='tasks-manage-template-check-poibt-index-inner'>
                                <If condition={privates.model.status.processStatus === 2 && !privates.isWechatQa}>
                                    <Alert
                                        message={locales.pages.tasks.manage.template.basicInfo.index.conduct2}
                                        type="info"
                                        closeText={locales.pages.tasks.manage.template.basicInfo.index.quit}
                                        onClose={actions.exitBoot}
                                    />
                                </If>
                                <If condition={hasFeature && hasFeature('task:template:point:list')}>
                                    <div style={{ backgroundColor: '#fff', display: 'flex', flex: 1 }}>
                                        <div className="tasks-manage-template-check-point-content-left" style={{ display: privates.anchorShow ? 'block' : 'none' }}>
                                            <div className="tasks-manage-template-check-point-content-left-inner">
                                                <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', height: 40, padding: '9px 15px' }}>
                                                    {locales.pages.tasks.manage.template.checkPoint.index[privates.isWechatQa ? 'classificationWeChat' : 'classification']}
                                                </div>
                                                <Anchor onClick={actions.anchorChange} getContainer={() => document.getElementsByClassName('tasks-manage-template-check-poibt-index')[0]}>
                                                    {
                                                        state.classifications.map(firstClass => {
                                                            return (
                                                                <Link href={`#${firstClass.id}`} title={titleNode(firstClass, { fontWeight: 600 })}>
                                                                    {
                                                                        firstClass.children && !!firstClass.children.length && firstClass.children.map(secondClass => {
                                                                            return (
                                                                                <Link href={`#${firstClass.id}-${secondClass.id}`} title={titleNode(secondClass, {})} />
                                                                            );
                                                                        })
                                                                    }
                                                                </Link>
                                                            );
                                                        })
                                                    }
                                                </Anchor>
                                            </div>
                                        </div>
                                        <div className="tasks-manage-template-check-point-content-right" ref={privates.checkPointList}>
                                            <div className="tasks-manage-template-check-point-content-right-header">
                                                <div>
                                                    <Icon type={privates.anchorShow ? 'ic-menufold-shouqicebianlan1' : 'ic-menuopen-zhankaicebianlan'} style={{ marginRight: 8 }} onClick={actions.showAnchor} />
                                                    {
                                                        ' ' + locales.pages.tasks.manage.template.checkPoint.index[privates.isWechatQa ? 'currentCheckPointScoreWechat' : 'currentCheckPointScore']
                                                            + ' '
                                                    }
                                                    {state.totalScore}
                                                    {' ' + locales.labels.scoreUnit}
                                                </div>
                                                {
                                                    actions.iFApproveWrap(
                                                        <Button 
                                                            type='link' 
                                                            onClick={actions.openMoveTree.params(true)}
                                                        >
                                                            <Icon type='list-check'></Icon>
                                                            {locales.labels.move}
                                                        </Button>)
                                                }
                                                
                                                <If condition={hasFeature && hasFeature('task:template:point:add')}>
                                                    <Modal
                                                        visible={state.createModalVisible}
                                                        title={
                                                            locales.pages.tasks.manage.template.checkPoint.index
                                                                .template
                                                        }
                                                        onCancel={actions.closeCreateModal}
                                                        footer={null}
                                                        width={768}
                                                        wrapClassName='check-point-index-create-modal'
                                                    >
                                                        <div className='check-point-index-create-modal-body'>
                                                            <div className='check-point-index-create-modal-tabs'>
                                                                <Radio.Group defaultValue="a" buttonStyle="solid" onChange={actions.radioChange}>
                                                                    <Radio.Button value="a">{locales.pages.tasks.manage.template.checkPoint.index.tabs.template}</Radio.Button>
                                                                    <Radio.Button value="b">{locales.pages.tasks.manage.template.checkPoint.index.tabs.demo}</Radio.Button>
                                                                </Radio.Group>
                                                            </div>
                                                            <div className='check-point-index-create-modal-templates'>
                                                                {
                                                                    privates.templateList.map((item, index) => {
                                                                        return (
                                                                            <div key={item.id} onClick={actions.tranToDetail.params(item, templateValue)} className='check-point-index-create-modal-templates-item'>
                                                                                <div style={{ textAlign: 'center' }}>
                                                                                    <span class="udesk-qa-react-web-iconfont">&#xe6cd;</span>
                                                                                </div>
                                                                                <div className='check-point-index-create-modal-templates-item-name'>
                                                                                    {item.name}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </Modal>
                                                    <Modal visible={privates.isUploadVisible} title={locales.pages.tasks.manage.template.checkPoint.index.import} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={actions.changeUploadVisible} onOk={actions.importTemplate} headerClassName="udesk-qa-modal-default-header" footerClassName="udesk-qa-modal-default-footer">
                                                        <div className="udesk-qa-modal-body-content">
                                                            <div className="form-group">
                                                                <label>
                                                                    {locales.labels.uploadFile}{locales.labels.colon}
                                                                </label>
                                                                <div className="upload-file-button-container">
                                                                    <input type="file" className="upload-file-inpiut" onChange={actions.uploadFile} />
                                                                    <button type="button" className="upload-file-button btn btn-sm btn-primary">{locales.labels.upload}</button>
                                                                </div>
                                                                <If condition={privates.file && privates.file.name}>
                                                                    <span className="upload-file-name">
                                                                        {privates.file.name}
                                                                    </span>
                                                                </If>
                                                            </div>
                                                            <div className="form-group">
                                                                {locales.labels.numOne}{locales.labels.colon}&nbsp;&nbsp;
                                                                {locales.labels.click}&nbsp;&nbsp;
                                                                <span className="upload-file-template" onClick={actions.downloadTemplate}>
                                                                    {locales.labels.downloadTemplate}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                {locales.labels.fillInTheForm}
                                                            </div>
                                                            <div className="form-group">
                                                                {locales.labels.numTwo}{locales.labels.colon}&nbsp;&nbsp;
                                                                {locales.labels.UploadExcelfile}
                                                            </div>
                                                        </div>
                                                    </Modal>
                                                    <Modal
                                                        visible={state.createWechatQaModalVisible}
                                                        title={
                                                            locales.pages.tasks.manage.template.checkPoint.index
                                                                .weChatNew
                                                        }
                                                        onCancel={actions.closeCreateModal}
                                                        onOk={actions.batchSave}
                                                        okButtonProps={{
                                                            disabled: !privates.selectedPointsId.length
                                                        }}
                                                        width={768}
                                                        confirmLoading={privates.createWechatQaModalConfirmLoading}
                                                        wrapClassName='check-point-index-create-modal'
                                                        maskClosable={false}
                                                    >
                                                        <Spin spinning={state.loading}>
                                                            <Space>
                                                                <div>
                                                                    {
                                                                        locales.pages.tasks.manage.template.checkPoint.index[state.hasPoint ? (privates.isWechatQa ? 'checkPointWeChat' : 'checkPoint') : (privates.isWechatQa ? 'wechatEmpty' : 'empty')]
                                                                    }
                                                                </div>
                                                                {
                                                                    state.hasPoint ? (
                                                                        <TreeSelect
                                                                            multiple
                                                                            treeCheckable
                                                                            treeDefaultExpandAll
                                                                            style={{ width: 655 }}
                                                                            value={privates.selectedPointsId}
                                                                            treeData={state.inspectionPointGroups}
                                                                            onChange={actions.onTreeSelect}
                                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                            treeNodeFilterProp='title'
                                                                        >
                                                                        </TreeSelect>
                                                                    ) : (
                                                                        <Button type='link' style={{ paddingLeft: 0 }} onClick={actions.goToWechatCheckPointSetting.bind(null, undefined)}>
                                                                            {
                                                                                locales.pages.tasks.manage.template.checkPoint.index[privates.isWechatQa ? 'createWeChat' : 'new']
                                                                            }
                                                                        </Button>
                                                                    )
                                                                }
                                                            </Space>
                                                        </Spin>
                                                    </Modal>
                                                </If>
                                                <If condition={hasFeature && hasFeature('task:template:point:edit') && state.classifications.length}>
                                                    <Modal
                                                        wrapClassName='check-point-index-tree-modal'
                                                        title={locales.pages.tasks.manage.template.checkPoint.index.move}
                                                        visible={privates.moveTreeVisible}
                                                        onOk={actions.moveCheckPoints}
                                                        confirmLoading={privates.confirmLoading}
                                                        onCancel={actions.openMoveTree.params(false)}
                                                    >
                                                        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                                            <DragDropContext onDragEnd={actions.onDragEnd}>
                                                                <Droppable droppableId="droppable" type="droppableItem">
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                                        >
                                                                            {privates.classTree.map((first, index) => (
                                                                                <Draggable
                                                                                    key={first.id}
                                                                                    draggableId={`${first.id}`}
                                                                                    index={index}
                                                                                >
                                                                                    {(provided, snapshot) => (
                                                                                        <div>
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                style={getFirstStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps.style
                                                                                                )}
                                                                                            >
                                                                                                <span
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={{
                                                                                                        display: "inline-block",
                                                                                                    }}
                                                                                                >{first.name}<Tag color='blue' style={{ marginLeft: 8, cursor: 'grab' }}>{locales.pages.tasks.manage.template.checkPoint.index.first}</Tag></span>
                                                                                                <Droppable droppableId={`${first.id}`} type={`droppableSubItem`}>
                                                                                                    {(provided, snapshot) => (
                                                                                                        <div
                                                                                                            ref={provided.innerRef}
                                                                                                            style={getListInnerStyle(snapshot.isDraggingOver)}
                                                                                                            className='check-point-index-tree-modal-first'
                                                                                                        >
                                                                                                            {first.children.map((second, index) => (
                                                                                                                <Draggable key={second.id} draggableId={`${second.id}`} index={index}>
                                                                                                                    {(provided, snapshot) => (
                                                                                                                        <div style={{ display: "flex" }}>
                                                                                                                            <div
                                                                                                                                ref={provided.innerRef}
                                                                                                                                {...provided.draggableProps}
                                                                                                                                style={getSecondStyle(
                                                                                                                                    snapshot.isDragging,
                                                                                                                                    provided.draggableProps.style
                                                                                                                                )}
                                                                                                                                className="check-point-index-tree-modal-item"
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    {...provided.dragHandleProps}
                                                                                                                                    style={{
                                                                                                                                        display: "block",
                                                                                                                                        paddingLeft: 16
                                                                                                                                    }}
                                                                                                                                >{second.name}<Tag color='geekblue' style={{ marginLeft: 8, cursor: 'grab' }}>{locales.pages.tasks.manage.template.checkPoint.index.second}</Tag></span>
                                                                                                                                <Droppable droppableId={`${second.id}`} type={`point`}>
                                                                                                                                    {(provided, snapshot) => (
                                                                                                                                        <div
                                                                                                                                            ref={provided.innerRef}
                                                                                                                                            style={getListInnerStyle(snapshot.isDraggingOver)}
                                                                                                                                            className="check-point-index-tree-modal-item"
                                                                                                                                        >
                                                                                                                                            {second.points.map((point, index) => (
                                                                                                                                                <Draggable
                                                                                                                                                    key={`${point.id}`}
                                                                                                                                                    draggableId={`${point.id}`}
                                                                                                                                                    index={index}
                                                                                                                                                >
                                                                                                                                                    {(provided, snapshot) => (
                                                                                                                                                        <div style={{ display: "flex" }}>
                                                                                                                                                            <div
                                                                                                                                                                ref={provided.innerRef}
                                                                                                                                                                {...provided.draggableProps}
                                                                                                                                                                style={getPointsStyle(
                                                                                                                                                                    snapshot.isDragging,
                                                                                                                                                                    provided.draggableProps.style
                                                                                                                                                                )}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    {...provided.dragHandleProps}
                                                                                                                                                                    style={{
                                                                                                                                                                        display: "block",
                                                                                                                                                                        paddingLeft: 32
                                                                                                                                                                    }}
                                                                                                                                                                    className="check-point-index-tree-modal-item"
                                                                                                                                                                >{point.name}</span>
                                                                                                                                                            </div>
                                                                                                                                                            {provided.placeholder}
                                                                                                                                                        </div>
                                                                                                                                                    )}
                                                                                                                                                </Draggable>
                                                                                                                                            ))}
                                                                                                                                            {provided.placeholder}
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                </Droppable>
                                                                                                                            </div>
                                                                                                                            {provided.placeholder}
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </Draggable>
                                                                                                            ))}
                                                                                                            {provided.placeholder}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Droppable>
                                                                                            </div>
                                                                                            {provided.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                        </div>
                                                    </Modal>
                                                </If>
                                            </div>
                                            <Collapse expandIcon={({ isActive }) => <Icon antdIcon={true} style={{ fontSize: '16px' }} type='CaretRightOutlined' rotate={isActive ? 90 : 0} />} className='tasks-manage-template-check-point-first-class-collapse' activeKey={state.firstClassActiveKey || []} onChange={actions.firstClassCollapseChange}>
                                                {
                                                    state.classifications.map(firstClass => {
                                                        let genExtraFirst = () => {
                                                            return (
                                                                <div>
                                                                    <Button size='small' type='link' onClick={actions.editFirstClassification.params(firstClass)}>
                                                                        {actions.iFApproveWrap(locales.labels.edit, locales.labels.view)}
                                                                    </Button>
                                                                    <If condition={!firstClass.isDefault}>
                                                                        <Popconfirm
                                                                            title={locales.labels.deleteContent}
                                                                            onConfirm={actions.deleteFirstClassification.params(firstClass)}
                                                                            onCancel={(e) => e.stopPropagation()}
                                                                            okText={locales.labels.confirm}
                                                                            placement="topRight"
                                                                            cancelText={locales.labels.cancel}
                                                                        >
                                                                            <Button size='small' type='link' onClick={(e) => e.stopPropagation()}>{locales.labels.delete}</Button>
                                                                        </Popconfirm>
                                                                    </If>
                                                                </div>
                                                            );
                                                        };
                                                        return (
                                                            // 一级分类
                                                            <Panel header={firstClass.name + " ( " + Udesk.business.scoreCompute.formatTotalScore(firstClass) + ' )'} key={firstClass.id} id={`${firstClass.id}`} extra={genExtraFirst()}>
                                                                <If condition={state.currentFirstClassification[firstClass.id] && state.currentFirstClassification[firstClass.id].isEdit}>
                                                                    {
                                                                        classificationDetail('first', state.currentFirstClassification, firstClass)
                                                                    }
                                                                </If>
                                                                <Collapse expandIcon={({ isActive }) => <Icon antdIcon={true} style={{ fontSize: '10px' }} type='RightOutlined' rotate={isActive ? 90 : 0} />} bordered={false} className='tasks-manage-template-check-point-second-class-collapse' onChange={actions.secondClassCollapseChange.params(firstClass.id)} activeKey={state.secondClassActiveKey ? state.secondClassActiveKey[firstClass.id] : []}>
                                                                    {
                                                                        firstClass.children && !!firstClass.children.length && firstClass.children.map(secondClass => {
                                                                            let genExtraSecond = () => {
                                                                                return (
                                                                                    <div>
                                                                                        <Button size='small' type='link' onClick={actions.editSecondClassification.params(secondClass)}
                                                                                        >
                                                                                            {actions.iFApproveWrap(locales.labels.edit, locales.labels.view)}
                                                                                        </Button>
                                                                                        <If condition={actions.iFApproveWrap(!secondClass.isDefault)}>
                                                                                            <Popconfirm
                                                                                                title={locales.labels.deleteContent}
                                                                                                onConfirm={actions.deleteSecondClassification.params(secondClass)}
                                                                                                onCancel={(e) => e.stopPropagation()}
                                                                                                okText={locales.labels.confirm}
                                                                                                placement="topRight"
                                                                                                cancelText={locales.labels.cancel}
                                                                                            >
                                                                                                <Button size='small' type='link' onClick={(e) => e.stopPropagation()}>{locales.labels.delete}</Button>
                                                                                            </Popconfirm>
                                                                                        </If>
                                                                                    </div>
                                                                                );
                                                                            };
                                                                            return (
                                                                                // 二级分类
                                                                                <Panel header={secondClass.name + " (" + Udesk.business.scoreCompute.formatTotalScore(secondClass) + ')'} key={secondClass.id} id={`${firstClass.id}-${secondClass.id}`} extra={genExtraSecond()}>
                                                                                    <If condition={state.currentSecondClassification[secondClass.id] && state.currentSecondClassification[secondClass.id].isEdit}>
                                                                                        {
                                                                                            classificationDetail('second', state.currentSecondClassification, secondClass)
                                                                                        }
                                                                                    </If>
                                                                                    {
                                                                                        // 质检点
                                                                                        secondClass.allPointDataList ?
                                                                                            <div style={{ padding: '0px 16px 16px' }}>
                                                                                                {
                                                                                                    secondClass.allPointDataList.length ?
                                                                                                        <Table size='small' pagination={false} columns={state.columns} dataSource={secondClass.allPointDataList}></Table>
                                                                                                        : <span style={{ color: 'rgba(0, 0, 0, 0.35)' }}>{locales.pages.tasks.manage.template.checkPoint.index[privates.isWechatQa ? 'wechatEmpty' : 'empty']}</span>
                                                                                                }
                                                                                                <If condition={actions.iFApproveWrap(hasFeature && hasFeature('task:template:point:add'))}>
                                                                                                    <Button type='link' style={{ paddingLeft: 0, paddingTop: 8 }} onClick={actions.showCreateModal.params(secondClass.id)}>
                                                                                                        {locales.pages.tasks.manage.template.checkPoint.index[privates.isWechatQa ? 'weChatNew' : 'new']}
                                                                                                    </Button>
                                                                                                </If>
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                    {/* {
                                                                                        // 质检点
                                                                                        secondClass.allPointDataList ?
                                                                                            secondClass.allPointDataList.length ?
                                                                                                <React.Fragment>
                                                                                                    <div style={{ padding: '0px 16px 16px' }}>
                                                                                                        <Table size='small' pagination={false} columns={state.columns} dataSource={secondClass.allPointDataList}></Table>
                                                                                                        <If condition={hasFeature && hasFeature('task:template:point:add')}>
                                                                                                            <Button type='link' style={{ paddingLeft: 0, paddingTop: 8 }} onClick={actions.showCreateModal.params(secondClass.id)}>
                                                                                                                {locales.pages.tasks.manage.template.checkPoint.index.new}
                                                                                                            </Button>
                                                                                                        </If>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                                :
                                                                                                <React.Fragment>
                                                                                                    <div style={{ padding: '0px 16px 16px', color: 'rgba(0, 0, 0, 0.35)' }}>
                                                                                                        {locales.pages.tasks.manage.template.checkPoint.index.empty}
                                                                                                        <If condition={hasFeature && hasFeature('task:template:point:add')}>
                                                                                                            <Button type='link' style={{ paddingLeft: 0, paddingTop: 8 }} onClick={actions.showCreateModal.params(secondClass.id)}>
                                                                                                                {locales.pages.tasks.manage.template.checkPoint.index.new}
                                                                                                            </Button>
                                                                                                        </If>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            : null
                                                                                    } */}
                                                                                </Panel>
                                                                            );
                                                                        })
                                                                    }
                                                                    {
                                                                        actions.iFApproveWrap(
                                                                            <Panel 
                                                                                style={{ borderBottom: 'none' }} 
                                                                                header={
                                                                                    <div style={{ color: '#1B6DFF', fontWeight: 400, paddingLeft: 4 }} 
                                                                                        onClick={actions.addClassification.params('second', firstClass)}
                                                                                    >
                                                                                        <Icon style={{ marginRight: 6 }} type='tianjia1'></Icon>
                                                                                        {locales.pages.tasks.manage.template.checkPoint.index.newSecond}
                                                                                    </div>
                                                                                } 
                                                                                key={`addSecond${firstClass.id}`} 
                                                                                showArrow={false}
                                                                            >
                                                                                {
                                                                                    classificationDetail('second', state.currentSecondClassification, firstClass)
                                                                                }
                                                                            </Panel>
                                                                        )
                                                                    }
                                                  
                                                                </Collapse>
                                                            </Panel>
                                                        );
                                                    })
                                                }
                                                {
                                                    actions.iFApproveWrap(
                                                        privates.isWechatQa || 
                                                        <Panel header={
                                                            <div style={{ paddingLeft: 4, color: '#1B6DFF', fontWeight: 400 }}
                                                                onClick={actions.addClassification.params('first', 'new')}
                                                            >
                                                                <Icon style={{ marginRight: 6 }} type='tianjia1'></Icon>
                                                                {locales.pages.tasks.manage.template.checkPoint.index.newFirst}
                                                            </div>
                                                        } 
                                                        key='addFirst' 
                                                        showArrow={false}
                                                        >
                                                            {
                                                                classificationDetail('first', state.currentFirstClassification, 'new')
                                                            }
                                                        </Panel>
                                                    )
                                                }

                                            </Collapse>
                                        </div>
                                    </div>
                                </If>
                            </div>
                            <If
                                condition={
                                    privates.model.status.processStatus === 2
                                    && hasFeature && hasFeature('task:template:point:list')
                                    && hasFeature('task:template:point:add')
                                    && !privates.isWechatQa
                                }
                            >
                                <div className='tasks-manage-template-basic-info-index-footer' style={{ padding: 16 }}>
                                    <Button type="primary" onClick={actions.handleNext}>
                                        {locales.pages.tasks.manage.template.basicInfo.index.next}
                                    </Button>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
                <If condition={!this.privates.model.taskVersion.pointVersion}>
                    <TaskConditionDictionaries items={privates.model.inspectionConditions} />
                </If>
            </React.Fragment>
        );
    }
}
