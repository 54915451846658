import React, { useState, useMemo, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Sticker from '../audio-stickers';
import AudioControl from '../audio-control';
import Player from '../audio-player-bar';
// import { Space } from 'udesk-ui';
import './style.scss';
import { postOperatorLogsSaveStudyLog } from 'src/api/operatorLogs/saveStudyLog';

const locales = Locales['current'];
interface Times {
    startPoint: number;
    endPoint: number;
}

interface RoleTimes {
    type: 'agent' | 'customer';
    voiceLength: number;
    times: Times[];
}

const InternalWaveAudio = React.memo((props: any) => {
    // const locales = Locales['current'];
    const {
        detail,
        onSelectTime,
        audioInstance,
        disabled,
        currentTime,
        isPlaying,
        changeAudioPlayingStatus,
        sessioLabel,
        // keyEvent,
        setCurrentTime,
        // onKeyEventClick,
    } = props;
    const chatColor = '#0FBA26'; //  闲聊
    const defaultColor = '#F09A00'; //  默认
    const explainColor = '#1A6EFF'; //  说明
    const scienceColor = '#DE6FF5'; //  科普
    const introduceColor = '#1C8AF0'; //  介绍
    const serviceColor = '#FF7A52'; //  咨询
    const otherColor = '#A26FF5'; //  其他
    const topicDescColor = {
        '-1': chatColor, //  闲聊
        '0': explainColor, //  产品效果说明
        '4': explainColor, //  产品价格说明
        '5': explainColor, //  产品权威性说明
        '6': serviceColor, //  病症情况咨询
        '7': introduceColor, //  产品介绍
        '8': introduceColor, //  产品活动介绍
        '9': scienceColor, //  病症科普
        '10': introduceColor, //  产品介绍
        '11': explainColor, //  产品价格说明
        '15': otherColor, //  稍后联系
        '16': serviceColor, //  病症情况咨询
        '19': introduceColor, //  产品使用方法介绍
        '21': serviceColor, // 客户使用状态回访
        '22': explainColor, // 客户需求场景沟通
        '23': introduceColor, // 欠费问题沟通
        '24': otherColor, //  续约问题沟通
        '25': scienceColor, // 不在续约原因咨询
        '26': scienceColor, //  数据报表问题沟通
        '27': chatColor, //  后续联系
        '28': serviceColor, //  到期关停问题沟通
        '30': serviceColor, //  就职状态
        '31': explainColor, //  离职/换岗原因
        '32': introduceColor, //  学历信息
        '33': otherColor, //  工作经历
        '34': scienceColor, //  目前薪酬构成及预期
        '35': explainColor, //  籍贯信息
        '36': otherColor, //  婚育情况
        '37': serviceColor, //  住址及通勤情况
        '38': explainColor, //  公司及面试流程介绍
        '39': introduceColor, //  面试后反馈跟进
        '40': otherColor, //  面试意向沟通
        '41': scienceColor, //  拒绝offer原因
        '42': explainColor, //  意向公司及offer情况
        '43': serviceColor, //  整理挽留方案
        '44': explainColor, //  引导沟通
    };

    const [hitPoints, setHitPoints] = useState<any[]>([]);
    const [volume, setVolume] = useState<number>(0.5);
    const [playbackRate, setPlaybackRate] = useState<number>(1);
    const waveAudioRef = useRef(null);
    const [mouseId, setMouseId] = useState<any>([]);
    const audioControlRef = useRef<any>();
    useEffect(() => {
        if (!detail) return;
        setHitPoints(
            (detail.highlightItemList || []).map((i) => {
                return {
                    index: i.index,
                    ...detail.dialogSentenceList[i.index - 1],
                };
            })
        );
    }, [detail]);

    const audioStickers = useMemo(() => {
        if (!detail?.dialogSentenceList && !detail?.voiceLength) {
            return [];
        }

        let agentTimes: RoleTimes = { type: 'agent', voiceLength: detail.voiceLength, times: [] };
        let customerTimes: RoleTimes = { type: 'customer', voiceLength: detail.voiceLength, times: [] };
        detail.dialogSentenceList.forEach((item) => {
            if (item.role === 'agent') {
                agentTimes.times.push({
                    startPoint: item.fromSec,
                    endPoint: item.endSec,
                });
            } else {
                customerTimes.times.push({
                    startPoint: item.fromSec,
                    endPoint: item.endSec,
                });
            }
        });
        return [agentTimes, customerTimes];
    }, [detail?.dialogSentenceList, detail?.voiceLength]);

    const onTimeChange = (v) => {
        if (!audioInstance) return;

        audioInstance.pause();
        setCurrentTime?.(v);
        // audioInstance.currentTime = v;
        // audioInstance.play();
    };
    const onDownload = () => {
        let src = detail.voiceUrl;
        if (src) {
            window.location = src;
        }
    };
    const changeVolume = (value) => {
        setVolume(value);
    };
    const onPlaybackRateChange = (value) => {
        setPlaybackRate(value * 1);
    };
    const handlerMouseClick = (idx) => {
        if (!audioInstance.duration) {
            Udesk.ui.notify.error(locales.components.gong.waveAudio.index.theFileCannotBeFound);
            return;
        }
        let mouseIdArr: any = [...mouseId];
        if (mouseIdArr.indexOf(idx) < 0) {
            // mouseIdArr.push(idx);    // 多选
            mouseIdArr = [idx]; // 单选
        } else {
            mouseIdArr = mouseIdArr.filter((item) => item !== idx);
        }
        // 适用单选
        if (mouseIdArr.length) {
            let currentIndex = sessioLabel[mouseIdArr[0]].startIndex;
            setCurrentTime(detail?.dialogSentenceList[currentIndex]?.fromSec);
        }
        mouseIdArr = mouseIdArr.sort((a, b) => a - b);
        setMouseId(mouseIdArr);
        audioControlRef?.current?.handlerTagsShow(mouseIdArr, topicDescColor, defaultColor);
    };
    useEffect(() => {
        if (audioInstance) {
            audioInstance.volume = volume;
        }
    }, [audioInstance, volume]);
    useEffect(() => {
        if (audioInstance) {
            audioInstance.playbackRate = playbackRate;
        }
    }, [audioInstance, playbackRate]);
    useEffect(() => {
        return () => {
            audioInstance?.pause?.();
        };
    }, [audioInstance]);
    return (
        <div className='wave-audio-component-talk-v3' ref={waveAudioRef}>
            {/* chanelType： 0 电话  1 企业会话 2 企业语音通话 */}
            {!detail?.insightInfo?.chanelType ? (
                <React.Fragment>
                    <Player
                        onDownload={onDownload}
                        changeAudioPlayingStatus={changeAudioPlayingStatus}
                        isPlaying={isPlaying}
                        duration={audioInstance?.duration ?? 1}
                        currentTime={currentTime}
                        onVolumeChange={changeVolume}
                        volume={volume}
                        playbackRate={playbackRate}
                        onPlaybackRateChange={onPlaybackRateChange}
                    />
                    <AudioControl
                        duration={audioInstance?.duration ?? 1}
                        currentTime={currentTime}
                        onTimeChange={onTimeChange}
                        hitPoints={hitPoints}
                        disabled={disabled}
                        onSelectTime={onSelectTime}
                        sessioLabel={sessioLabel}
                        detail={detail}
                        cRef={audioControlRef}
                    />
                </React.Fragment>
            ) : null}

            {audioStickers.map((item, index) => {
                return (
                    <div style={{ height: 30, marginBottom: index === 0 ? 8 : 0, zIndex: 1, position: 'relative' }}>
                        <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                            {!index
                                ? locales.components.gong.waveAudio.index.sale
                                : locales.components.gong.waveAudio.index.customer}
                        </div>
                        <Sticker {...item} />
                    </div>
                );
            })}
            {Boolean(sessioLabel.length) && (
                <div className='call-detail-wave-audio-player-tips'>
                    <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                        {locales.components.gong.waveAudio.index.topicDetection}
                    </div>
                    <div style={{ overflowX: 'hidden', flex: 1 }}>
                        <div style={{ display: 'flex', overflowX: 'auto', width: '100%' }}>
                            {sessioLabel.map((item, idx) => {
                                return (
                                    <span
                                        onClick={handlerMouseClick.bind(null, idx)}
                                        className={mouseId.includes(idx) ? 'active' : ''}
                                        key={item.id}
                                        style={
                                            mouseId.includes(idx)
                                                ? {
                                                    whiteSpace: 'nowrap',
                                                    background: topicDescColor[item.topicTagType] || defaultColor,
                                                    color: '#fff',
                                                    border: `1px solid ${
                                                        topicDescColor[item.topicTagType] || defaultColor
                                                    }`,
                                                }
                                                : {
                                                    whiteSpace: 'nowrap',
                                                    background:
                                                          (topicDescColor[item.topicTagType] || defaultColor) + '1a',
                                                    color: topicDescColor[item.topicTagType] || defaultColor,
                                                    border: `1px solid ${
                                                        topicDescColor[item.topicTagType] || defaultColor
                                                    }`,
                                                }
                                        }
                                    >
                                        {item.topicTagName || '--'}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
            {/* {Boolean(keyEvent.length) && (
                <div className='call-detail-wave-audio-player-tips-events'>                    
                    <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                        {locales.components.gong.waveAudio.index.keyEvents}
                    </div>
                    <div style={{ overflowX: 'hidden', flex: 1 }}>
                        <div style={{ display: 'flex', overflowX: 'auto', width: '100%' }}>
                            {keyEvent.map((item, idx) => {
                                return (
                                    <span
                                        style={{
                                            background: item.categoryColor + '1a',
                                            color: item.categoryColor,
                                            border: `1px solid ${item.categoryColor}`,
                                            whiteSpace: 'nowrap',
                                        }}
                                        key={item.id}
                                        onClick={onKeyEventClick.bind(null, item.sentencesIndices?.[0])}
                                    >
                                        {item.categoryName} | {item.tagName}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    );
});

// class Component extends React.Component {
//     render() {
//         return <Template {...this.props} />;
//     }
// }

const useAudio = (voiceUrl, defaultStartTime?, defaultEndTime?, callId?) => {
    const [audioInstance, setAudioInstance] = useState<any>(null);
    const [canPlay, setCanPlay] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    // const [autoStopTime, setAutoStopTime] = useState<number | null>(null);
    const autoStopTime = useRef<number | null>(null);

    useEffect(() => {
        if (
            audioInstance &&
            isPlaying &&
            typeof autoStopTime.current === 'number' &&
            currentTime >= autoStopTime.current
        ) {
            audioInstance?.pause?.();
            autoStopTime.current = null;
        }
    }, [currentTime, isPlaying, audioInstance]);
    useEffect(() => {
        if (!voiceUrl) {
            setAudioInstance(new Audio());
            return;
        }
        const audio = new Audio(normalizeAudioSrc(voiceUrl));

        audio.addEventListener('canplaythrough', (event) => {
            /* 音频可以播放；如果权限允许则播放 */
            // setDuration?.(audio.duration);
            setCanPlay(true);
            // audio.play();
        });
        audio.onpause = (event) => {
            setIsPlaying(false);
        };
        audio.onplay = (event) => {
            setIsPlaying(true);
        };
        audio.ontimeupdate = function (event) {
            setCurrentTime(audio.currentTime);
            if (defaultEndTime && audio.currentTime >= Number(defaultEndTime)) {
                audio.pause();
            }
        };

        setAudioInstance(audio);
    }, [voiceUrl]);
    useEffect(() => {
        setCurrentTime(Number(defaultStartTime ?? 0));
    }, [defaultStartTime]);
    const studyLogData = () => {
        postOperatorLogsSaveStudyLog({ callId }).then((res) => {});
    };
    const changeAudioPlayingStatus = (currentTime) => {
        audioInstance.currentTime = currentTime;
        if (defaultStartTime && currentTime < Number(defaultStartTime)) {
            audioInstance.currentTime = Number(defaultStartTime ?? 0);
        }
        isPlaying ? audioInstance?.pause?.() : audioInstance?.play?.();
        !isPlaying && studyLogData();
    };
    const startPlay = (playingStatus, endTime) => {
        playingStatus ? audioInstance?.play?.() : audioInstance?.pause?.();
        if (typeof endTime === 'number') {
            autoStopTime.current = endTime;
        }
    };
    const setCurrentTimeFuc = (currentTime) => {
        audioInstance.currentTime = currentTime;
        autoStopTime.current = null;
        isPlaying ? audioInstance?.play?.() : audioInstance?.pause?.();
    };

    return {
        audioInstance,
        canPlay,
        currentTime,
        isPlaying,
        changeAudioPlayingStatus,
        startPlay,
        setCurrentTime: setCurrentTimeFuc,
    };
};

// WaveAudio.useAudio = useAudio;

type InternalWaveAudioType = typeof InternalWaveAudio;

interface WaveAudioInterface extends InternalWaveAudioType {
    useAudio: typeof useAudio;
}

const WaveAudio = React.memo(InternalWaveAudio) as any as WaveAudioInterface;

WaveAudio.useAudio = useAudio;

export default WaveAudio;

function normalizeAudioSrc(src) {
    if (src == null) {
        return src;
    }
    const protocolRegExp = /^http:\/\/(.+?)$/i;
    if (window.location.protocol === 'https:' && protocolRegExp.test(src)) {
        src = src.replace(protocolRegExp, 'https://$1');
    }
    let domainRegExp = /^(http[s]?):\/\/ccrecords\.alioss\.udesk\.cn(.+?)$/i;
    if (domainRegExp.test(src)) {
        src = src.replace(domainRegExp, '$1://pro-ccrecords.oss-cn-hangzhou.aliyuncs.com$2');
    }
    return src;
}
