import Udesk from 'Udesk';
import React from 'react';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import { getCurrentRouteName } from 'Udesk/system/subApp';

class dataAnalysis extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let { route, location, sdkOptions } = this.props;

        let { locales } = this;

        let navLists = [];
        // 根据当前用户权限，组装菜单列表
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature("smart:discovery:root")) {
            navLists.push({
                text: locales.components.pages.sentimentAnalysis.title,
                pathName: "conversationAnalysisSentimentAnalysis",
                isActive: (match, location) => {
                    return location.pathname.includes("/public-sentiment");
                }
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature("smart:analysis:root")) {
            navLists.push({
                text: locales.components.pages.businessAnalysis.title,
                pathName: getCurrentRouteName("businessAnalysisList"),
                isActive: (match, location) => {
                    return location.pathname.includes("/business-analysis");
                }
            });
        }
        
        navLists.push({
            text: /* 分析报表 */locales.pages.intelligentAnalysis.analysisReportTitle,
            pathName: getCurrentRouteName("analysisReport"),
            isActive: (match, location) => {
                return location.pathname.includes("/analysis-report");
            }
        });

        navLists.push({
            text: locales.components.pages.dataAnalysis.intelligentTagsAnalysis,
            pathName: getCurrentRouteName('intelligentTagsAnalysis'),
            isActive: (match, location) => {
                return location.pathname.includes('/intelligent-tags-analysis');
            },
        });
        if (sdkOptions.props.landingPath != null && this.isRedirect(route.path, location.pathname)) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].pathName,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className='semantic-intelligence'>
                    <LayoutMenu
                        {...this.props}
                        navLists={navLists}
                        menuTitle={locales.components.pages.menuTitleList.informationTitle}
                    />
                    {/* <div className="semantic-intelligence-nav col-md-2 col-sm-3 col-xs-12">
                        <NavBar navLists={navLists} classNames="semantic-intelligence-nav-container" navItemClassNames="semantic-intelligence-nav-item" activeClassNames="semantic-intelligence-nav-item-active" />
                    </div>
                    <div className="semantic-intelligence-content col-md-10 col-sm-9 col-xs-12">
                        <For each="route" index="i" of={routes}>
                            <If condition={!route.authCode || (route.authCode && Udesk.data.init.user.hasFeature(route.authCode))}>
                                <SubRoutes key={i} route={route} />
                            </If>
                        </For>
                    </div> */}
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(dataAnalysis);
