import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Space } from 'udesk-ui';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import * as echarts from 'echarts';
import useResizeObserver from 'use-resize-observer';
import { useSummary } from '../hook/useSummary';
import { useUdeskDatePicker } from 'Component/common/udesk-date-picker/template';
import UdeskLocales from 'UdeskLocales';

type PracticeSummaryProps = {
    isAdmin?: boolean;
};

const PracticeSummaryWrap = styled.div`
    background-color: #fff;
    padding: 0 16px 16px;

    .udsk-qa-web-practice-summary-title {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #d9d9d9;
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }
    .udesk-qa-ui-space {
        width: 100%;
        .udesk-qa-ui-space-item {
            flex: 1;
        }
    }
    .echarts-wrap {
        margin-top: 20px;
        height: ${(props) => (props.isAdmin ? '570px' : '382px')};
    }
`;

export const PracticeSummary: React.FC<PracticeSummaryProps> = (props) => {
    const domInstance = useRef(null);
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

    const [echartInstance, setEchartInstance] = useState<any>(null);
    const { startTime, endTime, ...datePickerProps } = useUdeskDatePicker();
    const { taskSummary, taskSummaryEcharts } = useSummary(startTime, endTime, props.isAdmin);
    useEffect(() => {
        const c = echarts.init(domInstance.current);
        setEchartInstance(c);
    }, []);
    useEffect(() => {
        if (echartInstance && taskSummaryEcharts) {
            echartInstance.setOption(taskSummaryEcharts);
        }
    }, [echartInstance, taskSummaryEcharts]);
    useEffect(() => {
        requestIdleCallback(() => {
            echartInstance?.resize?.();
        });
    }, [width, height, echartInstance]);
    return (
        <PracticeSummaryWrap
            className="udsk-qa-web-practice-summary-wrap"
            ref={ref}
            isAdmin={props.isAdmin}
        >
            <div className="udsk-qa-web-practice-summary-title">
                <div>{/* 练习小结 */}{UdeskLocales['current'].pages.coach.dashboard.components.practiceSummary.practiceSummary}</div>
                <UdeskDatePicker {...datePickerProps} />
            </div>
            {!props.isAdmin && (
                <Space>
                    <DataCard title={/* 练习任务数 */UdeskLocales['current'].pages.coach.dashboard.components.practiceSummary.numberOfPracticeTasks} value={taskSummary?.taskCount} />
                    <DataCard title={/* 练习次数 */UdeskLocales['current'].pages.coach.dashboard.components.practiceSummary.numberOfExercises} value={taskSummary?.practiceTimes} />
                    <DataCard
                        title={/* 累计练习时长 */UdeskLocales['current'].pages.coach.dashboard.components.practiceSummary.accumulatedPracticeDuration}
                        value={getTotalTime(taskSummary?.practiceCostTime)}
                        rank={`${taskSummary?.costTimeRank ?? '-'}/${
                            taskSummary?.studentCount ?? '-'
                        }`}
                    />
                    <DataCard
                        title={/* 达标率 */UdeskLocales['current'].pages.coach.dashboard.components.practiceSummary.complianceRate}
                        value={getRate(taskSummary?.commitRate)}
                        rank={`${taskSummary?.commitRank ?? '-'}/${
                            taskSummary?.studentCount ?? '-'
                        }`}
                    />
                    <DataCard
                        title={/* 达优率 */UdeskLocales['current'].pages.coach.dashboard.components.practiceSummary.excellentRate}
                        value={getRate(taskSummary?.excellentRate)}
                        rank={`${taskSummary?.excellentRank ?? '-'}/${
                            taskSummary?.studentCount ?? '-'
                        }`}
                    />
                </Space>
            )}
            <div className="echarts-wrap" ref={domInstance}></div>
        </PracticeSummaryWrap>
    );
};

const DataCardWrap = styled.div`
    height: 118px;
    background: #f5f8fe;
    border-radius: 4px;
    padding: 12px 16px;
    position: relative;

    .data-card-title-wrap {
        display: flex;
        justify-content: space-between;
        .data-card-title {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            position: relative;
            margin-left: 10px;
            flex: 1;
            width: 0;

            > div {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &::before {
                content: ' ';
                width: 4px;
                height: 12px;
                background: #1a6eff;
                border-radius: 4px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -10px;
            }
        }
    }
    .data-card-rank {
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
        span {
            color: #1a6eff;
            margin-left: 4px;
        }
    }
    .data-card-content {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #1a6eff;
        text-align: center;
        /* margin-top: 16px; */
        position: absolute;
        left: 50%;
        transform: translate(-50%, -30%);
        top: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
`;

function DataCard(props) {
    return (
        <DataCardWrap className="data-card-wrap">
            <div className="data-card-title-wrap">
                <div className="data-card-title" title={props.title}>
                    <div>{props.title}</div>
                </div>
                {props.rank && (
                    <div className="data-card-rank">{/* 排行 */}{UdeskLocales['current'].pages.coach.dashboard.components.practiceSummary.ranking}<span>{props.rank}</span>
                    </div>
                )}
            </div>
            <div className="data-card-content">{props.value ?? '-'}</div>
        </DataCardWrap>
    );
}
function getTotalTime(practiceCostTime) {
    if (typeof practiceCostTime !== 'number') return '-';
    const min = practiceCostTime / 60;
    const hour = min / 60;
    if (hour > 1) return `${hour.toFixed(2)}h`;
    if (min > 1) return `${min.toFixed(2)}min`;
    return `${practiceCostTime}s`;
}
function getRate(rate) {
    if (typeof rate !== 'number') return '-';
    return `${rate.toFixed(2)}%`;
}
