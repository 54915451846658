import React, { useCallback, useState } from "react";
import Udesk from 'Udesk';
import { postIntelligentPartnerTtsTest } from "src/api/intelligentPartner/tts/test";
import { getReviewIntelligentPartnerDialogFlowConfig } from "src/api/review/intelligentPartner/dialogFlow/config";
import { useLiveEffect } from "src/util/hook";
import { Button, Icon, Input, Space } from "udesk-ui";
import { useAudioPlay } from "src/pages/coach/learning-center/components/record/hook";
import { RobotTimbreConfig } from "src/api/types";
import { DefaultConfig } from "./RobotTimbreForm";

export default React.memo((props: any) => {
    const {value, onChange, flowId, ...textareaProps} = props;
    const [robotTimbreConfig, setRobotTimbreConfig] = useState<RobotTimbreConfig>();
    const [audioRef, audioPlayHandle] = useAudioPlay();

    const play = useCallback(() => {
        if (value) {
            const config = robotTimbreConfig || DefaultConfig;
            postIntelligentPartnerTtsTest({
                ...config, text: value,
            }).then(
                resp => {
                    audioPlayHandle([resp.data]);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [audioPlayHandle, robotTimbreConfig, value]);

    useLiveEffect((success) => {
        if (!flowId) return;
        getReviewIntelligentPartnerDialogFlowConfig({
            params: {
                flowId,
            },
        }).then((resp) => {
            success(() => {
                setRobotTimbreConfig(resp?.data?.robotTimbreConfig);
            });
        });
    }, [
        flowId, 
    ]);

    return (
        <Space direction='vertical' style={{ display: 'flex'}}>
            <Input.TextArea value={value} onChange={onChange} {...textareaProps}/>
            <Button
                style={{float: 'right'}}
                className='udesk-qa-audition-button' 
                onClick={play} 
                type="primary" 
                icon={<Icon type='SoundFilled' antdIcon={true} />} 
            />
            <audio 
                className='hidden' 
                ref={audioRef} 
                controls 
                autoPlay={false} />
        </Space>
    );
});