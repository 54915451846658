import React, { useState, useMemo, useRef } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Button, Icon } from 'udesk-ui';
import type { ButtonProps } from 'udesk-ui/es/button';
// import MagicString from 'magic-string';
// import { EditorState, ContentState } from 'draft-js';
// import Editor from '@draft-js-plugins/editor';
// import createMentionPlugin from '@draft-js-plugins/mention';
// import { MentionData } from '@draft-js-plugins/mention';
// import '@draft-js-plugins/mention/lib/plugin.css';
import ReactQuill from 'react-quill';
import 'quill-mention';
// import 'react-quill/dist/quill.core.css';
import './style.scss';
import 'quill-mention/dist/quill.mention.min.css';
import 'react-quill/dist/quill.core.css';
// const AUTO_SIZE = { minRows: 3, maxRows: 6 };

export interface CommentReplyProps {
    publishBtnText?: string; //发布按钮的文案
    publishBtnProps?: Omit<ButtonProps, 'onClick'>; //发布按钮的Props
    defaultValue?: string; //TextArea的初始值
    onPublish?: (value: string, atList: any[], sourceCommentId?: number, replyCommentId?: number, newTopics?: any[]) => Promise<any>; //点击发布按钮时的回调
    onPublishSuccess?: (rootCommentId?: number) => void; //发布成功时的回调
    userList?: any[]; //可以被圈的人的列表
    topicsList?: any[]; //话题列表
    hiddenAvatar?: boolean; //隐藏头像
    placeholder?: string; //文本域的placeHolder
    autoSize?: { minRows: number; maxRows: number }; //文本域的autoSize
    currentTime?: number | null;
    clearCurrentTime?: () => void; //清空选择时间
    sourceCommentId?: number; //根评论的id
    replyCommentId?: number; //回复的评论、回复的id
    wrapperClassName?: string; // 组件外层的类名
    editorWrapperClassName?: string; // 富文本外层的类名
}

const topicsListDefault = [];

const Template = React.memo((props: CommentReplyProps) => {
    const locales = Locales['current'];
    const {
        publishBtnText = locales.labels.release,
        publishBtnProps = {},
        // defaultValue = '',
        wrapperClassName,
        editorWrapperClassName,
        onPublish,
        userList,
        hiddenAvatar = false,
        placeholder = '',
        // autoSize = AUTO_SIZE,
        currentTime,
        onPublishSuccess,
        clearCurrentTime,
        sourceCommentId,
        replyCommentId,
        topicsList = topicsListDefault,
    } = props;
    const textAreaRef = useRef<HTMLDivElement | null>(null);
    const ref = useRef<any>(null);
    const [editorState, setEditorState] = useState('');
    const [boxStyles] = useState<React.CSSProperties>({
        flex: 1,
    });

    const onPublishBtnClick = () => {
        const atUsers = Array.from(textAreaRef.current?.getElementsByClassName('mention') || [])
            .filter((dom: any) => dom.dataset.denotationChar === '@')
            .map((dom: any) => userList?.find((user) => user.id === parseInt(dom.dataset.id, 10)));
        if (!textAreaRef.current?.innerText) return;
        // const topics = Array.from(textAreaRef.current?.getElementsByClassName('mention') || [])
        //     .filter((dom: any) => dom.dataset.denotationChar === '#');
        const content = textAreaRef.current?.innerText ?? '';
        const newTopics = content.match(/#(.*?)#/g);
            
        onPublish?.(content, atUsers, sourceCommentId, replyCommentId, newTopics || []).then(() => {
            setEditorState('');
            onPublishSuccess?.(sourceCommentId);
        });
    };
    const userListFormatted = useMemo(() => {
        return userList?.map((user) => ({ id: user.value, value: user.label, email: user.email })) ?? [];
    }, [userList]);
    const modules = useMemo(() => {
        return {
            mention: {
                allowedChars: /^[A-Za-z\sÅÄÖåäö\u4e00-\u9fa5]*$/,
                mentionDenotationChars: ['@', '#'],
                // showDenotationChar: false,
                source: function (searchTerm, renderList, mentionChar) {                    
                    if (mentionChar === '@') {
                        renderList(
                            userListFormatted.filter((i) => i.value.includes(searchTerm) || i.email.includes(searchTerm)),
                            searchTerm
                        );
                        // ref.current.getEditor().getModule('mention').openMenu('@');
                    } else if (mentionChar === '#') {
                        const hitedTopicsList = (topicsList ?? []).filter((i) => i?.value.includes(searchTerm));
                        renderList(
                            [{value: searchTerm, id: 'unCommitedTopic'}, ...hitedTopicsList],
                            searchTerm
                        );
                    }
                },
                onSelect: (item, insertItem) => {
                    insertItem({
                        ...item,
                        value: item.denotationChar === '@' ? item.value : item.value + '#',
                    });
                },
            },
        };
    }, [userListFormatted, topicsList]);
    return (
        <div className={`comment-reply-component ${wrapperClassName ?? ''}`}>
            <div style={{ display: 'flex', flex: 1, overflowY: 'auto' }}>
                {hiddenAvatar || (
                    <div className='avatar'>
                        <img src={Udesk.data.init.user.headPortraitUrl} alt='' />
                    </div>
                )}
                <div style={boxStyles} className={editorWrapperClassName}>
                    <div
                        // className={editorStyles.editor}
                        ref={textAreaRef}
                        style={{ height: '100%' }}
                        onClick={() => {
                            ref.current!.focus();
                        }}
                    >
                        <div onClick={(e) => e.stopPropagation()}>
                            <ReactQuill
                                theme={null as any}
                                value={editorState}
                                onChange={setEditorState}
                                placeholder={placeholder}
                                ref={ref}
                                modules={modules}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8 }}>
                <div>
                    {typeof currentTime === 'number' && (
                        <div className='currentTime-button'>
                            {Udesk.utils.date.formatDuration(currentTime)}
                            {typeof clearCurrentTime === 'function' && (
                                <Button
                                    onClick={clearCurrentTime}
                                    size='small'
                                    type='text'
                                    icon={<Icon type='CloseOutlined' antdIcon={true} />}
                                ></Button>
                            )}
                        </div>
                    )}
                </div>
                <Button type='primary' shape='round' size='small' {...publishBtnProps} onClick={onPublishBtnClick}>
                    {publishBtnText}
                </Button>
            </div>
        </div>
    );
});

export default React.memo(Template);
