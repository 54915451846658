import React, { useCallback } from 'react';
import Udesk from 'Udesk';
import WaveAudioComponent from './component';
import AudioStickers from '../../pages/components/audio-stickers-talk-v2';
import { Divider, Icon, Dropdown, Space, Button, Modal, Slider } from 'udesk-ui';
import './style.scss';
import Locales from 'UdeskLocales';

const locales = Locales['current'];

const { confirm } = Modal;

const playBtnStyle = {
    color: '#1A6EFF',
};

const DownloadMenu = ({ downloadAudio, exportTxt /* exportWord */ }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}>
            {downloadAudio && (
                <Button type='text' onClick={downloadAudio}>
                    {locales.fix.voiceDownLoad}
                </Button>
            )}
            {exportTxt && (
                <Button type='text' onClick={exportTxt}>
                    {locales.fix.exportTxt}
                </Button>
            )}
            {/* {exportWord && (
                <Button type='text' onClick={exportWord}>
                    {locales.fix.exportWord}
                </Button>
            )} */}
        </div>
    );
};

const DownloadAstText = ({ downloadAudio, exportTxt /* exportWord */ }) => {
    return (
        <Dropdown
            overlay={<DownloadMenu downloadAudio={downloadAudio} exportTxt={exportTxt} />}
            placement='bottomCenter'
        >
            <Button style={playBtnStyle} size='small' type='text' icon={<Icon type='xiazai'></Icon>}>
                {locales.labels.download}
            </Button>
        </Dropdown>
    );
};

const modalCallback = (callback, title) => {
    return () => {
        confirm({
            title: title,
            onOk: callback,
        });
    };
};

const SettingMenu = ({ reverse, recover, reAsr, isRelated }) => {
    const reverseCallback = useCallback(modalCallback(reverse, locales.fix.sureReRole), [reverse]);
    const recoverCallback = useCallback(modalCallback(recover, locales.fix.sureRecover), [recover]);
    const reAsrCallback = useCallback(modalCallback(reAsr, locales.fix.sureReAsr), [reAsr]);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}>
            {!isRelated && (
                <Button type='text' onClick={reverseCallback}>
                    {locales.fix.reRole}
                </Button>
            )}
            <Button type='text' onClick={recoverCallback}>
                {locales.fix.recover}
            </Button>
            {reAsr && (
                <Button type='text' onClick={reAsrCallback}>
                    {locales.fix.reAsr}
                </Button>
            )}
        </div>
    );
};
export default class WaveAudioTemplate extends WaveAudioComponent {
    render() {
        let { locales } = this;
        let {
            /* rates, */ isShowEmotionButton,
            detail = {},
            agentFlag = true,
            isRelated = false,
            isAppeal = false,
        } = this.props;
        let { isShowPlayButton, /* isShowRateOptions, */ duration, currentTime, playbackRate, volume } = this.state;
        let { /* limitTimeOfWave, */ /* noWave, */ /* noWaveCursorPosition, */ speedMenu } = this.privates;
        return (
            <div className='wave-audio-component-talk-v2' ref={this.privates.waveAudioRef}>
                <AudioStickers
                    dialogSentenceList={detail?.dialogRecords?.dialogSentenceList ?? []}
                    voiceLength={detail?.voiceLength}
                />
                <div className='wave-audio-container'>
                    {/* <If condition={noWave}>
                        <Divider
                            type='vertical'
                            className='wave-audio-container-cursor'
                            style={{
                                left: noWaveCursorPosition + '%',
                                transform: 'translateX(' + (noWaveCursorPosition > 90 ? '-1' : '1') + 'px)',
                            }}
                        />
                        <Divider className='wave-audio-container-no-wave-message'>
                            {Udesk.utils.string.format(
                                locales.components.common.waveAudio.noWaveMessage,
                                limitTimeOfWave
                            )}
                        </Divider>
                    </If> */}
                </div>
                <div className='wave-audio-operation-panel'>
                    <Space>
                        <Button
                            type='text'
                            size='small'
                            icon={
                                <Icon
                                    type={isShowPlayButton ? 'PlayCircleFilled' : 'PauseCircleFilled'}
                                    style={playBtnStyle}
                                    antdIcon={true}
                                    onClick={isShowPlayButton ? this.actions.play : this.actions.pause}
                                />
                            }
                        ></Button>
                        <span className='wave-audio-time-box'>
                            {currentTime} / {duration}
                        </span>
                        <span className='play-speed-buttons'>
                            <div>{locales.labels.playSpeed}:</div>
                            <Dropdown overlay={speedMenu} trigger={['click']}>
                                <Button type='link' size='small'>
                                    {playbackRate}x
                                </Button>
                            </Dropdown>
                            {/* <span className='wave-audio-rate-selected-option' onClick={this.actions.showRateOptions}>
                                X {playbackRate}
                            </span> */}
                            {/* <If condition={isShowRateOptions}>
                                <ul className='wave-audio-rate-options'>
                                    <For each='rate' index='index' of={rates}>
                                        <li
                                            className='wave-audio-rate-option'
                                            key={index}
                                            onClick={this.actions.changePlaybackRate.params(rate)}
                                        >
                                            {rate}
                                        </li>
                                    </For>
                                </ul>
                            </If> */}
                        </span>
                        <span className='volume-buttons'>
                            <i
                                className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yinliang'
                                onClick={this.actions.showVolumeRange}
                            ></i>
                            <Slider
                                style={{ width: 100 }}
                                tipFormatter={null}
                                step={5}
                                value={volume}
                                onChange={this.actions.changeVolume}
                            />
                            {/* <span className='wave-audio-volumn-input-box'>
                                <input
                                    type='range'
                                    id='wave-audio-volume'
                                    min='0'
                                    max='1'
                                    step='0.05'
                                    value={volume}
                                    onChange={this.actions.changeVolume}
                                />
                            </span> */}
                        </span>
                    </Space>
                    <Space split={<Divider type='vertical' />}>
                        <If condition={agentFlag}>
                            <span className='wave-audio-button-box'>
                                <span className='speak-rate-show-item'>
                                    <span className='speak-rate-show agent'></span>
                                    {locales.labels.agent}
                                    <span className='speak-rate-show-item-num'>
                                        {(detail && detail.agentSpeakRate && `${detail.agentSpeakRate}%`) ||
                                            locales.labels.none}
                                    </span>
                                </span>

                                <span className='speak-rate-show-item'>
                                    <span className='speak-rate-show customer'></span>
                                    {locales.labels.customer}
                                    <span className='speak-rate-show-item-num'>
                                        {(detail && detail.customerSpeakRate && `${detail.customerSpeakRate}%`) ||
                                            locales.labels.none}
                                    </span>
                                </span>
                            </span>
                        </If>
                        <If condition={isShowEmotionButton}>
                            <span onClick={this.actions.showEmotion} title='Emotion Analysis'>
                                <i
                                    className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xindiantu'
                                    style={{ color: 'lor:rgba(0, 0, 0, 0.65)' }}
                                ></i>
                            </span>
                        </If>
                        <Space>
                            {Udesk.data.init?.user && Udesk.data.init.user.hasFeature('call:root:download') && (
                                <DownloadAstText
                                    downloadAudio={this.actions.downloadVoice}
                                    exportTxt={isAppeal ? undefined : this.actions.exportTxt}
                                />
                            )}

                            {!isAppeal && (
                                <Dropdown
                                    size='small'
                                    overlay={
                                        <SettingMenu
                                            reverse={this.props.reverse}
                                            recover={this.props.recover}
                                            reAsr={this.props.reAsr}
                                            isRelated={isRelated}
                                        />
                                    }
                                    placement='bottomCenter'
                                >
                                    <Button
                                        style={playBtnStyle}
                                        size='small'
                                        type='text'
                                        icon={<Icon type='ic-set-shezhi1' />}
                                    >
                                        {locales.fix.recordingOperation}
                                    </Button>
                                </Dropdown>
                            )}
                        </Space>
                    </Space>
                </div>
                {this.privates.downUrl && (
                    <iframe src={this.privates.downUrl} className='demo-inspection-iframe' title='url'></iframe>
                )}
            </div>
        );
    }
}
