import React from 'react';
import Udesk from 'Udesk';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import { nodeHeight, nodeWidth } from '../const/nodeStyle';
import { useAppState } from './useFlowState';

function getSelectedBorderStyle(selected, border) {
    if (selected) {
        // return `border: 1px solid ${border};`;
        return `box-shadow: inset 0 0 0 2px ${border};`;
    }
    return '';
    // return `
    // border: 1px solid transparent;
    // border-image-source: linear-gradient(to bottom, ${border} 50%, white 50%) 1;
    // border-image-slice: 1;
    // border-image-outset: 0;
    // border-image-repeat: fill;
    // border-radius: 4px;
    // `;
}

const NodeWrap = styled.div`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 2px;
    &.startNode {
        background: linear-gradient(to bottom, #1a6eff 50%, #ffffff 50%);
    }
    &.userNode {
        background: linear-gradient(to bottom, #1acc8c 50%, #ffffff 50%);
    }
    &.robotNode {
        background: linear-gradient(to bottom, #7963e0 50%, #ffffff 50%);
    }
    &.knowledgeNode {
        background: linear-gradient(to bottom, #f3aa18 50%, #ffffff 50%);
    }
    &.pptNode {
        background: linear-gradient(to bottom, #f34d18 50%, #ffffff 50%);
    }
    &.pptNode.childNode {
        background: linear-gradient(to bottom, #ff8743 50%, #ffffff 50%);
    }
    > .react-flow__handle {
        opacity: 0;
        width: 1px !important;
        height: 1px !important;
        min-width: 1px !important;
        min-height: 1px !important;
    }
    > .react-flow__handle-top {
        top: 0;
    }
    > .react-flow__handle-bottom {
        bottom: 0;
    }

    &.startNode {
        ${(props) => getSelectedBorderStyle(props.selected, '#1a6eff')}
        .node-title {
            /* background: linear-gradient(90deg, #1a6eff 0%, rgba(26, 110, 255, 0.45) 100%); */
        }
    }
    &.userNode {
        ${(props) => getSelectedBorderStyle(props.selected, '#1acc8c')}
        .node-title {
            /* background: linear-gradient(90deg, #1acc8c 0%, rgba(24, 204, 138, 0.45) 100%); */
        }
    }
    &.robotNode {
        ${(props) => getSelectedBorderStyle(props.selected, '#7963e0')}

        .node-title {
            /* background: linear-gradient(90deg, #7963e0 0%, rgba(121, 99, 224, 0.45) 100%); */
        }
    }
    &.knowledgeNode {
        ${(props) => getSelectedBorderStyle(props.selected, '#f3aa18')}

        .node-title {
            /* background: linear-gradient(90deg, #f3aa18 0.01%, rgba(242, 170, 24, 0.45) 100%); */
        }
    }
    &.pptNode {
        ${(props) => getSelectedBorderStyle(props.selected, '#F34D18')}

        .node-title {
            /* background: linear-gradient(90deg, #f34d18 0.01%, rgba(242, 75, 24, 0.45) 100%); */
        }
    }
    &.pptNode.childNode {
        ${(props) => getSelectedBorderStyle(props.selected, '#FF8743')}

        .node-title {
            /* background: linear-gradient(90deg, #ff8743 0.01%, rgba(255, 134, 66, 0.45) 100%); */
        }
    }
    .edge-connector-wrap {
        position: relative;
        top: 0;
        left: 0;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border-radius: 3px;
        overflow: hidden;
        margin: -2px;
    }
    .edge-connector {
        position: absolute;
        top: 0;
        bottom: auto !important;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: none !important;
        border: 0px solid transparent;
        border-radius: 0 !important;
        transform: none;
        cursor: inherit !important;
        border-radius: 3px;
        &:hover {
            border: 4px solid;
            border-image: radial-gradient(
                    circle,
                    rgba(63, 251, 217, 0.6306897759103641) 0%,
                    rgba(70, 181, 252, 1) 100%
                )
                1 !important;
            cursor: crosshair !important;
        }
    }
    .edge-connector-inner {
        position: absolute;
        z-index: 2;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        top: 4px;
        left: 6px;

        .edge-connector-inner-content {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-size: 12px;
            text-align: center;
        }
    }
    .node-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        width: 100%;
        flex: 1;
        line-height: 38px;
        padding: 0 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .node-content {
        padding: 6px 24px 4px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: 30px;
        width: 100%;
        background-color: #fff;
    }
`;

// const isValidConnection = (connection) => true;

const CustomNode = (props) => {
    // const { id } = props;
    const { id, data, selected } = props;

    const { configType } = props.data;

    const { connecting } = useAppState();

    const isTarget = connecting.sourceId && connecting.sourceId !== id;

    const getHandleType = (isTarget) => {
        return isTarget ? 'target' : 'source';
    };

    return (
        <>
            <NodeWrap
                style={{ width: nodeWidth, height: nodeHeight }}
                selected={selected}
                className={`${props.type}${
                    configType === Udesk.enums.pptConfigType.singlePage.id ? ' childNode' : ''
                }`}
            >
                {Array.isArray(data.handle) &&
                    data.handle.map((h) => {
                        const isHandleTarget = h.target === id;
                        return (
                            <Handle
                                type={getHandleType(isHandleTarget)}
                                position={isHandleTarget ? Position.Top : Position.Bottom}
                            />
                        );
                    })}
                <Handle type="target" position={Position.Top} />
                <div
                    className={`edge-connector-wrap ${props.type}${
                        configType === Udesk.enums.pptConfigType.singlePage.id ? ' childNode' : ''
                    }`}
                >
                    <Handle
                        type={getHandleType(isTarget)}
                        id="connector-handle"
                        position={Position.Bottom}
                        className="edge-connector"
                        style={{
                            zIndex: isTarget ? 3 : 1,
                        }}
                    />
                    <div className="drag-me edge-connector-inner">
                        <div className="edge-connector-inner-content">
                            <div className="node-title">
                                {id}
                                {props.data.label}
                            </div>
                            <div className="node-content" title={props.data.words}>
                                {props.data.words}
                            </div>
                        </div>
                    </div>
                </div>
                <Handle
                    type="source"
                    position={Position['Bottom']}
                    // style={{ background: '#fff' }}
                    className="source-handle-node"
                    // isValidConnection={isValidConnection}
                    isConnectable={props.isConnectable}
                />
            </NodeWrap>
        </>
    );
};

export const nodeTypes = {
    // custominput: CustomInput,
    startNode: CustomNode,
    userNode: CustomNode,
    robotNode: CustomNode,
    knowledgeNode: CustomNode,
    pptNode: CustomNode,
};
