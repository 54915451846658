// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PutReviewCallSummaryByCallIdParam,
    PutReviewCallSummaryByCallIdBody,
    BaseResponseVoid,
} from '../../../../types';

/**
 * updateSessionSummary
 *
 * @export
 * @tags 通话记录
 * @link [PUT] /review/call/summary/{callId}
 * @param callId
 */
export function putReviewCallSummaryByCallId(
    data: PutReviewCallSummaryByCallIdBody,
    options: ApiOptions<PutReviewCallSummaryByCallIdParam, never> & {
        segments: PutReviewCallSummaryByCallIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutReviewCallSummaryByCallIdBody, PutReviewCallSummaryByCallIdParam, never>(
        '/review/call/summary/{callId}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putReviewCallSummaryByCallId' }
    );
}

export const meta = [{ tags: ['通话记录'], path: '/review/call/summary/{callId}', method: 'put' }];
