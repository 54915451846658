import React, { useContext, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import UdeskLocales from 'UdeskLocales';
import { useFilterData, usePagination } from "../components";
import { createComponent } from 'src/util/component';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { EnumSelect } from "Component/common/enum-component";
import { useLiveEffect, useRequest } from 'src/util/hook';
import { CommonFilterProps } from 'udesk_gm_ui/es/common-filter';
import { linkTo } from 'src/util/link';
import { array2tree } from 'Component/pages/intelligent-analysis/supervision/detail/recommendation';
import { getDateTimeString } from 'src/util/moment';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { Badge, Button } from 'udesk-ui';
import { HistoricalLearningRenderCountContext } from 'src/pages/home';
import { inspect } from 'src/util/core';
import './index.less';

const Template = React.memo((props: any) => {
    const {saveStorages} = props;
    const [pagination, setPagination] = usePagination(props);
    const [filterData] = useFilterData(props);
    const [tableData, setTableData] = useState<any[]>([]);
    const [courseFolderList, setCourseFolderList] = useState<any[]>([]);
    const request = useRequest();
    const [loading, setLoading] = useState(false);
    const queryParams = new URLSearchParams(props.location.search);
    const taskId = queryParams.get('taskId');
    const lessonId = queryParams.get('lessonId');
    const updateHistoricalLearningRenderCount = useContext(HistoricalLearningRenderCountContext);

    const getTableSource = (data) => {
        const {
            filter, 
            pagination,
        } = data;
        const { time, ...otherFilterValue } = filter || {};
        const [ startTime, endTime ] = time || [];
       
        saveStorages((storages) => {
            return {
                ...storages,
                filterData: filter,
                pagination: pagination,
            };
        });

        const params = {
            ...otherFilterValue,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
        };
        if (startTime) {
            params.startTime = getDateTimeString(startTime);
        }
        if (endTime) {
            params.endTime = getDateTimeString(endTime, 'YYYY-MM-DD HH:mm:59');
        }
        if (taskId) {
            params.taskId = taskId;
        }
        if (lessonId) {
            params.lessonId = lessonId;
        }
        if(params.scoreResult === Udesk.enums.learningTaskScoreType.reviewing.id) {
            params.status = params.scoreResult;
            delete params.scoreResult;
        }

        setLoading(true);
        request('/intelligentPartnerTaskFinishRecords', params).then(
            resp => {
                setTableData(resp.data ?? []);
                setPagination({
                    total: resp.paging?.total,
                    current: resp.paging?.pageNum,
                    pageSize: resp.paging?.pageSize,
                });
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        ).finally(() => {
            setLoading(false);
        });
    };

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 课程 */UdeskLocales['current'].pages.coach.learningCenter.record.index.curriculum,
                key: 'lessonName',
                dataIndex: 'lessonName',
            },
            {
                title: /* 任务名称 */UdeskLocales['current'].pages.coach.learningCenter.record.index.taskName,
                key: 'taskName',
                dataIndex: 'taskName',
            },
            {
                title: /* 任务类型 */UdeskLocales['current'].pages.coach.learningCenter.record.index.taskType,
                key: 'taskType',
                dataIndex: 'taskType',
                render: (name, item, index) => {
                    return Udesk.enums.learningTaskType.getName(name);
                },
            },
            {
                title: /* 得分 */UdeskLocales['current'].pages.coach.learningCenter.record.index.score,
                key: 'score',
                dataIndex: 'score',
                render: (score, item) => {
                    if ([Udesk.enums.learningTaskScoreType.reviewing.id].includes(item.status)) {
                        return Udesk.enums.learningTaskScoreType.getName(item.status);
                    }
                    return score;
                },
            },
            {
                title: /* 得分结果 */UdeskLocales['current'].pages.coach.learningCenter.record.index.scoringResults,
                key: 'scoreResult',
                dataIndex: 'scoreResult',
                render: (name, item, index) => {
                    if ([Udesk.enums.learningTaskScoreType.reviewing.id].includes(item.status)) {
                        return Udesk.enums.learningTaskScoreType.getName(item.status);
                    }
                    if (typeof name === 'number') {
                        return Udesk.enums.learningTaskScoreType.getName(name);
                    }
                    return '';
                },
            },
            {
                title: /* 时间 */UdeskLocales['current'].pages.coach.learningCenter.record.index.time,
                key: 'createTime',
                dataIndex: 'createTime',
            },
            {
                title: /* 操作 */UdeskLocales['current'].pages.coach.learningCenter.record.index.operation,
                key: 'id',
                dataIndex: 'id',
                render: (name, record) => {
                    return (
                        <div className={'task-btns'}>
                            <Button 
                                type={'link'} 
                                disabled={
                                    [Udesk.enums.learningTaskScoreType.reviewing.id].includes(record.status)
                                }
                                onClick={ 
                                    () => {
                                        linkTo(props.history, getCurrentRouteName('wrongQuestionSetList'), {}, {}, {
                                            taskId: record.taskId
                                        });
                                    }
                                }
                            >{/* 错题集 */}{UdeskLocales['current'].pages.coach.learningCenter.record.index.wrongQuestionSet}</Button>
                            {
                                record.isScoreUpdate === 1 || record.status === 6 ? (
                                    <Badge 
                                        count={
                                            <div 
                                                style={{
                                                    color: '#fff',
                                                    background: inspect(record.status === 6, '#ff8c00', '#ff4d4f'),
                                                    borderRadius: '12px',
                                                    padding: '6px 10px',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                <span>
                                                    {inspect(record.isScoreUpdate === 1, /* 评分更新 */UdeskLocales['current'].pages.coach.learningCenter.record.index.ratingUpdate)}
                                                    {inspect(record.status === 6, /* 评分申诉中 */UdeskLocales['current'].pages.coach.learningCenter.record.index.ratingAppealInProgress)}
                                                </span>
                                            </div>
                                        }
                                    >
                                        <Button 
                                            disabled={
                                                [Udesk.enums.learningTaskScoreType.reviewing.id].includes(record.status)
                                            }
                                            onClick={
                                                () => {
                                                    request(`/intelligentPartnerTaskFinishRecords/${name}/updateScore/status`, {}, 'put').then(
                                                        resp => {
                                                            updateHistoricalLearningRenderCount(() => {
                                                                linkTo(props.history, 'coachLearningCenterRecordDetail', { id: name });
                                                            });
                                                        },
                                                        reason => {
                                                            Udesk.ui.notify.error(reason.errorMsg);
                                                        }
                                                    );
                                                }
                                            } type={'link'}
                                        >
                                            <span>{/* 查看详情 */}{UdeskLocales['current'].pages.coach.learningCenter.record.index.viewDetails}</span>
                                        </Button>
                                    </Badge>
                                ) : (
                                    <Button 
                                        disabled={
                                            [Udesk.enums.learningTaskScoreType.reviewing.id].includes(record.status)
                                        }
                                        onClick={
                                            () => {
                                                linkTo(props.history, 'coachLearningCenterRecordDetail', { id: name });
                                            }
                                        } type={'link'}
                                    >
                                        <span>{/* 查看详情 */}{UdeskLocales['current'].pages.coach.learningCenter.record.index.viewDetails}</span>
                                    </Button>
                                )
                            }
                            
                        </div>
                    );
                },
            },
        ];
        return {
            columns,
            dataSource: tableData,
            pagination,
            loading,
        };
    }, [tableData, pagination, loading, props.history, request]);

    const filter = useMemo<CommonFilterProps>(() => {
        return {
            customComponents: {
                EnumSelect
            },
            fields: [
                {
                    name: 'lessonId',
                    label: /* 课程分类 */UdeskLocales['current'].pages.coach.learningCenter.record.index.courseClassification,
                    type: 'TreeSelect',
                    props: {
                        treeData: courseFolderList,
                        style: {
                            width: 250,
                        },
                    },
                },
                {
                    name: 'taskName',
                    label: /* 任务名称 */UdeskLocales['current'].pages.coach.learningCenter.record.index.taskName,
                    type: 'Input'
                },
                {
                    name: 'taskType',
                    label: /* 任务类型 */UdeskLocales['current'].pages.coach.learningCenter.record.index.taskType,
                    type: 'EnumSelect',
                    props: {
                        width: 130,
                        enumKey: 'learningTaskType',
                        empty: {
                            label: /* 全部 */UdeskLocales['current'].pages.coach.learningCenter.record.index.whole
                        }
                    }
                },
                {
                    name: 'scoreResult',
                    label: /* 得分结果 */UdeskLocales['current'].pages.coach.learningCenter.record.index.scoringResults,
                    type: 'EnumSelect',
                    props: {
                        width: 130,
                        enumKey: 'learningTaskScoreType',
                        empty: {
                            label: /* 全部 */UdeskLocales['current'].pages.coach.learningCenter.record.index.whole
                        }
                    }
                },
                {
                    name: 'time',
                    label: /* 学习时间 */UdeskLocales['current'].pages.coach.learningCenter.record.index.learningTime,
                    type: 'RangePicker',
                    props: {
                        format: 'YYYY-MM-DD HH:mm',
                        showTime: true,
                        style: {
                            width: 340,
                        }
                    }
                },
            ].filter((item, index) => {
                // 任务页面来的数据将不再显示前俩个筛选项
                if (taskId) {
                    return index > 1;
                }
                // 课程页面来的数据将不再显示前一个筛选项
                if (lessonId) {
                    return index > 0;
                }
                return true;
            }) as CommonFilterProps['fields'],
            initialValues: filterData,
        };
    }, [courseFolderList, filterData, lessonId, taskId]);

    useEffect(() => {
        $('.udesk-qa-ui-common-filter').on('click', '.udesk-qa-ui-btn-default', () => {
            return !saveStorages({
                filterData: {}
            });
        });
    }, [
        saveStorages
    ]);

    useLiveEffect(() => {
        request('/review/intelligentPartner/category').then(
            resp => {
                setCourseFolderList(array2tree(resp.data ?? [], (node) => {
                    node.title = node.name;
                    node.value = node.id;
                    return node;
                }) as any[]);
            }
        );
    }, []);

    return (
        <Page
            pageClassName='learning-center-record'
            pageBodyClassName='learning-center-record-body'
            backGroundGhost={true}
            title={/* 历史学习数据 */UdeskLocales['current'].pages.coach.learningCenter.record.index.historicalLearningData}
        >
            <div className='page-content'>
                <StandardizeTable.RequestTable
                    onRequest={getTableSource}
                    table={tableProps}
                    filter={filter}
                />
            </div>
        </Page>
    );
});

export default createComponent(
    Template,
    undefined,
    [
        /^\/coach\/learning-center\/record\/*/i,
    ]
);
