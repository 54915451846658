// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetIntelligentPartnerTaskInfosResultAnalysisDetailByTaskIdQuery,
    GetIntelligentPartnerTaskInfosResultAnalysisDetailByTaskIdParam,
    BaseResponseListFinishRecordResultAnalysisDetail,
} from '../../../../../types';

/**
 * 获取单个任务结果分析概览
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/result/analysis/detail/{taskId}
 * @param taskId
 */
export function getIntelligentPartnerTaskInfosResultAnalysisDetailByTaskId(
    options: ApiOptions<
        GetIntelligentPartnerTaskInfosResultAnalysisDetailByTaskIdParam,
        GetIntelligentPartnerTaskInfosResultAnalysisDetailByTaskIdQuery
    > & { segments: GetIntelligentPartnerTaskInfosResultAnalysisDetailByTaskIdParam }
): Promise<BaseResponseListFinishRecordResultAnalysisDetail> {
    return request<
        never,
        GetIntelligentPartnerTaskInfosResultAnalysisDetailByTaskIdParam,
        GetIntelligentPartnerTaskInfosResultAnalysisDetailByTaskIdQuery
    >('/intelligentPartnerTaskInfos/result/analysis/detail/{taskId}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerTaskInfosResultAnalysisDetailByTaskId',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/result/analysis/detail/{taskId}',
        method: 'get',
    },
];
