import React from 'react';
import MassageConfigurationComponent from './component';
import './style.scss';
import MassagePushConfiguration from '../massage-push-configuration';
import MassagePushConfigurationWechat from '../massage-push-configuration-wechat';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import { Button, Checkbox, Select, Space, TimePicker, Input, Tooltip, Icon } from 'udesk-ui';
import Udesk from 'Udesk';
import moment from 'moment';
const Hours = Array.from(Array(24), (v, k) => k);
let getFiltedHours = () => Hours.filter((i) => !(i > 1 && i < 23));

const format = 'HH:mm';

export default class MassageConfigurationTemplate extends MassageConfigurationComponent {
    render() {
        let { privates, locales, actions, props } = this;
        return (
            <div className='massage-configuration'>
                <CustomFilterModal
                    querryType='manualAdd'
                    visible={privates.isFilterVisible}
                    cancel={actions.handleConditionCancel}
                    applyAction={actions.handleConditionApply}
                    //条件规则
                    judgeStrategy={privates.configuration.baseFilter.judgeStrategy}
                    //自定义规则
                    customJudgeLogic={privates.configuration.baseFilter.customJudgeLogic}
                    conditionList={privates.configuration.baseFilter.conditionList}
                    // customName={privates.customName}
                    // customId={privates.customId}
                    type={props.taskType}
                    taskId={props.taskId}
                    taskName={props.taskName}
                    filterList={['f_8', 'f_4', 'f_6', 'f_7', 'r_1', 'r_2', 'r_3']}
                />
                <div className={`massage-configuration-row massage-name`}>
                    <div className='massage-configuration-row-label'>
                        {locales.components.massageConfiguration.name}
                    </div>
                    <div className='massage-configuration-row-content'>
                        <Input
                            defaultValue={privates.configuration.monitorConf.name}
                            onChange={actions.changeMonitorConfName}
                        />
                    </div>
                </div>
                <div className={`massage-configuration-row`}>
                    <div className='massage-configuration-row-label'>
                        {locales.components.massageConfiguration.filter}
                    </div>
                    <div className='massage-configuration-row-content'>
                        {privates.configuration.baseFilter.conditionList &&
                            !privates.configuration.baseFilter.conditionList.length ? (
                                <Button size='small' type='link' onClick={actions.openCustomFilterModal}>
                                    <i className='udesk-qa-list-btn-icon udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1'></i>
                                    {locales.labels.filterConditions}
                                </Button>
                            ) : (
                                <div
                                    className='auto-add-filter-edit-item-row-filter'
                                    onClick={props.disabled ? null : actions.openCustomFilterModal}
                                >
                                    <div className='auto-add-filter-edit-item-row-filter-title'>
                                        <div>{locales.pages.tasks.manage.template.manualAdd.index.filterTitle}</div>
                                        {props.disabled ? null : (
                                            <div className='index-management-custom-filter-edit'>
                                                <Button type='link' size='small' onClick={actions.openCustomFilterModal}>
                                                    {locales.labels.edit}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                    <div className='index-management-custom-filter-Info'>
                                        <div className='auto-add-filter-edit-item-row-filter-title'>
                                            {locales.pages.tasks.manage.template.manualAdd.index.filterRule}
                                            {locales.labels.colon}
                                        </div>
                                        <div className='auto-add-filter-edit-item-row-filter-rules'>
                                            {actions.fieldsFilterChange(privates.configuration.baseFilter.conditionList)}
                                        </div>
                                    </div>
                                    <div className='index-management-custom-filter-Info' style={{ marginBottom: 0 }}>
                                        <div
                                            className='auto-add-filter-edit-item-row-filter-title'
                                            style={{ marginBottom: 0 }}
                                        >
                                            {locales.components.common.customFilter.conditionRule}：
                                        </div>
                                        <div className='auto-add-filter-edit-item-row-filter-rules'>
                                            {privates.configuration.baseFilter.judgeStrategy !== 3 &&
                                                Udesk.enums.operatorRuleTypes.find(
                                                    (current) =>
                                                        current.id ===
                                                        (privates.configuration.baseFilter.judgeStrategy ||
                                                            Udesk.enums.operatorRuleTypes.all.id)
                                                ).name}
                                            {privates.configuration.baseFilter.judgeStrategy === 3 &&
                                                privates.configuration.baseFilter.customJudgeLogic}
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                <If condition={props.type === Udesk.enums.messageType.trigger.id}>
                    <div className={`massage-configuration-row`}>
                        <div className='massage-configuration-row-label'>
                            {locales.components.massageConfiguration.triggerType}
                        </div>
                        <div className='massage-configuration-row-content'>
                            <Checkbox.Group
                                options={privates.triggerTypeList}
                                value={privates.configuration.monitorConf.eventSources}
                                onChange={actions.onSelectChange.params('eventSources')}
                            />
                        </div>
                    </div>
                </If>
                <If condition={props.type === Udesk.enums.messageType.timing.id}>
                    <div className={`massage-configuration-row massage-name`}>
                        <div className='massage-configuration-row-label'>
                            {locales.components.massageConfiguration.pushCycle}
                        </div>
                        <div className='massage-configuration-row-content'>
                            <Space>
                                <Select
                                    style={{ minWidth: 100 }}
                                    value={privates.configuration.monitorConf.pushCycle}
                                    onChange={actions.onSelectChange.params('pushCycle')}
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                >
                                    {Udesk.enums.assessFrequencyTypes.map((item) => (
                                        <Select.Option value={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                                <If
                                    condition={
                                        privates.configuration.monitorConf.pushCycle ===
                                        Udesk.enums.assessFrequencyTypes.everyWeek.id
                                    }
                                >
                                    <Select
                                        style={{ minWidth: 100 }}
                                        mode='multiple'
                                        value={privates.configuration.monitorConf.daysOfWeek}
                                        onChange={actions.onSelectChange.params('daysOfWeek')}
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                    >
                                        {Udesk.enums.assessFrequencyWeekDays.map((item) => (
                                            <Select.Option value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </If>
                                <If
                                    condition={
                                        privates.configuration.monitorConf.pushCycle ===
                                        Udesk.enums.assessFrequencyTypes.everyMonth.id
                                    }
                                >
                                    <Select
                                        style={{ minWidth: 150 }}
                                        mode='multiple'
                                        value={privates.configuration.monitorConf.daysOfMonth}
                                        onChange={actions.onSelectChange.params('daysOfMonth')}
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                    >
                                        {privates.days.map((item) => (
                                            <Select.Option value={item}>
                                                {item > 31
                                                    ? locales.labels.theLastDayOfMonth
                                                    : Udesk.utils.string.format(locales.labels.theDay, item)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </If>
                                <TimePicker
                                    disabledHours={getFiltedHours}
                                    value={moment(privates.configuration.monitorConf.executeTime, format)}
                                    format={format}
                                    allowClear={false}
                                    onChange={actions.onTimePickerChange.params('executeTime')}
                                />
                                <Tooltip placement='top' title={locales.business.notifyMessage.timePickerTips}>
                                    <Icon antdIcon={true} style={{ margin: '0 4px' }} type='QuestionCircleOutlined' />
                                </Tooltip>
                            </Space>
                        </div>
                    </div>
                    <div className={`massage-configuration-row`}>
                        <div className='massage-configuration-row-label'>
                            {locales.components.massageConfiguration.pushContent}
                        </div>
                        <div className='massage-configuration-row-content'>
                            <Checkbox checked disabled>
                                {locales.components.massageConfiguration.qualityList}
                            </Checkbox>
                        </div>
                    </div>
                </If>
                <div className={`massage-configuration-row massage-configuration-last-row`}>
                    <div className='massage-configuration-row-label'>
                        {locales.components.massageConfiguration.sdkPushWay}
                    </div>
                    <div className='massage-configuration-row-content'>
                        {!props.isWechatQa ? (
                            <MassagePushConfiguration
                                checkedTypes={privates.checkedTypes}
                                type={props.type}
                                // key={`MassagePushConfiguration${props.configuration._id}`}
                                onPushTypesChange={actions.onPushTypesChange}
                                pushConf={privates.configuration.monitorConf.pushConf}
                                onPushConfChange={actions.onPushConfChange}
                                users={props.users}
                                roles={props.roles}
                                taskType={props.taskType}
                                taskTypeList={props.taskTypeList}
                                taskId={props.taskId}
                            />
                        ) : (
                            <MassagePushConfigurationWechat
                                type={props.type}
                                taskType={props.taskType}
                                taskId={props.taskId}
                                onPushConfChange={actions.onPushConfChange}
                                pushConf={privates.configuration.monitorConf.pushConf}
                                messagePushTypeFilter={props.messagePushTypeFilter}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
