// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostAsrFilterConfigSaveBody, BaseResponseVoid } from '../../types';

/**
 * save
 *
 * @export
 * @tags asr-filter-config-controller
 * @link [POST] /asr-filter-config/save
 */
export function postAsrFilterConfigSave(
    data: PostAsrFilterConfigSaveBody
): Promise<BaseResponseVoid>;
export function postAsrFilterConfigSave(
    data: PostAsrFilterConfigSaveBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postAsrFilterConfigSave(
    data: PostAsrFilterConfigSaveBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostAsrFilterConfigSaveBody, never, never>('/asr-filter-config/save', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postAsrFilterConfigSave',
    });
}

export const meta = [
    { tags: ['asr-filter-config-controller'], path: '/asr-filter-config/save', method: 'post' },
];
