import React, { useEffect, useMemo, useState } from 'react';
import { Button, Empty, FormBuilder, Modal, Spin, Tabs } from 'udesk-ui';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import UdeskLocales from 'UdeskLocales';

const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    position: relative;
    padding-left: 12px;
    &::before {
        content: ' ';
        width: 6px;
        position: absolute;
        top: 4px;
        left: 0;
        right: 0;
        bottom: 4px;
        background-color: #1b6dff;
        border-radius: 3px;
    }
`;
const SummaryContent = styled.div`
    margin-bottom: 16px;
    padding: 8px;
    background-color: #f2f2f2;
    border-radius: 3px;

    table {
        margin: 8px;
    }
    thead {
        border-bottom: 1px solid #fff;
        border-top: 1px solid #fff;
    }
    th {
        border-right: 1px solid #fff;
        padding: 4px;
        &:last-child {
            border-right: none;
        }
    }
    tr {
        border-bottom: 1px solid #fff;
    }
    td {
        border-right: 1px solid #fff;
        padding: 4px;
        &:last-child {
            border-right: none;
        }
    }
`;
const SummaryPrompt = styled.div`
    margin-bottom: 8px;
`;

export const Summary = (props) => {
    const { data = [], loading, title, onAdd, modalTitle, onRemove, isKeyPoints } = props;
    const [selectedValue, setSelectedValue] = useState<string | undefined>();
    const [visible, setVisible] = useState<boolean>(false);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    // const [initialValues, setInitialValues] = useState<any>({});
    const [form] = FormBuilder.useForm();
    const dataMap = useMemo(() => {
        return data.reduce((p, c) => {
            p[c.id] = c;
            return p;
        }, {});
    }, [data]);
    useEffect(() => {
        if (Array.isArray(data) && data.length) {
            setSelectedValue(`${data[data.length - 1].id}`);
        }
    }, [data]);
    if (!Array.isArray(data) || (isKeyPoints && !data.length))
        return (
            <Spin spinning={loading}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Spin>
        );
    const add = () => {
        setVisible(true);
        form.resetFields();
    };
    const onClose = () => {
        setVisible(false);
    };
    const onOk = () => {
        form.validateFields()
            .then((value) => {
                console.log('value', value);
                setBtnLoading(true);
                return onAdd?.(value);
            })
            .then(() => {
                onClose();
            })
            .finally(() => {
                setBtnLoading(false);
            });
    };

    const remove = (targetKey: string) => {
        onRemove(targetKey).then(() => {
            let newActiveKey = selectedValue;
            let lastIndex = -1;
            data.forEach((item, i) => {
                if (item.key === targetKey) {
                    lastIndex = i - 1;
                }
            });
            const newPanes = data.filter((item) => item.key !== targetKey);
            if (newPanes.length && newActiveKey === targetKey) {
                if (lastIndex >= 0) {
                    newActiveKey = newPanes[lastIndex].key;
                } else {
                    newActiveKey = newPanes[0].key;
                }
            }
            setSelectedValue(newActiveKey);
        });
    };
    const onEdit = (targetKey: any, action: 'add' | 'remove') => {
        if (action === 'add') {
            add();
        } else {
            remove(targetKey);
        }
    };
    const onChange = (newActiveKey: string) => {
        setSelectedValue(newActiveKey);
        console.log('data', data);
        console.log('newActiveKey', newActiveKey);
    };

    return (
        <>
            {title && <Title>{title}</Title>}
            {!isKeyPoints && (
                <Tabs
                    type="editable-card"
                    onChange={onChange}
                    activeKey={selectedValue}
                    onEdit={onEdit}
                    // hideAdd={isKeyPoints}
                    size="small"
                >
                    {data.map((i) => (
                        <Tabs.TabPane tab={i.name} key={`${i.id}`} closable={i.type !== 4}></Tabs.TabPane>
                    ))}
                </Tabs>
            )}
            {data.length > 0 && (
                <>
                    <SummaryPrompt className="summary-prompt">
                        <span style={{ fontWeight: 600 }}>
                            {/* 提示语： */}
                            {
                                UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis
                                    .detail.components.summary.index.reminder
                            }
                        </span>
                        {(selectedValue && dataMap[selectedValue]?.prompt) || '-'}
                        {!isKeyPoints && (
                            <Button
                                type="link"
                                onClick={() => {
                                    setVisible(true);
                                    selectedValue && form.setFieldsValue(dataMap[selectedValue]);
                                }}
                            >
                                {/* 编辑 */}
                                {
                                    UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis
                                        .detail.components.summary.index.edit
                                }
                            </Button>
                        )}
                    </SummaryPrompt>
                    <SummaryContent className="summary-content">
                        <ReactMarkdown
                            children={(selectedValue && dataMap[selectedValue]?.summary) || '-'}
                            remarkPlugins={[remarkGfm]}
                        />
                    </SummaryContent>
                </>
            )}
            <Modal
                visible={visible}
                onCancel={onClose}
                title={modalTitle || title}
                onOk={onOk}
                okButtonProps={{
                    loading: btnLoading,
                }}
            >
                <FormBuilder
                    form={form}
                    fields={[
                        {
                            label:
                                (title || '') +
                                /* 名称 */ UdeskLocales['current'].pages.conversationAnalysis
                                    .videoAnalysis.detail.components.summary.index.name,
                            name: 'name',
                            type: 'Input',
                            rules: [{ required: true }],
                        },
                        {
                            label: /* 提示语 */ UdeskLocales['current'].pages.conversationAnalysis
                                .videoAnalysis.detail.components.summary.index.hintLanguage,
                            name: 'prompt',
                            type: 'TextArea',
                        },
                    ]}
                    footerRender={false}
                    labelCol={{
                        sm: {
                            span: 6,
                        },
                    }}
                    wrapperCol={{
                        sm: {
                            span: 16,
                        },
                    }}
                />
            </Modal>
        </>
    );
};
