import Udesk from 'Udesk';
import React from 'react';
import './style.scss';
import SmartTagsSimpleIndexComponent from './component';
import { Input, Icon, Button, Tag, Form, Spin, Tooltip, Space, Empty } from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import _filter from 'lodash-es/filter';
import Locales from 'UdeskLocales';

const { Search } = Input;

const HitSymbol = (props) => {
    const locales = Locales['current'];

    return (
        <Tooltip title={locales.fix.hit}>
            <div style={{ backgroundColor: '#11A848', width: 8, height: 8, borderRadius: '50%' }}></div>
        </Tooltip>
    );
};

const TagCard = React.memo(({item, actions, locales, privates }) => {
    let sentence = item.tags && item.tags.find(tag => tag.standardFlag === 1);
    return (
        <div
            className={`smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item${
                privates.activiteTagId.some(id => item.id === parseInt(id, 10))
                    ? ' smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item-active'
                    : ''
            }`}
        // key={item.id}
        >
            <div
                className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item-content'
                onClick={actions.selectSmartTag.params(item)}
            >
                <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item-content-title'>
                    {item.title}
                </div>
                <div
                    title={sentence && sentence.content}
                    className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item-content-sentence'
                >
                    {sentence ? sentence.content : ''}
                </div>
            </div>
            <If condition={!privates.isClustering}>
                <If condition={item.semanticHitType}>
                    <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item-hit'>
                        <Tag
                            color={
                                item.semanticHitType === 3 ||
                                    item.semanticHitType === 4 ||
                                    item.semanticHitType === 5 ||
                                    item.hitResultSimilar >= 80
                                    ? 'success'
                                    : 'warning'
                            }
                        >
                            {item.semanticHitType && item.semanticHitType !== 2
                                ? locales.components.pages.smartTools.smartTags.hit
                                : locales.components.pages.smartTools.smartTags
                                    .similar +
                                ':' +
                                parseInt(item.hitResultSimilar, 10)}
                        </Tag>
                    </div>
                </If>
                <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:semanticTag:edit")}>
                    <Icon
                        onClick={actions.tranToDetail.params(item)}
                        type='bianji'
                        style={{ padding: '0px 2.5px 0px 8px' }}
                    />
                </If>
            </If>
            <If condition={privates.isClustering}>
                <Space>
                    <Tag color={
                        parseInt(item.hitResultSimilar, 10) >= 80
                            ? 'success'
                            : 'warning'
                    }>
                        {locales.components.pages.smartTools.smartTags.similar +
                            ':' +
                            parseInt(item.hitResultSimilar, 10)}
                    </Tag>
                    <Button size='small' onClick={actions.tranToDetail.params(item)}>{locales.components.pages.trainingCenter.clusteringAnnotation.right.detail}</Button>
                    <Button size='small' onClick={actions.tagging.params(item)}>{locales.components.pages.trainingCenter.clusteringAnnotation.right.tagging}</Button>
                </Space>
            </If>
        </div>
    );
});

export default class SmartTagsSimpleIndexTemplate extends SmartTagsSimpleIndexComponent {
    render() {
        let { actions, locales, privates } = this;

        return (
            <React.Fragment>
                <div
                    className='smart-tools-sider-bar-index-page-drawer-body'
                    style={{ height: '100%', perspective: '1000', padding: 0 }}
                >
                    <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-index'>
                        <div className={`card${!privates.isFront ? ' flipme' : ''}`}>
                            <div class='front'>
                                <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:semanticTag:view")}>
                                    <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-search-bar'>
                                        <Search
                                            placeholder={locales.components.pages.smartTools.smartTags.searchPlaceHolder}
                                            onSearch={actions.search}
                                            value={privates.searchValue}
                                            onChange={actions.searchValueChange}
                                            style={{ maxWidth: 252, flex: '1' }}
                                        />
                                        <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:semanticTag:add")}>
                                            <If condition={!privates.isClustering}>
                                                <Button ton onClick={actions.createNewSmartTag} style={{ width: 76 }}>
                                                    <Icon type='tianjia1'></Icon>
                                                    {locales.components.pages.smartTools.smartTags.createSmartTag}
                                                </Button>
                                            </If>
                                            <If condition={privates.isClustering}>
                                                <Button ton onClick={actions.createNewClusteringSmartTag} style={{ marginLeft: 8 }}>
                                                    <Icon type='tianjia1'></Icon>
                                                    {Udesk.utils.string.format(locales.components.pages.smartTools.smartTags.createClusteringSmartTag, privates.sentenceCount ? privates.sentenceCount : 1)}
                                                </Button>
                                            </If>
                                        </If>
                                    </div>
                                    <Spin spinning={privates.loading} wrapperClassName='smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-loading'>
                                        <div ref={(instance) => (this.privates.smartTagLitContent = instance)} className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-list'>
                                            <If condition={privates.smartTagsList && privates.smartTagsList.length}>
                                                <If condition={privates.isNotAutoSort}>
                                                    {privates.smartTagsList.map(item => {
                                                        return <TagCard item={item} key={item.id} {...{actions, locales, privates}} />;
                                                    })}
                                                </If>
                                                <If condition={!privates.isNotAutoSort}>
                                                    {privates.smartTagsList.filter(item => privates.activiteTagId.some(id => item.id === parseInt(id, 10))).map(item => {
                                                        return <TagCard item={item} key={item.id} {...{actions, locales, privates}}/>;
                                                    })}
                                                    {privates.smartTagsList.filter(item => !privates.activiteTagId.some(id => item.id === parseInt(id, 10))).map(item => {
                                                        return <TagCard item={item} key={item.id} {...{actions, locales, privates}}/>;
                                                    })}
                                                </If>
                              
                                            </If>
                                            <If condition={(privates.smartTagsList && !privates.smartTagsList.length) || (!privates.smartTagsList)}>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </If>
                                        </div>
                                    </Spin>
                                </If>
                            </div>
                            <div class='back'>
                                <div style={{ padding: '0px 16px 8px', boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.1)', position: "relative", top: '-1px', backgroundColor: '#fff' }}>
                                    <Search
                                        placeholder={
                                            locales.components.pages.smartTools.smartTags.searchPlaceHolder
                                        }
                                        onSearch={actions.searchInDetail}
                                        value={privates.searchValue}
                                        onChange={actions.searchValueChange}
                                    />
                                </div>
                                <Spin spinning={privates.detailLoading} wrapperClassName='smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-loading'>
                                    <Form
                                        style={{ height: '100%' }}
                                        initialValues={{}}
                                        onFinish={actions.onFinish}
                                        ref={privates.form}
                                        onValuesChange={actions.onValuesChange}
                                        onFieldsChange={actions.onFieldsChange}
                                    >
                                        <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail'>
                                            <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-label'>
                                                {locales.components.pages.smartTools.smartTags.name}
                                            </div>
                                            <Form.Item
                                                name='name'
                                                rules={[{ required: true, message: ' ' }, {
                                                    whitespace: true, message: Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.smartTools.smartTags.name)
                                                }]}
                                            >
                                                <Input
                                                    placeholder={
                                                        locales.components.pages.smartTools.smartTags.namePlaceHolder
                                                    }
                                                />
                                            </Form.Item>
                                            {/* 标准句 */}
                                            <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-label'>
                                                {locales.components.pages.smartTools.smartTags.standardSentences}
                                            </div>
                                            <Form.Item name='standardSentences' rules={[{ whitespace: true, message: Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.smartTools.smartTags.standardSentences) }]}>
                                                <Input
                                                    placeholder={
                                                        locales.components.pages.smartTools.smartTags
                                                            .standardSentencesPlaceHolder
                                                    }
                                                    suffix={privates.currentTag.hitTypes && privates.currentTag.hitTypes.includes(2) && privates.currentTag.hitTagIds.includes(privates.similaSentences.find(item => item.standardFlag === 1).id) ?
                                                        <HitSymbol/> : null
                                                    }
                                                />
                                            </Form.Item>
                                            {/* 召回词 */}
                                            <div>{locales.components.pages.smartTools.smartTags.recallWords}</div>
                                            {!_isEmpty(privates.recallKeyWord) &&
                                                privates.recallKeyWord.map((item, index) => {
                                                    return (
                                                        <div
                                                            // key={item.id}
                                                            style={{ display: 'flex', alignItems: 'center' }}
                                                        >
                                                            <Form.Item
                                                                name={item.id}
                                                                rules={[{ whitespace: true, message: Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.smartTools.smartTags.recallWords) }]}
                                                                style={{ flex: 1, margin: '4px 0' }}
                                                            >
                                                                <Input
                                                                    placeholder={
                                                                        locales.components.pages.smartTools.smartTags
                                                                            .recallWordsPlaceHolder
                                                                    }
                                                                    suffix={privates.currentTag.hitTypes && privates.currentTag.hitTypes.includes(3) && item.text && privates.currentTag.hitRecallWords.includes(item.text) ?
                                                                        <HitSymbol /> : null
                                                                    }
                                                                />
                                                            </Form.Item>
                                                            <div
                                                                className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-delete'
                                                                onClick={actions.recallKeyWordDel.params(item.id, index)}
                                                            >
                                                                <Icon type='ic-minus-circle-jian'></Icon>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            <Button type='link' size='small' onClick={actions.recallKeyWordAdd}>
                                                <Icon type='tianjia1'></Icon>
                                                {locales.components.pages.smartTools.smartTags.add}
                                            </Button>
                                            {/* 召回正则 */}
                                            <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-label' style={{ display: 'flex' }}>
                                                <Space>
                                                    {locales.components.pages.smartTools.smartTags.recallRegular}
                                                    {privates.currentTag.hitTypes && privates.currentTag.hitTypes.includes(4) ? <HitSymbol /> : null}
                                                </Space>
                                            </div>
                                            <Form.Item
                                                name='recallRegular'
                                                rules={[{ whitespace: true, message: Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.smartTools.smartTags.recallRegular) }]}
                                            >
                                                <Input
                                                    placeholder={
                                                        locales.components.pages.smartTools.smartTags
                                                            .recallRegularPlaceHolder
                                                    }
                                                />
                                            </Form.Item>
                                            {/* 规则 */}
                                            <If condition={privates.currentTag.customRules && privates.currentTag.customRules.length}>
                                                <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-label' style={{ display: 'flex' }}>
                                                    <Space>
                                                        {locales.components.pages.smartTools.smartTags.rules}
                                                        {privates.currentTag.hitTypes && privates.currentTag.hitTypes.includes(5) ? <HitSymbol /> : null}
                                                    </Space>
                                                </div>
                                                {privates.currentTag.customRules &&
                                                    privates.currentTag.customRules
                                                        .filter(item => !!item.color)
                                                        .map((item, index) => {
                                                            let color =
                                                                item.color === '#1890ff'
                                                                    ? 'blue'
                                                                    : item.color === 'rgb(215, 88, 87)'
                                                                        ? 'red'
                                                                        : item.color === 'rgb(235, 192, 73)'
                                                                            ? 'gold'
                                                                            : item.color;
                                                            return (
                                                                <div
                                                                    // key={item.label + index}
                                                                    className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-rules'
                                                                >
                                                                    <Tag color={color}>{item.label ? item.label : item.keyWords}</Tag>
                                                                </div>
                                                            );
                                                        })}
                                            </If>
                                            {/* 相似句 */}
                                            <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-label'>
                                                {locales.components.pages.smartTools.smartTags.similaSentences}
                                            </div>
                                            {!_isEmpty(privates.similaSentences) &&
                                                _filter(
                                                    privates.similaSentences,
                                                    current => current.standardFlag === 0
                                                ).map((item, index) => {
                                                    return (
                                                        <div
                                                            // key={item.content + index}
                                                            title={item.content}
                                                            style={{ display: 'flex', alignItems: 'center' }}
                                                        >
                                                            <Form.Item
                                                                name={`similaSentences${item.order}`}
                                                                rules={[{ whitespace: true, message: Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.smartTools.smartTags.similaSentences) }]}
                                                                style={{ flex: 1, margin: '4px 0' }}
                                                            >
                                                                <Input
                                                                    placeholder={
                                                                        locales.components.pages.smartTools.smartTags
                                                                            .similaSentencesPlaceHolder
                                                                    }
                                                                    suffix={privates.currentTag.hitTypes && privates.currentTag.hitTypes.includes(2) && privates.currentTag.hitTagIds.includes(item.id) ?
                                                                        <HitSymbol /> : null
                                                                    }
                                                                />
                                                            </Form.Item>
                                                            <div
                                                                className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-delete'
                                                                onClick={actions.similaSentencesDel.params(item.order)}
                                                            >
                                                                <Icon type='ic-minus-circle-jian'></Icon>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            <Button type='link' size='small' onClick={actions.similaSentencesAdd.params(0)}>
                                                <Icon type='tianjia1'></Icon>
                                                {locales.components.pages.smartTools.smartTags.add}
                                            </Button>
                                            <If condition={privates.hasKeyword}>
                                                <Button type='link' size='small' style={{ color: 'rgba(0, 0, 0, 0.45)', marginLeft: 16 }} onClick={actions.addFromKeyword.params(0)}>
                                                    <Icon type='tianjia1'></Icon>
                                                    {locales.components.pages.smartTools.smartTags.addFromKeyword}
                                                </Button>
                                            </If>
                                        </div>
                                        <div className='smart-tools-sider-bar-index-page-drawer-body-smart-tags-footer'>
                                            <div style={{ display: 'flex' }}>
                                                <Form.Item style={{ height: 32, marginBottom: 0 }}>
                                                    <Button type='primary' htmlType='submit' style={{ marginRight: 8 }}>
                                                        {locales.labels.save}
                                                    </Button>
                                                </Form.Item>
                                                <Button onClick={actions.backToIndex}>{locales.labels.cancel}</Button>
                                            </div>
                                            <If condition={!!this.privates.currentTag.id}>
                                                <Button type='link' onClick={actions.openInNewPage}>
                                                    {locales.components.pages.smartTools.smartTags.newPage}
                                                </Button>
                                            </If>
                                        </div>
                                    </Form>
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
