import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import * as echarts from 'echarts';
import { Radio, Space } from 'udesk-ui';
import FilterItem from '../common/filter-item';
import { getReviewCallStatistics } from 'src/api/review/call/statistics';

function FilterStatistics(props) {
    const locales = Locales['current'];

    const [mainEchartsOptions, setMainEchartsOptions] = useState<any>({
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            data: [],
            axisTick: {
                alignWithLabel: true,
            },
        },
        yAxis: {
            name: '#',
            type: 'value',
            splitNumber: 1,
            // max: 2000,
            axisLine: {
                show: false,
            },
        },
        grid: {
            left: '0',
            right: '0',
            bottom: '3%',
            top: '50px',
            containLabel: true,
        },
        series: [
            {
                name: /* 通话 */ locales.pages.gong.common.filterStatistics.conversation,
                // data: [20],
                type: 'bar',
                stack: 'total',
                barMinHeight: 3,
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#4FAAEB' },
                            { offset: 1, color: '#4FAAEB' },
                        ]),
                    },
                },
                barMaxWidth: 40,
            },
            {
                name: /* 微信 */ locales.pages.gong.common.filterStatistics.weChat,
                // data: [20],
                type: 'bar',
                stack: 'total',
                barMinHeight: 3,
                itemStyle: {
                    normal: {
                        color: '#FED46B',
                    },
                },
                barMaxWidth: 40,
            },
        ],
    });
    const [secEchartsOptions, setSecEchartsOptions] = useState<any>({
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            data: [],
            axisTick: {
                alignWithLabel: true,
            },
        },
        yAxis: {
            type: 'value',
            splitNumber: 1,
            name: '%',
            // max: 100,
            axisLine: {
                show: false,
            },
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '3%',
            top: '50px',
            containLabel: true,
        },
        series: [
            {
                name:
                /* 通话 */ locales.pages.gong.common.filterStatistics.conversation +
                    locales.pages.gong.common.percentage,
                // data: [20],
                type: 'line',
                barMinHeight: 3,
                itemStyle: {
                    color: '#1A6EFF',
                },
                symbol: 'none',
            },
            {
                name:
                /* 微信 */ locales.pages.gong.common.filterStatistics.weChat +
                    locales.pages.gong.common.percentage,
                type: 'line',
                barMinHeight: 3,
                itemStyle: {
                    color: '#FED46B',
                },
                symbol: 'none',
            },
        ],
    });
    // 统计数量百分比tab切换
    const [statisticsTabs, setStatisticsTabs] = useState(Udesk.enums.statisticsType.unit.id);
    // 统计时间tab切换
    const [statisticsTime, setStatisticsTime] = useState(Udesk.enums.statisticsDate.week.id);

    const handlerClassChange = (key) => {
        setStatisticsTabs(key.target.value);
    };
    const handlerTimeChange = (key) => {
        setStatisticsTime(key.target.value);
    };
    // 获取列表统计
    const getStatisticsData = () => {
        getReviewCallStatistics({
            params: {
                dimension: statisticsTime,
            },
        }).then(
            (resp) => {
                let timeMap = new Map();
                if (resp.data) {
                    resp.data.forEach((item) => {
                        if (!timeMap.has(item.fromDate)) {
                            let value: any = {};
                            if (item.channelType === 0) {
                                // 语音
                                value.callBar = item.number ?? 0;
                                value.callLine = Math.floor((item.percent ?? 0) * 100);
                                value.wechartBar = null;
                                value.wechartLine = null;
                            }
                            if (item.channelType === 1) {
                                // 企业微信
                                value.wechartBar = item.number ?? 0;
                                value.wechartLine = Math.floor((item.percent ?? 0) * 100);
                                value.callBar = null;
                                value.callLine = null;
                            }
                            timeMap.set(item.fromDate, value);
                        } else {
                            let value = timeMap.get(item.fromDate);
                            if (item.channelType === 0) {
                                // 语音
                                value.callBar = item.number ?? 0;
                                value.callLine = Math.floor((item.percent ?? 0) * 100);
                            }
                            if (item.channelType === 1) {
                                // 企业微信
                                value.wechartBar = item.number ?? 0;
                                value.wechartLine = Math.floor((item.percent ?? 0) * 100);
                            }
                            timeMap.set(item.fromDate, value);
                        }
                    });
                }
                const timeData = Array.from(timeMap.keys());

                setMainEchartsOptions({
                    ...mainEchartsOptions,
                    series: [
                        {
                            ...mainEchartsOptions.series[0],
                            data: Array.from(timeMap.values()).map((i) => i.callBar),
                        },
                        {
                            ...mainEchartsOptions.series[1],
                            data: Array.from(timeMap.values()).map((i) => i.wechartBar),
                        },
                    ],
                    xAxis: {
                        ...mainEchartsOptions.xAxis,
                        data: timeData,
                    },
                });
                setSecEchartsOptions({
                    ...secEchartsOptions,
                    series: [
                        {
                            ...secEchartsOptions.series[0],
                            data: Array.from(timeMap.values()).map((i) => i.callLine),
                        },
                        {
                            ...secEchartsOptions.series[1],
                            data: Array.from(timeMap.values()).map((i) => i.wechartLine),
                        },
                    ],
                    xAxis: {
                        ...secEchartsOptions.xAxis,
                        data: timeData,
                    },
                });
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    useEffect(() => {
        const pageBodyOnresize = document.querySelector('.page-call-analysis-all-call-list');
        let timer: any = null;

        if (statisticsTabs === Udesk.enums.statisticsType.unit.id) {
            const mainCanvas = document.getElementById('mainCanvas');
            const myChart = echarts.init(mainCanvas);
            myChart.setOption(mainEchartsOptions);
            myChart.getDom().style.width = '100%';

            const resizeObserver = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    timer = setTimeout(() => {
                        myChart.resize();
                    }, 200);
                });
            });
            if (pageBodyOnresize) {
                resizeObserver.observe(pageBodyOnresize);
            }
        } else {
            const secCanvas = document.getElementById('secCanvas');
            const secChart = echarts.init(secCanvas);
            secChart.setOption(secEchartsOptions);
            secChart.getDom().style.width = '100%';

            const resizeObserver = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    timer = setTimeout(() => {
                        secChart.resize();
                    }, 200);
                });
            });
            if (pageBodyOnresize) {
                resizeObserver.observe(pageBodyOnresize);
            }
        }
        return () => {
            clearTimeout(timer);
        };
    }, [statisticsTabs, statisticsTime, mainEchartsOptions, secEchartsOptions]);
    useEffect(() => {
        getStatisticsData();
    }, [statisticsTime]);

    // ===================================================
    const { onPageChange, ...rest } = props;
    const { page } = rest;

    const pageChanged = (pageNum, pageSize) => {
        const newPage = {
            ...page,
            current: pageNum,
            pageSize: pageSize,
        };
        onPageChange(newPage);
    };

    return (
        <div>
            <div className="common-call-list-statistics-container">
                <h3>{locales.pages.gong.common.allCall}</h3>
                <Space>
                    <Radio.Group defaultValue={statisticsTabs} onChange={handlerClassChange}>
                        {Udesk.enums.statisticsType.map((item) => {
                            return (
                                <Radio.Button
                                    key={item.id}
                                    className={
                                        item.id === Udesk.enums.statisticsType.unit.id
                                            ? 'call-tabs-style-left'
                                            : 'call-tabs-style-right'
                                    }
                                    value={item.id}
                                >
                                    {item.name}
                                </Radio.Button>
                            );
                        })}
                    </Radio.Group>
                    <Radio.Group defaultValue={statisticsTime} onChange={handlerTimeChange}>
                        {Udesk.enums.statisticsDate.map((item) => {
                            return (
                                <Radio.Button
                                    key={item.id}
                                    className={
                                        item.id === Udesk.enums.statisticsDate.week.id
                                            ? 'call-tabs-style-left'
                                            : item.id === Udesk.enums.statisticsDate.season.id
                                                ? 'call-tabs-style-right'
                                                : ''
                                    }
                                    value={item.id}
                                >
                                    {item.name}
                                </Radio.Button>
                            );
                        })}
                    </Radio.Group>
                </Space>
            </div>
            <div className="page-call-analysis-all-call-list">
                {statisticsTabs === Udesk.enums.statisticsType.unit.id ? (
                    <div id="mainCanvas" style={{ width: '100%', height: '140px' }}></div>
                ) : (
                    <div id="secCanvas" style={{ width: '100%', height: '140px' }}></div>
                )}
                <FilterItem pageChanged={pageChanged} {...rest}></FilterItem>
            </div>
        </div>
    );
}

class Component extends React.Component {
    render() {
        return <FilterStatistics {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
