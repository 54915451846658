import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import DeviceModal, { RefType } from '../../components/device-modal';
import { useRequest } from 'src/util/hook';
import { Button, Rate, Tag } from "udesk-ui";
import { useFilterForm, usePagination } from "../../components";
import { createComponent } from 'src/util/component';
import { linkTo } from 'src/util/link';
import { linkToStart } from '../../components/util';
import { getDateTimeString } from 'src/util/moment';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { StandardizeTable } from 'udesk_gm_ui';
import { Cover } from '../../components/cover';

const getStartButtonStatus = (record) => {
    return [
        Udesk.enums.learningTaskStatusType.loseEfficacy.id,
        Udesk.enums.learningTaskStatusType.notStarted.id,
        Udesk.enums.learningTaskStatusType.finished.id,
    ].includes(record.taskStatus) || record.exerciseTimes >= record.limitTimes;
};

const Template = React.memo((props: any) => {
    const {saveStorages} = props;
    const [pagination, setPagination] = usePagination(props);
    const [filterConfig, lessonId] = useFilterForm(props, 1);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const request = useRequest();
    const modalRef = useRef<RefType>(null);

    const modalSubmit = useCallback((task) => {
        linkToStart(props.history, task.taskType, task.dialogueMode, {
            id: task.id
        });
    }, [props.history]);

    const getTableSource = useCallback((data) => {
        const {
            filter, 
            pagination,
        } = data;
        const { time, ...otherFilterValue } = filter || {};
        const [ startTime, endTime ] = time || [];

        saveStorages((storages) => {
            return {
                ...storages,
                filterData: filter,
                pagination: pagination,
            };
        });

        const params = {
            ...otherFilterValue,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            taskType: 2 // 1练习，2考试
        };
        if (startTime) {
            params.startTime = getDateTimeString(startTime);
        }
        if (endTime) {
            params.endTime = getDateTimeString(endTime, 'YYYY-MM-DD HH:mm:59');
        }
        if (lessonId) {
            params.lessonId = lessonId;
        }

        setLoading(true);
        request('/intelligentPartnerStudyTask/list', params).then(
            resp => {
                setTableData(resp.data ?? []);
                setPagination({
                    current: resp.paging.pageNum,
                    total: resp.paging.total,
                });
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        ).finally(() => {
            setLoading(false);
        });

    }, [lessonId, request, saveStorages, setPagination]);

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const isPad = window.innerWidth <= 1240;
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 任务列表 */UdeskLocales['current'].pages.coach.learningCenter.task.test.index.taskList,
                key: 'lessonName',
                dataIndex: 'lessonName',
                width: isPad ? 500 : undefined,
                ellipsis: isPad,
                render (text, record) {
                    return (
                        <div className='task-item'>
                            <div className={'course-logo'}>
                                <Cover src={record.cover} courseType={record.courseType} isPad={isPad} />
                            </div>
                            <div className={'course-info'}>
                                <div>
                                    <Tag color={
                                        ['','blue', 'green', 'orange'][record.dialogueMode]
                                    }>
                                        {Udesk.enums.learningTaskDialogueModeType.getName(record.dialogueMode)}
                                    </Tag>
                                    <span>{record.taskName}</span>
                                </div>
                                <div title={record.introduce}>{/* 课程介绍： */}{UdeskLocales['current'].pages.coach.learningCenter.task.test.index.courseIntroduction}{record.introduce}</div>
                                <div>{/* 考试难度： */}{UdeskLocales['current'].pages.coach.learningCenter.task.test.index.examDifficulty}<Rate allowHalf disabled defaultValue={record.difficultyLevel} /></div>
                                <div>{/* 考试通过要求： */}{UdeskLocales['current'].pages.coach.learningCenter.task.test.index.requirementsForPassingTheExam}{`${/* 考试得分≥ */UdeskLocales['current'].pages.coach.learningCenter.task.test.index.examScore}${record.completeByScore}${/* 分 */UdeskLocales['current'].pages.coach.learningCenter.task.test.index.branch}`}</div>
                                <div>
                                    {
                                        record.taskStartTime && record.taskEndTime ? (
                                            `${/* 考试时间： */UdeskLocales['current'].pages.coach.learningCenter.task.test.index.examTime}${record.taskStartTime}${/* 至 */UdeskLocales['current'].pages.coach.learningCenter.task.test.index.to}${record.taskEndTime}  `
                                        ): (
                                            /* 不限时间 */UdeskLocales['current'].pages.coach.learningCenter.task.test.index.unlimitedTime
                                        )
                                    }
                                    <Button type='link'>{Udesk.enums.learningTaskStatusType.getName(record.taskStatus)}</Button></div>
                            </div>
                        </div>
                    );
                }
            },
            {
                title: /* 完成状态 */UdeskLocales['current'].pages.coach.learningCenter.task.test.index.completionStatus,
                key: 'completeState',
                dataIndex: 'completeState',
                width: 100,
                render (text) {
                    return Udesk.enums.learningTaskScheduleStatusType2.getName(text);
                }
            },
            {
                title: /* 完成次数 */UdeskLocales['current'].pages.coach.learningCenter.task.test.index.completionTimes,
                key: 'exerciseTimes',
                dataIndex: 'exerciseTimes',
                width: 100,
                render (text, record) {
                    return<Button type={'link'}>{record.exerciseTimes}/{record.limitTimes}</Button>;
                }
            },
            {
                title: /* 最高分 */UdeskLocales['current'].pages.coach.learningCenter.task.test.index.maximumScore,
                key: 'highestScore',
                dataIndex: 'highestScore',
                width: 100,
            },
            {
                title: /* 操作 */UdeskLocales['current'].pages.coach.learningCenter.task.test.index.operation,
                key: 'id',
                dataIndex: 'id',
                width: isPad ? 200 : 250,
                render: (name, record) => {
                    return (
                        <div className={'task-btns' + (isPad ? ' column' : '')}>
                            <Button 
                                // 考试按钮置灰情况：
                                // 1 考试任务状态为：未开始、已结束、失效
                                // 2，考试次数达到限制次数
                                disabled={getStartButtonStatus(record)}
                                onClick={
                                    () => {
                                        modalRef.current?.open({
                                            task: record
                                        });
                                    }
                                } type={'link'}>{/* 立即考试 */}{UdeskLocales['current'].pages.coach.learningCenter.task.test.index.immediateExam}</Button>
                            <Button 
                                type={'link'} 
                                onClick={ 
                                    () => {
                                        linkTo(props.history, 'coachLearningCenterRecord', {}, {}, {
                                            taskId: record.id
                                        });
                                    }
                                }>{/* 历史记录 */}{UdeskLocales['current'].pages.coach.learningCenter.task.test.index.history}</Button>
                        </div>
                    );
                },
            },
        ];
        return {
            columns,
            dataSource: tableData,
            pagination,
            loading,
        };
    }, [tableData, pagination, props.history, loading]);

    useEffect(() => {
        $('.udesk-qa-ui-common-filter').on('click', '.udesk-qa-ui-btn-default', () => {
            return !saveStorages({
                filterData: {}
            });
        });
    }, [
        saveStorages
    ]);

    return (
        <div className='learning-center-tasks-table'>
            <StandardizeTable.RequestTable
                onRequest={getTableSource}
                table={tableProps}
                filter={filterConfig as any}
            />
            <DeviceModal ref={modalRef} modalSubmit={modalSubmit}></DeviceModal>
        </div>
    );
});

export default createComponent(
    Template,
    undefined,
    [
        /^\/coach\/learning-center\/task\/exercise\/*/i,
        /^\/coach\/learning-center\/task\/test\/*/i,
        /^\/coach\/learning-center\/record\/*/i,
    ]
);