// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerTaskInfosByLessonIdTaskScoreQuery,
    GetIntelligentPartnerTaskInfosByLessonIdTaskScoreParam,
    BaseResponseIntelligentPartnerTaskScoreDetail,
} from '../../../../types';

/**
 * 获取任务对应课程的总分详情
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/{lessonId}/task/score
 * @param lessonId
 */
export function getIntelligentPartnerTaskInfosByLessonIdTaskScore(
    options: ApiOptions<
        GetIntelligentPartnerTaskInfosByLessonIdTaskScoreParam,
        GetIntelligentPartnerTaskInfosByLessonIdTaskScoreQuery
    > & { segments: GetIntelligentPartnerTaskInfosByLessonIdTaskScoreParam }
): Promise<BaseResponseIntelligentPartnerTaskScoreDetail> {
    return request<
        never,
        GetIntelligentPartnerTaskInfosByLessonIdTaskScoreParam,
        GetIntelligentPartnerTaskInfosByLessonIdTaskScoreQuery
    >('/intelligentPartnerTaskInfos/{lessonId}/task/score', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerTaskInfosByLessonIdTaskScore',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/{lessonId}/task/score',
        method: 'get',
    },
];
