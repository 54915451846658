import Udesk from 'Udesk';
import React from 'react';
import AdminSystemIndexRoute from './route';
import { Radio, PageHeader, Switch, Button, Icon, Tooltip, Select, Checkbox } from 'udesk-ui';
import './style.scss';
import { enableQa } from 'Udesk/business';
import UdeskList from 'Component/common/udesk-list';
import { DataDeleteConfigItem } from './components/DataDeleteConfigItem';
import { DataDeleteConfig } from './component';
import UdeskLocales from 'UdeskLocales';

export default class AdminSystemIndexTemplate extends AdminSystemIndexRoute {
    render() {
        let { actions, locales, privates } = this;
        const company = Udesk.data.init.company;

        const imSendStatusFilterOptions = [
            { label: locales.pages.admin.system.template.shieldingWithdrawn, value: 1 },
            { label: locales.pages.admin.system.template.maskSendingFailed, value: 2 },
            {
                label: /* 屏蔽离线消息未读 */ locales.pages.admin.system.template
                    .maskOfflineMessageUnread,
                value: 4,
            },
        ];

        const dataDeleteConfig = privates.model.sysConfig.dataDeleteConfig || [];
        return (
            // <div className="page admin-system-index">
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.pages.admin.system.index.name}
                />
                <div className="udesk-qa-web-page-body">
                    <div
                        className="udesk-qa-web-page-body-root qa-page-admin-systom-index"
                        style={{ padding: 16 }}
                    >
                        <div className="page-content">
                            <div className="x_panel clearfix">
                                <div className="x_content clearfix">
                                    <div className="col-lg-9 col-md-9 col-xs-12 col-12">
                                        <If
                                            condition={
                                                Udesk.data.init.user &&
                                                Udesk.data.init.user.hasFeature('sys:info:view')
                                            }
                                        >
                                            <form className="form-horizontal form-label-left">
                                                <div className="form-group">
                                                    <label
                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                        style={{ padding: '0 15px' }}
                                                        htmlFor="system-name"
                                                    >
                                                        {
                                                            locales.pages.admin.system.index
                                                                .systemName
                                                        }
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <input
                                                            type="text"
                                                            id="system-name"
                                                            className="form-control"
                                                            value={privates.model.sysConfig.sysName}
                                                            disabled={
                                                                !Udesk.data.init.user ||
                                                                !Udesk.data.init.user.hasFeature(
                                                                    'sys:info:edit'
                                                                )
                                                            }
                                                            onChange={actions.onValueChanged.params(
                                                                privates.model.sysConfig,
                                                                'sysName'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                        style={{ padding: '0 15px' }}
                                                        htmlFor="system_speaker_recognize"
                                                    >
                                                        {
                                                            locales.pages.admin.system.index
                                                                .speakerRecognize
                                                        }
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <Radio.Group
                                                            value={
                                                                privates.model.sysConfig
                                                                    .talkerRecognitionType
                                                            }
                                                            onChange={actions.onValueChanged.params(
                                                                privates.model.sysConfig,
                                                                'talkerRecognitionType'
                                                            )}
                                                            disabled={
                                                                !Udesk.data.init.user ||
                                                                !Udesk.data.init.user.hasFeature(
                                                                    'sys:info:edit'
                                                                )
                                                            }
                                                        >
                                                            <For
                                                                each="item"
                                                                index="index"
                                                                of={
                                                                    Udesk.enums
                                                                        .talkerRecognitionType
                                                                }
                                                            >
                                                                <Radio
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    {item.name}
                                                                    <Button
                                                                        size="small"
                                                                        type="text"
                                                                        tooltip={
                                                                            locales.pages.admin
                                                                                .system.index[
                                                                                    Udesk.enums
                                                                                        .talkerRecognitionType
                                                                                        .API.id ===
                                                                                item.id
                                                                                        ? 'speakerRecognizeTip'
                                                                                        : 'speakerRecognizeTip2'
                                                                                ]
                                                                        }
                                                                        icon={
                                                                            <Icon
                                                                                type="QuestionCircleOutlined"
                                                                                antdIcon={true}
                                                                                style={{
                                                                                    color: 'rgba(0, 0, 0, 0.45)',
                                                                                }}
                                                                            />
                                                                        }
                                                                    ></Button>
                                                                </Radio>
                                                            </For>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                        style={{ padding: '0 15px' }}
                                                    >
                                                        {locales.pages.admin.system.index.massage}
                                                    </label>
                                                    <div
                                                        className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                        style={{ height: 30, lineHeight: '30px' }}
                                                    >
                                                        <Switch
                                                            checked={
                                                                privates.model.sysConfig
                                                                    .enabledMessage
                                                            }
                                                            disabled={
                                                                !Udesk.data.init.user ||
                                                                !Udesk.data.init.user.hasFeature(
                                                                    'sys:info:edit'
                                                                )
                                                            }
                                                            onChange={actions.onSwitchValueChanged.params(
                                                                privates.model.sysConfig,
                                                                'enabledMessage'
                                                            )}
                                                        ></Switch>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                        style={{ padding: '0 15px' }}
                                                    >
                                                        {locales.pages.admin.system.index.evaluate}
                                                        <Tooltip
                                                            title={
                                                                locales.pages.admin.system.index
                                                                    .evaluateTip
                                                            }
                                                        >
                                                            <Icon
                                                                type="QuestionCircleOutlined"
                                                                antdIcon={true}
                                                            ></Icon>
                                                        </Tooltip>
                                                    </label>
                                                    <div
                                                        className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                        style={{ height: 30, lineHeight: '30px' }}
                                                    >
                                                        <Switch
                                                            checked={
                                                                privates.model.sysConfig
                                                                    .enabledEvaluate
                                                            }
                                                            disabled={
                                                                !Udesk.data.init.user ||
                                                                !Udesk.data.init.user.hasFeature(
                                                                    'sys:info:edit'
                                                                )
                                                            }
                                                            onChange={actions.onSwitchValueChanged.params(
                                                                privates.model.sysConfig,
                                                                'enabledEvaluate'
                                                            )}
                                                        ></Switch>
                                                    </div>
                                                </div>
                                                {typeof privates.model.sysConfig
                                                    .enabledImSendStatusFilter === 'number' && (
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                            style={{ padding: '0 15px' }}
                                                        >
                                                            {
                                                                locales.pages.admin.system.index
                                                                    .enabledImSendStatusFilter
                                                            }
                                                            <Tooltip
                                                                title={
                                                                    locales.pages.admin.system.index
                                                                        .enabledImSendStatusFilterTips
                                                                }
                                                            >
                                                                <Icon
                                                                    type="QuestionCircleOutlined"
                                                                    antdIcon={true}
                                                                ></Icon>
                                                            </Tooltip>
                                                        </label>
                                                        <div
                                                            className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                            style={{
                                                                height: 30,
                                                                lineHeight: '30px',
                                                            }}
                                                        >
                                                            {/* <Switch
                                                                checked={
                                                                    privates.model.sysConfig.enabledImSendStatusFilter
                                                                }
                                                                disabled={
                                                                    !Udesk.data.init.user ||
                                                                    !Udesk.data.init.user.hasFeature('sys:info:edit')
                                                                }
                                                                onChange={actions.onSwitchValueChanged.params(
                                                                    privates.model.sysConfig,
                                                                    'enabledImSendStatusFilter'
                                                                )}
                                                            ></Switch> */}
                                                            <Checkbox.Group
                                                                value={(
                                                                    privates.model.sysConfig
                                                                        .enabledImSendStatusFilter ||
                                                                    0
                                                                )
                                                                    .toString(2)
                                                                    .split('')
                                                                    .reverse()
                                                                    .map(
                                                                        (n, i) => n * Math.pow(2, i)
                                                                    )
                                                                    .filter(Boolean)}
                                                                options={imSendStatusFilterOptions}
                                                                onChange={actions.imSendStatusFilterChange.params(
                                                                    privates.model.sysConfig,
                                                                    'enabledImSendStatusFilter'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {enableQa(
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                            style={{ padding: '0 15px' }}
                                                        >
                                                            {
                                                                /* 质检规则审批 */ locales.pages
                                                                    .admin.system.template
                                                                    .qualityInspectionRuleApproval
                                                            }
                                                            <Tooltip
                                                                title={
                                                                    /* 开启后，质检规则修改需要被审批通过后才能生效 */ locales
                                                                        .pages.admin.system.template
                                                                        .afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved
                                                                }
                                                            >
                                                                <Icon
                                                                    type="QuestionCircleOutlined"
                                                                    antdIcon={true}
                                                                ></Icon>
                                                            </Tooltip>
                                                        </label>
                                                        <div
                                                            className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                            style={{
                                                                height: 30,
                                                                lineHeight: '30px',
                                                            }}
                                                        >
                                                            <Switch
                                                                checked={
                                                                    privates.model.sysConfig
                                                                        .enabledInspectRuleApprove
                                                                }
                                                                disabled={
                                                                    !Udesk.data.init.user ||
                                                                    !Udesk.data.init.user.hasFeature(
                                                                        'sys:info:edit'
                                                                    )
                                                                }
                                                                onChange={actions.onSwitchValueChanged.params(
                                                                    privates.model.sysConfig,
                                                                    'enabledInspectRuleApprove'
                                                                )}
                                                            ></Switch>
                                                        </div>
                                                    </div>
                                                )}

                                                {Boolean(company.enabledCallInspect) && (
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                            style={{ padding: '0 15px' }}
                                                        >
                                                            {
                                                                locales.pages.admin.system.template
                                                                    .privacyNumberProtection
                                                            }
                                                            <Tooltip
                                                                title={
                                                                    locales.pages.admin.system
                                                                        .template
                                                                        .afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881
                                                                }
                                                            >
                                                                <Icon
                                                                    type="QuestionCircleOutlined"
                                                                    antdIcon={true}
                                                                ></Icon>
                                                            </Tooltip>
                                                        </label>
                                                        <div
                                                            className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                            style={{
                                                                height: 30,
                                                                lineHeight: '30px',
                                                            }}
                                                        >
                                                            <Switch
                                                                checked={
                                                                    privates.model.sysConfig
                                                                        .enabledPhoneNumberProtect
                                                                }
                                                                disabled={
                                                                    !Udesk.data.init.user ||
                                                                    !Udesk.data.init.user.hasFeature(
                                                                        'sys:info:edit'
                                                                    )
                                                                }
                                                                onChange={actions.onSwitchValueChanged.params(
                                                                    privates.model.sysConfig,
                                                                    'enabledPhoneNumberProtect'
                                                                )}
                                                            ></Switch>
                                                        </div>
                                                    </div>
                                                )}
                                                {Boolean(company.enabledImInspect) && (
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                            style={{ padding: '0 15px' }}
                                                        >{/* 文本数据脱敏 */}{UdeskLocales['current'].pages.admin.system.template.textDataDesensitization}<Tooltip
                                                                title={/* 开关开启则展示脱敏字段下拉列表（支持多选），脱敏字段：人名、手机号、地址 */UdeskLocales['current'].pages.admin.system.template.whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress}
                                                            >
                                                                <Icon
                                                                    type="QuestionCircleOutlined"
                                                                    antdIcon={true}
                                                                ></Icon>
                                                            </Tooltip>
                                                        </label>
                                                        <div
                                                            className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                            style={{
                                                                height: 30,
                                                                lineHeight: '30px',
                                                            }}
                                                        >
                                                            <Switch
                                                                checked={
                                                                    privates.model.sysConfig
                                                                        .enabledDataMask
                                                                }
                                                                disabled={
                                                                    !Udesk.data.init.user ||
                                                                    !Udesk.data.init.user.hasFeature(
                                                                        'sys:info:edit'
                                                                    )
                                                                }
                                                                onChange={actions.onSwitchValueChanged.params(
                                                                    privates.model.sysConfig,
                                                                    'enabledDataMask'
                                                                )}
                                                            ></Switch>
                                                        </div>
                                                    </div>
                                                )}
                                                {Boolean(
                                                    privates.model.sysConfig.enabledDataMask
                                                ) && (
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                            style={{ padding: '0 15px' }}
                                                        >{/* 脱敏字段 */}{UdeskLocales['current'].pages.admin.system.template.desensitizingField}</label>
                                                        <div
                                                            className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                            style={{
                                                                height: 30,
                                                                lineHeight: '30px',
                                                            }}
                                                        >
                                                            <Select
                                                                defaultValue={
                                                                    privates.model.sysConfig
                                                                        .dataMaskFields
                                                                }
                                                                mode="multiple"
                                                                disabled={
                                                                    !Udesk.data.init.user ||
                                                                    !Udesk.data.init.user.hasFeature(
                                                                        'sys:info:edit'
                                                                    )
                                                                }
                                                                options={[
                                                                    {
                                                                        value: 'sys_name',
                                                                        label: /* 人名 */UdeskLocales['current'].pages.admin.system.template.name,
                                                                    },
                                                                    {
                                                                        value: 'sys_phone',
                                                                        label: /* 手机号 */UdeskLocales['current'].pages.admin.system.template.cellphoneNumber,
                                                                    },
                                                                    {
                                                                        value: 'sys_address_privacy',
                                                                        label: /* 地址 */UdeskLocales['current'].pages.admin.system.template.address,
                                                                    },
                                                                ]}
                                                                onChange={actions.onSelectValueChanged.params(
                                                                    privates.model.sysConfig,
                                                                    'dataMaskFields'
                                                                )}
                                                                style={{
                                                                    width: 'auto',
                                                                    minWidth: 250,
                                                                }}
                                                            ></Select>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form-group">
                                                    <label
                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                        style={{ padding: '0 15px' }}
                                                    >
                                                        {locales.pages.admin.system.index.lang}
                                                    </label>
                                                    <div
                                                        className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                        style={{ height: 30, lineHeight: '30px' }}
                                                    >
                                                        <Select
                                                            defaultValue={
                                                                Udesk.data.init.userLanguage
                                                            }
                                                            disabled={
                                                                !Udesk.data.init.user ||
                                                                !Udesk.data.init.user.hasFeature(
                                                                    'sys:info:edit'
                                                                )
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'zh',
                                                                    label: locales.pages.admin
                                                                        .system.index.zh,
                                                                },
                                                                {
                                                                    value: 'en',
                                                                    label: locales.pages.admin
                                                                        .system.index.en,
                                                                },
                                                                {
                                                                    value: 'tw',
                                                                    label: locales.pages.admin
                                                                        .system.index.tw,
                                                                },
                                                            ]}
                                                            onChange={actions.onUserLanguageChange}
                                                            style={{
                                                                width: 200,
                                                            }}
                                                        ></Select>
                                                    </div>
                                                </div>
                                                {enableQa(
                                                    <React.Fragment>
                                                        <div className="form-group">
                                                            <label
                                                                className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                                style={{ padding: '0 15px' }}
                                                            >{/* 数据清理 */}{UdeskLocales['current'].pages.admin.system.template.dataCleaning}<Tooltip title={/* 设置后，质检通对话、企业微信会话数据将定期被清理，且不可恢复，请谨慎操作 */UdeskLocales['current'].pages.admin.system.template.afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful}>
                                                                    <Icon
                                                                        type="QuestionCircleOutlined"
                                                                        antdIcon={true}
                                                                    ></Icon>
                                                                </Tooltip>
                                                            </label>
                                                            <div
                                                                className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                                style={{
                                                                    height: 30,
                                                                    lineHeight: '30px',
                                                                }}
                                                            >
                                                                <Switch
                                                                    checked={
                                                                        privates.model.sysConfig
                                                                            .enabledDataDelete
                                                                    }
                                                                    disabled={
                                                                        !Udesk.data.init.user ||
                                                                        !Udesk.data.init.user.hasFeature(
                                                                            'sys:info:edit'
                                                                        )
                                                                    }
                                                                    onChange={actions.onSwitchValueChangedWithEffect.params(
                                                                        actions.onSwitchValueChanged,
                                                                        actions.addDataDeleteConfig.params(
                                                                            true
                                                                        ),
                                                                        privates.model.sysConfig,
                                                                        'enabledDataDelete'
                                                                    )}
                                                                ></Switch>
                                                            </div>
                                                        </div>
                                                        {!!privates.model.sysConfig
                                                            .enabledDataDelete && (
                                                            <div className="form-group">
                                                                <label
                                                                    className="col-lg-3 col-md-4 col-xs-12 col-12"
                                                                    style={{ padding: '0 15px' }}
                                                                ></label>
                                                                <div
                                                                    className="col-lg-9 col-md-8 col-xs-12 col-12"
                                                                    style={{ lineHeight: '30px' }}
                                                                >
                                                                    <UdeskList
                                                                        deleteItem={
                                                                            actions.deleteDataDeleteConfig
                                                                        }
                                                                        addItem={
                                                                            actions.addDataDeleteConfig
                                                                        }
                                                                        addBtnText={/* 新增清理规则 */UdeskLocales['current'].pages.admin.system.template.newCleaningRules}
                                                                        addBtnDisabled={
                                                                            dataDeleteConfig.length >=
                                                                            DataDeleteConfig
                                                                                .scopeOptions.length
                                                                        }
                                                                    >
                                                                        {dataDeleteConfig.map(
                                                                            (item, index) => {
                                                                                return (
                                                                                    <DataDeleteConfigItem
                                                                                        {...item}
                                                                                        onChange={actions.onDataDeleteConfigChange.params(
                                                                                            index
                                                                                        )}
                                                                                        usedScopes={dataDeleteConfig.map(
                                                                                            (i) =>
                                                                                                i.scope
                                                                                        )}
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </UdeskList>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                                <If
                                                    condition={
                                                        Udesk.data.init.user &&
                                                        Udesk.data.init.user.hasFeature(
                                                            'sys:info:edit'
                                                        )
                                                    }
                                                >
                                                    <div className="form-group">
                                                        <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
                                                            <Button
                                                                type="primary"
                                                                onClick={actions.save}
                                                            >
                                                                {locales.labels.save}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </If>
                                            </form>
                                        </If>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
