import React, { useState, useMemo, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import type { TableComponentProps } from 'Component/common/udesk-qa-table-page';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import { postWeComDataCallSearch } from 'src/api/weComDataCall/search';
import { getEmployeesAll } from 'src/api/employees/all';
import { getExternalUsersAll } from 'src/api/externalUsers/all';
import { EmployeeNameFoundResponse, ExternalUserNameFoundResponse, WeComDataCallSearchRequest } from 'src/api/types';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';
import moment from 'moment';

const DEFAULT_CALL_TIME = [
    moment().add(-30, 'day').format('YYYY-MM-DD 00:00:00'),
    moment().format('YYYY-MM-DD 23:59:59'),
];

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const { storagePage } = props;
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [originColumns, setOriginColumns] = useState<any[]>([]);
    const [externalUsers, setExternalUsers] = useState<ExternalUserNameFoundResponse[]>([]);
    const [employeesNames, setEmployeesNames] = useState<EmployeeNameFoundResponse[]>([]);
    const [pageNum, setPageNum] = useState(storagePage.pageNum);
    const [pageSize, setPageSize] = useState(storagePage.pageSize);
    const [total, setTotal] = useState(0);
    const [filterConditions, setFilterConditions] = useState<Omit<WeComDataCallSearchRequest, 'pageNum' | 'pageSize'>>(
        getCondition(props.storageFilter)
    );

    const columns = useMemo<any[]>(() => {
        return [
            {
                type: 'UdeskDatePicker',
                label: /* 通话时间 */ locales.pages.wechatQa.chartAudioRecord.index.talkTime,
                name: 'callTime',
            },
            {
                type: 'Select',
                label: /* 会话类型 */ locales.pages.wechatQa.chartAudioRecord.index.sessionType,
                name: 'sessionType',
                props: {
                    options: [
                        { label: /* 全部 */ locales.pages.wechatQa.chartAudioRecord.index.whole, value: null },
                    ].concat(Udesk.enums.chatTypes.map((i) => ({ label: i.name, value: i.id }))),
                    style: { width: 150 },
                },
            },
            {
                type: 'Select',
                label: /* 员工 */ locales.pages.wechatQa.chartAudioRecord.index.staff,
                name: 'staffId',
                props: {
                    options: employeesNames.map((i) => ({ label: i.name, value: i.userId })),
                    style: { width: 150 },
                    filterOption: (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                    mode: 'multiple',
                    maxTagTextLength: 8,
                    maxTagCount: 'responsive',
                },
            },
            {
                type: 'Select',
                label: /* 客户 */ locales.pages.wechatQa.chartAudioRecord.index.customer,
                name: 'customerId',
                props: {
                    options: externalUsers.map((i) => ({ label: i.nickName, value: i.externalUserId })),
                    style: { width: 150 },
                    filterOption: (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                    mode: 'multiple',
                    maxTagTextLength: 8,
                    maxTagCount: 'responsive',
                },
            },
            {
                type: 'Input',
                label: /* 通话关键词 */ locales.pages.wechatQa.chartAudioRecord.index.callKeywords,
                name: 'keyword',
            },
            ...originColumns,
        ];
    }, [externalUsers, employeesNames, originColumns]);

    const tableProps = useMemo<TableComponentProps>(() => {
        return {
            columns,
            dataSource,
            cacheKey: false,
            // subtractedHeight: 136,
            onRow: (record) => {
                return {
                    onClick: (event) => {
                        const routeOptions = {
                            history: props.history,
                            routeName: 'wechatQaChartRecordAudioDetail',
                            pathParams: {
                                id: record.id,
                            },
                            state: {
                                keyword: filterConditions.keyword
                            }
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    }, // 点击行
                };
            },
        };
    }, [columns, dataSource, filterConditions]);
    const paginationProps = useMemo(() => {
        return {
            current: pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {

                setPageNum(page);
                setPageSize(pageSize);

                props.saveStoragePage({
                    pageNum: page,
                    pageSize: pageSize,
                });
            },
        };
    }, [pageNum, pageSize, total]);
    useEffect(() => {
        let params = {
            pageNum,
            pageSize,
            ...filterConditions,
            // keyword: keywords
        };
        setLoading(true);

        postWeComDataCallSearch(params).then(
            (resp) => {
                setLoading(false);
                let fieldValueMaps: any[] = [];
                let data = resp.data;
                setTotal(data?.total ?? 0);
                if (data && data.fieldDataList && data.fieldDataList.length > 0) {
                    data.fieldDataList.forEach((item) => {
                        let fieldValueMap: any = item.fieldValueMap;
                        fieldValueMap.id = item.dataId;
                        fieldValueMaps.push(item.fieldValueMap);
                    });
                }
                setOriginColumns(
                    (data?.showFieldList ?? []).map((item) => {
                        return {
                            title: item.label,
                            dataIndex: item.id,
                        };
                    })
                );
                Promise.resolve().then(() => {
                    setDataSource(fieldValueMaps);
                });
            },
            (reason) => {
                setLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [filterConditions, pageNum, pageSize]);
    useEffect(() => {
        // setColumns([...filters]);
        getEmployeesAll().then((resp) => {
            setEmployeesNames(resp.data ?? []);
        });
        getExternalUsersAll().then((resp) => {
            setExternalUsers(resp.data ?? []);
        });
    }, []);
    const exportBtnProps = useMemo(() => {
        return {
            hasFilterCondition: true,
            onExport: () => {},
        };
    }, []);
    return (
        <Page pageBodyClassName='udesk-qa-chart-audio-record-page-index' padding={true}>
            <CommonTablePage
                loading={loading}
                // showExport={true}
                // showColumsControl={true}
                // showFilter={true}
                // filter={filterProps}
                exportBtn={exportBtnProps}
                searchFormInitialValues={props.storageFilter}
                customComponents={{ UdeskDatePicker }}
                onSearch={(values) => {
                    props.saveStorageFilter(values);
                    setFilterConditions(getCondition(values));
                }}
                onResetClick={(form) => {
                    const defaultValues = {
                        callTime: DEFAULT_CALL_TIME,
                        sessionType: undefined,
                        staffId: undefined,
                        customerId: undefined,
                        keyword: ''
                    };
                    form.setFieldsValue(defaultValues);
                }}
            >
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>
        </Page>
    );
});

function getCondition(values) {
    return {
        ...values,
        staffId: (values.staffId ?? []).join(','),
        customerId: (values.customerId ?? []).join(','),
        callTime:
            Array.isArray(values.callTime) && values.callTime[0] && values.callTime[1]
                ? values.callTime.join(',')
                : undefined,
    };
}

class Component extends React.Component<any, any> {
    privates = {
        storages: {
            selectedFilter: {
                callTime: DEFAULT_CALL_TIME,
            },
            page: {
                pageNum: 1,
                pageSize: 20,
                total: 0,
            },
        },
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'sessionStorage',
        extraStorages: [],
        resetStrategies: {
            transitionToRoutes: [],
            // notTransitionToRoutes: [/^\/site\/dialogue-manage\/view/i],
            notTransitionToRoutes: [/^\/site\/wechat-qa\/chart-record\/audio\/detail/i],
        },
    };

    actions = {
        saveStorageFilter(value) {
            (this as any).privates.storages.selectedFilter = value;
            (this as any).props?.onKeywordChange?.(value.keyword??'');
        },
        saveStoragePage(page) {
            (this as any).privates.storages.page = page;
        },
    };
    render() {
        return (
            <Template
                {...this.props}
                storageFilter={this.privates.storages.selectedFilter}
                saveStorageFilter={this.actions.saveStorageFilter}
                storagePage={this.privates.storages.page}
                saveStoragePage={this.actions.saveStoragePage}
            />
        );
    }
}
export default Udesk.react.udeskify(Component);
