import Udesk from 'Udesk';
import React from 'react';
import GearOptionListComponent from './component';
import './style.scss';

export default class GearOptionListTemplate extends GearOptionListComponent {
    render() {
        let { actions, locales, props, state } = this;
        return (
            <div className="gear-option-list-component">
                <For each="gearOption" index="index" of={state.gearOptions}>
                    <p key={gearOption._id} className="gear-option-list-item">
                        <If condition={props.type === Udesk.enums.scoreType.aiScore.id}>
                            <input
                                type="text"
                                className="form-control gear-option-list-item-col-input"
                                value={gearOption[props.formulaField]}
                                onChange={actions.changeGearOptionValue.params(gearOption, props.formulaField)}
                            />
                        </If>
                        <span className="gear-option-list-item-col-text">
                            {Udesk.enums.calculateScoreTypes.getName(props.scoreType)}
                        </span>
                        <input
                            type="number"
                            className="form-control gear-option-list-item-col-input"
                            value={gearOption[props.valueField]}
                            onChange={actions.changeGearOptionValue.params(gearOption, props.valueField)}
                        />
                        <span className="gear-option-list-item-col-text">
                            {locales.components.gearOptionList.evaluation}
                        </span>
                        <input
                            type="text"
                            className="form-control gear-option-list-item-col-input"
                            value={gearOption[props.evaluationField]}
                            onChange={actions.changeGearOptionValue.params(gearOption, props.evaluationField)}
                        />
                        <If condition={index > 0}>
                            <i
                                className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-delete_minus gear-option-list-item-delete-icon"
                                onClick={actions.deleteGearOption.params(gearOption)}></i>
                        </If>
                    </p>
                </For>
                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1" onClick={actions.addNewGearOption}></i>
            </div>
        );
    }
}
