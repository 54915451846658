import Udesk from 'Udesk';
import {useCallback, useState, useRef, useEffect, Dispatch} from 'react';
import { useSdkOptions } from '.';
import { fire, inspect, isDefined, isFunction, isUndefined } from '../core';

type ServiceType = (...args: any[]) => Promise<any>;
type ApiType = String | ServiceType;
type OptionsType = {
    autoUpdateData?: boolean; // 默认 true。 即在service reject 时，会调用setData方法。
    manual?: boolean; // 如果设置为 true | undefined，则需要手动调用 run 或 runAsync 触发执行。
    defaultApi?: ApiType // 首次默认执行时，传递给 service 的 API参数
    defaultParams?: any[], // 首次默认执行时，传递给 service 的参数
    onBefore?: (api: ApiType, ...params: any[]) => void, // service 执行前触发
    onSuccess?: (resp: any, api: ApiType, params: any, setData: Function) => void, // service resolve 时触发
    onError?: (reason: any, api: ApiType, params: any) => void, // service reject 时触发
    onFinally?: (api: ApiType, params: any) => void // service 执行完成时触发
}

interface ResultType extends ServiceType{
    data: any;
    loading: boolean;
    refresh: () => Promise<any>;
    run: ServiceType;
    mutate: Dispatch<any>;
}

export const useRequest: (options?: OptionsType) => ResultType = (options?) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>();

    const optionsRef = useRef<OptionsType>();
    const apiRef = useRef<ApiType>();
    const apiParamsRef = useRef<any[]>();
    const runRef = useRef<ServiceType>();
    const sdkOptionsRef = useRef<any>(useSdkOptions());

    const advanced = isDefined(optionsRef.current = options);

    runRef.current = useCallback((api: ApiType, ...params: any[]) => {        
        apiRef.current = api;
        apiParamsRef.current = params;

        let promise;

        inspect(advanced, () => {
            fire(optionsRef.current?.onBefore, api, ...params);
        });

        isFunction(api, () => {
            promise = fire(api, ...params);
        }, () => {
            let url = api; 
            if (sdkOptionsRef.current) {
                url = Udesk.business.apiPath.concatApiPath(
                    api,
                    sdkOptionsRef.current
                );
            }
            promise = new Promise((resolve, reject) => {
                Udesk.ajax[(params[1] || 'get').toLocaleLowerCase()](
                    url, params[0]
                ).then(
                    resolve, 
                    reject
                );
            });
        });

        inspect(advanced, () => {
            setLoading(true);
            promise.then(
                resp => {
                    fire(
                        optionsRef.current?.onSuccess, 
                        resp, api, params, setData
                    );
                    inspect(
                        isUndefined(optionsRef.current?.autoUpdateData) || inspect(optionsRef.current?.autoUpdateData),
                        () => {
                            setData(resp.data);
                        }
                    );
                    return resp;
                },
                reason => {
                    isFunction(api, () => {}, () => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    });
                    fire(
                        optionsRef.current?.onError, 
                        reason, api, params
                    );
                }
            );
            promise.finally(() => {
                setLoading(false);
                fire(
                    optionsRef.current?.onFinally, 
                    api, params
                );
            });
        });
        
        return promise;
    }, [
        advanced
    ]);

    const refresh = useCallback(() => {
        return runRef.current!(apiRef.current, ...apiParamsRef.current!);
    }, []);

    useEffect(() => {
        inspect(
            isDefined(optionsRef.current?.manual) && inspect(!optionsRef.current?.manual),
            () => {
                runRef.current?.(optionsRef.current?.defaultApi, ...(optionsRef.current?.defaultParams || []));
            }
        );
    }, []);

    if (advanced) {
        return {
            data,
            loading,
            refresh,
            run: runRef.current,
            mutate: setData,
        } as ResultType;
    }

    return runRef.current as ResultType;
};