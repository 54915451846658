import importSuccessNotification from 'Component/common/import-notification';
import React from 'react';
import { getSemanticTagReferenceById } from 'src/api/semantic-tag/reference/{id}';
import Udesk from 'Udesk';
import { Button, Space } from 'udesk-ui';
import { isAlgorithm, isSalesSubApp } from 'Udesk/system/subApp';
import { getSystemModule } from 'Udesk/system/subApp';

class SemanticTagsComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {

    };
    //#endregion

    state = {
        columns: [],
        pageNum: Udesk.config.paging.defaultPageNumber,
        pageSize: Udesk.config.paging.defaultPageSize,
        total: null,
    };
    privates = {
        keywords: null,
        isUploadVisible: false,
        file: null,
        uploadData: null,
        loading: false,
        selectedItems: [],
        dataSource: [],
        moduleType: null,
        viewReferenceVisible: false,
        currentTag: null,
    };

    actions = {
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        searchItems(e) {
            this.actions.reloadAsyncModel({
                pageNum: Udesk.config.paging.defaultPageNumber,
                pageSize: Udesk.config.paging.defaultPageSize,
            });
        },
        moduleTypeChange(value) {
            this.privates.moduleType = value;
            this.actions.update();
        },
        createItem() {
            let routeOptions = {
                history: this.props.history,
                routeName: isAlgorithm() ? 'algorithmSemanticTagsNew' : isSalesSubApp() ? 'salesSemanticTagsNew' : "semanticTagsNew",
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        editItem(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: isAlgorithm() ? 'algorithmSemanticTagsEdit' : isSalesSubApp() ? 'salesSemanticTagsEdit' : "semanticTagsEdit",
                pathParams: {
                    id: item.id
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        deleteItem(item) {
            let {
                locales
            } = this;
            let onConfirm = () => {
                let {
                    sdkOptions
                } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(`semantic-tag/${item.id}`, sdkOptions);
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg || locales.business.notifyMessage.deleteFailed);
                    }
                );
            };

            Udesk.ui.alerts.confirm({
                content: locales.labels.deleteContent,
                onOk: onConfirm
            });
        },
        changeUploadVisible() {
            let {
                isUploadVisible
            } = this.privates;
            this.privates.isUploadVisible = !isUploadVisible;
            this.actions.update();
        },
        handleFlowSave() {

        },
        uploadFile(e) {
            let {
                locales
            } = this;
            let files = e.target.files;

            if (files.length === 0) {
                return;
            }

            // 取消文件大小限制
            // if (files[0] && (files[0].size > 2 * 1024 * 1024)) {
            //     return Udesk.ui.notify.error(UDL.business.notifyMessage.overFileSize);
            // }
            let fileName = fileType(files[0].name);
            if (!fileName) {
                return;
            } else if (fileName !== 'xlsx') {
                return Udesk.ui.notify.error(Udesk.utils.string.format(locales.labels.fileTypeHolder, 'xlsx'));
            }
            //type 1语音,2导入,3导出,4语料数据
            Udesk.app.fileStorage.upload(files[0], {
                token: new Promise((resolve, reject) => {
                    Udesk.business.getServerAuthorization({
                        type: 2,
                    }).then(function (resp) {
                        resolve(resp);
                    }, function (reason) {
                        reject(reason);
                    });
                }),
                //progressCallback: progressCallback.bind(this),
                successCallback: this.actions.uploadFileSuccess.bind(this),
                errorCallback: this.actions.uploadFileError.bind(this),
            });
        },
        downloadTemplate() {
            let {
                sdkOptions
            } = this.props;

            let templateLocalUrl = `/import/semantic-tags-template.xlsx`;
            let templateUrl = Udesk.business.apiPath.getStaticBasePath(sdkOptions.props.domain, sdkOptions.props.pathPrefix) + templateLocalUrl;

            window.location = templateUrl;
        },
        uploadFileSuccess(file, uploadData) {
            this.privates.file = file;
            this.privates.uploadData = uploadData;
            this.actions.update();
        },
        uploadFileError(file, uploadData) {

        },
        importSemanticTags() {
            let {
                sdkOptions
            } = this.props;
            let {
                file,
                uploadData
            } = this.privates;
            if (file == null || uploadData == null) {
                return Udesk.ui.notify.error(this.locales.business.notifyMessage.pleaseUploadFile);
            }
            let params = {
                keyName: uploadData.key,
                fileName: file.name,
                systemModule: getSystemModule(),
            };
            let url = Udesk.business.apiPath.concatApiPath(`semantic-tag/import`, sdkOptions);
            Udesk.ajax.post(url, params)
                .then(
                    (resp) => {
                        Udesk.ui.notify.success(this.locales.business.notifyMessage.importFileSuccess);
                        this.actions.changeUploadVisible();
                        importSuccessNotification();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg || this.locales.business.notifyMessage.importFildFailed);
                        this.actions.changeUploadVisible();
                    }
                );
        },
        export() {
            let {
                sdkOptions,
            } = this.props;
            let getListUrl = Udesk.business.apiPath.concatApiPath(`/semantic-tag/export`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(getListUrl, { type: 1, systemModule: getSystemModule(), }).then(
                    resp => {
                        resolve(resp);
                        Udesk.ui.notify.success(resp.message);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        linkToTraining(index, item, trainType) {
            // trainType,1为快速，2为深度，和btn绑定，不需要修改 直接调用
            //跳转训练中心
            let itemNum = (this.state.pageNum - 1) * this.state.pageSize + index + 1;
            sessionStorage.setItem(
                'semanticTagsToFastTraining_pamars',
                JSON.stringify({
                    keywords: this.privates.keywords,
                    id: item.id,
                    title: item.title,
                    itemNum: itemNum
                })
            );
            const routeOptions = {
                history: this.props.history,
                routeName: isAlgorithm() ? 'algorithmFastTrainingIndex' : isSalesSubApp() ? 'salesFastTrainingIndex' : 'fastTrainingIndex'
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        onCheckChange(record, selected, selectedRows, nativeEvent) {
            let newSelectedItems = [...this.privates.selectedItems];
            if(selected){
                newSelectedItems.push(record);
            }else{
                newSelectedItems = newSelectedItems.filter(item => item.id !== record.id);
            }
            this.privates.selectedItems = newSelectedItems;
            this.actions.update();
        },
        onSelectAll(selected, selectedRows, changeRows) {
            let newSelectedItems = [...this.privates.selectedItems];
            if(selected){
                newSelectedItems = [...newSelectedItems, ...changeRows];
            }else{
                newSelectedItems = newSelectedItems.filter(item => !changeRows.find(r => r.id === item.id));
            }
            this.privates.selectedItems = newSelectedItems;
            this.actions.update();
        },
        batchDelete() {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `semantic-tag/delete`,
                sdkOptions
            );
            let params = {
                ids: this.privates.selectedItems.map(i => i.id),
            };
            return new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    resp => {
                        this.actions.reloadAsyncModel();
                        let failedCount = resp.data.failed;
                        let successCount = resp.data.success;
                        this.privates.selectedItems = [];
                        resolve({
                            failedCount,
                            successCount
                        });
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );    
            });
        },
        viewReference(item) {
            this.privates.currentTag = item;
            this.privates.viewReferenceVisible = true;
            this.actions.update();
            getSemanticTagReferenceById({
                segments: {
                    id: item.id
                }
            }).then(resp => {
                this.privates.currentTag = {...item, reference: resp.data??[]};
                this.actions.update();
            });
        },
        onViewReferenceVisibleClose() {
            this.privates.viewReferenceVisible = false;
            this.privates.currentTag = null;
            this.actions.update();
        },
    };

    //#region Life Cycle
    componentDidMount() {
        getColumns(this);
    }
    componentWillUnmount() {

    }
    //#endregion
}
function fileType(fileName) {
    if (!fileName || typeof fileName !== 'string') {
        return false;
    }
    let list = fileName.split('.');
    return list[list.length - 1];
}
function getColumns(that) {
    let {
        actions,
        locales
    } = that;
    let columns = [
        {
            dataIndex: "title",
            width: "15%",
            title: locales.fields.semanticTags.title
        },
        {
            dataIndex: "tags",
            width: "15%",
            title: locales.fields.semanticTags.tags,
            render: (name, item, index) => {
                let content;
                let tags = item.tags || [];
                if (tags && tags.length > 0) {
                    let tag = tags.filter(item => { return item.standardFlag === 1; });
                    if (tag && tag[0]) {
                        content = tag[0].content;
                    }
                }
                return (
                    <span title={content}>
                        {content}
                    </span>
                );
            }
        },
        {
            title: locales.fields.semanticTags.orgName,
            dataIndex: "type",
            render: (name, record) => record.type === 3 ? Udesk.enums.organizationTypes.getName(record.type) : /* 无 */locales.pages.semanticIntelligence.semanticTags.component.nothing
        },
        {
            dataIndex: "tagCount",
            width: "10%",
            title: locales.fields.semanticTags.tagCount
        },
        // {
        //     dataIndex: "usingCount",
        //     width: "10%",
        //     title: locales.fields.semanticTags.usingCount
        // },
        {
            dataIndex: "suggestion",
            width: "10%",
            title: locales.fields.semanticTags.suggestion,
            render: (name, item, index) => {
                // needSuggest是后端给的训练建议的字段名,0:两个都要，1：快速，2深度，null则不需要
                // suggestTimes是后端给的深度训练轮数的字段名
                let testFlag = item.needSuggest;
                let trainCount = item.suggestTimes;
                // 以下为测试
                // let testFlag = 0;
                // let trainCount = 2;
                return (
                    // 0:两个都要，1：快速，2深度，null则不需要
                    <Space>
                        {/* 快速训练 */}
                        <If condition={testFlag === 0 || testFlag === 1}>
                            <Button type='link' size='small' style={{padding: 0}} title={locales.components.pages.semanticTags.table.fastTraining} onClick={actions.linkToTraining.params(index, item, 1)}>
                                {locales.components.pages.semanticTags.table.fastTraining}
                            </Button>
                        </If>
                        <If condition={testFlag === 0 || testFlag === 2}>
                            <Button type='link' size='small' style={{padding: 0}} title={Udesk.utils.string.format(locales.components.pages.semanticTags.table.deepTraining, trainCount)} onClick={actions.linkToTraining.params(index, item, 2)}>
                                {Udesk.utils.string.format(locales.components.pages.semanticTags.table.deepTraining, trainCount)}
                            </Button>
                        </If>
                    </Space>
                );
            }
        },
        {
            dataIndex: "activeFlag",
            width: "10%",
            title: locales.fields.semanticTags.activeFlag,
            render: (name, item, index) => {
                return Udesk.enums.activeFlags.getName(item.activeFlag);
            }
        },
        {
            dataIndex: "actions",
            // width: 110,
            title: locales.fields.semanticTags.actions,
            render: (name, item, index) => {
                return (
                    <div className="qa-react-page-body-content-table-actions">
                        <Space>
                            <Button type='link' size='small' style={{padding: 0}} title={locales.labels.viewReference} onClick={actions.viewReference.params(item)}>
                                {locales.labels.viewReference}
                            </Button>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:semanticTag:edit")}>
                                <If condition={(item.moduleId && item.editEnable && item.type === 3) || !item.moduleId || item.type !== 3}>
                                    <Button type='link' size='small' style={{padding: 0}} title={locales.labels.edit} onClick={actions.editItem.params(item)}>
                                        {locales.labels.edit}
                                    </Button>
                                </If>
                                <If condition={item.moduleId && item.showEnable && !item.editEnable && item.type === 3}>
                                    <Button type='link' size='small' style={{padding: 0}} title={locales.labels.view} onClick={actions.editItem.params(item)}>
                                        {locales.labels.view}
                                    </Button>
                                </If>
                            </If>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:semanticTag:delete")}>
                                <Button type='link' size='small' danger style={{padding: 0}} title={locales.labels.delete} onClick={actions.deleteItem.params(item)}>
                                    {locales.labels.delete}
                                </Button>
                            </If>
                        </Space>
                    </div>
                );
            }
        }
    ];
    that.setState({
        columns
    });
}

// function uploadFileSuccess(file, uploadData) {
// let params = {
//     fileName: file.name,
//     keyName: uploadData.key
// };

/* POST /documents */
// UDK.ajax.post(`${UDK.config.urls.apiPath}/imps`, params)
//     .then((resp, textStatus, jqXHR) => {
//         Udesk.ui.notify.success(UDL.business.notifyMessage.files.uploadFileSuccess);
//         this.set("states.batchImportModalVisible", false);
//     }, function (reason) {
//         Udesk.ui.notify.error(reason.errorMsg || UDL.business.notifyMessage.files.uploadFileFailed);
//     });
// }

// function uploadFileError() {

// }

export default SemanticTagsComponent;