import React from 'react';
import Udesk from 'Udesk';
import LayoutMenu from 'Component/common/layout-menu';
import { Redirect } from 'react-router-dom';

class AdminEntry extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }
    actions = {
        transitionTo(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: item.key,
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
    };
    render() {

        let {
            route,
            location,
            sdkOptions,
        } = this.props;
        let {
            locales
        } = this;
        let navLists = [];

        // const isInspect = Udesk?.data?.init?.company?.enabledCallInspect !== 0 || Udesk?.data?.init?.company?.enabledImInspect !== 0;

        // 根据当前用户权限，组装菜单列表
        const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;

        if (hasFeature && hasFeature("sys:info:root")) {
            navLists.push({
                text: locales.menu.admin.system.text,
                pathName: "coachAdminSystemIndex",
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/system/");
                }
            });
        }
        if (hasFeature && hasFeature("sys:impExp:root")) {
            navLists.push({
                text: locales.menu.admin.taskCenter.text,
                pathName: "coachSystemTaskCenter",
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/task-center");
                }
            });
        }

        if (sdkOptions.props.landingPath != null && this.isRedirect(route.path, location.pathname)) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = null;
            if (navLists[0].pathName) {
                toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                    routeName: navLists[0].pathName
                });
            } else {
                toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                    routeName: navLists[0].children[0].pathName
                });
            }

            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className="admin-entry">
                    <LayoutMenu {...this.props} navLists={navLists} menuTitle={locales.components.pages.menuTitleList.adminTitle} />
                </div>
            );
        }
    }
}
export default Udesk.react.udeskify(AdminEntry);