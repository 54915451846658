import React, { useState, useMemo, useEffect, useImperativeHandle } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { FormBuilder, Space, Select, InputNumber } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import { postAutoMateTasksSampleTarget } from 'src/api/autoMateTasks/sampleTarget';
import { getAutoMateTasksSampleTargetByTaskId } from 'src/api/autoMateTasks/sampleTarget/{taskId}';
// import './style.scss';

const hours = new Array(24).fill(1).map((_, i) => ({ label: i + /* 时 */Locales['current'].components.samplingTarget.index.time, value: `${i}` }));

const Display = (props) => <div>{props.target}</div>;
const Warning = (props) => {
    const locales = Locales['current'];

    const { type, value, onChange } = props;
    const { warningTime, warningNum } = value;

    const [days] = useState<any>(() => {
        const days: any = [];
        for (let i = 1; i <= 32; i++) {
            days.push({
                label: i > 31 ? locales.labels.theLastDayOfMonth : Udesk.utils.string.format(locales.labels.theDay, i),
                value: `${i}`,
            });
        }
        return days;
    });
    const options = useMemo(() => {
        if (type === 'week') {
            return Udesk.enums.samplingTargetWeekDays.map((i) => ({ label: i.name, value: i.key }));
        } else if (type === 'month') {
            return days;
        } else if (type === 'day') {
            return hours;
        }
        return [];
    }, [type]);

    return (
        <Space>
            <Select
                onChange={(value) => {
                    onChange({
                        warningTime: value,
                        warningNum,
                    });
                }}
                value={warningTime}
                options={options}
                style={{ width: 200 }}
            />
            <div>{/* 抽检数量低于 */}{locales.components.samplingTarget.index.samplingQuantityIsLessThan}</div>
            <InputNumber
                value={warningNum}
                min={0}
                onChange={(value) => {
                    onChange({
                        warningTime,
                        warningNum: value,
                    });
                }}
            />
            <div>{/* 时进行预警 */}{locales.components.samplingTarget.index.alertWhen}</div>
        </Space>
    );
};

const cycleTypeOptions = [
    { label: () =>/* 每日 */Locales['current'].components.samplingTarget.index.everyday, value: 'day' },
    { label: () =>/* 每周 */Locales['current'].components.samplingTarget.index.weekly, value: 'week' },
    { label: () =>/* 每月 */Locales['current'].components.samplingTarget.index.monthly, value: 'month' },
];

const Template = React.forwardRef((props: any, ref: any) => {
    const { taskId } = props;
    const locales = Locales['current'];

    const [wrapForm] = FormBuilder.useForm();
    useImperativeHandle(ref, () => ({
        formInstance: wrapForm,
    }));
    const [initialValues, setInitialValues] = useState<any>({
        // target: '每位客服',
        // cycleType: 'week',
        // targetNum: 100,
        warning: {
            //     warningNum: 80,
        },
    });
    const [originData, setOriginData] = useState<any>({});
    const [type, setType] = useState<any>(initialValues.cycleType);
    const onValuesChange = (changedValues: any, values: any) => {
        if (type !== values.cycleType) {
            setType(values.cycleType);
        }
    };
    const onFinish = (values) => {
        const params = { ...originData, taskType: 10, taskId };
        params.sampleTargetConf = {
            ...(params.sampleTargetConf || {}),
            ...values,
            ...(values.warning || {}),
        };
        delete params.sampleTargetConf.warning;

        postAutoMateTasksSampleTarget(params, {
            successMsg: /* 抽检目标保存成功 */locales.components.samplingTarget.index.samplingInspectionTargetSavedSuccessfully,
        });
    };
    const config: FormProps = useMemo(
        () => ({
            initialValues: initialValues,
            onFinish: onFinish,
            onValuesChange: onValuesChange,
            customComponents: { Display, Warning },
            fields: [
                {
                    name: 'target',
                    label: /* 人员 */locales.components.samplingTarget.index.personnel,
                    type: 'Display',
                    props: {
                        target: /* 每位客服 */locales.components.samplingTarget.index.eachCustomerService,
                    },
                },
                {
                    name: 'cycleType',
                    label: /* 周期 */locales.components.samplingTarget.index.cycle,
                    type: 'Select',
                    props: {
                        options: cycleTypeOptions.map(i => ({...i, label: i.label()})),
                        style: {
                            width: 200,
                        },
                    },
                    effect: {
                        value: {
                            day: [
                                {
                                    name: 'warning',
                                    output: {
                                        warningTime: undefined,
                                        warningNum: initialValues.warning.warningNum,
                                    },
                                },
                            ],
                            week: [
                                {
                                    name: 'warning',
                                    output: {
                                        warningTime: undefined,
                                        warningNum: initialValues.warning.warningNum,
                                    },
                                },
                            ],
                            month: [
                                {
                                    name: 'warning',
                                    output: {
                                        warningTime: undefined,
                                        warningNum: initialValues.warning.warningNum,
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    name: 'targetNum',
                    label: /* 抽检目标 */locales.components.samplingTarget.index.samplingInspectionTarget,
                    type: 'InputNumber',
                    // addonBefore: '完成抽检',
                    addonAfter: /* 条 */locales.components.samplingTarget.index.strip,
                    props: {
                        style: {
                            width: 200,
                        },
                        min: 0,
                    },
                },
                {
                    name: 'warning',
                    label: /* 预警设置 */locales.components.samplingTarget.index.alertSettings,
                    type: 'Warning',
                    props: {
                        type,
                    },
                },
            ] as any,
            footerRender: false,
            labelCol: { span: 2 },
            wrapperCol: { span: 14 },
        }),
        [type, initialValues, taskId]
    );
    useEffect(() => {
        getAutoMateTasksSampleTargetByTaskId({
            segments: {
                taskId,
            },
        }).then((reps) => {
            const sampleTargetConf: any = Object.assign({}, reps.data?.[0].sampleTargetConf);
            sampleTargetConf.warning = {
                warningNum: sampleTargetConf.warningNum,
                warningTime: sampleTargetConf.warningTime,
            };
            delete sampleTargetConf.warningNum;
            delete sampleTargetConf.warningTime;
            setOriginData(reps.data?.[0] ?? {});
            setInitialValues(sampleTargetConf);
            if (sampleTargetConf.cycleType !== type) {
                setType(sampleTargetConf.cycleType);
            }
        });
    }, []);
    useEffect(() => {
        wrapForm.resetFields();
    }, [initialValues]);
    return <FormBuilder {...config} form={wrapForm}></FormBuilder>;
});

export default Template;
