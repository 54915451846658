import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { RegExpPassword } from 'src/util/regExp';
import { encryptByRSA, PUBLICKEY } from 'src/util/crypt';

const MAX_PICTURE_SIZE = 500000;

export default class AdminUserDetailComponent extends React.Component {
    //#region privates
    privates = {
        resetPasswordModalVisible: false,
        password: {
            password: '',
            newPassword: '',
            originPassword: '',
            resumePassword: '',
        },
        enableValidation: false,
        resetPwEnableValidation: false,
        headPortraitUrl: null,
    };
    //#endregion

    //#region Computed properties
    static computes = {
        isEditing: [
            'privates.model.user.id',
            function ({ props, state, privates, locales }) {
                return privates.model && privates.model.user.id ? true : false;
            },
        ],
        _originPasswordValidation: [
            'privates.password.originPassword',
            'privates.enableValidation',
            function ({ props, state, privates, locales }) {
                let originPassword = privates.password.originPassword;

                if (originPassword === '' || originPassword == null) {
                    return {
                        isValid: false,
                        messages: [UdeskLocales.current.pages.admin.user.detail.passwordFormWrong],
                    };
                } else if (!RegExpPassword.test(originPassword)) {
                    console.log(originPassword);
                    return {
                        isValid: false,
                        messages: ['请输入8-16位大小写字母与数字和特殊字符组合的密码！'],
                    };
                } else {
                    return {
                        isValid: true,
                        messages: [],
                    };
                    // if (regExp.test(originPassword)) {
                    //     return {
                    //         isValid: true,
                    //         messages: []
                    //     };
                    // } else {
                    //     return {
                    //         isValid: false,
                    //         messages: [UdeskLocales.current.pages.admin.user.detail.passwordFormWrong]
                    //     };
                    // }
                }
            },
        ],
        _passwordValidation: [
            'privates.password.password',
            'privates.enableValidation',
            function ({ props, state, privates, locales }) {
                // let regExp = /^[0-9a-zA-Z]{6,14}$/;
                let password = privates.password.password;

                if (password === '' || password == null) {
                    return {
                        isValid: false,
                        messages: [UdeskLocales.current.pages.admin.user.detail.passwordFormWrong],
                    };
                } else {
                    return {
                        isValid: true,
                        messages: [],
                    };
                    // if (regExp.test(password)) {
                    //     return {
                    //         isValid: true,
                    //         messages: []
                    //     };
                    // } else {
                    //     return {
                    //         isValid: false,
                    //         messages: [UdeskLocales.current.pages.admin.user.detail.passwordFormWrong]
                    //     };
                    // }
                }
            },
        ],
        _newPasswordValidation: [
            'privates.password.newPassword',
            'privates.enableValidation',
            function ({ props, state, privates, locales }) {
                let newPassword = privates.password.newPassword;

                if (newPassword === '' || newPassword == null) {
                    return {
                        isValid: false,
                        messages: [UdeskLocales.current.pages.admin.user.detail.passwordFormWrong],
                    };
                } else {
                    if (RegExpPassword.test(newPassword)) {
                        return {
                            isValid: true,
                            messages: [],
                        };
                    } else {
                        return {
                            isValid: false,
                            messages: ['请输入8-16位大小写字母与数字和特殊字符组合的密码！'],
                        };
                    }
                }
            },
        ],
        _resumePasswordValidation: [
            'privates.password.resumePassword',
            'privates.password.newPassword',
            'privates.enableValidation',
            function ({ props, state, privates, locales }) {
                let password = privates.password.originPassword || privates.password.newPassword;
                let resumePassword = privates.password.resumePassword;

                if (resumePassword === '' || resumePassword == null) {
                    return {
                        isValid: false,
                        messages: [
                            UdeskLocales.current.pages.admin.user.detail.resumePasswordWrong,
                        ],
                    };
                } else {
                    if (resumePassword === password) {
                        return {
                            isValid: true,
                            messages: [],
                        };
                    } else {
                        return {
                            isValid: false,
                            messages: [
                                UdeskLocales.current.pages.admin.user.detail.resumePasswordWrong,
                            ],
                        };
                    }
                }
            },
        ],
        _creatPasswordValidation: [
            'privates.computes._originPasswordValidation',
            'privates.computes._resumePasswordValidation',
            function ({ props, state, privates, locales }) {
                let originPasswordValidation = privates.computes._originPasswordValidation;
                let resumePasswordValidation = privates.computes._resumePasswordValidation;

                if (originPasswordValidation.isValid && resumePasswordValidation.isValid) {
                    return true;
                } else {
                    return false;
                }
            },
        ],
        _resetPasswordValidation: [
            'privates.computes._passwordValidation',
            'privates.computes._newPasswordValidation',
            'privates.computes._resumePasswordValidation',
            function ({ props, state, privates, locales }) {
                let userId = privates.model && privates.model.user.id;
                let passwordValidation = privates.computes._passwordValidation;
                let newPasswordValidation = privates.computes._newPasswordValidation;
                let resumePasswordValidation = privates.computes._resumePasswordValidation;

                if (userId === Udesk.data.init.user.id) {
                    if (
                        passwordValidation.isValid &&
                        newPasswordValidation.isValid &&
                        resumePasswordValidation.isValid
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (newPasswordValidation.isValid && resumePasswordValidation.isValid) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
        ],
    };
    //#endregion

    //#region Life Cycle
    onModelResolved(model) {
        let headPortraitUrl = model.user.headPortraitUrl;
        if (headPortraitUrl) {
            this.privates.headPortraitUrl = headPortraitUrl;
        }
    }
    //#endregion

    //#region actions
    actions = {
        onValueChanged(obj, key, e) {
            let value = e;
            if (e && e.target) {
                value = e.target.value;
            }
            obj[key] = value;
            this.actions.update();
        },

        save(e) {
            let user = Object.assign({}, this.privates.model.user);
            let isEditing = this.privates.computes.isEditing;

            if (!isEditing) {
                this.privates.enableValidation = true;
                let passwordValidation = this.privates.computes._creatPasswordValidation;

                if (passwordValidation) {
                    user.password = this.privates.password.originPassword;
                } else {
                    return this.actions.update();
                }
            }

            if (!isEditing) {
                if (user.mobile === '') {
                    user.mobile = null;
                }
            }

            if (user.roleList) {
                if (!(user.roleList instanceof Array)) user.roleList = [user.roleList];
            } else {
                user.roleList = [];
            }
            if (user.roleList.length === 0) {
                this.actions.update();
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.columns.admin.user.role
                    )
                );
            }
            let url = isEditing
                ? Udesk.business.apiPath.concatApiPath(`/users/${user.id}`, this.props.sdkOptions)
                : Udesk.business.apiPath.concatApiPath(`/users`, this.props.sdkOptions);
            var method = isEditing
                ? Udesk.enums.requestMethods.update.name
                : Udesk.enums.requestMethods.create.name;
            this.actions.update();

            user.password = encryptByRSA(PUBLICKEY, user.password);

            Udesk.ajax(url, user, method).then(
                (resp) => {
                    this.actions.userHistoryBack();
                    if (isEditing) {
                        if (Udesk.data.init.user.id === user.id) {
                            Udesk.ui.alerts.confirm({
                                content:
                                    UdeskLocales.current.pages.admin.user.detail.reloadBowerInfo,
                                onOk: () => {
                                    window.location.reload();
                                },
                            });
                        }
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || UdeskLocales.current.pages.admin.user.saveUserError
                    );
                }
            );
        },

        openResetPasswordModal() {
            this.privates.resetPasswordModalVisible = true;
            this.actions.update();
        },

        resetPassword() {
            if (!this.privates.resetPwEnableValidation) {
                this.privates.resetPwEnableValidation = true;
            }

            let passwordValidation = this.privates.computes._resetPasswordValidation;
            if (!passwordValidation) {
                return this.actions.update();
            }

            let data = {
                id: this.privates.model.user.id || Udesk.data.init.user.id,
                password: this.privates.password.password
                    ? encryptByRSA(PUBLICKEY, this.privates.password.password)
                    : '',
                newPassword: this.privates.password.newPassword
                    ? encryptByRSA(PUBLICKEY, this.privates.password.newPassword)
                    : '',
                confirmNewPassword: this.privates.password.resumePassword
                    ? encryptByRSA(PUBLICKEY, this.privates.password.resumePassword)
                    : '',
            };
            this.actions.update();
            Udesk.ajax
                .put(
                    Udesk.business.apiPath.concatApiPath(`/resetPassword`, this.props.sdkOptions),
                    data
                )
                .then(
                    (resp) => {
                        Udesk.ui.notify.success(
                            UdeskLocales.current.pages.admin.user.detail.resetPasswordSuccess
                        );
                        this.actions.userHistoryBack();
                    },
                    function (reason) {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                UdeskLocales.current.pages.admin.user.detail.resetPasswordFailed
                        );
                    }
                );
        },

        hidePasswordModal() {
            this.privates.resetPasswordModalVisible = false;
            this.privates.resetPwEnableValidation = false;
            this.privates.password = {
                password: '',
                newPassword: '',
                resumePassword: '',
            };
            this.actions.update();
        },

        uploadAvatar(e) {
            let file = e.target.files[0];
            if (file.size > MAX_PICTURE_SIZE) {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.business.info.pictureUploadFailedSize
                );
            }

            Udesk.app.fileStorage.upload(file, {
                token: new Promise((resolve, reject) => {
                    Udesk.business
                        .getServerAuthorization(Udesk.enums.uploadFileTypes.image.id)
                        .then(
                            function (resp) {
                                resolve(resp);
                            },
                            function (reason) {
                                reject(reason);
                            }
                        );
                }),
                //progressCallback: progressCallback.bind(this),
                successCallback: uploadFileSuccess.bind(this),
                //errorCallback: uploadFileError.bind(this),
            });
        },

        userHistoryBack() {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('adminUserIndex'),
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
    };
    //#endregion
}

function uploadFileSuccess(file, uploadData) {
    Udesk.ui.notify.success(UdeskLocales.current.labels.saveSuccessAndReload);
    let fileUrl = `${uploadData.token.host}/${uploadData.key}`;
    this.privates.headPortraitUrl = fileUrl;
    this.privates.model.user.headPortraitUrl = uploadData.key;
    this.actions.update();
}
