import React from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import PageLeftTable from './components/pageLeftTable';
import PageRightTable from './components/pageRightTable';
import { createComponent, useStorageProps } from 'src/util/component';
import { Tabs } from 'udesk-ui';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const { TabPane } = Tabs;

export const TabKey = {
    Text: '1',
    Call: '2',
};

const Template = React.memo<any>((props) => {
    const [tabKey, setTabKey] = useStorageProps(props, 'tabKey', TabKey.Text);

    return (
        <Page
            pageClassName='intelligent-extractors-page-index'
            pageBodyClassName='intelligent-extractors-page-body'
            bodyStyle={{ height: '100%' }}
            title={/* 智能抽取 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.index.intelligentExtraction}
            isRouteEntry={true}
            footer={
                <Tabs 
                    className='intelligent-extractors-index-tabs' 
                    activeKey={tabKey} 
                    onChange={setTabKey}>
                    <TabPane tab={/* 文档抽取 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.index.documentExtraction} key={TabKey.Text}></TabPane>
                    <TabPane tab={/* 对话抽取 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.index.dialogueExtraction} key={TabKey.Call}></TabPane>
                </Tabs>
            }
        >
            <div className={'intelligent-extractors-page-content'}>
                <PageLeftTable {...props}/>
                <PageRightTable {...props}/>
            </div>
        </Page>
    );
});

const defaultPagination = {
    current: Udesk.config.paging.defaultPageNumber,
    pageSize: Udesk.config.paging.defaultPageSize,
    total: 0,
};

export default createComponent(
    Template,
    {
        tabKey: TabKey.Text,
        leftPagePageNum: Udesk.config.paging.defaultPageNumber,
        leftPageSelectedRow: null as any,
        rightPagePagination: defaultPagination,
    },
    [
        /^\/algorithm-platform\/semantic-intelligence\/intelligent-extractors\/*/i,
    ]
);
