import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Udesk from 'Udesk';
import { IntelligentPartnerTaskScoreDetail } from 'src/api/types';
import { createClassName, inspect } from 'src/util/core';
import { useLiveEffect } from 'src/util/hook';
// import moment from 'moment';
import { Spin, Modal, Input, Timeline, Icon } from "udesk-ui";
import { useVideo } from '../camera';
import { useAudio } from '../record';
import { isExercise } from '../util';
import { getIntelligentPartnerTaskInfosByLessonIdTaskScore } from 'src/api/intelligentPartnerTaskInfos/{lessonId}/task/score';
import './index.less';
import UdeskLocales from 'UdeskLocales';

export type RefType = {
    open: (task: any) => void;
}

export default React.forwardRef<RefType, any>((props, ref) => {
    const {
        modalSubmit
    } = props;
    const [isVisible, setIsVisible] = useState(false);
    const [task, setTask] = useState<any>();
    const [taskScoreDetail, setTaskScoreDetail] = useState<IntelligentPartnerTaskScoreDetail>();
    const [deviceInfoData, setDeviceInfoData] = useState({});

    // task.commitScore // 标准
    // task.excellentScore // 优秀

    // task.courseType === Udesk.enums.lessonType.question.id // 单题作答时长限制
    // task.limitDuration // 练习时间
    // task.singleLimitDuration // 单题作答时间
    // task.limitTimes // 考试完成次数
    // task.dialogueMode // 对话模式
    
    // task.completeByTimes // 练习完成次数
    // task.completeByScore // 任务完成分数

    useImperativeHandle(ref, () => {
        return {
            open(data) {
                const {
                    task,
                    taskScoreDetail,
                } = data;
                setTask(task);
                setTaskScoreDetail(taskScoreDetail);
                setIsVisible(true);
            }
        };
    });

    // 获取任务详情信息
    useLiveEffect((success) => {
        if (task) {
            if (task.taskScoreDetail) {
                setTaskScoreDetail(task.taskScoreDetail);
            } else {
                getIntelligentPartnerTaskInfosByLessonIdTaskScore({
                    segments: {
                        lessonId: task?.lessonId
                    }
                }).then(
                    resp => {
                        success(() => {
                            setTaskScoreDetail(resp.data);
                        });
                    }
                );
            }
        }
    }, [task]);

    const submitHandle = useCallback(() => {
        modalSubmit(task);
    }, [
        modalSubmit, 
        task
    ]);

    const cancelHandle = useCallback(() => {
        setIsVisible(false);
    },[]);

    const visibility = useMemo(() => {
        const enableRecordVideo = Boolean(task?.enableRecordVideo);
        const isText = task?.dialogueMode === Udesk.enums.learningTaskDialogueModeType.text.id;

        return [
            !isText,
            !enableRecordVideo
        ];
    }, [
        task?.dialogueMode, 
        task?.enableRecordVideo
    ]);

    const okButtonProps = useMemo(() => {
        return {
            disabled: Reflect.ownKeys(deviceInfoData).some(item => !Reflect.get(deviceInfoData, item))
        };
    }, [deviceInfoData]);

    return (
        <Modal 
            wrapClassName={
                createClassName('record-device-modal', visibility.some(item => item) ? 'double' : '')
            }
            width={visibility.some(item => item) ? 760 : 500}
            zIndex={9999}
            title={/* 开始前请您认真阅读一下要求 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.pleaseReadTheRequirementsCarefullyBeforeStarting}
            cancelText={/* 返回 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.return}
            okText={/* 我知道了 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.iGotIt}
            maskClosable={false}
            getContainer={false}
            visible={isVisible} 
            okButtonProps={okButtonProps}
            onOk={submitHandle} 
            onCancel={cancelHandle}
        >
            <Spin spinning={false} delay={500}>
                <div className='record-device-modal-content'>
                    <div>
                        <div className='task-info blue'>
                            <div>
                                <div>
                                    {
                                        inspect(task?.courseType === Udesk.enums.lessonType.question.id, /* 单题作答时长限制 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.timeLimitForSingleQuestionAnswering, /* 总限时 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.totalTimeLimit)
                                    }
                                </div>
                                <div>
                                    {
                                        inspect(
                                            () => task?.courseType === Udesk.enums.lessonType.question.id, 
                                            () => task?.singleLimitDuration && task?.singleLimitDuration + 'min', 
                                            () => task?.limitDuration && task?.limitDuration + 'min'
                                        ) || /* 不限时 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.unlimitedTime
                                    }
                                </div>
                            </div>
                            <div>
                                <div>{/* 总完成任务还需 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.stillNeedToCompleteTheTaskInTotal}</div>
                                <div className='blue'>
                                    {
                                        isExercise(task?.taskType, () => {
                                            const list = new Array<string>();
                                            if (task?.completeByTimes) {
                                                list.push(`${/* 练习 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.practice}${Math.max(task?.completeByTimes - (task?.completedTimes || 0) - (task?.exerciseTimes || 0), 0)}${/* 次 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.second}`);
                                            }
                                            if (task?.completeByScore) {
                                                list.push(`${/* 任务一次得分≥ */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.taskScore}${task.completeByScore}${/* 分 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.branch}`);
                                            }
                                            return list.join(/* 或 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.or);
                                        }, () => {
                                            return `${/* 考试得分≥ */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.examScore}${task.completeByScore}${/* 分 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.branch}`;
                                        })
                                    }
                                </div>
                            </div>
                            {
                                isExercise(task?.taskType, null, (
                                    <div>
                                        <div>{/* 剩余考试次数 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.remainingExamTimes}</div>
                                        <div className='blue'>
                                            {`${Math.max(task?.limitTimes - (task?.completedTimes || 0) - (task?.exerciseTimes || 0), 0)}${/* 次 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.second}`}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='task-info green'>
                            <div>
                                <div>{/* 总分 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.totalScore}<span style={{fontSize: 12}}>{/* （话术 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.Script}{taskScoreDetail?.speechCraftScore}{/* 分+情绪 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.scoreEmotion}{taskScoreDetail?.emotionScore}{/* 分+表达 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.fractionExpression}{taskScoreDetail?.speedScore}{/* 分） */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.points}</span>
                                </div>
                                <div>
                                    {
                                        (taskScoreDetail?.emotionScore || 0) + 
                                        (taskScoreDetail?.speechCraftScore || 0) + 
                                        (taskScoreDetail?.speedScore || 0)
                                    }{/* 分 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.branch}</div>
                            </div>
                            <div>
                                <div>{/* 达标分 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.standardScore}</div><div>{task?.commitScore}{/* 分 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.branch}</div>
                            </div>
                            <div>
                                <div>{/* 优秀分 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.excellentScore}</div><div>{task?.excellentScore}{/* 分 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.branch}</div>
                            </div>
                        </div>
                        <div className='task-info-description'>
                            <div>
                                {
                                    isExercise(task?.taskType, () => {
                                        return /* 练习说明 */UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.exerciseInstructions;
                                    }, () => {
                                        return /* 考试说明 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.examInstructions;
                                    })
                                }
                            </div>
                            <Input.TextArea 
                                autoSize={{ minRows: 5, maxRows: 8 }} 
                                value={task?.taskDesc} 
                            />
                        </div>
                    </div>
                    <DeviceInfo visibility={visibility} setDeviceInfoData={setDeviceInfoData} />
                </div>
            </Spin>
        </Modal>
    );
});

const DeviceInfo = React.memo((props: {
    visibility: boolean[],
    setDeviceInfoData: Function,
}) => {
    const [isShowAudio, isShowVideo] = props.visibility;
    const setDeviceInfoData = props.setDeviceInfoData;

    useEffect(() => {
        setDeviceInfoData(data => {
            const result = {} as any;
            if (isShowAudio) {
                result.audio = data.audio;
            }
            if (isShowVideo) {
                result.video = data.video;
            }
            return result;
        });
    }, [
        isShowAudio, 
        isShowVideo, 
        setDeviceInfoData
    ]);

    return isShowAudio ||  isShowVideo ? (
        <div>
            <div className='device-label'>{/* 为了答题顺利，请先完成设备检测： */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst}</div>
            <Timeline className='device-info'>
                {isShowAudio ? <AudioInfo setDeviceInfoData={setDeviceInfoData}/> : null}
                {isShowVideo ? <VideoInfo setDeviceInfoData={setDeviceInfoData}/> : null}
            </Timeline>
        </div>
    ) : null;
});

export const VideoInfo = React.memo((props: any) => {
    const {setDeviceInfoData} = props;
    const videoRef = useRef<any>(null);
    const [isNotAllow, errorMsg] = useVideo(videoRef);

    useEffect(() => {
        setDeviceInfoData((data) => {
            return {
                ...data, video: !isNotAllow
            };
        });
    }, [
        isNotAllow, 
        setDeviceInfoData
    ]);

    return (
        <Timeline.Item 
            color={isNotAllow ? 'red' : 'green'} 
            dot={
                isNotAllow ? (
                    <Icon antdIcon={true} type='CloseCircleFilled' />
                ) : (
                    <Icon antdIcon={true} type='CheckCircleFilled' />
                )
            }>
            <div>{/* 摄像头 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.camera}</div>
            <span>{/* 能在视频内看见自己，意味着摄像头正常 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly}</span>
            <div className='video-panel'>
                {
                    isNotAllow ? (
                        <div>
                            <Icon antdIcon={true} type='StopOutlined' style={{ fontSize: '50px'}}/>
                            <span>{errorMsg}</span>
                        </div>
                    ) : (
                        <video
                            width={'100%'}
                            ref={videoRef}
                            autoPlay
                            playsInline
                            muted
                        />
                    )
                }
            </div>
        </Timeline.Item>
    );
});

export const AudioInfo = React.memo((props: any) => {
    const {setDeviceInfoData} = props;
    const [isNotAllow, errorMsg] = useAudio();

    useEffect(() => {
        setDeviceInfoData((data) => {
            return {
                ...data, audio: !isNotAllow
            };
        });
    }, [
        isNotAllow, 
        setDeviceInfoData
    ]);

    return (
        <Timeline.Item 
            color={isNotAllow ? 'red' : 'green'} 
            dot={
                isNotAllow ? (
                    <Icon antdIcon={true} type='CloseCircleFilled' />
                ) : (
                    <Icon antdIcon={true} type='CheckCircleFilled' />
                )
            }>
            <div>{/* 麦克风 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.microphone}</div>
            {
                isNotAllow ? (
                    <span>{errorMsg}</span>
                ) : (
                    <>
                        <span>{/* 请对着麦克风说话，看到波形图样意味着麦克风正常 */}{UdeskLocales['current'].pages.coach.learningCenter.components.deviceModal.index.pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly}</span>
                        <div className='wave'></div>
                    </>
                )
            }
        </Timeline.Item>       
    );
});