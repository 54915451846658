import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './style.scss';
import { Space, Button, Tooltip, Popover, Input } from 'udesk-ui';
import Locales from 'UdeskLocales';
import Udesk from 'Udesk';

const { TextArea } = Input;

const locales = Locales['current'];

interface RemarkProps {
    remark?: {
        remark: string;
        [key: string]: any;
    };
    canRemarkEdit?: boolean | number;
    fieldFlag?: boolean;
    index?: number;
    propsFromDetail?: any;
    sender: 'agent' | 'customer';
}
const RemarkUnMemo: React.FC<RemarkProps> = (props) => {
    const { remark, canRemarkEdit, index = 0, propsFromDetail = {}, sender } = props;
    const { changeDialogRemarkList, fieldFlag, isInformationCollect } = propsFromDetail;
    const hasRemark = props.remark?.remark;

    const remarkText = hasRemark ? `${locales.labels.remark}: ${remark!.remark}` : '';
    const [currentRemark, setCurrentRemark] = useState(remark ?? { remark: '' });
    const [visible, setVisible] = useState(false);
    const showRemark = (dialogRemark, item) => {
        if (visible) {
            cancelRemark();
        } else {
            setVisible(true);
            let ret = { ...currentRemark };
            if (typeof ret.index !== 'number') {
                ret.index = index + 1;
            }
            ret.type = canRemarkEdit;

            setCurrentRemark(ret);
        }
    };
    const remarkChange = useCallback(
        (e) => {
            setCurrentRemark({
                ...currentRemark,
                remark: e.target.value,
            });
        },
        [currentRemark]
    );
    const cancelRemark = useCallback(() => {
        setVisible(false);
        setCurrentRemark({ ...(remark ?? { remark: '' }) });
    }, [remark]);
    const saveRemark = useCallback(async () => {
        // /inspectionRemarks
        if (!canRemarkEdit) {
            setVisible(false);
            return;
        }
        changeDialogRemarkList && (await changeDialogRemarkList({ ...currentRemark, type: canRemarkEdit }));
    }, [currentRemark, changeDialogRemarkList, canRemarkEdit]);
    useEffect(() => {
        cancelRemark();
    }, [remark]);
    let popoverContent = useMemo(
        () => (
            <div style={{ margin: '-4px -8px' }}>
                <div className='message-tools-bar-remark-content'>
                    <TextArea bordered rows={4} showCount={true} maxCount={270} maxLength={270} value={currentRemark.remark} onChange={remarkChange} />
                </div>
                <div
                    className='message-tools-bar-remark-footer'
                    style={{ marginTop: 4, display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Space>
                        <Button size='small' onClick={cancelRemark}>
                            {locales.labels.cancel}
                        </Button>
                        <Button size='small' type='primary' onClick={saveRemark}>
                            {locales.labels.save}
                        </Button>
                    </Space>
                </div>
            </div>
        ),
        [currentRemark.remark, remarkChange, cancelRemark, saveRemark]
    );
    return (
        <div className='message-tools-bar' style={{ textAlign: sender !== 'agent' ? 'end' : 'start' }}>
            <Popover
                content={popoverContent}
                placement='bottomRight'
                visible={visible && !!canRemarkEdit && !fieldFlag}
                getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            >
                <React.Fragment>
                    {!isInformationCollect && canRemarkEdit && !fieldFlag && !hasRemark && (
                        <Button
                            type='link'
                            size='small'
                            style={{ color: 'rgb(24, 90, 189)' }}
                            onClick={showRemark.bind(null, remark || {}, index)}
                        >
                            {locales.labels.remark}
                        </Button>
                    )}
                    {!isInformationCollect && hasRemark && (
                        <Tooltip
                            title={remarkText}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                        >
                            <div
                                onClick={showRemark.bind(null, remark || {}, index)}
                                className='message-tools-bar-remark'
                                style={{ textAlign: 'start' }}
                            >
                                {remarkText}
                            </div>
                        </Tooltip>
                    )}
                </React.Fragment>
            </Popover>
        </div>
    );
};

const Remark = React.memo(RemarkUnMemo);

const btnStyles: React.CSSProperties = {
    color: 'rgb(24, 90, 189)',
};
const CaseBaseUnMemo = (props) => {
    let {
        // role,
        fieldFlag,
        type,
        inspectDataSource,
        caseBaselist,
        index,
        // onClickEdit,
        // isEditing,
        // onClickContentEditOk,
    } = props;
    const querryPamarsId = index + 1;

    const { isAdded, sender } = props;
    const [addedToBaseCase, setAddedToBaseCase] = useState(Boolean(isAdded));
    const caseBaseAdd = () => {
        caseBaselist(querryPamarsId);
    };
    //对应原文件的fieldsChange方法
    // const editContent = () => {
    //     onClickEdit(true);
    // };
    // const saveContentAfterEdit = () => {
    //     typeof onClickContentEditOk === 'function' && onClickContentEditOk(querryPamarsId);
    //     // TODO
    //     closeEditContent();
    // };
    // const closeEditContent = () => {
    //     onClickEdit(false);
    // };
    useEffect(() => {
        setAddedToBaseCase(Boolean(isAdded));
    }, [isAdded]);
    return (
        <div style={{ textAlign: sender !== 'agent' ? 'end' : 'start' }}>
            {fieldFlag &&
                (sender === 'customer' || sender === 'agent') &&
                ([Udesk.enums.inspectDataSources.voiceCall.id, Udesk.enums.inspectDataSources.textDialogue.id].includes(
                    inspectDataSource
                ) ||
                    type === 'message') && (
                <div className={`aduio-text-case-base-${sender}`}>
                    {!addedToBaseCase ? (
                        <Button onClick={caseBaseAdd} style={btnStyles} type='text' size='small'>
                            <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tianjiaanli'></i>
                            {locales.components.pages.caseBaseList.detail.addCaseBase}
                        </Button>
                    ) : (
                        <Button onClick={caseBaseAdd} style={btnStyles} type='text' size='small'>
                            <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yichu'></i>
                            {locales.components.pages.caseBaseList.detail.delCaseBase}
                        </Button>
                    )}
                    {/* {!isEditing ? (
                        <Button style={btnStyles} type='text' size='small' onClick={editContent}>
                            {locales.components.pages.caseBaseList.edit}
                        </Button>
                    ) : (
                        <Space>
                            <Button
                                style={btnStyles}
                                type='text'
                                size='small'
                                onClick={saveContentAfterEdit.bind(null, itemDataProps.id)}
                            >
                                {locales.components.pages.caseBaseList.ok}
                            </Button>
                            <Button style={btnStyles} type='text' size='small' onClick={closeEditContent}>
                                {locales.components.pages.caseBaseList.cancel}
                            </Button>
                        </Space>
                    )} */}
                </div>
            )}
        </div>
    );
};

const CaseBase = React.memo(CaseBaseUnMemo);

interface TrainRecordCreateRequest {
    callId?: number;
  
    newContent?: string;
  
    sentenceIndex?: number;
}
interface ErrorCorrectionProps {
    itemData?: string;
    index?: number;
    canErrorCorrection?: boolean;
    sender: 'agent' | 'customer';
    trainRecords?: (params: TrainRecordCreateRequest) => Promise<any>;
}
const ErrorCorrection = React.memo((props: ErrorCorrectionProps) => {
    const { itemData = '', canErrorCorrection, index = 0, sender, trainRecords = (() => Promise.resolve()) } = props;

    const [amendContent, setAmendContent] = useState(itemData || '');
    const [visible, setVisible] = useState(false);
    const showErrorCorrection = (dialogRemark, item) => {
        if (visible) {
            cancelRemark();
        } else {
            setVisible(true);
        }
    };
    const remarkChange = useCallback((e) => {
        setAmendContent(e.target.value);
    }, []);
    const cancelRemark = useCallback(() => {
        setVisible(false);
        setAmendContent(itemData || '');
    }, [itemData]);
    const saveRemark = useCallback(async () => {
        if (!canErrorCorrection) {
            setVisible(false);
            return;
        }
        trainRecords?.({
            sentenceIndex: index,
            newContent: amendContent
        })?.then?.(() => {
            setVisible(false);
        });
    }, [amendContent, canErrorCorrection, trainRecords, index]);
    useEffect(() => {
        cancelRemark();
    }, [itemData]);
    let popoverContent = useMemo(
        () => (
            <div style={{ margin: '-4px -8px' }}>
                <div className='message-tools-bar-remark-content'>
                    <TextArea bordered rows={4} value={amendContent} onChange={remarkChange} />
                </div>
                <div
                    className='message-tools-bar-remark-footer'
                    style={{ marginTop: 4, display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Space>
                        <Button size='small' onClick={cancelRemark}>
                            {locales.labels.cancel}
                        </Button>
                        <Button size='small' type='primary' onClick={saveRemark}>
                            {locales.labels.save}
                        </Button>
                    </Space>
                </div>
            </div>
        ),
        [amendContent, remarkChange, cancelRemark, saveRemark]
    );
    
    return (
        <div style={{ textAlign: sender !== 'agent' ? 'end' : 'start' }}>
            <Popover
                content={popoverContent}
                placement='bottomRight'
                visible={visible && !!canErrorCorrection}
                getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            >
                <React.Fragment>
                    {canErrorCorrection && (
                        <Button
                            type='link'
                            size='small'
                            style={{ color: 'rgb(24, 90, 189)' }}
                            onClick={showErrorCorrection.bind(null, itemData || {}, index)}
                        >
                            {locales.labels.correction}
                        </Button>
                    )}
                </React.Fragment>
            </Popover>
        </div>
    );
});
export { Remark, CaseBase, ErrorCorrection };
