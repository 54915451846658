import React from "react";
// import Udesk from 'Udesk';
import BusinessAnalysisRoute from "./route";
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Select, Button, Tooltip, PageHeader, Input, Space, Form as UdeskUIForm, Icon } from "udesk-ui";
import _isEmpty from "lodash-es/isEmpty";
import _filter from 'lodash-es/filter';
import "./style.scss";
import BusinessAnalysisFilter from './business-analysis-fiter';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import ClassNames from 'classnames';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import UdeskDatePicker from 'Component/common/udesk-date-picker';

export default class BusinessAnalysisTemplate extends BusinessAnalysisRoute {
    render() {
        // let { sdkOptions } = this.props;
        let { actions,locales} = this;

        let {

            xAxisItems,
            seriversItems,
            searchTemplate,
            seriversItemsTitle,
            timeList,
            judgeStrategy,
            customJudgeLogic,
            _isFilterVisible,
            graphData,
            sanKeyItems,
            customName,
            customId,
            conditionList,
            name,
        } = this.privates;
        let { Option } = Select;
        let {
            startTime,
            endTime,
            groupByType,
            data,
            dataSourceType,
            num,
        } = this.privates.storages;
        // const company = Udesk.data.init.company;
        const isCreate = this.props.route.name === 'businessAnalysisCreate';
        return (
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.businessAnalysis.title}
                    onBack={actions.backToList}
                    subTitle={
                        <Tooltip
                            title={
                                <div>
                                    <div>{locales.components.pages.businessAnalysis.tip1}</div>
                                    <div>{locales.components.pages.businessAnalysis.tip2}</div>
                                    <div>{locales.components.pages.businessAnalysis.tip3}</div>
                                </div>
                            }
                            overlayStyle={{maxWidth: 'unset'}}
                            placement='bottomLeft'
                        >
                            <Icon
                                type='QuestionCircleOutlined'
                                antdIcon={true}
                            ></Icon>
                        </Tooltip>
                    }
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root business-analysis-page-list' id='exportPDF' style={{ background: '#f0f2f5' }}>
                        <div style={{ padding: '10px', background: '#fff' }}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <UdeskUIForm ref={this.privates.formInstance} initialValues={{ name }}>
                                    <UdeskUIForm.Item
                                        name='name'
                                        label={locales.pages.businessAnalysis.keyword}
                                        required
                                        rules={[{ required: true }, { max: 20, type: 'string' }]}
                                    >
                                        <Input style={{width: 250}} onChange={actions.onNameChange} showCount={true} maxCount={20} />
                                    </UdeskUIForm.Item>
                                </UdeskUIForm>
                                {!isCreate && (
                                    <Tooltip placement="bottom" title={(<div className="custome-filter-tootip-button">
                                        <p onClick={actions.fieldExport}>{`${locales.labels.export} excel`}</p>
                                        <p onClick={actions.fieldPdf} download>{`${locales.labels.export} png`}</p>
                                        <Button id='exprotLink' href='#' type='link' style={{ display: 'none' }}></Button>
                                    </div>)} overlayClassName='custome-filter-tootip'>
                                        <Button style={{ float: 'right' }}>{locales.labels.export}</Button>
                                    </Tooltip>
                                )}
                            </div>
                            <div style={{marginLeft: 11}}>
                                {
                                    this.props.route.name !== 'analysisBusinessAnalysisDetail' && this.props.route.name !== 'analysisBusinessAnalysisCreate' && <>
                                        <span>{locales.fix.selectType}</span>
                                        <Select
                                            style={{width: 130}}
                                            // disabled={!(company.enabledCallInspect !== 0 && company.enabledImInspect !== 0)}
                                            value={dataSourceType}
                                            onChange={actions.dataSourceTypeChange}>
                                            <Option value={0}>{locales.fix.all}</Option>
                                            <Option value={1}>{locales.fix.call}</Option>
                                            <Option value={2}>{locales.fix.text}</Option>
                                            <Option value={5}>{locales.fix.wechat}</Option>
                                        </Select>
                                    </>
                                }
                                <div style={{ display: 'inline-block', margin: '0 10px' }}>
                                    <UdeskDatePicker style={{ width: 246 }} allowClear={false} onChange={actions.dateChanged} value={[startTime, endTime]} />
                                </div>
                                <Button onClick={actions.fieldSave}>{!isCreate ? locales.labels.query : locales.labels.preview}</Button>
                            </div>
                        </div>
                        <div className='business-analysis-page-list-filter'>
                            <div className="qa-react-list-connenct-by-converstaion-custome-filter">
                                <UdeskUIForm layout="inline">
                                    <div className="custome-filter-driver-box">
                                        <div className={ClassNames('customer-filter-driver-circle-add customer-filter-driver-circle-add-none', { "customer-filter-driver-circle-add-block": !_isEmpty(data) })}
                                            onClick={actions.addOneCustome}>
                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"></i>
                                        </div>
                                        <BusinessAnalysisFilter
                                            {...this.props}
                                            data={data}
                                            searchTemplate={searchTemplate}
                                            key='filterList'
                                            fieldDelList={actions.fieldDelListChange}
                                            fieldAddList={actions.fieldAddListChange}
                                            num={num}
                                            dataSourceChanged={actions.dataSourceChange}
                                            dataSourceFromChanged={actions.dataSourceFromChange}
                                            dataSourceFromThreeChanged={actions.dataSourceFromThreeChange}
                                            dataSourceFromfiveChanged={actions.dataSourceFromfiveChange}
                                            driverFlag={true}
                                            newAddFilterFileds={actions.newAddFilterFileds}
                                            InputColorItemChange={actions.InputColorItemChange}
                                            displacementChange={actions.displacementChange}
                                            dataSourceType={dataSourceType}
                                        />
                                    </div>
                                </UdeskUIForm>
                            </div>
                            <Space style={{padding: '10px'}}>
                                <Button onClick={actions.save.params(isCreate)} type='primary' style={{marginLeft: 81}}>{locales.labels.save}</Button>
                                <Button onClick={actions.backToList}>{locales.labels.cancel}</Button>
                            </Space>
                        </div>
                        <div className='business-analysis-page-list-line'>
                            {
                                !_isEmpty(seriversItems) ? (
                                    <div>
                                        <div className='business-analysis-page-list-line-time'>
                                            {
                                                !_isEmpty(timeList) && _filter(timeList, current => { return (current.key !== '1h' && (moment(endTime).diff(moment(startTime), 'day') > 180)) || ((moment(endTime) - moment(startTime)) / 1000 / 3600 / 24 < 180); }).map(item => {
                                                    return (<div className={ClassNames({ 'timeColor-echars': item.key === groupByType })} onClick={actions.timeClick.params(item.key)}>{item.name}</div>);
                                                })
                                            }
                                        </div>
                                        <LineEcharts locales={locales} seriversItemsTitle={seriversItemsTitle} xAxisItems={xAxisItems} seriversItems={seriversItems}/>
                                    </div>

                                ) : (<div className='echarts-data-none' style={{ textAlign: 'center', lineHeight: '480px' }}>{locales.fix.noEchart}</div>)
                            }

                        </div>
                        <div className='bussiness-analysis-page-list-echarts-list' style={{ position: 'relative' }}>
                            {
                                _isEmpty(graphData) && (
                                    <div className='echarts-data-none' style={{ textAlign: 'center', lineHeight: '480px', position: 'absolute', top: '0px', width: '50%', zIndex: 100 }}>{locales.fix.noEchart}</div>
                                )
                            }
                            {
                                _isEmpty(sanKeyItems) && (
                                    <div className='echarts-data-none' style={{ textAlign: 'center', lineHeight: '480px', position: 'absolute', top: '0px', width: '50%', zIndex: 100, right: '0px' }}>{locales.fix.noEchart}</div>
                                )
                            }
                            <div id='graph' className='business-analysis-page-list-sankey'>
                            </div>
                            <div id='sanKeyRef' className='business-analysis-page-list-sankey'>
                            </div>
                        </div>
                        {/* <ReactModal visible={_isFilterVisible} title={locales.components.pages.components.listManage.filterConditions} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={actions.handleConditionCancel} onOk={actions.handleConditionFilter} headerClassName="udesk-qa-modal-default-header" footerClassName="udesk-qa-modal-default-footer">
                    <div className="udesk-qa-modal-body-content">
                        <CustomFilters allowEmptyConditions={true} conditions={displayConditionList} fields={_fields} fieldValueSettings={_fieldValueSettings} theme="bootstrap" onChanged={actions.filterContidionChanged} fieldSelectorClass="form-control input-sm" operatorSelectorClass="form-control input-sm" languageCode={sdkOptions.props.language} fieldNameField="key" fieldKeyField="key" fieldTextField="label" showConditionNumber={true} />
                        <RadioBtn classNames="customec r-filters-judgeStrategy-radios" itemClassNames="customer-filters-judgeStrategy-radio-item" options={Udesk.enums.operatorRuleTypes} value={judgeStrategy} onChanged={actions.changeJudgeStrategy} />
                        <If condition={judgeStrategy === Udesk.enums.operatorRuleTypes.custom.id}>
                            <input type="text" className="form-control" defaultValue={customJudgeLogic} onChange={actions.mutator("privates.customJudgeLogic", e => e.target.value)} />
                        </If>
                        <input type="text" className="form-control" defaultValue={customTtile} onChange={actions.customTtileChange} />
                    </div>
                </ReactModal> */}
                        <CustomFilterModal
                            visible={_isFilterVisible}
                            cancel={actions.handleConditionCancel}
                            applyAction={actions.handleConditionApply}
                            judgeStrategy={judgeStrategy}
                            customJudgeLogic={customJudgeLogic}
                            customName={customName}
                            customId={customId}
                            conditionList={conditionList}
                            type={dataSourceType}
                            businessFlag={true}
                            filterList={['f_8', 'r_1', 'r_2', 'r_3']}
                        />
                    </div >

                </div >

            </div>

        );
    }
}
const LineEcharts = React.memo(({locales, seriversItemsTitle, xAxisItems, seriversItems}) => {
    return <ReactEcharts
        type="line"
        xLabelField="xLabel"
        enableDataZoom={true}
        height={460}
        finalizeChartOptions={option => {
            option.title = {
                text: locales.fix.businessTrendAnalysis
            };
            option.tooltip = {
                trigger: 'axis',
            };
            option.dataZoom = [{
                type: 'slider',
                bottom: 40
            }];
            option.yAxis = {
                type: 'value'
            };
            option.legend = {
                data: seriversItemsTitle,
                type: 'scroll',
                bottom: 10
            };
            option.grid.bottom = 100;
            option.xAxis = {
                type: 'category',
                boundaryGap: false,
                data: xAxisItems
            };
            option.series = seriversItems;
            return option;
        } } />;
});

