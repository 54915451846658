import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import { Button, Form, Input, Select } from 'udesk-ui';
import { FilterType } from './types';
import UdeskLocales from 'UdeskLocales';

const Filter: React.FC<FilterType> = (props) => {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({}); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    const onFinish = (values) => {
        console.log('Finish:', values);
        props.onSearch?.(values);
    };

    return (
        <Form form={form} layout='inline' onFinish={onFinish}>
            <Form.Item name='role' label={/* 角色类型 */UdeskLocales['current'].pages.trainingCenter.clusteringAnnotation.components.filter.index.roleType}>
                <Select
                    allowClear
                    options={Udesk.enums.clusteringLabelingRoles.map((i) => ({ label: i.name, value: i.id }))}
                    style={{ width: 150 }}
                />
            </Form.Item>
            <Form.Item name='keyword' label={/* 关键词 */UdeskLocales['current'].pages.trainingCenter.clusteringAnnotation.components.filter.index.keyWord}>
                <Input placeholder={/* 请输入关键词搜索 */UdeskLocales['current'].pages.trainingCenter.clusteringAnnotation.components.filter.index.pleaseEnterKeywordSearch} />
            </Form.Item>
            <Form.Item>
                <Button type='primary' htmlType='submit'>{/* 查询 */}{UdeskLocales['current'].pages.trainingCenter.clusteringAnnotation.components.filter.index.query}</Button>
            </Form.Item>
        </Form>
    );
};

export { Filter };
