import UdeskLocales from "UdeskLocales";
import Udesk from "Udesk";
import React from "react";
import PropTypes from "prop-types";
import _isEmpty from 'lodash-es/isEmpty';


export default class CheckPointConditionListGatherComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        onChange: null,
        conditionList: [],
        condition: {},
        type: []
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        isNewAssociate: PropTypes.bool,
        isAutoAssociateDateSource: PropTypes.bool,
        onChange: PropTypes.func,
        conditionList: PropTypes.array,
        condition: PropTypes.object,
        type: PropTypes.array,

    };

    privates = {
        conditionTypeList: [
            { key: 1, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.words },
            { key: 2, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.interaction },
            { key: 3, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.feature },
            { key: 4, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.information },
        ],
        fieldDataList: []
    };
    actions = {
        handleConditionListChange(index, conditionItem) {
            this.privates.conditionList[index] = conditionItem;
            this.actions.update();
            this.props.onChange?.(this.privates.conditionList);
            // this.trigger("onChange", this.privates.conditionList);
        },
        addCondition(key, e) {
            let { conditionList } = this.privates;
            let prefix = !_isEmpty(conditionList) ? conditionList[conditionList.length - 1].idCode.replace(/[0-9]/g, '') : 'R';
            let index = !_isEmpty(conditionList) ? conditionList[conditionList.length - 1].idCode.replace(prefix, '') : 0;
            let { isSales } = this.props;
            let applyRole = isSales ? Udesk.enums.saleApplyRoles[0].id : Udesk.enums.applyRoles[0].id;
            conditionList.push({
                idCode: `${prefix}${parseInt(index, 10) + 1}`,
                conditionType: key,
                applyRole,
                applyConditionHitType: 1,
                applyConditionScope: null,
                applyTimeType: Udesk.enums.applyTimeTypes.all.id,
                applyTimeId: null,
                operator: "all",
                operatorLogic: '1',
                operatorList: [{
                    applyConditionHitType: 1,
                    applyOperatorScope: {
                        key: 'all',
                        id: 1
                    },
                    operator: "all",
                    applyRole: 'all',
                    param: {
                        version: 1
                    },
                }]
            });
            // conditionList && this.actions.getOperatorList(conditionList);
            this.actions.handleConditionListChange();
        },
        deleteCondition(index) {
            let { conditionList } = this.privates;
            let { ruglar } = this.props;
            if (conditionList.length === 1 && (!ruglar || (ruglar && ruglar !== 'preprocess'))) {
                Udesk.ui.notify.error(UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.errorMessage);
                return;
            }
            conditionList.splice(index, 1);
            this.actions.handleConditionListChange();
        },
        getFieldId(type) {
            let { sdkOptions } = this.props;
            let fieldUrl = Udesk.business.apiPath.concatApiPath(
                `field/data/${type}?type=associate`,
                sdkOptions
            );
            Udesk.ajax.get(fieldUrl).then(
                resp => {
                    this.privates.fieldDataList = resp.data;
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    };




    parseProps({ props, prevProps, state, privates, isInitial }) {
        let conditionList = JSON.parse(JSON.stringify(props.conditionList));
        return {
            conditionList: conditionList
        };
    }
    componentDidUpdate(prevProps) {

        if (!this.props.sdkOptions.props.basicInfoFields) {
            if (!_isEmpty(this.props.type) && !_isEmpty(prevProps.type) && this.props.type[0] !== prevProps.type[0]) {
                if (this.props.type.includes(Udesk.enums.inspectDataSources.voiceCall.id)) {
                    this.actions.getFieldId(Udesk.enums.inspectDataSources.voiceCall.id);
                } else if (this.props.type.includes(Udesk.enums.inspectDataSources.textDialogue.id) || this.props.type.includes(Udesk.enums.inspectDataSources.wechatDialogue.id)) {
                    this.actions.getFieldId(Udesk.enums.inspectDataSources.textDialogue.id);
                }
            }
        }

    }
    componentDidMount() {
        if (this.props.sdkOptions.props.basicInfoFields) {
            this.privates.fieldDataList = this.props.sdkOptions.props.basicInfoFields;
            this.actions.update();
        }
    }
}