import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import InspectionDetail from 'Component/pages/components/inspection-detail';
import { useRequest } from 'src/util/hook';
import { getSearchParams } from 'src/util/link';
import 'udesk-ui/lib/form/style/index.less';
import './style.scss';
import UdeskLocales from 'UdeskLocales';

const SDK: React.FC<any> = (props) => {
    const { sdkOptions, location } = props;

    const [smartTags, setSmartTags] = useState<any[]>([]);
    const [detail, setDetail] = useState<any>({});

    const [loading, setLoading] = useState<boolean>(true);
    const [pointChildrenComputer] = useState<any>({});
    const [pointChildrenPerson] = useState<any>({});
    const [dialogRemarkList] = useState<any[]>([]);
    const [complaintHistory] = useState<any[]>([]);
    const [resetComplainPointIdMap] = useState(0);
    const [showHit, setShowHit] = useState<0 | 1>(0);
    
    const handMarkMap = () => {};
    const request = useRequest();
    
    const searchParams = useMemo(() => getSearchParams(location.search), [location.search]);

    const showHitChange = useCallback((showHit) => {
        setShowHit(showHit ? 1 : 0);
    }, []);

    const onComplainPointListChange = useCallback(() => {
    }, []);

    useEffect(() => {
        if (searchParams?.inspectionId && searchParams?.companyId) {
            request(`/api/noAuth/inspectionData/${searchParams?.inspectionId}?companyId=${searchParams.companyId}`).then(
                resp => {
                    setDetail(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            ).finally(() => {
                setLoading(false);
            });
        }
    }, [request, searchParams?.companyId, searchParams?.inspectionId]);


    useEffect(() => {
        if (searchParams?.callId && searchParams?.companyId) {
            request(`/api/noAuth/tagsOfCall/${searchParams.callId}?companyId=${searchParams.companyId}`).then(
                resp => {
                    setSmartTags(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [request, searchParams?.callId, searchParams?.companyId]);

    const getComplainHistory = useCallback(() => {
    }, []);
    const refreshList = useCallback(() => {
    }, []);

    return (
        <Page
            pageBodyClassName='wechat-message-notification-page'
            title={/* 会话详情 */UdeskLocales['current'].pages.wechatNotificationAnalysis.messageNotification.index.sessionDetails}
            // padding={true}
        >
            <InspectionDetail
                // evaluateChange={actions.evaluateChange}
                resetStatus={false}
                handMarkMap={handMarkMap}
                pointChildrenComputer={pointChildrenComputer}
                pointChildrenPerson={pointChildrenPerson}
                // keywords={sdkOptions.props._keywords}
                testSetList={null}
                testSetListFlag={false}
                caseBaseStatus={true}
                // onUpdate={actions.onUpdate}
                id={detail?.id}
                // caseBase={caseBase}
                // caseLibraryId={location.search}
                taskId={detail?.taskId}
                // hasFeature={hasFeature}
                detail={detail}
                smartTags={smartTags}
                disabledSmartTagsChange={true}
                // changeList={changeList}
                // qualityCheckScoreSaving={qualityCheckScoreSaving}
                // onQualityCheckScoreCancel={actions.qualityCheckScoreCanceled}
                // onQualityCheckScoreSave={actions.qualityCheckScoreSaved}
                // onQualityCheckScoreSubmit={actions.qualityCheckScoreSubmitted}
                // relatedDatas={relatedDatas}
                // relatedDatasPaging={relatedDatasPaging}
                // parentLoadMore={actions.parentLoadMore}
                // relatedLoading={relatedLoading}
                dialogRemarkList={dialogRemarkList}
                // changeDialogRemarkList={actions.changeDialogRemarkList}
                // querryChangeList={actions.querryChangeList}
                // querrySmartTags={actions.querrySmartTags}
                isWechatQa={true}
                // changeToHighlightItemsCallDetail={related ? actions.changeCallDetail : noop}
                // onUpdated={actions.onUpdated}
                // refresh={actions.reloadAsyncModel}
                // rerenderOptimization={true}
                // requestRelatedRecords={actions.requestRelatedRecords}
                // relatedRecordsLoading={relatedRecordsLoading}
                loading={loading}
                // complaintList={complaintList}
                onComplainPointListChange={onComplainPointListChange}
                complaintHistory={complaintHistory}
                appealSdkOptions={sdkOptions}
                refershHistory={getComplainHistory}
                refreshList={refreshList}
                resetComplainPointIdMap={resetComplainPointIdMap}
                showHit={showHit}
                onShowHitChange={showHitChange}
            />
        </Page>
    );
};

class Component extends React.Component {
    render() {
        return <SDK {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
