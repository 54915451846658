import React, { useCallback, useEffect, useState } from 'react';
import Page from 'Component/common/udesk-qa-page-layout';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import moment from 'moment';
import hookProps from 'udesk-react/src/udesk/react/hook-props';
import ManualReviewConfig from '../components/ManualReviewConfig';
import LessonSelect, { useLessonSelect } from '../components/LessonSelect';
import UserPortraitSelect from '../components/UserPortraitSelect';
import DurationLimit from '../components/DurationLimit';
import PracticeTimePicker from '../components/PracticeTimePicker';
import GradingStandart from '../components/GradingStandart';
import CompleteStandart from '../components/CompleteStandart';
import OrganizationMemberSelect from './OrganizationWithFinalValueSelect';
import ExamTimesInput from '../components/ExamTimesInput';
import AutoAddOrganizationList from './AutoAddOrganizationList';
import { getIntelligentPartnerTaskInfosById, putIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';
import { Button, Form, Input, Radio, Rate, Space, Switch } from 'udesk-ui';
import { postIntelligentPartnerTaskInfos } from 'src/api/intelligentPartnerTaskInfos';
import { getIntelligentPartnerTaskInfosByLessonIdTaskScore } from 'src/api/intelligentPartnerTaskInfos/{lessonId}/task/score';
import { EnumRadio } from 'Component/common/enum-component';
import { useCoachLessonUserTreeDataWithPermit } from 'src/util/hook/user';
import { isDefined } from 'src/util/core';
import './index.less';

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 8 },
};

const TaskCenterEdit: React.FC<any> = (props) => {
    const { match } = props;
    const locales = UdeskLocales['current'];
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(
        match?.params?.id === '0'
            ? locales.pages.gong.coachTaskCenter.edit.addTask
            : locales.pages.gong.coachTaskCenter.edit.editTask
    );
    const [taskType, setTaskType] = useState<any>(1);
    const [enableManualReview, setEnableManualReviewType] = useState< 0 | 1>(0);
    const [lessonId, setLessonId] = useState<any>();
    const [imageIdInfo, setImageIdInfo] = useState<any>({ status: 2 });
    const [courseType, setCourseType] = useState<any>(null);
    const [lessonScore, setLessonScore] = useState<any>({
        total: '-',
        speechCraftScore: '-', // 话术得分
        emotionScore: '-', // 情绪得分
        speedScore: '-', // 语速得分
    });
    const [taskTargetObj, setTaskTargetObj] = useState<any>();

    const { allLessons } = useLessonSelect();
    const { treeData, getUserByIds, getOrgByIds } = useCoachLessonUserTreeDataWithPermit(lessonId);

    const isEdit = match?.params?.id !== '0';

    const goBack = () => {
        // const routeName = 'coachTaskCenter';
        // Udesk.ui.routing.transitionTo({
        //     history,
        //     routeName,
        // });
        window.history.back();
    };

    const init = () => {
        const id = match?.params?.id || '0';
        if (id !== '0') {
            setLoading(true);
            getIntelligentPartnerTaskInfosById({
                segments: { id },
            })
                .then((resp) => {
                    let values: any = {
                        ...resp?.data,
                    };
                    //画像
                    values.imageIdInfo = {
                        status: 2,
                    };
                    if (values.imageId != null) {
                        values.imageIdInfo.status = 1;
                        values.imageIdInfo.selectedItemId = values.imageId;
                    }
                    // 时长限制
                    values.limitDurationInfo = {
                        status: 1,
                    };
                    if (values.singleLimitDuration != null || values.limitDuration != null) {
                        values.limitDurationInfo.status = 2;
                        values.limitDurationInfo.inputValue = values.singleLimitDuration || values.limitDuration;
                    }

                    // 时间
                    values.taskTimeInfo = {
                        status: 1,
                    };
                    if (values.taskStartTime && values.taskStartTime) {
                        values.taskTimeInfo.status = 2;
                        values.taskTimeInfo.inputValue = [
                            moment(values.taskStartTime),
                            moment(values.taskEndTime),
                        ];
                    }
                    // 评分标准
                    values.gradingStandartInfo = {
                        commitScore: values.commitScore,
                        excellentScore: values.excellentScore,
                        scoreStandardUnit: values.scoreStandardUnit,
                    };
                    // 完成要求
                    values.completeStandartInfo = {};
                    if (values.completeByTimes != null) {
                        values.completeStandartInfo.timesStatus = true;
                        values.completeStandartInfo.completeByTimes = values.completeByTimes;
                    }
                    if (values.completeByScore != null) {
                        values.completeStandartInfo.scoreStatus = true;
                        values.completeStandartInfo.completeByScore = values.completeByScore;
                        values.completeStandartInfo.completeByScoreUnit = values.completeByScoreUnit;
                    }
                    form.setFieldsValue(values);
                    setTitle(locales.labels.edit + ' - ' + values.taskName);
                    setTaskType(values.taskType);
                    setEnableManualReviewType(isDefined(values.enableManualReview, values.enableManualReview, 1));
                    setLessonId(values.lessonId);
                    setTaskTargetObj(values.taskTargetObj);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            const queryParams = new URLSearchParams(props.location.search);
            const lessonId = queryParams.get('lessonId');
            if (lessonId) {
                setLessonId(Number(lessonId));
                form.setFieldsValue({
                    lessonId: Number(lessonId),
                });
            }
        }
    };

    useEffect(() => {
        if (lessonId) {
            getIntelligentPartnerTaskInfosByLessonIdTaskScore({
                segments: { lessonId: lessonId },
                params: (imageIdInfo?.status === 1
                    ? { imageId: imageIdInfo?.selectedItemId }
                    : undefined) as any,
            }).then((resp) => {
                if (resp?.data) {
                    setLessonScore({
                        ...resp?.data,
                        total:
                            (resp?.data.speechCraftScore || 0) +
                            (resp?.data.emotionScore || 0) +
                            (resp?.data.speedScore || 0),
                    });
                }
            });
        }
    }, [lessonId, imageIdInfo]);

    const onFinish = (values) => {
        setLoading(true);
        let taskStartTime: any = null;
        let taskEndTime: any = null;
        if (values.taskTimeInfo?.status === 2) {
            if (values.taskTimeInfo.inputValue.length === 2) {
                taskStartTime = values.taskTimeInfo.inputValue[0].format('YYYY-MM-DD HH:mm:ss');
                taskEndTime = values.taskTimeInfo.inputValue[1].format('YYYY-MM-DD HH:mm:ss');
            }
        }
        const reqParams: any = {
            totalScore: lessonScore.total,
            taskName: values.taskName, // 任务名称
            taskDesc: values.taskDesc, // 任务描述
            taskType: values.taskType, // 任务类型 1-练习 2-考试
            lessonId: values.lessonId, // 课程ID
            taskTargetObj: values.taskTargetObj,
            isEnable: values.isEnable, // 是否生效 0-不生效 1-生效
            dialogueMode: values.dialogueMode, // 对话模式 1-语音 2-文本
            imageId: values.imageIdInfo?.status === 1 ? values.imageIdInfo.selectedItemId : null, // 客户画像ID
            difficultyLevel: values.difficultyLevel, // 难度等级 1-10
            [courseType === Udesk.enums.lessonType.question.id ? 'singleLimitDuration' : 'limitDuration']:
                values.limitDurationInfo?.status === 2 ? values.limitDurationInfo.inputValue : null, // 限定的时长（分钟），如果为null则不限时
            isTip: values.isTip ? 1 : 0, // 是否给与话术提示 0-不给予 1-给与，练习任务有改选项
            taskStartTime: taskStartTime, // 任务开始时间，为null则不限时间
            taskEndTime: taskEndTime, // 任务结束时间，为null则不限时间
            commitScore: values.gradingStandartInfo?.commitScore, // 评分标准 ，达标所需得分
            excellentScore: values.gradingStandartInfo?.excellentScore, //  评分标准，优秀所需得分
            scoreStandardUnit: values.gradingStandartInfo?.scoreStandardUnit, //  评分标准单位
            completeByTimes: values.completeStandartInfo?.timesStatus
                ? values.completeStandartInfo.completeByTimes
                : null, // 完成标准-按照练习次数
            completeByScore: values.completeStandartInfo?.scoreStatus
                ? values.completeStandartInfo.completeByScore
                : null, // 完成标准-按照得分
            completeByScoreUnit: values.completeStandartInfo?.completeByScoreUnit,
            enableManualReview,
            manualReviewConfigObj: values.manualReviewConfigObj,
            autoAddOrganizationList: values.autoAddOrganizationList,
        };
        if (taskType === 2) {
            reqParams.limitTimes = values.limitTimes; // 限制次数，考试任务用到改选项
        }
        const id = match?.params?.id || '0';

        if (id === '0') {
            postIntelligentPartnerTaskInfos(reqParams, {
                successMsg: locales.pages.gong.coachTaskCenter.edit.publishSuccess,
                errorMsg: locales.pages.gong.coachTaskCenter.edit.publishFailed,
            })
                .then(() => {
                    goBack();
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            putIntelligentPartnerTaskInfosById(reqParams, {
                segments: { id },
                successMsg: locales.pages.gong.coachTaskCenter.edit.updateSuccess,
                errorMsg: locales.pages.gong.coachTaskCenter.edit.updateFailed,
            })
                .then(() => {
                    goBack();
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    const onTaskTypeChange = (e) => {
        setTaskType(e.target.value);
        form.resetFields(['completeStandartInfo']);
    };

    const onEnableManualReviewTypeChange = useCallback((e) => {
        setEnableManualReviewType(e.target.value);
    }, []);

    useEffect(() => {
        init();
    }, []);

    const changeDialogueMode = (courseType) => {
        setCourseType(courseType);
        if(!isEdit) {
            let dialogueMode = Udesk.enums.learningTaskDialogueModeType.voice.id;
            if(courseType === Udesk.enums.lessonType.ppt.id) {
                dialogueMode = Udesk.enums.learningTaskDialogueModeType.ppt.id;
            }
            form.setFieldsValue({
                dialogueMode
            });
        }
    };
    
    useEffect(() => {
        const currentLesson = allLessons.find(l => l.id === lessonId);
        if(currentLesson) {
            changeDialogueMode(currentLesson.courseType);
        }
    }, [allLessons, lessonId]);


    const isDisabled = useCallback((name) => {
        const list = [
            'lessonId', // 所属课程
            'dialogueMode', // 对话模式
            'imageIdInfo', //用户画像
            'limitDurationInfo', // 单题作答时长限制
            'gradingStandartInfo', // 话术标准
            'completeStandartInfo', // 任务完成要求
        ];

        if (taskType === Udesk.enums.learningTaskType.notStarted.id) {
            list.push('isTip'); // 话术提示
        }

        return isEdit ? list.includes(name) : false;
    }, [
        isEdit, 
        taskType
    ]);

    return (
        <Page
            pageBodyClassName={'task-center-edit-body'}
            loading={loading}
            title={title}
            onBack={goBack}
        >
            <Form
                form={form}
                {...layout}
                initialValues={{
                    taskType: 1, // 任务类型 1-练习 2-考试
                    isEnable: 1, // 是否生效 0-不生效 1-生效
                    dialogueMode: 1, // 对话模式 1-语音 2-文本
                    imageIdInfo: { status: 2 }, // 客户画像ID
                    limitDurationInfo: { status: 1 }, // 限定的时长（分钟），如果为null则不限时
                    isTip: true, // 是否给与话术提示 0-不给予 1-给与，练习任务有改选项
                    taskTimeInfo: { status: 1 },
                    enableManualReview: 0,
                }}
                autoComplete="off"
                validateMessages={{
                    // eslint-disable-next-line no-template-curly-in-string
                    required: '${label}' + locales.pages.gong.coachTaskCenter.edit.cannotBeNull,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    label={locales.pages.gong.coachTaskCenter.list.taskType}
                    name="taskType"
                    rules={[{ required: true }]}
                >
                    <EnumRadio
                        disabled={isEdit} 
                        onChange={onTaskTypeChange}
                        enumKey={'learningTaskType'}
                        filter={(item) => Udesk.enums.learningTaskType.other.id !== item.id}
                    />
                </Form.Item>
                <Form.Item
                    label={locales.pages.gong.coachTaskCenter.list.lessonName}
                    name="lessonId"
                    rules={[{ required: true }]}
                >
                    <LessonSelect
                        disabled={isDisabled('lessonId')}
                        options={allLessons}
                        onChange={(val, option) => {
                            setLessonId(val);
                            changeDialogueMode(option.courseType);
                            // 重置评分标准
                            form.setFieldsValue({
                                imageIdInfo: {
                                    ...form.getFieldValue('imageIdInfo'),
                                    selectedItemId: undefined
                                },
                                gradingStandartInfo: {
                                    commitScore: undefined,
                                    excellentScore: undefined,
                                    scoreStandardUnit: undefined,
                                }
                            });
                        }}
                        style={{ width: 300 }}
                    />
                </Form.Item>
                <Form.Item
                    label={locales.pages.gong.coachTaskCenter.list.taskName}
                    name="taskName"
                    rules={[{ required: true }]}
                >
                    <Input maxLength={40} maxCount={40} showCount={true} style={{ width: 300 }} />
                </Form.Item>
                <Form.Item
                    label={`${
                        taskType === 1
                            ? locales.pages.gong.coachTaskCenter.list.taskTypePractice
                            : locales.pages.gong.coachTaskCenter.list.taskTypeTest
                    }${locales.pages.gong.coachTaskCenter.edit.explain}`}
                    name="taskDesc"
                    extra={Udesk.utils.string.format(
                        locales.pages.gong.coachTaskCenter.edit.taskDescExtra,
                        taskType === 1
                            ? locales.pages.gong.coachTaskCenter.list.taskTypePractice
                            : locales.pages.gong.coachTaskCenter.list.taskTypeTest
                    )}
                    rules={[{ required: true }]}
                >
                    <Input.TextArea
                        style={{ marginBottom: 0 }}
                        rows={4}
                        maxLength={500}
                        maxCount={500}
                        showCount={true}
                    />
                </Form.Item>
                <Form.Item
                    label={locales.pages.gong.coachTaskCenter.edit.taskTargetObj}
                    name="taskTargetObj"
                    rules={[{ required: true }]}
                >
                    <OrganizationMemberSelect 
                        treeData={treeData} 
                        finalValue={taskTargetObj}
                        getUserByIds={getUserByIds} getOrgByIds={getOrgByIds}
                    />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: layout.wrapperCol.span * 2,
                        offset: layout.labelCol.span,
                    }}
                    name={'autoAddOrganizationList'}
                >
                    <AutoAddOrganizationList 
                        width={500}
                        treeData={treeData}
                        getUserByIds={getUserByIds} getOrgByIds={getOrgByIds}
                    />
                </Form.Item>
                <Form.Item
                    label={locales.pages.gong.coachTaskCenter.edit.isEnable}
                    name={'isEnable'}
                    rules={[{ required: true }]}
                >
                    <Radio.Group>
                        <Radio value={1}>{locales.labels.switchOn}</Radio>
                        <Radio value={0}>{locales.labels.switchOff}</Radio>
                    </Radio.Group>
                </Form.Item>
                {courseType !== null && (
                    <Form.Item
                        label={locales.pages.gong.coachTaskCenter.edit.dialogueMode}
                        name={'dialogueMode'}
                        rules={[{ required: true }]}
                    >
                        <EnumRadio
                            disabled={isDisabled('dialogueMode')}
                            enumKey={'learningTaskDialogueModeType'}
                            filter={(item) => {
                                if(Udesk.enums.lessonType.ppt.id === courseType) {
                                    return Udesk.enums.learningTaskDialogueModeType.ppt.id === item.id;
                                } else {
                                    return Udesk.enums.learningTaskDialogueModeType.ppt.id !== item.id;
                                }
                            }}
                        ></EnumRadio>
                    </Form.Item>
                )}
                <Form.Item
                    label={locales.pages.gong.coachTaskCenter.edit.userPortrait}
                    name={'imageIdInfo'}
                    rules={[{ required: true }]}
                >
                    <UserPortraitSelect disabled={isDisabled('dialogueMode')} courseId={lessonId} onChange={(val) => setImageIdInfo(val)} />
                </Form.Item>
                <Form.Item
                    label={`${
                        taskType === 1
                            ? locales.pages.gong.coachTaskCenter.list.taskTypePractice
                            : locales.pages.gong.coachTaskCenter.list.taskTypeTest
                    }${locales.pages.gong.coachTaskCenter.edit.difficultyLevel}`}
                    name="difficultyLevel"
                    rules={[{ required: true }]}
                >
                    <Rate allowHalf count={5} />
                </Form.Item>
                <Form.Item
                    label={
                        courseType === Udesk.enums.lessonType.question.id ? /* 单题作答时长限制 */UdeskLocales['current'].pages.coach.taskCenter.edit.index.timeLimitForSingleQuestionAnswering : locales.pages.gong.coachTaskCenter.edit.limitDuration
                    }
                    name="limitDurationInfo"
                    rules={[{ required: true }]}
                >
                    <DurationLimit disabled={isDisabled('limitDurationInfo')}/>
                </Form.Item>
                {taskType === 1 && (
                    <Form.Item
                        label={locales.pages.gong.coachTaskCenter.edit.tipLabel}
                        name="isTip"
                        valuePropName="checked"
                        rules={[{ required: true }]}
                        // extra="是否允许学员查看参考话术"
                        tooltip={locales.pages.gong.coachTaskCenter.edit.tipTooltip}
                    >
                        <Switch disabled={isDisabled('isTip')}/>
                        {/* <Radio.Group>
                        <Radio value={1}>允许</Radio>
                        <Radio value={0}>不允许</Radio>
                    </Radio.Group> */}
                    </Form.Item>
                )}
                {taskType === 2 && (
                    <Form.Item
                        label={locales.pages.gong.coachTaskCenter.edit.limitTimes}
                        name="limitTimes"
                        rules={[{ required: true }]}
                    >
                        <ExamTimesInput />
                    </Form.Item>
                )}
                <Form.Item
                    label={`${
                        taskType === 1
                            ? locales.pages.gong.coachTaskCenter.list.taskTypePractice
                            : locales.pages.gong.coachTaskCenter.list.taskTypeTest
                    }${locales.pages.gong.coachTaskCenter.edit.time}`}
                    name="taskTimeInfo"
                    rules={[{ required: true }]}
                >
                    <PracticeTimePicker />
                </Form.Item>
                <Form.Item
                    label={locales.pages.gong.coachTaskCenter.edit.gradingStandart}
                    name="gradingStandartInfo"
                    extra={
                        <span style={{ color: '#555' }}>
                            <span>
                                {locales.pages.gong.coachTaskCenter.edit.gradingStandartDescAffix}
                            </span>
                            <span style={{ color: '#1D66F7' }}>{lessonScore.total}</span>
                            <span>
                                {Udesk.utils.string.format(
                                    locales.pages.gong.coachTaskCenter.edit
                                        .gradingStandartDescSuffix,
                                    lessonScore.speechCraftScore,
                                    lessonScore.emotionScore,
                                    lessonScore.speedScore
                                )}
                            </span>
                        </span>
                    }
                    rules={[{ validator: (_, value) => {
                        const { 
                            scoreStandardUnit, 
                            commitScore, excellentScore 
                        } = value || {};

                        if (commitScore >= excellentScore) {
                            return Promise.reject(
                                Udesk.utils.string.format(/* 优秀标准需高于达标标准 */UdeskLocales['current'].pages.coach.taskCenter.edit.index.excellentStandardsNeedToBeHigherThanStandardStandards)
                            );
                        }

                        if (scoreStandardUnit === Udesk.enums.scoreStandardUnitType.score.id) {
                            const flag = Reflect.ownKeys(value || {}).some(key => {
                                if (key !== 'scoreStandardUnit') {
                                    return value[key] > lessonScore?.total || value[key] <= 0;
                                }
                                return false;
                            });
                            
                            return !flag ? Promise.resolve() : Promise.reject(
                                Udesk.utils.string.format(
                                    /* 评分标准需要满足大于0分，小于等于{0}分 */UdeskLocales['current'].pages.coach.taskCenter.edit.index.theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points, lessonScore.total,
                                )
                            );
                        } else {
                            if (
                                0 < commitScore && commitScore <= 100 &&
                                0 < excellentScore && excellentScore <= 100
                            ) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject(
                                    Udesk.utils.string.format(/* 评分标准需要满足大于0%，小于等于100% */UdeskLocales['current'].pages.coach.taskCenter.edit.index.theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100)
                                );
                            }
                        }
                    }}, { required: true }]}
                >
                    <GradingStandart disabled={isDisabled('gradingStandartInfo')} lessonScore={lessonScore}/>
                </Form.Item>
                <Form.Item
                    label={locales.pages.gong.coachTaskCenter.edit.completeStandart}
                    name="completeStandartInfo"
                    rules={[{ required: true }]}
                >
                    <CompleteStandart disabled={isDisabled('completeStandartInfo')} taskType={taskType} lessonScore={lessonScore}/>
                </Form.Item>
                <Form.Item
                    label={/* 评分是否需人工审核 */UdeskLocales['current'].pages.coach.taskCenter.edit.index.doRatingsRequireManualReview}
                    name="enableManualReview"
                    rules={[{ required: true }]}
                >
                    <EnumRadio enumKey={'enableManualReviewType'} onChange={onEnableManualReviewTypeChange}/>
                </Form.Item>
                {
                    enableManualReview === Udesk.enums.enableManualReviewType.open.id ? (
                        <Form.Item
                            label={/* 分配策略 */UdeskLocales['current'].pages.coach.taskCenter.edit.index.allocationStrategy}
                            name="manualReviewConfigObj"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        let {type, ...others} = value || {};
                                        let flag = true;

                                        for(let key in others) {
                                            if (others[key]?.length) {
                                                flag = false;
                                            }
                                        }

                                        if(flag){
                                            return Promise.reject(new Error(type === 1 ? /* 请选择分配人员 */UdeskLocales['current'].pages.coach.taskCenter.edit.index.pleaseSelectTheAssignedPersonnel : /* 请选择指定人员 */UdeskLocales['current'].pages.coach.taskCenter.edit.index.pleaseSelectADesignatedPerson));
                                        }
                                        return Promise.resolve();
                                    }
                                },
                            ]}>
                            <ManualReviewConfig treeData={treeData} />
                        </Form.Item>
                    ) : null
                }
                <Form.Item {...tailLayout}>
                    <Space size={'middle'}>
                        <Button onClick={goBack}>{locales.labels.cancel}</Button>
                        <Button type="primary" htmlType="submit">
                            {locales.labels.release}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Page>
    );
};

export default hookProps(TaskCenterEdit);
