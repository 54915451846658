const salesRoutesGlobal = [
    {
        path: '/sales',
        name: 'salesHome',
        routes: [
            // 复盘分析-首页
            {
                path: '/home-page',
                name: 'homePage',
            },
            // 复盘分析-gong
            {
                path: '/call-analysis',
                name: 'callAnalysis',
                routes: [
                    {
                        path: '/call-list',
                        name: 'callAnalysisList',
                    },
                    {
                        path: '/call-detail/:callId',
                        name: 'gongCallDetail',
                    },
                ],
            },
            // 复盘分析-辅导任务
            {
                path: '/coach-task',
                name: 'coachTask',
                authCode: 'help:root',
                routes: [
                    {
                        path: '/list',
                        name: 'coachTaskList',
                    },
                ],
            },
            // 复盘分析-团队分析
            // {
            //     path: '/team-analysis',
            //     name: 'teamAnalysis',
            //     authCode: 'group:root',
            // },
            // 复盘分析-分析赋能
            {
                path: '/analysis-enable',
                name: 'analysisEnable',
                routes: [
                    {
                        path: '/monitoring-report',
                        name: 'monitoringReport',
                        // authCode: 'supervision:root',
                    },
                    {
                        path: '/team-analysis',
                        name: 'teamAnalysis',
                        authCode: 'group:root',
                    },
                    {
                        path: '/study',
                        name: 'analysisEnableStudy',
                    },
                    {
                        path: '/help',
                        name: 'analysisEnableHelp',
                    },
                    {
                        path: '/replay',
                        name: 'analysisEnableReplay',
                    },
                    {
                        path: '/sentiment',
                        name: 'analysisEnableSentiment',
                    },
                    {
                        path: '/execution-analysis',
                        name: 'executionAnalysis',
                        authCode: 'analysis:supervision:board',
                    },
                    {
                        path: '/business',
                        name: 'analysisEnableBusiness',
                        routes: [
                            {
                                path: '/list',
                                name: 'analysisBusinessAnalysisList',
                            },
                            {
                                path: '/detail/:id',
                                name: 'analysisBusinessAnalysisDetail',
                            },
                            {
                                path: '/create',
                                name: 'analysisBusinessAnalysisCreate',
                            },
                        ],
                    },
                ],
            },
            //销售赋能 客户
            {
                path: '/sale-client-center',
                name: 'saleClientCenterEntry',
                routes: [
                    {
                        path: '/list',
                        name: 'saleClientList',
                    },
                    {
                        path: '/detail/:id',
                        name: 'saleClientDetail',
                    },
                ],
            },
            // 业务配置
            {
                path: '/business-configuration',
                name: 'salesBusinessConfigurationEntry',
                routes: [
                    // 智能标签
                    {
                        path: '/intelligent-tags',
                        name: 'salesIntelligentTags',
                        authCode: 'smart:tag:root',
                        routes: [
                            {
                                path: '/session-label',
                                authCode: 'smart:tag:root',
                                name: 'sessionLabel',
                                routes: [
                                    {
                                        path: '/index',
                                        name: 'sessionLabelTagsIndex',
                                    },
                                    {
                                        path: '/new',
                                        authCode: 'smart:tag:add',
                                        name: 'sessionLabelTagsNew',
                                    },
                                    {
                                        path: '/edit/:id',
                                        authCode: 'smart:tag:edit',
                                        name: 'sessionLabelTagsEdit',
                                    },
                                ],
                            },
                            {
                                path: '/key-events',
                                authCode: 'smart:tag:root',
                                name: 'keyEvents',
                                routes: [
                                    {
                                        path: '/index',
                                        name: 'keyEventsTagsIndex',
                                    },
                                    {
                                        path: '/new',
                                        authCode: 'smart:tag:add',
                                        name: 'keyEventsTagsNew',
                                    },
                                    {
                                        path: '/edit/:id',
                                        authCode: 'smart:tag:edit',
                                        name: 'keyEventsTagsEdit',
                                    },
                                ],
                            },
                            {
                                path: '/customer-label',
                                authCode: 'customer:tag:root',
                                name: 'customerLabel',
                                routes: [
                                    {
                                        path: '/index',
                                        name: 'customerLabelTagsIndex',
                                    },
                                    {
                                        path: '/new',
                                        authCode: 'smart:tag:add',
                                        name: 'customerLabelTagsNew',
                                    },
                                    {
                                        path: '/edit/:id',
                                        authCode: 'smart:tag:edit',
                                        name: 'customerLabelTagsEdit',
                                    },
                                ],
                            },
                            {
                                path: '/supervision',
                                // authCode: 'smart:tag:root',
                                name: 'supervision',
                                routes: [
                                    {
                                        path: '/index',
                                        name: 'supervisionList',
                                    },
                                    {
                                        path: '/new',
                                        name: 'supervisionCreate',
                                    },
                                    {
                                        path: '/edit/:id',
                                        name: 'supervisionDetail',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: '/sales-stage',
                        name: 'salesStageIndex',
                        routes: [
                            {
                                path: '/index',
                                name: 'salesStageListIndex',
                            },
                            {
                                path: '/create',
                                name: 'salesStageCreate',
                            },
                            {
                                path: '/detail/:id',
                                name: 'salesStageDetail',
                            },
                        ],
                    },
                    {
                        path: '/communication-strategy',
                        name: 'communicationStrategyIndex',
                        routes: [
                            {
                                path: '/index',
                                name: 'communicationStrategyListIndex',
                            },
                            {
                                path: '/create',
                                name: 'communicationStrategyCreate',
                            },
                            {
                                path: '/detail/:id',
                                name: 'communicationStrategyDetail',
                            },
                        ],
                    },
                    {
                        path: '/risk-statement',
                        name: 'riskStatementIndex',
                        routes: [
                            {
                                path: '/index',
                                name: 'riskStatementListIndex',
                            },
                            {
                                path: '/create',
                                name: 'riskStatementCreate',
                            },
                            {
                                path: '/detail/:id',
                                name: 'riskStatementDetail',
                            },
                        ],
                    },
                    {
                        path: '/supervision-test',
                        name: 'supervisionTest',
                        authCode: 'smart:tag:test',
                    },
                ],
            },
            {
                path: '/learning-center',
                name: 'salesLearningCenter',
                routes: [
                    // 复盘分析-案例库
                    {
                        path: '/file-case-base',
                        name: 'fileCaseBase',
                        routes: [
                            {
                                path: '/folder-list',
                                name: 'caseBaseFolderList',
                            },
                        ],
                    },
                    //销售赋能 知识库
                    {
                        path: '/knowledge-base',
                        name: 'gongKnowledgeBase',
                        authCode: 'sys:knowledge:root',
                        routes: [
                            {
                                path: '/list',
                                authCode: 'sys:knowledgePoint:root',
                                name: 'gongKnowledgeBaseList',
                            },
                            {
                                path: '/detail/:id',
                                authCode: 'sys:knowledgePoint:edit',
                                name: 'gongKnowledgeBaseDetail',
                            },
                            {
                                path: '/view/:id',
                                authCode: 'sys:knowledgePoint:view',
                                name: 'gongKnowledgeBaseView',
                            },
                            {
                                path: '/create',
                                authCode: 'sys:knowledgePoint:add',
                                name: 'gongKnowledgeBaseCreate',
                            },
                        ],
                    },
                    // 8、话术挖掘
                    {
                        path: '/words-mining',
                        name: 'salesWordsMiningView',
                        authCode: 'semantic:WordsMining:root',
                        routes: [
                            {
                                path: '/dissent/favorite',
                                name: 'salesDissentFavoriteWordsMining',
                            },
                            {
                                path: '/selling-point/favorite',
                                name: 'salesSellingPointFavoriteWordsMining',
                            },
                        ],
                    },
                ],
            },
            // 智能分析
            {
                path: '/intelligent-analysis',
                authCode: 'smart:root',
                name: 'salesIntelligentAnalysis',
                routes: [
                    {
                        path: '/intellect-tags',
                        name: 'analysisEnableIntellectTags',
                    },
                    // 分组测试
                    {
                        path: '/group-test',
                        name: 'groupTest',
                        routes: [
                            {
                                path: '/index',
                                name: 'groupTestList',
                                routes: [
                                    {
                                        path: '/analysis',
                                        name: 'groupTestAnalysis',
                                    },
                                ]
                            },
                    
                            {
                                path: '/add',
                                name: 'groupTestDetail',
                            },
                            {
                                path: '/edit/:id',
                                name: 'groupTestDetailEdit',
                            },
                        ],
                    },
                ],
            },
            // 语义智能
            {
                path: '/semantic-intelligence',
                authCode: 'semantic:root',
                name: 'salesSemanticIntelligence',
                routes: [
                    // 智能词库
                    // {
                    //     path: '/smart-words-library',
                    //     name: 'smartWordsLibrary',
                    //     authCode: 'semantic:corpora:root',
                    //     routes: [
                    //         {
                    //             path: '/synonym-words',
                    //             name: 'smartWordsLibrarySynonymWords',
                    //         },
                    //         {
                    //             path: '/sensitive-words',
                    //             name: 'smartWordsLibrarySensitiveWords',
                    //         },
                    //         {
                    //             path: '/professional-words',
                    //             name: 'smartWordsLibraryProfessionalWords',
                    //         },
                    //         {
                    //             path: '/attention-words',
                    //             name: 'smartWordsLibraryAttentionWords',
                    //         },
                    //         {
                    //             path: '/ignore-words',
                    //             name: 'smartWordsLibraryIgnoreWords',
                    //         },
                    //         {
                    //             path: '/words-weight',
                    //             name: 'smartWordsLibraryWordsWeight',
                    //         },
                    //         {
                    //             path: '/corpus-data',
                    //             name: 'smartWordsLibraryCorpusData',
                    //         },
                    //         {
                    //             path: '/typo-library',
                    //             name: 'smartWordsLibraryTypoLibrary',
                    //         },
                    //     ],
                    // },
                    // 概念词
                    {
                        path: '/concept-word',
                        name: 'salesConceptWord',
                        authCode: 'semantic:conceptWords:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'salesConceptWordIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:conceptWords:add',
                                name: 'salesConceptWordNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:conceptWords:edit',
                                name: 'salesConceptWordEdit',
                            },
                        ],
                    },
                    // 语义标签
                    {
                        path: '/semantic-tags',
                        name: 'salesSemanticTags',
                        authCode: 'semantic:semanticTag:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'salesSemanticTagsIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:semanticTag:add',
                                name: 'salesSemanticTagsNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:semanticTag:edit',
                                name: 'salesSemanticTagsEdit',
                            },
                        ],
                    },
                    // 信息实体
                    {
                        path: '/information-entities',
                        name: 'salesInformationEntities',
                        authCode: 'semantic:imformation:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'salesInformationEntitiesIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:imformation:add',
                                name: 'salesInformationEntitiesNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:imformation:edit',
                                name: 'salesInformationEntitiesEdit',
                            },
                        ],
                    },
                ],
            },
            // 训练中心
            {
                path: '/training-center',
                name: 'salesTrainingCenter',
                authCode: 'training:root',
                routes: [
                    // 快速训练
                    {
                        path: '/fast-training',
                        name: 'salesFastTrainingIndex',
                        authCode: 'training:fast:root',
                    },
                    // 训练历史
                    {
                        path: '/training-history',
                        name: 'salesTrainingHistoryIndex',
                        authCode: 'training:history:root',
                    },
                    {
                        path: '/topic-index',
                        name: 'topicList',
                        authCode: 'training:subjectList:root',
                    },
                    {
                        path: '/topic-callout',
                        name: 'topicCallout',
                        authCode: 'training:subjectMark:root',
                    },
                    // 7、聚类分析
                    {
                        path: '/clustering-analysis',
                        name: 'salesClusteringAnalysis',
                        authCode: 'semantic:cluster:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'salesClusteringAnalysisIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:cluster:add',
                                name: 'salesClusteringAnalysisNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:cluster:edit',
                                name: 'salesClusteringAnalysisEdit',
                            },
                            {
                                path: '/detail/:id',
                                authCode: 'semantic:cluster:view',
                                name: 'salesClusteringAnalysisDetail',
                            },
                        ],
                    },
                    // 8、话术挖掘
                    // {
                    //     path: '/words-mining',
                    //     name: 'salesWordsMining',
                    //     authCode: 'semantic:WordsMining:root',
                    //     routes: [
                    //         {
                    //             path: '/dissent',
                    //             name: 'dissentWordsMining',
                    //         },
                    //         {
                    //             path: '/dissent/favorite',
                    //             authCode: 'semantic:WordsFavorite:root',
                    //             name: 'favoriteWordsMining',
                    //         },
                    //         {
                    //             path: '/selling-point',
                    //             name: 'sellingPointWordsMining',
                    //         },
                    //     ],
                    // },
                ],
            },
            // 系统设置
            {
                path: '/admin',
                authCode: 'sys:setting:root',
                name: 'salesAdminEntry',
                routes: [
                    // 系统设置
                    {
                        path: '/system/index',
                        name: 'salesAdminSystemIndex',
                        authCode: 'sys:info:root',
                    },
                    // 会话设置
                    {
                        path: '/session/index',
                        name: 'sessionSetings',
                        // authCode: 'sys:review:wecom:root',
                    },

                    // 语音识别
                    {
                        path: '/speech-recognition/index',
                        name: 'salesSpeechRecognitionIndex',
                        authCode: 'sys:voice:root',
                    },
                    // 消息日志
                    // {
                    //     path: '/system-log/index',
                    //     name: 'systemLogIndex',
                    //     authCode: 'sys:message:root',
                    // },
                    // 消息中心
                    {
                        path: '/message-center',
                        name: 'messageCenter',
                        authCode: 'sys:review:msgbox:root',
                        routes: [
                            {
                                path: '/reply',
                                name: 'adminMsgCenterReply',
                            },
                            {
                                path: '/answer',
                                name: 'adminMsgCenterAnswer',
                            },
                            {
                                path: '/share',
                                name: 'adminMsgCenterShare',
                            },
                            {
                                path: '/subscription',
                                name: 'adminMsgCenterSubscription',
                            },
                            {
                                path: '/study',
                                name: 'adminMsgCenterStudy',
                            },
                        ],
                    },
                    // gong 员工管理
                    {
                        path: '/medicine-user',
                        name: 'adminGongUser',
                        routes: [
                            {
                                path: '/list',
                                name: 'adminGongUserList',
                            },
                            {
                                path: '/edit/:id',
                                name: 'adminGongUserEdit',
                            },
                            {
                                path: '/detail/:id',
                                name: 'adminGongUserDetail',
                            },
                        ],
                    },
                    // gong 角色管理
                    {
                        path: '/medicine-role',
                        name: 'adminGongRole',
                        routes: [
                            {
                                path: '/list',
                                name: 'adminGongRoleList',
                            },
                            {
                                path: '/edit/:id',
                                name: 'adminGongRoleEdit',
                            },
                        ],
                    },
                    // 任务中心
                    {
                        path: '/task-center',
                        name: 'salesTaskCenter',
                        authCode: 'sys:impExp:root',
                        routes: [
                            {
                                path: '/download-list',
                                name: 'salesTaskCenterDownloadList',
                            },
                            {
                                path: '/upload-list',
                                name: 'salesTaskCenterUploadList',
                            },
                            {
                                path: '/task-list',
                                name: 'salesTaskCenterTaskList',
                            },
                        ],
                    },
                    // 计费中心
                    {
                        path: '/billing-center',
                        name: 'salesBillingCenterIndex',
                        authCode: 'hc:charge:root',
                    },
                    // 系统集成
                    {
                        path: '/integration',
                        name: 'salesSystemIntegration',
                        routes: [
                            {
                                path: '/cc-pass',
                                name: 'salesSystemIntegrationCCpass',
                            },
                            //企微对接
                            {
                                path: '/wecom',
                                name: 'salesWechatDocking',
                            },
                            {
                                path: '/taobao',
                                name: 'salesTaobaoDocking',
                            },
                            //微丰对接
                            {
                                path: '/weifeng',
                                name: 'salesWeifengDocking',
                            },
                        ],
                        // authCode: 'hc:charge:root',
                    },
                    //客户字段
                    // {
                    //     path: '/customer-field',
                    //     name: 'customerField',
                    //     authCode: 'sys:customer:field',
                    // },
                    //本地录音上传
                    {
                        path: '/record-upload/index',
                        name: 'salesRecordUploadIndex',
                        authCode: 'sys:voiceUpload:root',
                    },
                    {
                        path: '/record-upload/upload-call',
                        name: 'salesRecordUploadDetail',
                        authCode: 'sys:voiceUpload:root',
                    },
                    {
                        path: '/record-upload/upload-im',
                        name: 'salesRecordUploadDetailIm',
                        authCode: 'sys:voiceUpload:root',
                    },
                    // {
                    //     path: '/anomalous-data',
                    //     name: 'anomalousData',
                    //     authCode: 'sys:dataAbnormal:root',
                    //     routes: [
                    //         {
                    //             path: '/index',
                    //             name: 'anomalousDataIndex',
                    //         },
                    //     ],
                    // },
                    //logo设置
                    // {
                    //     path: '/logo-site',
                    //     name: 'logoSite',
                    //     // authCode: 'sys:customer:field',
                    // },
                ],
            },
            //404
            {
                path: '/404',
                name: 'salesPageNotFound',
            },
        ],
    },

];

export {
    salesRoutesGlobal
};
