import Locales from '../locales/index';
const templateTypeList = [
    { id: 1, key: 'task', name: Locales.current.pages.admin.templateComponent.list.task, list: [] },
    { id: 2, key: 'point', name: Locales.current.pages.admin.templateComponent.list.point, list: [] },
    { id: 10, key: 'tag', name: Locales.current.pages.admin.templateComponent.list.tag, list: [] },
    { id: 20, key: 'concept', name: Locales.current.pages.admin.templateComponent.list.concept, list: [] },
    { id: 13, key: 'info', name: Locales.current.pages.admin.templateComponent.list.info, list: [] },
    { id: 12, key: 'inter', name: Locales.current.pages.admin.templateComponent.list.inter, list: [] },
    { id: 19, key: 'multi', name: Locales.current.pages.admin.templateComponent.list.muti, list: [] },
];
const templateTypeStatus = [
    { id: 1, key: 'newer' },
    { id: 2, key: 'add' },
    { id: 3, key: 'delete' },
];
const templateModuleStatus = [
    { id: 1, key: 'status' },
    { id: 2, key: 'disabled' },
    { id: 3, key: 'open' },
    { id: 4, key: 'delete' },
    { id: 5, key: 'update' },
];
const templateChangeType = [
    { id: 1, key: 'add' },
    { id: 2, key: 'newer' },
    { id: 3, key: 'del' },
];
const templateShowable = [
    { id: 0, key: 'false' },
    { id: 1, key: 'true' },
];
export default {
    templateTypeList,
    templateTypeStatus,
    templateModuleStatus,
    templateChangeType,
    templateShowable,
};
