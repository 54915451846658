import Udesk from 'Udesk';
import { fire } from 'src/util/core';
import { linkTo } from 'src/util/link';
import { getDateTimeString } from 'src/util/moment';
import UdeskLocales from 'UdeskLocales';

let id = 1;
export const getId = () => {
    return id++;
};

export const notifyASREmpty = () => {
    Udesk.ui.notify.warn(`${/* 未检测到用户语音内容, 请重新尝试 */UdeskLocales['current'].pages.coach.learningCenter.components.util.noUserVoiceContentDetectedPleaseTryAgain}`);
};

export const createMessage = (props = {} as any) => {
    const {
        channel = 2, // 说话频道 1-非用户输入 2-用户输入
        nodeType = 2, // 节点类型 1-旁白 2-学员说话 3-机器人说话 4-知识库问答 5-ppt节点
        content = "", // 说话内容
        ossKey = "", // 如果是语音，语音上传到云服务器地址
        startSecond = Date.now(), // 开始说话的毫秒数
        bindNodeId,
        pptUrl,
        isSync, // 0:没有同步，1：正在同步，2：已同步
        createDate = getDateTimeString(),
        nodeId, // 对应的节点id
    } = props;

    const chatData: any = {
        id: getId().toString(), 
        channel,
        nodeType,
        content,
        ossKey,
        startSecond,
        bindNodeId,
        pptUrl,
        isSync,
        createDate,
        nodeId,
    };

    return chatData;
};

export const createMessage0 = (props = {} as any) => {
    const {
        id = getId().toString(), 
        created_at = getDateTimeString(),
        type = 'call', 
        sender = 'agent', 
        message = '', 
        voice_url = '/',
        isSync, // 0:没有同步，1：正在同步，2：已同步
        bindNodeId,
        pptUrl,
        audioPlayHandle = undefined as any,
    } = props;

    const chatData: any = {
        id,
        created_at,
        sender,
        describes: null,
        send_status: "",
        isPlaying: -1,
        canRemarkEdit: false,
        fieldFlag: false,
        isSync,
        bindNodeId,
        pptUrl,
        playRudio () {},
    };

    const content: any = {
        data: {
            content: message,
        },
        type: 'message',
    };

    if (type === 'call') {
        content.data.voice_url = voice_url;
        chatData.playRudio = (url) => {
            fire(audioPlayHandle, url);
        };
    }

    chatData.content = JSON.stringify(content);

    return chatData;
};


export const isExercise= (taskType: number | undefined, exerciseCallback: any = true, testCallback: any = false) => {
    if (taskType === Udesk.enums.learningTaskType.underWay.id) {
        return fire(testCallback); // 2: 考试
    } 
    if (taskType === Udesk.enums.learningTaskType.notStarted.id){
        return fire(exerciseCallback); // 1: 练习
    }
    return null;
};

export const switchDialogueMode = (dialogueMode: number, audioCallback, textCallback, pptCallback, error) => {
    if (dialogueMode === Udesk.enums.learningTaskDialogueModeType.voice.id) {
        return fire(audioCallback);
    }
    if (dialogueMode === Udesk.enums.learningTaskDialogueModeType.text.id) {
        return fire(textCallback);
    }
    if (dialogueMode === Udesk.enums.learningTaskDialogueModeType.ppt.id) {
        return fire(pptCallback);
    }
    return fire(error);
};

export const linkToStart = (history, taskType, dialogueMode, ...params) => {
    let routeName = '';

    isExercise(taskType, () => {
        switchDialogueMode(
            dialogueMode, 
            () => {
                routeName = 'coachLearningCenterExerciseTaskStart';
            },
            () => {
                routeName = 'coachLearningCenterExerciseTaskTextStart';
            },
            () => {
                routeName = 'coachLearningCenterExerciseTaskPPTStart';
            }, 
            () => {
                routeName = 'coachLearningCenterExerciseTaskTextStart';//'coachLearningCenterExerciseTaskStart';
            }
        );
    }, () => {
        switchDialogueMode(
            dialogueMode,             
            () => {
                routeName = 'coachLearningCenterTestTaskStart';
            },
            () => {
                routeName = 'coachLearningCenterTestTaskTextStart';
            },
            () => {
                routeName = 'coachLearningCenterTestTaskPPTStart';
            }, 
            () => {
                routeName = 'coachLearningCenterTestTaskTextStart';
            }
        );
    });
    linkTo(history, routeName, ...params);
};