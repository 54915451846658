import TasksCheckPointIndexComponent from './component';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

export default class TasksCheckPointIndexRoute extends TasksCheckPointIndexComponent {
    model() {
        const { props } = this;
        let params = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));
        let taskVersionPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/${params.taskId}/version`,
                props.sdkOptions
            );
            Udesk.ajax.get(url)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                        reject(reason);
                    }
                );
        });

        let modelPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionTasks/" + params.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                        reject(reason);
                    }
                );
        });
        let url = Udesk.business.apiPath.concatApiPath(
            `/inspectionPointTemplates?dataSource=${this.props.sdkOptions.props.task.inspectDataSource}`,
            this.props.sdkOptions
        );
        let checkModel = new Promise((resolve, reject) => {
            Udesk.ajax.get(url)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.pages.tasks.manage.template.checkPoint.index.getTemplateError);
                        reject(reason);
                    }
                );
        });
        let checkModule = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionModules/2/dataSource/${this.props.sdkOptions.props.task.inspectDataSource}`,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    reject(reason);
                }
            );
        });
        let sysConfigPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/companys/sys/config', this.props.sdkOptions);
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            taskId: params.taskId,
            task: props.sdkOptions.props.task,
            inspectionConditions: props.sdkOptions.props.inspectionConditions,
            status: modelPromise,
            taskVersion: taskVersionPromise,
            checkData: checkModel,
            checkModuleData: checkModule,
            sysConfig: sysConfigPromise,
        };
    }
}
