import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import { FormBuilder, Button, Space, Row, Col } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import cusConfigMap from 'src/common/config/form-config/cus-component-map';
import SmartTags from 'Component/pages/components/smart-tags';
import Page from 'Component/common/udesk-qa-page-layout';
import Locales from 'UdeskLocales';
import './style.scss';
import { getWecomSmartTagsById, putWecomSmartTagsById } from 'src/api/wecomSmartTags/{id}';
import { Rule, WecomSmartTagFoundResponse } from 'src/api/types';
import { getCustomerTagGroups } from 'src/api/customerTagGroups';
import { postWecomSmartTags } from 'src/api/wecomSmartTags';


const LAYOUT = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 },
};
const MAX_WIDTH = {
    maxWidth: 350,
};

const FormSmartTags = (props) => {
    const { value = [], onChange, ...others } = props;
    const onSamrTagAdd = (v, options) => {
        onChange([...value].concat(options.map(option => ({ categoryName: option.children, id: option.value, tagType: 1 }))));
    };
    const onSamrTagDelete = (tag) => {
        onChange(value.filter((i) => i.id !== tag.id));
    };
    return (
        <SmartTags
            {...others}
            selectMode='multiple'
            smartTags={value}
            onSmartTagsChange={onChange}
            onSamrTagDelete={onSamrTagDelete}
            onSamrTagAdd={onSamrTagAdd}
        />
    );
};
interface InitialValues extends Omit<WecomSmartTagFoundResponse, 'ruleList'> {
    ruleList: Rule;
}

const DEFAULT_CONDITION: any = {
    conditionType: 1, //1话术2交互3特征4信息
    taskConditionId: null,
    orderId: 1,
    operatorType: 11,
    idCode: 'R1',
    operatorList: [
        {
            applyConditionHitType: 1,
            applyOperatorScope: {
                key: 'all',
                id: 1,
            },
            type: 11,
            operator: 'all',
            applyRole: 'all',
        },
    ],
    applyConditionId: undefined,
    applyConditionIdCode: null,
    operatorLogic: '1',
    operator: 'all',
    applyRole: 'all',
    applyScope: null,
    similarityScore: null,
    differWordNumber: null,
    wordPerMinite: null,
    maxWordPerMinite: null,
    grabIntervalTime: null,
    grabMaxSpeakTime: null,
    intervalSecond: null,
    keyword: '',
    semanticSentence: null,
    applyConditionScope: null,
    applyConditionHitType: 1,
    // applyOperatorScope: {
    //     key: 'all',
    //     id: 1
    // },
    param: {},
};



function RealTimeAnalysis(props) {
    const locales = Locales['current'];
    const smartCustomerTag = locales.pages.wechatQa.smartCustomerTag.detail;

    const id = props.match.params.id;
    const [initialValues, setInitialValues] = useState<InitialValues>({
        conditionList: [DEFAULT_CONDITION],
        ruleList: {
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        },
        openStatus: 1,
    });
    const [loading, setLoading] = useState(false);
    const [conditions, setConditions] = useState<any[]>([]);
    const [tagSelectDataSource, setTagSelectDataSource] = useState<any[]>([]);
    const [wrapForm] = FormBuilder.useForm();
    // const [triggerPointId, setTriggerPointId] = useState<number | undefined>(undefined);
    const transitionToIndex = () => {
        let routeOptions = {
            history: props.history,
            routeName: 'wechatQaCustomerLabelIndex',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const onFinish = (values) => {
        const { tagList, ruleList, ...other } = values;
        let params = {
            ...other,
            tagIds: tagList.map((i) => i.tagId || i.id),
            ruleList: Array.isArray(ruleList) ? ruleList : [ruleList],
            relateConditionList: other.conditionList.map((i) => ({ idCode: i.idCode })),
        };

        if (id) {
            putWecomSmartTagsById(params, {
                segments: {
                    id,
                },
            }).then(
                (resp) => {
                    transitionToIndex();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
            return;
        }

        postWecomSmartTags(params).then(
            (resp) => {
                transitionToIndex();
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };

    const config: FormProps<any> = {
        // onValuesChange: onCollectionValuesChange,
        onFinish: onFinish,
        initialValues: initialValues,
        customComponents: { ...cusConfigMap, FormSmartTags },
        ...LAYOUT,
        fields: [
            {
                type: 'Input',
                name: 'ruleName',
                label: smartCustomerTag.name,
                props: {
                    showCount: true,
                    maxCount: 40,
                    style: MAX_WIDTH,
                },
                rules: [
                    {
                        required: true,
                    },
                    {
                        max: 40,
                        type: 'string',
                    },
                ],
            },
            {
                type: 'FormSmartTags',
                name: 'tagList',
                label: smartCustomerTag.tags,
                props: {
                    showCount: true,
                    maxCount: 40,
                    noStayle: true,
                    dataSource: tagSelectDataSource,
                    // style: MAX_WIDTH,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'Select',
                name: 'openStatus',
                label: smartCustomerTag.status,
                props: {
                    options: [
                        {
                            label: locales.labels.switchOn,
                            value: 1,
                        },
                        {
                            label: locales.labels.switchOff,
                            value: 0,
                        },
                    ],
                    style: MAX_WIDTH,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'ConditionListGather' as any,
                name: 'conditionList',
                label: smartCustomerTag.condition,
                props: {
                    type: [0],
                    ruglar: true,
                    isCollection: true,
                    onChange: (value) => {
                        setConditions(value);
                    }
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'Logic',
                name: 'ruleList',
                label: smartCustomerTag.logic,
                props: {
                    conditions: conditions,
                    isCondition: true,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        ],
        footerRender: false,
    };
    // useEffect(() => {
    //
    // }, [triggerPointId])
    useEffect(() => {
        getCustomerTagGroups().then((resp) => {
            setTagSelectDataSource(
                resp.data?.map((item) => {
                    return {
                        ...item,
                        categoryName: item.groupName,
                        children: item.tags?.map((tag) => {
                            return {
                                ...tag,
                                originId: tag.id,
                                id: tag.tagId,
                            };
                        }),
                    };
                }) ?? []
            );
        });
        if (id === undefined) return;
        setLoading(true);
        getWecomSmartTagsById({
            segments: {
                id,
            },
        }).then(
            (resp) => {
                let initValues = resp.data ?? {};
                // if (!initValues.eventConditionList) {
                //     initValues.eventConditionList = Udesk.enums.messagePushType
                //         .filter(filterFunction)
                //         .map((item) => PushConfig(item.id));
                // } else {
                //     initValues.eventConditionList = initValues.eventConditionList.map((item) =>
                //         PushConfig(item.type, item.actionIdList, item.enable)
                //     );
                // }
                setInitialValues({...initValues, ruleList: !Array.isArray(initValues.ruleList) ? initValues.ruleList??{} : initValues?.ruleList?.[0]??{}});
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    }, []);
    useEffect(() => {
        wrapForm.resetFields();
    }, [initialValues]);
    return (
        <Page
            pageBodyClassName='real-time-analysis-page-detail'
            title={locales.labels[id ? 'edit' : 'create'] + locales.pages.wechatQa.smartCustomerTag.title}
            onBack={transitionToIndex}
            padding={true}
            loading={loading}
        >
            <FormBuilder {...(config as FormProps)} form={wrapForm}></FormBuilder>
            <Row>
                <Col offset={4}>
                    <Space>
                        <Button type='primary' onClick={wrapForm.submit}>{locales.labels.save}</Button>
                        <Button onClick={transitionToIndex}>{locales.labels.cancel}</Button>
                    </Space>
                </Col>
            </Row>
        </Page>
    );
}

export default React.memo(RealTimeAnalysis);
