import React from 'react';
import { Empty, Tag } from 'udesk-ui';
import Locales from 'UdeskLocales';
import './style.scss';

const CustomerInsight = (props) => {
    const { tags } = props;
    const locales = Locales['current'];

    return (
        <div className='customer-insight-tab-content'>
            <div className='customer-insight-title'>{/* 客户洞察 */}{locales.components.gong.customerInsight.index.customerInsight}</div>
            {/* <div className='customer-insight-card'>
                <div>客户信息</div>
            </div> */}
            <div className='customer-insight-card'>
                <div>{/* 客户标签 */}{locales.components.gong.customerInsight.index.customerLabel}</div>
                <div>
                    {tags?.length > 0 ? (
                        tags?.map((tag) => (
                            <Tag
                                key={tag.id}
                                style={{
                                    marginBottom: 4,
                                    background: tag.categoryColor + '1a',
                                    color: tag.categoryColor,
                                    maxWidth: '100%',
                                    overflowX: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                color={tag.categoryColor}
                                title={tag.tagName}
                            >
                                {tag.tagName}
                            </Tag>
                        ))
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(CustomerInsight);
