import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import type { TableComponentProps } from 'Component/common/udesk-qa-table-page';
import { Button, Switch } from 'udesk-ui';
import { getMallConfigs, postMallConfigs } from 'src/api/mallConfigs';
import { MallConfigFoundResponse } from 'src/api/types';
import { getMallConfigsById, deleteMallConfigsById, putMallConfigsById } from 'src/api/mallConfigs/{id}';
import { putMallConfigsByIdStatusByStatus } from 'src/api/mallConfigs/{id}/status/{status}';
// import './style.scss';

const DEFAULT_INITIAL_VALUES: any = {
    status: 1,
};

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const formLabels = locales.pages.admin.taobao.formLabels;
    const tableLabels = locales.pages.admin.taobao.tableLabels;
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<MallConfigFoundResponse[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [fieldDetailVisible, setFieldDetailVisible] = useState(false);
    const [initialValues, setInitialValues] = useState(DEFAULT_INITIAL_VALUES);
    const [modalLoading, setModalLoading] = useState(false);
    // const [filterConditions, setFilterConditions] =  useState({
    //     judgeStrategy: 1,
    //     conditionList: [],
    //     customJudgeLogic: ''
    // });

    // const filterProps = useMemo(() => {
    //     const onApply = ({ conditionList, customJudgeLogic, judgeStrategy, id, name }) => {
    //         setFilterConditions({
    //             judgeStrategy,
    //             conditionList,
    //             customJudgeLogic,
    //         });
    //     };
    //     return {
    //         ...filterConditions,
    //         onApply: onApply,
    //         // type: 9,
    //         // querryType: 'personaField',
    //     };
    // }, [filterConditions]);

    // const exportBtnProps = useMemo(() => {
    //     const onExport = (showFieldList) => {
    //     };
    //     return {
    //         onExport: onExport,
    //     };
    // }, []);

    const tableProps = useMemo<TableComponentProps>(() => {
        return {
            columns,
            dataSource,
            cacheKey: false,
            // subtractedHeight: 136,
            // onRow: (record) => {
            //     return {
            //         onClick: (event) => {
            //             const routeOptions = {
            //                 history: props.history,
            //                 routeName: 'customerPortraitIndex',
            //                 state: {
            //                     clientId: record.pf_1,
            //                 },
            //             };
            //             Udesk.ui.routing.transitionTo(routeOptions);
            //         }, // 点击行
            //     };
            // },
        };
    }, [columns, dataSource]);
    const queryList = ({ pageNum, pageSize }) => {
        setLoading(true);
        getMallConfigs({
            params: {
                pageNum,
                pageSize,
            },
        })
            .then((resp) => {
                setLoading(false);
                let { data, paging = {} } = resp;
                setPageNum(paging.pageNum!);
                setPageSize(paging.pageSize!);
                setTotal(paging.total!);
                setDataSource(data || []);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const paginationProps = useMemo(() => {
        return {
            current: pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                setPageNum(page);
                setPageSize(pageSize);
                queryList({ pageNum: page, pageSize });
            },
        };
    }, [pageNum, pageSize, total]);
    useEffect(() => {
        queryList({ pageNum, pageSize });
    }, []);
    const onTableItemDelete = (item) => {
        deleteMallConfigsById({
            segments: {
                id: item.id,
            },
            successMsg: locales.business.info.deleteSuccess,
        }).then(() => {
            queryList({ pageNum, pageSize });
        });
    };
    const onTableItemEdit = (item) => {
        setFieldDetailVisible(true);
        setModalLoading(true);
        getMallConfigsById({
            segments: {
                id: item.id,
            },
        }).then((resp) => {
            let { data } = resp;
            if (!data) {
                data = {};
            }
            setInitialValues({ ...data, confirm: data.secret });
            setModalLoading(false);
        });
    };
    const onStatusChange = (item) => {
        const newStatus = item.status ? 0 : 1;
        putMallConfigsByIdStatusByStatus({
            segments: {
                id: item.id,
                status: newStatus,
            },
            successMsg: locales.fix.statusChangeSuccess,
        }).then(() => {
            setDataSource((prev) => {
                return prev.map((d) => {
                    if (d.id === item.id) {
                        return {
                            ...item,
                            status: newStatus,
                        };
                    }
                    return d;
                });
            });
        });
    };
    useEffect(() => {
        setColumns([
            {
                title: tableLabels.name,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: tableLabels.account,
                dataIndex: 'account',
                key: 'account',
            },
            {
                title: tableLabels.updateUser,
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: tableLabels.updateTime,
                dataIndex: 'updateTime',
                key: 'updateTime',
            },
            {
                title: tableLabels.authorization,
                dataIndex: 'status',
                key: 'status',
                render: (name, item, index) => {
                    return (
                        <div>
                            <Switch
                                checked={item.status === 1}
                                onChange={onStatusChange.bind(null, item)}
                                size='small'
                            />
                        </div>
                    );
                },
            },
            {
                title: locales.labels.action,
                dataIndex: 'action',
                key: 'action',
                onEdit: onTableItemEdit,
                disableRule: (item) => ({edit: item.status, delete: item.status}),
                onDelete: onTableItemDelete,
            },
        ]);
    }, [pageNum, pageSize]);
    const create = useCallback(() => {
        setFieldDetailVisible(true);
        setInitialValues({ ...DEFAULT_INITIAL_VALUES });
    }, []);
    const mainBtn = useMemo(() => <Button onClick={create}>{locales.labels.create}</Button>, [create, locales]);
    const formConfig: FormProps = {
        initialValues: initialValues,
        fields: [
            {
                name: 'name',
                label: formLabels.name,
                type: 'Input',
                rules: [{ required: true }, {max: 40}],
                props: {
                    showCount: true,
                    maxCount: 40,
                },
                forceDisabled: initialValues.id,
            },
            {
                name: 'account',
                label: formLabels.account,
                type: 'Input',
                rules: [{ required: true }],
            },
            {
                name: 'secret',
                label: formLabels.password,
                type: 'Password',
                rules: [{ required: true }],
            },
            {
                name: 'confirm',
                label: formLabels.confirm,
                type: 'Password',
                props: {
                    placeholder: formLabels.confirm,
                },
                rules: [
                    { required: true },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('secret') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(locales.pages.admin.user.detail.resumePasswordWrong));
                        },
                    }),
                ],
            },
            {
                name: 'status',
                label: formLabels.authorization,
                type: 'Select',
                props: {
                    options: [
                        { label: formLabels.switchOn, value: 1 },
                        { label: formLabels.switchOff, value: 0 },
                    ],
                },
            },
        ],
    };
    const createNewItem = (values) => {
        let ajax: any = null;
        if(!values.id){
            ajax = postMallConfigs(values, {
                successMsg: locales.business.notifyMessage.addSuccess,
            });
        }else{
            ajax = putMallConfigsById(values, {
                segments: {
                    id: values.id
                },
                successMsg: locales.business.info.editSuccess,
            });
        }
        ajax?.then?.(() => {
            setFieldDetailVisible(false);
            queryList({ pageNum, pageSize });
        });
    };
    return (
        <Page pageBodyClassName='taobao-page-index'>
            <CommonTablePage
                loading={loading}
                mainButton={mainBtn}
                // showExport={true}
                // showColumsControl={true}
                // showFilter={true}
                // filter={filterProps}
                // exportBtn={exportBtnProps}
            >
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
                <CommonTablePage.Modal
                    width={700}
                    formConfig={formConfig}
                    visible={fieldDetailVisible}
                    onCancel={() => setFieldDetailVisible(false)}
                    onOk={createNewItem}
                    loading={modalLoading}
                ></CommonTablePage.Modal>
            </CommonTablePage>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
