import React, { useEffect, useMemo, useState } from 'react';
import { Select, Input, Button, Icon, Space } from 'udesk-ui';

export const NewAssociateComponent = React.memo((props) => {
    const { associateList, locales, value, onChange, showTip = true } = props;
    const [hasCustomField, setHasCustomField] = useState(false);
    const [hasCustomValue, setHasCustomValue] = useState(false);
    const [field, setField] = useState('');
    const [operator, setOperator] = useState('');
    const [customValue, setCustomValue] = useState('');
    const [customField, setCustomField] = useState('');
    const operatorMap = useMemo(
        () =>
            new Map([
                [
                    /^Is\((.+)\)/,
                    {
                        format: 'Is({0}, {1})',
                        operatorValue: 'Is',
                        operatorLabel: locales.enums.fieldOperators.is,
                    },
                ],
                [
                    /^not Is\((.+)\)/,
                    {
                        format: 'not Is({0}, {1})',
                        operatorValue: 'not Is',
                        operatorLabel: locales.enums.fieldOperators.not,
                    },
                ],
                [
                    /(\S*) > (\S*)/,
                    {
                        format: '{0} > {1}',
                        operatorValue: '>',
                        operatorLabel: locales.enums.fieldOperators.greaterThan,
                    },
                ],
                [
                    /(\S*) < (\S*)/,
                    {
                        format: '{0} < {1}',
                        operatorValue: '<',
                        operatorLabel: locales.enums.fieldOperators.lessThan,
                    },
                ],
                [
                    /^Keyword\((.+)\)/,
                    {
                        format: 'Keyword({0}, {1})',
                        operatorValue: 'Keyword',
                        operatorLabel: locales.enums.fieldOperators.contains,
                    },
                ],
                [
                    /^IsEmpty\((.+)\)/,
                    {
                        format: 'IsEmpty({0})',
                        operatorValue: 'IsEmpty',
                        operatorLabel: locales.enums.fieldOperators.isNull,
                    },
                ],
                [
                    /^not IsEmpty\((.+)\)/,
                    {
                        format: 'not IsEmpty({0})',
                        operatorValue: 'not IsEmpty',
                        operatorLabel: locales.enums.fieldOperators.isNotNull,
                    },
                ],
            ]),
        []
    );

    const selectOptions = useMemo(() => {
        const result = associateList.filter(item => item.fieldList?.length > 0).map((item, index) => {
            let label = '';
            if (item.type === 1) {
                label = locales.components.operatorListGather.associateData;
            }
            if (item.type === 3) {
                label = locales.components.operatorListGather.pretreatmentData;
            }
            if (item.type === 6) {
                label = locales.components.operatorListGather.businessData;
            }

            return {
                label,
                options: item.fieldList.map((item, indexInChildren) => {
                    return {
                        label: item.label,
                        value: item.label,
                        key: `${index}/${indexInChildren}${item.label}`
                    };
                })
            };
        });
        return result;
    }, [
        associateList, 
        locales.components.operatorListGather.associateData, 
        locales.components.operatorListGather.businessData, 
        locales.components.operatorListGather.pretreatmentData
    ]);

    const getCustomField = (associateList) => {
        let noteCustomField = null;
        const targetTypeFieldList = associateList.find((i) => i.type === 6);
        if (targetTypeFieldList && Array.isArray(targetTypeFieldList.fieldList)) {
            noteCustomField = targetTypeFieldList.fieldList.find(
                (i) => i.fieldName === 'note_custom_fields'
            );
        }
        return noteCustomField;
    };

    const getOperator = (value) => {
        const operators = [...operatorMap].filter(([key]) => key.test(value));
        const operator = operators[operators.length - 1];
        return operator;
    };

    useEffect(() => {
        let noteCustomField = getCustomField(associateList);
        if (noteCustomField) {
            setHasCustomField((value || '').indexOf(noteCustomField.label) >= 0);
        }
        const operator = getOperator(value);

        if (Array.isArray(operator) && operator.length) {
            setHasCustomValue(
                !(
                    operator[1].operatorValue === 'IsEmpty' ||
                    operator[1].operatorValue === 'not IsEmpty'
                )
            );
            // const matches = value.match(operator[0])[1];
            const matches = Array.isArray(value.match(operator[0]))
                ? [...value.match(operator[0])].slice(1)
                : [];
            let [field, ...customValue] = matches;
            if (['Is', 'Keyword', 'not Is'].includes(operator[1].operatorValue)) {
                let values = field.split(',').map((i) => i.trim());
                field = values[0];
                customValue = values.slice(1);
            }
            if (field && (field.endsWith(', ') || field.endsWith(','))) {
                field = field.replace(/,\s+$/, '');
            }
            setOperator(operator[1]?.operatorValue ?? '');
            field &&
                setField(
                    noteCustomField && (field || '').indexOf(noteCustomField.label) >= 0
                        ? noteCustomField.label
                        : field.replace('[', '').replace(']', '')
                );
            field &&
                setCustomField(
                    noteCustomField && (field || '').indexOf(noteCustomField.label) >= 0
                        ? field.replace(noteCustomField.label, '').replace('[', '').replace(']', '')
                        : ''
                );
            customValue.length && setCustomValue(customValue.map((i) => i.trim()).join(','));
        } else {
            let hasCus = (value || '').replace('[', '').replace(']', '').includes('-');
            let arr = (value || '').replace('[', '').replace(']', '').split('-');
            setField(hasCus ? arr[0] + '-' : arr[0]);
            hasCus && setCustomField(arr[1] ?? '');
        }
    }, [associateList, value, operatorMap]);

    const onValuesChange = (targetKey, value) => {
        let operator = getOperator(props.value);
        let ret = props.value;
        if (operator || targetKey === 'operator') {
            if (targetKey === 'operator') {
                operator = [...operatorMap].filter(([key, v]) => v.operatorValue === value)[0];
                ret = operator[1].format
                    .replace('{0}', `[${field}${customField}]`)
                    .replace('{1}', customValue);
            } else if (targetKey === '{0}') {
                ret = operator[1].format.replace('{0}', `[${value}]`).replace('{1}', customValue);
            } else if (targetKey === '{1}') {
                ret = operator[1].format
                    .replace('{0}', `[${field}${customField}]`)
                    .replace('{1}', Array.isArray(value) ? value.join(',') : value.target.value);
            } else if (targetKey === 'customFieldValue') {
                ret = operator[1].format
                    .replace('{0}', `[${field}${value.target.value}]`)
                    .replace('{1}', customValue);
            }
            onChange?.(ret);
        } else {
            if (targetKey === 'customFieldValue') {
                value = `${field}${value.target.value}`;
            }
            onChange?.(`[${value}]`);
        }
    };

    return (
        <Space style={{ marginLeft: 8 }}>
            <Select
                style={{ width: 200 }}
                value={field}
                onChange={onValuesChange.bind(null, '{0}')}
                options={selectOptions}
            />
            {hasCustomField && (
                <Input
                    defaultValue={customField}
                    onChange={onValuesChange.bind(null, 'customFieldValue')}
                    style={{ width: 200 }}
                />
            )}
            <Select
                value={operator}
                onChange={onValuesChange.bind(null, 'operator')}
                options={[...operatorMap.values()].map((i) => ({
                    label: i.operatorLabel,
                    value: i.operatorValue,
                }))}
                style={{ width: 100 }}
            ></Select>
            {hasCustomValue &&
                (operator === 'Keyword' ? (
                    <Select
                        mode="tags"
                        value={customValue ? customValue.split(',') : []}
                        tokenSeparators={[',']}
                        onChange={onValuesChange.bind(null, '{1}')}
                        style={{ minWidth: '100px' }}
                    />
                ) : (
                    <Input
                        defaultValue={customValue}
                        onChange={onValuesChange.bind(null, '{1}')}
                        suffix={
                            ['Is', 'not Is'].includes(operator)
                                ? locales.components.operatorListGather.newAssociateComponentTip
                                : undefined
                        }
                    />
                ))}
            {['Is', 'not Is'].includes(operator) && showTip && (
                <Button
                    size="small"
                    icon={<Icon type="QuestionCircleTwoTone" antdIcon={true} />}
                    tooltip={props.tip || locales.components.operatorListGather.newAssociateDataTip}
                ></Button>
            )}
        </Space>
    );
});
