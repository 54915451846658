// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetSaleSettingBaseInfosByIdParam,
    BaseResponseSaleSettingBaseInfoFoundResponse,
    PutSaleSettingBaseInfosByIdParam,
    PutSaleSettingBaseInfosByIdBody,
    BaseResponseVoid,
    DeleteSaleSettingBaseInfosByIdParam,
} from '../../types';

/**
 * 获取销售配置详情-单个
 *
 * @export
 * @tags 销售赋能-销售阶段配置
 * @link [GET] /saleSettingBaseInfos/{id}
 * @param id
 */
export function getSaleSettingBaseInfosById(
    options: ApiOptions<GetSaleSettingBaseInfosByIdParam, never> & {
        segments: GetSaleSettingBaseInfosByIdParam;
    }
): Promise<BaseResponseSaleSettingBaseInfoFoundResponse> {
    return request<never, GetSaleSettingBaseInfosByIdParam, never>('/saleSettingBaseInfos/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSaleSettingBaseInfosById',
    });
}

/**
 * 更新销���配置
 *
 * @export
 * @tags 销售赋能-销售阶段配置
 * @link [PUT] /saleSettingBaseInfos/{id}
 * @param id
 */
export function putSaleSettingBaseInfosById(
    data: PutSaleSettingBaseInfosByIdBody,
    options: ApiOptions<PutSaleSettingBaseInfosByIdParam, never> & {
        segments: PutSaleSettingBaseInfosByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutSaleSettingBaseInfosByIdBody, PutSaleSettingBaseInfosByIdParam, never>(
        '/saleSettingBaseInfos/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putSaleSettingBaseInfosById' }
    );
}

/**
 * 删除销售配置
 *
 * @export
 * @tags 销售赋能-销售阶段配置
 * @link [DELETE] /saleSettingBaseInfos/{id}
 * @param id
 */
export function deleteSaleSettingBaseInfosById(
    options: ApiOptions<DeleteSaleSettingBaseInfosByIdParam, never> & {
        segments: DeleteSaleSettingBaseInfosByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteSaleSettingBaseInfosByIdParam, never>(
        '/saleSettingBaseInfos/{id}',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteSaleSettingBaseInfosById' }
    );
}

export const meta = [
    { tags: ['销售赋能-销售阶段配置'], path: '/saleSettingBaseInfos/{id}', method: 'get' },
    { tags: ['销售赋能-销售阶段配置'], path: '/saleSettingBaseInfos/{id}', method: 'put' },
    { tags: ['销售赋能-销售阶段配置'], path: '/saleSettingBaseInfos/{id}', method: 'delete' },
];
