import { useState, useRef, useCallback, useEffect } from 'react';
import Udesk from 'Udesk';
import { addEdge, useNodesState, useEdgesState, OnConnectStartParams } from 'reactflow';
import { getReviewIntelligentPartnerDialogFlow } from 'src/api/review/intelligentPartner/dialogFlow';
import { postReviewIntelligentPartnerNode } from 'src/api/review/intelligentPartner/node';
// import {
//     deleteReviewIntelligentPartnerNodeRelation,
//     // postReviewIntelligentPartnerNodeRelation,
// } from 'src/api/review/intelligentPartner/node/relation';
// import {
//     // deleteReviewIntelligentPartnerNodeById,
//     putReviewIntelligentPartnerNodeById,
// } from 'src/api/review/intelligentPartner/node/{id}';
import { message } from 'udesk-ui';
import { createBackEndNode, createNode, NodeBuilder, NodeTypes } from '../../utils/NodeBuilder';
import { lineStyle, markerEnd } from '../const/lineStyle';
import ConnectionLine from './ConnectionLine';
import CustomEdge from './CustomEdge';
import { nodeTypes } from './Nodes';
import {
    connectNodes,
    deleteNodeById,
    deleteNodesConnection,
    updateNodePositionById,
} from './utils/nodeOperates';
import { useFlowStepStore } from './useStore';
import { nodeHeight, nodeWidth } from '../const/nodeStyle';
import { putReviewIntelligentPartnerNodeBatch } from 'src/api/review/intelligentPartner/node/batch';
import { useDagreGraph } from 'src/util/hook/flow/index';
import { state } from './useFlowState';
import UdeskLocales from 'UdeskLocales';

const edgeTypes = {
    smoothstep: CustomEdge,
};

export const useFlow = (courseId, defaultDisabled = false) => {
    const { getLayoutedElements } = useDagreGraph({ nodeHeight, nodeWidth });

    const reactFlowWrapper = useRef<any>(null);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
    const [flowId, setFlowId] = useState<any>(null);
    const [disabled, setDisabled] = useState<boolean>(defaultDisabled);
    const [isPPTFlow, setIsPPTFlow] = useState<boolean>(true);
    const [courseType, setCourseType] = useState<number | null>(null);

    const nodePositionPrev = useRef<any>(null);

    const requestFlowAndInit = (courseId) => {
        const initFlow = (initialNodesBackend, courseType, flowId) => {
            const initPptFlow = (flowId) => {
                postReviewIntelligentPartnerNode({
                    flowId,
                    nodeName: Udesk.enums.nodeTypes.pptNode.name,
                    nodeType: NodeTypes.getNodeByKey(Udesk.enums.nodeTypes.pptNode.key).id,
                    nodeLocation: JSON.stringify({ x: 100, y: 100 }),
                }).then((resp) => {
                    setNodes((nds) => nds.concat(createNode(resp.data ?? {})));
                });
            };

            const initialNodes = new NodeBuilder(initialNodesBackend as any);
            
            setNodes(initialNodes.nodes);
            setEdges(initialNodes.edges);

            const isInitial = initialNodes.nodes.length === 0;
            const isPpt = Udesk.enums.lessonType.ppt.id === courseType;

            setCourseType(courseType);
            setIsPPTFlow(isPpt);
            if (isInitial) {
                if (isPpt) {
                    initPptFlow(flowId);
                }
            }
        };

        return getReviewIntelligentPartnerDialogFlow({
            params: {
                courseId,
            },
        }).then((resp) => {
            const initialNodesBackend = resp.data?.nodeList ?? [];

            const courseType = (resp.data as any)?.courseType;
            initFlow(initialNodesBackend, courseType, resp.data?.id);
            setFlowId(resp.data?.id);
        });
    };
    const {
        store,
        recordNodeCreate,
        recordNodeMove,
        recordNodeDeletion,
        recordEdgeCreate,
        recordEdgeDeletion,
        undoAble,
        redoAble,
    } = useFlowStepStore(requestFlowAndInit.bind(null, courseId));

    // const getNodeById = (nodeId) => {
    //     return nodes.find((node) => node.id === nodeId);
    // };

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (type === 'undefined' || type === 'null' || !type) {
                return;
            }

            const typeObj = JSON.parse(type);

            const zoom = reactFlowInstance.getZoom();

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left - 100 * zoom,
                y: event.clientY - reactFlowBounds.top - 37 * zoom,
            });

            const _createNode = () =>
                postReviewIntelligentPartnerNode({
                    flowId,
                    nodeName: typeObj.label,
                    nodeType: NodeTypes.getNodeByKey(typeObj.key).id,
                    nodeLocation: JSON.stringify(position),
                }).then((resp) => {
                    const newNode = createNode(resp.data ?? {});
                    setNodes((nds) => nds.concat(newNode));
                    return newNode;
                });

            _createNode().then((newNode) => {
                recordNodeCreate({ nodeId: newNode.id });
            });
        },
        [reactFlowInstance, flowId]
    );

    const onConnectStart = (_: any, params: OnConnectStartParams) => {
        // setConnecting(params);
        state.connecting.sourceId = params.nodeId || '';
        // setNodes((prev) => {
        //     const res = prev.map(n => {
        //         n.data.connecting = params;
        //         return {...n};
        //     });
        //     return res;
        // });
    };
    const onConnectEnd = () => {
        state.connecting.sourceId = '';
    };

    const onConnect = useCallback(
        (params) => {
            if (isPPTFlow) {
                return;
            }
            if (params.source === params.target) {
                message.error(/* 节点不能与自己相连接 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.flow.useFlow.nodeCannotBeConnectedToItself);
            } else {
                const paramsWithArrow = {
                    ...params,
                    // animated: true,
                    type: 'smoothstep',
                    markerEnd,
                    style: lineStyle,
                };
                paramsWithArrow.sourceHandle = undefined;
                paramsWithArrow.targetHandle = undefined;

                // const nodeNeedChange = nodes.find((n) => params.target === n.id);
                const _connectNodes = () =>
                    connectNodes(parseInt(params.source, 10), parseInt(params.target, 10));
                _connectNodes().then((newNode) => {
                    setNodes((nds) =>
                        nds.map((n) => {
                            if (n.id === params.target) {
                                const node = createNode(newNode.data);
                                node.data.handle = edges
                                    .filter((e) => e.target === node.id || e.source === node.id)
                                    .concat(paramsWithArrow);
                                return node;
                            }
                            if (n.id === params.source) {
                                const node = {...n};
                                node.data.handle = edges
                                    .filter((e) => e.target === node.id || e.source === node.id)
                                    .concat(paramsWithArrow);
                                return node;
                            }

                            return n;
                        })
                    );
                }).then(() => {
                    setEdges((eds) => addEdge(paramsWithArrow, eds));
                    recordEdgeCreate({
                        sourceNodeId: params.source,
                        targetNodeId: params.target,
                    });
                });
                // createBackEndNode(nodeNeedChange)
            }
        },
        [nodes, isPPTFlow]
    );
    useEffect(() => {
        setNodes((prev) => {
            return prev.map((node) => {
                node.data.handle = edges.filter(
                    (e) => e.target === node.id || e.source === node.id
                );
                return {
                    ...node,
                    data: {
                        ...node.data,
                    },
                };
            });
        });
    }, [edges.length]);
    const onEdgesDelete = (delEdges) => {
        // parentNodeId
        return Promise.all(
            delEdges.map((edge) => {
                return deleteNodesConnection(parseInt(edge.source, 10), parseInt(edge.target, 10));
                // return deleteReviewIntelligentPartnerNodeRelation({
                //     prevBindNodeId: parseInt(edge.source, 10),
                //     targetNodeId: parseInt(edge.target, 10),
                // });
            })
        ).then(([...newNodes]) => {
            delEdges.forEach((e) => {
                recordEdgeDeletion({
                    sourceNodeId: e.source,
                    targetNodeId: e.target,
                });
            });

            setNodes((nds) =>
                nds.map((n) => {
                    const changedNode = newNodes.find((node: any) => `${n.id}` === node.data.id);
                    if (changedNode) {
                        return createNode(n);
                    }
                    return n;
                })
            );
            setEdges((els) => els.filter((e) => !delEdges.find((d) => d.id === e.id)));
        });
    };
    const onEdgeUpdate = useCallback(
        (oldEdge, newConnection) => {
            if (isPPTFlow) {
                return;
            }
            if (newConnection.source === newConnection.target) {
                message.error(/* 节点不能与自己相连接 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.flow.useFlow.nodeCannotBeConnectedToItself);
            } else {
                onEdgesDelete([oldEdge]).then(() => {
                    onConnect(newConnection);
                });
            }

            // Promise.all([
            //     deleteReviewIntelligentPartnerNodeRelation({
            //         prevNodeId: parseInt(oldEdge.source, 10),
            //         targetNode: createBackEndNode(getNodeById(oldEdge.target)),
            //     }),
            //     postReviewIntelligentPartnerNodeRelation({
            //         prevNodeId: parseInt(newConnection.source, 10),
            //         targetNode: createBackEndNode(getNodeById(newConnection.target)),
            //     }),
            // ]).then(() => {
            //     setEdges((els) => updateEdge(oldEdge, newConnection, els));
            // });
        },
        [nodes]
    );

    const onNodeDragStop = (event, node, nodes) => {
        if (
            nodePositionPrev.current &&
            (nodePositionPrev.current.x !== node.position.x ||
                nodePositionPrev.current.y !== node.position.y)
        ) {
            const params = createBackEndNode(node);
            const { nodeLocation } = params;

            const _moveNode = (node) => {
                return updateNodePositionById(parseInt(node.id, 10), nodeLocation);
            };

            _moveNode(node).then(() => {
                recordNodeMove({
                    nodeId: node.id,
                    originPosition: JSON.stringify(nodePositionPrev.current),
                    newPosition: nodeLocation,
                });
                nodePositionPrev.current = null;
            });
        }
    };
    const onNodeDragStart = (event, node, nodes) => {
        nodePositionPrev.current = {
            ...node.position,
        };
    };
    const onNodesDelete = (nodes) => {
        nodes.forEach((node) => {
            deleteNodeById(parseInt(node.id, 10)).then(() => {
                recordNodeDeletion({
                    nodeId: node.id,
                });
            });
        });
    };
    const onInteractiveChange = (isInteractive) => {
        setDisabled(!isInteractive);
    };

    const autoLayout = () => {
        const { nodes: layoutedNodes } = getLayoutedElements(nodes, edges);
        return putReviewIntelligentPartnerNodeBatch(
            layoutedNodes.map((n) => ({ id: n.id, nodeLocation: JSON.stringify(n.position) }))
        ).then(requestFlowAndInit.bind(null, courseId));
    };

    return {
        requestFlowAndInit,
        onConnect,
        nodes,
        edges,
        onNodesChange,
        onEdgesChange,
        onInit: setReactFlowInstance,
        reactFlowInstance,
        onDragOver,
        onDrop,
        onEdgeUpdate,
        setNodes,
        onEdgesDelete,
        onNodeDragStart,
        onNodeDragStop,
        onNodesDelete,
        nodeTypes,
        connectionLineComponent: ConnectionLine,
        flowId,
        reactFlowWrapper,
        nodesDraggable: !disabled,
        elementsSelectable: !disabled,
        nodesConnectable: !disabled,
        onInteractiveChange,
        disabled,
        edgeTypes,
        sideBarVisible: !isPPTFlow,
        courseType,
        deleteKeyCode: null,
        store,
        undoAble,
        redoAble,
        autoLayout,
        onConnectStart,
        onConnectEnd,
    };
};
