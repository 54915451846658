import React, { useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { FormBuilder, Space, Button, Row, Col } from 'udesk-ui';
import './style.scss';
import DepartmentSelect from 'Component/common/gong/department-select';
import { NewAssociateComponent } from 'Component/pages/components/check-point-operator-list-gather/template';
import { postFieldDataSearchTemplate } from 'src/api/field/data/search-template';
import { Card } from './components/Card';
import { StageList } from './components/StageList';
import { Alias } from './components/Alias';
import { EndReasonList } from './components/EndReasonList';
import { postSmartTagsFindAll } from 'src/api/smartTags/findAll';
import {
    getSaleSettingBaseInfosById,
    putSaleSettingBaseInfosById,
} from 'src/api/saleSettingBaseInfos/{id}';
import { postSaleSettingBaseInfos } from 'src/api/saleSettingBaseInfos';

const AssociateComponent = (props) => {
    const { onChange: onChangeProps, value: valueProps, ...args } = props;

    const value = useMemo(() => {
        return valueProps?.operatorList?.[0]?.param?.inputContent ?? '';
    }, [valueProps]);

    const onChange = (value) => {
        onChangeProps({
            operatorType: 21,
            operatorList: [
                {
                    applyConditionHitType: 1,
                    applyOperatorScope: {
                        id: 1,
                        key: 'all',
                    },
                    type: 21,
                    operator: 'all',
                    applyRole: 'all',
                    param: {
                        version: 1,
                        type: 21,
                        inputContent: value,
                    },
                },
            ],
            operatorLogic: '1',
            operator: 'all',
            applyRole: 'all',
            param: {},
            conditionType: 4,
            applyConditionHitType: 1,
        });
    };

    return <NewAssociateComponent {...args} value={value} onChange={onChange} tip={/* 时间类型字段输入格式为：2022-12-31 00:00:00 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.theInputFormatOfTheTimeTypeFieldIs} />;
};

const Template = React.memo((props: any) => {
    const locales = UdeskLocales['current'];
    let { match } = props;
    let id = match.params.id;

    const [associateList, setAassociateList] = useState<any>([]);
    const [keyEventList, setKeyEventList] = useState<any>([]);
    const [detail, setDetail] = useState({});
    const [loading, setLoading] = useState<any>(false);
    const [baseInfoForm] = FormBuilder.useForm();
    const [stageInfoForm] = FormBuilder.useForm();

    useEffect(() => {
        postFieldDataSearchTemplate({
            sourceList: [9],
            typeList: [1, 3, 6],
        }).then((resp) => {
            setAassociateList(resp.data?.[0].typeList ?? []);
        });
        postSmartTagsFindAll({
            funcType: 'REVIEW_KEY_EVENT',
        }).then((resp) => {
            setKeyEventList(
                (resp.data ?? []).map((i) => ({ ...i, label: i.tagName, value: i.id }))
            );
        });

        if (id) {
            setLoading(true);
            getSaleSettingBaseInfosById({
                segments: {
                    id,
                },
                errorMsg: /* 请求失败，请稍后再试 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.requestFailedPleaseTryAgainLater,
            })
                .then((resp) => {
                    let res: any = resp.data ?? {};
                    if (Array.isArray(res.stageInfoList)) {
                        res.stageInfoList = res.stageInfoList.map((item) => {
                            return {
                                ...item,
                                ruleFormulas: {
                                    customJudgeLogic:
                                        item.customJudgeLogic
                                            ?.replace?.(/&&/g, 'and')
                                            .replace?.(/\|\|/g, 'or') ?? '',
                                    judgeStrategy: item.judgeStrategy,
                                },
                                // rules: (item.rules || []).map((r) => {
                                //     return {
                                //         ...r,
                                //         idCode: r.code,
                                //     };
                                // }),
                            };
                        });
                    }
                    res.stopBusinessReasonList = res.stopBusinessReasonList ?? [];
                    res.suitDepartmentList = res.suitDepartmentList ?? [];
                    setDetail(res);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);
    useEffect(() => {
        baseInfoForm.resetFields();
        stageInfoForm.resetFields();
    }, [detail]);
    const onBack = () => {
        let routeOptions = {
            history: props.history,
            routeName: 'salesStageListIndex',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    return (
        <Page
            pageBodyClassName="udesk-qa-sales-stage-page-detail"
            title={/* 销售阶段 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.salesStage}
            padding={true}
            onBack={onBack}
            loading={loading}
        >
            <Card title={/* 基本信息 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.essentialInformation}>
                <FormBuilder
                    form={baseInfoForm}
                    customComponents={{ DepartmentSelect, AssociateComponent }}
                    initialValues={detail}
                    fields={[
                        {
                            label: /* 名称 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.name,
                            name: 'name',
                            type: 'Input',
                            props: {
                                style: {
                                    width: 250,
                                },
                                showCount: true,
                                maxCount: 20,
                            },
                            rules: [{ required: true }, { max: 20, type: 'string' }],
                        },
                        {
                            label: /* 适用部门 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.applicableDepartments,
                            name: 'suitDepartmentList',
                            type: 'DepartmentSelect',
                            props: {
                                style: {
                                    width: 350,
                                },
                            },
                        },
                        {
                            label: /* 适用其他 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.applicableToOthers,
                            name: 'suitOthersObj',
                            type: 'AssociateComponent',
                            props: {
                                locales,
                                associateList,
                                showTip: false,
                            },
                        },
                        {
                            label: /* 手动变更阶段 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.manualChangePhase,
                            name: 'manualChange',
                            type: 'Switch',
                        },
                        {
                            label: /* 启用状态 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.enableStatus,
                            name: 'isEnable',
                            type: 'Switch',
                        },
                        {
                            label: /* 描述 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.describe,
                            name: 'description',
                            type: 'TextArea',
                        },
                    ]}
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 15 }}
                    footerRender={false}
                />
            </Card>
            <Card title={/* 商机阶段设置 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.opportunityStageSettings}>
                <FormBuilder
                    form={stageInfoForm}
                    customComponents={{ StageList, Alias, EndReasonList }}

                    initialValues={detail}
                    fields={[
                        {
                            label: /* 阶段设置 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.stageSettings,
                            name: 'stageInfoList',
                            type: 'StageList',
                            props: {
                                associateList,
                                keyEventList,
                            },
                        },
                        {
                            label: /* 别名设置 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.aliasSettings,
                            type: 'Alias',
                        },
                        {
                            label: /* 结束商机 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.closeOpportunity,
                            name: 'stopBusinessReasonList',
                            type: 'EndReasonList',
                        },
                    ]}
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 15 }}
                    footerRender={false}
                />
            </Card>
            <Row>
                <Col offset={3}>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                Promise.all([
                                    baseInfoForm.validateFields(),
                                    stageInfoForm.validateFields(),
                                ]).then(([baseInfoFormValues, stageInfoFormValues]) => {
                                    let formatedStageInfoFormValues = {
                                        ...stageInfoFormValues,
                                        stageInfoList: (
                                            stageInfoFormValues.stageInfoList || []
                                        ).map((s) => {
                                            return {
                                                ...s,
                                                baseInfoId: id || null,
                                                customJudgeLogic: s.ruleFormulas.customJudgeLogic
                                                    .replace(/and/g, '&&')
                                                    .replace(/or/g, '||'), // 逻辑表达式
                                                judgeStrategy: s.ruleFormulas.judgeStrategy,
                                            };
                                        }),
                                    };
                                    let formatedBaseInfoFormValues = {
                                        ...baseInfoFormValues,
                                        manualChange: baseInfoFormValues.manualChange ? 1 : 0,
                                        isEnable: baseInfoFormValues.isEnable ? 1 : 0,
                                    };
                                    let request: any = null;
                                    if (!id) {
                                        request = postSaleSettingBaseInfos(
                                            {
                                                ...formatedBaseInfoFormValues,
                                                ...formatedStageInfoFormValues,
                                            },
                                            {
                                                successMsg: /* 新建成功 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.newSuccessfully,
                                                errorMsg: /* 新建失败 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.newFailed,
                                            }
                                        );
                                    } else {
                                        request = putSaleSettingBaseInfosById(
                                            {
                                                ...formatedBaseInfoFormValues,
                                                ...formatedStageInfoFormValues,
                                            },
                                            {
                                                segments: {
                                                    id,
                                                },
                                                successMsg: /* 保存成功 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.savingSucceeded,
                                                errorMsg: /* 保存失败 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.index.saveFailed,
                                            }
                                        );
                                    }
                                    if (request) {
                                        request.then(() => {
                                            onBack();
                                        });
                                    }
                                });
                            }}
                        >
                            {locales.labels.save}
                        </Button>
                        <Button onClick={onBack}>{locales.labels.cancel}</Button>
                    </Space>
                </Col>
            </Row>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
