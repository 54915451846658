import Locales from '../locales/index';
import UdeskLocales from 'UdeskLocales';

let assessActionTypes = [
    { id: 1, key: "reassess", name: () => Locales.current.enums.tasks.assessActionTypes.reassess },
];

let assessFrequencyTypes = [
    { id: 1, key: "everyDay", name: () => Locales.current.enums.frequencyTypes.everyDay },
    { id: 2, key: "everyWeek", name: () => Locales.current.enums.frequencyTypes.everyWeek },
    { id: 3, key: "everyMonth", name: () => Locales.current.enums.frequencyTypes.everyMonth },
];

let assessFrequencyWeekDays = [
    { id: 1, key: "Monday", name: () => Locales.current.enums.tasks.weekdays.Monday },
    { id: 2, key: "Tuesday", name: () => Locales.current.enums.tasks.weekdays.Tuesday },
    { id: 3, key: "Wednesday", name: () => Locales.current.enums.tasks.weekdays.Wednesday },
    { id: 4, key: "Thursday", name: () => Locales.current.enums.tasks.weekdays.Thursday },
    { id: 5, key: "Friday", name: () => Locales.current.enums.tasks.weekdays.Friday },
    { id: 6, key: "Saturday", name: () => Locales.current.enums.tasks.weekdays.Saturday },
    { id: 7, key: "Sunday", name: () => Locales.current.enums.tasks.weekdays.Sunday },
];

let samplingTargetWeekDays = [
    { id: 1, key: "MONDAY", name: () => Locales.current.enums.tasks.weekdays.Monday },
    { id: 2, key: "TUESDAY", name: () => Locales.current.enums.tasks.weekdays.Tuesday },
    { id: 3, key: "WEDNESDAY", name: () => Locales.current.enums.tasks.weekdays.Wednesday },
    { id: 4, key: "THURSDAY", name: () => Locales.current.enums.tasks.weekdays.Thursday },
    { id: 5, key: "FRIDAY", name: () => Locales.current.enums.tasks.weekdays.Friday },
    { id: 6, key: "SATURDAY", name: () => Locales.current.enums.tasks.weekdays.Saturday },
    { id: 7, key: "SUNDAY", name: () => Locales.current.enums.tasks.weekdays.Sunday },
];

let autoMateTasks = [
    { id: "AUTOLOAD", key: "autoLoad", name: () => Locales.current.enums.tasks.autoMateTasks.autoLoad },
    { id: "SAMPLE", key: "sample", name: () => Locales.current.enums.tasks.autoMateTasks.sample },
    { id: "ASSIGN", key: "assign", name: () => Locales.current.enums.tasks.autoMateTasks.assign },
    // { id: "PUSH", key: "pushes", name: () => Locales.current.enums.tasks.autoMateTasks.pushes },
    { id: "MONITOR", key: "monitor", name: () => Locales.current.enums.tasks.autoMateTasks.pushes },
    { id: "AUTO_ASSESSMENT", key: "assess", name: () => Locales.current.enums.tasks.autoMateTasks.assess },
    { id: "CHECK", key: "check", name: () => Locales.current.enums.tasks.autoMateTasks.check },
    { id: "CASE", key: "case", name: () => Locales.current.enums.tasks.autoMateTasks.case },

];

let calculateScoreTypes = [
    { id: 1, key: "add", name: () => Locales.current.enums.tasks.calculateScoreTypes.add },
    { id: 2, key: "sub", name: () => Locales.current.enums.tasks.calculateScoreTypes.sub },
];

let strategyTypes = [
    { id: 1, key: "hitAdd", name: () => Locales.current.enums.tasks.strategyTypes.hitAdd },
    { id: 2, key: "hitNotAdd", name: () => Locales.current.enums.tasks.strategyTypes.hitNotAdd },
    { id: 3, key: "hitSub", name: () => Locales.current.enums.tasks.strategyTypes.hitSub },
    { id: 4, key: "hitNotSub", name: () => Locales.current.enums.tasks.strategyTypes.hitNotSub },
];

let distributeTypes = [
    { id: 1, key: "rule", name: () => Locales.current.enums.tasks.distributeTypes.rule },
    { id: 2, key: "average", name: () => Locales.current.enums.tasks.distributeTypes.average },
];

let notMatchAnyRuleTypes = [
    { id: 1, key: "random", name: () => Locales.current.enums.tasks.notMatchAnyRuleTypes.random },
    { id: 2, key: "assign", name: () => Locales.current.enums.tasks.notMatchAnyRuleTypes.assign },
    { id: 3, key: "ignore", name: () => Locales.current.enums.tasks.notMatchAnyRuleTypes.ignore },
];

let fastSampleInDetailList = [
    { id: 1, key: "fastSampleInInspectDetail", name: () => Locales.current.enums.tasks.fastSampleInDetailList.fastSampleInInspectDetail },
    { id: 2, key: "fastSampleInSampleDetail", name: () => Locales.current.enums.tasks.fastSampleInDetailList.fastSampleInSampleDetail },
    { id: 3, key: "fastSampleInReviewDetail", name: () => Locales.current.enums.tasks.fastSampleInDetailList.fastSampleInReviewDetail }
];

let gradeTypes = [
    { id: 1, key: 'yesOrNo', name: () => Locales.current.enums.tasks.gradeTypes.yesOrNo },
    { id: 2, key: 'input', name: () => Locales.current.enums.tasks.gradeTypes.input },
    { id: 3, key: 'level', name: () => Locales.current.enums.tasks.gradeTypes.level },
    { id: 4, key: 'multipleFactors', name: () => Locales.current.enums.tasks.gradeTypes.multipleFactors }
];

let inspectionUpdateTypes = [
    { id: "auto_inspection_plus", key: "autoInspection", name: () => Locales.current.enums.tasks.inspectionUpdateTypes.autoInspection, wechatName: Locales.current.enums.tasks.inspectionUpdateTypes.autoInspectionWechat },
    { id: "recheck_inspection", key: "recheckInspection", name: () => Locales.current.enums.tasks.inspectionUpdateTypes.recheckInspection, wechatName: Locales.current.enums.tasks.inspectionUpdateTypes.recheckInspectionWechat },
    { id: "sampling_assign", key: "samplingAssign", name: () => Locales.current.enums.tasks.inspectionUpdateTypes.samplingAssign },
    { id: "artificial_sampling", key: "artificialSampling", name: () => Locales.current.enums.tasks.inspectionUpdateTypes.artificialSampling },
    { id: "artificial_complain", key: "artificialComplain", name: () => Locales.current.enums.tasks.inspectionUpdateTypes.artificialComplain },
    { id: "artificial_review", key: "artificialReview", name: () => Locales.current.enums.tasks.inspectionUpdateTypes.artificialReview },
    { id: "fast_sampling", key: "fastSampling", name: () => Locales.current.enums.tasks.inspectionUpdateTypes.fastSampling },
    // { id: "timely_inspection", key: "timelyInspection", name: () => Locales.current.enums.tasks.inspectionUpdateTypes.timelyInspection },
];

let inspectorTypes = [
    { id: 1, key: "all", name: () => Locales.current.enums.tasks.inspectorTypes.all },
    { id: 2, key: "some", name: () => Locales.current.enums.tasks.inspectorTypes.some },
];

let inspectFortorsType = [
    { id: 0, key: 'inspectTrue', name: () => Locales.current.enums.tasks.inspectType.inspectFalse },
    { id: 1, key: "manualScore", name: () => Locales.current.enums.tasks.inspectType.inspectTrue },
];

let interactiveRules = [
    { id: 1, key: "proportional", name: () => Locales.current.enums.tasks.interactiveRules.proportional },
    { id: 2, key: "allRight", name: () => Locales.current.enums.tasks.interactiveRules.allRight },
    { id: 3, key: "anyRight", name: () => Locales.current.enums.tasks.interactiveRules.anyRight }
];
let noHitQuestionResults = [
    { id: 1, key: "true", name: () => Locales.current.enums.tasks.noHitQuestionResults.true },
    { id: 0, key: "false", name: () => Locales.current.enums.tasks.noHitQuestionResults.false },
];
let repeatRules = [
    { id: 1, key: "same", name: () => Locales.current.enums.tasks.repetRules.same },
    { id: 2, key: "similar", name: () => Locales.current.enums.tasks.repetRules.similar },
];

let judgeStrategy = [
    { id: 1, key: 'all', name: () => Locales.current.enums.tasks.judgeStrategy.all },
    { id: 2, key: 'arbitrarily', name: () => Locales.current.enums.tasks.judgeStrategy.arbitrarily },
    { id: 3, key: 'custom', name: () => Locales.current.enums.tasks.judgeStrategy.custom }
];

let permitCustomReasonList = [
    { id: 1, key: "permitCustomReason", name: () => Locales.current.enums.tasks.permitCustomReasonList.permitCustomReason },
];

let pushActionTypes = [
    { id: 1, key: "interfacePush", name: () => Locales.current.enums.tasks.pushActionTypes.interfacePush },
    { id: 2, key: "email", name: () => Locales.current.enums.tasks.pushActionTypes.email },
];

let pushContentTypes = [
    { id: "json", key: "json", name: () => Locales.current.enums.tasks.pushContentTypes.json }
];

let pushFrequencyTypes = [
    { id: 1, key: "everyDay", name: () => Locales.current.enums.tasks.pushFrequencyTypes.everyDay },
    { id: 2, key: "everyWeek", name: () => Locales.current.enums.tasks.pushFrequencyTypes.everyWeek },
    { id: 3, key: "everyMonth", name: () => Locales.current.enums.tasks.pushFrequencyTypes.everyMonth },
    { id: 4, key: "inspectionUpdate", name: () => Locales.current.enums.tasks.pushFrequencyTypes.inspectionUpdate },
    { id: 5, key: "timelyInspection", name: () => Locales.current.enums.tasks.pushFrequencyTypes.timelyInspection },
];

let pushObjectTypes = [
    { id: 1, key: "staff", name: () => Locales.current.enums.tasks.pushObjectTypes.staff },
    { id: 2, key: "email", name: () => Locales.current.enums.tasks.pushObjectTypes.email },
];

let pushRequestMethods = [
    { id: "post", key: "post", name: () => Locales.current.enums.tasks.pushRequestMethods.post }
];

let relateConditions = [
    { id: 1, key: "highlightQuestion", name: () => Locales.current.enums.tasks.relateConditions.highlightQuestion },
    { id: 2, key: "highlightAnswer", name: () => Locales.current.enums.tasks.relateConditions.highlightAnswer },
];

let relateConditionsHit = [
    { id: 3, key: "highlightQuestion", name: () => Locales.current.enums.tasks.relateConditions.hightlightFoctors },
];

let reviewlistResultOptions = [
    { id: 1, key: "checkResult", name: () => Locales.current.enums.tasks.reviewOptions.reviewResult },
    { id: 2, key: "checkRecord", name: () => Locales.current.enums.tasks.reviewOptions.reviewRecord },
    { id: 3, key: "reviewAgentHidden", name: () => Locales.current.enums.tasks.reviewOptions.reviewAgentHidden },
];

let reviewlistResultOptionsExportIphone = [
    { id: 1, key: "checkExportAddCallInfo", name: () => Locales.current.enums.tasks.reviewOptions.checkExportAddCallInfoIphone },
];

let reviewlistResultOptionsExportText = [
    { id: 1, key: "checkExportAddCallInfo", name: () => Locales.current.enums.tasks.reviewOptions.checkExportAddCallInfoText },
];

let riskAlertPushDataTypes = [
    { id: 1, key: "dialogueMessage", name: () => Locales.current.enums.tasks.riskAlertPushDataTypes.dialogueMessage },
    { id: 2, key: "inspectionResults", name: () => Locales.current.enums.tasks.riskAlertPushDataTypes.inspectionResults }
];

let samplingEveryDayTimeTypes = [
    { id: 1, key: "all", name: () => Locales.current.labels.all },
    { id: 2, key: "today", name: () => Locales.current.enums.tasks.samplingEveryDayTimeTypes.today },
    { id: 3, key: "yesterday", name: () => Locales.current.enums.tasks.samplingEveryDayTimeTypes.yesterday },
    { id: 4, key: "theDayBeforeYesterday", name: () => /* 前天 */UdeskLocales['current'].enums.tasks.theDayBeforeYesterday },
];

let samplingEveryMonthTimeTypes = [
    { id: 1, key: "all", name: () => Locales.current.labels.all },
    { id: 2, key: "thisMonth", name: () => Locales.current.enums.tasks.samplingEveryMonthTimeTypes.thisMonth },
    { id: 3, key: "lastMonth", name: () => Locales.current.enums.tasks.samplingEveryMonthTimeTypes.lastMonth },
];

let samplingEveryWeekTimeTypes = [
    { id: 1, key: "all", name: () => Locales.current.labels.all },
    { id: 2, key: "thisWeek", name: () => Locales.current.enums.tasks.samplingEveryWeekTimeTypes.thisWeek },
    { id: 3, key: "lastWeek", name: () => Locales.current.enums.tasks.samplingEveryWeekTimeTypes.lastWeek },
];

let samplingFrequencyTypes = [
    { id: 1, key: "everyDay", name: () => Locales.current.enums.tasks.samplingFrequencyTypes.everyDay },
    { id: 2, key: "everyWeek", name: () => Locales.current.enums.tasks.samplingFrequencyTypes.everyWeek, everyAgentCheckLimitName: () => Locales.current.components.common.datePicker.thisWeek },
    { id: 3, key: "everyMonth", name: () => Locales.current.enums.tasks.samplingFrequencyTypes.everyMonth, everyAgentCheckLimitName: () => Locales.current.components.common.datePicker.thisMonth },
];

let samplingFrequencyWeekDays = [
    { id: 1, key: "Monday", name: () => Locales.current.enums.tasks.weekdays.Monday },
    { id: 2, key: "Tuesday", name: () => Locales.current.enums.tasks.weekdays.Tuesday },
    { id: 3, key: "Wednesday", name: () => Locales.current.enums.tasks.weekdays.Wednesday },
    { id: 4, key: "Thursday", name: () => Locales.current.enums.tasks.weekdays.Thursday },
    { id: 5, key: "Friday", name: () => Locales.current.enums.tasks.weekdays.Friday },
    { id: 6, key: "Saturday", name: () => Locales.current.enums.tasks.weekdays.Saturday },
    { id: 7, key: "Sunday", name: () => Locales.current.enums.tasks.weekdays.Sunday },
];

let samplingTypes = [
    { id: 1, key: "random", name: () => Locales.current.enums.tasks.samplingTypes.random },
    { id: 2, key: "proportional", name: () => Locales.current.enums.tasks.samplingTypes.proportional }
];

let scoreType = [
    { id: 1, key: 'aiScore', name: () => Locales.current.enums.tasks.scoreType.aiScore },
    { id: 2, key: "manualScore", name: () => Locales.current.enums.tasks.scoreType.manualScore },
    { id: 3, key: "interactiveDetection", name: () => Locales.current.enums.tasks.scoreType.interactiveDetection },
    { id: 4, key: "smartRules", name: () => Locales.current.enums.tasks.scoreType.smartRules },
    { id: 5, key: "machineLearning", name: () => Locales.current.enums.tasks.scoreType.machineLearning },
    { id: 6, key: 'intelligentModel', name: () => Locales.current.enums.pointTypes.intelligentModel },
];

let spoktListResultOptions = [
    { id: 1, key: "sampleResult", name: () => Locales.current.enums.tasks.reviewOptions.samplingResult },
    { id: 2, key: "sampleRecord", name: () => Locales.current.enums.tasks.reviewOptions.samplingRecord },
    { id: 3, key: "sampleAgentHidden", name: () => Locales.current.enums.tasks.reviewOptions.sampleAgentHidden },
];

let spoktListResultOptionsExportIphone = [
    { id: 1, key: "sampleExportAddCallInfo", name: () => Locales.current.enums.tasks.reviewOptions.sampleExportAddCallInfoIphone },
];

let spoktListResultOptionsExportText = [
    { id: 1, key: "sampleExportAddCallInfo", name: () => Locales.current.enums.tasks.reviewOptions.sampleExportAddCallInfoText },
];

let inspectionListResultOptionsExportText = [
    { id: 1, key: "inspectionExportAddCallInfo", name: () => Locales.current.enums.tasks.reviewOptions.inspectionExportAddCallInfoText },
];

let inspectionListResultOptionsExportIphone = [
    { id: 1, key: "inspectionExportAddCallInfo", name: () => Locales.current.enums.tasks.reviewOptions.inspectionExportAddCallInfoIphone },
];

let subscribePushDataTypes = [
    { id: 1, key: "dialogueMessage", name: () => Locales.current.enums.tasks.subscribePushDataTypes.dialogueMessage },
    { id: 2, key: "textContent", name: () => Locales.current.enums.tasks.subscribePushDataTypes.textContent },
    { id: 3, key: "inspectionResults", name: () => Locales.current.enums.tasks.subscribePushDataTypes.inspectionResults }
];

let timelyInspectionTypes = [
    { id: "timely_inspection", key: "timelyInspection", name: () => Locales.current.enums.tasks.timelyInspectionTypes.timelyInspection },
];

let wayOverUpperLimits = [
    { id: 1, key: "ignoreExcessSpillover", name: () => Locales.current.enums.tasks.wayOverUpperLimits.ignoreExcessSpillover },
    { id: 2, key: "automaticWeightCalculation", name: () => Locales.current.enums.tasks.wayOverUpperLimits.automaticWeightCalculation }
];
let naScoreType = [
    { id: 1, key: "average", name: () => Locales.current.enums.tasks.notMatchPreRule.average },
    { id: 2, key: "addOrSubtract", name: () => Locales.current.enums.tasks.notMatchPreRule.addOrSubtract },
    { id: 3, key: "noChange", name: () => Locales.current.enums.tasks.notMatchPreRule.noChange },
];
let defaultScore = [
    { id: 0, key: "empty", name: () => Locales.current.enums.tasks.defaultScore.empty },
    { id: 1, key: "addOrSub", name: () => Locales.current.enums.tasks.defaultScore.add },
    { id: 2, key: "notAddOrSub", name: () => Locales.current.enums.tasks.defaultScore.notAdd },
    { id: 4, key: "input", name: () => Locales.current.enums.tasks.defaultScore.input },
    { id: 5, key: "choose", name: () => Locales.current.enums.tasks.defaultScore.choose },
    { id: 3, key: "na", name: () => Locales.current.enums.tasks.defaultScore.na },
];

let companyEnableInspect=[
    {id:0,key:'no'},
    {id:1,key:'regular'},
    {id:2,key:'realTime'},
    {id:3,key:'all'}
];
let gradeChooseHit=[
    {id:1,key:'true'},
    {id:0,key:'false'},
];


let autoAddPushTarget=[
    {id: 1, key: 'sampleAssignInspector', name: () => Locales.current.enums.autoAddPushTarget.sampleAssignInspector},
    {id: 2, key: 'sampleInspector', name: () => Locales.current.enums.autoAddPushTarget.sampleInspector},
    {id: 3, key: 'assignReviewer', name: () => Locales.current.enums.autoAddPushTarget.assignReviewer},
    {id: 4, key: 'reviewer', name: () => Locales.current.enums.autoAddPushTarget.reviewer},
    {id: 5, key: 'taskInspector', name: () => Locales.current.enums.autoAddPushTarget.taskInspector},
];

let sdkPushType=[
    {id: 1, key: 'webHook', name: () => Locales.current.enums.sdkPushType.webHook},
    {id: 2, key: 'dingDing', name: () => Locales.current.enums.sdkPushType.dingDing},
    {id: 3, key: 'weChat', name: () => Locales.current.enums.sdkPushType.weChat},
    {id: 4, key: 'customer', name: () => Locales.current.enums.sdkPushType.customer},
];

let inspectionRemarksType=[
    {id: 1, key: 'inspection', name: () => Locales.current.enums.inspectionRemarksType.inspection},
    {id: 2, key: 'check', name: () => Locales.current.enums.inspectionRemarksType.check},
    {id: 3, key: 'review', name: () => Locales.current.enums.inspectionRemarksType.review},
];

let segmentationStrategyType=[
    {id: 1, key: 'count', name: () => Locales.current.enums.segmentationStrategyType.count},
    {id: 2, key: 'spacing', name: () => Locales.current.enums.segmentationStrategyType.spacing},
];

let segmentationStrategy=[
    {id: 1, key: 'byTime', name: () => Locales.current.enums.segmentationStrategy.byTime},
    {id: 2, key: 'customize', name: () => Locales.current.enums.segmentationStrategy.customize},
];

const inspectType = [
    {id: 0, key: 'commonInspect', name: () => Locales.current.enums.inspectType.commonInspect},
    {id: 1, key: 'associateInspect', name: () => Locales.current.enums.inspectType.associateInspect},
];

const autoAddTypes = [
    {id: 0, key: 'realTimeAdd', name: () => Locales.current.enums.autoAddTypes.realTimeAdd},
    {id: 1, key: 'timingAdd', name: () => Locales.current.enums.autoAddTypes.timingAdd},
];

const taskType = [
    {id: 1, key: 'qualityCheckTasks'},
    {id: 2, key: 'informationCollection'},
];

export default {
    assessActionTypes,
    assessFrequencyTypes,
    assessFrequencyWeekDays,
    samplingTargetWeekDays,
    autoMateTasks,
    calculateScoreTypes,
    distributeTypes,
    fastSampleInDetailList,
    gradeTypes,
    inspectionUpdateTypes,
    inspectorTypes,
    inspectFortorsType,
    interactiveRules,
    judgeStrategy,
    permitCustomReasonList,
    pushActionTypes,
    pushContentTypes,
    pushFrequencyTypes,
    pushObjectTypes,
    pushRequestMethods,
    relateConditions,
    relateConditionsHit,
    reviewlistResultOptions,
    reviewlistResultOptionsExportIphone,
    reviewlistResultOptionsExportText,
    riskAlertPushDataTypes,
    samplingEveryDayTimeTypes,
    samplingEveryMonthTimeTypes,
    samplingEveryWeekTimeTypes,
    samplingFrequencyTypes,
    samplingFrequencyWeekDays,
    samplingTypes,
    scoreType,
    spoktListResultOptions,
    spoktListResultOptionsExportIphone,
    spoktListResultOptionsExportText,
    subscribePushDataTypes,
    timelyInspectionTypes,
    wayOverUpperLimits,
    naScoreType,
    strategyTypes,
    repeatRules,
    defaultScore,
    companyEnableInspect,
    gradeChooseHit,
    autoAddPushTarget,
    sdkPushType,
    noHitQuestionResults,
    inspectionRemarksType,
    inspectionListResultOptionsExportText,
    inspectionListResultOptionsExportIphone,
    segmentationStrategyType,
    segmentationStrategy,
    inspectType,
    notMatchAnyRuleTypes,
    taskType,
    autoAddTypes,
};