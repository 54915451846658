import React, { useState, useEffect, useCallback, useRef, forwardRef, useMemo } from 'react';
import Udesk from 'Udesk';
import './style.scss';
import ChatCardContentFactory from './components';
import { Space, Icon, Tag } from 'udesk-ui';
import XssComponent from 'udesk-react/src/components/xss';
// import Locales from 'UdeskLocales';
import { Remark, CaseBase, ErrorCorrection } from './components';
import styled from 'styled-components';
import { DebugInfoList } from './components/debug-info';
// import { LinkExplorer } from './components/LinkExplorer';

// const locales = Locales['current'];

const ChatBubble: React.FC<any> = (props) => {
    return props.isTicket ? (
        props.children
    ) : (
        <div
            className={`udesk-qa-chat-record-item-chat-bubble ${
                props.isMe ? 'right-bubble' : 'left-bubble'
            }`}
        >
            {React.Children.map(props.children, (item) => item)}
        </div>
    );
};

const HitTagsWarp = styled.div`
    /* margin-top: 8px; */
    padding: 0 8px 8px;
    background: ${(props) => (props.isMe ? '#bfdfff' : '#FFFFFF')};
    .udesk-qa-ui-tag {
        &:last-child {
            margin-right: 0;
        }
    }
`;

const ChatTitle: React.FC<any> = forwardRef(({ isMe, fromName, msgTime }, ref: any) => {
    // 昵称 & 时间
    return (
        <div style={{ textAlign: isMe ? 'end' : 'unset' }} ref={ref}>
            {!isMe ? (
                <Space>
                    <span className="udesk-qa-chat-record-item-name">{fromName}</span>
                    <span className="udesk-qa-chat-record-item-time">
                        <XssComponent value={msgTime} />
                    </span>
                </Space>
            ) : (
                <Space>
                    <span className="udesk-qa-chat-record-item-time">
                        <XssComponent value={msgTime} />
                    </span>
                    <span className="udesk-qa-chat-record-item-name">{fromName}</span>
                </Space>
            )}
        </div>
    );
});

const messageStatusRightStyles: React.CSSProperties = {
    position: 'absolute',
    left: 0,
    transform: 'translateX(-100%)',
    bottom: 0,
};
const messageStatusLeftStyles: React.CSSProperties = {
    position: 'absolute',
    right: 0,
    transform: 'translateX(100%)',
    bottom: 0,
};
const MessageStatus: React.FC<any> = React.memo((props) => {
    const { status, isMe } = props;
    const { color, name } = Udesk.enums.sendStatus[status] || {};
    return status && isMe ? (
        <div style={isMe ? messageStatusRightStyles : messageStatusLeftStyles}>
            <div style={{ alignSelf: 'flex-end', color, fontSize: '12px', wordBreak: 'keep-all' }}>
                {name}
            </div>
        </div>
    ) : null;
});

const ChatCardItem: React.FC<any> = ({
    // itemData: itemDataProps,
    isMe,
    isSysMessage,
    index,
    onItemMounted,
    highlightPoint,
    currentHighlightSeq,
    manualHighlightIndex,
    propsFromDetail = {},
    onMarkSemanticClick,
    currentHighlightGroup, //当前高亮信息
    prevHighlightGroupsList, //预高亮组信息
    searchHighlightKeywords, //搜索高亮关键词
    callId,
    virtual,
    // markFlag,
    hitPointTags,
    inspectDataSource,
    isDebug,
    debugInfo,
    ...itemDataProps
}) => {
    let itemData = useMemo(() => {
        return typeof itemDataProps.content === 'string'
            ? JSON.parse(itemDataProps.content)
            : itemDataProps?.content ?? {};
    }, [Object.keys(itemDataProps)]);
    // 工单质检 test
    // itemData.type = 'ticket';
    if (inspectDataSource === Udesk.enums.inspectDataSources.ticket.id) {
        itemData.type = 'ticket';
    }
    const isTicket = itemData.type === 'ticket';

    let ContentComponent = ChatCardContentFactory(itemData.type) as (
        props: any
    ) => React.ReactElement<any, any>;
    itemData.playRudio = itemDataProps.playRudio;
    itemData.isPlaying = itemDataProps.isPlaying;
    itemData.sendCurrentTimeRange = itemDataProps.sendCurrentTimeRange;
    itemData.virtual = virtual;

    // const showLinkExplorer = itemData.type === 'message';

    const [casebaseContentValue, setCasebaseContentValue] = useState(
        itemDataProps?.caseBaseData?.text ?? ''
    );
    /**
     * @isEditing 是否正处于文本编辑状态
     */
    const [isEditing, setIsEditing] = useState(false);
    const instance = useRef(null);
    const titleInstance = useRef(null);
    const editText = useCallback(
        (editStatus: boolean): void => {
            setIsEditing(editStatus);
        },
        [setIsEditing]
    );
    const onInputValueChange = useCallback((e) => {
        if (e?.target) {
            setCasebaseContentValue(e.target.value);
        } else {
            setCasebaseContentValue(e);
        }
    }, []);
    const onClickContentEditOk = useCallback(
        (querryPamarsId) => {
            let ret = {
                ...(itemDataProps?.caseBaseData ?? {}),
                text: casebaseContentValue,
            };
            if (!ret.id) {
                ret.id = querryPamarsId;
            }
            propsFromDetail.onClickContentEditOk?.(ret);
        },
        [casebaseContentValue, itemDataProps?.caseBaseData, propsFromDetail.onClickContentEditOk]
    );
    useEffect(() => {
        setCasebaseContentValue(itemDataProps?.caseBaseData?.text ?? '');
    }, [itemDataProps?.caseBaseData?.text]);
    useEffect(() => {
        /**
         * 高亮场景用例：
         * 改动：
         * 0.因性能优化方案采用虚拟列表导致文本、语音组件不一致，进而使高亮逻辑有所区别，故需分别测试。
         * 1.原有高亮控制组件拆分，改为组内、组间单独控制。
         * 2.高亮逻辑不变，组内同时高亮，组内高亮控制组件仅控制跳转至对应条目。
         * 3.如组内仅一条高亮则只显示组间高亮控制组件，组内有两条及以上高亮才显示组内高亮控制组件。
         * 4.
         *
         * 场景：
         * 一、组内高亮
         * 0.静默、前置条件
         * 二、组间高亮
         * 0.时间高亮。
         * 1.搜索关键字高亮，包括列表页搜索带到详情页的关键字，均为组间高亮，不应出现组内高亮控制组件。
         * 2.组件跳转时，组内高亮索引默认置回头部（0）。
         */
        
        instance?.current && Udesk.utils.dom.clearHighlight(instance?.current);
        titleInstance?.current && Udesk.utils.dom.clearHighlight(titleInstance?.current);
        //预高亮展示处理
        if (Array.isArray(prevHighlightGroupsList) && prevHighlightGroupsList.length > 0) {
            prevHighlightGroupsList.forEach((group) => {
                if (group.callId && callId !== group.callId) return;
                Array.isArray(group.highlightItemList) &&
                    group.highlightItemList.forEach((item) => {
                        if (item.index !== itemDataProps.highlightMessageIndex) return;
                        if (item.highlightType === 1 || item.highlightType === null) {
                            Udesk.utils.dom.highlightTextIndex(instance?.current, item, {
                                className: `audio-text-list-item-text-hit-status prev-highlight-${item.groupIndex}`,
                            });
                        } else if (item.highlightType === 2) {
                            Udesk.utils.dom.highlightTextIndex(titleInstance?.current, item, {
                                className: `audio-text-list-item-text-hit-status prev-highlight-${item.groupIndex}`,
                            });
                        }
                    });
            });
        }
        //当前高亮项处理逻辑
        if (currentHighlightGroup) {
            if (currentHighlightGroup.callId && callId !== currentHighlightGroup.callId) return;
            const { highlightItemList } = currentHighlightGroup;
            if (Array.isArray(highlightItemList) && highlightItemList.length > 0) {
                highlightItemList.forEach((item) => {
                    if (item.index === itemDataProps.highlightMessageIndex) {
                        if (item.highlightType === 1 || item.highlightType === null) {
                            Udesk.utils.dom.highlightTextIndex(instance?.current, item, {
                                className: `current-highlight-item`,
                            });
                        } else if (item.highlightType === 2) {
                            Udesk.utils.dom.highlightTextIndex(titleInstance?.current, item, {
                                className: `current-highlight-item`,
                            });
                        }
                    }
                });
            }
        }
    }, [
        prevHighlightGroupsList,
        currentHighlightGroup,
        searchHighlightKeywords,
        itemData,
        callId,
        itemDataProps.highlightMessageIndex,
    ]);
    /* eslint-disable no-unused-vars */
    const { data, ...allData } = itemData;
    // const isChecked = useMemo(() => propsFromDetail.checkedChanged.call(null, itemDataProps.id), [propsFromDetail.checkedChanged, itemDataProps.id])
    return (
        <React.Fragment>
            {isSysMessage ? (
                <div className="udesk-qa-chat-record-item">
                    <ContentComponent
                        index={index}
                        itemData={itemData?.data?.content}
                        // allData={itemData}
                        type={itemData.type}
                        ref={titleInstance}
                        onMarkSemanticClick={onMarkSemanticClick}
                        msgTime={itemDataProps.created_at}
                    />
                </div>
            ) : (
                <div
                    className={`udesk-qa-chat-record-item${
                        currentHighlightSeq === index ? ' current-high-light-item' : ''
                    }${manualHighlightIndex === index ? ' current-playing-item' : ''}`}
                    style={{
                        flexDirection: isMe && !isTicket ? 'row-reverse' : 'row',
                        padding: isTicket ? 0 : undefined,
                    }}
                >
                    {/* 头像 */}
                    {!isTicket && (
                        <div
                            className="udesk-qa-chat-record-item-avatar"
                            style={
                                itemDataProps.avatar
                                    ? { backgroundImage: `url('${itemDataProps.avatar}')` }
                                    : {}
                            }
                        >
                            {!itemDataProps.avatar && (
                                <Icon
                                    style={{ fontSize: '32px' }}
                                    type={itemDataProps.sender === 'agent' ? 'agent' : 'customer'}
                                />
                            )}
                        </div>
                    )}
                    <div
                        className="udesk-qa-chat-record-item-right"
                        style={isTicket ? { maxWidth: '100%' } : undefined}
                    >
                        {!isTicket && (
                            <ChatTitle
                                isMe={isMe}
                                fromName={itemDataProps.nickName || itemDataProps.senderUser}
                                msgTime={itemDataProps.created_at}
                                ref={titleInstance}
                            />
                        )}
                        <ChatBubble isMe={isMe} isTicket={isTicket}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: isMe && !isTicket ? 'row-reverse' : 'row',
                                    position: 'relative',
                                }}
                            >
                                <div style={isDebug ? undefined : { width: '100%' }}>
                                    <ContentComponent
                                        {...(isTicket ? itemDataProps : {})}
                                        index={index}
                                        itemData={itemData?.data?.content}
                                        // allData={itemData}
                                        onMarkSemanticClick={propsFromDetail.onMarkSemanticClick}
                                        isEditing={isEditing}
                                        onInputValueChange={onInputValueChange}
                                        tinymcePath={propsFromDetail.tinymcePath}
                                        itemDataType={itemDataProps.__type}
                                        ref={instance}
                                        titleRef={titleInstance}
                                        voice_url={itemData?.data?.voice_url}
                                        describes={itemDataProps.describes}
                                        {...allData}
                                        // measure={measure}
                                        // hasCached={hasCached}
                                        // setCached={setCached}
                                    />
                                    {!isTicket && Array.isArray(hitPointTags) && (
                                        <HitTagsWarp isMe={isMe}>
                                            {hitPointTags.map((tag) => {
                                                return <Tag color="red">{tag}</Tag>;
                                            })}
                                        </HitTagsWarp>
                                    )}
                                </div>
                                {!isTicket && (
                                    <div
                                        style={Object.assign(
                                            { height: 20, display: 'block', position: 'absolute' },
                                            isMe && { left: 0, transform: 'translateX(-100%)' },
                                            !isMe && { right: 0, transform: 'translateX(100%)' }
                                        )}
                                    >
                                        {Boolean(itemDataProps?.caseBaseData?.isAdded) && (
                                            <Icon
                                                type="yitianjiaanli"
                                                style={{
                                                    color: 'green',
                                                    width: isMe ? 'unset' : 0,
                                                    flex: 1,
                                                    textAlign: isMe ? 'end' : 'inherit',
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                                {/* 销售赋能 纠错 */}
                                {isDebug && typeof propsFromDetail?.trainRecords === 'function' && (
                                    <ErrorCorrection
                                        index={index}
                                        itemData={itemData?.data?.content}
                                        canErrorCorrection={[
                                            Udesk.enums.inspectDataSources.voiceCall.id,
                                            Udesk.enums.inspectDataSources.reviewCall.id,
                                        ].includes(inspectDataSource)}
                                        sender={itemDataProps.sender}
                                        trainRecords={propsFromDetail.trainRecords}
                                    />
                                )}
                                {!isTicket && !itemDataProps?.caseBaseData && !isSysMessage && (
                                    <MessageStatus isMe={isMe} status={itemDataProps.send_status} />
                                )}
                            </div>
                            {/* {showLinkExplorer && (
                                <LinkExplorer itemData={itemData?.data?.content} />
                            )} */}
                            {!isTicket && !isDebug && (
                                <div
                                    className="udesk-qa-chat-record-item-bottom-buttons"
                                    style={{
                                        flexDirection:
                                            itemDataProps.sender === 'agent'
                                                ? 'row'
                                                : 'row-reverse',
                                    }}
                                >
                                    {/* 纠错 */}
                                    {typeof propsFromDetail?.trainRecords === 'function' && (
                                        <ErrorCorrection
                                            index={index}
                                            itemData={itemData?.data?.content}
                                            canErrorCorrection={
                                                !propsFromDetail.fieldFlag &&
                                                propsFromDetail.inspectDataSource ===
                                                    Udesk.enums.inspectDataSources.voiceCall.id
                                            }
                                            sender={itemDataProps.sender}
                                            trainRecords={propsFromDetail.trainRecords}
                                        />
                                    )}
                                    {/* 单句备注 */}
                                    <Remark
                                        propsFromDetail={propsFromDetail}
                                        index={index}
                                        remark={itemDataProps.remark}
                                        canRemarkEdit={itemDataProps.canRemarkEdit}
                                        sender={itemDataProps.sender}
                                    />
                                    <CaseBase
                                        index={index}
                                        sender={itemDataProps.sender}
                                        // itemDataProps={itemDataProps}
                                        isAdded={itemDataProps?.caseBaseData?.isAdded}
                                        fieldFlag={propsFromDetail.fieldFlag}
                                        inspectDataSource={propsFromDetail.inspectDataSource}
                                        caseBaselist={propsFromDetail.caseBaselist}
                                        onClickEdit={editText}
                                        isEditing={isEditing}
                                        onClickContentEditOk={onClickContentEditOk}
                                    />
                                </div>
                            )}
                            {isDebug && <DebugInfoList list={debugInfo} />}
                        </ChatBubble>
                    </div>
                </div>
            )}
            {/* {
                markFlag && <Checkbox
                checked={isChecked}
                onChange={propsFromDetail.RadioChange.bind(
                    null,
                    itemDataProps.id,
                    markFlag
                )}
            />
            } */}
        </React.Fragment>
    );
};
export default React.memo(ChatCardItem);
