import Locales from '../locales/index';

let fieldTypes = [
    // { key: 'text', name: () => Locales.current.enums.fieldTypes.text },
    { id: 1, key: 'text', name: () => Locales.current.enums.fieldTypes.text },
    { id: 2, key: 'number', name: () => Locales.current.enums.fieldTypes.number },
    { id: 3, key: 'dateTime', name: () => Locales.current.enums.fieldTypes.dateTime },
    { id: 4, key: 'sigleListbox', name: () => Locales.current.enums.fieldTypes.sigleListbox },
    { id: 5, key: 'multiListbox', name: () => Locales.current.enums.fieldTypes.multiListbox },
    { id: 6, key: 'agent', name: () => Locales.current.enums.fieldTypes.agent },
    { id: 7, key: 'agentGroup', name: () => Locales.current.enums.fieldTypes.agentGroup },
    { id: 8, key: 'voice', name: () => Locales.current.enums.fieldTypes.voice },
    { id: 9, key: 'textArea', name: () => Locales.current.enums.fieldTypes.textArea },
    { id: 10, key: 'keyword', name: () => Locales.current.enums.fieldTypes.keyword },
    { id: 11, key: 'customer', name: () => Locales.current.enums.fieldTypes.customer },
    { id: 12, key: 'fieldWithLink', name: () => Locales.current.enums.fieldTypes.fieldWithLink },
    { id: 13, key: 'percentage', name: () => Locales.current.enums.fieldTypes.percentage },
    { id: 15, key: 'intelligentClassification', name: () => Locales.current.enums.fieldTypes.percentage },
    { id: 16, key: 'testSetList', name: () => Locales.current.enums.fieldTypes.testSetList },
    { id: 17, key: 'inspector', name: () => Locales.current.enums.fieldTypes.inspector },
    { id: 18, key: 'executeUser', name: () => Locales.current.enums.fieldTypes.executeUser },
    { id: 19, key: 'keywords', name: () => Locales.current.enums.fields.keyWord },
    { id: 20, key: 'keyEvents', name: () => Locales.current.enums.fields.keyEvents },
    { id: 21, key: 'supervision', name: () => Locales.current.pages.intelligentAnalysis.intelligentTags.supervisionItem },
];

// let inputTypes = [
//     { id: "text", key: "text" },
//     { id: "textArea", key: "textArea" },
//     { id: "dropDown", key: "dropDown" },
//     { id: "datePicker", key: "datePicker" },
//     { id: "time", key: "time" },
//     { id: "dateTimePicker", key: "dateTimePicker" },
//     { id: "dateRange", key: "dateRange" },
//     { id: "integerNumber", key: "integerNumber" },
//     { id: "floatNumber", key: "floatNumber" },
//     { id: "powerSelectMultiple", key: "powerSelectMultiple" },
//     { id: "powerSelect", key: "powerSelect" },
//     { id: "autoComplete", key: "autoComplete" },
//     { id: "checkBox", key: "checkBox" },
//     { id: "cascadeDropDown", key: "cascadeDropDown" },
//     { id: "simplePhone", key: "simplePhone" },
//     { id: "currentUser", key: "currentUser" },
//     { id: "currentOffice", key: "currentOffice" },
// ];

let inputTypes = [
    { id: 'text', key: 'text' },
    { id: 'textArea', key: 'textArea' },
    { id: 'dateTime', key: 'dateTime' },
    { id: 'number', key: 'number' },
    { id: 'agent', key: 'agent' },
    { id: 'agentGroup', key: 'agentGroup' },
    { id: 'sigleListbox', key: 'sigleListbox' },
    { id: 'multiListbox', key: 'multiListbox' },
    { id: 'voice', key: 'voice' },
    { id: 'keyword', key: 'keyword' },
    { id: 'customer', key: 'customer' },
    { id: 'fieldWithLink', key: 'fieldWithLink' },
    { id: 'percentage', key: 'percentage' },
    { id: 'intelligentClassification', key: 'intelligentClassification' },
    { id: 'testSetList', key: 'testSetList' },
    { id: 'inspector', key: 'inspector' },
    { id: 'executeUser', key: 'executeUser' },
    { id: 'keywords', key: 'keywords' },
    { id: 'keyEvents', key: 'keyEvents' },
    { id: 'supervision', key: 'supervision' },
];

let fieldOperators = [
    { id: "is", name: () => Locales.current.enums.fieldOperators.is, key: "is" },
    { id: "not", name: () => Locales.current.enums.fieldOperators.not, key: "not" },
    { id: "is_any", name: () => Locales.current.enums.fieldOperators.containsAny, key: "is_any" },
    { id: "not_any", name: () => Locales.current.enums.fieldOperators.notContainsAny, key: "not_any" },
    { id: "belong_to", name: () => Locales.current.enums.fieldOperators.belongTo, key: "belong_to" },
    { id: "not_belong_to", name: () => Locales.current.enums.fieldOperators.notBelongTo, key: "not_belong_to" },
    { id: "has_any", name: () => Locales.current.enums.fieldOperators.hasAny, key: "has_any" },
    { id: "not_has_any", name: () => Locales.current.enums.fieldOperators.notHasAny, key: "not_has_any" },
    { id: "is_null", name: () => Locales.current.enums.fieldOperators.isNull, key: "is_null" },
    { id: "is_not_null", name: () => Locales.current.enums.fieldOperators.isNotNull, key: "is_not_null" },
    { id: "contains", name: () => Locales.current.enums.fieldOperators.contains, key: "contains" },
    { id: "not_contains", name: () => Locales.current.enums.fieldOperators.notContains, key: "not_contains" },
    { id: "prefix_contains", name: () => Locales.current.enums.fieldOperators.prefixContains, key: "prefix_contains" },
    { id: "prefix_not_contains", name: () => Locales.current.enums.fieldOperators.prefixNotContains, key: "prefix_not_contains" },
    { id: "suffix_contains", name: () => Locales.current.enums.fieldOperators.suffixContains, key: "suffix_contains" },
    { id: "suffix_not_contains", name: () => Locales.current.enums.fieldOperators.suffixNotContains, key: "suffix_not_contains" },
    { id: "greater_than", name: () => Locales.current.enums.fieldOperators.greaterThan, key: "greater_than" },
    { id: "greater_than_eq", name: () => Locales.current.enums.fieldOperators.greaterThanEq, key: "greater_than_eq" },
    { id: "less_than", name: () => Locales.current.enums.fieldOperators.lessThan, key: "less_than" },
    { id: "less_than_eq", name: () => Locales.current.enums.fieldOperators.lessThanEq, key: "less_than_eq" },
    { id: "contains_all", name: () => Locales.current.enums.fieldOperators.containsAll, key: "contains_all" },
    { id: "contains_any", name: () => Locales.current.enums.fieldOperators.containsAny, key: "contains_any" },
    { id: "not_contains_any", name: () => Locales.current.enums.fieldOperators.notContainsAll, key: "not_contains_all" },
    { id: "today", name: () => Locales.current.enums.fieldOperators.today, key: "today" },
    { id: "yesterday", name: () => Locales.current.enums.fieldOperators.yesterday, key: "yesterday" },
    { id: "tomorrow", name: () => Locales.current.enums.fieldOperators.tomorrow, key: "tomorrow" },
    { id: "the_day_before_yesterday", name: () => Locales.current.enums.fieldOperators.the_day_before_yesterday, key: "the_day_before_yesterday" },
    { id: "next_seven_day", name: () => Locales.current.enums.fieldOperators.next_seven_day, key: "next_seven_day" },
    { id: "last_seven_day", name: () => Locales.current.enums.fieldOperators.last_seven_day, key: "last_seven_day" },
    { id: "this_week", name: () => Locales.current.enums.fieldOperators.this_week, key: "this_week" },
    { id: "next_week", name: () => Locales.current.enums.fieldOperators.next_week, key: "next_week" },
    { id: "last_week", name: () => Locales.current.enums.fieldOperators.last_week, key: "last_week" },
    { id: "this_month", name: () => Locales.current.enums.fieldOperators.this_month, key: "this_month" },
    { id: "next_month", name: () => Locales.current.enums.fieldOperators.next_month, key: "next_month" },
    { id: "last_month", name: () => Locales.current.enums.fieldOperators.last_month, key: "last_month" },
    { id: "this_year", name: () => Locales.current.enums.fieldOperators.this_year, key: "this_year" },
    { id: "next_year", name: () => Locales.current.enums.fieldOperators.next_year, key: "next_year" },
    { id: "last_year", name: () => Locales.current.enums.fieldOperators.last_year, key: "last_year" },
    { id: "after", name: () => Locales.current.enums.fieldOperators.after, key: "after" },
    { id: "before", name: () => Locales.current.enums.fieldOperators.before, key: "before" },
    { id: "all", key: "allMatching", name: () => Locales.current.enums.fieldOperators.allMatching },
    { id: "all_not", key: "allNotMatching", name: () => Locales.current.enums.fieldOperators.allNotMatching },
    { id: "any", key: "anyMatching", name: () => /* 任意匹配 */Locales.current.enums.fields.anyMatch },
    { id: "between", name: () => Locales.current.enums.fieldOperators.between, key: "between" },
    { id: "not_between", name: () => Locales.current.enums.fieldOperators.notBetween, key: "not_between" },
    { id: "relative", name: () => Locales.current.enums.fieldOperators.relative, key: "relative" },
    { id: "set", name: () => Locales.current.enums.fieldOperators.set, key: "set" },
    { id: "belongs", name: () => Locales.current.enums.fieldOperators.belongs, key: "belongs" },
    { id: "not_belongs", name: () => Locales.current.enums.fieldOperators.notBelongs, key: "not_belongs" },
    { id: "exact_match", name: () => Locales.current.enums.fieldOperators.exact_match, key: "exact_match" },
    { id: "add_value", name: () => Locales.current.enums.fieldOperators.add_value, key: "add_value" },
    { id: "remove_value", name: () => Locales.current.enums.fieldOperators.remove_value, key: "remove_value" },
    { id: "set_null", name: () => Locales.current.enums.fieldOperators.set_null, key: "set_null" },
    { id: "belongTo", name: () => Locales.current.enums.fieldOperators.belongTo, key: "belongTo" },
    { id: "notBelongTo", name: () => Locales.current.enums.fieldOperators.notBelongTo, key: "notBelongTo" },
    { id: "hit", name: () => Locales.current.enums.fieldOperators.hit, key: "hit" },
    { id: "not_hit", name: () => Locales.current.enums.fieldOperators.not_hit, key: "not_hit" },
];

export default {
    fieldTypes,
    inputTypes,
    fieldOperators
};