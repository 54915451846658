import React, { useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Icon, Space, Dropdown } from 'udesk-ui';
import './style.scss';

const locales = Locales['current'];

function FileItem(props) {
    
    const { fileType } = props;
    const [mouseOver, setMouseOver] = useState(false);
    const handlerMouseEnter = () => {
        setMouseOver(true);
    };
    const handlerMouseLeave = () => {
        setMouseOver(false);
    };
    const handlerOpen = () => {
        props.handlerFileDetail && props.handlerFileDetail(props);
    };
    const renderIcon = () => {
        switch (fileType) {
            case 2: // 企微通话
            case 0: // 电话
                return (
                    <Icon
                        style={{ fontSize: 20, color: '#1A6EFF' }}
                        type='a-maikefengshengyinluyinluzhishengyin1'
                    ></Icon>
                );
            default: // 企业微信
                return (
                    <Icon style={{fontSize: 20, color: '#1A6EFF'}} type='xingzhuang'></Icon>
                );
        }
    };
    return (
        <div
            className={
                mouseOver
                    ? 'component-common-file-item-container file-item-active'
                    : 'component-common-file-item-container'
            }
            onClick={handlerOpen}
            onMouseEnter={handlerMouseEnter}
            onMouseLeave={handlerMouseLeave}
        >
            <div className='file-icon'>
                {renderIcon()}
                {/* <Icon style={{fontSize: 20, color: '#1A6EFF'}} type='a-maikefengshengyinluyinluzhishengyin1'></Icon> */}
            </div>
            <div className='file-content-detail'>
                <div className='file-message'>
                    <Space direction='vertical' size={4}>
                        <Space size={40} className='file-detail-duration'>
                            <Space size={10} className='file-left-text-common-width'>
                                <h3>{props.name}</h3>
                                <span className='file-duration'>
                                    {((parseInt(props.callDuration) || 0) / 60).toFixed(0)}
                                    {locales.components.gong.filesOpen.fileItem.minute}
                                </span>
                            </Space>
                            {/* <Space>
                                {
                                    props.isFragment === 1 && <span className='file-paragraph'>{locales.components.gong.filesOpen.fileItem.callClip}</span>
                                }
                                <p>{locales.components.gong.filesOpen.fileItem.start}{props.startTime || '--'} - {locales.components.gong.filesOpen.fileItem.End}{props.endTime || '--'}
                                </p>
                            </Space> */}
                        </Space>
                        <Space size={40} className='file-content-date'>
                            <p className='file-left-text-common-width'>
                                {locales.components.gong.filesOpen.fileItem.employees} {props.answerUserName || '--'} |{' '}
                                {locales.components.gong.filesOpen.fileItem.Customer}
                                {props.dialUserName || '--'}
                            </p>
                            <p>
                                {locales.components.gong.filesOpen.fileItem.sessionTime}
                                {props.callTime || '--'}
                            </p>
                        </Space>
                        <p className='file-recommend-reason'>
                            {locales.components.gong.filesOpen.fileItem.reasonForRecommendation}
                            {props.recommendReason || '--'}
                        </p>
                        <Space size={40} className='file-create-user'>
                            <p className='file-left-text-common-width'>
                                {locales.components.gong.filesOpen.fileItem.addedBy}
                                {props.createUserName || '--'}
                            </p>
                            <span>
                                {locales.components.gong.filesOpen.fileItem.addTime}
                                {props.createTime || '--'}
                            </span>
                        </Space>
                    </Space>
                </div>
                <div className='right-opright' style={{ position: 'relative' }}>
                    {React.Children.map(props.children, (item) => item)}
                </div>
            </div>
            {mouseOver && !props.isCollection && Udesk.data.init.user.id === props.createUserId && (
                <Dropdown overlay={props.menu}>
                    <div>
                        <Icon style={{ fontSize: 20, color: '#1A6EFF' }} type='EllipsisOutlined' antdIcon={true} />
                    </div>
                </Dropdown>
            )}
        </div>
    );
}

export default FileItem;
