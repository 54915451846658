import React, { useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Tabs } from 'udesk-ui';
import Locales from 'UdeskLocales';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { useEffect } from 'react';
import './style.scss';
import { getCurrentRouteName } from 'Udesk/system/subApp';

const { TabPane } = Tabs;

function CollectionDataAdd(props) {
    const locales = Locales['current'];
    const ADD_TYPES = [
        {
            pathName: getCurrentRouteName('clientManage'),
            key: 'ccpass',
            text: locales.components.pages.clientCenter.clientManage.tabTitle,
            id: '/index',
        },
        {
            pathName: getCurrentRouteName('clientManageWechat'),
            key: 'wechat',
            text: locales.components.pages.clientCenter.wechatClientManage.title,
            id: '/wechat-index',
            hidden: !Udesk.data.init?.company.enabledWeComInspect,
        },
    ];
    const [activeKey, setActiveKey] = useState<'ccpass' | 'wechat'>('ccpass');
    const onAddTypeChange = (activeKey) => {
        setActiveKey(activeKey);
        const tab: any = ADD_TYPES.find((t) => t.key === activeKey) ?? {};
        const routeOptions = {
            history: props.history,
            routeName: tab.pathName,
            pathParams: { taskId: props.match.params.taskId },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() => {
        const currentPath = ADD_TYPES.find((item) => props.history.location.pathname.indexOf(item.id) >= 0);
        // if(props.history.location.pathname.indexOf('/integration/cc-pass') < 0){
        //     setActiveKey('wechat');
        // }else{
        //     setActiveKey('ccpass');
        // }
        setActiveKey(currentPath?.key ?? ('ccpass' as any));
    }, [props.history.location]);
    return (
        <Page
            pageBodyClassName='client-center-root-page-index'
            title={locales.components.pages.clientCenter.clientManage.title}
            padding={true}
            footer={
                <Tabs activeKey={activeKey} onChange={onAddTypeChange}>
                    {props.routes.map((item, i) => {
                        const tab: any = ADD_TYPES.find((t) => t.pathName === item.name) ?? {};
                        if (tab.hidden) return null;
                        return (
                            <TabPane tab={tab.text} key={tab.key}>
                            </TabPane>
                        );
                    })}
                </Tabs>
            }
        >
            <SubRoutes
                route={props.routes.find((r) => r.name === ADD_TYPES.find((i) => i.key === activeKey)!.pathName)}
            />
        </Page>
    );
}

export default React.memo(CollectionDataAdd);
