import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';
let myInterval = null;
class RecordDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        selectedDatum: {},
    };
    //#endregion

    state = {};
    privates = {
        detail: null,
        smartTags: [],
        templates: [],
        relatedDatas: [],
        waveAudioOptions: {
            height: '80',
        },
        showEmotionAnalysis: false,
        semanticMarkShow: false,
        semanticContent: '',
        waveAudioInstance: null,
        storages: {
            relatedDatasPaging: {
                pageNum: 1,
                pageSize: 10,
            },
        },
        relatedLoading: false,
        labelRef: React.createRef(),
        wordCloudRef: React.createRef(),
        tagId: null,
        treeValue: null,
        idState: null,
        labelLevelModal: null,
        labelParent: {},
        labelParentModal: {},
        hitData: {},
        treeDataRef: {},
        timeValue: null,
        spinLoading: false,
        changeList: [],
        changeListLoading: false,
        taskType: 1,
        taskList: [],
    };
    static computes = {};
    actions = {
        sendCurrentTimeRange(item) {
            let { waveAudioInstance } = this.privates;
            if (waveAudioInstance) {
                waveAudioInstance.actions.playSpecificTimePeriod(item.data.fromSec, item.data.endSec);
            }
        },
        onAudioPause(value) {
            let { waveAudioInstance } = this.privates;
            if (waveAudioInstance) {
                if (value) {
                    waveAudioInstance.actions.play();
                } else {
                    waveAudioInstance.actions.pause('1');
                }
            }
        },
        onAudioStop() {
            let { waveAudioInstance } = this.privates;
            if (waveAudioInstance) {
                waveAudioInstance.actions.pause();
            }
        },
        onWaveAudio(value) {
            this.privates.waveAudio = value;
            this.actions.update();
        },
        timeChaged(value) {
            this.privates.timeValue = value;
            this.actions.update();
        },
        changeShowEmotionAnalysis() {
            let { showEmotionAnalysis } = this.privates;
            this.privates.showEmotionAnalysis = !showEmotionAnalysis;
            this.actions.update();
        },
        transitionToTemplate(template) {
            this.trigger('onTransitionToTemplate', template);
        },
        onSeriesClicked() {
            this.trigger('onSeriesClicked');
        },
        markSemantic(content) {
            this.privates.semanticMarkShow = true;
            this.privates.semanticContent = content;
            this.actions.update();
        },
        markSemanticClose() {
            this.privates.semanticMarkShow = false;
            this.actions.update();
        },
        parentLoadMore(value) {
            let { relatedDatas, selectedDatum } = this.privates;
            if (!relatedDatas || _isEmpty(relatedDatas)) {
                this.privates.spinLoading = true;
                this.actions.update();
            }
            let { sdkOptions } = this.props;
            let relatedData = Object.assign({ id: selectedDatum.dataId, pageSize: 10 }, value);
            let relatedDataUrl = Udesk.business.apiPath.concatApiPath(`data/related-data`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(relatedDataUrl, relatedData).then(
                    (resp) => {
                        resolve(resp);
                        let list = [...relatedDatas, ...resp.data];
                        this.privates.relatedDatas = list;
                        this.privates.storages.relatedDatasPaging = resp.paging;
                        this.privates.relatedLoading = false;
                        this.privates.spinLoading = false;
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        this.privates.relatedLoading = false;
                        this.privates.spinLoading = false;
                        reject(reason);
                    }
                );
            });
        },
        getTagTreeInit(categoryId, type, tagId) {
            let { sdkOptions } = this.props;
            let data = {
                categoryId,
                treeType: type,
            };
            if (tagId) {
                data.tagId = tagId;
            }
            let url = Udesk.business.apiPath.concatApiPath(`smartTags/tree`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    (resp) => {
                        resolve(resp.data);
                        if (type === 1) {
                            if (resp.data && resp.data.length > 0) {
                                let level = resp.data[0].tagLevel;
                                if (!_isEmpty(this.privates.treeDataList)) {
                                    let obj = _find(this.privates.treeDataList, (item, key) => key === level);
                                    if (obj) {
                                        this.privates.treeDataList[level] = resp.data;
                                    } else {
                                        this.privates.treeDataList = {
                                            ...this.privates.treeDataList,
                                            [level]: resp.data,
                                        };
                                    }
                                    let data = {};
                                    for (let key in this.privates.treeDataList) {
                                        if (key <= level) {
                                            data[key] = this.privates.treeDataList[key];
                                        }
                                    }
                                    this.privates.treeDataList = data;
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                    }
                                } else {
                                    this.privates.treeDataList[level] = resp.data;
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                    }
                                }
                                this.actions.update();
                            } else {
                                if (this.privates.labelLevelModal) {
                                    this.privates.treeDataList = {
                                        ...this.privates.treeDataList,
                                        [this.privates.labelLevelModal + 1]: [],
                                    };
                                    let data = {};
                                    for (let key in this.privates.treeDataList) {
                                        if (key <= this.privates.labelLevelModal + 1) {
                                            data[key] = this.privates.treeDataList[key];
                                        }
                                    }
                                    this.privates.treeDataList = data;
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                    }
                                }
                                this.actions.update();
                            }
                        } else if (type === 2) {
                            this.privates.treeDataList = resp.data;
                            for (let key in this.privates.treeDataList) {
                                this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                            }
                            this.actions.treeMap(tagId, this.privates.treeDataList, -1);
                            this.actions.hitMap(tagId, this.privates.treeDataList, this.privates.hitData, -1);
                            this.privates.tagId = tagId;
                            this.privates.labelActiveModal = tagId;
                            this.actions.update();
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        treeMap(id, treeDataListObj, level) {
            for (let key in treeDataListObj) {
                if (parseInt(key, 10) === level || level === -1) {
                    if (treeDataListObj[key] && treeDataListObj[key].length > 0) {
                        let obj = treeDataListObj[key].find((item) => item.id === id);
                        if (obj && obj.parentId) {
                            treeDataListObj[key] = treeDataListObj[key].filter(
                                (item) => item.parentId === obj.parentId
                            );
                            this.actions.treeMap(obj.parentId, treeDataListObj, obj.tagLevel - 1);
                            break;
                        }
                    }
                }
            }
        },
        treeDrag(value, obj, e) {
            var x1 = e.pageX || e.clientX + (document.body.scrollLeft || document.documentElement.scrollLeft);
            if (value.current) {
                let width = value.current.style.width;
                let wid = parseInt(width.split('px')[0], 10);
                obj.current.onmousemove = (e) => {
                    var x2 = e.pageX || e.clientX + (document.body.scrollLeft || document.documentElement.scrollLeft);
                    if (x2 >= x1 && x2 - x1 <= 500) {
                        value.current.style.width = x2 - x1 + wid + 'px';
                    } else {
                        if (x2 - x1 < 0) {
                            value.current.style.width = wid - (x1 - x2) + 'px';
                        }
                    }
                };
            }
        },
        fieldUp(obj) {
            obj.current.onmousemove = (e) => {
                return;
            };
        },
        hitMap(id, treeDataListObj, hitList, level) {
            for (let key in treeDataListObj) {
                if (parseInt(key, 10) === level || level === -1) {
                    if (treeDataListObj[key] && treeDataListObj[key].length > 0) {
                        treeDataListObj[key].forEach((item) => {
                            if (item.id === id) {
                                hitList[item.tagLevel] = id;
                                if (item.parentId) {
                                    this.actions.hitMap(item.parentId, treeDataListObj, hitList, item.tagLevel - 1);
                                }
                            }
                        });
                    }
                }
            }
        },
        activesLabelModal(value, level, leveflag) {
            let { treeDataList } = this.privates;
            this.privates.labelActiveModal = value;
            this.privates.tagIdModal = value;
            this.privates.labelLevelModal = level;
            this.privates.labelParentModal[level] = value;
            for (let key in this.privates.labelParentModal) {
                if (key > level) {
                    this.privates.labelParentModal[key] = null;
                }
            }
            for (let key in this.privates.hitData) {
                if (key >= level) {
                    this.privates.hitData[key] = null;
                }
            }
            if (leveflag !== 1) {
                this.actions.getTagTreeInit(this.privates.treeValue.categoryId, 1, value, true);
            } else {
                for (let key in treeDataList) {
                    if (key > level) {
                        this.privates.treeDataList[key] = null;
                    }
                }
            }
            this.actions.update();
        },
        treeListVisible(value) {
            if (value) {
                if (value.tagType === 2) {
                    this.privates.treeValue = value;
                    this.privates.labelParentModal = {};
                    this.actions.getTagTreeInit(value.categoryId, 2, value.id);
                    this.privates.visible = true;
                    this.actions.update();
                }
            }
        },
        modalCancel() {
            this.privates.visible = false;
            this.actions.update();
        },
        modalOk() {
            let { treeValue, labelActiveModal, detail } = this.privates;
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`smartTagTrainRecordDetails`, sdkOptions);
            let data = {
                tagId: labelActiveModal,
                callId: detail.id,
                categoryId: treeValue.categoryId,
                trainType: treeValue.tagType,
                dataSource: detail.inspectDataSource,
            };
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    (resp) => {
                        resolve(resp);
                        this.privates.visible = false;
                        let smartTagsUrl = Udesk.business.apiPath.concatApiPath(
                            `callTags/${detail.id}/tagsOfCall`,
                            sdkOptions
                        );
                        new Promise((resolve, reject) => {
                            Udesk.ajax.get(smartTagsUrl).then(
                                (resp) => {
                                    resolve(resp);
                                    if (resp.data) {
                                        this.privates.smartTags = resp.data;
                                    }
                                    this.actions.update();
                                },
                                (reason) => {
                                    Udesk.ui.notify.error(
                                        reason.errorMsg ||
                                            this.locales.components.pages.components.recordDetail.getSmartTagsFailed
                                    );
                                    reject(reason);
                                }
                            );
                        });
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onSmartTagsChange(smartTags) {
            this.privates.smartTags = smartTags;
            this.actions.update();
            this.actions.querryChangeList();
        },
        addRightClickEvent() {
            let customerPortraitKeyWordsCloud = document.getElementById('reacordDetailKeyWordsCloud');
            customerPortraitKeyWordsCloud &&
                (customerPortraitKeyWordsCloud.oncontextmenu = function () {
                    return false;
                });
            if (this.privates.wordCloudRef.current) {
                clearInterval(myInterval);
                if (!this.privates.wordCloudRef.current.isDidMount) {
                    myInterval = setInterval(() => {
                        if (this.privates.wordCloudRef.current.reactEchartsInstance) {
                            this.privates.wordCloudRef.current.reactEchartsInstance.privates.echartsAPI.on(
                                'contextmenu',
                                (params) => {
                                    this.props.SmartToolBarRef.current.actions.seleteTag('2');
                                    this.props.SmartToolBarRef.current.actions.open(
                                        null,
                                        params.name,
                                        'sentenceSearch'
                                    );
                                }
                            );
                            clearInterval(myInterval);
                        }
                    }, 500);
                }
            }
        },
        querryChangeList() {
            let { sdkOptions } = this.props;
            let { selectedDatum } = this.privates;
            if (selectedDatum.dataId == null) {
                return;
            }
            this.privates.changeListLoading = true;
            let url = Udesk.business.apiPath.concatApiPath(`call/${selectedDatum.dataId}/changeList`, sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    this.privates.changeList = resp.data;
                    this.privates.changeListLoading = false;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    this.privates.changeListLoading = false;
                    this.actions.update();
                }
            );
        },
        reverse() {
            const { sdkOptions } = this.props;
            const { detail } = this.privates;
            if (!detail.id) {
                return;
            }
            let url = Udesk.business.apiPath.concatApiPath(`/data/${detail.id}/role-reverse`, sdkOptions);

            Udesk.ajax.post(url).then(
                (resp) => {
                    Udesk.ui.notify.success(this.locales.business.info.editSuccess);
                    setTimeout(() => {
                        this.actions.reloadAsyncModel();
                    }, 200);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        recover() {
            const { sdkOptions } = this.props;
            const { detail } = this.privates;
            if (!detail.id) {
                return;
            }
            let url = Udesk.business.apiPath.concatApiPath(`/data/${detail.id}/recover`, sdkOptions);
            Udesk.ajax.post(url).then(
                (resp) => {
                    Udesk.ui.notify.success(this.locales.business.info.editSuccess);
                    setTimeout(() => {
                        this.actions.reloadAsyncModel();
                    }, 200);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        reAsr() {
            const { sdkOptions } = this.props;
            const { detail } = this.privates;
            if (!detail.id) {
                return;
            }
            let url = Udesk.business.apiPath.concatApiPath(`/data/${detail.id}/reAsr`, sdkOptions);
            Udesk.ajax.post(url).then(
                (resp) => {
                    Udesk.ui.notify.success(this.locales.business.info.editSuccess);
                    setTimeout(() => {
                        this.actions.reloadAsyncModel();
                    }, 200);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        onTaskTypeChange(activeKey) {
            this.privates.taskType = activeKey * 1;
            this.actions.update();
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        return {
            selectedDatum: props.selectedDatum,
        };
    }

    componentDidMount() {
        this.actions.addRightClickEvent();
        // window.onscroll = function () {
        //     var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.actions.addRightClickEvent();
        let { selectedDatum } = this.privates;
        if (selectedDatum.dataId !== prevProps.selectedDatum.dataId) {
            this.privates.semanticMarkShow = false;
            this.privates.semanticContent = '';
            this.privates.relatedDatas = [];
            let { waveAudioInstance } = this.privates;
            if (waveAudioInstance) {
                this.privates.waveAudio = false;
                this.privates.timeValue = 'null';
                waveAudioInstance.actions.pause();
            }
            this.privates.storages.relatedDatasPaging = {
                pageNum: 1,
                pageSize: 10,
            };
            this.actions.reloadAsyncModel();
        }
        if (this.props.scrollFlag !== prevProps.scrollFlag && this.props.scrollFlag) {
            this.actions.parentLoadMore({ pageNum: this.privates.storages.relatedDatasPaging.pageNum });
        }
    }
    componentWillUnmount() {
        let { waveAudioInstance } = this.privates;
        if (waveAudioInstance) {
            waveAudioInstance.actions.pause();
            this.privates.waveAudioInstance = null;
        }
        clearInterval(myInterval);
    }
    asyncModel(changedParams) {
        let { sdkOptions } = this.props;
        let { selectedDatum } = this.privates;
        // let { relatedDatasPaging } = this.privates.storages;
        if (selectedDatum.dataId == null) {
            return;
        }
        let url = Udesk.business.apiPath.concatApiPath(`data/${selectedDatum.dataId}/dataCallDetail`, sdkOptions);
        let detailPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                (resp) => {
                    // 工单质检 test
                    // resolve({data: mockData});
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || this.locales.components.pages.components.recordDetail.getDetailFailed
                    );
                    reject(reason);
                }
            );
        });

        let smartTagsUrl = Udesk.business.apiPath.concatApiPath(
            `callTags/${selectedDatum.dataId}/tagsOfCall`,
            sdkOptions
        );
        let smartTagsPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(smartTagsUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || this.locales.components.pages.components.recordDetail.getSmartTagsFailed
                    );
                    reject(reason);
                }
            );
        });

        let templatesUrl = Udesk.business.apiPath.concatApiPath(
            `inspectionData/${selectedDatum.dataId}/appliedTemplates`,
            sdkOptions
        );
        let templatesPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(templatesUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || this.locales.components.pages.components.recordDetail.getTemplatesFailed
                    );
                    reject(reason);
                }
            );
        });
        // let relatedData = {
        //     id: selectedDatum.dataId,
        //     pageNum: ,
        //     pageSize: relatedDatasPaging.pageSize
        // };
        // let relatedDataUrl = Udesk.business.apiPath.concatApiPath(`data/related-data`, sdkOptions);
        // let relatedDataPromise = new Promise((resolve, reject) => {
        //     Udesk.ajax.post(relatedDataUrl, relatedData).then(
        //         (resp) => {
        //             resolve(resp);
        //         },
        //         (reason) => {
        //             Udesk.ui.notify.error(reason.errorMsg);
        //             reject(reason);
        //         }
        //     );
        // });
        this.actions.querryChangeList();
        return {
            detail: detailPromise,
            smartTags: smartTagsPromise,
            templates: templatesPromise,
            // relatedDatas: relatedDataPromise
        };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'detail') {
            if (asyncResult) {
                if (asyncResult.data) {
                    this.privates.detail = asyncResult.data;
                }
                this.actions.update();
            }
        } else if (asyncKey === 'smartTags') {
            if (asyncResult) {
                if (asyncResult.data) {
                    this.privates.smartTags = asyncResult.data;
                }
                this.actions.update();
            }
        } else if (asyncKey === 'templates') {
            if (asyncResult) {
                if (asyncResult.data) {
                    this.privates.templates = asyncResult.data;
                }
                this.actions.update();
            }
        } else if (asyncKey === 'relatedDatas') {
            this.privates.relatedLoading = false;
            if (asyncResult) {
                if (asyncResult.data) {
                    this.privates.relatedDatas = asyncResult.data;
                    this.privates.storages.relatedDatasPaging = asyncResult.paging;
                }
                this.actions.update();
            }
        }
    }
    //#endregion
}

export default RecordDetailComponent;

// var mockData = {
//     id: 103652,
//     inspectDataSource: 2,
//     voiceUrl: null,
//     voiceLength: null,
//     sentenceList: null,
//     dialogRecords: {
//         dialogSentenceList: [
//             {
//                 id: '4294978613',
//                 created_at: '2022-03-03 18:09:55',
//                 sender: 'customer',
//                 content:
//                     '{"type":"ticket","font":"","data":{"content":"您"},"platform":"web","version":1,"seq_num":""}',
//                 describes: null,
//                 send_status: 'arrive',
//             },
//             {
//                 id: '4294978617',
//                 created_at: '2022-03-03 18:09:57',
//                 sender: 'customer',
//                 content:
//                     '{"type":"ticket","data":{"content":"有新的咨询进来了。"},"im_sub_session_id":37493,"is_welcome":true}',
//                 describes: null,
//                 send_status: '',
//             },
//             {
//                 id: '4294978621',
//                 created_at: '2022-03-03 18:10:08',
//                 sender: 'agent',
//                 content:
//                     '{"type":"ticket","font":"","data":{"content":"客服超时我是王荣辉 你是北京朝阳电信&联通(1646302150)1","timeout_auto_msg":true},"platform":"web","version":1,"seq_num":"","auto":true}',
//                 describes: null,
//                 send_status: 'arrive',
//             },
//             {
//                 id: '4294978625',
//                 created_at: '2022-03-03 18:17:35',
//                 sender: 'agent',
//                 content:
//                     '{"type":"ticket","font":"","data":{"content":"会话结束，谢谢你的咨询，祝您愉快！","msg_close":true},"platform":"web","version":1,"seq_num":"","auto":true}',
//                 describes: null,
//                 send_status: 'arrive',
//             },
//         ],
//     },
//     evaluateDto: null,
// };
