import React, { useEffect, useState, useMemo } from 'react';
// import Udesk from 'Udesk';
import { Icon, Tag, Tooltip } from 'udesk-ui';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';

import Locales from 'UdeskLocales';
import { getExternalUsers } from 'src/api/externalUsers';
import { getCustomerTagGroups } from 'src/api/customerTagGroups';
import { Paging } from 'src/api/types';
import { getExternalUsersById, putExternalUsersById } from 'src/api/externalUsers/{id}';

const Display = (props) => <div>{props.value ?? ''}</div>;

function ClientManage(props) {
    const locales = Locales['current'];
    const tableLabels = locales.components.pages.clientCenter.wechatClientManage.table;
    const [fieldDetailVisible, setFieldDetailVisible] = useState(false);
    const [initialValues, setInitialValues] = useState<any>({});
    const [modalLoading, setModalLoading] = useState(false);
    const [tagOptions, setTagOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [colorMap, setColorMap] = useState<any>({});
    const [paging, setPaging] = useState<Paging>({
        pageNum: 1,
        pageSize: 20,
        total: 0,
    });

    const tableProps = useMemo(() => {
        return {
            columns,
            dataSource,
            subtractedHeight: 136,
        };
    }, [columns, dataSource]);

    const paginationProps = useMemo(() => {
        return {
            current: paging.pageNum,
            pageSize: paging.pageSize,
            total: paging.total,
            onChange: (page, pageSize) => {
                let newPaging = {
                    ...paging,
                    pageNum: page,
                    pageSize,
                };
                setPaging(newPaging);
            },
        };
    }, [paging.pageNum, paging.pageSize, paging.total]);
    const onTableItemEdit = (item) => {
        setFieldDetailVisible(true);
        setModalLoading(true);
        getExternalUsersById({
            segments: {
                id: item.id,
            },
        })
            .then((resp) => {
                const { data = {} } = resp;
                setInitialValues({ ...data, tags: (data.tags ?? []).map((i) => i.tagId) });
            })
            .finally(() => {
                setModalLoading(false);
            });
    };
    useEffect(() => {
        setColumns([
            {
                title: tableLabels.id,
                dataIndex: 'externalUserId',
                key: 'externalUserId',
            },
            {
                title: tableLabels.name,
                dataIndex: 'nickName',
                key: 'nickName',
            },
            {
                title: /* 企微备注 */locales.pages.clientCenter.wechatClientManage.template.enterpriseAndMicroNotes,
                dataIndex: 'markName',
                key: 'markName',
            },
            {
                title: /* 添加好友时间 */locales.pages.clientCenter.wechatClientManage.template.addFriendTime,
                dataIndex: 'addTime',
                key: 'addTime',
            },
            {
                title: tableLabels.tag,
                dataIndex: 'tags',
                key: 'tags',
                with: '20%',
                render: (name, item, index) => {
                    return (
                        <div>
                            {Array.isArray(name) ? (
                                <Tooltip
                                    placement='topLeft'
                                    title={
                                        <div>
                                            {name.map((tag) => (
                                                <Tag
                                                    style={{ color: tag.tagColor ?? '#000', marginBottom: 4 }}
                                                    color={tag.tagColor ? tag.tagColor + '1a' : undefined}
                                                >
                                                    {tag.tagName}
                                                </Tag>
                                            ))}
                                        </div>
                                    }
                                    color='#fff'
                                >
                                    <div>
                                        {name
                                            .filter((item, i) => i < 4)
                                            .map((tag) => (
                                                <Tag
                                                    style={{ color: tag.tagColor ?? '#000', marginBottom: 4, marginTop: 4 }}
                                                    color={tag.tagColor ? tag.tagColor + '1a' : undefined}
                                                >
                                                    {tag.tagName}
                                                </Tag>
                                            ))}
                                        {name.length > 4 && '...'}
                                    </div>
                                </Tooltip>
                            ) : (
                                '-'
                            )}
                        </div>
                    );
                },
            },
            {
                title: locales.labels.action,
                dataIndex: 'action',
                key: 'action',
                onEdit: onTableItemEdit,
                renderType: 'action',
                // disableRule: (item) => ({edit: item.status, delete: item.status}),
            },
        ]);
        getCustomerTagGroups().then((resp) => {
            let options = (resp.data ?? [])
                .filter((group) => Array.isArray(group.tags) && group.tags.length)
                .map((group) => {
                    return {
                        label: group.groupName,
                        options: group.tags!.map((tag: any) => {
                            return {
                                ...tag,
                                label: tag.tagName,
                                value: tag.tagId,
                                groupId: tag.groupId,
                            };
                        }),
                    };
                });
            const colorMap = options.reduce((prev, current) => {
                const subMap = current.options.reduce((prev, cur) => {
                    prev[cur.value] = cur.tagColor;
                    return prev;
                }, {});
                return {...subMap, ...prev};
            }, {});
            setColorMap(colorMap);
            setTagOptions(options);
        });
    }, []);

    const requestList = () => {
        const { pageNum, pageSize } = paging;
        setLoading(true);
        getExternalUsers({
            params: {
                pageNum,
                pageSize,
            },
        })
            .then((resp) => {
                setDataSource(resp.data ?? []);
                setPaging(resp.paging ?? paging);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        requestList();
    }, [paging.pageNum, paging.pageSize]);

    const formConfig: FormProps = {
        initialValues: initialValues,
        customComponents: { Display },
        fields: [
            {
                name: 'externalUserId',
                label: tableLabels.id,
                type: 'Display',
            },
            {
                name: 'nickName',
                label: tableLabels.name,
                type: 'Display',
            },
            {
                name: 'tags',
                label: tableLabels.tag,
                type: 'Select',
                props: {
                    options: tagOptions,
                    mode: 'multiple',
                    allowClear: true,
                    optionFilterProp: 'label',
                    tagRender: (props, o) => {
                        const { label, value, closable, onClose } = props;
                        const onPreventMouseDown = (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        };
                        return (
                            <Tag
                                style={{ color: colorMap[value] ?? '#000', marginRight: 4, marginTop: 2,  marginBottom: 2 }}
                                color={colorMap[value] ? colorMap[value] + '1a' : undefined}
                                onMouseDown={onPreventMouseDown}
                                closable={closable}
                                onClose={onClose}
                                closeIcon={<Icon type='CloseOutlined' antdIcon={true} style={{color: 'rgba(0, 0, 0, 0.45)'}} />}
                            >
                                {label}
                            </Tag>
                        );
                    },
                },
            },
        ],
    };
    const onSubmit = (value) => {
        const { tags, ...others } = value;
        putExternalUsersById(
            { ...others, tagIds: tags },
            {
                segments: {
                    id: value.id,
                },
                successMsg: locales.business.info.saveSuccess,
            }
        ).then(() => {
            requestList();
            setFieldDetailVisible(false);
        });
    };
    return (
        <Page pageBodyClassName='client-manage-page-index'>
            <CommonTablePage
                loading={loading}
                // table={tableProps}
                // pagination={paginationProps}
            >
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
                <CommonTablePage.Modal
                    width={700}
                    formConfig={formConfig}
                    visible={fieldDetailVisible}
                    onCancel={() => setFieldDetailVisible(false)}
                    onOk={onSubmit}
                    loading={modalLoading}
                ></CommonTablePage.Modal>
            </CommonTablePage>
        </Page>
    );
}

export default React.memo(ClientManage);
