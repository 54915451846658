import Udesk from 'Udesk';
import QualityInspectionListIndexComponent from "./component";
// import _isEmpty from "lodash-es/isEmpty";
import UdeskLocales from 'UdeskLocales';

class QualityInspectionListIndexRoute extends QualityInspectionListIndexComponent {
    asyncModel(changedParams) {
        let { sdkOptions, match } = this.props;
        let {
            pageSize,
            pageNum,
            conditionList,
            judgeStrategy,
            customJudgeLogic,
            keywords
        } = this.privates.storages;
        this.privates.loading = true;
        this.actions.update();
        let list = this.actions.getQueryConditionList(conditionList);
        let params = {
            pageNum,
            pageSize,
            taskId: match.params.taskId,
            conditionList: list.map(item => {
                if (item.field.inspectionTaskId) {
                    return {
                        ...item,
                        field: {
                            ...item.field,
                            id: item.field.statusKey
                        }
                    };
                } else {
                    return item;
                }
            }),
            judgeStrategy,
            customJudgeLogic,
            keyword: keywords
        };
        this.privates.storages.fieldList = [];
        this.privates.customDataSourceList = [];
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }
        
        let url = Udesk.business.apiPath.concatApiPath(
            !this.privates.isInformationCollect ? `inspectionData/search` : `informationDatas/search`,
            sdkOptions
        );
        let inspectionDataPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                resp => {
                    resolve(resp);
                    if(this.privates.originColumnsBack) {
                        this.privates.loading = false;
                    }
                    this.privates.asyncModalRequestBack = true;
                    this.actions.update();
                    
                },
                reason => {
                    if(this.privates.originColumnsBack) {
                        this.privates.loading = false;
                    }
                    this.privates.asyncModalRequestBack = true;
                    this.actions.update();

                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        let recheckUrl = Udesk.business.apiPath.concatApiPath(
            `inspectionData/${match.params.taskId}/recheck/process`,
            sdkOptions
        );
        let recheckParams = {
            id: match.params.taskId,
            manualTaskId: null
        };
        let recheckPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(recheckUrl, recheckParams).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        let checkPointsUrl = Udesk.business.apiPath.concatApiPath(
            `inspectionData/${match.params.taskId}/pointList`,
            sdkOptions
        );
        let checkPointsPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(checkPointsUrl).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        let processStatus = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionTasks/" + match.params.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url)
                .then(
                    resp => {
                        resolve(resp);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                        reject(reason);
                    }
                );
        });
        return {
            inspectionData: inspectionDataPromise,
            recheckProcess: recheckPromise,
            checkPoints: checkPointsPromise,
            task: processStatus
        };
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        if (asyncKey === "task") {
            if (asyncModel.task) {
                if (asyncModel.task.data) {
                    this.privates.taskStatus = asyncModel.task.data.status;
                }
            }
        }
        if (asyncKey === "inspectionData") {
            if (asyncModel.inspectionData) {
                if (asyncModel.inspectionData.data) {
                    this.privates.inspectionData = asyncModel.inspectionData.data;
                }
                if (asyncModel.inspectionData.paging) {
                    this.privates.storages.pageNum =
                        asyncModel.inspectionData.paging.pageNum;
                    this.privates.storages.pageSize =
                        asyncModel.inspectionData.paging.pageSize;
                    this.privates.total = asyncModel.inspectionData.paging.total;
                }
                this.privates.currentKeywords = this.privates.storages.keywords;
                this.actions.update();
            }
        }
        if (asyncKey === "recheckProcess") {
            if (asyncModel.recheckProcess) {
                this.privates.progressStatus = asyncModel.recheckProcess.status;
                if (
                    asyncModel.recheckProcess.status ===
                    Udesk.enums.recheckStatus.waitForExecution.id ||
                    asyncModel.recheckProcess.status ===
                    Udesk.enums.recheckStatus.inExecution.id
                ) {
                    this.privates.reInspectionModalVisible = true;
                    let params = {
                        taskId: asyncModel.recheckProcess.taskId,
                        manualTaskId: asyncModel.recheckProcess.manualTaskId
                    };
                    let timerToken = setInterval(() => {
                        this.actions.queryReInspectionProgress(params);
                    }, 5 * 1000);
                    this.privates.timerToken = timerToken;
                }
                this.actions.update();
            }
        }
        if (asyncKey === "checkPoints") {
            if (asyncModel.checkPoints) {
                let reInspectionPoints = asyncModel.checkPoints;
                reInspectionPoints = reInspectionPoints.filter(
                    current => current.children && current.children.length > 0
                );
                for (let i = 0; i < reInspectionPoints.length; i++) {
                    let firstCategory = reInspectionPoints[i];
                    if (firstCategory.children && firstCategory.children.length > 0) {
                        for (let j = 0; j < firstCategory.children.length; j++) {
                            let secondCategory = firstCategory.children[j];
                            secondCategory.points = secondCategory.points.filter(item => item.type !== Udesk.enums.pointTypes.manual.id);
                            for (
                                let index = 0;
                                index < secondCategory.points.length;
                                index++
                            ) {
                                secondCategory.points[index]._checked = true;
                            }
                        }
                    }
                }
                this.privates.reInspectionPoints = reInspectionPoints;
                this.actions.update();
            }
        }

    }
}

export default QualityInspectionListIndexRoute;
