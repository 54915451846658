import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import { FormBuilder, Button, Space, Row, Col } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import Page from 'Component/common/udesk-qa-page-layout';
import UdeskLocales from 'UdeskLocales';
import './style.scss';
import { CustomerLabeSelect } from './components/CustomerLabeSelect';
import { RiskSettingRule } from './components/RiskSettingRule';
import { getRiskSettingsById, putRiskSettingsById } from 'src/api/riskSettings/{id}';
import { postRiskSettings } from 'src/api/riskSettings';
import {
    // @ts-ignore
    // BaseResponseCommunicateStrategyFoundResponse,
    // @ts-ignore
    CommunicateStrategyFoundResponse,
} from 'src/api/types';

const LAYOUT = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 },
};
const MAX_WIDTH = {
    maxWidth: 350,
};

function SupervisionDetail(props) {
    const locales = UdeskLocales['current'];

    // const categoryId = useMemo(() => {
    //     const queryParams = new URLSearchParams(props.location.search);
    //     return Number(queryParams.get('classificationId'));
    // }, []);

    const [initialValues, setInitialValues] = useState<CommunicateStrategyFoundResponse>(() => {
        let res: any = {};
        return res;
    });
    const [loading, setLoading] = useState(false);
    const [wrapForm] = FormBuilder.useForm();
    // const [triggerPointId, setTriggerPointId] = useState<number | undefined>(undefined);
    useEffect(() => {
        let { match } = props;
        let id = match.params.id;
        if (id) {
            setLoading(true);
            getRiskSettingsById({
                segments: {
                    id,
                },
            })
                .then((resp) => {
                    if (resp.data) {
                        setInitialValues(resp.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);
    const transitionToIndex = () => {
        let routeOptions = {
            history: props.history,
            routeName: 'riskStatementListIndex',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const onFinish = async (values) => {
        let { match } = props;
        let id = match.params.id;
        // let request: BaseResponseCommunicateStrategyFoundResponse | null = null;
        const params = {
            ...values,
            isEnable: values.isEnable ? 1 : 0,
        };
        if (!id) {
            await postRiskSettings(params);
        } else {
            await putRiskSettingsById(params, {
                segments: {
                    id,
                },
            });
        }
        transitionToIndex();
    };

    const config: FormProps<any> = {
        onFinish: onFinish,
        initialValues: initialValues,
        customComponents: { CustomerLabeSelect, RiskSettingRule },
        ...LAYOUT,
        fields: [
            {
                type: 'Input',
                name: 'name',
                label: /* 名称 */ locales.pages.intelligentAnalysis.supervision.detail.index.name,
                props: {
                    showCount: true,
                    maxCount: 40,
                    style: MAX_WIDTH,
                },
                rules: [
                    {
                        required: true,
                    },
                    {
                        max: 40,
                        type: 'string',
                    },
                ],
            },
            {
                label: /* 描述 */UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.index.describe,
                name: 'description',
                type: 'TextArea',
            },
            {
                label: /* 适用客户 */UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.index.applicableCustomers,
                name: 'suitCustomerList',
                type: 'CustomerLabeSelect',
            },
            // {
            //     label: '适用员工',
            //     name: 'agent',
            //     type: 'CustomerLabeSelect',
            // },
            {
                label: /* 启用状态 */UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.index.enableStatus,
                name: 'isEnable',
                type: 'Switch',
            },
            {
                label: /* 触发规则 */UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.index.triggerRules,
                name: 'riskSettingRuleList',
                type: 'RiskSettingRule',
            },
        ],
        footerRender: false,
    };

    useEffect(() => {
        wrapForm.resetFields();
    }, [initialValues]);
    return (
        <Page
            title={/* 风险规则 */UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.index.riskRules}
            pageBodyClassName="udesk-qa-risk-statement-detail"
            padding={true}
            loading={loading}
            onBack={transitionToIndex}
        >
            <FormBuilder {...(config as FormProps)} form={wrapForm}></FormBuilder>
            <Row>
                <Col offset={4}>
                    <Space>
                        <Button type="primary" onClick={wrapForm.submit}>
                            {locales.labels.save}
                        </Button>
                        <Button onClick={transitionToIndex}>{locales.labels.cancel}</Button>
                    </Space>
                </Col>
            </Row>
        </Page>
    );
}

export default React.memo(SupervisionDetail);
