import React from 'react';
import Udesk from 'Udesk';
class MassagePushConfigurationComponent extends React.Component {

    privates = {
        associateActiveTab: 'data',
        operatorList: [],
        associateList: [],
        associateOperatorList: [],
        associateOperatorListSource: [],
        associateListSource: [],
        associateListSearchValue: '',
        associateOperatorIconList: [
            {
                subType: 0,
                icon: 'hanshu',
            },
            {
                subType: 1,
                icon: 'jia',
            },
            {
                subType: 2,
                icon: 'jian',
            },
            {
                subType: 3,
                icon: 'cheng',
            },
            {
                subType: 4,
                icon: 'chu',
            },
            {
                subType: 21,
                icon: 'dengyu',
            },
            {
                subType: 22,
                icon: 'dayu',
            },
            {
                subType: 23,
                icon: 'dayudengyu',
            },
            {
                subType: 24,
                icon: 'xiaoyu',
            },
            {
                subType: 25,
                icon: 'xiaoyudengyu',
            },
            {
                subType: 51,
                icon: 'yu',
            },
            {
                subType: 52,
                icon: 'huo',
            },
            {
                subType: 53,
                icon: 'fei',
            },
        ],
    };
    actions = {
        //随路字段
        associateInputFocus() {
            if (this.props.associateDivRef?.current.firstChild.selectionStart !== this.props.associateDivRef?.current.firstChild.selectionEnd) {
                this.props.textAreaRef?.current.focus();
            } else {
                this.props.textAreaRef?.current.focus();
            }
        },
        associateSearchChange(e) {
            e.stopPropagation();
            if (this.privates.associateListSearchValue) {
                this.actions.setAssociateList();
            } else {
                this.privates.associateList = this.privates.associateListSource;
                this.privates.associateOperatorList = this.privates.associateOperatorListSource;
                this.actions.update();
            }
        },
        setAssociateList(value) {
            if (this.privates.associateListSearchValue === '[' || this.privates.associateListSearchValue === ']') {
                this.privates.associateList = this.privates.associateListSource;
            } else {
                this.privates.associateList =
                    this.privates.associateListSource.map(associate => {
                        if (associate.fieldList) {
                            return {
                                type: associate.type,
                                fieldList: associate.fieldList.filter(item => item.label.indexOf(this.privates.associateListSearchValue) !== -1),
                            };
                        }
                        return {
                            type: associate.type,
                            fieldList: [],
                        };
                    });
            }
            this.privates.associateOperatorList =
                this.privates.associateOperatorListSource.map(operatorList => {
                    if (operatorList.list) {
                        return {
                            type: operatorList.type,
                            list: operatorList.list.filter(item => item.view.indexOf(this.privates.associateListSearchValue) !== -1 || item.note.indexOf(this.privates.associateListSearchValue) !== -1),
                        };
                    }
                    return {
                        type: operatorList.type,
                        list: [],
                    };
                });
            this.actions.update();
        },
        associateListSearchChange(e) {
            this.privates.associateListSearchValue = e.target.value;
            if (e.target.value === '') {
                this.privates.associateList = this.privates.associateListSource;
                this.privates.associateOperatorList = this.privates.associateOperatorListSource;
                this.actions.update();
            }
        },
        associateTabsChange(activeKey) {
            this.privates.associateActiveTab = activeKey;
            this.actions.update();
        },
        associateDataClick(actionType, type, item, e) {
            let pushConf = {...this.props.pushConf};
            let textAreaDom = this.props.associateDivRef?.current.firstChild;
            if(!textAreaDom){
                return;
            }
            //为运算操作符前后添加空格，以区分是否为新增字符
            let arrNew;
            let res;
            if (!pushConf.actionDetails) {
                pushConf.actionDetails = {};
            }
            arrNew = pushConf.actionDetails.content.split('');
            arrNew.splice(textAreaDom.selectionStart, 0, item);
            // if (type === 1 && item.length === 1) {
            //     item = ' ' + item + ' ';
            // }
            //删除示例中的形参
            // if (type === 2) {
            //     let itemCopy = item.split('');
            //     itemCopy.splice(item.indexOf('(') + 1, item.lastIndexOf(")") - item.indexOf('(') - 1);
            //     item = itemCopy.join('');
            // }
            pushConf.actionDetails.content = arrNew.join('');
            res = pushConf.actionDetails.content.length;
            
            let that = this;
            textAreaDom.focus();
            setTimeout(() => {
                // let res = operatorItem.operatorList[0].param.inputContent.length;
                //根据元素长度判断是否新增，新增元素长度恒大于1
                arrNew.forEach((ele, index) => {
                    //新添加的元素是函数操作符(包含()且不是数据)
                    // if (ele.indexOf('(') >= 1 && ele.indexOf('[') === -1 && ele.indexOf(']') === -1) {
                    //     res = index + ele.indexOf('(') + 1;
                    // }
                    //新添加的元素在函数的()中
                    // if (ele.indexOf('(') < 1 && arrNew[index + 1] && arrNew[index + 1].length !== 1) {
                    //     res = index + arrNew[index + 1].length + 1;
                    // }
                    //新添加的元素是数据或运算操作符
                    if (ele && ele.indexOf('(') < 1 && arrNew[index + 1] && arrNew[index].length !== 1) {
                        res = index + arrNew[index].length;
                    }
                });
                //定位光标位置，selectionStart与selectionEnd不同可实现自动选中某区间效果
                that.props.associateDivRef.current.firstChild.selectionStart = res;
                that.props.associateDivRef.current.firstChild.selectionEnd = res;
            }, 100);
            //阻止冒泡
            e.stopPropagation();
            
            this.trigger('pushConfContentChange', pushConf);
        },
        getAssociate() {
            let { sdkOptions, taskType, taskTypeList = [1, 3, 6, 8] } = this.props;
            let fieldUrl = Udesk.business.apiPath.concatApiPath(
                `field/data/search-template`,
                sdkOptions
            );
            let data = {
                sourceList: [taskType],
                typeList: taskTypeList
            };
            Udesk.ajax.post(fieldUrl, data).then(
                resp => {
                    this.privates.associateListSource = resp.data[0].typeList;
                    this.privates.associateListSource.push({
                        type: 'task',
                        fieldList: []
                    });
                    this.privates.associateList = this.privates.associateListSource;
                    this.actions.getTaskAssociate();
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getAssociateOperator() {
            let { sdkOptions } = this.props;
            let fieldUrl = Udesk.business.apiPath.concatApiPath(
                `operator/meta`,
                sdkOptions
            );
            let data = {
                typeList: [1, 2],
            };
            Udesk.ajax.post(fieldUrl, data).then(
                resp => {
                    this.privates.associateOperatorList = resp.data;
                    this.privates.associateOperatorListSource = resp.data;
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getTaskAssociate() {
            let { sdkOptions, taskId } = this.props;
            if(!taskId){
                return;
            }
            let fieldUrl = Udesk.business.apiPath.concatApiPath(
                `field/data/task/${taskId}`,
                sdkOptions
            );
            Udesk.ajax.get(fieldUrl).then(
                resp => {
                    this.privates.associateListSource.forEach(item => {
                        if(item.type === 'task'){
                            item.fieldList = resp.data;
                        }
                    });
                    this.privates.associateList = this.privates.associateListSource;
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
    };

    //#region Life Cycle
    asyncModel(changedParams) {
        this.actions.getAssociate();
        this.actions.getAssociateOperator();
    }
    //#endregion
}
export default MassagePushConfigurationComponent;