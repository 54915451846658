import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import moment from 'moment';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import { Spin, Modal, Progress, Space } from "udesk-ui";
import { isExercise } from '../util';
import './index.less';

export type RefType = {
    open: (type: 1 | 2 | 3 | 4 | 5) => void;
}

const SuspendDetailChart = React.memo((props: any) => {
    const {data, taskDetail} = props;

    const initTime = useCallback((time) => {
        return moment('000000', 'Hmmss').second(time).format('HH:mm:ss');
    }, []);

    const bottomInfo = useMemo(() => {
        if (taskDetail.dialogueMode === Udesk.enums.learningTaskDialogueModeType.ppt.id) {
            return (
                <div className='bottom-info'>
                    <span>{/* 已完成 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.completed}{data.finishQuestions}{/* 页 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.page}</span>
                    <span>{/* 总页数 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.pageCount}{data.totalQuestions}</span>
                </div>
            );
        }
        return (
            <div className='bottom-info'>
                <span>{/* 已完成 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.completed}{data.finishQuestions}{/* 题 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.topic}</span>
                <span>{/* 总题数 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.totalNumberOfQuestions}{data.totalQuestions}</span>
            </div>
        );
    }, [data.finishQuestions, data.totalQuestions, taskDetail.dialogueMode]);

    return (
        <Space 
            size='large'
            direction='vertical'
            align="center" 
            className='suspend-detail-chart space-block'
        >
            <Space direction='vertical' align="center" className='total-part-wrap'>
                <div className='total-part'>
                    <div>
                        <Progress type="dashboard" percent={data.score / data.totalScore * 100} format={() => data.score} strokeWidth={8}/>
                    </div>
                    <div style={{ marginLeft: 16, width: 90 }}>
                        <div className='total'>{/* 总分 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.totalScore}{data.totalScore}{/* 分 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.branch}</div>
                        <div className='part'>{/* 话术总分: */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.totalScriptScore}{data.speechCraftTotalScore}</div>
                        <div className='part'>{/* 情绪总分: */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.totalEmotionalScore}{data.emotionTotalScore}</div>
                        <div className='part'>{/* 表达总分: */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.totalScoreOfExpression}{data.speedTotalScore}</div>
                    </div>
                </div>
            </Space>
            <div className='content'>
                <Space size={5} direction='vertical' className='space-block' style={{ marginBottom: 16 }}>
                    <span>{/* 累计完成进度 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.accumulatedCompletionProgress}{Math.ceil(data.finishQuestions/data.totalQuestions * 100)}%</span>
                    <Progress strokeLinecap="butt" showInfo={false} percent={Math.ceil(data.finishQuestions/data.totalQuestions * 100)} />
                    {bottomInfo}
                </Space>
                {
                    data.limitTime ? (
                        <Space size={5} direction='vertical' className='space-block'>
                            <span>{/* 累计练习时长 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.accumulatedPracticeDuration}</span>
                            <Progress strokeLinecap="butt" showInfo={false} percent={Math.ceil(data.costTime/data.limitTime * 100)} />
                            <div className='bottom-info'>
                                <span>{initTime(data.costTime)}</span>
                                <span>{initTime(data.limitTime)}</span>
                            </div>
                        </Space>
                    ) : (
                        <Space>
                            <span>{/* 累计练习时长 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.accumulatedPracticeDuration}</span>
                            <span>{initTime(data.costTime)}</span>
                        </Space>
                    )
                }
            </div>
        </Space>
    );
});

export default React.forwardRef<RefType, any>((props, ref) => {
    const {
        isSuspend, isLoadSuspendInfo = true, suspendDetail ={
            "score": 30, // 得分
            "totalScore": 39, // 总分
            "finishQuestions": 4, // 完成知识点个数
            "totalQuestions": 5, // 总的知识点个数
            "costTime": 22, // 已累计耗时 单位秒
            "limitTime": null // 限制时长，为null不限制时间
        }, 
        continueTask, restartTask,
        taskDetail,
        goBack, 
        cancelTaskAjax, 
        submitTaskAjax,
        visible = false
    } = props;
    // 0：页面进入的提示， 1：练习提前退出，2：考试提前退出，3：正常提交
    const [type, setType] = useState(0); 
    const [isVisible, setIsVisible] = useState(visible);
    
    useImperativeHandle(ref, () => {
        return {
            open(type) {
                setType(type);
                setIsVisible(true);
            }
        };
    });

    const title= useMemo(() => {
        if (isLoadSuspendInfo) {
            if (type === 0) {
                return isExercise(taskDetail?.taskType, () => {
                    if (isSuspend) {
                        return /* 上次暂停-练习结果 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.lastPauseExerciseResults;
                    }
                    return /* 练习说明 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.exerciseInstructions;
                }, () => {
                    return /* 考试说明 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.examInstructions;
                });
            }
            if (type === 5) {
                return /* 中途暂停-练习结果 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.halfwayPausePracticeResults;
            }
        }
        return /* 提示 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.tips;
    }, [isLoadSuspendInfo, isSuspend, taskDetail?.taskType, type]);

    const addManualReviewTip = useCallback((text) => {
        const tip = /* 当前任务评分结果需人工审核，审核完成将通知您最终成绩，请耐心等待 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait;
        if (taskDetail?.enableManualReview === Udesk.enums.enableManualReviewType.open.id) {
            return text + tip;
        }
        return text;
    }, [taskDetail?.enableManualReview]);

    const taskDesc = useMemo(() => {
        if (type === 0) {
            if (isLoadSuspendInfo) {
                if (isSuspend && isExercise(taskDetail?.taskType)) {
                    return <SuspendDetailChart data={suspendDetail} taskDetail={taskDetail}/>;
                }
            }
            return taskDetail?.taskDesc;
        }
        if (type === 1) {
            return /* 退出后此练习不计入成绩， 是否确认退出？ */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit;
        }
        if (type === 2) {
            return (
                <div className='test-exit-desc' >{/* 考试中途不允许退出，是否确认提交？ */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit}<span className='num'>{taskDetail?.limitTimes - taskDetail?.completeByTimes}</span>{/* 次，请谨慎操作） */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.pleaseOperateWithCaution}</div>
            );
        }
        if (type === 3) {
            return addManualReviewTip(/* 是否确认提交？ */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.areYouSureToSubmit);
        }
        if (type === 4) {
            return addManualReviewTip(isExercise(taskDetail?.taskType, /* 练习已结束，是否确认提交？ */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.theExerciseHasEndedAreYouSureToSubmit, /* 考试已结束，是否确认提交？ */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.theExamHasEndedAreYouSureToSubmit));
        }
        if (type === 5) {
            return <SuspendDetailChart data={suspendDetail} taskDetail={taskDetail}/>;
        }
    }, [
        addManualReviewTip, 
        isLoadSuspendInfo, 
        isSuspend, 
        suspendDetail, 
        taskDetail, 
        type
    ]);

    const cancelText = useMemo(() => {
        if (type === 0) {
            if (isLoadSuspendInfo && isSuspend) {
                return /* 重新开始 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.restart;
            }
            return /* 返回学习 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.returnToLearning;
        }
        if (type === 1) {
            return /* 继续练习 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.continuePracticing;
        }
        if (type === 2) {
            return /* 继续考试 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.continuingTheExam;
        }
        if (type === 3) {
            return /* 返回 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.return;
        }
        if (type === 4) {
            return /* 暂不提交 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.notSubmittedTemporarily;
        }
        if (type === 5) {
            return /* 继续练习 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.continuePracticing;
        }
        return null;
    }, [isLoadSuspendInfo, isSuspend, type]);

    const okText = useMemo(() => {
        if (type === 0) {
            if (isLoadSuspendInfo && isSuspend) {
                return /* 继续练习 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.continuePracticing;
            }
            return /* 我知道了 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.iGotIt;
        }
        if (type === 1) {
            return /* 确认退出 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.confirmExit;
        }
        if ([2, 3, 4].includes(type)) {
            return /* 确认提交 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.confirmSubmission;
        }
        if (type === 5) {
            return /* 退出 */UdeskLocales['current'].pages.coach.learningCenter.components.taskMessageModal.index.signOut;
        }
        return null;
    }, [isLoadSuspendInfo, isSuspend, type]);

    const submitHandle = useCallback(() => {
        if (type === 0) {
            setIsVisible(false);
        }
        if ([1, 2].includes(type)) {
            cancelTaskAjax();
        }
        if ([3, 4].includes(type)) {
            submitTaskAjax();
        } 
        if (type === 5) {
            goBack();
        }
    }, [cancelTaskAjax, goBack, submitTaskAjax, type]);

    const cancelHandle = useCallback(() => {
        if (type === 0) {
            if (isLoadSuspendInfo) {
                if (isSuspend) {
                    restartTask();
                } else {
                    goBack();
                }
                setIsVisible(false);
            }
        } else if (type === 5) {
            continueTask(() => {
                setIsVisible(false);
            });
        } else {
            setIsVisible(false);
        }
    },[continueTask, goBack, isLoadSuspendInfo, isSuspend, restartTask, type]);

    return (
        <Modal 
            wrapClassName='task-exercise-message-modal'
            zIndex={9999}
            title={title}
            cancelText={cancelText}
            okText={okText}
            maskClosable={false}
            getContainer={false}
            visible={isVisible} 
            onOk={submitHandle} 
            onCancel={cancelHandle}
        >
            <Spin spinning={!taskDesc} delay={500}>
                {taskDesc}
            </Spin>
        </Modal>
    );
});

