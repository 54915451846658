import React, { useCallback, useContext } from "react";
import UdeskLocales from 'UdeskLocales';
import { useCoachOrgTreeDataWithPermit } from "src/util/hook/user";
import { Button, Form, Space } from "udesk-ui";
import OrganizationMemberSelect from "../../task-center/components/OrganizationMemberSelect";
import { UserContext } from "..";


export default React.memo<any>((props) => {
    const {treeData} = useCoachOrgTreeDataWithPermit();
    const [form] = Form.useForm();
    const [, setOrgIds] = useContext(UserContext);

    const onFinish = useCallback((values) => {
        setOrgIds(values.data?.organizationIds?.join());
    }, []);

    const onReset = useCallback(() => {
        form.resetFields();
    }, []);

    return (
        <Form
            form={form}
            layout="inline"
            onFinish={onFinish}
            style={{
                padding: '16px',
                justifyContent: 'space-between',
            }}
        >
            <Form.Item name="data" label={/* 员工部门 */UdeskLocales['current'].pages.coach.dashboard.components.filter.employeeDepartment}>
                <OrganizationMemberSelect width={300} treeData={treeData}/>
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button onClick={onReset}>
                        {UdeskLocales['current'].labels.reset}
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {UdeskLocales['current'].labels.confirm}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
});