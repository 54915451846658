import React from 'react';
import { Icon, Tooltip } from 'udesk-ui';

export default function Card(props) {
    const tip = props.subTip ? (
        <div>
            <div>{props.tip}</div>
            <div>{props.subTip}</div>
        </div>
    ) : (
        props.tip
    );
    return (
        <div className='numerical-card'>
            <div className='numerical-card-tooltip'>
                <Tooltip title={tip}>
                    <Icon
                        antdIcon={true}
                        type='InfoCircleOutlined'
                        style={{ color: 'rgba(0, 0, 0, 0.25)' }}
                    />
                </Tooltip>
            </div>
            <div className='numerical-card-main'>
                <div className='numerical-card-main-icon'>
                    <Icon type={props.icon} style={{ fontSize: '48px' }} />
                </div>
                <div className='numerical-card-main-count'>
                    <div className='title'>{props.name}</div>
                    <div className='count'>{props.data || '-'}</div>
                </div>
            </div>
            {props.percentName && (
                <div className='numerical-card-desc'>
                    {`${props.percentName}: ${props.percent || '-'}`}
                </div>
            )}
        </div>
    );
}
