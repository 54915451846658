// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewCallCustomerMessageFindallQuery,
    BaseResponseListDataCallAnalysisFoundResponse,
} from '../../../../../types';

/**
 * findCustomerMessages
 *
 * @export
 * @tags 联络看板
 * @link [GET] /review/call/customer/message/findall
 */
export function getReviewCallCustomerMessageFindall(): Promise<BaseResponseListDataCallAnalysisFoundResponse>;
export function getReviewCallCustomerMessageFindall(
    options: ApiOptions<never, GetReviewCallCustomerMessageFindallQuery>
): Promise<BaseResponseListDataCallAnalysisFoundResponse>;
export function getReviewCallCustomerMessageFindall(
    options?: ApiOptions<never, GetReviewCallCustomerMessageFindallQuery>
): Promise<BaseResponseListDataCallAnalysisFoundResponse> {
    return request<never, never, GetReviewCallCustomerMessageFindallQuery>(
        '/review/call/customer/message/findall',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewCallCustomerMessageFindall' }
    );
}

export const meta = [
    { tags: ['联络看板'], path: '/review/call/customer/message/findall', method: 'get' },
];
