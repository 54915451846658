import React from 'react';
import { InputNumber, Select } from 'udesk-ui';
import Locales from 'UdeskLocales';
import './index.less';

const ExecutionFilter = (props) => {
    const { defaultValue, value, onChange } = props;
    const locales = Locales['current'];

    const getValue = () => {
        return value || defaultValue || {};
    };

    const handleChange = (val) => {
        if (typeof onChange === 'function') {
            onChange({
                ...getValue(),
                ...val,
            });
        }
    };

    return (
        <div className="execution-filter-wrapper">
            <Select
                style={{ width: 100 }}
                options={[
                    { label: locales.pages.gong.coachTaskCenter.list.executeRate, value: 1 },
                    { label: locales.pages.gong.coachTaskCenter.list.completeRate, value: 2 },
                    { label: locales.pages.gong.coachTaskCenter.list.commitRate, value: 3 },
                    { label: locales.pages.gong.coachTaskCenter.list.excellentRate, value: 4 },
                ]}
                placeholder={locales.labels.pleaseSelect}
                value={getValue().executeType}
                onChange={(val) => handleChange({ executeType: val })}
            />
            <Select
                style={{ width: 100, marginLeft: 4 }}
                options={[
                    { label: locales.enums.fieldOperators.greaterThanEq, value: 'more_than_eq' },
                    { label: locales.enums.fieldOperators.relative, value: 'eq' },
                    { label: locales.enums.fieldOperators.lessThanEq, value: 'less_than_eq' },
                ]}
                placeholder={locales.labels.pleaseSelect}
                value={getValue().executeOperate}
                onChange={(val) => handleChange({ executeOperate: val })}
            />
            <InputNumber
                style={{ width: 100, marginLeft: 4, marginRight: 4 }}
                placeholder={locales.labels.pleaseEnter}
                precision={2}
                value={getValue().executeValue}
                onChange={(val) => handleChange({ executeValue: val })}
            />
            <span>%</span>
        </div>
    );
};

export default ExecutionFilter;
