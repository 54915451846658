import { algorithmRoutesGlobal } from "./algorithm/routes-global";
import { coachRoutesGlobal } from "./coach/routes-global";
import { salesRoutesGlobal } from "./sales/routes-global";
import { generalSettingRoutesGlobal } from "./setting/routes-global";
import { qaRoutesGlobal } from "./qa/routes-global";
import { conversationAnalysisRoutesGlobal } from "./conversationAnalysis/routes-global";

const RoutesGlobal = [
    {
        path: '/entry-page',
        name: 'appEntry'
    },
    // 系统登录
    {
        path: '/auth/login',
        name: 'authLogin',
    },
    // 系统退出
    {
        path: '/auth/logout',
        name: 'authLogout',
    },
    //404
    {
        path: '/404',
        name: 'pageNotFound',
    },
    ...qaRoutesGlobal,
    ...salesRoutesGlobal,
    ...algorithmRoutesGlobal,
    ...generalSettingRoutesGlobal,
    ...coachRoutesGlobal,
    ...conversationAnalysisRoutesGlobal,
];

export default RoutesGlobal;
