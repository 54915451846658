var locales = {
    labels: {
        clear: "清空",
        refresh: "刷新",
        nextStep: "下一步",
        prevStep: "上一步",
        closeDriver: "退出引導",
        complete: "完成",
        viewStatistics: "查看統計",
        colon: "：",
        splitter: "/",
        remark: "備註",
        correction: "纠错",
        save: "保存",
        reset: "重置",
        increase: "添加",
        preview: "預覽",
        unit: "條",
        cancel: "取消",
        all: "全部",
        searchPlaceHolder: "輸入關鍵字查詢",
        greaterThan: "大於",
        lessThan: "小於",
        second: "秒",
        description: "描述",
        column: "列",
        pleaseChoice: "請選擇",
        filter: "篩選",
        saveAndSubmit: "保存並提交",
        submit: "提交",
        appeal: "申訴",
        review: "復核",
        choose: "選擇",
        manage: "管理",
        delete: "刪除",
        add: "新增",
        create: "新建",
        edit: "編輯",
        name: "名稱",
        describe: "描述",
        next: "下一步",
        percent: "%",
        export: "導出",
        exportAll: "全部導出",
        confirm: "確定",
        back: "返回",
        versionUpdate:
      "很高興的通知您，我們網站剛剛發布了新版本！為了保證您的正常使用，我們需要您立即保存當前的工作，然後刷新頁面（點擊瀏覽器的刷新按鈕，或者按下F5鍵），以讓我們的新版本生效。非常感謝您的配合！",
        more: "更多",
        selected: "已選",
        deduct: "扣",
        maxScore: "最多",
        scoreUnit: "分",
        pointUnit: "個",
        comma: "，",
        view: "查看",
        batchingImport: "導入",
        deleteContent: "您確認要刪除嗎？刪除後將不能再恢復",
        import: "導入",
        uploadFile: "上傳文件",
        downloadFile: "導出文件",
        upload: "上傳",
        click: "點擊",
        downloadTemplate: "下載模板",
        fillInTheForm: "按格式填寫",
        UploadExcelfile: "上傳Excel完成批量導入",
        uploadIdentical: "如果API名稱存在，導入時，會對系統中已有的數據進行更新",
        numOne: "1",
        numTwo: "2",
        numThree: "3",
        apply: "適用",
        leftBracket: "(",
        rightBracket: ")",
        question: "問題",
        answer: "答案",
        content: "內容",
        search: "搜索",
        query: "查詢",
        cancelEdit: "取消編輯",
        noValue: "暫無數據",
        selectAll: "全選",
        changeAGroup: "換一組",
        arrow: " ---> ",
        saveReview: "復核提交",
        saveSpotCheck: "抽檢提交",
        rapidSampling: "快速抽檢",
        file: "歸檔",
        download: "下載",
        lastPage: "上一頁",
        nextPage: "下一頁",
        batchActions: "批量操作",
        cancelDistribution: "取消分配",
        againDistribution: "重新分配",
        deleteRecord: "刪除記錄",
        oneOptionOpertor: "自選質檢員",
        allOptionOpertor: "所有質檢員",
        distributionInfoFirstOne: "確認要對",
        distributionInfoFirstTwo: "條記錄重新分配嗎?請選擇分配目標",
        distributionInfoSecondOne: "確定要取消",
        distributionInfoSecondTwo:
      "條記錄的分配人信息嗎,取消後將變為未分配的復核記錄.",
        distributionInfoSecondThree:
      "條記錄的分配人信息嗎,取消後將變為未分配的抽檢記錄.",
        distributionInfoThirdOne: "所選記錄中有",
        distributionInfoThirdTwo: "條已復核的記錄,已復核記錄將不會被處理.",
        distributionInfoThirdThree: "條已抽檢的記錄,已抽檢記錄將不會被處理.",
        exportError: "導出失敗",
        exportSuccess: "導出成功",
        deleteRecordInfo: "確認要刪除{0}條記錄嗎?刪除後數據將不能恢復",
        pleaseSetFilter: "請選擇篩選條件",
        noCountAfterFilter: "暫無導出數據，請重新設置篩選條件",
        redo: "重新執行",
        batchMove: "批量移动",
        websiteName: "智能質檢",
        salesEnable: "销售赋能",
        systemName: "智能質檢",
        initSystemError: "網絡異常或頁面初始化失敗，請聯系客服",
        day: "天",
        hour: "小時",
        minute: "分",
        questionMark: "？",
        personalSettings: "個人設置",
        logout: "註銷",
        ok: "確定",
        saveAs: "另存為",
        pleaseEnter: "請輸入",
        executeMessage: {
            executeSuccess: "成功執行{0}條",
            executeError: "{0}條執行失敗",
        },
        field: "字段",
        operator: "運算符",
        value: "值",
        placeholder: "請選擇",
        pleaseSelect: "請選擇",
        totalScore: "總分",
        hyphen: "-",
        semicolon: "；",
        wfm: "智能排班",
        user: "用戶",
        none: "無",
        plusSign: "+",
        minusSign: "-",
        saveSuccessAndReload: "保存成功！相關員工刷新頁面後生效",
        saveAsName: "{0}(副本)",
        systemInformation: "查看系統資料",
        billingCenter: "計費中心",
        warningMessage: "剩余配額不足，請盡快充值",
        fileTypeHolder: "文件格式有誤，請上傳{0}格式文件",
        dataNo: "暫無數據",
        move: "移動",
        study: "您有未標記的自學習質檢點",
        studySuccess: "標註成功",
        studyError: "標註失敗",
        editType: "編輯分類標簽",
        copyed: "已復製到剪貼板",
        clickToCopy: "点击复制",
        addFilterConditions: "添加篩選條件",
        filterConditions: "篩選條件",
        messageTime: "消息時間",
        messageType: "消息類型",
        pushType: "消息渠道",
        messageContent: "消息內容",
        messageName: "消息名稱",
        messageStatus: "消息狀態",
        configuration: "配置信息",
        sendTarget: "發送目標",
        guideUrl: "引導URL",
        noticeDetail: "消息詳情",
        action: "操作",
        seeDetail: "查看詳情",
        theDay: "{0}日",
        theLastDayOfMonth: "每月的最後一天",
        ignore: "忽略",
        seeNow: "立即查看",
        exportConfirmText: {
            export: "總共{0}條數據，確認導出?",
            exportAll: "確認導出全部內容?",
        },
        samrTagAdd: "添加標簽",
        deletePopconfirmContent: "您確認要刪除嗎？",
        TheLastThirtyDays: "最近30天",
        addNumber: "加",
        subNumber: "減",
        addStrategy: "添加策略",
        minutes: "分鐘",
        status: "啟用狀態",
        pushConfig: "推送配置",
        category: "分類",
        and: "與",
        interval: "間隔",
        switchOn: "開啟",
        switchOff: "關閉",
        batchDel: "批量刪除",
        exportTxt: "導出TXT文本",
        playSpeed: "倍速",
        messageLogBtn: "查看觸發通知的通/對話",
        dataLink: "數據鏈接",
        yes: "是",
        no: "否",
        sentenceUnit: "句",
        appealDetail: "申訴記錄詳情",
        detail: "詳情",
        default: "默認",
        customer: "客戶",
        agent: "客服",
        sort: "排序",
        applyNow: "應用",
        index: "序號",
        allColumns: "可添加的列",
        selectedColumns: "已添加的列",
        syn: "同步",
        saveAndNext: "保存并创建下一条",
        viewReference: "查看引用",
        referenceDetail: "引用详情",
        goToQaList: "去质检",
        goToCheck: "去抽检",
        businessCard: "名片",
        weapp: "小程序",
        redpacket: "红包",
        externalRedpacket: "互通红包",
        sphfeed: "视频号",
        exportTaskCreated: "导出任务创建成功",
        plzGoTo: "请跳转至",
        importTaskCreated: "导入任务创建成功",
        taskCreated: "任务创建成功",
        reply: "回复",
        replied: "回复了",
        release: "发布",
        expandMore: "展开其他 {0} 条回复",
        comment: "评论",
    },
    business: {
        notifyMessage: {
            IsNullErrorFormat: "請至少有一個一個{0}",
            canNotBeEmptyFormat: "{0}不能為空",
            repeatErrorTipFormat: "{0}有重復",
            getInformationFailedFormat: "獲取{0}信息失敗，請檢查網絡連接或稍後再試",
            deleteSuccessFormat: "{0}刪除成功。",
            deleteFailedFormat: "{0}刪除失敗，請稍後再試。",
            saveSuccessFormat: "{0}保存成功。",
            saveFailedFormat: "{0}保存失敗，請稍後再試。",
            fileSuccessFormat: "{0}歸檔成功。",
            fileFailedFormat: "{0}歸檔失敗，請稍後再試。",
            exportSuccessFormat: "{0}導出成功",
            exportFailedFormat: "{0}導出失敗，請稍後再試。",
            submitSuccessFormat: "{0}提交成功。",
            submitFailedFormat: "{0}提交失敗，請稍後重試。",
            confirmSuccessFormat: "{0}確認成功。",
            confirmFailedFormat: "{0}確認失敗，請稍後重試。",
            pleaseUploadFile: "請先上傳文件！",
            importFileSuccess: "文件導入成功！",
            importFildFailed: "文件導入失敗！",
            deleteSuccess: "刪除成功！",
            deleteFailed: "刪除失敗，請稍後重試！",
            illegalTitle: "權限不足",
            illegalMessage:
        "您沒有下一步驟的部分或全部權限，請聯系管理員配置，點擊確定按鈕將退出引導",
            illegalMessageCurrent:
        "您沒有當前步驟的部分或全部權限，請聯系管理員配置，點擊確定按鈕將退出引導",
            skipMessage: "如果您想暫時跳過此步，請點擊跳過",
            errorMsg500: "服務器請求失敗，請檢查您的操作或刷新重試",
            errorMsg404: "Ooops...您找的頁面不見了",
            errorMsg400: "服務器請求失敗，請檢查您的數據後重試",
            pageOverFlow: "當前僅支持顯示10000條，超出部分請設置篩選條件查看",
            isHandAddSmartTag: "是手動添加的智能標簽",
            defaultGradeMustAtLast: "默認評級必須置於最末尾",
            AutomaticAllocationInspectorsIsNull: "請進入基本信息頁面添加質檢員",
            timePickerTips:
        "為了不影響您的正常使用，23：00~2：00為系統維護時間，不執行定時任務。",
            batchDeleteInfo: "成功刪除{0}條，失敗{1}條，失敗原因為該數據已經被使用。",
            batchDeleteTips: "共勾選{0}條數據，確認刪除?",
            correctionSuccess: "纠错成功",
        },
        pages: {
            qualityInspectionForm: {
                list: { IsMaxFormNumberError: "最多添加10個質檢表單" },
                basicInformation: {},
                specialProject: {
                    sumOfScoresErrorTip: "指標項目{0}之和加起來必須為100",
                    banProjectMaxErrorTip: "禁止項最多添加2000個",
                    deadlyProjectMaxErrorTip: "致命項最多添加500個",
                },
            },
        },
        info: {
            creatSuccess: "創建成功",
            creatError: "創建失敗",
            editSuccess: "修改成功",
            editError: "修改失敗",
            deleteSuccess: "刪除成功",
            addSuccess: "新增成功",
            deleteError: "刪除失敗",
            conditionError: "算子邏輯表達式錯誤",
            customJudgeLogic: "自定義條件組合邏輯表達式錯誤",
            exportError: "導出失敗",
            searchError: "篩選失敗",
            loadError: "{0}加載失敗",
            saveSuccess: "保存成功",
            saveError: "保存失敗",
            fileSuccess: "歸檔成功",
            fileError: "歸檔失敗",
            cannotBeNull: "{0} 不能為空!",
            pleaseSearchAndSelect: "請輸入關鍵字搜索",
            clickAndToggle: "點擊選擇切換",
            deleteWarning: "{0}刪除將無法恢復，您確定要繼續麽？",
            getOssAuthFailed: "獲取OSS授權失敗，請檢查網絡連接或稍後再試。",
            getInsightAuthFailed: "獲取Insight授權失敗，請檢查網絡連接或稍後再試。",
            pictureUploadFailed: "圖片上傳失敗",
            pictureUploadFailedSize: "圖片大小超過限製，圖片上傳失敗",
            wecomTagsSyncInfo: "最近更新：{0} ，同步{1}条信息",
            collectSuccess: "收藏成功",
        },
    },
    components: {
        common: {
            qcReportEcharts: { errorMessage: "獲取報表數據失敗" },
            waveAudio: {
                noWaveMessage:
          "為了維護您的體驗，超過{0}分鐘的錄音暫不顯示波形，您可以直接播放",
            },
            customFilter: {
                del: "刪除",
                edit: "編輯",
                new: "自定義篩選",
                apply: "應用",
                cancel: "取消",
                onlyApply: "僅適用",
                ohterSave: "另存為",
                save: "保存",
                customFilter: "常用篩選",
                reset: "重置",
                holderKeyWord: "請輸入關鍵詞",
                customFilterRule: "篩選邏輯規則：",
                customFilterName: "篩選器名稱：",
                holderCustomFilterName: "請輸入篩選器名稱",
                manualConfiguration: "手動配置",
                holderCustomRule: "請輸入自定義邏輯條件",
                example: "示例",
                dragConfiguration: "拖拽配置",
                condition: "條件",
                operation: "運算",
                infoDel: "內容拖出區域外刪除",
                toConfigure: "配置",
                holderCustomFilterNameLength: "篩選器名稱最大長度為10",
                customFilterNameNone: "篩選器名稱不能為空",
                and: "和",
                or: "或",
                not: "非",
                delSuccess: "刪除成功",
                saveSuccess: "保存成功",
                updateSuccess: "修改成功",
                customFilterCondition: "篩選條件",
                conditionRule: "條件規則",
                none: "無",
                filterPlaceholder: "請輸入關鍵詞",
            },
            labelTabs: {
                addThis: "添加到此",
                goThis: "移動到此",
                saveGo: "保存並訓練",
            },
            datePicker: {
                today: "今天",
                yesterday: "昨天",
                theDayBeforeYesterday: "前天",
                thisWeek: "本周",
                lastWeek: "上周",
                thisMonth: "本月",
                lastMonth: "上個月",
                lastSevenDay: "最近7天",
                lastThirtyDay: "最近30天",
                thisQuarter: "本季度",
                lastQuarter: "上個季度",
                thisYear: "今年",
                lastYear: "去年",
                lastYearFromToday: "最近一年",
            },
            pagination: { page: "共{0}條，{1}頁" },
            wechatRecord: {
                loadMoreBtn: { top: "加載更早的消息", bottom: "加載更多消息" },
            },
        },
        pages: {
            menuTitleList: {
                tasksManageTitle: "質檢任務",
                informationCollectTasksManageTitle: "采集任務",
                customerServiceCenterTitle: "客服中心",
                informationTitle: "數據中心",
                intelligentAnalysisTitle: "智能分析",
                semanticIntelligence: "語義智能",
                trainingCenterTitle: "訓練中心",
                adminTitle: "系統管理",
            },
            qualityCheckingTask: {
                title: "通話質檢",
                index: {
                    tipOne:
            "通話質檢用於評價員工的呼叫中心工作質量，您可以結合自己的業務，設置合適的質檢表單",
                    tipTwo:
            "可以為質檢任務設定合適的規則，包括篩選、抽樣等。質檢員進行評分後，客服可以查看質檢結果並進一步申訴,",
                    addTaskTitle: "添加質檢任務",
                    deleteTipTitle: "刪除質檢任務",
                    deleteTipContent: "質檢任務刪除後不可恢復，確認刪除嗎？",
                    checkingTask: "質檢任務",
                    checkingTaskList: "質檢任務列表",
                    formList: "表單列表",
                    viewHelpDocuments: "查看幫助文檔",
                },
                manage: {
                    taskSetting: {
                        title: "任務設置",
                        form: "表單",
                        basicInfo: "基本信息",
                        visibleRange: "可見範圍",
                        visibleRangeTip:
              "選擇的客服、客服組可以查看該質檢任務，另外可以結合權限設置，為這個任務客服組長、客服、質檢員等",
                        automaticSampling: "自動抽樣",
                        automaticSamplingTip:
              "您可以設置自動抽樣的規則，這樣系統將按照您設置的規則定期添加質檢任務，例如每周一抽取上周5%的通話",
                        samplingRate: "抽樣頻率",
                        samplingTime: "抽樣時間",
                        samplingRange: "抽樣範圍",
                        samplingRule: "抽樣規則",
                        fillterCondition: "篩選條件",
                        workflow: "質檢工作流程",
                        appealsTime: "允許申訴時間",
                        appealsTimeTipOne: "評分提交後",
                        appealsTimeTipTwo: "天內",
                        appealsTimeTipThree:
              "超過允許申訴時間，記錄質檢狀態自動修改為「已確認」",
                        auditTime: "審核處理時間",
                        auditTimeTipOne: "提出申訴後",
                        auditTimeTipTwo: "天內",
                        auditTimeTipThree:
              "超過審核處理時間，記錄維持原評分，質檢狀態自動修改為「已確認」",
                        noAppealsMode: "無申訴模式",
                        appealsMode: "允許申訴模式",
                        noAppealsModeImage: "no-appeal.png",
                        appealsModeImage: "appeal.png",
                        customerServices: "客服",
                        customerServicesGroup: "客服組",
                        surveyList: "評價列表",
                        customFields: "自定義字段",
                        fieldConfig: "字段配置",
                        from: "來自",
                        owner: "負責人",
                        inspectorAndReviewerAuthority: "質檢復核權限",
                        inspector: "質檢員",
                        reviewer: "復核員",
                        inspectorTip: "質檢員可以對該任務進行評分",
                        reviewerTip: "復核員可以對該任務進行評分",
                        dataStatisticsAuthority: "數據統計權限",
                    },
                    samplingRecords: {
                        title: "抽樣記錄",
                        index: {},
                        manualSampling: {
                            title: "抽樣",
                            fillterCondition: "篩選條件",
                            samplingRule: "抽樣規則",
                            agent: "客服",
                            type: "類型",
                            result: "結果",
                            evaluate: "評價",
                            relayNumber: "中繼號",
                            source: "來源",
                            task: "任務",
                            queueState: "排隊狀態",
                            callHanger: "通話掛斷方",
                            queueTime: "排隊耗時",
                            talkTime: "通話時長",
                            bellTime: "響鈴時長",
                            recordCountTipOne: "共計 ",
                            recordCountTipTwo: " 條記錄",
                            customerServices: "客服",
                            customerServicesGroup: "客服組",
                            surveyList: "評價列表",
                            customFields: "自定義字段",
                            fieldConfig: "字段配置",
                            defaultFields: "默認字段",
                        },
                    },
                    taskList: {
                        title: "任務列表",
                        index: {
                            batchDelete: "批量刪除",
                            batchSubmit: "批量提交",
                            batchConfirm: "批量確認",
                            itemFields: "評分項目字段",
                            categoryFields: "二級分類字段",
                            forbidsAndDeadlies: "禁止項和致命項",
                            scoreFieldList: "評分項字段列表",
                            customerName: "客戶名稱",
                            cellphone: "電話",
                            email: "郵箱",
                            organization: "公司",
                            description: "描述",
                            tags: "標簽",
                            taskUserRole: "當前任務用戶角色",
                        },
                        workBench: {
                            title: "記錄詳情",
                            checkForm: "質檢表單",
                            waitEvaluate: "待評價",
                            alreadyEvaluate: "已評價",
                            waitSubmit: "待提交",
                            alreadySubmit: "已提交",
                            waitConfirm: "待確認",
                            alreadyConfirm: "已確認",
                            waitReview: "待復核",
                            alreadyReview: "已復核",
                            alreadyDone: "已完成",
                            comment: "評價備註",
                            totalScore: "總分",
                            score: "分",
                            remark: "備註",
                            changeRecords: "變更記錄",
                            subject: "主題",
                            secondLevelScore: "二級評分",
                            deadlyList: "致命項",
                            forbidList: "禁止項",
                            addToCheckTask: "添加到質檢任務",
                            submitCheckRemark: "提交了質檢評價",
                            changeCheckRemark: "修改了質檢評價",
                            doneCheckRemark: "完成了質檢評價",
                            submitCheckReview: "提交了質檢復核",
                            submitCheckAppeal: "提交了質檢申訴",
                            saveFlowSuccess: "評價成功。",
                            saveFlowFailed: "評價失敗，請稍後重試。",
                            FormDetail: "表單詳情",
                            callDetail: "通話記錄詳情",
                            sampleCalllogDetail: "記錄詳情",
                            appealReasonTip: "請輸入申訴理由（必填）",
                            appeal: "申訴",
                            appealReason: "申訴理由",
                        },
                    },
                },
            },
            qualityInspectionForm: {
                formformat: "{0}質檢表單",
                basicInformationTitle: "基本信息",
                indicatorsProjectTitle: "指標項目",
                specialProjectTitle: "特殊項",
                totalScore: "總分",
                indicatorsName: "指標名稱",
                score: "分數",
                singleScore: "單項分",
                weight: "權重",
                maxDeductScore: "最多扣分",
                add: "增加",
                detail: "表單詳情",
                list: {
                    title: "質檢表單",
                    tipOne:
            "配置質檢表單是質檢工作的第一步，您可以結合公司的業務，配置最適合的質檢表單。",
                    tipTwo:
            "我們支持直接評分、權重評分、扣分製等多種表單。建議您設置好規則後，再添加到系統。",
                },
                basicInformation: {
                    formTemplate: "表單模板",
                    directScore: "直接評分",
                    weightedScore: "權重評分",
                    deductScore: "扣分",
                    directScoreSecondLevel: "直接評分-二級",
                    weightedScoreSecondLevel: "權重評分-二級",
                    deductScoreSecondLevel: "扣分-二級",
                    deductScoreImageFileName: "deduct-score.png",
                    weightedScoreImageFileName: "weighted-score.png",
                    directScoreImageFileName: "direct-score.png",
                    deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
                    weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
                    directScoreSecondLevelImageFileName: "direct-score-second-level.png",
                },
                specialProject: {
                    banProject: "禁止項",
                    banProjectTip:
            "可以用於在質檢評分的時候勾選，可以視作違規件，也可以視為備註的補充，不影響實際分數。",
                    deadlyProject: "致命項",
                    deadlyProjectTip:
            "致命項可以用於在質檢評分的時候勾選，如果勾選了致命項，則該條質檢任務直接評分0分",
                },
            },
            qualityInspectionStatisticalReports: {
                reports: {
                    title: "通話質檢",
                    entiretyReportTitle: "整體報表",
                    serviceGroupReportTitle: "客服組報表",
                    serviceReportTitle: "客服報表",
                    entiretyReport: {
                        pandect: "總覽",
                        contrast: "對比",
                        trend: "趨勢",
                        analyze: "分析",
                        totalScore: "總分",
                        banProject: "禁止項",
                        deadlyProject: "致命項",
                    },
                },
            },
            appealChekingTask: {
                list: {
                    title: "質檢結果列表",
                    taskSelectPlaceholder: "選擇質檢任務",
                    agentSelectPlaceholder: "選擇客服",
                    refresh: "刷新",
                    all: "全部",
                    unread: "未讀",
                    inComplaint: "申訴中",
                    reviewed: "已復核",
                    totalScore: "總評",
                    detail: "質檢詳情",
                    audioText: "錄音文本",
                    callInfo: "通話信息",
                    ticketInfo: "工单信息",
                    wechatInfo: "會話信息",
                    dialogText: "對話文本",
                    dialogInfo: "對話信息",
                    customer: "客戶",
                    agent: "客服",
                    none: "暫無質檢數據",
                    appealSuccess: "申訴成功！",
                    appealFailed: "申訴失敗，請稍後重試！",
                    pointListIsNull: "申訴質檢點不能為空！",
                    pleaseSelectPoint: "請選擇質檢點！",
                    unsupportedFormat: "暫不支持該格式",
                    shardingInformation: "以下為分片{0}",
                    smartTags: "智能標簽",
                    businessRecords: "業務記錄",
                    selectedPointsWarning:
            "請勾選要申訴的質檢項，分別為申訴項指定相關語句，然後點擊提交。",
                    applyReason: "申訴理由（最多50字）",
                    chatMessageTypes: {
                        all: "全部",
                        text: "文本",
                        image: "图片",
                        agree: "同意会话内容",
                        video: "视频",
                        voice: "语音",
                        file: "文件",
                        emotion: "表情",
                        link: "链接",
                        chatrecord: "聊天记录",
                        mixed: "混合消息",
                        default: "未支持消息",
                        deleteFriend: "删除好友",
                        redpacket: "红包",
                        card: "名片",
                        disAgree: "不同意会话内容",
                        weapp: "小程序",
                        externalRedpacket: "互通红包",
                        meetingVoicevCall: "通话消息",
                        uRL: "网页",
                        replyTimeout: "超时回复",
                        sphfeed: "视频号",
                        chatRecordTitle: "聊天记录",
                    },
                },
                components: {
                    qualityCheckScore: {
                        totalScore: "總分",
                        actualScore: "評分",
                        actualGrade: "評級",
                        appeal: "申訴",
                        remark: "人工備註",
                        none: "暫無質檢詳情數據",
                        pointEdit: "質檢點編輯分類",
                    },
                    changeList: { title: "變更記錄", user: "用戶", point: "質檢點" },
                    relatedRecord: {
                        title: "相關記錄",
                        qualityList: "質檢任務",
                        none: "無",
                        check: "查看",
                        loadMore: "加載更多",
                    },
                },
            },
            grade: {
                title: "質檢評級",
                subTitle:
          "质检评级用于质检打分完成后对质检对象进行分档评级，例如可配置低于60分为不合格，60-85分为良好，高于80分为优秀",
                list: {
                    up: "上移",
                    down: "下移",
                    del: "刪除",
                    case: "自動加入案例庫",
                    orderCase: "默認案例庫",
                    info: "默認評級無匹配條件，不可刪除，若不符合上述任何評級，則獲得默認評級",
                    add: "添加評級",
                    save: "保存",
                    close: "取消",
                    max: "質檢評級最多可配置20條",
                    name: "未命名",
                    success: "保存成功",
                },
            },
            qualityInspectionList: {
                title: "質檢列表",
                fastFilter: "質檢違規",
                fastFilterTip: "減分製命中為違規，加分製未命中為違規",
                titleWechat: "分析列表",
                titleCollect: "采集列表",
                status: "分析狀態",
                reQualityInspectionConfig: "重新質檢任務選項",
                pleaseSelectRecheckPoints: "請選擇需要重新核算的質檢點",
                beginReQualityInspection: "開始執行",
                cancelReQualityInspection: "取消任務",
                reQualityInspection: "重新質檢",
                reQualityInspectionWechat: "重新分析",
                reQualityInspectionUnderWay: "重新質檢任務中...",
                reQualityInspectionUnderWayTip: "關閉窗口後離開頁面不會影響任務進行",
                reQualityInspectionDone: "重新質檢已完成",
                reQualityInspectionDoneTip: "現已成功完成重新質檢，請點擊確認按鈕",
                endReQualityInspection: "終止任務",
                alreadyDoneTip: "已完成 {0} 條",
                totalTip: "合計 {0} 條",
                endReInspectionSuccess: "重新質檢任務已終止",
                closeWindow: "關閉窗口",
                endReInspectionFailed: "重新質檢任務失敗，請稍後重試!",
                detail: {
                    title: "質檢詳情",
                    wechatTitle: "分析詳情",
                    titleCollect: "采集詳情",
                    callIndex: "關聯通話",
                    textIndex: "關聯對話",
                    fileConfirm: "確認歸檔嗎？",
                    fileConfirmDesc: "歸檔後將無法編輯質檢得分，請確認是否歸檔",
                },
                sysCloumnsContal: "系統列字段管理",
                personCloumnsContal: "個人列字段管理",
            },
            spotCheckList: {
                title: "抽檢列表",
                onlyMyTip: "僅查看我的抽檢",
                plInspector: "請選擇抽檢員",
                allInspector: "查看全部",
                seInspector: "查看指定抽檢員",
                myInspector: "僅查看我的",
                noInspector: "查看未分配抽檢員",
                detail: { title: "抽檢詳情" },
            },
            reviewList: {
                title: "復核列表",
                onlyMyTip: "僅查看我的復核",
                plInspector: "請選擇復核員",
                allInspector: "查看全部",
                seInspector: "查看指定復核員",
                myInspector: "僅查看我的",
                noInspector: "查看未分配復核員",
                detail: { title: "復核詳情" },
            },
            caseBaseList: {
                title: "案例庫",
                tip: "质检列表中典型的通话和对话数据，可以保存至案例库方便学习和分享",
                del: "刪除",
                delTitle: "刪除後所有內容不會被保存",
                cancel: "取消",
                actions: "操作",
                seeCaseBase: "查看案例",
                seeDetail: "查看詳情",
                edit: "編輯",
                save: "保存",
                addCaseBase: "新增案例庫",
                export: "導出",
                ok: "確定",
                detail: {
                    choiceCaseBase: "請選擇案例",
                    delCaseBase: "刪除案例",
                    addCaseBase: "添加案例",
                    successed: "保存成功",
                    info: "請先保存或取消正在編輯的內容",
                    caseType: "請選擇要加入的案例庫",
                },
            },
            testSetList: {
                title: "質檢測試集",
                tip: "质检员抽检的数据会保存在质检测试集中，方便针对抽检后比对出来的一些机检错误项去优化质检条件和质检规则的配置",
                refresh: "刷新",
                reQualityInspectionUnderWay: "重新評估任務中...",
                reQualityInspectionUnderWayTip: "關閉窗口後離開頁面不會影響任務進行",
                reQualityInspectionDone: "重新評估已完成",
                reQualityInspectionDoneTip: "現已成功完成重新評估，請點擊確認按鈕",
                endReQualityInspection: "終止任務",
                alreadyDoneTip: "已完成 {0} 條",
                totalTip: "合計 {0} 條",
                endReInspectionSuccess: "重新評估任務已終止",
                closeWindow: "關閉窗口",
                endReInspectionFailed: "重新評估任務失敗，請稍後重試!",
                recheckPoint: "重新核算質檢點分數與準確率",
                recheckPointTitle: "重新評估選項",
                delete: {
                    reQualityInspectionUnderWay: "批量刪除任務中...",
                    reQualityInspectionUnderWayTip: "關閉窗口後離開頁面不會影響任務進行",
                    reQualityInspectionDone: "批量刪除任務已完成",
                    reQualityInspectionDoneTip: "現已成功完成批量刪除，請點擊確認按鈕",
                    endReQualityInspection: "終止任務",
                    alreadyDoneTip: "已完成 {0} 條",
                    totalTip: "合計 {0} 條",
                    endReInspectionSuccess: "批量刪除任務已終止",
                    closeWindow: "關閉窗口",
                    endReInspectionFailed: "批量刪除任務失敗，請稍後重試!",
                },
                reultList: {
                    assessmentTime: "上次評估時間",
                    assessmentAll: "合計評估",
                    strip: "條",
                    spot: "點",
                    deviation: "偏差",
                    accuracyRate: "準確率",
                    info: "無評估結果，請評估",
                    onlyDeviationInfo: "僅查看偏差記錄",
                    againAssessment: "重新評估",
                    screen: "篩選",
                    batchDelete: "批量移除",
                    matching: "匹配",
                    ok: "是",
                    noCancel: "否",
                },
            },
            smartWordsLibrary: {
                title: "智能詞庫",
                tip1: "同义词：用于降低语义标签的训练量，同义词的相似句无需重复训练",
                tip2: "专业名词：针对识别不准确的企业名称、产品名、行业词汇等专有名词进行转写优化、自然语言理解优化，提升识别准确率、分析准确率",
                tip3: "关注词：对词云分析中想关注的词进行关注，帮助AI分析热点符合当前业务",
                tip4: "屏蔽词：对词云分析中不想关注的词进行屏蔽，帮助AI分析热点符合当前业务",
                tip5: "词权重：用于对语义标签的识别进行纠错优化，通过修改词在句中的影响权重，根据需要优化特定场景下的语义识别效果",
                tip6: "语料数据：录入针对各自业务场景的大段落文本，用于转写纠错和ASR训练，提高ASR转写准确率",
                upload: {
                    uploadSynonymWords: "導入同義詞",
                    uploadProfessionalWords: "導入專業名詞",
                    uploadAttentionWords: "導入關註詞",
                    uploadIgnoreWords: "導入忽略詞",
                    uploadWordsWeight: "導入權重詞",
                    uploadCorpusData: "導入語料數據",
                    uploadTypo: "導入錯別字詞集",
                    uploadCorpusContent: "導入語料數據內容",
                    firstUploadStep: "1.下載模板,按模板格式填寫",
                    downloadTemplateMessage: "下載模板:",
                    firstUploadJSONButton: "JSON",
                    firstUploadExcelButton: "EXCEl",
                    firstUploadTxtButton: "TXT",
                    secondUploadStep: "2.點擊導入按鈕,上傳符合模板格式的EXCEl文件",
                    secondUploadCorpusDataStep:
            "2.點擊導入按鈕,上傳符合模板格式的TXT文件",
                    postUploadDataFail: "提交上傳數據失敗",
                    pleaseUploadTemplate: "請上傳EXCEl/JSON/TXT文件",
                    templateNeedJSONOrXLSX: "文件模板只能為JSON或xlsx格式",
                    templateNeedTxt: "語料數據文件模板只能為TXT文件",
                    uploadSuccess: "導入成功",
                    uploadFailed: "導入失敗",
                    downloadError: "下載失敗",
                    downLoadTxtTitle: "語料內容.txt",
                },
                navTabNames: {
                    synonymWords: "同義詞",
                    professionalWords: "專業名詞",
                    attentionWords: "關註詞",
                    ignoreWords: "忽略詞",
                    wordsWeight: "詞權重",
                    corpusData: "語料數據",
                    typoLibrary: "錯別字詞集",
                },
                synonymWords: {
                    createSynonymWords: "新建同義詞",
                    editSynonymWords: "編輯同義詞",
                    addSynonymWords: "添加同義詞",
                    pleaseEnterStandardWords: "請輸入標準詞",
                    synonymWordsExists: "該同義詞已存在,無法添加",
                    synonymWordsCanNotNull: "添加的同義詞不能為空",
                    pleaseAddSynonymWords: "請添加同義詞",
                    addSuccess: "新增成功",
                    editSuccess: "修改成功",
                    deleteSuccess: "刪除成功",
                    deleteError: "刪除失敗",
                    required: "*",
                },
                professionalWords: {
                    create: "新建專業名詞",
                    edit: "專業名詞編輯",
                    addTargetWords: "添加替換詞",
                    targetWordsExists: "該替換詞已存在,無法添加",
                    targetWordsCanNotNull: "添加的替換詞不能為空",
                    pleaseAddTargetWords: "請添加替換詞",
                    pleaseEnter: "請輸入專業名詞",
                    wordsExists: "該專業名詞已存在",
                    createSuccess: "添加成功",
                    editSuccess: "修改成功",
                    deleteSuccess: "刪除成功",
                    deleteError: "刪除失敗",
                    saveError: "保存失敗",
                    addTargetWordsMaxNumberError: "替換詞最多可以添加100個",
                    addHomophonyWordsMaxNumberError: "屏蔽詞最多可以添加100個",
                },
                attentionWords: {
                    create: "新建關註詞",
                    edit: "關註詞編輯",
                    pleaseEnter: "請輸入關註詞",
                    wordsExists: "該關註詞已存在",
                    createSuccess: "添加成功",
                    editSuccess: "修改成功",
                    deleteSuccess: "刪除成功",
                    deleteError: "刪除失敗",
                },
                ignoreWords: {
                    create: "新建忽略詞",
                    edit: "忽略詞編輯",
                    pleaseEnter: "請輸入忽略詞",
                    wordsExists: "該忽略詞已存在",
                    createSuccess: "添加成功",
                    editSuccess: "修改成功",
                    deleteSuccess: "刪除成功",
                    deleteError: "刪除失敗",
                },
                wordsWeight: {
                    createWordsWeight: "新建權重詞",
                    editWordsWeight: "編輯權重詞",
                    addWordsWeight: "添加權重詞",
                    pleaseEnterWeightWords: "請輸入權重詞",
                    pleaseEnterWeight: "請輸入權重",
                    weightNeedInZeroToHundred: "權重需要在0~100之間",
                    synonymWordsExists: "該權重詞已存在",
                    weightNeedNumber: "權重需要整數格式",
                    standardWeightInformation: "基準權重為50,權重越大在分析中影響力越強",
                    createSuccess: "添加成功",
                    editSuccess: "修改成功",
                    deleteSuccess: "刪除成功",
                    deleteError: "刪除失敗",
                },
                corpusData: {
                    createCorpusData: "新建語料數據",
                    editCorpusData: "編輯語料數據",
                    addCorpusData: "添加語料數據",
                    pleaseEnterCorpusDescription: "請輸入語料描述",
                    pleaseEnterCorpusContent: "請輸入語料內容",
                    synonymWordsExists: "該語料數據已存在",
                    CorpusContentWordsOverSetting: "內容字數統計超過20000字,",
                    download: "下載",
                    upload: "上傳",
                    see: "查看",
                    canNotSave: "請使用導入功能,否則無法保存",
                    createSuccess: "添加成功",
                    editSuccess: "修改成功",
                    deleteSuccess: "刪除成功",
                    deleteError: "刪除失敗",
                    required: "*",
                },
                typoLibrary: {
                    fields: {
                        rightWord: "正確詞",
                        wrongWord: "錯詞",
                        wrongWordCount: "錯詞個數",
                        wrongWordTip: "按下回車鍵添加",
                        status: "有效性",
                    },
                    create: "新建錯別字詞集",
                    edit: "編輯錯別字詞集",
                    tip: "錯別字詞集用於優化「錯別字檢測」的效果，例如」退款」誤發送為「退寬」，則可以通過此功能設置常見的錯別字以及正確的字，註意保存後次日生效。",
                    onlyChinese: "只支持輸入中文詞語",
                    wrongWordLengthErr: "每個錯詞長度需在2~10個字",
                },
            },
            semanticTags: {
                title: "語義標簽",
                current: "当前语义标签",
                reference1: "质检任务: {0} - {1}",
                reference2: "智能标签: {0}",
                reference3: "数据预处理: {0}",
                reference4: "知识库: {0}",
                index: { import: "導入語義標簽" },
                detail: {
                    new: "新建語義標簽",
                    edit: "編輯語義標簽",
                    name: "名稱",
                    standardSentences: "標準句",
                    effectiveness: "有效性",
                    similarSentences: "相似句",
                    tip: "支持使用##調用實體API，示例：您的手機號是#sys_phone#嗎？",
                    excludeSimilarSentences: "排除相似句",
                    intelligentRecommend: "智能推薦相似內容",
                    fastTraining: "快速訓練: ",
                    qualify: "正则",
                    recallKeyWord: "召回關鍵詞",
                    excludeKeywords: "排除关键词",
                    excludeKeywordsInfo1:
            "排除关键词、正则优先于所有逻辑生效，若命中排除则不会命中该语义标签",
                    keyWordInfo1:
            "召回關鍵詞每行支持填寫多個，用英文逗號分隔，填寫多個時邏輯為全部命中。",
                    keyWordInfo2:
            "召回關鍵詞支持填寫多行，填寫多行時邏輯為命中任意均可成立。",
                    recallRegular: "召回正則",
                    excludeRegular: "排除正则",
                    similaSentences: "排除相似句",
                    simim: "相似度",
                    keyWordInfo3:
            "排除相似句優先於所有邏輯生效，若命中排除相似句則不會命中該語義標簽，默認相似度80%，相似度越大越相似。",
                    keyWordInfo4:
            "規則類似正則，默認存在開始和結束。非必須配合中括號使用，例如[非XXXX]",
                    keyWordInfo5:
            "高級模塊中的功能通常是為熟悉操作的專家準備的實驗功能，如果您並不能確定您應如何操作，建議您不要改動此部分。",
                    keyWordInfo6: "跨句檢測功能賦予了語義標簽跨越句子進行檢測的能力。",
                    keyWordInfo7: "最多跨越同一角色連續的",
                    keyWordInfo8: "忽略不同角色句長不大於",
                    keyWordInfo9: "個字的句子進行跨句檢測",
                    keyWordInfo10: "最長跨句極限句長不大於",
                    keyWordInfo11: "去除相似百分比不小於",
                    keyWordInfo12: "相似度不低於",
                    keyWordInfo13: "%的相似句會被相似匹配（默認相似度80%）",
                    keyWordInfo14:
            "相似去重會依據句子的相似度進行匹配，建議去除過於相似的句子以提升檢測效率。",
                    keyWord: "關鍵詞",
                    entity: "實體",
                    ruleField: "概念詞",
                    customRule: "話術規則設計",
                    fanhua: "相似问",
                    test: "測試",
                    testOk: "命中測試",
                    senior: "高级设置",
                    seniorList: "跨句檢測",
                    up: "啟用",
                    down: "關閉",
                    ju: "句",
                    oneWord: "個字",
                    simimNo: "相似去重",
                    seniorField: "相似度閾值",
                    save: "保存",
                    cancel: "取消",
                    specialElements: "特殊要素",
                    logicRule: "邏輯規則",
                    every: "多次匹配",
                    recallOk: "命中召回詞：",
                    recallNo: "未命中召回詞",
                    ruleOk: "命中召回正則",
                    ruleNo: "未命中正則",
                    rularOk: "命中規則",
                    rularNo: "未命中規則",
                    infoOk: "命中標準句或相似句：",
                    infoNo: "未命中標準句或相似句",
                    fieldOk: "命中排除相似句：",
                    fieldNo: "未命中排除相似句",
                    excludeRuleNo: "未命中排除正则",
                    excludeRuleOk: "命中排除正则",
                    excludeKeywordNo: "未命中排除关键词",
                    excludeKeywordOk: "命中排除关键词",
                    update: "更新成功",
                    fanhuaList: "已提交到泛化頁簽相似句列表",
                    wordList: "訓練列表為空，無法提交到泛化列表",
                    start: "開始",
                    end: "結束",
                    keyWordInfo15: "泛化列表中存在相似字段（高級配置相似去重約束）",
                    saveSuccess: "保存成功",
                    errInfo: "有字段未填寫或填寫不正確",
                },
                table: { deepTraining: "深度訓練{0}輪", fastTraining: "快速訓練" },
            },
            intelligentCollection: {
                title: "智能采集",
                classification: "分組列表",
                new: "新建采集",
                table: {
                    name: "字段名稱",
                    props: "客戶屬性",
                    type: "字段類型",
                    desc: "字段描述",
                },
                detail: {
                    isCustomerProps: "保存至客戶屬性",
                    customerPropsHelper:
            "保存後，采集的信息會更新至客戶信息。不保存，則不會更新至客戶信息",
                    name: "字段名稱",
                    type: "字段類型",
                    text: "文本",
                    desc: "字段描述",
                    descPlaceHolder: "描述該字段采集信息的用處",
                    condition: "條件",
                    logic: "規則",
                    method: "采集方式",
                    message: "信息內容",
                    range: "抽取範圍",
                    entity: "抽取實體",
                    base: "基本信息",
                    collect: "采集信息",
                },
            },
            intelligentTags: {
                title: "智能標簽",
                sessionLabelTitle: "會話場景",
                keyEventsTitle: "关键事件",
                customerLabelTitle: "客戶標簽",
                addClassification: "新建一個標簽分類",
                defaultCategoryName: "默認分類",
                index: {
                    createTag: "新建標簽",
                    createEvent: "新建事件",
                    editTagClassification: "編輯標簽分類",
                    selectTagClassification: "選擇標簽分類",
                    editTagGrounp: "編輯分組",
                    callTimeError:
            "選擇的通話時間/對話開始時間範圍不能超過一個月（31天）",
                    callRecordCountError: "記錄條數為0，請重新選擇通話/對話時間",
                    selectItmeCountError: "請選擇需要執行批量操作的智能標簽",
                    redoSuccess: "重新執行成功,請到任務中心中查看",
                    redoError: "重新執行失敗，請稍後重試",
                    timeError: "查詢時間不能超過一個月（31天）",
                    categoryName: "分類名稱",
                    categoryColor: "分類顏色",
                    newTagClassification: "新建標簽分類",
                    newEventClassification: "新建分类",
                    tagClassification: "標簽分類",
                    eventClassification: "事件分类",
                },
                detail: {
                    new: "新建智能標簽",
                    edit: "編輯智能標簽",
                    tagName: "名稱",
                    tagDesc: "說明",
                    category: "分類",
                    tagStatus: "有效性",
                    operator: "算子",
                    rule: "規則",
                    labelClassAtion: "標簽類型",
                    labelClassAtionTip1: "标注标签：基于规则进行标签标记",
                    labelClassAtionTip2: "分类标签：基于模型训练进行标签标记",
                    group: "分組",
                    checkLabel: "選擇分類",
                    warn: "预警",
                    warnTips:
            "开启预警后，当会话满足此关键事件规则时，会提示到指定人员，可在待办事项处收到提醒",
                    warnError: "请选择预警指定人员",
                },
                subTitle: { common: "智能会话标签", wecom: "企微标签" },
            },
            customerPortrait: {
                title: "客戶畫像",
                index: { import: "導入客戶畫像" },
                searchPlaceHolder: "客戶ID、昵稱或電話",
                callCount: "通對話數:",
                callDuration: "總通話時長:",
                dialogCount: "總對話條數:",
                keyWordsStatistics: "關鍵詞統計（Top 20 & 關註詞）",
                keyWordsCount: "（{0}）",
                businessTag: "業務標簽",
                keyWordsCloudImg: "關鍵詞雲圖",
                customerEmotionTrend: "客戶情緒變化趨勢",
                notFoundCustomer: "未檢索到相關客戶",
                noKeyWords: "未查詢到相關關鍵詞",
                noBusinessTags: "未查詢到相關業務標簽",
                getKeyWordsStatisticsDataError: "客戶關鍵詞統計查詢失敗",
                getBusinessTagsStatisticsDataError: "客戶業務標簽查詢失敗",
                personCustome: "未查詢客戶信息",
                notimeInfo: "未查詢到情緒信息",
                timeList: "日期範圍",
                operator: "智能標簽",
                placeHolder: "請選擇",
                placeOperator: "請選擇智能標簽",
                search: "查詢",
                reset: "重置",
                searchHolder: "搜索",
                call: "通話",
                text: "對話",
            },
            sentimentAnalysis: { title: "輿情發現" },
            saleSentimentAnalysis: { title: "舆情分析" },
            dataPreprocess: {
                title: "數據預處理",
                index: {
                    createData: "新建數據",
                    selectDataSource: "選擇數據源",
                    selectStatus: "選擇有效性",
                    callTimeError:
            "選擇的通話時間/對話開始時間範圍不能超過一個月（31天）",
                    callRecordCountError: "記錄條數為0，請重新選擇通話/對話時間",
                    selectItmeCountError: "請選擇需要執行批量操作的數據",
                    redoSuccess: "重新執行成功,請到任務中心中查看",
                    redoError: "重新執行失敗，請稍後重試",
                },
                detail: {
                    new: "新建數據",
                    edit: "編輯數據",
                    name: "名稱",
                    desc: "說明",
                    dataSource: "數據源",
                    status: "有效性",
                    entity: "抽取實體",
                    operator: "算子",
                    rule: "規則",
                },
            },
            connectedByConversation: {
                title: "通對話關聯",
                list: {
                    title: "通對話關聯功能",
                    info: "通對話關聯功能可按照預設規則，將不同的通話與對話關聯起來",
                    rule: "通對話關聯規則",
                    source: "請選擇數據源",
                    classification: "請選擇分類",
                    choose: "請選擇",
                    input: "請輸入",
                    save: "保存",
                    cancel: "取消",
                    fieldwei: "位",
                    fieldNot: "有未填寫字段",
                    fiedldlengthNo: "字段長度超過限製",
                },
            },
            components: {
                operatorListGather: {
                    conditionTitle: "CK",
                    keywordTip: "關鍵詞可設置多個，使用逗號分隔",
                    applyRole: "檢測角色",
                    preOperator: "前置算子",
                    applyOperatorScope: "檢測範圍",
                    addOperator: "添加算子",
                    noPreOperator: "無",
                    from: "第",
                    to: "句至第",
                    end: "句",
                    testRegular: "測試正則表達式",
                    test: "測試",
                    matchSuccess: "匹配成功",
                    matchError: "匹配失敗",
                    querySementicTags: "選擇語義標簽",
                    reQuerySementicTags: "重新選擇語義標簽",
                    sementicTag: "語義標簽",
                    standardSentence: "標準句",
                    similarity: "相似度",
                    actions: "操作",
                    noFitTag: "沒有找到合適的標簽，",
                    addSemanticTag: "添加一個新的語義標簽",
                    pleaseEnterRegular: "請輸入正則表達式",
                    pleaseEnterRegularText: "請輸入需要驗證的內容",
                    newAssociateComponentTip: "如果值為數字類型，請使用舊版功能",
                },
                filterManage: {
                    noValueTip: "暫無數據",
                    allCall: "全部通話",
                    allDialogue: "全部對話",
                    allWechat: "全部會話",
                    getFiltersListFailed: "獲取過濾器列表失敗，請稍後重試！",
                    getFilterDetailFailed: "獲取過濾器詳情失敗，請稍後重試！",
                    getAllCountFailed: "獲取通話/對話總條數失敗，請稍後重試！",
                },
                listManage: {
                    searchPlaceHolder: "請輸入關鍵字搜索",
                    filterConditions: "篩選條件",
                    records: "記錄",
                    getRecordsFailed: "獲取記錄失敗，請稍後重試！",
                },
                miniListManage: { getRecordsFailed: "獲取記錄失敗，請稍後重試！" },
                applyTemplates: {
                    title: "適用質檢模板",
                    collectionTitle: "適用采集模板",
                },
                recordDetail: {
                    showWaveAudio: "顯示錄音",
                    getDetailFailed: "獲取詳情失敗，請稍後重試！",
                    getSmartTagsFailed: "獲取智能標簽失敗，請稍後重試！",
                    getTemplatesFailed: "獲取適用模板失敗，請稍後重試！",
                },
                changeList: {
                    sampleStatus: "抽檢狀態",
                    sampleAssignInspector: "分配抽檢員",
                    reviewAssignInspector: "分配復核員",
                    read: "已讀",
                    distributivePerson: "分配人",
                    totalScore: "總分",
                },
                qualityCheckScore: {
                    totalScore: "總分",
                    actualScore: "得分",
                    actualGrade: "評級",
                    appeal: "申訴",
                    remark: "備註",
                    manualRemark: "人工備註",
                    none: "暫無質檢詳情數據",
                    interactiveTip: "使用交互識別對客服FAQ的使用情況進行檢測",
                    interactiveIsEmptyTip: "未檢測到交互識別內容",
                },
                semanticMark: {
                    semanticContent: "標註句",
                    markedSimilarTitel: "已標相似語義標簽",
                    markedExcludeTitle: "已標排除相似語義標簽",
                    taskTagTitle: "任務相關語義標簽",
                    similarTagTitle: "識別相似語義標簽（相似度>40%，Top 20）",
                    delete: "刪除",
                    markSimilar: "標記相似",
                    markExclude: "排除相似",
                    none: "無",
                },
                selectCallTime: {
                    callTime: "通話時間/會話開始時間",
                    callTimeRemind:
            "您最多可以選擇最近一年內單次不超過一個月（31天）的通對話記錄",
                    callRecordCount: "您已選擇通話/對話記錄共計{0}條",
                    getCallRecordError: "獲取記錄失敗，請稍後重試",
                },
            },
            informationEntities: {
                title: "信息實體",
                index: { import: "導入信息實體" },
                detail: {
                    new: "新建信息實體",
                    edit: "編輯信息實體",
                    name: "名稱",
                    apiName: "API名稱",
                    activeFlag: "有效性",
                    remark: "描述",
                    logicExp: "實體正則",
                    sentenceList: "實體內容",
                    tip: "實體內容支持輸入多個，以空格分隔，為同一實體的同義詞條",
                    remarkLengthError: "描述长度不能超过255",
                    nameLengthError: "名称长度不能超过40",
                    entityDuplicateError: "实体内容重复",
                },
            },
            interactiveRecognition: {
                title: "交互識別",
                classify: "分類",
                addClassify: "添加分類",
                editClassify: "編輯分類",
                index: { import: "導入交互識別" },
                detail: {
                    new: "新建智能交互",
                    edit: "編輯智能交互",
                    type: "類型",
                    standardQuestion: "標準問題",
                    similarQList: "相似問題",
                    exceptSimilarQList: "排除相似問題",
                    category: "分類",
                    targetType: "問題檢測目標",
                    status: "有效性",
                    standardAnswer: "標準答案",
                    similarAList: "相似答案",
                    wrongAList: "錯誤答案",
                },
            },
            knowledgeBase: {
                title: "知识库",
                list: {
                    add: "添加子分类",
                    delete: "删除分类",
                    categoryName: "分类名称",
                    createSuccess: "子分类创建成功",
                    keyWord: "关键词",
                },
                detail: {
                    type: "类型",
                    status: "状态",
                    classification: "分类",
                    standardQuestion: "标准问题",
                    answerDetectionRange: "答案检测范围",
                    standardAnswer: "标准答案",
                },
            },
            businessAnalysis: {
                title: "業務分析",
                tip1: "业务趋势分析：纵坐标为通对话数，即满足条件的通对话共计多少条，横坐标为时间",
                tip2: "相关性分析：分析多个指标的关联情况，鼠标悬浮显示相关性数据情况,数据量,相关性,重复率",
                tip3: "转化分析：分析多指标间可形成相互的转化关系，转化的判定标准为同一条通话/对话，若能命中两个不同的指标，则可认为这两个指标存在转化关系",
            },
            callManage: {
                title: "通話錄音篩選器",
                listTitle: "通話管理",
                detailTitle: "通話詳情",
            },
            dialogueManage: {
                title: "對話文本篩選器",
                listTitle: "對話管理",
                detailTitle: "對話詳情",
            },
            weChatManage: {
                title: "企業微信會話篩選器",
                listTitle: "企業微信會話管理",
                detailTitle: "企業微信會話詳情",
            },
            speechRecognition: {
                title: "語音識別",
                asr: "語音識別",
                asrRemind: "關閉此開關會導致語音轉文本功能以及機器質檢功能失效",
                asrConfig: "ASR過濾",
                imAsrConfig: "IMASR",
                imAsrConfigRemind: "啟用後，會執行IM的ASR轉寫",
                asrConfigRemind: "啟用後，僅符合條件的通話會執行ASR內容",
                correction: "智能糾錯",
                correctionRemind:
          "啟用後，會使用「<a href='/site/semantic-intelligence/smart-words-library/professional-words' target='_blank'>智能詞庫-專業名詞</a>」中的內容進行強製糾錯轉換",
                correctionConfig: "糾錯過濾",
                advancedSettings: "高级设置",
                advancedSettingsTip: "为指定数据选择指定的ASR模型。",
                mandarinASRModel: "普通话ASR模型",
                cantoneseASRModel: "粤语ASR模型",
                englishASRModel: "英语ASR模型",
                correctionConfigRemind: "啟用後，僅符合下述條件的通話會進行糾錯",
                customJudgeLogicIsNull: "自定義邏輯不能為空",
                redoCorrection: "重新執行糾錯",
                callTime: "選擇通話時間",
                callTimeRemind:
          "您最多可以選擇最近一年內單次不超過一個月（31天）的通話記錄",
                callRecordCount: "您已選擇通話記錄共計{0}條",
                redoCorrectionSubmit: "提交執行",
                callTimeError: "選擇的通話時間範圍不能超過一個月（31天）",
                callRecordCountError: "通話記錄條數為0，請重新選擇通話時間",
                redoCorrectionSuccess: "重新糾錯成功,請到任務中心中查看",
                redoCorrectionError: "重新糾錯失敗，請稍後重試",
            },
            role: {
                title: "角色管理",
                create: "新建角色",
                detail: {
                    title: "角色",
                    new: "新建角色",
                    view: "查看角色",
                    edit: "編輯角色",
                    navTabNames: {
                        basicInfo: "菜單權限",
                        taskPermissions: "質檢任務權限",
                        dataPermissions: "數據權限",
                    },
                    basicInfo: { roleName: "名稱", permissions: "權限" },
                    taskPermissions: {
                        allTask: "全部質檢任務",
                        myCreatedTask: "我創建的質檢任務",
                    },
                    dataPermissions: {
                        dataPermissionsConfig: "數據權限配置",
                        tips: "該角色能夠看見所選分類下的數據",
                        select: "選擇分類",
                        selectPlaceholder: "請選擇需要數據可見權限的分類",
                    },
                },
            },
            dataMigration: {
                title: "數據遷移",
                dataUpload: "數據遷入",
                dataDowload: "數據遷出",
                error: "請選擇要導出的質檢任務",
                list: {
                    info: "請選擇要遷出的質檢任務",
                    listInfo:
            "請選擇是否要遷出質檢任務的相關數據（例如智能詞庫、語義標簽、信息實體、智能交互等）",
                    noInfo: "請選擇是否要遷出其他非質檢數據",
                    ownInfo: "僅遷出任務",
                    everyInfo: "遷出任務相關",
                    allInfo: "遷出所有",
                    callFilter: "通話過濾器",
                    talkFilter: "對話過濾器",
                    smartTag: "智能標簽",
                },
                upload: {
                    info: "1.點擊上傳按鈕，上傳符合格式的JSON文件",
                    uploadSuccess: "上傳成功",
                    uploadError: "上傳失敗",
                },
            },
            dataAnalysis: {
                businessStatistics: "業務統計",
                qualityEffect: "質檢健康度",
                qualityAnalysis: "質檢分析",
                generalInspecitionReport: "通用報告",
                fullAmountWork: "質檢員工作量",
                intelligentTagsAnalysis: "標簽分析",
                saleIntelligentTagsAnalysis: "智能标签分析",
                generalInspecitionReportTip:
          "数据总量：统计当前限制的时间和任务范围内，通话和对话数据总量，其中智能质检数据数量&百分比，人工抽检数据数量&百分比，申诉量，抽复检率以及时间趋势图",
                groupTest: "分組測試",
            },
            uploadDownloadList: {
                title: "任務中心",
                tabs: {
                    upload: "上傳導入",
                    download: "下載導出",
                    taskCenter: "任務執行",
                },
            },
            uploadDownloadModel: {
                title: "上傳下載",
                list: {
                    clear: "清空",
                    success: "成功",
                    fail: "失敗",
                    hand: "進行中",
                    noInfo: "沒有數據",
                },
            },
            billingCenter: {
                title: "計費中心",
                export: "導出",
                limit: "提醒限額",
                tips: "計費結果會因四舍五入有所偏差，僅供參考",
                dashboard: {
                    remainingQuota: "當前剩余配額",
                    usedQuota: "累計已用配額",
                    voiceQuality: "語音質檢：",
                    voice: "語音：",
                    textQuality: "文本質檢：",
                    text: "文本：",
                    hour: "小時",
                    tenThousandHour: "萬小時",
                    day: "天",
                    cutoff: "截止",
                    warning: "（剩余配額不足，請盡快充值）",
                    isClosed: "（關閉狀態）",
                },
                table: {
                    quotaRecord: "配額記錄",
                    timeFilter: "選擇日期：",
                    search: "查詢",
                    reset: "重置",
                    id: "流水號",
                    type: "類型",
                    qualityType: "質檢類型",
                    quotaChange: "變動",
                    surplus: "余額",
                    changeTime: "時間",
                    voiceQuality: "語音質檢",
                    textQuality: "文本質檢",
                    sizeChangeText: "每頁顯示",
                    used: "使用",
                    give: "授予",
                    deduction: "扣除",
                    callTime: "通話時間",
                    count: "處理數據量",
                    page: "共{0}條，{1}頁",
                },
                limitModal: {
                    call: "語音質檢配額低於",
                    callFix: "小時進行提醒",
                    dialogue: "文本質檢剩余時間小於",
                    dialogueFix: "天進行提醒",
                },
            },
            messageCenter: {
                replyTitle: "回复我的",
                answerTitle: "@我的",
                shareTitle: "分享我的",
            },
            columnsManage: { title: "質檢字段管理" },
            recordUpload: {
                list: {
                    task: "質檢任務",
                    user: "操作人",
                    uploadTime: "上傳時間",
                    endTime: "結束時間",
                    total: "上傳總量",
                    success: "質檢成功",
                    failed: "質檢失敗",
                    progress: "質檢進度",
                    inProgress: "進行中",
                    finished: "已完成",
                },
                steps: {
                    uploading: "上傳錄音文件",
                    baseInfo: "填寫基本信息",
                    start: "開始質檢",
                },
                uploadComponent: {
                    dragger: "拖拽添加文件",
                    or: "或",
                    selectFile: "選擇文件",
                    des: "支持wav、mp3格式，采樣率為8000Hz，16位的文件",
                    limit1: "一次最多上傳",
                    limit2: "個文件，每個文件大小不超過",
                    uploading: "上傳中",
                    finished: "上傳完成",
                    success: "成功",
                    failure: "失敗",
                },
                baseInfo: {
                    task: "選擇質檢任務",
                    type: "選擇錄音形式",
                    agentSide: "客服所在通道",
                    excel: "上傳隨路數據",
                    checkFileInfo: "校驗文件信息",
                    excelFileError: "請上傳隨路數據",
                    checking: "校驗中...",
                    reCheck: "重新校驗",
                    success: "校驗成功",
                    excelUoloadFail: "隨路數據上傳失敗",
                    tips: {
                        tip1: "1.ecxel中錄音文件名稱需要與上傳的錄音名稱一致，且不能重名",
                        tip2: "2.系統以錄音文件名稱、通話ID查重，重復會導致質檢失敗",
                        tip3: "3.下載的excel錄音模版，字體顏色為紅色的是必填字段",
                        tip4: "4.時間類型的字段格式為:yyyy-mm-dd hh:mm:ss，例如2021-04-20 17:36:30",
                    },
                },
                title: "本地錄音上傳",
                checkTitle: "錄音質檢",
                start: "開始質檢",
                disabledTips: "請先校驗錄音文件再進行質檢",
                tips: "同名錄音文件覆蓋質檢結果和基本信息",
                tips1: "將文件拖拽到此，或點擊上傳",
                tips2: "支持",
                tips3: "格式",
                type: "wav、mp3",
                tips4: "一次最多上傳",
                tips5: "個文件，每個文件大小不超過",
                tipsOverSize: "當前隊列已滿，請先移除隊列中的文件再上傳",
                size: "500M",
                count: "20",
                uploaded: "已上傳錄音文件",
                uploading: "（{0}%）",
                uploadFinished: "（上傳完成）",
                bottomTitle: "錄音信息采集：",
                recordType: "選擇錄音形式：",
                double: "雙通道通話錄音",
                single: "單通道通話錄音",
                recordBaseInfo: "上傳錄音基本信息：",
                addBtn: "添加文件",
                downloadTemplate: "模板下載.excel",
                checkRecord: "校驗錄音信息",
                checking: "校驗中...",
                reCheck: "重新校驗",
                checkAll: "全選",
                cancelCheckAll: "取消全選",
                delete: "刪除",
                deleteLotSize: "批量刪除",
                agentChannel: "客服所在通道：",
                left: "左通道",
                right: "右通道",
                donotKonw: "不知道",
                mission: "選擇質檢任務：",
                uploadFail: "上傳失敗",
                repeat: "重復文件",
                overCount: "文件數量超出限製",
                overSize: "文件大小超出限製",
                uoloadFail: "上傳至雲端失敗",
                formatError: "格式錯誤",
                seccess: "校驗成功",
                fail: "校驗失敗",
                recordChecking: "錄音質檢中，請稍後...",
                recordCheckSuccess: "錄音質檢完成",
                recordCheckFail: "錄音質檢失敗，請檢查錄音是否重復或無效",
                backToIndex: "返回上傳列表",
                recordCheckIsStarted:
          "當前有質檢任務正在執行，請在當前任務結束後，開始新的任務",
            },
            customerServicePortrait: {
                title: "客服畫像",
                tip1: "句长分布律：句长的分布情况,按照句长字数,分段进行统计,只计算客服的",
                tip2: "语速分布律：语速的分布情况,按照语速(字/每分钟),分段进行统计,只计算客服的",
                tip3: "响应时间分布律：响应时间的分布情况,只计算客服响应客户的情况",
                tip4: "质检合规：质检点的合规率",
                tip5: "质检任务平均分：质检任务平均分",
                contol: {
                    date: "選擇日期：",
                    today: "今天",
                    thisMonth: "本月",
                    lastMonth: "上個月",
                    thisQuarter: "本季度",
                    lastQuarter: "上個季度",
                    thisYear: "今年",
                    lastYear: "去年",
                    customer: "客服：",
                    search: "查詢",
                    reset: "重置",
                    export: "導出",
                    indicator: "指標管理",
                    selectPlaceholder: "請選擇需要對比的客服",
                    warning: "最多選擇5名客服進行對比",
                    warning2: "請至少選擇1名客服進行查詢",
                    dataWarning: "最多只支持查詢從開始日期後366天的數據",
                    seccuss: "記錄導出成功",
                },
                echarts: { distributionLaw: "分布律", count: "次數", bout: "次" },
                portrait: {
                    ability: "客服能力維度",
                    option: "配置",
                    abilityOption: "配置能力維度",
                    placeholder: "輸入能力維度關鍵詞搜索",
                    save: "保存",
                    warning: "請至少選擇3項指標進行配置，指標數至多可選擇10項",
                },
                label: { title: "客服業務標簽" },
            },
            customerServiceRankList: {
                routerTitle: "RankList",
                title: "客服排名",
                select: {
                    date: "選擇日期：",
                    today: "今天",
                    thisMonth: "本月",
                    lastMonth: "上個月",
                    thisQuarter: "本季度",
                    lastQuarter: "上個季度",
                    thisYear: "今年",
                    lastYear: "去年",
                    group: "客服組：",
                    groupPlaceholder: "請選擇客服組",
                    customer: "客服：",
                    customerPlaceholder: "請選擇客服",
                    search: "查詢",
                    reset: "重置",
                    compared: "對比",
                    export: "導出",
                    column: "列",
                    warning: "最多選擇5名客服進行對比",
                    warning2: "請至少選擇一名客服",
                    dataWarning: "最多只支持查詢從開始日期後366天的數據",
                    selected: "已選客服：",
                    seccuss: "記錄導出成功",
                },
                compared: {
                    title: "選擇客服",
                    return: "取消",
                    compared: "對比",
                    tips: "請選擇2-5名客服進行對比",
                    warning: "僅支持2-5名客服進行對比",
                },
                transfer: { leftTitle: "列表項", rightTitle: "展示項" },
                table: { page: "共{0}條，{1}頁", actionTitle: "操作", action: "查看" },
            },
            conceptWord: {
                title: "概念詞",
                new: "新建概念詞",
                control: {
                    search: "搜索：",
                    searchPlaceholder: "名稱或關鍵字搜索",
                    commit: "查詢",
                    reset: "重置",
                    import: "導入",
                    export: "導出",
                    seccuss: "記錄導出成功",
                },
                modal: { title: "導入概念詞" },
                table: {
                    sizeChangeText: "每頁顯示",
                    page: "共{0}條，{1}頁",
                    edit: "編輯",
                    delete: "刪除",
                    request: "刪除後將不可恢復，是否確認刪除？",
                    cancel: "取消",
                    title: {
                        conceptWord: "概念詞",
                        describeWordsNum: "描述詞數",
                        updateTime: "更新時間",
                        status: "有效性",
                        effective: "有效",
                        invalid: "無效",
                        action: "操作",
                    },
                },
                detail: {
                    title: { new: "新建概念詞", edit: "編輯概念詞" },
                    name: "概念詞名稱",
                    namePlaceholder: "請輸入概念詞名稱",
                    effectiveness: "有效性",
                    effective: "有效",
                    invalid: "無效",
                    description: "描述詞 : ",
                    descriptionPlaceholder: "請輸入描述詞",
                    add: "添加",
                    repeat: "重復內容",
                    empty: "描述詞不能為空",
                    recommendEmpty: "暫無更多推薦描述詞，請手動輸入添加",
                    recommend: "智能推薦描述詞",
                    cancel: "取消",
                    change: "換一組",
                    descriptionSelected: "已添加描述詞 :",
                    save: "保存",
                    success: "概念詞保存成功",
                    warning: "概念詞名稱不能為空",
                    warning2: "請至少添加1個描述詞",
                    submitBtnHandle: {
                        title: "有勾選的描述詞未添加，保存將放棄未添加的描述詞",
                        content: "點擊確定將回到概念詞首頁，確認繼續嗎？",
                    },
                },
            },
            smartTools: {
                btn: "智能工具",
                title: {
                    similarWords: "相關詞發現",
                    search: "按詞索句",
                    semanticTags: "語義標簽",
                },
                sentenceSearch: {
                    placeholder: "請輸入關鍵詞查找，支持多個關鍵詞",
                    more: "查看全文",
                    pageUp: "上一頁",
                    pageDown: "下一頁",
                    total: "共 {0} 條記錄",
                },
                similarDiscover: {
                    placeholder: "請輸入關鍵詞",
                    copyed: "已復製",
                    none: "暫無相關詞語，請嘗試其他關鍵詞",
                },
                smartTags: {
                    searchPlaceHolder: "請輸入關鍵詞或句子",
                    createSmartTag: "新建",
                    createClusteringSmartTag: " 用 {0} 條數據新建",
                    addFromKeyword: "添加搜索內容至相似句",
                    hit: "命中",
                    similar: "相似",
                    name: "語義標簽名稱",
                    namePlaceHolder: "請輸入語義標簽名稱",
                    recallWords: "召回詞",
                    recallWordsPlaceHolder: "請輸入召回關鍵詞",
                    recallRegular: "召回正則",
                    recallRegularPlaceHolder: "請輸入召回正則",
                    rules: "規則",
                    standardSentences: "標準句",
                    standardSentencesPlaceHolder: "請輸入標準句",
                    similaSentences: "相似句",
                    similaSentencesPlaceHolder: "請輸入相似句",
                    notSaveError: "請先保存語義標簽，再進行查找",
                    add: "添加",
                    newPage: "在新頁面打開",
                    querryError: "泛化列表中存在相似字段（高級配置相似去重約束）",
                    detailTitle: "語義標簽詳情",
                },
            },
            trainingCenter: {
                title: "訓練中心",
                edit: "編輯",
                delete: "刪除",
                seleteAll: "全選",
                cancelCheckAll: "取消全選",
                add: "添加",
                remove: "移除",
                save: "保存",
                cancel: "取消",
                fastTraining: {
                    title: "快速訓練",
                    titleDes: "快速訓練旨在幫助語義標簽快速泛化到達20個相似句",
                    semanticTags: "語義標簽",
                    semanticTagsPlaceholder: "請輸入流程關鍵字搜索",
                    standardSentence: "標準句：{0}",
                    similarSentencesPlaceholder: "輸入相似句添加或搜索",
                    similarSentencesAdd: "添加",
                    similarSentencesSearch: "搜索",
                    similarSentences: "已添加相似句",
                    trainingCount: "訓練數：{0}",
                    similarSentencesRecommend: "推薦相似句",
                    noResp: "暫無相關語義標簽，請更換關鍵詞",
                    similarSentencesModalTitle: "相似句搜索",
                    moreInfo: "查看上下文",
                    moreInfoBtn: "上下文",
                    modalSearchType: { all: "全部", customer: "客服", client: "客戶" },
                    repetWarning: "已存在相同的相似句，請勿重復添加",
                    repetRemoveTip: "保存相似句中存在重復內容，已自動去重",
                },
                trainingHistory: {
                    title: "訓練歷史",
                    select: {
                        date: "選擇時間：",
                        today: "今天",
                        thisMonth: "本月",
                        lastMonth: "上個月",
                        thisQuarter: "本季度",
                        lastQuarter: "上個季度",
                        thisYear: "今年",
                        lastYear: "去年",
                        search: "查詢",
                        reset: "重置",
                    },
                    table: {
                        time: "時間",
                        user: "操作人",
                        tag: "語義標簽",
                        similarCount: "相似句標識數量",
                        deleteCount: "移除句數量",
                        page: "共{0}條，{1}頁",
                    },
                },
                classifiedTraining: {
                    title: "分類訓練",
                    tagsGrounp: "標簽分組",
                    placeHolder: "請選擇",
                    cellTime: "選擇時間",
                    search: "查詢",
                    reset: "重置",
                    classifiedFlag: "分類訓練情況",
                    num: "數值",
                    gas: "百分比",
                    noData: "未分類訓練",
                    noDataHeight: "未分類數據占比較高,急需進行訓練",
                    noDataSmall: "未分類數據占比較高,建議進行訓練",
                    noDataDays: "上次執行{0}天前",
                    training: "訓練",
                    start: "開始",
                    addClass: "增加分類",
                    custom: "高級篩選",
                    marked: "已標註數據勘誤",
                    markedHeight: "已經{0}天未進行勘誤,建議進行勘誤",
                    markedSmall: "已經{0}天未進行勘誤,急需進行勘誤",
                    mark: "勘誤",
                    dataCheck: "已分類數據校驗",
                    dataCheckSmall: "K-L散度高於0.5，建議訓練",
                    dataCheckHeight: "K-L散度高於0.8，急需訓練",
                    check: "校驗",
                    history: "標註歷史記錄",
                    historyTime: "每日標註與修改會在淩晨2:00自動執行模型訓練",
                    today: "今日標註",
                    article: "條",
                    none: "無",
                    historyCheck: "歷史合計標註",
                    look: "查看記錄",
                    historyluate: "歷史數據重算",
                    startluate: "發起重算",
                    classData: "分類數據",
                    classStatus: "訓練進度",
                    next: "下一條",
                    prev: "上一條",
                    finish: "完成訓練",
                    stop: "結束訓練",
                    classDetail: "訓練詳情",
                    trainingNone: "暫無可訓練的智能標簽",
                },
                classifiedTrainingHistory: {
                    title: "訓練歷史",
                    time: "選擇時間：",
                    tag: "標簽類型：",
                    user: "操作員：",
                    data: "選擇數據源：",
                    search: "查詢",
                    searchBar: "搜索：",
                    searchBarPlaceholder: "請輸入通話ID/對話 ID 搜索",
                    reset: "重置",
                    export: "導出",
                    all: "全部",
                    select: {
                        today: "今天",
                        thisMonth: "本月",
                        lastMonth: "上個月",
                        thisQuarter: "本季度",
                        lastQuarter: "上個季度",
                        thisYear: "今年",
                        lastYear: "去年",
                        search: "查詢",
                        reset: "重置",
                    },
                    table: {
                        user: "操作員",
                        date: "操作時間",
                        data: "數據源",
                        id: "通話ID/對話ID",
                        tagType: "智能標簽分組",
                        tagName: "標簽名稱",
                        state: "狀態",
                        sizeChangeText: "每頁顯示",
                        page: "共{0}條，{1}頁",
                        call: "通話",
                        dialogue: "對話",
                    },
                },
                clusteringAnnotation: {
                    title: "聚類標註",
                    left: { title: "聚類結果" },
                    right: {
                        title: "聚類句子",
                        checkAll: "全選本頁",
                        tagging: "標註",
                        detail: "查看",
                        meaningless: "忽略",
                        meaninglessTips: "已忽略的聚類結果後續將不再顯示，您確認要忽略嗎？",
                        cancel: "取消",
                    },
                },
            },
            clientCenter: {
                clientManage: { title: "客戶管理", tabTitle: "客服中心客户" },
                wechatClientManage: {
                    title: "企微客户",
                    table: { id: "客户ID", name: "客户昵称", tag: "客户标签" },
                },
            },
            collectionRule: {
                title: "采集規則",
                form: { info: "智能采集信息", infoPlaceholder: "請選擇分類" },
                table: {
                    name: "字段名稱",
                    props: "客戶屬性",
                    type: "字段類型",
                    desc: "字段描述",
                },
            },
            dataAdd: {
                title: "數據添加",
                types: { auto: "自動添加", manual: "手動添加" },
            },
            wechatStaff: {
                table: {
                    name: "姓名",
                    part: "所屬部門",
                    phone: "手機號",
                    email: "郵箱",
                    wechartId: "企業微信ID",
                    status: "在職狀態",
                },
                info: "請先選擇需要同步數據的部門",
                synSuccess: "同步成功",
                init: "初始化",
                syn: "同步",
            },
            appealHistory: {
                appealTime: "申訴時間",
                cancelTime: "撤銷時間",
                reviewTime: "復核時間",
                inspector: "質檢員",
                result: "申訴結果",
                complainResult: "一共申訴 {0} 個質檢項，成功申訴 {1} 個，駁回 {2} 個",
                reason: "申訴原因",
                rejection: "駁回原因",
                cancel: "撤銷",
                tip: "確認要撤銷申訴嗎？",
            },
            appealSdk: {
                time: "通對話時間",
                externalIdCall: "通話記錄ID",
                externalIdText: "對話記錄ID",
                customerNickName: "客戶昵稱",
                readStatus: "查閱狀態",
                status: "申訴狀態",
                isManual: "是否人工質檢",
                complainButtonInfo:
          "允許申訴 {0} 次，已申訴 {1} 次，可申訴剩余 {2} 次。{3} 前可申訴",
                submit: "提交申訴",
                complain: "申訴",
                allComplain: "申訴全部",
                pendingSubmit: "待提交",
                taskIds: "質檢任務",
                graded: "評分",
                complainSuccess: "申訴提交成功",
                appealSdkIndex: { title: "智能質檢結果" },
            },
        },
        lookupRecords: {
            getFiltersListError: "獲取過濾器列表信息失敗，請檢查網絡連接或稍後再試！",
            columns: "列",
            filter: "篩選",
            noSearchResult: "沒有找到記錄",
            pleaseSearch: "請選擇一個過濾器，並嘗試搜索",
            sift: "篩選",
            create: "新建",
            search: "搜索",
            createTaskTitle: "新建任務",
            title: "標題",
            deadline: "截止時間",
            principal: "負責人",
            enqueueTime: "入隊時間",
            computeError: "計算失敗，請稍後!",
            sum: "求和",
            max: "最大值",
            min: "最小值",
            avg: "平均值",
            count: "計數",
            maxDate: "最大值（最新）",
            minDate: "最小值（最舊）",
        },
        mainMenu: {
            insight: {
                name: "Insight",
                getInsightUrlError: "獲取Insight鏈接地址失敗，請稍後重試！",
            },
            template: {
                learningReminder: "學習提醒",
                subscriptionDynamics: "订阅动态",
            },
        },
        tasks: {
            subMenu: {
                manageHome: { text: "任務管理", chaneg: "切換" },
                qualityCheckTemplate: {
                    text: "質檢模板",
                    collectText: "采集模板",
                    condition: { text: "質檢條件" },
                    checkPoint: { text: "規則配置" },
                    automaticAdd: { text: "流程自動化" },
                    manualAdd: { text: "手動添加" },
                    roleConfig: { text: "角色配置" },
                    basicInfo: { text: "基本信息" },
                    smartWordsLibrary: { text: "智能詞庫" },
                    grade: { text: "質檢評級" },
                    collectionRule: { text: "采集規則" },
                    addData: { text: "添加數據" },
                    collectionList: { text: "采集列表" },
                },
                qualityCheckWorkbench: {
                    text: "質檢工作臺",
                    wechatText: "分析工作臺",
                    informationCollectText: "采集工作臺",
                    qualityInspectionList: {
                        text: "質檢列表",
                        wechatText: "分析列表",
                        informationCollectText: "采集列表",
                    },
                    condition: { text: "質檢列表(已廢棄)" },
                    reviewList: { text: "復核列表" },
                    spotCheckList: { text: "抽檢列表" },
                    caseBaseList: { text: "案例庫" },
                    testSetList: { text: "質檢測試集" },
                },
                statisticsAndReports: {
                    text: "統計與報表",
                    wholeReport: { text: "整體報表" },
                    groupReport: { text: "客服組報表" },
                    agentReport: { text: "客服報表" },
                    customerServiceExecution: { text: "客服分析" },
                    analysisOverview: { text: "分析概覽" },
                    inspectionItemsAnalysis: { text: "質檢項分析" },
                },
                wechatQa: {
                    text: "企業微信質檢",
                    task: { text: "分析任務" },
                    baseInfo: { text: "基本信息" },
                    checkPoint: { text: "分析模型配置" },
                    setting: { text: "企業微信分析配置" },
                    automaticAdd: { text: "流程自動化" },
                    manualAdd: { text: "手動添加" },
                },
                clientCenter: { text: "客服中心" },
            },
            conditionDictionaries: {
                title: "所有條件",
                searchDefaultText: "請輸入ID或名稱",
                id: "ID",
                name: "名稱",
                conditionDetailedShow: "展開",
                conditionDetailedHide: "收起",
                remark: "描述:",
                checkRole: "角色:",
                operator: "算子:",
                operatorLogic: "邏輯:",
            },
            audioTextView: {
                customer: "客戶",
                agent: "客服",
                unsupportedFormat: "暫不支持該格式",
            },
            checkPointClassifications: {
                add: "新增分類",
                name: "名稱",
                enterToSave: "按回車鍵保存",
                highestScore: "最高得分上限",
                minimumScore: "最低得分下限",
                defaultMinScore: "默認為0分",
                minimumScoreTitle: "得分下限",
                beginScore: "總體起評分值",
                beginScoreTitle: "起評分值",
                scoreSetTip: "最高得分上限小於各項分值總和，您可以選擇：",
                ignoreExcessSpillover: "忽略超額溢出",
                automaticWeightCalculation: "自動權重核算",
            },
        },
        operatorGather: {},
        fieldSelect: { field: "字段" },
        checkRange: {
            numberTipOne: "第",
            numberTipTwo: "句",
            numberTipThree: "句 到 第",
            conditionLimit: "條件限製",
            roleLimit: "角色限製",
            contextLimit: "句子限製",
            notZero: "句子限製不支持輸入0",
            startMustLessThanEnd: "起始句位置必須小於結束句位置",
        },
        waveAudio: {
            play: "播放",
            pause: "暫停",
            rateOne: "0.5",
            rateTwo: "1.0",
            rateThree: "1.5",
            rateFour: "2.0",
            rateFive: "1.2",
        },
        operatorListGather: {
            pleaseEnter: "請輸入",
            pleaseEnterRegular: "請輸入正則表達式",
            pleaseEnterGrammar: "請輸入文法規則",
            pleaseEnterTestText: "請輸入需要驗證的內容",
            pleaseEnterLoudness: "請輸入響度分貝",
            pleaseEnterRangeAbility: "請輸入變化幅度",
            afterHit: "命中後檢查",
            pleaseEnterContext: "請輸入參考語句",
            wordNumber: "字數差距",
            intervalSecond: "時間間隔大於",
            minute: "每分鐘",
            wordUnit: "個字",
            test: "測試",
            testRegular: "測試正則表達式",
            testGrammar: "測試文法",
            testGrammarError: "文法測試失敗",
            regularError: "正則表達式錯誤，請重新輸入",
            pleaseEnterSemanticSentence: "請輸入需要進行語義檢測的句子",
            pleaseEnterAssociate: "請參考右側說明，以構建隨路數據表達式",
            querySemanticTags: "選擇語義標簽",
            reQuerySemanticTags: "重新選擇語義標簽",
            semanticTags: "語義標簽",
            standardSentence: "標準句",
            semanticName: "語義標簽",
            sentence: "標準句",
            sUnit: "秒",
            similarity: "相似度",
            actions: "操作",
            tipOne: "沒找到合適的標簽，",
            tipTwo: "添加一個新的語義標簽",
            notLessThan: "不小於",
            notGreaterThan: "不大於",
            ignoreLessThan: "忽略小於",
            wordUnitSet: "字的句子",
            speedWordNotNull: "語速檢測最少字數和最多字數不能都為空",
            speedWordMinValueLessThanMaxValue: "語速檢測最少字數不能大於最多字數",
            speechGrabbingIntervalTip: "搶話間隔 >= ",
            speechLengthTip: "搶話句長 >= ",
            msUnit: "ms",
            grabIntervalNotNull: "搶話間隔不能為空",
            grabLengthNotNull: "搶話句長不能為空",
            pleaseSelectEntity: "請選擇信息實體",
            pleaseSelectOperator: "請選擇操作",
            pleaseSelectBasicInfo: "請選擇基礎信息",
            pleaseEnterFixedValue: "請輸入固定值",
            loudnessUnit: "db",
            percent: "%",
            matchSuccess: "匹配成功",
            matchError: "匹配失敗",
            mindetectionStatement: "最小檢測語句",
            conceptWordholder: "請選擇概念詞",
            elementHolder: "請輸入一個要素",
            associateMedol: { tabs: { data: "數據", operator: "操作符" } },
            repet: "重復",
            bout: "次",
            ignore: "忽略小於",
            ignoreAffix: "字的句子",
            associateData: "隨路數據",
            pretreatmentData: "預處理數據",
            reviewTaskData: "復核任務數據",
            taskData: "質檢任務數據",
            businessData: "業務記錄",
            functionOperator: "函數操作符",
            operation: "運算操作符",
            notAnyMatch: "未命中任何問題時：",
            times: "出現次數",
            newAssociateDataTip: "如果值為數字類型，請使用舊版功能",
        },
        conditionGather: {
            cycle: "周期",
            rlue: "規則",
            delete: "移除",
            sampling: "抽取",
            item: "條",
            percent: "%",
            samplingSame: "每客服抽取數量相同",
            samplingRecheck: "允許重復抽取已抽檢條目",
            dateUnit: "號",
            samplingTip:
        "不同規則覆蓋相同通話不會重復抽取，已抽取未檢查的記錄不會被再次抽取。",
            add: "新增規則",
        },
        ruleGather: {
            title: "規則",
            screeningCondition: "篩選條件",
            addRule: "新增規則",
            inspector: "質檢員",
            ruleTip:
        "規則優先的分配模式，從上向下依次分配，不重復分配，規則內或不匹配任意規則項的將隨機分配",
            moveUp: "上移",
            moveDown: "下移",
            top: "置頂",
        },
        pushRulesGather: {
            title: "規則",
            delete: "移除",
            newPush: "新增推送",
            newRule: "新增規則",
            sampling: "抽取",
            item: "條",
            percent: "%",
            samplingSame: "每客服抽取數量相同",
            dateUnit: "號",
            screeningCondition: "篩選條件",
            email: "郵箱",
            emailPlaceholder: "輸入郵箱，用逗號分割",
            updateType: "更新類型",
            pushDetail: "推送詳情",
            pushUrl: "推送URL",
            pushWay: "推送類型",
            pushMethod: "推送方式",
            pushContentType: "數據格式",
            pushData: "推送數據",
            additionalData: "附加數據",
            inspectionType: "質檢類型",
            all: "全部",
        },
        taskAdditionalData: {
            newData: "新增數據",
            delete: "移除",
            placeholder: "請輸入名稱",
            dataPlaceholder: "請輸入內容",
        },
        UdeskQaReact: { loadSdkError: "SDK加載失敗，請稍後重試!" },
        gearOptionList: { evaluation: "評價" },
        tableComponentTransfer: { leftTitle: "列表項", rightTitle: "展示項" },
        kmTinymce: {
            link: "插入/編輯鏈接",
            modalTitle: "插入鏈接",
            href: "地址",
            text: "顯示文字",
            title: "標題",
            target: "打開方式",
            none: "無",
            newWindow: "在新窗口打開",
            buttonStyle: "按鈕樣式",
            close: "關閉",
            open: "開啟",
            answerMessage: "跳轉/發送消息",
            answerMessageTitle: "發送消息按鈕",
            answerMessageText: "顯示文字",
            answerMessageContent: "消息內容",
            answerMessageButtonStyle: "按鈕樣式",
            answerMessageTips: "註：發送消息按鈕僅支持web渠道，請謹慎配置",
            answerSwitchStaff: "跳轉/轉人工",
            answerSwitchStaffTitle: "轉人工按鈕",
            answerSwitchStaffText: "顯示文字",
            answerSwitchStaffRule: "按鈕規則",
            answerSwitchStaffButtonStyle: "按鈕樣式",
            answerSwitchStaffTips: "註：轉人工按鈕僅支持web渠道，請謹慎配置",
            textButton: "文字按鈕",
            borderButton: "帶框按鈕",
            message: "發送消息",
            switchStaff: "轉人工",
            messageError: "按鈕內容不能超過10個字",
            messageIsEmptyError: "按鈕內容不能為空",
            okText: "確定",
            cancelText: "取消",
            video: "視頻",
            uploadVideo: "上傳視頻",
            uploadText: "僅支持MP4格式，大小不超過20M",
            videoEmptyOrUploading: "視頻為空或上傳中",
            customEmoji: "表情",
        },
        massageConfiguration: {
            pushTarget: "通知目標",
            customizePushTarget: "自定義推送目標",
            sysUser: "當前系統用戶",
            sysRole: "當前系統角色",
            emailPushTarget: "發送目標",
            targetEmailsPlaceholder: "請輸入郵箱，並使用英文逗號分隔",
            subject: "郵件名稱",
            SDKID: "SDK識別ID",
            content: "通知內容",
            sdkPushType: "推送方案",
            sdkPushURL: "推送URL",
            pushData: "推送數據",
            sdkPushWay: "推送方式",
            dataType: "數據格式",
            additionalData: "附加數據",
            name: "消息名稱",
            filter: "篩選條件",
            triggerType: "觸發機製",
            pushCycle: "定時定頻",
            pushContent: "報告內容",
            qualityList: "質檢結果列表",
            designated: "指定人員",
            messageType: "消息類型",
        },
        taskCard: {
            template: { cancelCollection: "取消收藏", collection: "收藏" },
        },
        semanticTagsSelect: { index: { standardSentence: "标准句:" } },
        gong: {
            filesOpen: {
                folderItem: {
                    collection: "收藏",
                    calls: "个通话",
                    createdBy: "创建人：",
                    createdOn: "创建时间：",
                },
                fileItem: {
                    uIProvidesIcon: "ui提供icon",
                    minute: "分钟",
                    callClip: "通话片段",
                    start: "开始",
                    End: "结束",
                    employees: "员工：",
                    Customer: "客户：",
                    sessionTime: "会话时间：",
                    reasonForRecommendation: "推荐理由：",
                    addedBy: "添加者：",
                    addTime: "添加时间：",
                },
                modalAddFile: {
                    caseTitle: "案例標題",
                    addSomeClipsToTheCaseLibrary: "添加部分片段至案例库",
                    addToCaseBase: "添加到案例库",
                    storedIn: "存储于",
                    pleaseSelect: "请选择",
                    reasonsForRecommendation: "推荐理由",
                    pleaseEnter: "请输入",
                },
            },
            shareModal: {
                index: {
                    customer: "客户：",
                    employees: "员工：",
                    timeliness: "时效性：",
                    recordingLinkValidWithinDays: "天内录音链接有效",
                    sharingInstructions: "分享说明：",
                    shareSuccess: "分享成功！",
                    cancel: "取消",
                    share: "分享",
                    copySucceeded: "复制成功！",
                    copyShareLink: "复制分享链接",
                    shareWithColleagues: "分享给同事",
                    shareWithCustomers: "分享给客户",
                    staff: "员工",
                    selectColleagues: "选择同事",
                    pleaseSelectColleaguesToShare: "请选择要分享的同事",
                    pleaseEnterTheSharingDescription: "请输入分享说明",
                },
            },
            waveAudio: {
                index: {
                    theFileCannotBeFound: "无法找到该文件！",
                    sale: "销售：",
                    customer: "客户：",
                    topicDetection: "话题检测：",
                    keyEvents: "关键事件：",
                },
            },
            helpCommentModal: {
                index: {
                    selectColleagues: "选择同事",
                    helpContent: "求助内容",
                    visibleRange: "可见范围",
                    seekCounseling: "寻求辅导",
                },
            },
            commentDispalyItem: {
                index: { seekAdvice: "寻求建议", concatText: "向" },
            },
            customerInsight: {
                index: { customerInsight: "客戶洞察", customerLabel: "客戶標籤" },
            },
            intelligentRecommend: {
                index: {
                    scriptRecommendation: "話術推薦",
                    objectionHandlingScripts: "抗告處理話術",
                    productSellingPointScripts: "產品賣點話術",
                    knowledgePointRecommendation: "知識點推薦",
                    relatedKnowledgePoints: "關聯知識點",
                    recommendedCases: "推薦案例",
                },
            },
            supervision: {
                index: {
                    scriptRecommendation: "話術推薦",
                    caseRecommendation: "案例推薦",
                    hit: "命中",
                    miss: "未命中",
                    supervisionItem: "監督項",
                    implementation: "執行情况",
                    reInspection: "重新質檢",
                    supervisionItemSettings: "監督項設定",
                },
            },
            supervisionClassificationModal: {
                index: {
                    telephoneCall: "電話通話",
                    enterpriseWeChatCall: "企微通話",
                    enterpriseMicroConversation: "企微會話",
                    open: "開啟",
                    close: "關閉",
                    conversationScenario: "會話場景",
                    monitorConversationChannels: "監督會話通路",
                    permanentlyValid: "永久有效",
                    permanentlyInvalid: "永久無效",
                    customValidity: "自定義有效期",
                    termOfValidity: "有效期",
                    newRating: "新建評級",
                    whenConversation: "當會話",
                    hit: "命中",
                    miss: "未命中",
                    time: "時",
                    rated: "評級為",
                    qualified: "合格",
                    unqualified: "不合格",
                    modifiedSuccessfully: "修改成功",
                    classificationName: "分類名稱",
                    supervisionObject: "監督對象",
                    entryintoforceTime: "生效時間",
                    supervisionItemRating: "監督項評級",
                    pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "請檢查監督項評級是否正確",
                    ratingRules: "評級規則",
                    settingOfRatingRulesForSupervisionItems: "監督項評級規則設定",
                },
            },
            reInspect: {
                index: {
                    keyEvents: "關鍵事件",
                    supervisionItem: "監督項",
                    conversationScenario: "會話場景",
                    customerLabel: "客戶標籤",
                    reDetectionRules: "重新檢測規則",
                },
            },
        },
        imgUpload: {
            index: {
                uploadPictures: "上传图片",
                pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "请选择大小不超过2048kb的图片",
            },
        },
        chatRecord: {
            chatRecordItem: {
                components: {
                    debugInfo: {
                        or: "或",
                        and: "和",
                        wrong: "非",
                        matchTo: "匹配到",
                        rule: "規則",
                        satisfactionOperator: "滿足運算元",
                        customerLabel: "客戶標籤",
                        keyEvents: "關鍵事件",
                        knowledgePoints: "知識點",
                        sessionLabel: "會話標籤",
                        supervisionItem: "監督項",
                    },
                    linkExplorer: { linkInTheText: "文中連結：" },
                },
            },
        },
        createForm: { field: { customComponentNotFound: "未找到自定義組件：" } },
        sdkLoaderBase: {
            component: { custom: "自定義" },
            dropdownMonit: {
                updateSucceeded: "更新成功",
                areYouSureToDelete: "確定删除嗎？",
                custom: "自定義",
            },
            modalMonit: {
                saveCurrentReportConfigurationForm: "保存當前報表配寘===表單",
                saveCurrentReportConfiguration: "保存當前報表配寘",
                pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "請輸入常用設定名稱，最大長度為20！",
                preservation: "保存",
            },
            template: {
                saveCurrentReportConfiguration: "保存當前報表配寘",
                updateCurrentReportConfiguration: "更新當前報表配寘",
            },
        },
        audioCallBasicInfoV2: {
            template: {
                workOrderInformation: "工單資訊",
                workOrder: "工單",
                triggerScenario: "觸發場景",
                associatedCallConversation: "關聯通話/對話",
            },
        },
        audioCallContentInfoV2: {
            components: {
                testBtn: {
                    testWithCurrentCommunicationDialogueText: "以當前通/對話文字進行測試",
                },
            },
        },
        checkPointOperatorListGather: { template: { section: "第", day: "天" } },
        massagePushConfiguration: {
            component: {
                iAmMeItsADifferentFireworksxxxx8827:
          "我就是我，是不一樣的烟火@156xxxx8827",
                hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hi，我是機器人質檢預警消息通知，由於10月17日添加到群",
            },
            interfacePush: {
                component: {
                    iAmMeItsADifferentFireworksxxxx8827:
            "我就是我，是不一樣的烟火@156xxxx8827",
                    hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hi，我是機器人質檢預警消息通知，由於10月17日添加到群",
                },
            },
        },
        productMatrix: {
            index: { clickToLearnMore: "點擊瞭解更多" },
            schema: {
                textRobot: "文字機器人",
                trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "訓練師護航、檔案問答、任務式對話、表格問答、圖譜問答",
                capitalRetentionRobot: "留資機器人",
                richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "豐富的追問留資策略，高擬人度溝通，保障高留資率、線索高效自動轉化",
                outboundRobot: "外呼機器人",
                workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "工單完結回訪、服務滿意度調研、運維故障警告提示、訂單發貨提醒、電商活動通知、候選人預約，助力業務自動化、業務效率提升",
                incomingRobot: "呼入機器人",
                flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "靈活集成工單、訂單等業務系統，實現流程自動化； 語音語義模型定制化識別，業務效果立竿見影； 實體（手機號、訂單金額、訂單時間、地址、……）識別，快速支持複雜業務",
                intelligentQualityInspection: "智慧質檢",
                fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "全量質檢，及時高效，準確率85%",
                intelligentSessionAnalysis: "智慧會話分析",
                insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "洞察業務短板，聆聽客戶心聲，提升行銷轉化",
                enterpriseWechatConversationAnalysis: "企業微信會話分析",
                effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "高效識別潜在商機、風險，行銷轉化率提升20%",
                assistant: "坐席助手",
                theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "坐席助手能保障新手客服完成服務，提升熟手客服通話品質。 讓每一個坐席都是您的王牌",
                digitalPeople: "數字人",
                FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          "“面對面”擬人化交流溝通，支持聲音、文字、視頻、觸控多模態互動，實現沉浸式交流體驗，提升品牌形象及使用者服務體驗",
                aIKnowledgeZhongtaiEnterpriseSearch: "AI知識中台·企業搜索",
                knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "知識蒐索，知識共享，檔案蒐索，資料庫對接蒐索",
                aIKnowledgeCenterKCsKnowledgeBase: "AI知識中台·KCS知識庫",
                provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "提供知識的全生命週期管理，機器人和IM知識庫統一維護，支持對接協力廠商知識庫，知識範本、原子知識等强大功能帶來更加便捷的知識管理體驗",
                aIKnowledgeCenterKnowledgeMap: "AI知識中台·知識圖譜",
                oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "一站式圖譜構建融合企業知識，大圖展示知識細節，對接能力打造定制化體驗",
                modularDevelopmentPlatformOriginalEngineASR:
          "模組化開發平臺·原心引擎·ASR",
                theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "採用端到端算灋，實現業務定制化的效果體驗，自然場景識別率超過90%，個性化詞彙識別超過97%",
                modularDevelopmentPlatformIntelligentMarketing:
          "模組化開發平臺·智慧行銷",
                makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "利用深度學習算灋，結合用戶畫像，實現商品的精准推薦，提高銷售轉化",
                modularDevelopmentPlatformUserPortrait: "模組化開發平臺·用戶畫像",
                accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "根據用戶數據和對話數據精准構建用戶畫像，充分描述客戶特徵",
                modularDevelopmentPlatformIntelligentRecommendation:
          "模組化開發平臺·智慧推薦",
                automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "根據用戶畫像和產品畫像，自動給用戶推薦可能感興趣的產品，實現千人千面的個性化推薦",
                modularDevelopmentPlatformNLPPAAS: "模組化開發平臺·NLP PaaS",
                provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "提供高準確率的NLP基礎能力，涵蓋詞、句子、文章等不同細微性的底層能力",
            },
        },
        qualityScore: {
            index: {
                clickCopy: "點擊複製",
                hitDetailsOfOperatorsInRules: "規則內運算元命中詳情：",
                hit: "命中",
                miss: "未命中",
                filter: "篩選：",
                clickToViewDetails: "點擊查看詳細資訊",
            },
            components: {
                flowNodeList: {
                    branch: "分",
                    pleaseSelect: "請選擇",
                    viewTheCompleteProcess: "查看完整流程",
                    thereIsCurrentlyNoProcessAvailable: "暫無流程",
                },
                pointItem: { xiangHit: "項命中" },
                flowNodeListModal: { index: { completeProcess: "完整流程" } },
            },
        },
        recordDetail: {
            template: {
                theDataHasBeenClearedPeriodically: "該數據已被定期清除",
                subject: "主題：",
                description: "描述：",
            },
            components: {
                addToTask: { index: { addToQualityInspectionTask: "添加至質檢任務" } },
            },
        },
        relatedDetail: { index: { associatedCallConversation: "關聯通話/對話" } },
        samplingTarget: {
            index: {
                time: "時",
                samplingQuantityIsLessThan: "抽檢數量低於",
                alertWhen: "時進行預警",
                everyday: "每日",
                weekly: "每週",
                monthly: "每月",
                samplingInspectionTargetSavedSuccessfully: "抽檢目標保存成功",
                personnel: "人員",
                eachCustomerService: "每位客服",
                cycle: "週期",
                samplingInspectionTarget: "抽檢目標",
                strip: "條",
                alertSettings: "預警設定",
            },
        },
        samplingTargetBanner: {
            index: {
                customerService: "客服",
                complete: "完成",
                Objective: "/目標",
                everyday: "每日",
                weekly: "每週",
                monthly: "每月",
            },
        },
        sessionInsights: {
            components: {
                numberOfPreemptiveCalls: "搶話次數",
                numberOfMessages: "消息條數",
                strip: "條",
                proportionOfMessages: "消息條數占比",
                speechPercentage: "說話占比",
                longestCustomerTalk: "最長客戶發言",
                numberOfIndepthDiscussions: "深入討論次數",
                second: "次",
                maximumDurationOfContinuousSpeech: "最長持續說話時長",
                questionFrequency: "提問頻次",
                customer: "客戶",
                keyEvents: "關鍵事件：",
            },
            index: {
                conversationSummary: "會話小結",
                cancel: "取消",
                determine: "確定",
                edit: "編輯",
                establish: "創建",
                conversationalInsights: "會話洞察",
                keyEvents: "關鍵事件",
                numKeyEvents: "個關鍵事件",
                questionAnalysis: "提問分析",
                find: "發現",
                questions: "次提問",
                conversationAction: "會話動作",
                customer: "客戶",
                sale: "銷售",
                sessionLabel: "會話標籤",
                employeeQuestions: "員工提問",
                customerQuestions: "客戶提問",
            },
        },
        ticketTextInfo: {
            index: {
                customer: "客戶",
                customerService: "客服",
                replyTo: "回復至",
                addressee: "收件人：",
            },
        },
        listManage: {
            template: {
                reIdentification: "重新識別",
                rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "重新識別會消耗轉寫時長，請謹慎操作",
                determine: "確定",
                executionSuccessful: "執行成功",
                addToQualityInspectionTask: "添加至質檢任務",
                batchOperation: "批量操作",
            },
        },
        addToTaskModal: {
            index: {
                successfullyAdded: "添加成功",
                addToQualityInspectionTask: "添加至質檢任務",
                qualityInspectionTask: "質檢任務",
            },
        },
        customFilterModal: {
            component: {
                detectRoles: "檢測角色",
                detectionRange: "檢測範圍",
                sentence: "句",
            },
        },
        inspectionDetail: {
            template: { theDataHasBeenClearedPeriodically: "該數據已被定期清除" },
        },
        backToSubSystem: { index: { returnToBusinessSystem: "返回業務系統" } },
        qualityCheckScoreTalk: { template: { keyWord: "關鍵字" } },
        coach: {
            customerPortrait: {
                detail: {
                    index: {
                        portraitName: "畫像名稱",
                        backgroundDescription: "背景描述",
                        customerLabel: "客戶標籤",
                        labelManagement: "標籤管理",
                    },
                },
                list: {
                    components: {
                        addCustomerPortraitItemBtn: { index: { newlyBuild: "新建" } },
                    },
                },
            },
            detailedInquiryConfig: {
                components: {
                    detailedInquiryConfigTitle: {
                        index: { questioningSettings: "追問設定" },
                    },
                    detailedInquiryList: {
                        index: {
                            increaseQuestioning: "新增追問",
                            remove: "移除",
                            addQuestioning: "添加追問",
                            section: "第",
                            secondInquiry: "次追問",
                            questioningScore: "追問得分",
                        },
                    },
                },
                index: {
                    accordingToRules: "按規則",
                    byScore: "按得分",
                    whenMissRule: "當未命中規則",
                    whenConductingQuestioning: "時，進行追問",
                    pleaseSelectARule: "請選擇規則",
                    ruleCannotBeEmpty: "規則不能為空",
                    currentScriptScore: "當話術得分≤",
                    pleaseEnterTheScore: "請輸入得分",
                    scoreCannotBeEmpty: "得分不能為空",
                },
            },
            dialogue: {
                components: {
                    narrator: { narrator: "旁白：" },
                    pPT: { slide: "幻燈片" },
                    realTimeAnalyzer: {
                        index: {
                            script: "話術",
                            semantics: "語義",
                            violatingWords: "違規詞",
                            speechSpeed: "語速",
                            emotion: "情緒",
                        },
                    },
                    scoreDetail: {
                        index: {
                            appealToBeSubmitted: "申訴待提交",
                            ratingHasBeenReviewed: "評分已覆核",
                            ratingAppealInProgress: "評分申訴中",
                            expressionScore: "表達得分",
                            moodParticlesTimes: "語氣詞，{0}次",
                            changeTheModalParticlesTo: "語氣詞改為",
                            second: "次",
                            interrogation: "追問",
                            questioningWithoutScoring: "追問不計分",
                            branch: "分",
                            matchingRate: "匹配率",
                            ratingAppeal: "評分申訴",
                            changeTo: "改為",
                            wordPerMinute: "字每分鐘",
                            completeScript: "話術完整",
                            incompleteScript: "話術不完整",
                            semanticHit: "語義命中",
                            semanticMisses: "語義未命中",
                            scriptViolation: "話術違規-",
                            modified: "已修改",
                            notChanged: "未修改",
                            keyWord: "關鍵字",
                            semanticLabels: "語義標籤",
                            semanticMatchingRate: "語義匹配率",
                            violatingWords: "違規詞“",
                            delete: "删除",
                            unmatched: "未匹配",
                            matching: "匹配",
                            matchTo: "匹配到",
                            notMatchedTo: "未匹配到",
                            scriptScore: "話術得分",
                            standardScript: "標準話術",
                            emotionalScore: "情感得分",
                            speechSpeedScore: "語速得分",
                            viewResolution: "查看解析",
                            nodeConfiguration: "節點配寘",
                        },
                    },
                    userNode: {
                        theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "本題作答時間超時，自動提交作答內容",
                    },
                },
            },
            scoringRules: {
                components: {
                    drawerTitle: {
                        scoringRules: "得分規則",
                        cancel: "取消",
                        determine: "確定",
                    },
                    scoringRuleItem: {
                        whole: "全部",
                        arbitrarily: "任意",
                        pleaseEnterARuleName: "請輸入規則名稱",
                        standardScript: "標準話術",
                        if: "如果",
                        and: "且",
                        or: "或",
                        thenScore: "則得分",
                        pleaseEnterAScore: "請輸入分值",
                        bonusPoints: "加分",
                        minusPoints: "减分",
                        hit: "命中",
                        misses: "未命中",
                        keyWord: "關鍵字",
                        semantics: "語義",
                    },
                    title: {
                        scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "得分規則（當前話術總得分為",
                        points: "分）",
                        editRules: "編輯規則",
                    },
                },
            },
            userTagsSelect: { index: { noLabelsCurrentlyAvailable: "暫無標籤" } },
        },
        smartTags: {
            template: {
                areYouSureYouWantToReExecuteTheSmartLabel: "確定要重新執行智慧標籤嗎？",
                reExecute: "重新執行",
            },
        },
        reInspectionResultModal: {
            index: {
                reQualityInspectionTask: "重新質檢任務",
                requalityInspection: "重新質檢",
                waiting: "等待中",
                viewDetails: "查看詳情",
            },
        },
        checkPointConditionListGather: {
            checkPointCondition: {
                components: {
                    applyTimeType: { index: { detectionTime: "檢測時間：" } },
                },
            },
        },
    },
    fields: {
        qualityInspectionForm: {
            list: {
                name: "名称",
                description: "描述",
                type: "类型",
                status: "状态",
                actions: "操作",
            },
        },
        qualityCheckingTask: {
            index: {
                name: "任务名称",
                description: "描述",
                form: "质检表单",
                actions: "操作",
                createUser: "创建人",
            },
            samplingRecords: {
                createTime: "时间",
                type: "类型",
                operateUser: "操作人",
                number: "添加数量",
                filterConditionList: "筛选条件",
                sampleRuleStr: "抽样条件",
                timeGreaterThan: "时间大于",
                timeLessThan: "时间小于",
            },
        },
        customFilters: {
            createdAt: "通话时间",
            customerId: "客户",
            agentId: "客服",
            userGroupId: "客服组",
            state: "状态",
            lastEvaluateUserId: "最后评分人",
            submitTime: "最后提交时间",
            totalScore: "总分",
            formItemForbidIdList: "禁止项",
            formItemDeadlyIdList: "致命项",
        },
        samplingInspection: {
            callTime: "通话时间",
            dialogTime: "对话时间",
            originator: "分配人",
            createTime: "创建时间",
            updateTime: "更新时间",
            status: "流程状态",
            score: "分数",
            actions: "操作",
            reviewer: "复核员",
            samplingOfficer: "抽检员",
            id: "通话记录ID",
            time: "通话时间",
            phone: "客户电话",
            staff: "客服",
            inspectionStatus: "质检状态",
            samplingStatus: "抽检状态",
            inspectionGrade: "评级",
        },
        smartWordsLibrary: {
            synonymWords: {
                standardWords: "标准词",
                synonymWords: "同义词",
                synonymWordsNumber: "同义词数",
                useFrequency: "使用频率",
                effectiveness: "有效性",
                operation: "操作",
            },
            common: {
                useFrequency: "使用频率",
                effectiveness: "有效性",
                operation: "操作",
            },
            professionalWords: {
                professionalWords: "专业名词",
                useFrequency: "使用统计",
                homophonic: "热词（同音替换）",
                target: "名词替换",
                targetWords: "替换词",
                homophonyWords: "同音屏蔽词",
                operation: "操作",
            },
            attentionWords: "关注词",
            ignoreWords: "忽略词",
            wordsWeight: {
                selfDefineWeightWords: "自定义权重词",
                weightWords: "权重词",
                weight: "权重",
                useFrequency: "使用频率",
                effectiveness: "有效性",
                operation: "操作",
            },
            corpusData: {
                corpusDescription: "语料描述",
                updateTime: "更新时间",
                wordsCount: "词数",
                effectiveness: "有效性",
                operation: "操作",
                corpusContent: "内容",
            },
        },
        semanticTags: {
            title: "语义标签",
            tags: "标准句",
            orgName: "所属组织",
            tagCount: "训练数",
            usingCount: "引用数",
            activeFlag: "有效性",
            suggestion: "训练建议",
            actions: "操作",
        },
        intelligentTags: {
            tagName: "名称",
            tagDesc: "说明",
            tagRefCnt: "标记数",
            categoryName: "标签分类",
            eventName: "事件分类",
            tagStatus: "有效性",
            actions: "操作",
            tagType: "标签类型",
        },
        dataPreprocess: {
            name: "名称",
            desc: "说明",
            dataSource: "数据源限制",
            type: "类型",
            status: "有效性",
            actions: "操作",
        },
        informationEntities: {
            name: "名称",
            apiName: "API名称",
            type: "类型",
            remark: "描述",
            activeFlag: "有效性",
            actions: "操作",
        },
        interactiveRecognition: {
            questionAndAnswer: "標準問題&答案",
            type: "類型",
            status: "狀態",
            actions: "操作",
            question: "问题",
        },
        role: { id: "ID", name: "名稱", actions: "操作" },
        uploadDownload: {
            taskName: "文件名称",
            createTime: "日期",
            size: "大小",
            createUserName: "操作员",
            status: "状态",
            result: "结果",
            actions: "操作",
        },
        taskCenterList: {
            name: "任务内容",
            createTime: "提交时间",
            startTime: "开始时间",
            endTime: "结束时间",
            createUserName: "操作员",
            status: "状态",
            result: "结果",
            actions: "操作",
        },
        customerInformation: {
            title: "客服信息",
            data: "仅查看异常数据",
            list: {
                agentNo: "工号/客服ID",
                agentName: "客服",
                agentTel: "电话",
                agentEmail: "邮箱",
                updateTime: "更新时间",
                actions: "操作",
            },
            upload: { second: "2.點擊導入按鈕,上傳符合模板格式的EXCEl文件" },
        },
        IndexManagement: {
            title: "指标管理",
            list: {
                indicatorName: "名称",
                indicatorDesc: "描述",
                activity: "有效性",
                inspectDataSource: "数据源",
                sysType: "类型",
                agentGroups: "客服组",
                actions: "操作",
            },
            detail: {
                nameEmpty: "名稱不能為空",
                descript: "描述",
                descriptEmpty: "描述不能為空",
                active: "有效性",
                activeEmpty: "有效性不能為空",
                dataSource: "數據源",
                dataSourceEmpty: "數據源不能為空",
                grounp: "客服組",
                grounpEmpty: "客服組不能為空",
                grounpHolder: "請選擇客服組",
                type: "評分方式",
                typeNone: "評分方式不能為空",
                base: "基礎分",
                baseNone: "基礎分不能為空",
                positive: "正面因素",
                positiveNone: "正面因素不能為空",
                customAdd: "添加篩選",
                negative: "負面因素",
                negativeNone: "負面因素不能為空",
                customOK: "篩選條件：",
                judgeStrategy: "條件規則：",
                other: "其他因素",
                otherAdd: "添加因素",
                agent: "原始數據",
                section: "區間數量",
                sectionNone: "區間數量不能為空",
                step: "區間步長",
                stepNone: "區間步長",
                save: "保存",
                cancel: "取消",
            },
        },
    },
    enums: {
        gongRolesTypes: {
            all: "全部",
            staff: "仅本人",
            department: "本部门及以下",
        },
        gongStatisticsDate: { week: "周", month: "月", season: "季" },
        gongCoachTaskStatus: {
            wait: "待辅导",
            already: "已辅导",
            ignore: "已忽略",
        },
        callRangeStatus: {
            all: "全部通话",
            sale: "员工发起",
            customer: "客户发起",
        },
        todoListType: {
            later: "稍后听",
            help: "辅导求助",
            contact: "稍后联系",
            send: "发送资料",
            warn: "风险预警",
        },
        frequencyTypes: { everyDay: "每日", everyWeek: "每周", everyMonth: "每月" },
        classifiedTraing: {
            trainTypeList: {
                noneDataTrain: "未分類數據訓練",
                markedDataCorrigenda: "已標註數據勘誤",
                classifiedDataCheck: "已分類數據校驗",
                markedHistoryRecord: "標註歷史記錄",
                historyDataRecalculate: "歷史數據重算",
            },
            dataExtractMode: {
                random: "完全隨機",
                intelligentRecommend: "智能推薦",
                custom: "自定義",
                specifiedClass: "指定分類",
            },
            smartRatesList: {
                markRate: "標註率",
                corrigendaRate: "勘誤率",
                kl: "K-L散度",
                signalNoiseRate: "信噪比",
            },
        },
        visibleRangeTypes: {
            all: "全部",
            customerGroup: "客服組",
            userDefined: "自定義",
        },
        samplingRateTypes: { everyDay: "每天抽取", everyWeek: "每周抽取" },
        taskRemindTypes: {
            confirm: "質檢任務記錄需要確認時，發送提醒",
            appeal: "質檢任務記錄發起申訴時，發送提醒",
        },
        weekDays: {
            monday: "星期一",
            tuesday: "星期二",
            wednesday: "星期三",
            thursday: "星期四",
            friday: "星期五",
            saturday: "星期六",
            sunday: "星期日",
        },
        reportDateTypes: { dailyReport: "日報表", weeklyReport: "周報表" },
        samplingRangeDayTypes: { today: "今天", yesterday: "昨天" },
        samplingRangeWeekTypes: { thisWeek: "本周", lastWeek: "上周" },
        samplingRuleTypes: { proportion: "比例抽樣", random: "隨機抽樣" },
        taskStatusTypes: {
            waitEvaluate: "待評價",
            waitSubmit: "待提交",
            waitConfirm: "待確認",
            waitReview: "待復核",
            completed: "已完成",
            all: "全部",
        },
        qualityInspectionForm: {
            formTemplateTypes: {
                directScore: "直接評分",
                weightedScore: "權重評分",
                deductScore: "扣分",
                directScoreSecondLevel: "直接評分-二級",
                weightedScoreSecondLevel: "權重評分-二級",
                deductScoreSecondLevel: "扣分-二級",
            },
        },
        fieldOperators: {
            is: "等於",
            not: "不等於",
            contains: "包含",
            notContains: "不包含",
            not_contains: "不包含",
            containsAny: "包含任意",
            contains_any: "包含任意",
            is_any: "包含任意",
            not_any: "不包含任意",
            notContainsAny: "不包含任意",
            not_contains_any: "不包含任意",
            containsAll: "包含所有",
            contains_all: "包含所有",
            notContainsAll: "不包含所有",
            nont_contains_all: "不包含所有",
            isNull: "為空",
            is_null: "為空",
            isNotNull: "不為空",
            is_not_null: "不為空",
            greaterThan: "大於",
            greater_than: "大於",
            greaterThanEq: "大於等於",
            greater_than_eq: "大於等於",
            lessThan: "小於",
            less_than: "小於",
            lessThanEq: "小於等於",
            less_than_eq: "小於等於",
            between: "介於",
            notBetween: "不介於",
            not_between: "不介於",
            relative: "等於",
            set: "設置為",
            belongs: "屬於",
            notBelongs: "不屬於",
            not_belongs: "不等於",
            today: "今天",
            yesterday: "昨天",
            the_day_before_yesterday: "前天",
            tomorrow: "明天",
            next_seven_day: "今後七天",
            last_seven_day: "最近七天",
            this_week: "本周",
            next_week: "下周",
            last_week: "上周",
            this_month: "本月",
            next_month: "下月",
            last_month: "上月",
            this_year: "今年",
            next_year: "明年",
            last_year: "去年",
            exact_match: "精確匹配",
            add_value: "新增值",
            remove_value: "刪除值",
            set_null: "置空",
            isAny: "任意等於",
            notAny: "任意不等於",
            belongTo: "屬於",
            notBelongTo: "不屬於",
            hasAny: "包含任意",
            notHasAny: "不包含任意",
            prefixContains: "開頭等於",
            prefixNotContains: "開頭不等於",
            suffixContains: "結尾等於",
            suffixNotContains: "結尾不等於",
            nextSevenDay: "今後7天",
            lastSevenDay: "最近7天",
            thisWeek: "本周",
            nextWeek: "下周",
            lastWeek: "上周",
            thisMonth: "本月",
            nextMonth: "下月",
            lastMonth: "上月",
            thisYear: "今年",
            nextYear: "明年",
            lastYear: "去年",
            after: "晚於（包含當天）",
            before: "早於（包含當天）",
            allMatching: "全部匹配",
            allNotMatching: "全部不匹配",
            hit: "命中",
            not_hit: "未命中",
        },
        fieldsOperators: {
            is: "等於",
            is_null: "同時為空",
            head_equal: "開頭等於",
            tail_equal: "結尾等於",
            is_not_null: "同時不為空",
            not: "不等於",
            diff_equal: "相差等於",
            diff_greater_than: "相差大於",
            diff_greater_than_eq: "相差大於等於",
            diff_less_than: "相差小於",
            diff_less_than_eq: "相差小於等於",
            hour_equal: "同小時",
            day_equal: "同天",
            week_equal: "同周",
            month_equal: "同月",
            year_equal: "同年",
            day: "天",
            minute: "分鐘",
            hour: "小時",
            part_equal: "部分等於",
        },
        samplingColumnCaptions: {
            created_at: "通話時間",
            agent_id: "客服",
            get_direction: "通話類型",
            call_result: "通話結果",
            survey: "評價",
            derived_from_id: "來源",
            quit_queue_why: "排隊狀態",
            queuing_duration: "排隊耗時",
            talking_seconds: "通話時長",
            ringing_seconds: "響鈴時間",
            hangup_by: "通話掛斷方",
        },
        callTypes: {
            callIn: "呼入",
            callOut: "呼出",
            threeParty: "（三方）",
            consultation: "（咨詢）",
            insert: "（強插）",
            monitor: "（監聽）",
            transfer: "（轉接）",
            intercept: "（攔截）",
            transferOutside: "（轉接外線）",
            threePartyOutside: "（三方外線）",
            consultingOutside: "（咨詢外線）",
        },
        callResults: {
            staffAnswer: "客服接聽",
            staffNotAnswer: "客服未接",
            customerAnswer: "客戶接聽",
            customerNotAnswer: "客戶未接",
            phoneBusy: "話機占線",
            phoneOffline: "話機離線",
            customerSpeedHangUp: "客戶速掛",
            customerHangUp: "客戶掛機",
            queueTimeout: "排隊超時",
            queueGiveUp: "放棄排隊",
            outlineAnswer: "外線接聽",
            outlineNotAnswer: "外線未接",
            noChooseQueue: "未選擇隊列",
        },
        defaultSurveys: { noEvaluation: "未評價", noNeedToEvaluate: "無需評價" },
        queueStates: {
            queueSuccess: "排隊成功",
            queueTimeout: "排隊超時",
            queueGiveUp: "放棄排隊",
            noStaffOnline: "無客服在線",
        },
        callHangers: { customer: "客戶", staff: "客服", outline: "外線" },
        workFlowTypes: { noAppealsMode: "無申訴模式", appealsMode: "允許申訴模式" },
        fieldCategories: {
            standardCondition: "標準篩選條件",
            customerCondition: "用戶自定義篩選條件",
        },
        sampleTypes: { automatic: "自動抽樣", manual: "手動抽樣" },
        defaultFields: {
            createdAt: "通話時間",
            agentId: "客服",
            getDirection: "通話類型",
            callResult: "通話結果",
            survey: "評價",
            derivedFrom: "來源",
            quitQueueWhy: "排隊狀態",
            queuingDuration: "排隊耗時",
            talkingSeconds: "通話時長",
            ringingSeconds: "響鈴時間",
            hangupBy: "通話掛斷方",
        },
        conditionFieldCategories: {
            fixedField: "固定字段",
            customField: "自定義字段",
            secondLevelScore: "二級分類",
            formItemScore: "分值項",
            forbids: "禁止項",
            deadlies: "致命項",
        },
        columnFieldCategories: {
            agent: "客服",
            callLog: "通話記錄",
            businessFields: "業務信息",
            customer: "客戶",
            customerCustomFields: "客戶自定義",
            source: "來源",
            queue: "隊列",
            fixed: "固定",
            secondLevelScore: "二級分類",
            formItemScore: "分值項",
            forbids: "禁止項",
            deadlies: "致命項",
        },
        taskListDefaultFields: {
            startTime: "通話時間",
            name: "客服",
            state: "狀態",
            totalScore: "總分",
            formItemForbidList: "禁止項",
            formItemDeadlyList: "致命項",
            comment: "評價備註",
            inspector: "評分人",
            review: "復核員",
        },
        evaluationStates: {
            initial: "初始化",
            waitEvaluate: "待評價",
            evaluated: "待提交",
            waitConfirm: "待確認",
            waitCheck: "待復核",
            complete: "已完成",
        },
        workBenchInfoTypes: {
            callInfo: "通話信息",
            businessInfo: "業務信息",
            customerInfo: "客戶信息",
        },
        evaluationEventStates: {
            create: "創建",
            valuate: "評價",
            edit: "編輯",
            submit: "提交",
            confirm: "確認",
            appeal: "申訴",
            checkConfirm: "復核確認",
            check: "復核",
        },
        formItemTypes: {
            direct: "直接評分",
            weight: "權重",
            deduct: "扣分",
            forbid: "禁止項",
            deadly: "致命項",
        },
        appealCheckCallTypes: {
            callIn: "呼入",
            callBack: "雙向回撥",
            callOut: "外呼直撥",
            autoCall: "自動外呼",
        },
        appealCheckCallStatus: {
            waitReview: "待復核",
            reviewed: "已復核",
            filed: "已歸檔",
        },
        samplingStatus: { all: "全部", unchecked: "抽取未檢", checked: "已抽檢" },
        inspectionStatus: {
            all: "全部",
            unread: "未讀",
            readed: "已讀",
            appeal: "申訴",
            review: "復核",
            filed: "已歸檔",
        },
        gradeChooseHits: { yes: "是", no: "否" },
        appealCheckStatus: {
            all: "全部狀態",
            unread: "未讀",
            inComplaint: "申訴中",
            reviewed: "已復核",
            readed: "已讀",
            filed: "已歸檔",
            spotchecked: "已抽檢",
        },
        flowStatus: {
            unread: "未讀",
            alreadyRead: "已讀",
            complaining: "申訴中",
            reviewed: "已復核",
            sampling: "抽取未檢",
            samplingCompleted: "已抽檢",
            complainCancel: "已撤銷",
        },
        complainStatus: {
            success: "成功",
            fail: "失敗",
            default: "申訴中",
            canceled: "已撤銷",
        },
        reviewInspectionStatus: { inComplaint: "申訴中", reviewed: "已復核" },
        gradeChangeTypes: {
            edit: "編輯",
            check: "復核",
            file: "歸檔",
            remark: "備註",
            complain: "申訴",
            review: "復核",
            reviewEvaluation: "復核評價",
            sample: "抽檢",
            sampleEvaluation: "抽檢評價",
            sampleSubmit: "抽檢提交",
            sampleAssign: "抽檢分配",
            reviewAssign: "復核分配",
            read: "已讀",
            reviewConfirm: "復核確認",
            sampleConfirm: "抽檢確認",
            caseBase: "添加至案例組",
            sampleCancel: "抽檢取消分配",
            reviewCancel: "復核取消分配",
            sampleAgain: "抽檢重新分配",
            reviewAgain: "復核重新分配",
            btachDelete: "刪除抽檢記錄",
            rapidSampling: "快速抽檢",
            reInspection: "重新質檢",
            smartTagsChange: "智能標簽變更",
            cancelAppeal: "撤銷申訴",
        },
        appealTypes: {
            transliterationError: "轉寫錯誤",
            discriminationError: "判別錯誤",
            otherError: "其他錯誤",
        },
        inspectDataSources: {
            voiceCall: "語音通話",
            textDialogue: "文本對話",
            realTimeVoiceCall: "實時語音通話",
            realTimeTextDialogue: "實時文本對話",
            wechatDialogue: "企微会话",
            taobao: "电商文本对话",
            ticket: "工单",
            wechatRadio: "企微语音",
        },
        inspectTaskType: { all: "全部", common: "常規質檢", relate: "關聯質檢" },
        inspectApproveType: {
            all: "全部",
            resolve: "審核通過",
            resolving: "審核中",
            reject: "審核未通過",
        },
        dataSourceFlags: { all: "不限製", voiceCall: "通話", textDialogue: "對話" },
        smartWordsLibrary: { effective: "有效", invalid: "無效" },
        activeFlags: { effiective: "有效", invalid: "無效" },
        labelTypes: { dimensionLabel: "標註標簽", classificationLabel: "分類標簽" },
        pointTypes: {
            automatic: "自動評價",
            manual: "手動評價",
            interactiveDetection: "交互檢測",
            smartRules: "智能規則",
            machineLearning: "自學習模式",
            intelligentModel: "智能模型",
        },
        pointGradeTypes: {
            radio: "單選",
            input: "輸入",
            select: "選擇",
            all: "滿足全部",
            any: "滿足任意",
            customize: "自定義",
        },
        pointNaChecked: { check: "N/A" },
        operatorTypes: {
            keyword: "關鍵詞匹配",
            question: "疑問句",
            regular: "正則表達式",
            context: "上下文重復",
            semantics: "語義匹配",
            word: "字數相似句",
            dialogue: "對話時間間隔",
            speed: "語速檢測",
            grabDetection: "搶話檢測",
            semanticTags: "語義標簽",
        },
        applyRoles: {
            all: "全部",
            customer: "客戶",
            agent: "客服",
            robot: "機器人",
        },
        saleApplyRoles: { all: "全部", customer: "客户", agent: "员工" },
        applyOperatorScopes: {
            all: "全文",
            preCondition: "前置條件",
            scope: "指定範圍",
        },
        keywordScopes: { all: "檢測全部關鍵詞", any: "檢測任意關鍵詞" },
        preOperatorHitTypes: {
            first: "首次匹配",
            every: "每次匹配",
            last: "最後一次匹配",
            any: "任意一次匹配",
            none: "未匹配",
        },
        operatorRuleTypes: {
            all: "滿足所有",
            any: "滿足任意",
            custom: "自定義邏輯",
        },
        informationEntityTypes: {
            system: "系統",
            custom: "自定義",
            moduleCompany: "模組",
        },
        organizationTypes: { null: "無", moduleCompany: "模組" },
        customType: {
            smartTag: "抽取實體",
            scriptTimes: "話術計次",
            dataCategory: "數據分類",
        },
        interactiveQuestionTypes: {
            standard: "標準問答",
            multiElement: "多要素問答",
            guideMultiRound: "引導式多輪交互",
        },
        targetTypes: { all: "全部", customerService: "客服", customer: "客戶" },
        interactiveTypes: {
            similarQList: "相似問題",
            exceptSimilarQList: "排除相似問題",
            similarAList: "相似答案",
            wrongAList: "錯誤答案",
        },
        filterTypes: {
            call: "通話錄音",
            dialogue: "對話文本",
            wechat: "企業微信",
            wechatAll: "企業微信全部對話",
            ticket: "工单会话",
            taobao: "电商会话",
            wechatRadio: "企微语音",
        },
        fieldTypes: {
            keyword: "關鍵詞",
            agent: "客服",
            agentGroup: "客服組",
            sigleListbox: "單選",
            multiListbox: "多選",
            text: "單行文本",
            textArea: "多行文本",
            dateTime: "時間",
            number: "數字",
            voice: "",
            customer: "客戶",
            fieldWithLink: "帶鏈接字段",
            percentage: "百分比",
            intelligentClassification: "智能分類",
            testSetList: "測試集動態字段",
            inspector: "質檢員",
        },
        saveTypes: { save: "保存" },
        knowledgeType: { oneToOne: "一问一答" },
        updateTypes: { update: "更新", saveAs: "另存為" },
        recommendItemActionTypes: {
            similar: "相似",
            exclude: "排除",
            ignore: "忽略",
        },
        gradeStatus: {
            unread: "未讀",
            alreadyReview: "已復核",
            alreadyFiled: "已歸檔",
            waitReview: "待復核",
            alreadyRead: "已讀",
        },
        qualityCheckDetailTypes: { review: "復核", spotCheck: "抽檢" },
        sampleAndReviewStatus: {
            init: "未抽取",
            unread: "未讀",
            alreadyRead: "已讀",
            complaining: "申訴中",
            reviewed: "已復核",
            sampling: "抽取未檢",
            samplingCompleted: "已抽檢",
        },
        interactiveAnswerTypes: {
            noAnswer: "未檢測到答案",
            answer: "檢測到答案 ",
            wrongAnswer: "檢測到錯誤答案",
        },
        interactiveMatchedQuestionTypes: {
            noQuestion: "未檢測到問題",
            question: "檢測到問題",
            eliminateQuestion: "檢測到排除問題",
        },
        isManual: { yes: "已人工質檢", no: "未人工質檢" },
        readStatus: { yes: "已讀", no: "未讀" },
        reInspectionType: {
            recalcScore: "僅重新核算分數（含評級）",
            recheckPoint: "重新核算質檢點與分數",
        },
        common: {
            customer: "客戶",
            customerService: "客服",
            robot: "機器人",
            customerInformation: "客戶資訊",
            workOrderFields: "工單欄位",
            intelligentConversationAnalysis: "智慧會話分析",
            insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "洞察業務短板，聆聽客戶心聲，提升行銷轉化",
            intelligentAccompanyingPractice: "智慧陪練",
            createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "打造類比業務真實場景的沉浸式AI陪練，實現金牌話術全員掌握，對話技巧快速提升",
            intelligentQualityInspection: "智慧質檢",
            fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "全量質檢，及時高效，準確率85%以上",
            salesEmpowerment: "銷售賦能",
            aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI重塑銷售管理，實現溝通過程數位化、視覺化，透明管理銷售每一環，智慧洞察溝通每一步，精細化診斷業務問題，完美複刻銷冠經驗",
            algorithmPlatform: "算灋平臺",
            basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "基於自研原心引擎語音語義，ASR、NLP模型定制，打通業務場景數據，實現系統自學習，達到越用越智慧，越用越準確的目的",
            generalSettings: "通用設定",
            unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "對數據許可權、員工角色進行統一設定管理",
            callLog: "通話記錄",
            intelligentRules: "智慧規則",
            businessRecords: "業務記錄",
            customFilter: {
                call: "語音通話",
                text: "文本對話",
                smartTags: "智能標簽",
                pretreatment: "預處理",
                smartTagGroup: "智能標簽組",
                currency: "通用數據",
                wechat: "企業微信",
                spotCheck: "抽檢",
                personaField: "客戶列表",
                ticket: "工单会话",
                taobao: "电商会话",
                wechatRadio: "企微语音",
                reviewList: "复核",
            },
            talkerRecognitionType: { API: "API標識軌道角色", text: "文本識別" },
            operatorTypes: {
                keyword: "關鍵詞匹配",
                question: "疑問句",
                regular: "正則表達式",
                context: "上下文重復",
                semantics: "語義匹配",
                word: "字數相似句",
                dialogue: "對話時間間隔",
                speed: "語速檢測",
                grabDetection: "搶話檢測",
                semanticTags: "語義標簽",
                keywordStrict: "關鍵詞匹配(嚴格)",
                link: "鏈接字段",
                entityInfoDetection: "信息實體檢測",
                basicInfoDetection: "基礎信息檢測",
                volume: "音量檢測",
                emotion: "情緒分析",
                grammar: "文法規則",
                sentenceLength: "句長檢測",
                conceptWord: "概念詞",
                questionsAndAnswers: "知识库解答（旧）",
                element: "要素問答",
                silent: "響應超時",
                associate: "隨路數據",
                repeat: "重復發送",
                timeInterval: "間隔時長",
                typoDetection: "錯別字檢測",
                silentOld: "靜默",
                knowledge: "知识解答",
                wecomRemark: "客户企微备注",
                customerAddTime: "客户添加时间",
            },
            keywordOperators: {
                all: "全部匹配",
                allNot: "全部不匹配",
                any: "任意匹配",
            },
            applyRoles: { all: "全部", customer: "客戶", agent: "客服" },
            sentenceLength: {
                less: "小於",
                greater: "大於",
                equal: "等於",
                greater_or_equal: "大於等於",
                less_or_equal: "小於等於",
            },
            dialogueApplyScopes: {
                differentRoles: "不同角色之間",
                agent: "客服",
                customer: "客戶",
                agentResponse: "客服應答",
                customerResponse: "客戶應答",
                customerNoResponse: "客戶無響應",
            },
            applyConditionHitTypes: {
                first: "第一次命中",
                every: "每一次命中",
                last: "最後一次命中",
            },
            applyConditionScopes: {
                current: "當前",
                before: "前",
                after: "後",
                beforeAll: "之前全部",
                afterAll: "之後全部",
                around: "附近",
            },
            voiceDemoTypes: {
                singleChannel: "單通道通話錄音",
                dualChannel: "雙通道通話錄音",
            },
            sendStatus: {
                sending: "發送中",
                arrive: "已送達",
                fail: "發送失敗",
                off_sending: "離線發送未讀",
                off_arrive: "已讀",
                rollback: "已撤回",
            },
            collectionMethod: { assignment: "直接賦值", entity: "實體抽取" },
            systemTypes: { qa: "智能質檢", wfm: "智能排班" },
            entityOperators: {
                equals: "等於信息",
                notEquals: "不等於信息",
                contains: "包含信息",
                notContains: "不包含信息",
                equalsFix: "等於固定值",
                notEqualsFix: "不等於固定值",
                containsFix: "包含固定值",
                notContainsFix: "不包含固定值",
            },
            basicInfoOperators: {
                equals: "等於",
                notEquals: "不等於",
                contains: "包含",
                notContains: "不包含",
                isNull: "為空",
                isNotNull: "不為空",
            },
            volumeDetectionWays: {
                loudness: "響度分貝",
                rangeAbilitySelf: "變化幅度(比本人上句)",
                rangeAbilityOth: "變化幅度(比對方上句)",
                rangeAbility3: "變化幅度(比本人本句)",
            },
            volumeDetectionOperators: {
                is: "等於",
                not: "不等於",
                greaterThan: "大於",
                greaterThanEq: "大於等於",
                lessThan: "小於",
                lessThanEq: "小於等於",
            },
            sentimentTypes: {
                positive: "正面情緒",
                negative: "負面情緒",
                neutral: "中性情緒",
                thankful: "感謝",
                happy: "高興",
                complaining: "抱怨",
                angry: "生氣",
                post: "正面",
                negat: "負面",
                neut: "中性",
            },
            emotionOperators: { is: "符合", not: "不符合" },
            propTypes: { system: "系統", user: "自定義" },
            valueTypes: { text: "文本", number: "數字" },
        },
        tasks: {
            theDayBeforeYesterday: "前天",
            assessActionTypes: { reassess: "重新評估" },
            scoreType: {
                aiScore: "智能評價",
                manualScore: "手動評價",
                interactiveDetection: "交互檢測",
                smartRules: "智能規則",
                machineLearning: "自學習模式",
            },
            inspectType: { inspectTrue: "檢查到", inspectFalse: "未檢查到" },
            operatorType: { keyword: "關鍵詞匹配", notKeyword: "關鍵詞不匹配" },
            applyRole: { all: "全部", customer: "客戶", agent: "客服" },
            applyScope: { all: "全部", specific: "指定句", scope: "指定範圍" },
            judgeStrategy: {
                all: "滿足所有條件",
                arbitrarily: "滿足任意條件",
                custom: "自定義邏輯",
            },
            checkPointStatus: {
                waitFor: "等待復核",
                notDeductScore: "不扣分",
                deductScore: "扣分",
            },
            applyOptions: { hit: "條件命中", notHit: "無指定條件" },
            gradeTypes: {
                yesOrNo: "是否符合",
                input: "手動輸入",
                level: "分檔評價",
                multipleFactors: "多要素",
            },
            gradeChooseHits: { yes: "是", no: "否" },
            gradeChangeTypes: {
                edit: "編輯",
                check: "復核",
                file: "歸檔",
                remark: "備註",
                complain: "申訴",
                review: "復核",
                reviewEvaluation: "復核評價",
                sample: "抽檢",
                sampleEvaluation: "抽檢評價",
                sampleSubmit: "抽檢提交",
                sampleAssign: "抽檢分配",
                reviewAssign: "復核分配",
                read: "已讀",
                reviewConfirm: "復核確認",
                sampleConfirm: "抽檢確認",
            },
            gradeStatus: {
                unread: "未讀",
                alreadyReview: "已復核",
                alreadyFild: "已歸檔",
                waitReview: "待復核",
                alreadyRead: "已讀",
            },
            samplingFrequencyTypes: {
                everyDay: "每日",
                everyWeek: "每周",
                everyMonth: "每月",
            },
            samplingEveryDayTimeTypes: { yesterday: "昨天", today: "今天" },
            samplingEveryWeekTimeTypes: { thisWeek: "本周", lastWeek: "上周" },
            samplingEveryMonthTimeTypes: { thisMonth: "本月", lastMonth: "上月" },
            samplingTypes: { random: "隨機抽樣", proportional: "比例抽樣" },
            weekdays: {
                Monday: "周一",
                Tuesday: "周二",
                Wednesday: "周三",
                Thursday: "周四",
                Friday: "周五",
                Saturday: "周六",
                Sunday: "周日",
            },
            distributeTypes: { rule: "規則優先分配", average: "平均隨機分配" },
            notMatchAnyRuleTypes: {
                random: "隨機平均分配",
                assign: "分配給指定人員",
                ignore: "不分配",
            },
            inspectorTypes: { all: "全部質檢員", some: "指定質檢員" },
            pushFrequencyTypes: {
                everyDay: "每日",
                everyWeek: "每周",
                everyMonth: "每月",
                inspectionUpdate: "質檢更新",
                timelyInspection: "實時質檢",
            },
            pushObjectTypes: { email: "指定郵箱", staff: "所屬客服" },
            reviewOptions: {
                reviewResult: "復核時顯示質檢結果",
                reviewRecord: "復核時顯示相關記錄",
                reviewAgentHidden: "復核時隱藏客服信息",
                samplingResult: "抽檢時顯示質檢結果",
                samplingRecord: "抽檢時顯示相關記錄",
                sampleAgentHidden: "抽檢時隱藏客服信息",
                checkExportAddCallInfoIphone: "導出列表時附加通話記錄",
                sampleExportAddCallInfoIphone: "導出列表時附加通話記錄",
                checkExportAddCallInfoText: "導出列表時附加對話記錄",
                sampleExportAddCallInfoText: "導出列表時附加對話記錄",
                inspectionExportAddCallInfoIphone:
          "導出列表時附加通話記錄(每次最多可導出10000條)",
                inspectionExportAddCallInfoText:
          "導出列表時附加對話記錄(每次最多可導出10000條)",
            },
            fastSampleInDetailList: {
                fastSampleInInspectDetail: "允許在質檢詳情快速執行抽檢",
                fastSampleInSampleDetail: "允許在抽檢詳情快速執行抽檢",
                fastSampleInReviewDetail: "允許在復核詳情快速執行抽檢",
            },
            permitCustomReasonList: { permitCustomReason: "允許自定義申述理由" },
            autoMateTasks: {
                autoLoad: "自動添加",
                sample: "自動抽檢",
                assign: "自動分配",
                pushes: "自動推送",
                assess: "自動評估",
                check: "申訴復檢",
                case: "案例库同步",
            },
            qualityCheckDetailTypes: { review: "復核", spotCheck: "抽檢" },
            appealTypes: {
                transliterationError: "轉寫錯誤",
                discriminationError: "判別錯誤",
                otherError: "其他錯誤",
            },
            inspectDataSources: {
                voiceCall: "語音通話",
                textDialogue: "文本對話",
                realTimeVoiceCall: "實時語音通話",
                realTimeTextDialogue: "實時文本對話",
            },
            pointTypes: {
                automatic: "自動評價",
                manual: "手動評價",
                interactiveDetection: "交互檢測",
            },
            pointGradeTypes: { radio: "單選", input: "輸入", select: "選擇" },
            sampleAndReviewStatus: {
                init: "未抽取",
                unread: "未讀",
                alreadyRead: "已讀",
                complaining: "申訴中",
                reviewed: "已復核",
                sampling: "抽取未檢",
                samplingCompleted: "已抽檢",
            },
            wayOverUpperLimits: {
                ignoreExcessSpillover: "忽略超額溢出",
                automaticWeightCalculation: "自動權重核算",
            },
            calculateScoreTypes: { add: "加分", sub: "減分" },
            naChecked: { check: "允許檢測中不涉及此項" },
            pointNaChecked: { check: "N/A" },
            interactiveRules: {
                proportional: "按比例給分",
                allRight: "全部正確給分",
                anyRight: "任意正確給分",
            },
            noHitQuestionResults: { true: "結果為「是」", false: "結果為「否」" },
            repetRules: { same: "內容完全相同", similar: "語義相似" },
            relateConditions: {
                highlightQuestion: "高亮問題",
                highlightAnswer: "高亮答案",
                hightlightFoctors: "高亮要素",
            },
            interactiveQuestionTypes: {
                question: "檢測到問題",
                eliminateQuestion: "檢測到排除問題",
            },
            interactiveAnswerTypes: {
                noAnswer: "未檢測到答案",
                answer: "檢測到答案 ",
                wrongAnswer: "檢測到錯誤答案",
            },
            inspectionUpdateTypes: {
                autoInspection: "自動質檢",
                autoInspectionWechat: "自動分析",
                artificialSampling: "人工抽檢",
                artificialReview: "人工復核",
                manualModification: "快速抽檢",
                timelyInspection: "實時質檢",
                samplingAssign: "抽檢分配",
                artificialComplain: "申訴提交",
                fastSampling: "快速抽檢提交",
                recheckInspection: "重新質檢",
                recheckInspectionWechat: "重新分析",
            },
            timelyInspectionTypes: { timelyInspection: "實時質檢" },
            pushActionTypes: { email: "郵件", interfacePush: "接口推送" },
            pushDetailWays: { riskAlert: "風險提醒", subscribe: "訂閱消息" },
            riskAlertPushDataTypes: {
                dialogueMessage: "通話/對話消息",
                inspectionResults: "質檢結果",
            },
            subscribePushDataTypes: {
                dialogueMessage: "通話/對話消息",
                textContent: "通話/對話文本內容",
                inspectionResults: "質檢結果",
            },
            pushRequestMethods: { post: "POST" },
            pushContentTypes: { json: "JSON" },
            notMatchPreRule: {
                average: "組內分數平均",
                addOrSubtract: "直接加減分",
                noChange: "不加減分",
            },
            defaultScore: {
                empty: "為空",
                add: "加分",
                notAdd: "不加分",
                sub: "減分",
                notSub: "不減分",
                na: "N/A",
                input: "手動輸入",
                choose: "選擇",
            },
            strategyTypes: {
                hitAdd: "命中加分",
                hitNotAdd: "命中不加分",
                hitSub: "命中減分",
                hitNotSub: "命中不減分",
            },
        },
        download: {
            exportStatus: {
                prepare: "準備中",
                process: "進行中",
                faild: "導出失敗",
                success: "成功",
            },
        },
        messageType: {
            all: "全部",
            monitor: "即時監測",
            trigger: "觸發通知",
            timing: "定時報告",
        },
        messagePushType: {
            all: "全部",
            system: "系統消息",
            sdk: "消息SDK",
            push: "接口推送",
            email: "郵件",
            udesk: "udesk通知",
            udeskWhite: "客服系统通知",
            weChat: "企微消息",
        },
        autoAddPushTarget: {
            sampleAssignInspector: "分配抽檢員",
            sampleInspector: "抽檢員",
            assignReviewer: "分配復核員",
            reviewer: "復核員",
            taskInspector: "任務質檢員",
        },
        sdkPushType: {
            webHook: "標準webhook",
            dingDing: "釘釘機器人",
            weChat: "企業微信機器人",
            customer: "自定義Json內容",
        },
        inspectionRemarksType: {
            inspection: "質檢",
            check: "抽檢",
            review: "復核",
        },
        segmentationStrategyType: { count: "消息數量", spacing: "消息間隔" },
        segmentationStrategy: { byTime: "定時切分", customize: "組合切分" },
        inspectType: { commonInspect: "常規質檢", associateInspect: "關聯質檢" },
        typoDetectionOperators: { less: "小於", greater: "大於" },
        autoAddTypes: { realTimeAdd: "實時添加", timingAdd: "定時添加" },
        messageTypes: { text: "文本消息", markdown: "Markdown消息" },
        inServiceStatus: {
            activated: "已激活",
            disabled: "已禁用",
            inactivated: "未激活",
            resigned: "退出企業",
        },
        roles: { agent: "员工", customer: "客户" },
        commentVisibleAuthority: {
            all: "对所有人可见",
            self: "仅自己可见",
            designation: "指定人可见",
        },
        appealCheckingTask: {
            enterpriseWeChatText: "企微文字",
            generalQualityInspection: "普通質檢",
            processQualityInspection: "流程質檢",
            voiceCopy: "語音複盤",
            beEqualTo: "等于",
            notEqualTo: "不等于",
            canAppeal: "可以申诉",
            noAppeal: "不可申诉",
        },
        wechat: {
            redEnvelopes: "红包",
            text: "文本",
            picture: "图片",
            voice: "语音",
            voiceCall: "语音通话",
            video: "视频",
            businessCard: "名片",
            position: "位置",
            expression: "表情",
            link: "链接",
            applet: "小程序",
            chatRecord: "聊天记录",
            mixedMessage: "混合消息",
            file: "文件",
        },
        gong: {
            sessionActivity: "會話活動",
            conversationAction: "會話動作",
            riskSemantics: "風險語義",
            daysNotFollowedUp: "未跟進天數",
            waysideData: "隨路數據",
            notStarted: "未開始",
            completed: "已完成",
            haveInHand: "進行中",
            unpublished: "未發佈",
            published: "已發佈",
            knowledgePoints: "知識點",
            technologicalProcess: "流程",
            notMarked: "未標注",
            inAnnotation: "標注中",
            annotated: "已標注",
        },
        qualityChecingTask: {
            incomingCall: "呼入",
            exhale: "呼出",
            incomingCallthirdParty: "呼入（三方）",
            incomingCallconsultation: "呼入（諮詢）",
            incomingCallforcedInsertion: "呼入（强插）",
            incomingCallmonitoring: "呼入（監聽）",
            incomingCalltransfer: "呼入（轉接）",
            incomingCallinterception: "呼入（攔截）",
            incomingCalltransferToExternalLine: "呼入（轉接外線）",
            incomingCallthirdPartyExternalLine: "呼入（三方外線）",
            incomingCallexternalLine: "呼入（諮詢外線）",
            outgoingCallthirdParty: "呼出（三方）",
            outgoingCalltransfer: "呼出（轉接）",
            outgoingCallforcedInsertion: "呼出（强插）",
            outgoingCallmonitoring: "呼出（監聽）",
            outgoingCallinterception: "呼出（攔截）",
            outgoingCalltransferToExternalLine: "呼出（轉接外線）",
            outgoingCallthreePartyExternalLine: "呼出（三方外線）",
            customerServiceAnswer: "客服接聽",
            customerServiceMissed: "客服未接",
            customerAnswer: "客戶接聽",
            customerMissed: "客戶未接",
            theTelephoneIsBusy: "話機占線",
            phoneOffline: "話機離線",
            customerQuickHanging: "客戶速掛",
            clientHangsUp: "客戶掛機",
            queueTimeout: "排隊超時",
            giveUpQueuing: "放弃排隊",
            outsideLineAnswering: "外線接聽",
            externalLineIsNotConnected: "外線未接",
            noQueueSelected: "未選擇隊列",
            notEvaluated: "未評估",
            noEvaluationRequired: "無需評估",
            queuingSucceeded: "排隊成功",
            noCustomerServiceOnline: "無客服線上",
            customer: "客戶",
            customerService: "客服",
            outsideLines: "外線",
        },
        sessionActivity: {
            single: "單次平均通話時間",
            all: "縂通話量",
            allTime: "通話縂時長",
        },
        sessionAction: {
            salesProportion: "銷售説話占比",
            salesLongest: "平均最長銷售發言",
            customerLongest: "平均最長客戶發言",
            discussNumber: "深入探討次數",
            frequency: "提問頻次",
        },
        monitorCriteria: { all: "前後", before: "前", after: "後" },
        monitorTimeCycle: { day: "天", week: "周", month: "月" },
        monitorGroupTestType: { ab: "倆組對比", a: "A組", b: "B組" },
        fields: { anyMatch: "任意匹配", keyWord: "關鍵字", keyEvents: "關鍵事件" },
        semanticIntelligence: {
            staff: "員工",
            customer: "客戶",
            employeesAndCustomers: "員工和客戶",
            notStarted: "未開始",
            haveInHand: "進行中",
            completed: "已完成",
            callRecording: "通話錄音",
            enterpriseWeChatCall: "企微通話",
            enterpriseWeChatText: "企微文字",
            discourseMiningForObjectionHandling: "抗告處理話術挖掘",
            productSellingPointScriptsMining: "產品賣點話術挖掘",
            conversationScenario: "會話場景",
            topicOfConversation: "話題",
            keyEvents: "關鍵事件",
            customerLabel: "客戶標籤",
            includeAny: "包含任意",
            includeAll: "包含全部",
            and: "且",
            or: "或",
            question: "疑問句",
            nonInterrogativeSentence: "非疑問句",
        },
        coach: {
            allData: "全部數據",
            dataFromThisDepartment: "本部門數據",
            multidepartmentalData: "多部門數據",
            underReview: "稽核中",
            no: "否",
            yes: "是",
            automaticRating: "自動評分",
            auditRating: "稽核評分",
            reRating: "重新評分",
            inAppeal: "申訴中",
            reviewed: "已覆核",
            notViewed: "未查看",
            viewed: "已查看",
            uncorrected: "未修正",
            corrected: "已修正",
            practice: "練習",
            examination: "考試",
            other: "其他",
            notMeetingStandards: "未達標",
            meetingStandards: "達標",
            excellent: "優秀",
            invalid: "失效",
            notStarted: "未開始",
            haveInHand: "進行中",
            ended: "已結束",
            completed: "已完成",
            hangInTheAir: "未完成",
            passed: "已通過",
            voiceConversation: "語音對話",
            textDialogue: "文字對話",
            slidePresentation: "幻燈片演講",
            unpublished: "未發佈",
            published: "已發佈",
            narration: "旁白",
            studentSpeaking: "營員說話",
            robotTalk: "機器人說話",
            knowledgeBaseQA: "知識庫問答",
            slide: "幻燈片",
            negativeEmotions: "情緒負向",
            emotionallyNormal: "情緒正常",
            incompleteScript: "話術不完整",
            completeScript: "話術完整",
            normalSpeechSpeed: "語速正常",
            speakTooFast: "語速過快",
            speakTooSlowly: "語速過慢",
            whole: "全部",
            singlePage: "單頁",
            qA: "一問一答",
            situationalDialogue: "情景對話",
            misses: "未命中",
            hit: "命中",
            sequentialQA: "順序問答",
            randomQA: "隨機問答",
            custom: "自定義",
            mastered: "已掌握",
            notMastered: "未掌握",
        },
        algorithm: {
            waiting: "等待中",
            extracting: "抽取中",
            success: "成功",
            fail: "失敗",
            intelligentQualityInspection: "智慧質檢",
            textDialogue: "文字對話",
            voiceCall: "語音通話",
            smartTags: "智慧標籤",
            satisfactionEvaluation: "滿意度評估",
            score: "評分",
            grade: "評級",
            customerServiceTeam: "客服組",
            salesEmpowerment: "銷售賦能",
            conversationAnalysis: "會話分析",
        },
        operatorListGather: {
            allTime: "全部時間",
            nonBusinessWorkingHours: "非業務工作時間",
            businessWorkingHours: "業務工作時間",
        },
    },
    pages: {
        auth: {
            welcome: "",
            copyRight: "",
            login: {
                name: "登錄",
                email: "郵箱",
                password: "密碼",
                forgetPwd: "忘記密碼",
                emailCannotBeEmpty: "郵箱不得為空。",
                passwordCannotByEmpty: "密碼不得為空。",
                mustChangeLogin: "對不起，您的密碼必須重置，請聯系管理員重置密碼。",
                dismissionLogin: "對不起，您已經離職。",
                forbiddenLogin: "對不起，您的賬戶是禁用狀態。",
                loginError: "登錄失敗，郵箱或密碼錯誤。",
                loginPrompt: "登錄到系統",
                rememberMe: "記住我",
            },
            logout: { logoutError: "註銷失敗，請查看網絡連接或稍後再試。" },
            resetPassword: {
                forgetPwd: "忘記密碼",
                inputEmailPrompt:
          "在下方輸入你的帳號郵箱，我們將向您發送重置密碼的電子郵件和說明。",
                sendEmailSuccess:
          "修改密碼確認信函已經成功發送至您填寫的郵箱中，請及時查收。",
                sendEmailFailed: "郵箱發送失敗，請檢查網絡連接或稍後再試。",
                setNewPassword: "設置您的新密碼",
                passwordCannotEmpty: "密碼不得為空",
                passwordFormWrong: "密碼格式不正確",
                confirmPasswordWrong: "兩次輸入的密碼不相等。",
                passwordResetSuccess: "密碼重置成功。",
                passwordResetFailed: "密碼重置失敗，請檢查網絡連接或稍後再試。",
                linkTimeover: "重置密碼鏈接到期，請重試",
            },
            form: { title: "CASE表單展示" },
        },
        public: {
            auth: {
                forgotPassword: {
                    email: "郵箱",
                    emailFormatError: "郵箱格式錯誤，請重新輸入！",
                    forgotPassword: "忘記密碼？",
                    describe:
            "在下方輸入你的帳號郵箱，我們將向您發送重置密碼的電子郵件和說明。",
                    send: "發送",
                    emailCannotBeEmpty: "郵箱不能為空",
                    resetPasswordExpire: "重置密碼鏈接到期，請重試",
                },
                resetPassword: {
                    setPassword: "設置您的新密碼",
                    newPassword: "新密碼",
                    repeatPassword: "重復密碼",
                    resetPassword: "重置密碼",
                    passwordCannotBeEmpty: "新密碼不能為空。",
                    confirmPasswordCannotBeEmpty: "重復密碼不能為空。",
                    passwordsNotMatch: "兩次密碼不一致！",
                    passwordFormWrong: "密碼不能帶有中文，並且個數在6-14位！",
                    linkExpiration: "重置密碼鏈接到期，請重試",
                },
            },
            errors: { haveNoPermissionWarningText: "您沒有此頁面的查看權限" },
        },
        downloadCenter: {
            name: "下載中心",
            saveTime: "(默認保存30天)",
            headerTitle: "下載文件",
            download: "下載",
            downloadPromp: "下載任務已提交，請前往",
            viewProgress: "查看進度",
        },
        uploadCenter: { name: "上传中心" },
        tasks: {
            index: {
                name: "名稱",
                remark: "描述",
                qualityCheckTasksTitle: "工作臺",
                informationCollection: "采集任務",
                qualityCheckTasks: "質檢任務",
                wechatQaTasks: "分析任務",
                moduleName: "使用模板新建",
                creatNewQualityCheckTask: "新建任務",
                generalInspectionList: "通用質檢報告",
                editQualityCheckTask: "編輯質檢任務",
                creatNewQualityCheckTaskError: "新建質檢任務失敗",
                editQualityCheckTaskError: "編輯質檢任務失敗",
                type: "表單類型",
                singleScore: "單項分數",
                singleScoreNullError: "單項分數不能為空",
                inspector: "質檢員",
                inspectDataSource: "數據源",
                taskName: "任務名稱",
                inspectionType: "質檢類型",
            },
            manage: {
                screeningCondition: "篩選條件",
                template: {
                    condition: {
                        index: { name: "條件", existingConditions: "已有條件" },
                        detail: {
                            id: "ID",
                            name: "條件名稱",
                            remark: "描述",
                            checkRange: "檢測範圍",
                            operator: "算子",
                            operatorLogic: "算子邏輯",
                        },
                    },
                    checkPointTest: {
                        title: "規則測試",
                        running: "規則測試中，請稍後...",
                        clearSuccess: "模擬對話清空成功",
                        mock: "模擬對話",
                        save: "保存選中對話",
                        saveAll: "保存全部對話",
                        saveSuccess: "對話保存成功",
                        close: "退出",
                        delete: "刪除選中",
                        clear: "清空",
                        customer: "客戶",
                        agent: "客服",
                        test: "測試",
                        request: "模擬對話清空後將不可恢復，是否確認清空？",
                        deletePopconfirmContent: "是否確認刪除選中對話？",
                    },
                    checkPoint: {
                        index: {
                            name: "規則配置",
                            wechatTitle: "分析模型配置",
                            existingCheckPoints: "已有質檢規則",
                            currentCheckPointScore: "當前質檢規則分值為",
                            currentCheckPointScoreWechat: "當前分析模型分值為",
                            currentCheckPointWeight: "當前質檢規則權重總和為",
                            weightTotalScoreAndItemScore: "總分：100分 單項分：",
                            template: "質檢規則模板",
                            import: "導入質檢規則模板",
                            getTemplateError: "質檢規則模板獲取失敗",
                            tabs: { template: "模板", demo: "樣例" },
                            customize: "空白模板",
                            move: "拖拽以調整分類和排序",
                            classification: "質檢分類",
                            classificationWeChat: "模型分類",
                            first: "一級分類",
                            second: "二級分類",
                            new: "新建質檢規則",
                            weChatNew: "添加分析模型",
                            createWeChat: "新建分析模型",
                            empty: "暫無質檢規則，",
                            wechatEmpty: "暫無分析模型，",
                            newSecond: "新建二級分類",
                            newFirst: "新建一級分類",
                            checkPoint: "質檢規則",
                            checkPointWeChat: "分析模型",
                        },
                        detail: {
                            name: "名稱",
                            remark: "描述",
                            type: "類型",
                            deduction: "扣分",
                            rule: "規則",
                            highlighted: "高亮",
                            gradeType: "評分方式",
                            interactiveCategory: "交互分類",
                            predeterminedScore: "分值",
                            weight: "權重",
                            na: "N/A",
                            default: "默認得分",
                            preRule: "前置條件規則",
                            analysisHighlighted: "解析規則",
                            categorySelectPrompt: "請選擇分類",
                            checkHighlightArrayError:
                "規則與高亮不匹配，請重新點擊解析規則按鈕",
                            foctorsHolder: "請選擇要素",
                            foctorsErrorNone: "多要素規則為空，請新增多要素類型",
                            naError: "默認得分為N/A時無法關閉該項",
                            templateNew: {
                                ruleType: "規則類型",
                                dataSource: "資料來源",
                                whole: "全部",
                                enterpriseMicroConversation: "企微會話",
                                enterpriseAndMicroCommunication: "企微通話",
                            },
                            components: {
                                flow: {
                                    flow: {
                                        determineNodes: "判斷節點",
                                        dialogueNode: "對話節點",
                                        editNodes: "編輯節點",
                                    },
                                    nodeConfig: {
                                        nodeName: "節點名稱",
                                        nodeRules: "節點規則",
                                        scoringLogic: "評分邏輯",
                                        score: "分值：",
                                        rule: "規則",
                                        multiBranchLogic: "多分支邏輯",
                                        highlight: "高亮",
                                        submit: "提交",
                                    },
                                    ruleList: {
                                        branch: "分支",
                                        branchName: "分支名稱：",
                                        addRules: "新增規則",
                                    },
                                    sidebar: { node: "節點" },
                                    hooks: {
                                        useControlButtons: {
                                            automaticOrganization: "自動整理",
                                            canvasAdaptation: "畫布自我調整",
                                            copy: "複製",
                                            delete: "删除",
                                        },
                                        useFlow: {
                                            determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "判斷節點的子節點不能作為連接目標",
                                            theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "開始節點不能作為連接目標",
                                            startingNodeCannotConnectMultipleNodes:
                        "開始節點不能連接多個節點",
                                            nodeCannotBeConnectedToItself: "節點不能與自己相連接",
                                            startNode: "開始節點",
                                        },
                                    },
                                },
                            },
                        },
                        configuration: {
                            title: "質檢規則",
                            wechatTitle: "分析模型",
                            neme: "名稱",
                            errorMessage: "刪除失敗，至少要有一個條件",
                            desc: "描述",
                            status: "狀態",
                            yes: "有效",
                            no: "無效",
                            namePlaceHolder: "請輸入標簽名稱",
                            desPlaceHolder: "請輸入描述",
                            nameEmpty: "名稱不能為空",
                            desEmpty: "描述不能為空",
                            type: "類型",
                            ruler: "智能規則",
                            machineLearning: "自學習模式",
                            autoScore: "智能評分（舊）",
                            manualScore: "手動評分",
                            interaction: "交互檢測（舊）",
                            typeTips: {
                                model:
                  "智能模型：是預設的一套質檢體系，無需配置維護，能夠快速質檢出服務中存在的問題。該模型較通用，如不滿足業務訴求請使用「智能規則」或」手動評分",
                                rule: "智能規則：即基於規則AI自動進行評分",
                                manual: "手動評分：即人工質檢，由人工手動打分",
                            },
                            model: "模型：",
                            ruleType: {
                                words: "話術",
                                interaction: "交互",
                                feature: "特征",
                                information: "信息",
                            },
                            testingMode: { all: "檢測全部關鍵詞", any: "檢測任意關鍵詞" },
                            operatorTypesOfInteraction: {
                                question: "一問一答",
                                element: "要素回答",
                                repet: "上下文重復",
                                snatch: "搶話",
                                silent: "靜默",
                            },
                            role: "檢測角色：",
                            preposition: "前置算子：",
                            range: "檢測範圍：",
                            from: "第",
                            to: "句至第",
                            end: "句",
                            hasOldSilentTip:
                "您好，為提升準確率，「智能規則-交互-靜默」已升級為「響應超時」，建議更新使用規則！",
                            changeToOld: "切換至舊版",
                            changeToNew: "切換至新版",
                            logic: {
                                logic: "評分邏輯",
                                naTip:
                  "N/A即Not Applicable，为该条质检规则设置前置条件，符合条件时检测，不符合条件时即该规则不适用，则不检测该条规则",
                                score: "分值：",
                                na: "N/A：",
                                naDes: "允許檢測中不涉及此項",
                                preRule: "前置條件規則：",
                                notCatchPreRule: "不滿足前置條件時：",
                                naChecked: "命中N/A時：",
                                preRuleEmpty: "前置條件規則不能為空",
                                operatorEmpty: "算子中必要條件不能為空",
                                multipleEmpty: "多重邏輯評分中必要條件不能為空",
                                average: "組內分數平均",
                                addOrSubtract: "直接加減分",
                                noChange: "不加減分",
                                rule: "規則：",
                                all: "滿足所有算子",
                                any: "滿足任意算子",
                                customize: "自定義算子邏輯",
                                multiple: "多重邏輯評分",
                                heightLight: "高亮：",
                                machineLearningTip:
                  "自學習模式目前僅針對單句話術生效，復雜話術請使用智能規則",
                                strategy: "策略：",
                            },
                        },
                        component: { approvalSubmittedSuccessfully: "審批提交成功" },
                        components: {
                            pageHeaderExtra: { test: "測試", formal: "正式", submit: "提交" },
                        },
                    },
                    automaticAdd: {
                        index: {
                            name: "流程自動化",
                            screeningCondition: "篩選條件",
                            aiScreeningCondition: "智能篩選條件",
                            rule: "規則",
                            automaticAddition: "自動添加",
                            automaticAdditionTip:
                "自动实时或者定时将数据添加至任务进行AI质检",
                            autoAdd: {
                                autoAddType: "自動添加方式",
                                addFrequency: "添加頻率",
                                add: "添加",
                            },
                            automaticSamplingInspection: "自動抽檢",
                            automaticSamplingInspectionTip:
                "自动抽取需要人工质检的数据，例如，每日抽取昨日随机10%的数据进行人工抽检",
                            everyAgentCheckLimit: "每客服抽取上限",
                            automaticAllocation: "自動分配",
                            automaticAllocationTip:
                "抽检分配：抽取的数据自动分配给质检员，例如，每日抽取昨日随机10%的数据进行人工抽检，平均随机分配给此任务的2位质检员",
                            automaticAllocationTip2:
                "复核分配：申诉的数据自动分配给质检员复核，例如，每日申诉的数据平均随机分配给此任务的2位质检员进行复核",
                            automaticPush: "自動推送",
                            automaticPushTip:
                "触发通知：质检时进行通知/告警，例如，当客服出现致命错误时，给企微群内发送通知消息",
                            automaticPushTip2:
                "定时报告：将质检结果以邮件形式发送定时报告，例如，每日9：00将昨日人工抽检的质检结果发送至客服主管邮箱",
                            automaticAssess: "自動評估",
                            automaticAssessTip:
                "定时将人工抽检结果对比AI质检结果进行准确率评估。例如，每日8：00将昨日抽检的数据进行准确率评估",
                            reexaminationComplaint: "申訴復檢",
                            spoktReviewDetail: "业务配置",
                            spoktReviewDetailTip:
                "对质检列表、抽检列表、申诉业务、复核列表、备注等功能配置",
                            qaDetailAction: "質檢配置",
                            spoktDetailAction: "抽檢配置",
                            reviewDetailAction: "復核配置",
                            appealAction: "申訴配置",
                            remarkTemplate: "备注模板",
                            automaticArchiving: "自動歸檔",
                            conditionalRules: "條件規則",
                            qualityInspectionConditions: "質檢條件",
                            unopened: "暫未開啟",
                            sampleInspectionDistributionModel: "抽檢分配",
                            reviewAllocationModel: "復核分配",
                            distributionModelTitle: "分配策略：",
                            pushRules: "推送規則",
                            push: "推送",
                            assessRules: "評估規則",
                            on: "on",
                            off: "off",
                            updateStatusSuccess: "狀態更改成功！",
                            updateStatusFailed: "狀態更改失敗，請稍後重試！",
                            sampleNumberOrPercent: "抽樣條數或比例",
                            inspectorList: "指定質檢員",
                            numberOfSubmission: "允許提交申述次數",
                            rewivewDays: "天",
                            TimeOfSubmission: "提交申述有效期",
                            rewivewSecond: "次",
                            switchOn: "開啟",
                            switchOff: "關閉",
                            notMatchAnyRule: "不匹配任意規則時：",
                            appealOptionsList: {
                                hiddenInspector: "申訴隱藏質檢員信息",
                                hiddenHit: "申訴頁面支持高亮定位命中",
                            },
                            caseLibrary: "案例库同步",
                            caseLibrarySyncTip: "将QA系统添加的案例同步至客服系统",
                            addCase: "增加同步",
                            caseLibraryId: "案例库",
                            caseLibraryIdTip: "QA系统的案例库分组",
                            udeskCaseLibraryIdTip: "客服系统的案例分组",
                            syncHis: "同步历史案例",
                            otherSettings: "其他设置",
                            udeskCaseLibraryId: "推送案例组",
                            url: "推送URL",
                            urlTip: "请输入以http://或https://开头的接口地址",
                        },
                        templateNew: {
                            onlyTheFirstLevelReviewerAllocationStrategy:
                "僅一級覆核員分配策略",
                            secondaryCompounding: "二級複合：",
                            secondLevelReviewersOnlySupportAverageDistribution:
                "二級覆核員僅支持平均分配",
                            secondLevelReviewer: "二級覆核員：",
                            samplingInspectionTarget: "抽檢目標",
                            setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "設定人工抽檢應完成的目標，目標未達成或者超過時給出提示，例如每客服每月應完成抽檢100條，低於80條時，質檢、抽檢頁面進行提示。",
                        },
                        component: {
                            pleaseAddASecondLevelQualityInspector: "請添加二級質檢員",
                        },
                    },
                    roleConfig: {
                        index: {
                            name: "角色配置",
                            roleConfigTip:
                "用于对导入的通话数据为单声道时，为话者分离处理配置识别规则。符合规则的为客服角色",
                            operator: "算子",
                            operatorLogic: "算子邏輯",
                        },
                    },
                    manualAdd: {
                        index: {
                            name: "手動添加",
                            tip: "用于通过手动配置条件筛选预览数据以及快速将其加入所处质检任务",
                            screeningCondition: "按照篩選條件添加",
                            aiScreeningCondition: "智能篩選條件",
                            rule: "規則",
                            createTime: "添加時間",
                            filterTitle: "篩選內容",
                            filterRule: "篩選條件",
                            startTime: "開始時間",
                            endTime: "結束時間",
                            records: "導入記錄",
                            createUserName: "操作人員",
                            successCount: "添加成功",
                            conditionRule: "條件規則：",
                            preview: "預覽",
                            previewData:
                "當前符合篩選條件{0}條，已加入任務{1}條，可加入任務{2}條，不可加入任務{3}條",
                            failCount: "添加失敗",
                        },
                    },
                    basicInfo: {
                        index: {
                            title: "基本信息",
                            name: "名稱",
                            taskName: "任務名稱",
                            taskNamePlaceholder: "請輸入任務名稱",
                            requiredName: "任務名稱不能為空",
                            taskRemarkPlaceholder: "請輸入任務描述",
                            requiredRemark: "任務描述不能為空",
                            remark: "描述",
                            taskRemark: "任務描述",
                            source: "數據源",
                            benchmarkScore: "基準分",
                            benchmarkScorePlaceholder: "請輸入基準分",
                            scoreLowerLimit: "最低得分下限",
                            scoreUpperLimit: "最高得分上限",
                            inspectType: "質檢類型",
                            nlpModel: "NLP模型",
                            commonInspect: "常規質檢",
                            associateInspect: "關聯質檢",
                            associateCycle: "關聯周期",
                            associateRegulation: "關聯規則",
                            judgeStrategy: "執行邏輯",
                            rule: "規則",
                            commonTip:
                "常規質檢：適用於單次通、對話，將單次溝通的內容進行質檢",
                            associateTip:
                "關聯質檢：適用於多次的通、對話，將多次溝通的內容關聯起來進行質檢",
                            cycleTip:
                "如果關聯的多個通、對話，符合關聯周期內的數據進入該任務質檢",
                            scoreLowerLimitPlaceholder: "請輸入最低得分下限",
                            scoreUpperLimitPlaceholder: "請輸入最高得分上限",
                            call: "語音通話",
                            dialogue: "文本對話",
                            user: "質檢員",
                            wechatUser: "操作員",
                            noDesignated: "不指定",
                            type: "表單類型",
                            singleScore: "單項分數",
                            conduct: "請先完善下列信息，完成後請點擊下一步",
                            conduct2:
                "請完成質檢規則與質檢點的配置,若您已完成配置,您可以點擊下一步或退出引導",
                            conduct3:
                "質檢評級是依據質檢點與其評分情況進行評級的配置,您可以定義不同的質檢結果級別,若您對評級沒有需求您可以直接跳過此步驟,若您已完成配置,您可以點擊下一步或退出引導",
                            conduct4:
                "您可以在自動添加位置按照本質檢任務應當檢查的數據特性,對將會被自動引入任務的數據特性進行配置,若您暫時不需要自動添加數據,可以點擊跳過,若您已完成配置,您可以點擊下一步",
                            conduct5:
                "若您使用的是單聲道錄音,請您務必配置此項並點擊下一步,請使用僅客服會使用的話術幫助系統識別出客服與客戶的不同軌道.若您使用雙聲道錄音或已完成配置,您可以點擊跳過",
                            conduct6:
                "您可以通過手動添加功能,引入系統裏已有的通對話數據進行質檢,或您已提交手動添加任務,請您直接點擊完成",
                            helper: "查看幫助文檔",
                            quit: "退出引導",
                            next: "下一步",
                            complete: "完成",
                            skip: "跳過",
                            cancel: "取消",
                            delete: "刪除",
                            save: "保存",
                        },
                        template: { sessionType: "會話類型" },
                    },
                },
                workbench: {
                    qualityCheckList: {
                        index: {
                            name: "質檢工作臺",
                            search: "篩選",
                            export: "導出",
                            condition: "條件",
                            logic: "邏輯",
                            reQualityInspection: "重新質檢",
                            reQualityInspectionUnderWay: "重新質檢任務中...",
                            reQualityInspectionUnderWayTip:
                "關閉窗口後離開頁面不會影響任務進行",
                            reQualityInspectionDone: "重新質檢已完成",
                            reQualityInspectionDoneTip:
                "現已成功完成重新質檢，請點擊確認按鈕",
                            endReQualityInspection: "終止任務",
                            alreadyDoneTip: "已完成",
                            totalTip: "合計",
                            unit: "條",
                            endReInspectionSuccess: "重新質檢任務已終止",
                            closeWindow: "關閉窗口",
                            endReInspectionFailed: "重新質檢任務失敗，請稍後重試!",
                        },
                        detail: {
                            name: "質檢詳情",
                            reviewName: "復核詳情",
                            spotCheckName: "抽檢詳情",
                            audioText: "錄音文本",
                            callInfo: "通話信息",
                            satisfactionEvaluation: "滿意度評價：",
                            queueTime: "排隊耗時：",
                            ringingTime: "振鈴時間：",
                            callTime: "通話事件：",
                            hangUpIdentity: "通話掛斷方：",
                            location: "歸屬地：",
                            source: "來源：",
                            agent: "客服：",
                            deviceType: "設備類型：",
                            callType: "通話類型：",
                            phoneNumber: "中繼號：",
                            task: "任務：",
                            commentsTip: "請輸入質檢評語",
                            lastPage: "上一頁",
                            nextPage: "下一頁",
                            score: "質檢評分",
                            review: "復核",
                            file: "歸檔",
                            changeList: "變更記錄",
                            fullMarks: "滿分",
                            most: "最多",
                            saveReview: "復核提交",
                            saveSpotCheck: "抽檢提交",
                            point: "質檢點",
                            sampleAssignInspector: "分配抽檢員",
                            reviewAssignInspector: "分配復核員",
                            sampleStatus: "抽檢狀態",
                            read: "已讀",
                            user: "用戶",
                            confirmer: "確認人",
                            voiceDownloadError: "語音下載失敗，請稍後重試！",
                            smartTags: "智能標簽",
                            interactiveTip: "使用交互識別對客服FAQ的使用情況進行檢測",
                            interactiveIsEmptyTip: "未檢測到交互識別內容",
                            businessRecords: "業務記錄",
                        },
                    },
                },
                reports: {
                    groupChatAnalysis: {
                        index: { analysisOfEnterpriseWeChatGroupChat: "企業微信群聊分析" },
                    },
                },
            },
            cards: {
                health: "質檢健康度",
                accuracy: "發現核準率",
                sessionsTotal: "會話總數",
                enable: "啟用",
                disable: "禁用",
                none: "暫無",
                call: "通話",
                dialogue: "對話",
                taobao: "电商",
                wecom: "会话",
                ticket: "工单",
            },
            component: {
                backup: "（備份）",
                taskSaveAsSucceeded: "任務另存為成功",
                copyContent: "複製內容",
                essentialInformation: "基本資訊",
                ruleConfiguration: "規則配寘",
                basicInformationQualityInspector: "基本資訊-質檢員",
                qualityInspectionRating: "質檢評級",
                roleConfiguration: "角色配寘",
                autoAddConfiguration: "自動添加配寘",
                autoPushConfiguration: "自動推送配寘",
                automaticSamplingConfiguration: "自動抽檢配寘",
                automaticAllocationConfiguration: "自動分配配寘",
                businessConfiguration: "業務配寘",
            },
            template: {
                ruleApproval: "規則審批",
                enterpriseMicroConversationAnalysisTask: "企微會話分析任務",
                enterpriseAndMicroVoiceAnalysisTask: "企微語音分析任務",
                saveTaskAs: "任務另存為",
            },
            approve: {
                checkPoint: { index: { ruleDetails: "規則詳情" } },
                components: {
                    autoApproveSwitch: {
                        modificationSucceeded: "修改成功",
                        automaticApproval: "自動審批",
                    },
                    pageHeaderExtra: {
                        modificationSucceeded: "修改成功",
                        test: "測試",
                        formal: "正式",
                        adopt: "通過",
                    },
                    rejectButton: {
                        index: {
                            areYouSureToReject: "確定駁回嗎",
                            pleaseEnterTheRejectionReason: "請輸入駁回原因",
                            reject: "駁回",
                        },
                    },
                    table: {
                        index: {
                            modificationSucceeded: "修改成功",
                            taskName: "任務名稱",
                            dataSource: "資料來源",
                            qualityInspectionType: "質檢類型",
                            state: "狀態",
                            updateTime: "更新時間",
                            updatedBy: "更新人",
                            approver: "審批人",
                            reasonForRejection: "駁回原因",
                            operation: "操作",
                            details: "詳情",
                            adopt: "通過",
                            whole: "全部",
                            approvalStatus: "審批狀態",
                        },
                    },
                },
            },
        },
        dashboard: {
            title: "概览",
            hi: "嗨，",
            standardEdition: "标准版",
            call: "语音质检余额",
            text: "文本质检有效期",
            collectionTasks: "收藏的质检任务",
            noCollection: "暂无收藏的质检任务，",
            goToCollecte: "去收藏",
            yesterdayOverView: "昨日业务统计",
            yesterdayAgent: "昨日客服排行榜",
            yesterdayInspWork: "昨日质检员工作情况",
            operationalStatistics: {
                title: "业务统计",
                qualityTesting: "质检",
                spotCheck: "抽检",
                appeal: "申诉",
                chartTitles: {
                    qualityTestingTrend: "质检量趋势",
                    qualityTestingAnalysis: "质检任务情况",
                    spotCheckTrend: "抽检趋势",
                    spotCheckAnalysis: "抽检任务情况",
                    appealTrend: "申诉趋势",
                    appealAnalysis: "申诉任务情况",
                },
                titles: {
                    qualityDataTend: "质检数据分布",
                    qualityCover: "质检覆盖情况",
                    qualityAverageTime: "平均质检等待时长",
                    qualityCoverTend: "质检覆盖趋势",
                    handCover: "人工处理情况",
                    checkCover: "抽检覆盖率",
                    resAverageTime: "平均处理时长",
                    handTend: "人工处理趋势",
                    dataTend: "数据任务分布",
                },
                qualityAverage: "质检平均分",
                scoreTrend: "质检评分趋势",
                qualityAverageTime: "平均质检等待时长",
                callCoverage: "语音质检覆盖率",
                textCoverage: "文本质检覆盖率",
                checkCount: "抽检数据量",
                siphonCount: "抽取数据量",
                reviewCount: "复核数据量",
                appealCount: "申诉数据量",
                checkRate: "抽检执行比例",
                reviewRate: "申诉复核比例",
                PieTitle: {
                    totalCount: "通对话总数",
                    totalCall: "语音通话数",
                    totalIm: "文本对话数",
                    inspectionCall: "语音质检量",
                    inspectionIm: "文本质检量",
                    checkCall: "语音抽检量",
                    unCheckCall: "语音未抽检量",
                    checkIm: "文本抽检量",
                    unCheckIm: "文本未抽检量",
                    reviewCall: "语音复核量",
                    reviewIm: "文本复核量",
                    unReviewCall: "语音未复核量",
                    unReviewIm: "文本未复核量",
                    unInspectionCall: "语音未质检量",
                    unInspectionIm: "文本未质检量",
                },
                check_rate: "抽检覆盖率",
                voice_check_rate: "语音抽检覆盖率",
                text_check_rate: "文本抽检覆盖率",
                sample_execute_time: "平均抽检处理时长",
                review_execute_time: "平均申诉处理时长",
            },
            analysisOverview: {
                tableTitle: { conditions: "质检规则看板", agent: "客服排行榜" },
                tableTips: {
                    conditions:
            "<div>对质检规则违规、合规的通对话总数进行排序。<br />\n                    质检项：质检点的名称<br />\n                    违规通对话总数：在该质检项上违规的通对话总数<br />\n                    违规占比：违规占比=违规通对话总数/通对话总数</div>",
                    agent:
            "<div>对坐席的平均评分进行排序<br />\n                    客服名称：客服的名称<br />\n                    客服组：客服所在客服组（客服信息内的组织信息）<br />\n                    评分的通对话总数：该客服的质检总通对话数<br />\n                    质检平均得分：该客服的质检平均得分（得分求和/通对话总数）</div>",
                },
                tips: {
                    total: "进入质检任务的通/对话总数",
                    agentTotal: "进入质检任务的质检客服总数",
                    avg: "质检评分的平均分",
                    violation: "所有的通对话质检命中质检项违规的次数求和",
                    compliance: "通对话质检过程中命中质检项合规的次数求和",
                    check: "人工抽检的总数",
                    appeal: "通对话总数中提交过申诉的总数",
                    review: "通对话总数中提交过复核的总数",
                },
                subTips: {
                    violation: "违规率：违规总次数/质检项总次数*100%",
                    compliance: "合规率：合规总次数/质检项总次数*100%",
                    check: "抽检覆盖率：人工抽检总数/质检总量*100%",
                    appeal: "申诉率：申诉总数/质检总量*100%",
                    review: "复核率：复核总数/质检总量*100%",
                },
            },
        },
        admin: {
            system: {
                index: {
                    name: "系統設置",
                    systemName: "系統名稱",
                    speakerRecognize: "話者識別",
                    massage: "消息通知",
                    evaluate: "人工認知評價",
                    evaluateTip: "开启后在质检详情中选择快速抽检会提示进行人工评价",
                    speakerRecognizeTip:
            "通过API接入第三方的通话录音需要标识左声道是客服还是客户",
                    speakerRecognizeTip2:
            "单通道录音通过文本识别，角色配置定义客服的角色",
                    enabledImSendStatusFilter: "文本質檢消息過濾",
                    enabledImSendStatusFilterTips:
            "質檢的算子話術的檢測內容不包含狀態為【已撤回】、【發送失敗】的消息",
                    lang: "系統語言設置",
                    zh: "简体中文",
                    en: "English",
                    tw: "繁体中文",
                },
                template: {
                    textDataDesensitization: "文字數據脫敏",
                    whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "開關開啟則展示脫敏欄位下拉清單（支持多選），脫敏欄位：人名、手機號、地址",
                    desensitizingField: "脫敏欄位",
                    name: "人名",
                    cellphoneNumber: "手機號",
                    address: "地址",
                    dataCleaning: "數據清理",
                    afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "設定後，質檢通對話、企業微信會話數據將定期被清理，且不可恢復，請謹慎操作",
                    newCleaningRules: "新增清理規則",
                    qualityInspectionRuleApproval: "質檢規則審批",
                    afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "開啟後，質檢規則修改需要被審批通過後才能生效",
                    maskOfflineMessageUnread: "遮罩離線消息未讀",
                    privacyNumberProtection: "隐私号码保护",
                    afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            "开启后对通话中”客户电话”字段进行脱敏，显示为1881*******",
                    shieldingWithdrawn: "屏蔽已撤回",
                    maskSendingFailed: "屏蔽发送失败",
                },
                component: {
                    conversation: "通話",
                    dialogue: "對話",
                    weCom: "企業微信",
                    day: "日",
                    week: "周",
                    month: "月",
                    year: "年",
                },
                components: {
                    dataDeleteConfigItem: {
                        scopeOfCleaning: "清理範圍",
                        dataCleaningTimeSetting: "數據清理時間設定",
                        afterCleanUpTheData: "後，清理數據",
                        dataAfterComplaintCompletion: "申訴完成後數據",
                    },
                },
            },
            systemIntegration: {
                title: "系統設置",
                ccIntegrationTips: "智能質檢對接Udesk呼叫中心說明文檔",
                unauthorized: "未授權",
                authorized: "已授權",
                udeskDomain: "客服系統域名",
                udeskToken: "客服系統密鑰",
                udeskAdminEmail: "客服系統管理員帳號",
                password: "管理員帳號密碼",
                applyAuth: "授權",
                cancelAuth: "取消授權",
                reApplyAuth: "重新授權",
                keyTips: "客服系統後臺管理-單點登錄中的API鑒權Token",
            },
            customerField: {
                title: "客戶字段",
                table: {
                    propName: "字段名稱",
                    propDesc: "字段描述",
                    valueType: "字段類型",
                    propType: "創建方式",
                    createTime: "創建時間",
                    canNotEdit: "系統字段不支持編輯",
                },
                edit: "編輯字段",
                new: "新建字段",
            },
            wechatDocking: {
                title: "企微對接",
                formLabels: {
                    companyId: "企業ID",
                    communicationKey: "通訊密鑰",
                    token: "Token",
                    encodingAESKey: "EncodingAESKey",
                    url: "事件回調URL",
                    applicationName: "應用名稱",
                    applicationId: "網頁應用ID",
                    applicationSecret: "應用secret",
                },
                formTitle: {
                    initialForm: "初始設置",
                    synchronizeForm: "通訊錄同步",
                    settingForm: "應用設置",
                },
                qrCodeUrlTip: "请管理员扫描下方二维码进行代开发应用绑定",
            },
            taobao: {
                formLabels: {
                    name: "门店名称",
                    account: "账号",
                    password: "密码",
                    confirm: "确认密码",
                    authorization: "授权",
                    switchOn: "开启",
                    switchOff: "关闭",
                },
                tableLabels: {
                    name: "门店名称",
                    account: "账号",
                    authorization: "授权",
                    updateUser: "最近更新人",
                    updateTime: "最近更新时间",
                },
            },
            weifengDocking: {
                formLabels: {
                    secondaryDomain: "微丰二级域名",
                    secretKeyTip: "租户秘钥，在微丰系统后台系统对接处获取",
                },
            },
            user: {
                index: {
                    name: "員工管理",
                    existingUsers: "已有員工",
                    deleteSuccess: "刪除成功",
                    deleteFailed: "刪除失敗",
                },
                detail: {
                    accountPrompt: "請輸入郵箱",
                    resetPassword: "重置密碼",
                    passwordPrompt: "密碼（6-14位字母與數字組合）",
                    originPasswordPrompt: "請輸入初始密碼",
                    resumePasswordPrompt: "請重新輸入密碼",
                    passwordFormWrong: "請輸入6-14位字母與數字組合的密碼！",
                    resumePasswordWrong: "兩次密碼不一致！",
                    suppertImageType: "支持jpg、gif、 png ，最大 500Kb",
                    namePrompt: "請輸入姓名",
                    nickNamePrompt: "請輸入昵稱",
                    telephonePrompt: "請輸入電話號碼",
                    roleSelectedPrompt: "請選擇角色",
                    agentIdPrompt: "請輸入工號",
                    disabled: "禁用",
                    enabled: "啟用",
                    reloadBowerInfo: "用戶信息變更，需要重新刷新瀏覽器嗎？",
                    saveUserError: "用戶信息保存失敗，請稍後重試",
                    resetPasswordSuccess: "密碼重置成功",
                    resetPasswordFailed: "密碼重置失敗，請稍後重試",
                },
            },
            userGroups: {
                index: { name: "員工組管理", existingUserGroups: "已有員工組" },
                detail: {},
            },
            role: {
                index: { name: "角色管理", existingRoles: "已有角色" },
                edit: {
                    editRole: "編輯角色",
                    createRole: "創建角色",
                    basicInfo: "基本信息",
                    taskPermissions: "任務權限",
                    existingTasks: "已有任務",
                },
            },
            anomalousData: {
                title: "異常數據",
                list: {
                    selectHolder: "請選擇數據源",
                    businessTime: "業務時間",
                    businessId: "業務Id",
                    export: "導出",
                },
            },
            templateComponent: {
                list: {
                    title: "模板市場",
                    name: "模板名稱",
                    searchHolder: "請輸入要查詢的內容",
                    update: "更新情況",
                    sort: "排序方式",
                    moduleType: "模板類型",
                    moduleSourceType: "適用類型",
                    fetchNum: "獲取次數",
                    newest: "最近更新",
                    usedMost: "獲取最多",
                    call: "通話",
                    dialogue: "對話",
                    waiting: "待審核",
                    cancel: "取消發布",
                    success: "審核通過，已發布",
                    failed: "審核駁回",
                    no: "不可升級",
                    ok: "可以升級",
                    all: "全部",
                    delete: "刪除成功",
                    downOk: "下架成功",
                    withdraw: "撤回成功",
                    updateOk: "升級成功",
                    get: "獲取",
                    width: "撤回",
                    getOk: "獲取成功",
                    orgName: "所屬組織",
                    nowVersion: "當前版本",
                    lastedVersion: "最新版本",
                    appStatus: "應用狀態",
                    actions: "操作",
                    down: "下架",
                    updateTop: "升級",
                    check: "查看",
                    deleteWhen: "確認刪除嗎？",
                    yes: "是",
                    noo: "否",
                    deleteOk: "刪除",
                    permissible: "允許",
                    noPermissible: "不允許",
                    openUp: "開放",
                    openDown: "不開放",
                    task: "質檢任務",
                    point: "質檢點",
                    tag: "語義標簽",
                    muti: "多要素",
                    inter: "一問一答",
                    info: "信息實體",
                    concept: "概念詞",
                    detail: "模組詳情",
                    infomation: "基本信息",
                    detailConfig: "詳情配置",
                    twoEdit: "二次編輯",
                    mustUpdate: "立即升級",
                    descition: "模板描述",
                    descitionSize: "模板描述不能超過250個字",
                    stencilsDetail: "模板詳情",
                    updatedRecords: "更新記錄",
                    version: "版本號",
                    updateDescription: "更新描述",
                    myModule: "我的模板",
                    addModule: "新建模組",
                    publishVersion: "發布版本",
                    earliestVersion: "最早版本",
                    edit: "編輯",
                    add: "新增",
                    configNo: "模組配置詳情不能為空",
                    updateNo: "更新說明不能為空",
                    editSuccess: "修改成功",
                    saveSuccess: "保存成功",
                    newer: "更新",
                    sumbit: "提交",
                    cancelName: "取消",
                    nameRequired: "模組名稱是必填字段",
                    nameSize: "模組名稱不能超過16個字",
                    publishStatus: "發布狀態",
                    submitUpdate: "提交更新",
                    updateVersion: "更新版本",
                    updateExplain: "更新說明",
                },
            },
        },
        demo: {
            title: "智能質檢",
            template: "選擇質檢模板",
            radio: "選擇錄音形式",
            uploadAudio: "上傳錄音",
            uploadAudioTip: "上傳錄音僅支持wav和mp3格式，大小不超過1G",
            uploadText: "上傳文本",
            uploadTextTip:
        "上傳文本僅支持srt和txt格式，大小不超過10M，上傳文本後將不再使用ASR解析錄音文件",
            uploadSuccess: "錄音文件上傳成功",
            uploadFailed: "錄音文件上傳失敗",
            startQualityInspection: "開始質檢",
            qualityInspectionStarted: "正在質檢中",
            downloadText: "文本下載",
            checking: "質檢進度",
            checkingTip: "預計需要數分鐘，請耐心等待",
            noCheckContent: "尚無質檢結果",
            noTextDownload: "暫無質檢文本可下載!",
            pleaseCheckParams: "請檢查是否選擇模板、語音類型和上傳錄音文件",
            inspectionFailed: "質檢失敗，請稍後重試!",
        },
        wechatQa: {
            baseInfo: { title: "基本信息" },
            conversationSetting: {
                title: "會話設置",
                segmentationStrategy: "切分策略",
                rule: "策略",
                time: "切分時間",
                daily: "每天",
                passScore: "會話合格分",
                ruleLogic: "執行邏輯",
            },
            message: {
                title: "推送事件",
                table: {
                    index: "序號",
                    name: "名稱",
                    type: "推送方式",
                    desc: "描述",
                    status: "狀態",
                },
                subTitle: "事件列表",
            },
            checkPoint: {
                title: "分析模型",
                groups: "模型分組",
                newClassification: "新建分組",
                editClassification: "編輯分組",
                benchmarkScore: "基準分",
                pointCount: "當前分類下共有分析模型",
                openPointCount: "啟用數量為",
                closedPointCount: "停用數量為",
                scoreLowerLimit: "最低得分下限",
                scoreUpperLimit: "最高得分上限",
                classificationName: "分組名稱",
                classificationNamePlaceHolder: "請輸入分組名稱",
                classificationRemark: "分組描述",
                classificationRemarkPlaceHolder: "請輸入分組描述",
            },
            chartRecord: {
                title: "企業微信會話",
                table: {
                    id: "會話ID",
                    type: "會話類型",
                    startTime: "會話開始時間",
                    endTime: "會話最近結束時間",
                    groupId: "群ID",
                    client: "客戶",
                    customer: "客服",
                    averageScore: "平均質檢評分",
                },
                detail: {
                    title: "企業微信會話詳情",
                    inspectionInfo: "分析信息",
                    chartInfo: "會話信息",
                    tasks: "分析任務",
                    avgScore: "分析平均分",
                    qualified: "合格",
                    unqualified: "不合格",
                    taskTitle: "適用分析模板",
                    info: "實時分析信息",
                    today: "今日消息",
                    history: "歷史消息",
                    components: {
                        filter: {
                            index: {
                                pleaseEnterACommentNickname: "請輸入備註/昵稱",
                                pleaseEnterTheGroupChatName: "請輸入群聊名稱",
                            },
                        },
                    },
                    template: { whole: "全部" },
                },
            },
            realTimeAnalysis: {
                title: "實時分析",
                table: {
                    name: "場景名稱",
                    count: "分析模型數量",
                    baseScore: "基準分",
                    passScore: "合格分",
                    maxScore: "最高得分上限",
                    minScore: "最低得分下限",
                    status: "狀態",
                    actions: "操作",
                },
                detail: {
                    title: "實時分析場景",
                    form: {
                        name: "場景名稱",
                        prevCondition: "場景觸發條件",
                        prevConditionToolTip: "若條件為空，則默認對此場景進行分析",
                        prevConditionError: "場景觸發條件不能與分析模型重合",
                        scoreInputPlaceholder: "輸入分數",
                        baseScore: "基準分",
                        status: "狀態",
                        passScore: "合格分",
                        maxScore: "最高分上限",
                        minScore: "最低分下限",
                        analysisModel: "分析模型",
                        message: "觸發通知",
                        pushType: "推送方式",
                        createAnalysisModel: "添加模型",
                        scoreUpperLimitError: "最低分下限不能大於最高分上限",
                        table: { name: "模型名稱", rule: "規則", score: "分值" },
                        pushConfigSwitchTip1: "當該場景出現",
                        pushConfigSwitchTip2: "次及以上時，推送通知",
                    },
                },
            },
            smartCustomerTag: {
                title: "智能客户标签",
                table: {
                    name: "规则名称",
                    tags: "企微客户标签",
                    status: "启用状态",
                    createUser: "创建人",
                    createTime: "创建时间",
                },
                detail: {
                    name: "规则名称",
                    condition: "设置规则",
                    logic: "逻辑",
                    status: "启用状态",
                    tags: "选择标签",
                },
            },
            setting: {
                wechatQaCustomerLabel: {
                    template: { numberOfCustomerTags: "客户标记数量" },
                },
            },
            chartAudioRecord: {
                index: {
                    talkTime: "通话时间",
                    sessionType: "会话类型",
                    whole: "全部",
                    staff: "员工",
                    customer: "客户",
                    callKeywords: "通话关键词",
                },
                detail: {
                    template: { enterpriseMicroVoiceCallDetails: "企微语音通话详情" },
                },
            },
            wechatQaChartRecordEntry: {
                sessionArchiving: "会话存档",
                voiceCall: "语音通话",
                noMoreVoiceCalls: "暂无更多语音通话",
                enterpriseWechatVoiceCall: "企业微信语音通话",
                enterpriseWechatConversationDetails: "企业微信会话详情",
                previous: "上一条",
                next: "下一条",
            },
            dashboard: {
                template: {
                    enterpriseMicroConversationAnalysisTask: "企微會話分析任務",
                    enterpriseAndMicroVoiceAnalysisTask: "企微語音分析任務",
                },
            },
            wechatQaTaskManagerEntry: {
                analysisOfEnterpriseWeChatGroupChat: "企業微信群聊分析",
            },
        },
        businessAnalysis: {
            keyword: "報表名稱",
            createUser: "創建人",
            createTime: "創建時間",
            updateTime: "更新時間",
        },
        semanticIntelligence: {
            clusterAnalysis: "聚類分析",
            speechMining: "話術挖掘",
            informationEntities: {
                detail: {
                    batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "支持通过API接口批量导入信息实体，该API名称保存后不支持编辑",
                },
                component: { nothing: "無" },
            },
            knowledgeBase: {
                index: {
                    classification: "分類",
                    import: "導入",
                    importKnowledgeBase: "導入知識庫",
                    moveKnowledgePointsInBatch: "批量移动知识点",
                    moveTo: "移动到：",
                    pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "请选择需要批量移动的知识点",
                },
                detail: { index: { intrasentence: "句内", afterProblem: "问题后" } },
            },
            semanticTags: { component: { nothing: "無" } },
            smartWordsLibrary: {
                template: {
                    sensitiveWords: "敏感詞",
                    sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "敏感詞：敏感詞可配置業務關注詞，用於輿情發現敏感詞詞雲",
                },
                sensitiveWords: {
                    component: {
                        newSensitiveWord: "新建敏感詞",
                        editSensitiveWords: "編輯敏感詞",
                        sensitiveWords: "敏感詞",
                    },
                    template: {
                        sensitiveWords: "敏感詞",
                        sensitiveWordsCannotBeEmpty: "敏感詞不能為空",
                        pleaseEnterSensitiveWordsSeparatedBy:
              "請輸入敏感詞，多個敏感詞中間用‘，’隔開",
                    },
                },
            },
            clusteringAnalysis: {
                add: {
                    index: {
                        pleaseSelectAnApplicationScenario: "請選擇應用場景",
                        keyWord: "關鍵字",
                        pleaseEnterKeywords: "請輸入關鍵字",
                        keywordLengthWithinCharacters: "關鍵字長度40字元內",
                        dataSources: "數據來源",
                        pleaseSelectADataSource: "請選擇數據來源",
                        theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "當前聚類已完成，重新編輯將產生一條新的聚類數據，不會覆蓋已完成的聚類數據，請確認",
                        savingSucceeded: "保存成功",
                        clusteringStart: "聚類開始",
                        clustering: "聚類",
                        edit: "編輯",
                        newlyBuild: "新建",
                        clusterName: "聚類名稱",
                        pleaseEnterAName: "請輸入名稱",
                        withinCharactersOfTheName: "名稱長度40字元內",
                        speaker: "說話人",
                        pleaseSelectASpeaker: "請選擇說話人",
                        sessionDate: "會話日期",
                        pleaseEnterTheSessionDate: "請輸入會話日期",
                        selectUpToMonthOfHistoricalSessionData:
              "最多選擇1個月的歷史會話數據",
                        automaticallyClusterDailyNewData: "自動聚類每日新增數據",
                        conversationChannel: "會話通路",
                        pleaseSelectAConversationChannel: "請選擇會話通路",
                        applicationScenario: "應用場景",
                        clusteringRules: "聚類規則",
                        incompleteClusteringRuleSettings: "聚類規則設定不完整",
                        startClustering: "開始聚類",
                    },
                    components: {
                        conditionItem: {
                            pleaseSelectAQualityInspectionTask: "請選擇質檢任務",
                            beEqualTo: "等於",
                        },
                    },
                },
                detail: {
                    index: {
                        theme: "主題",
                        numberOfCorpora: "語料數",
                        corpusCoverage: "語料覆蓋",
                        clusterDetails: "聚類詳情",
                    },
                    ranking: {
                        hotWords: "熱詞",
                        numberOfCorpora: "語料數",
                        speaker: "說話人",
                        sit: "坐",
                        passenger: "客",
                        hotSentence: "熱句",
                        corpusDetails: "語料詳情",
                    },
                },
                index: {
                    delete: "删除",
                    deletionSucceeded: "删除成功",
                    clusterName: "聚類名稱",
                    speaker: "說話人",
                    creationTime: "創建時間",
                    state: "狀態",
                    operation: "操作",
                    see: "查看",
                    edit: "編輯",
                    clusterAnalysis: "聚類分析",
                    newCluster: "新建聚類",
                    clusteringStatus: "聚類狀態",
                },
            },
            wordsMining: {
                dissent: {
                    components: {
                        pageLeftTable: {
                            enterKeywordsToSearchForObjections: "輸入關鍵字搜索抗告",
                        },
                        pageRightTable: {
                            columnHeader: {
                                scriptLibrary: "話術庫",
                                recommendedScripts: "推薦話術",
                                common: "共",
                                strip: "條",
                                scriptFavorites: "話術我的最愛",
                            },
                            conditionPostfix: {
                                detectRoles: "檢測角色",
                                detectionRange: "檢測範圍",
                                sentence: "句",
                            },
                            customerFilter: { scriptFiltering: "話術過濾", cancel: "取消" },
                            index: {
                                viewDetails: "查看詳情",
                                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "您確認要捨棄嗎？ 捨棄後將不能再恢復",
                                abandonment: "捨棄",
                                deletionSucceeded: "删除成功",
                                passenger: "客",
                                sit: "坐",
                                adopted: "已採納",
                                determine: "確定",
                                cancel: "取消",
                                adopt: "採納",
                            },
                            pageRightFrom: {
                                savingSucceeded: "保存成功",
                                pleaseEnterTheScriptContent: "請輸入話術內容",
                                pleaseSelectAFavoriteGroup: "請選擇我的最愛分組",
                            },
                        },
                    },
                    favorite: {
                        components: {
                            pageLeftTable: {
                                objectionHandlingScriptLibrary: "抗告處理話術庫",
                            },
                            pageRightTable: {
                                index: { pleaseChooseToAdoptScript: "請選擇採納話術" },
                            },
                        },
                    },
                },
                favoriteView: {
                    components: {
                        pageLeftTable: {
                            objectionHandlingScriptFavorites: "抗告處理話術我的最愛",
                        },
                        pageRightTable: {
                            likeCancel: "點贊取消",
                            likeSuccessfully: "點贊成功",
                            adoptScripts: "採納話術",
                            operation: "操作",
                            viewConversationDetails: "查看對話詳情",
                        },
                    },
                    sellingPoint: {
                        components: {
                            pageLeftTable: {
                                productSellingPointScriptLibrary: "產品賣點話術庫",
                            },
                            pageRightTable: { keyPointsOfScript: "話術要點" },
                        },
                    },
                },
                favorite: {
                    components: {
                        pageLeftTable: {
                            objectionHandlingScriptFavorites: "抗告處理話術我的最愛",
                            newGroup: "新建分組",
                            editGroup: "編輯分組",
                            addGroup: "添加分組",
                            savingSucceeded: "保存成功",
                            groupName: "分組名稱",
                            pleaseEnterTheGroupName: "請輸入分組名稱",
                            groupNameWithinCharacters: "分組名稱長度20字元內",
                            deletionSucceeded: "删除成功",
                        },
                        pageRightTable: {
                            configDrawer: {
                                index: { scriptRecommendationSettings: "話術推薦設定" },
                                rightForm: {
                                    savingSucceeded: "保存成功",
                                    intelligentRecommendation: "智慧推薦",
                                    theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "將已採納的優秀話術自動顯示在相似抗告的會話側邊欄，員工在會話複盤詳情頁可直接查看推薦話術進行學習",
                                    objectionsIncludedByDefault: "默認包含的抗告",
                                    addRecommendationTriggerSemantics: "新增推薦觸發語義",
                                    remindEmployeesToView: "提醒員工查看",
                                    shareWithMoreEmployees: "分享給更多員工",
                                    allHands: "全體員工",
                                },
                                smartToolbar: { selectSemanticTag: "選擇語義標籤" },
                            },
                            index: {
                                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "是否匯出當前分組下所有的數據記錄？",
                                newScript: "新建話術",
                                editingScript: "編輯話術",
                                savingSucceeded: "保存成功",
                                objection: "抗告",
                                pleaseEnterAScriptObjection: "請輸入話術抗告",
                                speechTechnique: "話術",
                                pleaseEnterTheScriptContent: "請輸入話術內容",
                                grouping: "分組",
                                pleaseSelectAFavoriteGroup: "請選擇我的最愛分組",
                                adoptScripts: "採納話術",
                                passenger: "客",
                                sit: "坐",
                                numberOfViews: "查看次數",
                                useful: "有用",
                                operation: "操作",
                                edit: "編輯",
                                delete: "删除",
                                deletionSucceeded: "删除成功",
                                scriptRecommendationSettings: "話術推薦設定",
                            },
                        },
                    },
                },
                index: {
                    scriptLibrary: "話術庫",
                    scriptMining: "話術挖掘",
                    objectionHandlingScripts: "抗告處理話術",
                    productSellingPointScripts: "產品賣點話術",
                },
                sellingPoint: {
                    index: { newCluster: "新建聚類" },
                    components: {
                        callDetailModal: {
                            sessionDetails: "會話詳情",
                            cancel: "取消",
                            determine: "確定",
                            choice: "選擇",
                        },
                        pageCenterTable: { scriptOverview: "話術概況" },
                        pageLeftTable: {
                            enterSearchKeywords: "輸入蒐索關鍵字",
                            keyWord: "關鍵字",
                        },
                        pageRightTable: {
                            index: {
                                viewDetails: "查看詳情",
                                scriptLibrary: "話術庫",
                                scriptRefinement: "話術提煉",
                                adoptScript: "採納話術",
                            },
                            tagsEdit: { keyPointsOfScript: "話術要點" },
                        },
                    },
                    favorite: {
                        components: {
                            pageLeftTable: {
                                productSellingPointScriptLibrary: "產品賣點話術庫",
                            },
                            pageRightTable: {
                                configDrawer: {
                                    rightForm: { defaultIncludedSellingPoints: "默認包含的賣點" },
                                },
                                index: {
                                    keyWord: "關鍵字",
                                    pleaseSelectAKeyword: "請選擇關鍵字",
                                    keyPointsOfScript: "話術要點",
                                    pleaseInputTheKeyPointsOfTheScript: "請輸入話術要點",
                                    viewDetails: "查看詳情",
                                    pleaseChooseToAdoptScript: "請選擇採納話術",
                                },
                            },
                        },
                    },
                },
            },
        },
        gong: {
            common: {
                pleaseSelectATemMember: "请选择团队成员",
                existingFilter: "已有过滤器",
                pleaseSelect: "请选择",
                filterCondition: "过滤条件",
                areYouSureToDeleteTheCurrentFilter: "是否确认删除当前过滤器？",
                sessionDate: "会话日期",
                staff: "员工",
                customer: "客户",
                pleaseEnterCustomerName: "请输入客户名称",
                keyWord: "关键词",
                whole: "全部",
                pleaseEnterSessionKeywords: "请输入会话关键词",
                sessionDuration: "会话时长",
                sessionLabel: "会话标签",
                pleaseSelectASessionLabel: "请选择会话标签",
                commentTopic: "评论话题",
                pleaseSelectACommentTopic: "请选择评论话题",
                areYouSureYouWantToOverwriteTheCurrentFilter:
          "是否确认覆盖当前过滤器？",
                overlayFilter: "覆盖过滤器",
                NewFilter: "+ 新建过滤器",
                newFilter: "新建过滤器",
                filterName: "过滤器名称",
                pleaseEnter: "请输入",
                allCall: "所有通话",
                percentage: "百分比",
                filterScreen: {
                    callRecordID: "通話記錄ID",
                    pleaseFillInTheCallRecordID: "請填寫通話記錄ID",
                    minute: "分鐘",
                    second: "秒",
                    whole: "全部",
                    callRecording: "通話錄音",
                    enterpriseWechat: "企業微信",
                    conversationChannel: "會話通路",
                    pleaseSelectASessionChannel: "請選擇會話通路",
                    learningStatus: "學習狀態",
                    pleaseSelectLearningStatus: "請選擇學習狀態",
                    notLearned: "未學習",
                    learned: "已學習",
                    Minutes: "分钟",
                    theFilterConditionIsEmptyNewFilterIsNotSupported:
            "过滤条件为空，不支持新建过滤器！",
                    filterAddedSuccessfully: "过滤器新增成功！",
                    failedToAddFilter: "过滤器新增失败！",
                    filterDeletedSuccessfully: "过滤器删除成功！",
                    filterDeletionFailed: "过滤器删除失败！",
                    filterOverwriteSucceeded: "过滤器覆盖成功！",
                    filterOverwriteFailed: "过滤器覆盖失败！",
                    theme: "主题",
                    pleaseSelectATheme: "请选择主题",
                    keyEvents: "关键事件",
                    pleaseSelectAKeyEvent: "请选择关键事件",
                },
                filterItem: {
                    startRetesting: "開始重新檢測",
                    time: "时间",
                    talkTime: "通话时长",
                    keyEvents: "關鍵事件",
                    totalImplementationRateOfSupervisionItems: "監督項總執行率",
                    sort: "排序：",
                    seeMore: "查看更多",
                },
                filterStatistics: {
                    conversation: "通話",
                    weChat: "微信",
                    quantity: "数量",
                },
            },
            caseBase: {
                index: {
                    copyTo: "复制到",
                    pleaseSelectTheLocationToMove: "请选择移动的位置！",
                    folderCopiedSuccessfully: "文件夹复制成功！",
                    cancelCollectionSuccessfully: "取消收藏成功！",
                    collectionSucceeded: "收藏成功！",
                    starCaseBase: "星标案例库",
                    move: "移动",
                    rename: "重命名",
                    delete: "删除",
                    folderMovedSuccessfully: "文件夹移动成功！",
                    fileMovedSuccessfully: "文件移动成功！",
                    fileMoveFailed: "文件移动失败！",
                    folderAddedSuccessfully: "添加文件夹成功！",
                    folderRenameSucceeded: "文件夹重命名成功！",
                    fileRenameSucceeded: "文件重命名成功！",
                    theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "删除文件夹后将无法找回，您确认删除吗？",
                    deleteSucceeded: "删除成功！",
                    theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "删除文件后将无法找回，您确认删除吗？",
                    share: "共有",
                    folders: "个文件夹，",
                    calls: "个通话",
                    newFolder: "新增文件夹",
                    modifyFolder: "修改文件夹",
                    title: "标题",
                    pleaseEnter: "请输入",
                    selectMoveLocation: "选择移动位置",
                },
            },
            msgCenter: {
                index: {
                    learningReminder: "學習提醒",
                    subscriptionDynamics: "订阅动态",
                },
                subscription: {
                    index: {
                        theSessionYouSubscribedToID: "你订阅的会话（ID",
                        thereAreDynamicUpdates: "有动态更新啦",
                        newCommentsReplies: "新增评论/回复",
                        strip: "条",
                        newHelp: "新增求助",
                        goAndWatch: "去围观",
                    },
                },
                reply: {
                    index: {
                        respondedToMyComments: "回复了我的评论",
                        replySucceeded: "回复成功！",
                    },
                },
                answer: {
                    index: {
                        youMeInTheComments: "在评论中@了我",
                        replySucceeded: "回复成功！",
                    },
                },
                share: { index: { shareCallsForYou: "为你通话分享" } },
                study: {
                    index: {
                        learned: "已學習",
                        ignored: "已忽略",
                        study: "學習",
                        ignore: "忽略",
                    },
                },
            },
            userManage: {
                list: {
                    headPortrait: "头像",
                    fullName: "姓名",
                    nickname: "昵称",
                    excellentStaff: "优秀员工",
                    yes: "是",
                    no: "否",
                    department: "部门",
                    accountNumberemail: "账号（邮箱）",
                    telephone: "电话",
                    jobNumber: "工号",
                    role: "角色",
                    pleaseEnterANameOrPhoneSearch: "请输入姓名或电话搜索",
                    person: "人）",
                    AddEmployee: "+ 添加员工",
                },
                edit: {
                    jobNo: "工號",
                    udeskCustomerServiceSystemIdentity: "Udesk客服系統身份",
                    enterpriseWechatIdentity: "企業微信身份",
                    inputID: "輸入標識",
                    newIdentityBinding: "新增身份綁定",
                    identityBinding: "身份綁定",
                    avatarGreaterThan500kb: "头像大于500Kb",
                    uploadSuccessful: "上传成功",
                    upload: "上传",
                    supportJPGGIFAndPNGUpTo500kb: "支持jpg、gif、png，最大500Kb",
                    yes: "是",
                    no: "否",
                    accountNumber: "账号",
                    pleaseEnterTheAccountNumber: "请输入账号",
                    initialPassword: "初始密码",
                    pleaseInputAPassword: "请输入密码",
                    confirmPassword: "确认密码",
                    thePasswordsEnteredTwiceAreDifferent: "两次输入的密码不相同!",
                    headPortrait: "头像",
                    fullName: "姓名",
                    pleaseEnterYourName: "请输入姓名",
                    nickname: "昵称",
                    department: "部门",
                    excellentStaff: "优秀员工",
                    sessionIdentity: "会话身份标识",
                    markTheSessionRecordAsBelongingToTheEmployee:
            "标记会话记录归属于该员工",
                    telephone: "电话",
                    jobNumber: "工号",
                    role: "角色",
                    pleaseSelectARole: "请选择角色",
                    preservation: "保存",
                    cancel: "取消",
                    addedSuccessfully: "添加成功！",
                    modificationSucceeded: "修改成功！",
                    newEmployees: "新增员工",
                    editEmployee: "编辑员工",
                },
                detail: {
                    viewDetails: "查看详情",
                    accountNumber: "账号",
                    headPortrait: "头像",
                    fullName: "姓名",
                    nickname: "昵称",
                    department: "部门",
                    excellentStaff: "优秀员工",
                    yes: "是",
                    no: "否",
                    sessionIdentity: "会话身份标识",
                    markTheSessionRecordAsBelongingToTheEmployee:
            "标记会话记录归属于该员工",
                    telephone: "电话",
                    jobNumber: "工号",
                    role: "角色",
                },
            },
            roleManage: {
                list: {
                    menu: "菜单",
                    functionalCategory: "功能大类",
                    functionalSubclass: "功能小类",
                    deletedSuccessfully: "删除成功",
                    NewRole: "+ 新建角色",
                    role: "角色",
                    dataPermissions: "数据权限：",
                    edit: "编辑",
                    areYouSureYouWantToDeleteTheRole: "确定删除角色",
                    determine: "确定",
                    cancel: "取消",
                    delete: "删除",
                    applicationPermission: "应用权限",
                },
                edit: {
                    youCanOnlySeeYourOwnSessionData: "只能看到自己的會話數據",
                    youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "可以查看本部門及下屬部門的會話數據",
                    menu: "菜单",
                    functionalCategory: "功能大类",
                    functionalSubclass: "功能小类",
                    successfullyAdded: "新增成功",
                    modifiedSuccessfully: "修改成功",
                    edit: "编辑",
                    cancel: "取消",
                    confirm: "确认",
                    roleName: "角色名称",
                    pleaseEnterTheRoleName: "请输入角色名称",
                    dataAuthority: "数据权限",
                    youCanViewCompanyWideSessionData: "可以查看全公司的会话数据",
                    applicationPermission: "应用权限",
                },
            },
            callDetail: {
                index: {
                    theSettingWillBeSuccessfulLater: "設定稍後看成功！",
                    previous: "上一條",
                    next: "下一條",
                    debuggingMode: "調試模式",
                    afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "開通後消息如果匹配關鍵事件、監督項、客戶標籤的規則或者知識點規則，則會顯示消息匹配到的規則名稱，以及知識庫的知識點名稱。",
                    seeLater: "稍後看",
                    cancel: "取消",
                    determine: "確定",
                    conversationalInsights: "會話洞察",
                    customerInsight: "客戶洞察",
                    knowledgePointRecommendation: "知識點推薦",
                    comment: "評論",
                    sessionQuality: "會話質量",
                    setToListenLaterSuccessfully: "设置稍后听成功！",
                    listenLater: "稍后听",
                    seekCounselingSuccess: "寻求辅导成功！",
                    altogether: "一共有",
                    appearsAt: "处出现 '",
                    commentsPublishedSuccessfully: "评论发表成功",
                    callRecording: "通话录音",
                    addedSuccessfully: "添加成功",
                    shareWithColleagues: "分享给同事",
                    shareWithCustomers: "分享给客户",
                    resumptionDetails: "复盘详情",
                    addToCaseBase: "添加至案例库",
                    shareCases: "分享案例",
                    seekCounseling: "寻求辅导",
                    sessionRecord: "会话记录",
                    businessRecord: "业务记录",
                    keywordSearch: "关键词搜索",
                    whole: "全部",
                    pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "请输入评论，输入@以通知其他人，输入#以添加话题",
                    subscriptionSession: "订阅会话",
                    afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "订阅后，当有新的求助、评论、回复会收到通知提醒",
                },
            },
            coachTask: {
                index: {
                    employeeName: "员工姓名",
                    customerName: "客户姓名",
                    source: "来源",
                    viewSessionRecords: "查看会话记录",
                    coachingStatus: "辅导状态",
                    helpTime: "求助时间",
                    counselingTime: "辅导时间",
                    helpContent: "求助内容",
                    coachingOperation: "辅导操作",
                    areYouSureYouWantToIgnoreIt: "确定忽略吗？",
                    determine: "确定",
                    cancel: "取消",
                    ignoreHelp: "忽略求助",
                    coaching: "进行辅导",
                    viewCoaching: "查看辅导",
                    areYouSureToReturnToCounseling: "确定恢复至待辅导吗？",
                    returnToPendingCoaching: "恢复至待辅导",
                    tutor: "辅导人",
                    helpSeekingStatus: "求助状态",
                    recentCoachingTime: "最近辅导时间",
                    operation: "操作",
                    ignoreSuccess: "忽略成功！",
                    recoverySucceeded: "恢复成功！",
                    successfulCoaching: "辅导成功！",
                    myMission: "我的任务",
                    selectCoachingStatus: "选择辅导状态",
                    whole: "全部",
                    iAskedForHelp: "我求助的",
                    signOut: "退出",
                    submit: "提交",
                    helpPerson: "求助人",
                    customer: "客户",
                    toBeCoached: "待辅导",
                    counselingSuggestions: "辅导建议",
                    pleaseEnterCoachingSuggestions: "请输入辅导建议",
                },
            },
            coachTaskCenter: {
                title: "任務中心",
                list: {
                    taskName: "任務名稱",
                    lessonGroup: "課程分類",
                    lessonName: "所屬課程",
                    taskType: "任務類型",
                    taskStatus: "任務狀態",
                    taskExecutionInfo: "執行情況",
                    totalCount: "應執行人數",
                    executeCount: "已執行人數",
                    executeRate: "執行率",
                    completeCount: "完成人數",
                    completeRate: "完成率",
                    commitCount: "達標人數",
                    commitRate: "達標率",
                    excellentRate: "達優率",
                    taskTime: "任務時間",
                    option: "操作",
                    edit: "編輯",
                    taskDetail: "任務數據",
                    delete: "刪除",
                    new: "新建發布",
                    taskTypeAll: "全部",
                    taskTypePractice: "練習",
                    taskTypeTest: "考試",
                    taskStatusNotStarted: "未開始",
                    taskStatusRunning: "進行中",
                    taskStatusEnd: "已結束",
                    taskStatusInvalid: "未生效",
                    unlimitedTime: "不限時間",
                    to: "至",
                    deleteConfirm: "刪除後此任務下所有學習記錄將被清空，是否確認刪除？ ",
                    deleteSuccess: "任務刪除成功",
                },
                edit: {
                    addTask: "新建發布任務",
                    editTask: "編輯發布任務",
                    publishSuccess: "發布成功",
                    publishFailed: "發布失敗",
                    updateSuccess: "更新成功",
                    updateFailed: "更新失敗",
                    cannotBeNull: "不能為空",
                    explain: "說明",
                    taskDescExtra: "（此處內容將在{0}入口處展示）",
                    taskTargetObj: "發布對象",
                    isEnable: "生效狀態",
                    dialogueMode: "對話模式",
                    voice: "語音",
                    text: "文本",
                    userPortrait: "用戶畫像",
                    difficultyLevel: "難度",
                    limitDuration: "時長限制",
                    tipLabel: "話術提示",
                    tipTooltip: "是否允許學員查看參考話術",
                    limitTimes: "考試次數",
                    time: "時間",
                    gradingStandart: "評分標準",
                    gradingStandartDescAffix: "（當前對話流程總分為",
                    gradingStandartDescSuffix: "分，話術{0}分，情緒{1}分，語速{2}分）",
                    completeStandart: "任務完成要求",
                    byTimes: "按次數",
                    byScore: "按得分",
                    completeByTimes: "練習次數",
                    times: "次",
                    score: "分",
                    anyTimes: "任意一次",
                    getScore: "得分",
                    offLimitDuration: "不限時長",
                    onLimitDuration: "限定時長",
                    commitScore: "達標標準，得分",
                    excellentScore: "優秀標準，得分",
                    offLimitTime: "不限時間",
                    onLimitTime: "限定時間",
                },
            },
            homePage: {
                common: {
                    useMessage: { hi: "嗨，" },
                    callDynamic: { today: "今日", yesterday: "昨日", sessions: "个会话" },
                    todoList: {
                        theSessionIsAddedTolistenLater: "会话被加入“稍后听”",
                        askYouForHelp: "向你求助",
                        iHopeToContactYouLater: "期望稍后联系",
                        lookingForwardToSendingTheInformationLater: "期望稍后发送资料",
                        dialogueRisk: "会话存在风险点",
                        tutored: "已辅导",
                        goToCounseling: "去辅导",
                        contacted: "已联系",
                        hasBeenSent: "已发送",
                        areYouSureYouWantToIgnoreIt: "确定忽略吗？",
                        determine: "确定",
                        cancel: "取消",
                        ignore: "忽略",
                        checked: "已查看",
                    },
                    callListItem: {
                        strip: "條",
                        learned: "已學習",
                        keyEvents: "關鍵事件",
                        totalImplementationRateOfSupervisionItems: "監督項總執行率",
                    },
                },
                index: {
                    operationSucceeded: "操作成功！",
                    sessionDynamics: "会话动态",
                    my: "我的",
                    department: "部门",
                    company: "公司",
                    toDoList: "待办事项",
                },
            },
            teamAnalysis: {
                index: {
                    effectiveCallVolumenumber: "有效通話量（個）",
                    bestPractices: "最佳实践",
                    averageDurationOfASingleCallminutes: "单次平均通话时长（分钟）",
                    totalCallVolumePCs: "总通话量（个）",
                    totalTalkTimehours: "通话总时长（小时）",
                    speakingPercentagepercentage: "说话占比（百分比）",
                    maximumContinuousSpeakingTimeminutes: "最长持续说话时长（分钟）",
                    numberOfIndepthDiscussionsPCs: "深入讨论次数（个）",
                    maximumCustomerTalkminutes: "最长客户发言（分钟）",
                    questionFrequencyPCs: "提问频次（个）",
                    averageValue: "平均值",
                    selectDate: "选择日期",
                    personnel: "人员",
                    pleaseSelect: "请选择",
                    callRange: "通话范围",
                    conversationActivity: "会话活动",
                    conversationAction: "会话动作",
                    keyEvents: "关键事件",
                    callRecord: "通话记录",
                    filter: "筛选：",
                    conversation: "通话",
                    minute: "分钟",
                    sale: "员工",
                },
            },
            saleClientCenter: {
                detail: {
                    index: {
                        january: "1月",
                        numberOfCalls: "通话数",
                        accountInformation: "账户信息",
                        month: "月",
                        today: "今天",
                        customerDetails: "客户详情",
                        searchKeywordsInCommunicationContent: "沟通内容中检索关键词",
                        allMembers: "所有成员",
                        reset: "重置",
                        query: "查询",
                        contactOverview: "联络概览",
                        lastContact: "上次联络",
                        nextContact: "下次联络",
                        previous: "上一条",
                        next: "下一条",
                        viewDetails: "查看详情",
                        contactDynamics: "联络动态",
                        lastYear: "最近一年",
                        lastCall: "最近一次通话",
                        hotTopicsForDiscussion: "讨论话题热点",
                        totalInTheLastYear: "最近一年一共",
                        callTimes: "次通话",
                    },
                },
                list: {
                    index: {
                        followUpSuggestions: "跟進建議",
                        customer: "客戶中心",
                        risk: "风险",
                        followUp: "跟进情况",
                        personInCharge: "负责人",
                        recentFollowup: "最近跟进人",
                        lastContactTime: "最近一次联系时间",
                        operation: "操作",
                        see: "查看",
                        exceed: "超过",
                        noActivityForMoreThanDays: "天没有活动",
                        lately: "最近",
                        risksOccurredInTheLastDays: "天内出现风险语义",
                        savedSuccessfully: "保存成功",
                        today: "今天",
                        allDepartments: "所有部门",
                        allMembers: "所有成员",
                        customerName: "客户名称",
                        reset: "重置",
                        query: "查询",
                        customers: "个客户",
                        showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "展示员工与客户的联络情况，其中粉色泡泡为客户消息，蓝色为员工消息，发言占比越大，气泡越大",
                        riskAllocation: "风险配置",
                        preservation: "保存",
                        pleaseEnter: "请输入",
                        noActivityForDays: "天没有活动",
                        riskSemanticsWithinDays: "天内出现风险语义",
                        addRiskSemantics: "添加风险语义",
                        semanticTags: "语义标签：",
                        standardSentence: "标准句：",
                    },
                },
                components: {
                    businessStage: {
                        index: {
                            changeOpportunityStage: "變更商機階段",
                            theCurrentStageIs: "當前階段為【",
                            ConfirmToChangeItTo: "】，確定將其變更為【",
                            IsIt: "】嗎？",
                            StageCoStay: "】階段共停留",
                            day: "天",
                            start: "開始",
                            end: "結束",
                            orderInput: "輸單",
                            restart: "重新啟動",
                            pleaseConfigureTheSalesStageInformation: "請配寘銷售階段資訊",
                            reasonForDocumentInput: "輸單原因：",
                            completeOrderInput: "完成輸單",
                            pleaseSelectTheReason: "請選擇原因",
                        },
                        modal: {
                            customerDynamics: { theManagerSuggests: "管理者建議：" },
                            index: {
                                sessionDetails: "會話詳情",
                                customerDynamics: "客戶動態",
                                riskPoint: "風險點",
                                followUpSuggestions: "跟進建議",
                            },
                            sessionDetails: {
                                common: "共",
                                currentlyViewingThe: "當前查看第",
                                sessions: "個會話",
                            },
                        },
                    },
                    businessTitle: {
                        index: { theCurrentStageHasStayed: "當前階段已停留", day: "天" },
                    },
                    business: { index: { reEnableOpportunity: "重新啟用商機" } },
                    customerTag: { index: { label: "標籤" } },
                    moreCustomer: {
                        index: {
                            essentialInformation: "基本資訊",
                            cancel: "取消",
                            confirm: "確認",
                            customer: "客戶",
                            currentFollowupStage: "當前跟進階段",
                            telephone: "電話",
                            followUpDays: "跟進天數",
                            day: "天",
                            remarks: "備註",
                            channelPreference: "通路偏好",
                            hello: "你好",
                            customFields: "自定義欄位",
                            opportunityAmount: "商機金額",
                            focus: "關注點",
                            customerInformation: "客戶資訊",
                            viewMoreCustomerInformation: "查看更多客戶資訊",
                        },
                    },
                    table: {
                        components: {
                            row: {
                                row0: { keyEvents: "關鍵事件：" },
                                row1: {
                                    customerDynamics: "客戶動態",
                                    common: "共",
                                    barDynamic: "條動態",
                                },
                                row2: { riskPoint: "風險點", common: "共", risks: "條風險" },
                                row3: {
                                    followUpSuggestions: "跟進建議",
                                    intelligentRecommendation: "智慧推薦",
                                    strip: "條",
                                    managersSuggestATotalOf: "管理者建議共",
                                    recommendedByManagers: "管理者推薦",
                                    creationTime: "創建時間",
                                    administratorSuggestions: "管理員建議",
                                    suggestionsForNewManagers: "新增管理者建議",
                                },
                            },
                        },
                        index: { typeTime: "類型/時間" },
                    },
                    userInfo: {
                        index: {
                            personInCharge: "負責人",
                            recentFollower: "最近跟進人",
                            followUpDays: "跟進天數",
                            day: "天",
                            remarks: "備註",
                        },
                    },
                },
            },
            studyAnalysis: {
                index: {
                    teamMembers: "团队成员",
                    membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "成员学习他人会话的总数（去重），查阅会话详情且点击过录音播放视为学习一次",
                    totalNumberOfStudies: "学习总数",
                    theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "成员对他人会话发起评论、回复的总数（去重）",
                    totalNumberOfInteractions: "互动总数",
                    learnTheSumOfConversationCallDuration: "学习的会话通话时长求和",
                    lengthOfStudy: "学习时长",
                    totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "成员对他人的会话发起求助的总数（去重）",
                    totalNumberOfRequests: "求助总数",
                    totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "成员对他人的求助进行辅导的总数（去重）",
                    totalCounseling: "辅导总数",
                    totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "成员对他人的会话添加到案例库的总数（去重）",
                    addCase: "添加案例",
                    totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "成员对他人的会话进行订阅的总数（去重）",
                    subscribe: "订阅",
                    totalNumberOfSessionsCommunicatedByMembers: "成员沟通的会话总数",
                    totalSessions: "会话总数",
                    theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "成员复盘自己会话的总数（去重），查阅会话详情且点击过录音播放视为复盘一次",
                    totalNumberOfDoubleDisk: "复盘总数",
                    totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "复盘总数/会话总数*100%",
                    proportionOfDoubleDisk: "复盘占比",
                    summationOfConversationCallDurationOfDuplicateDisk:
            "复盘的会话通话时长求和",
                    resumptionDuration: "复盘时长",
                    totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "成员对自己的会话发起求助的总数（去重）",
                    theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "成员的会话收到他人评论（包括回复）的总数",
                    theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "成员的会话被他人添加到案例库的总数（去重）",
                    theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "成员的会话被他人进行订阅的总数（去重）",
                    askForHelpInTheLastMonth: "最近一个月求助",
                    lastTimeForHelp: "最近一次求助时间",
                    operation: "操作",
                    viewAllSessionsInTheLastMonth: "查看最近一个月的所有会话",
                    allDepartments: "所有部门",
                    reset: "重置",
                    query: "查询",
                    altogether: "一共",
                    secondSession: "次会话",
                },
            },
            checkPointTest: {
                components: {
                    testSelectComponent: {
                        currentTestItems: "當前測試項目：",
                        supervisionItem: "監督項",
                        keyEvents: "關鍵事件",
                    },
                },
                index: {
                    pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "請勾選要測試的監督項或關鍵事件，並確保類比對話中有通話內容",
                },
            },
            executionAnalysis: { index: { executiveForceAnalysis: "執行力分析" } },
            konwledgeBase: {
                detail: {
                    components: {
                        richTextList: {
                            process: "流程1",
                            technologicalProcess: "流程",
                            newProcess: "新建流程",
                        },
                    },
                    index: {
                        upload: "上傳",
                        preservation: "保存",
                        newlyBuild: "新建",
                        cancel: "取消",
                        name: "名稱",
                        describe: "描述",
                        type: "類型",
                        classification: "分類",
                        content: "內容",
                        enclosure: "附件",
                        knowledgePointAssociation: "知識點關聯",
                        open: "開啟",
                        close: "關閉",
                        operator: "運算元",
                        logic: "邏輯",
                        see: "查看",
                        edit: "編輯",
                    },
                },
                index: {
                    name: "名稱",
                    describe: "描述",
                    createdBy: "創建人",
                    type: "類型",
                    lastUpdated: "最近更新時間",
                    numberOfReferences: "引用次數",
                    operation: "操作",
                    see: "查看",
                    edit: "編輯",
                    delete: "删除",
                    categoryDeletedSuccessfully: "分類删除成功",
                    classificationOfKnowledgePoints: "知識點分類",
                    newlyBuild: "新建",
                    knowledgeBase: "知識庫",
                    knowledgePoints: "知識點",
                    technologicalProcess: "流程",
                    keyword: "關鍵字",
                    batchExport: "批量匯出",
                    batchDelete: "批量删除",
                    importProcess: "導入流程",
                    importKnowledgePoints: "導入知識點",
                    ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "若知識點在知識庫中不存在則直接新增知識點，若存在則跳過，不做更新",
                    editKnowledgePointClassification: "編輯知識點分類",
                    newKnowledgePointClassification: "新建知識點分類",
                    categoryEditingSucceeded: "分類編輯成功",
                    newClassificationSucceeded: "新建分類成功",
                    classificationName: "分類名稱",
                },
            },
            monitoringReport: { index: { executiveSupervision: "執行力監督" } },
            topicCallout: {
                index: {
                    whole: "全部",
                    date: "日期",
                    dimensionStatus: "標注狀態",
                    callRecordID: "通話記錄ID",
                    callRecordIDMustBeNumeric: "通話記錄ID必須為數位",
                    strip: "條",
                    topicAnnotation: "話題標注",
                    annotated: "已標注",
                    stripNotMarked: "條，未標注",
                    barInDimension: "條，標注中",
                    customer: "客戶",
                    sale: "銷售",
                    common: "共",
                    sessions: "條會話",
                    currentTopic: "當前話題：",
                    staff: "員工",
                    topicOfConversation: "話題",
                    theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "操作成功，標注已重置",
                    afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "重置後將清空本通會話的所有標注記錄，請確認",
                    resetAll: "全部重置",
                    pleaseSelectTheTopicToBeMarked: "請選擇要標注到的話題",
                    pleaseTickTheSentenceToBeMarked: "請勾選要標注的句子",
                    theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "操作成功，標注已生效",
                    theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "本次標注的範圍包含其他話題的消息，點擊確定將進行覆蓋",
                    confirmDimensions: "確認標注",
                    talkTime: "通話時間",
                    callID: "通話id",
                },
            },
            topicList: {
                index: {
                    callID: "通話ID",
                    latestMarkedTime: "最新標注時間",
                    topicOfConversation: "話題",
                    numberOfSentences: "句子數量",
                    sentence: "句子",
                    operation: "操作",
                    see: "查看",
                    delete: "删除",
                    edit: "編輯",
                    newSubTopic: "新建子話題",
                    move: "移動",
                    whole: "全部",
                    topicDeletedSuccessfully: "删除話題成功",
                    newlyBuild: "新建",
                    topicList: "話題清單",
                    callIDMustBeNumeric: "通話ID必須為數位",
                    leadInTopic: "導入話題",
                    whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "分類名稱重複時，不會覆蓋原有的話題分類，該項會被直接跳過，不會導入",
                    mobileClassification: "移動分類",
                    editTopic: "編輯話題",
                    newTopic: "新建話題",
                    topicName: "話題名稱",
                },
            },
            groupTest: {
                detail: {
                    template: {
                        modificationSucceeded: "修改成功",
                        createdSuccessfully: "創建成功",
                        modificationFailed: "修改失敗",
                        creationFailed: "創建失敗",
                        monitoringName: "監測名稱",
                        upToCharacters: "最多40個字元",
                        pleaseEnterAName: "請輸入名稱",
                        monitoringIndicators: "監測名額",
                        sessionActivity: "會話活動",
                        conversationAction: "會話動作",
                        keyEvents: "關鍵事件",
                        supervisionItems: "監督項",
                        monitoringMembers: "監測成員",
                        groupReceivingGuidanceTrainingOrManagementIntervention:
              "接受指導、培訓或管理干預的組別",
                        groupA: "A組",
                        pleaseSelectGroupAData: "請選擇A組數據",
                        thereAreDuplicateUsersInGroupB: "與B組中存在重複的用戶",
                        groupARemarks: "A組備註",
                        controlGroupGroupComparedWithGroupA: "對照組，與A組進行對比的組別",
                        groupB: "B組",
                        pleaseSelectGroupBData: "請選擇B組數據",
                        thereAreDuplicateUsersInGroupA: "與A組中存在重複的用戶",
                        groupBRemarks: "B組備註",
                        monitoringPeriod: "監測週期",
                        startNode: "開始節點",
                        monitoringScope: "監測範圍",
                        pleaseSelectMonitoringConditions: "請選擇監測條件",
                        pleaseEnterANumber: "請輸入數位",
                        pleaseSelectATimePeriod: "請選擇時間週期",
                        preservation: "保存",
                        cancel: "取消",
                    },
                },
                list: {
                    template: {
                        deletionSucceeded: "删除成功",
                        notStarted: "未開始",
                        haveInHand: "進行中",
                        closed: "已結束",
                        newGroupTest: "新建分組測試",
                    },
                },
            },
            businessConfiguration: {
                salesStage1: "銷售階段",
                communicationStrategy1: "溝通策略",
                riskAllocation: "風險配寘",
                businessConfiguration: "業務配寘",
                communicationStrategy: {
                    detail: {
                        components: {
                            pushRichText: {
                                index: {
                                    objectionHandlingScript: "抗告處理話術",
                                    productSellingPointScript: "產品賣點話術",
                                    caseGrouping: "案例分組",
                                    pleaseSelectExcellentCases: "請選擇優秀案例",
                                    pleaseSelectKnowledgePoints: "請選擇知識點",
                                    associativeLinks: "關聯連結",
                                    jumpTo: "跳轉至",
                                    jumpType: "跳轉類型",
                                    knowledgePoints: "知識點",
                                    excellentCases: "優秀案例",
                                    excellentScript: "優秀話術",
                                    pleaseSelectASingleKnowledgePoint: "請選擇單個知識點",
                                },
                            },
                        },
                        index: {
                            name: "名稱",
                            pushText: "推送文字",
                            enableStatus: "啟用狀態",
                            triggerRules: "觸發規則",
                            communicationStrategy: "溝通策略",
                        },
                    },
                    list: {
                        index: {
                            communicationStrategyGrouping: "溝通策略分組",
                            name: "名稱",
                            pushText: "推送文字",
                            effectiveness: "有效性",
                            openSuccessfully: "開啟成功",
                            closeSuccessfully: "關閉成功",
                            operationFailed: "操作失敗",
                            operation: "操作",
                            edit: "編輯",
                            delete: "删除",
                            newlyBuild: "新建",
                            communicationStrategy: "溝通策略",
                            editGroup: "編輯分組",
                            groupName: "分組名稱",
                        },
                    },
                },
                riskStatement: {
                    detail: {
                        components: {
                            riskSettingRule: {
                                index: {
                                    allSessions: "所有會話",
                                    recentDays: "最近天數",
                                    recentSessions: "最近會話",
                                    scopeOfApplication: "適用範圍：",
                                    day: "天",
                                    through: "通",
                                },
                            },
                        },
                        index: {
                            describe: "描述",
                            applicableCustomers: "適用客戶",
                            enableStatus: "啟用狀態",
                            triggerRules: "觸發規則",
                            riskRules: "風險規則",
                        },
                    },
                    list: {
                        index: {
                            riskAllocation: "風險配寘",
                            keyWord: "關鍵字",
                            newRiskRule: "新建風險規則",
                            riskRuleName: "風險規則名稱",
                            describe: "描述",
                            state: "狀態",
                            openSuccessfully: "開啟成功",
                            closeSuccessfully: "關閉成功",
                            operationFailed: "操作失敗",
                            operation: "操作",
                            edit: "編輯",
                            delete: "删除",
                            deletionSucceeded: "删除成功",
                        },
                    },
                },
                salesStage: {
                    detail: {
                        components: {
                            alias: { orderInput: "輸單" },
                            endReasonList: {
                                addEndReason: "添加結束原因",
                                enterEndReason: "輸入結束原因",
                            },
                            stageList: {
                                keyEvents: "關鍵事件",
                                waysideData: "隨路數據",
                                includeAny: "包含任意",
                                includeAll: "包含全部",
                                pleaseEnterTheStageName: "請輸入階段名稱",
                                setStageRules: "設定階段規則",
                                stageName: "階段名稱",
                                operator: "運算元",
                                logic: "邏輯",
                            },
                        },
                        index: {
                            theInputFormatOfTheTimeTypeFieldIs:
                "時間類型欄位輸入格式為：2022-12-31 00:00:00",
                            requestFailedPleaseTryAgainLater: "請求失敗，請稍後再試",
                            salesStage: "銷售階段",
                            essentialInformation: "基本資訊",
                            name: "名稱",
                            applicableDepartments: "適用部門",
                            applicableToOthers: "適用其他",
                            manualChangePhase: "手動變更階段",
                            enableStatus: "啟用狀態",
                            describe: "描述",
                            opportunityStageSettings: "商機階段設定",
                            stageSettings: "階段設定",
                            aliasSettings: "別名設定",
                            closeOpportunity: "結束商機",
                            newSuccessfully: "新建成功",
                            newFailed: "新建失敗",
                            savingSucceeded: "保存成功",
                            saveFailed: "保存失敗",
                        },
                    },
                    list: {
                        index: {
                            salesStage: "銷售階段",
                            keyWord: "關鍵字",
                            newSalesStage: "新建銷售階段",
                            salesStageName: "銷售階段名稱",
                            describe: "描述",
                            state: "狀態",
                            openSuccessfully: "開啟成功",
                            closeSuccessfully: "關閉成功",
                            operationFailed: "操作失敗",
                            operation: "操作",
                            edit: "編輯",
                            delete: "删除",
                            deletionSucceeded: "删除成功",
                        },
                    },
                },
            },
            salesBusinessConfigurationEntry: {
                salesStage: "銷售階段",
                communicationStrategy: "溝通策略",
                riskAllocation: "風險配寘",
                businessConfiguration: "業務配寘",
            },
            salesLearningCenter: {
                knowledgeBase: "知識庫",
                speechCorpus: "話術庫",
                learningCenter: "學習中心",
            },
        },
        intelligentAnalysis: {
            analysisReportTitle: "分析報表",
            ruleTest: "規則測試",
            intelligentTags: {
                supervisionItem: "監督項",
                customerLabel: "客戶標籤",
                newSupervisionItemRule: "新建監督項規則",
                editSupervisionItemRule: "編輯監督項規則",
                newCustomerLabel: "新建客戶標籤",
                editCustomerLabel: "編輯客戶標籤",
                newSessionLabel: "新建会话标签",
                editSessionLabel: "编辑会话标签",
                newKeyEvent: "新建关键事件",
                editKeyEvents: "编辑关键事件",
                detail: { template: { nameIsARequiredField: "名稱是必填欄位" } },
                template: {
                    advancedFiltering: "高級篩選",
                    dataType: "資料類型",
                    filterCriteria: "篩選條件",
                    edit: "編輯",
                    createANewSubcategory: "新建子分類",
                    delete: "删除",
                    nameLengthCannotExceed: "名稱長度不能超過40",
                },
                component: {
                    standardScreening: "標準篩選",
                    advancedFiltering: "高級篩選",
                },
            },
            businessAnalysis: {
                detail: {
                    businessAnalysisFiter: {
                        component: {
                            keyWord: "關鍵字",
                            sessionLabel: "會話標籤",
                            keyEvents: "關鍵事件",
                            matchAny: "匹配任意",
                            matchAll: "匹配全部",
                            beEqualTo: "等於",
                            includeAll: "包含全部",
                            includeAny: "包含任意",
                            advancedConditions: "高級條件",
                            smartTag: "智慧標籤",
                            smartTagI: "智慧標籤一",
                            smartTagII: "智慧標籤二",
                        },
                        template: {
                            pleaseSelectSmartTag: "請選擇智慧標籤",
                            pleaseSelectASessionLabel: "請選擇會話標籤",
                            pleaseSelectAKeyEvent: "請選擇關鍵事件",
                            pleaseSelectAdvancedCondition: "請選擇高級條件",
                            newAdvancedFilter: "新建高級篩選",
                            moveUp: "上移",
                            moveDown: "下移",
                            moveLeft: "左移",
                            shiftRight: "右移",
                        },
                    },
                    component: {
                        businessAnalysispng: "業務分析.png",
                        savedSuccessfully: "保存成功",
                    },
                },
            },
            supervision: {
                detail: {
                    index: {
                        permanentlyValid: "永久有效",
                        permanentlyInvalid: "永久無效",
                        customValidity: "自定義有效期",
                        termOfValidity: "有效期",
                        name: "名稱",
                        explain: "說明",
                        state: "狀態",
                        classification: "分類",
                        smartRecommendation: "智慧推薦",
                        preconditions: "前置條件",
                        SetPreconditionsForThisSupervisionItem:
              "1.為該條監督項設定前置條件：",
                        whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              "符合前置條件時，即為“應執行監督項”，則對該條監督項進行檢測；",
                        ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "不符合前置條件時，則不檢測該條監督項；",
                        NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2.不設定前置條件，即默認對所有會話進行檢測",
                        have: "有",
                        nothing: "無",
                        preconditionRule: "前置條件規則",
                        preconditionLogic: "前置條件邏輯",
                    },
                    recommendation: {
                        caseGrouping: "案例分組",
                        whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "當員工執行不到位時，可智慧推薦優秀話術和優秀案例",
                        smartRecommendation: "智慧推薦",
                        open: "開啟",
                        close: "關閉",
                        whenThisSupervisionItem: "當此監督項",
                        implement: "執行",
                        unexecuted: "未執行",
                        when: "  時：",
                        recommendedKnowledgePoints: "推薦知識點",
                        caseRecommendation: "案例推薦",
                    },
                },
                index: {
                    determine: "確定",
                    sort: "排序",
                    sortSucceeded: "排序成功",
                    supervisionItemClassification: "監督項分類",
                    pressEnterToCreateANew: "按回車新建",
                    nameCannotBeEmpty: "名稱不能為空",
                    newlyBuild: "新建",
                    name: "名稱",
                    explain: "說明",
                    validStatus: "有效狀態",
                    permanentlyValid: "永久有效",
                    permanentlyInvalid: "永久無效",
                    operation: "操作",
                    edit: "編輯",
                    batchDelete: "批量删除",
                    newSupervisionItem: "新建監督項",
                    keyWord: "關鍵字",
                    delete: "删除",
                    deleteSucceeded: "删除成功",
                },
            },
            analysisReport: {
                components: {
                    list: {
                        index: {
                            theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "數據删除後將無法恢復，是否確認删除？",
                        },
                    },
                    businessAnalysisFiter: {
                        component: {
                            smartTag: "智慧標籤",
                            beEqualTo: "等於",
                            includeAll: "包含全部",
                            includeAny: "包含任意",
                            matchAny: "匹配任意",
                            matchAll: "匹配全部",
                            smartTagI: "智慧標籤一",
                            smartTagII: "智慧標籤二",
                        },
                        template: {
                            pleaseSelectASmartTag: "請選擇智慧標籤",
                            pleaseSelectAdvancedConditions: "請選擇高級條件",
                            newAdvancedFilter: "新建高級篩選",
                            rename: "重命名",
                            moveUp: "上移",
                            moveDown: "下移",
                            shiftLeft: "左移",
                            shiftRight: "右移",
                        },
                    },
                },
                template: {
                    delete: "删除",
                    labelFiltering: "標籤篩選：",
                    satisfyAny: "滿足任意",
                    meetAll: "滿足全部",
                    theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "二級分類標籤對話數≥一級分類，一個會話可能包含多個標籤",
                    calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "環比變化計算邏輯：（所選時間段對話量-緊挨著的上個時間段的對話量）/上個時間段的對話量",
                    proportionOfPrimaryClassification: "一級分類占比情况",
                    saveAs: "另存為",
                    cover: "覆蓋",
                    preservation: "保存",
                    qualityInspectionTask: "質檢任務：",
                    query: "査詢",
                    changeFromMonthToMonth: "環比變化情况",
                    tagRanking: "標籤排行",
                    proportion: "占比情况",
                },
                component: {
                    numberOfConversations: "對話數",
                    operation: "操作",
                    businessAnalysispng: "業務分析.png",
                    savingSucceeded: "保存成功",
                    deletionSucceeded: "删除成功",
                    sequence: "序列",
                    firstLevelClassification: "一級分類",
                    ringRatioChange: "環比變化",
                    classClassification: "級分類",
                },
            },
            intelligentExtractors: {
                components: {
                    pageLeftTable: {
                        areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "確認删除檔案及其抽取內容？",
                        addKnowledgeBase: "添加知識庫",
                        uploadDocument: "上傳檔案",
                        supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "支持word、pdf、ppt、excel、txt格式檔案",
                        taskName: "任務名稱",
                        extractConversationDate: "抽取對話日期",
                        pleaseSelectAConversationDate: "請選擇對話日期",
                        dataSource: "資料來源",
                        dataType: "資料類型",
                    },
                    pageRightTable: {
                        index: {
                            problem: "問題",
                            answer: "答案",
                            addToKnowledgeBase: "添加到知識庫",
                            pleaseConfirmWhetherToDeleteIt: "請確定是否删除",
                        },
                        pageRightFrom: {
                            addToKnowledgeBase: "添加到知識庫",
                            selectClassification: "選擇分類",
                            pleaseSelectAKnowledgeBaseClassification: "請選擇知識庫分類",
                        },
                    },
                },
                index: {
                    intelligentExtraction: "智慧抽取",
                    documentExtraction: "檔案抽取",
                    dialogueExtraction: "對話抽取",
                },
            },
        },
        logoSite: {
            index: {
                intelligentQualityInspection: "智能质检",
                savedSuccessfully: "保存成功",
                logoSettings: "logo设置",
                default: "默认",
                custom: "自定义",
                title: "标题",
                theTitleOfTheSystemDisplayShouldBeWithinWords:
          "系统展示的标题名称，建议6字以内。",
                logoImageUpload: "logo图片上传",
                pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "请选择大小不超过2048kb的图片",
                inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "为保证logo浏览效果，上传图片建议：",
                Size5050Pixels: "1.尺寸：50*50像素；",
                FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2.格式：透明背景的图片，png或jpg，logo图形使用白色",
                browserLabelIcon: "浏览器标签图标",
                toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "为保证标签浏览效果，上传图片建议：",
                Size3232Pixels: "1.尺寸：32*32像素；",
                FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2.格式：透明背景的图片，png或jpg",
            },
        },
        sdkTest: {
            index: {
                qualityInspectionInProgressPleaseCheckLater: "质检进行中，请稍后...",
            },
            componentIndex: {
                atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "2015年那會，我國房地產到了生存的關鍵時刻，大量的房子無人問津，老百姓不願意購買。 杭州一度跌破8000，有開發商砸盤出貨，深圳當時還在2w-3w左右盤整，一線都市的房子也岌岌可危",
                xiaoAn: "小安",
                storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload這些記憶體屏障叫jsr記憶體屏障，是jvm層面的，在作業系統底層實現管道有二：1是鎖匯流排，二是記憶體屏障元語，如sfance（寫屏障）mfence（全屏障）ifence（讀屏障）。 hotspots虛擬機器底層採用的是一條彙編語句：lock addl，lock一個空操作，即為鎖匯流排，這麼做是因為部分作業系統不支持記憶體屏障元語。",
                xiaobei: "小北",
                TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1.試著醃過幾次肉，加料酒後炒出來的菜，肉和餿了似的，一股酸臭味！ 從此醃肉再不放料酒",
                xiaodong: "小東",
                xiaoxi: "小西",
                TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3.試著醃過幾次肉，加料酒後炒出來的菜，肉和餿了似的，一股酸臭味！ 從此醃肉再不放料酒",
            },
        },
        home: {
            index: {
                taskList: "任務清單",
                videoAnalysis: "視頻分析",
                overview: "總覽",
                personalSignage: "個人看板",
                learningCenter: "學習中心",
                wrongQuestionSet: "錯題集",
                abilityImprovement: "能力提升",
                close: "關閉",
                switchingSystemApplications: "切換系統應用",
                moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "滑鼠移動至此處可喚出應用切換選單，點擊功能表項目跳轉不同應用",
                teamAnalysis: "團隊分析",
                businessConfiguration: "業務配寘",
                semanticIntelligence: "語義智慧",
                permissionManagement: "許可權管理",
                dataConnection: "數據對接",
                courseManagement: "課程管理",
                labelManagement: "標籤管理",
                curriculum: "課程中心",
                courseConfiguration: "課程配寘",
                task: "我的任務",
                historicalLearningData: "歷史記錄",
                taskCenter: "任務管理",
                knowledgeBase: "知識庫",
                ecommerceSessionManagement: "电商会话管理",
            },
            components: {
                subAppEntries: {
                    applicationSwitching: "應用切換",
                    descriptionOfIntelligentQualityInspectionSystem: "智慧質檢系統描述",
                },
            },
        },
        eCommerceManage: {
            template: {
                ecommerceSessionFilter: "电商会话筛选器",
                allEcommerceSessions: "全部电商会话",
                ecommerceSessionManagement: "电商会话管理",
            },
        },
        analysisEnable: {
            bestPractices: "最佳實踐",
            behaviorAnalysis: "行為分析",
            teamAnalysis: "團隊分析",
            executiveSupervision: "執行力監督",
            executiveForceAnalysis: "執行力分析",
        },
        appealCheckingTask: {
            sdk: {
                index: {
                    grade: "評級：",
                    startTime: "開始時間",
                    workOrderNo: "工單編號",
                    customerServiceHasReadNotRead: "客服已讀/未讀",
                    canYouAppeal: "是否可申訴",
                },
            },
        },
        clientCenter: {
            wechatClientManage: {
                template: {
                    enterpriseAndMicroNotes: "企微備註",
                    addFriendTime: "添加好友時間",
                },
            },
        },
        qualityInspectionList: {
            detail: {
                component: {
                    startPerformingReQualityInspection: "開始執行重新質檢",
                    callID: "通話ID",
                },
                template: {
                    newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "新功能！ 點擊‘快速抽檢’按鈕，提交成功後會自動跳轉到下一頁啦~~~",
                },
            },
            template: {
                reIdentification: "重新識別",
                rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "重新識別會消耗轉寫時長，請謹慎操作",
                determine: "確定",
                executionSuccessful: "執行成功",
            },
            component: { startPerformingReQualityInspection: "開始執行重新質檢" },
        },
        recordUpload: {
            template: {
                uploadFileName: "上傳文件名稱",
                analysisTask: "分析任務",
                analyzeProgress: "分析進度",
                analysisSucceeded: "分析成功",
                analysisFailed: "分析失敗",
                localDataUpload: "本地數據上傳",
                recordingUpload: "錄音上傳",
                textUpload: "文字上傳",
                uploadTime: "上傳時間",
                taskName: "任務名稱",
                operator: "操作人",
                qualityInspectionProgress: "質檢進度",
                whole: "全部",
                haveInHand: "進行中",
                completed: "已完成",
                exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "是否匯出當前篩選條件下所有已完成的數據記錄？",
                upload: "上傳",
            },
            detail: {
                info: {
                    template: {
                        fillInTheName: "填寫名稱",
                        fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "填寫名稱，如本次導入數據的類型/集合/目的",
                        default: "默認",
                        jDCOM: "京東",
                        dataType: "資料類型",
                        selectAnalysisTask: "選擇分析任務",
                        uploadFiles: "上傳文件",
                        DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1.下載excel範本，按格式填寫：",
                        template: "範本",
                        SupportUpTo10000PiecesOfData: "2.最大支持10000條數據",
                        supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "支持京東匯出的對話數據上傳，.txt檔案，文件大小最大支持100M",
                        alongTheWayDataTemplate: "隨路數據範本",
                    },
                },
                result: {
                    template: {
                        textUnderInspection: "文字質檢中",
                        textQualityInspectionCompleted: "文字質檢完成",
                    },
                },
                template: {
                    startAnalysis: "開始分析",
                    pleaseUploadTheFile: "請上傳文件",
                    localDataUpload: "本地數據上傳",
                },
            },
        },
        reviewList: {
            detail: {
                template: {
                    newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "新功能！ 點擊‘覆核提交’按鈕，提交成功後會自動跳轉到下一頁啦~~~",
                },
            },
        },
        role: {
            detail: {
                taskPermissions: {
                    template: {
                        qualityInspectionTaskPermission: "質檢任務許可權",
                        edit: "編輯",
                        iParticipated: "我參與的",
                    },
                },
                dataPermissions: {
                    template: {
                        thisRoleCanSeeDataUnderTheSelectedDepartment:
              "該角色能够看見所選部門下的數據",
                        dataPermissions: "數據許可權",
                        selectDepartment: "選擇部門",
                        edit: "編輯",
                    },
                },
            },
        },
        spotCheckList: {
            detail: {
                component: {
                    startPerformingReQualityInspection: "開始執行重新質檢",
                    callID: "通話ID",
                },
                template: {
                    newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "新功能！ 點擊‘抽檢提交’和‘快速抽檢’按鈕，提交成功後會自動跳轉到下一頁啦~~~",
                },
            },
        },
        templateTest: { semanticTags: { template: { rule: "規則" } } },
        ticketManage: {
            detail: {
                template: {
                    workOrderManagement: "工單管理",
                    workOrderDetails: "工單詳情",
                },
            },
            template: {
                workOrderFilter: "工單篩選器",
                allWorkOrders: "全部工單",
                workOrderManagement: "工單管理",
            },
        },
        trainingCenter: {
            topicAnnotation: "話題標注",
            topicList: "話題清單",
            clusteringAnnotation: {
                components: {
                    filter: {
                        index: {
                            roleType: "角色類型",
                            keyWord: "關鍵字",
                            pleaseEnterKeywordSearch: "請輸入關鍵字搜索",
                            query: "査詢",
                        },
                    },
                },
            },
        },
        adminEntry: {
            businessWorkingHours: "業務工作時間",
            localDataUpload: "本地數據上傳",
            assembleMenuListAccordingToCurrentUserPermissions:
        "根據當前用戶許可權，組裝選單清單",
            sessionSettings: "會話設定",
            logoSettings: "logo設定",
        },
        login: {
            template: {
                welcomeBackPleaseEnterYourDetails: "歡迎回來！ 請輸入您的詳細資訊",
                mailbox: "郵箱",
                password: "密碼",
                signIn: "登入",
            },
        },
        checkPointTest: {
            index: {
                ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "規則測試：部分規則不能使用規則測試，如語速音量、隨路數據等",
            },
        },
        appEntry: {
            components: {
                subSystemEntryCard: {
                    learnMore: "瞭解更多",
                    enteringTheSystem: "進入系統",
                },
            },
            index: {
                close: "關閉",
                enterSystemApplication: "進入系統應用",
                clickEnterSystemToJumpToTheEnabledSystemApplication:
          "點擊“進入系統”跳轉至已啟用的系統應用",
            },
        },
        permissionManagement: {
            employeeManagement: "員工管理",
            roleManagement: "角色管理",
            permissionManagement: "許可權管理",
        },
        generalSetting: {
            docking: {
                components: {
                    ruleList: {
                        beEqualTo: "等於",
                        contain: "包含",
                        doesNotContain: "不包含",
                        applicationSystem: "應用系統",
                        intelligentQualityInspection: "智慧質檢/智慧會話分析",
                        salesEmpowerment: "銷售賦能",
                        dataSourceType: "資料來源類型",
                        filterCriteria: "篩選條件",
                    },
                },
                index: {
                    dataConnection: "數據對接",
                    savingSucceeded: "保存成功",
                    dataIsolation: "數據隔離",
                    afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "開啟後，可設定各應用系統接入通話、企業微信會話的數據範圍。 開關不開啟，則各個系統都接入全量數據",
                    accessRules: "接入規則",
                    preservation: "保存",
                },
            },
            permissionManagement: {
                role: {
                    index: {
                        applyName: "應用名稱",
                        accessRights: "存取權限",
                        inAppPermissionSettings: "應用內許可權設定",
                        setUp: "設定",
                        applicationAccess: "應用存取權限",
                        newRole: "新增角色",
                    },
                },
            },
        },
        wechatNotificationAnalysis: {
            conversationalAnalysis: { index: { sessionDetails: "會話詳情" } },
            messageNotification: { index: { sessionDetails: "會話詳情" } },
        },
        coach: {
            courseManagement: {
                baseInfo: {
                    index: {
                        visibleEmployees: "可見員工",
                        newCourse: "新建課程",
                        editCourse: "編輯課程",
                        courseName: "課程名稱",
                        courseIntroduction: "課程介紹",
                        courseType: "課程類型",
                        courseClassification: "課程分類",
                        courseCover: "課程封面",
                        learningContent: "學習內容",
                        uploadLearningMaterials: "上傳學習資料",
                    },
                },
                flow: {
                    components: {
                        configDrawer: {
                            preservation: "保存",
                            cancel: "取消",
                            editNodeName: "編輯節點名稱",
                            determine: "確定",
                            individual: "個",
                            of: "，共",
                            branch: "分",
                        },
                        configDrawerComponents: {
                            pPTParsing: "PPT解析中…",
                            uploadSuccessful: "上傳成功",
                            uploadAttachments: "上傳附件",
                            pPTParsingPleaseWait: "PPT解析中，請稍後",
                        },
                        customerPortraitConfigDrawer: {
                            cancel: "取消",
                            afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "删除後無法恢復，確定要删除此畫像嗎？",
                            delete: "删除",
                            determine: "確定",
                            customerPortrait: "客戶畫像",
                            bindSelectedCharacterPortrait: "綁定選中角色畫像",
                            customerProfileDetails: "客戶畫像詳情",
                            createANewCustomerProfile: "新建客戶畫像",
                        },
                        flow: {
                            sidebar: { node: "節點" },
                            useControlButtons: {
                                cancellingPleaseWait: "撤銷中，請稍後…",
                                redoInProgressPleaseWait: "重做中，請稍後…",
                                automaticOrganization: "自動整理",
                                canvasAdaptation: "畫布自我調整",
                                delete: "删除",
                            },
                            useFlow: {
                                nodeCannotBeConnectedToItself: "節點不能與自己相連接",
                            },
                        },
                        globalConfigDrawer: {
                            cancel: "取消",
                            determine: "確定",
                            globalConfiguration: "全域配寘",
                            sessionDetection: "會話檢測",
                            reciprocalRule: "互動規則",
                            robotVoice: "機器人音色",
                        },
                        interactionRuleForm: {
                            studentNodeTimeoutWithoutAnsweringSetting:
                "營員節點超時未作答設定",
                            maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "營員未作答允許的最大靜默時間（s）",
                            promptScriptForFailureToAnswerOverTime: "超時未作答提示話術",
                            addScript: "添加話術",
                        },
                        robotTimbreForm: {
                            aixianatural: "艾夏（自然）",
                            zhishaChat: "知莎（聊天）",
                            zhiqiangentle: "知倩（溫和）",
                            zhimiEmotion: "知米（情感）",
                            aiShuoNatural: "艾碩（自然）",
                            zhishuoChat: "知碩（聊天）",
                            aiHaoAffinity: "艾浩（親和）",
                            zhixiangEmotion: "知祥（情感）",
                            robotVoice: "機器人音色",
                            schoolboy: "男生",
                            girlStudent: "女生",
                            volume: "音量",
                            tone: "音調",
                            speechSpeed: "語速",
                            test: "測試",
                            helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "您好，我是智慧培訓教練，期待與你的交流",
                        },
                        sessionDetectionForm: {
                            expressionDetection: "表達檢測",
                            excessiveDetectionOfModalParticles: "語氣詞過多檢測",
                            theNumberOfRepetitionsIs: "重複次數為",
                            minus: "次時，减",
                            appear: "出現",
                            subtract: "次，减",
                            branch: "分",
                            addRules: "新增規則",
                            includingNegativeAngryComplaining: "（包括負面、生氣、抱怨）",
                            scorePointsDisplay: "得分要點顯示",
                            reminderOfSpeakingTooFasttooSlow: "語速過快/過慢提醒",
                            scriptViolationReminder: "話術違規提醒",
                            emotionalReminder: "情緒提醒",
                            speechSpeedDetection: "語速檢測",
                            normalSpeechSpeedScore: "語速正常得分",
                            speakTooFast: "語速過快",
                            everyMinuteGreaterThan: "每分鐘大於",
                            aWordAppears: "個字，出現",
                            speakTooSlowly: "語速過慢",
                            lessThanPerMinute: "每分鐘小於",
                            ignoreLessThan: "忽略小於",
                            aSentenceOfWords: "字的句子",
                            emotionalDetection: "情緒檢測",
                            emotionalNormalScore: "情緒正常得分",
                            negativeEmotions: "負向情緒",
                            illegalWordDetection: "違規詞檢測",
                            scriptViolationConfiguration: "話術違規配寘",
                            realTimePromptOnTheFrontEnd: "前端實时提示",
                        },
                        const: {
                            nodeConfigs: {
                                knowledgeNode: {
                                    knowledgeBaseQA: "知識庫問答",
                                    qAMethod: "問答管道",
                                    numberOfQuestionsAndAnswers: "問答數量",
                                    scoringRules: "得分規則",
                                    ifAnyQuestionAndAnswerIsCorrect: "任意一個問答正確，得",
                                    branch: "分",
                                    successfullySaved: "保存成功",
                                },
                                pptNode: {
                                    uploadSlide: "上傳幻燈片",
                                    assessmentMethod: "考核管道",
                                    associateSlide: "關聯幻燈片",
                                    standardScript: "標準話術",
                                    successfullySaved: "保存成功",
                                },
                                robotNode: {
                                    robotScript: "機器人話術",
                                    randomScript: "隨機話術",
                                    chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "選擇相似語義的話術，機器人將隨機說出其中一句",
                                    customerPortrait: "客戶畫像",
                                    whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "當客戶畫像一致時，機器人說出此話術",
                                    setAsDefaultScript: "設為默認話術",
                                    yes: "是",
                                    no: "否",
                                    useThisScriptWhenTheRobotMissesAnyUserTags:
                    "當機器人未命中任何用戶標籤時出此話術",
                                    successfullySaved: "保存成功",
                                    thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "此分支節點已設有默認話術，是否將默認話說替換為當前話術？",
                                },
                                startNode: {
                                    paragon: "旁白話術",
                                    successfullySaved: "保存成功",
                                },
                                userNode: {
                                    standardScript: "標準話術",
                                    successfullySaved: "保存成功",
                                },
                            },
                        },
                    },
                    index: {
                        editBasicInformation: "編輯基本資訊",
                        test: "測試",
                        release: "發佈",
                    },
                },
            },
            customerTagsManagement: {
                components: {
                    addTag: { index: { newLabel: "新建標籤" } },
                    tag: {
                        index: {
                            afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "删除後無法恢復，確定要删除此標籤嗎？",
                            delete: "删除",
                            cancel: "取消",
                        },
                    },
                },
                index: {
                    labelManagement: "標籤管理",
                    newGroup: "新建分組",
                    edit: "編輯",
                    afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "删除後無法恢復，確定要删除此標籤組嗎？",
                    delete: "删除",
                    cancel: "取消",
                    editGroup: "編輯分組",
                    labelGroupName: "標籤分組名稱",
                },
            },
            learningCenter: {
                components: {
                    camera: {
                        index: {
                            cameraDisabled: "禁用了監視器",
                            noCameraDevicesFound: "沒有找到監視器設備",
                        },
                    },
                    cue: {
                        index: {
                            referenceScript: "參攷話術",
                            mattersNeedingAttention: "注意事項",
                            examFrequency: "考試次數",
                            section: "第",
                            timestotal: "次/共",
                            second: "次",
                            requirementsForPassingTheExam: "考試通過要求",
                            examScore: "考試得分≥",
                            branch: "分",
                        },
                    },
                    customerInfo: {
                        index: {
                            thereIsCurrentlyNoPortraitAvailable: "暫無畫像",
                            customerBackground: "客戶背景",
                            customerLabel: "客戶標籤",
                        },
                    },
                    flow: {
                        index: {
                            processReminder: "流程提示（",
                            score: "得分",
                            TotalScore: "/總分",
                        },
                    },
                    index: {
                        taskName: "任務名稱",
                        taskStatus: "任務狀態",
                        whole: "全部",
                        completionStatus: "完成狀態",
                        courseClassification: "課程分類",
                        practiceMode: "練習模式",
                        examMode: "考試模式",
                        practiceTime: "練習時間",
                    },
                    ppt: {
                        index: {
                            nextPage: "下一頁",
                            tips: "提示",
                            continueWithCurrentPage: "繼續當前頁",
                            goToTheNextPage: "進入下一頁",
                            afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "進入下一頁後將不能再返回上一頁，請確認當前頁講解完整後再跳轉",
                            clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "點擊下一頁將不在彈出此提示",
                        },
                        list: { index: { slide: "幻燈片" } },
                    },
                    record: {
                        index: {
                            unableToRecord: "無法錄音：",
                            creatingARecorderService: "創建Recorder服務",
                            turnOffTheRecorderService: "關閉Recorder服務",
                            theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "當前用戶端已經連接到ASR Websocket服務器",
                            afterProcessingTheLastFrameRestoreTheState:
                "處理完最後一幀後，將狀態恢復",
                            aSRConnectionClosed: "ASR連接已關閉…",
                            recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "錄音暫停，並且已處理完，但是緩存中仍然存在數據",
                            foundASRChannelClosedRecreateWebsocketLink:
                "發現ASR通道關閉，重新創建Websocket連結",
                            foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "發現ASR通道未開啟，重新創建Websocket連結",
                        },
                        ppt: { hook: { index: { serverError: "服務器錯誤" } } },
                        text: {
                            index: {
                                pleaseEnter: "請輸入",
                                sendOut: "發送",
                                startPracticing: "開始練習",
                                startTheExam: "開始考試",
                            },
                        },
                        util: {
                            recorded: "已錄製",
                            BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16比特pcm SendFrameSize必須為2的整數倍",
                            pCMSamplingRateShouldNotOccur: "不應該出現pcm取樣速率",
                            andTheRequiredSamplingRate: "和需要的取樣速率",
                            atypism: "不一致",
                            errorsThatShouldNotOccur: "不應該出現的錯誤：",
                            spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                "<span style=“color:red”>順序錯亂的數據，如果要求不高可以直接丟棄</span>",
                            flower: "花",
                            TransferStopped: "：已停止傳輸",
                        },
                        video: { index: { camera: "監視器" } },
                        voice: {
                            hook: {
                                bat: {
                                    index: {
                                        theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "當前用戶端已經連接到ASR Websocket服務器",
                                        aSRConnectionClosed: "ASR連接已關閉…",
                                        foundASRChannelClosedRecreateWebsocketLink:
                      "發現ASR通道關閉，重新創建Websocket連結",
                                        unableToRecord: "無法錄音：",
                                        aSRChannelClosedReopened: "ASR通道關閉，重新打開",
                                        theASRChannelWasNotCreatedStartingNow:
                      "ASR通道未創建，現在開始創建",
                                        useEffectCallbackToClearTheASRChannel:
                      "useEffect回檔，進行清除ASR通道",
                                        creatingARecorderService: "創建Recorder服務",
                                        turnOffTheRecorderService: "關閉Recorder服務",
                                    },
                                    util: {
                                        recorded: "已錄製",
                                        BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16比特pcm SendFrameSize必須為2的整數倍",
                                        pCMSamplingRateShouldNotOccur: "不應該出現pcm取樣速率",
                                        andTheRequiredSamplingRate: "和需要的取樣速率",
                                        atypism: "不一致",
                                        errorsThatShouldNotOccur: "不應該出現的錯誤：",
                                        spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      "<span style=“color:red”>順序錯亂的數據，如果要求不高可以直接丟棄</span>",
                                        flower: "花",
                                        TransferStopped: "：已停止傳輸",
                                    },
                                },
                            },
                            index: {
                                startRecording: "開始錄音",
                                endRecording: "結束錄音",
                                startPracticing: "開始練習",
                                startTheExam: "開始考試",
                            },
                        },
                    },
                    taskMessageModal: {
                        index: {
                            totalScriptScore: "話術總分：",
                            totalEmotionalScore: "情緒總分：",
                            totalScoreOfExpression: "表達總分：",
                            theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "當前任務評分結果需人工稽核，稽核完成將通知您最終成績，請耐心等待",
                            areYouSureToSubmit: "是否確認提交？",
                            theExerciseHasEndedAreYouSureToSubmit:
                "練習已結束，是否確認提交？",
                            theExamHasEndedAreYouSureToSubmit: "考試已結束，是否確認提交？",
                            completed: "已完成",
                            page: "頁",
                            pageCount: "總頁數",
                            topic: "題",
                            totalNumberOfQuestions: "總題數",
                            totalScore: "總分",
                            branch: "分",
                            accumulatedCompletionProgress: "累計完成進度",
                            accumulatedPracticeDuration: "累計練習時長",
                            lastPauseExerciseResults: "上次暫停-練習結果",
                            exerciseInstructions: "練習說明",
                            examInstructions: "考試說明",
                            halfwayPausePracticeResults: "中途暫停-練習結果",
                            tips: "提示",
                            afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "退出後此練習不計入成績，是否確認退出？",
                            exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "考試中途不允許退出，是否確認提交？",
                            pleaseOperateWithCaution: "次，請謹慎操作）",
                            restart: "重新開始",
                            returnToLearning: "返回學習",
                            continuePracticing: "繼續練習",
                            continuingTheExam: "繼續考試",
                            return: "返回",
                            notSubmittedTemporarily: "暫不提交",
                            iGotIt: "我知道了",
                            confirmExit: "確認退出",
                            confirmSubmission: "確認提交",
                            signOut: "退出",
                        },
                    },
                    taskSubmitButton: {
                        index: {
                            suspend: "暫停",
                            submit: "提交",
                            remainingTimeOfThisQuestion: "本題剩餘時間",
                            SecondsLeftUntilTheEndTime: "距離結束時間還剩10秒",
                            remainingTime: "剩餘時間",
                        },
                    },
                    util: {
                        noUserVoiceContentDetectedPleaseTryAgain:
              "未檢測到用戶語音內容，請重新嘗試",
                    },
                    wave: {
                        index: {
                            continueRecording: "繼續錄音",
                            submit: "提交",
                            endRecording: "結束錄音",
                            suspendRecording: "暫停錄音",
                            startPracticing: "開始練習",
                            startTheExam: "開始考試",
                        },
                    },
                    deviceModal: {
                        index: {
                            pleaseReadTheRequirementsCarefullyBeforeStarting:
                "開始前請您認真閱讀一下要求",
                            return: "返回",
                            iGotIt: "我知道了",
                            timeLimitForSingleQuestionAnswering: "單題作答時長限制",
                            totalTimeLimit: "總限時",
                            unlimitedTime: "不限時",
                            stillNeedToCompleteTheTaskInTotal: "總完成任務還需",
                            practice: "練習",
                            second: "次",
                            taskScore: "任務一次得分≥",
                            branch: "分",
                            or: "或",
                            examScore: "考試得分≥",
                            remainingExamTimes: "剩餘考試次數",
                            totalScore: "總分",
                            Script: "（話術",
                            scoreEmotion: "分+情緒",
                            fractionExpression: "分+表達",
                            points: "分）",
                            standardScore: "達標分",
                            excellentScore: "優秀分",
                            exerciseInstructions: "練習說明",
                            forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "為了答題順利，請先完成設備檢測：",
                            camera: "監視器",
                            beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "能在視頻內看見自己，意味著監視器正常",
                            microphone: "麥克風",
                            pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "請對著麥克風說話，看到波形圖樣意味著麥克風正常",
                        },
                    },
                },
                course: {
                    components: {
                        folderTree: {
                            modal: {
                                index: {
                                    empty: "空",
                                    copyTo: "複製到",
                                    classification: "分類",
                                    rename: "重命名",
                                    newlyBuild: "新建",
                                    categoryParent: "上級分類",
                                    moveTo: "移動到",
                                    name: "名稱",
                                },
                            },
                            util: {
                                rename: "重命名",
                                moveTo: "移動到",
                                copyTo: "複製到",
                                delete: "删除",
                                courseClassification: "課程分類",
                                newlyBuild: "新建",
                            },
                        },
                    },
                    edit: { index: { curriculum: "課程" } },
                    index: {
                        learn: "學",
                        practice: "練",
                        examination: "考",
                        curriculum: "課程",
                        courseIntroduction: "課程介紹：",
                        createdOn: "創建時間：",
                        published: "已發佈",
                        tasks: "個任務",
                        unpublishedTasks: "未發佈任務",
                        task: "任務",
                        operation: "操作",
                        learningMaterials: "學習資料",
                        goPractice: "去練習",
                        publishTask: "發佈任務",
                        taskData: "任務數據",
                        edit: "編輯",
                        copy: "複製",
                        successfullyCopied: "複製成功",
                        delete: "删除",
                        afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "删除後此課程下的所有任務數據都將被清空，是否確認删除課程？",
                        courseName: "課程名稱",
                        creationTime: "創建時間",
                        releaseStatus: "發佈狀態",
                        whole: "全部",
                    },
                },
                nodeConfigTest: {
                    components: {
                        input: {
                            noSoundDetected: "未檢測到聲音",
                            voice: "語音",
                            text: "文字",
                            referenceScript: "參攷話術：",
                            clickOnTheRightSideToStartRecording: "點擊右側開始錄音",
                            sendOut: "發送",
                        },
                        hooks: {
                            input: {
                                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "當前用戶端已經連接到ASR Websocket服務器",
                                afterProcessingTheLastFrameRestoreTheState:
                  "處理完最後一幀後，將狀態恢復",
                                aSRConnectionClosed: "ASR連接已關閉…",
                                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "錄音暫停，並且已處理完，但是緩存中仍然存在數據",
                                foundASRChannelClosedRecreateWebsocketLink:
                  "發現ASR通道關閉，重新創建Websocket連結",
                                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "發現ASR通道未開啟，重新創建Websocket連結",
                                unableToRecord: "無法錄音：",
                                creatingARecorderService: "創建Recorder服務",
                                turnOffTheRecorderService: "關閉Recorder服務",
                            },
                            util: {
                                recorded: "已錄製",
                                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16比特pcm SendFrameSize必須為2的整數倍",
                                pCMSamplingRateShouldNotOccur: "不應該出現pcm取樣速率",
                                andTheRequiredSamplingRate: "和需要的取樣速率",
                                atypism: "不一致",
                                errorsThatShouldNotOccur: "不應該出現的錯誤：",
                                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  "<span style=“color:red”>順序錯亂的數據，如果要求不高可以直接丟棄</span>",
                                flower: "花",
                                TransferStopped: "：已停止傳輸",
                            },
                        },
                    },
                    index: {
                        testFromCurrentNode: "從當前節點測試",
                        listening: "收音中…",
                        canvasTesting: "畫布測試",
                        endOfProcess: "流程結束",
                        restart: "重新開始",
                        start: "開始",
                    },
                },
                record: {
                    detail: {
                        components: {
                            openButton: {
                                index: {
                                    collapseAnalysis: "收起解析",
                                    expandParsing: "展開解析",
                                },
                            },
                            overView: {
                                index: {
                                    tooManyModalParticles: "，語氣詞過多",
                                    second: "次",
                                    ratingOverview: "評分總覽",
                                    totalScore: "總分",
                                    section: "第",
                                    name: "名",
                                    of: "（共",
                                    people: "人）",
                                    scriptScore: "話術得分",
                                    common: "共",
                                    keyPoints: "個要點，",
                                    notReached: "個未達到，",
                                    have: "有",
                                    violationOfRegulations: "處違規",
                                    emotionalScore: "情緒得分",
                                    appear: "出現",
                                    secondaryNegativeEmotions: "次負面情緒",
                                    expressionScore: "表達得分",
                                    speakTooFast: "語速過快",
                                    times: "次，",
                                    speakTooSlowly: "語速過慢",
                                    average: "平均",
                                    wordminute: "字/分鐘",
                                },
                            },
                            score: {
                                index: {
                                    studentNodeName: "營員節點名稱",
                                    keyPointName: "要點名稱",
                                    slideNodeName: "幻燈片節點名稱",
                                    dialogue: "對話",
                                    mainPoints: "要點",
                                    slide: "幻燈片",
                                    detectionResult: "檢測結果",
                                    satisfy: "滿足",
                                    dissatisfaction: "不滿足",
                                    score: "得分",
                                    script: "話術",
                                    semantics: "語義",
                                    scriptViolation: "話術違規",
                                    emotion: "情緒",
                                    expression: "表達",
                                },
                            },
                            taskTitle: { index: { branch: "分" } },
                            comprehensiveEvaluation: {
                                const: {
                                    thisTime: "本次",
                                    lastTime: "上次",
                                    comprehensiveEvaluation: "綜合評價",
                                },
                                index: {
                                    complete: "內容完整",
                                    keyHit: "要點命中",
                                    languageNorm: "語言規範",
                                    fluentExpression: "表達流暢",
                                    emotionallyPositive: "情感積極",
                                    radarChart: "雷達圖",
                                    spanStylemarginLeftpxNumberOfCompleteProcesses:
                    "<span style=“margin-left: 8px”>·完整流程數",
                                    spanStylemarginLeftpxNumberOfHitPoints:
                    "<span style=“margin-left: 8px”>·命中要點數",
                                    spanStylemarginLeftpxYes:
                    "<span style=“margin-left: 8px”>·有",
                                    dealingWithIllegalWordsspan: "處違規詞</span>",
                                    spanStylemarginLeftpxSpeakTooFast:
                    "<span style=“margin-left: 8px”>·語速過快",
                                    spanbrspanStylemarginLeftpxTooManyModalParticles:
                    "次</span><br /><span style=“margin-left: 8px”>·語氣詞過多",
                                    timesspan: "次</span>",
                                    spanStylemarginLeftpxNegativeEmotions:
                    "<span style=“margin-left: 8px”>·負面情緒",
                                },
                            },
                            scoreRecord: {
                                index: {
                                    scoringRecords: "評分記錄",
                                    firstRating: "首次評分",
                                    totalScore: "總分",
                                    changeTo: "變更為",
                                },
                            },
                        },
                        index: {
                            submitARatingAppeal: "提交評分申訴",
                            reviewSubmission: "覆核提交",
                            saveChanges: "保存修改",
                            previousSentence: "上一句",
                            nextSentence: "下一句",
                            score: "評分",
                            scoringSituation: "得分情况",
                            dialogueAnalysis: "對話解析",
                        },
                    },
                    index: {
                        ratingAppealInProgress: "評分申訴中",
                        operation: "操作",
                        wrongQuestionSet: "錯題集",
                        ratingUpdate: "評分更新",
                        viewDetails: "查看詳情",
                        curriculum: "課程",
                        taskName: "任務名稱",
                        taskType: "任務類型",
                        score: "得分",
                        scoringResults: "得分結果",
                        time: "時間",
                        courseClassification: "課程分類",
                        whole: "全部",
                        learningTime: "學習時間",
                        historicalLearningData: "歷史學習數據",
                    },
                },
                task: {
                    exercise: {
                        index: {
                            startPracticing: "開始練習",
                            historicalData: "歷史資料",
                            courseIntroduction: "課程介紹：",
                            exerciseDifficulty: "練習難度：",
                            practiceCompletionRequirements: "練習完成要求：",
                            practiceFrequency: "練習次數≥",
                            second: "次",
                            anyScore: "任意一次得分≥",
                            branch: "分",
                            or: "或",
                            taskList: "任務清單",
                            practiceTime: "練習時間：",
                            to: "至",
                            unlimitedTime: "不限時間",
                            completionStatus: "完成狀態",
                            numberOfExercises: "練習次數",
                            maximumScore: "最高分",
                            operation: "操作",
                            enteringLearning: "進入學習",
                            practice: "練習",
                            historicalLearningData: "歷史學習數據",
                            wrongQuestionSet: "錯題集",
                        },
                    },
                    index: {
                        practiceTasks: "練習任務",
                        examTasks: "考試任務",
                        task: "任務",
                    },
                    learn: {
                        index: {
                            courseName: "課程名稱",
                            courseIntroduction: "課程介紹",
                            learningContent: "學習內容",
                            downloadLearningMaterials: "學習資料下載",
                            startPracticing: "開始練習",
                        },
                    },
                    test: {
                        index: {
                            taskList: "任務清單",
                            courseIntroduction: "課程介紹：",
                            examDifficulty: "考試難度：",
                            requirementsForPassingTheExam: "考試通過要求：",
                            examScore: "考試得分≥",
                            branch: "分",
                            examTime: "考試時間：",
                            to: "至",
                            unlimitedTime: "不限時間",
                            completionStatus: "完成狀態",
                            completionTimes: "完成次數",
                            maximumScore: "最高分",
                            operation: "操作",
                            immediateExam: "立即考試",
                            history: "歷史記錄",
                        },
                    },
                },
                wrongQuestionSet: {
                    exercise: {
                        components: {
                            cue: { index: { referenceScript: "參攷話術" } },
                            flow: {
                                index: {
                                    wrongQuestionItem: "錯題項（",
                                    score: "得分",
                                    TotalScore: "/總分",
                                },
                            },
                            record: {
                                ppt: {
                                    hook: { index: { serverError: "服務器錯誤" } },
                                    index: {
                                        startRecording: "開始錄音",
                                        endRecording: "結束錄音",
                                        startPracticing: "開始練習",
                                    },
                                },
                                text: {
                                    index: {
                                        pleaseEnter: "請輸入",
                                        sendOut: "發送",
                                        startPracticing: "開始練習",
                                    },
                                },
                                voice: {
                                    index: {
                                        startRecording: "開始錄音",
                                        endRecording: "結束錄音",
                                        startPracticing: "開始練習",
                                    },
                                },
                            },
                            taskMessageModal: {
                                index: {
                                    scoreForThisPracticeOfIncorrectQuestions: "本次錯題練習得分",
                                    tips: "提示",
                                    areYouSureToEnd: "是否確認結束？",
                                    practiceAgainWithoutMasteringTheWrongQuestions:
                    "再次練習未掌握錯題",
                                    continue: "繼續",
                                    returnToTheSetOfIncorrectQuestions: "返回錯題集",
                                    end: "結束",
                                    wrongQuestionItem: "錯題項",
                                    numberOfExercisesThisTime: "本次練習次數",
                                    masteringTheSituation: "掌握情况",
                                    mastered: "已掌握",
                                    notMastered: "未掌握",
                                },
                            },
                            taskSubmitButton: {
                                index: {
                                    practiceThisQuestionAgain: "再次練習本題",
                                    nextQuestion: "下一題",
                                },
                            },
                        },
                        ppt: {
                            index: {
                                mispracticeExercises: "錯題練習",
                                endExercise: "結束練習",
                            },
                        },
                        text: {
                            index: {
                                mispracticeExercises: "錯題練習",
                                endExercise: "結束練習",
                            },
                        },
                        voice: {
                            index: {
                                mispracticeExercises: "錯題練習",
                                endExercise: "結束練習",
                            },
                        },
                    },
                    list: {
                        components: {
                            checkScript: {
                                index: {
                                    checkScript: "查看話術",
                                    section: "第",
                                    secondaryDiscourseTechnique: "次話術",
                                },
                            },
                            tableItem: {
                                count: { second: "次" },
                                scoreRuleList: {
                                    branch: "分",
                                    answerIncorrectly: "答錯",
                                    second: "次",
                                    wrongAnswerRate: "答錯率",
                                },
                                standardScript: {
                                    standardScript: "標準話術",
                                    myScript: "我的話術",
                                },
                                status: { mastered: "已掌握", notMastered: "未掌握" },
                                index: {
                                    totalNumberOfQuestionsAnswered: "共計答題次數",
                                    numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "錯題已練習次數",
                                },
                            },
                        },
                        index: {
                            wrongQuestionList: "錯題清單",
                            wrongQuestionSet: "錯題集",
                            mispracticeExercises: "錯題練習",
                            taskName: "任務名稱",
                            staff: "員工",
                            masteringTheSituation: "掌握情况",
                            whole: "全部",
                        },
                    },
                },
            },
            taskCenter: {
                components: {
                    completeStandart: {
                        index: {
                            practiceFrequency: "練習次數≥",
                            second: "次",
                            or: "或",
                            excellentScoreAtAnyOneTime: "任意一次得分優秀（≥",
                            points: "分）",
                            examScore: "考試得分≥",
                            branch: "分",
                        },
                    },
                    simplePieChart: { index: { nameb: "{name|{b}}" } },
                    manualReviewConfig: {
                        index: {
                            randomAverageDistribution: "隨機平均分配",
                            assignToDesignatedPersonnel: "分配給指定人員",
                        },
                    },
                },
                detail: {
                    resultAnalysis: {
                        totalScore: "總分",
                        average: "平均分",
                        total: "總數",
                        keyHitCount: "要點命中次數",
                        practiceAverageScore: "練習平均分",
                        averagePracticeDurationmin: "平均練習時長（min）",
                        topOfTheHighestScore: "最高分Top3",
                        branch: "分",
                        errorProneItemTop: "易錯項Top5",
                        hitRate: "命中率",
                        hitRateOfKeyPointsInScript: "話術要點命中率",
                        averageScriptScore: "話術平均得分",
                        sortByProcess: "按流程排序",
                        sortByScore: "按得分排序",
                        averageScoreOfThisPagetotalScoreOfThisPage: "該頁平均分/該頁總分",
                        averageScoreOfThisItemtotalScoreOfThisItem: "該項平均分/該項總分",
                        details: "詳情",
                        viewRules: "查看規則",
                        standardScript: "標準話術",
                    },
                    scoreDetailList: {
                        section: "第",
                        second: "次",
                        practice: "練習",
                        examination: "考試",
                        notMeetingStandards: "未達標",
                        meetingStandards: "達標",
                        excellent: "優秀",
                        frequency: "次數",
                        fraction: "分數",
                        result: "結果",
                        operation: "操作",
                        viewDetails: "查看詳情",
                    },
                    index: {
                        staff: "員工",
                        notMeetingStandards: "未達標",
                        meetingStandards: "達標",
                        excellent: "優秀",
                        notStarted: "未開始",
                        haveInHand: "進行中",
                        completed: "已完成",
                        passed: "已通過",
                        hangInTheAir: "未完成",
                        failed: "未通過",
                        unlimitedTime: "不限時間",
                        to: "至",
                        maximumScoringCriteria: "最高得分標準",
                        completionStatus: "完成狀態",
                        employeeName: "員工姓名",
                        frequency: "次數",
                        practice: "練習",
                        examination: "考試",
                        average: "平均分",
                        maximumScore: "最高分",
                        highestScoreResult: "最高得分結果",
                        state: "狀態",
                        averageDurationmin: "平均時長（min）",
                        lately: "最近",
                        time: "時間",
                        operation: "操作",
                        viewDetails: "查看詳情",
                        wrongQuestionSet: "錯題集",
                        taskName: "任務名稱",
                        completeRequirements: "完成要求",
                        publishTo: "發佈對象",
                        taskTime: "任務時間",
                        fractionalComposition: "分數構成",
                        totalScore: "總分",
                        branch: "分",
                        Script: "（話術",
                        scoreEmotion: "分+情緒",
                        fractionExpression: "分+表達",
                        points: "分）",
                        implementationRate: "執行率",
                        numberOfExecutors: "執行人數",
                        numberOfPeopleNotExecuted: "未執行人數",
                        completionRate: "完成率",
                        numberOfCompletedPersonnel: "完成人數",
                        numberOfUnfinishedPersonnel: "未完成人數",
                        complianceRate: "達標率",
                        numberOfQualifiedPersonnel: "達標人數",
                        numberOfNonCompliantIndividuals: "未達標人數",
                        excellentRate: "達優率",
                        numberOfOutstandingIndividuals: "優秀人數",
                        numberOfNonOutstandingIndividuals: "非優秀人數",
                        export: "匯出",
                        taskData: "任務數據",
                        resultAnalysis: "結果分析",
                        scoreDetails: "得分詳情-",
                    },
                },
                edit: {
                    index: {
                        excellentStandardsNeedToBeHigherThanStandardStandards:
              "優秀標準需高於達標標準",
                        theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "評分標準需要滿足大於0%，小於等於100%",
                        theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "評分標準需要滿足大於0分，小於等於{0}分",
                        doRatingsRequireManualReview: "評分是否需人工稽核",
                        allocationStrategy: "分配策略",
                        pleaseSelectTheAssignedPersonnel: "請選擇分配人員",
                        pleaseSelectADesignatedPerson: "請選擇指定人員",
                        timeLimitForSingleQuestionAnswering: "單題作答時長限制",
                    },
                    autoAddOrganizationList: {
                        publishToNewlyAddedEmployeesByDefault: "默認發佈給新添加員工",
                    },
                },
                list: { index: { releaseTime: "發佈時間" } },
                audit: {
                    components: {
                        pageLeftTable: {
                            reviewed: "已稽核",
                            pendingReview: "待稽核",
                            enterKeywordSearch: "輸入關鍵字搜索",
                        },
                        pageRightTable: {
                            index: {
                                employeeName: "員工姓名",
                                systemRating: "系統評分",
                                scoringResults: "得分結果",
                                duration: "時長",
                                time: "時間",
                                state: "狀態",
                                whetherToModifyTheRating: "是否修正評分",
                                finalScore: "最終得分",
                                reviewedBy: "稽核人",
                                operation: "操作",
                                viewScoreDetails: "查看得分詳情",
                                viewStatus: "查看狀態",
                                whole: "全部",
                                correctionStatus: "修正狀態",
                                completeReview: "完成稽核",
                                tips: "提示",
                                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "營員最終成績依當前最終稽核結果為准，請確認",
                            },
                        },
                    },
                    index: { manualReview: "人工稽核" },
                },
                review: {
                    index: {
                        employeeName: "員工姓名",
                        score: "得分",
                        scoringResults: "得分結果",
                        duration: "時長",
                        taskName: "任務名稱",
                        numberOfAppealScripts: "申訴話術數量",
                        appealTime: "申訴時間",
                        processStatus: "流程狀態",
                        scoreAfterReview: "覆核後得分",
                        reviewedBy: "覆核人",
                        operation: "操作",
                        viewScoreStatus: "查看得分情况",
                        whole: "全部",
                        manualReview: "人工覆核",
                    },
                },
            },
            dashboard: {
                components: {
                    adminDataCards: {
                        published: "已發佈",
                        courses: "個課程",
                        courseData: "課程數據",
                        practiceTasks: "練習任務",
                        examTasks: "考試任務",
                    },
                    practiceSummary: {
                        practiceSummary: "練習小結",
                        numberOfPracticeTasks: "練習任務數",
                        numberOfExercises: "練習次數",
                        accumulatedPracticeDuration: "累計練習時長",
                        complianceRate: "達標率",
                        excellentRate: "達優率",
                        ranking: "排行",
                    },
                    rankingList: {
                        exerciseDuration: "練習時長",
                        complianceRate: "達標率",
                        excellentRate: "達優率",
                        diligenceChart: "勤奮榜",
                        excellentList: "優秀榜",
                        ranking: "排名",
                        fullName: "姓名",
                        i: "我",
                    },
                    taskCard: {
                        end: "截止：",
                        noDeadline: "無截止日期",
                        second: "次",
                        branch: "分",
                    },
                    taskProgress: {
                        thereAreCurrentlyNoTasksAvailable: "暫無任務",
                        completed: "已完成",
                    },
                    filter: { employeeDepartment: "員工部門" },
                },
                hook: {
                    useSummary: {
                        activePopulation: "活躍人數",
                        accumulatedPracticeDuration: "累計練習時長",
                        averagePracticeDuration: "平均練習時長",
                        people: "人",
                        numberOfPracticeTasks: "練習任務數",
                        numberOfExercises: "練習次數",
                        exerciseDuration: "練習時長",
                        second: "次",
                    },
                },
                index: {
                    overview: "總覽",
                    personalSignage: "個人看板",
                    ongoingExercises: "進行中的練習",
                    ongoingExams: "進行中的考試",
                    practiceTaskProgress: "練習任務進度",
                    examTaskProgress: "考試任務進度",
                },
            },
        },
        conversationAnalysis: {
            videoAnalysis: {
                detail: {
                    components: {
                        dialogue: {
                            index: {
                                keywordSearch: "關鍵字搜索",
                                previous: "上一個",
                                next: "下一個",
                                theObjectThatTriggersTheScrollingEventIs:
                  "觸發滾動事件的對象是",
                                userScrolling: "用戶滾動",
                            },
                        },
                        summary: {
                            index: {
                                reminder: "提示語：",
                                edit: "編輯",
                                name: "名稱",
                                hintLanguage: "提示語",
                            },
                        },
                        viewpoint: { index: { viewpointExtraction: "觀點選取" } },
                        outline: {
                            index: {
                                numberOfKeywords: "關鍵字次數",
                                speaker: "說話人",
                                speechDuration: "說話時長",
                                proportionOfSpeaking: "說話占比",
                                maximumSpeechDuration: "最長發言時長",
                                numberOfQuestions: "提問數量",
                                averageSpeechSpeed: "平均語速",
                                conversationalActions: "會話動作",
                                contentSummary: "內容摘要",
                                generateSummary: "生成摘要",
                                keywordCloud: "關鍵字詞雲",
                            },
                        },
                    },
                    index: {
                        outline: "概要",
                        viewpoint: "觀點",
                        contentSummary: "內容摘要",
                        viewpointExtraction: "觀點選取",
                        summaryOfKeyPoints: "要點總結",
                        videoAnalysis: "視頻分析",
                    },
                },
                list: {
                    index: {
                        delete: "删除",
                        uploadSuccessful: "上傳成功",
                        videoList: "視頻清單",
                        uploadVideo: "上傳視頻",
                        videoName: "視頻名稱",
                        uploadTime: "上傳時間",
                        endTime: "結束時間",
                        analyzeProgress: "分析進度",
                        haveInHand: "進行中",
                        completed: "已完成",
                        fail: "失敗",
                        toBegin: "待開始",
                        operation: "操作",
                        see: "查看",
                    },
                },
            },
        },
        businessWorkingHours: {
            components: {
                businessWorkingHoursSetting: {
                    index: { addWorkingHours: "添加工作時間" },
                },
            },
            index: {
                businessWorkingHours: "業務工作時間",
                newlyBuild: "新建",
                name: "名稱",
                describe: "描述",
                operation: "操作",
                edit: "編輯",
                delete: "删除",
                editBusinessWorkingHours: "編輯業務工作時間",
                newBusinessWorkingHours: "新建業務工作時間",
                workingHours: "工作時間",
            },
        },
        algorithmSemanticIntelligence: { intelligentExtraction: "智慧抽取" },
        taskCenter: {
            downloadList: {
                component: {
                    areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "您確認要取消嗎？ 取消後將不能再恢復",
                },
            },
        },
    },
    columns: {
        admin: {
            user: {
                id: "ID",
                headPortraitUrl: "頭像",
                realname: "姓名",
                nickname: "昵稱",
                name: "姓名",
                email: "賬號（郵箱）",
                mobile: "電話",
                agentId: "工號",
                position: "崗位",
                role: "角色",
                actions: "操作",
                createUser: "新增員工",
                editUser: "編輯員工",
                account: "賬號",
                originPassword: "初始密碼",
                newPassword: "新密碼",
                resumePassword: "確認密碼",
                userGroup: "員工組",
                authorizationGroup: "權限組",
                resetPassword: "重置密碼",
            },
            userGroups: {
                id: "ID",
                name: "名稱",
                userCount: "員工數",
                actions: "操作",
                createUserGroup: "新增員工組",
                editUserGroup: "編輯員工組",
                member: "成員",
            },
            role: {
                id: "ID",
                name: "名稱",
                actions: "操作",
                all: "全部",
                taskName: "任務",
                readTask: "任務查看",
                manageTemplate: "模板管理",
                manualInspection: "人工復檢",
                readResultAll: "結果查看",
                readResultPersonal: "結果查看（個人）",
                readProcess: "質檢進程查看",
                readReport: "統計與報表",
            },
        },
        download: {
            downloadCenter: {
                createTime: "日期",
                taskName: "名稱",
                size: "大小",
                status: "狀態",
            },
        },
        tasks: {
            manage: {
                template: {
                    condition: {
                        idCode: "ID",
                        name: "名稱",
                        remark: "描述",
                        actions: "操作",
                    },
                    checkPoint: {
                        name: "名稱",
                        remark: "描述",
                        type: "類型",
                        gradeType: "評價方式",
                        conditionLogic: "規則",
                        predeterminedScore: "分值",
                        weight: "權重",
                        status: "狀態",
                        actions: "操作",
                        switchOn: "啟用",
                        switchOff: "停用",
                    },
                },
                workbench: {
                    qualityCheckList: {
                        time: "時間",
                        customer: "客戶",
                        customerPhone: "客戶電話",
                        callType: "通話類型",
                        source: "來源",
                        voiceRecording: "通話錄音",
                        graded: "評分",
                        openingGreeting: "開場問候",
                    },
                    checkPointList: {
                        name: "質檢點",
                        type: "類型",
                        deductScore: "分數",
                        isHit: "命中情況",
                        status: "復核",
                        finalDeductScore: "確認扣分",
                        hit: "命中",
                        notHit: "未命中",
                    },
                },
            },
        },
        custom: { keywords: "對話關鍵詞" },
    },
    privateEnums: {},
    menu: {
        admin: {
            system: { text: "系統設置" },
            systemIntegration: {
                text: "系統集成",
                subTitle: {
                    udesk: "Udesk客服系統",
                    udeskWhite: "客服系统",
                    wechat: "企業微信",
                    taobao: "淘宝千牛",
                    weifeng: "微丰",
                },
            },
            columnsManage: { text: "質檢字段管理" },
            customerField: { text: "客戶字段" },
            speechRecognition: { text: "語音識別" },
            user: { text: "員工管理" },
            userGroups: { text: "員工組管理" },
            role: { text: "角色管理" },
            dataMigration: { text: "數據遷移" },
            taskCenter: { text: "任務中心" },
            recordUpload: { text: "本地錄音上傳" },
            anomalousData: { text: "異常數據" },
            systemLog: { text: "消息日誌" },
            msgCenter: { text: "消息中心" },
            wechatDocking: { text: "企微對接" },
        },
        tasks: {
            homePage: { text: "首页" },
            workbench: { text: "工作臺" },
            semanticIntelligence: { text: "語義智能" },
            intelligentAnalysis: { text: "智能分析" },
            intelligentLabel: { text: "智能標簽" },
            admin: { text: "管理" },
            callManage: { text: "通話管理" },
            dialogueManage: { text: "對話管理" },
            ticketManage: { text: "工单管理" },
            dataAnalysis: { text: "數據分析" },
            customerService: { text: "客服中心" },
            clientCenter: { text: "客戶中心" },
            insight: { text: "Insight" },
            trainingCenter: { text: "訓練中心" },
            templates: { text: "模板管理" },
            wechatQaTasks: { text: "企業微信工作臺" },
            wechatQaSetting: { text: "企業微信分析配置" },
            wechatQaChartRecord: { text: "企業微信會話" },
            wechatStaffCenter: { text: "企微員工中心" },
            callAnalysis: { text: "会话分析" },
            caseBaseFolder: { text: "案例库" },
            coachTask: { text: "辅导任务" },
            teamAnalysis: { text: "团队分析" },
            customer: { text: "客戶中心" },
            analysisEnable: { text: "分析赋能" },
            analysisHelp: { text: "求助分析" },
            analysisStudy: { text: "学习分析" },
            analysisReview: { text: "复盘分析" },
            analysisTags: { text: "智能标签分析" },
            analysisSentiment: { text: "舆情分析" },
            analysisBusiness: { text: "业务分析" },
            groupTest: { text: "分組測試" },
        },
        navBar: {
            billingCenter: "幫助中心",
            uploadDownload: "上傳下載中心",
            setting: "系統設置",
        },
    },
    fix: {
        selectType: "選擇類型：",
        all: "全部",
        call: "通話",
        text: "對話",
        wechat: "企業微信會話",
        businessTrendAnalysis: "業務趨勢分析",
        noEchart: "圖表暫無數據",
        checkPointisRequired: "規則配置為必填項",
        voiceDownLoad: "下載錄音",
        exportTxt: "導出TXT文本",
        exportWord: "導出word文本",
        sureReRole: "確認反轉說話人的角色嗎？",
        sureRecover: "確認重新糾錯嗎？",
        sureReAsr: "確認重新識別嗎？",
        reRole: "反轉說話人",
        recover: "重新糾錯",
        reAsr: "重新識別",
        recordingOperation: "錄音操作",
        onlySave30Day: "（僅保留30天）",
        appealError: "請先對質檢點進行申訴再提交",
        exportToTaskCenter: "已成功導出到上傳下載中心",
        caseBaseLimit: "案例庫不能超過50個",
        unnamed: "未命名",
        wordSpeed: "字/分",
        hit: "命中",
        checkPoint: "質檢點",
        realTimeAdditionTip1: "實時添加：數據進入質檢系統時立即開始質檢",
        realTimeAdditionTip2: "定時添加：定時自動添加數據進行質檢",
        null: "無",
        collcetedProps: "采集字段",
        editingNameNow: "有名稱正在編輯",
        upToFourLevels: "分類最多四級",
        default: "默認",
        nameCantBeNull: "名稱不能為空",
        editingNow: "有操作正在編輯",
        nameError: "名稱有誤",
        nameMaxLength8: "名稱最大長度為8",
        callTime: "通話時間",
        sentimentValue: "客戶情緒值",
        agentSentimentValue: "客服情緒值",
        sentimentAnalysis: "情緒分析",
        associatedData: "隨路數據",
        notifyTheOwner: "通知群主",
        notifyTheEmployee: "通知員工本人",
        notifyTheOwnerTip: "當會話類型為群聊，才會通知群主",
        customNotifyEmployee: "自定義通知員工",
        customNotifyEmployeeTip: "觸發人員為員工，則通知消息歸屬的員工本人",
        clickToLearnMore: "點擊了解更多",
        repeatMarkingTip: "該條記錄已被該質檢點標註，無需重復標註",
        successfulEvaluation: "評價成功",
        evaluationFailed: "評價失敗",
        qualityInspectionScore: "質檢評分",
        semanticTags: "語義標簽",
        smartTags: "智能標簽",
        collect: "采集信息",
        appealRecord: "申訴記錄",
        satisfy: "滿意",
        notSatisfy: "不滿意",
        appealPlaceHolder: "請輸入申訴原因",
        giveUp: "放棄",
        rejectReasonPlaceHolder: "請輸入駁回理由",
        reject: "駁回",
        changeRejectReason: "修改駁回原因",
        pass: "通過",
        revise: "修改",
        machineEvaluation: "機器評價",
        poor: "差",
        excellent: "好",
        moderate: "一般",
        mustMarked: "該質檢點必須進行標註",
        pleaseChoose: "請選擇",
        manualEvaluation: "人工評價",
        qualityInspectionTemplate: "適用質檢模板",
        collectionTemplate: "適用采集模板",
        keywordsWordCloud: "關鍵詞詞雲",
        editCategoryLabel: "編輯分類標簽",
        justCall: "僅看通話",
        justConversation: "僅看對話",
        batchOperation: "批量操作",
        customerServicesGroup: "客服組",
        joinGroup: "加入組",
        removeGroup: "移出組",
        enterInformationEntity: "請輸入信息實體內容",
        enterNotBeNull: "輸入不能為空",
        operationGroup: "操作組",
        customerServiceSelected: "已選客服",
        callAndConversation: "通話/對話",
        conversation: "對話",
        system: "系統",
        customize: "自定義",
        effective: "有效",
        invalid: "無效",
        successfulAndToTaskCenter: "執行成功，請到任務中心查看",
        recalculationFailed: "重算失敗",
        selectRecalculatedIndicator: "請選擇要重算的指標",
        source: "請選擇數據源",
        placeholder: "請輸入關鍵詞",
        cumulative: "累計型",
        average: "平均型",
        distribution: "分布律",
        originalDataCannotEmpty: "原始數據不能為空",
        inspection: "質檢",
        check: "抽檢",
        review: "復核",
        draftSavedSuccessfully: "草稿保存成功",
        optimization: "優化",
        agentName: "客服名稱",
        allTasks: "全部質檢任務",
        datePickerSelect: "選擇日期",
        callSelect: "選擇對話",
        taskSelect: "選擇任務",
        genReport: "生成報告",
        dataSource: "數據來源",
        callsTotal: "通話總量",
        intelligentQualityInspection: "智能質檢",
        percentage: "占比",
        artificialSampling: "人工抽檢",
        appealNumber: "申訴量",
        totalConversation: "對話總量",
        samplingReviewRate: "抽復檢率",
        data: "數據",
        inspectionResults: "質檢結果",
        inspected: "已質檢",
        totalScore: "總分",
        learnMore: "查看更多",
        inspectionEffect: "質檢效果",
        allTotal: "總量",
        manualInspection: "人工復檢",
        artificialReview: "人工復核",
        approvalAmountAndRate: "核準量&核準率",
        customerScore: "客服得分",
        inspectionRecommendations: "質檢建議",
        saveDraft: "保存草稿",
        historicalAdvice: "歷史建議",
        advicePlaceHolder: "請輸入建議...",
        upload: "文件上傳",
        customAdd: "添加篩選",
        advancedConditions: "高級條件",
        keyWord: "關鍵詞",
        containsAll: "包含全部",
        containsAny: "包含任意",
        matchAny: "匹配任意",
        matchAll: "匹配全部",
        smartTagsOne: "智能標簽一",
        smartTagsTwo: "智能標簽二",
        season: "季",
        month: "月",
        week: "周",
        day: "天",
        hour: "時",
        notMoreThan366: "時間不能超過366天",
        propsCannotBeNull: "有字段未填寫",
        other: "其他",
        correlationCoefficient: "相關系數",
        repetitionRate: "重復率",
        configurationItemsMoreThan20: "配置項最多添加20個",
        conversionAnalysis: "轉化分析",
        correlationAnalysis: "相關性分析",
        successfulExportAndToTaskCenter: "導出成功，請前往任務中心查看",
        typeSelect: "選擇類型",
        exportExcel: "導出excel",
        exportPng: "導出png",
        noDataChart: "圖表暫無數據",
        placeOperator: "請選擇智能標簽",
        placeAdvancedConditions: "請選擇高級條件",
        createAdvancedConditions: "新建高級篩選",
        moveUp: "上移",
        moveDown: "下移",
        moveLeft: "左移",
        moveRight: "右移",
        statusChangeSuccess: "狀態修改成功",
        editSuccess: "修改成功",
        saveSuccess: "保存成功",
        firstPage: "首頁",
        lastPage: "尾頁",
        customerInfo: "客戶信息",
        type: "類型",
        NumberTypeErrorMessage: "數字類型的字段信息內容只能包含數字或「.」",
        taskStartPrompt: "任務開啟提示",
        startConfirm: "請點擊確認按鈕開啟質檢任務",
        continueToSubmitWithoutEvaluation: "不進行評價繼續提交",
        rejectTip: "沒有檢測到變更操作，您確認直接駁回所有申訴嗎？",
        unmarkedSelfLearningTip: "您有未標記的自學習質檢點",
        markedSuccessfully: "標註成功",
        markingFailed: "標註失敗",
        exceedingLength: "超出長度",
        uncategorized: "未分類",
        addSuccess: "新增成功",
        deleteSuccess: "刪除成功",
        featureName: "要素名稱",
        informationEntity: "信息實體",
        standardAnswer: "標準回答",
        keyElementAnswer: "關鍵要素回答",
        generalStatement: "一般陳述（無需詢問）",
        generalQuestionsAndConfirmation: "一般疑問以及確認",
        specialQuestionsAndAnswers: "特殊疑問以及回答",
        agent: "客服",
        customer: "客戶",
        semanticTagsAndInformationEntitiesCannotBeEmpty:
      "關鍵要素 語義標簽和信息實體不能同時為空",
        standardSentence: "標準句",
        acquaintance: "相識度",
        smartUePlaceholder: "請輸入智能交互名稱",
        typeSelectPlaceholder: "請選擇類型",
        keyElement: "關鍵要素",
        reQuerySementicTags: "重新選擇語義標簽",
        querySementicTags: "選擇語義標簽",
        QATestForm: "問答檢測形式",
        selectNotBeNull: "選擇不能為空",
        featureDetectionTarget: "要素檢測目標",
        categorized: "分類",
        effectiveness: "有效性",
        hideAdvancedSettings: "隱藏高級設置",
        showAdvancedSettings: "顯示高級設置",
        generalStatementOnly: "一般陳述",
        generalQuestions: "一般疑問",
        affirmativeExpression: "肯定表達",
        negativeIntention: "否定意圖",
        searchSementicTags: "查詢語義標簽",
        choiceQuestion: "選擇型疑問句",
        selectiveAnswer: "選擇型回答",
        sementicTagsPlaceholder: "請輸入語義標簽內容",
        openSpecialQuestion: "開放型特殊疑問",
        openAnswer: "開放型回答",
        customQA: "自定義問答",
        questions: "問法",
        dataPlaceholder: "請輸入內容",
        reply: "回答",
        notFindSuitableLabel: "沒找到合適的標簽",
        addSemanticTag: "添加一個新的語義標簽",
        intelligentInteraction: "智能交互",
        matching: "匹配",
        bout: "次",
        keywordMatch: "關鍵詞匹配",
        addFilterConditions: "添加篩選條件",
        buttonStartCollectionTask: "請點擊確定按鈕開啟采集任務",
        buttonStartTask: "請點擊確定按鈕開啟質檢任務",
        ruleConfigurationIsRequired: "規則配置為必填項",
        asrRunning: "ASR執行",
        errorCorrectionExecution: "糾錯執行",
        emotionalVolumeRecognition: "情緒音量識別",
        dataPreprocessing: "數據預處理",
        inspectionExecution: "質檢執行",
        businessId: "業務Id",
        businessTime: "業務時間",
        createTime: "創建時間",
        abnormalReason: "異常原因",
        singleChat: "單聊",
        groupChat: "群聊",
        taskContent: "任務內容",
        submissionTime: "提交時間",
        startTime: "開始時間",
        endTime: "結束時間",
        runningTime: "運行耗時",
        totalAmountOfData: "總數據量",
        resultsOfThe: "執行結果",
        operator: "操作員",
        taskDetails: "任務詳情",
        executiveFunction: "執行功能",
        qualityCheckTasks: "質檢任務",
        implementationDetails: "執行詳情",
        conditionalRules: "條件規則",
        smartCondition: "智能條件",
        goThis: "移動到此",
        pleaseMarkClassification: "請標註分類",
        advancedScreening: "高級篩選",
        noDataToManipulate: "沒有可以操作的數據",
        concept: "概念詞",
        checkPoint1: "規則配置",
        requiredFieldsCannotEmpty: "必填項不能為空",
        rule: "規則",
        afterFixUnit: "個字",
        unmarkedSelfLearningPointsTip: "您有未標記的自學習質檢點",
        onlyReservedFor7Days: "（僅保留7天）",
        downloadError: "下載失敗",
        pause: "暫停",
        continue: "繼續",
        recalcScore: "僅重新核算分數（含評級）",
        recalculatePointsAndScores: "重新核算質檢點和分數",
        recalculatePointsAndScoresWithEmtry: "重新核算質檢點和分數（質檢點為空）",
        inProgress: "進行中",
        toBeExecuted: "待執行",
        executeImmediately: "立即執行",
        specialElements: "特殊要素",
        logicRule: "邏輯規則",
        every: "多次匹配",
        hitRecall: "命中召回詞",
        notHitRecall: "未命中召回詞",
        hitRecallRegular: "命中召回正則",
        notHitRecallRegular: "未命中正則",
        hitRule: "命中規則",
        notHitRule: "未命中規則",
        hitSimilarSentence: "命中相似句",
        notHitSimilarSentence: "未命中相似句",
        hitExcludeSimilarSentences: "命中排除相似句",
        notHitExcludeSimilarSentences: "未命中排除相似句",
        updateSuccess: "更新成功",
        qualify: "正则",
        recallKeyWord: "召回關鍵詞",
        grammar: "文法规则",
    },
    api: {
        putTypoDatasByIdStatusByStatus: "有效性變更失敗，請重試",
        postAutoMateTasksCaseByTaskId: "案例库同步配置保存失败",
        getAutoMateTasksCaseByTaskId: "案例库同步配置获取失败",
        postInspectionRemarkModelConfigs: "备注模板保存失败",
        putInspectionRemarkModelConfigsById: "备注模板保存失败",
        getInspectionRemarkModelConfigsById: "获取备注模板数据失败",
        postKnowledgeDatas: "知识库新建失败，请重试",
        putKnowledgeDatasById: "知识库修改失败，请重试",
        getLibraryFiles: "获取文价夹列表失败",
        getLibraryCalls: "获取文件失败",
        getLibraryFilesSearch: "搜索失败",
        putLibraryFilesMoveById: "问价夹移动失败",
        putLibraryCallsById: "文件重命名失败！",
        postLibraryFiles: "添加文件夹失败",
        putLibraryFilesById: "文件夹重命名失败",
        deleteLibraryFilesById: "删除失败",
        deleteLibraryCallsById: "删除失败",
        postLibraryCalls: "添加失败",
        getVoiceUploadResultById: "上传结果导出失败",
    },
};
export default locales;
