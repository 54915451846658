import React from 'react';
// import Udesk from 'Udesk';
import ClassNames from 'classnames';
import SafeHtmlComponent from './component';
import './style.scss';
import XssComponent from 'udesk-react/src/components/xss';

class SafeHtmlTemplate extends SafeHtmlComponent {
    render() {
        let {
            content,
            bodyStyle,
            className,
        } = this.props;
        return (
            // <div className={ClassNames("udesk-qa-react-safe-html", className)} style={bodyStyle} dangerouslySetInnerHTML={{ __html: Udesk.utils.web.safeHtml(content) }}>
            // </div>
            <XssComponent
                classNames={ClassNames("udesk-qa-react-safe-html", className)}
                style={bodyStyle}
                value={ content }
            />
        );
    }
}
export default SafeHtmlTemplate;