import React, { useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { FormBuilder, Card, Space, Button, Col, Row/*, Icon*/ } from 'udesk-ui';
import Page from 'Component/common/udesk-qa-page-layout';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import './style.scss';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';

interface ConfigFoundResponse {
    applicationName?: string;

    applicationSecret?: string;

    commSecret?: string;

    commToken?: string;

    encodingAesKey?: string;

    eventCallbackUrl?: string;

    id?: number;

    numberId?: string;

    sessionArchivePrivateKey?: string;

    sessionArchiveSecretKey?: string;

    webApplicationId?: string;

    qrCodeUrl?: string;
}

const WechatDocking = (props) => {
    const locales = Locales['current'];
    const formLabels = locales.pages.admin.wechatDocking.formLabels;
    const formTitle = locales.pages.admin.wechatDocking.formTitle;

    const { sdkOptions } = props;

    const [initialValues, setInitialValues] = useState<ConfigFoundResponse>({});
    const [initialForm] = FormBuilder.useForm();
    // const [synchronizeForm] = FormBuilder.useForm();
    // const [settingForm] = FormBuilder.useForm();
    // const [copyText, setCopyText] = useState('');
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(true);

    const initialFormFields: FormProps['fields'] = [
        {
            name: 'numberId',
            type: 'Input',
            label: formLabels.companyId,
            rules: [
                {
                    required: true,
                },
            ],
        },
        // {
        //     name: 'eventCallbackUrl',
        //     type: 'Input',
        //     label: formLabels.url,
        //     forceHidden: !initialValues.eventCallbackUrl,
        //     forceDisabled: Boolean(initialValues.eventCallbackUrl),
        //     props: {
        //         style: {
        //             width: '100%',
        //         },
        //         onChange: (e) => {
        //             setCopyText(e.target.value);
        //         },
        //     },
        //     addonAfter: (
        //         <CopyToClipboard text={copyText}>
        //             <Button
        //                 size='small'
        //                 type='text'
        //                 tooltip={locales.labels.clickToCopy}
        //                 icon={<Icon type='CopyTwoTone' antdIcon={true}></Icon>}
        //             />
        //         </CopyToClipboard>
        //     ),
        // },
    ];

    const synchronizeFormFields: FormProps['fields'] = [
        {
            name: 'commSecret',
            type: 'Input',
            label: formLabels.communicationKey,
            rules: [
                {
                    required: true,
                },
            ],
        },
        {
            name: 'commToken',
            type: 'Input',
            label: formLabels.token,
        },
        {
            name: 'encodingAesKey',
            type: 'Input',
            label: formLabels.encodingAESKey,
        },
    ];
    const settingFormFields: FormProps['fields'] = [
        {
            name: 'applicationName',
            type: 'Input',
            label: formLabels.applicationName,
        },
        {
            name: 'webApplicationId',
            type: 'Input',
            label: formLabels.applicationId,
            rules: [
                {
                    required: true,
                },
            ],
        },
        {
            name: 'applicationSecret',
            type: 'Input',
            label: formLabels.applicationSecret,
            rules: [
                {
                    required: true,
                },
            ],
        },
    ];
    const formSchema = [
        {
            title: formTitle.initialForm,
            field: initialFormFields,
        },
        {
            title: formTitle.synchronizeForm,
            field: synchronizeFormFields,
        },
        {
            title: formTitle.settingForm,
            field: settingFormFields,
        },
    ];

    const formList = useMemo(
        () => [initialForm /*synchronizeForm, settingForm */],
        [initialForm /*synchronizeForm, settingForm */]
    );
    // const formList = [initialForm, synchronizeForm, settingForm];
    const onValuesChange = () => {
        setIsChanged(true);
    };
    const formConfigs = useMemo(
        () => formSchema.map((i) => getConfig(i.field, initialValues, onValuesChange)),
        // [initialValues, copyText]
        [initialValues]
    );
    // const formConfigs = formSchema.map((i) => getConfig(i.field, initialValues));

    const cancel = () => {
        formList.forEach((f) => f.resetFields());
    };
    const queryConfigs = () => {
        const url = Udesk.business.apiPath.concatApiPath(`wecom/configs`, sdkOptions);
        setLoading(true);
        Udesk.ajax.get(url).then(
            (resp) => {
                setInitialValues(resp.data);
                setLoading(false);
                resp?.data?.numberId && setIsChanged(false);
            },
            (reason) => {
                Udesk.ui.notify.error(
                    reason.errorMsg || locales.components.pages.components.recordDetail.getDetailFailed
                );
                setLoading(false);
            }
        );
    };
    const save = () => {
        const values = formList.map((i) => i.validateFields());
        Promise.all(values).then((res) => {
            setBtnLoading(true);
            let params = res.reduce((prev, current) => {
                return { ...prev, ...current };
            }, {});
            delete params.eventCallbackUrl;
            let ajax: Promise<{ data: ConfigFoundResponse }> | null = null;
            if (initialValues.id) {
                let url = Udesk.business.apiPath.concatApiPath(`wecom/configs/${initialValues.id}`, sdkOptions);
                ajax = Udesk.ajax.put(url, params);
            } else {
                let url = Udesk.business.apiPath.concatApiPath(`wecom/configs`, sdkOptions);
                ajax = Udesk.ajax.post(url, params);
            }
            ajax?.then?.(
                (resp) => {
                    Udesk.ui.notify.success(locales.business.info.saveSuccess);
                    setBtnLoading(false);
                    setIsChanged(false);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || locales.components.pages.components.recordDetail.getDetailFailed
                    );
                    setBtnLoading(false);
                }
            );
        });
    };

    useEffect(() => {
        queryConfigs();
    }, []);
    useEffect(() => {
        formList.forEach((f) => f.resetFields());
    }, [initialValues]);
    return (
        <Page
            pageBodyClassName='wechat-docking-page-index'
            title={locales.pages.admin.wechatDocking.title}
            // padding={true}
            loading={loading}
            // backGroundGhost={true}
        >
            {formList.map((form, index) => (
                <Card
                    size='small'
                    title={formSchema[index].title}
                    style={{ width: '100%', marginBottom: 16, borderRadius: '3px' }}
                    bordered={false}
                >
                    <FormBuilder form={form} {...formConfigs[index]}></FormBuilder>
                </Card>
            ))}
            {!isChanged && initialValues?.qrCodeUrl && (
                <Card
                    size='small'
                    title={locales.pages.admin.wechatDocking.qrCodeUrlTip}
                    style={{ width: '100%', marginBottom: 16, borderRadius: '3px' }}
                    bordered={false}
                >
                    <Row>
                        <Col offset={7}>
                            <img src={initialValues?.qrCodeUrl} alt='' />
                        </Col>
                    </Row>
                </Card>
            )}

            <Card bordered={false} size='small' style={{ width: '100%', borderRadius: '3px' }}>
                <Row>
                    <Col offset={7}>
                        <Space>
                            <Button loading={btnLoading && { delay: 200 }} onClick={cancel}>
                                {locales.labels.cancel}
                            </Button>
                            <Button loading={btnLoading && { delay: 200 }} type='primary' onClick={save}>
                                {locales.labels.save}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
        </Page>
    );
};

class Component extends React.Component {
    render() {
        return <WechatDocking {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);

const configTemplate: Omit<FormProps, 'fields'> = {
    footerRender: false,
};

function getConfig(fields, initialValues, onValuesChange) {
    return {
        ...configTemplate,
        onValuesChange,
        fields,
        initialValues,
    };
}
