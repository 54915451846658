import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
// import _isEmpty from 'lodash-es/isEmpty';
import _map from 'lodash-es/map';
import { postInspectionTasksSaveAs } from 'src/api/inspectionTasks/saveAs';

const DEFAULT_STORAGES = {
    inspectionType: null,
    inspectDataSource: null,
    activeTabKey: 'qualityCheckTasks',
    wecomInspectDataSource: Udesk.enums.inspectDataSources.wechatDialogue.id,
};
export default class TasksIndexComponent extends React.Component {
    //#region state
    state = {
        taskModalVisible: false,
        taskModalTitle: null,
        name: null,
        remark: null,
        modalId: null,
        modalTitle: UdeskLocales.current.pages.tasks.index.creatNewQualityCheckTask,
        modalErrorMsg: UdeskLocales.current.pages.tasks.index.creatNewQualityCheckTaskError,
        modalAction: 'saveCreact',
        modalEditTask: null,
        // type: UDQ.enums.taskTypes.forwardScore.id,
        singleItemScore: null,
        isEdit: false,
        inspectorList: [],
        inspectDataSource: null,
    }; //
    //#endregion

    privates = {
        tasks: [],
        createModalVisible: false,
        templateList: [],
        xhr: null,
        isWechatQa: false,
        loading: true,
        chartsFulfilled: false,
        filterControlFormFields: [],
        initialValues: {},
        formRef: React.createRef(),
        storages: { ...DEFAULT_STORAGES },
        inspectDataSources: Udesk.enums.companyEnableInspect.no.id,
        moduleSourceType: null,
        asyncModelTasks: [],
        asyncModelCharts: [],
        saveAsModelVisible: false,
        currentSaveAsTask: null,
        saveAsFormFields: [],
        saveAsFormInitValue: {
            configSettings: ['BASE_INFO', 'CHECK_POINT'],
        },
        saveAsFormRef: React.createRef(),
        enabledInspectRuleApprove: false,
        enabledInspectRuleApproveAuto: false,
        controller: new AbortController(),
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'memory',
        resetStrategies: {
            notTransitionToRoutes: [
                /^\/site\/tasks\/manage\/\d+\/*/i,
                /^\/site\/wechat-qa\/task\/manage\/\d+\/*/i,
                /^\/site\/information-collection\/manage\/\d+\/*/i,
                /^\/site\/tasks\/approve\/\d+\/template\/check-point\/\d+\/\d+\/index\/*/i,
            ],
        },
    };

    //#region Computed properties
    static computes = {
        _autoCompleteUrl: function ({ props, state, privates, locales }) {
            return Udesk.business.apiPath.concatApiPath('/users', props.sdkOptions);
        },
        _inspectDataSources: function ({ props, state, privates, locales }) {
            let company = Udesk.data.init.company;
            let inspectDataSources = [];

            // enabledCallInspect 0:未启用 1:启用语音质检 2:启用实时语音质检 3:都启用
            // 1、语音通话
            if (company.enabledCallInspect === Udesk.enums.companyEnableInspect.regular.id) {
                inspectDataSources.push(Udesk.enums.inspectDataSources.voiceCall);
            } else if (company.enabledCallInspect === Udesk.enums.companyEnableInspect.realTime.id) {
                inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeVoiceCall);
            } else if (company.enabledCallInspect === Udesk.enums.companyEnableInspect.all.id) {
                inspectDataSources.push(Udesk.enums.inspectDataSources.voiceCall);
                inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeVoiceCall);
            }

            // enabledImInspect 0:未启用 1:启用IM质检 2:启用实时IM质检 3:都启用
            // 2、文本对话
            if (company.enabledImInspect === Udesk.enums.companyEnableInspect.regular.id) {
                inspectDataSources.push(Udesk.enums.inspectDataSources.textDialogue);
            } else if (company.enabledImInspect === Udesk.enums.companyEnableInspect.realTime.id) {
                inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeTextDialogue);
            } else if (company.enabledImInspect === Udesk.enums.companyEnableInspect.all.id) {
                inspectDataSources.push(Udesk.enums.inspectDataSources.textDialogue);
                inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeTextDialogue);
            }
            return inspectDataSources;
        },
        _tasks: [
            'privates.asyncModelTasks',
            'privates.asyncModelCharts',
            'privates.storages.keyword',
            'privates.storages.inspectDataSource',
            'privates.storages.inspectionType',
            'privates.storages.activeTabKey',
            'privates.storages.wecomInspectDataSource',
            function ({ props, state, privates, locales }) {
                const { inspectDataSource, inspectionType, keyword } = privates.storages;

                let tasks = privates.asyncModelTasks || [];
                let charts = privates.asyncModelCharts || [];
                let result = tasks
                    .filter((task) => {
                        return privates.storages.activeTabKey === 'informationCollection'
                            ? task.taskType === 2
                            : task.taskType === 1;
                    })
                    .filter((task) => {
                        if (['wechatDialogue', 'wechatRadio'].includes(privates.storages.activeTabKey)) {
                            return privates.storages.wecomInspectDataSource === task.inspectDataSource;
                        }
                        if (inspectDataSource && task.inspectDataSource !== inspectDataSource) {
                            return false;
                        }
                        if (inspectionType && task.related !== inspectionType) {
                            return false;
                        }
                        if (keyword && task.name.indexOf(keyword) === -1) {
                            return false;
                        }
                        return true;
                    });
                if (!tasks.some((i) => i.chart)) {
                    result = result.map((task) => {
                        let target = charts.find((chart) => {
                            return task.id === chart.taskId;
                        });
                        target && (task.chart = target);
                        return task;
                    });
                }
                return result;
            },
        ],
    }; //
    //#endregion

    //#region actions
    actions = {
        actionHandler(event) {
            this.actions[event]();
        },
        creatTaskModelVisible() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'tasksBasicInfoCreateIndex',
                pathParams: { taskId: '0' },
            };
            if (this.privates.isWechatQa) {
                routeOptions.routeName = 'wechatQaCreateBaseInfo';
            }
            if (this.privates.storages.activeTabKey === 'informationCollection') {
                routeOptions.routeName = 'informationCollectionBasicInfoCreateIndex';
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        tranToDetail(item) {
            new Promise((resolve, reject) => {
                let url = Udesk.business.apiPath.concatApiPath(
                    `/inspectionModules/task/${item.id}`,
                    this.props.sdkOptions
                );
                Udesk.ajax.get(url).then(
                    (resp) => {
                        this.privates.createModalVisible = false;
                        this.actions.update();
                        this.actions.reloadAsyncModel();
                        resolve(resp.data);
                    },
                    (reason) => {
                        reject(reason);
                    }
                );
            });
        },
        closeCreateModal() {
            this.privates.createModalVisible = false;
            this.actions.update();
        },
        creatModule() {
            this.privates.createModalVisible = true;
            this.actions.update();
        },
        generalChange() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'generalInspectionReportIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },

        editTaskModelVisible(task) {
            this.setState({
                modalTitle: UdeskLocales.current.pages.tasks.index.editQualityCheckTask,
                moddalErrorMsg: UdeskLocales.current.pages.tasks.index.editQualityCheckTaskError,
                modalAction: 'editTask',
            });
            // this.setState({
            //     moddalErrorMsg: UdeskLocales.current.pages.tasks.index.editQualityCheckTaskError
            // });
            // this.setState({
            //     modalAction: "editTask"
            // });
            this.actions.setModelData(task);
        },

        saveAsTaskModelVisible(task) {
            // this.setState({
            //     modalTitle: UdeskLocales.current.pages.tasks.index.creatNewQualityCheckTask
            // });
            // this.setState({
            //     modalErrorMsg: UdeskLocales.current.pages.tasks.index.creatNewQualityCheckTaskError
            // });
            // this.setState({
            //     modalAction: "saveAsTask"
            // });
            // this.actions.setModelData(task);
            let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/saveAs', this.props.sdkOptions);
            let taskCopy = task;
            // taskCopy.name = Udesk.utils.string.format(UdeskLocales.current.labels.saveAsName, task.name);
            Udesk.ajax.post(url, taskCopy).then(
                (resp, textStatus, jqXHR) => {
                    this.actions.reloadAsyncModel();
                    Udesk.ui.notify.success(UdeskLocales.current.pages.tasks.index.creatNewQualityCheckTask);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || UdeskLocales.current.pages.tasks.index.creatNewQualityCheckTaskError
                    );
                }
            );
        },

        cancel() {
            this.setState({
                taskModalVisible: false,
                name: null,
                remark: null,
                inspectDataSource: null,
                inspectorList: [],
            });
            // this.setState({
            //     name: null
            // });
            // this.setState({
            //     remark: null
            // });
            // this.setState({
            //     inspectDataSource: null
            // });
            // this.setState({
            //     inspectorList: []
            // });
        },

        upDataTasks(name, remark) {
            // let url = Udesk.business.apiPath.concatApiPath(
            //     "/inspectionTasks",
            //     this.props.sdkOptions
            // );
            // Udesk.ajax.get(url).then(
            //     (resp, textStatus, jqXHR) => {
            //         this.privates.tasks = resp.data;
            //         this.actions.update();
            //     },
            //     reason => {
            //         Udesk.ui.notify.error(
            //             reason.errorMsg ||
            //             Udesk.utils.string.format(
            //                 UdeskLocales.current.business.info.loadError,
            //                 UdeskLocales.current.pages.tasks.index.qualityCheckTasks
            //             )
            //         );
            //     }
            // );
            this.actions.reloadAsyncModel();
        },

        creatTask() {
            let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks', this.props.sdkOptions);
            let method = Udesk.enums.requestMethods.create.name;
            this.actions.sendTaskQuery({
                url,
                method,
            });
        },

        editTask() {
            let id = this.state.modalEditTask.id;
            let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/' + id, this.props.sdkOptions);
            let method = Udesk.enums.requestMethods.update.name;
            this.actions.sendTaskQuery({
                id,
                url,
                method,
            });
        },

        saveAsTask() {
            let id = this.state.modalEditTask.id;
            let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/saveAs', this.props.sdkOptions);
            let method = Udesk.enums.requestMethods.create.name;
            this.actions.sendTaskQuery({
                id,
                url,
                method,
            });
        },

        userListChanged(values) {
            this.setState({
                inspectorList: values,
            });
        },

        sendTaskQuery({ id, url, method }) {
            let name = this.state.name;

            if (name == null || name.trim() === '') {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.pages.tasks.index.name
                    )
                );
            }

            let remark = this.state.remark;
            let inspectDataSource = this.state.inspectDataSource;

            if (inspectDataSource == null) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.pages.tasks.index.inspectDataSource
                    )
                );
            }
            // let type = this.get('states.type');

            let singleItemScore = this.state.singleItemScore;
            let inspectorList = this.state.inspectorList;
            let inspectorIds = [];

            if (inspectorList && inspectorList.length > 0) {
                inspectorIds = inspectorList.map((item) => {
                    return item.id;
                });
            }

            let params = {
                name,
                remark,
                inspectDataSource,
                // type,
                singleItemScore,
                inspectorIds,
            };

            if (id) {
                params.id = id;
            }

            Udesk.ajax(url, params, method).then(
                (resp, textStatus, jqXHR) => {
                    this.actions.cancel();
                    this.actions.upDataTasks();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.state.modalErrorMsg);
                }
            );
        },

        setModelData(task) {
            this.setState({
                modalEditTask: task,
                name: task.name,
                remark: task.remark,
                inspectDataSource: task.inspectDataSource,
                inspectorList: task.inspectorList,
                isEdit: true,
                taskModalVisible: true,
            });
            // this.setState({
            //     name: task.name
            // });
            // this.setState({
            //     remark: task.remark
            // });
            // this.setState({
            //     inspectDataSource: task.inspectDataSource
            // });
            // this.setState({
            //     inspectorList: task.inspectorList
            // });
            // this.setState({
            //     isEdit: true
            // });
            // this.setState({
            //     taskModalVisible: true
            // });
        },

        filterControlSearch(values) {
            this.privates.storages = { ...this.privates.storages, ...values };
            this.actions.update();
        },

        filterBtnOncilck() {
            this.privates?.formRef?.current?.submit();
        },

        filterBtnReset() {
            this.privates.storages = DEFAULT_STORAGES;
            this.actions.update();
            setTimeout(() => {
                this.privates?.formRef?.current?.resetFields();
                this.privates?.formRef?.current?.submit();
            }, 100);
        },
        handleKeyDown(e) {
            if (window.event) {
                e = window.event;
            }
            let code = e.charCode || e.keyCode;
            if (code === 13) {
                e.stopPropagation(); //阻止冒泡或捕获促使enter键下拉框展开
                this.actions.filterBtnOncilck();
            }
        },
        getModuleData() {
            const { moduleSourceType } = this.privates;
            if (!moduleSourceType) return;
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionModules/1/dataSource/${moduleSourceType}`,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    this.privates.templateList = [];
                    _map(resp.data, (item, key) => {
                        this.privates.templateList = this.privates.templateList.concat(item);
                    });
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.index.qualityCheckTasks
                            )
                    );
                }
            );
        },
        filterChange(e) {
            this.privates.moduleSourceType = e.target.value;
            this.actions.update();
            this.actions.getModuleData();
        },
        onTabsChange(activeKey) {
            this.privates.storages.activeTabKey = activeKey;
            if (this.privates.isWechatQa) {
                this.privates.storages.wecomInspectDataSource = Udesk.enums.inspectDataSources[activeKey].id;
            }
            this.actions.getFilterControlFormFields();
            this.actions.update();
        },
        getFilterControlFormFields() {
            let company = Udesk.data.init.company;
            let sources = [{ label: UdeskLocales.current.labels.all, value: null }];
            let inspectDataSources = Udesk.business.task.getInspectDataSources(
                this.privates.isWechatQa,
                company,
                this.privates.storages.activeTabKey === 'informationCollection'
            );
            inspectDataSources.forEach((i) => sources.push({ label: i.name, value: i.id }));
            this.privates.filterControlFormFields = [
                {
                    name: 'keyword',
                    label: UdeskLocales.current.pages.tasks.index.taskName,
                    type: 'Input',
                    props: {
                        style: {
                            maxWidth: 246,
                            minWidth: 200,
                            width: '100%',
                        },
                        placeHolder: `${UdeskLocales.current.labels.pleaseEnter} ${UdeskLocales.current.pages.tasks.index.taskName}`,
                    },
                },
                {
                    name: 'inspectDataSource',
                    label: UdeskLocales.current.pages.tasks.index.inspectDataSource,
                    type: 'Select',
                    props: {
                        options: sources,
                        style: {
                            minWidth: 200,
                            width: '100%',
                            maxWidth: 246,
                        },
                    },
                },
                {
                    name: 'inspectionType',
                    label: UdeskLocales.current.pages.tasks.index.inspectionType,
                    type: 'Select',
                    props: {
                        options: Udesk.enums.inspectTaskType.map((i) => ({ label: i.name, value: i.id })),
                        style: {
                            minWidth: 200,
                            width: '100%',
                            maxWidth: 246,
                        },
                    },
                },
            ];
            this.actions.update();
        },
        openSaveAsModel(task) {
            this.privates.saveAsModelVisible = true;
            this.privates.saveAsFormInitValue.name = task.name + /* (备份) */UdeskLocales.current.pages.tasks.component.backup;
            this.privates.currentSaveAsTask = task;
            this.actions.update();
            this.privates.saveAsFormRef?.current?.resetFields?.();
        },
        commitSaveAs(values) {
            if (!this.privates.currentSaveAsTask) return;
            const { taskType, remark, id } = this.privates.currentSaveAsTask;
            postInspectionTasksSaveAs(
                {
                    taskType,
                    remark,
                    id,
                    ...values,
                },
                {
                    successMsg: /* 任务另存为成功 */UdeskLocales.current.pages.tasks.component.taskSaveAsSucceeded,
                }
            ).then(() => {
                this.privates.saveAsModelVisible = false;
                this.privates.currentSaveAsTask = null;
                this.actions.update();
                this.actions.reloadAsyncModel();
            });
        },
        getSaveAsFormFields() {
            this.privates.saveAsFormFields = [
                {
                    name: 'name',
                    label: UdeskLocales.current.pages.tasks.index.taskName,
                    type: 'Input',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    props: {
                        style: {
                            maxWidth: 246,
                            minWidth: 200,
                            width: '100%',
                        },
                        placeHolder: `${UdeskLocales.current.labels.pleaseEnter} ${UdeskLocales.current.pages.tasks.index.taskName}`,
                    },
                },
                {
                    name: 'configSettings',
                    label: /* 复制内容 */UdeskLocales.current.pages.tasks.component.copyContent,
                    type: 'CheckBoxGroupCol',
                    props: {
                        options: [
                            { disabled: true, label: /* 基本信息 */UdeskLocales.current.pages.tasks.component.essentialInformation, value: 'BASE_INFO', defaultChecked: true },
                            { disabled: true, label: /* 规则配置 */UdeskLocales.current.pages.tasks.component.ruleConfiguration, value: 'CHECK_POINT', defaultChecked: true },
                        ].concat(
                            this.privates.isWechatQa
                                ? []
                                : [
                                    { label: /* 基本信息-质检员 */UdeskLocales.current.pages.tasks.component.basicInformationQualityInspector, value: 'INSPECTOR' },
                                    { label: /* 质检评级 */UdeskLocales.current.pages.tasks.component.qualityInspectionRating, value: 'INSPECT_GRADE' },
                                    { label: /* 角色配置 */UdeskLocales.current.pages.tasks.component.roleConfiguration, value: 'ROLE' },
                                    { label: /* 自动添加配置 */UdeskLocales.current.pages.tasks.component.autoAddConfiguration, value: 'AUTO_LOAD' },
                                    { label: /* 自动推送配置 */UdeskLocales.current.pages.tasks.component.autoPushConfiguration, value: 'AUTO_PUSH' },
                                    { label: /* 自动抽检配置 */UdeskLocales.current.pages.tasks.component.automaticSamplingConfiguration, value: 'AUTO_SAMPLE' },
                                    { label: /* 自动分配配置 */UdeskLocales.current.pages.tasks.component.automaticAllocationConfiguration, value: 'AUTO_ASSIGN' },
                                    // { label: '案例库同步', value: 'CASE_LIBRARY' },
                                    { label: /* 业务配置 */UdeskLocales.current.pages.tasks.component.businessConfiguration, value: 'BIZ_SETTING' },
                                ]
                        ),
                    },
                },
            ];
        },
    }; //
    //#endregion
    init() {
        this.privates.isWechatQa = this.props.route.name === 'wechatQaTasksIndex';
        this.actions.getFilterControlFormFields();
        this.actions.getSaveAsFormFields();

        if (this.privates.isWechatQa) {
            if (!['wechatDialogue', 'wechatRadio'].includes(this.privates.storages.activeTabKey)) {
                this.privates.storages.activeTabKey = 'wechatDialogue';
            }
        } else {
            this.privates.storages.wecomInspectDataSource = DEFAULT_STORAGES.wecomInspectDataSource;
            this.privates.storages.activeTabKey =
                this.props.location?.state?.taskType === Udesk.enums.taskType.informationCollection.id
                    ? 'informationCollection'
                    : 'qualityCheckTasks';
        }
    }
    onAsyncModelResolved(asyncModel) {
        requestIdleCallback(() => {
            if (asyncModel.tasks) {
                this.privates.asyncModelTasks = asyncModel.tasks;
            }
            if (asyncModel.charts) {
                this.privates.asyncModelCharts = asyncModel.charts;
            }
            if (asyncModel.sysConfig) {
                this.privates.enabledInspectRuleApprove = !!asyncModel.sysConfig.enabledInspectRuleApprove;
                this.privates.enabledInspectRuleApproveAuto = !!asyncModel.sysConfig.enabledInspectRuleApproveAuto;
            }
            this.actions.update();
        });
    }
    componentDidMount() {
        document.body.addEventListener('keydown', this.actions.handleKeyDown);
    }
    componentWillUnmount() {
        // this.privates.xhr && this.privates.xhr.abort && this.privates.xhr.abort();
        this.privates.controller.abort();

        document.body.removeEventListener('keydown', this.handleKeyDown);
    }
}
