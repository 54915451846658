import React from 'react';
import SmartWordsLibraryCommonComponent from './component';
import ReactModal from 'udesk-react/src/components/react-modal';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'udesk-ui';
import ReactSelect from 'udesk-react/src/components/react-select';
import Udesk from 'Udesk';
import SmartWordsLibraryCommonList from '../smart-words-library-common-list';

export default class SmartWordsLibraryCommonTemplate extends SmartWordsLibraryCommonComponent {
    render() {

        let {
            navItemName,
            navItemType,
            boardName,
            uploadModalTitle,
            getCommonListPath
        } = this.props;

        let {
            getFieldDecorator
        } = this.props.form;

        let {
            locales,
            actions
        } = this;

        let {
            columns,
            isCommonWordsModalShow,
            commonWordsModalTitle,
            corporaName,
            corporaStatus,
        } = this.privates;

        return (
            <div>
                <SmartWordsLibraryCommonList
                    navItemType={navItemType}
                    boardName={boardName}
                    columns={columns}
                    uploadModalTitle={uploadModalTitle}
                    getlistDataPath={getCommonListPath}
                    openAddModal={actions.createCommonWords}
                    onCreated={(instance) => { this.privates.smartWordsLibraryListInstance = instance; }}
                />

                <ReactModal cancelText={locales.labels.cancel}
                    visible={isCommonWordsModalShow}
                    title={commonWordsModalTitle}
                    headerClassName="udesk-qa-smart-words-library-modal-header"
                    footerClassName="udesk-qa-smart-words-library-modal-footer"
                    okText={locales.labels.save}
                    onOk={actions.saveCommonWords}
                    onCancel={actions.closeCommonWordsModal}
                >
                    <Form className="smart-words-library-nav-item-form" layout="horizontal" onSubmit={actions.saveCommonWords.preventDefault()}>

                        <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} label={locales.fields.smartWordsLibrary[navItemName]}>
                            {getFieldDecorator('corporaName', {
                                initialValue: corporaName,
                                rules: [{
                                    required: true,
                                    message: locales.components.pages.smartWordsLibrary[navItemName].pleaseEnter
                                }]
                            })(
                                <Input
                                    placeholder={locales.components.pages.smartWordsLibrary[navItemName].pleaseEnter}
                                />
                            )}
                        </Form.Item>

                        <Form.Item
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            label={locales.fields.smartWordsLibrary.common.effectiveness}
                        >
                            {getFieldDecorator('corporaStatus', {
                                initialValue: corporaStatus.toString()
                            })(
                                <ReactSelect
                                    classNames="udesk-qa-smart-words-library-react-select"
                                    hasNullSelect={false}
                                    dataScource={Udesk.enums.wordsEffectiveness}
                                    onChanged={actions.changeCommonWordsEffectiveness}
                                ></ReactSelect>
                            )}
                        </Form.Item>

                    </Form>

                </ReactModal>

            </div>
        );
    }
}