import React, { useState, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';
import { ScoringRulesTitle } from './components/Title';
import styled from 'styled-components';
import { Drawer } from 'udesk-ui';
import { useDrawer } from './hooks/useDrawer';
import UdeskList from 'Component/common/udesk-list';
import _cloneDeep from 'lodash-es/cloneDeep';
import { ScoringRulesDrawerTitle } from './components/DrawerTitle';
import { ScoringRulesAbbreviatedList } from './components/ScoringRulesAbbreviatedList';
import { ScoringRuleItem } from './components/ScoringRuleItem';
import {
    deleteReviewIntelligentPartnerNodeScoreRule,
    postReviewIntelligentPartnerNodeScoreRule,
    putReviewIntelligentPartnerNodeScoreRule,
} from 'src/api/review/intelligentPartner/node/scoreRule';

const ScoringRuleListWrap = styled.div`
    .udesk-coach-web-scoring-rule-item-delete-btn {
        position: absolute;
        top: 20px;
        right: 8px;
    }
`;

const useCloneValue = (safeValue) => {
    const cloneValue = (safeValue) => _cloneDeep(safeValue) ?? [];
    const [value, setValue] = useState(() => {
        return cloneValue(safeValue);
    });

    useEffect(() => {
        setValue(cloneValue(safeValue).map((i) => ({ ...i, _id: Udesk.utils.string.uuid() })));
    }, [safeValue]);

    const setValueOut = (newValueCb) => {
        setValue((prev) => {
            const newValue = typeof newValueCb === 'function' ? newValueCb(prev) : newValueCb;
            const res = newValue.map((i) => {
                if (i._id) return i;
                return { ...i, _id: Udesk.utils.string.uuid() };
            });

            return res;
        });
    };
    return [value, setValueOut];
};

export const ScoringRules = React.memo((props: any) => {
    // const locales = Locales['current'];
    const { value: safeValue, onChange, configId, type, showWords = false } = props;
    const { showDrawer, ...drawerProps } = useDrawer();
    const [value, setValue] = useCloneValue(safeValue);
    // const [formMap, setFormMap] = useState<any>({});
    const formMap = useRef({});
    const deletedItemIds = useRef<any[]>([]);

    const registerScoringRuleItemForm = (key, form) => {
        // setFormMap((prev) => {
        //     return {
        //         ...prev,
        //         [key]: form,
        //     };
        // });
        formMap.current[key] = form;
    };

    return (
        <React.Fragment>
            <ScoringRulesTitle
                onButtonClick={showDrawer}
                totalScoring={(safeValue || []).reduce((prev, cur) => {
                    return cur.operator === 1 ? prev + cur.score : prev - cur.score;
                }, 0)}
            ></ScoringRulesTitle>
            <ScoringRulesAbbreviatedList value={safeValue || []}></ScoringRulesAbbreviatedList>
            <Drawer
                title={
                    <ScoringRulesDrawerTitle
                        onClose={drawerProps.onClose}
                        onSubmit={() => {
                            const allIds = value.map((i) => i._id);
                            const res = Object.entries(formMap.current)
                                .map(([key, value]: [string, any]) => {
                                    if (allIds.includes(key)) return value;
                                    return null;
                                })
                                .filter(Boolean);
                            Promise.all(res.map((i: any) => i.form.validateFields())).then(
                                (values) => {
                                    // name?: string;
                                    // operator?: number;
                                    // rule?: string;
                                    // score?: number;
                                    const params = values.map((i, index) => ({
                                        ...i,
                                        rule: JSON.stringify(i.rule),
                                        configId,
                                        id: res[index].id,
                                        type,
                                    }));
                                    const request = configId
                                        ? putReviewIntelligentPartnerNodeScoreRule
                                        : postReviewIntelligentPartnerNodeScoreRule;
                                    if (deletedItemIds.current.length) {
                                        deleteReviewIntelligentPartnerNodeScoreRule(
                                            deletedItemIds.current
                                        );
                                    }
                                    request(params).then((resp) => {
                                        drawerProps.onClose();
                                        onChange(
                                            (resp as any).data.map((i) => ({
                                                ...i,
                                                rule: JSON.parse(i.rule),
                                            }))
                                            // configId
                                            //     ? params.map((i) => ({
                                            //         ...i,
                                            //         rule: JSON.parse(i.rule),
                                            //     }))
                                            //     : (resp as any).data.map((i) => ({
                                            //         ...i,
                                            //         rule: JSON.parse(i.rule),
                                            //     }))
                                        );
                                    });
                                }
                            );
                        }}
                    />
                }
                {...drawerProps}
                width={700}
            >
                <ScoringRuleListWrap>
                    <UdeskList
                        deleteItem={(index) => {
                            let newValue = [...value];
                            newValue[index] = null;
                            // onChange?.(newValue.filter(Boolean));
                            setValue(newValue.filter(Boolean));
                            const _id = value[index]._id;
                            // setFormMap((prev) => {
                            //     prev[_id] = null;
                            //     return prev;
                            // });
                            if (value[index].id) {
                                deletedItemIds.current.push(value[index].id);
                            }
                            formMap.current[_id] = null;
                        }}
                        addItem={() => {
                            // onChange?.([...value, {}]);
                            setValue([
                                ...value,
                                {
                                    rule: {
                                        conditionList: [
                                            {
                                                ...(ScoringRuleItem as any).newScoringRuleItemIf,
                                                idCode: 'R1',
                                            },
                                        ],
                                        logicalOperator: '&&',
                                    },
                                    operator: 1,
                                    score: 0,
                                },
                            ]);
                        }}
                        deleteBtnClassName="udesk-coach-web-scoring-rule-item-delete-btn"
                    >
                        {value.map((item, index) => {
                            return (
                                <ScoringRuleItem
                                    key={item._id}
                                    {...item}
                                    ref={(ref: any) => {
                                        registerScoringRuleItemForm(item._id, ref);
                                    }}
                                    showWords={showWords}
                                ></ScoringRuleItem>
                            );
                        })}
                    </UdeskList>
                </ScoringRuleListWrap>
            </Drawer>
        </React.Fragment>
    );
});
