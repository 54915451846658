import { useCallback, useEffect, useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

export const useControlButtons = (
    reactFlowInstance: any,
    currentSelectedNode,

    store,
    undoAble,
    redoAble,
    autoLayout
) => {
    const { undo, redo } = store;
    const [redoLoading, setRedoLoading] = useState<boolean>(false);
    const [undoLoading, setUndoLoading] = useState<boolean>(false);

    const checkBtnLoadingStatus = useCallback(() => {
        if (undoLoading) return Udesk.ui.notify.error(/* 撤销中，请稍后... */UdeskLocales['current'].pages.coach.courseManagement.flow.components.flow.useControlButtons.cancellingPleaseWait);
        if (redoLoading) return Udesk.ui.notify.error(/* 重做中，请稍后... */UdeskLocales['current'].pages.coach.courseManagement.flow.components.flow.useControlButtons.redoInProgressPleaseWait);
        return redoLoading || undoLoading;
    }, [redoLoading, undoLoading]);

    const { triggerFitView } = useFitView(reactFlowInstance);
    const showDel =
        currentSelectedNode && currentSelectedNode.type !== Udesk.enums.nodeTypes.pptNode.key;

    const [forceUpdate, _forceUpdate] = useState<number>(0);
    const triggerForceUpdate = () => {
        _forceUpdate((p) => p + 1);
    };
    const [btns, setBtns] = useState<any[]>([]);

    const fitView = () => {
        triggerFitView();
        triggerForceUpdate();
    };
    useEffect(() => {
        const zoomNumber = Math.floor(reactFlowInstance?.getZoom?.() * 100) + '%';
        const autoLayoutBtn = {
            label: /* 自动整理 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.flow.useControlButtons.automaticOrganization,
            icon: 'ApartmentOutlined',
            onClick: () => {
                autoLayout().then(fitView);
            },
        };
        const fitViewBtn = {
            label: /* 画布自适应 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.flow.useControlButtons.canvasAdaptation,
            icon: 'AimOutlined',
            onClick: fitView,
        };
        const zoomIn = {
            icon: 'ZoomInOutlined',
            onClick: () => {
                reactFlowInstance?.zoomIn?.();
                Promise.resolve().then(() => {
                    triggerForceUpdate();
                });
            },
        };
        const zoomNumberBtn = {
            label: zoomNumber,
        };
        const zoomOut = {
            icon: 'ZoomOutOutlined',
            onClick: () => {
                reactFlowInstance?.zoomOut?.();
                Promise.resolve().then(() => {
                    triggerForceUpdate();
                });
            },
        };
        const undobtn = {
            icon: 'UndoOutlined',
            style: {
                color: getBtnColor(!undoAble),
            },
            onClick: () => {
                if (!undoAble || checkBtnLoadingStatus()) return;
                setUndoLoading(true);
                undo().finally(() => {
                    setUndoLoading(false);
                });
            },
        };
        const redobtn = {
            icon: 'RedoOutlined',
            style: {
                color: getBtnColor(!redoAble),
            },
            onClick: () => {
                if (!redoAble || checkBtnLoadingStatus()) return;
                setRedoLoading(true);
                redo().finally(() => {
                    setRedoLoading(false);
                });
            },
        };
        const defaultExBtns = [
            [undobtn, redobtn],
            [zoomIn, zoomNumberBtn, zoomOut],
            [fitViewBtn, autoLayoutBtn],
        ];
        if (showDel) {
            setBtns([
                [
                    {
                        label: /* 删除 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.flow.useControlButtons.delete,
                        icon: 'DeleteOutlined',
                        style: { color: 'red' },
                        onClick: () => {
                            reactFlowInstance?.deleteElements({
                                nodes: [currentSelectedNode],
                            });
                        },
                    },
                ],
                ...defaultExBtns,
            ]);
        } else {
            setBtns(defaultExBtns);
        }
    }, [
        showDel,
        currentSelectedNode,
        reactFlowInstance,
        forceUpdate,
        undoAble,
        redoAble,
        autoLayout,
        checkBtnLoadingStatus,
        undo,
        redo,
    ]);

    return { btns, onZoom: triggerForceUpdate };
};

function useFitView(reactFlowInstance) {
    const [random, setRandom] = useState(0);

    const trigger = () => {
        setRandom((prev) => prev + 1);
    };

    useEffect(() => {
        reactFlowInstance?.fitView();
    }, [random]);

    return {
        triggerFitView: trigger,
    };
}

function getBtnColor(disabled: boolean) {
    if (disabled) return 'rgba(0, 0, 0, 0.25)';
    return 'rgba(0, 0, 0, 0.88)';
}
