import React, { useState, useEffect, useCallback } from 'react';
import Udesk from 'Udesk';
import { Card, Drawer, Menu, Icon, Space, Anchor, Badge, Tag, Modal } from 'udesk-ui';
// import './style.scss';
import _isEmpty from 'lodash-es/isEmpty';
import styled from 'styled-components';
import { PointItem } from './components/PointItem';
import { ComplainButton } from './components/ComplainButton';
import { CopyButton } from './components/CopyButton';
import { ReviewComponent } from './components/ReviewComponent';

const { SubMenu } = Menu;
const { Link } = Anchor;

const HitFilterDiv = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.03);
`;

function QualityScore(componentProps) {
    const {
        isReview,
        pointCategoryList,
        visible,
        locales,
        actions,
        menuSelectId,
        complainPointIdMap,
        testSetListFlag,
        dropChed,
        dropValue,
        showAppealButton,
        task,
        testSetList,
        xhr,
        prointSet,
        isTest,
        // type,
        isPopoverVisible,
        appealHistory,
        props,
    } = componentProps;
    const show = useCallback((item) => (props.showHit ? item._isHit : true), [props.showHit]);
    const options = [
        { label: locales.labels.all, value: 0 },
        { label: locales.columns.tasks.manage.workbench.checkPointList.hit, value: 1 },
    ];
    const [modal, setModal] = useState(null);

    const forEachAllPoint = useCallback(
        (callback) => {
            const predicate = (item) => !item._hidden;
            if (pointCategoryList) {
                pointCategoryList.filter(predicate).forEach((item) => {
                    if (item.children) {
                        item.children.filter(predicate).forEach((item) => {
                            if (item.pointDataList) {
                                item.pointDataList.filter(predicate).forEach((item) => {
                                    callback(item);
                                });
                            }
                        });
                    }
                });
            }
        },
        [pointCategoryList]
    );

    const getAllPoint = useCallback(() => {
        const result = [];
        forEachAllPoint((item) => {
            result.push(item);
        });
        return result;
    }, [forEachAllPoint]);

    const getAllPointId = useCallback(
        () =>
            getAllPoint()
                .map((item) => item.pointId)
                .join('/'),
        [getAllPoint]
    );

    const openDetail = (point) => {
        const conditionMatchResults = point?.conditionMatchResults?.filter(Boolean) ?? [];
        if (modal) {
            modal.destroy();
        }
        conditionMatchResults.length &&
            setModal(
                Modal.info({
                    content: (
                        <React.Fragment>
                            <div style={{ marginBottom: 8 }}>{`${point.name}${
                                /* 规则内算子命中详情： */ locales.components.qualityScore.index
                                    .hitDetailsOfOperatorsInRules
                            }`}</div>
                            {conditionMatchResults.map((res, index, arr) => {
                                return (
                                    <div
                                        style={
                                            index === arr.length - 1
                                                ? undefined
                                                : { marginBottom: 8 }
                                        }
                                    >
                                        <Space>
                                            <Tag color="blue">{`${res.conditionIdCode}`}</Tag>
                                            <span>:</span>
                                            <div>
                                                <Badge status={res.hit ? 'success' : 'error'} />
                                                <span>
                                                    {res.hit
                                                        ? /* 命中 */ locales.components.qualityScore
                                                            .index.hit
                                                        : /* 未命中 */ locales.components
                                                            .qualityScore.index.miss}
                                                </span>
                                            </div>
                                        </Space>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    ),
                })
            );
    };
    useEffect(() => {
        return () => {
            if (modal) {
                modal.destroy();
            }
        };
    }, []);
    return (
        <React.Fragment>
            {!isReview && !_isEmpty(pointCategoryList) && (
                <Drawer
                    mask={false}
                    maskClosable={false}
                    visible={visible}
                    width={190}
                    placement="left"
                    height="100%"
                    getContainer={false}
                    closable={false}
                    title={locales.components.pages.components.qualityCheckScore.pointEdit}
                    onMouseMove={actions.drawerVisibleTrue.params(true)}
                    onMouseLeave={actions.drawerVisibleTrue.params(false)}
                >
                    <If condition={pointCategoryList && pointCategoryList.length > 0}>
                        <Menu
                            theme="light"
                            mode="inline"
                            defaultOpenKeys={actions.openMap(pointCategoryList)}
                            selectedKeys={[`${menuSelectId}`]}
                        >
                            <For each="firstCategory" index="index" of={pointCategoryList}>
                                <SubMenu
                                    title={`${firstCategory.name}\n${
                                        firstCategory.score !== null ? firstCategory.score : `--`
                                    }/${actions.formatTotalScore(firstCategory)}`}
                                    key={`${firstCategory.id}`}
                                >
                                    <If
                                        condition={
                                            firstCategory.children &&
                                            firstCategory.children.length > 0
                                        }
                                    >
                                        <For
                                            each="secondCategory"
                                            index="secondIndex"
                                            of={firstCategory.children}
                                        >
                                            <Menu.Item
                                                onClick={actions.selectedCategory.params(
                                                    `quality-check-talk-point-${index}-${secondIndex}`,
                                                    secondCategory.id
                                                )}
                                                key={`${secondCategory.id}`}
                                                title={
                                                    secondCategory.name +
                                                    ' ' +
                                                    (secondCategory.score !== null
                                                        ? secondCategory.score
                                                        : `--`) +
                                                    locales.labels.scoreUnit +
                                                    locales.labels.splitter +
                                                    actions.formatTotalScore(secondCategory)
                                                }
                                            >{`${secondCategory.name} \n ${
                                                    secondCategory.score !== null
                                                        ? secondCategory.score
                                                        : `--`
                                                }/${actions.formatTotalScore(
                                                    secondCategory
                                                )}`}</Menu.Item>
                                        </For>
                                    </If>
                                </SubMenu>
                            </For>
                        </Menu>
                    </If>
                </Drawer>
            )}
            {typeof props.onShowHitChange === 'function' && (
                <HitFilterDiv>
                    <div style={{ marginRight: 8 }}>
                        {/* 筛选: */}
                        {locales.components.qualityScore.index.filter}
                    </div>
                    {options.map((item) => {
                        return (
                            <Tag
                                style={{ cursor: 'pointer' }}
                                onClick={() => props.onShowHitChange?.(item.value)}
                                color={props.showHit === item.value ? 'blue' : undefined}
                            >
                                {item.label}
                            </Tag>
                        );
                    })}
                </HitFilterDiv>
            )}
            <div
                className={`quality-check-score-talk-body-content ${
                    typeof props.onShowHitChange === 'function' ? 'with-filter' : ''
                }`}
            >
                {!_isEmpty(pointCategoryList) &&
                    pointCategoryList
                        .filter((i) => !i._hidden && show(i))
                        .map((item, firstIndex) => {
                            return (
                                <div>
                                    <If condition={item.children && item.children.length > 0}>
                                        {item.children
                                            .filter((i) => !i._hidden && show(i))
                                            .map((items, index) => {
                                                return (
                                                    <If
                                                        condition={
                                                            items.pointDataList &&
                                                            items.pointDataList.length > 0
                                                        }
                                                    >
                                                        <PointClassificationAnchor
                                                            actions={actions}
                                                            visible={visible}
                                                            items={items}
                                                            item={item}
                                                            showAppealButton={showAppealButton}
                                                        />
                                                        <div
                                                            className="quality-check-score-talk-field"
                                                            onMouseEnter={actions.drawerVisibleTrue.params(
                                                                false
                                                            )}
                                                            id={`quality-check-talk-point-${firstIndex}-${index}`}
                                                        >
                                                            <Card bordered={false}>
                                                                <For
                                                                    each="point"
                                                                    index="index"
                                                                    of={items.pointDataList.filter(
                                                                        (i) => !i._hidden && show(i)
                                                                    )}
                                                                >
                                                                    <PointItem
                                                                        complainPointIdMap={
                                                                            complainPointIdMap
                                                                        }
                                                                        testSetListFlag={
                                                                            testSetListFlag
                                                                        }
                                                                        dropChed={dropChed}
                                                                        actions={actions}
                                                                        dropValue={dropValue}
                                                                        isTest={isTest}
                                                                        openDetail={openDetail}
                                                                        locales={locales}
                                                                        showAppealButton={
                                                                            showAppealButton
                                                                        }
                                                                        task={task}
                                                                        items={items}
                                                                        testSetList={testSetList}
                                                                        index={index}
                                                                        xhr={xhr}
                                                                        prointSet={prointSet}
                                                                        props={props}
                                                                        isPopoverVisible={
                                                                            isPopoverVisible
                                                                        }
                                                                        appealHistory={
                                                                            appealHistory
                                                                        }
                                                                        isReview={isReview}
                                                                        point={point}
                                                                    />
                                                                </For>
                                                            </Card>
                                                        </div>
                                                    </If>
                                                );
                                            })}
                                    </If>
                                </div>
                            );
                        })}

                {actions.isAllComplain(getAllPointId(), (allPointId) => (
                    <div
                        style={{
                            display: 'flex',
                            margin: 10,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ComplainButton
                            btnText={locales.components.pages.appealSdk.allComplain}
                            visible={showAppealButton}
                            pointId={allPointId}
                            onSubmitClick={actions.onSubmitClick}
                            onCancelClick={actions.onCancelClick}
                            onVisibleChange={actions.onVisibleChange}
                            hasOtherPopoverOpen={isPopoverVisible}
                            originValue={complainPointIdMap[allPointId]}
                            inspectDataSource={task.inspectDataSource}
                        />
                    </div>
                ))}
                {actions.isAllApprove(getAllPoint(), (point) => (
                    <ReviewComponent
                        localesReview={appealHistory}
                        visible={isReview}
                        complaintInfo={point.pointComplaintInfo}
                        onReject={actions.changePointRejectReason.params(point)}
                        onResolve={actions.passComplain.params(point)}
                        showActionsBtn={
                            props.detail.reviewStatus !==
                            Udesk.enums.sampleAndReviewStatus.reviewed.id
                        }
                    />
                ))}
            </div>
        </React.Fragment>
    );
}

export default QualityScore;

function PointClassificationAnchor(props) {
    const { actions, visible, items, item, showAppealButton } = props;
    return (
        <Anchor
            showInkInFixed={false}
            affix={true}
            getContainer={() =>
                document.getElementsByClassName('quality-check-score-talk-body-content')[0]
            }
            onChange={actions.anchorChange}
        >
            <Link
                title={
                    <div className="quality-check-score-talk-class">
                        <div className="quality-check-score-talk-class-draw">
                            {!visible && (
                                <Icon
                                    type="ic-menuopen-zhankaicebianlan"
                                    onClick={actions.drawerVisibleTrue.params(true, items.id)}
                                />
                            )}
                            {visible && (
                                <Icon
                                    type="ic-menufold-shouqicebianlan1"
                                    onClick={actions.drawerVisibleTrue.params(false)}
                                />
                            )}
                        </div>
                        <ClassificationName
                            showAppealButton={showAppealButton}
                            firstClassification={item}
                            secondClassification={items}
                            formatTotalScore={actions.formatTotalScore}
                        />
                    </div>
                }
            />
        </Anchor>
    );
}

function ClassificationName({
    firstClassification,
    formatTotalScore,
    secondClassification,
    showAppealButton,
}) {
    const firstClassificationName =
        !_isEmpty(firstClassification) && firstClassification.name
            ? firstClassification.name
            : '--';
    const secondClassificationName =
        !_isEmpty(secondClassification) && secondClassification.name
            ? secondClassification.name
            : '--';

    return (
        <span className="quality-check-score-talk-class-title">
            {firstClassificationName}
            <span className="quality-check-score-talk-class-score">
                {!_isEmpty(firstClassification) &&
                    (firstClassification.score !== null ? firstClassification.score : `--`)}
                /{formatTotalScore(firstClassification)}
            </span>
            <Icon
                type="ic-arrow-right-s-youjiantou"
                style={{
                    fontSize: '16px',
                    color: 'rgba(0,0,0,0.25)',
                    verticalAlign: 'top',
                }}
            ></Icon>
            <span
                style={{
                    color: 'rgba(0,0,0,0.85)',
                    fontSize: '14px',
                }}
            >
                {secondClassificationName}
                <span className="quality-check-score-talk-class-score">
                    {!_isEmpty(secondClassification) &&
                        (secondClassification.score !== null ? secondClassification.score : `--`)}
                    /{formatTotalScore(secondClassification)}
                </span>
            </span>
            {!showAppealButton && (
                <div className="check-point-name-copy-btn">
                    <CopyButton text={`${firstClassificationName} > ${secondClassificationName}`} />
                </div>
            )}
        </span>
    );
}
