import React, { useEffect, useState, useMemo } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import {
    Modal,
    Form,
    Input,
    Select,
    InputNumber,
    // Row,
    // Col,
    // Switch,
    // TimePicker,
    // Icon,
    // Slider,
    Space,
    Button,
} from 'udesk-ui';
// import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './style.scss';
import { postShareLogs } from 'src/api/shareLogs';
import { ShareLogCreateRequest } from 'src/api/types';
import { apiPrefix } from 'src/util/settings';

const { TextArea } = Input;
const locales = Locales['current'];
// const format = 'mm:ss';

const ShareModal: React.FC<any> = (props) => {
    let { detail } = props;
    let callId = detail && detail.id;
    let answerUserName =
        (detail &&
            detail.dialogSentenceList &&
            detail.dialogSentenceList.filter((item) => item.role === 'agent')[0]?.nickName) ||
        '';
    let dialUserName =
        (detail &&
            detail.dialogSentenceList &&
            detail.dialogSentenceList.filter((item) => item.role === 'customer')[0]?.nickName) ||
        '';
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 20,
            },
        },
    };
    const [form] = Form.useForm();
    const [isShare, setIsShare] = useState(false);
    // const [rangTime, setRangTime] = useState<any[]>([]);
    // const [rangTimeVal, setrRangTimeVal] = useState<number[]>([]);
    // const [isOpen, setIsOpen] = useState(true);
    const [timeLiness, setTimeLiness] = useState<number>(7);
    const [shareRecommend, setShareRecommend] = useState<string>('');
    const [audioUrl, setAudioUrl] = useState('');

    const shareText = useMemo(() => {
        return `${locales.components.gong.shareModal.index.customer}${dialUserName || '--'}${
            locales.components.gong.shareModal.index.employees
        }${answerUserName || '--'}${
            locales.components.gong.shareModal.index.timeliness
        }${timeLiness}${locales.components.gong.shareModal.index.recordingLinkValidWithinDays}${
            audioUrl || '--'
        }${locales.components.gong.shareModal.index.sharingInstructions}${shareRecommend || '--'}`;
    }, [answerUserName, dialUserName, timeLiness, audioUrl, shareRecommend]);
    const timeLineChange = (val) => {
        setTimeLiness(val);

        if (props.shareType === 'customer') {
            const params: ShareLogCreateRequest = {
                callId,
                age: val,
                content: shareRecommend,
                customerName: dialUserName,
                employeeName: answerUserName,
                type: 1,
            };
            // if (isOpen) {
            //     params.startSecond = rangTimeVal[0];
            //     params.endSecond = rangTimeVal[1];
            // }
            getShareData(params);
        }
    };
    const shareRecommendChange = (e) => {
        setShareRecommend(e.target.value);

        if (props.shareType === 'customer') {
            const params: ShareLogCreateRequest = {
                callId,
                age: timeLiness,
                content: e.target.value,
                customerName: dialUserName,
                employeeName: answerUserName,
                type: 1,
            };
            // if (isOpen) {
            //     params.startSecond = rangTimeVal[0];
            //     params.endSecond = rangTimeVal[1];
            // }
            getShareData(params);
        }
    };
    const getShareData = (params) => {
        postShareLogs(params).then((res) => {
            if (props.shareType === 'customer') {
                setAudioUrl(
                    `${props.sdkOptions.props.domain}/${apiPrefix}/shareLogs/share/video/${
                        res.data?.id ?? 0
                    }`
                );
            } else {
                Udesk.ui.notify.success(locales.components.gong.shareModal.index.shareSuccess);
                setIsShare(false);
                props.handlerModalCancel && props.handlerModalCancel();
            }
        });
    };
    const handleOk = () => {
        form.validateFields().then((values) => {
            const params: ShareLogCreateRequest = {
                callId,
                employeeIds: values.employeeIds,
                content: values.recommend,
                customerName: dialUserName,
                employeeName: answerUserName,
                type: 0,
            };
            // if (isOpen) {
            //     params.startSecond = rangTimeVal[0];
            //     params.endSecond = rangTimeVal[1];
            // }
            getShareData(params);
            // props.handlerModalOk && props.handlerModalOk(values);
        });
    };
    const handleCancel = () => {
        form.resetFields();
        setIsShare(false);
        props.handlerModalCancel && props.handlerModalCancel();
    };
    // const handlerChange = (value) => {
    //     let startTime = timeFormat(value[0]);
    //     let endTime = timeFormat(value[1]);
    //     setrRangTimeVal(value);
    //     setRangTime([startTime, endTime]);

    //     if (props.shareType === 'customer') {
    //         const params: ShareLogCreateRequest = {
    //             callId,
    //             age: timeLiness,
    //             content: shareRecommend,
    //             customerName: dialUserName,
    //             employeeName: answerUserName,
    //             type: 1,
    //         };
    //         if (isOpen) {
    //             params.startSecond = value[0];
    //             params.endSecond = value[1];
    //         }
    //         getShareData(params);
    //     }
    // };
    // const timeFormat = (value) => {
    //     let hh: number = Math.floor(value / 60 / 60);
    //     let mm: number = Math.floor((value / 60) % 60);
    //     let ss: number = Math.floor(value % 60);
    //     if (hh === 0) {
    //         return (mm < 10 ? '0' + mm : mm) + ':' + (ss < 10 ? '0' + ss : ss);
    //     } else {
    //         return (hh < 10 ? '0' + hh : hh) + ':' + (mm < 10 ? '0' + mm : mm) + ':' + (ss < 10 ? '0' + ss : ss);
    //     }
    // };
    // const handlerSelectTime = (value) => {
    //     let startTime = moment(value[0]).format('mm:ss');
    //     let endTime = moment(value[1]).format('mm:ss');
    //     setRangTime([startTime, endTime]);
    //     let resultStart = moment(value[0]).format('m:s').split(':');
    //     let resultEnd = moment(value[1]).format('m:s').split(':');
    //     let startTimeVal = parseInt(resultStart[0]) * 60 + parseInt(resultStart[1]);
    //     let endTimeVal = parseInt(resultEnd[0]) * 60 + parseInt(resultEnd[1]);
    //     setrRangTimeVal([startTimeVal, endTimeVal]);

    //     if (props.shareType === 'customer') {
    //         const params: ShareLogCreateRequest = {
    //             callId,
    //             age: timeLiness,
    //             content: shareRecommend,
    //             customerName: dialUserName,
    //             employeeName: answerUserName,
    //             type: 1,
    //             startSecond: startTimeVal,
    //             endSecond: endTimeVal,
    //         };
    //         if (isOpen) {
    //             params.startSecond = startTimeVal;
    //             params.endSecond = endTimeVal;
    //         }
    //         getShareData(params);
    //     }
    // };
    const footerNode = () => {
        if (props.shareType === 'colleague') {
            return (
                <Space>
                    <Button onClick={handleCancel}>
                        {locales.components.gong.shareModal.index.cancel}
                    </Button>
                    <Button type="primary" onClick={handleOk}>
                        {locales.components.gong.shareModal.index.share}
                    </Button>
                </Space>
            );
        } else {
            return (
                <Space>
                    <Button onClick={handleCancel}>
                        {locales.components.gong.shareModal.index.cancel}
                    </Button>
                    <CopyToClipboard
                        text={shareText}
                        onCopy={() => {
                            Udesk.ui.notify.success(
                                locales.components.gong.shareModal.index.copySucceeded
                            );
                            setIsShare(false);
                            props.handlerModalCancel && props.handlerModalCancel();
                        }}
                    >
                        <Button type="primary">
                            {locales.components.gong.shareModal.index.copyShareLink}
                        </Button>
                    </CopyToClipboard>
                </Space>
            );
        }
    };
    // const handlerSwitchChange = (checked) => {
    //     setIsOpen(checked);
    // };
    useEffect(() => {
        setIsShare(props.isShare);
        // let startTime = timeFormat(0);
        // let endTime = timeFormat(parseInt(detail?.voiceLength));
        // setrRangTimeVal([0, parseInt(detail?.voiceLength)]);
        // setRangTime([startTime, endTime]);
        if (props.shareType === 'customer') {
            const params: ShareLogCreateRequest = {
                callId,
                age: timeLiness,
                content: shareRecommend,
                customerName: dialUserName,
                employeeName: answerUserName,
                type: 1,
            };
            // if (isOpen) {
            //     params.startSecond = 0;
            //     params.endSecond = parseInt(detail?.voiceLength);
            // }
            getShareData(params);
        }
    }, [props.isShare, detail]);
    return (
        <Modal
            title={
                props.shareType === 'colleague'
                    ? locales.components.gong.shareModal.index.shareWithColleagues
                    : locales.components.gong.shareModal.index.shareWithCustomers
            }
            visible={isShare}
            footer={footerNode()}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form form={form} {...formItemLayout} initialValues={{ timeLiness }}>
                <Form.Item label={locales.components.gong.shareModal.index.customer}>
                    {dialUserName || '--'}
                </Form.Item>
                <Form.Item label={locales.components.gong.shareModal.index.staff}>
                    {answerUserName || '--'}
                </Form.Item>

                {props.shareType === 'colleague' ? (
                    <Form.Item
                        name="employeeIds"
                        label={locales.components.gong.shareModal.index.selectColleagues}
                        rules={[
                            {
                                required: true,
                                message:
                                    locales.components.gong.shareModal.index
                                        .pleaseSelectColleaguesToShare,
                            },
                        ]}
                    >
                        <Select
                            options={props.userList}
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder={
                                locales.components.gong.shareModal.index
                                    .pleaseSelectColleaguesToShare
                            }
                            optionFilterProp="label"
                        ></Select>
                    </Form.Item>
                ) : (
                    <Form.Item label={locales.components.gong.shareModal.index.timeliness}>
                        <Space>
                            <Form.Item name="timeLiness" noStyle>
                                <InputNumber onChange={timeLineChange} min={1} />
                            </Form.Item>
                            <span className="udesk-ui-form-text">
                                {
                                    locales.components.gong.shareModal.index
                                        .recordingLinkValidWithinDays
                                }
                            </span>
                        </Space>
                    </Form.Item>
                )}
                <Form.Item
                    name="recommend"
                    label={locales.components.gong.shareModal.index.sharingInstructions}
                >
                    <TextArea
                        onChange={shareRecommendChange}
                        placeholder={
                            locales.components.gong.shareModal.index
                                .pleaseEnterTheSharingDescription
                        }
                        rows={3}
                    />
                </Form.Item>
            </Form>
            {/* <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={10}>
                    <Space>
                        <Switch defaultChecked onChange={handlerSwitchChange} />
                        <span>分享会话片段</span>
                    </Space>
                </Col>
                {isOpen ? (
                    <React.Fragment>
                        <Col span={14}>
                            <TimePicker.RangePicker onChange={handlerSelectTime} format={format} />
                        </Col>
                        <Col span={24}>
                            <div className='component-share-modal-rang-time-container'>
                                <div className='select-rang-time'>
                                    <Icon type='PlayCircleOutlined' style={{fontSize: 20, color: '#1A6EFF'}} antdIcon={true}></Icon>
                                    <Slider
                                        value={[rangTimeVal[0], rangTimeVal[1]]}
                                        min={0}
                                        max={parseInt(detail?.voiceLength)}
                                        onChange={handlerChange}
                                        tooltipVisible={false}
                                        className='slider-rang'
                                        range
                                    />
                                </div>
                                <div className='slider-rang-time'>
                                    {rangTime[0]}/{rangTime[1]}
                                </div>
                            </div>
                        </Col>
                    </React.Fragment>
                ) : null}
            </Row> */}
        </Modal>
    );
};

export default React.memo(ShareModal);
