// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdParam,
    BaseResponseFinishRecordResultAnalysisOverview,
} from '../../../../../types';

/**
 * 获取单个任务结果分析概览
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/result/analysis/overview/{taskId}
 * @param taskId
 */
export function getIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskId(
    options: ApiOptions<
        GetIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdParam,
        never
    > & { segments: GetIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdParam }
): Promise<BaseResponseFinishRecordResultAnalysisOverview> {
    return request<never, GetIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdParam, never>(
        '/intelligentPartnerTaskInfos/result/analysis/overview/{taskId}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskId',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/result/analysis/overview/{taskId}',
        method: 'get',
    },
];
