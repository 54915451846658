import Udesk from 'Udesk';
import React from 'react';
import { Pagination, Button, Table, PageHeader, Icon, Modal, Space, InputNumber } from 'udesk-ui';
import './style.scss';
import BillingCenterIndexRoute from './route';
import AutoSizeTable from 'Component/common/auto-size-table';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import { isQaSubApp, isSalesSubApp } from 'Udesk/system/subApp';

const { Column } = Table;

export default class BillingCenterIndexTemplate extends BillingCenterIndexRoute {
    render() {
        let {
            pageNum,
            pageSize,
            total,
            surplus,
            deadline,
            callUse,
            textUse,
            data,
            isWarning,
            startTime,
            endTime,
            warningColor,
            normalColor,
            callWarning,
            enabledImInspect,
            enabledCallInspect,
            visibleLimitEdit,
            callLimit,
            dialogueLimit,
        } = this.state;
        // let {
        // } = this.privates;
        let {
            actions,
            locales,
            privates
        } = this;
        // let { sdkOptions } = this.props;
        return (
            // <div className='billing-center-index-page'>
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.billingCenter.title}
                    extra={Udesk.data.init.user && Udesk.data.init.user.hasFeature("hc:charge:export") ? [
                        <Button onClick={actions.showLimitEdit} type='primary'>{locales.components.pages.billingCenter.limit}</Button>,
                        <Button onClick={actions.exportExcel}>{locales.components.pages.billingCenter.export}</Button>,
                    ] : []}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root billing-center-index-page'>
                        <div className='billing-center-index-page-body'>
                            <div className='billing-center-index-page-dashboard'>
                                <div className='billing-center-index-page-dashboard-quotaes billing-center-index-page-left'>
                                    <div className='billing-center-index-page-dashboard-title'>
                                        {locales.components.pages.billingCenter.dashboard.remainingQuota}
                                        <If condition={(surplus && enabledCallInspect && surplus * 1 < (callLimit || 50)) || (isWarning && enabledImInspect)}>
                                            <div className='billing-center-index-page-dashboard-warning'>
                                                {locales.components.pages.billingCenter.dashboard.warning}
                                            </div>
                                        </If>
                                    </div>
                                    
                                    <div className='billing-center-index-page-dashboard-item-cover'>
                                        <div className='billing-center-index-page-dashboard-item-left' style={{ color: enabledCallInspect && callWarning ? warningColor : normalColor }}>
                                            <div className='billing-center-index-page-dashboard-item-icon billing-center-index-page-left'>
                                                <span class="udesk-qa-react-web-iconfont billing-center-index-page-call">&#xe6e9;</span>
                                            </div>
                                            <div className='billing-center-index-page-dashboard-item-description-title'>
                                                {isSalesSubApp() ? locales.components.pages.billingCenter.dashboard.voice : locales.components.pages.billingCenter.dashboard.voiceQuality}
                                            </div>

                                            <If condition={surplus * 1 <= 9999}>
                                                <div className='billing-center-index-page-dashboard-item-description-quota'>
                                                    {surplus}
                                                </div>
                                                <div className='billing-center-index-page-dashboard-item-description-suffix'>
                                                    {locales.components.pages.billingCenter.dashboard.hour}
                                                </div>
                                                <If condition={!enabledCallInspect}>
                                                    <div className='billing-center-index-page-dashboard-item-description-suffix'>
                                                        {locales.components.pages.billingCenter.dashboard.isClosed}
                                                    </div>
                                                </If>
                                            </If>
                                            <If condition={surplus * 1 > 9999}>
                                                {/* else */}
                                                <div className='billing-center-index-page-dashboard-item-description-quota'>
                                                    {(surplus / 10000).toFixed(1)}
                                                </div>
                                                <div className='billing-center-index-page-dashboard-item-description-suffix'>
                                                    {locales.components.pages.billingCenter.dashboard.tenThousandHour}
                                                </div>
                                                <If condition={!enabledCallInspect}>
                                                    <div className='billing-center-index-page-dashboard-item-description-suffix'>
                                                        {locales.components.pages.billingCenter.dashboard.isClosed}
                                                    </div>
                                                </If>
                                            </If>

                                            {/* <div className='billing-center-index-page-dashboard-item-description-quota'>
                                        {surplus}
                                    </div>
                                    <div className='billing-center-index-page-dashboard-item-description-suffix'>
                                        {locales.components.pages.billingCenter.dashboard.hour}
                                    </div> */}
                                        </div>
                                        <div className='billing-center-index-page-dashboard-item-right' style={{ color: enabledImInspect && isWarning ? warningColor : normalColor }}>
                                            <div className='billing-center-index-page-dashboard-item-icon billing-center-index-page-left'>
                                                <span class="udesk-qa-react-web-iconfont billing-center-index-page-text">&#xe6ed;</span>
                                            </div>
                                            <div className='billing-center-index-page-dashboard-item-description-title'>
                                                {isSalesSubApp() ? locales.components.pages.billingCenter.dashboard.text : locales.components.pages.billingCenter.dashboard.textQuality}
                                            </div>
                                            <div className='billing-center-index-page-dashboard-item-description-quota'>
                                                {deadline}
                                            </div>
                                            <div className='billing-center-index-page-dashboard-item-description-suffix'>
                                                {locales.components.pages.billingCenter.dashboard.cutoff}
                                            </div>
                                            <If condition={!enabledImInspect}>
                                                <div className='billing-center-index-page-dashboard-item-description-suffix'>
                                                    {locales.components.pages.billingCenter.dashboard.isClosed}
                                                </div>
                                            </If>
                                        </div>
                                    </div>

                                </div>
                                <div className='billing-center-index-page-dashboard-quotaes billing-center-index-page-right'>
                                    <div className='billing-center-index-page-dashboard-title'>
                                        {locales.components.pages.billingCenter.dashboard.usedQuota}
                                    </div>
                                    <div className='billing-center-index-page-dashboard-item-cover'>
                                        <div className='billing-center-index-page-dashboard-item-left'>
                                            <div className='billing-center-index-page-dashboard-item-icon billing-center-index-page-left'>
                                                <span class="udesk-qa-react-web-iconfont billing-center-index-page-call">&#xe6e9;</span>
                                            </div>
                                            <div className='billing-center-index-page-dashboard-item-description-title'>
                                                {isSalesSubApp() ? locales.components.pages.billingCenter.dashboard.voice : locales.components.pages.billingCenter.dashboard.voiceQuality}
                                            </div>
                                            <If condition={callUse <= 9999}>
                                                <div className='billing-center-index-page-dashboard-item-description-quota'>
                                                    {callUse}
                                                </div>
                                                <div className='billing-center-index-page-dashboard-item-description-suffix'>
                                                    {locales.components.pages.billingCenter.dashboard.hour}
                                                </div>
                                            </If>
                                            <If condition={callUse > 9999}>
                                                {/* else */}
                                                <div className='billing-center-index-page-dashboard-item-description-quota'>
                                                    {(callUse / 10000).toFixed(1)}
                                                </div>
                                                <div className='billing-center-index-page-dashboard-item-description-suffix'>
                                                    {locales.components.pages.billingCenter.dashboard.tenThousandHour}
                                                </div>
                                            </If>
                                        </div>
                                        <div className='billing-center-index-page-dashboard-item-right'>
                                            <div className='billing-center-index-page-dashboard-item-icon billing-center-index-page-left'>
                                                <span class="udesk-qa-react-web-iconfont billing-center-index-page-text">&#xe6ed;</span>
                                            </div>
                                            <div className='billing-center-index-page-dashboard-item-description-title'>
                                                {isSalesSubApp() ? locales.components.pages.billingCenter.dashboard.text : locales.components.pages.billingCenter.dashboard.textQuality}
                                            </div>
                                            <div className='billing-center-index-page-dashboard-item-description-quota'>
                                                {textUse}
                                            </div>
                                            <div className='billing-center-index-page-dashboard-item-description-suffix'>
                                                {locales.components.pages.billingCenter.dashboard.day}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='billing-center-index-page-table'>
                                <div className='billing-center-index-page-table-time-filter'>
                                    <div className='billing-center-index-page-table-time-filter-description'>
                                        {locales.components.pages.billingCenter.table.timeFilter}
                                    </div>
                                    <div className='billing-center-index-page-table-time-filter-checkbox'>
                                        <UdeskDatePicker onChange={actions.checkTime} value={[startTime, endTime]} allowClear={false} />
                                    </div>
                                    <div className='search-btn'>
                                        <Button type="primary" onClick={actions.getSearchData}>{locales.components.pages.billingCenter.table.search}</Button>
                                        <Button onClick={actions.resetSearchData}>{locales.components.pages.billingCenter.table.reset}</Button>
                                    </div>
                                    <div style={{ float: 'right',height: 32,lineHeight: '32px'}}>
                                        <Icon type='WarningFilled' antdIcon={true} style={{color: '#1b6dff', marginRight: 8}}></Icon>
                                        {locales.components.pages.billingCenter.tips}
                                    </div>
                                </div>
                                <div className='billing-center-index-page-table-content'>
                                    <AutoSizeTable subtractedHeight={144} dataSource={data} pagination={false} onExpand={actions.onExpand} expandedRowKeys={privates.expandedRowKeys} rowKey={(record) => record.id}>
                                        <Column title={locales.components.pages.billingCenter.table.id} dataIndex='flowNo' key='flowNo' width='263px' />
                                        <Column title={locales.components.pages.billingCenter.table.type} dataIndex="flowTypeName" key="flowTypeName" />
                                        {
                                            isQaSubApp() && <Column title={locales.components.pages.billingCenter.table.qualityType} dataIndex="inspectTypeName" key="inspectTypeName" />
                                        }
                                        <Column title={locales.components.pages.billingCenter.table.callTime} dataIndex="callTime" key="callTime" width='124px' />
                                        <Column title={locales.components.pages.billingCenter.table.count} dataIndex="count" key="count" />
                                        <Column title={locales.components.pages.billingCenter.table.quotaChange} dataIndex="change" key="change" />
                                        <Column title={locales.components.pages.billingCenter.table.surplus} dataIndex="balance" key="balance" />
                                        <Column title={locales.components.pages.billingCenter.table.changeTime} dataIndex="dataTime" key="dataTime" />
                                    </AutoSizeTable>
                                </div>
                                <If condition={total > 0}>
                                    <div className='billing-center-index-page-table-size-changer'>
                                        {/* <div className='billing-center-index-page-table-size-changer-text'>
                                            {locales.components.pages.billingCenter.table.sizeChangeText}
                                        </div> */}
                                        <Pagination
                                            showSizeChanger
                                            current={pageNum}
                                            pageSize={pageSize}
                                            pageSizeOptions={['5', '10', '20', '50', '100']}
                                            showTotal={actions.showTotal}
                                            onShowSizeChange={actions.onShowSizeChange}
                                            defaultCurrent={1}
                                            total={total}
                                            onChange={actions.pageChange}
                                        />
                                    </div>
                                </If>
                            </div>
                            <Modal
                                visible={visibleLimitEdit}
                                title={locales.components.pages.billingCenter.limit}
                                getContainer={false}
                                onOk={actions.handleOk}
                                onCancel={actions.handleCancel}
                                okText={locales.labels.save}
                                cancelText={locales.labels.cancel}
                                closable
                            >
                                <div style={{marginBottom: 16}}>
                                    <Space>
                                        <div>{locales.components.pages.billingCenter.limitModal.call}</div>
                                        <InputNumber value={callLimit} min={0} onChange={actions.onLimitValueChange.params('callLimit')} />
                                        <div>{locales.components.pages.billingCenter.limitModal.callFix}</div>
                                    </Space>
                                </div>
                                <div>
                                    <Space>
                                        <div>{locales.components.pages.billingCenter.limitModal.dialogue}</div>
                                        <InputNumber value={dialogueLimit} min={0} onChange={actions.onLimitValueChange.params('dialogueLimit')}/>
                                        <div>{locales.components.pages.billingCenter.limitModal.dialogueFix}</div>
                                    </Space>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}