import React from 'react';
import SynonymWordsComponent from './component';
import ReactModal from 'udesk-react/src/components/react-modal';
import ReactSelect from 'udesk-react/src/components/react-select';
import Udesk from 'Udesk';
// import classNames from 'classnames';
import SmartWordsLibraryCommonList from '../smart-words-library-common-list';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'udesk-ui';

export default class SynonymWordsTemplate extends SynonymWordsComponent {
    render() {

        let {
            locales,
            actions
        } = this;

        let {
            columns,
            isSynonymWordsModalShow,
            synonymWordsModalTitle,
            standardWords,
            effectiveness,
        } = this.privates;

        let {
            getFieldDecorator
        } = this.props.form;

        let corporaType = Udesk.enums.smartWordsLibraryMenus.synonymWords.id;

        return (
            <div>
                <SmartWordsLibraryCommonList
                    columns={columns}
                    navItemType={corporaType}
                    getlistDataPath='corpora/commonList/7'
                    openAddModal={actions.createSynonymWords}
                    uploadModalTitle={locales.components.pages.smartWordsLibrary.upload.uploadSynonymWords}
                    onCreated={(instance) => { this.privates.smartWordsLibraryListInstance = instance; }}
                    boardName={Udesk.enums.smartWordsLibraryBoard.synonymWords.id}
                    buttons={['add']}
                />

                <ReactModal cancelText={locales.labels.cancel}
                    visible={isSynonymWordsModalShow}
                    title={synonymWordsModalTitle}
                    headerClassName="udesk-qa-smart-words-library-modal-header"
                    footerClassName="udesk-qa-smart-words-library-modal-footer"
                    okText={locales.labels.save}
                    onOk={actions.saveSynonymWords}
                    onCancel={actions.closeSynonymWordsModal}
                >
                    <Form onSubmit={actions.saveSynonymWords} layout="horizontal" className="synonym-words-modal-form smart-words-library-nav-item-form">

                        <Form.Item
                            label={/* 敏感词 */locales.pages.semanticIntelligence.smartWordsLibrary.sensitiveWords.template.sensitiveWords}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 18 }}
                        >
                            {getFieldDecorator('corporaName', {
                                initialValue: standardWords,
                                rules: [{
                                    required: true,
                                    message: /* 敏感词不能为空 */locales.pages.semanticIntelligence.smartWordsLibrary.sensitiveWords.template.sensitiveWordsCannotBeEmpty
                                }]
                            })(
                                <Input
                                    className="synonym-words-login-form-input"
                                    placeholder={/* 请输入敏感词，多个敏感词中间用‘,’隔开 */locales.pages.semanticIntelligence.smartWordsLibrary.sensitiveWords.template.pleaseEnterSensitiveWordsSeparatedBy}
                                />,
                            )}
                        </Form.Item>

                        {/* <Form.Item style={{display: 'none'}}>
                            {getFieldDecorator('synonymWords', {
                                rules: [{
                                    required: true,
                                    message: ''
                                }]
                            })(<span></span>)}
                        </Form.Item> */}
                        <Form.Item
                            label={locales.fields.smartWordsLibrary.synonymWords.effectiveness}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 18 }}
                        >
                            {getFieldDecorator('corporaStatus', {
                                initialValue: effectiveness.toString()
                            })(
                                <ReactSelect
                                    classNames="udesk-qa-smart-words-library-react-select"
                                    hasNullSelect={false}
                                    dataScource={Udesk.enums.wordsEffectiveness}
                                    onChanged={actions.changeSynonymWordsEffectiveness}
                                >
                                </ReactSelect>
                            )}

                        </Form.Item>

                    </Form>

                </ReactModal>

            </div>
        );
    }
}