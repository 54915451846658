import Udesk from 'Udesk';
import { useCallback, useState } from 'react';
import { DEFAULT_DATE_TIME_FORMAT, getDateTimeString } from 'src/util/moment';
import { createMessage } from '../../../util';
import { usePassThroughParams } from '../../..';
import { useDebounce } from 'src/util/hook';
import { useAnswerTimeout, useStartSecond, useSuspend, useTaskFlow } from '../../hook';
import { isBoolean } from 'src/util/core';
import moment from 'moment';

const updatePassThroughParams = (passThroughParams, item) => {
    $.extend(passThroughParams, {
        bindNodeId: item.bindNodeId, // 节点ID ，后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionClosely: item.questionClosely, // 是否未追问 0: 否 1: 是,后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionCloselyWordsIndex: item.questionCloselyWordsIndex, // 追问索引句子, 后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionIdList: item.questionIdList, // 已经回答的知识库节点的问答id, 后续交互作为参数传入，如果返回多个，取最后一个节点的
        hasAnswerFinishKnowledge: item.hasAnswerFinishKnowledge, // 知识库节点是否已经回答完毕// 后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionCloselyId: item.questionCloselyId, // 追问增加的id
    });
};

export const useChatRecord = (props: any) => {
    const {request, task, record, setRecord} = props;
    const [isStartTask, setIsStartTask] = useState<boolean>(false);
    const [isLastNode, setIsLastNode] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [list, setList] = useState<any[]>([]);
    const [getPassThroughParams, setPassThroughParams, passThroughParams] = usePassThroughParams();
    const [initStartSecond, newStartSecond] = useStartSecond();

    const [globalConfig, setGlobalConfig] = useState<any>(null);
    const [referenceWords, setReferenceWords] = useState('');

    const [loadingForTaskStart, setLoadingForTaskStart] = useState(false);
    const [loadingForSendMessage, setLoadingForSendMessage] = useState(false);
    const {flowIndex, flowData, getFlowData }= useTaskFlow(request, task, record);

    const {
        suspendDetail,
        isSuspend, isSuspending, isLoadSuspendInfo, 
        loadingForSuspend, loadingForRestart, 
        pauseTask, continueTask, restartTask,
    } = useSuspend(request, task, record, setRecord);

    useAnswerTimeout(globalConfig, data, isSuspending, 0);

    /**
     * 针对单题限时使用，我们在没有交互前会自动生成一个缓存数据，此数据不在list中
     * 只有在语音对话开始录音或者文本对话发送消息时才会被添加到列表中去
     */
    const createNextMessage = useCallback((isLastNode) => {
        if (isLastNode) {
            setData(undefined);
        } else {
            const data = createMessage({
                isSync: 0,
            });
            data.isNotInList = true;
            data.isTimeout = false;
            setData(data);
        }
    }, []);

    const updateDataInfo = useCallback((data, info) => {
        setList(list => {
            return list.map(item => {
                if (item.id === data.id) {
                    return $.extend(true, {}, item, info);
                }

                return item;
            });
        });
    }, []);

    const sendMessage = useDebounce((data: any, fileInfo?) => {
        const params = getPassThroughParams({
            taskId: task?.id, // 任务ID
            dialogueMode: task?.dialogueMode, // 对话类型 1-语音 2-文本 3-ppt
            words: data.content, // 用户说的话，如果是语音，需转化为文字
            speakDuration: fileInfo?.duration, // 说话时长 毫秒
            speakTime: data.createDate, // 说话时间
            ossKey: fileInfo?.url, // 语音上传的地址
            isTimeout: data.isTimeout, // 是否超时
        });
        request(`/intelligentPartner/${record?.id}/interactive`, params, 'post').then(
            resp => {
                const {
                    nextNodeList, traineeWordsResult
                } = resp.data;
                const newPassThroughParams = {
                    flowId: passThroughParams.flowId, // 对话流程ID，后续交互需要作为参数传入
                };

                let isLastNode = false;
                let newList = [];
                if (nextNodeList) {
                    newList = nextNodeList.map((item, index) => {
                        // 如果发现机器人对话为最后一个阶段，将自动触发后续的逻辑
                        isLastNode = !!item.isLastNode;
                        // 更新透传参数
                        if (index === nextNodeList.length - 1) {
                            updatePassThroughParams(newPassThroughParams, item);
                        }
                        return createMessage({
                            channel: 1,
                            nodeType: item.nodeType,
                            content: item.words,
                            ossKey: item.ossKey,
                            startSecond: newStartSecond(),
                        });
                    });
                } else {
                    isLastNode = true;
                }
                setList(list => {
                    if (traineeWordsResult) {
                        list[list.length - 1]['traineeWordsResult'] = traineeWordsResult;
                    }
                    return new Array<any>().concat(list, newList);
                });
                if (isLastNode) {
                    setIsLastNode(isLastNode);
                }
                createNextMessage(isLastNode);
                setReferenceWords(resp.data?.referenceWords || ''); // 重置推荐话术
                setPassThroughParams(newPassThroughParams);
                updateDataInfo(data, {
                    isSync: 2
                });
                getFlowData();
            },
            reason => {
                updateDataInfo(data, {
                    isSync: 0
                });
                Udesk.ui.notify.error(reason.errorMsg);
            }
        ).finally(() => {
            setLoadingForSendMessage(false);
        });
    }, [
        task?.id, 
        task?.dialogueMode, 
        request, 
        record?.id, 
        createNextMessage
    ]);

    const submitMessageHandle = useCallback(content => {
        const isTimeout = isBoolean(content) ? content : false;
        if (data) {
            data.startSecond = newStartSecond();
            data.isSync = 1;

            if (isBoolean(content)) {
                data.isTimeout = isTimeout;
            } else {
                data.content = content;
            }

            if (data.isNotInList) {
                data.isNotInList = false;
                setList(list => {
                    const newList = [...list];
                    const index = list.findIndex(item => item.id === data.id);
        
                    if (index > -1) {
                        newList.splice(index, 1, data);
                    } else {
                        newList.push(data);
                    }
                    return newList;
                });
            }
            sendMessage(data);
        }
    }, [data, newStartSecond, sendMessage]);

    // 开始任务
    const startTaskHandle= useCallback(() => {
        if (task) {
            setLoadingForTaskStart(true);
            if (isSuspend) {
                continueTask((data) => {
                    const {dialogue, lastNodeDetail, globalConfig} = data;
                    const list = new Array<any>();
                    if (dialogue) {
                        list.splice(0, 0, ...dialogue.map(item => {
                            const {
                                channel, nodeType, content, ossKey, startSecond,
                                traineeWordsResult, isTimeout
                            } = item;
                            const message = createMessage({
                                channel,
                                nodeType,
                                content,
                                ossKey,
                                startSecond,
                            });
                            message.isTimeout = isTimeout;
                            if (traineeWordsResult) {
                                message.isSync= 2;
                                message.traineeWordsResult = {
                                    resultAnalysis: traineeWordsResult
                                };
                            }
                            return message;
                        }));
                    }
                    setList(list);
                    if (lastNodeDetail) {
                        setPassThroughParams(lastNodeDetail);
                        setReferenceWords(lastNodeDetail.nextProcessNodeWords || ''); // 重置推荐话术
                    }
                    if (globalConfig) {
                        setGlobalConfig(globalConfig);
                    }
                    setIsStartTask(true); //这里标记开始录音
                    getFlowData();
                    let start;
                    setRecord(record => {
                        const {id, costTime} = record;
                        start = moment().subtract('seconds', costTime);
                        return {
                            id, dialogueStartTime: start.format(DEFAULT_DATE_TIME_FORMAT)
                        };
                    });
                    if (start) {
                        initStartSecond(start.toDate().getTime()); // 初始化任务开始时间
                    }
                    createNextMessage(false);
                }).finally(() => {
                    setLoadingForTaskStart(false);
                });
            } else {
                request('/intelligentPartner/start', {
                    taskId: task.id,
                    taskType: task.taskType,
                    dialogueMode: Udesk.enums.learningTaskDialogueModeType.text.id,
                    time: getDateTimeString(),
                    lessonId: task.courseId
                }, 'post').then(
                    resp => {
                        const globalConfig = resp.data?.trainingWords?.globalConfig;
                        const nextNodeList = resp.data?.trainingWords?.nextNodeList;
                        const passThroughParams = {
                            flowId: resp.data?.flowId, // 对话流程ID，后续交互需要作为参数传入
                        };
                        if (globalConfig) {
                            setGlobalConfig(globalConfig);
                        }
                        if (nextNodeList) {
                            const list = nextNodeList.map((item, index) => {
                                // 更新透传参数
                                if (index === nextNodeList.length - 1) {
                                    updatePassThroughParams(passThroughParams, item);
                                }
    
                                return createMessage({
                                    channel: 1,
                                    nodeType: item.nodeType,
                                    content: item.words,
                                    ossKey: item.ossKey,
                                    startSecond: 0,
                                });
                            });
    
                            setList(list);
                        }
    
                        setReferenceWords(resp.data?.trainingWords?.referenceWords || ''); // 重置推荐话术
                        setIsStartTask(true); //这里标记开始录音
                        setPassThroughParams(passThroughParams);
                        setRecord(resp.data?.record);
                        initStartSecond(); // 初始化任务开始时间
                        createNextMessage(false);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                ).finally(() => {
                    setLoadingForTaskStart(false);
                });
            }
        }
    }, [
        continueTask, 
        getFlowData, 
        initStartSecond, 
        isSuspend, 
        request, 
        setPassThroughParams, 
        setRecord, 
        task,
        createNextMessage
    ]);

    return {
        nextMessage: data, 
        flowIndex, flowData,
        globalConfig,
        referenceWords,
        loadingForSendMessage,
        loadingForTaskStart, // 任务开始的loading
        passThroughParams,
        record,
        setRecord,
        isStartTask,
        isLastNode,
        list,
        startTaskHandle,
        submitMessageHandle,

        suspendDetail,
        isSuspend, isSuspending, isLoadSuspendInfo, 
        loadingForSuspend, loadingForRestart, 
        pauseTask, continueTask, restartTask,
    };
};