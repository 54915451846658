import Udesk from 'Udesk';
import React, { useImperativeHandle } from 'react';
import styled from 'styled-components';
import Text from './Text';
import Avatar from './Avatar';
import { IntelligentPartnerSentence } from 'src/api/types';
import { PlayBtn } from './PlayBtn';
import { fire } from 'src/util/core';
import UdeskLocales from 'UdeskLocales';

const UserNodeWrap = styled.div`
    display: flex;
    justify-content: ${props => props.align ==='right' ? 'flex-end' : 'flex-start'};

    & ::selection {
        background-color: rgba(199, 237, 206);
        color: rgba(0, 0, 0, 0.85);
    }
    .udesk-coach-user-node-left {
        /* flex: 1; */
        max-width: 70%;
        margin: ${props => props.align ==='right' ? '0 12px 0 0' : '0 0 0 12px'};

        .udesk-coach-user-node-title {
            color: rgba(0, 0, 0, 0.65);
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 8px;
            text-align: ${props => props.align ==='right' ? 'end' : 'start'};
        }
        .udesk-coach-user-node-content {
            background: #1a6eff;
            border-radius: 4px 0px 4px 4px;
            padding: 10px 12px;
            color: #fff;
            font-size: 14px;
            line-height: 22px;
            display: inline-flex;
            float: right
        }
        .udesk-coach-user-node-footer {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            padding: 2px 8px;
            color: rgba(0, 0, 0, 0.45);
            clear: both;
        }
    }

    .udesk-qa-ui-avatar {
        width: 36px;
        height: 36px;
    }
`;

type UserNodeProps = IntelligentPartnerSentence & { 
    index: number; 
    align: 'right' | 'left'; 
    isTimeout: boolean;
    extra: any;
};

export const FormatDate = (props) => {
    return <span>{Udesk.utils.date.formatDurationWithMillisecond((props.startSecond || 0) / 1000)}</span>;
};

export const UserNode = React.memo(
    React.forwardRef((props: UserNodeProps, ref) => {
        const {align, extra, ...otherProps} = props;

        useImperativeHandle(ref, () => {
            return {};
        });

        return (
            <UserNodeWrap align={align}>
                {
                    align === 'left' ? <Avatar align={align}/>  : null
                }
                <div className="udesk-coach-user-node-left">
                    <div className="udesk-coach-user-node-title">
                        <FormatDate startSecond={props.startSecond} />
                    </div>
                    {
                        props.isTimeout ? (
                            <>
                                {
                                    props.content ? (
                                        <div 
                                            className="udesk-coach-user-node-content"                                     
                                            style={{
                                                marginBottom: props.content ? 8 : 0,
                                            }}
                                        >
                                            <Text>{props.content}</Text>
                                            <PlayBtn url={props.ossKey} position={align} />
                                        </div>
                                    ) : null
                                }
                                <div className="udesk-coach-user-node-footer">{/* 本题作答时间超时，自动提交作答内容 */}{UdeskLocales['current'].components.coach.dialogue.components.userNode.theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted}</div>
                            </>
                        ) : (
                            [
                                fire(extra, otherProps) || null,
                                <div className="udesk-coach-user-node-content">
                                    <Text>{props.content}</Text>
                                    <PlayBtn url={props.ossKey} position={align} />
                                </div>
                            ]
                        )
                    }
                </div>
                {
                    align === 'right' ? <Avatar align={align}/>  : null
                }
            </UserNodeWrap>
        );
    })
);
