import React, { useMemo } from 'react';
import Udesk from 'Udesk';
import styled from 'styled-components';
import { Button, Icon, Radio, Select } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';
import { FlowNodeListModal, useFlowNodeListModal } from './FlowNodeListModal/index';
import { FlowModalProvider } from './FlowNodeListModal/Context';

const colorMap = {
    JUDGE_NODE: '#F09A00',
    DIALOG_NODE: '#1A6EFF',
};

const Wrap = styled.div`
    padding: 4px 12px 0;
    background-color: #f5f6f8;
    position: relative;
    margin: 0 16px 8px;
    border-radius: 4px;

    > div:first-child {
        &::before {
            margin-top: 7px;
        }
    }
    > div:nth-last-child(2) {
        &::before {
            margin-bottom: 8px;
        }
    }
`;
const ItemWrap = styled.div`
    padding: 8px 0px 8px 12px;
    display: flex;
    /* justify-content: space-between; */
    /* &::after {
            content: ' ';
        } */
    &::before {
        content: ' ';
        width: 2px;
        background: rgba(0, 0, 0, 0.1);
        margin-right: -5px;
        margin-top: -2px;
        margin-bottom: -15px;
    }
    .check-point-flow-node-item-node-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
    }
`;

const Badge = styled.div`
    width: 8px;
    height: 8px;
    background-color: ${(props) => colorMap[props.nodeType] ?? 'rgba(5, 5, 5, 0.06)'};
    align-self: center;
    border-radius: 50%;
    margin-right: 8px;
`;

export const FlowNodeList = (props) => {
    const { canvasNodeMatchResultList, hitCheckPointSelected, onNodeChange, disabled } = props;
    const { changeModalVisible, ...modalProps } = useFlowNodeListModal();

    const nodeBranchesMap = useMemo(() => {
        if (!Array.isArray(canvasNodeMatchResultList)) return {};

        const indexIdMap = getIndexIdMap(canvasNodeMatchResultList);

        return canvasNodeMatchResultList.reduce((p, c) => {
            if (c.nodeType === 'BRANCH') {
                // const [pIndex] = c.preIndexList;
                c.preIndexList?.forEach((pIndex) => {
                    if (!p[indexIdMap[pIndex]]) {
                        p[indexIdMap[pIndex]] = [c];
                    } else {
                        p[indexIdMap[pIndex]].push(c);
                    }
                });
            }

            return p;
        }, {});
    }, [canvasNodeMatchResultList]);

    const indexNodeMap = useMemo(
        () => getIndexNodeMap(canvasNodeMatchResultList),
        [canvasNodeMatchResultList]
    );

    const showNodeIndexMap = useMemo(
        () => getShowNodeIndexMap(canvasNodeMatchResultList, nodeBranchesMap, indexNodeMap),
        [canvasNodeMatchResultList, nodeBranchesMap, indexNodeMap]
    );

    const onBranchChange = (childrenNodes, value) => {
        // const indexNodeMap = getIndexNodeMap(canvasNodeMatchResultList);
        // const hiddenNodeIndexList = Object.keys(showNodeIndexMap).filter(
        //     (key) => !showNodeIndexMap[key]
        // );
        // const wipShowNodeIndexMap = getShowNodeIndexMap(canvasNodeMatchResultList, nodeBranchesMap);

        let parentNode: any = null;
        const newCanvasNodeMatchResultList = canvasNodeMatchResultList.map((node) => {
            // 先判断是不是子节点，根据UI选择的结果判断是否命中
            if (childrenNodes.find((c) => node.nodeId === c.nodeId)) {
                parentNode = indexNodeMap[node.preIndexList[0]];
                return {
                    ...node,
                    isHit: value === node.nodeId,
                };
            }
            // 展示的非子节点 保持不变
            return node;
        });

        if (parentNode) {
            parentNode.isHit = true;
        }

        const newIndexNodeMap = getIndexNodeMap(newCanvasNodeMatchResultList);

        // 判断是不是 不该展示的节点，不展示的节点都要初始化成不命中的
        // 这里认为子节点下的节点都是不该展示的
        childrenNodes.forEach((child) => {
            const nodesWillReset: number[] = [].concat(child.nextIndexList).filter(Boolean);
            while (nodesWillReset.length) {
                const current = nodesWillReset.pop();
                if (typeof current !== 'undefined') {
                    // 通过引用关系重置isHit属性
                    newIndexNodeMap[current].isHit = false;

                    if (newIndexNodeMap[current].nextIndexList) {
                        Array.prototype.push.apply(
                            nodesWillReset,
                            newIndexNodeMap[current].nextIndexList
                        );
                    }
                }
            }
        });

        console.log('newCanvasNodeMatchResultList:: ', newCanvasNodeMatchResultList);
        onNodeChange(newCanvasNodeMatchResultList);
    };
    const onNodeRadioChange = (canvasNodeMatchResult, e) => {
        // const indexNodeMap = getIndexNodeMap(canvasNodeMatchResultList);
        // Object.keys(showNodeIndexMap)
        //     .filter((key) => !showNodeIndexMap[key])
        //     .forEach((hiddenNodeIndex) => {
        //         const node = indexNodeMap[hiddenNodeIndex];
        //         if (node) {
        //             console.log('node', node);

        //             onNodeChange(node, false);
        //         }
        //     });
        const newCanvasNodeMatchResultList = canvasNodeMatchResultList.map((node) => {
            if (canvasNodeMatchResult.nodeId === node.nodeId) {
                return {
                    ...node,
                    isHit: e.target.value,
                };
            } else {
                // 未展示的节点
                return node;
            }
        });

        const indexNodeMap = getIndexNodeMap(newCanvasNodeMatchResultList);

        const nodesWillReset: number[] = [].concat(canvasNodeMatchResult.nextIndexList).filter(Boolean);

        canvasNodeMatchResult.nextIndexList?.forEach((nextIdex) => {
            while (nodesWillReset.length) {
                const current = nodesWillReset.pop();
                if (typeof current !== 'undefined') {
                    // 通过引用关系重置isHit属性
                    indexNodeMap[current].isHit = false;

                    if (indexNodeMap[current].nextIndexList) {
                        Array.prototype.push.apply(
                            nodesWillReset,
                            indexNodeMap[current].nextIndexList
                        );
                    }
                }
            }
        });

        onNodeChange(newCanvasNodeMatchResultList, e);
    };
    console.log('canvasNodeMatchResultList111', canvasNodeMatchResultList);

    return (
        <Wrap className="check-point-flow-node-list">
            {Array.isArray(canvasNodeMatchResultList) ? (
                <>
                    {canvasNodeMatchResultList.map((canvasNodeMatchResult, index) => {
                        const childrenNodes = nodeBranchesMap[canvasNodeMatchResult.nodeId];
                        if (canvasNodeMatchResult.nodeType === 'BRANCH') return null;
                        if (!showNodeIndexMap[canvasNodeMatchResult.nodeIndex]) return null;

                        return (
                            <ItemWrap className="check-point-flow-node-item">
                                {canvasNodeMatchResult.highlightGroupList.length > 0 && (
                                    <Button
                                        type="text"
                                        size="small"
                                        icon={<Icon type="daohang"></Icon>}
                                        style={{ position: 'absolute', left: 0 }}
                                        onClick={hitCheckPointSelected.params(
                                            canvasNodeMatchResult
                                        )}
                                    ></Button>
                                )}
                                <Badge
                                    isHit={canvasNodeMatchResult.isHit}
                                    nodeType={canvasNodeMatchResult.nodeType}
                                    className="check-point-flow-node-item-badge"
                                ></Badge>
                                <div className="check-point-flow-node-item-node-name">
                                    {/* <HitIcon isHit={canvasNodeMatchResult.isHit} /> */}
                                    {/* {canvasNodeMatchResult.nodeIndex} */}
                                    {canvasNodeMatchResult.name}
                                </div>
                                <div style={{ marginLeft: 'auto', marginRight: 12 }}>
                                    {canvasNodeMatchResult.score
                                        ? `${canvasNodeMatchResult.scoreType === 2 ? '-' : '+'}${
                                            canvasNodeMatchResult.score
                                        }${/* 分 */UdeskLocales['current'].components.qualityScore.components.flowNodeList.branch}`
                                        : null}
                                </div>
                                {childrenNodes ? (
                                    <Select
                                        options={childrenNodes.map((i) => ({
                                            ...i,
                                            value: i.nodeId,
                                            label: i.name,
                                        }))}
                                        onChange={onBranchChange.bind(null, childrenNodes)}
                                        value={childrenNodes.find((n) => n.isHit)?.nodeId}
                                        size="small"
                                        style={{
                                            width: 92,
                                        }}
                                        allowClear={true}
                                        placeholder={/* 请选择 */UdeskLocales['current'].components.qualityScore.components.flowNodeList.pleaseSelect}
                                    />
                                ) : (
                                    <Radio.Group
                                        value={canvasNodeMatchResult.isHit}
                                        disabled={disabled}
                                        onChange={onNodeRadioChange.bind(
                                            null,
                                            canvasNodeMatchResult
                                        )}
                                    >
                                        {Udesk.enums.gradeChooseHits.map((item) => {
                                            return (
                                                <Radio
                                                    onClick={(e) => e.stopPropagation()}
                                                    value={Boolean(item.id)}
                                                >
                                                    {item.name}
                                                </Radio>
                                            );
                                        })}
                                    </Radio.Group>
                                )}
                            </ItemWrap>
                        );
                    })}
                    <Button
                        onClick={changeModalVisible}
                        block
                        type="link"
                        style={{
                            margin: '0 -12px',
                            width: 'calc(100% + 24px)',
                            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                            padding: 8,
                            height: 'auto',
                        }}
                    >{/* 查看完整流程 */}{UdeskLocales['current'].components.qualityScore.components.flowNodeList.viewTheCompleteProcess}</Button>
                    <FlowModalProvider
                        value={{
                            canvasNodeMatchResultList,
                            showNodeIndexMap,
                            onNodeChange,
                            indexNodeMap,
                            nodeBranchesMap,
                        }}
                    >
                        <FlowNodeListModal {...modalProps} />
                    </FlowModalProvider>
                </>
            ) : (
                <div style={{ paddingLeft: 24, color: 'rgba(0, 0, 0, .45)', fontSize: '12px' }}>
                    {/* 暂无流程 */}
                    {
                        UdeskLocales['current'].components.qualityScore.components.flowNodeList
                            .thereIsCurrentlyNoProcessAvailable
                    }
                </div>
            )}
        </Wrap>
    );
};

function getIndexIdMap(canvasNodeMatchResultList) {
    if (!Array.isArray(canvasNodeMatchResultList)) return {};

    return canvasNodeMatchResultList.reduce((p, c) => {
        p[c.nodeIndex] = c.nodeId;

        return p;
    }, {});
}
function getIndexNodeMap(canvasNodeMatchResultList) {
    if (!Array.isArray(canvasNodeMatchResultList)) return {};

    return canvasNodeMatchResultList.reduce(
        (p, c) => {
            p[c.nodeIndex] = c;

            return p;
        },
        { 1: { isHit: true } }
    );
}

function getShowNodeIndexMap(canvasNodeMatchResultList, nodeBranchesMap, indexNodeMap) {
    if (!Array.isArray(canvasNodeMatchResultList)) return {};

    // 1. 开始节点永远可以展示
    // 2. 待选择的节点可以展示
    // 如果待选择的节点有分支，其分支也可以展示
    const hitMap = canvasNodeMatchResultList.reduce(
        (p, c) => {
            p[c.nodeIndex] =
                c.isHit ||
                (Reflect.has(nodeBranchesMap, c.nodeId) &&
                    c.preIndexList.some((pre) => indexNodeMap[pre].isHit));
            return p;
        },
        { 1: true }
    );

    console.log('getShowNodeIndexMap nodeBranchesMap', nodeBranchesMap);
    console.log('getShowNodeIndexMap hitMap', hitMap);

    // const res = {};

    const res = Object.keys(hitMap).reduce(
        (res, c) => {
            if (hitMap[c]) {
                res[c] = true;
            } else {
                res[c] = indexNodeMap[c].preIndexList.some((pre) => hitMap[pre] || pre === 1);
            }
            return res;
        },
        { 1: true }
    );

    // canvasNodeMatchResultList.forEach((n) => {
    //     // let [prevNodeIndex] = n.preIndexList;
    //     n.preIndexList?.forEach((prevNodeIndex) => {
    //         res[n.nodeIndex] = [prevNodeIndex];
    //         while (res[prevNodeIndex]) {
    //             res[n.nodeIndex] = [].concat(res[prevNodeIndex], res[n.nodeIndex]);
    //             prevNodeIndex = res[prevNodeIndex][0];
    //         }
    //     });
    // });

    // Object.keys(res).forEach((n) => {
    //     res[n] = res[n].every((i) => hitMap[i]);
    // });
    // res['1'] = true;
    console.log('getShowNodeIndexMap res', res);

    return res;
}
