import React, { useState, useMemo, useEffect, useRef, useContext } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import Wave from 'Component/common/gong/wave-audio';
import Comment from 'Component/common/gong/comment-component';
import CommentReply from 'Component/common/gong/comment-reply';
import AddFileModal from 'Component/common/gong/files-open/modal-add-file';
import ChatRecord from 'Component/common/chat-record';
import HelpModal from 'Component/pages/components/gong/help-comment-modal';
import UserVisibleSelect from 'Component/pages/components/gong/user-visible-select';
import Supervision from 'Component/pages/components/gong/supervision';
import { Menu, Select, Input, Space, Button, Icon, Drawer, Empty, Dropdown, Tabs, Tooltip, Switch } from 'udesk-ui';
import useResizeObserver from 'use-resize-observer';
import { getReviewCallDataCallDetailById } from 'src/api/review/call/dataCall/detail/{id}';
import { postReplayCommentsShowAll } from 'src/api/replayComments/showAll';
import {
    ReplayCommentFoundResponse,
    PostReplayCommentsBody,
    LibraryCallCreateRequest,
    BaseResponseListTopicTagFoundResultResponse,
    BaseResponseListKeyEventFoundResultResponse,
    PostSeekHelpsBody,
    GetReviewCallDataCallDetailByIdQuery,
    DataCallDto,
} from 'src/api/types';
import { postReplayComments } from 'src/api/replayComments';
import { getUsers } from 'src/api/users';
import { deleteReplayCommentsById } from 'src/api/replayComments/{id}';
import './style.scss';
import { postLibraryCalls } from 'src/api/libraryCalls';
import ShareModal from 'Component/common/gong/share-modal';
import BusinessRecordsView from 'Component/pages/components/business-records-view';
import CallTextView from 'Component/pages/components/call-text-view';
import useSubscription from './hooks/subscription';
import { MsgCenterContext } from 'src/pages/home';
import { getTopicDetailsFindTopicByName } from 'src/api/topicDetails/findTopicByName';
import { getReviewCallByIdTopicTag } from 'src/api/review/call/{id}/topicTag';
import { getReviewCallByIdKeyEvent } from 'src/api/review/call/{id}/keyEvent';
// import _findLastIndex from 'lodash-es/findLastIndex';
import { postSeekHelps } from 'src/api/seekHelps';
import { postToDosGenLaterOnListener } from 'src/api/toDos/genLaterOnListener';
import moment from 'moment';
import SessionInsights from 'Component/pages/components/session-insights';
import styled from 'styled-components';
import { getReviewCallNextByIdByType } from 'src/api/review/call/next/{id}/{type}';
import { getReviewCallDataCallDetailByIdCustomerCalls } from 'src/api/review/call/dataCall/detail/{id}/customerCalls';
import { ListItem } from 'Component/pages/components/session-insights/components';
import CustomerInsight from 'Component/pages/components/gong/customer-insight';
import IntelligentRecommend from 'Component/pages/components/gong/intelligent-recommend';
import { postReviewCallAsrUpdate } from 'src/api/review/call/asr/update';
// import { getReviewCallByIdLibrary } from 'src/api/review/call/{id}/library';

const { TabPane } = Tabs;

let timer2: any = null;
const colorList = [
    '#5B8FF9',
    '#6DC9EC',
    '#FF9AC3',
    '#5AD8A6',
    '#FF9846',
    '#5B8FF9',
    '#6DC9EC',
    '#FF9AC3',
    '#5AD8A6',
    '#FF9846',
];

const useAudio: () => [HTMLAudioElement, number, React.Dispatch<React.SetStateAction<number>>] = () => {
    const [instance] = useState(new Audio());
    const [isPlaying, setIsPlaying] = useState<number>(-1);

    useEffect(() => {
        const cb = () => {
            setIsPlaying(-1);
        };
        instance.addEventListener('ended', cb);
        return () => {
            instance.removeEventListener('ended', cb);
        };
    }, []);

    return [instance, isPlaying, setIsPlaying];
};

const TabTitle = (props) => {
    const { title, icon } = props;
    return (
        <div className='tab-title-render' id={`udesk-qa-second-element-introduction-${icon}`}>
            <Tooltip placement='right' title={title}>
                <div>
                    <Icon type={icon} antdIcon={true} />
                </div>
            </Tooltip>
            {/* <div style={{
                width: '100%',
                overflowWrap: 'break-word',
                whiteSpace: 'break-spaces'
            }}>{title}</div> */}
        </div>
    );
};

const Handle = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    clip-path: polygon(0 0, 100% 12%, 100% 84%, 0% 100%);
    background-color: #fff;
    height: 48px;
    width: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
const RelatedCalls = styled.div`
    position: absolute;
    right: 50%;
    transform: translate(50%, -5px);
    display: flex;
    z-index: 1000;
    &:hover {
        div {
            transform: translateX(0) !important;
            opacity: 1 !important;
            &:hover {
                transform: translate(0, -18%) !important;
            }
        }
        .current-selected-related-call {
            transform: translate(0, -18%) !important;
            background: #1a6eff;
        }
        .export-selected-related-call {
            background: #bddeff;
        }
        .date-time-text {
            display: block;
            text-algin: center;
            transform: translate(0, -18%) !important;
        }
    }
`;
const RelatedCallItem = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    background: #5f9aff;
    border-radius: 20px;
    transform: ${(props) => props.transform};
    background-color: ${(props) => props.backgroundColor};
    transition: all 1s;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    // opacity: 0.3;
    opacity: ${(props) => props.opacity};
`;
const DateTimeText = styled.div`
    font-size: 12px;
    color: ${(props) => props.color};

    transform: ${(props) => props.transform};
    // text-align:center;

    display: none;
    position: relative;
    right: 3px;
    top: 0;
`;
const RelatedWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: all 1s;
`;

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const rootCallId = parseInt(props.match.params.callId ?? 0, 10);
    const [callId, setCallId] = useState<number>(rootCallId);
    const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    const msgNoticeCountData = useContext(MsgCenterContext);
    // 剪辑功能---是否显示复选框
    const [showCheckbox, setShowCheckbox] = useState(false);
    // 剪辑功能---控制工具条是否显示
    const [showCutFragment, setShowCutFragment] = useState(false);

    const [detail, setDetail] = useState<DataCallDto | null>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const { audioInstance, canPlay, currentTime, isPlaying, changeAudioPlayingStatus, startPlay, setCurrentTime } =
        Wave.useAudio(detail?.voiceUrl, detail?.fragmentStartTime, detail?.fragmentEndTime, detail?.id);
    /**
     * @dialogSentenceList 格式化对话数据
     */
    const [dialogSentenceList, setDialogSentenceList] = useState<any[]>([]);
    /**
     *
     * @sentenceIndex 当前播放的句子索引
     */
    const [sentenceIndex, setSentenceIndex] = useState<number | null>(null);
    const timeRangeList = useMemo(() => {
        return (
            detail?.dialogSentenceList?.map((item) => {
                return [item.fromSec, item.endSec];
            }) ?? []
        );
    }, [detail?.dialogSentenceList]);
    // const chatRecordWrapRef = useRef(null);
    const [role, setRole] = useState<-1 | 0 | 1>(props?.location?.state?.role ?? -1);
    const [keyword, setKeyword] = useState<string>(props?.location?.state?.keyword ?? '');
    const [comments, setComments] = useState<ReplayCommentFoundResponse[]>([]);
    const [highlightItemList, setHighlightItemList] = useState<any[]>([]);
    const [allHighlightPoints, setAllHighlightPoints] = useState<any[]>([]);
    // const [allHighlightPointsWithSupervision, setAllHighlightPointsWithSupervision] = useState<any[]>([]);
    const [currentHighLightPointOrSmartKey] = useState<any>(null);
    const [userList, setUserList] = useState<any[]>([]);
    const [authority, setAuthority] = useState<0 | 1 | 2>(Udesk.enums.commentVisibleAuthority.all.id);
    const [selectTime, setSelectTime] = useState<number | null>(null);
    const [selectIndex, setSelectIndex] = useState<number | null>(null);
    const [authorityUser, setAuthorityUser] = useState<any[]>([]);
    const [helpModalVisible, setHelpModalVisible] = useState<boolean>(false);
    const [addCaseBaseModal, setAddCaseBaseModal] = useState<boolean>(false);
    const [isShareModal, setIsShareModal] = useState<boolean>(false);
    const [shareType, setShareType] = useState('');
    const [subscriptionLoading, subscriptionChecked, onSubscriptionChange] = useSubscription({ callId });
    const [topicsList, setTopicsList] = useState<any[]>([]);
    const [sessioLabel, setSessioLabel] = useState<any[]>([]);
    const [keyEvent, setKeyEvent] = useState<any[]>([]);
    const [isDebug, setIsDebug] = useState<boolean>(false);
    // 案列片段
    // const [caseFragment, /* setCaseFragment */] = useState<any[]>([
    //     {
    //         categoryColor: '#001eff',
    //         categoryId: 240,
    //         categoryName: '关键事件1',
    //         companyId: 5,
    //         id: 4480,
    //         sentencesIndices: null,
    //         tagDesc: '',
    //         tagName: '关键事件测试',
    //     },
    //     {
    //         categoryColor: '#0FFecc',
    //         categoryId: 240,
    //         categoryName: '关键事件1',
    //         companyId: 5,
    //         id: 5580,
    //         sentencesIndices: null,
    //         tagDesc: '',
    //         tagName: '价格晚会优秀话术',
    //     },
    // ]);
    const [openLeft, setOpenLeft] = useState<boolean>(true);
    const [recentCalls, setRecentCalls] = useState<any[]>([]);
    const [helpTime, setHelpTime] = useState<number>(0);
    const [supervisionInspectionDetail, setSupervisionInspectionDetail] = useState<any>({});

    useEffect(() => {
        if (currentTime === 0) {
            setSentenceIndex(null);
            return;
        }
        // if (!autoStepToNext) {
        //     currentTime === 0 && setSentenceIndex(null);
        //     return;
        // }
        // let sentenceIndex = _findLastIndex(timeRangeList, (range) => {
        //     return range[1] > currentTime && currentTime >= range[0];
        // });
        let sentenceIndex = timeRangeList.findIndex((range) => {
            return range[0] && range[1] && range[1] > currentTime && currentTime >= range[0];
        });
        // timeRangeList.some((range, index) => {
        //     sentenceIndex = index;
        //     return range[1] > currentTime && currentTime >= range[0];
        // });

        currentTime && sentenceIndex >= 0 && setSentenceIndex(sentenceIndex);
        currentTime === 0 && setSentenceIndex(null);
    }, [currentTime, timeRangeList]);
    useEffect(() => {
        let libraryCallId = getQueryVariable('libraryCallId');
        const params: GetReviewCallDataCallDetailByIdQuery = {
            role,
            key: keyword,
        };
        if (libraryCallId) {
            params.libraryCallId = parseInt(getQueryVariable('libraryCallId'), 10);
        }
        getReviewCallDataCallDetailById({
            // params: {
            //     taskId: 530,
            // },
            segments: {
                id: callId,
            },
            params,
        }).then((resp) => {
            const insightInfo = resp.data?.insightInfo ?? {};
            const dialogSentenceList = resp.data?.dialogSentenceList || [];
            setDetail(
                resp.data
                    ? {
                        ...resp.data,
                        insightInfo: {
                            ...insightInfo,
                            agentQuestionIndices: insightInfo.agentQuestionIndices?.map(
                                (indice) => dialogSentenceList[indice]
                            ) as any,
                            customerQuestionIndices: insightInfo.customerQuestionIndices?.map(
                                (indice) => dialogSentenceList[indice]
                            ) as any,
                        },
                        keyEvents: (resp.data?.keyEvents ?? []).map((e) => {
                            return Object.assign(
                                {},
                                e,
                                typeof e.sentencesIndices?.[0] === 'number'
                                    ? dialogSentenceList[e.sentencesIndices[0] - 1] ?? {}
                                    : {}
                            );
                        }),
                    }
                    : null
            );

            if (resp.data?.dialogSentenceList?.length) {
                setHighlightItemList(
                    (resp.data?.highlightItemList ?? []).map((item) => {
                        return {
                            ...item,
                            ...(resp.data?.dialogSentenceList?.[(item.index ?? 1) - 1] ?? {}),
                        };
                    })
                );

                setAllHighlightPoints(
                    (resp.data?.highlightItemList ?? []).map((item, index) => {
                        return {
                            highlightGroupsList: [
                                {
                                    ...item,
                                    groupIndex: index,
                                    itemIndex: index,
                                    parentId: 'searchItem',
                                    pointId: index,
                                },
                            ],
                        };
                    })
                );
                let from: any = null;
                let to: any = null;
                if (libraryCallId && resp.data?.fragmentStartTime && resp.data?.fragmentEndTime) {
                    let startSecList: any = [];
                    let endSecList: any = [];
                    let fragmentStart = parseInt(resp.data?.fragmentStartTime, 10);
                    let fragmentEnd = parseInt(resp.data?.fragmentEndTime, 10);
                    resp.data?.dialogSentenceList?.forEach((item, idx) => {
                        if (item.fromSec! < fragmentStart && fragmentStart < item.endSec!) {
                            from = idx;
                        } else if (fragmentStart <= item.fromSec! && item.fromSec! <= fragmentEnd) {
                            startSecList.push(idx);
                            if (startSecList.length) {
                                from = startSecList[0];
                            }
                        } else if (item.fromSec! < fragmentEnd && fragmentEnd < item.endSec!) {
                            to = idx;
                        } else if (item.endSec! <= fragmentEnd && item.endSec! >= fragmentStart) {
                            endSecList.push(idx);
                            if (endSecList.length) {
                                to = endSecList[endSecList.length - 1];
                            }
                        }
                    });
                    if (from && !to) {
                        to = from;
                    }
                }
                topicDetectionTig(from, to);
            }
        });
    }, [role, keyword, callId]);

    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return '';
    };
    const { ref, height: h = 1 } = useResizeObserver<HTMLDivElement>();
    const height = Math.max(h - 5, 0);
    const [playingRudio, isImAudioPlaying, setIsImAudioPlaying] = useAudio();
    const playRudio = (url, index) => {
        playingRudio.pause();
        if (isImAudioPlaying === -1 || isImAudioPlaying !== index) {
            playingRudio.src = url;
            playingRudio.play();
            setIsImAudioPlaying(index);
        } else {
            setIsImAudioPlaying(-1);
        }
    };
    useEffect(() => {
        if (timer2) {
            clearTimeout(timer2);
        }
        timer2 = setTimeout(() => {
            if (!Array.isArray(detail?.dialogSentenceList)) {
                setDialogSentenceList([]);
                return;
            }
            let highlightMessageIndex = 1;
            let ret =
                detail?.inspectDataSource !== 14
                    ? detail?.dialogSentenceList.map((item, index) => {
                        // item.fromSec = item.fromSec * 1000;
                        // item.endSec = item.endSec * 1000;
                        let ret: any = {
                            ...item,
                            // isPlaying: isImAudioPlaying,
                            // playRudio: playRudio,
                            canRemarkEdit: false,
                            fieldFlag: false,
                            dataIdForCreateRemark: props.id,
                        };
                        ret.highlightMessageIndex = highlightMessageIndex;
                        highlightMessageIndex++;

                        //格式化语音类型的数据
                        {
                            //构造开始截止时间
                            let created_at = getAudioItemTime(item.fromSec, item.endSec);
                            //后端返回的语音类型的数据没有id，规则测试页面造了id字段，所以判断一下
                            if(!ret.id) {
                                ret.id = `dialogSentenceList-item-${index}`;
                            }
                            //企业微信类型的数据解构和文本类型是一致的，所以这里只对语音类型的数据增加__type以作区分
                            ret.__type = 'call';
                            ret.sender = item.role;
                            ret.created_at = created_at;
                            //content: "{"type":"message","data":{"content":"企微测试lianpp"}}"
                            ret.content = {
                                // ...item,
                                type: 'message',
                                data: {
                                    content: item.text,
                                    fromSec: item.fromSec,
                                    endSec: item.endSec,
                                },
                            };
                            ret.isPlaying = isPlaying;
                            ret.sendCurrentTimeRange = () => {
                                setCurrentTime(item.fromSec);
                                // audioInstance.currentTime = item.fromSec;
                                startPlay(true, item.endSec);
                            };
                        }
                        return ret;
                    }) ?? []
                    : detail?.content?.dialogSentenceList?.map((item) => {
                        return {
                            ...item,
                            isPlaying: isImAudioPlaying,
                            playRudio: playRudio,
                        };
                    }) ?? [];
            if (detail?.debugModeTagMatchResult) {
                Object.keys(detail?.debugModeTagMatchResult).forEach((key) => {
                    const value = detail?.debugModeTagMatchResult?.[key];
                    if (Array.isArray(value)) {
                        value.forEach((item) => {
                            item.sentencesIndices?.forEach((index) => {
                                if (ret[index - 1]) {
                                    if (!ret[index - 1].debugInfo) {
                                        ret[index - 1].debugInfo = [];
                                    }
                                    ret[index - 1].debugInfo.push({ ...item, debugInfoType: key });
                                }
                            });
                        });
                    }
                });
            }

            setDialogSentenceList(ret ?? []);
            // setLoadingDelay(false);
            timer2 = null;
        }, 300);
    }, [
        detail?.dialogSentenceList,
        // sendCurrentTimeRange,
        isPlaying,
        audioInstance,
        isImAudioPlaying,
    ]);

    const handleClick = (e) => {
        if (canPlay) {
            const index = parseInt(e.key, 10) - 1;
            const itemDetail = detail?.dialogSentenceList![index];

            // audioInstance.currentTime = itemDetail.fromSec;
            setCurrentTime(itemDetail?.fromSec);
            // audioInstance.play();
            startPlay(true, itemDetail?.endSec);
        }
    };
    // 案例片段点击事件
    const onCaseFragment = (e, item) => {
        console.log('onCaseFragment', item);
    };
    const handlerCurtChange = (item) => {
        console.log(item);
    };
    const handleKeyEventClick = (index) => {
        if (typeof index !== 'number') return;
        if (canPlay) {
            const itemDetail = detail?.dialogSentenceList![index];

            // audioInstance.currentTime = itemDetail.fromSec;
            setCurrentTime(itemDetail?.fromSec);
            // audioInstance.play();
            startPlay(true, itemDetail?.endSec);
        }
    };
    const handleClickEventKey = (fromSec, endSec) => {
        if (canPlay) {
            // audioInstance.currentTime = itemDetail.fromSec;
            setCurrentTime(fromSec);
            // audioInstance.play();
            startPlay(true, endSec);
        }
    };
    const onCommentTimeClick = (fromSec, endSec) => {
        // audioInstance.currentTime = fromSec;
        setCurrentTime(fromSec);
        // audioInstance.play();
        startPlay(true, endSec);
    };
    const chatRecordRef = useRef<any>(null);
    useEffect(() => {
        if (sentenceIndex === null) return;
        chatRecordRef.current?.scrollTo?.({
            index: sentenceIndex,
            align: 'top',
            offset: height / 4,
        });
    }, [sentenceIndex]);

    // eslint-disable-next-line no-unused-vars
    // const [_, forceUpdate] = useState({});
    // useEffect(() => {
    //     forceUpdate({});
    // }, [h]);
    const onReply = (textAreaValue, allAtUser, sourceCommentId, replyCommentId, newTopics) => {
        let params: PostReplayCommentsBody = {
            contentType: 3,
            callId: callId,
            isHelp: 0,
            content: textAreaValue,
            remindUsers: allAtUser.map((i) => ({ id: i.value })),
            authority: 0,
            sourceCommentId,
            replyCommentId,
            isTopic: Array.isArray(newTopics) && newTopics.length ? 1 : 0,
            topicNameList: Array.isArray(newTopics) && newTopics.length ? newTopics : undefined,
        };
        return postReplayComments(params);
    };
    const getAllComments = () => {
        setSelectTime(null);
        setSelectIndex(null);
        postReplayCommentsShowAll({
            callId: callId,
            contentType: 1,
        }).then((resp) => {
            setComments(resp.data ?? []);
        });
    };
    const getAllTopics = () => {
        getTopicDetailsFindTopicByName({
            params: { name: '' },
        }).then((resp) => {
            setTopicsList((resp.data ?? []).map((i) => ({ ...i, value: i.topicName })));
        });
    };
    // 排序
    const compare = (property) => {
        return function (a, b) {
            let value1 = a[property];
            let value2 = b[property];
            return value1 - value2;
        };
    };
    // 话题检测接口数据
    const topicDetectionTig = (from, to) => {
        const params: any = {
            from,
            to,
        };
        getReviewCallByIdTopicTag({ params, segments: { id: callId } }).then(
            (res: BaseResponseListTopicTagFoundResultResponse) => {
                let result: any = res.data?.sort(compare('startIndex'));
                setSessioLabel(result);
            }
        );
    };
    useEffect(() => {
        getAllComments();
        getAllTopics();
        getUsers({
            params: {
                pageNum: 1,
                pageSize: 1000,
            },
        }).then((resp) => {
            setUserList(
                (resp.data ?? []).map((i) => ({ ...i, value: i.id, label: i.realname || i.nickname || i.email }))
            );
            if (props.location.state && props.location.state.type === 'subscription') {
                msgNoticeCountData && msgNoticeCountData();
            }
        });

        getReviewCallByIdTopicTag({ segments: { id: callId } }).then(
            (res: BaseResponseListTopicTagFoundResultResponse) => {
                let result: any = res.data?.sort(compare('startIndex'));
                setSessioLabel(result);
            }
        );
        getReviewCallByIdKeyEvent({ segments: { id: callId } }).then(
            (res: BaseResponseListKeyEventFoundResultResponse) => {
                setKeyEvent(res.data ?? []);
            }
        );
        // getReviewCallByIdLibrary({
        //     segments: {
        //         id: callId,
        //     },
        // }).then((resp) => {
        //     window.console.log('getReviewCallByIdLibrary ::', resp.data);
        // });
    }, [callId]);
    useEffect(() => {
        getReviewCallDataCallDetailByIdCustomerCalls({
            segments: {
                id: rootCallId,
            },
        }).then((resp) => {
            setRecentCalls(resp.data?.items ?? []);
        });
        setCallId(rootCallId);
    }, [rootCallId]);
    const onCommentPublish = (textAreaValue, allAtUser, _, __, newTopics) => {
        let params: PostReplayCommentsBody = {
            contentType: 1,
            callId: callId,
            isHelp: 0,
            content: textAreaValue,
            remindUsers: allAtUser.map((i) => ({ id: i.value })),
            authority,
            isTopic: Array.isArray(newTopics) && newTopics.length ? 1 : 0,
            topicNameList: Array.isArray(newTopics) && newTopics.length ? newTopics : undefined,
        };
        if (params.authority === Udesk.enums.commentVisibleAuthority.designation.id) {
            params.userIdList = authorityUser;
        }

        if (typeof selectTime === 'number') {
            params.contentType = 2;
            params.sessionIndex =
                typeof selectIndex === 'number'
                    ? selectIndex + 1
                    : getSelectIndexFromSelectTime(dialogSentenceList, selectTime) + 1;
        }
        return postReplayComments(params, {
            successMsg: locales.pages.gong.callDetail.index.commentsPublishedSuccessfully,
        });
    };
    const onCommentDelete = (detail) => {
        return new Promise((res, rej) => {
            deleteReplayCommentsById({
                segments: {
                    id: detail.id,
                },
            })
                .then((resp) => {
                    const isRootComment = comments.find((i) => i.id === detail.id);
                    if (isRootComment) {
                        getAllComments();
                    }
                    res(resp);
                })
                .catch((e) => {
                    rej(e);
                });
        });
    };
    const onHelpModalOk = (values) => {
        const params: PostSeekHelpsBody = {
            replayComment: {
                callId,
                contentType: 1,
                isHelp: 1,
                ...values,
                authority: values.authority.authority,
                userIdList: values.authority.authorityUser,
            },
            content: values.content,
            helpStatus: 0,
            sessionId: callId,
            helpUserIdList: values?.helpUserIdList,
            callLocation: Udesk.utils.date.formatDuration(helpTime),
        };
        return new Promise((res, rej) => {
            postSeekHelps(params, { successMsg: locales.pages.gong.callDetail.index.seekCounselingSuccess }).then(
                (record) => {
                    res(record);
                    setHelpModalVisible(false);
                    getAllComments();
                },
                (err) => {
                    rej(err);
                }
            );
        });
    };
    // 添加案例库方法
    // const onAddCaseBase = () => {
    //     setShowCheckbox(true);
    //     setShowCutFragment(true);
    // };
    const onCancleCaseBase = () => {
        setShowCheckbox(false);
        setShowCutFragment(false);
    };
    const onHelpModalCancel = () => {
        setHelpModalVisible(false);
    };
    const handlerModalCancel = () => {
        setAddCaseBaseModal(false);
        setIsShareModal(false);
    };
    const handlerModalOk = (values, secondsValue, isOpen) => {
        let answerUserName =
            (detail?.dialogSentenceList &&
                (detail?.dialogSentenceList.filter((item) => item.role === 'agent')[0] as any)?.nickName) ||
            '';
        let dialUserName =
            (detail?.dialogSentenceList &&
                (detail?.dialogSentenceList.filter((item) => item.role === 'customer')[0] as any)?.nickName) ||
            '';

        let params: LibraryCallCreateRequest = {
            name: values.name || locales.pages.gong.callDetail.index.callRecording + callId,
            caseName: values.name || locales.pages.gong.callDetail.index.callRecording + callId,
            fileId: values.fileId,
            callId,
            callDuration: detail?.voiceLength as unknown as string,
            dialUserName,
            answerUserName,
            recommendReason: values.recommendReason,
            startTime: detail?.asrStartTime,
            endTime: detail?.asrEndTime,
        };
        let callTimeDetail = detail?.fieldList?.filter((item) => item.name === 'call_time');
        if (callTimeDetail?.length && callTimeDetail?.[0]?.id) {
            params.callTime = detail?.fieldValueMap?.[callTimeDetail[0].id];
        }
        if (detail?.callTime?.length) {
            params.callTime = moment(detail?.callTime).format('YYYY-MM-DD HH:mm:ss');
        }
        if (isOpen) {
            params.fragmentStartTime = secondsValue[0];
            params.fragmentEndTime = secondsValue[1];
        }
        postLibraryCalls(params, { successMsg: locales.pages.gong.callDetail.index.addedSuccessfully }).then((resq) => {
            setAddCaseBaseModal(false);
        });
    };
    // 剪辑---复选框事件
    const onMessageItemCheckboxClick = (checkList) => {
        console.log('onMessageItemCheckboxClick', checkList);
    };
    // 稍后听
    const handlerListenLater = () => {
        const params = {
            callId,
        };
        postToDosGenLaterOnListener(params, {
            successMsg:
                detail?.inspectDataSource === 14
                    ? /* 设置稍后看成功！ */locales.pages.gong.callDetail.index.theSettingWillBeSuccessfulLater
                    : locales.pages.gong.callDetail.index.setToListenLaterSuccessfully,
        }).then((res) => {});
    };
    const messageMenuNotice = (e) => {
        setShareType(e.key);
        setIsShareModal(true);
    };
    const nextCall = (type) => {
        getReviewCallNextByIdByType({ segments: { id: rootCallId, type } }).then((resp) => {
            setCurrentTime(0);
            const routeOptions = {
                history: props.history,
                routeName: 'gongCallDetail',
                pathParams: { callId: resp.data },
                state: {
                    ...(props.location?.state ?? {}),
                },
            };
            resp.data && Udesk.ui.routing.transitionTo(routeOptions);
        });
    };
    const trainRecords = ({
        sentenceIndex: index,
        newContent: text
    }) => {
        return postReviewCallAsrUpdate({ index, text, callId }).then(
            (resp) => {
                Udesk.ui.notify.success(locales.business.notifyMessage.correctionSuccess);
                let newDialogSentenceList =
                    detail?.dialogSentenceList?.map((item, i) => {
                        if (i === index) {
                            return {
                                ...item,
                                text: text,
                            };
                        }
                        return item;
                    }) ?? [];
                setDetail({
                    ...detail,
                    dialogSentenceList: newDialogSentenceList,
                });
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const menu = (
        <Menu onClick={messageMenuNotice}>
            <Menu.Item key='colleague'>{locales.pages.gong.callDetail.index.shareWithColleagues}</Menu.Item>
            <Menu.Item key='customer'>{locales.pages.gong.callDetail.index.shareWithCustomers}</Menu.Item>
        </Menu>
    );
    return (
        <Page
            pageBodyClassName='call-review-detail-page-index'
            title={locales.pages.gong.callDetail.index.resumptionDetails}
            padding={false}
            backGroundGhost={true}
            pageClassName='call-review-detail-page-index-wrap'
            onBack={() => {
                let routeOptions: any = {
                    history: props.history,
                    routeName: '',
                    state: {
                        ...(props.location?.state ?? {}),
                    },
                };
                if (props.location?.state?.type === 'case') {
                    routeOptions.routeName = 'caseBaseFolderList';
                } else if (props.location?.state?.type === 'reply') {
                    routeOptions.routeName = 'adminMsgCenterReply';
                } else if (props.location?.state?.type === 'answer') {
                    routeOptions.routeName = 'adminMsgCenterAnswer';
                } else if (props.location?.state?.type === 'share') {
                    routeOptions.routeName = 'adminMsgCenterShare';
                } else if (props.location?.state?.type === 'subscription') {
                    routeOptions.routeName = 'adminMsgCenterSubscription';
                } else if (props.location?.state?.type === 'coach') {
                    routeOptions.routeName = 'coachTaskList';
                } else if (props.location?.state?.type === 'home') {
                    routeOptions.routeName = 'homePage';
                } else if (props.location?.state?.type === 'customer') {
                    routeOptions.routeName = 'saleClientDetail';
                    routeOptions.pathParams = {
                        id: props.location?.state?.id,
                    };
                } else {
                    routeOptions.routeName = 'callAnalysisList';
                }
                Udesk.ui.routing.transitionTo(routeOptions);
            }}
            extra={
                !showCheckbox
                    ? [
                        <Button size='small' type='link' onClick={() => nextCall(1)}>{/* 上一条 */}{locales.pages.gong.callDetail.index.previous}</Button>,
                        <Button size='small' type='link' onClick={() => nextCall(2)}>{/* 下一条 */}{locales.pages.gong.callDetail.index.next}</Button>,
                        hasFeature && hasFeature('session:root:debug') ? (
                            <Space>
                                <div style={{ display: 'flex' }}>{/* 调试模式 */}{locales.pages.gong.callDetail.index.debuggingMode}<Button
                                    size='small'
                                    icon={
                                        <Icon
                                            type='QuestionCircleOutlined'
                                            antdIcon={true}
                                            style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                                        />
                                    }
                                    tooltip={/* 开通后消息如果匹配关键事件、监督项、客户标签的规则或者知识点规则， 则会显示消息匹配到的规则名称，以及知识库的知识点名称。 */locales.pages.gong.callDetail.index.afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed}
                                />
                                  :
                                </div>
                                <Switch onChange={setIsDebug} />
                            </Space>
                        ) : null,
                        <Button onClick={handlerListenLater}>
                            {detail?.inspectDataSource !== 14
                                ? locales.pages.gong.callDetail.index.listenLater
                                : /* 稍后看 */locales.pages.gong.callDetail.index.seeLater}
                        </Button>,
                        <Button onClick={() => setAddCaseBaseModal(true)}>
                            {locales.pages.gong.callDetail.index.addToCaseBase}
                        </Button>,
                        <React.Fragment>
                            {hasFeature && hasFeature('session:root:share') && (
                                <Dropdown overlay={menu}>
                                    <Button onClick={(e) => e.preventDefault()}>
                                        {locales.pages.gong.callDetail.index.shareCases}
                                    </Button>
                                </Dropdown>
                            )}
                        </React.Fragment>,
                        <Button
                            onClick={() => {
                                setHelpTime(currentTime);
                                setHelpModalVisible(true);
                            }}
                        >
                            {locales.pages.gong.callDetail.index.seekCounseling}
                        </Button>,
                    ]
                    : [
                        <Button size='small' type='link' onClick={() => onCancleCaseBase()}>{/* 取消 */}{locales.pages.gong.callDetail.index.cancel}</Button>,
                        <Button size='small' type='primary' onClick={() => setAddCaseBaseModal(true)}>{/* 确定 */}{locales.pages.gong.callDetail.index.determine}</Button>,
                    ]
            }
        >
            <div className={`call-review-detail-page-index-list ${openLeft ? 'open' : ''}`}>
                {detail && (
                    <Tabs tabPosition='left' style={{ width: '100%' }} defaultActiveKey={`insight-${detail.id}`}>
                        <TabPane
                            tab={
                                <TabTitle
                                    icon='ProfileOutlined'
                                    title={locales.pages.gong.callDetail.index.sessionRecord}
                                />
                            }
                            key={`callInfos-${detail.id}`}
                        >
                            <div className='audio-text-view'>
                                {detail && detail.fieldList && detail.fieldList.length > 0 && (
                                    <CallTextView
                                        callInfoList={detail.fieldList}
                                        fieldValueMap={detail.fieldValueMap}
                                    />
                                )}
                            </div>
                        </TabPane>
                        <TabPane
                            tab={
                                <TabTitle
                                    icon='TableOutlined'
                                    title={locales.pages.gong.callDetail.index.businessRecord}
                                />
                            }
                            key={`businessRecords-${detail.id}`}
                        >
                            <div className='audio-text-view'>
                                {detail.noteInfoExtension && (
                                    <BusinessRecordsView noteInfoExtension={detail.noteInfoExtension} />
                                )}
                            </div>
                        </TabPane>
                        <TabPane
                            tab={
                                <TabTitle
                                    icon='SearchOutlined'
                                    title={locales.pages.gong.callDetail.index.keywordSearch}
                                />
                            }
                            key={`searchList-${detail.id}`}
                        >
                            <Space>
                                <Select
                                    options={Udesk.enums.roles.reduce(
                                        (prev, cur) => {
                                            prev.push({ label: cur.name, value: cur.id });
                                            return prev;
                                        },
                                        [{ label: locales.pages.gong.callDetail.index.whole, value: -1 }]
                                    )}
                                    value={role}
                                    onChange={setRole}
                                ></Select>
                                <Input.Search defaultValue={keyword} onSearch={setKeyword}></Input.Search>
                            </Space>
                            {highlightItemList.length > 0 && (
                                <div
                                    style={{
                                        marginTop: 8,
                                        color: 'rgba(0, 0, 0, .45)',
                                        whiteSpace: 'nowrap',
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >{`${locales.pages.gong.callDetail.index.altogether}${highlightItemList.length}${locales.pages.gong.callDetail.index.appearsAt}${keyword}' `}</div>
                            )}
                            {highlightItemList.length > 0 ? (
                                <div style={{ height: 'calc(100vh - 218px)', overflowY: 'auto' }}>
                                    <Menu
                                        onClick={handleClick}
                                        style={{ width: '100%' }}
                                        defaultSelectedKeys={['1']}
                                        mode='inline'
                                    >
                                        {highlightItemList.map((item) => (
                                            <Menu.Item key={`${item.index}`}>
                                                <ListItem {...item} />
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                </div>
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </TabPane>
                        <TabPane
                            tab={<TabTitle icon='BarChartOutlined' title={/* 会话洞察 */locales.pages.gong.callDetail.index.conversationalInsights} />}
                            key={`insight-${detail.id}`}
                        >
                            <SessionInsights
                                callId={detail?.id}
                                sessionSummary={(detail as any)?.sessionSummary}
                                enableSessionSummary={(detail as any)?.enableSessionSummary}
                                insightInfo={detail?.insightInfo}
                                keyEvents={detail?.keyEvents}
                                keyEventTag={keyEvent}
                                onKeyEventTagClick={handleKeyEventClick}
                                tags={detail?.tags ?? []}
                                knowledgePoint={(detail as any)?.knowledgePoint ?? []}
                                customer={(detail as any)?.customer ?? {}}
                                user={(detail as any)?.user ?? {}}
                                onCaseFragment={onCaseFragment}
                                onClick={handleClickEventKey}
                                isWechatIm={detail.inspectDataSource === 14}
                            />
                        </TabPane>
                        <TabPane
                            tab={<TabTitle icon='ic-contacts1' title={/* 客户洞察 */locales.pages.gong.callDetail.index.customerInsight} />}
                            key={`customer-insight-${detail.id}`}
                        >
                            <CustomerInsight tags={(detail as any)?.customerTagList}></CustomerInsight>
                        </TabPane>
                        <TabPane
                            tab={<TabTitle icon='LikeOutlined' antdIcon={true} title={/* 知识点推荐 */locales.pages.gong.callDetail.index.knowledgePointRecommendation} />}
                            key={`recommend-${detail.id}`}
                        >
                            <IntelligentRecommend
                                tags={detail?.tags ?? []}
                                keyEvents={detail?.keyEvents}
                                recommendWordsFavoriteGroupList={(detail as any)?.recommendWordsFavoriteGroupList}
                                knowledgePoint={(detail as any)?.knowledgePoint ?? []}
                            />
                        </TabPane>
                        {/* <TabPane
                            tab={<TabTitle icon='ic-ask-tiwen' title='会话监督' />}
                            key={`supervision-${detail.id}`}
                        >
                            <Supervision
                            // insightInfo={detail?.insightInfo}
                            // keyEvents={detail?.keyEvents}
                            // tags={detail?.tags ?? []}
                            // customer={(detail as any)?.customer??{}}
                            // user={(detail as any)?.user??{}}
                            // onClick={handleClickEventKey}
                            />
                        </TabPane> */}
                        <TabPane tab={<TabTitle icon='ic-ask-tiwen' title={/* 评论 */locales.pages.gong.callDetail.index.comment} />} key={`comment-${detail.id}`}>
                            <div
                                style={{ overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column' }}
                            >
                                <div style={{ overflow: 'auto', flex: 1 }}>
                                    <div className='call-review-detail-page-index-content-comment'>
                                        <Comment
                                            hiddenReplayAvatar={true}
                                            comments={comments}
                                            onReply={onReply}
                                            onDelete={onCommentDelete}
                                            onCommentTimeClick={onCommentTimeClick}
                                            createTimePlacement='bottom'
                                            userList={userList}
                                            topicsList={topicsList}
                                        />
                                    </div>
                                </div>
                                {hasFeature && hasFeature('session:root:comment') && (
                                    <React.Fragment>
                                        <Button
                                            style={{ marginTop: visible ? 240 : 16 }}
                                            type='primary'
                                            onClick={() => setVisible(true)}
                                        >
                                            {`${locales.labels.add}${locales.labels.comment}`}
                                        </Button>
                                        <Drawer
                                            title={
                                                <UserVisibleSelect
                                                    value={{ authorityUser, authority }}
                                                    onChange={(value) => {
                                                        setAuthorityUser(value.authorityUser);
                                                        setAuthority(value.authority);
                                                    }}
                                                    userList={userList}
                                                />
                                            }
                                            placement='bottom'
                                            mask={false}
                                            onClose={() => setVisible(false)}
                                            visible={visible}
                                            getContainer={false}
                                            style={{ position: 'absolute' }}
                                            bodyStyle={{
                                                padding: '8px 16px 0',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                            headerStyle={{ padding: '16px 16px 0', borderBottom: 'none' }}
                                        >
                                            <CommentReply
                                                currentTime={selectTime}
                                                hiddenAvatar={true}
                                                // autoSize={{ maxRows: 6, minRows: 6 }}
                                                onPublish={onCommentPublish}
                                                userList={userList}
                                                topicsList={topicsList}
                                                onPublishSuccess={() => {
                                                    setAuthorityUser([]);
                                                    setAuthority(Udesk.enums.commentVisibleAuthority.all.id);
                                                    getAllComments();
                                                    getAllTopics();
                                                }}
                                                clearCurrentTime={() => {
                                                    setSelectTime(null);
                                                    setSelectIndex(null);
                                                }}
                                                placeholder={
                                                    locales.pages.gong.callDetail.index
                                                        .pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic
                                                }
                                                wrapperClassName='udesk-qa-ui-input call-review-detail-page-index-content-comment-input'
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginTop: 8,
                                                    marginBottom: 8,
                                                }}
                                            >
                                                <Switch
                                                    loading={subscriptionLoading}
                                                    checked={subscriptionChecked}
                                                    onChange={onSubscriptionChange}
                                                ></Switch>
                                                <div style={{ marginLeft: 8 }}>
                                                    {locales.pages.gong.callDetail.index.subscriptionSession}
                                                </div>
                                                <Tooltip
                                                    title={
                                                        locales.pages.gong.callDetail.index
                                                            .afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies
                                                    }
                                                >
                                                    <Icon
                                                        style={{ marginLeft: 8 }}
                                                        type='QuestionCircleOutlined'
                                                        antdIcon={true}
                                                    ></Icon>
                                                </Tooltip>
                                            </div>
                                        </Drawer>
                                    </React.Fragment>
                                )}
                            </div>
                        </TabPane>
                    </Tabs>
                )}
                <Handle onClick={() => setOpenLeft((prev) => !prev)}>
                    <Icon type='shouqi1' rotate={openLeft ? -90 : 90}></Icon>
                </Handle>
            </div>
            <RelatedCalls>
                {recentCalls.length > 0 &&
                    recentCalls.map((item: any, index, arr) => {
                        const mid = (arr.length / 2) >>> 0;
                        return (
                            <>
                                <RelatedWrap>
                                    <DateTimeText
                                        transform={
                                            index > mid
                                                ? `translateX(-${(index - mid) * 70}%)`
                                                : `translateX(${(mid - index) * 70}%)`
                                        }
                                        className='date-time-text'
                                        style={{ color: `rgba(26, 110, 255, 1)` }}
                                        color={colorList[index % 10]}
                                    >
                                        {item.dateTime}
                                    </DateTimeText>
                                    <RelatedCallItem
                                        // backgroundColor={colorList[index % 10]}
                                        backgroundColor='#1A6EFF'
                                        opacity={`${callId}` === `${item.id}` ? 1 : 0.3}
                                        transform={
                                            index > mid
                                                ? `translateX(-${(index - mid) * 70}%)`
                                                : `translateX(${(mid - index) * 70}%)`
                                        }
                                        onClick={() => {
                                            setCallId(item.id);
                                        }}
                                        className={
                                            `${callId}` === `${item.id}`
                                                ? 'current-selected-related-call'
                                                : 'export-selected-related-call'
                                        }
                                    >
                                        {item?.inspectDataSource === 14 ? (
                                            <Icon type='xingzhuang' />
                                        ) : item?.inspectDataSource === 13 ? (
                                            <Icon type='a-maikefengshengyinluyinluzhishengyin1' />
                                        ) : (
                                            <Icon type='tonghuaguanli' />
                                        )}
                                    </RelatedCallItem>
                                </RelatedWrap>
                            </>
                        );
                    })}
            </RelatedCalls>
            <div className='call-review-detail-page-index-content'>
                <div className='call-review-detail-page-index-content-audio'>
                    <div style={{ flex: 1 }} ref={ref} onMouseEnter={() => chatRecordRef.current?.scrollTo?.(null)}>
                        <ChatRecord
                            key={`${height}-${(detail as any)?.callId}`}
                            data={dialogSentenceList}
                            height={height}
                            showTopMore={false}
                            loading={false}
                            defaultScrollToTop={true}
                            // propsFromDetail={propsFromDetail}
                            highlightPoint={currentHighLightPointOrSmartKey}
                            allHighlightPoints={allHighlightPoints}
                            // closeHighlight={closeHighLightAndClearKeyWords}
                            manualHighlightIndex={sentenceIndex}
                            ref={chatRecordRef}
                            virtual={false}
                            // searchHighlightKeywords={keywords}
                            // searchHighlightRoleType={type}
                            // markFlag={MarkFlag}
                            propsFromDetail={{
                                onMarkSemanticClick: (content, index) => {
                                    setSelectIndex(index);
                                    setSelectTime((dialogSentenceList[index] as any)?.fromSec ?? null);
                                    props?.SmartToolBarRef?.current?.actions?.getKeyword?.(content);
                                },
                                trainRecords,
                                // openConditionPage: (params) => {
                                //     let routeOptions: any = {
                                //         history: props.history,
                                //         ...params,
                                //     };
                                //     Udesk.ui.routing.transitionTo(routeOptions);
                                // },
                            }}
                            callId={(detail as any)?.callId}
                            allHighlightMap={supervisionInspectionDetail.indexPointTag}
                            // changeToHighlightItemsCallDetail={changeToHighlightItemsCallDetail}
                            showCheckbox={showCheckbox}
                            onMessageItemCheckboxClick={onMessageItemCheckboxClick}
                            isDebug={isDebug}
                            inspectDataSource={detail?.inspectDataSource}
                        />
                    </div>
                    {typeof detail?.channelType === 'number' && detail.channelType !== 1 && (
                        <Wave
                            changeAudioPlayingStatus={changeAudioPlayingStatus}
                            isPlaying={isPlaying}
                            detail={detail}
                            audioInstance={audioInstance}
                            disabled={!canPlay}
                            currentTime={currentTime}
                            onSelectTime={setSelectTime}
                            sessioLabel={sessioLabel}
                            keyEvent={keyEvent}
                            // caseFragment={caseFragment}
                            showCutFragment={showCutFragment}
                            setCurrentTime={setCurrentTime}
                            handlerCurtChange={handlerCurtChange}
                            voiceLength={detail?.voiceLength ?? audioInstance?.duration}
                            onKeyEventClick={handleKeyEventClick}
                        />
                    )}
                </div>
                <div style={{ width: 350 }}>
                    <div className='call-review-detail-page-index-content-right'>
                        <div
                            style={{
                                padding: '0 24px 16px',
                                fontSize: '16px',
                                fontWeight: 600,
                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                marginBottom: 24,
                            }}
                        >{/* 会话质量 */}{locales.pages.gong.callDetail.index.sessionQuality}</div>
                        <div style={{ padding: '0 24px', flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                            <Supervision
                                onSupervisionInspectionDetailUpdate={(data) => {
                                    setSupervisionInspectionDetail(data);
                                }}
                                dialogSentenceList={dialogSentenceList}
                                callId={callId}
                                onClick={handleClick}
                            />
                        </div>
                        <HelpModal
                            onCancel={onHelpModalCancel}
                            onOk={onHelpModalOk}
                            userList={userList}
                            helpTime={helpTime}
                            visible={helpModalVisible}
                        />
                    </div>
                </div>
            </div>
            {addCaseBaseModal ? (
                <AddFileModal
                    isAddCaseBaseShow={addCaseBaseModal}
                    handlerModalOk={handlerModalOk}
                    handlerModalCancel={handlerModalCancel}
                    voiceLength={detail?.voiceLength}
                    voiceUrl={detail?.voiceUrl}
                ></AddFileModal>
            ) : null}
            {isShareModal ? (
                <ShareModal
                    sdkOptions={props.sdkOptions}
                    userList={userList}
                    detail={detail}
                    handlerModalCancel={handlerModalCancel}
                    shareType={shareType}
                    isShare={isShareModal}
                ></ShareModal>
            ) : null}
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

function getAudioItemTime(startPoint, endPoint) {
    let start = Udesk.utils.date.formatDuration(startPoint);
    let end = Udesk.utils.date.formatDuration(endPoint);
    return `${start} - ${end}`;
}

function getSelectIndexFromSelectTime(dialogSentenceList, selectTime) {
    const hitItemIndex = dialogSentenceList.findIndex(
        (item: any) => item.fromSec <= selectTime && selectTime < item.endSec
    );

    if (hitItemIndex >= 0) return hitItemIndex;

    return dialogSentenceList.findIndex((item: any) => item.fromSec >= selectTime);
}
