import React from 'react';
import Udesk from 'Udesk';
import ClassNames from 'classnames';
import AudioCallTextInfoComponent from './component';
import './style.scss';
import { Input, Icon, Button, Popover, Space, Tooltip } from 'udesk-ui';
import XssComponent from 'udesk-react/src/components/xss';
import { MessageComponent, MarkerListComponent } from './components';
import Locales from 'UdeskLocales';


const { TextArea } = Input;
const TextInfoView = (props) => {
    let { fieldFlag, inputStatus, item, callFields, actions, locales, copied, content, allData, type, sdkOptions, inspectDataSource } = props;
    return (
        <div className='audio-call-content-view-custom-text-info-view' key={item.data.id}>
            <Choose>
                <When condition={[Udesk.enums.inspectDataSources.voiceCall.id, Udesk.enums.inspectDataSources.wechatRadio.id].includes(inspectDataSource)}>
                    {fieldFlag ? (
                        <div>
                            {inputStatus ? (
                                <XssComponent
                                    classNames={ClassNames(
                                        '',
                                        {
                                            'audio-text-list-item-text-hit-status':
                                                item.states.isHitStatus && !item.states.isHit,
                                        },
                                        { 'audio-text-list-item-text-hit': item.states.isHit }
                                    )}
                                    value={callFields}
                                />
                            ) : (
                                <Input defaultValue={callFields} onChange={actions.inputEdit} />
                            )}
                        </div>
                    ) : (
                        <div onClick={actions.markSemantic.params(item.states.text)}>
                            <XssComponent
                                classNames={ClassNames(
                                    '',
                                    {
                                        'audio-text-list-item-text-hit-status':
                                            item.states.isHitStatus && !item.states.isHit,
                                    },
                                    { 'audio-text-list-item-text-hit': item.states.isHit }
                                )}
                                value={item.states.text}
                            />
                        </div>
                    )}
                </When>
                <When condition={[Udesk.enums.inspectDataSources.textDialogue.id, Udesk.enums.inspectDataSources.wechatDialogue.id].includes(inspectDataSource)}>
                    <MessageComponent
                        locales={locales}
                        copied={copied}
                        callFields={callFields}
                        content={content}
                        allData={allData}
                        type={type}
                        item={item}
                        fieldFlag={fieldFlag}
                        sdkOptions={sdkOptions}
                        actions={actions}
                        inputStatus={inputStatus}
                    />
                </When>
            </Choose>
        </div>
    );
};
const TextTimeUnMemo = (props) => {
    let { item, inspectDataSource } = props;
    const locales = Locales['current'];

    return (
        <div className='audio-call-content-view-costom-text-time'>
            <Choose>
                <When condition={inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id}>
                    {getAudioItemTime(item.data.fromSec, item.data.endSec)}
                </When>
                <When condition={[Udesk.enums.inspectDataSources.textDialogue.id, Udesk.enums.inspectDataSources.wechatDialogue.id].includes(inspectDataSource)}>
                    {
                        item.data.role === 'customer' && item.data.nickName && <span style={{marginRight: 8}}>{item.data.nickName}</span>
                    }
                    {item.data.createdTime}
                    {
                        item.data.role === 'customer' || item.data.nickName && <span style={{marginLeft: 8}}>{item.data.nickName}</span>
                    }
                </When>
            </Choose>
            {/* <If condition={item.data.averageSpeakSpeed && averageFlag}> */}
            <If condition={item.data.averageSpeakSpeed}>
                <span style={{ marginLeft: '5px' }}> -{item.data.averageSpeakSpeed} {locales.fix.wordSpeed}</span>
            </If>
        </div>
    );
};
const CaseBaseAddedUnMemo = ({ role, markSemanticVisible, fieldFlag, addFlag, inspectDataSource, actions, item, content, type }) => {
    let roleVCondition = false;
    if (role === 'customer') {
        roleVCondition = markSemanticVisible;
    }
    if (role === 'agent') {
        roleVCondition = true;
    }
    return (
        <If condition={fieldFlag}>
            <If condition={addFlag}>
                <If
                    condition={
                        roleVCondition &&
                        (inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
                            type === 'message')
                    }
                >
                    <div className='audio-text-list-item-text-action-caseBase'>
                        <i
                            className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yitianjiaanli'
                            onClick={actions.markSemantic.params(item.states.text || content)}
                        ></i>
                    </div>
                </If>
            </If>
        </If>
    );
};
const CaseBaseUnMemo = (props) => {
    let { fields, inputStatus, role, markSemanticVisible, canRemarkEdit, dialogRemarkList, fieldFlag, item, actions, locales, type, inspectDataSource } = props;

    let dialogRemark = Array.isArray(dialogRemarkList) ? dialogRemarkList.find(i => `${i.index}` === `${item.data.id}`) : null;
    let hasRemark = !!dialogRemark;
    let remarkText = hasRemark ? `${locales.labels.remark}: ${dialogRemark.remark}` : '';
    const EditBtn = () => (
        <React.Fragment>
            {canRemarkEdit && !fieldFlag && !hasRemark && (
                <Button
                    type='link'
                    size='small'
                    style={{ color: 'rgb(24, 90, 189)' }}
                    onClick={actions.showRemark.params(dialogRemark || {}, item)}
                >
                    {locales.labels.remark}
                </Button>
            )}
            {hasRemark && (
                <Tooltip title={remarkText} getPopupContainer={(triggerNode) => triggerNode.parentNode}>
                    <div
                        onClick={actions.showRemark.params(dialogRemark || {}, item)}
                        className='message-tools-bar-remark'
                        style={{ textAlign: 'start' }}
                    >{remarkText}</div>
                </Tooltip>
            )}
        </React.Fragment>
    );
    return (
        <div className='message-tools-bar' style={{ textAlign: item.data.role !== "agent" ? 'end' : 'start' }}>
            <EditBtn />
            <If condition={fieldFlag}>
                <If
                    condition={
                        markSemanticVisible &&
                        (item.data.role === "customer" || item.data.role === "agent") &&
                        (inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id || type === 'message')
                    }
                >
                    <div className={`aduio-text-case-base-${role}`}>
                        <span onClick={actions.caseBaseAdd.params(item.data.id)}>
                            {fields ? (
                                <span className='case-audio-add'>
                                    <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tianjiaanli'></i>
                                    {locales.components.pages.caseBaseList.detail.addCaseBase}
                                </span>
                            ) : (
                                <span className='case-audio-add'>
                                    <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yichu'></i>
                                    {locales.components.pages.caseBaseList.detail.delCaseBase}
                                </span>
                            )}
                        </span>
                        {inputStatus ? (
                            <span style={{ cursor: 'pointer' }} onClick={actions.fieldsChange}>{locales.components.pages.caseBaseList.edit}</span>
                        ) : (
                            <span>
                                <span
                                    className='audio-detail-okText'
                                    onClick={actions.onOkFields.params(item.data.id)}
                                >
                                    {locales.components.pages.caseBaseList.ok}
                                </span>
                                <span onClick={actions.closeFields}>
                                    {locales.components.pages.caseBaseList.cancel}
                                </span>
                            </span>
                        )}
                    </div>
                </If>
            </If>
        </div>
    );
};
// const TextInfoView = React.memo(TextInfoViewUnMemo);
const TextTime = React.memo(TextTimeUnMemo);
const CaseBaseAdded = React.memo(CaseBaseAddedUnMemo);
const CaseBase = React.memo(CaseBaseUnMemo);
export default class AudioCallTextInfoTemplate extends AudioCallTextInfoComponent {
    render() {
        let {
            inspectDataSource,
            item,
            type,
            content,
            markSemanticVisible,
            sdkOptions,
            role,
            heightList,
            pointChildrenComputerInfo,
            markFlag,
            pointChildrenPersonInfo,
            allData,
            isSysMsg,
            dialogRemarkList,
            canRemarkEdit,
        } = this.props;
        let { actions, state, locales } = this;
        let { callFields, visible, remark, addFlag } = this.privates;
        let popoverContent = (
            <div style={{ margin: '-4px -8px' }}>
                <div className='message-tools-bar-remark-content'>
                    <TextArea bordered rows={4} showCount={true} maxCount={270} maxLength={270} value={remark.remark} onChange={actions.remarkChange} />
                </div>
                <div className='message-tools-bar-remark-footer' style={{ marginTop: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Space>
                        <Button size='small' onClick={actions.cancelRemark}>{locales.labels.cancel}</Button>
                        <Button size='small' type='primary' onClick={actions.saveRemark}>{locales.labels.save}</Button>
                    </Space>
                </div>
            </div>
        );
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Choose>
                    <When condition={isSysMsg}>
                        <div className='audio-call-content-view-costom-text-time' style={{ display: 'none' }}></div>
                        <div className='audio-text-list-item-sys-msg audio-call-content-view-custom-text-info-view'>
                            {content}
                        </div>
                    </When>
                    <When condition={!isSysMsg}>
                        <Choose>
                            <When condition={role === 'customer'}>
                                <div className='audio-call-content-view-custom-text'>
                                    <TextTime
                                        item={item}
                                        inspectDataSource={inspectDataSource}
                                    />
                                    <div className='audio-call-content-view-custom-text-info'>
                                        <TextInfoView
                                            inputStatus={this.privates.inputStatus}
                                            item={item}
                                            locales={locales}
                                            copied={state.copied}
                                            callFields={callFields}
                                            content={content}
                                            allData={allData}
                                            type={type}
                                            fieldFlag={this.props.fieldFlag}
                                            sdkOptions={sdkOptions}
                                            actions={actions}
                                            inspectDataSource={inspectDataSource}
                                        />
                                        <div className='audio-call-content-view-custom-text-info-time'>
                                            <If
                                                condition={
                                                    inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id
                                                }
                                            >
                                                <Icon
                                                    type='ic-play-circle-bofang'
                                                    onClick={actions.sendCurrentTimeRange.params(item)}
                                                    style={{ marginLeft: '3px' }}
                                                />
                                            </If>
                                        </div>
                                    </div>
                                    <Popover
                                        content={popoverContent}
                                        placement='bottomRight'
                                        visible={visible && canRemarkEdit && !this.props.fieldFlag}
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    >
                                        <CaseBase
                                            role={item.data.role}
                                            fields={this.privates.fields}
                                            markSemanticVisible={markSemanticVisible}
                                            canRemarkEdit={canRemarkEdit}
                                            dialogRemarkList={dialogRemarkList}
                                            fieldFlag={this.props.fieldFlag}
                                            item={item}
                                            actions={actions}
                                            locales={locales}
                                            type={type}
                                            inspectDataSource={inspectDataSource}
                                            inputStatus={this.privates.inputStatus}
                                        />
                                    </Popover>
                                </div>
                                <CaseBaseAdded
                                    role={item.data.role}
                                    markSemanticVisible={markSemanticVisible}
                                    fieldFlag={this.props.fieldFlag}
                                    addFlag={addFlag}
                                    inspectDataSource={inspectDataSource}
                                    actions={actions}
                                    item={item}
                                    content={content}
                                    type={type}
                                />
                                <If condition={!this.props.fieldFlag}>
                                    <MarkerListComponent
                                        actions={actions}
                                        pointChildrenComputerInfo={pointChildrenComputerInfo}
                                        markFlag={markFlag}
                                        heightList={heightList}
                                        pointChildrenPersonInfo={pointChildrenPersonInfo}
                                    />
                                </If>
                            </When>
                            <When condition={role === 'agent'}>
                                <CaseBaseAdded
                                    role={item.data.role}
                                    markSemanticVisible={markSemanticVisible}
                                    fieldFlag={this.props.fieldFlag}
                                    addFlag={addFlag}
                                    inspectDataSource={inspectDataSource}
                                    actions={actions}
                                    item={item}
                                    content={content}
                                    type={type}
                                />
                                <If condition={!this.props.fieldFlag}>
                                    <MarkerListComponent
                                        actions={actions}
                                        pointChildrenComputerInfo={pointChildrenComputerInfo}
                                        markFlag={markFlag}
                                        heightList={heightList}
                                        pointChildrenPersonInfo={pointChildrenPersonInfo}
                                    />
                                </If>
                                <div className='audio-call-content-view-costom-text'>
                                    <TextTime
                                        item={item}
                                        inspectDataSource={inspectDataSource}
                                    />
                                    <div className='audio-call-content-view-costom-text-info'>
                                        <div className='audio-call-content-view-costom-text-info-time'>
                                            <Choose>
                                                <When
                                                    condition={
                                                        inspectDataSource ===
                                                        Udesk.enums.inspectDataSources.voiceCall.id
                                                    }
                                                >
                                                    <Icon
                                                        type='ic-play-circle-bofang'
                                                        onClick={actions.sendCurrentTimeRange.params(item)}
                                                        style={{ marginLeft: '3px' }}
                                                    />
                                                </When>
                                            </Choose>
                                        </div>
                                        <TextInfoView
                                            inputStatus={this.privates.inputStatus}
                                            item={item}
                                            locales={locales}
                                            copied={state.copied}
                                            callFields={callFields}
                                            content={content}
                                            allData={allData}
                                            type={type}
                                            fieldFlag={this.props.fieldFlag}
                                            sdkOptions={sdkOptions}
                                            actions={actions}
                                            inspectDataSource={inspectDataSource}
                                        />
                                    </div>
                                    <Popover
                                        content={popoverContent}
                                        placement='bottomLeft'
                                        visible={visible && canRemarkEdit && !this.props.fieldFlag}
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    >
                                        <CaseBase
                                            role={item.data.role}
                                            fields={this.privates.fields}
                                            markSemanticVisible={markSemanticVisible}
                                            canRemarkEdit={canRemarkEdit}
                                            dialogRemarkList={dialogRemarkList}
                                            fieldFlag={this.props.fieldFlag}
                                            item={item}
                                            actions={actions}
                                            locales={locales}
                                            type={type}
                                            inspectDataSource={inspectDataSource}
                                            inputStatus={this.privates.inputStatus}
                                        />
                                    </Popover>
                                </div>
                            </When>
                        </Choose>
                    </When>
                </Choose>
            </div>
        );
    }
}

function getAudioItemTime(startPoint, endPoint) {
    let start = Udesk.utils.date.formatDurationWithMillisecond(startPoint);
    let end = Udesk.utils.date.formatDurationWithMillisecond(endPoint);
    return `${start} - ${end}`;
}
