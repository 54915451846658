import React from 'react';
import {
    Select,
    Popconfirm,
    Button,
    Space,
    Icon,
    Input,
    Modal,
    Form,
    FormBuilder,
    Checkbox,
} from 'udesk-ui';
import Udesk from 'Udesk';
import UdeskPagination from 'Component/common/udesk-pagination';
import SelectCallTimer from '../../components/select-call-time';
import IntelligentTagsRoute from './route';
import AutoSizeTable from 'Component/common/auto-size-table';
import './style.scss';
import { TreeMenu } from 'udesk_gm_ui';
import UdeskLocales from 'UdeskLocales';
import { hasFeature } from 'src/util/permit';
import { CustomFilter } from './components/CustomFilter/index';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default class IntelligentTagsTemplate extends IntelligentTagsRoute {
    render() {
        let { route } = this.props;
        let { columns, pageNum, pageSize, total } = this.state;
        let {
            smartTags,
            selectTagClassification,
            smartTagCategorysTree,
            smartTagCategorys,
            // isClassificationVisible,
            selectedItems,
            selectCallTimeVisible,
            redoCallIds,
            tagClassificationModalVisible,
            confirmLoading,
            formRef,
            currentTagClassification,
            loading,
            funcType,
            redoLoading,
        } = this.privates;
        const { actions, privates, locales } = this;
        const intelligentTagsLocales = locales.components.pages.intelligentTags.index;

        return (
            // <div className="intelligent-tags-index-page">
            <div className="intelligent-tags-index-page-body">
                <If condition={hasFeature('smart:tag:view')}>
                    <div className="intelligent-tags-index-page-body-bottom">
                        <div className="intelligent-tags-index-page-body-bottom-left">
                            <div
                                style={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    {route.name === 'keyEventsTagsIndex'
                                        ? intelligentTagsLocales.eventClassification
                                        : intelligentTagsLocales.tagClassification}
                                </div>
                                <If condition={hasFeature('smart:tag:editCategory')}>
                                    <div className="wechart-qa-tasks-check-point-classification-item">
                                        <Button
                                            // style={{ width: '100%' }}
                                            size="small"
                                            type="text"
                                            onClick={actions.newClassification.params(null)}
                                            icon={<Icon type="PlusOutlined" antdIcon={true} />}
                                            tooltip={
                                                route.name === 'keyEventsTagsIndex'
                                                    ? intelligentTagsLocales.newEventClassification
                                                    : intelligentTagsLocales.newTagClassification
                                            }
                                        ></Button>
                                    </div>
                                </If>
                            </div>
                            <TreeMenu
                                treeData={smartTagCategorysTree}
                                indentUnitWidth={16}
                                selectedKeys={
                                    selectTagClassification ? [`${selectTagClassification}`] : []
                                }
                                onSelect={(selectedKeys) => {
                                    actions.changeTagClassification({
                                        key: selectedKeys[0] * 1,
                                    });
                                }}
                                treeNodeActionMenu={[
                                    {
                                        label: /* 编辑 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.template.edit,
                                        onClick: actions.editTagClassification,
                                    },
                                    {
                                        label: /* 新建子分类 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.template.createANewSubcategory,
                                        onClick: actions.newClassification,
                                    },
                                    {
                                        label: /* 删除 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.template.delete,
                                        danger: true,
                                        onClick: actions.deleteTagClassification,
                                    },
                                ]}
                            />
                        </div>
                        <div className="intelligent-tags-index-page-body-bottom-right">
                            <div className="intelligent-tags-index-page-body-search-container">
                                <Input.Search
                                    defaultValue={privates.tagName}
                                    placeholder={locales.labels.searchPlaceHolder}
                                    onSearch={actions.searchItems}
                                    style={{ width: 250 }}
                                    onChange={actions.searchOnChange}
                                />
                                <Space>
                                    <If condition={hasFeature('smart:tag:view')}>
                                        <Select
                                            value={locales.labels.batchActions}
                                            style={{ width: 120, marginRight: '10px' }}
                                            onChange={actions.selecthandleChange}
                                        >
                                            <If condition={hasFeature('smart:tag:delete')}>
                                                <Select.Option
                                                    value="delete"
                                                    style={{ padding: '0px' }}
                                                >
                                                    <Popconfirm
                                                        title={Udesk.utils.string.format(
                                                            locales.labels.deleteRecordInfo,
                                                            selectedItems.length
                                                        )}
                                                        okText={locales.labels.confirm}
                                                        cancelText={locales.labels.cancel}
                                                        onCancel={actions.deleteRecordCancel}
                                                        onConfirm={actions.deleteRecordOk}
                                                    >
                                                        <a
                                                            href=" "
                                                            onClick={actions.deletReordClick}
                                                            style={{
                                                                display: 'inline-block',
                                                                width: '100%',
                                                                padding: '5px 12px',
                                                            }}
                                                        >
                                                            {locales.labels.deleteRecord}
                                                        </a>
                                                    </Popconfirm>
                                                </Select.Option>
                                            </If>
                                            <If
                                                condition={
                                                    hasFeature('smart:tag:redo') &&
                                                    funcType === 'QUALITY_INSPECT'
                                                }
                                            >
                                                <Select.Option value="redo">
                                                    {locales.labels.redo}
                                                </Select.Option>
                                            </If>
                                        </Select>
                                    </If>
                                    <If condition={hasFeature('smart:tag:add')}>
                                        <Button type="primary" onClick={actions.createItem}>
                                            {route.name === 'keyEventsTagsIndex'
                                                ? intelligentTagsLocales.createEvent
                                                : intelligentTagsLocales.createTag}
                                        </Button>
                                    </If>
                                </Space>
                            </div>
                            <AutoSizeTable
                                columns={columns}
                                dataSource={smartTags}
                                // checkable={true}
                                subtractedHeight={144}
                                rowSelection={{
                                    onSelectAll: actions.onSelectAll,
                                    selectedRowKeys: selectedItems.map((i) => i.id),
                                    onSelect: actions.onCheckChange,
                                }}
                                loading={loading}
                                pagination={false}
                            />
                            <UdeskPagination
                                current={pageNum}
                                pageSize={pageSize}
                                total={total}
                                onChange={actions.pageChanged}
                            />
                        </div>
                    </div>
                </If>
                <Modal
                    visible={selectCallTimeVisible}
                    title={locales.labels.redo}
                    closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right"
                    onCancel={actions.redo.params(false)}
                    onOk={actions.redo.params(true)}
                    headerClassName="udesk-qa-modal-default-header"
                    footerClassName="udesk-qa-modal-default-footer"
                    confirmLoading={redoLoading}
                    destroyOnClose={true}
                >
                    <SelectCallTimer
                        onDateChanged={actions.onDateChanged}
                        recordCount={redoCallIds.length}
                    >
                        <Checkbox
                            checked={privates.cusFilterVisible}
                            onChange={actions.onFilterTypeChange}
                        >{/* 高级筛选 */}{UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.template.advancedFiltering}</Checkbox>
                        {privates.cusFilterVisible && (
                            <FormBuilder
                                ref={privates.formBuilderInstance}
                                initialValues={{
                                    type: privates.currentFilterDataSource,
                                    filter: {
                                        judgeStrategy: 1,
                                        customJudgeLogic: '',
                                    },
                                }}
                                customComponents={{ CustomFilter }}
                                fields={[
                                    {
                                        type: 'Select',
                                        name: 'type',
                                        label: /* 数据类型 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.template.dataType,
                                        props: {
                                            onChange: (value) => {
                                                this.privates.currentFilterDataSource = value;
                                                this.actions.update();
                                                this.actions.onFilterValueChange();
                                            },
                                            options: privates.filterDataSource.map((i) => ({
                                                label: i.name,
                                                value: i.id,
                                            })),
                                            style: {
                                                width: 200,
                                            },
                                        },
                                        effect: {
                                            value: privates.filterDataSource.reduce((p, c) => {
                                                p[c.id] = [
                                                    {
                                                        name: ['filter'],
                                                        output: {
                                                            judgeStrategy: 1,
                                                            customJudgeLogic: '',
                                                            conditionList: [],
                                                        },
                                                    },
                                                ];
                                                return p;
                                            }, {}),
                                        },
                                    },
                                    {
                                        type: 'CustomFilter',
                                        name: 'filter',
                                        label: /* 筛选条件 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.template.filterCriteria,
                                        rules: [{ required: true }],
                                        props: {
                                            type: privates.currentFilterDataSource,
                                            onChange: this.actions.onFilterValueChange,
                                            filterList: [
                                                'a_9',
                                                'a_35',
                                                'a_36',
                                                'a_228',
                                                'a_229',
                                                'a_505',
                                                'a_506',
                                                'a_480',
                                            ],
                                        },
                                    },
                                ]}
                                footerRender={false}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                            />
                        )}
                    </SelectCallTimer>
                </Modal>
                <Modal
                    title={`${
                        currentTagClassification.id ? locales.labels.edit : locales.labels.create
                    }${locales.labels.category}`}
                    onOk={actions.tagClassificationModalHandleOk}
                    confirmLoading={confirmLoading}
                    onCancel={actions.tagClassificationModalHandleCancel}
                    visible={tagClassificationModalVisible}
                    destroyOnClose
                >
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={
                            currentTagClassification.id
                                ? currentTagClassification
                                : {
                                    categoryName: `${
                                        locales.components.pages.intelligentTags
                                            .defaultCategoryName
                                    }${smartTagCategorys.length + 1}`,
                                    categoryColor: '#001eff',
                                }
                        }
                        ref={formRef}
                        onFinish={actions.onFinish}
                    >
                        <Form.Item
                            label={intelligentTagsLocales.categoryName}
                            name="categoryName"
                            rules={[
                                { required: true },
                                {
                                    pattern: new RegExp('^.{1,40}$'),
                                    message:
                                    /* 名称长度不能超过40 */ locales.pages.intelligentAnalysis
                                        .intelligentTags.template.nameLengthCannotExceed,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={intelligentTagsLocales.categoryColor}
                            name="categoryColor"
                        >
                            <input
                                className="udesk-qa-ui-input"
                                style={{ width: 70 }}
                                type="color"
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}