import LocalesManagerClass from 'udesk-web-toolkits/src/udesk/locales/locales-manager';
import reactUdeskLocales from 'udesk-react/src/udesk/locales';
import toolkitUdeskLocales from 'udesk-web-toolkits/src/udesk/locales';
import Chinese from './zh-cn';
import English from './en-us';
import ChineseTw from './zh-tw';
const localesStore = {
    'ZH-CN': Chinese,
    'EN-US': English,
    'ZH-TW': ChineseTw,
};

class BiLocalesManagerClass extends LocalesManagerClass {
    setLanguage(languageCode) {
        super.setLanguage(languageCode);
        reactUdeskLocales.setLanguage(languageCode);
    }
}

let localesManager = new BiLocalesManagerClass(localesStore, 'ZH-CN');
localesManager.merge(reactUdeskLocales, toolkitUdeskLocales);
export default localesManager;
