import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import UdeskLocales from 'UdeskLocales';
import { Space, Button } from "udesk-ui";
import { useDisabledForStartButton } from '../record/hook';
import { isExercise, notifyASREmpty } from '../util';
import { isNotEmpty } from 'src/util/core';
import './index.less';

export default React.memo((props: any) => {
    const {
        isHiddenButton = false,
        className = 'record-toolbar',
        direction = 'horizontal',
        goIndex,
        current,
        task,
        recordText,
        isStartTask,
        isPauseRecord,
        isLastPage,
        isOver,
        pause,
        stop,
        startTaskHandle,
        startAudioRecord,
        taskMessageModalRef,
        loadingForTaskStart,
        loadingForSendMessage,
    } = props;
    const divRef = useRef<HTMLDivElement>(null);
    const [isDisabled] = useDisabledForStartButton(task);

    const start = useCallback(() => {
        goIndex(0);
        startTaskHandle();
    }, [goIndex, startTaskHandle]);

    const sendButtonText = useMemo(() => {
        if (isPauseRecord) {
            return /* 继续录音 */UdeskLocales['current'].pages.coach.learningCenter.components.wave.index.continueRecording;
        }
        if (isLastPage) {
            if (isOver) {
                return /* 提交 */UdeskLocales['current'].pages.coach.learningCenter.components.wave.index.submit;
            }
            return /* 结束录音 */UdeskLocales['current'].pages.coach.learningCenter.components.wave.index.endRecording;
        }
        return /* 暂停录音 */UdeskLocales['current'].pages.coach.learningCenter.components.wave.index.suspendRecording;
    }, [isLastPage, isOver, isPauseRecord]);

    const clickHandle = useCallback(() => {
        taskMessageModalRef.current?.open(3); // 提交确认
    }, [taskMessageModalRef]);

    /**
     * 当上一页为暂停时，翻到下一页也将会是暂停，所以这里会重新开始录音
     * 当为最后一页时，如果isOver!=true时，将会直接结束录音，最后一页不再有暂停&继续的逻辑
     */
    const sendButtonClickHandle = useCallback(() => {
        if (!current.isStartRecord) {
            current.isStartRecord = true;
            return startAudioRecord({isStart: true});
        }
        if (isPauseRecord) {
            return pause();
        }
        if (isLastPage) {
            if (isOver) {
                return clickHandle();
            } else if (recordText) {
                return stop();
            } else {
                return notifyASREmpty();
            }
        }
        return pause();
    }, [
        current,
        recordText,
        isLastPage,
        pause,
        startAudioRecord,
        isOver,
        clickHandle,
        stop,
        isPauseRecord,
    ]);

    useEffect(() => {
        divRef.current?.scrollTo({
            top: divRef.current?.scrollHeight, behavior: 'smooth',
        });
    }, [recordText]);

    return (
        <div className={className}>
            {
                isHiddenButton ? (
                    <div ref={divRef} className={'text'}>{recordText}</div>
                ) : (
                    <Space direction={direction}>
                        <div>
                            <div className={'wave'}></div>
                            {
                                isNotEmpty(recordText, () => (
                                    <div ref={divRef} className={'text'}>{recordText}</div>
                                ))
                            }
                        </div> 
                        <Button
                            loading={loadingForSendMessage}
                            type='primary'
                            size='large'
                            onClick={sendButtonClickHandle}
                        >
                            {sendButtonText}
                        </Button>
                    </Space>
                )
            }
            {
                !isStartTask && (
                    <div className='start-button'>
                        <Button
                            disabled={isDisabled}
                            loading={loadingForTaskStart}
                            type='primary'
                            size='large'
                            onClick={start}
                        >
                            {isExercise(task?.taskType, /* 开始练习 */UdeskLocales['current'].pages.coach.learningCenter.components.wave.index.startPracticing, /* 开始考试 */UdeskLocales['current'].pages.coach.learningCenter.components.wave.index.startTheExam)}
                        </Button>
                    </div>
                )
            }
        </div>
    );
});