import Udesk from 'Udesk';
import TasksCheckPointDetailTemplate from '../detail/template-new';

export default class TasksCheckPointNewRoute extends TasksCheckPointDetailTemplate {
    model() {
        const { props } = this;
        let params = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));

        let modelPromise = Promise.resolve({
            type: Udesk.enums.scoreType.smartRules.id,
            gradeType: Udesk.enums.gradeTypes.yesOrNo.id,
            scoreType: Udesk.enums.calculateScoreTypes.add.id
        });

        let categoriesPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/interactiveCategories/initTree', props.sdkOptions);
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(getTreeData(resp.data));
                },
                reason => {
                    reject(reason);
                }
            );
        });

        let foctorsPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/interactiveDatas/multiElements', props.sdkOptions);
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    reject(reason);
                }
            );
        });
        let conditionPromise = Promise.resolve({ applyRole: Udesk.enums.applyRoles.all.id });
        let templatePromise = null;
        if (params && params.templateId) {
            templatePromise = new Promise((resolve, reject) => {
                let url = Udesk.business.apiPath.concatApiPath(
                    `/inspectionPointTemplates/${params.templateId}`,
                    props.sdkOptions);
                Udesk.ajax.get(url).then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        reject(reason);
                    }
                );
            });
        }
        if (params && params.moduleId) {
            templatePromise = new Promise((resolve, reject) => {
                let url = Udesk.business.apiPath.concatApiPath(
                    `/inspectionModules/moduleDetail/${params.moduleId}/dataSource/${this.props.sdkOptions.props.task.inspectDataSource}`,
                    props.sdkOptions);
                Udesk.ajax.get(url).then(
                    resp => {
                        //这个地方需要去除id，此id是样例的id
                        const {id, ...data} = resp.data;// eslint-disable-line no-unused-vars
                        resolve(data);
                    },
                    reason => {
                        reject(reason);
                    }
                );
            });
        }
        return {
            taskId: params.taskId,
            categoryId: params.categoryId,
            inspectionPoint: templatePromise || modelPromise,
            categories: categoriesPromise,
            foctorsData: foctorsPromise,
            task: props.sdkOptions.props.task,
            inspectionConditions: props.sdkOptions.props.inspectionConditions,
            basicInfoFields: props.sdkOptions.props.basicInfoFields,
            condition: conditionPromise,
            template: templatePromise,
        };
    }
}

function getTreeData(tree) {
    return tree.map(item => {
        return { key: item.id, value: item.id, title: item.name, children: getTreeData(item.children) };
    });
}