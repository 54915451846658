import Recorder, { RealTimeSendTry, RealTimeSendTryReset } from './util';
import { useCallback, useEffect, useRef, useState } from 'react';
import { sha256 } from 'js-sha256';
import { fire } from 'src/util/core';
import UdeskLocales from 'UdeskLocales';

// const errorCorrectionUrl = '/v1/nlp/error_correction/query';

let useProConfig = false; // 测试使用，生产环境下需要关闭
// let protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
let protocol = 'wss://';
let [AsrWebsocketUrl, appId, appSecret] = JSON.parse(process.env.REACT_APP_ASR || '[]');

if (useProConfig) {
    protocol = 'wss://';
    AsrWebsocketUrl = 'asr-service.s4.udesk.cn'; // 生产环境
    appId = '2016';
    appSecret = 'fd4d7d03-03ea-48a3-b472-a8e2001819fa';
}

const useAsr = ({ onASRTextChange }) => {
    const isCreatingRef = useRef<boolean>(false);
    const websocketRef = useRef<WebSocket>();
    const isLastFrameRef = useRef<boolean>(false);
    const allFrame = useRef<any[]>([]);

    const createAsrWebsocket = useCallback((callback?) => {
        if (isCreatingRef.current) {
            return;
        } else {
            isCreatingRef.current = true;
        }
        const timestamp = Math.floor(new Date().getTime() / 1000);
        const sign = sha256.hex([appId, appSecret, timestamp].join(''));

        const websocket: WebSocket = new WebSocket(
            `${
                protocol + AsrWebsocketUrl
            }/v1/iat?app_id=${appId}&sign=${sign}&timestamp=${timestamp}`
        );

        websocket.onerror = function () {
            isCreatingRef.current = false;
        };

        //开启连接open后客户端处理方法
        websocket.onopen = function () {
            console.log(/* 当前客户端已经连接到ASR Websocket服务器 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.hooks.input.theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer);
            websocket.send({
                option: {
                    app_id: appId,
                    language: 'zh_cn',
                    app_bussiness_token: 'sign',
                    app_group_id: '1',
                    domain: 'iat',
                    accent: 'mandarin',
                },
            } as any);
            fire(callback);
            isCreatingRef.current = false;
        };
        // 接收消息后客户端处理方法
        websocket.onmessage = function (evt) {
            const result = JSON.parse(evt.data);
            console.log('why u run??? ', result?.data?.result?.ws?.[0]?.cw?.[0]?.w);
            if (result.code === 0) {
                onASRTextChange({
                    additionalText: result.data.result.ws?.[0]?.cw?.[0]?.w,
                });
                // setInput((prev) => {
                //     if (!prev.isAsrOver) {
                //         prev.text += result.data.result.ws?.[0]?.cw?.[0]?.w;
                //         console.log('prev.text', prev.text);

                //         prev.isAsrOver = prev.isOver;
                //     }
                //     return { ...prev };
                // });
                // 处理完最后一帧后， 将状态恢复
                if (isLastFrameRef.current) {
                    console.log(/* 处理完最后一帧后， 将状态恢复 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.hooks.input.afterProcessingTheLastFrameRestoreTheState);
                    isLastFrameRef.current = false;
                }
            }
        };
        // 关闭websocket
        websocket.onclose = function () {
            console.log(/* ASR连接已关闭... */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.hooks.input.aSRConnectionClosed);
        };

        return (websocketRef.current = websocket);
    }, []);

    // 通过websocket发送消息到服务器
    // 这里是通过数组长度决定发送次数的， 这个函数可能会被多个线程多次执行，但是执行数量和顺序不变
    const sendMessage = useCallback(() => {
        const list = allFrame.current;
        const asrWebsocket: any = websocketRef.current;

        if (!isLastFrameRef.current) {
            const frame = list.shift();

            if (frame) {
                const status = frame.status;

                // 记录最后一帧的状态
                if (status === 2) {
                    isLastFrameRef.current = true;
                }
                // 暂定时将结束ASR转译，但是并会不会标记当前为结束帧。
                // console.log('ASR正在向后台发送数据---------->', frame, list.length);
                asrWebsocket.send(
                    JSON.stringify({
                        data: {
                            ...frame,
                            status: status === 3 ? 2 : status,
                        },
                    })
                );
                sendMessage();
            }
        } else {
            // 如果是最后一帧，将等待300毫秒再执行发送命令
            if (list.length > 0) {
                // setTimeout(sendMessage, 300);
                console.log(/* 录音暂停，并且已处理完，但是缓存中仍然存在数据 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.hooks.input.recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache);
            }
        }
    }, []);

    /**
     * @status 0:第一帧，1:中间帧，2:最后一帧 3:录音暂停，但是会向ASR服务发送 status = 2
     * @audio Base64文本
     */
    const updateAsrInfo = useCallback(
        (status: number, audio: string | null) => {
            const asrWebsocket: any = websocketRef.current;

            // 将数据放到缓存中
            if (status !== undefined) {
                allFrame.current.push({
                    status,
                    format: 'audio/L16;rate=8000',
                    encoding: 'rwa',
                    audio,
                });
            }

            if (asrWebsocket) {
                if (asrWebsocket.readyState === WebSocket.OPEN) {
                    sendMessage();
                }
                if (asrWebsocket.readyState === WebSocket.CONNECTING) {
                    setTimeout(updateAsrInfo, 10);
                }
                if ([WebSocket.CLOSED, WebSocket.CLOSING].includes(asrWebsocket.readyState)) {
                    console.log(/* 发现ASR通道关闭，重新创建Websocket链接 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.hooks.input.foundASRChannelClosedRecreateWebsocketLink);
                    createAsrWebsocket(() => {
                        sendMessage();
                    });
                }
            } else {
                console.log(/* 发现ASR通道未开启，重新创建Websocket链接 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.hooks.input.foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink);
                createAsrWebsocket(() => {
                    sendMessage();
                });
            }
        },
        [createAsrWebsocket, sendMessage]
    );

    useEffect(() => {
        return () => {
            websocketRef.current?.close();
            websocketRef.current = undefined;
        };
    }, []);

    return [updateAsrInfo];
};

const useWave = (list) => {
    const recordWaveListRef = useRef<any[]>([]);

    const createRecordWave = useCallback((elem) => {
        return Recorder.FrequencyHistogramView({
            elem, //自动显示到dom，并以此dom大小为显示大小
            lineCount: 120,
            position: 0,
            minHeight: 1,
            fallDuration: 400,
            stripeEnable: false,
            // mirrorEnable: true,
            linear: [0, '#1A6EFF', 1, '#4FAAFF'],
        });
    }, []);

    const updateWave = useCallback((buffer: any, powerLevel: number, bufferSampleRate: number) => {
        recordWaveListRef.current = list.map((elem, index) => {
            if (elem) {
                if (typeof elem == 'string') {
                    elem = document.querySelector(elem);
                } else if (elem.length) {
                    elem = elem[0];
                }
            }
            let wave = recordWaveListRef.current?.[index];
            if (wave) {
                if (elem === wave.elem) {
                    return wave;
                }
            }
            return createRecordWave(elem);
        });
        recordWaveListRef.current.forEach((wave) => {
            wave.input(buffer, powerLevel, bufferSampleRate);
        });
    }, []);

    useEffect(() => {
        return () => {
            recordWaveListRef.current?.forEach((wave) => {
                wave.elem.innerHTML = '';
            });
            recordWaveListRef.current = [];
        };
    }, []);

    return [updateWave];
};

type AudioFile = {
    id: number; // 前端自增长ID，由前端自己生成
    text: string; // 转译文本
    blob: Blob | null; // 文件内容
    duration: number; // 文件时长
    url: string; // 文件路径, 默认为本地路径，上传成功后会更改为网络地址
    isOver: boolean; // 文件暂停，开始等待ASR及上传结束
    isAsrOver: boolean; // ASR转译状态
    isFileUploadOver: boolean; // 文件上传状态
};

export const useAudioRecorder = (task) => {
    // const [list, setList] = useState<AudioFile[]>([]);
    const [input, setInput] = useState<AudioFile>({
        id: 1,
        text: '',
        blob: null,
        duration: 0,
        url: '',
        isOver: false,
        isAsrOver: false,
        isFileUploadOver: false,
    });
    const [updateAsrInfo] = useAsr({
        onASRTextChange: ({ additionalText }) => {
            setInput((prev) => {
                let res = { ...prev };
                if (!prev.isAsrOver) {
                    res.text = prev.text + additionalText;
                    res.isAsrOver = prev.isOver;
                }
                return res;
            });
        },
    });
    const [updateWave] = useWave(['.wave']);
    const recorderRef = useRef<any>();

    const createRecorder = useCallback(() => {
        const recorder = Recorder({
            type: 'wav',
            sampleRate: 8000,
            bitRate: 16,
            onProcess: function (
                buffers: any,
                powerLevel: number,
                bufferDuration: number,
                bufferSampleRate: number
            ) {
                //推入实时处理，因为是unknown格式，buffers和rec.buffers是完全相同的，只需清理buffers就能释放内存。
                RealTimeSendTry(buffers, bufferSampleRate, false, (number, blob) => {
                    if (blob) {
                        const reader = new FileReader();
                        reader.onloadend = function () {
                            updateAsrInfo(
                                number === 1 ? 0 : 1,
                                (/.+;\s*base64\s*,\s*(.+)$/i.exec(reader?.result as string) ||
                                    [])[1]
                            );
                        };
                        reader.readAsDataURL(blob);
                    }
                });
                updateWave(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
            },
        });

        return (recorderRef.current = recorder);
    }, [updateAsrInfo, updateWave]);

    const startRecord = useCallback(() => {
        recorderRef.current.open(
            function () {
                //打开麦克风授权获得相关资源
                recorderRef.current.start(); //开始录音
                RealTimeSendTryReset(); //重置环境，开始录音时必须调用一次\
                setInput((prev) => ({
                    id: prev.id++,
                    text: '',
                    blob: null,
                    duration: 0,
                    url: '',
                    isOver: false,
                    isAsrOver: false,
                    isFileUploadOver: false,
                }));
            },
            function (msg: string, isUserNotAllow: boolean) {
                throw new Error((isUserNotAllow ? 'UserNotAllow，' : '') + /* 无法录音: */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.hooks.input.unableToRecord + msg);
            }
        );
    }, []);

    // const uploadFile = useDebounce(
    //     (active, errorCallback) => {
    //         UploadFile(
    //             new window.File([active.blob], `task-${task.id}-${active.id}.wav`),
    //             ({ url }) => {
    //                 setInput(prev => {
    //                     return {

    //                     }
    //                 })
    //                 setList((list) => {
    //                     return list.map((item) => {
    //                         if (item.id === active.id) {
    //                             // 这里清空blob文件的内存占用
    //                             if (item.url) {
    //                                 (window.URL || window.webkitURL).revokeObjectURL(item.url);
    //                             }
    //                             item.url = url;
    //                             item.blob = null; //这里释放blob的数据
    //                             item.isFileUploadOver = true;
    //                         }
    //                         return item;
    //                     });
    //                 });
    //             },
    //             (file, error) => {
    //                 fire(errorCallback, error);
    //             }
    //         );
    //     },
    //     [task?.id]
    // );

    const command = useCallback(
        (state: 2 | 3) => {
            // 这里等待最后一帧的数据，暂停或停止并不能立即结束流
            setTimeout(() => {
                RealTimeSendTry([], 0, true, () => updateAsrInfo(state, '')); //最后一次发送
            }, 300);
        },
        [updateAsrInfo]
    );

    const pauseRecord = useCallback(() => {
        const recorder = recorderRef.current;
        const state = recorder?.state; // 0未录音 1录音中 2暂停 3等待ctx激活

        if ([1, 2].includes(state)) {
            recorder.stop((blob: any, duration?: number) => {
                setInput((prev) => {
                    if (!prev.isOver) {
                        prev.blob = blob;
                        prev.duration = duration || 0;
                        prev.url = (window.URL || window.webkitURL).createObjectURL(blob);
                        prev.isOver = true;
                    }
                    setTimeout(() => {
                        if (prev) {
                            prev.isAsrOver = true;
                        }
                    }, 1000);

                    return {
                        ...prev,
                    };
                });
            });

            command(2); //最后一次发送
        }
    }, [command]);

    const abortRecord = useCallback(() => {
        const recorder = recorderRef.current;
        const state = recorder?.state; // 0未录音 1录音中 2暂停 3等待ctx激活

        if ([1, 2].includes(state)) {
            recorder.stop((blob: any, duration?: number) => {
                setInput((prev) => ({
                    id: prev.id,
                    text: '',
                    blob: null,
                    duration: 0,
                    url: '',
                    isOver: false,
                    isAsrOver: false,
                    isFileUploadOver: false,
                }));
            });

            command(2); //最后一次发送
        }
    }, [command]);

    useEffect(() => {
        let recorder = recorderRef.current;

        if (!recorder) {
            console.log(/* 创建Recorder服务 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.hooks.input.creatingARecorderService);
            recorder = createRecorder();
        }

        return () => {
            console.log(/* 关闭Recorder服务 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.hooks.input.turnOffTheRecorderService);
            recorder?.close();
        };
    }, [createRecorder]);

    // return { input, startRecord, pauseRecord, abortRecord, uploadFile };
    return { input, startRecord, pauseRecord, abortRecord };
};
