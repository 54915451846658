import React from 'react';
// import Locales from 'UdeskLocales';
// import Udesk from 'Udesk';
import { Tag, Space } from 'udesk-ui';
import styled from 'styled-components';
import { useWrongQuestionSetContext } from '../../context';
import UdeskLocales from 'UdeskLocales';

const Text = (props) => {
    const { before, after, children, color } = props;
    return (
        <Space>
            {!!before && <span>{before}</span>}
            <span
                style={{
                    color,
                }}
            >
                {children ?? '-'}
            </span>
            {!!after && <span>{after}</span>}
        </Space>
    );
};

const ScoreRuleListWrap = styled.div`
    height: 100%;
    table {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        height: 100%;
        table-layout: fixed;
        /* text-align: center; */
    }

    table td {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 8px;
        .udesk-qa-ui-tag {
            color: #1a6eff;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

export const ScoreRuleList = (props) => {
    // const locales = Locales['current'];

    // const { status } = props;
    const context = useWrongQuestionSetContext();
    const { mistakeRuleDetailList = [] } = context;

    return (
        <ScoreRuleListWrap>
            <table>
                <colgroup>
                    <col width="40%" />
                    <col width="20%" />
                    <col width="20%" />
                    <col width="20%" />
                </colgroup>
                {mistakeRuleDetailList?.map((i) => (
                    <tr>
                        {React.Children.map(
                            [
                                <Tag color="#E6F3FF" title={i.ruleName ?? '-'}>
                                    {i.ruleName ?? '-'}
                                </Tag>,
                                <Text after={/* 分 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.scoreRuleList.branch} color="#1A6EFF">
                                    {i.ruleScore}
                                </Text>,
                                <Text before={/* 答错 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.scoreRuleList.answerIncorrectly} after={/* 次 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.scoreRuleList.second} color="#FB5127">
                                    {i.wrongTimes}
                                </Text>,
                                <Text before={/* 答错率 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.scoreRuleList.wrongAnswerRate} color="#FB5127">
                                    {`${i.wrongRatio ?? '-'}%`}
                                </Text>,
                            ],
                            (child: any, index: number) => {
                                return <td>{child}</td>;
                            }
                        )}
                    </tr>
                )) ?? null}
            </table>
        </ScoreRuleListWrap>
    );
};
