import React from 'react';
import Udesk from 'Udesk';
import AdminSystemIndexRoute from './route';
import { PageHeader, Button, Select } from 'udesk-ui';
import './style.scss';

export default class AdminSystemIndexTemplate extends AdminSystemIndexRoute {
    render() {
        let { actions, locales, privates } = this;
        return (
            // <div className="page admin-system-index">
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.pages.admin.system.index.name}
                />
                <div className="udesk-qa-web-page-body">
                    <div
                        className="udesk-qa-web-page-body-root qa-page-admin-systom-index"
                        style={{ padding: 16 }}
                    >
                        <div className="page-content">
                            <div className="x_panel clearfix">
                                <div className="x_content clearfix">
                                    <div className="col-lg-9 col-md-9 col-xs-12 col-12">
                                        <If
                                            condition={
                                                Udesk.data.init.user &&
                                                Udesk.data.init.user.hasFeature('sys:info:view')
                                            }
                                        >
                                            <form className="form-horizontal form-label-left">
                                                <div className="form-group">
                                                    <label
                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                        style={{ padding: '0 15px' }}
                                                        htmlFor="system-name"
                                                    >
                                                        {
                                                            locales.pages.admin.system.index
                                                                .systemName
                                                        }
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <input
                                                            type="text"
                                                            id="system-name"
                                                            className="form-control"
                                                            value={privates.model.sysConfig.sysName}
                                                            disabled={
                                                                !Udesk.data.init.user ||
                                                                !Udesk.data.init.user.hasFeature(
                                                                    'sys:info:edit'
                                                                )
                                                            }
                                                            onChange={actions.onValueChanged.params(
                                                                privates.model.sysConfig,
                                                                'sysName'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                        style={{ padding: '0 15px' }}
                                                    >
                                                        {locales.pages.admin.system.index.lang}
                                                    </label>
                                                    <div
                                                        className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                        style={{ height: 30, lineHeight: '30px' }}
                                                    >
                                                        <Select
                                                            defaultValue={
                                                                Udesk.data.init.userLanguage
                                                            }
                                                            disabled={
                                                                !Udesk.data.init.user ||
                                                                !Udesk.data.init.user.hasFeature(
                                                                    'sys:info:edit'
                                                                )
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'zh',
                                                                    label: locales.pages.admin
                                                                        .system.index.zh,
                                                                },
                                                                {
                                                                    value: 'en',
                                                                    label: locales.pages.admin
                                                                        .system.index.en,
                                                                },
                                                                {
                                                                    value: 'tw',
                                                                    label: locales.pages.admin
                                                                        .system.index.tw,
                                                                },
                                                            ]}
                                                            onChange={actions.onUserLanguageChange}
                                                            style={{
                                                                width: 200,
                                                            }}
                                                        ></Select>
                                                    </div>
                                                </div>
                                                <If
                                                    condition={
                                                        Udesk.data.init.user &&
                                                        Udesk.data.init.user.hasFeature(
                                                            'sys:info:edit'
                                                        )
                                                    }
                                                >
                                                    <div className="form-group">
                                                        <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
                                                            <Button
                                                                type="primary"
                                                                onClick={actions.save}
                                                            >
                                                                {locales.labels.save}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </If>
                                            </form>
                                        </If>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
