import tranToTaskCenterNotification from 'Component/common/task-notification';
import React from 'react';
import { postDataCallNum } from 'src/api/data/call-num';
import Udesk from 'Udesk';
import { Popconfirm } from 'udesk-ui';
import { getCurrentRouteName, getSystemModule } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

const DEFAULT_PAGE = {
    pageNum: Udesk.config.paging.defaultPageNumber,
    pageSize: 10,
    total: null,
};
class IntelligentTagsComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {
        columns: [],
        pageNum: DEFAULT_PAGE.pageNum,
        pageSize: DEFAULT_PAGE.pageSize,
        total: DEFAULT_PAGE.total,
    };
    privates = {
        tagName: null,
        smartTags: [],
        selectTagClassification: undefined,
        originalSmartTagCategorys: [],
        smartTagCategorys: [],
        smartTagCategorysTree: [],
        isClassificationVisible: false,
        selectedItems: [],
        selectCallTimeVisible: false,
        redoCallIds: [],
        callTimeRange: [],
        currentTagClassification: {},
        tagClassificationModalVisible: false,
        confirmLoading: false,
        formRef: React.createRef(),
        loading: false,
        tabsType: 'intellect',
        funcType: undefined,
        storages: {
            tagName: null,
            funcType: 'QUALITY_INSPECT',
            selectTagClassification: undefined,
            page: DEFAULT_PAGE,
        },
        redoLoading: false,
        parentClassification: null,
        filterTypes: [
            {
                label: /* 标准筛选 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.component.standardScreening,
                value: '1',
            },
            {
                label: /* 高级筛选 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.component.advancedFiltering,
                value: '2',
            },
        ],
        cusFilterVisible: false,
        filterDataSource: [],
        currentFilterDataSource: null,
        defaultFilterDataSource: null,
        formBuilderInstance: React.createRef(),
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'sessionStorage',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/intelligent-analysis\/intelligent-tags\//i,
            ],
        },
    };
    actions = {
        onCheckChange(record, selected, selectedRows, nativeEvent) {
            if (selected) {
                this.privates.selectedItems = [...this.privates.selectedItems, record];
            } else {
                this.privates.selectedItems = this.privates.selectedItems.filter((i) => i.key !== record.key);
            }
            this.actions.update();
        },
        onSelectAll(selected, selectedRows, changeRows) {
            if (selected) {
                this.privates.selectedItems = [...this.privates.selectedItems, ...changeRows];
            } else {
                this.privates.selectedItems = this.privates.selectedItems.filter(
                    (i) => !changeRows.some((c) => c.key === i.key)
                );
            }
            this.actions.update();
        },
        deletReordClick(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        deleteRecordCancel() {},
        deleteRecordOk() {
            let { locales } = this;
            let { sdkOptions } = this.props;
            let { selectedItems } = this.privates;
            let ids = selectedItems.map((item) => item.id);
            if (ids.length === 0) return;
            let params = {
                ids,
            };
            let url = Udesk.business.apiPath.concatApiPath('smartTags', sdkOptions);
            Udesk.ajax.del(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                    this.privates.selectedItems = [];
                    let { selectTagClassification } = this.privates;
                    this.actions.reloadAsyncModel({ categoryId: selectTagClassification });
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || locales.business.notifyMessage.deleteFailed);
                }
            );
        },
        selecthandleChange(value) {
            if (value === 'delete') {
                return;
            }
            let { locales } = this;
            let { selectedItems } = this.privates;
            if (selectedItems.length === 0) {
                return Udesk.ui.notify.error(locales.components.pages.intelligentTags.index.selectItmeCountError);
            }
            if (value === 'redo') {
                this.privates.selectCallTimeVisible = true;
            }
            this.actions.update();
        },
        redo(value) {
            const resetRedoFilter = () => {
                this.privates.callTimeRange = [];
                this.privates.redoCallIds = [];
                this.privates.currentFilterDataSource = this.privates.defaultFilterDataSource;
                this.privates.cusFilterVisible = false;
                this.actions.update();
            };

            this.privates.selectCallTimeVisible = false;
            this.actions.update();
            if (!value) {
                resetRedoFilter();
                return;
            }

            let { locales } = this;

            let { callTimeRange, redoCallIds, selectedItems } = this.privates;
            if (moment(callTimeRange[0]).add(31, 'days') <= callTimeRange[1]) {
                return Udesk.ui.notify.error(locales.components.pages.intelligentTags.index.callTimeError);
            }
            if (redoCallIds.length === 0) {
                return Udesk.ui.notify.error(locales.components.pages.intelligentTags.index.callRecordCountError);
            }
            let url = Udesk.business.apiPath.concatApiPath('smartTags/redo', this.props.sdkOptions);
            let data = {
                startTime: moment(callTimeRange[0])
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(callTimeRange[1])
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .millisecond(999)
                    .format('YYYY-MM-DD HH:mm:ss'),
                ids: selectedItems.map((item) => item.id),
                processCount: redoCallIds.length,
                callIds: redoCallIds,
                relations: selectedItems.map((item) => {
                    return { tagId: item.id, tagType: item.tagType, categoryId: item.categoryId };
                }),
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, data).then(
                (resp) => {
                    Udesk.ui.notify.success(locales.components.pages.intelligentTags.index.redoSuccess);
                    tranToTaskCenterNotification();
                    this.privates.selectedItems = [];
                    resetRedoFilter();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || locales.components.pages.intelligentTags.redoError);
                    this.privates.callTimeRange = [];
                    this.privates.redoCallIds = [];
                }
            );
        },
        pageChanged(pageNum, pageSize) {
            let { selectTagClassification } = this.privates;
            this.actions.reloadAsyncModel({ pageNum, pageSize, categoryId: selectTagClassification });
        },
        searchItems(value) {
            this.actions.reloadAsyncModel({
                pageNum: Udesk.config.paging.defaultPageNumber,
                pageSize: Udesk.config.paging.defaultPageSize,
            });
            return;
        },
        searchOnChange(e) {
            // this.actions.mutator('privates.tagName', (e) => e.target.value);
            this.privates.tagName = e.target.value;
            this.actions.update();
            if (e.target.value === '') {
                this.actions.searchItems();
            }
        },
        createItem() {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('intelligentTagsNew'),
                state: {
                    funcType: this.privates.funcType,
                    categoryId: this.privates.selectTagClassification,
                },
            };
            if (this.privates.tabsType === 'intellect') {
                routeOptions.routeName = getCurrentRouteName('intelligentTagsNew');
            } else if (this.privates.tabsType === 'label') {
                routeOptions.routeName = 'sessionLabelTagsNew';
            } else if (this.privates.tabsType === 'events') {
                routeOptions.routeName = 'keyEventsTagsNew';
            } else if (this.privates.tabsType === 'customer') {
                routeOptions.routeName = 'customerLabelTagsNew';
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        editItem(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('intelligentTagsEdit'),
                pathParams: {
                    id: item.id,
                },
                state: {
                    funcType: this.privates.funcType
                },
            };
            if (this.privates.tabsType === 'intellect') {
                routeOptions.routeName = getCurrentRouteName('intelligentTagsEdit');
            } else if (this.privates.tabsType === 'label') {
                routeOptions.routeName = 'sessionLabelTagsEdit';
            } else if (this.privates.tabsType === 'events') {
                routeOptions.routeName = 'keyEventsTagsEdit';
            } else if (this.privates.tabsType === 'customer') {
                routeOptions.routeName = 'customerLabelTagsEdit';
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        deleteTagClassification(item) {
            let { sdkOptions } = this.props;

            let url = Udesk.business.apiPath.concatApiPath(`smartTagCategorys/${item.id}`, sdkOptions);
            let params = {
                id: item.id,
            };
            Udesk.ajax.del(url, params).then(
                (resp) => {
                    this.privates.smartTagCategorys = this.privates.smartTagCategorys.filter((i) => i.id !== item.id);
                    if (this.privates.smartTagCategorys.length) {
                        this.actions.changeTagClassification({ key: `${this.privates.smartTagCategorys[0].id}` });
                    }
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        newClassification(item) {
            this.privates.parentClassification = item;
            this.privates.tagClassificationModalVisible = true;
            this.actions.update();
        },
        deleteItem(item) {
            let { locales } = this;
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`smartTags/${item.id}`, sdkOptions);
            Udesk.ajax.del(url).then(
                (resp) => {
                    Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                    let { selectTagClassification } = this.privates;
                    this.actions.reloadAsyncModel({ categoryId: selectTagClassification });
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || locales.business.notifyMessage.deleteFailed);
                }
            );
        },
        changeTagClassification({ item, key, keyPath, domEvent }) {
            if (this.privates.selectTagClassification !== key) {
                this.privates.selectTagClassification = key;
                this.privates.selectedItems = [];
            }
            this.actions.update();
            this.actions.reloadAsyncModel({
                categoryId: this.privates.selectTagClassification,
                pageNum: Udesk.config.paging.defaultPageNumber,
                // pageSize: Udesk.config.paging.defaultPageSize,
            });
        },
        editTagClassification(item) {
            this.privates.currentTagClassification = item;
            this.privates.tagClassificationModalVisible = true;
            this.actions.update();
        },
        tagClassificationModalHandleOk() {
            this.privates.formRef.current.submit();
        },
        tagClassificationModalHandleCancel() {
            this.privates.tagClassificationModalVisible = false;
            this.privates.parentClassification = null;
            this.privates.currentTagClassification = {};
            this.actions.update();
        },
        onFinish(values) {
            let smartTagCategorys = [];
            values.funcType = this.privates.funcType;
            if(this.privates.parentClassification) {
                values.parentId = this.privates.parentClassification.id;
                values.parentIds = [this.privates.parentClassification.parentIds, this.privates.parentClassification.id].filter(Boolean).join(',');
            } else {
                values.parentId = 0;
                values.parentIds = '0';
            }
            if (this.privates.currentTagClassification.id) {
                smartTagCategorys = this.privates.smartTagCategorys.map((category) => {
                    category.funcType = this.privates.funcType;
                    if (category.id === this.privates.currentTagClassification.id) {
                        return { ...category, ...values };
                    }
                    return category;
                });
            } else {
                smartTagCategorys = [...this.privates.smartTagCategorys];
                smartTagCategorys.push(values);
            }
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`smartTagCategorys`, sdkOptions);
            let params = smartTagCategorys;
            this.privates.confirmLoading = true;
            this.actions.update();
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    this.privates.confirmLoading = false;
                    this.privates.smartTagCategorys = resp.data;
                    this.actions.update();
                    this.actions.tagClassificationModalHandleCancel();
                    this.privates.selectTagClassification = null;
                    this.actions.reloadAsyncModel({
                        pageNum: Udesk.config.paging.defaultPageNumber,
                        pageSize: Udesk.config.paging.defaultPageSize,
                    });
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    this.privates.confirmLoading = false;
                    this.actions.update();
                }
            );
        },
        changeClassificationModalVisible() {
            let { isClassificationVisible, selectTagClassification } = this.privates;
            this.privates.isClassificationVisible = !isClassificationVisible;
            this.actions.update();
            if (isClassificationVisible) {
                if (selectTagClassification != null) {
                    this.actions.reloadAsyncModel({ categoryId: selectTagClassification });
                } else {
                    this.actions.reloadAsyncModel();
                }
            }
        },
        saveClassification(smartTagCategorys) {
            this.privates.smartTagCategorys = smartTagCategorys;
            this.actions.changeClassificationModalVisible();
        },
        deleteClassification(item) {
            let { selectTagClassification, smartTagCategorys } = this.privates;
            this.privates.smartTagCategorys = smartTagCategorys.filter((category) => {
                return category.id !== item.id;
            });
            if (selectTagClassification != null && selectTagClassification === item.id) {
                this.privates.selectTagClassification = '';
            }
            // this.actions.update();
        },
        getRequestDataCallNumParams(changedParams = {}) {
            const params = {
                startTime: moment(this.privates.callTimeRange[0]).hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(this.privates.callTimeRange[1]).hour(23).minute(59).second(59).millisecond(999).format('YYYY-MM-DD HH:mm:ss'),
                ...changedParams,
            };
            if(!this.privates.cusFilterVisible) {
                return params;
            }

            const inspectDataSource = this.privates.currentFilterDataSource * 1;
            const formValues = this.privates.formBuilderInstance?.current?.getFieldsValue?.() ?? {};

            return {
                ...formValues,
                inspectDataSource,
                ...params,
            };
        },
        onDateChanged(value) {
            this.privates.callTimeRange = value;
            this.actions.update();
            if (moment(value[0]).add(31, 'days') <= value[1]) {
                return Udesk.ui.notify.error(this.locales.components.pages.intelligentTags.index.callTimeError);
            }
            const data = this.actions.getRequestDataCallNumParams({
                startTime: moment(value[0]).hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(value[1]).hour(23).minute(59).second(59).millisecond(999).format('YYYY-MM-DD HH:mm:ss'),
            });
            this.actions.requestDataCallNum(data);
        },
        onFilterTypeChange({ target: { checked } }) {
            this.privates.cusFilterVisible = checked;
            this.actions.update();
            const data = this.actions.getRequestDataCallNumParams();

            this.actions.requestDataCallNum(data);
        },
        onFilterValueChange(params) {
            if(!this.privates.callTimeRange.length) {
                return Udesk.ui.notify.error(this.locales.components.pages.intelligentTags.index.callTimeError);
            }
            const data = this.actions.getRequestDataCallNumParams(params);

            this.actions.requestDataCallNum(data);
        },
        requestDataCallNum(params) {
            this.privates.redoLoading = true;
            this.actions.update();
            return postDataCallNum(params, {
                errorMsg: this.locales.components.pages.components.selectCallTime.getCallRecordError,
            }).then(
                (resp) => {
                    this.privates.redoCallIds = resp.data.ids;
                    this.actions.update();
                }
            ).finally(() => {
                this.privates.redoLoading = false;
                this.actions.update();
            });
        },
    };

    //#region Life Cycle
    componentDidMount() {
        const { location } = this.props;
        const {      
            tagName,       
            funcType,
            selectTagClassification,
            page
        } = this.privates.storages;

        this.privates.tagName = tagName;
        this.privates.funcType= funcType;
        this.privates.selectTagClassification= selectTagClassification;

        this.privates.filterDataSource = Udesk.business.task.filterEnabledtInspectDataSources();
        
        this.privates.defaultFilterDataSource = this.privates.filterDataSource[0].id;
        this.privates.currentFilterDataSource = this.privates.defaultFilterDataSource;

        this.setState({
            ...page
        });

        if (location.pathname.indexOf('/intelligent-tags/smart-conversation/index') >= 0) {
            this.privates.tabsType = 'intellect';
            this.privates.funcType = 'QUALITY_INSPECT';
        } else if (location.pathname.indexOf('/intelligent-tags/session-label/index') >= 0) {
            this.privates.tabsType = 'label';
            this.privates.funcType = 'REVIEW_CALL';
        } else if (location.pathname.indexOf('/intelligent-tags/key-events/index') >= 0) {
            this.privates.tabsType = 'events';
            this.privates.funcType = 'REVIEW_KEY_EVENT';
        } else if (location.pathname.indexOf('/intelligent-tags/customer-label/index')) {
            this.privates.tabsType = 'customer';
            this.privates.funcType = 'CUSTOMER_TAG';
        }
        this.actions.update();
        getColumns(this);
    }
    componentDidUpdate() {
        const {        
            pageNum,
            pageSize,
            total,
        } = this.state;
        const {      
            tagName,
            funcType,
            selectTagClassification,
        } = this.privates;

        this.privates.storages = {
            tagName,
            funcType,
            selectTagClassification,
            page : {
                pageNum,
                pageSize,
                total,
            }
        };
    }
    componentWillUnmount() {}
    //#endregion
}

function getColumns(that) {
    let { actions, locales, privates } = that;
    let columns = [
        {
            dataIndex: 'tagName',
            width: '15%',
            title: locales.fields.intelligentTags.tagName,
        },
        {
            dataIndex: 'tagDesc',
            width: '20%',
            title: locales.fields.intelligentTags.tagDesc,
        },
        {
            dataIndex: 'categoryName',
            width: '15%',
            title:
                that.props.route.name === 'keyEventsTagsIndex'
                    ? locales.fields.intelligentTags.eventName
                    : locales.fields.intelligentTags.categoryName,
        },
        {
            dataIndex: 'tagType',
            width: '15%',
            title: locales.fields.intelligentTags.tagType,
            // hasTemplate: true,
            render: (name, item, index) => {
                if (item.tagType === Udesk.enums.labelTypes.classificationLabel.id) {
                    return Udesk.enums.labelTypes.classificationLabel.name;
                } else if (item.tagType === Udesk.enums.labelTypes.dimensionLabel.id) {
                    return Udesk.enums.labelTypes.dimensionLabel.name;
                }
            },
        },
        {
            dataIndex: 'tagRefCnt',
            width: '10%',
            title: locales.fields.intelligentTags.tagRefCnt,
        },
        {
            dataIndex: 'tagStatus',
            width: '10%',
            title: locales.fields.intelligentTags.tagStatus,
            // hasTemplate: true,
            render: (name, item, index) => {
                return Udesk.enums.activeFlags.getName(item.tagStatus);
            },
        },
        {
            dataIndex: 'actions',
            width: '15%',
            title: locales.fields.intelligentTags.actions,
            // hasTemplate: true,
            render: (name, item, index) => {
                return (
                    <div className='qa-react-page-body-content-table-actions'>
                        <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:tag:edit')}>
                            <span
                                className='action-btn'
                                style={{ cursor: 'pointer' }}
                                title={locales.labels.edit}
                                onClick={actions.editItem.params(item)}
                            >
                                {locales.labels.edit}
                            </span>
                        </If>
                        <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:tag:delete')}>
                            <Popconfirm
                                onConfirm={actions.deleteItem.params(item)}
                                title={locales.labels.deletePopconfirmContent}
                                okText={locales.labels.delete}
                                cancelText={locales.labels.cancel}
                            >
                                <span
                                    className='action-btn'
                                    title={locales.labels.delete}
                                    style={{ marginLeft: 4, color: 'red', cursor: 'pointer' }}
                                >
                                    {locales.labels.delete}
                                </span>
                            </Popconfirm>
                        </If>
                    </div>
                );
            },
        },
    ];
    if (privates.funcType === 'REVIEW_CALL' || privates.funcType === 'REVIEW_KEY_EVENT') {
        columns.splice(3, 1);
    }
    that.setState({
        columns,
    });
}

export default IntelligentTagsComponent;
