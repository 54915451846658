var locales = {
    labels: {
        clear: "clear",
        refresh: "refresh",
        nextStep: "Next",
        prevStep: "Previous step",
        closeDriver: "Exit boot",
        complete: "Complete",
        viewStatistics: "View Statistics",
        colon: ":",
        splitter: "/",
        remark: "Remarks",
        correction: "correction",
        save: "Save",
        reset: "Reset",
        increase: "Add",
        preview: "Preview",
        unit: "bar",
        cancel: "Cancel",
        all: "all",
        searchPlaceHolder: "Enter keyword query",
        greaterThan: "Greater than",
        lessThan: "less than",
        second: "second",
        description: "Description",
        column: "Column",
        pleaseChoice: "Please choose",
        filter: "Filter",
        saveAndSubmit: "Save and Submit",
        submit: "Submit",
        appeal: "Appeal",
        review: "review",
        choose: "Choose",
        manage: "Manage",
        delete: "delete",
        add: "Add",
        create: "Create",
        edit: "Edit",
        name: "name",
        describe: "Description",
        next: "Next",
        percent: "%",
        export: "export",
        exportAll: "Export all",
        confirm: "OK",
        back: "Return",
        versionUpdate:
      "I am very happy to inform you that our website has just released a new version! In order to ensure your normal use, we need you to save your current work immediately, and then refresh the page (click the browser refresh button, or press the F5 key) to make our new version take effect. Thank you very much for your cooperation! ",
        more: "More",
        selected: "Selected",
        deduct: "buckle",
        maxScore: "Max",
        scoreUnit: "point",
        pointUnit: "piece",
        comma: ", ",
        view: "View",
        batchingImport: "import",
        deleteContent:
      "Are you sure you want to delete? Cannot be restored after deletion",
        import: "import",
        uploadFile: "Upload file",
        downloadFile: "Export file",
        upload: "Upload",
        click: "click",
        downloadTemplate: "Download Template",
        fillInTheForm: "Fill in format",
        UploadExcelfile: "Upload Excel to complete batch import",
        uploadIdentical:
      "If the API name exists, the existing data in the system will be updated when importing",
        numOne: "1",
        numTwo: "2",
        numThree: "3",
        apply: "Apply",
        leftBracket: "(",
        rightBracket: ")",
        question: "Question",
        answer: "Answer",
        content: "Content",
        search: "Search",
        query: "query",
        cancelEdit: "Cancel edit",
        noValue: "No data yet",
        selectAll: "Select All",
        changeAGroup: "Change a group",
        arrow: "---> ",
        saveReview: "Review Submit",
        saveSpotCheck: "Spot Check Submit",
        rapidSampling: "Quick sampling",
        file: "Archive",
        download: "download",
        lastPage: "Previous page",
        nextPage: "Next Page",
        batchActions: "Batch Actions",
        cancelDistribution: "Cancel distribution",
        againDistribution: "Redistribution",
        deleteRecord: "Delete Record",
        oneOptionOpertor: "Optional Quality Inspector",
        allOptionOpertor: "All quality inspectors",
        distributionInfoFirstOne: "Confirm to be right",
        distributionInfoFirstTwo:
      "Does the record redistribute? Please select the distribution target",
        distributionInfoSecondOne: "Are you sure you want to cancel",
        distributionInfoSecondTwo:
      "is the record of the distributor information? After cancellation, it will become an unallocated review record.",
        distributionInfoSecondThree:
      "Is it the assignee information of the record? After cancellation, it will become an unassigned sampling record.",
        distributionInfoThirdOne: "There are in selected records",
        distributionInfoThirdTwo:
      "The reviewed record, the reviewed record will not be processed.",
        distributionInfoThirdThree:
      "Records that have been sampled, the records that have been sampled will not be processed.",
        exportError: "Export failed",
        exportSuccess: "Export successful",
        deleteRecordInfo:
      "Are you sure you want to delete {0} records? Data cannot be restored after deletion",
        pleaseSetFilter: "Please select filter criteria",
        noCountAfterFilter: "No export data, please reset the filter conditions",
        redo: "re-execute",
        batchMove: "Bulk move",
        websiteName: "Smart Quality Inspection",
        salesEnable: "Sales enablement",
        systemName: "Smart Quality Inspection",
        initSystemError:
      "Network exception or page initialization failed, please contact agent",
        day: "day",
        hour: "hour",
        minute: "minute",
        questionMark: "? ",
        personalSettings: "Personal Settings",
        logout: "Logout",
        ok: "OK",
        saveAs: "Save As",
        pleaseEnter: "Please enter",
        executeMessage: {
            executeSuccess: "Successfully executed {0} items",
            executeError: "{0} execution failed",
        },
        field: "Field",
        operator: "Operator",
        value: "value",
        placeholder: "Please choose",
        pleaseSelect: "Please select",
        totalScore: "Total Score",
        hyphen: "-",
        semicolon: ";",
        wfm: "Smart Scheduling",
        user: "User",
        none: "none",
        plusSign: "+",
        minusSign: "-",
        saveSuccessAndReload:
      "Save successfully! Effective after relevant employees refresh the page",
        saveAsName: "{0}(copy)",
        systemInformation: "View system information",
        billingCenter: "Billing Center",
        warningMessage:
      "The remaining quota is insufficient, please recharge as soon as possible",
        fileTypeHolder: "File format is wrong, please upload {0} format file",
        dataNo: "No data yet",
        move: "Move",
        study: "You have unmarked self-learning quality inspection points",
        studySuccess: "Mark success",
        studyError: "labeling failed",
        editType: "Edit category label",
        copyed: "Copy to clipboard",
        clickToCopy: "Click to copy",
        addFilterConditions: "Add filter conditions",
        filterConditions: "Filter Conditions",
        messageTime: "message time",
        messageType: "Message Type",
        pushType: "message channel",
        messageContent: "Message content",
        messageName: "message name",
        messageStatus: "Message Status",
        configuration: "Configuration information",
        sendTarget: "send target",
        guideUrl: "Guide URL",
        noticeDetail: "Message Details",
        action: "operation",
        seeDetail: "View Details",
        theDay: "{0}day",
        theLastDayOfMonth: "The last day of the month",
        ignore: "ignore",
        seeNow: "View now",
        exportConfirmText: {
            export: "Total {0} data, confirm export?",
            exportAll: "Confirm to export all content?",
        },
        samrTagAdd: "Add tag",
        deletePopconfirmContent: "Are you sure you want to delete? ",
        TheLastThirtyDays: "Last 30 days",
        addNumber: "Add",
        subNumber: "Subtract",
        addStrategy: "Add Strategy",
        minutes: "minutes",
        status: "Enable status",
        pushConfig: "Push Configuration",
        category: "category",
        and: "and",
        interval: "interval",
        switchOn: "Turn on",
        switchOff: "Close",
        batchDel: "Batch delete",
        exportTxt: "Export TXT text",
        playSpeed: "Double speed",
        messageLogBtn:
      "View the communication/dialog that triggered the notification",
        dataLink: "Data Link",
        yes: "Yes",
        no: "No",
        sentenceUnit: "sentence",
        appealDetail: "Appeal Record Details",
        detail: "details",
        default: "default",
        customer: "Customer",
        agent: "Agent",
        sort: "sort",
        applyNow: "Apply",
        index: "Serial Number",
        allColumns: "Columns that can be added",
        selectedColumns: "Columns added",
        syn: "Sync",
        saveAndNext: "Save and create the next one",
        viewReference: "View reference",
        referenceDetail: "reference details",
        goToQaList: "Inspection",
        goToCheck: "Sampling",
        businessCard: "Business Card",
        weapp: "Mini Program",
        redpacket: "red packet",
        externalRedpacket: "Exchange Red Packet",
        sphfeed: "video number",
        exportTaskCreated: "The export task was created successfully",
        plzGoTo: "Please jump to",
        importTaskCreated: "The import task was created successfully",
        taskCreated: "Task created successfully",
        reply: "reply",
        replied: "replied",
        release: "Release",
        expandMore: "Expand other {0} replies",
        comment: "comment",
    },
    business: {
        notifyMessage: {
            IsNullErrorFormat: "Please have at least one {0}",
            canNotBeEmptyFormat: "{0} cannot be empty",
            repeatErrorTipFormat: "{0} has a repeat",
            getInformationFailedFormat:
        "Failed to obtain {0} information, please check the network connection or try again later",
            deleteSuccessFormat: "{0} was successfully deleted. ",
            deleteFailedFormat: "{0} failed to delete, please try again later. ",
            saveSuccessFormat: "{0} was saved successfully. ",
            saveFailedFormat: "{0} failed to save, please try again later. ",
            fileSuccessFormat: "{0} was archived successfully. ",
            fileFailedFormat: "Failed to archive {0}, please try again later. ",
            exportSuccessFormat: "{0}export successful",
            exportFailedFormat: "{0} export failed, please try again later. ",
            submitSuccessFormat: "{0} submitted successfully. ",
            submitFailedFormat: "{0} submission failed, please try again later. ",
            confirmSuccessFormat: "{0} confirmed success. ",
            confirmFailedFormat: "{0} failed to confirm, please try again later. ",
            pleaseUploadFile: "Please upload the file first! ",
            importFileSuccess: "File import successfully! ",
            importFildFailed: "File import failed! ",
            deleteSuccess: "Delete successfully! ",
            deleteFailed: "Deletion failed, please try again later! ",
            illegalTitle: "Insufficient authority",
            illegalMessage:
        "You do not have some or all permissions for the next step, please contact the administrator to configure, click the OK button to exit the guide",
            illegalMessageCurrent:
        "You do not have some or all permissions for the current step, please contact the administrator to configure, click the OK button to exit the guide",
            skipMessage:
        "If you want to skip this step temporarily, please click Skip",
            errorMsg500:
        "Server request failed, please check your operation or refresh and try again",
            errorMsg404: "Ooops...The page you were looking for is missing",
            errorMsg400:
        "Server request failed, please check your data and try again",
            pageOverFlow:
        "Currently, only 10000 items are supported, please set the filter criteria to view the excess part.",
            isHandAddSmartTag: "is a manually added smart tag",
            defaultGradeMustAtLast: "The default rating must be placed at the end",
            AutomaticAllocationInspectorsIsNull:
        "Please enter the basic information page to add a quality inspector",
            timePickerTips:
        "In order not to affect your normal use, 23:00~2:00 is the system maintenance time and no timing tasks are performed. ",
            batchDeleteInfo:
        "Successfully deleted {0} items and failed {1} items. The reason for the failure is that the data has been used. ",
            batchDeleteTips: "Tick {0} data in total, confirm to delete?",
            correctionSuccess: "Error correction success",
        },
        pages: {
            qualityInspectionForm: {
                list: { IsMaxFormNumberError: "Add up to 10 quality inspection forms" },
                basicInformation: {},
                specialProject: {
                    sumOfScoresErrorTip:
            "The sum of indicator items {0} must add up to 100",
                    banProjectMaxErrorTip: "Add up to 2000 banned items",
                    deadlyProjectMaxErrorTip: "Add up to 500 deadly items",
                },
            },
        },
        info: {
            creatSuccess: "Create successful",
            creatError: "Create failed",
            editSuccess: "Modification successful",
            editError: "Modification failed",
            deleteSuccess: "Delete success",
            addSuccess: "Add success",
            deleteError: "Delete failed",
            conditionError: "Operator logic expression error",
            customJudgeLogic: "Custom condition combination logic expression error",
            exportError: "Export failed",
            searchError: "Filtering failed",
            loadError: "{0} failed to load",
            saveSuccess: "Save successfully",
            saveError: "Save failed",
            fileSuccess: "Archive successful",
            fileError: "Archive failed",
            cannotBeNull: "{0} cannot be empty!",
            pleaseSearchAndSelect: "Please enter keywords to search",
            clickAndToggle: "Click to select switch",
            deleteWarning:
        "{0} deletion cannot be restored, are you sure you want to continue? ",
            getOssAuthFailed:
        "Failed to obtain OSS authorization, please check the network connection or try again later. ",
            getInsightAuthFailed:
        "Failed to obtain Insight authorization, please check the network connection or try again later. ",
            pictureUploadFailed: "Picture upload failed",
            pictureUploadFailedSize:
        "Picture size exceeds the limit, picture upload failed",
            wecomTagsSyncInfo: "Last update: {0}, sync {1} pieces of information",
            collectSuccess: "collection success",
        },
    },
    components: {
        common: {
            qcReportEcharts: { errorMessage: "Failed to obtain report data" },
            waveAudio: {
                noWaveMessage:
          "In order to maintain your experience, no waveform will be displayed for recordings over {0} minutes, you can play it directly",
            },
            customFilter: {
                del: "delete",
                edit: "Edit",
                new: "Custom Filter",
                apply: "Apply",
                cancel: "Cancel",
                onlyApply: "only applicable",
                ohterSave: "Save as",
                save: "Save",
                customFilter: "Common filter",
                reset: "Reset",
                holderKeyWord: "Please enter a keyword",
                customFilterRule: "Filter logic rule:",
                customFilterName: "Filter name:",
                holderCustomFilterName: "Please enter the filter name",
                manualConfiguration: "Manual Configuration",
                holderCustomRule: "Please enter a custom logic condition",
                example: "example",
                dragConfiguration: "Drag and drop configuration",
                condition: "condition",
                operation: "operation",
                infoDel: "Delete the content out of the area",
                toConfigure: "Configuration",
                holderCustomFilterNameLength:
          "The maximum length of the filter name is 10",
                customFilterNameNone: "Filter name cannot be empty",
                and: "and",
                or: "or",
                not: "not",
                delSuccess: "Delete success",
                saveSuccess: "Save successfully",
                updateSuccess: "Modification successful",
                customFilterCondition: "Filter Condition",
                conditionRule: "Condition Rule",
                none: "none",
                filterPlaceholder: "Please enter keywords",
            },
            labelTabs: {
                addThis: "Add to this",
                goThis: "Move to this",
                saveGo: "Save and train",
            },
            datePicker: {
                today: "today",
                yesterday: "Yesterday",
                theDayBeforeYesterday: "Day before yesterday",
                thisWeek: "this week",
                lastWeek: "last week",
                thisMonth: "this month",
                lastMonth: "Last Month",
                lastSevenDay: "Last 7 days",
                lastThirtyDay: "Last 30 days",
                thisQuarter: "this quarter",
                lastQuarter: "last quarter",
                thisYear: "this year",
                lastYear: "Last Year",
                lastYearFromToday: "Last Year",
            },
            pagination: { page: "Total {0} items, {1} pages" },
            wechatRecord: {
                loadMoreBtn: {
                    top: "Load earlier messages",
                    bottom: "Load more messages",
                },
            },
        },
        pages: {
            menuTitleList: {
                tasksManageTitle: "Quality Inspection Task",
                informationCollectTasksManageTitle: "Collect Tasks",
                customerServiceCenterTitle: "Agent Center",
                informationTitle: "Data Center",
                intelligentAnalysisTitle: "Intelligent Analysis",
                semanticIntelligence: "Semantic Intelligence",
                trainingCenterTitle: "Training Center",
                adminTitle: "System Management",
            },
            qualityCheckingTask: {
                title: "Call Quality Inspection",
                index: {
                    tipOne:
            "Call quality inspection is used to evaluate the quality of employees' call center work. You can set up an appropriate quality inspection form based on your business.",
                    tipTwo:
            "Appropriate rules can be set for quality inspection tasks, including screening, sampling, etc. After the quality inspector has scored, the agent can view the quality inspection results and further appeal, ",
                    addTaskTitle: "Add Quality Inspection Task",
                    deleteTipTitle: "Delete quality inspection task",
                    deleteTipContent:
            "After the quality inspection task is deleted, it cannot be restored. Are you sure to delete it? ",
                    checkingTask: "Quality inspection task",
                    checkingTaskList: "Quality inspection task list",
                    formList: "Form list",
                    viewHelpDocuments: "View Help Documents",
                },
                manage: {
                    taskSetting: {
                        title: "Task Settings",
                        form: "Form",
                        basicInfo: "Basic information",
                        visibleRange: "Visible Range",
                        visibleRangeTip:
              "The selected agent, agent group can view the quality inspection task, and in addition, you can combine the permission settings to be the agent team leader, agent, quality inspector, etc. for this task.",
                        automaticSampling: "Automatic sampling",
                        automaticSamplingTip:
              "You can set up automatic sampling rules, so that the system will regularly add quality inspection tasks according to the rules you set, such as taking 5% of the last week’s calls every Monday.",
                        samplingRate: "Sampling frequency",
                        samplingTime: "Sampling time",
                        samplingRange: "Sampling Range",
                        samplingRule: "Sampling Rule",
                        fillterCondition: "Filter Condition",
                        workflow: "Quality inspection workflow",
                        appealsTime: "allow appeal time",
                        appealsTimeTipOne: "After the score is submitted",
                        appealsTimeTipTwo: "within days",
                        appealsTimeTipThree:
              'When the allowed appeal time is exceeded, the record quality inspection status is automatically modified to "confirmed"',
                        auditTime: "audit processing time",
                        auditTimeTipOne: "After filing an appeal",
                        auditTimeTipTwo: "days",
                        auditTimeTipThree:
              'Exceed the audit processing time, the record maintains the original score, and the quality inspection status is automatically modified to "confirmed"',
                        noAppealsMode: "No Appeals Mode",
                        appealsMode: "Allow appeals mode",
                        noAppealsModeImage: "no-appeal.png",
                        appealsModeImage: "appeal.png",
                        customerServices: "Agent",
                        customerServicesGroup: "Agent Group",
                        surveyList: "Review List",
                        customFields: "Custom Fields",
                        fieldConfig: "Field Configuration",
                        from: "From",
                        owner: "person in charge",
                        inspectorAndReviewerAuthority:
              "Quality Inspection Review Authority",
                        inspector: "Quality Inspector",
                        reviewer: "Reviewer",
                        inspectorTip: "Quality inspector can score this task",
                        reviewerTip: "The reviewer can rate the task",
                        dataStatisticsAuthority: "Data Statistics Authority",
                    },
                    samplingRecords: {
                        title: "Sampling Record",
                        index: {},
                        manualSampling: {
                            title: "Sampling",
                            fillterCondition: "Filter Condition",
                            samplingRule: "Sampling Rule",
                            agent: "Agent",
                            type: "type",
                            result: "Result",
                            evaluate: "evaluation",
                            relayNumber: "Relay Number",
                            source: "source",
                            task: "Task",
                            queueState: "Queue State",
                            callHanger: "Call Hangup Party",
                            queueTime: "Queue time-consuming",
                            talkTime: "Talk time",
                            bellTime: "Bell Duration",
                            recordCountTipOne: "Total",
                            recordCountTipTwo: "Records",
                            customerServices: "Agent",
                            customerServicesGroup: "Agent Group",
                            surveyList: "Review List",
                            customFields: "Custom Fields",
                            fieldConfig: "Field Configuration",
                            defaultFields: "default fields",
                        },
                    },
                    taskList: {
                        title: "Task List",
                        index: {
                            batchDelete: "Batch delete",
                            batchSubmit: "Batch Submit",
                            batchConfirm: "Batch Confirmation",
                            itemFields: "Rating item fields",
                            categoryFields: "Secondary category fields",
                            forbidsAndDeadlies: "Prohibited items and deadly items",
                            scoreFieldList: "Score item field list",
                            customerName: "Customer Name",
                            cellphone: "Phone",
                            email: "Mailbox",
                            organization: "Company",
                            description: "Description",
                            tags: "tag",
                            taskUserRole: "Current task user role",
                        },
                        workBench: {
                            title: "Record details",
                            checkForm: "Quality Inspection Form",
                            waitEvaluate: "To be evaluated",
                            alreadyEvaluate: "Already evaluated",
                            waitSubmit: "to be submitted",
                            alreadySubmit: "Submitted",
                            waitConfirm: "To be confirmed",
                            alreadyConfirm: "Confirmed",
                            waitReview: "To be reviewed",
                            alreadyReview: "Already reviewed",
                            alreadyDone: "completed",
                            comment: "evaluation notes",
                            totalScore: "Total Score",
                            score: "point",
                            remark: "Remarks",
                            changeRecords: "Change Records",
                            subject: "Subject",
                            secondLevelScore: "second level score",
                            deadlyList: "deadly item",
                            forbidList: "Forbidden Items",
                            addToCheckTask: "Add to quality inspection task",
                            submitCheckRemark: "Submitted quality inspection review",
                            changeCheckRemark: "modified the quality inspection evaluation",
                            doneCheckRemark: "Quality inspection evaluation completed",
                            submitCheckReview: "Submitted quality inspection review",
                            submitCheckAppeal: "Submitted quality inspection appeal",
                            saveFlowSuccess: "The evaluation is successful. ",
                            saveFlowFailed: "The evaluation failed, please try again later. ",
                            FormDetail: "Form Details",
                            callDetail: "Call record details",
                            sampleCalllogDetail: "Record details",
                            appealReasonTip: "Please enter the reason for appeal (required)",
                            appeal: "Appeal",
                            appealReason: "Reason for appeal",
                        },
                    },
                },
            },
            qualityInspectionForm: {
                formformat: "{0} quality inspection form",
                basicInformationTitle: "Basic Information",
                indicatorsProjectTitle: "Indicator Project",
                specialProjectTitle: "Special Item",
                totalScore: "Total Score",
                indicatorsName: "Indicator name",
                score: "score",
                singleScore: "Single Score",
                weight: "weight",
                maxDeductScore: "Max deduct points",
                add: "add",
                detail: "Form details",
                list: {
                    title: "Quality Inspection Form",
                    tipOne:
            "Configuring the quality inspection form is the first step of the quality inspection work. You can configure the most suitable quality inspection form based on the company's business. ",
                    tipTwo:
            "We support multiple forms such as direct scoring, weighted scoring, deduction system, etc. It is recommended that you set the rules before adding them to the system. ",
                },
                basicInformation: {
                    formTemplate: "Form template",
                    directScore: "Direct Score",
                    weightedScore: "weighted score",
                    deductScore: "deduct points",
                    directScoreSecondLevel: "Direct Score-Second Level",
                    weightedScoreSecondLevel: "Weighted Score-Second Level",
                    deductScoreSecondLevel: "Deduct Score-Second Level",
                    deductScoreImageFileName: "deduct-score.png",
                    weightedScoreImageFileName: "weighted-score.png",
                    directScoreImageFileName: "direct-score.png",
                    deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
                    weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
                    directScoreSecondLevelImageFileName: "direct-score-second-level.png",
                },
                specialProject: {
                    banProject: "ban item",
                    banProjectTip:
            "Can be used to check the quality inspection scoring. It can be regarded as a violation or as a supplement to the remarks, and does not affect the actual score. ",
                    deadlyProject: "Deadly Project",
                    deadlyProjectTip:
            "The deadly item can be used to check the quality inspection scoring. If the deadly item is checked, the quality inspection task will directly score 0 points.",
                },
            },
            qualityInspectionStatisticalReports: {
                reports: {
                    title: "Call Quality Inspection",
                    entiretyReportTitle: "Overall Report",
                    serviceGroupReportTitle: "Agent Group Report",
                    serviceReportTitle: "Agent Report",
                    entiretyReport: {
                        pandect: "Overview",
                        contrast: "contrast",
                        trend: "trend",
                        analyze: "Analysis",
                        totalScore: "Total Score",
                        banProject: "ban item",
                        deadlyProject: "Deadly Project",
                    },
                },
            },
            appealChekingTask: {
                list: {
                    title: "Quality inspection result list",
                    taskSelectPlaceholder: "Select quality inspection task",
                    agentSelectPlaceholder: "Select agent",
                    refresh: "refresh",
                    all: "all",
                    unread: "Unread",
                    inComplaint: "Complaint in progress",
                    reviewed: "reviewed",
                    totalScore: "Total Score",
                    detail: "Quality inspection details",
                    audioText: "Recording text",
                    callInfo: "call information",
                    ticketInfo: "Work order information",
                    wechatInfo: "session information",
                    dialogText: "Dialog text",
                    dialogInfo: "Dialog information",
                    customer: "Customer",
                    agent: "Agent",
                    none: "No quality inspection data yet",
                    appealSuccess: "The appeal is successful! ",
                    appealFailed: "Appeal failed, please try again later! ",
                    pointListIsNull:
            "The appeal quality inspection point cannot be empty! ",
                    pleaseSelectPoint: "Please select a quality inspection point! ",
                    unsupportedFormat: "This format is not currently supported",
                    shardingInformation: "The following is sharding {0}",
                    smartTags: "Smart Tags",
                    businessRecords: "Business Records",
                    selectedPointsWarning:
            "Please check the quality inspection items to be appealed, specify the relevant sentences for the appeal items respectively, and then click Submit. ",
                    applyReason: "Reason for appeal (up to 50 characters)",
                    chatMessageTypes: {
                        all: "all",
                        text: "text",
                        image: "image",
                        agree: "Agree to the content of the conversation",
                        video: "video",
                        voice: "Voice",
                        file: "file",
                        emotion: "expression",
                        link: "Link",
                        chatrecord: "Chat record",
                        mixed: "Mixed messages",
                        default: "message not supported",
                        deleteFriend: "Delete friend",
                        redpacket: "red packet",
                        card: "business card",
                        disAgree: "Disagree with the content of the conversation",
                        weapp: "Mini Program",
                        externalRedpacket: "Exchange Red Packet",
                        meetingVoicevCall: "Call message",
                        uRL: "webpage",
                        replyTimeout: "Timeout reply",
                        sphfeed: "video number",
                        chatRecordTitle: "Chat record",
                    },
                },
                components: {
                    qualityCheckScore: {
                        totalScore: "Total Score",
                        actualScore: "Score",
                        actualGrade: "rating",
                        appeal: "Appeal",
                        remark: "Manual remarks",
                        none: "No quality inspection details yet",
                        pointEdit: "Quality inspection point edit classification",
                    },
                    changeList: {
                        title: "Change History",
                        user: "User",
                        point: "Quality Inspection Point",
                    },
                    relatedRecord: {
                        title: "Related Records",
                        qualityList: "Quality Inspection Task",
                        none: "none",
                        check: "View",
                        loadMore: "Load more",
                    },
                },
            },
            grade: {
                title: "Quality inspection rating",
                subTitle:
          "Quality inspection rating is used to grade the quality inspection objects after the quality inspection scoring is completed. For example, it can be configured to be less than 60 as unqualified, 60-85 as good, and higher than 80 as excellent",
                list: {
                    up: "Move up",
                    down: "Move down",
                    del: "delete",
                    case: "Automatically join the case library",
                    orderCase: "default case library",
                    info: "The default rating has no matching conditions and cannot be deleted. If it does not meet any of the above ratings, the default rating will be obtained.",
                    add: "Add rating",
                    save: "Save",
                    close: "Cancel",
                    max: "Quality inspection rating can be configured up to 20",
                    name: "unnamed",
                    success: "Save successfully",
                },
            },
            qualityInspectionList: {
                title: "Quality Inspection List",
                fastFilter: "Quality inspection violation",
                fastFilterTip:
          "The point reduction system hits is a violation, and the bonus point system misses is a violation",
                titleWechat: "Analysis List",
                titleCollect: "Collection list",
                status: "Analysis status",
                reQualityInspectionConfig: "Re-Quality Inspection Task Option",
                pleaseSelectRecheckPoints:
          "Please select the quality check points that need to be recalculated",
                beginReQualityInspection: "Start execution",
                cancelReQualityInspection: "Cancel task",
                reQualityInspection: "Re-Quality Inspection",
                reQualityInspectionWechat: "Re-analysis",
                reQualityInspectionUnderWay: "Re-Quality Inspection Task...",
                reQualityInspectionUnderWayTip:
          "Leaving the page after closing the window will not affect the task progress",
                reQualityInspectionDone: "Re-quality inspection has been completed",
                reQualityInspectionDoneTip:
          "The re-quality inspection has been successfully completed, please click the confirm button",
                endReQualityInspection: "terminate task",
                alreadyDoneTip: "{0} completed",
                totalTip: "Total {0} items",
                endReInspectionSuccess: "Re-inspection task has been terminated",
                closeWindow: "Close window",
                endReInspectionFailed:
          "Re-inspection task failed, please try again later!",
                detail: {
                    title: "Quality Inspection Details",
                    wechatTitle: "Analysis Details",
                    titleCollect: "Collection details",
                    callIndex: "associated call",
                    textIndex: "Associated Dialogue",
                    fileConfirm: "Confirm the archive? ",
                    fileConfirmDesc:
            "The QC score cannot be edited after archiving, please confirm whether to archive",
                },
                sysCloumnsContal: "System column field management",
                personCloumnsContal: "Personal column field management",
            },
            spotCheckList: {
                title: "Sampling list",
                onlyMyTip: "Only view my sampling",
                plInspector: "Please select a random inspector",
                allInspector: "View all",
                seInspector: "View designated spot inspectors",
                myInspector: "Only view mine",
                noInspector: "View unassigned spot inspectors",
                detail: { title: "Details of sampling inspection" },
            },
            reviewList: {
                title: "Review List",
                onlyMyTip: "Only view my review",
                plInspector: "Please select a reviewer",
                allInspector: "View all",
                seInspector: "View designated reviewers",
                myInspector: "Only view mine",
                noInspector: "View unassigned reviewers",
                detail: { title: "Review Details" },
            },
            caseBaseList: {
                title: "Case Library",
                tip: "The typical call and conversation data in the quality inspection list can be saved to the case library for easy learning and sharing",
                del: "delete",
                delTitle: "All content will not be saved after deletion",
                cancel: "Cancel",
                actions: "action",
                seeCaseBase: "View case",
                seeDetail: "View Details",
                edit: "Edit",
                save: "Save",
                addCaseBase: "Add Case Base",
                export: "export",
                ok: "OK",
                detail: {
                    choiceCaseBase: "Please select a case",
                    delCaseBase: "Delete Case",
                    addCaseBase: "Add Case",
                    successed: "Save successfully",
                    info: "Please save or cancel the content being edited first",
                    caseType: "Please select the case library to be added",
                },
            },
            testSetList: {
                title: "Quality Test Collection",
                tip: "The data of the quality inspector's random inspection will be saved in the quality inspection test set, which is convenient for optimizing the configuration of quality inspection conditions and quality inspection rules for some machine inspection error items that are compared after the sampling inspection.",
                refresh: "refresh",
                reQualityInspectionUnderWay: "Reevaluation task...",
                reQualityInspectionUnderWayTip:
          "Leaving the page after closing the window will not affect the task progress",
                reQualityInspectionDone: "Re-evaluation completed",
                reQualityInspectionDoneTip:
          "The re-evaluation has been successfully completed, please click the confirm button",
                endReQualityInspection: "terminate task",
                alreadyDoneTip: "{0} completed",
                totalTip: "Total {0} items",
                endReInspectionSuccess: "Re-evaluation task has been terminated",
                closeWindow: "Close window",
                endReInspectionFailed:
          "Failed to re-evaluate the task, please try again later!",
                recheckPoint:
          "Re-calculate the quality check point score and accuracy rate",
                recheckPointTitle: "Re-evaluation option",
                delete: {
                    reQualityInspectionUnderWay: "Bulk deleting tasks...",
                    reQualityInspectionUnderWayTip:
            "Leaving the page after closing the window will not affect the task progress",
                    reQualityInspectionDone: "Bulk delete tasks have been completed",
                    reQualityInspectionDoneTip:
            "The batch deletion has been successfully completed, please click the confirm button",
                    endReQualityInspection: "terminate task",
                    alreadyDoneTip: "{0} completed",
                    totalTip: "Total {0} items",
                    endReInspectionSuccess: "Bulk delete task has been terminated",
                    closeWindow: "Close window",
                    endReInspectionFailed:
            "Failed to delete tasks in batches, please try again later!",
                },
                reultList: {
                    assessmentTime: "Last assessment time",
                    assessmentAll: "Total assessment",
                    strip: "piece",
                    spot: "spot",
                    deviation: "deviation",
                    accuracyRate: "Accuracy Rate",
                    info: "No evaluation result, please evaluate",
                    onlyDeviationInfo: "Only view deviation records",
                    againAssessment: "Reassessment",
                    screen: "screening",
                    batchDelete: "Batch remove",
                    matching: "match",
                    ok: "Yes",
                    noCancel: "No",
                },
            },
            smartWordsLibrary: {
                title: "Smart Thesaurus",
                tip1: "Synonyms: used to reduce the training amount of semantic labels, similar sentences of synonyms do not need repeated training",
                tip2: "Professional nouns: optimize the transliteration and natural language understanding of inaccurately identified nouns such as company names, product names, industry vocabulary, etc., to improve the recognition accuracy and analysis accuracy",
                tip3: "Focus on words: pay attention to the words you want to pay attention to in the word cloud analysis to help AI analyze hot spots in line with the current business",
                tip4: "Block words: block words that you don't want to pay attention to in word cloud analysis to help AI analyze hot spots in line with current business",
                tip5: "Word weight: used for error correction and optimization of semantic label recognition, by modifying the influence weight of words in the sentence to optimize the semantic recognition effect in specific scenarios as needed",
                tip6: "corpus data: enter large paragraphs of text for respective business scenarios for transcription error correction and ASR training to improve ASR transcription accuracy",
                upload: {
                    uploadSynonymWords: "Import synonyms",
                    uploadProfessionalWords: "Import professional terms",
                    uploadAttentionWords: "Import attention words",
                    uploadIgnoreWords: "Import Ignore Words",
                    uploadWordsWeight: "Import weighted words",
                    uploadCorpusData: "Import Corpus Data",
                    uploadTypo: "Import typos",
                    uploadCorpusContent: "Import corpus data content",
                    firstUploadStep:
            "1. Download the template and fill in the template format",
                    downloadTemplateMessage: "Download template:",
                    firstUploadJSONButton: "JSON",
                    firstUploadExcelButton: "EXCEl",
                    firstUploadTxtButton: "TXT",
                    secondUploadStep:
            "2. Click the import button to upload the EXCEl file in the template format",
                    secondUploadCorpusDataStep:
            "2. Click the import button to upload a TXT file that conforms to the template format",
                    postUploadDataFail: "Failed to submit upload data",
                    pleaseUploadTemplate: "Please upload EXCEl/JSON/TXT file",
                    templateNeedJSONOrXLSX:
            "File template can only be in JSON or xlsx format",
                    templateNeedTxt:
            "The corpus data file template can only be a TXT file",
                    uploadSuccess: "Import successful",
                    uploadFailed: "Import failed",
                    downloadError: "Download failed",
                    downLoadTxtTitle: "corpus content.txt",
                },
                navTabNames: {
                    synonymWords: "Synonym",
                    professionalWords: "Professional Nouns",
                    attentionWords: "Attention Words",
                    ignoreWords: "Ignore words",
                    wordsWeight: "word weight",
                    corpusData: "corpus data",
                    typoLibrary: "Set of typos",
                },
                synonymWords: {
                    createSynonymWords: "Create Synonym",
                    editSynonymWords: "Edit Synonyms",
                    addSynonymWords: "Add synonyms",
                    pleaseEnterStandardWords: "Please enter standard words",
                    synonymWordsExists: "The synonym already exists and cannot be added",
                    synonymWordsCanNotNull: "The added synonym cannot be empty",
                    pleaseAddSynonymWords: "Please add synonyms",
                    addSuccess: "Add success",
                    editSuccess: "Modification successful",
                    deleteSuccess: "Delete success",
                    deleteError: "Delete failed",
                    required: "*",
                },
                professionalWords: {
                    create: "Create professional term",
                    edit: "Professional noun editing",
                    addTargetWords: "Add replacement words",
                    targetWordsExists:
            "The replacement word already exists and cannot be added",
                    targetWordsCanNotNull: "The added replacement word cannot be empty",
                    pleaseAddTargetWords: "Please add replacement words",
                    pleaseEnter: "Please enter a professional term",
                    wordsExists: "The term already exists",
                    createSuccess: "add success",
                    editSuccess: "Modification successful",
                    deleteSuccess: "Delete success",
                    deleteError: "Delete failed",
                    saveError: "Save failed",
                    addTargetWordsMaxNumberError:
            "You can add up to 100 replacement words",
                    addHomophonyWordsMaxNumberError: "You can add up to 100 shield words",
                },
                attentionWords: {
                    create: "Create word of interest",
                    edit: "Follow word edit",
                    pleaseEnter: "Please enter the word of interest",
                    wordsExists: "The word of interest already exists",
                    createSuccess: "add success",
                    editSuccess: "Modification successful",
                    deleteSuccess: "Delete success",
                    deleteError: "Delete failed",
                },
                ignoreWords: {
                    create: "Create ignore word",
                    edit: "Ignore word edit",
                    pleaseEnter: "Please enter the ignored word",
                    wordsExists: "The ignored word already exists",
                    createSuccess: "add success",
                    editSuccess: "Modification successful",
                    deleteSuccess: "Delete success",
                    deleteError: "Delete failed",
                },
                wordsWeight: {
                    createWordsWeight: "Create weighted word",
                    editWordsWeight: "Edit Words Weight",
                    addWordsWeight: "Add weighted words",
                    pleaseEnterWeightWords: "Please enter weight words",
                    pleaseEnterWeight: "Please enter the weight",
                    weightNeedInZeroToHundred: "Weight needs to be between 0~100",
                    synonymWordsExists: "The weighted word already exists",
                    weightNeedNumber: "Weight needs integer format",
                    standardWeightInformation:
            "The benchmark weight is 50, the larger the weight, the stronger the influence in the analysis",
                    createSuccess: "add success",
                    editSuccess: "Modification successful",
                    deleteSuccess: "Delete success",
                    deleteError: "Delete failed",
                },
                corpusData: {
                    createCorpusData: "Create corpus data",
                    editCorpusData: "Edit Corpus Data",
                    addCorpusData: "Add Corpus Data",
                    pleaseEnterCorpusDescription: "Please enter a corpus description",
                    pleaseEnterCorpusContent: "Please enter the corpus content",
                    synonymWordsExists: "The corpus data already exists",
                    CorpusContentWordsOverSetting:
            "Content word count exceeds 20000 words, ",
                    download: "download",
                    upload: "Upload",
                    see: "View",
                    canNotSave:
            "Please use the import function, otherwise it cannot be saved",
                    createSuccess: "add success",
                    editSuccess: "Modification successful",
                    deleteSuccess: "Delete success",
                    deleteError: "Delete failed",
                    required: "*",
                },
                typoLibrary: {
                    fields: {
                        rightWord: "correct word",
                        wrongWord: "Wrong Word",
                        wrongWordCount: "Number of wrong words",
                        wrongWordTip: "Press Enter to add",
                        status: "Validity",
                    },
                    create: "Create a new set of typos",
                    edit: "Edit typos",
                    tip: 'The typos set is used to optimize the effect of "typos detection". For example, if "refund" is mistakenly sent as "refund", you can use this function to set common typos and correct words. Note that it will take effect the next day after saving. ',
                    onlyChinese: "Only support the input of Chinese words",
                    wrongWordLengthErr:
            "The length of each wrong word must be 2~10 words",
                },
            },
            semanticTags: {
                title: "Semantic label",
                current: "Current semantic label",
                reference1: "Quality inspection tasks: {0} - {1}",
                reference2: "Smart tag: {0}",
                reference3: "Data preprocessing: {0}",
                reference4: "knowledge base: {0}",
                index: { import: "Import semantic tags" },
                detail: {
                    new: "Create semantic label",
                    edit: "Edit semantic tags",
                    name: "name",
                    standardSentences: "Standard sentences",
                    effectiveness: "effectiveness",
                    similarSentences: "Similar sentences",
                    tip: "Support the use of ## to call entity APIs. Example: Is your phone number #sys_phone#? ",
                    excludeSimilarSentences: "Exclude similar sentences",
                    intelligentRecommend: "intelligent recommend similar content",
                    fastTraining: "fast training: ",
                    excludeKeywords: "Exclude keywords",
                    excludeKeywordsInfo1:
            "Exclude keywords and regular expressions take precedence over all logic to take effect. If the exclusion is hit, the semantic tag will not be hit",
                    qualify: "limit",
                    recallKeyWord: "Recall Keyword",
                    keyWordInfo1:
            "Recall keywords support multiple entries in each line, separated by commas, the logic is all hits when multiple entries are entered. ",
                    keyWordInfo2:
            "Recall keywords support to fill in multiple lines. When filling in multiple lines, the logic can be any hit. ",
                    recallRegular: "Recall Regular",
                    excludeRegular: "Exclude regular",
                    similaSentences: "Exclude similar sentences",
                    simim: "similarity",
                    keyWordInfo3:
            "Excluded similar sentences take precedence over all logics. If the excluded similar sentences are hit, the semantic tag will not be hit. The default similarity is 80%. The greater the similarity, the more similar. ",
                    keyWordInfo4:
            "Rules are similar to regular ones, with a beginning and an end by default. It does not have to be used with square brackets, such as [non-XXXX]",
                    keyWordInfo5:
            "The functions in the advanced module are usually experimental functions prepared for experts who are familiar with the operation. If you are not sure how you should operate, it is recommended that you do not change this part. ",
                    keyWordInfo6:
            "Cross-sentence detection function gives the ability to detect semantic tags across sentences. ",
                    keyWordInfo7: "At most continuous across the same role",
                    keyWordInfo8:
            "Ignore different roles and sentence length is not greater than",
                    keyWordInfo9: "Cross sentence detection for sentence of words",
                    keyWordInfo10:
            "The maximum sentence length of the longest spanning sentence is not greater than",
                    keyWordInfo11: "Remove similarity percentage not less than",
                    keyWordInfo12: "The similarity is not lower than",
                    keyWordInfo13:
            "% of similar sentences will be similarly matched (default similarity 80%)",
                    keyWordInfo14:
            "Similar deduplication will match based on the similarity of sentences. It is recommended to remove sentences that are too similar to improve detection efficiency. ",
                    keyWord: "Keyword",
                    entity: "Entity",
                    ruleField: "Concept word",
                    customRule: "Speaking rules design",
                    fanhua: "Generalization",
                    test: "Test",
                    testOk: "hit test",
                    senior: "Senior",
                    seniorList: "Cross sentence detection",
                    up: "Enable",
                    down: "close",
                    ju: "sentence",
                    oneWord: "one word",
                    simimNo: "Similar to Duplicate",
                    seniorField: "Similarity threshold",
                    save: "Save",
                    cancel: "Cancel",
                    specialElements: "Special Elements",
                    logicRule: "Logic Rule",
                    every: "multiple matches",
                    recallOk: "Hit the recall word:",
                    recallNo: "Missing recall word",
                    ruleOk: "hit recall regular",
                    ruleNo: "Missing regular",
                    rularOk: "hit rule",
                    rularNo: "Missing rule",
                    infoOk: "Hit standard sentence or similar sentence:",
                    infoNo: "Missing standard sentence or similar sentence",
                    fieldOk: "Hit to exclude similar sentences:",
                    fieldNo: "Missing Exclude Similar Sentences",
                    excludeRuleNo: "Missing exclusion rule",
                    excludeRuleOk: "Hit exclusion rule",
                    excludeKeywordNo: "Missing excluded keywords",
                    excludeKeywordOk: "Hit excluded keywords",
                    update: "Update successful",
                    fanhuaList:
            "Submitted to the generalization tab page similar sentence list",
                    wordList:
            "The training list is empty and cannot be submitted to the generalization list",
                    start: "Start",
                    end: "end",
                    keyWordInfo15:
            "There are similar fields in the generalization list (advanced configuration similar deduplication constraints)",
                    saveSuccess: "Save successfully",
                    errInfo: "Some fields are missing or incorrectly filled in",
                },
                table: {
                    deepTraining: "Deep training {0} rounds",
                    fastTraining: "fast training",
                },
            },
            intelligentCollection: {
                title: "Smart Collection",
                classification: "Group list",
                new: "Create Collection",
                table: {
                    name: "Field name",
                    props: "Customer properties",
                    type: "Field Type",
                    desc: "Field description",
                },
                detail: {
                    isCustomerProps: "Save to customer properties",
                    customerPropsHelper:
            "After saving, the collected information will be updated to customer information. If you do not save, it will not be updated to the customer information",
                    name: "Field name",
                    type: "Field Type",
                    text: "text",
                    desc: "Field description",
                    descPlaceHolder:
            "Describe the use of information collected by this field",
                    condition: "condition",
                    logic: "rule",
                    method: "Collection method",
                    message: "Message content",
                    range: "Extraction range",
                    entity: "Extract entity",
                    base: "Basic information",
                    collect: "Collect information",
                },
            },
            intelligentTags: {
                title: "Smart Tag",
                sessionLabelTitle: "Session Scene",
                keyEventsTitle: "Key Events",
                customerLabelTitle: "Customer Tag",
                addClassification: "Create a new label classification",
                defaultCategoryName: "default category",
                index: {
                    createTag: "Create",
                    createEvent: "Create event",
                    editTagClassification: "Edit Tag Classification",
                    selectTagClassification: "Select tag classification",
                    editTagGrounp: "Edit Group",
                    callTimeError:
            "The selected call time/conversation start time range cannot exceed one month (31 days)",
                    callRecordCountError:
            "The number of records is 0, please select the call/talk time again",
                    selectItmeCountError:
            "Please select the smart tag that needs to perform batch operations",
                    redoSuccess:
            "Re-execution is successful, please check in the task center",
                    redoError: "Re-execution failed, please try again later",
                    timeError: "The query time cannot exceed one month (31 days)",
                    categoryName: "category name",
                    categoryColor: "category color",
                    newTagClassification: "Create label classification",
                    newEventClassification: "Create classification",
                    tagClassification: "tag classification",
                    eventClassification: "Event Classification",
                },
                detail: {
                    new: "Create Smart Tag",
                    edit: "Edit Smart Tag",
                    tagName: "Name",
                    tagDesc: "Description",
                    category: "category",
                    tagStatus: "Validity",
                    operator: "Operator",
                    rule: "rule",
                    labelClassAtion: "label type",
                    labelClassAtionTip1: "Label label: labeling based on rules",
                    labelClassAtionTip2:
            "Classification label: labeling based on model training",
                    group: "group",
                    checkLabel: "Select category",
                    warn: "Early Warning",
                    warnTips:
            "With alerts turned on, when a session meets this key event rule, it will be alerted to a designated person who can receive an alert on the to-do list",
                    warnError: "Please select an early warning designator",
                },
                subTitle: {
                    common: "Smart Session Tag",
                    wecom: "Enterprise Micro Tag",
                },
            },
            customerPortrait: {
                title: "Customer Portrait",
                index: { import: "Import customer portrait" },
                searchPlaceHolder: "Customer ID, nickname or phone",
                callCount: "Number of conversations:",
                callDuration: "Total call duration:",
                dialogCount: "Total number of dialogs:",
                keyWordsStatistics: "Keyword Statistics (Top 20 & Followed Words)",
                keyWordsCount: "({0})",
                businessTag: "Business Tag",
                keyWordsCloudImg: "Keyword Cloud Map",
                customerEmotionTrend: "Customer Emotion Trend",
                notFoundCustomer: "Related customers were not retrieved",
                noKeyWords: "Related keywords not found",
                noBusinessTags: "No related business tags found",
                getKeyWordsStatisticsDataError:
          "Customer keyword statistics query failed",
                getBusinessTagsStatisticsDataError:
          "Customer business tag query failed",
                personCustome: "Customer information not queried",
                notimeInfo: "No mood information found",
                timeList: "date range",
                operator: "smart tag",
                placeHolder: "Please choose",
                placeOperator: "Please choose a smart tag",
                search: "query",
                reset: "Reset",
                searchHolder: "Search",
                call: "call",
                text: "Dialog",
            },
            sentimentAnalysis: { title: "Discovery of public opinion" },
            saleSentimentAnalysis: { title: "Public opinion analysis" },
            dataPreprocess: {
                title: "Data preprocessing",
                index: {
                    createData: "Create data",
                    selectDataSource: "Select data source",
                    selectStatus: "Select validity",
                    callTimeError:
            "The selected call time/conversation start time range cannot exceed one month (31 days)",
                    callRecordCountError:
            "The number of records is 0, please select the call/talk time again",
                    selectItmeCountError:
            "Please select the data that needs to perform batch operations",
                    redoSuccess:
            "Re-execution is successful, please check in the task center",
                    redoError: "Re-execution failed, please try again later",
                },
                detail: {
                    new: "Create data",
                    edit: "Edit data",
                    name: "name",
                    desc: "description",
                    dataSource: "Data Source",
                    status: "Validity",
                    entity: "Extract entity",
                    operator: "Operator",
                    rule: "rule",
                },
            },
            connectedByConversation: {
                title: "Communications Association",
                list: {
                    title: "Communications related function",
                    info: "Communication dialogue association function can associate different calls and dialogues according to preset rules",
                    rule: "Tong dialog association rule",
                    source: "Please select the data source",
                    classification: "Please select a classification",
                    choose: "Please choose",
                    input: "Please enter",
                    save: "Save",
                    cancel: "Cancel",
                    fieldwei: "bit",
                    fieldNot: "There is an unfilled field",
                    fiedldlengthNo: "Field length exceeds limit",
                },
            },
            components: {
                operatorListGather: {
                    conditionTitle: "CK",
                    keywordTip: "You can set multiple keywords, separated by commas",
                    applyRole: "Detect role",
                    preOperator: "pre-operator",
                    applyOperatorScope: "Detection scope",
                    addOperator: "Add operator",
                    noPreOperator: "No",
                    from: "from",
                    to: "to",
                    end: "Sentence",
                    testRegular: "Test regular expression",
                    test: "Test",
                    matchSuccess: "Match successful",
                    matchError: "Match failed",
                    querySementicTags: "Select semantic tags",
                    reQuerySementicTags: "Reselect semantic tags",
                    sementicTag: "Semantic Tag",
                    standardSentence: "Standard sentence",
                    similarity: "similarity",
                    actions: "action",
                    noFitTag: "No suitable tag was found, ",
                    addSemanticTag: "Add a new semantic tag",
                    pleaseEnterRegular: "Please enter a regular expression",
                    pleaseEnterRegularText:
            "Please enter the content that needs to be verified",
                    newAssociateComponentTip:
            "If the value is a number type, please use the old version function",
                },
                filterManage: {
                    noValueTip: "No data yet",
                    allCall: "All calls",
                    allDialogue: "All Dialogues",
                    allWechat: "All conversations",
                    getFiltersListFailed:
            "Failed to get the filter list, please try again later! ",
                    getFilterDetailFailed:
            "Failed to get filter details, please try again later! ",
                    getAllCountFailed:
            "Failed to get the total number of calls/conversations, please try again later! ",
                },
                listManage: {
                    searchPlaceHolder: "Please enter keywords to search",
                    filterConditions: "Filter Conditions",
                    records: "Record",
                    getRecordsFailed: "Failed to get records, please try again later! ",
                },
                miniListManage: {
                    getRecordsFailed: "Failed to get records, please try again later! ",
                },
                applyTemplates: {
                    title: "Applicable quality inspection template",
                    collectionTitle: "Applicable collection template",
                },
                recordDetail: {
                    showWaveAudio: "Show recording",
                    getDetailFailed: "Failed to get details, please try again later! ",
                    getSmartTagsFailed:
            "Failed to get smart tags, please try again later! ",
                    getTemplatesFailed:
            "Failed to get the applicable template, please try again later! ",
                },
                changeList: {
                    sampleStatus: "Sampling inspection status",
                    sampleAssignInspector: "Assign Inspector",
                    reviewAssignInspector: "Assign Reviewer",
                    read: "Read",
                    distributivePerson: "distributive person",
                    totalScore: "Total Score",
                },
                qualityCheckScore: {
                    totalScore: "Total Score",
                    actualScore: "Score",
                    actualGrade: "rating",
                    appeal: "Appeal",
                    remark: "Remarks",
                    manualRemark: "Manual Remark",
                    none: "No quality inspection details yet",
                    interactiveTip:
            "Use interactive recognition to detect the usage of agent FAQ",
                    interactiveIsEmptyTip:
            "Interactive identification content not detected",
                },
                semanticMark: {
                    semanticContent: "Marked sentence",
                    markedSimilarTitel: "marked similar semantic tags",
                    markedExcludeTitle: "marked to exclude similar semantic tags",
                    taskTagTitle: "Task-related semantic tags",
                    similarTagTitle:
            "Identify similar semantic tags (similar degree>40%, Top 20)",
                    delete: "delete",
                    markSimilar: "Mark similar",
                    markExclude: "Exclude similar",
                    none: "none",
                },
                selectCallTime: {
                    callTime: "Call time/session start time",
                    callTimeRemind:
            "You can select up to a single conversation record of no more than one month (31 days) in the last year",
                    callRecordCount:
            "You have selected a total of {0} call/conversation records",
                    getCallRecordError:
            "Failed to get the record, please try again later",
                },
            },
            informationEntities: {
                title: "Information entity",
                index: { import: "import information entity" },
                detail: {
                    new: "Create information entity",
                    edit: "Edit information entity",
                    name: "name",
                    apiName: "API name",
                    activeFlag: "Validity",
                    remark: "description",
                    logicExp: "Entity Regular",
                    sentenceList: "Entity content",
                    tip: "The content of the entity supports multiple entries, separated by spaces, which are synonyms for the same entity",
                    remarkLengthError: "The description length cannot exceed 255",
                    nameLengthError: "The name length cannot exceed 40",
                    entityDuplicateError: "Entity content duplicate",
                },
            },
            interactiveRecognition: {
                title: "Interactive recognition",
                classify: "Classification",
                addClassify: "Add Classify",
                editClassify: "Edit Classify",
                index: { import: "Import interactive recognition" },
                detail: {
                    new: "Create smart interaction",
                    edit: "Edit Smart Interaction",
                    type: "type",
                    standardQuestion: "Standard Question",
                    similarQList: "similar question",
                    exceptSimilarQList: "Exclude similar problems",
                    category: "category",
                    targetType: "Problem detection target",
                    status: "Validity",
                    standardAnswer: "standard answer",
                    similarAList: "similar answer",
                    wrongAList: "Wrong answer",
                },
            },
            businessAnalysis: {
                title: "Business Analysis",
                tip1: "Business trend analysis: the ordinate is the number of calls, that is, the total number of calls that meet the conditions, and the abscissa is the time",
                tip2: "Correlation analysis: analyze the correlation of multiple indicators, hover the mouse to display the correlation data, data volume, correlation, repetition rate",
                tip3: "Conversion analysis: Analyse the mutual conversion relationship between multiple indicators. The criterion for conversion is the same call/conversation. If two different indicators can be hit, it can be considered that there is a conversion relationship between these two indicators",
            },
            knowledgeBase: {
                title: "Knowledge base",
                list: {
                    add: "Add subcategory",
                    delete: "Delete category",
                    categoryName: "category name",
                    createSuccess: "Subcategory created successfully",
                    keyWord: "Keyword",
                },
                detail: {
                    type: "type",
                    status: "status",
                    classification: "classification",
                    standardQuestion: "Standard Question",
                    answerDetectionRange: "Answer detection range",
                    standardAnswer: "Standard Answer",
                },
            },
            callManage: {
                title: "Call recording filter",
                listTitle: "Call management",
                detailTitle: "Call details",
            },
            dialogueManage: {
                title: "Dialog Text Filter",
                listTitle: "Dialog management",
                detailTitle: "Dialog details",
            },
            weChatManage: {
                title: "Enterprise WeChat Conversation Filter",
                listTitle: "Enterprise WeChat Session Management",
                detailTitle: "Enterprise WeChat session details",
            },
            speechRecognition: {
                title: "Voice recognition",
                asr: "voice recognition",
                asrRemind:
          "Turning off this switch will cause the voice-to-text function and the machine quality inspection function to fail",
                asrConfig: "ASR filtering",
                imAsrConfig: "IMASR",
                imAsrConfigRemind:
          "When enabled, ASR transcription of IM will be executed",
                asrConfigRemind:
          "When enabled, only qualified calls will execute ASR content",
                correction: "Smart Error Correction",
                correctionRemind:
          "After enabling, it will use the content in \"<a href='/site/semantic-intelligence/smart-words-library/professional-words' target='_blank'>smart vocabulary-professional nouns</a>\" Mandatory Error Correction Conversion",
                correctionConfig: "Error correction filtering",
                advancedSettings: "Advanced Settings",
                advancedSettingsTip:
          "Select the specified ASR model for the specified data. ",
                mandarinASRModel: "Mandarin ASR Model",
                cantoneseASRModel: "Cantonese ASR Model",
                englishASRModel: "English ASR Model",
                correctionConfigRemind:
          "After enabling, only calls that meet the following conditions will be corrected",
                customJudgeLogicIsNull: "Custom logic cannot be empty",
                redoCorrection: "Re-execute error correction",
                callTime: "Select call time",
                callTimeRemind:
          "You can select up to a single call record of no more than one month (31 days) in the last year",
                callRecordCount: "You have selected {0} call records in total",
                redoCorrectionSubmit: "Submit for execution",
                callTimeError:
          "The selected call time range cannot exceed one month (31 days)",
                callRecordCountError:
          "The number of call records is 0, please select the call time again",
                redoCorrectionSuccess:
          "Re-correction is successful, please check in the task center",
                redoCorrectionError: "Re-correction failed, please try again later",
            },
            role: {
                title: "Role Management",
                create: "Create role",
                detail: {
                    title: "role",
                    new: "Create role",
                    view: "View role",
                    edit: "Edit role",
                    navTabNames: {
                        basicInfo: "Menu permissions",
                        taskPermissions: "Quality inspection task permissions",
                        dataPermissions: "Data permissions",
                    },
                    basicInfo: { roleName: "Name", permissions: "Permissions" },
                    taskPermissions: {
                        allTask: "All quality inspection tasks",
                        myCreatedTask: "Quality inspection task I created",
                    },
                    dataPermissions: {
                        dataPermissionsConfig: "Data permission configuration",
                        tips: "This role can see the data under the selected category",
                        select: "Select category",
                        selectPlaceholder:
              "Please select a category that requires data visibility permissions",
                    },
                },
            },
            dataMigration: {
                title: "Data migration",
                dataUpload: "Data Move In",
                dataDowload: "Data Move Out",
                error: "Please select the quality inspection task to be exported",
                list: {
                    info: "Please select the quality inspection task to be moved out",
                    listInfo:
            "Please select whether you want to move out the relevant data of the quality inspection task (such as smart vocabulary, semantic tags, information entities, intelligent interaction, etc.)",
                    noInfo:
            "Please select whether you want to move out other non-quality inspection data",
                    ownInfo: "Move out tasks only",
                    everyInfo: "Related to move out tasks",
                    allInfo: "Move out all",
                    callFilter: "Call Filter",
                    talkFilter: "Talk Filter",
                    smartTag: "Smart Tag",
                },
                upload: {
                    info: "1. Click the upload button to upload a JSON file that conforms to the format",
                    uploadSuccess: "Upload successful",
                    uploadError: "Upload failed",
                },
            },
            dataAnalysis: {
                businessStatistics: "Business Statistics",
                qualityEffect: "Quality Inspection Health",
                qualityAnalysis: "Quality Inspection Analysis",
                generalInspecitionReport: "General Report",
                fullAmountWork: "Quality inspector workload",
                intelligentTagsAnalysis: "tag analysis",
                saleIntelligentTagsAnalysis: "Intelligent Tag Analysis",
                generalInspecitionReportTip:
          "Total data: Count the total amount of call and conversation data within the currently limited time and task scope, including the amount & percentage of intelligent quality inspection data, the amount & percentage of manual sampling data, the amount of appeals, the sampling re-inspection rate, and the time trend graph",
                groupTest: "Group Test",
            },
            uploadDownloadList: {
                title: "Task Center",
                tabs: {
                    upload: "Upload import",
                    download: "Download Export",
                    taskCenter: "Task Execution",
                },
            },
            uploadDownloadModel: {
                title: "Upload and download",
                list: {
                    clear: "clear",
                    success: "success",
                    fail: "Fail",
                    hand: "in progress",
                    noInfo: "No data",
                },
            },
            billingCenter: {
                title: "Billing Center",
                export: "export",
                limit: "remind limit",
                tips: "The billing result will be deviated due to rounding, which is for reference only",
                dashboard: {
                    remainingQuota: "Current remaining quota",
                    usedQuota: "Cumulative used quota",
                    voiceQuality: "Voice Quality Inspection:",
                    voice: "Voice:",
                    textQuality: "Text Quality Inspection:",
                    text: "Text:",
                    hour: "hour",
                    tenThousandHour: "10,000 hours",
                    day: "day",
                    cutoff: "cutoff",
                    warning:
            "(The remaining quota is insufficient, please recharge as soon as possible)",
                    isClosed: "(Closed state)",
                },
                table: {
                    quotaRecord: "quota record",
                    timeFilter: "Select date:",
                    search: "query",
                    reset: "Reset",
                    id: "serial number",
                    type: "type",
                    qualityType: "Quality inspection type",
                    quotaChange: "Change",
                    surplus: "Balance",
                    changeTime: "Time",
                    voiceQuality: "Voice Quality Inspection",
                    textQuality: "Text Quality Inspection",
                    sizeChangeText: "Display per page",
                    used: "Use",
                    give: "grant",
                    deduction: "deduction",
                    callTime: "Call time",
                    count: "Processing data amount",
                    page: "Total {0} items, {1} pages",
                },
                limitModal: {
                    call: "Voice quality inspection quota is lower than",
                    callFix: "Remind by hour",
                    dialogue:
            "The remaining time for text quality inspection is less than",
                    dialogueFix: "Day to remind",
                },
            },
            messageCenter: {
                replyTitle: "Reply to me",
                answerTitle: "@my",
                shareTitle: "Share my",
            },
            columnsManage: { title: "Quality inspection field management" },
            recordUpload: {
                list: {
                    task: "Quality Inspection Task",
                    user: "operator",
                    uploadTime: "upload time",
                    endTime: "End Time",
                    total: "Total upload amount",
                    success: "Quality inspection successful",
                    failed: "Quality inspection failed",
                    progress: "Quality inspection progress",
                    inProgress: "in progress",
                    finished: "completed",
                },
                steps: {
                    uploading: "Upload recording file",
                    baseInfo: "Fill in basic information",
                    start: "Start quality inspection",
                },
                uploadComponent: {
                    dragger: "Drag and drop to add files",
                    or: "or",
                    selectFile: "Select file",
                    des: "Support wav, mp3 format, sampling rate 8000Hz, 16-bit file",
                    limit1: "Upload at a time",
                    limit2: "files, each file size does not exceed",
                    uploading: "Uploading",
                    finished: "Upload completed",
                    success: "success",
                    failure: "Failure",
                },
                baseInfo: {
                    task: "Select quality inspection task",
                    type: "Select recording format",
                    agentSide: "Channel where agent is located",
                    excel: "Upload associated data",
                    checkFileInfo: "Check file information",
                    excelFileError: "Please upload data associated with the road",
                    checking: "Checking...",
                    reCheck: "Recheck",
                    success: "Verification successful",
                    excelUoloadFail: "Failed to upload data along the way",
                    tips: {
                        tip1: "The name of the recording file in 1.ecxel must be the same as the name of the uploaded recording, and the name cannot be the same",
                        tip2: "2. The system uses the name of the recording file and the call ID to check the duplicates. If repeated, the quality inspection will fail.",
                        tip3: "3. The downloaded excel recording template, the font color is red is a required field",
                        tip4: "4. The field format of the time type is: yyyy-mm-dd hh:mm:ss, such as 2021-04-20 17:36:30",
                    },
                },
                title: "Local recording upload",
                checkTitle: "Recording quality inspection",
                start: "Start quality inspection",
                disabledTips:
          "Please verify the recording file first and then perform quality inspection",
                tips: "The recording file with the same name covers the quality inspection results and basic information",
                tips1: "Drag and drop the file here, or click upload",
                tips2: "Support",
                tips3: "Format",
                type: "wav, mp3",
                tips4: "Upload at a time",
                tips5: "Files, each file size does not exceed",
                tipsOverSize:
          "The current queue is full, please remove the files in the queue before uploading",
                size: "500M",
                count: "20",
                uploaded: "Recording file uploaded",
                uploading: "({0}%)",
                uploadFinished: "(Upload completed)",
                bottomTitle: "Recording information collection:",
                recordType: "Select the recording format:",
                double: "Dual channel call recording",
                single: "Single channel call recording",
                recordBaseInfo: "Upload basic information of recording:",
                addBtn: "Add file",
                downloadTemplate: "Template download.excel",
                checkRecord: "Check recording information",
                checking: "Checking...",
                reCheck: "Recheck",
                checkAll: "Select all",
                cancelCheckAll: "Cancel Select All",
                delete: "delete",
                deleteLotSize: "Batch delete",
                agentChannel: "Channel where the agent is located:",
                left: "Left Channel",
                right: "right channel",
                donotKonw: "Don't know",
                mission: "Select quality inspection mission:",
                uploadFail: "Upload failed",
                repeat: "Repeat file",
                overCount: "The number of files exceeds the limit",
                overSize: "File size exceeds limit",
                uoloadFail: "Failed to upload to the cloud",
                formatError: "format error",
                seccess: "verification successful",
                fail: "verification failed",
                recordChecking: "Recording quality check, please wait...",
                recordCheckSuccess: "Recording quality inspection completed",
                recordCheckFail:
          "Recording quality check failed, please check whether the recording is duplicated or invalid",
                backToIndex: "Return to upload list",
                recordCheckIsStarted:
          "There is currently a quality inspection task being executed, please start a new task after the current task ends.",
            },
            customerServicePortrait: {
                title: "Agent Portrait",
                tip1: "Sentence length distribution law: The distribution of sentence lengths is calculated according to the number of words in the sentence length and segmented, and only the agent is counted",
                tip2: "Speaking rate distribution law: the distribution of speaking rate, according to the speaking rate (words/minute), statistics are made in segments, only agent is calculated",
                tip3: "Response time distribution law: the distribution of response time, only the agent response to customers is calculated",
                tip4: "Quality inspection compliance: compliance rate of quality inspection points",
                tip5: "The average score of quality inspection tasks: the average score of quality inspection tasks",
                contol: {
                    date: "Select date:",
                    today: "today",
                    thisMonth: "this month",
                    lastMonth: "Last Month",
                    thisQuarter: "this quarter",
                    lastQuarter: "last quarter",
                    thisYear: "this year",
                    lastYear: "Last Year",
                    customer: "Agent:",
                    search: "query",
                    reset: "Reset",
                    export: "export",
                    indicator: "Indicator management",
                    selectPlaceholder:
            "Please select the agent that needs to be compared",
                    warning: "Select up to 5 customer services for comparison",
                    warning2: "Please select at least 1 agent for inquiry",
                    dataWarning:
            "Only support querying data up to 366 days after the start date",
                    seccuss: "Record exported successfully",
                },
                echarts: {
                    distributionLaw: "distribution law",
                    count: "number of times",
                    bout: "times",
                },
                portrait: {
                    ability: "Agent Ability Dimensions",
                    option: "Configuration",
                    abilityOption: "Configuration Ability Dimension",
                    placeholder: "Keyword Search for Input Ability Dimensions",
                    save: "Save",
                    warning:
            "Please select at least 3 indicators for configuration, the number of indicators can be selected at most 10",
                },
                label: { title: "Agent Business Label" },
            },
            customerServiceRankList: {
                routerTitle: "RankList",
                title: "Agent Ranking",
                select: {
                    date: "Select date:",
                    today: "today",
                    thisMonth: "this month",
                    lastMonth: "Last Month",
                    thisQuarter: "this quarter",
                    lastQuarter: "last quarter",
                    thisYear: "this year",
                    lastYear: "Last Year",
                    group: "Agent Group:",
                    groupPlaceholder: "Please select a agent group",
                    customer: "Agent:",
                    customerPlaceholder: "Please select agent",
                    search: "query",
                    reset: "Reset",
                    compared: "contrast",
                    export: "export",
                    column: "Column",
                    warning: "Select up to 5 customer services for comparison",
                    warning2: "Please select at least one agent",
                    dataWarning:
            "Only support querying data up to 366 days after the start date",
                    selected: "Selected agent:",
                    seccuss: "Record exported successfully",
                },
                compared: {
                    title: "Select Agent",
                    return: "Cancel",
                    compared: "contrast",
                    tips: "Please choose 2-5 agent for comparison",
                    warning: "Only support 2-5 agent for comparison",
                },
                transfer: { leftTitle: "List item", rightTitle: "Display item" },
                table: {
                    page: "Total {0} items, {1} pages",
                    actionTitle: "Action",
                    action: "View",
                },
            },
            conceptWord: {
                title: "Concept word",
                new: "Create concept word",
                control: {
                    search: "Search:",
                    searchPlaceholder: "Name or keyword search",
                    commit: "query",
                    reset: "Reset",
                    import: "import",
                    export: "export",
                    seccuss: "Record exported successfully",
                },
                modal: { title: "Import concept words" },
                table: {
                    sizeChangeText: "Display per page",
                    page: "Total {0} items, {1} pages",
                    edit: "Edit",
                    delete: "delete",
                    request:
            "After deletion, it cannot be restored. Are you sure to delete? ",
                    cancel: "Cancel",
                    title: {
                        conceptWord: "Concept word",
                        describeWordsNum: "Description word count",
                        updateTime: "Update time",
                        status: "Validity",
                        effective: "effective",
                        invalid: "invalid",
                        action: "operation",
                    },
                },
                detail: {
                    title: { new: "Create concept word", edit: "Edit concept words" },
                    name: "Concept word name",
                    namePlaceholder: "Please enter the name of the concept word",
                    effectiveness: "effectiveness",
                    effective: "effective",
                    invalid: "invalid",
                    description: "Description word: ",
                    descriptionPlaceholder: "Please enter a description word",
                    add: "add",
                    repeat: "repeat content",
                    empty: "Descriptor cannot be empty",
                    recommendEmpty:
            "There are no more recommended description words, please enter manually to add",
                    recommend: "smart recommendation descriptor",
                    cancel: "Cancel",
                    change: "Change a group",
                    descriptionSelected: "Description word added:",
                    save: "Save",
                    success: "Concept word save success",
                    warning: "Concept word name cannot be empty",
                    warning2: "Please add at least 1 descriptive word",
                    submitBtnHandle: {
                        title:
              "The descriptive words that are checked are not added, save the descriptive words that are not added will be discarded",
                        content:
              "Click OK to return to the homepage of the concept word, confirm to continue? ",
                    },
                },
            },
            smartTools: {
                btn: "Smart Tools",
                title: {
                    similarWords: "Related Words Discovery",
                    search: "Search sentence by word",
                    semanticTags: "Semantic Tags",
                },
                sentenceSearch: {
                    placeholder:
            "Please enter keywords to search, support multiple keywords",
                    more: "View full text",
                    pageUp: "Previous page",
                    pageDown: "Next page",
                    total: "Total {0} records",
                },
                similarDiscover: {
                    placeholder: "Please enter keywords",
                    copyed: "Copied",
                    none: "No related words, please try other keywords",
                },
                smartTags: {
                    searchPlaceHolder: "Please enter keywords or sentences",
                    createSmartTag: "Create",
                    createClusteringSmartTag: "Create a new with {0} data",
                    addFromKeyword: "Add search content to similar sentences",
                    hit: "hit",
                    similar: "Similar",
                    name: "Semantic Tag Name",
                    namePlaceHolder: "Please enter the semantic label name",
                    recallWords: "Recall Words",
                    recallWordsPlaceHolder: "Please enter recall keywords",
                    recallRegular: "Recall Regular",
                    recallRegularPlaceHolder: "Please enter the recall regular",
                    rules: "rules",
                    standardSentences: "Standard sentences",
                    standardSentencesPlaceHolder: "Please enter a standard sentence",
                    similaSentences: "similar sentences",
                    similaSentencesPlaceHolder: "Please enter a similar sentence",
                    notSaveError: "Please save the semantic tags first, and then search",
                    add: "add",
                    newPage: "Open in a new page",
                    querryError:
            "There are similar fields in the generalization list (advanced configuration similar deduplication constraints)",
                    detailTitle: "Semantic label details",
                },
            },
            trainingCenter: {
                title: "Training Center",
                edit: "Edit",
                delete: "delete",
                seleteAll: "Select all",
                cancelCheckAll: "Cancel Select All",
                add: "add",
                remove: "Remove",
                save: "Save",
                cancel: "Cancel",
                fastTraining: {
                    title: "Quick training",
                    titleDes:
            "Quick training aims to help semantic tags quickly generalize to 20 similar sentences",
                    semanticTags: "Semantic Tags",
                    semanticTagsPlaceholder: "Please enter the process keyword search",
                    standardSentence: "Standard sentence: {0}",
                    similarSentencesPlaceholder:
            "Enter similar sentences to add or search",
                    similarSentencesAdd: "Add",
                    similarSentencesSearch: "Search",
                    similarSentences: "Similar sentences have been added",
                    trainingCount: "Training number: {0}",
                    similarSentencesRecommend: "Recommend similar sentences",
                    noResp: "No relevant semantic tags, please change keywords",
                    similarSentencesModalTitle: "Similar sentence search",
                    moreInfo: "View context",
                    moreInfoBtn: "Context",
                    modalSearchType: { all: "all", customer: "Agent", client: "Client" },
                    repetWarning:
            "The same similar sentence already exists, please do not add it repeatedly",
                    repetRemoveTip:
            "Repetitive content in the save similar sentence has been automatically removed",
                },
                trainingHistory: {
                    title: "Training History",
                    select: {
                        date: "Select time:",
                        today: "today",
                        thisMonth: "this month",
                        lastMonth: "Last Month",
                        thisQuarter: "this quarter",
                        lastQuarter: "last quarter",
                        thisYear: "this year",
                        lastYear: "Last Year",
                        search: "query",
                        reset: "Reset",
                    },
                    table: {
                        time: "time",
                        user: "operator",
                        tag: "Semantic tag",
                        similarCount: "Number of similar sentence identifiers",
                        deleteCount: "Number of sentences removed",
                        page: "Total {0} items, {1} pages",
                    },
                },
                classifiedTraining: {
                    title: "Classification training",
                    tagsGrounp: "tag grouping",
                    placeHolder: "Please choose",
                    cellTime: "Select time",
                    search: "query",
                    reset: "Reset",
                    classifiedFlag: "Classified training situation",
                    num: "numerical value",
                    gas: "percentage",
                    noData: "unclassified training",
                    noDataHeight:
            "Unclassified data accounts for a relatively high proportion, and training is urgently needed",
                    noDataSmall:
            "Unclassified data accounts for a relatively high proportion, it is recommended to train",
                    noDataDays: "{0} days before the last execution",
                    training: "training",
                    start: "Start",
                    addClass: "Add category",
                    custom: "Advanced Filter",
                    marked: "Data errata has been marked",
                    markedHeight:
            "No errata has been made for {0} days, it is recommended to make errata",
                    markedSmall:
            "No errata has been performed for {0} days, errata is urgently needed",
                    mark: "errata",
                    dataCheck: "Classified data check",
                    dataCheckSmall:
            "K-L divergence is higher than 0.5, it is recommended to train",
                    dataCheckHeight:
            "K-L divergence is higher than 0.8, need training urgently",
                    check: "Check",
                    history: "Mark history record",
                    historyTime:
            "Daily annotation and modification will automatically perform model training at 2:00 AM",
                    today: "Today mark",
                    article: "Article",
                    none: "none",
                    historyCheck: "Historical total mark",
                    look: "View record",
                    historyluate: "Recalculation of historical data",
                    startluate: "Initiate a recalculation",
                    classData: "Classification data",
                    classStatus: "Training progress",
                    next: "Next",
                    prev: "Previous",
                    finish: "Complete training",
                    stop: "End training",
                    classDetail: "Training Details",
                    trainingNone: "There is no smart tag to train yet",
                },
                classifiedTrainingHistory: {
                    title: "Training History",
                    time: "Select time:",
                    tag: "Tag type:",
                    user: "Operator:",
                    data: "Select data source:",
                    search: "query",
                    searchBar: "Search:",
                    searchBarPlaceholder: "Please enter the call ID/talk ID search",
                    reset: "Reset",
                    export: "export",
                    all: "all",
                    select: {
                        today: "today",
                        thisMonth: "this month",
                        lastMonth: "Last Month",
                        thisQuarter: "this quarter",
                        lastQuarter: "last quarter",
                        thisYear: "this year",
                        lastYear: "Last Year",
                        search: "query",
                        reset: "Reset",
                    },
                    table: {
                        user: "operator",
                        date: "operation time",
                        data: "Data source",
                        id: "Call ID/Conversation ID",
                        tagType: "Smart tag grouping",
                        tagName: "tag name",
                        state: "state",
                        sizeChangeText: "Display per page",
                        page: "Total {0} items, {1} pages",
                        call: "call",
                        dialogue: "Dialogue",
                    },
                },
                clusteringAnnotation: {
                    title: "Clustering labeling",
                    left: { title: "Clustering Results" },
                    right: {
                        title: "Clustering sentences",
                        checkAll: "Select all this page",
                        tagging: "tagging",
                        detail: "View",
                        meaningless: "ignore",
                        meaninglessTips:
              "The ignored clustering results will no longer be displayed in the future. Are you sure you want to ignore it? ",
                        cancel: "Cancel",
                    },
                },
            },
            clientCenter: {
                clientManage: {
                    title: "Customer Management",
                    tabTitle: "Customer Agent Center",
                },
                wechatClientManage: {
                    title: "Enterprise Wechat Customer",
                    table: {
                        id: "Customer ID",
                        name: "Customer Nickname",
                        tag: "Customer Tag",
                    },
                },
            },
            collectionRule: {
                title: "Collection Rules",
                form: {
                    info: "Smart Information Collection",
                    infoPlaceholder: "Please select a category",
                },
                table: {
                    name: "Field name",
                    props: "Customer properties",
                    type: "Field Type",
                    desc: "Field description",
                },
            },
            dataAdd: {
                title: "Data Add",
                types: { auto: "Automatically add", manual: "Manually add" },
            },
            wechatStaff: {
                table: {
                    name: "Name",
                    part: "Department to which you belong",
                    phone: "Mobile phone number",
                    email: "Mailbox",
                    wechartId: "Enterprise WeChat ID",
                    status: "On-job status",
                },
                info: "Please select the department that needs to synchronize data first",
                synSuccess: "Sync successful",
                init: "Initialize",
                syn: "Sync",
            },
            appealHistory: {
                appealTime: "Appeal Time",
                cancelTime: "Cancel time",
                reviewTime: "Review Time",
                inspector: "Quality Inspector",
                result: "Appeal result",
                complainResult:
          "A total of {0} quality inspection items were appealed, {1} successfully appealed and {2} rejected",
                reason: "reason for appeal",
                rejection: "Rejection Reason",
                cancel: "Cancel",
                tip: "Are you sure you want to withdraw the appeal? ",
            },
            appealSdk: {
                time: "Communication time",
                externalIdCall: "Call record ID",
                externalIdText: "Dialog record ID",
                customerNickName: "Customer Nickname",
                readStatus: "View status",
                status: "Appeal status",
                isManual: "Whether manual quality inspection",
                complainButtonInfo:
          "{0} appeals are allowed, {1} appeals have been made, and there are {2} appeals remaining. {3} Can appeal before ",
                submit: "Submit Appeal",
                complain: "Appeal",
                allComplain: "Appeal All",
                pendingSubmit: "Pending Submit",
                taskIds: "Quality Inspection Task",
                graded: "graded",
                complainSuccess: "Complaint submitted successfully",
                appealSdkIndex: { title: "Smart Quality Inspection Results" },
            },
        },
        lookupRecords: {
            getFiltersListError:
        "Failed to get filter list information, please check the network connection or try again later! ",
            columns: "Columns",
            filter: "Filter",
            noSearchResult: "No record found",
            pleaseSearch: "Please select a filter and try to search",
            sift: "sift",
            create: "Create",
            search: "Search",
            createTaskTitle: "Create task",
            title: "title",
            deadline: "Deadline time",
            principal: "person in charge",
            enqueueTime: "Enqueue Time",
            computeError: "Calculation failed, please wait!",
            sum: "Sum",
            max: "Maximum",
            min: "Minimum value",
            avg: "average value",
            count: "count",
            maxDate: "Maximum (Latest)",
            minDate: "Minimum value (oldest)",
        },
        mainMenu: {
            insight: {
                name: "Insight",
                getInsightUrlError:
          "Failed to obtain Insight link address, please try again later! ",
            },
            template: {
                learningReminder: "Learning Reminder",
                subscriptionDynamics: "Subscribe to News",
            },
        },
        tasks: {
            subMenu: {
                manageHome: { text: "Task Management", chaneg: "Switch" },
                qualityCheckTemplate: {
                    text: "Quality inspection template",
                    collectText: "Collection template",
                    condition: { text: "Quality inspection conditions" },
                    checkPoint: { text: "Rule configuration" },
                    automaticAdd: { text: "Process automation" },
                    manualAdd: { text: "Manually add" },
                    roleConfig: { text: "role configuration" },
                    basicInfo: { text: "Basic information" },
                    smartWordsLibrary: { text: "Smart Thesaurus" },
                    grade: { text: "Quality inspection rating" },
                    collectionRule: { text: "Collection rules" },
                    addData: { text: "Add data" },
                    collectionList: { text: "Collection list" },
                },
                qualityCheckWorkbench: {
                    text: "Quality Inspection Workbench",
                    wechatText: "Analysis Workbench",
                    informationCollectText: "Collection Workbench",
                    qualityInspectionList: {
                        text: "Quality Inspection List",
                        wechatText: "Analysis list",
                        informationCollectText: "Collection list",
                    },
                    condition: { text: "Quality inspection list (obsolete)" },
                    reviewList: { text: "Review List" },
                    spotCheckList: { text: "Sampling list" },
                    caseBaseList: { text: "case library" },
                    testSetList: { text: "Quality Test Collection" },
                },
                statisticsAndReports: {
                    text: "Statistics and Reports",
                    wholeReport: { text: "Overall report" },
                    groupReport: { text: "Agent Group Report" },
                    agentReport: { text: "Agent Report" },
                    customerServiceExecution: { text: "Agent Analysis" },
                    analysisOverview: { text: "Analysis Overview" },
                    inspectionItemsAnalysis: { text: "Quality inspection item analysis" },
                },
                wechatQa: {
                    text: "Enterprise WeChat Quality Inspection",
                    task: { text: "Analysis task" },
                    baseInfo: { text: "Basic information" },
                    checkPoint: { text: "Analysis model configuration" },
                    setting: { text: "Enterprise WeChat analysis configuration" },
                    automaticAdd: { text: "Process automation" },
                    manualAdd: { text: "Manually add" },
                },
                clientCenter: { text: "Customer Center" },
            },
            conditionDictionaries: {
                title: "All conditions",
                searchDefaultText: "Please enter ID or name",
                id: "ID",
                name: "name",
                conditionDetailedShow: "Expand",
                conditionDetailedHide: "Collapse",
                remark: "Description:",
                checkRole: "Role:",
                operator: "Operator:",
                operatorLogic: "Logic:",
            },
            audioTextView: {
                customer: "Customer",
                agent: "Agent",
                unsupportedFormat: "This format is not currently supported",
            },
            checkPointClassifications: {
                add: "Add category",
                name: "name",
                enterToSave: "Press Enter to save",
                highestScore: "Highest score upper limit",
                minimumScore: "Minimum Score Lower Limit",
                defaultMinScore: "The default is 0 points",
                minimumScoreTitle: "Minimum score",
                beginScore: "Overall starting score value",
                beginScoreTitle: "Start score value",
                scoreSetTip:
          "The upper limit of the maximum score is less than the sum of the scores, you can choose:",
                ignoreExcessSpillover: "Ignore excess overflow",
                automaticWeightCalculation: "Automatic weight calculation",
            },
        },
        operatorGather: {},
        fieldSelect: { field: "Field" },
        checkRange: {
            numberTipOne: "NO.",
            numberTipTwo: "sentence",
            numberTipThree: "sentence to the first",
            conditionLimit: "Condition Limit",
            roleLimit: "Role Limit",
            contextLimit: "sentence limit",
            notZero: "Sentence limit does not support input 0",
            startMustLessThanEnd:
        "The starting sentence position must be less than the ending sentence position",
        },
        waveAudio: {
            play: "Play",
            pause: "Pause",
            rateOne: "0.5",
            rateTwo: "1.0",
            rateThree: "1.5",
            rateFour: "2.0",
            rateFive: "1.2",
        },
        operatorListGather: {
            pleaseEnter: "Please enter",
            pleaseEnterRegular: "Please enter a regular expression",
            pleaseEnterGrammar: "Please enter the grammar rules",
            pleaseEnterTestText: "Please enter the content that needs to be verified",
            pleaseEnterLoudness: "Please enter the loudness in decibels",
            pleaseEnterRangeAbility: "Please enter the range of change",
            afterHit: "Check after hit",
            pleaseEnterContext: "Please enter a reference sentence",
            wordNumber: "Word number gap",
            intervalSecond: "The interval is greater than",
            minute: "every minute",
            wordUnit: "words",
            test: "Test",
            testRegular: "Test regular expression",
            testGrammar: "Test Grammar",
            testGrammarError: "Grammar test failed",
            regularError: "Regular expression error, please re-enter",
            pleaseEnterSemanticSentence:
        "Please enter the sentence that needs semantic detection",
            pleaseEnterAssociate:
        "Please refer to the description on the right to construct an associated data expression",
            querySemanticTags: "Select Semantic Tags",
            reQuerySemanticTags: "Reselect Semantic Tags",
            semanticTags: "Semantic Tags",
            standardSentence: "Standard sentence",
            semanticName: "semantic label",
            sentence: "Standard sentence",
            similarity: "similarity",
            actions: "action",
            tipOne: "No suitable label was found,",
            tipTwo: "Add a new semantic tag",
            notLessThan: "Not less than",
            notGreaterThan: "Not Greater Than",
            ignoreLessThan: "Ignore less than",
            wordUnitSet: "word sentence",
            speedWordNotNull:
        "The minimum and maximum words of speech rate detection cannot both be empty",
            speedWordMinValueLessThanMaxValue:
        "The minimum number of words for speed detection cannot be greater than the maximum number of words",
            speechGrabbingIntervalTip: "Grabbing Interval >= ",
            speechLengthTip: "Sentence length >= ",
            msUnit: "ms",
            sUnit: "s",
            grabIntervalNotNull: "Grab the call interval cannot be empty",
            grabLengthNotNull: "Grab the sentence length cannot be empty",
            pleaseSelectEntity: "Please select information entity",
            pleaseSelectOperator: "Please select an operation",
            pleaseSelectBasicInfo: "Please select basic information",
            pleaseEnterFixedValue: "Please enter a fixed value",
            loudnessUnit: "db",
            percent: "%",
            matchSuccess: "Match successful",
            matchError: "Match failed",
            mindetectionStatement: "Minimum detection statement",
            conceptWordholder: "Please select a concept word",
            elementHolder: "Please enter an element",
            associateMedol: { tabs: { data: "Data", operator: "operator" } },
            repet: "repeat",
            bout: "times",
            ignore: "Ignore less than",
            ignoreAffix: "Word sentence",
            associateData: "Associated Data",
            pretreatmentData: "pretreatment data",
            reviewTaskData: "Review task data",
            taskData: "Quality inspection task data",
            businessData: "Business records",
            functionOperator: "Function Operator",
            operation: "operation operator",
            notAnyMatch: "When any problem is not hit:",
            times: "Number of occurrences",
            newAssociateDataTip:
        "If the value is a number type, please use the old version function",
        },
        conditionGather: {
            cycle: "cycle",
            rlue: "rules",
            delete: "Remove",
            sampling: "Sampling",
            item: "bar",
            percent: "%",
            samplingSame: "Same number of samples per agent",
            samplingRecheck:
        "Allow repeated sampling of items that have been sampled",
            dateUnit: "Number",
            samplingTip:
        "Different rules covering the same call will not be sampled repeatedly, and records that have been sampled but not checked will not be sampled again. ",
            add: "Add Rule",
        },
        ruleGather: {
            title: "Rules",
            screeningCondition: "Screening Condition",
            addRule: "Add Rule",
            inspector: "Quality Inspector",
            ruleTip:
        "Rule-priority allocation mode, from top to bottom, no repetitive allocation, random allocation for items within the rule or that don’t match any rule",
            moveUp: "Move up",
            moveDown: "Move down",
            top: "Top",
        },
        pushRulesGather: {
            title: "Rules",
            delete: "Remove",
            newPush: "Create Push",
            newRule: "Create rule",
            sampling: "Sampling",
            item: "bar",
            percent: "%",
            samplingSame: "Same number of samples per agent",
            dateUnit: "Number",
            screeningCondition: "Screening Condition",
            email: "Mailbox",
            emailPlaceholder: "Enter email address, separated by comma",
            updateType: "Update Type",
            pushDetail: "Push details",
            pushUrl: "Push URL",
            pushWay: "Push Type",
            pushMethod: "Push method",
            pushContentType: "Data Format",
            pushData: "Push data",
            additionalData: "additional data",
            inspectionType: "Quality Inspection Type",
            all: "all",
        },
        taskAdditionalData: {
            newData: "Create data",
            delete: "Remove",
            placeholder: "Please enter a name",
            dataPlaceholder: "Please enter content",
        },
        UdeskQaReact: {
            loadSdkError: "SDK failed to load, please try again later!",
        },
        gearOptionList: { evaluation: "evaluation" },
        tableComponentTransfer: {
            leftTitle: "List item",
            rightTitle: "Display item",
        },
        kmTinymce: {
            link: "Insert/Edit link",
            modalTitle: "Insert link",
            href: "Address",
            text: "Display text",
            title: "title",
            target: "Open mode",
            none: "none",
            newWindow: "Open in a new window",
            buttonStyle: "button style",
            close: "Close",
            open: "open",
            answerMessage: "Jump/Send Message",
            answerMessageTitle: "Send message button",
            answerMessageText: "Display text",
            answerMessageContent: "Message Content",
            answerMessageButtonStyle: "Button Style",
            answerMessageTips:
        "Note: The send message button only supports web channels, please configure carefully",
            answerSwitchStaff: "Jump/Transfer to Manual",
            answerSwitchStaffTitle: "Switch to manual button",
            answerSwitchStaffText: "Display text",
            answerSwitchStaffRule: "Button Rule",
            answerSwitchStaffButtonStyle: "Button Style",
            answerSwitchStaffTips:
        "Note: The manual switch button only supports web channels, please configure carefully",
            textButton: "Text button",
            borderButton: "Button with frame",
            message: "Send message",
            switchStaff: "switch to manual",
            messageError: "Button content cannot exceed 10 words",
            messageIsEmptyError: "Button content cannot be empty",
            okText: "OK",
            cancelText: "Cancel",
            video: "video",
            uploadVideo: "Upload video",
            uploadText: "Only support MP4 format, the size does not exceed 20M",
            videoEmptyOrUploading: "Video is empty or uploading",
            customEmoji: "Emoji",
        },
        massageConfiguration: {
            pushTarget: "Notify target",
            customizePushTarget: "Custom push target",
            sysUser: "Current system user",
            sysRole: "Current system role",
            emailPushTarget: "Send target",
            targetEmailsPlaceholder:
        "Please enter the email address, separated by commas",
            subject: "mail name",
            SDKID: "SDK identification ID",
            content: "Notification content",
            sdkPushType: "Push plan",
            sdkPushURL: "Push URL",
            pushData: "Push data",
            sdkPushWay: "Push Way",
            dataType: "Data format",
            additionalData: "additional data",
            name: "message name",
            filter: "filter conditions",
            triggerType: "Trigger mechanism",
            pushCycle: "Timed and fixed frequency",
            pushContent: "Report content",
            qualityList: "Quality inspection result list",
            designated: "designated personnel",
            messageType: "Message Type",
        },
        taskCard: {
            template: {
                cancelCollection: "Cancel collection",
                collection: "Collection",
            },
        },
        semanticTagsSelect: { index: { standardSentence: "Standard Sentence:" } },
        gong: {
            filesOpen: {
                folderItem: {
                    collection: "collection",
                    calls: "calls",
                    createdBy: "created by:",
                    createdOn: "created time:",
                },
                fileItem: {
                    uIProvidesIcon: "UI provides Icon",
                    minute: "minute",
                    callClip: "Call Clip",
                    start: "start",
                    End: "End",
                    employees: "Employee:",
                    Customer: "Customer:",
                    sessionTime: "Session time:",
                    reasonForRecommendation: "Recommendation reason:",
                    addedBy: "Added by:",
                    addTime: "Add time:",
                },
                modalAddFile: {
                    caseTitle: "Case Title:",
                    addSomeClipsToTheCaseLibrary: "Add some clips to the case library",
                    addToCaseBase: "Add to case base",
                    storedIn: "stored in",
                    pleaseSelect: "Please select",
                    reasonsForRecommendation: "recommendation reasons",
                    pleaseEnter: "Please enter",
                },
            },
            shareModal: {
                index: {
                    customer: "Customer:",
                    employees: "Employee:",
                    timeliness: "Timeliness:",
                    recordingLinkValidWithinDays:
            "The recording link is valid within days",
                    sharingInstructions: "Sharing Instructions:",
                    shareSuccess: "Share successfully! ",
                    cancel: "Cancel",
                    share: "Share",
                    copySucceeded: "Copy succeeded! ",
                    copyShareLink: "Copy share link",
                    shareWithColleagues: "Share with colleagues",
                    shareWithCustomers: "Share to customers",
                    staff: "staff",
                    selectColleagues: "Select Colleagues",
                    pleaseSelectColleaguesToShare: "Please select a colleague to share",
                    pleaseEnterTheSharingDescription:
            "Please enter the sharing description",
                },
            },
            waveAudio: {
                index: {
                    theFileCannotBeFound: "The file cannot be found! ",
                    sale: "Sale:",
                    customer: "Customer:",
                    topicDetection: "Topic detection:",
                    keyEvents: "Key events:",
                },
            },
            helpCommentModal: {
                index: {
                    selectColleagues: "Select Colleagues",
                    helpContent: "Help Content",
                    visibleRange: "Visible range",
                    seekCounseling: "seeking counseling",
                },
            },
            commentDispalyItem: {
                index: { seekAdvice: "seek advice", concatText: "to" },
            },
            customerInsight: {
                index: {
                    customerInsight: "Customer Insight ",
                    customerLabel: "Customer label",
                },
            },
            intelligentRecommend: {
                index: {
                    scriptRecommendation: "Script recommendation",
                    objectionHandlingScripts: "Objection handling scripts",
                    productSellingPointScripts: "Product selling point scripts",
                    knowledgePointRecommendation: "Knowledge point recommendation",
                    relatedKnowledgePoints: "Related knowledge points",
                    recommendedCases: "Recommended cases",
                },
            },
            supervision: {
                index: {
                    scriptRecommendation: "Script recommendation",
                    caseRecommendation: "Case recommendation",
                    hit: "Hit",
                    miss: "Miss",
                    supervisionItem: "Supervision item",
                    implementation: "Implementation",
                    reInspection: "Re inspection",
                    supervisionItemSettings: "Supervision item settings",
                },
            },
            supervisionClassificationModal: {
                index: {
                    telephoneCall: "Telephone call",
                    enterpriseWeChatCall: "Enterprise WeChat Call",
                    enterpriseMicroConversation: "Enterprise micro conversation",
                    open: "open",
                    close: "close",
                    conversationScenario: "Conversation scenario",
                    monitorConversationChannels: "Monitor conversation channels",
                    permanentlyValid: "Permanently valid",
                    permanentlyInvalid: "Permanently invalid",
                    customValidity: "Custom validity",
                    termOfValidity: "term of validity",
                    newRating: "Create rating",
                    whenConversation: "When conversation",
                    hit: "Hit",
                    miss: "Miss",
                    time: "Time",
                    rated: "Rated",
                    qualified: "qualified",
                    unqualified: "unqualified",
                    modifiedSuccessfully: "Modified successfully",
                    classificationName: "Classification Name:",
                    supervisionObject: "Supervision object",
                    entryintoforceTime: "entry-into-force time",
                    supervisionItemRating: "Supervision item rating",
                    pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Please check whether the rating of supervision item is correct",
                    ratingRules: "Rating rules",
                    settingOfRatingRulesForSupervisionItems:
            "Setting of rating rules for supervision items",
                },
            },
            reInspect: {
                index: {
                    keyEvents: "Key events",
                    supervisionItem: "Supervision item",
                    conversationScenario: "Conversation scenario",
                    customerLabel: "Customer label",
                    reDetectionRules: "Re detection rules",
                },
            },
        },
        imgUpload: {
            index: {
                uploadPictures: "Upload pictures",
                pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Please select an image whose size does not exceed 2048kb",
            },
        },
        chatRecord: {
            chatRecordItem: {
                components: {
                    debugInfo: {
                        or: "or",
                        and: "and",
                        wrong: "wrong",
                        matchTo: "Match to",
                        rule: "rule",
                        satisfactionOperator: "Satisfaction operator",
                        customerLabel: "Customer label",
                        keyEvents: "Key events",
                        knowledgePoints: "Knowledge points",
                        sessionLabel: "Session label",
                        supervisionItem: "Supervision item",
                    },
                    linkExplorer: { linkInTheText: "Link in the text:" },
                },
            },
        },
        createForm: {
            field: { customComponentNotFound: "Custom component not found:" },
        },
        sdkLoaderBase: {
            component: { custom: "custom" },
            dropdownMonit: {
                updateSucceeded: "Update succeeded",
                areYouSureToDelete: "Are you sure to delete?",
                custom: "custom",
            },
            modalMonit: {
                saveCurrentReportConfigurationForm:
          "Save current report configuration = = = form",
                saveCurrentReportConfiguration: "Save current report configuration",
                pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Please enter the name of common settings. The maximum length is 20!",
                preservation: "preservation",
            },
            template: {
                saveCurrentReportConfiguration: "Save current report configuration",
                updateCurrentReportConfiguration: "Update current report configuration",
            },
        },
        audioCallBasicInfoV2: {
            template: {
                workOrderInformation: "Work Order Information",
                workOrder: "Work Order",
                triggerScenario: "Trigger scenario",
                associatedCallConversation: "Associated call / conversation",
            },
        },
        audioCallContentInfoV2: {
            components: {
                testBtn: {
                    testWithCurrentCommunicationDialogueText:
            "Test with current communication / dialogue text",
                },
            },
        },
        checkPointOperatorListGather: {
            template: { section: "Section", day: "day" },
        },
        massagePushConfiguration: {
            component: {
                iAmMeItsADifferentFireworksxxxx8827:
          "I am me, it's a different Fireworks @ 156xxxx8827",
                hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hi, this is the notification of robot quality inspection warning message. Since it was added to the group on October 17",
            },
            interfacePush: {
                component: {
                    iAmMeItsADifferentFireworksxxxx8827:
            "I am me, it's a different Fireworks @ 156xxxx8827",
                    hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hi, this is the notification of robot quality inspection warning message. Since it was added to the group on October 17",
                },
            },
        },
        productMatrix: {
            index: { clickToLearnMore: "Click to learn more" },
            schema: {
                textRobot: "Text robot",
                trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Trainer escort, document Q & A, task dialogue, table Q & A, Atlas Q & A",
                capitalRetentionRobot: "Capital retention robot",
                richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Rich inquiry and retention strategies, high personification communication, ensuring high retention rate and efficient automatic conversion of clues",
                outboundRobot: "Outbound robot",
                workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Work order completion return visit, service satisfaction survey, operation and maintenance fault warning prompt, order delivery reminder, e-commerce activity notice, candidate appointment, and help business automation and business efficiency improvement",
                incomingRobot: "Incoming robot",
                flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Flexibly integrate work orders, orders and other business systems to realize process automation; Customized recognition of speech semantic model, and the business effect is immediate; Entity (mobile phone number, order amount, order time, address,...) identification to quickly support complex business",
                intelligentQualityInspection: "Intelligent quality inspection",
                fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Full quality inspection, timely and efficient, with an accuracy rate of 85%",
                intelligentSessionAnalysis: "Intelligent session analysis",
                insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Insight into business weaknesses, listen to the voice of customers, and improve marketing transformation",
                enterpriseWechatConversationAnalysis:
          "Enterprise wechat conversation analysis",
                effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Effectively identify potential business opportunities and risks, and increase the marketing conversion rate by 20%",
                assistant: "Assistant",
                theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "The agent assistant can guarantee the novice agent to complete the service and improve the call quality of the skilled agent. Let every seat be your trump card",
                digitalPeople: "Digital people",
                FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          '"Face to face" anthropomorphic communication, support voice, text, video, touch multi-modal interaction, achieve immersive communication experience, and improve brand image and user service experience',
                aIKnowledgeZhongtaiEnterpriseSearch:
          "AI knowledge Zhongtai · Enterprise Search",
                knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Knowledge search, knowledge sharing, document search, database docking search",
                aIKnowledgeCenterKCsKnowledgeBase:
          "AI knowledge center · KCs knowledge base",
                provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Provide full life-cycle management of knowledge, unified maintenance of robot and im knowledge base, support docking with third-party knowledge base, knowledge template, atomic knowledge and other powerful functions to bring more convenient knowledge management experience",
                aIKnowledgeCenterKnowledgeMap: "AI knowledge center · knowledge map",
                oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "One stop map construction integrates enterprise knowledge, large-scale map shows knowledge details, docking ability and customized experience",
                modularDevelopmentPlatformOriginalEngineASR:
          "Modular development platform · original engine · ASR",
                theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "The end-to-end algorithm is adopted to realize the effect experience of business customization. The natural scene recognition rate exceeds 90%, and the personalized vocabulary recognition rate exceeds 97%",
                modularDevelopmentPlatformIntelligentMarketing:
          "Modular development platform · intelligent marketing",
                makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Make use of deep learning algorithms and user portraits to achieve accurate recommendation of goods and improve sales conversion",
                modularDevelopmentPlatformUserPortrait:
          "Modular development platform · user portrait",
                accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Accurately build user portraits based on user data and conversation data to fully describe customer characteristics",
                modularDevelopmentPlatformIntelligentRecommendation:
          "Modular development platform · intelligent recommendation",
                automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Automatically recommend products that users may be interested in according to user portraits and product portraits, and realize personalized recommendation for thousands of people",
                modularDevelopmentPlatformNLPPAAS:
          "Modular development platform · NLP PAAS",
                provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Provide NLP basic capabilities with high accuracy, covering the underlying capabilities of different granularity such as words, sentences and articles",
            },
        },
        qualityScore: {
            index: {
                clickCopy: "Click Copy",
                hitDetailsOfOperatorsInRules: "Hit details of operators in rules:",
                hit: "Hit",
                miss: "Miss",
                filter: "Filter:",
                clickToViewDetails: "Click to view details",
            },
            components: {
                flowNodeList: {
                    branch: "branch",
                    pleaseSelect: "Please select",
                    viewTheCompleteProcess: "View the complete process",
                    thereIsCurrentlyNoProcessAvailable:
            "There is currently no process available",
                },
                pointItem: { xiangHit: "Xiang Hit" },
                flowNodeListModal: { index: { completeProcess: "Complete process" } },
            },
        },
        recordDetail: {
            template: {
                theDataHasBeenClearedPeriodically:
          "The data has been cleared periodically",
                subject: "Subject:",
                description: "Description:",
            },
            components: {
                addToTask: {
                    index: {
                        addToQualityInspectionTask: "Add to quality inspection task",
                    },
                },
            },
        },
        relatedDetail: {
            index: { associatedCallConversation: "Associated call / conversation" },
        },
        samplingTarget: {
            index: {
                time: "Time",
                samplingQuantityIsLessThan: "Sampling quantity is less than",
                alertWhen: "Alert when",
                everyday: "everyday",
                weekly: "weekly",
                monthly: "monthly",
                samplingInspectionTargetSavedSuccessfully:
          "Sampling inspection target saved successfully",
                personnel: "personnel",
                eachCustomerService: "Each agent",
                cycle: "cycle",
                samplingInspectionTarget: "Sampling inspection target",
                strip: "strip",
                alertSettings: "Alert settings",
            },
        },
        samplingTargetBanner: {
            index: {
                customerService: "agent",
                complete: "complete",
                Objective: "/Objective",
                everyday: "everyday",
                weekly: "weekly",
                monthly: "monthly",
            },
        },
        sessionInsights: {
            components: {
                numberOfPreemptiveCalls: "Number of preemptive calls",
                numberOfMessages: "Number of messages",
                strip: "strip",
                proportionOfMessages: "Proportion of messages",
                speechPercentage: "Speech percentage",
                longestCustomerTalk: "Longest customer talk",
                numberOfIndepthDiscussions: "Number of in-depth discussions",
                second: "second",
                maximumDurationOfContinuousSpeech:
          "Maximum duration of continuous speech",
                questionFrequency: "Question frequency",
                customer: "customer",
                keyEvents: "Key events:",
            },
            index: {
                conversationSummary: "Conversation Summary",
                cancel: "cancel",
                determine: "determine",
                edit: "edit",
                establish: "establish",
                conversationalInsights: "Conversational insights",
                keyEvents: "Key events",
                numKeyEvents: "Key events",
                questionAnalysis: "Question analysis",
                find: "find",
                questions: "Questions",
                conversationAction: "Conversation action",
                customer: "customer",
                sale: "sale",
                sessionLabel: "Session label",
                employeeQuestions: "Employee questions",
                customerQuestions: "Customer questions",
            },
        },
        ticketTextInfo: {
            index: {
                customer: "customer",
                customerService: "agent",
                replyTo: "Reply to:",
                addressee: "addressee:",
            },
        },
        listManage: {
            template: {
                reIdentification: "Re identification",
                rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Re-recognition will consume transcription time, please operate with caution",
                determine: "determine",
                executionSuccessful: "Execution successful",
                addToQualityInspectionTask: "Add to quality inspection task",
                batchOperation: "Batch operation",
            },
        },
        addToTaskModal: {
            index: {
                successfullyAdded: "Successfully added",
                addToQualityInspectionTask: "Add to quality inspection task",
                qualityInspectionTask: "Quality inspection task",
            },
        },
        customFilterModal: {
            component: {
                detectRoles: "Detect Roles",
                detectionRange: "Detection range",
                sentence: "sentence",
            },
        },
        inspectionDetail: {
            template: {
                theDataHasBeenClearedPeriodically:
          "The data has been cleared periodically",
            },
        },
        backToSubSystem: {
            index: { returnToBusinessSystem: "Return to business system" },
        },
        qualityCheckScoreTalk: { template: { keyWord: "key word" } },
        coach: {
            customerPortrait: {
                detail: {
                    index: {
                        portraitName: "Portrait Name",
                        backgroundDescription: "Background Description",
                        customerLabel: "Customer label",
                        labelManagement: "Label management",
                    },
                },
                list: {
                    components: {
                        addCustomerPortraitItemBtn: {
                            index: { newlyBuild: "newly build" },
                        },
                    },
                },
            },
            detailedInquiryConfig: {
                components: {
                    detailedInquiryConfigTitle: {
                        index: { questioningSettings: "Questioning settings" },
                    },
                    detailedInquiryList: {
                        index: {
                            increaseQuestioning: "Increase questioning",
                            remove: "remove",
                            addQuestioning: "Add Questioning",
                            section: "Section",
                            secondInquiry: "Second inquiry",
                            questioningScore: "Questioning score",
                        },
                    },
                },
                index: {
                    accordingToRules: "According to rules",
                    byScore: "By score",
                    whenMissRule: "When Miss Rule",
                    whenConductingQuestioning: "When conducting questioning",
                    pleaseSelectARule: "Please select a rule",
                    ruleCannotBeEmpty: "Rule cannot be empty",
                    currentScriptScore: "Current script score ≤",
                    pleaseEnterTheScore: "Please enter the score",
                    scoreCannotBeEmpty: "Score cannot be empty",
                },
            },
            dialogue: {
                components: {
                    narrator: { narrator: "Narrator:" },
                    pPT: { slide: "slide" },
                    realTimeAnalyzer: {
                        index: {
                            script: "Script",
                            semantics: "semantics",
                            violatingWords: "Violating words",
                            speechSpeed: "Speech speed",
                            emotion: "emotion",
                        },
                    },
                    scoreDetail: {
                        index: {
                            appealToBeSubmitted: "Appeal to be submitted",
                            ratingHasBeenReviewed: "Rating has been reviewed",
                            ratingAppealInProgress: "Rating appeal in progress",
                            expressionScore: "Expression score",
                            moodParticlesTimes: "Mood particles, {0} times",
                            changeTheModalParticlesTo: "Change the modal particles to",
                            second: "second",
                            interrogation: "Interrogation",
                            questioningWithoutScoring: "Questioning without scoring",
                            branch: "branch",
                            matchingRate: "Matching rate",
                            ratingAppeal: "Rating appeal",
                            changeTo: "Change to",
                            wordPerMinute: "Word per minute",
                            completeScript: "Complete script",
                            incompleteScript: "Incomplete script",
                            semanticHit: "Semantic hit",
                            semanticMisses: "Semantic Misses",
                            scriptViolation: "Script violation-",
                            modified: "Modified",
                            notChanged: "not changed",
                            keyWord: "key word",
                            semanticLabels: "Semantic labels",
                            semanticMatchingRate: "Semantic matching rate",
                            violatingWords: "Violating words“",
                            delete: "delete",
                            unmatched: "Unmatched",
                            matching: "matching",
                            matchTo: "Match to",
                            notMatchedTo: "Not matched to",
                            scriptScore: "Script score",
                            standardScript: "Standard Script",
                            emotionalScore: "Emotional score",
                            speechSpeedScore: "Speech speed score",
                            viewResolution: "View Resolution",
                            nodeConfiguration: "Node Configuration",
                        },
                    },
                    userNode: {
                        theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "The answer time for this question has exceeded, and the answer content will be automatically submitted",
                    },
                },
            },
            scoringRules: {
                components: {
                    drawerTitle: {
                        scoringRules: "Scoring Rules",
                        cancel: "cancel",
                        determine: "determine",
                    },
                    scoringRuleItem: {
                        whole: "whole",
                        arbitrarily: "arbitrarily",
                        pleaseEnterARuleName: "Please enter a rule name",
                        standardScript: "Standard Script",
                        if: "If",
                        and: "And",
                        or: "or",
                        thenScore: "Then score",
                        pleaseEnterAScore: "Please enter a score",
                        bonusPoints: "Bonus points",
                        minusPoints: "Minus points",
                        hit: "Hit",
                        misses: "Misses",
                        keyWord: "key word",
                        semantics: "semantics",
                    },
                    title: {
                        scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Scoring rules (the total score of the current script is",
                        points: "Points)",
                        editRules: "Edit Rules",
                    },
                },
            },
            userTagsSelect: {
                index: { noLabelsCurrentlyAvailable: "No labels currently available" },
            },
        },
        smartTags: {
            template: {
                areYouSureYouWantToReExecuteTheSmartLabel:
          "Are you sure you want to re execute the smart label?",
                reExecute: "Re execute",
            },
        },
        reInspectionResultModal: {
            index: {
                reQualityInspectionTask: "Re quality inspection task",
                requalityInspection: "Re-quality inspection",
                waiting: "Waiting",
                viewDetails: "View Details",
            },
        },
        checkPointConditionListGather: {
            checkPointCondition: {
                components: {
                    applyTimeType: { index: { detectionTime: "Detection time:" } },
                },
            },
        },
    },
    fields: {
        qualityInspectionForm: {
            list: {
                name: "name",
                description: "Description",
                type: "type",
                status: "Status",
                actions: "action",
            },
        },
        qualityCheckingTask: {
            index: {
                name: "Task name",
                description: "Description",
                form: "Quality Inspection Form",
                actions: "action",
                createUser: "creator",
            },
            samplingRecords: {
                createTime: "Time",
                type: "type",
                operateUser: "operator",
                number: "Add number",
                filterConditionList: "Filter Condition",
                sampleRuleStr: "Sampling conditions",
                timeGreaterThan: "Time is greater than",
                timeLessThan: "Time is less than",
            },
        },
        customFilters: {
            createdAt: "Call time",
            customerId: "Customer",
            agentId: "Agent",
            userGroupId: "Agent Group",
            state: "state",
            lastEvaluateUserId: "Last Evaluator",
            submitTime: "Last submission time",
            totalScore: "Total Score",
            formItemForbidIdList: "Forbidden Item",
            formItemDeadlyIdList: "Deadly Item",
        },
        samplingInspection: {
            callTime: "Call time",
            dialogTime: "Dialog time",
            originator: "assigner",
            createTime: "Create Time",
            updateTime: "Update time",
            status: "Process status",
            score: "score",
            actions: "action",
            reviewer: "Reviewer",
            samplingOfficer: "Sampling inspector",
            id: "call log ID",
            time: "call time",
            phone: "Customer Phone",
            staff: "Agent",
            inspectionStatus: "Quality Inspection Status",
            samplingStatus: "Sampling status",
            inspectionGrade: "grading",
        },
        smartWordsLibrary: {
            synonymWords: {
                standardWords: "Standard Words",
                synonymWords: "Synonym",
                synonymWordsNumber: "Synonym Number",
                useFrequency: "use frequency",
                effectiveness: "effectiveness",
                operation: "operation",
            },
            common: {
                useFrequency: "use frequency",
                effectiveness: "effectiveness",
                operation: "operation",
            },
            professionalWords: {
                professionalWords: "Professional Nouns",
                useFrequency: "Use Statistics",
                homophonic: "Hot words (homonymous replacement)",
                target: "noun replacement",
                targetWords: "Replacement words",
                homophonyWords: "Homophony shielded words",
                operation: "operation",
            },
            attentionWords: "Attention Words",
            ignoreWords: "Ignore words",
            wordsWeight: {
                selfDefineWeightWords: "Custom weight words",
                weightWords: "Weight Words",
                weight: "weight",
                useFrequency: "use frequency",
                effectiveness: "effectiveness",
                operation: "operation",
            },
            corpusData: {
                corpusDescription: "corpus description",
                updateTime: "Update time",
                wordsCount: "Word count",
                effectiveness: "effectiveness",
                operation: "operation",
                corpusContent: "Content",
            },
        },
        semanticTags: {
            title: "Semantic label",
            tags: "standard sentence",
            orgName: "Owning Organization",
            tagCount: "training number",
            usingCount: "Reference count",
            activeFlag: "Validity",
            suggestion: "training suggestion",
            actions: "action",
        },
        intelligentTags: {
            tagName: "Name",
            tagDesc: "Description",
            tagRefCnt: "Number of tags",
            categoryName: "tag category",
            eventName: "event classification",
            tagStatus: "Validity",
            actions: "action",
            tagType: "tag type",
        },
        dataPreprocess: {
            name: "name",
            desc: "description",
            dataSource: "Data source limit",
            type: "type",
            status: "Validity",
            actions: "action",
        },
        informationEntities: {
            name: "name",
            apiName: "API name",
            type: "type",
            remark: "description",
            activeFlag: "Validity",
            actions: "action",
        },
        interactiveRecognition: {
            questionAndAnswer: "Standard Questions & Answers",
            type: "type",
            status: "Status",
            actions: "action",
            question: "question",
        },
        role: { id: "ID", name: "name", actions: "action" },
        uploadDownload: {
            taskName: "File name",
            createTime: "date",
            size: "size",
            createUserName: "Operator",
            status: "Status",
            result: "Result",
            actions: "action",
        },
        taskCenterList: {
            name: "task content",
            createTime: "Submit Time",
            startTime: "start time",
            endTime: "End Time",
            createUserName: "Operator",
            status: "Status",
            result: "Result",
            actions: "action",
        },
        customerInformation: {
            title: "Agent Information",
            data: "View only abnormal data",
            list: {
                agentNo: "Work ID/Agent ID",
                agentName: "Agent",
                agentTel: "Phone",
                agentEmail: "Mailbox",
                updateTime: "Update time",
                actions: "action",
            },
            upload: {
                second:
          "2. Click the import button to upload the EXCEl file that conforms to the template format",
            },
        },
        IndexManagement: {
            title: "Indicator Management",
            list: {
                indicatorName: "name",
                indicatorDesc: "Description",
                activity: "Validity",
                inspectDataSource: "Data Source",
                sysType: "Type",
                agentGroups: "Agent Group",
                actions: "action",
            },
            detail: {
                nameEmpty: "Name cannot be empty",
                descript: "Description",
                descriptEmpty: "Description cannot be empty",
                active: "Validity",
                activeEmpty: "Validity cannot be empty",
                dataSource: "Data Source",
                dataSourceEmpty: "Data source cannot be empty",
                grounp: "Agent Team",
                grounpEmpty: "Agent group cannot be empty",
                grounpHolder: "Please select a agent group",
                type: "Score method",
                typeNone: "Score method cannot be empty",
                base: "Basic points",
                baseNone: "Basic points cannot be empty",
                positive: "positive factor",
                positiveNone: "Positive factors cannot be empty",
                customAdd: "Add filter",
                negative: "Negative factors",
                negativeNone: "Negative factors cannot be empty",
                customOK: "Filter criteria:",
                judgeStrategy: "Condition rule:",
                other: "Other factors",
                otherAdd: "add factor",
                agent: "raw data",
                section: "section number",
                sectionNone: "The number of sections cannot be empty",
                step: "Interval step length",
                stepNone: "Interval step length",
                save: "Save",
                cancel: "Cancel",
            },
        },
    },
    enums: {
        gongRolesTypes: {
            all: "all",
            staff: "only myself",
            department: "this department and below",
        },
        gongStatisticsDate: { week: "week", month: "month", season: "season" },
        gongCoachTaskStatus: {
            wait: "to be coached",
            already: "coached",
            ignore: "ignored",
        },
        callRangeStatus: {
            all: "All calls",
            sale: "Initiated by employee",
            customer: "Initiated by customer",
        },
        todoListType: {
            later: "listen later",
            help: "counseling for help",
            contact: "contact later",
            send: "send information",
            warn: "risk warning",
        },
        frequencyTypes: {
            everyDay: "daily",
            everyWeek: "Weekly",
            everyMonth: "Monthly",
        },
        classifiedTraing: {
            trainTypeList: {
                noneDataTrain: "Uncategorized data training",
                markedDataCorrigenda: "Marked DataCorrigenda",
                classifiedDataCheck: "Classified data check",
                markedHistoryRecord: "Marked History Record",
                historyDataRecalculate: "Recalculation of historical data",
            },
            dataExtractMode: {
                random: "completely random",
                intelligentRecommend: "intelligent recommendation",
                custom: "Custom",
                specifiedClass: "Specified Class",
            },
            smartRatesList: {
                markRate: "Marking rate",
                corrigendaRate: "errata rate",
                kl: "K-L divergence",
                signalNoiseRate: "Signal-to-noise ratio",
            },
        },
        visibleRangeTypes: {
            all: "all",
            customerGroup: "Agent Group",
            userDefined: "Custom",
        },
        samplingRateTypes: { everyDay: "Draw every day", everyWeek: "Weekly draw" },
        taskRemindTypes: {
            confirm:
        "Send reminder when the quality inspection task record needs to be confirmed",
            appeal:
        "When the quality inspection task record initiates an appeal, send a reminder",
        },
        weekDays: {
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
        },
        reportDateTypes: {
            dailyReport: "daily report",
            weeklyReport: "weekly report",
        },
        samplingRangeDayTypes: { today: "today", yesterday: "Yesterday" },
        samplingRangeWeekTypes: { thisWeek: "this week", lastWeek: "last week" },
        samplingRuleTypes: {
            proportion: "proportion sampling",
            random: "random sampling",
        },
        taskStatusTypes: {
            waitEvaluate: "To be evaluated",
            waitSubmit: "to be submitted",
            waitConfirm: "To be confirmed",
            waitReview: "To be reviewed",
            completed: "Completed",
            all: "all",
        },
        qualityInspectionForm: {
            formTemplateTypes: {
                directScore: "Direct Score",
                weightedScore: "weighted score",
                deductScore: "deduct points",
                directScoreSecondLevel: "Direct Score-Second Level",
                weightedScoreSecondLevel: "Weighted Score-Second Level",
                deductScoreSecondLevel: "Deduct Score-Second Level",
            },
        },
        fieldOperators: {
            is: "equal to",
            not: "not equal to",
            contains: "contains",
            notContains: "does not contain",
            not_contains: "does not contain",
            containsAny: "contains any",
            contains_any: "contains any",
            is_any: "Contains any",
            not_any: "Does not contain any",
            notContainsAny: "Does not contain any",
            not_contains_any: "Does not contain any",
            containsAll: "Contains all",
            contains_all: "include all",
            notContainsAll: "not contain all",
            nont_contains_all: "not contain all",
            isNull: "is empty",
            is_null: "is empty",
            isNotNull: "Not empty",
            is_not_null: "not empty",
            greaterThan: "Greater than",
            greater_than: "greater than",
            greaterThanEq: "Greater than or equal",
            greater_than_eq: "greater than or equal",
            lessThan: "less than",
            less_than: "less than",
            lessThanEq: "Less than or equal to",
            less_than_eq: "less than or equal to",
            between: "between",
            notBetween: "not between",
            not_between: "not between",
            relative: "equal to",
            set: "Set to",
            belongs: "belongs",
            notBelongs: "Not Belongs",
            not_belongs: "not equal to",
            today: "today",
            yesterday: "Yesterday",
            the_day_before_yesterday: "Day before yesterday",
            tomorrow: "Tomorrow",
            next_seven_day: "The next seven days",
            last_seven_day: "Last seven days",
            this_week: "this week",
            next_week: "Next week",
            last_week: "last week",
            this_month: "this month",
            next_month: "Next month",
            last_month: "last month",
            this_year: "this year",
            next_year: "Next year",
            last_year: "last year",
            exact_match: "Exact match",
            add_value: "Add value",
            remove_value: "Remove value",
            set_null: "blank",
            isAny: "any equal to",
            notAny: "Any is not equal to",
            belongTo: "belong to",
            notBelongTo: "not belong",
            hasAny: "Contains any",
            notHasAny: "Does not contain any",
            prefixContains: "The beginning is equal to",
            prefixNotContains: "The beginning is not equal to",
            suffixContains: "The end is equal to",
            suffixNotContains: "The end is not equal to",
            nextSevenDay: "The next 7 days",
            lastSevenDay: "Last 7 days",
            thisWeek: "this week",
            nextWeek: "Next week",
            lastWeek: "last week",
            thisMonth: "this month",
            nextMonth: "Next month",
            lastMonth: "Last Month",
            thisYear: "this year",
            nextYear: "Next year",
            lastYear: "Last Year",
            after: "Later than (including the day)",
            before: "Before (including the day)",
            allMatching: "Match all",
            allNotMatching: "All Not Matching",
            hit: "Hit",
            not_hit: "Not Hit",
        },
        fieldsOperators: {
            is: "equal to",
            is_null: "Also empty",
            head_equal: "The beginning is equal to",
            tail_equal: "The end is equal to",
            is_not_null: "Not empty at the same time",
            not: "not equal to",
            diff_equal: "The difference is equal to",
            diff_greater_than: "The difference is greater than",
            diff_greater_than_eq: "The difference is greater than or equal to",
            diff_less_than: "The difference is less than",
            diff_less_than_eq: "The difference is less than or equal to",
            hour_equal: "same hour",
            day_equal: "same day",
            week_equal: "Same week",
            month_equal: "Same month",
            year_equal: "same year",
            day: "day",
            minute: "minute",
            hour: "hour",
            part_equal: "Part is equal to",
        },
        samplingColumnCaptions: {
            created_at: "call time",
            agent_id: "Agent",
            get_direction: "call type",
            call_result: "call result",
            survey: "evaluation",
            derived_from_id: "source",
            quit_queue_why: "queue status",
            queuing_duration: "queuing time consuming",
            talking_seconds: "Talk time",
            ringing_seconds: "Ring time",
            hangup_by: "call hangup party",
        },
        callTypes: {
            callIn: "Call In",
            callOut: "Call Out",
            threeParty: "(Third party)",
            consultation: "(consult)",
            insert: "(force insertion)",
            monitor: "(monitor)",
            transfer: "(transfer)",
            intercept: "(intercept)",
            transferOutside: "(transfer outside line)",
            threePartyOutside: "(Tripartite outside)",
            consultingOutside: "(Consulting outside line)",
        },
        callResults: {
            staffAnswer: "Agent answering",
            staffNotAnswer: "Agent missed",
            customerAnswer: "Customer Answer",
            customerNotAnswer: "Customer missed",
            phoneBusy: "Phone Busy",
            phoneOffline: "Phone offline",
            customerSpeedHangUp: "Customer SpeedHangUp",
            customerHangUp: "Customer Hang Up",
            queueTimeout: "Queue timeout",
            queueGiveUp: "Abandon the queue",
            outlineAnswer: "External line answer",
            outlineNotAnswer: "Outside line missed",
            noChooseQueue: "No queue selected",
        },
        defaultSurveys: {
            noEvaluation: "not evaluated",
            noNeedToEvaluate: "No need to evaluate",
        },
        queueStates: {
            queueSuccess: "Queue success",
            queueTimeout: "Queue timeout",
            queueGiveUp: "Abandon the queue",
            noStaffOnline: "No agent online",
        },
        callHangers: {
            customer: "Customer",
            staff: "Agent",
            outline: "outside line",
        },
        workFlowTypes: {
            noAppealsMode: "No Appeals Mode",
            appealsMode: "Allow appeals mode",
        },
        fieldCategories: {
            standardCondition: "Standard Filter Condition",
            customerCondition: "User-defined filter conditions",
        },
        sampleTypes: { automatic: "Automatic sampling", manual: "Manual sampling" },
        defaultFields: {
            createdAt: "Call time",
            agentId: "Agent",
            getDirection: "Call type",
            callResult: "call result",
            survey: "evaluation",
            derivedFrom: "source",
            quitQueueWhy: "Queue status",
            queuingDuration: "queuing time consuming",
            talkingSeconds: "Call Duration",
            ringingSeconds: "Ring time",
            hangupBy: "call hangup party",
        },
        conditionFieldCategories: {
            fixedField: "Fixed Field",
            customField: "Custom Field",
            secondLevelScore: "Secondary classification",
            formItemScore: "Score item",
            forbids: "forbidden items",
            deadlies: "Deadly Item",
        },
        columnFieldCategories: {
            agent: "Agent",
            callLog: "Call log",
            businessFields: "Business Information",
            customer: "Customer",
            customerCustomFields: "Customer Customization",
            source: "source",
            queue: "Queue",
            fixed: "Fixed",
            secondLevelScore: "Secondary classification",
            formItemScore: "Score item",
            forbids: "forbidden items",
            deadlies: "Deadly Item",
        },
        taskListDefaultFields: {
            startTime: "Call time",
            name: "Agent",
            state: "state",
            totalScore: "Total Score",
            formItemForbidList: "Forbidden Item",
            formItemDeadlyList: "Deadly Item",
            comment: "evaluation notes",
            inspector: "Scorer",
            review: "Reviewer",
        },
        evaluationStates: {
            initial: "Initialize",
            waitEvaluate: "To be evaluated",
            evaluated: "To be submitted",
            waitConfirm: "To be confirmed",
            waitCheck: "To be reviewed",
            complete: "Completed",
        },
        workBenchInfoTypes: {
            callInfo: "call information",
            businessInfo: "Business Information",
            customerInfo: "Customer Information",
        },
        evaluationEventStates: {
            create: "Create",
            valuate: "evaluation",
            edit: "Edit",
            submit: "Submit",
            confirm: "Confirm",
            appeal: "Appeal",
            checkConfirm: "Recheck Confirm",
            check: "Recheck",
        },
        formItemTypes: {
            direct: "Direct score",
            weight: "weight",
            deduct: "deduct points",
            forbid: "forbidden item",
            deadly: "Deadly Item",
        },
        appealCheckCallTypes: {
            callIn: "Call In",
            callBack: "Two-way Call Back",
            callOut: "Outbound call direct dial",
            autoCall: "Automatic outbound call",
        },
        appealCheckCallStatus: {
            waitReview: "To be reviewed",
            reviewed: "reviewed",
            filed: "Archived",
        },
        samplingStatus: {
            all: "all",
            unchecked: "Extract unchecked",
            checked: "Sampled checked",
        },
        inspectionStatus: {
            all: "all",
            unread: "Unread",
            readed: "read",
            appeal: "Appeal",
            review: "review",
            filed: "Archived",
        },
        gradeChooseHits: { yes: "Yes", no: "No" },
        appealCheckStatus: {
            all: "All status",
            unread: "Unread",
            inComplaint: "Complaint in progress",
            reviewed: "reviewed",
            readed: "read",
            filed: "Archived",
            spotchecked: "Spotchecked",
        },
        flowStatus: {
            unread: "Unread",
            alreadyRead: "Already read",
            complaining: "Appealing",
            reviewed: "reviewed",
            sampling: "Sampling unchecked",
            samplingCompleted: "sampled",
            complainCancel: "Cancelled",
        },
        complainStatus: {
            success: "success",
            fail: "Fail",
            default: "Appealing",
            canceled: "Canceled",
        },
        reviewInspectionStatus: {
            inComplaint: "Complaint in progress",
            reviewed: "reviewed",
        },
        gradeChangeTypes: {
            edit: "Edit",
            check: "Recheck",
            file: "Archive",
            remark: "Remarks",
            complain: "Appeal",
            review: "review",
            reviewEvaluation: "Review Evaluation",
            sample: "Sampling inspection",
            sampleEvaluation: "Sampling evaluation",
            sampleSubmit: "Sampling submission",
            sampleAssign: "Sampling Assignment",
            reviewAssign: "Review Assignment",
            read: "Read",
            reviewConfirm: "Review Confirm",
            sampleConfirm: "Sampling Confirmation",
            caseBase: "Add to case group",
            sampleCancel: "Sampling cancel allocation",
            reviewCancel: "Review cancel assignment",
            sampleAgain: "Sampling redistribution",
            reviewAgain: "Review Reallocation",
            btachDelete: "Delete sampling records",
            rapidSampling: "Quick sampling",
            reInspection: "Re-inspection",
            smartTagsChange: "Smart Tag Change",
            cancelAppeal: "Cancel Appeal",
        },
        appealTypes: {
            transliterationError: "Transliteration Error",
            discriminationError: "discrimination error",
            otherError: "Other error",
        },
        inspectDataSources: {
            voiceCall: "Voice call",
            textDialogue: "Text Dialogue",
            realTimeVoiceCall: "Real Time Voice Call",
            realTimeTextDialogue: "Real-time text dialogue",
            wechatDialogue: "Enterprise Wechat Dialogue",
            taobao: "E-commerce text dialogue",
            ticket: "Ticket",
            wechatRadio: "Qiwei Voice",
        },
        inspectTaskType: {
            all: "all",
            common: "Regular quality inspection",
            relate: "Related Quality Inspection",
        },
        inspectApproveType: {
            all: "All",
            resolve: "Approved",
            resolving: "Audit in progress",
            reject: "Failed to pass the audit",
        },
        dataSourceFlags: {
            all: "No limit",
            voiceCall: "Call",
            textDialogue: "Dialogue",
        },
        smartWordsLibrary: { effective: "effective", invalid: "invalid" },
        activeFlags: { effiective: "effective", invalid: "invalid" },
        labelTypes: {
            dimensionLabel: "dimension label",
            classificationLabel: "Classification label",
        },
        pointTypes: {
            automatic: "automatic evaluation",
            manual: "Manual evaluation",
            interactiveDetection: "Interactive Detection",
            smartRules: "Smart Rules",
            machineLearning: "Self-learning mode",
            intelligentModel: "Intelligent Model",
        },
        pointGradeTypes: {
            radio: "Single selection",
            input: "input",
            select: "Select",
            all: "Satisfy all",
            any: "satisfy any",
            customize: "Customize",
        },
        pointNaChecked: { check: "N/A" },
        operatorTypes: {
            keyword: "keyword matching",
            question: "Interrogative sentence",
            regular: "Regular expression",
            context: "Context repeat",
            semantics: "Semantic match",
            word: "Word count similar sentence",
            dialogue: "Dialog time interval",
            speed: "Speech speed detection",
            grabDetection: "Grab the call detection",
            semanticTags: "Semantic Tags",
        },
        applyRoles: {
            all: "all",
            customer: "Customer",
            agent: "Agent",
            robot: "Robot",
        },
        saleApplyRoles: { all: "all", customer: "customer", agent: "employee" },
        applyOperatorScopes: {
            all: "full text",
            preCondition: "Precondition",
            scope: "specified scope",
        },
        keywordScopes: { all: "Detect all keywords", any: "Detect any keyword" },
        preOperatorHitTypes: {
            first: "First match",
            every: "every match",
            last: "last match",
            any: "Match any time",
            none: "not matched",
        },
        operatorRuleTypes: {
            all: "satisfy all",
            any: "satisfy any",
            custom: "Custom logic",
        },
        informationEntityTypes: {
            system: "System",
            custom: "Custom",
            moduleCompany: "Module",
        },
        organizationTypes: { null: "None", moduleCompany: "Module" },
        customType: {
            smartTag: "Extract entities",
            scriptTimes: "Speech count times",
            dataCategory: "Data Classification",
        },
        interactiveQuestionTypes: {
            standard: "Standard Questions and Answers",
            multiElement: "Multi-element question and answer",
            guideMultiRound: "guided multi-round interaction",
        },
        targetTypes: { all: "all", customerService: "Agent", customer: "Customer" },
        interactiveTypes: {
            similarQList: "similar question",
            exceptSimilarQList: "Exclude similar problems",
            similarAList: "similar answer",
            wrongAList: "Wrong answer",
        },
        filterTypes: {
            call: "Call recording",
            dialogue: "Dialogue text",
            wechat: "Enterprise WeChat",
            wechatAll: "Enterprise WeChat All Dialogue",
            ticket: "Ticket session",
            taobao: "E-commerce session",
            wechatRadio: "Qiwei Voice",
        },
        fieldTypes: {
            keyword: "Keyword",
            agent: "Agent",
            agentGroup: "Agent Group",
            sigleListbox: "single selection",
            multiListbox: "Multiple selection",
            text: "Single line text",
            textArea: "Multi-line text",
            dateTime: "Time",
            number: "number",
            voice: "",
            customer: "Customer",
            fieldWithLink: "With link field",
            percentage: "percentage",
            intelligentClassification: "Intelligent Classification",
            testSetList: "Test Set Dynamic Field",
            inspector: "Quality Inspector",
        },
        saveTypes: { save: "Save" },
        knowledgeType: { oneToOne: "Q&A" },
        updateTypes: { update: "Update", saveAs: "Save As" },
        recommendItemActionTypes: {
            similar: "Similar",
            exclude: "Exclude",
            ignore: "ignore",
        },
        gradeStatus: {
            unread: "Unread",
            alreadyReview: "Already reviewed",
            alreadyFiled: "Archived",
            waitReview: "To be reviewed",
            alreadyRead: "Already read",
        },
        qualityCheckDetailTypes: { review: "review", spotCheck: "spot check" },
        sampleAndReviewStatus: {
            init: "not extracted",
            unread: "Unread",
            alreadyRead: "Already read",
            complaining: "Appealing",
            reviewed: "reviewed",
            sampling: "Sampling unchecked",
            samplingCompleted: "sampled",
        },
        interactiveAnswerTypes: {
            noAnswer: "No answer detected",
            answer: "Answer detected",
            wrongAnswer: "Wrong answer detected",
        },
        interactiveMatchedQuestionTypes: {
            noQuestion: "No problem detected",
            question: "Problem detected",
            eliminateQuestion: "Exclude problem detected",
        },
        isManual: {
            yes: "Has manual quality inspection",
            no: "No manual quality inspection",
        },
        readStatus: { yes: "read", no: "unread" },
        reInspectionType: {
            recalcScore: "Only recalculate score (including rating)",
            recheckPoint: "Re-calculate quality check points and scores",
        },
        common: {
            customer: "customer",
            customerService: "Customer service",
            robot: "robot",
            customerInformation: "Customer Information",
            workOrderFields: "Work Order Fields",
            intelligentConversationAnalysis: "Intelligent conversation analysis",
            insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Insight into business weaknesses, listen to customer voices, and enhance marketing transformation",
            intelligentAccompanyingPractice: "Intelligent accompanying practice",
            createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Create immersive AI accompaniment exercises that simulate real business scenarios, enabling all employees to master gold medal scripts and quickly improve dialogue skills",
            intelligentQualityInspection: "Intelligent quality inspection",
            fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Full quality inspection, timely and efficient, with accuracy rate above 85%",
            salesEmpowerment: "Sales empowerment",
            aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI reshapes sales management, realizes digital and visual communication process, transparently manages every link of sales, intelligently insight into every step of communication, finely diagnoses business problems, and perfectly replicates sales experience",
            algorithmPlatform: "Algorithm platform",
            basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Based on self-developed original heart engine voice semantics, ASR and NLP model customization, get through business scenario data, realize system self-learning, and achieve the goal of more intelligent and more accurate when used",
            generalSettings: "General settings",
            unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Unified setting and management of data permissions and employee roles",
            callLog: "Call log",
            intelligentRules: "Intelligent Rules",
            businessRecords: "Business Records",
            customFilter: {
                call: "Voice call",
                text: "Text dialogue",
                smartTags: "Smart Tags",
                pretreatment: "Pretreatment",
                smartTagGroup: "Smart Tag Group",
                currency: "General Data",
                wechat: "Enterprise WeChat",
                spotCheck: "spot check",
                personaField: "Customer List",
                ticket: "Ticket session",
                taobao: "E-commerce session",
                wechatRadio: "Qiwei Voice",
                reviewList: "Review",
            },
            talkerRecognitionType: {
                API: "API identification track role",
                text: "Text recognition",
            },
            operatorTypes: {
                keyword: "keyword matching",
                question: "Interrogative sentence",
                regular: "Regular expression",
                context: "Context repeat",
                semantics: "Semantic match",
                word: "Word count similar sentence",
                dialogue: "Dialog time interval",
                speed: "Speech speed detection",
                grabDetection: "Grab the call detection",
                semanticTags: "Semantic Tags",
                keywordStrict: "Keyword matching (strict)",
                link: "Link field",
                entityInfoDetection: "Information entity detection",
                basicInfoDetection: "Basic information detection",
                volume: "Volume detection",
                emotion: "Sentiment analysis",
                grammar: "grammar rules",
                sentenceLength: "Sentence length detection",
                conceptWord: "Concept word",
                questionsAndAnswers: "Knowledge Base Answers (Old)",
                element: "Element Q&A",
                silent: "Response timeout",
                associate: "Access data",
                repeat: "Repeat send",
                timeInterval: "Interval duration",
                typoDetection: "typo detection",
                silentOld: "Silent",
                knowledge: "knowledge answer",
                wecomRemark: "Customer enterprise remark",
                customerAddTime: "Customer Add Time",
            },
            keywordOperators: {
                all: "match all",
                allNot: "All does not match",
                any: "any match",
            },
            applyRoles: { all: "all", customer: "Customer", agent: "Agent" },
            sentenceLength: {
                less: "less than",
                greater: "greater than",
                equal: "equal",
                greater_or_equal: "Greater than or equal",
                less_or_equal: "less than or equal to",
            },
            dialogueApplyScopes: {
                differentRoles: "Between different roles",
                agent: "Agent",
                customer: "Customer",
                agentResponse: "Agent Response",
                customerResponse: "Customer Response",
                customerNoResponse: "Customer no response",
            },
            applyConditionHitTypes: {
                first: "first hit",
                every: "Every hit",
                last: "last hit",
            },
            applyConditionScopes: {
                current: "current",
                before: "before",
                after: "after",
                beforeAll: "Before all",
                afterAll: "After all",
                around: "near",
            },
            voiceDemoTypes: {
                singleChannel: "Single channel call recording",
                dualChannel: "Dual channel call recording",
            },
            sendStatus: {
                sending: "Sending",
                arrive: "delivered",
                fail: "send failed",
                off_sending: "Offline sending unread",
                off_arrive: "read",
                rollback: "Withdrawn",
            },
            collectionMethod: {
                assignment: "Direct assignment",
                entity: "Entity extraction",
            },
            systemTypes: { qa: "Smart Quality Inspection", wfm: "Smart scheduling" },
            entityOperators: {
                equals: "equal to information",
                notEquals: "Not equal to information",
                contains: "contains information",
                notContains: "Does not contain information",
                equalsFix: "equal to fixed value",
                notEqualsFix: "Not equal to a fixed value",
                containsFix: "Contains fixed value",
                notContainsFix: "Does not contain a fixed value",
            },
            basicInfoOperators: {
                equals: "equal",
                notEquals: "not equal to",
                contains: "contains",
                notContains: "does not contain",
                isNull: "is empty",
                isNotNull: "Not empty",
            },
            volumeDetectionWays: {
                loudness: "loudness in decibels",
                rangeAbilitySelf: "Range of change (compared to my previous sentence)",
                rangeAbilityOth:
          "Range of change (compared to the opponent's previous sentence)",
                rangeAbility3: "Range of change (than my own sentence)",
            },
            volumeDetectionOperators: {
                is: "equal to",
                not: "not equal to",
                greaterThan: "Greater than",
                greaterThanEq: "Greater than or equal",
                lessThan: "less than",
                lessThanEq: "Less than or equal to",
            },
            sentimentTypes: {
                positive: "positive emotions",
                negative: "Negative emotions",
                neutral: "neutral emotion",
                thankful: "thanks",
                happy: "happy",
                complaining: "complaining",
                angry: "angry",
                post: "front",
                negat: "negative",
                neut: "neutral",
            },
            emotionOperators: { is: "match", not: "does not meet" },
            propTypes: { system: "System", user: "Custom" },
            valueTypes: { text: "text", number: "number" },
        },
        tasks: {
            theDayBeforeYesterday: "The day before yesterday",
            assessActionTypes: { reassess: "Reassess" },
            scoreType: {
                aiScore: "Smart Evaluation",
                manualScore: "Manual evaluation",
                interactiveDetection: "Interactive Detection",
                smartRules: "Smart Rules",
                machineLearning: "Self-learning mode",
            },
            inspectType: { inspectTrue: "Inspect to", inspectFalse: "Not inspected" },
            operatorType: {
                keyword: "keyword matching",
                notKeyword: "Keywords do not match",
            },
            applyRole: { all: "all", customer: "Customer", agent: "Agent" },
            applyScope: {
                all: "all",
                specific: "specified sentence",
                scope: "specified scope",
            },
            judgeStrategy: {
                all: "Meet all conditions",
                arbitrarily: "Satisfy any conditions",
                custom: "Custom logic",
            },
            checkPointStatus: {
                waitFor: "Waiting for review",
                notDeductScore: "Do not deduct points",
                deductScore: "deduct points",
            },
            applyOptions: {
                hit: "conditional hit",
                notHit: "No specified conditions",
            },
            gradeTypes: {
                yesOrNo: "Does it meet?",
                input: "Manual input",
                level: "Classification evaluation",
                multipleFactors: "Multi-Factors",
            },
            gradeChooseHits: { yes: "Yes", no: "No" },
            gradeChangeTypes: {
                edit: "Edit",
                check: "Recheck",
                file: "Archive",
                remark: "Remarks",
                complain: "Appeal",
                review: "review",
                reviewEvaluation: "Review Evaluation",
                sample: "Sampling inspection",
                sampleEvaluation: "Sampling evaluation",
                sampleSubmit: "Sampling submission",
                sampleAssign: "Sampling Assignment",
                reviewAssign: "Review Assignment",
                read: "Read",
                reviewConfirm: "Review Confirm",
                sampleConfirm: "Sampling Confirmation",
            },
            gradeStatus: {
                unread: "Unread",
                alreadyReview: "Already reviewed",
                alreadyFild: "Archived",
                waitReview: "To be reviewed",
                alreadyRead: "Already read",
            },
            samplingFrequencyTypes: {
                everyDay: "daily",
                everyWeek: "Weekly",
                everyMonth: "Monthly",
            },
            samplingEveryDayTimeTypes: { yesterday: "Yesterday", today: "today" },
            samplingEveryWeekTimeTypes: {
                thisWeek: "this week",
                lastWeek: "last week",
            },
            samplingEveryMonthTimeTypes: {
                thisMonth: "this month",
                lastMonth: "Last Month",
            },
            samplingTypes: {
                random: "random sampling",
                proportional: "proportional sampling",
            },
            weekdays: {
                Monday: "Monday",
                Tuesday: "Tuesday",
                Wednesday: "Wednesday",
                Thursday: "Thursday",
                Friday: "Friday",
                Saturday: "Saturday",
                Sunday: "Sunday",
            },
            distributeTypes: {
                rule: "Rule priority allocation",
                average: "Average random allocation",
            },
            notMatchAnyRuleTypes: {
                random: "Random average distribution",
                assign: "Assign to designated personnel",
                ignore: "Do not allocate",
            },
            inspectorTypes: {
                all: "All quality inspectors",
                some: "Designated Quality Inspector",
            },
            pushFrequencyTypes: {
                everyDay: "daily",
                everyWeek: "Weekly",
                everyMonth: "Monthly",
                inspectionUpdate: "Quality Inspection Update",
                timelyInspection: "Real-time quality inspection",
            },
            pushObjectTypes: { email: "Specify Email", staff: "Belonging to agent" },
            reviewOptions: {
                reviewResult: "Display quality inspection result during review",
                reviewRecord: "Display related records during review",
                reviewAgentHidden: "Hide agent information during review",
                samplingResult: "Display quality inspection results during sampling",
                samplingRecord: "Display relevant records during sampling",
                sampleAgentHidden: "Hide agent information during sampling",
                checkExportAddCallInfoIphone:
          "Add call records when exporting the list",
                sampleExportAddCallInfoIphone:
          "Add call records when exporting the list",
                checkExportAddCallInfoText: "Add dialog record when exporting list",
                sampleExportAddCallInfoText: "Add dialog record when exporting list",
                inspectionExportAddCallInfoIphone:
          "Add call records when exporting the list (up to 10000 entries can be exported each time)",
                inspectionExportAddCallInfoText:
          "Add dialog records when exporting the list (up to 10000 entries can be exported each time)",
            },
            fastSampleInDetailList: {
                fastSampleInInspectDetail:
          "Allow fast execution of random inspection in QC details",
                fastSampleInSampleDetail:
          "Allow quick execution of sampling inspection in details of sampling inspection",
                fastSampleInReviewDetail:
          "Allow to quickly perform random inspection in review details",
            },
            permitCustomReasonList: {
                permitCustomReason: "Allow custom statement reason",
            },
            autoMateTasks: {
                autoLoad: "Automatically add",
                sample: "Automatic sampling",
                assign: "Automatically assign",
                pushes: "Auto push",
                assess: "Automatic assessment",
                check: "Appeal review",
                case: "Case library synchronization",
            },
            qualityCheckDetailTypes: { review: "review", spotCheck: "spot check" },
            appealTypes: {
                transliterationError: "Transliteration Error",
                discriminationError: "discrimination error",
                otherError: "Other error",
            },
            inspectDataSources: {
                voiceCall: "Voice call",
                textDialogue: "Text Dialogue",
                realTimeVoiceCall: "Real Time Voice Call",
                realTimeTextDialogue: "Real-time text dialogue",
            },
            pointTypes: {
                automatic: "automatic evaluation",
                manual: "Manual evaluation",
                interactiveDetection: "Interactive Detection",
            },
            pointGradeTypes: {
                radio: "Single selection",
                input: "input",
                select: "Select",
            },
            sampleAndReviewStatus: {
                init: "not extracted",
                unread: "Unread",
                alreadyRead: "Already read",
                complaining: "Appealing",
                reviewed: "reviewed",
                sampling: "Sampling unchecked",
                samplingCompleted: "sampled",
            },
            wayOverUpperLimits: {
                ignoreExcessSpillover: "Ignore excess overflow",
                automaticWeightCalculation: "Automatic weight calculation",
            },
            calculateScoreTypes: { add: "add points", sub: "minus points" },
            naChecked: { check: "Allow this item not to be involved in detection" },
            pointNaChecked: { check: "N/A" },
            interactiveRules: {
                proportional: "Give points in proportion",
                allRight: "All correct points",
                anyRight: "Arbitrarily correct points",
            },
            noHitQuestionResults: {
                true: 'The result is "Yes"',
                false: 'The result is "No"',
            },
            repetRules: {
                same: "The content is exactly the same",
                similar: "Semantic similar",
            },
            relateConditions: {
                highlightQuestion: "Highlight Question",
                highlightAnswer: "Highlight Answer",
                hightlightFoctors: "Hightlight Elements",
            },
            interactiveQuestionTypes: {
                question: "Problem detected",
                eliminateQuestion: "Exclude problem detected",
            },
            interactiveAnswerTypes: {
                noAnswer: "No answer detected",
                answer: "Answer detected",
                wrongAnswer: "Wrong answer detected",
            },
            inspectionUpdateTypes: {
                autoInspection: "Automatic quality inspection",
                autoInspectionWechat: "Automatic analysis",
                artificialSampling: "Manual sampling",
                artificialReview: "Manual review",
                manualModification: "Quick spot check",
                timelyInspection: "Real-time quality inspection",
                samplingAssign: "Sampling Assignment",
                artificialComplain: "Appeal submission",
                fastSampling: "Quick sampling submission",
                recheckInspection: "Re-inspection",
                recheckInspectionWechat: "Re-analyze",
            },
            timelyInspectionTypes: {
                timelyInspection: "Real-time quality inspection",
            },
            pushActionTypes: { email: "Mail", interfacePush: "interface push" },
            pushDetailWays: {
                riskAlert: "risk alert",
                subscribe: "Subscribe message",
            },
            riskAlertPushDataTypes: {
                dialogueMessage: "Call/Dialog Message",
                inspectionResults: "Quality Inspection Results",
            },
            subscribePushDataTypes: {
                dialogueMessage: "Call/Dialog Message",
                textContent: "call/conversation text content",
                inspectionResults: "Quality Inspection Results",
            },
            pushRequestMethods: { post: "POST" },
            pushContentTypes: { json: "JSON" },
            notMatchPreRule: {
                average: "Average of scores in the group",
                addOrSubtract: "Add and subtract points directly",
                noChange: "No points added or subtracted",
            },
            defaultScore: {
                empty: "is empty",
                add: "add points",
                notAdd: "No points added",
                sub: "minus points",
                notSub: "No points deduction",
                na: "N/A",
                input: "Manual input",
                choose: "Choose",
            },
            strategyTypes: {
                hitAdd: "hit plus points",
                hitNotAdd: "Hit does not add points",
                hitSub: "Hit points minus",
                hitNotSub: "Hit does not deduct points",
            },
        },
        download: {
            exportStatus: {
                prepare: "Preparing",
                process: "in progress",
                faild: "export failed",
                success: "success",
            },
        },
        messageType: {
            all: "all",
            monitor: "Real-time monitoring",
            trigger: "Trigger notification",
            timing: "timing report",
        },
        messagePushType: {
            all: "all",
            system: "System Message",
            sdk: "Message SDK",
            push: "interface push",
            email: "Mail",
            udesk: "udesk notification",
            udeskWhite: "Agent system notification",
            weChat: "Enterprise WeChat Message",
        },
        autoAddPushTarget: {
            sampleAssignInspector: "Assign Inspector",
            sampleInspector: "Sampling inspector",
            assignReviewer: "Assign reviewer",
            reviewer: "Reviewer",
            taskInspector: "Task Quality Inspector",
        },
        sdkPushType: {
            webHook: "standard webhook",
            dingDing: "DingDing Robot",
            weChat: "Enterprise WeChat Robot",
            customer: "Custom Json content",
        },
        inspectionRemarksType: {
            inspection: "Quality Inspection",
            check: "Sampling check",
            review: "review",
        },
        segmentationStrategyType: {
            count: "Number of messages",
            spacing: "message interval",
        },
        segmentationStrategy: {
            byTime: "Timed segmentation",
            customize: "combination segmentation",
        },
        inspectType: {
            commonInspect: "General quality inspection",
            associateInspect: "Associate Quality Inspection",
        },
        typoDetectionOperators: { less: "less than", greater: "greater than" },
        autoAddTypes: { realTimeAdd: "Real Time Add", timingAdd: "timing add" },
        messageTypes: { text: "text message", markdown: "Markdown message" },
        inServiceStatus: {
            activated: "activated",
            disabled: "Disabled",
            inactivated: "Not activated",
            resigned: "Exit the enterprise",
        },
        roles: { agent: "employee", customer: "customer" },
        commentVisibleAuthority: {
            all: "Visible to everyone",
            self: "Visible only to myself",
            designation: "Visible to specified people",
        },
        appealCheckingTask: {
            enterpriseWeChatText: "Enterprise WeChat Text",
            generalQualityInspection: "General quality inspection",
            processQualityInspection: "Process quality inspection",
            voiceCopy: "Voice copy",
            beEqualTo: "equal",
            notEqualTo: "not equal",
            canAppeal: "appealable",
            noAppeal: "not appealable",
        },
        wechat: {
            redEnvelopes: "red envelopes",
            text: "text",
            picture: "picture",
            voice: "Voice",
            voiceCall: "Voice call",
            video: "video",
            businessCard: "Business Card",
            position: "position",
            expression: "expression",
            link: "Link",
            applet: "Applet",
            chatRecord: "Chat record",
            mixedMessage: "Mixed message",
            file: "file",
        },
        gong: {
            sessionActivity: "Session activity",
            conversationAction: "Conversation action",
            riskSemantics: "Risk semantics",
            daysNotFollowedUp: "Days not followed up",
            waysideData: "Wayside data",
            notStarted: "Not Started",
            completed: "Completed",
            haveInHand: "have in hand",
            unpublished: "Unpublished",
            published: "Published",
            knowledgePoints: "Knowledge points",
            technologicalProcess: "technological process",
            notMarked: "Not marked",
            inAnnotation: "In annotation",
            annotated: "Annotated",
        },
        qualityChecingTask: {
            incomingCall: "Incoming call",
            exhale: "exhale",
            incomingCallthirdParty: "Incoming call (third party)",
            incomingCallconsultation: "Incoming call (consultation)",
            incomingCallforcedInsertion: "Incoming call (forced insertion)",
            incomingCallmonitoring: "Incoming call (monitoring)",
            incomingCalltransfer: "Incoming call (transfer)",
            incomingCallinterception: "Incoming call (interception)",
            incomingCalltransferToExternalLine:
        "Incoming call (transfer to external line)",
            incomingCallthirdPartyExternalLine:
        "Incoming call (third party external line)",
            incomingCallexternalLine: "Incoming call (external line)",
            outgoingCallthirdParty: "Outgoing call (third party)",
            outgoingCalltransfer: "Outgoing call (transfer)",
            outgoingCallforcedInsertion: "Outgoing call (forced insertion)",
            outgoingCallmonitoring: "Outgoing call (monitoring)",
            outgoingCallinterception: "Outgoing call (interception)",
            outgoingCalltransferToExternalLine:
        "Outgoing call (transfer to external line)",
            outgoingCallthreePartyExternalLine:
        "Outgoing call (three party external line)",
            customerServiceAnswer: "Agent answer",
            customerServiceMissed: "Agent missed",
            customerAnswer: "Customer answer",
            customerMissed: "Customer missed",
            theTelephoneIsBusy: "The telephone is busy",
            phoneOffline: "Phone offline",
            customerQuickHanging: "Customer quick hanging",
            clientHangsUp: "Client hangs up",
            queueTimeout: "Queue timeout",
            giveUpQueuing: "Give up queuing",
            outsideLineAnswering: "Outside line answering",
            externalLineIsNotConnected: "External line is not connected",
            noQueueSelected: "No queue selected",
            notEvaluated: "Not evaluated",
            noEvaluationRequired: "No evaluation required",
            queuingSucceeded: "Queuing succeeded",
            noCustomerServiceOnline: "No agent online",
            customer: "customer",
            customerService: "agent",
            outsideLines: "outside lines",
        },
        sessionActivity: {
            single: "Average talk time per call",
            all: "The total usage",
            allTime: "Total call duration",
        },
        sessionAction: {
            salesProportion: "Sales account for the proportion",
            salesLongest: "Average longest sales statement",
            customerLongest: "Average longest customer speech",
            discussNumber: "Number of in-depth discussions",
            frequency: "Questions frequency",
        },
        monitorCriteria: {
            all: "Before and after",
            before: "Before",
            after: "After",
        },
        monitorTimeCycle: { day: "Day", week: "Week", month: "month" },
        monitorGroupTestType: {
            ab: "Two groups of contrast",
            a: "Group A",
            b: "Group B",
        },
        fields: {
            anyMatch: "Any match",
            keyWord: "key word",
            keyEvents: "Key events",
        },
        semanticIntelligence: {
            staff: "staff",
            customer: "customer",
            employeesAndCustomers: "Employees and customers",
            notStarted: "Not Started",
            haveInHand: "have in hand",
            completed: "Completed",
            callRecording: "Call recording",
            enterpriseWeChatCall: "Enterprise WeChat Call",
            enterpriseWeChatText: "Enterprise WeChat Text",
            discourseMiningForObjectionHandling:
        "Discourse mining for objection handling",
            productSellingPointScriptsMining: "Product selling point scripts mining",
            conversationScenario: "Conversation scenario",
            topicOfConversation: "topic of conversation",
            keyEvents: "Key events",
            customerLabel: "Customer Label",
            includeAny: "Include any",
            includeAll: "Include All",
            and: "And",
            or: "or",
            question: "question",
            nonInterrogativeSentence: "Non interrogative sentence",
        },
        coach: {
            allData: "All data",
            dataFromThisDepartment: "Data from this department",
            multidepartmentalData: "Multidepartmental data",
            underReview: "Under review",
            no: "no",
            yes: "yes",
            automaticRating: "Automatic rating",
            auditRating: "Audit rating",
            reRating: "Re rating",
            inAppeal: "In appeal",
            reviewed: "Reviewed",
            notViewed: "Not viewed",
            viewed: "Viewed",
            uncorrected: "Uncorrected",
            corrected: "Corrected",
            practice: "practice",
            examination: "examination",
            other: "other",
            notMeetingStandards: "Not meeting standards",
            meetingStandards: "Meeting standards",
            excellent: "excellent",
            invalid: "invalid",
            notStarted: "Not Started",
            haveInHand: "have in hand",
            ended: "Ended",
            completed: "Completed",
            hangInTheAir: "hang in the air",
            passed: "Passed",
            voiceConversation: "Voice conversation",
            textDialogue: "Text dialogue",
            slidePresentation: "slide presentation ",
            unpublished: "Unpublished",
            published: "Published",
            narration: "Narration",
            studentSpeaking: "Student speaking",
            robotTalk: "Robot Talk",
            knowledgeBaseQA: "Knowledge Base Q&A",
            slide: "slide",
            negativeEmotions: "Negative emotions",
            emotionallyNormal: "Emotionally normal",
            incompleteScript: "Incomplete script",
            completeScript: "Complete script",
            normalSpeechSpeed: "Normal speech speed",
            speakTooFast: "Speak too fast",
            speakTooSlowly: "Speak too slowly",
            whole: "whole",
            singlePage: "Single page",
            qA: "Q&A",
            situationalDialogue: "Situational dialogue",
            misses: "Misses",
            hit: "Hit",
            sequentialQA: "Sequential Q&A",
            randomQA: "Random Q&A",
            custom: "custom",
            mastered: "Mastered",
            notMastered: "Not mastered",
        },
        algorithm: {
            waiting: "Waiting",
            extracting: "Extracting",
            success: "success",
            fail: "fail",
            intelligentQualityInspection: "Intelligent quality inspection",
            textDialogue: "Text dialogue",
            voiceCall: "voice call ",
            smartTags: "Smart tags",
            satisfactionEvaluation: "Satisfaction evaluation",
            score: "score",
            grade: "grade",
            customerServiceTeam: "Customer service team",
            salesEmpowerment: "Sales empowerment",
            conversationAnalysis: "Conversation analysis",
        },
        operatorListGather: {
            allTime: "All time",
            nonBusinessWorkingHours: "Non business working hours",
            businessWorkingHours: "Business working hours",
        },
    },
    pages: {
        auth: {
            welcome: "",
            copyRight: "",
            login: {
                name: "Login",
                email: "Mailbox",
                password: "Password",
                forgetPwd: "Forget Password",
                emailCannotBeEmpty: "The mailbox cannot be empty. ",
                passwordCannotByEmpty: "The password cannot be empty. ",
                mustChangeLogin:
          "Sorry, your password must be reset, please contact the administrator to reset the password. ",
                dismissionLogin: "Sorry, you have resigned. ",
                forbiddenLogin: "Sorry, your account is disabled. ",
                loginError: "Login failed, email or password is wrong. ",
                loginPrompt: "Log in to the system",
                rememberMe: "Remember me",
            },
            logout: {
                logoutError:
          "Logout failed, please check the network connection or try again later. ",
            },
            resetPassword: {
                forgetPwd: "Forget Password",
                inputEmailPrompt:
          "Enter your account email below, we will send you an email and instructions for resetting your password. ",
                sendEmailSuccess:
          "The password confirmation letter has been successfully sent to the mailbox you filled in, please check it in time. ",
                sendEmailFailed:
          "The email sending failed, please check the network connection or try again later. ",
                setNewPassword: "Set your new password",
                passwordCannotEmpty: "Password cannot be empty",
                passwordFormWrong: "Password format is incorrect",
                confirmPasswordWrong: "The passwords entered twice are not equal. ",
                passwordResetSuccess: "Password reset successfully. ",
                passwordResetFailed:
          "Password reset failed, please check the network connection or try again later. ",
                linkTimeover: "Reset password link expired, please try again",
            },
            form: { title: "CASE form display" },
        },
        public: {
            auth: {
                forgotPassword: {
                    email: "Mailbox",
                    emailFormatError: "The email format is wrong, please re-enter! ",
                    forgotPassword: "Forgot your password? ",
                    describe:
            "Enter your account email below, and we will send you an email and instructions for resetting your password. ",
                    send: "send",
                    emailCannotBeEmpty: "Email cannot be empty",
                    resetPasswordExpire: "Reset password link expires, please try again",
                },
                resetPassword: {
                    setPassword: "Set your new password",
                    newPassword: "Create Password",
                    repeatPassword: "repeat password",
                    resetPassword: "Reset Password",
                    passwordCannotBeEmpty: "The new password cannot be empty. ",
                    confirmPasswordCannotBeEmpty:
            "The repeated password cannot be empty. ",
                    passwordsNotMatch: "The two passwords are inconsistent! ",
                    passwordFormWrong:
            "The password cannot contain Chinese characters, and the number is 6-14! ",
                    linkExpiration: "Reset password link expires, please try again",
                },
            },
            errors: {
                haveNoPermissionWarningText:
          "You do not have permission to view this page",
            },
        },
        downloadCenter: {
            name: "Download Center",
            saveTime: "(Save for 30 days by default)",
            headerTitle: "download file",
            download: "download",
            downloadPromp: "The download task has been submitted, please go to",
            viewProgress: "View progress",
        },
        uploadCenter: { name: "Upload Center" },
        tasks: {
            index: {
                name: "name",
                remark: "description",
                qualityCheckTasksTitle: "Workbench",
                informationCollection: "Collection task",
                qualityCheckTasks: "Quality Check Tasks",
                wechatQaTasks: "Analysis tasks",
                moduleName: "Create new using template",
                creatNewQualityCheckTask: "Create Task",
                generalInspectionList: "General Quality Inspection Report",
                editQualityCheckTask: "Edit Quality Check Task",
                creatNewQualityCheckTaskError: "Create quality inspection task failed",
                editQualityCheckTaskError: "Edit quality check task failed",
                type: "Form Type",
                singleScore: "single score",
                singleScoreNullError: "Single score cannot be empty",
                inspector: "Quality Inspector",
                inspectDataSource: "Data Source",
                taskName: "task name",
                inspectionType: "Quality Inspection Type",
            },
            manage: {
                screeningCondition: "Screening Condition",
                template: {
                    condition: {
                        index: {
                            name: "condition",
                            existingConditions: "Existing Conditions",
                        },
                        detail: {
                            id: "ID",
                            name: "condition name",
                            remark: "description",
                            checkRange: "Detection Range",
                            operator: "Operator",
                            operatorLogic: "Operator logic",
                        },
                    },
                    checkPointTest: {
                        title: "Rule Test",
                        running: "Rule testing, please wait...",
                        clearSuccess: "Simulation dialogue cleared successfully",
                        mock: "mock dialogue",
                        save: "Save selected dialog",
                        saveAll: "Save all conversations",
                        saveSuccess: "Dialog saved successfully",
                        close: "Exit",
                        delete: "Delete selected",
                        clear: "clear",
                        customer: "Customer",
                        agent: "Agent",
                        test: "Test",
                        request:
              "The simulation dialog cannot be restored after being cleared. Are you sure to clear it? ",
                        deletePopconfirmContent:
              "Are you sure to delete the selected conversation? ",
                    },
                    checkPoint: {
                        index: {
                            name: "Rule Configuration",
                            wechatTitle: "Analysis model configuration",
                            existingCheckPoints: "Existing quality inspection rules",
                            currentCheckPointScore:
                "The current quality inspection rule score is ",
                            currentCheckPointScoreWechat:
                "The current analysis model score value",
                            currentCheckPointWeight:
                "The sum of the weights of the current quality inspection rules is ",
                            weightTotalScoreAndItemScore:
                "Total score: 100 points Single item score:",
                            template: "Quality inspection rule template",
                            import: "Import quality inspection rule template",
                            getTemplateError:
                "Failed to obtain the quality inspection rule template",
                            tabs: { template: "template", demo: "sample" },
                            customize: "blank template",
                            move: "Drag and drop to adjust classification and sorting",
                            classification: "Quality inspection classification",
                            classificationWeChat: "Model classification",
                            first: "First level classification",
                            second: "Secondary classification",
                            new: "Create quality inspection rule",
                            weChatNew: "Add analysis model",
                            createWeChat: "Create analysis model",
                            empty: "No quality inspection rules yet, ",
                            wechatEmpty: "No analysis model yet,",
                            newSecond: "Create secondary category",
                            newFirst: "Create first level category",
                            checkPoint: "Quality Inspection Rules",
                            checkPointWeChat: "Analysis Model",
                        },
                        detail: {
                            name: "name",
                            remark: "description",
                            type: "type",
                            deduction: "deduction",
                            rule: "rule",
                            highlighted: "Highlight",
                            gradeType: "Score method",
                            interactiveCategory: "Interactive category",
                            predeterminedScore: "Score",
                            weight: "weight",
                            na: "N/A",
                            default: "default score",
                            preRule: "pre-condition rule",
                            analysisHighlighted: "Analysis Rules",
                            categorySelectPrompt: "Please select a category",
                            checkHighlightArrayError:
                "The rule does not match the highlight, please click the parse rule button again",
                            foctorsHolder: "Please select elements",
                            foctorsErrorNone:
                "Multi-element rule is empty, please add multi-element type",
                            naError:
                "This item cannot be closed when the default score is N/A",
                            templateNew: {
                                ruleType: "Rule Type",
                                dataSource: "data source",
                                whole: "whole",
                                enterpriseMicroConversation: "Enterprise micro conversation",
                                enterpriseAndMicroCommunication:
                  "Enterprise and Micro Communication",
                            },
                            components: {
                                flow: {
                                    flow: {
                                        determineNodes: "Determine nodes",
                                        dialogueNode: "Dialogue node",
                                        editNodes: "Edit nodes",
                                    },
                                    nodeConfig: {
                                        nodeName: "Node Name",
                                        nodeRules: "Node Rules",
                                        scoringLogic: "Scoring logic",
                                        score: "Score:",
                                        rule: "rule",
                                        multiBranchLogic: "Multi branch logic",
                                        highlight: "Highlight ",
                                        submit: "Submit",
                                    },
                                    ruleList: {
                                        branch: "branch",
                                        branchName: "Branch Name:",
                                        addRules: "Add rules",
                                    },
                                    sidebar: { node: "node" },
                                    hooks: {
                                        useControlButtons: {
                                            automaticOrganization: "Automatic organization",
                                            canvasAdaptation: "Canvas Adaptation",
                                            copy: "copy",
                                            delete: "delete",
                                        },
                                        useFlow: {
                                            determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Determine that the child nodes of a node cannot be used as connection targets",
                                            theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "The starting node cannot be used as a connection target",
                                            startingNodeCannotConnectMultipleNodes:
                        "Starting node cannot connect multiple nodes",
                                            nodeCannotBeConnectedToItself:
                        "Node cannot be connected to itself",
                                            startNode: "Start Node",
                                        },
                                    },
                                },
                            },
                        },
                        configuration: {
                            title: "Quality Inspection Rules",
                            wechatTitle: "Analysis Model",
                            neme: "name",
                            errorMessage:
                "Deletion failed, there must be at least one condition",
                            desc: "Description",
                            status: "Status",
                            yes: "valid",
                            no: "invalid",
                            namePlaceHolder: "Please enter the label name",
                            desPlaceHolder: "Please enter a description",
                            nameEmpty: "Name cannot be empty",
                            desEmpty: "Description cannot be empty",
                            type: "type",
                            ruler: "smart rule",
                            machineLearning: "Self-learning mode",
                            autoScore: "Smart Score (Old)",
                            manualScore: "manual score",
                            interaction: "Interaction detection (old)",
                            typeTips: {
                                model:
                  'Smart model: It is a set of preset quality inspection system, without configuration and maintenance, and can quickly check out the problems in the service. This model is more general, if you do not meet the business requirements, please use "smart rules" or "manual scoring',
                                rule: "Smart rule: automatically score based on rule AI",
                                manual:
                  "Manual scoring: manual quality inspection, manual scoring by manual",
                            },
                            model: "Model:",
                            ruleType: {
                                words: "Words",
                                interaction: "Interaction",
                                feature: "feature",
                                information: "Information",
                            },
                            testingMode: {
                                all: "Detect all keywords",
                                any: "Detect any keyword",
                            },
                            operatorTypesOfInteraction: {
                                question: "One question and one answer",
                                element: "Element answer",
                                repet: "Context repeat",
                                snatch: "Snatch the conversation",
                                silent: "Silent",
                            },
                            role: "Detection role:",
                            preposition: "pre-operator:",
                            range: "Detection range:",
                            from: "from",
                            to: "to",
                            end: "Sentence",
                            hasOldSilentTip:
                'Hello, in order to improve the accuracy rate, "Smart Rules-Interaction-Silence" has been upgraded to "Response Timeout", it is recommended to update the rules! ',
                            changeToOld: "Switch to old version",
                            changeToNew: "Switch to the new version",
                            logic: {
                                logic: "Score logic",
                                naTip:
                  "N/A means Not Applicable, set preconditions for this quality inspection rule, check when the conditions are met, if the conditions are not met, the rule is not applicable, then the rule is not checked",
                                score: "score:",
                                na: "N/A:",
                                naDes: "Allow this item not involved in detection",
                                preRule: "Precondition rule:",
                                notCatchPreRule: "When the precondition is not met:",
                                naChecked: "When N/A is hit:",
                                preRuleEmpty: "Precondition rule cannot be empty",
                                operatorEmpty: "Required condition in operator cannot be empty",
                                multipleEmpty:
                  "Required conditions in multiple logical scoring cannot be empty",
                                average: "Average of scores in the group",
                                addOrSubtract: "Add and subtract points directly",
                                noChange: "No points added or subtracted",
                                rule: "Rule:",
                                all: "Satisfy all operators",
                                any: "Satisfy any operator",
                                customize: "Custom operator logic",
                                multiple: "Multiple Logical Score",
                                heightLight: "Highlight:",
                                machineLearningTip:
                  "The self-learning mode is currently only effective for single-sentence techniques, please use smart rules for complex words",
                                strategy: "strategy:",
                            },
                        },
                        component: {
                            approvalSubmittedSuccessfully: "Approval submitted successfully",
                        },
                        components: {
                            pageHeaderExtra: {
                                test: "test",
                                formal: "formal",
                                submit: "Submit",
                            },
                        },
                    },
                    automaticAdd: {
                        index: {
                            name: "Process Automation",
                            screeningCondition: "Screening Condition",
                            aiScreeningCondition: "Smart Screening Condition",
                            rule: "rule",
                            automaticAddition: "Automatically add",
                            automaticAdditionTip:
                "Automatically add data to the task for AI quality inspection in real time or periodically",
                            autoAdd: {
                                autoAddType: "Automatically add method",
                                addFrequency: "Add frequency",
                                add: "add",
                            },
                            automaticSamplingInspection: "Automatic Sampling Inspection",
                            automaticSamplingInspectionTip:
                "Automatically extract data that requires manual quality inspection, for example, randomly extract 10% of yesterday's data for manual sampling every day",
                            automaticAllocationTip:
                "Sampling inspection allocation: The extracted data is automatically distributed to the quality inspectors. For example, 10% of the data from yesterday is randomly selected for manual sampling every day, and the average is randomly assigned to 2 quality inspectors for this task",
                            automaticAllocationTip2:
                "Review allocation: The data of the appeal is automatically allocated to the quality inspector for review. For example, the data of the daily appeal is randomly assigned to the 2 quality inspectors of this task for review",
                            automaticPushTip:
                "Trigger notification: notification/alarm during quality inspection, for example, when a fatal error occurs in the agent, a notification message is sent to the enterprise and micro group",
                            automaticPushTip2:
                "Scheduled report: Send the quality inspection result by email as a regular report, for example, send the quality inspection result of yesterday's manual sampling inspection to the mailbox of the agent supervisor at 9:00 every day",
                            automaticAssessTip:
                "Regularly compare the results of manual sampling inspections with the results of AI quality inspections to evaluate the accuracy. For example, at 8:00 every day, the data from yesterday's random inspection will be evaluated for accuracy",
                            spoktReviewDetailTip:
                "Configure the quality inspection list, sampling inspection list, appeal business, review list, remarks and other functions",
                            everyAgentCheckLimit: "Limit per agent draw",
                            automaticAllocation: "Automatic allocation",
                            automaticPush: "Automatic push",
                            automaticAssess: "Automatic assessment",
                            reexaminationComplaint: "Appeal review",
                            spoktReviewDetail: "Sampling review details configuration",
                            qaDetailAction: "Quality inspection configuration",
                            spoktDetailAction: "Sampling configuration",
                            reviewDetailAction: "Review Configuration",
                            appealAction: "Appeal configuration",
                            remarkTemplate: "Remark Template",
                            automaticArchiving: "Automatic Archiving",
                            conditionalRules: "Conditional Rules",
                            qualityInspectionConditions: "Quality Inspection Conditions",
                            unopened: "Not yet opened",
                            sampleInspectionDistributionModel: "Sampling distribution",
                            reviewAllocationModel: "Review Allocation",
                            distributionModelTitle: "Distribution strategy:",
                            push: "Push",
                            pushRules: "Push Rules",
                            assessRules: "assessment rules",
                            on: "on",
                            off: "off",
                            updateStatusSuccess: "Status changed successfully! ",
                            updateStatusFailed:
                "Status change failed, please try again later! ",
                            sampleNumberOrPercent: "sample number or proportion",
                            inspectorList: "Designated Quality Inspector",
                            numberOfSubmission: "Number of submissions allowed",
                            rewivewDays: "days",
                            TimeOfSubmission: "Valid period of submission of complaints",
                            rewivewSecond: "Second",
                            switchOn: "Turn on",
                            switchOff: "Close",
                            notMatchAnyRule: "When any rule is not matched:",
                            appealOptionsList: {
                                hiddenInspector: "Appeal to hide quality inspector information",
                                hiddenHit:
                  "The appeal page supports highlight positioning hits",
                            },
                            caseLibrary: "Case library synchronization",
                            caseLibrarySyncTip:
                "Synchronize the cases added by the QA system to the agent system",
                            addCase: "Add synchronization",
                            caseLibraryId: "case library",
                            caseLibraryIdTip: "The case library grouping of the QA system",
                            udeskCaseLibraryIdTip: "Case grouping of agent system",
                            syncHis: "Sync history case",
                            otherSettings: "Other Settings",
                            udeskCaseLibraryId: "Push case group",
                            url: "Push URL",
                            urlTip:
                "Please enter the interface address starting with http:// or https://",
                        },
                        templateNew: {
                            onlyTheFirstLevelReviewerAllocationStrategy:
                "Only the first level reviewer allocation strategy",
                            secondaryCompounding: "Secondary compounding:",
                            secondLevelReviewersOnlySupportAverageDistribution:
                "Second level reviewers only support average distribution",
                            secondLevelReviewer: "Second level reviewer:",
                            samplingInspectionTarget: "Sampling inspection target",
                            setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Set the target to be completed by manual sampling inspection. When the target is not reached or exceeded, a prompt will be given. For example, every agent should complete 100 sampling inspections every month. When the number is less than 80, a prompt will be given on the quality inspection and sampling inspection page.",
                        },
                        component: {
                            pleaseAddASecondLevelQualityInspector:
                "Please add a second level quality inspector",
                        },
                    },
                    roleConfig: {
                        index: {
                            roleConfigTip:
                " is used to configure recognition rules for speaker separation processing when the imported call data is mono. Those who meet the rules are agent roles",
                            name: "role configuration",
                            operator: "Operator",
                            operatorLogic: "Operator logic",
                        },
                    },
                    manualAdd: {
                        index: {
                            name: "Manually add",
                            tip: "Used to filter preview data by manually configuring conditions and quickly add it to the quality inspection task",
                            screeningCondition: "Add according to screening conditions",
                            aiScreeningCondition: "Smart Screening Condition",
                            rule: "rule",
                            createTime: "Add time",
                            filterTitle: "Filter content",
                            filterRule: "Filter Condition",
                            startTime: "start time",
                            endTime: "End Time",
                            records: "import records",
                            createUserName: "Operator",
                            successCount: "add success",
                            conditionRule: "Condition Rule:",
                            preview: "Preview",
                            previewData:
                "{0} items currently meet the filtering conditions, {1} items have been added to the task, {2} items can be added to the task, {3} items cannot be added to the task",
                            failCount: "Add failed",
                        },
                    },
                    basicInfo: {
                        index: {
                            title: "Basic Information",
                            name: "name",
                            taskName: "task name",
                            taskNamePlaceholder: "Please enter the name of the task",
                            requiredName: "Task name cannot be empty",
                            taskRemarkPlaceholder: "Please enter a task description",
                            requiredRemark: "Task description cannot be empty",
                            remark: "description",
                            taskRemark: "Task description",
                            source: "data source",
                            benchmarkScore: "benchmark score",
                            benchmarkScorePlaceholder: "Please enter benchmark score",
                            scoreLowerLimit: "Minimum score lower limit",
                            scoreUpperLimit: "Maximum score limit",
                            inspectType: "Quality inspection type",
                            nlpModel: "NLP Model",
                            commonInspect: "General quality inspection",
                            associateInspect: "Associate Quality Inspection",
                            associateCycle: "association cycle",
                            associateRegulation: "Association Rules",
                            judgeStrategy: "execution logic",
                            rule: "rule",
                            commonTip:
                "Regular quality inspection: suitable for single communication, dialogue, quality inspection of the content of a single communication",
                            associateTip:
                "Associated quality inspection: suitable for multiple communications and dialogues, associating the content of multiple communications for quality inspection",
                            cycleTip:
                "If there are multiple communication and dialogues associated, the data in the associated cycle will enter the task quality inspection",
                            scoreLowerLimitPlaceholder:
                "Please enter the lowest score lower limit",
                            scoreUpperLimitPlaceholder:
                "Please enter the highest score limit",
                            call: "Voice call",
                            dialogue: "text dialogue",
                            user: "Quality Inspector",
                            wechatUser: "Operator",
                            noDesignated: "not specified",
                            type: "Form Type",
                            singleScore: "single score",
                            conduct:
                "Please complete the following information first, and click Next after completion",
                            conduct2:
                "Please complete the configuration of quality inspection rules and quality inspection points. If you have completed the configuration, you can click Next or exit the guide",
                            conduct3:
                "Quality inspection rating is the configuration of rating based on quality inspection points and their rating. You can define different quality inspection result levels. If you have no need for rating, you can skip this step directly. If you have completed the configuration, you can click Next step or exit the guide",
                            conduct4:
                "You can configure the data features that will be automatically introduced into the task according to the data features that the essential inspection task should check in the automatic addition location. If you do not need to automatically add data for the time being, you can click Skip, if you have completed the configuration , You can click Next",
                            conduct5:
                "If you are using mono recording, please be sure to configure this item and click Next. Please use the words that only the agent will use to help the system identify the different tracks between the agent and the customer. If you use two-channel recording or have already After completing the configuration, you can click Skip",
                            conduct6:
                "You can manually add the function to import the existing communication data in the system for quality inspection, or you have submitted a manual addition task, please click directly to complete",
                            helper: "View help documentation",
                            quit: "Quit guide",
                            next: "Next",
                            complete: "Complete",
                            skip: "skip",
                            cancel: "Cancel",
                            delete: "delete",
                            save: "Save",
                        },
                        template: { sessionType: "Session Type" },
                    },
                },
                workbench: {
                    qualityCheckList: {
                        index: {
                            name: "Quality Inspection Workbench",
                            search: "Search",
                            export: "export",
                            condition: "condition",
                            logic: "Logic",
                            reQualityInspection: "Re-Quality Inspection",
                            reQualityInspectionUnderWay: "Re-Quality Inspection Task...",
                            reQualityInspectionUnderWayTip:
                "Leaving the page after closing the window will not affect the task progress",
                            reQualityInspectionDone:
                "Re-quality inspection has been completed",
                            reQualityInspectionDoneTip:
                "The re-quality inspection has been successfully completed, please click the confirm button",
                            endReQualityInspection: "terminate task",
                            alreadyDoneTip: "completed",
                            totalTip: "Total",
                            unit: "bar",
                            endReInspectionSuccess: "Re-inspection task has been terminated",
                            closeWindow: "Close window",
                            endReInspectionFailed:
                "Re-inspection task failed, please try again later!",
                        },
                        detail: {
                            name: "Quality inspection details",
                            reviewName: "Review Details",
                            spotCheckName: "Spot Check Details",
                            audioText: "Recording text",
                            callInfo: "call information",
                            satisfactionEvaluation: "Satisfaction evaluation:",
                            queueTime: "Queue time-consuming:",
                            ringingTime: "Ring time:",
                            callTime: "Call event:",
                            hangUpIdentity: "Call hangup party:",
                            location: "Attribution:",
                            source: "Source:",
                            agent: "Agent:",
                            deviceType: "Device type:",
                            callType: "Call type:",
                            phoneNumber: "Relay Number:",
                            task: "Task:",
                            commentsTip: "Please enter quality inspection comments",
                            lastPage: "Previous page",
                            nextPage: "Next Page",
                            score: "Quality inspection score",
                            review: "review",
                            file: "Archive",
                            changeList: "change record",
                            fullMarks: "Full marks",
                            most: "most",
                            saveReview: "Review Submit",
                            saveSpotCheck: "Spot Check Submit",
                            point: "Quality Inspection Point",
                            sampleAssignInspector: "Assign Inspector",
                            reviewAssignInspector: "Assign Reviewer",
                            sampleStatus: "Sampling inspection status",
                            read: "Read",
                            user: "User",
                            confirmer: "Confirmer",
                            voiceDownloadError:
                "Voice download failed, please try again later! ",
                            smartTags: "Smart Tags",
                            interactiveTip:
                "Use interactive recognition to detect the usage of agent FAQ",
                            interactiveIsEmptyTip:
                "Interactive identification content not detected",
                            businessRecords: "Business Records",
                        },
                    },
                },
                reports: {
                    groupChatAnalysis: {
                        index: {
                            analysisOfEnterpriseWeChatGroupChat:
                "Analysis of Enterprise WeChat Group Chat",
                        },
                    },
                },
            },
            cards: {
                health: "Quality Inspection Health",
                accuracy: "Discover approval rate",
                sessionsTotal: "Total number of sessions",
                enable: "Enable",
                disable: "Disable",
                none: "Nothing",
                call: "call",
                dialogue: "Dialogue",
                taobao: "e-commerce",
                wecom: "session",
                ticket: "Ticket",
            },
            component: {
                backup: "(backup)",
                taskSaveAsSucceeded: "Task save as succeeded",
                copyContent: "Copy content",
                essentialInformation: "essential information",
                ruleConfiguration: "Rule configuration",
                basicInformationQualityInspector:
          "Basic information - Quality Inspector",
                qualityInspectionRating: "Quality inspection rating",
                roleConfiguration: "Role configuration",
                autoAddConfiguration: "Auto add configuration",
                autoPushConfiguration: "Auto push configuration",
                automaticSamplingConfiguration: "Automatic sampling configuration",
                automaticAllocationConfiguration: "Automatic allocation configuration",
                businessConfiguration: "Business configuration",
            },
            template: {
                ruleApproval: "Rule Approval",
                enterpriseMicroConversationAnalysisTask:
          "Enterprise micro conversation analysis task",
                enterpriseAndMicroVoiceAnalysisTask:
          "Enterprise and micro voice analysis task",
                saveTaskAs: "Save task as",
            },
            approve: {
                checkPoint: { index: { ruleDetails: "Rule Details" } },
                components: {
                    autoApproveSwitch: {
                        modificationSucceeded: "Modification succeeded",
                        automaticApproval: "Automatic approval",
                    },
                    pageHeaderExtra: {
                        modificationSucceeded: "Modification succeeded",
                        test: "test",
                        formal: "formal",
                        adopt: "adopt",
                    },
                    rejectButton: {
                        index: {
                            areYouSureToReject: "Are you sure to reject",
                            pleaseEnterTheRejectionReason:
                "Please enter the rejection reason",
                            reject: "reject",
                        },
                    },
                    table: {
                        index: {
                            modificationSucceeded: "Modification succeeded",
                            taskName: "Task Name",
                            dataSource: "data source",
                            qualityInspectionType: "Quality inspection type",
                            state: "state",
                            updateTime: "Update time",
                            updatedBy: "Updated by",
                            approver: "Approver",
                            reasonForRejection: "Reason for rejection",
                            operation: "operation",
                            details: "details",
                            adopt: "adopt",
                            whole: "whole",
                            approvalStatus: "Approval status",
                        },
                    },
                },
            },
        },
        dashboard: {
            title: "Overview",
            hi: "Hi,",
            standardEdition: "Standard Edition",
            call: "Voice quality check balance",
            text: "Text quality inspection validity period",
            collectionTasks: "collected quality inspection tasks",
            noCollection: "There is no collection of quality inspection tasks,",
            goToCollecte: "To collect",
            yesterdayOverView: "Yesterday's business statistics",
            yesterdayAgent: "Yesterday agent leaderboard",
            yesterdayInspWork: "The quality inspector's work situation yesterday",
            operationalStatistics: {
                title: "Business Statistics",
                qualityTesting: "Quality Testing",
                spotCheck: "Spot Check",
                appeal: "appeal",
                chartTitles: {
                    qualityTestingTrend: "Quality testing trend",
                    qualityTestingAnalysis: "Quality inspection task status",
                    spotCheckTrend: "Spot Check Trend",
                    spotCheckAnalysis: "Spot check task situation",
                    appealTrend: "Appeal Trend",
                    appealAnalysis: "Appeal task situation",
                },
                titles: {
                    qualityDataTend: "Quality inspection data distribution",
                    qualityCover: "Quality inspection coverage",
                    qualityAverageTime: "Average quality inspection waiting time",
                    qualityCoverTend: "Quality coverage trend",
                    handCover: "Manual handling situation",
                    checkCover: "Sampling coverage rate",
                    resAverageTime: "Average processing time",
                    handTend: "Manual processing trend",
                    dataTend: "Data task distribution",
                },
                qualityAverage: "Quality average score",
                scoreTrend: "Quality inspection score trend",
                qualityAverageTime: "Average quality inspection waiting time",
                callCoverage: "Voice quality inspection coverage",
                textCoverage: "Text quality inspection coverage",
                checkCount: "Sampling data volume",
                siphonCount: "Amount of extracted data",
                reviewCount: "Review data volume",
                appealCount: "Appeal data volume",
                checkRate: "Sample check execution ratio",
                reviewRate: "Appeal review ratio",
                PieTitle: {
                    totalCount: "Total number of conversations",
                    totalCall: "Number of voice calls",
                    totalIm: "Number of text conversations",
                    inspectionCall: "Voice quality inspection",
                    inspectionIm: "Text quality inspection",
                    checkCall: "voice sampling amount",
                    unCheckCall: "Voice unsampling amount",
                    checkIm: "Text sampling amount",
                    unCheckIm: "The amount of unchecked texts",
                    reviewCall: "Voice review volume",
                    reviewIm: "Text review amount",
                    unReviewCall: "The amount of unreviewed voice",
                    unReviewIm: "The amount of unreviewed text",
                    unInspectionCall: "Voice is not quality inspected",
                    unInspectionIm: "Text is not inspected for quality",
                },
                check_rate: "Sampling coverage rate",
                voice_check_rate: "Voice sampling coverage rate",
                text_check_rate: "Text sampling coverage rate",
                sample_execute_time: "Average sampling processing time",
                review_execute_time: "Average appeal processing time",
            },
            analysisOverview: {
                tableTitle: {
                    conditions: "Quality Inspection Rules Kanban",
                    agent: "Agent Leaderboard",
                },
                tableTips: {
                    conditions:
            "<div> Sort the total number of communication sessions that violate and comply with the quality inspection rules. <br />\n Quality inspection item: the name of the quality inspection point<br />\n Total number of violations: the total number of violations in this quality inspection item<br />\n Violation percentage: Violation percentage =Total number of illegal conversations/Total number of conversations</div>",
                    agent:
            "<div>Sort the agent's average rating<br />\n Agent name: the name of the agent<br />\n Agent group: the agent group where the agent agent is located (organization information in the agent information)<br /> \n The total number of chats scored: the total number of QC conversations of this agent<br />\n The average QC score: the average QC score of this agent (sum of scores/total number of conversations)</div>",
                },
                tips: {
                    total:
            "The total number of calls/dialogues entering the quality inspection task",
                    agentTotal: "Total number of QC agents entering QC tasks",
                    avg: "Average of quality inspection scores",
                    violation:
            "The sum of the number of violations of the quality inspection items that are hit by the quality inspection of all communication calls",
                    compliance:
            "Sum the number of times the quality inspection items are compliant during the communication quality inspection process",
                    check: "The total number of manual sampling",
                    appeal:
            "The total number of appeals submitted in the total number of conversations",
                    review:
            "The total number of reviews submitted in the total number of conversations",
                },
                subTips: {
                    violation:
            "Violation rate: total number of violations/total number of quality inspection items*100%",
                    compliance:
            "Compliance rate: total number of compliances/total number of quality inspection items*100%",
                    check:
            "Sampling inspection coverage rate: total number of manual random inspections/total quality inspections*100%",
                    appeal:
            "Appeal rate: total number of appeals/total quality inspection*100%",
                    review:
            "Review rate: total number of reviews/total quality inspection*100%",
                },
            },
        },
        admin: {
            system: {
                index: {
                    name: "System Settings",
                    systemName: "System name",
                    speakerRecognize: "speaker recognition",
                    massage: "Message notification",
                    evaluate: "Artificial cognitive evaluation",
                    evaluateTip:
            "After opening, selecting quick sampling in the quality inspection details will prompt manual evaluation",
                    speakerRecognizeTip:
            "Access to third-party call recording through API needs to identify whether the left channel is a agent or a customer",
                    speakerRecognizeTip2:
            "Single-channel recording is recognized by text, and the role configuration defines the role of agent",
                    enabledImSendStatusFilter:
            "Text quality inspection message filtering",
                    enabledImSendStatusFilterTips:
            "The detection content of the arithmetic of the quality inspection does not include the message with the status of [Retracted] and [Failed to send]",
                    lang: "System language setting",
                    zh: "Simplified Chinese",
                    en: "English",
                    tw: "traditional Chinese",
                },
                template: {
                    textDataDesensitization: "Text data desensitization",
                    whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "When the switch is turned on, the desensitization field drop-down list will be displayed (multiple choices are supported). The desensitization fields: person name, mobile number, address",
                    desensitizingField: "Desensitizing field",
                    name: "name",
                    cellphoneNumber: "cell-phone number",
                    address: "address",
                    dataCleaning: "Data cleaning",
                    afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "After setting, the conversation data of QC and WeChat will be cleaned regularly and cannot be recovered. Please be careful",
                    newCleaningRules: "New cleaning rules",
                    qualityInspectionRuleApproval: "Quality Inspection Rule Approval",
                    afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "After opening, the modification of quality inspection rules can take effect only after being approved",
                    maskOfflineMessageUnread: "Mask offline message unread",
                    privacyNumberProtection: "Privacy Number Protection",
                    afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'After opening, desensitize the "customer phone number" field in the call and display it as 1881*******',
                    shieldingWithdrawn: "Shielding has been withdrawn",
                    maskSendingFailed: "Mask sending failed",
                },
                component: {
                    conversation: "conversation",
                    dialogue: "dialogue",
                    weCom: "WeCom",
                    day: "day",
                    week: "week",
                    month: "month",
                    year: "year",
                },
                components: {
                    dataDeleteConfigItem: {
                        scopeOfCleaning: "Scope of cleaning",
                        dataCleaningTimeSetting: "Data cleaning time setting",
                        afterCleanUpTheData: "After, clean up the data",
                        dataAfterComplaintCompletion: "Data after complaint completion",
                    },
                },
            },
            systemIntegration: {
                title: "System Settings",
                ccIntegrationTips:
          "Intelligent quality inspection docking Udesk call center instruction document",
                unauthorized: "Unauthorized",
                authorized: "Authorized",
                udeskDomain: "Agent System Domain Name",
                udeskToken: "Agent System Key",
                udeskAdminEmail: "Agent System Administrator Account",
                password: "Administrator account password",
                applyAuth: "Authorization",
                cancelAuth: "Cancel authorization",
                reApplyAuth: "Reauthorize",
                keyTips:
          "Agent system background management-API authentication token in single sign-on",
            },
            customerField: {
                title: "Customer Field",
                table: {
                    propName: "Field name",
                    propDesc: "Field description",
                    valueType: "Field Type",
                    propType: "Create method",
                    createTime: "Create Time",
                    canNotEdit: "System field does not support editing",
                },
                edit: "Edit field",
                new: "Create field",
            },
            wechatDocking: {
                title: "Enterprise micro docking",
                formLabels: {
                    companyId: "Company ID",
                    communicationKey: "Communication Key",
                    token: "Token",
                    encodingAESKey: "EncodingAESKey",
                    url: "Event callback URL",
                    applicationName: "application name",
                    applicationId: "Web Application ID",
                    applicationSecret: "application secret",
                },
                formTitle: {
                    initialForm: "Initial Settings",
                    synchronizeForm: "Address Book Synchronization",
                    settingForm: "Application Settings",
                },
                qrCodeUrlTip:
          "Ask the administrator to scan the QR code below for code development and application binding",
            },
            taobao: {
                formLabels: {
                    name: "Store name",
                    account: "account",
                    password: "password",
                    confirm: "Confirm password",
                    authorization: "authorization",
                    switchOn: "On",
                    switchOff: "off",
                },
                tableLabels: {
                    name: "Store name",
                    account: "account",
                    authorization: "authorization",
                    updateUser: "Recently updated person",
                    updateTime: "last update time",
                },
            },
            weifengDocking: {
                formLabels: {
                    secondaryDomain: "WeFeng second-level domain name",
                    secretKeyTip:
            "Tenant secret key, obtained from the backend system of WeFeng system",
                },
            },
            user: {
                index: {
                    name: "Employee Management",
                    existingUsers: "Existing Employees",
                    deleteSuccess: "Delete success",
                    deleteFailed: "Delete failed",
                },
                detail: {
                    accountPrompt: "Please enter email",
                    resetPassword: "Reset Password",
                    passwordPrompt: "Password (6-14 letters and numbers combination)",
                    originPasswordPrompt: "Please enter the initial password",
                    resumePasswordPrompt: "Please re-enter the password",
                    passwordFormWrong:
            "Please enter a password with a combination of 6-14 letters and numbers! ",
                    resumePasswordWrong: "The two passwords are inconsistent! ",
                    suppertImageType: "support jpg, gif, png, up to 500Kb",
                    namePrompt: "Please enter your name",
                    nickNamePrompt: "Please enter a nickname",
                    telephonePrompt: "Please enter a phone number",
                    roleSelectedPrompt: "Please select a role",
                    agentIdPrompt: "Please enter the job number",
                    disabled: "disabled",
                    enabled: "Enable",
                    reloadBowerInfo:
            "If user information is changed, do I need to refresh the browser? ",
                    saveUserError:
            "Failed to save user information, please try again later",
                    resetPasswordSuccess: "Password reset successfully",
                    resetPasswordFailed: "Password reset failed, please try again later",
                },
            },
            userGroups: {
                index: {
                    name: "Employee Group Management",
                    existingUserGroups: "Existing employee groups",
                },
                detail: {},
            },
            role: {
                index: { name: "role management", existingRoles: "Existing Roles" },
                edit: {
                    editRole: "Edit role",
                    createRole: "Create role",
                    basicInfo: "Basic information",
                    taskPermissions: "Task permissions",
                    existingTasks: "Existing Tasks",
                },
            },
            anomalousData: {
                title: "Exception data",
                list: {
                    selectHolder: "Please select the data source",
                    businessTime: "Business Time",
                    businessId: "BusinessId",
                    export: "export",
                },
            },
            templateComponent: {
                list: {
                    title: "Template Market",
                    name: "template name",
                    searchHolder: "Please enter the content to be searched",
                    update: "Update status",
                    sort: "sort method",
                    moduleType: "template type",
                    moduleSourceType: "applicable type",
                    fetchNum: "Number of fetches",
                    newest: "Recently updated",
                    usedMost: "Get the most",
                    call: "call",
                    dialogue: "Dialogue",
                    waiting: "Pending review",
                    cancel: "Cancel release",
                    success: "Approved and released",
                    failed: "review rejected",
                    no: "Not upgradeable",
                    ok: "Can be upgraded",
                    all: "all",
                    delete: "Delete successfully",
                    downOk: "Successfully removed from the shelf",
                    withdraw: "Withdraw successfully",
                    updateOk: "Upgrade successful",
                    get: "Get",
                    width: "Withdraw",
                    getOk: "Get success",
                    orgName: "Owning Organization",
                    nowVersion: "current version",
                    lastedVersion: "Latest version",
                    appStatus: "application status",
                    actions: "action",
                    down: "off shelf",
                    updateTop: "Upgrade",
                    check: "View",
                    deleteWhen: "Are you sure to delete? ",
                    yes: "Yes",
                    noo: "No",
                    deleteOk: "Delete",
                    permissible: "allow",
                    noPermissible: "Not allowed",
                    openUp: "open",
                    openDown: "Not open",
                    task: "Quality Inspection Task",
                    point: "Quality Inspection Point",
                    tag: "Semantic tag",
                    muti: "multi-element",
                    inter: "One question and one answer",
                    info: "Information entity",
                    concept: "Concept word",
                    detail: "Module details",
                    infomation: "Basic information",
                    detailConfig: "Detailed configuration",
                    twoEdit: "Secondary Edit",
                    mustUpdate: "Update now",
                    descition: "template description",
                    descitionSize: "Template description cannot exceed 250 words",
                    stencilsDetail: "Template Details",
                    updatedRecords: "Update records",
                    version: "Version number",
                    updateDescription: "Update description",
                    myModule: "My Template",
                    addModule: "Create Module",
                    publishVersion: "publish version",
                    earliestVersion: "earliest version",
                    edit: "Edit",
                    add: "Add",
                    configNo: "Module configuration details cannot be empty",
                    updateNo: "Update description cannot be empty",
                    editSuccess: "Modification successful",
                    saveSuccess: "Save successfully",
                    newer: "update",
                    sumbit: "Submit",
                    cancelName: "Cancel",
                    nameRequired: "Module name is a required field",
                    nameSize: "Module name cannot exceed 16 characters",
                    publishStatus: "Publish Status",
                    submitUpdate: "Submit Update",
                    updateVersion: "Update version",
                    updateExplain: "Update Explain",
                },
            },
        },
        demo: {
            title: "Smart Quality Inspection",
            template: "Select quality inspection template",
            radio: "Select recording format",
            uploadAudio: "Upload audio recording",
            uploadAudioTip:
        "Upload recording only supports wav and mp3 formats, and the size does not exceed 1G",
            uploadText: "Upload text",
            uploadTextTip:
        "Upload text only supports srt and txt formats, and the size does not exceed 10M. After uploading text, ASR will no longer be used to parse the recording file",
            uploadSuccess: "Recording file upload successfully",
            uploadFailed: "Recording file upload failed",
            startQualityInspection: "Start Quality Inspection",
            qualityInspectionStarted: "Quality inspection in progress",
            downloadText: "Text download",
            checking: "Quality inspection progress",
            checkingTip:
        "It is estimated to take a few minutes, please wait patiently",
            noCheckContent: "No quality inspection result yet",
            noTextDownload:
        "No quality inspection text can be downloaded temporarily!",
            pleaseCheckParams:
        "Please check whether to select a template, voice type and upload a recording file",
            inspectionFailed: "Quality inspection failed, please try again later!",
        },
        wechatQa: {
            baseInfo: { title: "Basic Information" },
            conversationSetting: {
                title: "Session Settings",
                segmentationStrategy: "Segmentation Strategy",
                rule: "Strategy",
                time: "Split Time",
                daily: "daily",
                passScore: "Session Pass Score",
                ruleLogic: "execution logic",
            },
            message: {
                title: "Push Event",
                table: {
                    index: "Serial Number",
                    name: "name",
                    type: "Push method",
                    desc: "Description",
                    status: "Status",
                },
                subTitle: "Event list",
            },
            checkPoint: {
                title: "Analysis Model",
                groups: "Model grouping",
                newClassification: "Create grouping",
                editClassification: "Edit Group",
                benchmarkScore: "benchmark score",
                pointCount: "Total analysis models under the current category",
                openPointCount: "The number of enabled is",
                closedPointCount: "Deactivated number is",
                scoreLowerLimit: "Minimum score lower limit",
                scoreUpperLimit: "Maximum score limit",
                classificationName: "Group name",
                classificationNamePlaceHolder: "Please enter the group name",
                classificationRemark: "Group description",
                classificationRemarkPlaceHolder: "Please enter a group description",
            },
            chartRecord: {
                title: "Enterprise WeChat Session",
                table: {
                    id: "session ID",
                    type: "session type",
                    startTime: "session start time",
                    endTime: "Last end time of the session",
                    groupId: "Group ID",
                    client: "Client",
                    customer: "Agent",
                    averageScore: "Average quality inspection score",
                },
                detail: {
                    title: "Details of Enterprise WeChat Session",
                    inspectionInfo: "Analysis Information",
                    chartInfo: "session information",
                    tasks: "Analysis tasks",
                    avgScore: "Analysis average score",
                    qualified: "qualified",
                    unqualified: "Unqualified",
                    taskTitle: "Applicable analysis template",
                    info: "Real-time analysis information",
                    today: "Today's News",
                    history: "History message",
                    components: {
                        filter: {
                            index: {
                                pleaseEnterACommentNickname:
                  "Please enter a comment / nickname",
                                pleaseEnterTheGroupChatName: "Please enter the group chat name",
                            },
                        },
                    },
                    template: { whole: "whole" },
                },
            },
            realTimeAnalysis: {
                title: "Real-time analysis",
                table: {
                    name: "Scene name",
                    count: "Number of analysis models",
                    baseScore: "Base Score",
                    passScore: "pass score",
                    maxScore: "Maximum score limit",
                    minScore: "Minimum Score Lower Limit",
                    status: "Status",
                    actions: "action",
                },
                detail: {
                    title: "Real-time analysis scenario",
                    form: {
                        name: "Scene name",
                        prevCondition: "Scene trigger condition",
                        prevConditionToolTip:
              "If the condition is empty, the scene will be analyzed by default",
                        prevConditionError:
              "Scene trigger conditions cannot coincide with the analysis model",
                        scoreInputPlaceholder: "input score",
                        baseScore: "Base Score",
                        status: "Status",
                        passScore: "pass score",
                        maxScore: "Maximum score limit",
                        minScore: "Minimum score lower limit",
                        analysisModel: "Analysis Model",
                        message: "Trigger notification",
                        pushType: "Push method",
                        createAnalysisModel: "Add Model",
                        scoreUpperLimitError:
              "The lower limit of the lowest score cannot be greater than the upper limit of the highest score",
                        table: { name: "model name", rule: "rule", score: "score" },
                        pushConfigSwitchTip1: "When this scene appears",
                        pushConfigSwitchTip2:
              "Push notification for the second time and above",
                    },
                },
            },
            smartCustomerTag: {
                title: "Smart Customer Label",
                table: {
                    name: "rule name",
                    tags: "Enterprise and micro customer tags",
                    status: "enable status",
                    createUser: "Creator",
                    createTime: "create time",
                },
                detail: {
                    name: "rule name",
                    condition: "set rules",
                    logic: "logic",
                    status: "enable status",
                    tags: "Select tags",
                },
            },
            setting: {
                wechatQaCustomerLabel: {
                    template: { numberOfCustomerTags: "Number of customer tags" },
                },
            },
            chartAudioRecord: {
                index: {
                    talkTime: "talk time",
                    sessionType: "session type",
                    whole: "all",
                    staff: "staff",
                    customer: "customer",
                    callKeywords: "Call keywords",
                },
                detail: {
                    template: {
                        enterpriseMicroVoiceCallDetails:
              "Enterprise MicroVoiceCall Details",
                    },
                },
            },
            wechatQaChartRecordEntry: {
                sessionArchiving: "Session Archiving",
                voiceCall: "Voice call",
                noMoreVoiceCalls: "No more voice calls",
                enterpriseWechatVoiceCall: "Enterprise WeChat voice call",
                enterpriseWechatConversationDetails:
          "Enterprise WeChat session details",
                previous: "Previous",
                next: "Next",
            },
            dashboard: {
                template: {
                    enterpriseMicroConversationAnalysisTask:
            "Enterprise micro conversation analysis task",
                    enterpriseAndMicroVoiceAnalysisTask:
            "Enterprise and micro voice analysis task",
                },
            },
            wechatQaTaskManagerEntry: {
                analysisOfEnterpriseWeChatGroupChat:
          "Analysis of Enterprise WeChat Group Chat",
            },
        },
        businessAnalysis: {
            keyword: "Report name",
            createUser: "creator",
            createTime: "Create Time",
            updateTime: "Update time",
        },
        semanticIntelligence: {
            clusterAnalysis: "cluster analysis ",
            speechMining: "Speech mining",
            informationEntities: {
                detail: {
                    batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Support batch import of information entities through the API interface, the API name does not support editing after saving",
                },
                component: { nothing: "nothing" },
            },
            knowledgeBase: {
                index: {
                    classification: "classification",
                    import: "Import",
                    importKnowledgeBase: "Import knowledge base",
                    moveKnowledgePointsInBatch: "Batch move knowledge points",
                    moveTo: "Move to:",
                    pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Please select the knowledge points that need to be moved in batches",
                },
                detail: {
                    index: {
                        intrasentence: "inside the sentence",
                        afterProblem: "after the question",
                    },
                },
            },
            semanticTags: { component: { nothing: "nothing" } },
            smartWordsLibrary: {
                template: {
                    sensitiveWords: "Sensitive words",
                    sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Sensitive words: sensitive words can be configured with business focus words for public opinion to discover sensitive word clouds",
                },
                sensitiveWords: {
                    component: {
                        newSensitiveWord: "New Sensitive Word",
                        editSensitiveWords: "Edit Sensitive Words",
                        sensitiveWords: "Sensitive words",
                    },
                    template: {
                        sensitiveWords: "Sensitive words",
                        sensitiveWordsCannotBeEmpty: "Sensitive words cannot be empty",
                        pleaseEnterSensitiveWordsSeparatedBy:
              "Please enter sensitive words separated by ','",
                    },
                },
            },
            clusteringAnalysis: {
                add: {
                    index: {
                        pleaseSelectAnApplicationScenario:
              "Please select an application scenario",
                        keyWord: "key word",
                        pleaseEnterKeywords: "Please enter keywords",
                        keywordLengthWithinCharacters:
              "Keyword length within 40 characters",
                        dataSources: "data sources ",
                        pleaseSelectADataSource: "Please select a data source",
                        theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "The current clustering has been completed. Re editing will generate a new clustering data and will not overwrite the completed clustering data. Please confirm",
                        savingSucceeded: "Saving succeeded",
                        clusteringStart: "Clustering start",
                        clustering: "clustering",
                        edit: "edit",
                        newlyBuild: "newly build",
                        clusterName: "Cluster name",
                        pleaseEnterAName: "Please enter a name",
                        withinCharactersOfTheName: "Within 40 characters of the name",
                        speaker: "speaker",
                        pleaseSelectASpeaker: "Please select a speaker",
                        sessionDate: "Session Date",
                        pleaseEnterTheSessionDate: "Please enter the session date",
                        selectUpToMonthOfHistoricalSessionData:
              "Select up to 1 month of historical session data",
                        automaticallyClusterDailyNewData:
              "Automatically cluster daily new data",
                        conversationChannel: "Conversation channel",
                        pleaseSelectAConversationChannel:
              "Please select a conversation channel",
                        applicationScenario: "Application scenario",
                        clusteringRules: "Clustering rules",
                        incompleteClusteringRuleSettings:
              "Incomplete clustering rule settings",
                        startClustering: "Start clustering",
                    },
                    components: {
                        conditionItem: {
                            pleaseSelectAQualityInspectionTask:
                "Please select a quality inspection task",
                            beEqualTo: "be equal to",
                        },
                    },
                },
                detail: {
                    index: {
                        theme: "theme",
                        numberOfCorpora: "Number of corpora",
                        corpusCoverage: "Corpus coverage",
                        clusterDetails: "Cluster Details",
                    },
                    ranking: {
                        hotWords: "Hot words",
                        numberOfCorpora: "Number of corpora",
                        speaker: "speaker",
                        sit: "sit",
                        passenger: "passenger",
                        hotSentence: "Hot sentence",
                        corpusDetails: "Corpus details",
                    },
                },
                index: {
                    delete: "delete",
                    deletionSucceeded: "Deletion succeeded",
                    clusterName: "Cluster name",
                    speaker: "speaker",
                    creationTime: "Creation time",
                    state: "state",
                    operation: "operation",
                    see: "see",
                    edit: "edit",
                    clusterAnalysis: "cluster analysis ",
                    newCluster: "New Cluster",
                    clusteringStatus: "Clustering status",
                },
            },
            wordsMining: {
                dissent: {
                    components: {
                        pageLeftTable: {
                            enterKeywordsToSearchForObjections:
                "Enter keywords to search for objections",
                        },
                        pageRightTable: {
                            columnHeader: {
                                scriptLibrary: "Script Library",
                                recommendedScripts: "Recommended scripts",
                                common: "common",
                                strip: "strip",
                                scriptFavorites: "Script Favorites",
                            },
                            conditionPostfix: {
                                detectRoles: "Detect Roles",
                                detectionRange: "Detection range",
                                sentence: "sentence",
                            },
                            customerFilter: {
                                scriptFiltering: "Script filtering",
                                cancel: "cancel",
                            },
                            index: {
                                viewDetails: "View Details",
                                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Are you sure you want to discard? You cannot restore after discarding",
                                abandonment: "Abandonment",
                                deletionSucceeded: "Deletion succeeded",
                                passenger: "passenger",
                                sit: "sit",
                                adopted: "Adopted",
                                determine: "determine",
                                cancel: "cancel",
                                adopt: "adopt",
                            },
                            pageRightFrom: {
                                savingSucceeded: "Saving succeeded",
                                pleaseEnterTheScriptContent: "Please enter the script content",
                                pleaseSelectAFavoriteGroup: "Please select a favorite group",
                            },
                        },
                    },
                    favorite: {
                        components: {
                            pageLeftTable: {
                                objectionHandlingScriptLibrary:
                  "Objection Handling Script Library",
                            },
                            pageRightTable: {
                                index: {
                                    pleaseChooseToAdoptScript: "Please choose to adopt script",
                                },
                            },
                        },
                    },
                },
                favoriteView: {
                    components: {
                        pageLeftTable: {
                            objectionHandlingScriptFavorites:
                "Objection Handling Script Favorites",
                        },
                        pageRightTable: {
                            likeCancel: "Like Cancel",
                            likeSuccessfully: "Like successfully",
                            adoptScripts: "Adopt scripts",
                            operation: "operation",
                            viewConversationDetails: "View conversation details",
                        },
                    },
                    sellingPoint: {
                        components: {
                            pageLeftTable: {
                                productSellingPointScriptLibrary:
                  "Product Selling Point Script Library",
                            },
                            pageRightTable: { keyPointsOfScript: "Key points of script" },
                        },
                    },
                },
                favorite: {
                    components: {
                        pageLeftTable: {
                            objectionHandlingScriptFavorites:
                "Objection Handling Script Favorites",
                            newGroup: "New Group",
                            editGroup: "Edit Group",
                            addGroup: "Add Group",
                            savingSucceeded: "Saving succeeded",
                            groupName: "Group Name",
                            pleaseEnterTheGroupName: "Please enter the group name",
                            groupNameWithinCharacters: "Group name within 20 characters",
                            deletionSucceeded: "Deletion succeeded",
                        },
                        pageRightTable: {
                            configDrawer: {
                                index: {
                                    scriptRecommendationSettings:
                    "Script recommendation settings",
                                },
                                rightForm: {
                                    savingSucceeded: "Saving succeeded",
                                    intelligentRecommendation: "Intelligent recommendation",
                                    theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "The adopted excellent scripts will be automatically displayed in the conversation sidebar of similar objections, and employees can directly view the recommended scripts for learning on the detailed page of conversation review",
                                    objectionsIncludedByDefault: "Objections included by default",
                                    addRecommendationTriggerSemantics:
                    "Add recommendation trigger semantics",
                                    remindEmployeesToView: "Remind employees to view",
                                    shareWithMoreEmployees: "Share with more employees",
                                    allHands: "all hands",
                                },
                                smartToolbar: { selectSemanticTag: "Select semantic tag" },
                            },
                            index: {
                                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Do you want to export all data records under the current group?",
                                newScript: "New Script",
                                editingScript: "Editing script",
                                savingSucceeded: "Saving succeeded",
                                objection: "objection",
                                pleaseEnterAScriptObjection: "Please enter a script objection",
                                speechTechnique: "Speech technique",
                                pleaseEnterTheScriptContent: "Please enter the script content",
                                grouping: "grouping",
                                pleaseSelectAFavoriteGroup: "Please select a favorite group",
                                adoptScripts: "Adopt scripts",
                                passenger: "passenger",
                                sit: "sit",
                                numberOfViews: "Number of views",
                                useful: "Useful",
                                operation: "operation",
                                edit: "edit",
                                delete: "delete",
                                deletionSucceeded: "Deletion succeeded",
                                scriptRecommendationSettings: "Script recommendation settings",
                            },
                        },
                    },
                },
                index: {
                    scriptLibrary: "Script Library",
                    scriptMining: "Script mining",
                    objectionHandlingScripts: "Objection handling scripts",
                    productSellingPointScripts: "Product selling point scripts",
                },
                sellingPoint: {
                    index: { newCluster: "New Cluster" },
                    components: {
                        callDetailModal: {
                            sessionDetails: "Session Details",
                            cancel: "cancel",
                            determine: "determine",
                            choice: "choice",
                        },
                        pageCenterTable: { scriptOverview: "Script Overview" },
                        pageLeftTable: {
                            enterSearchKeywords: "Enter search keywords",
                            keyWord: "key word",
                        },
                        pageRightTable: {
                            index: {
                                viewDetails: "View Details",
                                scriptLibrary: "Script Library",
                                scriptRefinement: "Script Refinement",
                                adoptScript: "Adopt script",
                            },
                            tagsEdit: { keyPointsOfScript: "Key points of script" },
                        },
                    },
                    favorite: {
                        components: {
                            pageLeftTable: {
                                productSellingPointScriptLibrary:
                  "Product Selling Point Script Library",
                            },
                            pageRightTable: {
                                configDrawer: {
                                    rightForm: {
                                        defaultIncludedSellingPoints:
                      "Default included selling points",
                                    },
                                },
                                index: {
                                    keyWord: "key word",
                                    pleaseSelectAKeyword: "Please select a keyword",
                                    keyPointsOfScript: "Key points of script",
                                    pleaseInputTheKeyPointsOfTheScript:
                    "Please input the key points of the script",
                                    viewDetails: "View Details",
                                    pleaseChooseToAdoptScript: "Please choose to adopt script",
                                },
                            },
                        },
                    },
                },
            },
        },
        gong: {
            common: {
                pleaseSelectATemMember: "Please select a team member",
                existingFilter: "Existing filter",
                pleaseSelect: "Please select",
                filterCondition: "Filter Condition",
                areYouSureToDeleteTheCurrentFilter:
          "Are you sure to delete the current filter? ",
                sessionDate: "session date",
                staff: "staff",
                customer: "customer",
                pleaseEnterCustomerName: "Please enter the customer name",
                keyWord: "Keyword",
                whole: "all",
                pleaseEnterSessionKeywords: "Please enter session keywords",
                sessionDuration: "session duration",
                sessionLabel: "Session Label",
                pleaseSelectASessionLabel: "Please select the session label",
                commentTopic: "Comment topic",
                pleaseSelectACommentTopic: "Please select a comment topic",
                areYouSureYouWantToOverwriteTheCurrentFilter:
          "Are you sure to overwrite the current filter? ",
                overlayFilter: "Overlay filter",
                NewFilter: "+ Create filter",
                newFilter: "Create filter",
                filterName: "Filter name",
                pleaseEnter: "Please enter",
                allCall: "All calls",
                percentage: "percentage",
                filterScreen: {
                    callRecordID: "Call Record ID",
                    pleaseFillInTheCallRecordID: "Please fill in the call record ID",
                    minute: "minute",
                    second: "second",
                    whole: "all",
                    callRecording: "Call recording",
                    enterpriseWechat: "Enterprise wechat",
                    conversationChannel: "Conversation channel",
                    pleaseSelectASessionChannel: "Please select a session channel",
                    learningStatus: "Learning status",
                    pleaseSelectLearningStatus: "Please select learning status",
                    notLearned: "Not learned",
                    learned: "Learned",
                    Minutes: "minutes",
                    theFilterConditionIsEmptyNewFilterIsNotSupported:
            "The filter condition is empty and new filters are not supported! ",
                    filterAddedSuccessfully: "Filter added successfully! ",
                    failedToAddFilter: "Filter addition failed! ",
                    filterDeletedSuccessfully: "Filter deleted successfully! ",
                    filterDeletionFailed: "Filter deletion failed! ",
                    filterOverwriteSucceeded: "The filter was overwritten successfully! ",
                    filterOverwriteFailed: "Filter Overwrite Failed! ",
                    theme: "theme",
                    pleaseSelectATheme: "Please select a theme",
                    keyEvents: "Key Events",
                    pleaseSelectAKeyEvent: "Please select a key event",
                },
                filterItem: {
                    startRetesting: "Start retesting",
                    time: "time",
                    talkTime: "talk time",
                    keyEvents: "Key events",
                    totalImplementationRateOfSupervisionItems:
            "Total implementation rate of supervision items",
                    sort: "Sort:",
                    seeMore: "See more",
                },
                filterStatistics: {
                    conversation: "conversation",
                    weChat: "WeChat",
                    quantity: "quantity",
                },
            },
            caseBase: {
                index: {
                    copyTo: "Copy to",
                    pleaseSelectTheLocationToMove: "Please select the location to move! ",
                    folderCopiedSuccessfully: "The folder was copied successfully! ",
                    cancelCollectionSuccessfully: "Cancel the collection successfully! ",
                    collectionSucceeded: "The collection is successful! ",
                    starCaseBase: "Star Case Base",
                    move: "move",
                    rename: "rename",
                    delete: "delete",
                    folderMovedSuccessfully: "Folder moved successfully! ",
                    fileMovedSuccessfully: "The file was moved successfully! ",
                    fileMoveFailed: "File move failed! ",
                    folderAddedSuccessfully: "Add folder successfully! ",
                    folderRenameSucceeded: "The folder was renamed successfully! ",
                    fileRenameSucceeded: "The file was renamed successfully! ",
                    theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "After deleting the folder, it cannot be retrieved, are you sure you want to delete it? ",
                    deleteSucceeded: "Delete successful! ",
                    theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "The file cannot be retrieved after it is deleted, are you sure to delete it? ",
                    share: "shared",
                    folders: "folders,",
                    calls: "calls",
                    newFolder: "Create folder",
                    modifyFolder: "Modify folder",
                    title: "Title",
                    pleaseEnter: "Please enter",
                    selectMoveLocation: "Select move location",
                },
            },
            msgCenter: {
                index: {
                    learningReminder: "Learning Reminder",
                    subscriptionDynamics: "Subscription Dynamics",
                },
                subscription: {
                    index: {
                        theSessionYouSubscribedToID: "The session you subscribed to (ID",
                        thereAreDynamicUpdates: "There are dynamic updates",
                        newCommentsReplies: "Add Comments/Replies",
                        strip: "strip",
                        newHelp: "Add help",
                        goAndWatch: "Go to watch",
                    },
                },
                reply: {
                    index: {
                        respondedToMyComments: "responded to my comment",
                        replySucceeded: "responded successfully! ",
                    },
                },
                answer: {
                    index: {
                        youMeInTheComments: "@Me in the comments",
                        replySucceeded: "The reply was successful! ",
                    },
                },
                share: { index: { shareCallsForYou: "Share for your calls" } },
                study: {
                    index: {
                        learned: "Learned",
                        ignored: "Ignored",
                        study: "study",
                        ignore: "ignore",
                    },
                },
            },
            userManage: {
                list: {
                    headPortrait: "Portrait",
                    fullName: "name",
                    nickname: "nickname",
                    excellentStaff: "Excellent Staff",
                    yes: "Yes",
                    no: "No",
                    department: "Department",
                    accountNumberemail: "Account (email)",
                    telephone: "telephone",
                    jobNumber: "job number",
                    role: "role",
                    pleaseEnterANameOrPhoneSearch:
            "Please enter a name or phone to search",
                    person: "person)",
                    AddEmployee: "+ Add employee",
                },
                edit: {
                    jobNo: "Job No",
                    udeskCustomerServiceSystemIdentity: "Udesk agent system identity",
                    enterpriseWechatIdentity: "Enterprise wechat identity",
                    inputID: "Input ID",
                    newIdentityBinding: "Create identity binding",
                    identityBinding: "Identity binding",
                    avatarGreaterThan500kb: "The avatar is greater than 500Kb",
                    uploadSuccessful: "Upload successful",
                    upload: "Upload",
                    supportJPGGIFAndPNGUpTo500kb: "Support jpg, gif, png, maximum 500Kb",
                    yes: "Yes",
                    no: "No",
                    accountNumber: "Account",
                    pleaseEnterTheAccountNumber: "Please enter the account number",
                    initialPassword: "Initial Password",
                    pleaseInputAPassword: "Please enter the password",
                    confirmPassword: "Confirm Password",
                    thePasswordsEnteredTwiceAreDifferent:
            "The passwords entered twice are not the same!",
                    headPortrait: "Portrait",
                    fullName: "name",
                    pleaseEnterYourName: "Please enter your name",
                    nickname: "nickname",
                    department: "Department",
                    excellentStaff: "Excellent Staff",
                    sessionIdentity: "Session Identity",
                    markTheSessionRecordAsBelongingToTheEmployee:
            "mark the session record belongs to the employee",
                    telephone: "telephone",
                    jobNumber: "job number",
                    role: "role",
                    pleaseSelectARole: "Please select the role",
                    preservation: "Save",
                    cancel: "Cancel",
                    addedSuccessfully: "Added successfully! ",
                    modificationSucceeded: "The modification is successful! ",
                    newEmployees: "Create employee",
                    editEmployee: "Edit employee",
                },
                detail: {
                    viewDetails: "View details",
                    accountNumber: "Account",
                    headPortrait: "Portrait",
                    fullName: "name",
                    nickname: "nickname",
                    department: "Department",
                    excellentStaff: "Excellent Staff",
                    yes: "Yes",
                    no: "No",
                    sessionIdentity: "Session Identity",
                    markTheSessionRecordAsBelongingToTheEmployee:
            "mark the session record belongs to the employee",
                    telephone: "telephone",
                    jobNumber: "job number",
                    role: "role",
                },
            },
            roleManage: {
                list: {
                    menu: "menu",
                    functionalCategory: "Functional category",
                    functionalSubclass: "Functional subclass",
                    deletedSuccessfully: "Deleted successfully",
                    NewRole: "+ Create role",
                    role: "role",
                    dataPermissions: "Data permissions:",
                    edit: "edit",
                    areYouSureYouWantToDeleteTheRole: "Delete the role",
                    determine: "OK",
                    cancel: "Cancel",
                    delete: "delete",
                    applicationPermission: "Application permission",
                },
                edit: {
                    youCanOnlySeeYourOwnSessionData:
            "You can only see your own session data",
                    youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "You can view the session data of your department and subordinate departments",
                    menu: "menu",
                    functionalCategory: "Functional category",
                    functionalSubclass: "Functional subclass",
                    successfullyAdded: "Added successfully",
                    modifiedSuccessfully: "Modified successfully",
                    edit: "edit",
                    cancel: "Cancel",
                    confirm: "confirm",
                    roleName: "role name",
                    pleaseEnterTheRoleName: "Please enter the role name",
                    dataAuthority: "Data Authority",
                    youCanViewCompanyWideSessionData:
            "You can view the session data of the whole company",
                    applicationPermission: "Application permission",
                },
            },
            callDetail: {
                index: {
                    theSettingWillBeSuccessfulLater:
            "The setting will be successful later!",
                    previous: "Previous",
                    next: "Next",
                    debuggingMode: "Debugging mode",
                    afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "After activation, if the message matches the rules of key events, supervision items, customer labels or knowledge point rules, the rule name that the message matches and the knowledge point name of the knowledge base will be displayed.",
                    seeLater: "See later",
                    cancel: "cancel",
                    determine: "determine",
                    conversationalInsights: "Conversational insights",
                    customerInsight: "Customer Insight ",
                    knowledgePointRecommendation: "Knowledge point recommendation",
                    comment: "comment",
                    sessionQuality: "Session quality",
                    setToListenLaterSuccessfully: "Set to listen later successfully! ",
                    listenLater: "Listen later",
                    seekCounselingSuccess: "Seek Counseling Success! ",
                    altogether: "total",
                    appearsAt: "appears at '",
                    commentsPublishedSuccessfully: "Comments published successfully",
                    callRecording: "Call recording",
                    addedSuccessfully: "Added successfully",
                    shareWithColleagues: "Share with colleagues",
                    shareWithCustomers: "Share to customers",
                    resumptionDetails: "Resumption Details",
                    addToCaseBase: "Add to case base",
                    shareCases: "Share case",
                    seekCounseling: "seeking counseling",
                    sessionRecord: "Session record",
                    businessRecord: "Business Record",
                    keywordSearch: "Keyword search",
                    whole: "all",
                    pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Please enter a comment, enter @ to notify others, enter # to add a topic",
                    subscriptionSession: "Subscription session",
                    afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "After subscribing, you will receive a notification when there is a new request, comment or reply",
                },
            },
            coachTask: {
                index: {
                    employeeName: "employee name",
                    customerName: "Customer name",
                    source: "source",
                    viewSessionRecords: "View session records",
                    coachingStatus: "Coaching Status",
                    helpTime: "Help Time",
                    counselingTime: "Counseling Time",
                    helpContent: "Help Content",
                    coachingOperation: "coaching operation",
                    areYouSureYouWantToIgnoreIt: "Are you sure you want to ignore? ",
                    determine: "OK",
                    cancel: "Cancel",
                    ignoreHelp: "Ignore help",
                    coaching: "coaching",
                    viewCoaching: "View Coaching",
                    areYouSureToReturnToCounseling:
            "Are you sure to return to counseling? ",
                    returnToPendingCoaching: "Recovery to pending coaching",
                    tutor: "Tutor",
                    helpSeekingStatus: "Seeking status",
                    recentCoachingTime: "Recent coaching time",
                    operation: "operation",
                    ignoreSuccess: "Ignore success! ",
                    recoverySucceeded: "Recovery succeeded! ",
                    successfulCoaching: "Coaching successfully! ",
                    myMission: "My Mission",
                    selectCoachingStatus: "Select Coaching Status",
                    whole: "all",
                    iAskedForHelp: "I asked for help",
                    signOut: "Sign out",
                    submit: "Submit",
                    helpPerson: "help person",
                    customer: "customer",
                    toBeCoached: "to be coached",
                    counselingSuggestions: "Counseling Suggestions",
                    pleaseEnterCoachingSuggestions: "Please enter coaching suggestions",
                },
            },
            coachTaskCenter: {
                title: "Task Center",
                list: {
                    taskName: "task name",
                    lessonGroup: "course classification",
                    lessonName: "Course",
                    taskType: "task type",
                    taskStatus: "Task Status",
                    taskExecutionInfo: "Execution",
                    totalCount: "Number of people who should be executed",
                    executeCount: "Number of people who have been executed",
                    executeRate: "execution rate",
                    completeCount: "Complete number",
                    completeRate: "Completion rate",
                    commitCount: "Number of people reaching the standard",
                    commitRate: "Standard compliance rate",
                    excellentRate: "Excellent rate",
                    taskTime: "task time",
                    option: "Operation",
                    edit: "edit",
                    taskDetail: "task data",
                    delete: "delete",
                    new: "New release",
                    taskTypeAll: "All",
                    taskTypePractice: "practice",
                    taskTypeTest: "Exam",
                    taskStatusNotStarted: "not started",
                    taskStatusRunning: "in progress",
                    taskStatusEnd: "End",
                    taskStatusInvalid: "Not valid",
                    unlimitedTime: "unlimited time",
                    to: "to",
                    deleteConfirm:
            "After deletion, all learning records under this task will be cleared. Do you confirm the deletion? ",
                    deleteSuccess: "Task deleted successfully",
                },
                edit: {
                    addTask: "New release task",
                    editTask: "Edit release task",
                    publishSuccess: "Published successfully",
                    publishFailed: "Publish failed",
                    updateSuccess: "Update successful",
                    updateFailed: "Update failed",
                    cannotBeNull: "Cannot be null",
                    explain: "Description",
                    taskDescExtra:
            "(The content here will be displayed at the entry of {0})",
                    taskTargetObj: "Release object",
                    isEnable: "Effective state",
                    dialogueMode: "dialogue mode",
                    voice: "Voice",
                    text: "text",
                    userPortrait: "User Portrait",
                    difficultyLevel: "difficulty",
                    limitDuration: "Duration limit",
                    tipLabel: "Speaking tips",
                    tipTooltip: "Whether to allow students to view reference words",
                    limitTimes: "Number of exams",
                    time: "time",
                    gradingStandart: "grading standard",
                    gradingStandartDescAffix:
            "(the total score of the current dialogue process",
                    gradingStandartDescSuffix:
            "Score, speech skills {0} points, emotion {1} points, speech speed {2} points)",
                    completeStandart: "Task completion requirements",
                    byTimes: "by times",
                    byScore: "by score",
                    completeByTimes: "Practice times",
                    times: "times",
                    score: "point",
                    anyTimes: "any time",
                    getScore: "Score",
                    offLimitDuration: "Unlimited duration",
                    onLimitDuration: "limited duration",
                    commitScore: "Meet the standard, score",
                    excellentScore: "Excellent standard, score",
                    offLimitTime: "Unlimited time",
                    onLimitTime: "limited time",
                },
            },
            homePage: {
                common: {
                    useMessage: { hi: "Hi," },
                    callDynamic: {
                        today: "today",
                        yesterday: "yesterday",
                        sessions: "sessions",
                    },
                    todoList: {
                        theSessionIsAddedTolistenLater:
              'The session was added to "listen later"',
                        askYouForHelp: "ask you for help",
                        iHopeToContactYouLater: "Hope to contact later",
                        lookingForwardToSendingTheInformationLater:
              "Expecting to send information later",
                        dialogueRisk: "Sessions have risk points",
                        tutored: "tutored",
                        goToCounseling: "go to counseling",
                        contacted: "Contacted",
                        hasBeenSent: "sent",
                        areYouSureYouWantToIgnoreIt: "Are you sure you want to ignore? ",
                        determine: "OK",
                        cancel: "Cancel",
                        ignore: "ignore",
                        checked: "checked",
                    },
                    callListItem: {
                        strip: "strip",
                        learned: "Learned",
                        keyEvents: "Key events",
                        totalImplementationRateOfSupervisionItems:
              "Total implementation rate of supervision items",
                    },
                },
                index: {
                    operationSucceeded: "The operation succeeded! ",
                    sessionDynamics: "Session Dynamics",
                    my: "my",
                    department: "Department",
                    company: "company",
                    toDoList: "To Do",
                },
            },
            teamAnalysis: {
                index: {
                    effectiveCallVolumenumber: "Effective call volume (number)",
                    bestPractices: "Best Practices",
                    averageDurationOfASingleCallminutes:
            "Single average call duration (minutes)",
                    totalCallVolumePCs: "Total call volume (pieces)",
                    totalTalkTimehours: "Total talk time (hours)",
                    speakingPercentagepercentage: "Speaking percentage (percentage)",
                    maximumContinuousSpeakingTimeminutes:
            "The longest continuous speaking time (minutes)",
                    numberOfIndepthDiscussionsPCs: "Number of in-depth discussions (pcs)",
                    maximumCustomerTalkminutes: "The longest customer talk (minutes)",
                    questionFrequencyPCs: "Question frequency (number)",
                    averageValue: "Average",
                    selectDate: "Select date",
                    personnel: "personnel",
                    pleaseSelect: "Please select",
                    callRange: "Call range",
                    conversationActivity: "Conversation activity",
                    conversationAction: "Conversation action",
                    keyEvents: "Key Events",
                    callRecord: "Call Record",
                    filter: "Filter:",
                    conversation: "call",
                    minute: "minute",
                    sale: "employee",
                },
            },
            saleClientCenter: {
                detail: {
                    index: {
                        january: "January",
                        numberOfCalls: "Number of calls",
                        accountInformation: "Account Information",
                        month: "month",
                        today: "today",
                        customerDetails: "Customer Details",
                        searchKeywordsInCommunicationContent:
              "Search keywords in communication content",
                        allMembers: "All members",
                        reset: "reset",
                        query: "query",
                        contactOverview: "Contact Overview",
                        lastContact: "Last Contact",
                        nextContact: "Next contact",
                        previous: "Previous",
                        next: "Next",
                        viewDetails: "View details",
                        contactDynamics: "Contact Dynamics",
                        lastYear: "Last year",
                        lastCall: "Last call",
                        hotTopicsForDiscussion: "Discussion topic hot",
                        totalInTheLastYear: "Total in the last year",
                        callTimes: "Calls",
                    },
                },
                list: {
                    index: {
                        followUpSuggestions: "Follow up suggestions",
                        customer: "customer",
                        risk: "risk",
                        followUp: "Follow up situation",
                        personInCharge: "Person in charge",
                        recentFollowup: "Recent Followup",
                        lastContactTime: "The last contact time",
                        operation: "operation",
                        see: "view",
                        exceed: "exceed",
                        noActivityForMoreThanDays: "No activity for moreThanDays",
                        lately: "latest",
                        risksOccurredInTheLastDays: "Risk semantics occur within days",
                        savedSuccessfully: "Saved successfully",
                        today: "today",
                        allDepartments: "All departments",
                        allMembers: "All members",
                        customerName: "Customer name",
                        reset: "reset",
                        query: "query",
                        customers: "customers",
                        showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Show the contact between employees and customers, in which the pink bubbles are customer messages, and the blue are employee messages. The larger the proportion of speeches, the larger the bubbles",
                        riskAllocation: "Risk Allocation",
                        preservation: "Save",
                        pleaseEnter: "Please enter",
                        noActivityForDays: "No activity for days",
                        riskSemanticsWithinDays: "Risk semantics occur within days",
                        addRiskSemantics: "Add Risk Semantics",
                        semanticTags: "Semantic tags:",
                        standardSentence: "Standard Sentence:",
                    },
                },
                components: {
                    businessStage: {
                        index: {
                            changeOpportunityStage: "Change Opportunity Stage",
                            theCurrentStageIs: "The current stage is【",
                            ConfirmToChangeItTo: "】, confirm to change it to【",
                            IsIt: "】Is it?",
                            StageCoStay: "】Stage co stay",
                            day: "day",
                            start: "start",
                            end: "end",
                            orderInput: "Order input",
                            restart: "Restart",
                            pleaseConfigureTheSalesStageInformation:
                "Please configure the sales stage information",
                            reasonForDocumentInput: "Reason for document input:",
                            completeOrderInput: "Complete order input",
                            pleaseSelectTheReason: "Please select the reason",
                        },
                        modal: {
                            customerDynamics: { theManagerSuggests: "The manager suggests:" },
                            index: {
                                sessionDetails: "Session Details",
                                customerDynamics: "Customer Dynamics",
                                riskPoint: "Risk point",
                                followUpSuggestions: "Follow up suggestions",
                            },
                            sessionDetails: {
                                common: "common",
                                currentlyViewingThe: "Currently viewing the",
                                sessions: "Sessions",
                            },
                        },
                    },
                    businessTitle: {
                        index: {
                            theCurrentStageHasStayed: "The current stage has stayed",
                            day: "day",
                        },
                    },
                    business: { index: { reEnableOpportunity: "Re enable Opportunity" } },
                    customerTag: { index: { label: "label" } },
                    moreCustomer: {
                        index: {
                            essentialInformation: "essential information",
                            cancel: "cancel",
                            confirm: "confirm",
                            customer: "customer",
                            currentFollowupStage: "Current follow-up stage",
                            telephone: "Telephone",
                            followUpDays: "Follow up days",
                            day: "day",
                            remarks: "remarks",
                            channelPreference: "Channel preference",
                            hello: "Hello",
                            customFields: "Custom Fields",
                            opportunityAmount: "Opportunity amount",
                            focus: "Focus",
                            customerInformation: "Customer information",
                            viewMoreCustomerInformation: "View more customer information",
                        },
                    },
                    table: {
                        components: {
                            row: {
                                row0: { keyEvents: "Key events:" },
                                row1: {
                                    customerDynamics: "Customer Dynamics",
                                    common: "common",
                                    barDynamic: "Bar dynamic",
                                },
                                row2: {
                                    riskPoint: "Risk point",
                                    common: "common",
                                    risks: "Risks",
                                },
                                row3: {
                                    followUpSuggestions: "Follow up suggestions",
                                    intelligentRecommendation: "Intelligent recommendation",
                                    strip: "strip",
                                    managersSuggestATotalOf: "Managers suggest a total of",
                                    recommendedByManagers: "Recommended by managers",
                                    creationTime: "Creation time",
                                    administratorSuggestions: "Administrator suggestions",
                                    suggestionsForNewManagers: "Suggestions for new managers",
                                },
                            },
                        },
                        index: { typeTime: "Type/Time" },
                    },
                    userInfo: {
                        index: {
                            personInCharge: "person in charge",
                            recentFollower: "Recent Follower",
                            followUpDays: "Follow up days",
                            day: "day",
                            remarks: "remarks",
                        },
                    },
                },
            },
            studyAnalysis: {
                index: {
                    teamMembers: "team members",
                    membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "The total number of sessions a member has learned from others (de-duplication), viewing the session details and clicking on the recording playback is regarded as learning once",
                    totalNumberOfStudies: "Total number of studies",
                    theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "The total number of comments and replies made by members to other people's conversations (de-duplication)",
                    totalNumberOfInteractions: "Total number of interactions",
                    learnTheSumOfConversationCallDuration:
            "Sum of learned conversation call duration",
                    lengthOfStudy: "Study time",
                    totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "The total number of members who have initiated help in other people's conversations (de-duplication)",
                    totalNumberOfRequests: "Total number of requests",
                    totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "The total number of members who have counseled others for help (de-duplication)",
                    totalCounseling: "Total counseling",
                    totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "The total number of members' sessions to others added to the case base (de-duplication)",
                    addCase: "Add case",
                    totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "The total number of subscriptions by members to other people's sessions (de-duplication)",
                    subscribe: "Subscribe",
                    totalNumberOfSessionsCommunicatedByMembers:
            "Total number of sessions communicated by members",
                    totalSessions: "Total number of sessions",
                    theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "The total number of sessions that a member has replayed (de-duplicated), viewing the session details and clicking on the recording playback is regarded as a replay",
                    totalNumberOfDoubleDisk: "Total number of double disks",
                    totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Total number of copies/total number of sessions*100%",
                    proportionOfDoubleDisk: "Report proportion",
                    summationOfConversationCallDurationOfDuplicateDisk:
            "The summation of the duration of the session call duration",
                    resumptionDuration: "Resumption Duration",
                    totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "The total number of requests from members for their own sessions (de-duplication)",
                    theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "The total number of comments (including replies) received from others in the member's conversation",
                    theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "The total number of members' sessions added to the case library by others (de-duplication)",
                    theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "The total number of members' sessions subscribed by others (de-duplication)",
                    askForHelpInTheLastMonth: "Asking for help in the last month",
                    lastTimeForHelp: "The last time for help",
                    operation: "operation",
                    viewAllSessionsInTheLastMonth: "View all sessions in the last month",
                    allDepartments: "All departments",
                    reset: "reset",
                    query: "query",
                    altogether: "total",
                    secondSession: "second session",
                },
            },
            checkPointTest: {
                components: {
                    testSelectComponent: {
                        currentTestItems: "Current test items:",
                        supervisionItem: "Supervision item",
                        keyEvents: "Key events",
                    },
                },
                index: {
                    pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Please check the monitoring items or key events to be tested and ensure that there are calls in the simulated dialogue",
                },
            },
            executionAnalysis: {
                index: { executiveForceAnalysis: "Executive force analysis" },
            },
            konwledgeBase: {
                detail: {
                    components: {
                        richTextList: {
                            process: "Process 1",
                            technologicalProcess: "technological process",
                            newProcess: "Create process",
                        },
                    },
                    index: {
                        upload: "upload",
                        preservation: "preservation",
                        newlyBuild: "newly build",
                        cancel: "cancel",
                        name: "name",
                        describe: "describe",
                        type: "type",
                        classification: "classification",
                        content: "content",
                        enclosure: "enclosure",
                        knowledgePointAssociation: "Knowledge point Association",
                        open: "open",
                        close: "close",
                        operator: "operator",
                        logic: "logic",
                        see: "see",
                        edit: "edit",
                    },
                },
                index: {
                    name: "name",
                    describe: "describe",
                    createdBy: "Created by:",
                    type: "type",
                    lastUpdated: "Last updated:",
                    numberOfReferences: "Number of references",
                    operation: "operation",
                    see: "see",
                    edit: "edit",
                    delete: "delete",
                    categoryDeletedSuccessfully: "Category deleted successfully",
                    classificationOfKnowledgePoints: "Classification of knowledge points",
                    newlyBuild: "newly build",
                    knowledgeBase: "knowledge base",
                    knowledgePoints: "Knowledge points",
                    technologicalProcess: "technological process",
                    keyword: "keyword",
                    batchExport: "Batch export",
                    batchDelete: "Batch delete",
                    importProcess: "Import process",
                    importKnowledgePoints: "Import knowledge points",
                    ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "If the knowledge point does not exist in the knowledge base, add the knowledge point directly; if it exists, skip and do not update",
                    editKnowledgePointClassification:
            "Edit knowledge point classification",
                    newKnowledgePointClassification:
            "Create knowledge point classification",
                    categoryEditingSucceeded: "Category editing succeeded",
                    newClassificationSucceeded: "Create classification succeeded",
                    classificationName: "Classification Name:",
                },
            },
            monitoringReport: {
                index: { executiveSupervision: "Executive supervision" },
            },
            topicCallout: {
                index: {
                    whole: "whole",
                    date: "date",
                    dimensionStatus: "Dimension status",
                    callRecordID: "Call record ID",
                    callRecordIDMustBeNumeric: "Call record ID must be numeric",
                    strip: "strip",
                    topicAnnotation: "Topic annotation",
                    annotated: "Annotated",
                    stripNotMarked: "Strip, not marked",
                    barInDimension: "Bar, in dimension",
                    customer: "customer",
                    sale: "sale",
                    common: "common",
                    sessions: "Sessions",
                    currentTopic: "Current topic:",
                    staff: "staff",
                    topicOfConversation: "topic of conversation",
                    theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "The operation is successful and the dimension has been reset",
                    afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "After reset, all the annotation records of this communication session will be cleared. Please confirm",
                    resetAll: "reset all",
                    pleaseSelectTheTopicToBeMarked:
            "Please select the topic to be marked",
                    pleaseTickTheSentenceToBeMarked:
            "Please tick the sentence to be marked",
                    theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "The operation is successful and the annotation has taken effect",
                    theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "The scope of this annotation includes messages of other topics. Click OK to cover them",
                    confirmDimensions: "Confirm dimensions",
                    talkTime: "Talk time",
                    callID: "Call ID",
                },
            },
            topicList: {
                index: {
                    callID: "Call ID",
                    latestMarkedTime: "Latest marked time",
                    topicOfConversation: "topic of conversation",
                    numberOfSentences: "Number of sentences",
                    sentence: "sentence",
                    operation: "operation",
                    see: "see",
                    delete: "delete",
                    edit: "edit",
                    newSubTopic: "Create sub topic",
                    move: "move",
                    whole: "whole",
                    topicDeletedSuccessfully: "Topic deleted successfully",
                    newlyBuild: "newly build",
                    topicList: "Topic list",
                    callIDMustBeNumeric: "Call ID must be numeric",
                    leadInTopic: "Lead in topic",
                    whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "When the category name is repeated, the original topic category will not be overwritten. This item will be skipped directly and will not be imported",
                    mobileClassification: "Mobile classification",
                    editTopic: "Edit topic",
                    newTopic: "Create topic",
                    topicName: "Topic name:",
                },
            },
            groupTest: {
                detail: {
                    template: {
                        modificationSucceeded: "Modification succeeded",
                        createdSuccessfully: "Created successfully",
                        modificationFailed: "Modification failed",
                        creationFailed: "Creation failed",
                        monitoringName: "Monitoring name",
                        upToCharacters: "Up to 40 characters",
                        pleaseEnterAName: "Please enter a name",
                        monitoringIndicators: "Monitoring indicators",
                        sessionActivity: "Session activity",
                        conversationAction: "Conversation action",
                        keyEvents: "Key events",
                        supervisionItems: "Supervision items",
                        monitoringMembers: "Monitoring members",
                        groupReceivingGuidanceTrainingOrManagementIntervention:
              "Group receiving guidance, training or management intervention",
                        groupA: "Group A",
                        pleaseSelectGroupAData: "Please select Group A data",
                        thereAreDuplicateUsersInGroupB:
              "There are duplicate users in Group B",
                        groupARemarks: "Group A Remarks",
                        controlGroupGroupComparedWithGroupA:
              "Control group, group compared with group A",
                        groupB: "Group B",
                        pleaseSelectGroupBData: "Please select Group B data",
                        thereAreDuplicateUsersInGroupA:
              "There are duplicate users in Group A",
                        groupBRemarks: "Group B Remarks",
                        monitoringPeriod: "Monitoring period",
                        startNode: "Start Node",
                        monitoringScope: "Monitoring scope",
                        pleaseSelectMonitoringConditions:
              "Please select monitoring conditions",
                        pleaseEnterANumber: "please enter a number",
                        pleaseSelectATimePeriod: "Please select a time period",
                        preservation: "preservation",
                        cancel: "cancel",
                    },
                },
                list: {
                    template: {
                        deletionSucceeded: "Deletion succeeded",
                        notStarted: "Not Started",
                        haveInHand: "have in hand",
                        closed: "Closed",
                        newGroupTest: "New Group Test",
                    },
                },
            },
            businessConfiguration: {
                salesStage1: "Sales stage",
                communicationStrategy1: "Communication strategy",
                riskAllocation: "Risk allocation",
                businessConfiguration: "Business configuration",
                communicationStrategy: {
                    detail: {
                        components: {
                            pushRichText: {
                                index: {
                                    objectionHandlingScript: "Objection Handling Script",
                                    productSellingPointScript: "Product Selling Point Script",
                                    caseGrouping: "Case grouping",
                                    pleaseSelectExcellentCases: "Please select excellent cases",
                                    pleaseSelectKnowledgePoints: "Please select knowledge points",
                                    associativeLinks: "Associative Links",
                                    jumpTo: "Jump to",
                                    jumpType: "Jump Type",
                                    knowledgePoints: "Knowledge points",
                                    excellentCases: "Excellent cases",
                                    excellentScript: "Excellent Script",
                                    pleaseSelectASingleKnowledgePoint:
                    "Please select a single knowledge point",
                                },
                            },
                        },
                        index: {
                            name: "name",
                            pushText: "Push Text",
                            enableStatus: "Enable Status",
                            triggerRules: "Trigger Rules",
                            communicationStrategy: "Communication strategy",
                        },
                    },
                    list: {
                        index: {
                            communicationStrategyGrouping: "Communication strategy grouping",
                            name: "name",
                            pushText: "Push Text",
                            effectiveness: "Effectiveness",
                            openSuccessfully: "Open successfully",
                            closeSuccessfully: "Close successfully",
                            operationFailed: "operation failed",
                            operation: "operation",
                            edit: "edit",
                            delete: "delete",
                            newlyBuild: "newly build",
                            communicationStrategy: "Communication strategy",
                            editGroup: "Edit Group",
                            groupName: "Group Name",
                        },
                    },
                },
                riskStatement: {
                    detail: {
                        components: {
                            riskSettingRule: {
                                index: {
                                    allSessions: "All Sessions",
                                    recentDays: "Recent Days",
                                    recentSessions: "Recent Sessions",
                                    scopeOfApplication: "Scope of application:",
                                    day: "day",
                                    through: "through",
                                },
                            },
                        },
                        index: {
                            describe: "describe",
                            applicableCustomers: "Applicable customers",
                            enableStatus: "Enable Status",
                            triggerRules: "Trigger Rules",
                            riskRules: "Risk rules",
                        },
                    },
                    list: {
                        index: {
                            riskAllocation: "Risk allocation",
                            keyWord: "key word",
                            newRiskRule: "New Risk Rule",
                            riskRuleName: "Risk rule name",
                            describe: "describe",
                            state: "state",
                            openSuccessfully: "Open successfully",
                            closeSuccessfully: "Close successfully",
                            operationFailed: "operation failed",
                            operation: "operation",
                            edit: "edit",
                            delete: "delete",
                            deletionSucceeded: "Deletion succeeded",
                        },
                    },
                },
                salesStage: {
                    detail: {
                        components: {
                            alias: { orderInput: "Order input" },
                            endReasonList: {
                                addEndReason: "Add End Reason",
                                enterEndReason: "Enter end reason",
                            },
                            stageList: {
                                keyEvents: "Key events",
                                waysideData: "Wayside data",
                                includeAny: "Include any",
                                includeAll: "Include All",
                                pleaseEnterTheStageName: "Please enter the stage name",
                                setStageRules: "Set Stage Rules",
                                stageName: "Stage name",
                                operator: "operator",
                                logic: "logic",
                            },
                        },
                        index: {
                            theInputFormatOfTheTimeTypeFieldIs:
                "The input format of the time type field is: 2022-12-31 00:00:00",
                            requestFailedPleaseTryAgainLater:
                "Request failed, please try again later",
                            salesStage: "Sales stage",
                            essentialInformation: "essential information",
                            name: "name",
                            applicableDepartments: "Applicable departments",
                            applicableToOthers: "Applicable to others",
                            manualChangePhase: "Manual Change Phase",
                            enableStatus: "Enable Status",
                            describe: "describe",
                            opportunityStageSettings: "Opportunity Stage Settings",
                            stageSettings: "Stage Settings",
                            aliasSettings: "Alias Settings",
                            closeOpportunity: "Close Opportunity",
                            newSuccessfully: "New successfully",
                            newFailed: "New Failed",
                            savingSucceeded: "Saving succeeded",
                            saveFailed: "Save failed",
                        },
                    },
                    list: {
                        index: {
                            salesStage: "Sales stage",
                            keyWord: "key word",
                            newSalesStage: "New Sales Stage",
                            salesStageName: "Sales Stage Name",
                            describe: "describe",
                            state: "state",
                            openSuccessfully: "Open successfully",
                            closeSuccessfully: "Close successfully",
                            operationFailed: "operation failed",
                            operation: "operation",
                            edit: "edit",
                            delete: "delete",
                            deletionSucceeded: "Deletion succeeded",
                        },
                    },
                },
            },
            salesBusinessConfigurationEntry: {
                salesStage: "Sales stage",
                communicationStrategy: "Communication strategy",
                riskAllocation: "Risk allocation",
                businessConfiguration: "Business configuration",
            },
            salesLearningCenter: {
                knowledgeBase: "knowledge base",
                speechCorpus: "Speech corpus",
                learningCenter: "Learning Center",
            },
        },
        intelligentAnalysis: {
            analysisReportTitle: "Analysis report",
            ruleTest: "Rule test",
            intelligentTags: {
                supervisionItem: "Supervision item",
                customerLabel: "Customer label",
                newSupervisionItemRule: "Create supervision item rule",
                editSupervisionItemRule: "Edit supervision item rule",
                newCustomerLabel: "Create customer label",
                editCustomerLabel: "Edit customer label",
                newSessionLabel: "Create session label",
                editSessionLabel: "Edit session label",
                newKeyEvent: "Create key event",
                editKeyEvents: "Edit key events",
                detail: {
                    template: { nameIsARequiredField: "Name is a required field" },
                },
                template: {
                    advancedFiltering: "Advanced filtering",
                    dataType: "data type",
                    filterCriteria: "Filter criteria",
                    edit: "edit",
                    createANewSubcategory: "Create a new subcategory",
                    delete: "delete",
                    nameLengthCannotExceed: "Name length cannot exceed 40",
                },
                component: {
                    standardScreening: "Standard screening",
                    advancedFiltering: "Advanced filtering",
                },
            },
            businessAnalysis: {
                detail: {
                    businessAnalysisFiter: {
                        component: {
                            keyWord: "key word",
                            sessionLabel: "Session label",
                            keyEvents: "Key events",
                            matchAny: "Match any",
                            matchAll: "Match all",
                            beEqualTo: "be equal to",
                            includeAll: "Include all",
                            includeAny: "Include any",
                            advancedConditions: "Advanced conditions",
                            smartTag: "Smart tag",
                            smartTagI: "Smart tag I",
                            smartTagII: "Smart tag II",
                        },
                        template: {
                            pleaseSelectSmartTag: "Please select smart tag",
                            pleaseSelectASessionLabel: "Please select a session label",
                            pleaseSelectAKeyEvent: "Please select a key event",
                            pleaseSelectAdvancedCondition: "Please select advanced condition",
                            newAdvancedFilter: "Create advanced filter",
                            moveUp: "Move up",
                            moveDown: "Move down",
                            moveLeft: "Move left",
                            shiftRight: "Shift right",
                        },
                    },
                    component: {
                        businessAnalysispng: "Business analysis.png",
                        savedSuccessfully: "Saved successfully",
                    },
                },
            },
            supervision: {
                detail: {
                    index: {
                        permanentlyValid: "Permanently valid",
                        permanentlyInvalid: "Permanently invalid",
                        customValidity: "Custom validity",
                        termOfValidity: "term of validity",
                        name: "name",
                        explain: "explain",
                        state: "state",
                        classification: "classification",
                        smartRecommendation: "Smart recommendation",
                        preconditions: "Preconditions",
                        SetPreconditionsForThisSupervisionItem:
              "1. Set preconditions for this supervision item:",
                        whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'When the preconditions are met, it is the "supervision item to be implemented", and then the supervision item shall be tested;',
                        ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "If the preconditions are not met, the supervision item will not be detected;",
                        NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. No preconditions are set, that is, all sessions are detected by default",
                        have: "have",
                        nothing: "nothing",
                        preconditionRule: "Precondition rule",
                        preconditionLogic: "Precondition logic",
                    },
                    recommendation: {
                        caseGrouping: "Case grouping",
                        whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "When employees fail to perform well, they can intelligently recommend excellent scripts and excellent cases",
                        smartRecommendation: "Smart recommendation",
                        open: "open",
                        close: "close",
                        whenThisSupervisionItem: "When this supervision item",
                        implement: "implement",
                        unexecuted: "unexecuted",
                        when: "When:",
                        recommendedKnowledgePoints: "Recommended knowledge points",
                        caseRecommendation: "Case recommendation",
                    },
                },
                index: {
                    determine: "determine",
                    sort: "sort",
                    sortSucceeded: "Sort succeeded",
                    supervisionItemClassification: "Supervision item classification",
                    pressEnterToCreateANew: "Press enter to create a new",
                    nameCannotBeEmpty: "Name cannot be empty",
                    newlyBuild: "newly build",
                    name: "name",
                    explain: "explain",
                    validStatus: "Valid status:",
                    permanentlyValid: "Permanently valid",
                    permanentlyInvalid: "Permanently invalid",
                    operation: "operation",
                    edit: "edit",
                    batchDelete: "Batch delete",
                    newSupervisionItem: "Create supervision item",
                    keyWord: "key word",
                    delete: "delete",
                    deleteSucceeded: "Delete succeeded",
                },
            },
            analysisReport: {
                components: {
                    list: {
                        index: {
                            theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "The data cannot be recovered after deletion. Are you sure you want to delete it?",
                        },
                    },
                    businessAnalysisFiter: {
                        component: {
                            smartTag: "Smart tag",
                            beEqualTo: "be equal to",
                            includeAll: "Include All",
                            includeAny: "Include any",
                            matchAny: "Match Any",
                            matchAll: "Match All",
                            smartTagI: "Smart Tag I",
                            smartTagII: "Smart tag II",
                        },
                        template: {
                            pleaseSelectASmartTag: "Please select a smart tag",
                            pleaseSelectAdvancedConditions:
                "Please select advanced conditions",
                            newAdvancedFilter: "New Advanced Filter",
                            rename: "rename",
                            moveUp: "Move Up",
                            moveDown: "Move Down",
                            shiftLeft: "Shift Left",
                            shiftRight: "Shift Right",
                        },
                    },
                },
                template: {
                    delete: "delete",
                    labelFiltering: "Label filtering:",
                    satisfyAny: "Satisfy any",
                    meetAll: "Meet all",
                    theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "The number of conversations of secondary category labels ≥ primary category, and a session may contain multiple labels",
                    calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Calculation logic of month on month change: (conversation volume of the selected time period - conversation volume of the immediately preceding time period)/conversation volume of the last time period",
                    proportionOfPrimaryClassification:
            "Proportion of primary classification",
                    saveAs: "Save As",
                    cover: "cover",
                    preservation: "preservation",
                    qualityInspectionTask: "Quality inspection task:",
                    query: "query",
                    changeFromMonthToMonth: "Change from month to month",
                    tagRanking: "Tag Ranking",
                    proportion: "Proportion",
                },
                component: {
                    numberOfConversations: "Number of conversations",
                    operation: "operation",
                    businessAnalysispng: "Business Analysis.png",
                    savingSucceeded: "Saving succeeded",
                    deletionSucceeded: "Deletion succeeded",
                    sequence: "sequence",
                    firstLevelClassification: "First level classification",
                    ringRatioChange: "Ring ratio change",
                    classClassification: "Class classification",
                },
            },
            intelligentExtractors: {
                components: {
                    pageLeftTable: {
                        areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Are you sure to delete the document and its extracted content?",
                        addKnowledgeBase: "Add Knowledge Base",
                        uploadDocument: "Upload Document",
                        supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Supports Word, PDF, PowerPoint, Excel, and txt format files",
                        taskName: "Task Name",
                        extractConversationDate: "Extract conversation date",
                        pleaseSelectAConversationDate: "Please select a conversation date",
                        dataSource: "data source",
                        dataType: "data type",
                    },
                    pageRightTable: {
                        index: {
                            problem: "problem",
                            answer: "answer",
                            addToKnowledgeBase: "Add to Knowledge Base",
                            pleaseConfirmWhetherToDeleteIt:
                "Please confirm whether to delete it",
                        },
                        pageRightFrom: {
                            addToKnowledgeBase: "Add to Knowledge Base",
                            selectClassification: "Select Classification",
                            pleaseSelectAKnowledgeBaseClassification:
                "Please select a knowledge base classification",
                        },
                    },
                },
                index: {
                    intelligentExtraction: "Intelligent extraction",
                    documentExtraction: "Document extraction",
                    dialogueExtraction: "Dialogue extraction",
                },
            },
        },
        logoSite: {
            index: {
                intelligentQualityInspection: "Intelligent Quality Inspection",
                savedSuccessfully: "Saved successfully",
                logoSettings: "logo settings",
                default: "default",
                custom: "custom",
                title: "Title",
                theTitleOfTheSystemDisplayShouldBeWithinWords:
          "The title name displayed by the system, it is recommended to be within 6 words. ",
                logoImageUpload: "logo image upload",
                pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Please select an image whose size does not exceed 2048kb",
                inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "In order to ensure the logo browsing effect, upload pictures suggestions:",
                Size5050Pixels: "1. Size: 50*50 pixels;",
                FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Format: picture with transparent background, png or jpg, logo graphics in white",
                browserLabelIcon: "Browser label icon",
                toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "In order to ensure the tag browsing effect, upload picture suggestions:",
                Size3232Pixels: "1. Size: 32*32 pixels;",
                FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Format: picture with transparent background, png or jpg",
            },
        },
        sdkTest: {
            index: {
                qualityInspectionInProgressPleaseCheckLater:
          "Quality inspection is in progress, please wait...",
            },
            componentIndex: {
                atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "At that time in 2015, China's real estate reached a critical moment for its survival. A large number of houses were left unattended and the people were unwilling to buy them. Hangzhou once fell below 8000, and some developers failed to ship. At that time, Shenzhen was still about 2w-3w consolidation, and the houses in the first tier cities were also in danger",
                xiaoAn: "Xiao an",
                storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload these memory barriers are called JSR memory barriers. They are implemented at the JVM level. There are two ways to implement them at the bottom of the operating system: 1. Lock bus; 2. Memory barrier meta language, such as sfance (write barrier), mfence (full screen barrier) and ifence (read barrier). The bottom layer of the hotspots virtual machine uses an assembly statement: lock ADDL. Lock is an empty operation, that is, lock bus. This is because some operating systems do not support memory barrier meta language.",
                xiaobei: "Xiaobei",
                TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Try to marinate the meat for several times. The dishes fried after adding the ingredients and wine are like rotten meat, with a sour smell! Since then, no cooking wine has been added to the bacon",
                xiaodong: "Xiaodong",
                xiaoxi: "Xiaoxi",
                TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Try to marinate the meat for several times. The dishes fried after adding the wine are like rotten meat, with a sour smell! Since then, no cooking wine has been added to the bacon",
            },
        },
        home: {
            index: {
                taskList: "Task list",
                videoAnalysis: "Video analysis",
                overview: "Overview",
                personalSignage: "Personal signage",
                learningCenter: "Learning Center",
                wrongQuestionSet: "Wrong question set",
                abilityImprovement: "Ability improvement",
                close: "close",
                switchingSystemApplications: "Switching system applications",
                moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Move the mouse here to call up the application switching menu. Click the menu item to jump to different applications",
                teamAnalysis: "Team analysis",
                businessConfiguration: "Business configuration",
                semanticIntelligence: "Semantic intelligence",
                permissionManagement: "Permission management",
                dataConnection: "Data connection",
                courseManagement: "Course management",
                labelManagement: "Label management",
                curriculum: "Curriculum center",
                courseConfiguration: "Course configuration",
                task: "My task",
                historicalLearningData: "Historical learning data",
                taskCenter: "Task management",
                knowledgeBase: "knowledge base",
                ecommerceSessionManagement: "ecommerce session management",
            },
            components: {
                subAppEntries: {
                    applicationSwitching: "Application switching",
                    descriptionOfIntelligentQualityInspectionSystem:
            "Description of intelligent quality inspection system",
                },
            },
        },
        eCommerceManage: {
            template: {
                ecommerceSessionFilter: "Ecommerce Session Filter",
                allEcommerceSessions: "All Ecommerce Sessions",
                ecommerceSessionManagement: "Ecommerce Session Management",
            },
        },
        analysisEnable: {
            bestPractices: "Best Practices",
            behaviorAnalysis: "behavior analysis ",
            teamAnalysis: "Team analysis",
            executiveSupervision: "Executive supervision",
            executiveForceAnalysis: "Executive force analysis",
        },
        appealCheckingTask: {
            sdk: {
                index: {
                    grade: "grade:",
                    startTime: "start time",
                    workOrderNo: "Work order No",
                    customerServiceHasReadNotRead: "Agent has read / not read",
                    canYouAppeal: "Can you appeal",
                },
            },
        },
        clientCenter: {
            wechatClientManage: {
                template: {
                    enterpriseAndMicroNotes: "Enterprise and micro notes",
                    addFriendTime: "Add friend time",
                },
            },
        },
        qualityInspectionList: {
            detail: {
                component: {
                    startPerformingReQualityInspection:
            "Start performing re quality inspection",
                    callID: "Call ID",
                },
                template: {
                    newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Create features! Click the 'quick spot check' button, and after the submission is successful, it will automatically jump to the next page~~~",
                },
            },
            template: {
                reIdentification: "Re identification",
                rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Re-recognition will consume transcription time, please operate with caution",
                determine: "determine",
                executionSuccessful: "Execution successful",
            },
            component: {
                startPerformingReQualityInspection:
          "Start performing re quality inspection",
            },
        },
        recordUpload: {
            template: {
                uploadFileName: "Upload file name",
                analysisTask: "Analysis task",
                analyzeProgress: "Analyze progress",
                analysisSucceeded: "Analysis succeeded",
                analysisFailed: "Analysis failed",
                localDataUpload: "Local data upload",
                recordingUpload: "Recording upload",
                textUpload: "Text upload",
                uploadTime: "Upload time:",
                taskName: "Task name:",
                operator: "Operator",
                qualityInspectionProgress: "Quality inspection progress",
                whole: "whole",
                haveInHand: "have in hand",
                completed: "Completed",
                exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Export all completed data records under the current filter condition?",
                upload: "upload",
            },
            detail: {
                info: {
                    template: {
                        fillInTheName: "Fill in the name",
                        fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Fill in the name, such as the type/set/purpose of the imported data",
                        default: "default",
                        jDCOM: "JD.COM",
                        dataType: "data type",
                        selectAnalysisTask: "Select Analysis Task",
                        uploadFiles: "Upload files",
                        DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Download excel template and fill in according to the format:",
                        template: "Template",
                        SupportUpTo10000PiecesOfData:
              "2. Support up to 10000 pieces of data",
                        supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Support the upload of dialog data exported by JD,. txt file, with a maximum file size of 100M",
                        alongTheWayDataTemplate: "Along the way data template",
                    },
                },
                result: {
                    template: {
                        textUnderInspection: "Text under inspection",
                        textQualityInspectionCompleted: "Text quality inspection completed",
                    },
                },
                template: {
                    startAnalysis: "Start Analysis",
                    pleaseUploadTheFile: "Please upload the file",
                    localDataUpload: "Local data upload",
                },
            },
        },
        reviewList: {
            detail: {
                template: {
                    newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            'Create features! Click the "review and submit" button, and the page will automatically jump to the next page after the submission is successful~~~',
                },
            },
        },
        role: {
            detail: {
                taskPermissions: {
                    template: {
                        qualityInspectionTaskPermission:
              "Quality Inspection Task Permission",
                        edit: "edit",
                        iParticipated: "I participated",
                    },
                },
                dataPermissions: {
                    template: {
                        thisRoleCanSeeDataUnderTheSelectedDepartment:
              "This role can see data under the selected department",
                        dataPermissions: "Data permissions",
                        selectDepartment: "Select Department",
                        edit: "edit",
                    },
                },
            },
        },
        spotCheckList: {
            detail: {
                component: {
                    startPerformingReQualityInspection:
            "Start performing re quality inspection",
                    callID: "Call ID",
                },
                template: {
                    newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Create features! Click the 'sample inspection submission' and 'quick sample inspection' buttons. After the submission is successful, it will automatically jump to the next page~~~",
                },
            },
        },
        templateTest: { semanticTags: { template: { rule: "rule" } } },
        ticketManage: {
            detail: {
                template: {
                    workOrderManagement: "Work order management",
                    workOrderDetails: "Work order details",
                },
            },
            template: {
                workOrderFilter: "Work order filter",
                allWorkOrders: "All work orders",
                workOrderManagement: "Work order management",
            },
        },
        trainingCenter: {
            topicAnnotation: "Topic annotation",
            topicList: "Topic list",
            clusteringAnnotation: {
                components: {
                    filter: {
                        index: {
                            roleType: "Role Type",
                            keyWord: "key word",
                            pleaseEnterKeywordSearch: "Please enter keyword search",
                            query: "query",
                        },
                    },
                },
            },
        },
        adminEntry: {
            businessWorkingHours: "Business working hours",
            localDataUpload: "Local data upload",
            assembleMenuListAccordingToCurrentUserPermissions:
        "Assemble menu list according to current user permissions",
            sessionSettings: "Session settings",
            logoSettings: "Logo settings",
        },
        login: {
            template: {
                welcomeBackPleaseEnterYourDetails:
          "welcome back! Please enter your details",
                mailbox: "mailbox",
                password: "password",
                signIn: "Sign in",
            },
        },
        checkPointTest: {
            index: {
                ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Rule test: some rules cannot be tested using rules, such as voice speed, volume, and on the road data",
            },
        },
        appEntry: {
            components: {
                subSystemEntryCard: {
                    learnMore: "Learn more",
                    enteringTheSystem: "Entering the system",
                },
            },
            index: {
                close: "close",
                enterSystemApplication: "Enter system application",
                clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Click "Enter System" to jump to the enabled system application',
            },
        },
        permissionManagement: {
            employeeManagement: "Employee management",
            roleManagement: "Role management",
            permissionManagement: "Permission management",
        },
        generalSetting: {
            docking: {
                components: {
                    ruleList: {
                        beEqualTo: "be equal to",
                        contain: "contain",
                        doesNotContain: "Does not contain",
                        applicationSystem: "application system",
                        intelligentQualityInspection:
              "Intelligent quality inspection/intelligent session analysis",
                        salesEmpowerment: "Sales empowerment",
                        dataSourceType: "Data source type",
                        filterCriteria: "Filter Criteria",
                    },
                },
                index: {
                    dataConnection: "Data connection",
                    savingSucceeded: "Saving succeeded",
                    dataIsolation: "Data isolation",
                    afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "After opening, you can set the data range of each application system access call and enterprise WeChat session. If the switch is not turned on, all systems are connected to full data",
                    accessRules: "Access rules",
                    preservation: "preservation",
                },
            },
            permissionManagement: {
                role: {
                    index: {
                        applyName: "apply name",
                        accessRights: "Access rights",
                        inAppPermissionSettings: "In app permission settings",
                        setUp: "set up",
                        applicationAccess: "Application Access",
                        newRole: "New Role",
                    },
                },
            },
        },
        wechatNotificationAnalysis: {
            conversationalAnalysis: { index: { sessionDetails: "Session Details" } },
            messageNotification: { index: { sessionDetails: "Session Details" } },
        },
        coach: {
            courseManagement: {
                baseInfo: {
                    index: {
                        visibleEmployees: "Visible Employees",
                        newCourse: "New Course",
                        editCourse: "Edit Course",
                        courseName: "Course Name",
                        courseIntroduction: "Course Introduction",
                        courseType: "Course Type",
                        courseClassification: "Course classification",
                        courseCover: "Course Cover",
                        learningContent: "Learning content",
                        uploadLearningMaterials: "Upload learning materials",
                    },
                },
                flow: {
                    components: {
                        configDrawer: {
                            preservation: "preservation",
                            cancel: "cancel",
                            editNodeName: "Edit node name",
                            determine: "determine",
                            individual: "individual",
                            of: "Of",
                            branch: "branch",
                        },
                        configDrawerComponents: {
                            pPTParsing: "PPT parsing",
                            uploadSuccessful: "Upload successful",
                            uploadAttachments: "Upload attachments",
                            pPTParsingPleaseWait: "PPT parsing, please wait",
                        },
                        customerPortraitConfigDrawer: {
                            cancel: "cancel",
                            afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "After deletion, it cannot be restored. Are you sure you want to delete this portrait?",
                            delete: "delete",
                            determine: "determine",
                            customerPortrait: "Customer Portrait",
                            bindSelectedCharacterPortrait: "Bind Selected Character Portrait",
                            customerProfileDetails: "Customer profile details",
                            createANewCustomerProfile: "Create a new customer profile",
                        },
                        flow: {
                            sidebar: { node: "node" },
                            useControlButtons: {
                                cancellingPleaseWait: "Cancelling, please wait",
                                redoInProgressPleaseWait: "Redo in progress, please wait",
                                automaticOrganization: "Automatic organization",
                                canvasAdaptation: "Canvas Adaptation",
                                delete: "delete",
                            },
                            useFlow: {
                                nodeCannotBeConnectedToItself:
                  "Node cannot be connected to itself",
                            },
                        },
                        globalConfigDrawer: {
                            cancel: "cancel",
                            determine: "determine",
                            globalConfiguration: "Global Configuration",
                            sessionDetection: "Session detection",
                            reciprocalRule: "reciprocal rule",
                            robotVoice: "Robot Voice",
                        },
                        interactionRuleForm: {
                            studentNodeTimeoutWithoutAnsweringSetting:
                "Student node timeout without answering setting",
                            maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Maximum allowed silence time for students not answering (s)",
                            promptScriptForFailureToAnswerOverTime:
                "Prompt Script for Failure to Answer Over Time",
                            addScript: "Add Script",
                        },
                        robotTimbreForm: {
                            aixianatural: "Aixia (natural)",
                            zhishaChat: "Zhisha (Chat)",
                            zhiqiangentle: "Zhiqian (gentle)",
                            zhimiEmotion: "Zhimi (Emotion)",
                            aiShuoNatural: "Ai Shuo (Natural)",
                            zhishuoChat: "Zhishuo (Chat)",
                            aiHaoAffinity: "Ai Hao (Affinity)",
                            zhixiangEmotion: "Zhixiang (Emotion)",
                            robotVoice: "Robot Voice",
                            schoolboy: "schoolboy",
                            girlStudent: "girl student",
                            volume: "volume",
                            tone: "tone",
                            speechSpeed: "Speech speed",
                            test: "test",
                            helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Hello, I am an intelligent training coach and look forward to communicating with you",
                        },
                        sessionDetectionForm: {
                            expressionDetection: "Expression detection",
                            excessiveDetectionOfModalParticles:
                "Excessive detection of modal particles",
                            theNumberOfRepetitionsIs: "The number of repetitions is",
                            minus: "Minus",
                            appear: "appear",
                            subtract: "Subtract",
                            branch: "branch",
                            addRules: "Add rules",
                            includingNegativeAngryComplaining:
                "(including negative, angry, complaining)",
                            scorePointsDisplay: "Score points display",
                            reminderOfSpeakingTooFasttooSlow:
                "Reminder of speaking too fast/too slow",
                            scriptViolationReminder: "Script violation reminder",
                            emotionalReminder: "Emotional Reminder",
                            speechSpeedDetection: "Speech speed detection",
                            normalSpeechSpeedScore: "Normal speech speed score",
                            speakTooFast: "Speak too fast",
                            everyMinuteGreaterThan: "Every minute greater than",
                            aWordAppears: "A word appears",
                            speakTooSlowly: "Speak too slowly",
                            lessThanPerMinute: "Less than per minute",
                            ignoreLessThan: "Ignore less than",
                            aSentenceOfWords: "A sentence of words",
                            emotionalDetection: "Emotional detection",
                            emotionalNormalScore: "Emotional Normal Score",
                            negativeEmotions: "Negative emotions",
                            illegalWordDetection: "Illegal word detection",
                            scriptViolationConfiguration: "Script violation configuration",
                            realTimePromptOnTheFrontEnd: "Real time prompt on the front end",
                        },
                        const: {
                            nodeConfigs: {
                                knowledgeNode: {
                                    knowledgeBaseQA: "Knowledge Base Q&A",
                                    qAMethod: "Q&A method",
                                    numberOfQuestionsAndAnswers:
                    "Number of questions and answers",
                                    scoringRules: "Scoring Rules",
                                    ifAnyQuestionAndAnswerIsCorrect:
                    "If any question and answer is correct",
                                    branch: "branch",
                                    successfullySaved: "Successfully saved",
                                },
                                pptNode: {
                                    uploadSlide: "Upload Slide",
                                    assessmentMethod: "Assessment method",
                                    associateSlide: "Associate Slide",
                                    standardScript: "Standard Script",
                                    successfullySaved: "Successfully saved",
                                },
                                robotNode: {
                                    robotScript: "Robot Script",
                                    randomScript: "Random Script",
                                    chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Choose a script with similar semantics, and the robot will randomly say one sentence",
                                    customerPortrait: "Customer Portrait",
                                    whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "When the customer's portrait is consistent, the robot says this script",
                                    setAsDefaultScript: "Set as default script",
                                    yes: "yes",
                                    no: "no",
                                    useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Use this script when the robot misses any user tags",
                                    successfullySaved: "Successfully saved",
                                    thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "This branch node already has a default script. Do you want to replace the default script with the current script?",
                                },
                                startNode: {
                                    paragon: "Paragon",
                                    successfullySaved: "Successfully saved",
                                },
                                userNode: {
                                    standardScript: "Standard Script",
                                    successfullySaved: "Successfully saved",
                                },
                            },
                        },
                    },
                    index: {
                        editBasicInformation: "Edit basic information",
                        test: "test",
                        release: "release",
                    },
                },
            },
            customerTagsManagement: {
                components: {
                    addTag: { index: { newLabel: "new label" } },
                    tag: {
                        index: {
                            afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "After deletion, it cannot be restored. Are you sure you want to delete this label?",
                            delete: "delete",
                            cancel: "cancel",
                        },
                    },
                },
                index: {
                    labelManagement: "Label management",
                    newGroup: "New Group",
                    edit: "edit",
                    afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "After deletion, it cannot be restored. Are you sure you want to delete this label group?",
                    delete: "delete",
                    cancel: "cancel",
                    editGroup: "Edit Group",
                    labelGroupName: "Label Group Name",
                },
            },
            learningCenter: {
                components: {
                    camera: {
                        index: {
                            cameraDisabled: "Camera disabled",
                            noCameraDevicesFound: "No camera devices found",
                        },
                    },
                    cue: {
                        index: {
                            referenceScript: "Reference Script",
                            mattersNeedingAttention: "matters needing attention",
                            examFrequency: "Exam frequency",
                            section: "Section",
                            timestotal: "Times/total",
                            second: "second",
                            requirementsForPassingTheExam:
                "Requirements for passing the exam",
                            examScore: "Exam score ≥",
                            branch: "branch",
                        },
                    },
                    customerInfo: {
                        index: {
                            thereIsCurrentlyNoPortraitAvailable:
                "There is currently no portrait available",
                            customerBackground: "Customer Background",
                            customerLabel: "Customer label",
                        },
                    },
                    flow: {
                        index: {
                            processReminder: "Process Reminder（",
                            score: "score",
                            TotalScore: "/Total score",
                        },
                    },
                    index: {
                        taskName: "Task Name",
                        taskStatus: "Task Status",
                        whole: "whole",
                        completionStatus: "Completion status",
                        courseClassification: "Course classification",
                        practiceMode: "Practice mode",
                        examMode: "Exam mode",
                        practiceTime: "Practice time",
                    },
                    ppt: {
                        index: {
                            nextPage: "next page",
                            tips: "Tips",
                            continueWithCurrentPage: "Continue with current page",
                            goToTheNextPage: "Go to the next page",
                            afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "After entering the next page, you will not be able to return to the previous page. Please confirm that the explanation on the current page is complete before jumping to the next page",
                            clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Clicking on the next page will no longer cause this prompt to pop up",
                        },
                        list: { index: { slide: "slide" } },
                    },
                    record: {
                        index: {
                            unableToRecord: "Unable to record:",
                            creatingARecorderService: "Creating a Recorder Service",
                            turnOffTheRecorderService: "Turn off the Recorder service",
                            theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "The current client is already connected to the ASR Websocket server",
                            afterProcessingTheLastFrameRestoreTheState:
                "After processing the last frame, restore the state",
                            aSRConnectionClosed: "ASR connection closed",
                            recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "Recording paused and has been processed, but there is still data in the cache",
                            foundASRChannelClosedRecreateWebsocketLink:
                "Found ASR channel closed, recreate Websocket link",
                            foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Found that the ASR channel is not open, recreate the Websocket link",
                        },
                        ppt: { hook: { index: { serverError: "Server error" } } },
                        text: {
                            index: {
                                pleaseEnter: "Please enter",
                                sendOut: "send out",
                                startPracticing: "Start practicing",
                                startTheExam: "Start the exam",
                            },
                        },
                        util: {
                            recorded: "Recorded",
                            BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16 bit pcm SendFrameSize must be an integer multiple of 2",
                            pCMSamplingRateShouldNotOccur:
                "PCM sampling rate should not occur",
                            andTheRequiredSamplingRate: "And the required sampling rate",
                            atypism: "atypism",
                            errorsThatShouldNotOccur: "Errors that should not occur:",
                            spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<span style="color: red">Data with disordered order can be directly discarded if the requirements are not high</span>',
                            flower: "flower",
                            TransferStopped: ": Transfer stopped",
                        },
                        video: { index: { camera: "camera" } },
                        voice: {
                            hook: {
                                bat: {
                                    index: {
                                        theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "The current client is already connected to the ASR Websocket server",
                                        aSRConnectionClosed: "ASR connection closed",
                                        foundASRChannelClosedRecreateWebsocketLink:
                      "Found ASR channel closed, recreate Websocket link",
                                        unableToRecord: "Unable to record:",
                                        aSRChannelClosedReopened: "ASR channel closed, reopened",
                                        theASRChannelWasNotCreatedStartingNow:
                      "The ASR channel was not created, starting now",
                                        useEffectCallbackToClearTheASRChannel:
                      "UseEffect callback to clear the ASR channel",
                                        creatingARecorderService: "Creating a Recorder Service",
                                        turnOffTheRecorderService: "Turn off the Recorder service",
                                    },
                                    util: {
                                        recorded: "Recorded",
                                        BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16 bit pcm SendFrameSize must be an integer multiple of 2",
                                        pCMSamplingRateShouldNotOccur:
                      "PCM sampling rate should not occur",
                                        andTheRequiredSamplingRate:
                      "And the required sampling rate",
                                        atypism: "atypism",
                                        errorsThatShouldNotOccur: "Errors that should not occur:",
                                        spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<span style="color: red">Data with disordered order can be directly discarded if the requirements are not high</span>',
                                        flower: "flower",
                                        TransferStopped: ": Transfer stopped",
                                    },
                                },
                            },
                            index: {
                                startRecording: "Start recording",
                                endRecording: "End recording",
                                startPracticing: "Start practicing",
                                startTheExam: "Start the exam",
                            },
                        },
                    },
                    taskMessageModal: {
                        index: {
                            totalScriptScore: "Total Script Score:",
                            totalEmotionalScore: "Total emotional score:",
                            totalScoreOfExpression: "Total score of expression:",
                            theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "The current task rating result needs to be manually reviewed. After the review is completed, you will be notified of the final score. Please be patient and wait",
                            areYouSureToSubmit: "Are you sure to submit?",
                            theExerciseHasEndedAreYouSureToSubmit:
                "The exercise has ended. Are you sure to submit?",
                            theExamHasEndedAreYouSureToSubmit:
                "The exam has ended. Are you sure to submit?",
                            completed: "Completed",
                            page: "page",
                            pageCount: "PageCount ",
                            topic: "topic",
                            totalNumberOfQuestions: "Total number of questions",
                            totalScore: "Total score",
                            branch: "branch",
                            accumulatedCompletionProgress: "Accumulated completion progress",
                            accumulatedPracticeDuration: "Accumulated practice duration",
                            lastPauseExerciseResults: "Last Pause - Exercise Results",
                            exerciseInstructions: "Exercise Instructions",
                            examInstructions: "Exam Instructions",
                            halfwayPausePracticeResults: "Halfway Pause - Practice Results",
                            tips: "Tips",
                            afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "After exiting, this exercise will not be counted towards the grade. Are you sure to exit?",
                            exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Exit is not allowed midway through the exam. Are you sure to submit?",
                            pleaseOperateWithCaution: "Please operate with caution",
                            restart: "restart",
                            returnToLearning: "Return to Learning",
                            continuePracticing: "Continue practicing",
                            continuingTheExam: "Continuing the exam",
                            return: "return",
                            notSubmittedTemporarily: "Not submitted temporarily",
                            iGotIt: "I got it!",
                            confirmExit: "Confirm Exit",
                            confirmSubmission: "Confirm submission",
                            signOut: "sign out",
                        },
                    },
                    taskSubmitButton: {
                        index: {
                            suspend: "suspend",
                            submit: "Submit",
                            remainingTimeOfThisQuestion: "Remaining time of this question",
                            SecondsLeftUntilTheEndTime: "10 seconds left until the end time",
                            remainingTime: "Remaining time",
                        },
                    },
                    util: {
                        noUserVoiceContentDetectedPleaseTryAgain:
              "No user voice content detected, please try again",
                    },
                    wave: {
                        index: {
                            continueRecording: "Continue recording",
                            submit: "Submit",
                            endRecording: "End recording",
                            suspendRecording: "suspend recording",
                            startPracticing: "Start practicing",
                            startTheExam: "Start the exam",
                        },
                    },
                    deviceModal: {
                        index: {
                            pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Please read the requirements carefully before starting",
                            return: "return",
                            iGotIt: "I got it!",
                            timeLimitForSingleQuestionAnswering:
                "Time limit for single question answering",
                            totalTimeLimit: "Total time limit",
                            unlimitedTime: "Unlimited time",
                            stillNeedToCompleteTheTaskInTotal:
                "Still need to complete the task in total",
                            practice: "practice",
                            second: "second",
                            taskScore: "Task score ≥",
                            branch: "branch",
                            or: "or",
                            examScore: "Exam score ≥",
                            remainingExamTimes: "Remaining Exam Times",
                            totalScore: "Total score",
                            Script: "(Script)",
                            scoreEmotion: "Score+Emotion",
                            fractionExpression: "Fraction+Expression",
                            points: "Points)",
                            standardScore: "Standard score",
                            excellentScore: "Excellent score",
                            exerciseInstructions: "Exercise Instructions",
                            forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "For a smooth answer, please complete the equipment inspection first:",
                            camera: "camera",
                            beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Being able to see oneself in the video means that the camera is functioning properly",
                            microphone: "Microphone",
                            pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Please speak into the microphone. Seeing the waveform pattern indicates that the microphone is functioning properly",
                        },
                    },
                },
                course: {
                    components: {
                        folderTree: {
                            modal: {
                                index: {
                                    empty: "empty",
                                    copyTo: "Copy to",
                                    classification: "classification",
                                    rename: "rename",
                                    newlyBuild: "newly build",
                                    categoryParent: "Category parent ",
                                    moveTo: "Move to",
                                    name: "name",
                                },
                            },
                            util: {
                                rename: "rename",
                                moveTo: "Move to",
                                copyTo: "Copy to",
                                delete: "delete",
                                courseClassification: "Course classification",
                                newlyBuild: "newly build",
                            },
                        },
                    },
                    edit: { index: { curriculum: "curriculum" } },
                    index: {
                        learn: "learn",
                        practice: "Practice",
                        examination: "Examination",
                        curriculum: "curriculum",
                        courseIntroduction: "Course Introduction:",
                        createdOn: "Created on:",
                        published: "Published",
                        tasks: "Tasks",
                        unpublishedTasks: "Unpublished tasks",
                        task: "task",
                        operation: "operation",
                        learningMaterials: "Learning materials",
                        goPractice: "Go practice",
                        publishTask: "Publish Task",
                        taskData: "Task Data",
                        edit: "edit",
                        copy: "copy",
                        successfullyCopied: "Successfully copied",
                        delete: "delete",
                        afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "After deletion, all task data under this course will be cleared. Are you sure to delete the course?",
                        courseName: "Course Name",
                        creationTime: "Creation time",
                        releaseStatus: "Release status",
                        whole: "whole",
                    },
                },
                nodeConfigTest: {
                    components: {
                        input: {
                            noSoundDetected: "No sound detected",
                            voice: "voice",
                            text: "text",
                            referenceScript: "Reference script:",
                            clickOnTheRightSideToStartRecording:
                "Click on the right side to start recording",
                            sendOut: "send out",
                        },
                        hooks: {
                            input: {
                                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "The current client is already connected to the ASR Websocket server",
                                afterProcessingTheLastFrameRestoreTheState:
                  "After processing the last frame, restore the state",
                                aSRConnectionClosed: "ASR connection closed",
                                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "Recording paused and has been processed, but there is still data in the cache",
                                foundASRChannelClosedRecreateWebsocketLink:
                  "Found ASR channel closed, recreate Websocket link",
                                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Found that the ASR channel is not open, recreate the Websocket link",
                                unableToRecord: "Unable to record:",
                                creatingARecorderService: "Creating a Recorder Service",
                                turnOffTheRecorderService: "Turn off the Recorder service",
                            },
                            util: {
                                recorded: "Recorded",
                                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16 bit pcm SendFrameSize must be an integer multiple of 2",
                                pCMSamplingRateShouldNotOccur:
                  "PCM sampling rate should not occur",
                                andTheRequiredSamplingRate: "And the required sampling rate",
                                atypism: "atypism",
                                errorsThatShouldNotOccur: "Errors that should not occur:",
                                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<span style="color: red">Data with disordered order can be directly discarded if the requirements are not high</span>',
                                flower: "flower",
                                TransferStopped: ": Transfer stopped",
                            },
                        },
                    },
                    index: {
                        testFromCurrentNode: "Test from current node",
                        listening: "Listening",
                        canvasTesting: "Canvas testing",
                        endOfProcess: "End of process",
                        restart: "restart",
                        start: "start",
                    },
                },
                record: {
                    detail: {
                        components: {
                            openButton: {
                                index: {
                                    collapseAnalysis: "Collapse Analysis",
                                    expandParsing: "Expand parsing",
                                },
                            },
                            overView: {
                                index: {
                                    tooManyModalParticles: "Too many modal particles",
                                    second: "second",
                                    ratingOverview: "Rating Overview",
                                    totalScore: "Total score",
                                    section: "Section",
                                    name: "name",
                                    of: "Of",
                                    people: "People)",
                                    scriptScore: "Script score",
                                    common: "common",
                                    keyPoints: "Key points,",
                                    notReached: "Not reached,",
                                    have: "have",
                                    violationOfRegulations: "Violation of regulations",
                                    emotionalScore: "Emotional score",
                                    appear: "appear",
                                    secondaryNegativeEmotions: "Secondary negative emotions",
                                    expressionScore: "Expression score",
                                    speakTooFast: "Speak too fast",
                                    times: "Times,",
                                    speakTooSlowly: "Speak too slowly",
                                    average: "average",
                                    wordminute: "Word/minute",
                                },
                            },
                            score: {
                                index: {
                                    studentNodeName: "Student node name",
                                    keyPointName: "Key point name",
                                    slideNodeName: "Slide Node Name",
                                    dialogue: "dialogue",
                                    mainPoints: "main points",
                                    slide: "slide",
                                    detectionResult: "detection result ",
                                    satisfy: "satisfy",
                                    dissatisfaction: "dissatisfaction",
                                    score: "score",
                                    script: "Script",
                                    semantics: "semantics",
                                    scriptViolation: "Script violation",
                                    emotion: "emotion",
                                    expression: "expression",
                                },
                            },
                            taskTitle: { index: { branch: "branch" } },
                            comprehensiveEvaluation: {
                                const: {
                                    thisTime: "This time",
                                    lastTime: "last time",
                                    comprehensiveEvaluation: "Comprehensive evaluation",
                                },
                                index: {
                                    complete: "complete",
                                    keyHit: "Key Hit",
                                    languageNorm: "language norm",
                                    fluentExpression: "Fluent expression",
                                    emotionallyPositive: "Emotionally positive",
                                    radarChart: "Radar chart",
                                    spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<span style="margin left: 8px">· Number of complete processes',
                                    spanStylemarginLeftpxNumberOfHitPoints:
                    '<span style="margin left: 8px">· Number of hit points',
                                    spanStylemarginLeftpxYes:
                    '<span style="margin left: 8px">· Yes',
                                    dealingWithIllegalWordsspan:
                    "Dealing with illegal words</span>",
                                    spanStylemarginLeftpxSpeakTooFast:
                    '<span style="margin left: 8px">· Speak too fast',
                                    spanbrspanStylemarginLeftpxTooManyModalParticles:
                    '</span><br/><span style="margin left: 8px">· Too many modal particles',
                                    timesspan: "Times</span>",
                                    spanStylemarginLeftpxNegativeEmotions:
                    '<span style="margin left: 8px">· Negative emotions',
                                },
                            },
                            scoreRecord: {
                                index: {
                                    scoringRecords: "Scoring records",
                                    firstRating: "First rating",
                                    totalScore: "Total score",
                                    changeTo: "Change to",
                                },
                            },
                        },
                        index: {
                            submitARatingAppeal: "Submit a rating appeal",
                            reviewSubmission: "Review submission",
                            saveChanges: "Save Changes",
                            previousSentence: "Previous sentence",
                            nextSentence: "Next sentence",
                            score: "score",
                            scoringSituation: "Scoring situation",
                            dialogueAnalysis: "Dialogue Analysis",
                        },
                    },
                    index: {
                        ratingAppealInProgress: "Rating appeal in progress",
                        operation: "operation",
                        wrongQuestionSet: "Wrong question set",
                        ratingUpdate: "Rating update",
                        viewDetails: "View Details",
                        curriculum: "curriculum",
                        taskName: "Task Name",
                        taskType: "Task Type",
                        score: "score",
                        scoringResults: "Scoring results",
                        time: "time",
                        courseClassification: "Course classification",
                        whole: "whole",
                        learningTime: "Learning time",
                        historicalLearningData: "Historical learning data",
                    },
                },
                task: {
                    exercise: {
                        index: {
                            startPracticing: "Start practicing",
                            historicalData: "historical data ",
                            courseIntroduction: "Course Introduction:",
                            exerciseDifficulty: "Exercise difficulty:",
                            practiceCompletionRequirements:
                "Practice completion requirements:",
                            practiceFrequency: "Practice frequency ≥",
                            second: "second",
                            anyScore: "Any score ≥",
                            branch: "branch",
                            or: "or",
                            taskList: "task list",
                            practiceTime: "Practice time:",
                            to: "to",
                            unlimitedTime: "Unlimited time",
                            completionStatus: "Completion status",
                            numberOfExercises: "Number of exercises",
                            maximumScore: "Maximum score",
                            operation: "operation",
                            enteringLearning: "Entering Learning",
                            practice: "practice",
                            historicalLearningData: "Historical learning data",
                            wrongQuestionSet: "Wrong question set",
                        },
                    },
                    index: {
                        practiceTasks: "Practice tasks",
                        examTasks: "Exam tasks",
                        task: "task",
                    },
                    learn: {
                        index: {
                            courseName: "Course Name",
                            courseIntroduction: "Course Introduction",
                            learningContent: "Learning content",
                            downloadLearningMaterials: "Download learning materials",
                            startPracticing: "Start practicing",
                        },
                    },
                    test: {
                        index: {
                            taskList: "task list",
                            courseIntroduction: "Course Introduction:",
                            examDifficulty: "Exam difficulty:",
                            requirementsForPassingTheExam:
                "Requirements for passing the exam:",
                            examScore: "Exam score ≥",
                            branch: "branch",
                            examTime: "Exam time:",
                            to: "to",
                            unlimitedTime: "Unlimited time",
                            completionStatus: "Completion status",
                            completionTimes: "Completion Times",
                            maximumScore: "Maximum score",
                            operation: "operation",
                            immediateExam: "Immediate Exam",
                            history: "History",
                        },
                    },
                },
                wrongQuestionSet: {
                    exercise: {
                        components: {
                            cue: { index: { referenceScript: "Reference Script" } },
                            flow: {
                                index: {
                                    wrongQuestionItem: "Wrong question item（",
                                    score: "score",
                                    TotalScore: "/Total score",
                                },
                            },
                            record: {
                                ppt: {
                                    hook: { index: { serverError: "Server error" } },
                                    index: {
                                        startRecording: "Start recording",
                                        endRecording: "End recording",
                                        startPracticing: "Start practicing",
                                    },
                                },
                                text: {
                                    index: {
                                        pleaseEnter: "Please enter",
                                        sendOut: "send out",
                                        startPracticing: "Start practicing",
                                    },
                                },
                                voice: {
                                    index: {
                                        startRecording: "Start recording",
                                        endRecording: "End recording",
                                        startPracticing: "Start practicing",
                                    },
                                },
                            },
                            taskMessageModal: {
                                index: {
                                    scoreForThisPracticeOfIncorrectQuestions:
                    "Score for this practice of incorrect questions",
                                    tips: "Tips",
                                    areYouSureToEnd: "Are you sure to end?",
                                    practiceAgainWithoutMasteringTheWrongQuestions:
                    "Practice again without mastering the wrong questions",
                                    continue: "continue",
                                    returnToTheSetOfIncorrectQuestions:
                    "Return to the set of incorrect questions",
                                    end: "end",
                                    wrongQuestionItem: "Wrong question item",
                                    numberOfExercisesThisTime: "Number of exercises this time",
                                    masteringTheSituation: "Mastering the situation",
                                    mastered: "Mastered",
                                    notMastered: "Not mastered",
                                },
                            },
                            taskSubmitButton: {
                                index: {
                                    practiceThisQuestionAgain: "Practice this question again",
                                    nextQuestion: "Next question",
                                },
                            },
                        },
                        ppt: {
                            index: {
                                mispracticeExercises: "Mispractice exercises",
                                endExercise: "End Exercise",
                            },
                        },
                        text: {
                            index: {
                                mispracticeExercises: "Mispractice exercises",
                                endExercise: "End Exercise",
                            },
                        },
                        voice: {
                            index: {
                                mispracticeExercises: "Mispractice exercises",
                                endExercise: "End Exercise",
                            },
                        },
                    },
                    list: {
                        components: {
                            checkScript: {
                                index: {
                                    checkScript: "Check script",
                                    section: "Section",
                                    secondaryDiscourseTechnique: "Secondary discourse technique",
                                },
                            },
                            tableItem: {
                                count: { second: "second" },
                                scoreRuleList: {
                                    branch: "branch",
                                    answerIncorrectly: "answer incorrectly",
                                    second: "second",
                                    wrongAnswerRate: "Wrong answer rate",
                                },
                                standardScript: {
                                    standardScript: "Standard Script",
                                    myScript: "My Script",
                                },
                                status: { mastered: "Mastered", notMastered: "Not mastered" },
                                index: {
                                    totalNumberOfQuestionsAnswered:
                    "Total number of questions answered",
                                    numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Number of times incorrect questions have been practiced",
                                },
                            },
                        },
                        index: {
                            wrongQuestionList: "Wrong question list",
                            wrongQuestionSet: "Wrong question set",
                            mispracticeExercises: "Mispractice exercises",
                            taskName: "Task Name",
                            staff: "staff",
                            masteringTheSituation: "Mastering the situation",
                            whole: "whole",
                        },
                    },
                },
            },
            taskCenter: {
                components: {
                    completeStandart: {
                        index: {
                            practiceFrequency: "Practice frequency ≥",
                            second: "second",
                            or: "or",
                            excellentScoreAtAnyOneTime: "Excellent score at any one time (≥",
                            points: "Points)",
                            examScore: "Exam score ≥",
                            branch: "branch",
                        },
                    },
                    simplePieChart: { index: { nameb: "{name|{b}}" } },
                    manualReviewConfig: {
                        index: {
                            randomAverageDistribution: "Random average distribution",
                            assignToDesignatedPersonnel: "Assign to designated personnel",
                        },
                    },
                },
                detail: {
                    resultAnalysis: {
                        totalScore: "Total score",
                        average: "average",
                        total: "total",
                        keyHitCount: "Key Hit Count",
                        practiceAverageScore: "Practice Average Score",
                        averagePracticeDurationmin: "Average Practice Duration (min)",
                        topOfTheHighestScore: "Top 3 of the highest score",
                        branch: "branch",
                        errorProneItemTop: "Error prone item Top5",
                        hitRate: "hit rate",
                        hitRateOfKeyPointsInScript: "Hit rate of key points in script",
                        averageScriptScore: "Average Script Score",
                        sortByProcess: "Sort by process",
                        sortByScore: "Sort by score",
                        averageScoreOfThisPagetotalScoreOfThisPage:
              "Average score of this page/total score of this page",
                        averageScoreOfThisItemtotalScoreOfThisItem:
              "Average score of this item/total score of this item",
                        details: "details",
                        viewRules: "View Rules",
                        standardScript: "Standard Script",
                    },
                    scoreDetailList: {
                        section: "Section",
                        second: "second",
                        practice: "practice",
                        examination: "examination",
                        notMeetingStandards: "Not meeting standards",
                        meetingStandards: "Meeting standards",
                        excellent: "excellent",
                        frequency: "frequency",
                        fraction: "fraction",
                        result: "result",
                        operation: "operation",
                        viewDetails: "View Details",
                    },
                    index: {
                        staff: "staff",
                        notMeetingStandards: "Not meeting standards",
                        meetingStandards: "Meeting standards",
                        excellent: "excellent",
                        notStarted: "Not Started",
                        haveInHand: "have in hand",
                        completed: "Completed",
                        passed: "Passed",
                        hangInTheAir: "hang in the air",
                        failed: "Failed",
                        unlimitedTime: "Unlimited time",
                        to: "to",
                        maximumScoringCriteria: "Maximum scoring criteria",
                        completionStatus: "Completion status",
                        employeeName: "Employee Name",
                        frequency: "frequency",
                        practice: "practice",
                        examination: "examination",
                        average: "average",
                        maximumScore: "Maximum score",
                        highestScoreResult: "Highest score result",
                        state: "state",
                        averageDurationmin: "Average duration (min)",
                        lately: "lately",
                        time: "time",
                        operation: "operation",
                        viewDetails: "View Details",
                        wrongQuestionSet: "Wrong question set",
                        taskName: "Task Name",
                        completeRequirements: "Complete requirements",
                        publishTo: "Publish to",
                        taskTime: "Task time",
                        fractionalComposition: "Fractional composition",
                        totalScore: "Total score",
                        branch: "branch",
                        Script: "(Script)",
                        scoreEmotion: "Score+Emotion",
                        fractionExpression: "Fraction+Expression",
                        points: "Points)",
                        implementationRate: "implementation rate",
                        numberOfExecutors: "Number of executors",
                        numberOfPeopleNotExecuted: "Number of people not executed",
                        completionRate: "Completion rate",
                        numberOfCompletedPersonnel: "Number of completed personnel",
                        numberOfUnfinishedPersonnel: "Number of unfinished personnel",
                        complianceRate: "Compliance rate",
                        numberOfQualifiedPersonnel: "Number of qualified personnel",
                        numberOfNonCompliantIndividuals:
              "Number of non compliant individuals",
                        excellentRate: "Excellent rate",
                        numberOfOutstandingIndividuals: "Number of outstanding individuals",
                        numberOfNonOutstandingIndividuals:
              "Number of non outstanding individuals",
                        export: "export",
                        taskData: "Task Data",
                        resultAnalysis: "Result analysis",
                        scoreDetails: "Score details-",
                    },
                },
                edit: {
                    index: {
                        excellentStandardsNeedToBeHigherThanStandardStandards:
              "Excellent standards need to be higher than standard standards",
                        theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "The scoring criteria need to be greater than 0% and less than or equal to 100%",
                        theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "The scoring criteria need to be greater than 0 points and less than or equal to {0} points",
                        doRatingsRequireManualReview: "Do ratings require manual review",
                        allocationStrategy: "Allocation strategy",
                        pleaseSelectTheAssignedPersonnel:
              "Please select the assigned personnel",
                        pleaseSelectADesignatedPerson: "Please select a designated person",
                        timeLimitForSingleQuestionAnswering:
              "Time limit for single question answering",
                    },
                    autoAddOrganizationList: {
                        publishToNewlyAddedEmployeesByDefault:
              "Publish to newly added employees by default",
                    },
                },
                list: { index: { releaseTime: "Release time" } },
                audit: {
                    components: {
                        pageLeftTable: {
                            reviewed: "Reviewed",
                            pendingReview: "Pending review",
                            enterKeywordSearch: "Enter keyword search",
                        },
                        pageRightTable: {
                            index: {
                                employeeName: "Employee Name",
                                systemRating: "System rating",
                                scoringResults: "Scoring results",
                                duration: "duration",
                                time: "time",
                                state: "state",
                                whetherToModifyTheRating: "Whether to modify the rating",
                                finalScore: "Final score",
                                reviewedBy: "Reviewed by",
                                operation: "operation",
                                viewScoreDetails: "View score details",
                                viewStatus: "View Status",
                                whole: "whole",
                                correctionStatus: "Correction status",
                                completeReview: "Complete review",
                                tips: "Tips",
                                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "The final score of the student is based on the current final review result. Please confirm",
                            },
                        },
                    },
                    index: { manualReview: "Manual check" },
                },
                review: {
                    index: {
                        employeeName: "Employee Name",
                        score: "score",
                        scoringResults: "Scoring results",
                        duration: "duration",
                        taskName: "Task Name",
                        numberOfAppealScripts: "Number of appeal scripts",
                        appealTime: "Appeal time",
                        processStatus: "Process Status",
                        scoreAfterReview: "Score after review",
                        reviewedBy: "Reviewed by",
                        operation: "operation",
                        viewScoreStatus: "View score status",
                        whole: "whole",
                        manualReview: "Manual recheck",
                    },
                },
            },
            dashboard: {
                components: {
                    adminDataCards: {
                        published: "Published",
                        courses: "Courses",
                        courseData: "Course data",
                        practiceTasks: "Practice tasks",
                        examTasks: "Exam tasks",
                    },
                    practiceSummary: {
                        practiceSummary: "Practice Summary",
                        numberOfPracticeTasks: "Number of practice tasks",
                        numberOfExercises: "Number of exercises",
                        accumulatedPracticeDuration: "Accumulated practice duration",
                        complianceRate: "Compliance rate",
                        excellentRate: "Excellent rate",
                        ranking: "Ranking",
                    },
                    rankingList: {
                        exerciseDuration: "Exercise duration",
                        complianceRate: "Compliance rate",
                        excellentRate: "Excellent rate",
                        diligenceChart: "Diligence Chart",
                        excellentList: "Excellent List",
                        ranking: "ranking",
                        fullName: "full name",
                        i: "I",
                    },
                    taskCard: {
                        end: "end:",
                        noDeadline: "No deadline",
                        second: "second",
                        branch: "branch",
                    },
                    taskProgress: {
                        thereAreCurrentlyNoTasksAvailable:
              "There are currently no tasks available",
                        completed: "Completed",
                    },
                    filter: { employeeDepartment: "Employee department" },
                },
                hook: {
                    useSummary: {
                        activePopulation: "Active population",
                        accumulatedPracticeDuration: "Accumulated practice duration",
                        averagePracticeDuration: "Average Practice Duration",
                        people: "people",
                        numberOfPracticeTasks: "Number of practice tasks",
                        numberOfExercises: "Number of exercises",
                        exerciseDuration: "Exercise duration",
                        second: "second",
                    },
                },
                index: {
                    overview: "Overview",
                    personalSignage: "Personal signage",
                    ongoingExercises: "Ongoing exercises",
                    ongoingExams: "Ongoing exams",
                    practiceTaskProgress: "Practice Task Progress",
                    examTaskProgress: "Exam Task Progress",
                },
            },
        },
        conversationAnalysis: {
            videoAnalysis: {
                detail: {
                    components: {
                        dialogue: {
                            index: {
                                keywordSearch: "Keyword search",
                                previous: "Previous",
                                next: "Next",
                                theObjectThatTriggersTheScrollingEventIs:
                  "The object that triggers the scrolling event is",
                                userScrolling: "User scrolling",
                            },
                        },
                        summary: {
                            index: {
                                reminder: "Reminder:",
                                edit: "edit",
                                name: "name",
                                hintLanguage: "Hint language",
                            },
                        },
                        viewpoint: {
                            index: { viewpointExtraction: "Viewpoint extraction" },
                        },
                        outline: {
                            index: {
                                numberOfKeywords: "Number of keywords",
                                speaker: "speaker",
                                speechDuration: "Speech duration",
                                proportionOfSpeaking: "Proportion of speaking",
                                maximumSpeechDuration: "Maximum speech duration",
                                numberOfQuestions: "Number of Questions",
                                averageSpeechSpeed: "Average speech speed",
                                conversationalActions: "Conversational Actions",
                                contentSummary: "Content Summary",
                                generateSummary: "Generate Summary",
                                keywordCloud: "Keyword Cloud",
                            },
                        },
                    },
                    index: {
                        outline: "outline",
                        viewpoint: "viewpoint",
                        contentSummary: "Content Summary",
                        viewpointExtraction: "Viewpoint extraction",
                        summaryOfKeyPoints: "Summary of key points",
                        videoAnalysis: "Video analysis",
                    },
                },
                list: {
                    index: {
                        delete: "delete",
                        uploadSuccessful: "Upload successful",
                        videoList: "Video List",
                        uploadVideo: "Upload Video",
                        videoName: "Video Name",
                        uploadTime: "Upload time",
                        endTime: "End time",
                        analyzeProgress: "Analyze progress",
                        haveInHand: "have in hand",
                        completed: "Completed",
                        fail: "fail",
                        toBegin: "To begin",
                        operation: "operation",
                        see: "see",
                    },
                },
            },
        },
        businessWorkingHours: {
            components: {
                businessWorkingHoursSetting: {
                    index: { addWorkingHours: "Add working hours" },
                },
            },
            index: {
                businessWorkingHours: "Business working hours",
                newlyBuild: "newly build",
                name: "name",
                describe: "describe",
                operation: "operation",
                edit: "edit",
                delete: "delete",
                editBusinessWorkingHours: "Edit business working hours",
                newBusinessWorkingHours: "New business working hours",
                workingHours: "working hours",
            },
        },
        algorithmSemanticIntelligence: {
            intelligentExtraction: "Intelligent extraction",
        },
        taskCenter: {
            downloadList: {
                component: {
                    areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Are you sure you want to cancel? After cancellation, it cannot be restored again",
                },
            },
        },
    },
    columns: {
        admin: {
            user: {
                id: "ID",
                headPortraitUrl: "Portrait",
                realname: "Name",
                nickname: "nickname",
                name: "Name",
                email: "Account (Email)",
                mobile: "Phone",
                agentId: "Job ID",
                position: "Post",
                role: "role",
                actions: "action",
                createUser: "Create employee",
                editUser: "Edit employee",
                account: "account",
                originPassword: "initial password",
                newPassword: "Create Password",
                resumePassword: "Confirm password",
                userGroup: "Employee Group",
                authorizationGroup: "Authorization Group",
                resetPassword: "Reset Password",
            },
            userGroups: {
                id: "ID",
                name: "name",
                userCount: "Number of employees",
                actions: "action",
                createUserGroup: "Add Employee Group",
                editUserGroup: "Edit employee group",
                member: "member",
            },
            role: {
                id: "ID",
                name: "name",
                actions: "action",
                all: "all",
                taskName: "task",
                readTask: "Task View",
                manageTemplate: "Template management",
                manualInspection: "Manual reinspection",
                readResultAll: "Result View",
                readResultPersonal: "Result View (Personal)",
                readProcess: "Quality inspection process view",
                readReport: "Statistics and Reports",
            },
        },
        download: {
            downloadCenter: {
                createTime: "date",
                taskName: "Name",
                size: "size",
                status: "Status",
            },
        },
        tasks: {
            manage: {
                template: {
                    condition: {
                        idCode: "ID",
                        name: "name",
                        remark: "description",
                        actions: "action",
                    },
                    checkPoint: {
                        name: "name",
                        remark: "description",
                        type: "type",
                        gradeType: "evaluation method",
                        conditionLogic: "rule",
                        predeterminedScore: "Score",
                        weight: "weight",
                        status: "Status",
                        actions: "action",
                        switchOn: "Enable",
                        switchOff: "Disable",
                    },
                },
                workbench: {
                    qualityCheckList: {
                        time: "time",
                        customer: "Customer",
                        customerPhone: "Customer Phone",
                        callType: "Call Type",
                        source: "source",
                        voiceRecording: "Call recording",
                        graded: "graded",
                        openingGreeting: "Opening greeting",
                    },
                    checkPointList: {
                        name: "Quality Inspection Point",
                        type: "type",
                        deductScore: "score",
                        isHit: "hit situation",
                        status: "Recheck",
                        finalDeductScore: "Confirm deducted points",
                        hit: "hit",
                        notHit: "miss hit",
                    },
                },
            },
        },
        custom: { keywords: "Conversation keywords" },
    },
    privateEnums: {},
    menu: {
        admin: {
            system: { text: "System Settings" },
            systemIntegration: {
                text: "System Integration",
                subTitle: {
                    udesk: "Udesk agent system",
                    udeskWhite: "Agent System",
                    wechat: "Enterprise WeChat",
                    taobao: "Taobao Qianniu",
                    weifeng: "weifeng",
                },
            },
            columnsManage: { text: "Quality inspection field management" },
            customerField: { text: "Customer Field" },
            speechRecognition: { text: "Voice recognition" },
            user: { text: "Employee Management" },
            userGroups: { text: "Employee Group Management" },
            role: { text: "Role Management" },
            dataMigration: { text: "Data migration" },
            taskCenter: { text: "Task Center" },
            recordUpload: { text: "Local recording upload" },
            anomalousData: { text: "abnormal data" },
            systemLog: { text: "message log" },
            msgCenter: { text: "Message Center" },
            wechatDocking: { text: "Enterprise micro docking" },
        },
        tasks: {
            homePage: { text: "Home" },
            workbench: { text: "Workbench" },
            semanticIntelligence: { text: "Semantic Intelligence" },
            intelligentAnalysis: { text: "Smart Analysis" },
            intelligentLabel: { text: "Smart label" },
            admin: { text: "Management" },
            callManage: { text: "Call management" },
            dialogueManage: { text: "Dialog management" },
            ticketManage: { text: "Ticket Management" },
            dataAnalysis: { text: "Data Analysis" },
            customerService: { text: "Agent Center" },
            clientCenter: { text: "Customer Center" },
            insight: { text: "Insight" },
            trainingCenter: { text: "Training Center" },
            templates: { text: "Template Management" },
            wechatQaTasks: { text: "Enterprise WeChat Workbench" },
            wechatQaSetting: { text: "Enterprise WeChat analysis configuration" },
            wechatQaChartRecord: { text: "Enterprise WeChat conversation" },
            wechatStaffCenter: { text: "Enterprise Micro Staff Center" },
            callAnalysis: { text: "Session Analysis" },
            caseBaseFolder: { text: "Case Base" },
            coachTask: { text: "coaching task" },
            teamAnalysis: { text: "Team Analysis" },
            customer: { text: "customer" },
            analysisEnable: { text: "Analysis Enable" },
            analysisHelp: { text: "Analysis help" },
            analysisStudy: { text: "Learning to analyze" },
            analysisReview: { text: "Review analysis" },
            analysisTags: { text: "Smart Tag Analysis" },
            analysisSentiment: { text: "Public opinion analysis" },
            analysisBusiness: { text: "Business Analysis" },
            groupTest: { text: "Group Test" },
        },
        navBar: {
            billingCenter: "Help Center",
            uploadDownload: "Upload Download Center",
            setting: "System Settings",
        },
    },
    fix: {
        selectType: "Select Type:",
        all: "all",
        call: "call",
        text: "Dialog",
        wechat: "Enterprise WeChat conversation",
        businessTrendAnalysis: "Business Trend Analysis",
        noEchart: "The chart has no data yet",
        checkPointisRequired: "Rule configuration is required",
        voiceDownLoad: "download recording",
        exportTxt: "Export TXT text",
        exportWord: "Export word text",
        sureReRole: "Are you sure to reverse the role of the speaker? ",
        sureRecover: "Are you sure to re-correct? ",
        sureReAsr: "Are you sure to re-identify? ",
        reRole: "Reverse speaker",
        recover: "Re-correction",
        reAsr: "Re-identify",
        recordingOperation: "Recording operation",
        onlySave30Day: "(Only save 30 days)",
        appealError:
      "Please appeal to the quality inspection point before submitting",
        exportToTaskCenter:
      "Successfully exported to the upload and download center",
        caseBaseLimit: "The case base cannot exceed 50",
        unnamed: "Unnamed",
        wordSpeed: "word/minute",
        hit: "hit",
        checkPoint: "Quality Check Point",
        realTimeAdditionTip1:
      "Real-time addition: the quality inspection starts immediately when the data enters the quality inspection system",
        realTimeAdditionTip2:
      "Timed addition: Automatically add data for quality inspection on a regular basis",
        null: "None",
        collcetedProps: "collection field",
        editingNameNow: "There is a name being edited",
        upToFourLevels: "Classification up to four levels",
        default: "default",
        nameCantBeNull: "Name cannot be empty",
        editingNow: "There is an operation being edited",
        nameError: "Name is wrong",
        nameMaxLength8: "The maximum length of the name is 8",
        callTime: "Call time",
        sentimentValue: "Customer sentiment value",
        agentSentimentValue: "Customer Sentiment Value",
        sentimentAnalysis: "Sentiment Analysis",
        associatedData: "Associated Data",
        notifyTheOwner: "Notify the owner",
        notifyTheEmployee: "Notify the employee himself",
        notifyTheOwnerTip:
      "When the session type is group chat, the group owner will be notified",
        customNotifyEmployee: "Custom Notify Employee",
        customNotifyEmployeeTip:
      "The trigger is an employee, then the employee to whom the notification message belongs",
        clickToLearnMore: "Click to learn more",
        repeatMarkingTip:
      "This record has been marked by this quality inspection point, no need to repeat marking",
        successfulEvaluation: "Successful evaluation",
        evaluationFailed: "evaluation failed",
        qualityInspectionScore: "Quality Inspection Score",
        semanticTags: "Semantic Tags",
        smartTags: "Smart Tags",
        collect: "Collect information",
        appealRecord: "Appeal Record",
        satisfy: "satisfied",
        notSatisfy: "not satisfied",
        appealPlaceHolder: "Please enter the reason for appeal",
        giveUp: "give up",
        rejectReasonPlaceHolder: "Please enter the reason for rejection",
        reject: "Reject",
        changeRejectReason: "Modify the reason for rejection",
        pass: "Pass",
        revise: "modify",
        machineEvaluation: "Machine Evaluation",
        poor: "Poor",
        excellent: "good",
        moderate: "General",
        mustMarked: "The quality inspection point must be marked",
        pleaseChoose: "Please choose",
        manualEvaluation: "Manual Evaluation",
        qualityInspectionTemplate: "Applicable quality inspection template",
        collectionTemplate: "Applicable collection template",
        keywordsWordCloud: "Keyword Word Cloud",
        editCategoryLabel: "Edit category label",
        justCall: "Just watch the call",
        justConversation: "Just watch the conversation",
        batchOperation: "Batch operation",
        customerServicesGroup: "Agent Group",
        joinGroup: "join group",
        removeGroup: "Remove group",
        enterInformationEntity:
      "Please enter the content of the information entity",
        enterNotBeNull: "The input cannot be empty",
        operationGroup: "operation group",
        customerServiceSelected: "Selected agent",
        callAndConversation: "Call/Conversation",
        conversation: "Dialog",
        system: "System",
        customize: "Customize",
        effective: "effective",
        invalid: "invalid",
        successfulAndToTaskCenter:
      "Successful execution, please go to the task center to view",
        recalculationFailed: "Recalculation failed",
        selectRecalculatedIndicator:
      "Please select the indicator to be recalculated",
        source: "Please select the data source",
        placeholder: "Please enter keywords",
        cumulative: "cumulative type",
        average: "average type",
        distribution: "distribution law",
        originalDataCannotEmpty: "Original data cannot be empty",
        inspection: "Quality Inspection",
        check: "Sampling check",
        review: "review",
        draftSavedSuccessfully: "Draft Saved Successfully",
        optimization: "Optimization",
        agentName: "Agent Name",
        allTasks: "All quality inspection tasks",
        datePickerSelect: "Select date",
        callSelect: "Select dialogue",
        taskSelect: "Select task",
        genReport: "Generate report",
        dataSource: "Data Source",
        callsTotal: "Total calls",
        intelligentQualityInspection: "Intelligent Quality Inspection",
        percentage: "Account ratio",
        artificialSampling: "Manual sampling",
        appealNumber: "Appeal amount",
        totalConversation: "Total Conversation",
        samplingReviewRate: "Sampling Review Rate",
        data: "Data",
        inspectionResults: "Quality Inspection Results",
        inspected: "Quality inspected",
        totalScore: "Total Score",
        learnMore: "View more",
        inspectionEffect: "Quality inspection effect",
        allTotal: "Total",
        manualInspection: "Manual reinspection",
        artificialReview: "Manual review",
        approvalAmountAndRate: "Approval Amount & Approval Rate",
        customerScore: "Agent Score",
        inspectionRecommendations: "Quality Inspection Recommendations",
        saveDraft: "Save Draft",
        historicalAdvice: "Historical Advice",
        advicePlaceHolder: "Please enter advice...",
        upload: "File upload",
        customAdd: "Add filter",
        advancedConditions: "Advanced Conditions",
        keyWord: "Keyword",
        containsAll: "contains all",
        containsAny: "contains any",
        matchAny: "match any",
        matchAll: "match all",
        smartTagsOne: "Smart Tag One",
        smartTagsTwo: "Smart Tag Two",
        season: "season",
        month: "month",
        week: "week",
        day: "day",
        hour: "Hour",
        notMoreThan366: "Time cannot exceed 366 days",
        propsCannotBeNull: "There are fields not filled in",
        other: "Other",
        correlationCoefficient: "Correlation coefficient",
        repetitionRate: "repetition rate",
        configurationItemsMoreThan20: "Add up to 20 configuration items",
        conversionAnalysis: "Conversion Analysis",
        correlationAnalysis: "Correlation Analysis",
        successfulExportAndToTaskCenter:
      "Export successfully, please go to the task center to view",
        typeSelect: "Select type",
        exportExcel: "Export excel",
        exportPng: "export png",
        noDataChart: "Chart has no data yet",
        placeOperator: "Please choose a smart tag",
        placeAdvancedConditions: "Please select advanced conditions",
        createAdvancedConditions: "Create advanced filter",
        moveUp: "Move up",
        moveDown: "Move down",
        moveLeft: "Move Left",
        moveRight: "Move right",
        statusChangeSuccess: "Status change success",
        editSuccess: "Modification successful",
        saveSuccess: "Save successfully",
        firstPage: "Home",
        lastPage: "Last Page",
        customerInfo: "Customer Information",
        type: "type",
        NumberTypeErrorMessage:
      'Number type field information content can only contain numbers or "."',
        taskStartPrompt: "Task Start Prompt",
        startConfirm:
      "Please click the confirm button to start the quality inspection task",
        continueToSubmitWithoutEvaluation: "Continue to submit without evaluation",
        rejectTip:
      "No change has been detected, are you sure to reject all appeals directly? ",
        unmarkedSelfLearningTip:
      "You have unmarked self-learning quality inspection points",
        markedSuccessfully: "Marked Successfully",
        markingFailed: "Marking failed",
        exceedingLength: "Exceeding length",
        uncategorized: "Uncategorized",
        addSuccess: "Add success",
        deleteSuccess: "Delete success",
        featureName: "feature name",
        informationEntity: "Information entity",
        standardAnswer: "standard answer",
        keyElementAnswer: "Key Element Answer",
        generalStatement: "General statement (no need to ask)",
        generalQuestionsAndConfirmation: "General Questions and Confirmation",
        specialQuestionsAndAnswers: "Special Questions and Answers",
        agent: "Agent",
        customer: "Customer",
        semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Key elements: Semantic tags and information entities cannot be empty at the same time",
        standardSentence: "Standard sentence",
        acquaintance: "acquaintance degree",
        smartUePlaceholder: "Please enter the name of the smart interaction",
        typeSelectPlaceholder: "Please select the type",
        keyElement: "Key Element",
        reQuerySementicTags: "Reselect semantic tags",
        querySementicTags: "Select semantic tags",
        QATestForm: "Question and answer test form",
        selectNotBeNull: "Select cannot be empty",
        featureDetectionTarget: "Feature detection target",
        categorized: "categorized",
        effectiveness: "effectiveness",
        hideAdvancedSettings: "Hide Advanced Settings",
        showAdvancedSettings: "Show advanced settings",
        generalStatementOnly: "General Statement",
        generalQuestions: "General Questions",
        affirmativeExpression: "affirmative expression",
        negativeIntention: "Negative Intention",
        searchSementicTags: "Search Semantic Tags",
        choiceQuestion: "choice question sentence",
        selectiveAnswer: "selective answer",
        sementicTagsPlaceholder: "Please enter the semantic tag content",
        openSpecialQuestion: "Open Special Question",
        openAnswer: "Open Answer",
        customQA: "Custom Q&A",
        questions: "FAQ",
        dataPlaceholder: "Please enter content",
        reply: "answer",
        notFindSuitableLabel: "No suitable label was found",
        addSemanticTag: "Add a new semantic tag",
        intelligentInteraction: "intelligent interaction",
        matching: "match",
        bout: "times",
        keywordMatch: "Keyword Match",
        addFilterConditions: "Add filter conditions",
        buttonStartCollectionTask:
      "Please click the OK button to start the collection task",
        buttonStartTask:
      "Please click the OK button to start the quality inspection task",
        ruleConfigurationIsRequired: "Rule configuration is required",
        asrRunning: "ASR execution",
        errorCorrectionExecution: "Error correction execution",
        emotionalVolumeRecognition: "Emotional volume recognition",
        dataPreprocessing: "Data Preprocessing",
        inspectionExecution: "Quality Inspection Execution",
        businessId: "BusinessId",
        businessTime: "Business Time",
        createTime: "Create Time",
        abnormalReason: "abnormal reason",
        singleChat: "single chat",
        groupChat: "Group Chat",
        taskContent: "task content",
        submissionTime: "Submission Time",
        startTime: "start time",
        endTime: "End Time",
        runningTime: "Run time-consuming",
        totalAmountOfData: "Total data amount",
        resultsOfThe: "execution result",
        operator: "operator",
        taskDetails: "Task Details",
        executiveFunction: "Executive function",
        qualityCheckTasks: "Quality Check Tasks",
        implementationDetails: "implementation details",
        conditionalRules: "Conditional Rules",
        smartCondition: "Smart Condition",
        goThis: "Move to this",
        pleaseMarkClassification: "Please mark the classification",
        advancedScreening: "Advanced Screening",
        noDataToManipulate: "No data to manipulate",
        concept: "Concept word",
        checkPoint1: "Rule configuration",
        requiredFieldsCannotEmpty: "Required field cannot be empty",
        rule: "rule",
        afterFixUnit: "Words",
        unmarkedSelfLearningPointsTip: "You have unmarked self-learning points",
        onlyReservedFor7Days: "(only reserved for 7 days)",
        downloadError: "Download failed",
        pause: "Pause",
        continue: "Continue",
        recalcScore: "Only recalculate score (including rating)",
        recalculatePointsAndScores: "Recalculate QC points and scores",
        recalculatePointsAndScoresWithEmtry:
      "Recalculate QC points and scores (QC points are empty)",
        inProgress: "in progress",
        toBeExecuted: "To be executed",
        executeImmediately: "Execute immediately",
        specialElements: "Special Elements",
        logicRule: "Logic Rule",
        every: "multiple matches",
        hitRecall: "Hit Recall Word",
        notHitRecall: "Miss Hit Recall Word",
        hitRecallRegular: "hit recall regular",
        notHitRecallRegular: "Missing Regular",
        hitRule: "hit rule",
        notHitRule: "Miss Hit Rule",
        hitSimilarSentence: "hit similar sentence",
        notHitSimilarSentence: "Missing similar sentence",
        hitExcludeSimilarSentences: "Hit to exclude similar sentences",
        notHitExcludeSimilarSentences: "Missing Exclude Similar Sentences",
        updateSuccess: "Update successful",
        qualify: "regular",
        recallKeyWord: "Recall Keyword",
        grammar: "Grammar rules",
    },
    api: {
        putTypoDatasByIdStatusByStatus: "Validity change failed, please try again",
        postAutoMateTasksCaseByTaskId:
      "Failed to save the synchronization configuration of the case library",
        getAutoMateTasksCaseByTaskId:
      "Case library synchronization configuration acquisition failed",
        postInspectionRemarkModelConfigs: "Remark template save failed",
        putInspectionRemarkModelConfigsById: "Remark template save failed",
        getInspectionRemarkModelConfigsById: "Failed to get remark template data",
        postKnowledgeDatas: "The new knowledge base failed, please try again",
        putKnowledgeDatasById:
      "The knowledge base modification failed, please try again",
        getLibraryFiles: "Failed to get the list of price folders",
        getLibraryCalls: "Failed to get file",
        getLibraryFilesSearch: "Search failed",
        putLibraryFilesMoveById: "Failed to move the price folder",
        putLibraryCallsById: "File renaming failed! ",
        postLibraryFiles: "Failed to add folder",
        putLibraryFilesById: "Folder renaming failed",
        deleteLibraryFilesById: "Delete failed",
        deleteLibraryCallsById: "Delete failed",
        postLibraryCalls: "Add failed",
        getVoiceUploadResultById: "Upload result export failed",
    },
};
export default locales;
