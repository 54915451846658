import React, { useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { Button, Space, Table, Popconfirm, Switch, Modal, Form, Input } from 'udesk-ui';
import './style.scss';
import { getRoles, postRoles } from 'src/api/roles';
import { deleteRolesById, getRolesById } from 'src/api/roles/{id}';
import { putRolesModuleById } from 'src/api/roles/module/{id}';
import { CommonPage } from 'udesk_gm_ui';

const SysModuleSwitch = (props) => {
    const [loading, setLoading] = useState(false);

    return (
        <Switch
            {...props}
            loading={loading}
            onChange={(...args) => {
                setLoading(true);
                props.onChange?.(...args).finally(() => {
                    setLoading(false);
                });
            }}
        />
    );
};

// const DEFAULT_DATA_SOURCE = Udesk.enums.sysModuleList.map((i) => ({
//     ...i,
//     status: 0,
//     type: i.id,
// }));
const AdminGongRole = React.memo((props: any) => {
    const locales = UdeskLocales['current'];

    const { pageInfo = {}, storePageInfo } = CommonPage.usePageStateMemo();
    const [form] = Form.useForm();

    const [roleList, setRoleList] = useState<any[]>([]);
    const [createRoleModalVisible, setCreateRoleModalVisible] = useState<boolean>(false);
    const [dataDetail, setDataDetail] = useState<any>({});
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [currentTabIdx, setCurrentTabIdx] = useState(pageInfo.currentTabIdx ?? 0);
    const [currentId, setCurrentId] = useState<any>();
    const columns = useMemo(() => {
        return [
            {
                title: /* 应用名称 */UdeskLocales['current'].pages.generalSetting.permissionManagement.role.index.applyName,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: /* 访问权限 */UdeskLocales['current'].pages.generalSetting.permissionManagement.role.index.accessRights,
                dataIndex: 'status',
                key: 'status',
                render: (text, record, index) => {
                    return (
                        <SysModuleSwitch
                            disabled={dataDetail.type !== 3}
                            checked={record.status}
                            onChange={(checked) => {
                                const currentSysModuleList = dataDetail.sysModuleList
                                    .filter((i) => i.status)
                                    .map((i) => i.type);
                                return putRolesModuleById(
                                    {
                                        name: dataDetail.name,
                                        sysModuleList: checked
                                            ? currentSysModuleList.concat(record.type)
                                            : currentSysModuleList.filter((i) => i !== record.type),
                                    },
                                    {
                                        segments: {
                                            id: currentId,
                                        },
                                    }
                                ).then(() => {
                                    setDataSource((prev) => {
                                        let ret = [...prev];
                                        const changedIndex = ret.findIndex(
                                            (i) => i.type === record.type
                                        );
                                        ret[changedIndex].status = record.status ? 0 : 1;
                                        return ret;
                                    });
                                });
                            }}
                        />
                    );
                },
            },
            {
                title: /* 应用内权限设置 */UdeskLocales['current'].pages.generalSetting.permissionManagement.role.index.inAppPermissionSettings,
                dataIndex: 'actions',
                key: 'actions',
                render: (text, record, index) => {
                    return (
                        <Button
                            onClick={() => {
                                storePageInfo((prev) => ({
                                    ...prev,
                                    id: dataDetail.id,
                                    currentTabIdx,
                                }));
                                let routeOptions = {
                                    history: props.history,
                                    routeName: 'generalSettingRoleView',
                                    pathParams: { id: dataDetail.id, sysModule: record.type },
                                };
                                Udesk.ui.routing.transitionTo(routeOptions);
                            }}
                            disabled={!record.status}
                            type="link"
                        >{/* 设置 */}{UdeskLocales['current'].pages.generalSetting.permissionManagement.role.index.setUp}</Button>
                    );
                },
            },
        ];
    }, [dataDetail, currentId, currentTabIdx]);
    // const handlerEditRole = (id) => {
    //     let routeOptions = {
    //         history: props.history,
    //         routeName: 'generalSettingRoleEdit',
    //         pathParams: { id },
    //     };
    //     Udesk.ui.routing.transitionTo(routeOptions);
    // };
    const getRolesData = (id?) => {
        getRoles().then((res) => {
            setRoleList(res.data ?? []);
            currentRolesData(id ? id : res.data?.[0]?.id);
            setCurrentId(id ? id : res.data?.[0]?.id);
        });
    };
    const currentRolesData = (id) => {
        getRolesById({ segments: { id } }).then((res: any) => {
            setDataDetail(res.data);
            setDataSource(
                res.data?.sysModuleList ?? []
                // DEFAULT_DATA_SOURCE.map((i) => ({
                //     ...i,
                //     status: res.data?.sysModuleList?.find?.((s) => s.type === i.type)?.status ?? 0,
                // }))
            );
        });
    };
    const handlerToggle = (idx, item) => {
        setCurrentTabIdx(idx);
        getRolesData(item.id);
        // currentRolesData(item.id);
        // setCurrentId(item.id);
    };
    const deleteRoleData = () => {
        deleteRolesById({
            segments: { id: currentId },
            successMsg: locales.pages.gong.roleManage.list.deletedSuccessfully,
        }).then((res) => {
            setCurrentTabIdx(0);
            getRolesData();
        });
    };
    useEffect(() => {
        getRolesData(pageInfo.id);
    }, []);
    return (
        <Page
            pageBodyClassName="page-role-manage-center"
            title={locales.menu.admin.role.text}
            padding={true}
            extra={
                <Button type="primary" onClick={() => {
                    setCreateRoleModalVisible(true);
                }}>
                    {locales.pages.gong.roleManage.list.NewRole}
                </Button>
            }
        >
            <div className="page-role-manage-container">
                <div className="role-frame">
                    <h3>{locales.pages.gong.roleManage.list.role}</h3>
                    {roleList.map((item, idx) => {
                        return (
                            <p
                                className={currentTabIdx === idx ? 'active role-tabs' : 'role-tabs'}
                                onClick={handlerToggle.bind(null, idx, item)}
                                key={item.id}
                            >
                                {item.name}
                            </p>
                        );
                    })}
                </div>
                <div className="role-frame-content">
                    <div className="role-frame-content-actions">
                        <Space className="actions-title">
                            <h3>{dataDetail.name}</h3>
                        </Space>
                        <Space>
                            {/* <Button onClick={handlerEditRole.bind(null, dataDetail.id)}>
                                {locales.pages.gong.roleManage.list.edit}
                            </Button> */}
                            <Popconfirm
                                title={`${locales.pages.gong.roleManage.list.areYouSureYouWantToDeleteTheRole}${dataDetail.name}？`}
                                onConfirm={deleteRoleData}
                                okText={locales.pages.gong.roleManage.list.determine}
                                cancelText={locales.pages.gong.roleManage.list.cancel}
                            >
                                <Button>{locales.pages.gong.roleManage.list.delete}</Button>
                            </Popconfirm>
                        </Space>
                    </div>
                    <div className="page-role-frame-scroll role-frame-detail">
                        <h3>{/* 应用访问权限 */}{UdeskLocales['current'].pages.generalSetting.permissionManagement.role.index.applicationAccess}</h3>
                        <div className="page-role-manage-container level-child">
                            <Table
                                pagination={false}
                                style={{ flex: 1 }}
                                dataSource={dataSource}
                                columns={columns}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title={/* 新增角色 */UdeskLocales['current'].pages.generalSetting.permissionManagement.role.index.newRole}
                visible={createRoleModalVisible}
                onOk={() => {
                    form.submit();
                }}
                onCancel={() => {
                    setCreateRoleModalVisible(false);
                }}
            >
                <Form
                    form={form}
                    initialValues={{}}
                    onFinish={(values) => {
                        postRoles({
                            ...values,
                            dataPermissionLevel: 1,
                            sysModuleList: [],
                        }).then(() => {
                            return getRoles().then((res) => {
                                if(res.data?.length){
                                    const lastIndex = res.data.length - 1;
                                    setCurrentTabIdx(lastIndex);
                                    setRoleList(res.data ?? []);
                                    currentRolesData(res.data[lastIndex].id);
                                    setCurrentId(res.data[lastIndex].id);
                                    form.resetFields();
                                }
                            });
                        }).then((res) => {
                            setCreateRoleModalVisible(false);
                        });
                    }}
                >
                    <Form.Item
                        name="name"
                        label={locales.pages.gong.roleManage.edit.roleName}
                        rules={[
                            {
                                required: true,
                                message: locales.pages.gong.roleManage.edit.pleaseEnterTheRoleName,
                            },
                        ]}
                    >
                        <Input
                            placeholder={locales.pages.gong.roleManage.edit.pleaseEnterTheRoleName}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <AdminGongRole {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
