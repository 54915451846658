import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { EnumSelect } from "Component/common/enum-component";
import { linkTo } from 'src/util/link';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { Button, Modal } from 'udesk-ui';
import { isDefined } from 'src/util/core';
import { useTableBtn } from 'src/util/hook/table';
import { beautifulTime } from 'src/util/moment';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const {
        history,
        request,
        // locales,
        selectedRowKey, leftCurrent, updateLeftData,
        rightFilterData: filterData, setRightData,
        rightPagePagination: pagination, setRightPagePagination,
    } = props;
    const [tableData, setTableData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {submitFilterForm} = useTableBtn('task-center-audit-page-right');

    const clickHandle = useCallback((record) => {
        request(`/intelligentPartner/review/${record.id}/record/viewStatus`, {}, 'put').then(
            resp => {
                linkTo(history, getCurrentRouteName('taskCenterAuditDetail'), {
                    id: record.recordId,
                    auditId: record.id,
                });
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [history, request]);

    const submitAudit = useCallback(() => {
        if (selectedRowKey) {
            request(`/intelligentPartner//review/${selectedRowKey}/finish`, {}, 'put').then(
                resp => {
                    updateLeftData();
                    setIsModalOpen(false);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [request, selectedRowKey, updateLeftData]);

    useEffect(() => {
        $('.task-center-audit-page-right .udesk-qa-ui-common-filter').on('click', '.udesk-qa-ui-btn-default', (event, isTree) => {
            setRightData(filter => {
                return {
                    reviewTaskId: filter?.reviewTaskId,
                };
            }, (pagination) => pagination);
            if (!isTree) {
                submitFilterForm();
            }
            return !!isTree;
        });
            
    }, [
        setRightData, 
        submitFilterForm
    ]);

    useEffect(() => {
        setRightData((filter) => {
            return {
                ...filter, reviewTaskId: selectedRowKey
            };
        }, (pagination) =>  pagination);
        submitFilterForm();
    }, [
        selectedRowKey, 
        setRightData, 
        submitFilterForm
    ]);

    const getTableSource = useCallback((data) => {
        const {
            filter = {}, pagination,
        } = data;

        if (!isDefined(filter.reviewTaskId)) return;

        setRightData(filter, pagination);

        const params = {
            ...filter,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
        };

        setLoading(true);
        request('/intelligentPartner/review/record/findAll', params, 'post').then(
            resp => {
                setTableData(resp.data ?? []);
                setRightPagePagination({
                    total: resp.paging?.total,
                    current: resp.paging?.pageNum,
                    pageSize: pagination.pageSize,
                });
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        ).finally(() => {
            setLoading(false);
        });
    }, [
        request, 
        setRightData, 
        setRightPagePagination
    ]);

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 员工姓名 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.employeeName,
                key: 'userName',
                dataIndex: 'userName',
            },
            {
                title: /* 系统评分 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.systemRating,
                key: 'systemScore',
                dataIndex: 'systemScore',
            },
            {
                title: /* 得分结果 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.scoringResults,
                key: 'systemScoreResult',
                dataIndex: 'systemScoreResult',
                render: (name, item, index) => {
                    return Udesk.enums.learningTaskScoreType.getName(name);
                },
            },
            {
                title: /* 时长 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.duration,
                key: 'costTime',
                dataIndex: 'costTime',
                render: beautifulTime,
            },
            {
                title: /* 时间 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.time,
                key: 'recordTime',
                dataIndex: 'recordTime',
                width: 160,
                // render: (name) => <span style={{whiteSpace: 'nowrap'}} >{name}</span>
            },
            {
                title: /* 状态 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.state,
                key: 'viewStatus',
                dataIndex: 'viewStatus',
                render: (name, item, index) => {
                    return Udesk.enums.taskCenterAuditViewStatus.getName(name);
                },
            },
            {
                title: /* 是否修正评分 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.whetherToModifyTheRating,
                key: 'isChangeScore',
                dataIndex: 'isChangeScore',
                render: (name, item, index) => {
                    return Udesk.enums.taskCenterAuditChangedStatus.getName(name);
                },
            },
            {
                title: /* 最终得分 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.finalScore,
                key: 'finalScore',
                dataIndex: 'finalScore',
            },
            {
                title: /* 审核人 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.reviewedBy,
                key: 'reviewerName',
                dataIndex: 'reviewerName',
            },
            {
                title: /* 操作 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.operation,
                key: 'id',
                dataIndex: 'id',
                width: 120,
                render: (name, record) => {
                    return (
                        <div className={'task-btns'}>
                            <Button 
                                type={'link'} 
                                onClick={() => clickHandle(record)}
                            >
                                {/* 查看得分详情 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.viewScoreDetails}
                            </Button>
                        </div>
                    );
                },
            },
        ];
        return {
            columns,
            dataSource: tableData,
            pagination,
            loading,
        };
    }, [tableData, pagination, loading, clickHandle]);

    const filter = useMemo<any>(() => {
        return {
            customComponents: {
                EnumSelect
            },
            fields: [
                {
                    name: 'reviewTaskId',
                    type: 'Input',
                    props: {
                        type: 'hidden'
                    }
                },
                {
                    name: 'viewStatus',
                    label: /* 查看状态 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.viewStatus,
                    type: 'EnumSelect',
                    props: {
                        width: 130,
                        enumKey: 'taskCenterAuditViewStatus',
                        empty: {
                            label: /* 全部 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.whole
                        }
                    }
                },
                {
                    name: 'isChangeScore',
                    label: /* 修正状态 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.correctionStatus,
                    type: 'EnumSelect',
                    props: {
                        width: 130,
                        enumKey: 'taskCenterAuditChangedStatus',
                        empty: {
                            label: /* 全部 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.whole
                        }
                    }
                },
            ],
            initialValues: filterData,
        };
    }, [filterData]);

    return (
        <div className='task-center-audit-page-right'>
            <StandardizeTable.RequestTable
                onRequest={getTableSource}
                table={tableProps}
                filter={filter}               
                toolBarRightButtons={
                    leftCurrent?.reviewStatus === 0 ? [{
                        label: /* 完成审核 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.completeReview,
                        mainBtn: true,
                        onClick: () => setIsModalOpen(true),
                    }] : undefined
                }
            />
            <Modal 
                title={/* 提示 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.tips} 
                visible={isModalOpen} 
                onOk={submitAudit} 
                onCancel={() => setIsModalOpen(false)}
            >
                <p>{/* 学员最终成绩依当前最终审核结果为准，请确认 */}{UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageRightTable.index.theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm}</p>
            </Modal>
        </div>
    );
});
