import React from 'react';
import PropTypes from 'prop-types';
import Udesk from '../../../../common';
import { apiPrefix } from 'src/util/settings';

class SemanticMarkComponent extends React.Component {
    //#region Default Props
    static propTypes = {
        show: PropTypes.bool,
        content: PropTypes.string,
        taskId: PropTypes.number,
    };
    static defaultProps = {
        show: true,
        content: '',
    };
    //#endregion

    state = {};
    privates = {
        show: false,
        content: '',
        markedSimilarList: [],
        markedExcludeList: [],
        similarTagList: [],
        taskTagList: [],
    };

    actions = {
        close() {
            this.privates.show = false;
            this.actions.update();
            this.trigger('onClose');
        },
        changeContent(value) {
            this.privates.content = value;
            this.actions.update();
        },
        submit() {
            let { locales } = this;
            let { content } = this.privates;
            if (content == null || content.trim() === '') {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.components.pages.components.semanticMark.semanticContent
                    )
                );
            }
            this.actions.reloadAsyncModel();
        },
        pressEnter(e) {
            if (e.keyCode === 13 || e.which === 13 || e.charCode === 13) {
                this.actions.submit();
            }
            return;
        },
        delete(isExclude, groupId) {
            let { locales } = this;
            let url = `/${apiPrefix}/semantic-tag/delete-semantic-tag`;
            let params = {
                groupId: groupId,
                content: this.privates.content,
                standardFlag: isExclude ? 2 : 0,
            };
            let locale = isExclude
                ? locales.components.pages.components.semanticMark.markExclude
                : locales.components.pages.components.semanticMark.markSimilar;
            Udesk.ajax.del(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            locale
                        )
                    );
                    this.actions.reloadAsyncModel();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                locale
                            )
                    );
                }
            );
        },
        mark(isExclude, groupId) {
            let { locales } = this;
            let url = `/${apiPrefix}/semantic-tag/similar-exclude-task-semantic-tag`;
            let params = {
                groupId: groupId,
                content: this.privates.content,
                standardFlag: isExclude ? 2 : 0,
            };
            let locale = isExclude
                ? locales.components.pages.components.semanticMark.markExclude
                : locales.components.pages.components.semanticMark.markSimilar;
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            locale
                        )
                    );
                    this.actions.reloadAsyncModel();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                locale
                            )
                    );
                }
            );
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        return {
            show: props.show,
            content: props.content || '',
        };
    }
    componentDidMount() {}
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.content !== this.props.content) {
            this.actions.reloadAsyncModel();
        }
    }
    componentWillUnmount() {}
    //#endregion
}

export default SemanticMarkComponent;
