import { CustomerPortraitConfig } from 'Component/pages/components/coach/CustomerPortrait';
import React, { useEffect, useMemo, useState } from 'react';
import { Icon, Avatar, Space, Tag, Empty, Select } from 'udesk-ui';
import './index.less';
import UdeskLocales from 'UdeskLocales';

const CustomerInfo = React.memo((props: any) => {
    const { data } = props;
    const { customerPortraitOptions, onSelectedCustomerPortraitChange } = props;

    return (
        <div className={'customer-info'}>
            <div className="customer-cover">
                <Space direction="vertical" size={20}>
                    <Avatar
                        src={data.cover}
                        size={64}
                        icon={<Icon type="UserOutlined" antdIcon={true} />}
                    />
                    {Array.isArray(customerPortraitOptions) ? (
                        <>
                            {customerPortraitOptions.length ? (
                                <Select
                                    onChange={onSelectedCustomerPortraitChange}
                                    value={data.id}
                                    options={customerPortraitOptions?.map((c) => ({
                                        label: c.name,
                                        value: c.id,
                                    }))}
                                ></Select>
                            ) : (
                                <div style={{ color: '#fff' }}>{/* 暂无画像 */}{UdeskLocales['current'].pages.coach.learningCenter.components.customerInfo.index.thereIsCurrentlyNoPortraitAvailable}</div>
                            )}
                        </>
                    ) : (
                        <>
                            <span>{data.name}</span>
                        </>
                    )}
                </Space>
            </div>
            <div className="customer-desc">
                <div className="title">
                    <span>{/* 客户背景 */}{UdeskLocales['current'].pages.coach.learningCenter.components.customerInfo.index.customerBackground}</span>
                </div>
                <div className="content">
                    {data.desc ? data.desc : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div>
            </div>
            <div className="customer-tags">
                <div className="title">
                    <span>{/* 客户标签 */}{UdeskLocales['current'].pages.coach.learningCenter.components.customerInfo.index.customerLabel}</span>
                </div>
                {data?.customerTags && data?.customerTags.length > 0 ? (
                    <Space direction="vertical" className="content">
                        {data?.customerTags.map((item) => {
                            return (
                                <Space direction="vertical">
                                    <div className="group-name">{item.groupName}</div>
                                    <Space>
                                        {item.tags.map((tag) => (
                                            <Tag color="processing">{tag.tagName}</Tag>
                                        ))}
                                    </Space>
                                </Space>
                            );
                        })}
                    </Space>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        </div>
    );
});

const withCustomerInfoSelect = (Component) => {
    return (props) => {
        const { onCustomerInfoChange, ...componentProps } = props;
        const { allCustomerPortrait, flowId } =
            CustomerPortraitConfig.useCustomerPortraitConfigContext();

        const [selectedCustomerPortraitId, setSelectedCustomerPortraitId] = useState<
            number | undefined
        >(undefined);

        const customerPortraitOptions = useMemo(
            () => allCustomerPortrait.filter((c) => c.bindFlowIds?.includes(flowId)),
            [allCustomerPortrait, flowId]
        );

        useEffect(() => {
            if (!selectedCustomerPortraitId && customerPortraitOptions.length) {
                setSelectedCustomerPortraitId(customerPortraitOptions[0].id);
            }
        }, [customerPortraitOptions]);

        const selectedCustomerPortrait = useMemo(
            () => customerPortraitOptions.find((c) => c.id === selectedCustomerPortraitId) ?? {},
            [customerPortraitOptions, selectedCustomerPortraitId]
        );
        useEffect(() => {
            onCustomerInfoChange?.(selectedCustomerPortrait);
        }, [selectedCustomerPortrait]);
        return (
            <Component
                {...componentProps}
                customerPortraitOptions={customerPortraitOptions}
                // data={selectedCustomerPortrait}
                onSelectedCustomerPortraitChange={setSelectedCustomerPortraitId}
            />
        );
    };
};

const CustomerInfoTest = withCustomerInfoSelect(CustomerInfo);

export default CustomerInfo;
export { CustomerInfoTest };
