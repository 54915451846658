import React from 'react';
import Udesk from 'Udesk';
import './style.scss';
import InformationEntitiesDetailRoute from './route';
// import ReactSelect from 'udesk-react/src/components/react-select';
import List from 'Component/common/udesk-list';
import { PageHeader, FormBuilder, Space, Button, Input } from 'udesk-ui';

const FormInputList = (props) => {
    const { value, onChange, addBtnText, tip } = props;
    const deleteSentence = (index) => {
        let newValue = [...value];
        newValue.splice(index, 1);
        onChange(newValue);
    };
    const addSentence = () => {
        let newValue = [...value];
        if (Array.isArray(newValue) && newValue.length >= 2000) {
            return;
        }
        newValue.push({
            content: '',
        });
        onChange(newValue);
    };
    const sentenceValueChange = (index, e) => {
        const inputValue = e.target.value;
        let newValue = [...value];
        newValue[index].content = inputValue;
        onChange(newValue);
    };
    return (
        <div className='form-input-list-component'>
            <List
                deleteItem={deleteSentence}
                addItem={addSentence}
                addBtnText={addBtnText}
            >
                {value.map((data, index) => {
                    return (
                        <Input
                            value={data.content}
                            onChange={sentenceValueChange.bind(null, index)}
                        />
                    );
                })}
            </List>
            {tip}
        </div>
    );
};
export default class InformationEntitiesDetailTemplate extends InformationEntitiesDetailRoute {
    render() {
        let {
            // activeFlags,
            loading,
            // config,
            wrapForm,
            initialValues,
        } = this.privates;
        let { actions, locales, privates } = this;
        let isSystem = privates?.model?.type === Udesk.enums.informationEntityTypes.system.id;
        const config = {
            initialValues: initialValues,
            customComponents: { InputList: FormInputList },
            onFinish: actions.onFinish,
            fields: [
                {
                    name: 'name',
                    label: locales.labels.name,
                    type: 'Input',
                    forceDisabled: isSystem,
                    props: {
                        showCount: true,
                        style: {
                            width: 400,
                        },
                    },
                    rules: [
                        {
                            required: true,
                        },
                        {
                            validator: (_, value) => {
                                let currentValue = value && value.trim();
                                if(currentValue && value.length > 40){
                                    return Promise.reject(new Error(this.locales.components.pages.informationEntities.detail.nameLengthError));
                                }
                                return Promise.resolve();
                            }
                        },
                    ],
                },
                {
                    name: 'apiName',
                    label: locales.components.pages.informationEntities.detail.apiName,
                    type: 'Input',
                    forceDisabled: isSystem || this.props.match.params.id,
                    tooltip: locales.pages.semanticIntelligence.informationEntities.detail.batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName,
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                {
                    name: 'activeFlag',
                    label: locales.components.pages.informationEntities.detail.activeFlag,
                    type: 'Select',
                    props: {
                        style: {
                            width: 100,
                        },
                        options: Udesk.enums.activeFlags.map((i) => ({ label: i.name, value: i.id })),
                        allowClear: true,
                    },
                },
                {
                    name: 'remark',
                    label: locales.components.pages.informationEntities.detail.remark,
                    type: 'TextArea',
                    forceDisabled: isSystem,
                    rules: [
                        {
                            validator: (_, value) => {
                                let currentValue = value && value.trim();
                                if(currentValue && value.length > 255){
                                    return Promise.reject(new Error(this.locales.components.pages.informationEntities.detail.remarkLengthError));
                                }
                                return Promise.resolve();
                            }
                        },
                    ],
                },
                {
                    name: 'logicExp',
                    label: locales.components.pages.informationEntities.detail.logicExp,
                    type: 'Input',
                    forceDisabled: isSystem,
                },
                {
                    name: 'sentenceList',
                    label: locales.components.pages.informationEntities.detail.sentenceList,
                    type: 'InputList',
                    props: {
                        sentenceEmptyErrorTip: actions.sentenceEmptyErrorTip,
                        tip: locales.components.pages.informationEntities.detail.tip,
                        addBtnText: locales.labels.add + locales.components.pages.informationEntities.detail.sentenceList,
                    },
                    rules: [
                        {
                            validator: (_, value) => {
                                if(Array.isArray(value) && value.some(i => i.content.trim() === "")){
                                    return Promise.reject(new Error(Udesk.utils.string.format(this.locales.business.notifyMessage.canNotBeEmptyFormat, this.locales.components.pages.informationEntities.detail.sentenceList)));
                                }
                                if (Array.isArray(value)) {
                                    let validateList = [];
                                    let hasDuplicationItem = false;
                                    value.forEach(element => {
                                        let content = element.content && element.content.trim();
                                        if (validateList.includes(content)) {
                                            hasDuplicationItem = true;
                                        } else {
                                            validateList.push(content);
                                        }
                                    });
                                    if (hasDuplicationItem) {
                                        return Promise.reject(new Error(this.locales.components.pages.informationEntities.detail.entityDuplicateError));
                                    }
                                }
                                return Promise.resolve();
                            }
                        },
                    ],
                    // addonAfter: <InputList list={privates.model.sentenceList} listItemTextFieldName="content" inputListClassNames="information-entities-detail-input-list" addIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707" deleteIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao" inputTextChanged={actions.sentenceValueChange} addListValue={actions.addSentence} deleteListValue={actions.deleteSentence} emptyErrorTip={actions.sentenceEmptyErrorTip} maxListValueLength={255} />,
                },
            ],
            footerRender: () => {
                if ((privates.model.editEnable && privates.model.type === 3) || privates.model.type !== 3) {
                    return (
                        <Space>
                            <Button onClick={actions.backToIndex}>{locales.labels.cancel}</Button>
                            <Button type='primary' htmlType='submit' loading={loading}>
                                {locales.labels.save}
                            </Button>
                        </Space>
                    );
                } else {
                    return false;
                }
            },
        };
        return (
            // <div className="information-entities-detail-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className='udesk-qa-web-page-header'
                    onBack={actions.backToIndex}
                    title={
                        this.props.match.params.id
                            ? locales.components.pages.informationEntities.detail.edit
                            : locales.components.pages.informationEntities.detail.new
                    }
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root information-entities-detail-page'>
                        <If condition={privates.modelFulfilled && privates.modelResolved}>
                            <div className='information-entities-detail-page-body'>
                                <FormBuilder {...config} form={wrapForm}></FormBuilder>
                                {/* <div className="form-group row">
                                    <label htmlFor="entity_name" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.labels.name}
                                        <span className="required"></span>
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <Choose>
                                            <When condition={privates.model.type === Udesk.enums.informationEntityTypes.system.id}>
                                                <input type="text" id="entity_name" className="form-control" defaultValue={privates.model.name} onChange={actions.mutator('privates.model.name', e => e.target.value)} disabled />
                                            </When>
                                            <Otherwise>
                                                <input type="text" id="entity_name" className="form-control" defaultValue={privates.model.name} onChange={actions.mutator('privates.model.name', e => e.target.value)} />
                                            </Otherwise>
                                        </Choose>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="api_name" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.components.pages.informationEntities.detail.apiName}
                                        <span className="required"></span>
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <Choose>
                                            <When condition={privates.model.type === Udesk.enums.informationEntityTypes.system.id}>
                                                <input type="text" id="api_name" className="form-control" defaultValue={privates.model.apiName} onChange={actions.mutator('privates.model.apiName', e => e.target.value)} disabled />
                                            </When>
                                            <Otherwise>
                                                <input type="text" id="api_name" className="form-control" defaultValue={privates.model.apiName} onChange={actions.mutator('privates.model.apiName', e => e.target.value)} />
                                            </Otherwise>
                                        </Choose>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="active_flag" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.components.pages.informationEntities.detail.activeFlag}
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <Select style={{width: 100}} value={privates.model.activeFlag} onChange={actions.changeActiveFlag}>
                                            {
                                                activeFlags.map(item => {
                                                    return <Select.Option value={item.id}>{item.name}</Select.Option>;
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="remark" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.components.pages.informationEntities.detail.remark}
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <Choose>
                                            <When condition={privates.model.type === Udesk.enums.informationEntityTypes.system.id}>
                                                <textarea id="remark" className="form-control form-control-textarea" cols="30" rows="5" defaultValue={privates.model.remark} onChange={actions.mutator('privates.model.remark', e => e.target.value)} disabled></textarea>
                                            </When>
                                            <Otherwise>
                                                <textarea id="remark" className="form-control form-control-textarea" cols="30" rows="5" defaultValue={privates.model.remark} onChange={actions.mutator('privates.model.remark', e => e.target.value)}></textarea>
                                            </Otherwise>
                                        </Choose>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="logicExp" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.components.pages.informationEntities.detail.logicExp}
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <Choose>
                                            <When condition={privates.model.type === Udesk.enums.informationEntityTypes.system.id}>
                                                <input type="text" id="logicExp" className="form-control" defaultValue={privates.model.logicExp} onChange={actions.mutator('privates.model.logicExp', e => e.target.value)} disabled />
                                            </When>
                                            <Otherwise>
                                                <input type="text" id="logicExp" className="form-control" defaultValue={privates.model.logicExp} onChange={actions.mutator('privates.model.logicExp', e => e.target.value)} />
                                            </Otherwise>
                                        </Choose>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="sentenceList" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.components.pages.informationEntities.detail.sentenceList}
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">

                                        <InputList list={privates.model.sentenceList} listItemTextFieldName="content" inputListClassNames="information-entities-detail-input-list" addIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707" deleteIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao" inputTextChanged={actions.sentenceValueChange} addListValue={actions.addSentence} deleteListValue={actions.deleteSentence} emptyErrorTip={actions.sentenceEmptyErrorTip} maxListValueLength={255} />
                                        <span className="information-entities-detail-tip">
                                            {locales.components.pages.informationEntities.detail.tip}
                                        </span>
                                    </div>
                                </div> */}
                                {/* <If condition={(privates.model.editEnable && privates.model.type === 3) || privates.model.type !== 3}>
                                    <div className="form-group row">
                                        <div className="col-sm-offset-2 col-md-offset-2 col-lg-offset-1 col-xs-12 col-sm-4 col-md-4 col-lg-4 information-entities-detail-page-buttons">
                                            <button type="button" className="btn btn-default" onClick={actions.backToIndex}>
                                                {locales.labels.cancel}
                                            </button>
                                            <button type="button" disabled={loading} className="btn btn-primary" onClick={actions.saveEntity}>
                                                {locales.labels.save}
                                            </button>
                                        </div>
                                    </div>
                                </If> */}
                            </div>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}
