import React, { useEffect, useMemo, useRef } from 'react';
import { Space, Button } from "udesk-ui";
import { isExercise } from '../../util';
import { useDisabledForStartButton } from '../hook';
import { Dialogue } from 'Component/pages/components/coach/Dialogue';
import { getRtaProps, isRta } from 'Component/pages/components/coach/Dialogue/components/RealTimeAnalyzer';
import './index.less';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const {
        task,
        globalConfig,
        isStartTask,
        isLastNode,
        isOver,
        pause,
        list,
        audioRef,
        startTaskHandle,
        startAudioRecord,
        loadingForTaskStart,
        loadingForSendMessage,
    } = props;

    const chatRecordRef = useRef<any>(null);

    const [isDisabled] = useDisabledForStartButton(task);

    const scoreDetailProps = useMemo(() => getRtaProps(task, globalConfig), [task, globalConfig]);

    useEffect(() => {
        chatRecordRef.current?.scrollTo(list?.length);
    }, [list?.length]);

    return (
        <div className={'chat-record-panel'}>
            <audio 
                className='hidden' 
                ref={audioRef} 
                controls 
                autoPlay={false} />
            <div className='chat-panel'>
                <Dialogue
                    ref={chatRecordRef}
                    dialogueSentenceList={list}
                    itemRender={(props, index) => {
                        return (
                            <React.Fragment>
                                <Dialogue.Item 
                                    {...props} 
                                    {...scoreDetailProps}
                                    index={index}
                                    isRta={isRta(task, props)}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </div>
            {!isLastNode && <div className='record-toolbar'>
                <Space>
                    <div className={'wave'}></div>
                    <Button 
                        loading={loadingForSendMessage}
                        type='primary' 
                        size='large' 
                        onClick={isOver ? startAudioRecord : pause}
                    >
                        {isOver ? /* 开始录音 */UdeskLocales['current'].pages.coach.learningCenter.components.record.voice.index.startRecording: /* 结束录音 */UdeskLocales['current'].pages.coach.learningCenter.components.record.voice.index.endRecording}
                    </Button>
                </Space>
                {
                    !isStartTask && (
                        <div className='start-button'>
                            <Button 
                                disabled={isDisabled}
                                loading={loadingForTaskStart}
                                type='primary'
                                size='large' 
                                onClick={startTaskHandle}
                            >
                                {isExercise(task?.taskType, /* 开始练习 */UdeskLocales['current'].pages.coach.learningCenter.components.record.voice.index.startPracticing, /* 开始考试 */UdeskLocales['current'].pages.coach.learningCenter.components.record.voice.index.startTheExam)}
                            </Button>
                        </div>
                    )
                }
            </div>
            }
        </div>
    );
});

