import React, { useRef, useState, useMemo, useImperativeHandle } from 'react';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
import ReactFlow, {
    MiniMap,
    Background,
    ReactFlowProvider,
    Controls,
    ControlButton,
} from 'reactflow';

import 'reactflow/dist/style.css';
import { Divider, Drawer, Icon, Space } from 'udesk-ui';
import { DialogNodeConfig, JudgeNodeConfig } from './NodeConfig';
// import { postReviewIntelligentPartnerCourse } from 'src/api/review/intelligentPartner/course';
import { Sidebar } from './Sidebar';
import { findAllSelectedBranch } from './utils/node';
import { useDagreGraph } from './hooks/useDagreGraph';
import CustomEdge from './CustomEdge';
import { useFlow } from './hooks/useFlow';
import { useControlButtons } from './hooks/useControlButtons';
import { ResizableNode } from './Node';
import './style.scss';
import { lineStyle } from './const/lineStyle';
import UdeskLocales from 'UdeskLocales';

const edgeTypes = {
    smoothstep: CustomEdge,
};
// import { useFlow } from './useFlow';

// const courseId = 1;
// const flowId = 1;

const nodeTypes = [
    { name: /* 判断节点 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.flow.determineNodes, type: 'JUDGE_NODE', icon: 'fenzhi' },
    { name: /* 对话节点 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.flow.dialogueNode, type: 'DIALOG_NODE', icon: 'ic-ask-tiwen' },
];
const flowNodeTypes = {
    cusNode: ResizableNode,
};
const InternalFlow = React.forwardRef((props: any, ref: any) => {
    // const locales = Locales['current'];
    const { initNodes } = props;
    const reactFlowWrapper = useRef<any>(null);
    const [visible, setVisible] = useState(false);

    const [currentNode, setCurrentNode] = useState<any>(null);
    const { reactFlowInstance, setNodes, setEdges, currentSelectedNode, ...flowProps } = useFlow(
        initNodes,
        reactFlowWrapper,
        setCurrentNode
    );
    const { nodes, edges } = flowProps;

    const { getLayoutedBranches } = useDagreGraph();
    useImperativeHandle(ref, () => {
        return {
            getNodes: () => {
                const nodeMap = {};
                const indexMap = {};
                let res = nodes.map((n, i) => {
                    const nodeIndex = i + 1;
                    const conditionList = n.data.conditionList?.map((c) => {
                        return {
                            ...c,
                            operatorList: c.operatorList || c.taskOperatorList,
                        };
                    });
                    nodeMap[n.id] = {
                        ...n.data,
                        nodeIndex,
                        position: n.position,
                        nextIndexList: [],
                        preIndexList: [],
                        id: n.data._id,
                        conditionList,
                    };
                    indexMap[n.id] = nodeIndex;
                    return nodeMap[n.id];
                });
                edges.forEach((e) => {
                    const { source, target } = e;
                    if (nodeMap[source] && nodeMap[target]) {
                        if (!nodeMap[source].nextIndexList) {
                            nodeMap[source].nextIndexList = [];
                        }
                        if (!nodeMap[target].preIndexList) {
                            nodeMap[target].preIndexList = [];
                        }
                        nodeMap[source].nextIndexList.push(indexMap[target]);
                        nodeMap[source].nextIndex = nodeMap[source].nextIndexList.join(',');
                        nodeMap[target].preIndexList.push(indexMap[source]);
                        nodeMap[target].preIndex = nodeMap[target].preIndexList.join(',');
                        // nextIndexList: [5];
                        // nextIndex: '5';
                        // preIndex: 2;
                    }
                });
                return res;
            },
        };
    });

    const onNodeClick = (_, node) => {
        flowProps.onNodeClick(_, node).then(() => {
            setVisible(true);
        });
    };

    const { btns: controlButtonList } = useControlButtons(
        reactFlowInstance,
        currentSelectedNode,
        nodes,
        edges,
        setNodes
    );

    const onClose = () => {
        setVisible(false);
        setCurrentNode(null);
    };
    const onSubmit = (values) => {
        const { ruleList, ...others } = values;
        // const nodes = reactFlowInstance.getNodes();
        // const { allBranch, allEdges } = getAllBranch(
        //     currentNode,
        //     nodes,
        //     getEdges(),
        //     _type
        // );

        // deleteElements({
        //     nodes: allBranch,
        //     edges: allEdges,
        // });
        if (others.judgeStrategy <= 3) {
            const childNodeIds = edges
                .filter((e) => e.source === currentNode.id)
                .map((e) => e.target);
            setNodes((prev) => {
                const res = prev
                    .filter(
                        (n) =>
                            n.id !== currentNode.id &&
                            (!childNodeIds.includes(n.id) || n.data.type !== 'BRANCH')
                    )
                    .concat([
                        {
                            ...currentNode,
                            data: {
                                ...currentNode.data,
                                ...values,
                                ruleList: ruleList || [
                                    { formula: '', judgeStrategy: others.judgeStrategy },
                                ],
                            },
                        },
                    ]);
                return res;
            });
            setEdges((eds) => {
                const allRelatedEdges = eds.filter((e) => e.source === currentNode.id);
                const willDeletedEdges = allRelatedEdges
                    .filter((e) => reactFlowInstance.getNode(e.target)?.data?.type === 'BRANCH')
                    .map((e) => e.target);

                return eds.filter((e) => !willDeletedEdges.includes(e.target));
            });
            onClose();
            return;
        }
        const originBranches = findAllSelectedBranch(nodes, currentNode);
        const originBranchIds = originBranches.map((i) => i.id);

        const currentBranchIds = ruleList.map((i) => i.nodeId);
        const survivedBranches = originBranches
            .filter((b) => currentBranchIds.includes(b.id))
            .map((b) => ({
                ...b,
                data: {
                    ...b.data,
                    name: ruleList.find((r) => r.nodeId === b.id).name,
                    ruleList: ruleList.filter((r) => r.nodeId === b.id),
                },
            }));

        const newNode = ruleList
            .filter((r) => !r.nodeId) // 找出新建的分支
            .map((r, index) => {
                const parentNodePosition = currentNode.position;
                return createBranchNode(
                    r,
                    {
                        x: parentNodePosition.x + index * 200,
                        y: parentNodePosition.y + 80,
                    },
                    currentNode.id,
                    currentNode.data.type
                );
            });

        const newEdges = newNode.map((n) => ({
            type: 'smoothstep',
            id: `${Math.random()}`,
            source: currentNode.id,
            target: n.id,
            style: lineStyle,
        }));

        setEdges((eds) => {
            const allRelatedEdges = eds.filter((e) => e.source === currentNode.id);
            const willDeletedEdges = allRelatedEdges
                .filter((e) => reactFlowInstance.getNode(e.target)?.data?.type !== 'BRANCH')
                .map((e) => e.target);

            return eds.filter((e) => !willDeletedEdges.includes(e.target)).concat(...newEdges);
        });
        setNodes((prev) => {
            const res = prev
                // 过滤掉判断节点和所有分支
                .filter((n) => n.id !== currentNode.id && !originBranchIds.includes(n.id))
                .concat(
                    ...getLayoutedBranches(
                        [
                            {
                                ...currentNode,
                                data: { ...currentNode.data, ...others, ruleList: undefined },
                            },
                            ...newNode,
                            ...survivedBranches,
                        ],
                        edges.concat(...newEdges)
                    ).nodes
                );
            return res;
        });

        // const newNode = ruleList
        //     .filter((r) => !r.nodeId) // 找出新建的分支
        //     .map((r, index) => {
        //         const parentNodePosition = currentNode.position;
        //         return createBranchNode(
        //             r,
        //             {
        //                 x: parentNodePosition.x + index * 200,
        //                 y: parentNodePosition.y + 80,
        //             },
        //             currentNode.id,
        //             currentNode.data.type
        //         );
        //     });
        // const originBranches = findAllSelectedBranch(nodes, currentNode);
        // const notDeletedNodeIds = ruleList.filter((r) => r.nodeId).map((i) => i.nodeId); // 找出非新建的分支id
        // const deletedNodes = originBranches.filter((b) => !notDeletedNodeIds.includes(b.id));
        // const deletedNodeIds = deletedNodes.map((i) => i.id);
        // const newEdges = newNode.map((n) => ({
        //     type: 'smoothstep',
        //     id: `${Math.random()}`,
        //     source: currentNode.id,
        //     target: n.id,
        // }));
        // // currentNode.data.conditionList = conditionList;
        // // currentNode.data.name = name;
        // setEdges((eds) => eds.concat(...newEdges));
        // setNodes((prev) => {
        //     const res = prev
        //         .filter((n) => n.id !== currentNode.id && !deletedNodeIds.includes(n.id))
        //         .concat(
        //             ...getLayoutedBranches(
        //                 [{ ...currentNode, data: { ...currentNode.data, ...others } }, ...newNode],
        //                 newEdges
        //             ).nodes
        //         );
        //     return res;
        // });
        onClose();
    };

    const NodeConfig = useMemo(() => {
        if (currentNode?.data?.type === 'DIALOG_NODE') return DialogNodeConfig;
        return JudgeNodeConfig;
    }, [currentNode?.data?.type]);

    return (
        <div className="dndflow">
            <ReactFlowProvider>
                <Sidebar nodeTypes={nodeTypes} />
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                    <ReactFlow
                        {...flowProps}
                        onNodeDoubleClick={onNodeClick}
                        fitView
                        deleteKeyCode={null}
                        edgeTypes={edgeTypes}
                        nodeTypes={flowNodeTypes}
                        zoomOnScroll={false}
                        panOnScroll={true}
                    >
                        <Controls
                            position="top-center"
                            showFitView={false}
                            showZoom={false}
                            showInteractive={false}
                            className="control-button-bar"
                        >
                            <Space split={<Divider type="vertical" />}>
                                {controlButtonList.map((btns) => {
                                    return (
                                        <Space>
                                            {btns.map((b) => {
                                                return (
                                                    <ControlButton onClick={b.onClick}>
                                                        <div
                                                            style={(b as any).style}
                                                            title={b.label}
                                                        >
                                                            <Space>
                                                                {b.icon && (
                                                                    <Icon
                                                                        type={b.icon}
                                                                        antdIcon={true}
                                                                    />
                                                                )}
                                                                {b.label && <span>{b.label}</span>}
                                                            </Space>
                                                        </div>
                                                    </ControlButton>
                                                );
                                            })}
                                        </Space>
                                    );
                                })}
                            </Space>
                        </Controls>
                        <MiniMap
                            nodeColor={(node) => {
                                const currentSelectedNodeId = currentSelectedNode?.id;
                                const dialogNode = () => {
                                    if (currentSelectedNodeId === node.id) return '#f3aa18';
                                    return 'rgba(242,170,24,0.45)';
                                };
                                const judgeNode = () => {
                                    if (currentSelectedNodeId === node.id) return '#1a6eff';
                                    return 'rgba(26,110,255,0.45)';
                                };
                                switch (node.data.type) {
                                    case 'JUDGE_NODE':
                                        return judgeNode();
                                    case 'DIALOG_NODE':
                                        return dialogNode();
                                    case 'START':
                                        if (currentSelectedNodeId === node.id) return '#1acc8c';
                                        return 'rgba(24, 204, 138, 0.45)';
                                    case 'BRANCH':
                                        if (node.data.preNodeType === 'DIALOG_NODE') {
                                            return dialogNode();
                                        } else {
                                            return judgeNode();
                                        }
                                    default:
                                        if (currentSelectedNodeId === node.id) return '#f3aa18';
                                        return 'rgba(242,170,24,0.45)';
                                }
                            }}
                        />
                        <Background />
                    </ReactFlow>
                </div>
                <Drawer title={/* 编辑节点 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.flow.editNodes} visible={visible} onClose={onClose} width={800}>
                    <NodeConfig
                        currentNode={currentNode}
                        inspectDataSource={props.inspectDataSource}
                        onSubmit={onSubmit}
                    />
                </Drawer>
            </ReactFlowProvider>
        </div>
    );
});

const Flow = InternalFlow;

export { Flow };

function createBranchNode(rule, position, preId, preNodeType) {
    return {
        id: `${Math.random()}`,
        data: {
            type: 'BRANCH',
            name: rule.name,
            preId,
            preNodeType,
            ruleList: [rule],
        },
        type: 'cusNode',
        position,
        parentNode: preId,
    };
}
