import { fire } from '../../util/core';
import Udesk from 'Udesk';

export const getSystemType = () => Udesk.data.init.company.systemModule?.split(',').map(Number) || [];

const createFunction = (systemModuleFactory, getSystemTypeFunc = getSystemType) => (...callback) => {
    const isIncludes = getSystemTypeFunc().includes(systemModuleFactory());
    if (callback.length > 0) {
        return fire(callback[isIncludes ? 0 : 1]);
    }
    return isIncludes;
};


export const enableQa = createFunction(() => Udesk.enums.sysModuleList.qa.id);

export const enableSale = createFunction(() => Udesk.enums.sysModuleList.sales.id);

export const enableAlgorithmPlatform = createFunction(() => Udesk.enums.sysModuleList.algorithmPlatform.id);

export const enableCommonSetting = createFunction(() => Udesk.enums.sysModuleList.commonSetting.id);

export const enableSaleAndQa = enableQa && enableSale;

export const enableSysModule = (sysModule) => createFunction(() => sysModule);



export const getRoleSystemType = () => Udesk.data.init.user.roleList[0].sysModule?.split(',').map(Number) || [];

export const userEnableSysModule = (sysModule) => createFunction(() => sysModule, () => {
    return Udesk.data.init.user.admin ? getSystemType() : getRoleSystemType();
});