import JSEncrypt from 'jsencrypt';

export const PUBLICKEY =
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1W3FRpDy2UZKrT9BQKEwMNMowIBZZtFwoOGawOz1r8P1pOgx9j5aeNxvWljpAhldsyhAS6HcKe/7McA1hUiFjza+U4uW/UvCFfCH3STpvv2OOT64Di/fum/eiS5UU+tiTAmhbM4Ay7PsYx/xI6dBpvWoslxhlvYbCCJYX06gBUQIDAQAB';

/**
 * RSA加密
 * @param publicKey 公钥
 * @param plainText 明文
 * @returns {*} 密文
 */
export function encryptByRSA(publicKey, plainText) {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey);
    return encryptor.encrypt(plainText);
}

/**
 * RSA解密
 * @param privateKey 私钥
 * @param cipherText 密文
 * @returns {*} 明文
 */
export function decryptByRSA(privateKey, cipherText) {
    const decrypter = new JSEncrypt();
    decrypter.setPrivateKey(privateKey);
    return decrypter.decrypt(cipherText);
}
