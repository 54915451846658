import React, { useState, useRef } from 'react';
import { Button, Drawer, Popconfirm, Space } from 'udesk-ui';
import { CustomerPortraitConfig } from 'Component/pages/components/coach/CustomerPortrait';
import './style.scss';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';
// import Locales from 'UdeskLocales';

const TitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DetailDrawerTitle = React.memo((props: any) => {
    // const locales = Locales['current'];
    const { onClose, onSubmit, title, submitText, showCancel = true, onDelete } = props;
    return (
        <TitleWrap className="udesk-coach-web-scoring-rules-drawer-title">
            <div>{title}</div>
            <Space>
                {showCancel && <Button onClick={onClose}>{/* 取消 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.customerPortraitConfigDrawer.cancel}</Button>}
                {onDelete && (
                    <Popconfirm
                        title={/* 删除后无法恢复，确定要删除此画像吗？ */UdeskLocales['current'].pages.coach.courseManagement.flow.components.customerPortraitConfigDrawer.afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait}
                        onConfirm={onDelete}
                        // onCancel={cancel}
                        okText={/* 删除 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.customerPortraitConfigDrawer.delete}
                        cancelText={/* 取消 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.customerPortraitConfigDrawer.cancel}
                    >
                        <Button danger>{/* 删除 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.customerPortraitConfigDrawer.delete}</Button>
                    </Popconfirm>
                )}
                <Button onClick={onSubmit} type="primary">
                    {submitText || /* 确定 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.customerPortraitConfigDrawer.determine}
                </Button>
            </Space>
        </TitleWrap>
    );
});

const InitializedConfigDrawer = React.memo((props: any) => {
    const { ...drawerProps } = props;
    // const id = initialValues.id;
    const {
        requestCustomerPortraitConfigDetail,
        detail,
        createCustomerPortrait,
        updateCustomerPortrait,
        deleteCustomerPortrait,
    } = CustomerPortraitConfig.Detail.useCustomerPortraitConfigDetail();
    const { openDrawer, onClose, ...customerPortraitConfigDetailDrawerProps } = useDrawer();
    const { batchBindFlow, ...customerPortraitConfigProps } =
        CustomerPortraitConfig.useCustomerPortraitList(drawerProps.visible);

    const detailRef = useRef<any>(null);
    const createRef = useRef<any>(null);
    return (
        <Drawer
            {...drawerProps}
            mask={false}
            width={500}
            closable={false}
            title={
                <DetailDrawerTitle
                    title={/* 客户画像 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.customerPortraitConfigDrawer.customerPortrait}
                    // showCancel={false}
                    onClose={drawerProps.onClose}
                    submitText={/* 绑定选中角色画像 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.customerPortraitConfigDrawer.bindSelectedCharacterPortrait}
                    onSubmit={() => {
                        batchBindFlow(customerPortraitConfigProps.value).then(drawerProps.onClose);
                    }}
                />
            }
        >
            <CustomerPortraitConfig
                {...customerPortraitConfigProps}
                onView={(item) => {
                    openDrawer();
                    requestCustomerPortraitConfigDetail(item.id);
                }}
            />
            <Drawer
                {...customerPortraitConfigDetailDrawerProps}
                // mask={false}
                closable={false}
                width={500}
                title={
                    <DetailDrawerTitle
                        title={detail.id ? /* 客户画像详情 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.customerPortraitConfigDrawer.customerProfileDetails : /* 新建客户画像 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.customerPortraitConfigDrawer.createANewCustomerProfile}
                        onClose={onClose}
                        onDelete={
                            detail.id
                                ? () => {
                                    deleteCustomerPortrait().then(onClose);
                                }
                                : undefined
                        }
                        onSubmit={() => {
                            if (detail.id) {
                                detailRef.current.validateFields().then((values) => {
                                    updateCustomerPortrait(values).then(onClose);
                                });
                            } else {
                                createRef.current.validateFields().then((values) => {
                                    createCustomerPortrait(values).then(onClose);
                                });
                            }
                        }}
                    />
                }
            >
                {detail.id ? (
                    <CustomerPortraitConfig.Detail {...detail} ref={detailRef} />
                ) : (
                    <CustomerPortraitConfig.Create ref={createRef} />
                )}
            </Drawer>
        </Drawer>
    );
});

const useDrawer = () => {
    const [visible, setVisible] = useState(false);

    const onClose = () => setVisible(false);
    const openDrawer = () => setVisible(true);

    return {
        visible,
        onClose,
        openDrawer,
    };
};

type ConfigDrawerType = typeof InitializedConfigDrawer & {
    useDrawer: typeof useDrawer;
};
const CustomerPortraitConfigDrawer: ConfigDrawerType = InitializedConfigDrawer as ConfigDrawerType;

CustomerPortraitConfigDrawer.useDrawer = useDrawer;
export { CustomerPortraitConfigDrawer };
