import React from 'react';
// import Locales from 'UdeskLocales';
import styled from 'styled-components';
import { Button, Space } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const TitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ScoringRulesDrawerTitle = React.memo((props: any) => {
    // const locales = Locales['current'];
    const { onClose, onSubmit } = props;
    return (
        <TitleWrap className="udesk-coach-web-scoring-rules-drawer-title">
            <div>{/* 得分规则 */}{UdeskLocales['current'].components.coach.scoringRules.components.drawerTitle.scoringRules}</div>
            <Space>
                <Button onClick={onClose}>{/* 取消 */}{UdeskLocales['current'].components.coach.scoringRules.components.drawerTitle.cancel}</Button>
                <Button onClick={onSubmit} type="primary">{/* 确定 */}{UdeskLocales['current'].components.coach.scoringRules.components.drawerTitle.determine}</Button>
            </Space>
        </TitleWrap>
    );
});
