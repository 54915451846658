import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { FormBuilder } from 'udesk-ui';
import { Upload } from './components/Upload';
import { UploadList } from './components/UploadList';
import { RichText } from './components/RichText';
import { postReviewIntelligentPartnerCourse } from 'src/api/review/intelligentPartner/course';
import { putReviewIntelligentPartnerCourseById } from 'src/api/review/intelligentPartner/course/{id}';
import { useCategorySelect } from './hooks/useCategorySelect';
import { getReviewIntelligentPartnerCourseById } from 'src/api/review/intelligentPartner/course/{id}';
import { linkTo } from 'src/util/link';
import { useCoachUserTreeDataWithPermit, UserTreeSelect } from 'src/util/hook/user';
import UdeskLocales from 'UdeskLocales';

// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';

// /** 课程类别id */
// categoryId?: number;
// /** 课程封面 */
// cover?: string;

// id?: number;
// /** 课程介绍 */
// introduce?: string;
// /** 学习内容 */
// learningContent?: string;
// /** 学习资料 */
// learningMaterials?: string;
// /** 学习资料名称 多个使用逗号分割 */
// learningMaterialsName?: string;
// /** 课程名称 */
// name?: string;
// /** 发布状态 0:未发布 1:已发布 */
// status?: number;
const NAME_MAX_COUNT = 40;
const INTRODUCE_MAX_COUNT = 500;
const LAYOUT = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
const Template = React.memo((props: any) => {
    const isEdit = props.route.name !== 'courseManagementCreate';
    const id = props.match.params.id;
    const queryParams = new URLSearchParams(props.location.search);
    const categorySelectProps = useCategorySelect();
    const [loading, setLoading] = useState(false);
    const {treeData} = useCoachUserTreeDataWithPermit();
    const [form] = FormBuilder.useForm();

    const classification =
        typeof queryParams.get('classification') !== 'object'
            ? parseInt(queryParams.get('classification')!, 10)
            : undefined;
    const [initialValues, setInitialValues] = useState<any>({
        visibleUser: {
            organizationIds: Udesk.data.init.user ? [Udesk.data.init.user.organizationId] :[],
            userIds: []
        },
        categoryId: classification,
        courseType: Udesk.enums.lessonType.dialogue.id,
    });

    const onBack = () => window.history.back();

    useEffect(() => {
        if (!isEdit) return;
        setLoading(true);
        getReviewIntelligentPartnerCourseById({
            segments: {
                id,
            },
        })
            .then((resp) => {
                const data: any = resp.data ?? {};
                data.visibleUser = {
                    organizationIds: data.visibleOrganizationIds,
                    userIds: data.visibleUserIds,
                };
                setInitialValues(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);
    return (
        <Page
            pageBodyClassName="udesk-coach-web-course-management-base-info"
            title={!isEdit ? /* 新建课程 */UdeskLocales['current'].pages.coach.courseManagement.baseInfo.index.newCourse : /* 编辑课程 */UdeskLocales['current'].pages.coach.courseManagement.baseInfo.index.editCourse}
            padding={true}
            onBack={onBack}
            loading={loading}
        >
            <FormBuilder
                form={form}
                {...LAYOUT}
                btnsCol={{
                    offset: LAYOUT.labelCol.span,
                    span: LAYOUT.wrapperCol.span
                }}
                initialValues={initialValues}
                customComponents={{ Upload, RichText, UploadList, UserTreeSelect }}
                onFinish={(values) => {
                    const {visibleUser, ...params} = values;
                    
                    params.visibleOrganizationIds = visibleUser?.organizationIds;
                    params.visibleUserIds = visibleUser?.userIds;

                    if (isEdit) {
                        putReviewIntelligentPartnerCourseById(params, {
                            segments: {
                                id,
                            },
                        }).then(onBack);
                    } else {
                        postReviewIntelligentPartnerCourse(params).then((resp) => {
                            linkTo(props.history, 'courseManagementFlow', {
                                id: resp.data?.id,
                            });
                        });
                    }
                }}
                fields={[
                    {
                        name: 'name',
                        type: 'Input',
                        label: /* 课程名称 */UdeskLocales['current'].pages.coach.courseManagement.baseInfo.index.courseName,
                        props: {
                            showCount: true,
                            maxCount: NAME_MAX_COUNT,
                            style: {
                                width: 400,
                            },
                        },
                        rules: [
                            {
                                required: true,
                            },
                            {
                                max: NAME_MAX_COUNT,
                                type: 'string',
                            },
                        ],
                    },
                    {
                        name: 'introduce',
                        type: 'TextArea',
                        label: /* 课程介绍 */UdeskLocales['current'].pages.coach.courseManagement.baseInfo.index.courseIntroduction,
                        props: {
                            maxCount: INTRODUCE_MAX_COUNT,
                            showCount: true,
                            style: {
                                width: 400,
                            },
                        },
                        rules: [
                            {
                                max: INTRODUCE_MAX_COUNT,
                                type: 'string',
                            },
                        ],
                    },
                    {
                        name: 'courseType',
                        type: 'RadioGroup',
                        label: /* 课程类型 */UdeskLocales['current'].pages.coach.courseManagement.baseInfo.index.courseType,
                        props: {
                            options: Udesk.enums.lessonType.map((i) => {
                                return {
                                    label: i.name,
                                    value: i.id,
                                };
                            }),
                        },
                        forceDisabled: isEdit,
                        rules: [
                            {
                                required: true,
                            },
                        ],
                    },
                    {
                        name: 'categoryId',
                        type: 'TreeSelect',
                        label: /* 课程分类 */UdeskLocales['current'].pages.coach.courseManagement.baseInfo.index.courseClassification,
                        props: {
                            style: {
                                width: 250,
                            },
                            ...categorySelectProps,
                        },
                        rules: [
                            {
                                required: true,
                            },
                        ],
                    },
                    {
                        name: 'visibleUser',
                        type: 'UserTreeSelect',
                        label: /* 可见员工 */UdeskLocales['current'].pages.coach.courseManagement.baseInfo.index.visibleEmployees,
                        props: {
                            width: 250,
                            treeData,
                            multiple: true,
                            showSearch: true,
                            allowClear: true,
                            showArrow: true,
                            maxTagCount: 3,
                            placeholder: UdeskLocales['current'].labels.pleaseSelect,
                            showCheckedStrategy: "SHOW_PARENT",
                            treeDefaultExpandAll: false,
                            treeCheckable: true,
                        },
                        rules: [
                            {
                                required: true,
                            },
                        ],
                    },
                    {
                        name: 'cover',
                        type: 'Upload',
                        label: /* 课程封面 */UdeskLocales['current'].pages.coach.courseManagement.baseInfo.index.courseCover,
                        props: {
                            onUploadData(data) {
                                // setHeadImgUrl(data);
                            },
                        },
                    },
                    {
                        name: 'learningContent',
                        type: 'RichText',
                        label: /* 学习内容 */UdeskLocales['current'].pages.coach.courseManagement.baseInfo.index.learningContent,
                    },
                    {
                        name: 'learningMaterialList',
                        type: 'UploadList',
                        label: /* 上传学习资料 */UdeskLocales['current'].pages.coach.courseManagement.baseInfo.index.uploadLearningMaterials,
                    },
                ]}
            />
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
