import React, { useEffect, useMemo, useRef, useState } from 'react';
import {Empty, Space, Switch} from "udesk-ui";
import { isExercise } from '../util';
import './index.less';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const {taskDetail, referenceWords} = props;
    const [isShow, setIsShow] = useState(false);
    const cueListRef = useRef<HTMLDivElement>(null);

    const data = useMemo(() => {
        if (Array.isArray(referenceWords)) {
            return referenceWords;
        }

        if (typeof referenceWords === 'string') {
            return [referenceWords];
        }

        return undefined;
    }, [referenceWords]);

    useEffect(() => {
        cueListRef.current?.scrollTo({top: 0, behavior: 'smooth'});
    }, [
        data
    ]);

    return (
        <div className='cue-panel'>
            {
                isExercise(
                    taskDetail?.taskType,
                    () => (
                        <>
                            <div className='cue-title'>
                                <div><span>{/* 参考话术 */}{UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.referenceScript}</span></div>
                                {
                                    !!taskDetail?.isTip && (
                                        <Switch checked={isShow} onChange={() => setIsShow(isShow => !isShow)} />
                                    )
                                }
                            </div>
                            {
                                isShow && taskDetail?.isTip && data && data.length > 0 ? (
                                    <div ref={cueListRef} className='cue-list'>
                                        {
                                            data.map(words => {
                                                return <div>{words}</div>;
                                            })
                                        }
                                    </div>
                                ): <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </>
                    ),
                    () => (
                        <>
                            <div className='cue-title'>
                                <div><span>{/* 注意事项 */}{UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.mattersNeedingAttention}</span></div>
                            </div>
                            <div className='cue-tip'>
                                <Space direction='vertical'>
                                    <div>
                                        <span>{/* 考试次数 */}{UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.examFrequency}</span>
                                        <span>{`${/* 第 */UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.section}${taskDetail?.completedTimes}${/* 次 / 共 */UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.timestotal}${taskDetail?.limitTimes}${/* 次 */UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.second}`}</span>
                                    </div>
                                    <div>
                                        <span>{/* 考试通过要求 */}{UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.requirementsForPassingTheExam}</span>
                                        <span>{`${/* 考试得分 ≥ */UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.examScore}${taskDetail?.completeByScore}${/* 分 */UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.branch}`}</span>
                                    </div>
                                </Space>
                            </div>
                        </>
                    ),
                )
            }
        </div>
    );
});

