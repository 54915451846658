import { useEffect, useMemo, useState } from 'react';
import { cloneNode, findAllSelectedBranch } from '../utils/node';
import { useDagreGraph } from './useDagreGraph';
import { useFitView } from './useFitView';
import UdeskLocales from 'UdeskLocales';

export const useControlButtons = (
    reactFlowInstance: any,
    currentSelectedNode,
    nodes,
    edges,
    setNodes
) => {
    const { triggerFitView } = useFitView(reactFlowInstance);
    const { getLayoutedElements } = useDagreGraph();
    const showCopyAndDel = useMemo(
        () => currentSelectedNode && currentSelectedNode.data.type !== 'START',
        [currentSelectedNode]
    );

    const [forceUpdate, _forceUpdate] = useState<number>(0);
    const triggerForceUpdate = () => {
        _forceUpdate((p) => p + 1);
    };
    const [btns, setBtns] = useState<any[]>([]);
    useEffect(() => {
        const zoomNumber = Math.floor(reactFlowInstance?.getZoom?.() * 100) + '%';
        const autoLayout = () => {
            const res = getLayoutedElements(nodes, edges);
            setNodes([...res.nodes]);
            triggerFitView();
            triggerForceUpdate();
        };
        const autoLayoutBtn = {
            label: /* 自动整理 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.hooks.useControlButtons.automaticOrganization,
            icon: 'ApartmentOutlined',
            onClick: autoLayout,
        };
        const fitViewBtn = {
            label: /* 画布自适应 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.hooks.useControlButtons.canvasAdaptation,
            icon: 'AimOutlined',
            onClick: () => {
                triggerFitView();
                triggerForceUpdate();
            },
        };
        const zoomIn = {
            icon: 'ZoomInOutlined',
            onClick: () => {
                reactFlowInstance?.zoomIn?.();
                triggerForceUpdate();
            },
        };
        const zoomNumberBtn = {
            label: zoomNumber,
        };
        const zoomOut = {
            icon: 'ZoomOutOutlined',
            onClick: () => {
                reactFlowInstance?.zoomOut?.();
                triggerForceUpdate();
            },
        };
        const defaultExBtns = [
            [zoomIn, zoomNumberBtn, zoomOut],
            [fitViewBtn, autoLayoutBtn],
        ];
        if (showCopyAndDel) {
            setBtns([
                [
                    {
                        label: /* 复制 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.hooks.useControlButtons.copy,
                        icon: 'CopyOutlined',
                        onClick: () => {
                            // reactFlowInstance?.deleteElements({
                            //     nodes: [currentSelectedNode],
                            // });
                            const { cloneEdges, cloneNodes } = cloneElements(
                                currentSelectedNode,
                                nodes,
                                edges
                            );
                            reactFlowInstance?.addEdges(cloneEdges);
                            // reactFlowInstance?.addNodes(cloneNodes);
                            setNodes((prev) => prev.concat(cloneNodes));
                        },
                    },
                    {
                        label: /* 删除 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.hooks.useControlButtons.delete,
                        icon: 'DeleteOutlined',
                        style: { color: 'red' },
                        onClick: () => {
                            const allBranch = findAllSelectedBranch(nodes, currentSelectedNode);
                            const allEdges = findAllSelectedEdges(
                                edges,
                                currentSelectedNode,
                                allBranch
                            );
                            reactFlowInstance?.deleteElements({
                                nodes: [currentSelectedNode, ...allBranch],
                                edges: allEdges,
                            });
                        },
                    },
                ],
                ...defaultExBtns,
            ]);
        } else {
            setBtns(defaultExBtns);
        }
    }, [showCopyAndDel, currentSelectedNode, edges, nodes, reactFlowInstance, forceUpdate]);
    return { btns };
};

function findAllSelectedEdges(edges: any, currentSelectedNode: any, allBranch: any) {
    return edges.filter((e) => {
        return e.source === currentSelectedNode.id && allBranch.find((b) => b.id === e.target);
    });
}

function cloneElements(currentSelectedNode: any, nodes, edges) {
    const idMap = {};
    const cloneParentNode = cloneNode(currentSelectedNode);
    idMap[currentSelectedNode!.id] = cloneParentNode.id;

    const allBranch = findAllSelectedBranch(nodes, currentSelectedNode);
    const cloneBranches = allBranch.map((i) => {
        const res = cloneNode(i, cloneParentNode.id);
        idMap[i.id] = res.id;
        return res;
    });

    const allEdges = findAllSelectedEdges(edges, currentSelectedNode, allBranch).map((e) => {
        return {
            ...e,
            source: idMap[e.source],
            target: idMap[e.target],
            id: `${Math.random()}`,
        };
    });
    return { cloneEdges: allEdges, cloneNodes: [...cloneBranches, cloneParentNode] };
}
