import React, { useState, useEffect, useMemo, useCallback, useRef, useImperativeHandle } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
// import _isEmpty from 'lodash-es/isEmpty';
// import _find from 'lodash-es/find';
import _cloneDeep from 'lodash-es/cloneDeep';
import useResizeObserver from 'use-resize-observer';

import { Input, Select, Button, Icon, Space } from 'udesk-ui';
import { useInputValue } from './hooks';
import DownloadAstText from './components/download-ast-text';
import TestBtn from './components/test-btn';
import ChatRecord from 'Component/common/chat-record';

import './style.scss';

const useAudio: () => [HTMLAudioElement, number, React.Dispatch<React.SetStateAction<number>>] = () => {
    const [instance] = useState(new Audio());
    const [isPlaying, setIsPlaying] = useState<number>(-1);

    useEffect(() => {
        const cb = () => {
            setIsPlaying(-1);
        };
        instance.addEventListener('ended', cb);
        return () => {
            instance.removeEventListener('ended', cb);
        };
    }, []);

    return [instance, isPlaying, setIsPlaying];
};

const { Option } = Select;
// const noop = {};
const getPopupContainer = (triggerNode) => triggerNode.parentElement;
const caseBaseStyle = { width: '130px', marginRight: '8px' };
// let timer: NodeJS.Timeout | null = null;
let timer2: NodeJS.Timeout | null = null;

const ExportComponent = React.forwardRef((props: any, forwardRef) => {
    const locales = Locales['current'];

    const {
        isWechatQa,
        isInformationCollect,
        testSetListFlag,
        detail,
        caseLibraryId,
        caseBase,
        isRecordDetail,
        inspectDataSource,
        /**
         * @canRemarkEdit 标志单句备注是否可编辑，类型为false | 对应的页面的枚举
         */
        canRemarkEdit,
        changeDialogRemarkList,
        dialogRemarkList,
        /**
         * @onMarkSemanticClick 点击对话后，在侧边栏搜索语义标签的回调
         */
        onMarkSemanticClick,
        /**
         * 取消案例库之后的回调，具体意义不明，需要与右侧质检点部分联动（？）
         */
        // caseBaseUnRemrks,
        /**
         * @talkAudioFlag 似乎是判断录音是否在播放的标志位，
         */
        // talkAudioFlag,
        showBaseInfo,
        /**
         * @virtual 是否启用虚拟化
         */
        virtual,
        sdkOptions,
        /**
         * @autoStepToNext 播放中 高亮是否自动跳转到下一句
         */
        autoStepToNext,
        smartTags,
        currentHighLightPointOrSmartKey,
        closeHighLight,
        changeToHighlightItemsCallDetail,
        loading = false,
        isAppeal, //申诉页面
        isTest, //规则测试
        showCheckbox, //显示左侧勾选
        renderHeader, //自定义头
        onMessageItemCheckboxClick,
        //纠错
        trainRecords,
        //调试
        isDebug,
        indexPointTag,
    } = props;
    const { related } = detail;
    const [playingRudio, isPlaying, setIsPlaying] = useAudio();
    // const [isPlaying, setIsPlaying] = useState<number>(-1);

    // useEffect(() => {
    //     const cb = () => {
    //         setIsPlaying(-1);
    //     };
    //     playingRudio.addEventListener('ended', cb);
    //     return () => {
    //         playingRudio.removeEventListener('ended', cb);
    //     };
    // }, []);
    const [loadingDelay, setLoadingDelay] = useState(false);
    useEffect(() => {
        setLoadingDelay(loading);
    }, [loading]);
    /**
     * @type 关键字高亮功能-选择高亮角色
     */
    const [type, setType] = useState(Udesk.enums.applyRoles.all.id);
    /**
     * @keywords 关键字高亮功能-高亮关键字
     */
    const [keywords, setKeywords] = useInputValue(props.keywords);
    /**
     * @fieldsId 当前选中的案例库的id，未选中案例库时为unnamed
     */
    const [fieldsId, setFieldsId] = useState<'unnamed' | number>('unnamed');
    /**
     * @fieldFlag 是否在添加/删减/编辑案例库状态的标志位
     */
    const [fieldFlag, setFieldFlag] = useState(false);
    /**
     * @caseBaseFields 记录被加入案例库的数据 TODO需确认
     */
    // const [caseBaseFields, setCaseBaseFields] = useState<any[] | null>([]);
    /**
     * @fieldsList TODO
     */
    // const [fieldsList, setFieldsList] = useState<any>({});
    /**
     * @safeCaseBaseData QA-1255 新增状态： caseLibraryDatas/caseSearch接口返回数据
     */
    const [safeCaseBaseData, setSafeCaseBaseData] = useState<any>({});
    /**
     * @editedCaseBaseData QA-1255 新增状态： safeCaseBaseData的备份，记录各种修改
     */
    const [editedCaseBaseData, setEditedCaseBaseData] = useState<any>({});
    const [timeRangeList, setTimeRangeList] = useState<[number, number][]>([]);
    /**
     * @height 对话记录窗口高度
     */
    const [height, setHeight] = useState(1);
    const chatRecordRef = useRef<any>(null);
    useImperativeHandle(forwardRef, () => {
        return chatRecordRef.current;
    });
    /**
     *
     * @sentenceIndex 当前播放的句子索引
     */
    const [sentenceIndex, setSentenceIndex] = useState<number | null>(null);
    /**
     * highlightPoint 当前高亮的质检点id
     */
    // const [highlightPoint, setHighlightPoint] = useState<string | null>(null);
    /**
     * pointsHighlight 质检点的高亮信息
     */
    const [pointsHighlight, setPointsHighlight] = useState<any[]>([]);
    /**
     * smartTagsHighlight 智能标签的高亮信息
     */
    const [smartTagsHighlight, setSmartTagsHighlight] = useState<any[]>([]);
    /**
     * allHighlightPoints 收集所有的质检点和智能标签的高亮信息
     */
    const [allHighlightPoints, setAllHighlightPoints] = useState<any[]>([]);
    /**
     * remark 案例库备注，和人工备注保持一致
     */
    const [remark, setRemark] = useState('');
    /**
     * 请求案例库对应的数据
     * @param value 案例库Id（fieldsId）
     */
    /**
     * @dialogSentenceList 格式化对话数据
     */
    const [dialogSentenceList, setDialogSentenceList] = useState([]);

    // const [detailId, setDetailId] = useState(null);

    const roadListSelect = (value) => {
        const { caseBaseUnRemrks, detail, id } = props;
        let caseBaseUrl = Udesk.business.apiPath.concatApiPath(
            `caseLibraryDatas/caseSearch/${value}/${detail.id ? detail.id : id}`,
            props.sdkOptions
        );
        Udesk.ajax.get(caseBaseUrl).then(
            (resp) => {
                setSafeCaseBaseData({
                    ...(resp.data || {}),
                    dialogTextList: resp.data?.dialogText ? JSON.parse(resp.data?.dialogText) : [],
                });
                // let lists;
                // if (resp.data.dialogText) {
                //     lists = JSON.parse(resp.data.dialogText);
                //     let list = lists.map((item) => {
                //         return { id: item.id, caseBaseFlag: true, field: item.text };
                //     });
                //     setCaseBaseFields(list);
                // } else {
                //     if(resp.data.dialogText === null){
                //         setCaseBaseFields(null);
                //     }else{
                //         setCaseBaseFields([]);
                //     }
                // }
                // setFieldsList({
                //     ...resp.data,
                //     dialogText: lists || null,
                // });
                typeof caseBaseUnRemrks === 'function' && caseBaseUnRemrks(resp.data.remarks, true);
                setRemark(resp.data.remarks);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    useEffect(() => {
        let newCaseBaseData = _cloneDeep(safeCaseBaseData);
        newCaseBaseData.dialogTextList?.forEach((item) => {
            item.isAdded = true;
        });
        if (safeCaseBaseData.dialogText === null && safeCaseBaseData.libraryId) {
            newCaseBaseData.dialogTextList = detail?.dialogRecords?.dialogSentenceList.reduce(
                (ret: any, item: any, index) => {
                    let caseBaseData: any = {};
                    if (detail.inspectDataSource === 1 || detail.inspectDataSource === 3) {
                        caseBaseData = {
                            id: index + 1,
                            field: item.text,
                            isAdded: true,
                        };
                    } else {
                        caseBaseData = {
                            field: item.text,
                            id: index + 1,
                            isAdded: true,
                        };
                    }
                    return [...ret, caseBaseData];
                },
                []
            );
        }
        setEditedCaseBaseData(newCaseBaseData);
    }, [safeCaseBaseData, detail?.dialogRecords?.dialogSentenceList]);
    const caseBaselist = useCallback(
        (id) => {
            let newCaseBaseData = { ...editedCaseBaseData };
            let added = false;
            newCaseBaseData.dialogTextList = newCaseBaseData.dialogTextList.map((item) => {
                if (item.id === id) {
                    added = true;
                    return {
                        ...item,
                        isAdded: !item.isAdded,
                    };
                }
                return item;
            });
            // 直接添加的情况
            if (!added) {
                newCaseBaseData.dialogTextList.push({
                    id: id,
                    isAdded: true,
                });
            }
            setEditedCaseBaseData(newCaseBaseData);
        },
        [editedCaseBaseData]
    );

    const caseBaseSelectChange = (value) => {
        if (value !== 'unnamed') {
            roadListSelect(value);
            setFieldFlag(true);
        } else {
            setFieldFlag(false);
            const { caseBaseUnRemrks } = props;
            typeof caseBaseUnRemrks === 'function' && caseBaseUnRemrks('', false);
            setSafeCaseBaseData({});
        }
        setFieldsId(value);
    };
    const saveCaseBase = () => {
        // 对应旧文件的save方法 TODO
        if (fieldsId === 'unnamed') return;
        let params: any = {
            libraryId: fieldsId,
            inspectionDataId: detail.id,
            remarks: props.remark || remark,
        };
        if (safeCaseBaseData.id) {
            params.id = safeCaseBaseData.id;
        }
        const textList = editedCaseBaseData.dialogTextList;
        const selectAll = !textList?.length || (Array.isArray(textList) && !textList.some((i) => i.isAdded));
        if (selectAll) {
            params.dialogText = null;
        } else {
            let dialogText = textList.reduce((ret, caseItem, index) => {
                if (!caseItem.isAdded) return ret;
                let caseBaseData: any = null;
                const item: any = dialogSentenceList[caseItem.id - 1];
                if (detail.inspectDataSource === 1 || detail.inspectDataSource === 3) {
                    caseBaseData = {
                        begin_time: item.fromSec * 1000 || '',
                        channel_id: item.sender === 'agent' ? 1 : 0,
                        end_time: item.endSec * 1000 || '',
                        id: caseItem.id,
                        text: item.text ?? '',
                    };
                } else {
                    caseBaseData = {
                        text: item.content,
                        createdTime: item.created_at,
                        id: caseItem.id,
                        channel_id: item.sender === 'agent' ? 1 : 0,
                    };
                }
                if (!caseBaseData) return ret;
                return [...ret, caseBaseData];
            }, []);
            // dialogText.sort((a, b) => a.id - b.id);
            params.dialogText = JSON.stringify(dialogText);
        }
        let { sdkOptions } = props;
        let caseBaseUrl = Udesk.business.apiPath.concatApiPath(`caseLibraryDatas`, sdkOptions);
        new Promise((resolve, reject) => {
            Udesk.ajax.post(caseBaseUrl, params).then(
                (resp) => {
                    resolve(resp);
                    Udesk.ui.notify.success(locales.components.pages.caseBaseList.detail.successed);
                    roadListSelect(fieldsId);
                    props.onUpdate?.();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    };
    const playRudio = (url, index) => {
        playingRudio.pause();
        if (isPlaying === -1 || isPlaying !== index) {
            playingRudio.src = url;
            playingRudio.play();
            setIsPlaying(index);
        } else {
            setIsPlaying(-1);
        }
    };
    const onClickContentEditOk = useCallback(
        (obj) => {
            let newCaseBaseData = { ...editedCaseBaseData };
            if (newCaseBaseData.dialogTextList.find((i) => i.id === obj.id)) {
                newCaseBaseData.dialogTextList = newCaseBaseData.dialogTextList?.map((item) => {
                    if (obj.id === item.id) {
                        return {
                            ...item,
                            ...obj,
                        };
                    }
                    return item;
                });
            } else {
                newCaseBaseData.dialogTextList = [...newCaseBaseData.dialogTextList, obj];
            }
            setEditedCaseBaseData(newCaseBaseData);
        },
        [editedCaseBaseData]
    );
    // useEffect(() => {
    // }, [editedCaseBaseData]);
    useEffect(() => {
        let urlData = Object.assign({}, Object.fromEntries(new URLSearchParams(caseLibraryId) as any));
        if (urlData.libraryId) {
            roadListSelect(urlData.libraryId);
            setFieldsId(parseInt(urlData.libraryId, 10));
            setFieldFlag(true);
        }
    }, [detail, caseLibraryId]);
    /**初始化对话记录列表高度 */
    useEffect(() => {
        // resizeHandler();
        let newTimeRange =
            detail?.dialogRecords?.dialogSentenceList?.map((item) => {
                return [item.fromSec, item.endSec];
            }) ?? [];
        setTimeRangeList(newTimeRange);
    }, [detail?.dialogRecords?.dialogSentenceList]);

    /**
     * @downUrl 下载对话记录文本TXT的路径
     */
    const downUrl = useMemo(() => {
        let id = detail.id;
        if (!isRecordDetail) {
            id = detail.callId;
        }
        if (!id) {
            return '';
        }
        return Udesk.business.apiPath.concatApiPath(`/data/dialog/download/${id}`, props.sdkOptions);
    }, [detail, isRecordDetail]);
    const sendCurrentTimeRange = useCallback(
        (item) => {
            props.onTimeRangeChanged?.(item);
        },
        [props.onTimeRangeChanged]
    );
    const closeHighLightAndClearKeyWords = useCallback(() => {
        setKeywords('');
        closeHighLight();
    }, [closeHighLight]);
    // const checkedChanged = useCallback((id) => {
    //     let obj = heightList.find((item) => parseInt(item.id, 10) === parseInt(id, 10));
    //     if (obj) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }, [heightList]);
    // const RadioChange = useCallback((id, mark, e) => {
    //     let data = {
    //         id,
    //         pointId: mark.id,
    //         name: mark.name,
    //         flag: e.target.checked,
    //     };
    //     props?.checkedProps?.(data);
    // }, [props?.checkedProps]);
    useEffect(() => {
        if (timer2) {
            clearTimeout(timer2);
        }
        timer2 = setTimeout(() => {
            if (!Array.isArray(detail?.dialogRecords?.dialogSentenceList)) {
                setDialogSentenceList([]);
                return;
            }
            let remarkList = dialogRemarkList || [];
            let highlightMessageIndex = 1;
            let ret = detail?.dialogRecords?.dialogSentenceList.map((item, index) => {
                let ret = {
                    ...item,
                    playRudio: playRudio,
                    isPlaying: isPlaying,
                    sendCurrentTimeRange: sendCurrentTimeRange.bind(null, item),
                    canRemarkEdit: canRemarkEdit,
                    fieldFlag: fieldFlag,
                    dataIdForCreateRemark: props.id,
                };
                if (inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id) {
                    ret.highlightMessageIndex = highlightMessageIndex;
                    highlightMessageIndex++;
                } else {
                    // const content = item?.content ? JSON.parse(item.content) : {};
                    // if(content.type && isHighlightHitType(content.type)){
                    ret.highlightMessageIndex = highlightMessageIndex;
                    highlightMessageIndex++;
                    // }
                }
                let remark = remarkList.find((item) => item.index - 1 === index);
                let caseBaseData = editedCaseBaseData?.dialogTextList?.find((item) => item.id - 1 === index) ?? null;
                //添加单句备注
                if (remark) {
                    ret.remark = remark;
                }
                //添加当前案例库数据
                if (caseBaseData) {
                    ret.caseBaseData = caseBaseData;
                }
                //格式化语音类型的数据
                if ([Udesk.enums.inspectDataSources.voiceCall.id, Udesk.enums.inspectDataSources.wechatRadio.id].includes(inspectDataSource)) {
                    //构造开始截止时间
                    let created_at = getAudioItemTime(item.fromSec, item.endSec);
                    //后端返回的语音类型的数据没有id，规则测试页面造了id字段，所以判断一下
                    if(!ret.id) {
                        ret.id = `dialogSentenceList-item-${index}`;
                    }
                    //企业微信类型的数据解构和文本类型是一致的，所以这里只对语音类型的数据增加__type以作区分
                    ret.__type = 'call';
                    ret.sender = item.role;
                    ret.created_at = created_at;
                    //content: "{"type":"message","data":{"content":"企微测试lianpp"}}"
                    ret.content = {
                        // ...item,
                        type: 'message',
                        data: {
                            content: item.text,
                            fromSec: item.fromSec,
                            endSec: item.endSec,
                        },
                    };
                }
                return ret;
            });
            setDialogSentenceList(ret);
            setLoadingDelay(false);
            timer2 = null;
        }, 300);
    }, [
        detail?.dialogRecords?.dialogSentenceList,
        inspectDataSource,
        dialogRemarkList,
        editedCaseBaseData.dialogTextList,
        sendCurrentTimeRange,
        isPlaying,
    ]);
    useEffect(() => {
        // if (detailId === detail.id) return;        
        let pointsHighlight: any[] = [];
        //遍历一级分类
        (detail?.pointCategoryList ?? []).forEach((pointCategory: any) => {
            //遍历二级分类
            if (Array.isArray(pointCategory.children)) {
                pointCategory.children.forEach((secondCategory: any) => {
                    if (Array.isArray(secondCategory.pointDataList)) {
                        let pointDataList = secondCategory.pointDataList.filter(i => i.ruleType === Udesk.enums.ruleTypes.common.id).map((i) => {
                            let { highlightGroupList = [], highlightSeqList, ...args } = i;
                            //兼容老的质检任务，通过highlightSeqList构建highlightGroupList
                            if (
                                !highlightGroupList?.length &&
                                Array.isArray(highlightSeqList) &&
                                highlightSeqList.length
                            ) {
                                return {
                                    ...args,
                                    highlightGroupsList: highlightSeqList.map((seq, seqIndex) => {
                                        return {
                                            highlightItemList: [
                                                {
                                                    highlightType: 1,
                                                    index: seq,
                                                    length: null,
                                                    start: null,
                                                    groupIndex: seqIndex,
                                                    itemIndex: 0,
                                                },
                                            ],
                                        };
                                    }),
                                };
                            }
                            return {
                                ...args,
                                highlightGroupsList: (highlightGroupList || []).map((g, groupIndex) => {
                                    return {
                                        ...g,
                                        highlightItemList: (g.highlightItemList || []).map((i, itemIndex) => ({
                                            ...i,
                                            groupIndex,
                                            itemIndex,
                                        })),
                                    };
                                }),
                            };
                        });
                        pointsHighlight = [...pointsHighlight, ...pointDataList];
                        
                        const pointsHighLightTotal = pointsHighlight.length;
                        let flowDataList: any[] = [];
                        secondCategory.pointDataList.filter(i => i.ruleType === Udesk.enums.ruleTypes.flow.id).forEach((i) => {
                            let { canvasNodeMatchResultList } = i;
                            if(!canvasNodeMatchResultList) return;
                            canvasNodeMatchResultList.forEach(canvasNode => {

                                canvasNode.parentId = 'flowNode';
                                canvasNode.pointId = `${canvasNode.nodeId}`;

                                let { highlightGroupList = [], ...args } = canvasNode;
                                flowDataList.push({
                                    ...args,
                                    highlightGroupsList: (highlightGroupList || []).map((g, groupIndex) => {
                                        return {
                                            ...g,
                                            highlightItemList: (g.highlightItemList || []).map((i, itemIndex) => ({
                                                ...i,
                                                groupIndex: pointsHighLightTotal + groupIndex,
                                                itemIndex,
                                            })),
                                        };
                                    }),
                                });
                            });
                        });
                        pointsHighlight = [...pointsHighlight, ...flowDataList];
                    }
                });
            }
        });
        
        setPointsHighlight(pointsHighlight);
    }, [detail?.pointCategoryList, detail.id]);
    useEffect(() => {
        let smartTagsWithParentId = (smartTags || []).map((smartTag) => {
            let { highlightGroups, id, ...args } = smartTag;
            return {
                ...args,
                highlightGroupsList: (highlightGroups || []).map((g, groupIndex) => ({
                    ...g,
                    highlightItemList: (g.highlightItemList || []).map((item, itemIndex) => ({
                        ...item,
                        groupIndex,
                        itemIndex,
                    })),
                })),
                pointId: `smartTags${id}`,
                parentId: 'smartTags',
            };
        });
        setSmartTagsHighlight(smartTagsWithParentId);
    }, [smartTags]);
    useEffect(() => {
        setAllHighlightPoints([...pointsHighlight, ...smartTagsHighlight]);
    }, [pointsHighlight, smartTagsHighlight]);
    // useEffect(() => {
    //     setDetailId(detail.id);
    // }, [detail.id]);
    const propsFromDetail = useMemo(() => {
        let tinymcePath = `${Udesk.business.apiPath.getStaticBasePath(
            sdkOptions?.props?.domain,
            sdkOptions?.props?.pathPrefix
        )}/vendor/tinymce/tinymce.min.js`;
        return {
            changeDialogRemarkList,
            onMarkSemanticClick,
            fieldFlag,
            inspectDataSource,
            caseBaselist,
            caseBaseData: editedCaseBaseData,
            onClickContentEditOk,
            tinymcePath,
            // checkedChanged,
            // RadioChange,
            isInformationCollect,
            trainRecords,
        };
    }, [
        changeDialogRemarkList,
        fieldFlag,
        onMarkSemanticClick,
        inspectDataSource,
        caseBaselist,
        editedCaseBaseData,
        onClickContentEditOk,
        sdkOptions,
        // checkedChanged,
        // RadioChange,
        isInformationCollect,
        trainRecords,
    ]);
    const onSearch = (e) => {
        closeHighLight?.();
        setKeywords(e);
    };
    useEffect(() => {
        if (!autoStepToNext) {
            props.timeValue === 'null' && setSentenceIndex(null);
            return;
        }
        let sentenceIndex = 0;
        timeRangeList.some((range, index) => {
            sentenceIndex = index;
            return range[1] > props.timeValue;
        });
        props.timeValue !== null && props.timeValue !== 'null' && setSentenceIndex(sentenceIndex);
        props.timeValue === 'null' && setSentenceIndex(null);
    }, [props.timeValue, timeRangeList]);
    useEffect(() => {
        if (sentenceIndex === null) return;
        chatRecordRef.current?.scrollTo?.({
            index: sentenceIndex,
            align: 'top',
            offset: height / 4,
        });
    }, [sentenceIndex]);
    const { ref, width = 1, height: h = 1 } = useResizeObserver<HTMLDivElement>();
    useEffect(() => {
        const timer = setTimeout(() => {
            let newHeight = h - 49 < 1 ? 1 : h - 49;
            if (newHeight !== height) {
                setHeight(newHeight);
            }
        }, 300);
        return () => {
            clearTimeout(timer);
        };
    }, [width, h]);
    useEffect(() => {
        currentHighLightPointOrSmartKey && setKeywords('');
    }, [currentHighLightPointOrSmartKey]);
    return (
        <div
            className='audio-call-content-info-v2'
            style={isTest ? { borderLeft: '1px solid rgba(0, 0, 0, 0.1)' } : undefined}
            ref={ref}
        >
            {isTest ? (
                renderHeader
            ) : (
                <div className='audio-call-content-info-control'>
                    {/* 关闭左侧基本信息 */}
                    {typeof props.changeBaseInfoOnOff === 'function' && !showBaseInfo && (
                        <Button
                            onClick={props.changeBaseInfoOnOff}
                            type='text'
                            size='small'
                            style={{ marginRight: 16 }}
                            icon={<Icon type='MenuUnfoldOutlined' antdIcon={true} />}
                        ></Button>
                    )}
                    {/* 关键字高亮功能-高亮关键字 */}
                    <Input.Search
                        defaultValue={keywords}
                        className='audio-call-content-search-input'
                        // onChange={setKeywords}
                        addonBefore={
                            // 关键字高亮功能-选择高亮角色
                            <Select
                                value={type}
                                className='audio-call-content-info-control-role-filter'
                                onSelect={(v) => {
                                    setType(v);
                                    closeHighLight?.();
                                }}
                                options={Udesk.enums.applyRoles.map((item) => ({ label: item.name, value: item.id }))}
                            />
                        }
                        placeholder={locales.labels.pleaseEnter}
                        onSearch={onSearch}
                    />
                    {/* 案例库选择 */}
                    {!isWechatQa && !isInformationCollect && !testSetListFlag && !related && !isAppeal && (
                        <Select
                            getPopupContainer={getPopupContainer}
                            value={fieldsId || 'unnamed'}
                            onChange={caseBaseSelectChange}
                            style={caseBaseStyle}
                            disabled={testSetListFlag}
                        >
                            <Option value={'unnamed'}>
                                {locales.components.pages.caseBaseList.detail.choiceCaseBase}
                            </Option>
                            {Array.isArray(caseBase) &&
                                caseBase.map((item) => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    );
                                })}
                        </Select>
                    )}
                    {/* 案例库保存按钮 */}
                    {fieldsId !== 'unnamed' && <Button onClick={saveCaseBase}>{locales.labels.save}</Button>}
                    <Space>
                        {!isAppeal && [2, 4].includes(detail?.inspectDataSource) && <DownloadAstText downUrl={downUrl} />}
                        {!isAppeal && [1, 2, 3, 4].includes(detail?.inspectDataSource) && <TestBtn taskId={detail?.taskId} callId={detail?.callId} />}
                    </Space>
                </div>
            )}
            <div
                className='audio-call-content-info-record'
                style={{
                    backgroundColor:
                        inspectDataSource !== Udesk.enums.inspectDataSources.ticket.id ? 'rgba(0, 0, 0, 0.03)' : '#fff',
                }}
            >
                <ChatRecord
                    key={virtual ? `${height}` : 'un-virtual-list'}
                    data={dialogSentenceList}
                    height={height}
                    showTopMore={false}
                    loading={loadingDelay}
                    defaultScrollToTop={inspectDataSource !== Udesk.enums.inspectDataSources.wechatDialogue.id}
                    propsFromDetail={propsFromDetail}
                    highlightPoint={currentHighLightPointOrSmartKey}
                    allHighlightPoints={allHighlightPoints}
                    closeHighlight={closeHighLightAndClearKeyWords}
                    manualHighlightIndex={sentenceIndex}
                    ref={chatRecordRef}
                    virtual={virtual}
                    searchHighlightKeywords={keywords}
                    searchHighlightRoleType={type}
                    // markFlag={MarkFlag}
                    callId={detail.callId}
                    changeToHighlightItemsCallDetail={changeToHighlightItemsCallDetail}
                    showCheckbox={showCheckbox}
                    onMessageItemCheckboxClick={onMessageItemCheckboxClick}
                    allHighlightMap={indexPointTag}
                    inspectDataSource={inspectDataSource}
                    isDebug={isDebug}
                />
            </div>
        </div>
    );
});

export default React.memo(ExportComponent);

function getAudioItemTime(startPoint, endPoint) {
    let start = Udesk.utils.date.formatDurationWithMillisecond(startPoint);
    let end = Udesk.utils.date.formatDurationWithMillisecond(endPoint);
    return `${start} - ${end}`;
}

// function isHighlightHitType(type) {
//     return [
//         'message',
//         'rich',
//     ].includes(type);
// }

// changeToHighlightItemsCallDetail={isRelated ? this.props.changeToHighlightItemsCallDetail : noop}
// isWechatQa={isWechatQa}
// canRemarkEdit={canRemarkEdit}
// changeDialogRemarkList={changeDialogRemarkList}
// dialogRemarkList={dialogRemarkList}
// talkAudioFlag={talkAudioFlag}
// onAudioStop={actions.onAudioStop}
// audioPlay={actions.onAudioPlay}
// waveAudio={waveAudio}
// onAudioPause={actions.onAudioPause}
// timeValue={timeValue}
// highlightGroupList={highlightGroupList}
// testSetListFlag={testSetListFlag}
// key={detail.id}
// checkedProps={actions.checkedProps}
// pointChildrenComputer={pointChildrenComputer}
// pointChildrenPerson={pointChildrenPerson}
// MarkFlag={MarkFlag}
// heightList={heightList}
// dropChecked={actions.dropChecked}
// sentenceList={
//     detail && detail.dialogRecords && detail.dialogRecords.dialogSentenceList
// }
// keywords={keywords}
// qcsRef={qcsRef}
// caseBaseStatus={caseBaseStatus}
// sdkOptions={sdkOptions}
// onUpdate={actions.onUpdate}
// caseLibraryId={this.props.caseLibraryId}
// caseBaseFlagRight={this.privates.caseBaseFlagRight}
// caseBaseUnRemrks={actions.caseBaseUnRemrks}
// detail={detail}
// caseBase={caseBase}
// id={id}
// highlightSeqList={highlightSeqList}
// inspectDataSource={computes._inspectDataSource}
// isBusinessRecordsVisible={true}
// onTimeRangeChanged={actions.sendCurrentTimeRange}
// onMarkSemanticClick={actions.markSemantic}
// onCloseHitClick={actions.closeHit}
