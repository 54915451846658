import React from 'react';
import Udesk from 'Udesk';
import './style.scss';
import { Form, Input, Select } from '@alifd/next';
import DataPreprocessDetailRoute from './route';
import AutoComplete from 'udesk-react/src/components/auto-complete';
// import RadioBtn from 'udesk-react/src/components/radio-btn';
import { Radio, Tabs, Input as InputUdesk, PageHeader } from 'udesk-ui';
// import { ReactSortable } from 'react-sortablejs';
// import uniqueId from 'lodash-es/uniqueId';
import ConditionListGather from 'Component/pages/components/check-point-condition-list-gather';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import UdeskLocales from 'UdeskLocales';
import { apiPrefix } from 'src/util/settings';
const { TabPane } = Tabs;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        fixedSpan: 4,
    },
    wrapperCol: {
        span: 10,
    },
};
const grid = 8;
// const sortableOption = {
//     animation: 150,
//     fallbackOnBody: true,
//     swapThreshold: 0.65,
//     group: {
//         name: 'formItem',
//         pull: true,
//         put: true,
//     },
// };
// interface BasicClassState {
//     list: { id: string; name: string }[];
// }
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    width: '32px',
    height: '24px',
    color: 'rgba(0,0,0,0.65)',
    borderRadius: '3px',
    margin: `0 ${grid}px 0 0 `,
    textAlign: 'center',
    lineHeight: '24px',
    // 拖拽的时候背景变化
    background: isDragging ? 'lightgreen' : 'rgba(232,244,255,1)',
    marginBottom: '3px',
    ...draggableStyle,
});

const getListStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});
const getListDropStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    width: '100%',
    border: '1px solid #e8e8e8',
    minHeight: '40px',
});
export default class DataPreprocessDetailTemplate extends DataPreprocessDetailRoute {
    render() {
        let {
            id,
            name,
            desc,
            dataSourceFlags,
            dataSource,
            statusFlags,
            status,
            entity,
            ruleType,
            customTypeList,
            ruleSecondaryType,
            conditionList,
            loading,
        } = this.privates;
        let { actions, locales, privates } = this;
        const company = Udesk.data.init.company;
        // const loop = (arr,index) => (
        //     arr.map((item,i) => {
        //         const indexs = index === '' ? String(i) : `${index}-${i}`;
        //         if(item.children){
        //             return <div {...item.attr}
        //                 data-id={indexs}
        //             >
        //                 <Sortable
        //                     key={uniqueId()}
        //                     style={{
        //                         minHeight:100,
        //                         margin:10,
        //                     }}
        //                     ref={c => c && (this.sortable = c.sortable)}
        //                     options={{
        //                         ...sortableOption,
        //                         // onUpdate: evt => (this.sortableUpdate(evt)),
        //                         onAdd: evt => (this.sortableAdd(evt)),
        //                     }}
        //                 >
        //                     {loop(item.children,indexs)}
        //                 </Sortable>
        //             </div>
        //         }
        //         const ComponentInfo = GlobalComponent[item.name]
        //         return <div data-id={indexs}><ComponentInfo {...item.attr} /></div>
        //     })
        // )
        return (
            // <div className="data-preprocess-detail-page">
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    onBack={actions.backToIndex}
                    title={
                        id
                            ? locales.components.pages.dataPreprocess.detail.edit
                            : locales.components.pages.dataPreprocess.detail.new
                    }
                />
                <div className="udesk-qa-web-page-body">
                    <div className="udesk-qa-web-page-body-root data-preprocess-detail-page-body">
                        <Form style={{ width: '100%' }} {...formItemLayout}>
                            <FormItem
                                required
                                label={locales.components.pages.dataPreprocess.detail.name}
                            >
                                <Input
                                    name="name"
                                    disabled={
                                        ruleType === Udesk.enums.dataPreprocessTypes.system.id
                                    }
                                    value={name}
                                    maxCount={20}
                                    showCount={true}
                                    maxLength={20}
                                    onChange={actions.nameChanged}
                                />
                            </FormItem>
                            <FormItem label={locales.components.pages.dataPreprocess.detail.desc}>
                                <Input
                                    name="desc"
                                    disabled={
                                        ruleType === Udesk.enums.dataPreprocessTypes.system.id
                                    }
                                    value={desc}
                                    maxCount={100}
                                    showCount={true}
                                    maxLength={100}
                                    onChange={actions.descChanged}
                                />
                            </FormItem>
                            <FormItem
                                label={locales.components.pages.dataPreprocess.detail.dataSource}
                            >
                                <Select
                                    disabled={
                                        ruleType === Udesk.enums.dataPreprocessTypes.system.id ||
                                        !(
                                            company.enabledCallInspect !== 0 &&
                                            company.enabledImInspect !== 0
                                        )
                                    }
                                    name="dataSource"
                                    value={dataSource}
                                    dataSource={dataSourceFlags}
                                    onChange={actions.dataSourceChanged}
                                />
                            </FormItem>
                            <FormItem label={locales.components.pages.dataPreprocess.detail.status}>
                                <Select
                                    name="status"
                                    value={status}
                                    dataSource={statusFlags}
                                    onChange={actions.statusChanged}
                                />
                            </FormItem>
                            <If condition={ruleType === Udesk.enums.dataPreprocessTypes.custom.id}>
                                <FormItem label={locales.fix.type}>
                                    <Select
                                        name="customType"
                                        value={ruleSecondaryType}
                                        dataSource={customTypeList}
                                        onChange={actions.customTypeChanged}
                                    />
                                </FormItem>
                                <If
                                    condition={
                                        ruleSecondaryType ===
                                        Udesk.enums.dataSourceCutom.smartTag.id
                                    }
                                >
                                    <FormItem
                                        required
                                        label={
                                            locales.components.pages.dataPreprocess.detail.entity
                                        }
                                    >
                                        <AutoComplete
                                            name="entity"
                                            enableEmptySearch={true}
                                            value={entity}
                                            onChanged={actions.entityChanged}
                                            url={`/${apiPrefix}/information-entity/list`}
                                            extraQueryParams={{ activeFlag: 1 }}
                                            method="POST"
                                            queryParamName="keyword"
                                            valueField="id"
                                        />
                                    </FormItem>
                                </If>
                                <FormItem
                                    label={locales.components.pages.dataPreprocess.detail.operator}
                                >
                                    <ConditionListGather
                                        conditionList={conditionList}
                                        type={[dataSource]}
                                        ruglar="preprocess"
                                        onChange={actions.getConditionList}
                                    ></ConditionListGather>
                                </FormItem>
                                <FormItem
                                    label={locales.components.pages.dataPreprocess.detail.rule}
                                >
                                    <div className="tasks-manage-template-configuration-detail-logic-rule">
                                        <Radio.Group
                                            onChange={actions.onGradeTypeChange}
                                            value={privates.compositeType}
                                        >
                                            <Radio value={1}>
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.configuration.logic.all
                                                }
                                            </Radio>
                                            <Radio value={2}>
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.configuration.logic.any
                                                }
                                            </Radio>
                                            <Radio value={3}>
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.configuration.logic.customize
                                                }
                                            </Radio>
                                        </Radio.Group>
                                        <If condition={privates.compositeType === 3}>
                                            {/* <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} /> */}
                                            <Tabs defaultActiveKey="2">
                                                <TabPane
                                                    tab={
                                                        UdeskLocales.current.components.common
                                                            .customFilter.manualConfiguration
                                                    }
                                                    key="1"
                                                >
                                                    <InputUdesk
                                                        placeholder={
                                                            UdeskLocales.current.components.common
                                                                .customFilter.holderCustomRule
                                                        }
                                                        value={privates.operatorLogic}
                                                        onChange={actions.customJudgeLogicChange}
                                                    />
                                                    {/* <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} /> */}
                                                </TabPane>
                                                <TabPane
                                                    tab={
                                                        UdeskLocales.current.components.common
                                                            .customFilter.dragConfiguration
                                                    }
                                                    key="2"
                                                >
                                                    <DragDropContext
                                                        onDragEnd={actions.onDragEnd}
                                                        onDragStart={actions.onDragStart}
                                                    >
                                                        <div className="data-preprocess-custom">
                                                            <div className="data-preprocess-custom-drap-title">
                                                                {
                                                                    UdeskLocales.current.components
                                                                        .common.customFilter
                                                                        .condition
                                                                }
                                                                ：
                                                            </div>
                                                            <div className="data-preprocess-cutom-drap-info">
                                                                <Droppable
                                                                    droppableId="droppables"
                                                                    direction="horizontal"
                                                                    isDropDisabled={true}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            style={getListStyle(
                                                                                snapshot.isDraggingOver
                                                                            )}
                                                                        >
                                                                            {privates.conditionListFilter.map(
                                                                                (item, index) => (
                                                                                    <Draggable
                                                                                        key={
                                                                                            item.id
                                                                                        }
                                                                                        draggableId={
                                                                                            item.id
                                                                                        }
                                                                                        index={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        {(
                                                                                            provided,
                                                                                            snapshot
                                                                                        ) => (
                                                                                            <div
                                                                                                ref={
                                                                                                    provided.innerRef
                                                                                                }
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided
                                                                                                        .draggableProps
                                                                                                        .style
                                                                                                )}
                                                                                            >
                                                                                                {
                                                                                                    item.content
                                                                                                }
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                )
                                                                            )}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        </div>
                                                        <div className="data-preprocess-custom">
                                                            <div className="data-preprocess-custom-drap-title">
                                                                {
                                                                    UdeskLocales.current.components
                                                                        .common.customFilter
                                                                        .condition
                                                                }
                                                                ：
                                                            </div>
                                                            <div className="data-preprocess-cutom-drap-info">
                                                                <Droppable
                                                                    droppableId="droppabless"
                                                                    direction="horizontal"
                                                                    isDropDisabled={true}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            style={getListStyle(
                                                                                snapshot.isDragging
                                                                            )}
                                                                        >
                                                                            {privates.logicList
                                                                                .filter(
                                                                                    (item) =>
                                                                                        item.flag
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        item,
                                                                                        index
                                                                                    ) => (
                                                                                        <Draggable
                                                                                            key={
                                                                                                item.id
                                                                                            }
                                                                                            draggableId={
                                                                                                item.id
                                                                                            }
                                                                                            index={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {(
                                                                                                provided,
                                                                                                snapshot
                                                                                            ) => (
                                                                                                <div
                                                                                                    ref={
                                                                                                        provided.innerRef
                                                                                                    }
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provided
                                                                                                            .draggableProps
                                                                                                            .style
                                                                                                    )}
                                                                                                >
                                                                                                    {
                                                                                                        item.content
                                                                                                    }
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    )
                                                                                )}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        </div>
                                                        <div className="data-preprocess-custom">
                                                            {privates.tarskDel && (
                                                                <div className="index-manmagement-custom-drag-tarsk">
                                                                    {
                                                                        UdeskLocales.current
                                                                            .components.common
                                                                            .customFilter.infoDel
                                                                    }
                                                                </div>
                                                            )}
                                                            <div className="data-preprocess-custom-drap-title">
                                                                {
                                                                    UdeskLocales.current.components
                                                                        .common.customFilter
                                                                        .toConfigure
                                                                }
                                                                ：
                                                            </div>
                                                            <div className="data-preprocess-cutom-drap-info">
                                                                <Droppable
                                                                    droppableId="droppable"
                                                                    direction="horizontal"
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            style={getListDropStyle(
                                                                                snapshot.isDraggingOver
                                                                            )}
                                                                        >
                                                                            {this.privates.items.map(
                                                                                (item, index) => (
                                                                                    <Draggable
                                                                                        key={
                                                                                            item.id
                                                                                        }
                                                                                        draggableId={
                                                                                            item.id
                                                                                        }
                                                                                        index={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        {(
                                                                                            provided,
                                                                                            snapshot
                                                                                        ) => (
                                                                                            <div
                                                                                                ref={
                                                                                                    provided.innerRef
                                                                                                }
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided
                                                                                                        .draggableProps
                                                                                                        .style
                                                                                                )}
                                                                                            >
                                                                                                {
                                                                                                    item.content
                                                                                                }
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                )
                                                                            )}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        </div>
                                                    </DragDropContext>
                                                </TabPane>
                                            </Tabs>
                                        </If>
                                    </div>
                                </FormItem>
                            </If>
                            <FormItem label=" ">
                                <Form.Reset type="normal" onClick={actions.backToIndex}>
                                    {locales.labels.cancel}
                                </Form.Reset>
                                &nbsp;
                                <Form.Submit
                                    type="primary"
                                    onClick={actions.saveDataPreprocess}
                                    loading={loading}
                                >
                                    {locales.labels.save}
                                </Form.Submit>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
