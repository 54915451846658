import { useState, useEffect } from 'react';
import {
    getIntelligentPartnerCustomerPersonas,
    postIntelligentPartnerCustomerPersonas,
} from 'src/api/intelligentPartnerCustomerPersonas';
import { IntelligentPartnerCustomerPersonaFoundResponse } from 'src/api/types';
import {
    deleteIntelligentPartnerCustomerPersonasById,
    getIntelligentPartnerCustomerPersonasById,
    putIntelligentPartnerCustomerPersonasById,
} from 'src/api/intelligentPartnerCustomerPersonas/{id}';
import { postIntelligentPartnerCustomerPersonasBindFlow } from 'src/api/intelligentPartnerCustomerPersonas/bindFlow';

export const useCustomerPortraitConfig = (flowId) => {
    const [allCustomerPortrait, setAllCustomerPortrait] = useState<
        IntelligentPartnerCustomerPersonaFoundResponse[]
    >([]);
    const [loading, setLoading] = useState(true);
    const requestAllCustomerPortrait = () => {
        setLoading(true);
        getIntelligentPartnerCustomerPersonas()
            .then((resp) => {
                setAllCustomerPortrait(resp.data ?? []);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        requestAllCustomerPortrait();
    }, []);
    const createCustomerPortrait = (params) => {
        return postIntelligentPartnerCustomerPersonas(params).then(requestAllCustomerPortrait);
    };
    const updateCustomerPortrait = (id, params) => {
        return putIntelligentPartnerCustomerPersonasById(params, {
            segments: {
                id,
            },
        }).then(requestAllCustomerPortrait);
    };
    const deleteCustomerPortrait = (id) => {
        return deleteIntelligentPartnerCustomerPersonasById({
            segments: {
                id,
            },
        }).then(requestAllCustomerPortrait);
    };
    const requestCustomerPortraitDetail = (currentId) => {
        return getIntelligentPartnerCustomerPersonasById({
            segments: {
                id: currentId,
            },
        });
    };
    const batchBindFlow = (toBindCustomerPersonaIds) => {
        return postIntelligentPartnerCustomerPersonasBindFlow({
            flowId,
            toBindCustomerPersonaIds,
        }).then(requestAllCustomerPortrait);
    };
    return {
        allCustomerPortrait,
        createCustomerPortrait,
        updateCustomerPortrait,
        deleteCustomerPortrait,
        requestCustomerPortraitDetail,
        loading,
        batchBindFlow,
        flowId,
    };
};
