import React, { useState, useMemo, useRef, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Avatar } from 'udesk-ui';
import type { TableComponentProps } from 'Component/common/udesk-qa-table-page';
import { getUsers } from 'src/api/users';
import './style.scss';
import { GetUsersQuery } from 'src/api/types';
// import { BaseResponseHeadCountResponse, GetUsersQuery } from 'src/api/types';
import { putUsersByIdStatusByStatus } from 'src/api/users/{id}/status/{status}';
import { deleteUsersByIds } from 'src/api/users/{ids}';
// import { getUsersHeadcount } from 'src/api/users/headcount';
import { isGeneralSetting, isSalesSubApp } from 'Udesk/system/subApp';

const locales = Locales['current'];

interface DataNode {
    title: string;
    key: string;
    isLeaf?: boolean;
    children?: DataNode[];
    icon?: any;
    userId?: string;
}

interface FindAllOrigange extends GetUsersQuery {
    organizationId: number;
}

const AdminGongUser = React.memo((props: any) => {
    const ref = useRef<any>({});
    const { table: tableRef } = ref.current;
    const [selectedKey, setSelectedKey] = useState<string>(props.location?.state?.categoryId ?? '');
    const [detailModalInitialValues, setDetailModalInitialValues] = useState<any>({});
    const [detailModalVisible, setDetailModalVisible] = useState(false);
    const [keyWord, setKeyWord] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const expandedKeys = useRef<string[]>([]);
    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [detailModalLoading, setDetailModalLoading] = useState(false);
    // const [headcount, setHeadcount] = useState(0);
    // const [companyName, setCompanyName] = useState('');
    const [paging, setPaging] = useState({
        pageNum: 1,
        pageSize: 20,
        total: 0,
    });
    const treeProps = useMemo(() => {
        const onSelect = (selectedKeys, { selected, selectedNodes, node, event }) => {
            if (selected) {
                tableRef.clearSelectedRowKeys();
                setSelectedKey(node.key);
                const newPaging = {
                    ...paging,
                    pageNum: 1,
                };
                setPaging(newPaging);
                queryList({ categoryId: parseInt(node.key, 10), ...newPaging });
            }
        };
        const onAdd = (item) => {
            setDetailModalInitialValues({ pid: item.id });
            setDetailModalVisible(true);
        };
        const onEdit = (item) => {
            setDetailModalInitialValues({ ...item });
            setDetailModalVisible(true);
        };
        const onDelete = (item) => {
            let url = Udesk.business.apiPath.concatApiPath(`organizations/${item.id}`, props.sdkOptions);
            Udesk.ajax.del(url, {}).then(
                (resp) => {
                    Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                    queryTree();
                    // companyMember();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        };
        return {
            treeNodeExtra: [
                {
                    label: locales.components.pages.knowledgeBase.list.add,
                    onClick: onAdd,
                    disabled: (item) => item.level > 4 || (item.pid && item.defaultFlag),
                },
                {
                    label: locales.labels.edit,
                    disabled: (item) => item.defaultFlag,
                    onClick: onEdit,
                },
                {
                    label: locales.components.pages.knowledgeBase.list.delete,
                    danger: true,
                    disabled: (item) => item.defaultFlag,
                    onClick: onDelete,
                },
            ],
            treeData,
            blockNode: true,
            onSelect,
            selectedKeys: [selectedKey],
            showIcon: true,
        };
    }, [treeData, selectedKey, paging]);
    const queryList = ({ pageSize, pageNum, categoryId, keyword = keyWord }) => {
        setLoading(true);
        const params: FindAllOrigange = {
            pageNum,
            pageSize,
            keyword,
            organizationId: parseInt(categoryId, 10),
        };
        getUsers({
            params,
        }).then((resp) => {
            setDataSource(resp.data || []);
            setLoading(false);
            setPaging(resp.paging as any);
        });
    };
    const modalProps = useMemo(() => {
        const formConfig = {
            initialValues: detailModalInitialValues,
            fields: [
                {
                    name: 'name',
                    label: locales.components.pages.knowledgeBase.list.categoryName,
                    type: 'Input',
                    props: {
                        style: {
                            width: 300,
                        },
                    },
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            ],
            footerRender: false as false,
        };
        const onOk = async (values) => {
            setDetailModalLoading(true);
            if (detailModalInitialValues.id) {
                const params = {
                    id: detailModalInitialValues.id,
                    ...values,
                    parentId: detailModalInitialValues.parentId,
                };
                await editOrganizations(params);
            } else {
                if (!detailModalInitialValues.pid) return;
                const params = {
                    ...values,
                    parentId: detailModalInitialValues.pid,
                };
                await addOrganizations(params);
            }
            setDetailModalVisible(false);
            setDetailModalLoading(false);
            queryTree();
        };
        return {
            visible: detailModalVisible,
            loading: detailModalLoading,
            formConfig,
            onCancel: () => setDetailModalVisible(false),
            onOk,
        };
    }, [detailModalVisible, detailModalInitialValues, detailModalLoading]);
    const addOrganizations = (params) => {
        return new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(`organizations`, props.sdkOptions);
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(locales.components.pages.knowledgeBase.list.createSuccess);
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    };
    const editOrganizations = (params) => {
        return new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(`organizations/${params.id}`, props.sdkOptions);
            Udesk.ajax.put(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(locales.business.info.editSuccess);
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    };
    const queryTree = () => {
        let url = Udesk.business.apiPath.concatApiPath(`organizations`, props.sdkOptions);
        Udesk.ajax.get(url, {}).then(
            (resp) => {
                const newTreeData = getNode(resp.data);
                const getExpandedKeys = (source: any[] = [], level = 0) => {
                    if (level > 2) return [];
                    let ret = source.map((s) => s.key);

                    return [...ret, ...source.map((s) => getExpandedKeys(s.children, level + 1)).flat(Infinity)];
                };
                const expKeys = getExpandedKeys(newTreeData);
                expandedKeys.current = expKeys;
                setTreeData(newTreeData);
                if (Array.isArray(resp.data) && resp.data.length) {
                    queryList({
                        categoryId: selectedKey ? parseInt(selectedKey, 10) : newTreeData[0].id,
                        pageNum: 1,
                        pageSize: paging.pageSize,
                    });
                    if (newTreeData[0].id && !selectedKey) {
                        setSelectedKey(`${newTreeData[0].id}`);
                    }
                }
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    // const companyMember = () => {
    //     getUsersHeadcount().then((res:BaseResponseHeadCountResponse) => {
    //         // setHeadcount(res.data?.headcount??0);
    //         // setCompanyName(res.data?.companyName??'');
    //     });
    // };
    useEffect(() => {
        queryTree();
        // companyMember();
    }, []);
    useEffect(() => {
        setColumns([
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: locales.pages.gong.userManage.list.headPortrait,
                dataIndex: 'headPortraitUrl',
                key: 'headPortraitUrl',
                render: (name, item) => <Avatar src={item.headPortraitUrl || '/static/images/avatar.png'} />
            },
            {
                title: locales.pages.gong.userManage.list.fullName,
                dataIndex: 'realname',
                key: 'realname',
            },
            {
                title: locales.pages.gong.userManage.list.nickname,
                dataIndex: 'nickname',
                key: 'nickname',
            },
            {
                title: locales.pages.gong.userManage.list.excellentStaff,
                dataIndex: 'isExample',
                key: 'isExample',
                render: (name, item) => {
                    return item.isExample ? locales.pages.gong.userManage.list.yes : locales.pages.gong.userManage.list.no;
                },
            },
            {
                title: locales.pages.gong.userManage.list.department,
                dataIndex: 'organizationName',
                key: 'organizationName',
            },
            {
                title: locales.pages.gong.userManage.list.accountNumberemail,
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: locales.pages.gong.userManage.list.telephone,
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: locales.pages.gong.userManage.list.jobNumber,
                dataIndex: 'agentId',
                key: 'agentId',
            },
            {
                title: locales.pages.gong.userManage.list.role,
                dataIndex: 'role',
                key: 'role',
                render: (text, record) => {
                    if (!record.roleList || record.roleList.length === 0) {
                        return '';
                    }
                    return record.roleList[0].name;
                }
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: locales.fields.interactiveRecognition.status,
                renderType: 'switch',
                onSwitchChange: (item, index) => {
                    const { id, status } = item;
                    putUsersByIdStatusByStatus({ segments: { id, status: status ? 0 : 1 } }).then(() => {
                        let newData = [...dataSource];
                        newData[index].status = !newData[index].status;
                        setDataSource(newData);
                    });
                },
            },
            {
                key: 'actions',
                dataIndex: 'actions',
                title: locales.labels.action,
                renderType: 'actions',
                width: 180,
                onView:
                    Udesk.data.init.user 
                        ?   (item) => {
                            let routeOptions = {
                                history: props.history,
                                routeName: isGeneralSetting() ? 'generalSettingUserDetail' : isSalesSubApp() ? 'adminGongUserDetail' : 'adminUserView',
                                pathParams: { id: item.uuid },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        } : undefined,
                onEdit:
                    Udesk.data.init.user
                        ? (item) => {
                            let routeOptions = {
                                history: props.history,
                                routeName: isGeneralSetting() ? 'generalSettingUserEdit' : isSalesSubApp() ? 'adminGongUserEdit' : 'adminUserEdit',
                                pathParams: { id: item.uuid },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        }
                        : undefined,
                onDelete:
                    Udesk.data.init.user
                        ? (item) => {
                            deleteUsersByIds({
                                segments: {
                                    ids: item.id,
                                },
                                successMsg: locales.business.notifyMessage.deleteSuccess,
                            }).then(res => {
                                queryList({
                                    categoryId: parseInt(selectedKey, 10),
                                    keyword: keyWord,
                                    pageNum: 1,
                                    pageSize: paging.pageSize,
                                });
                                // companyMember();
                            });
                        }
                        : undefined,
            },
            {
                name: 'keyword',
                type: 'Input',
                props: {
                    placeholder: locales.pages.gong.userManage.list.pleaseEnterANameOrPhoneSearch,
                },
            },
        ]);
    }, [selectedKey, keyWord, dataSource]);
    // const tableHeaderLeftRender = (
    //     <p>{companyName}（{headcount}{locales.pages.gong.userManage.list.person}</p>
    // );
    const tableProps = useMemo<TableComponentProps>(() => {
        return {
            columns,
            dataSource,
            cacheKey: false,
            checkable: false,
        };
    }, [columns, dataSource]);
    const paginationProps = useMemo(() => {
        const { pageSize, total } = paging;
        return {
            current: paging.pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                const newPages = {
                    ...paging,
                    pageNum: page,
                    pageSize,
                };
                queryList({ ...newPages, categoryId: parseInt(selectedKey, 10) });
                setPaging(newPages);
            },
        };
    }, [paging.pageNum, paging.pageSize, paging.total, selectedKey]);
    const handlerEditUser = () => {
        let routeOptions = {
            history: props.history,
            routeName: isGeneralSetting() ? 'generalSettingUserEdit' : isSalesSubApp() ? 'adminGongUserEdit' : 'adminUserNew',
            pathParams: { id: -1 },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    return (
        <Page pageBodyClassName='page-user-manage-center' title={locales.menu.admin.user.text} padding={true}>
            <CommonTablePage
                loading={loading}
                // tableHeaderLeftRender={companyName ? tableHeaderLeftRender : null}
                ref={ref}
                mainButton={<Button onClick={handlerEditUser}>{locales.pages.gong.userManage.list.AddEmployee}</Button>}
                onReset={(params) => {
                    setKeyWord('');
                    queryList({
                        ...params,
                        keyword: '',
                        categoryId: parseInt(selectedKey, 10),
                        pageNum: 1,
                        pageSize: paging.pageSize,
                    });
                }}
                onSearch={(params) => {
                    setKeyWord(params.keyword ?? '');
                    queryList({
                        ...params,
                        categoryId: parseInt(selectedKey, 10),
                        pageNum: 1,
                        pageSize: paging.pageSize,
                    });
                }}
            >
                <CommonTablePage.Tree
                    {...treeProps}
                    defaultExpandedKeys={expandedKeys.current}
                    treeKey='knowledgeGroupTree'
                ></CommonTablePage.Tree>
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
                <CommonTablePage.Modal {...modalProps}></CommonTablePage.Modal>
            </CommonTablePage>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <AdminGongUser {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

const getNode = (arr = []) => {
    if (!arr || !arr.length) return [];
    const sortedArr = arr
        .map((i: any) => ({
            ...i,
            title: i.name,
            key: i.id,
            parentId: i.parentId * 1,
        }))
        .sort((a, b) => a.parentId - b.parentId);
    let root = sortedArr.filter((i) => i.parentId === sortedArr[0].parentId);
    let nodeMap = {};
    sortedArr.forEach((item) => {
        nodeMap[item.id] = item;
        if (nodeMap[item.parentId]) {
            if (Array.isArray(nodeMap[item.parentId].children)) {
                nodeMap[item.parentId].children.push(item);
            } else {
                nodeMap[item.parentId].children = [item];
            }
        }
    });
    return [...root];
};
