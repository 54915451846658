import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import EditorModal, { RefType } from './components/folder-tree/modal';
import { useFilterData, usePagination } from "../components";
import { createComponent } from 'src/util/component';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { EnumSelect } from "Component/common/enum-component";
import { Avatar, Space, Tag } from "udesk-ui";
import { useRequest } from 'src/util/hook';
import { useTree } from './components/folder-tree/util';
import { CommonFilterProps } from 'udesk_gm_ui/es/common-filter';
import { linkTo } from 'src/util/link';
import { createClassName, inspect } from 'src/util/core';
import { getDateTimeString } from 'src/util/moment';
import { Cover } from '../components/cover';
import { postReviewIntelligentPartnerCourseCopyById } from 'src/api/review/intelligentPartner/course/copy/{id}';
import './index.less';

const Template = React.memo((props: any) => {
    const {isEdit, history, storages, saveStorages} = props;
    const [pagination, setPagination] = usePagination(props);
    const [filterData] = useFilterData(props);
    const [tableData, setTableData] = useState<any[]>([]);
    const locales = UdeskLocales['current'];
    const editorModalRef = useRef<RefType>(null);
    const request = useRequest();
    const [loading, setLoading] = useState(false);
    const [
        treeData,
        getTreeData,
        classificationProps,
        setSelectedKey,
    ] = useTree({
        locales, isEdit, editorModalRef, request, storages, saveStorages
    });
    const prevFilterValue = useRef<any>({});

    const getTableData = (data) => {        
        const {
            filter, 
            pagination, 
            classificationId
        } = data;

        const { time, ...otherFilterValue } = filter || {};
        const [ startTime, endTime ] = time || [];

        saveStorages((storages) => {
            return {
                ...storages,
                filterData: filter,
                pagination: pagination,
                selectedKey: prevFilterValue.current.name !== filter.name ? [] : classificationId,
            };
        });

        const params = {
            ...otherFilterValue,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            categoryId: classificationId
        };
        if(prevFilterValue.current.name !== filter.name) {
            setSelectedKey([]);
            params.categoryId = undefined;
        }
        if (startTime) {
            params.startTime = startTime;
        }
        if (endTime) {
            params.endTime = endTime;
        }

        setLoading(true);
        request('/review/intelligentPartner/course' + inspect(isEdit, () => '', '/center'), params).then(
            resp => {
                setTableData(resp.data ?? []);
                setPagination({
                    total: resp.paging?.total,
                    current: resp.paging?.pageNum,
                });
                prevFilterValue.current = filter;
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        ).finally(() => {
            setLoading(false);
        });
    };

    const delCourseHandle = useCallback((course) => {
        return request(`/review/intelligentPartner/course/${course.id}`, {}, 'del').then(
            resp => {
                Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [
        locales.business.notifyMessage.deleteSuccess, 
        request
    ]);

    const tableInstance = useRef<any>(null);
    
    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 课程 */UdeskLocales['current'].pages.coach.learningCenter.course.index.curriculum,
                key: 'course',
                dataIndex: 'course',
                render (text, record) {
                    return (
                        <div className='task-item'>
                            <div className={'course-logo'}>
                                <Cover src={record.cover} courseType={record.courseType} />
                            </div>
                            <div className={'course-info'}>
                                <div>
                                    <Tag color={
                                        ['blue', 'orange'][record.status]
                                    }>
                                        {Udesk.enums.corseStatusType.getName(record.status)}
                                    </Tag>
                                    <span className={'text-nowrap'}>{record.name}</span>
                                </div>
                                <div>{/* 课程介绍： */}{UdeskLocales['current'].pages.coach.learningCenter.course.index.courseIntroduction}{record.introduce}
                                </div>
                                <Space>
                                    <span>{/* 创建时间： */}{UdeskLocales['current'].pages.coach.learningCenter.course.index.createdOn}{getDateTimeString(record.createTime, 'YYYY-MM-DD HH:mm')}</span>
                                    <span>{record.publishTaskNum ? `${/* 已发布 */UdeskLocales['current'].pages.coach.learningCenter.course.index.published}${record.publishTaskNum}${/* 个任务 */UdeskLocales['current'].pages.coach.learningCenter.course.index.tasks}`: /* 未发布任务 */UdeskLocales['current'].pages.coach.learningCenter.course.index.unpublishedTasks}</span>
                                </Space>
                            </div>
                        </div>
                    );
                }
            },
            inspect(isEdit, () => ({
                title: /* 任务 */UdeskLocales['current'].pages.coach.learningCenter.course.index.task,
                key: 'actions',
                renderType: 'actions',
                buttons: [
                    {
                        label: /* 发布任务 */UdeskLocales['current'].pages.coach.learningCenter.course.index.publishTask,
                        disabled: (record) => record.status === Udesk.enums.corseStatusType.notStarted.id,
                        onClick: (item) => {
                            linkTo(history, 'coachTaskCenterEdit', {id: 0}, {}, {lessonId: item.id});
                        },
                    },
                    {
                        label: /* 任务数据 */UdeskLocales['current'].pages.coach.learningCenter.course.index.taskData,
                        disabled: (record) => record.status === Udesk.enums.corseStatusType.notStarted.id,
                        onClick: (item) => {
                            linkTo(history, 'coachTaskCenterList', {}, {}, {lessonId: item.id});
                        },
                    },
                ]
            }), () => ({
                title: /* 操作 */UdeskLocales['current'].pages.coach.learningCenter.course.index.operation,
                key: 'actions',
                width: 170,
                fixed: 'right',
                render (text, record) {
                    return (
                        <Space className='task-item-toolbar'>
                            <TableRowButton 
                                count={record.finishCourseMaterialNum ?? 0}
                                maxCount={record.totalCourseMaterialNum ?? 0}
                                isHiddenStatistics={!record.totalCourseMaterialNum}
                                onClick={() => {
                                    linkTo(history, 'coachLearningCenterCourseLearn', {
                                        id: record.id,
                                    });
                                }}
                            >{/* 学 */}{UdeskLocales['current'].pages.coach.learningCenter.course.index.learn}</TableRowButton>
                            <TableRowButton
                                count={record.finishPracticeTaskNum ?? 0}
                                maxCount={record.totalPracticeTaskNum ?? 0}
                                isDisabled={!record.totalPracticeTaskNum}
                                isHiddenStatistics={!record.totalPracticeTaskNum}
                                onClick={() => {
                                    linkTo(history, 'coachLearningCenterExerciseTask', {}, {}, {lessonId: record.id});
                                }}
                            >{/* 练 */}{UdeskLocales['current'].pages.coach.learningCenter.course.index.practice}</TableRowButton>
                            <TableRowButton 
                                count={record.finishExamTaskNum ?? 0}
                                maxCount={record.totalExamTaskNum ?? 0}
                                isDisabled={!record.totalExamTaskNum}
                                isHiddenStatistics={!record.totalExamTaskNum}
                                onClick={() => {
                                    linkTo(history, 'coachLearningCenterTestTask', {}, {}, {lessonId: record.id});
                                }}
                            >{/* 考 */}{UdeskLocales['current'].pages.coach.learningCenter.course.index.examination}</TableRowButton>
                            {/* <TableRowButton
                                isHiddenStatistics={true}                
                                onClick={() => {
                                    linkTo(history, 'coachLearningCenterTestTask', {}, {}, {lessonId: record.id});
                                }}
                            >数据</TableRowButton> */}
                        </Space>
                    );
                }
            })),
            inspect(isEdit, () => ({
                title: /* 操作 */UdeskLocales['current'].pages.coach.learningCenter.course.index.operation,
                key: 'actions1',
                renderType: 'actions',
                buttons: [
                    {
                        label: /* 编辑 */UdeskLocales['current'].pages.coach.learningCenter.course.index.edit,
                        onClick: (item) => {
                            linkTo(history, 'courseManagementFlow', {
                                id: item.id
                            });
                        },
                    },
                    {
                        label: /* 复制 */UdeskLocales['current'].pages.coach.learningCenter.course.index.copy,
                        onClick: (item) => {
                            postReviewIntelligentPartnerCourseCopyById({
                                segments: {
                                    id: item.id
                                },
                                successMsg: /* 复制成功 */UdeskLocales['current'].pages.coach.learningCenter.course.index.successfullyCopied
                            }).then(() => {
                                tableInstance.current?.filterInstance?.formInstance?.submit?.();
                            });
                        },
                    },
                    {
                        label: /* 删除 */UdeskLocales['current'].pages.coach.learningCenter.course.index.delete,
                        danger: true,
                        confirm: {
                            title: /* 删除后此课程下的所有任务数据都将被清空，是否确认删除课程？ */UdeskLocales['current'].pages.coach.learningCenter.course.index.afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse
                        },
                        onClick: delCourseHandle,
                    },
                ]
            }))
        ].filter(Boolean);
        return {
            columns,
            dataSource: tableData,
            pagination,
            loading,
        };
    }, [
        delCourseHandle, 
        tableData, 
        pagination, 
        history, 
        isEdit,
        loading,
    ]);

    const filter = useMemo<CommonFilterProps>(() => {
        return {
            customComponents: {
                EnumSelect
            },
            fields: [
                {
                    name: 'name',
                    label: /* 课程名称 */UdeskLocales['current'].pages.coach.learningCenter.course.index.courseName,
                    type: 'Input'
                },
                {
                    name: 'time',
                    label: /* 创建时间 */UdeskLocales['current'].pages.coach.learningCenter.course.index.creationTime,
                    type: 'RangePicker',
                    props: {
                        showTime: true,
                        style: {
                            width: 340,
                        }
                    }
                },
                {
                    name: 'status',
                    label: /* 发布状态 */UdeskLocales['current'].pages.coach.learningCenter.course.index.releaseStatus,
                    type: 'EnumSelect',
                    props: {
                        width: 130,
                        enumKey: 'corseStatusType',
                        empty: {
                            label: /* 全部 */UdeskLocales['current'].pages.coach.learningCenter.course.index.whole
                        }
                    }
                },
            ].filter((item, index) => {
                if (!isEdit) {
                    return index < 2;
                }
                return true;
            }) as CommonFilterProps['fields'],
            initialValues: filterData,
        };
    }, [
        filterData, 
        isEdit
    ]);

    useEffect(() => {
        $('.udesk-qa-ui-common-filter').on('click', '.udesk-qa-ui-btn-default', () => {
            return !saveStorages({
                filterData: {}
            });
        });
    }, [
        saveStorages
    ]);

    return (
        <Page
            pageClassName='learning-center-record'
            pageBodyClassName='learning-center-record-body'
            backGroundGhost={true}
            title={/* 课程 */UdeskLocales['current'].pages.coach.learningCenter.course.index.curriculum}
        >
            <div className='page-content'>
                <StandardizeTable.RequestTable
                    ref={tableInstance}
                    onRequest={getTableData}
                    table={tableProps}
                    classification={classificationProps as StandardizeTableProps['classification']}
                    filter={filter}
                    toolBarRightButtons={
                        isEdit 
                            ? [
                                {
                                    label: /* 新建 */locales.pages.gong.konwledgeBase.index.newlyBuild,
                                    onClick: () => {
                                        linkTo(history, 'courseManagementCreate', {}, {}, {
                                            classification: (classificationProps as StandardizeTableProps['classification'])?.selectedKeys?.[0]
                                        });
                                    },
                                    mainBtn: true,
                                },
                            ].filter(Boolean) 
                            : undefined
                    }
                />
            </div>

            <EditorModal ref={editorModalRef} {...{getTreeData, locales, request, treeData}}></EditorModal>

        </Page>
    );
});

export const LearningCenterCourse = createComponent(
    Template,
    undefined,
    [
        /^\/coach\/learning-center\/course\/learn\/*/i,
        /^\/coach\/learning-center\/task\/exercise\/*/i,
    ],
    {
        isEdit: false,
    }
);

export const LearningCenterCourseConfig = createComponent(
    Template,
    undefined,
    [
        /^\/coach\/course-management\/detail\/*/i,
        /^\/coach\/course-management\/flow\/*/i,
        /^\/coach\/learning-center\/record\/*/i,
        /^\/coach\/task-center\/edit\/*/i,
    ],
    {
        isEdit: true,
    }
);


const TableRowButton = React.memo<any>(props => {
    const {
        count = 0, maxCount = 0, 
        isDisabled = false,
        isHiddenStatistics = false,
        onClick, 
        children, 
        ...otherProps
    } = props;

    return (
        <div 
            className={
                createClassName('table-row-button', {
                    'hidden-statistics': isHiddenStatistics,
                    'disabled': isDisabled
                })
            }
            onClick={isDisabled ? $.noop : onClick} 
        >
            <Avatar {...otherProps}>{children}</Avatar>
            <Space size={0}>
                <span>{count}</span>
                <span>{'/'}</span>
                <span>{maxCount}</span>
            </Space>
        </div>
    );
});