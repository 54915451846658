import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import ScoreRecordButton from './components/ScoreRecord';
import isEqual from 'lodash-es/isEqual';
import cloneDeep from 'lodash-es/cloneDeep';
import { OverView } from './components/OverView';
import { Button, Space, Tabs } from 'udesk-ui';
import { ScoreDetailProvider } from './components/Context';
import { TaskTitle } from './components/TaskTitle';
import { Score } from './components/Score';
import { Dialogue, RefType } from 'Component/pages/components/coach/Dialogue';
import { ScoreDetailOpenButton } from './components/OpenButton';
import { linkTo } from 'src/util/link';
import { useDataPath, useLiveEffect, useRequest } from 'src/util/hook';
import { fire, inspect, isDefined } from 'src/util/core';
import { IntelligentPartnerSentence } from 'src/api/types';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { HistoricalLearningRenderCountContext } from 'src/pages/home';
import { DoubleRightArrowOutlined } from '@udesk/icons';
import { ComprehensiveEvaluation } from './components/ComprehensiveEvaluation';
import './style.scss';

const formatItemRenderProps = (data) => {
    if (data) {
        data.scoreAnalysis?.forEach(item => {
            if (item.traineeWordsResult) {
                return $.extend(true, item, {
                    traineeWordsResult: {
                        // 是否是追问， 默认false：不是追问
                        isEnableQuestionClosely: isDefined(item.isEnableQuestionClosely, item.isEnableQuestionClosely, false),
                        // 追问是否得分，默认true：得分
                        isQuestionCloselyScore: isDefined(item.isQuestionCloselyScore, item.isQuestionCloselyScore, true),
                        // 是否已经完成审核  默认false，从审核列表转跳得分详情页面，根据该值，可置灰"保存修改"按钮，已审核的记录不允许再次修改得分
                        isFinishReview: isDefined(data.isFinishReview, !!data.isFinishReview, false),
                        // 是否已经完成复核 默认false，从复核列表转跳得分详情页面，根据该值，可置灰"复核提交"按钮，已经复核的记录不允许再次复核
                        isFinishChecked: isDefined(data.isFinishChecked, !!data.isFinishChecked, false),
                        // 是否复核中 默认false，学员从历史记录查看得分详情，如果该练习有申诉记录正在复核中，不允许提交评分申诉，用来置灰该申诉按钮
                        isInChecked: isDefined(data.isInChecked, !!data.isInChecked, false),
                    }
                });
            }
        });
    }
};

const Template = React.memo((props: any) => {
    // const locales = Locales['current'];
    const recordId = parseInt(props.match.params.id, 10);
    const {location, history} = props;
    const [detail, setDetail] = useState<any>({});
    const [scoreAnalysisDataSource, setScoreAnalysisDataSource] = useState<IntelligentPartnerSentence[]>();
    const [isAllOpen, setIsAllOpen] = useState(true);
    const [tabKey, setTabKey] = useState('1');
    const {
        data: scoreAnalysis,
        setPropValue: setPropValueHandle, 
        getPropValue
    } = useDataPath(detail?.scoreAnalysis);
    const {
        getPropValue: getPropValueWithDataSource
    } = useDataPath(scoreAnalysisDataSource);
    const [scoreRecordData, setScoreRecordData] = useState<any[]>();
    const request = useRequest();
    const dialogueRef = useRef<RefType>(null);
    const isAudit = isDefined(props.match.params?.auditId); // 审核详情
    const isReview = isDefined(props.match.params?.reviewId); // 复合详情
    const isEdit = isAudit || isReview; // 历史记录详情
    const updateHistoricalLearningRenderCount = useContext(HistoricalLearningRenderCountContext);

    const goBack = useCallback(() => {
        if (location?.state?.backRouteParams) {
            linkTo(history, ...location.state.backRouteParams);
        } else {
            history.goBack();
        }
    }, [history, location?.state?.backRouteParams]);

    const setPropValue = useCallback((path, value) => {
        const data = setPropValueHandle(path, value);
        if (path.indexOf('..') === -1) {
            request(`/intelligentPartner/${props.match.params.id}/score/recalculate`, data, 'post').then(
                resp => {
                    formatItemRenderProps(resp.data);
                    setDetail(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [
        props.match.params.id, 
        request, 
        setPropValueHandle,
    ]);

    const submitHandle = useCallback(() => {
        if (isReview) {
            const scoreAnalysisClone = cloneDeep(scoreAnalysis);
            scoreAnalysisClone.forEach(item => {
                if (item.appealContent) {
                    item.appealContent.scoringStatus = 2;
                }
            });
            request(`/intelligentPartner/check/record/${props.match.params.reviewId}/submit`, scoreAnalysisClone, 'put').then(
                resp => {
                    fire(updateHistoricalLearningRenderCount, () => {
                        linkTo(history, getCurrentRouteName('taskCenterReview'));
                    });
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
        if (isAudit) {
            request(`/intelligentPartner/review/${props.match.params.auditId}/record/updateScore`, scoreAnalysis, 'put').then(
                resp => {
                    fire(updateHistoricalLearningRenderCount, () => {
                        linkTo(history, getCurrentRouteName('taskCenterAudit'));
                    });
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
        // 学员申诉提交
        if (!isEdit) {
            request(`/intelligentPartner/appeal/${props.match.params.id}/submit`, scoreAnalysis, 'post').then(
                resp => {
                    linkTo(history, getCurrentRouteName('learningCenterRecord'));
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [
        isAudit, 
        isEdit, 
        isReview, 
        props.match.params.reviewId, 
        props.match.params.auditId, 
        props.match.params.id, 
        request, 
        scoreAnalysis,
        history,
        updateHistoricalLearningRenderCount,
    ]);

    useLiveEffect((success) => {
        const params: any = {};
        if (isReview) {
            params.checkId = props.match.params?.reviewId; // 复核任务记录ID, 后台与前端所用字段不一致，前端用reviewId， 后端用checkId
        }
        if (isAudit) {
            params.reviewId = props.match.params?.auditId; // 审核记录ID，后台与前端所用字段不一致，前端用auditId， 后端用reviewId
        }

        request(`/intelligentPartner/${props.match.params.id}/scorePageDetail`, params).then(
            resp => {
                success(() => {
                    formatItemRenderProps(resp.data);
                    setDetail(resp.data ?? {});
                    setScoreAnalysisDataSource(resp.data?.scoreAnalysis);
                });
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [
        props.match.params?.id, 
        props.match.params?.reviewId,
        isReview
    ]);

    useLiveEffect((success) => {
        request(`/intelligentPartner/${props.match.params.id}/scoring/list`).then(
            resp => {
                success(() => {
                    setScoreRecordData(resp.data.reverse());
                });
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [
        request, 
        props.match.params.id
    ]);
    console.log(scoreAnalysisDataSource, scoreAnalysis);
    return (
        <Page
            pageBodyClassName="udesk-coach-web-learning-center-record-detail"
            title={/* 评分 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.index.score}
            padding={0}
            backGroundGhost={true}
            onBack={goBack}
            extra={[
                <ScoreRecordButton data={scoreRecordData}/>,
                inspect(!isEdit, () => [
                    <Button disabled={!(!detail?.isInChecked && !isEqual(scoreAnalysisDataSource, scoreAnalysis))} type="primary" onClick={() => submitHandle()}>{/* 提交评分申诉 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.index.submitARatingAppeal}</Button>,
                ]),
                inspect(isReview, () => [
                    <Button disabled={!!detail?.isFinishChecked} type="primary" onClick={() => submitHandle()}>{/* 复核提交 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.index.reviewSubmission}</Button>,
                ]),
                inspect(isAudit, () => [
                    <Button disabled={!(!detail?.isFinishReview && !isEqual(scoreAnalysisDataSource, scoreAnalysis))} type="primary" onClick={() => submitHandle()}>{/* 保存修改 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.index.saveChanges}</Button>,
                ])
            ]}
        >
            <ScoreDetailProvider value={detail}>
                <div className="udesk-coach-web-learning-center-record-detail-left">
                    <TaskTitle />
                    <ComprehensiveEvaluation recordId={recordId} />
                    <OverView />
                </div>
                <div className="udesk-coach-web-learning-center-record-detail-right">
                    <Tabs
                        activeKey={tabKey}
                        onChange={(activeKey) => setTabKey(activeKey)}
                        tabBarExtraContent={
                            tabKey === '1' ? null : (
                                <ScoreDetailOpenButton
                                    isAllOpen={isAllOpen}
                                    setIsAllOpen={setIsAllOpen}
                                />
                            )
                        }
                    >
                        <Tabs.TabPane tab={/* 得分情况 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.index.scoringSituation} key="1">
                            <Score
                                onItemClick={(nodeId) => {
                                    setTabKey('2');
                                    requestIdleCallback(() => {
                                        dialogueRef.current?.scrollTo?.(
                                            detail.scoreAnalysis?.findIndex(
                                                (i) => i.nodeId === nodeId
                                            ) || 0
                                        );
                                    });
                                }}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane className='udesk-coach-components-dialogue-tab' tab={/* 对话解析 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.index.dialogueAnalysis} key="2">
                            {inspect(isReview, () => <Tools {...{scoreAnalysis, dialogueRef}}/>)}
                            <Dialogue
                                ref={dialogueRef}
                                dialogueSentenceList={scoreAnalysis}
                                itemRender={(props, index) => {
                                    return (
                                        <React.Fragment>
                                            <Dialogue.Item
                                                isChangeMatchResult={!isEqual(scoreAnalysis?.[index], scoreAnalysisDataSource?.[index])}
                                                isAudit={isAudit}
                                                isReview={isReview}
                                                isEdit={
                                                    inspect(isEdit, () => {
                                                        if (isReview) {
                                                            return !detail?.isFinishChecked && !!props.appealContent;
                                                        }
                                                        if (isAudit) {
                                                            return !detail?.isFinishReview;
                                                        }
                                                        return true;
                                                    })
                                                }
                                                setPropValue={(path, value) => setPropValue(`${index}.traineeWordsResult.${path}`, value)} 
                                                getPropValue={(path) => getPropValue(`${index}.traineeWordsResult.${path}`)} 
                                                getPropValueWithDataSource={(path) => getPropValueWithDataSource(`${index}.traineeWordsResult.${path}`)} 
                                                isAllOpen={isAllOpen} index={index} 
                                                {...props} 
                                            />
                                        </React.Fragment>
                                    );
                                }}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </ScoreDetailProvider>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

const Tools = React.memo<any>((props) => {
    const {scoreAnalysis, dialogueRef} = props;
    const [index, setIndex] = useState(0);
    const item = scoreAnalysis[index];
    const list = scoreAnalysis.filter(item => isDefined(item.appealContent));
    const firstIndex = scoreAnalysis.findIndex(item => isDefined(item.appealContent));
    const isFist = list[0] === item;
    const isLast = list[list.length - 1] === item;

    const getNextIndex = useCallback((reversed, start) => {
        for(
            let index = inspect(reversed, start - 1, start + 1); 
            inspect(reversed, () => index > -1, () => index < scoreAnalysis.length); 
            inspect(reversed, () => index--, () => index++)
        ) {
            if (scoreAnalysis[index].appealContent) {
                return index;
            }
        }
        return inspect(reversed, scoreAnalysis.length - 1, 0);
    }, [
        scoreAnalysis
    ]);

    const scrollTo = useCallback((index) => {
        setIndex(index);
    }, []);

    useEffect(() => {
        isDefined(firstIndex, () => {
            scrollTo(firstIndex);
        });
    }, [firstIndex, scrollTo]);

    useEffect(() => {
        dialogueRef.current.scrollTo(index);
    }, [
        dialogueRef, 
        index
    ]);

    return inspect(list.length > 1 && isDefined(item?.appealContent), () => {
        return (
            <Space direction='vertical' className='udesk-coach-components-dialogue-tools'>
                <Button 
                    onClick={() => scrollTo(getNextIndex(true, index))} 
                    disabled={isFist} 
                    icon={<DoubleRightArrowOutlined rotate={-90} />}
                    shape="round"
                >
                    {/* 上一句 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.index.previousSentence}
                </Button>
                <Button 
                    onClick={() => scrollTo(getNextIndex(false, index))} 
                    disabled={isLast} 
                    icon={<DoubleRightArrowOutlined rotate={90} />}
                    shape="round"
                >
                    {/* 下一句 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.index.nextSentence}
                </Button>
            </Space>
        );
    });
});
