import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import {
    Button,
    Space,
    Table,
    Form,
    Select,
    Input,
    Icon,
    Tooltip,
    Row,
    Col,
    Carousel,
    Popover,
    Modal,
    InputNumber,
    TreeSelect,
    Tag,
} from 'udesk-ui';
import './index.scss';
import { getReviewCallCustomerMessageFindall } from 'src/api/review/call/customer/message/findall';
import { GetReviewCallCustomerMessageFindallQuery } from 'src/api/types';
import { getOrganizations } from 'src/api/organizations';
import { getUsers } from 'src/api/users';
import moment from 'moment';
import { postRiskConfigs } from 'src/api/riskConfigs';
import { getRiskConfigsFindByCompany } from 'src/api/riskConfigs/findByCompany';
import { getSmartTagsList } from 'src/api/smartTags/list';
import Circle from '../components/circle';

const locales = UdeskLocales['current'];
const { Option } = Select;
const CURRENT_NUM = 1;
const CURRENT_SIZE = 10;

const createCustomerTag = (tag) => (
    <Tag
        key={tag.id}
        style={{
            borderWidth: tag.borderWidth, 
            marginBottom: 4,
            background: tag.categoryColor + '1a',
            color: tag.categoryColor,
            maxWidth: '100%',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
        }}
        color={tag.categoryColor}
        title={tag.tagName}
    >
        {tag.tagName}
    </Tag>
);

const Template = React.memo((props: any) => {
    let currentDay = moment().endOf('day').format('YYYY-MM-DD 23:59:59');
    const [form] = Form.useForm();
    const [riskForm] = Form.useForm();
    const carouselRef: any = React.createRef();
    const [loading, setLoading] = useState(false);
    const [lastTime, setLastTime] = useState(currentDay);
    const [dataLastTime, setDataLastTime] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [isShowRisk, setIsShowRisk] = useState(false);
    const [semanticsList, setSemanticsList] = useState<any[]>([]);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any[]>([]);
    const [searchCondition, setSearchCondition] = useState({});
    const [organizationList, setOrganizationList] = useState<any[]>([]);
    const [userList, setUserList] = useState<any[]>([]);
    const [customerLabelList, setCustomerLabelList] = useState<any[]>([]);
    const [outerId, setOuterId] = useState<any>(null);
    const [innerId, setInnerId] = useState<any>(null);
    const columns = [
        {
            title: locales.pages.gong.saleClientCenter.list.index.customer,
            dataIndex: 'customerName',
            key: 'customerName',
            ellipsis: true,
        },
        {
            title: locales.pages.gong.saleClientCenter.list.index.risk,
            dataIndex: 'riskCount',
            key: 'riskCount',
            width: 90,
            render: (text, record) => {
                const content = (
                    <div className='pages-gong-sale-client-center-risk-tips-text'>
                        {record?.isNotActive === 1 && (
                            <p>
                                <i></i>
                                {locales.pages.gong.saleClientCenter.list.index.exceed}
                                {record.timeoutDayCounts}
                                {locales.pages.gong.saleClientCenter.list.index.noActivityForMoreThanDays}
                            </p>
                        )}
                        {record?.isRisk === 1 && (
                            <p>
                                <i></i>
                                {locales.pages.gong.saleClientCenter.list.index.lately}
                                {record.riskDayCounts}
                                {locales.pages.gong.saleClientCenter.list.index.risksOccurredInTheLastDays}
                            </p>
                        )}
                    </div>
                );
                return (
                    <>
                        {record?.isNotActive === 1 || record?.isRisk === 1 ? (
                            <Popover placement='bottom' content={content}>
                                <span className='risk-tips'>
                                    <Space>
                                        <Icon style={{ color: '#F09A00', fontSize: 14 }} type='tishi'></Icon>
                                        <p style={{ fontSize: 14, color: '#F09A00' }}>{record.riskCount ?? 0}</p>
                                    </Space>
                                </span>
                            </Popover>
                        ) : (
                            <span className='risk-tips'>
                                <Space>
                                    <Icon style={{ color: '#F09A00', fontSize: 14 }} type='tishi'></Icon>
                                    <p style={{ fontSize: 14, color: '#F09A00' }}>{record.riskCount ?? 0}</p>
                                </Space>
                            </span>
                        )}
                    </>
                );
            },
        },
        {
            title: locales.pages.gong.saleClientCenter.list.index.followUp,
            dataIndex: 'dataCallAnalysisList',
            key: 'dataCallAnalysisList',
            width: 500,
            className: 'sale-client-center-list-echarts-container',
            render: (text, record, index) => {
                let obj = {
                    children: (
                        <div className='carousel-detail'>
                            <Button
                                className='prev-btn'
                                onClick={handlerPrevCarousel}
                                type='text'
                                icon={<Icon type='ic-arrow-left-s-line'></Icon>}
                            ></Button>
                            {currentDay === dataLastTime ? (
                                <Button
                                    className='next-btn'
                                    type='text'
                                    icon={<Icon type='ic-arrow-right-s-youjiantou' style={{ color: '#ccc' }}></Icon>}
                                ></Button>
                            ) : (
                                <Button
                                    className='next-btn'
                                    onClick={handlerNextCarousel}
                                    type='text'
                                    icon={<Icon type='ic-arrow-right-s-youjiantou'></Icon>}
                                ></Button>
                            )}
                            <Carousel className='custom-carousel-container-class-name' ref={carouselRef} dots={false}>
                                <div key={1} className='carousel-detail-container'>
                                    {chartData.map((item, idx) => {
                                        return (
                                            <div key={item.date + idx} className='carousel-detail-day'>
                                                <h3>{item.date}</h3>
                                                {item?.children?.map((data, index) => {
                                                    return (
                                                        <div key={data.customerId}>
                                                            {data.children?.map((record) => {
                                                                return (
                                                                    <Circle data={record} key={record.id} />
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </Carousel>
                        </div>
                    ),
                    props: {
                        rowSpan: dataSource.length,
                    },
                };
                if (index === 0) {
                    obj.props.rowSpan = dataSource.length;

                    clearTimeout(record.timer);
                    record.timer = setTimeout(() => {
                        const $table = $('.sale-client-center-list-echarts-container').parents('table');
                        const $trList = $('tbody tr', $table);
                        const $divList = $('.carousel-detail-day', $table);
                        const heightList = $trList.map((index, tr) => $(tr).height()! - 1);

                        $divList.each((index, div) => {
                            $('> div', div).each((index, div) => {
                                $(div).height(heightList[index] as any);
                            });
                        });
                    }, 100);

                } else {
                    obj.props.rowSpan = 0;
                }

                return obj;
            },
        },
        {
            title: locales.components.pages.intelligentTags.customerLabelTitle,
            dataIndex: 'customerTagList',
            key: 'customerTagList',
            width: 200,
            render: (name, item, index) => {
                return (
                    <div>
                        {Array.isArray(name) ? (
                            <div>
                                {name.filter((item, i) => i < 3).map(createCustomerTag)}

                                {name.length > 3 && (
                                    <Tooltip
                                        placement='topLeft'
                                        title={<div>{name.filter((item, i) => i >= 3).map(createCustomerTag)}</div>}
                                        color='#fff'
                                    >
                                        {
                                            createCustomerTag({
                                                id: -1,
                                                borderWidth: 0,
                                                background: '#fafafa',
                                                color: '#1b6dff',
                                                tagName: `+${name.length - 3}`
                                            })
                                        }
                                    </Tooltip>
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                );
            },
        },
        {
            title: /* 跟进建议 */UdeskLocales['current'].pages.gong.saleClientCenter.list.index.followUpSuggestions,
            dataIndex: 'followUpSuggestions',
            key: 'followUpSuggestions',
            ellipsis: true,
            render (text) {
                return (
                    <div style={{position: 'relative'}}>
                        <Tooltip
                            zIndex={1}
                            placement={'bottom'}
                            title={
                                <div>
                                    <div dangerouslySetInnerHTML={{__html: text}} />
                                </div>
                               
                            } 
                            trigger="click"
                            getPopupContainer={(triggerNode) => {
                                return $(triggerNode).parents('table').get(0) as any;
                            }}
                        >
                            <div 
                                style={{
                                    width: '100%',
                                    maxHeight: 60,
                                    overflow: 'hidden'
                                }} 
                                dangerouslySetInnerHTML={{__html: text}} />
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: locales.pages.gong.saleClientCenter.list.index.personInCharge,
            dataIndex: 'ownerName',
            key: 'ownerName',
            ellipsis: true,
        },
        {
            title: locales.pages.gong.saleClientCenter.list.index.recentFollowup,
            dataIndex: 'userName',
            key: 'userName',
            ellipsis: true,
        },
        {
            title: locales.pages.gong.saleClientCenter.list.index.lastContactTime,
            dataIndex: 'callTime',
            key: 'callTime',
            ellipsis: true,
        },
        {
            title: locales.pages.gong.saleClientCenter.list.index.operation,
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <>
                        {record.customerId && (
                            <Button type='link' onClick={handlerDetail.bind(null, record)}>
                                {locales.pages.gong.saleClientCenter.list.index.see}
                            </Button>
                        )}
                    </>
                );
            },
        },
    ];
    const [page, setPage] = useState({
        current: CURRENT_NUM,
        pageSize: CURRENT_SIZE,
        total: 0,
    });
    const handlerPageChange = (current, pageSize) => {
        setPage({
            ...page,
            current,
            pageSize,
        });
        getCustomDataList(current, pageSize);
    };
    const handlerDetail = (record) => {
        const routeOptions = {
            history: props.history,
            routeName: 'saleClientDetail',
            pathParams: { id: record.customerId },
            state: {
                data: {
                    customerId: record.customerId, // 客户ID
                    customerName: record.customerName, // 客户名称
                    followUpSuggestions: record.followUpSuggestions, // 跟进建议
                    followUpDays: record.followUpDays, // 跟进天数
                    ownerName: record.ownerName, // 负责人
                    userName: record.userName, // 最近跟进人
                    customerTagList: record.customerTagList, // 客户标签
                },
                searchCondition,
                page: {
                    current: page.current,
                    pageSize: page.pageSize,
                },
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    const handlerRiskAdd = () => {
        setSemanticsList([]);
        getRiskConfigsFindByCompany().then((res) => {
            setOuterId(res.data?.id);
            setInnerId(res.data?.riskTag?.id);
            setIsShowRisk(true);
            riskForm &&
                riskForm.setFieldsValue({
                    timeoutDayCounts: res.data?.timeoutDayCounts,
                    riskDayCounts: res.data?.riskDayCounts,
                });
            let tagList: any = [];
            res.data?.conditionListAfterSort?.forEach((item) => {
                item?.operatorList?.forEach((record) => {
                    let tags: any = [
                        {
                            content: record?.semanticTag?.sentence,
                            standardFlag: 1,
                            groupId: record?.semanticTag?.semanticId,
                        },
                    ];
                    tagList.push({
                        id: record?.semanticTag?.semanticId,
                        title: record?.semanticTag?.semanticName,
                        tags,
                    });
                });
            });
            setSemanticsList(tagList);
        });
    };
    const handlerAddTags = () => {
        props.SmartToolBarRef.current.actions.open(handlerSelectTags, '');
        props.SmartToolBarRef.current.actions.seleteTag('3');
        if (props.SmartToolBarRef.current.privates.isFront) {
            props.SmartToolBarRef.current.actions.backToIndex();
        }
    };
    const handlerSelectTags = (tag) => {
        let newTags = [tag, ...semanticsList];
        setSemanticsList(newTags);
    };
    const handlerDelete = (id) => {
        let newTags = [...semanticsList];
        newTags.forEach((item, index) => {
            if (id === item.id) {
                newTags.splice(index, 1);
            }
        });
        setSemanticsList(newTags);
    };
    const handlerSave = () => {
        riskForm.validateFields().then((values) => {
            let conditionList: any = [];
            semanticsList.forEach((item) => {
                conditionList.push({
                    operatorList: [
                        {
                            keyword: item.id,
                        },
                    ],
                });
            });
            const params = {
                id: outerId,
                riskTagCreateRequest: {
                    id: innerId,
                    ruleList: [{ formula: 'R1', judgeStrategy: 2 }],
                    tagType: 2,
                    funcType: 'REVIEW_CALL',
                    conditionList,
                },
                ...values,
            };
            postRiskConfigs(params, {
                successMsg: locales.pages.gong.saleClientCenter.list.index.savedSuccessfully,
            }).then((res) => {
                setIsShowRisk(false);
            });
        });
    };
    const handlerSearch = (values) => {
        setSearchCondition(values);
        getCustomDataList(CURRENT_NUM, CURRENT_SIZE, values);
    };
    const handlerPrevCarousel = () => {
        // carouselRef.current.prev();
        let prevDate = moment(lastTime).subtract(7, 'day').format('YYYY-MM-DD 23:59:59');
        setLastTime(prevDate);
        getCustomDataList(CURRENT_NUM, CURRENT_SIZE, undefined, prevDate);
    };
    const handlerNextCarousel = () => {
        // carouselRef.current.next();
        let nextDate = moment(lastTime).add(7, 'day').format('YYYY-MM-DD 23:59:59');
        setLastTime(nextDate);
        getCustomDataList(CURRENT_NUM, CURRENT_SIZE, undefined, nextDate);
    };
    // 树结构数据处理
    const transformArrayToTree = (arr: any = []) => {
        if (!arr || !arr.length) return [];
        const sortedArr = arr
            .map((i: any) => ({
                ...i,
                title: i.name,
                key: i.id,
                parentId: i.parentId * 1,
            }))
            .sort((a, b) => a.parentId - b.parentId);
        let root = sortedArr.filter((i) => i.parentId === sortedArr[0].parentId);
        let nodeMap = {};
        sortedArr.forEach((item) => {
            nodeMap[item.id] = item;
            if (nodeMap[item.parentId]) {
                if (Array.isArray(nodeMap[item.parentId].children)) {
                    nodeMap[item.parentId].children.push(item);
                } else {
                    nodeMap[item.parentId].children = [item];
                }
            }
        });
        return [...root];
    };
    const getCustomDataList = (pageNum, pageSize, search?, time?) => {
        setLoading(true);
        const params: GetReviewCallCustomerMessageFindallQuery = {
            pageNum,
            pageSize: Math.max(CURRENT_SIZE, pageSize),
            lastTime: time || lastTime,
            ...(search || searchCondition),
        };
        getReviewCallCustomerMessageFindall({ params })
            .then((res) => {
                setLoading(false);
                setDataSource(res.data ?? []);
                let followDataList: any = [];
                if (res.data && res.data.length) {
                    setTotalCount(res.paging?.total ?? 0);
                    setDataLastTime(res.data[0]?.lastTime ?? '');
                    let monthDate = res.data[0]?.timeStringList;
                    monthDate?.forEach((item) => {
                        followDataList.push({
                            // allDate: item,
                            date: moment(item).format('M-D'),
                            children: [],
                        });
                    });
                    if ((time || lastTime) === moment().format('YYYY-MM-DD 23:59:59')) {
                        followDataList.push({
                            allDate: moment().format('YYYY-M-D'),
                            date: locales.pages.gong.saleClientCenter.list.index.today,
                            children: [],
                        });
                    }
                    followDataList.forEach((item, idx) => {
                        res.data?.forEach((record) => {
                            item.children.push({
                                customerId: record.customerId,
                                children: [],
                            });
                        });
                    });
                    followDataList.forEach((item, idx) => {
                        item.children.forEach((record) => {
                            res.data?.forEach((data) => {
                                if (record.customerId === data.customerId) {
                                    record.children = [
                                        ...record.children,
                                        ...(data?.oneWeekDataCallAnalysisList?.[idx] ?? []),
                                    ];
                                    if (
                                        idx === followDataList.length - 1 &&
                                        (time || lastTime) === moment().format('YYYY-MM-DD 23:59:59')
                                    ) {
                                        record.children = data?.dataCallAnalysisList?.filter(
                                            (sin) => moment(sin.callTime).format('YYYY-M-D') === item.allDate
                                        );
                                    }
                                }
                            });
                        });
                    });
                }
                setChartData(followDataList);
                setPage({
                    current: res.paging?.pageNum ?? CURRENT_NUM,
                    pageSize: res.paging?.pageSize ?? CURRENT_SIZE,
                    total: res.paging?.total ?? 0,
                });
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    const getUserDataList = () => {
        const params = {
            pageSize: 1000,
        };
        getUsers({ params }).then((res) => {
            setUserList(res.data ?? []);
        });
    };
    const getCustomerTagDataList = () => {
        getSmartTagsList({
            params: {
                funcType: 'CUSTOMER_TAG',
            },
        }).then((res) => {
            setCustomerLabelList(res.data ?? []);
        });
    };
    const getDepartmentList = () => {
        const params = {
            pageSize: 10000,
        };
        getOrganizations({ params }).then((res) => {
            let treeData = transformArrayToTree(res.data ?? []);
            setOrganizationList(treeData);
        });
    };
    useEffect(() => {
        let backState = props.location?.state;
        if (backState) {
            getCustomDataList(
                backState?.page?.current ?? page.current,
                backState?.page?.pageSize ?? page.pageSize,
                backState.searchCondition
            );
            form &&
                form.setFieldsValue({
                    ...backState.searchCondition,
                });
        } else {
            getCustomDataList(page.current, page.pageSize);
        }
        getDepartmentList();
        getUserDataList();
        getCustomerTagDataList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Page
            pageBodyClassName='pages-gong-sale-client-center-list-container'
            title={locales.pages.gong.saleClientCenter.list.index.customer}
            padding={true}
        >
            <Form form={form} onFinish={handlerSearch} layout='inline' style={{ width: '100%' }}>
                <Row style={{ width: '100%' }}>
                    <Col span={5}>
                        <Form.Item name='organizationIds'>
                            <TreeSelect
                                showSearch
                                placeholder={locales.pages.gong.saleClientCenter.list.index.allDepartments}
                                allowClear
                                multiple
                                treeDefaultExpandAll
                                treeNodeFilterProp='name'
                                treeData={organizationList}
                            ></TreeSelect>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name='userIds'>
                            <Select
                                showSearch
                                showArrow
                                allowClear
                                mode='multiple'
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={locales.pages.gong.saleClientCenter.list.index.allMembers}
                            >
                                {userList.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.realname}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name='key'>
                            <Input placeholder={locales.pages.gong.saleClientCenter.list.index.customerName} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name='customerTagKeyword'>
                            <Select
                                showSearch
                                showArrow
                                allowClear
                                mode='multiple'
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={locales.components.pages.intelligentTags.customerLabelTitle}
                            >
                                {customerLabelList.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id.split('_').reverse()[0]}>
                                            {item.tagName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item>
                            <Space>
                                <Button
                                    onClick={() => {
                                        setLastTime(currentDay);
                                        form.resetFields();
                                    }}
                                >
                                    {locales.pages.gong.saleClientCenter.list.index.reset}
                                </Button>
                                <Button type='primary' htmlType='submit'>
                                    {locales.pages.gong.saleClientCenter.list.index.query}
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className='risk-allocation'>
                <Space size={40}>
                    <p>
                        {totalCount}
                        {locales.pages.gong.saleClientCenter.list.index.customers}
                    </p>
                    <div className='user-proportion'>
                        <Space size={4}>
                            <div>
                                <i className='bg-custom'></i>
                                <i className='bg-user'></i>
                            </div>
                            <Tooltip
                                title={
                                    locales.pages.gong.saleClientCenter.list.index
                                        .showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles
                                }
                            >
                                <Icon type='icon_explain' style={{ fontSize: 16 }}></Icon>
                            </Tooltip>
                        </Space>
                    </div>
                    <Button type='link' onClick={handlerRiskAdd}>
                        {locales.pages.gong.saleClientCenter.list.index.riskAllocation}
                    </Button>
                </Space>
            </div>
            <Table
                bordered
                key='customerId'
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                pagination={{
                    ...page,
                    onChange: handlerPageChange,
                }}
            />
            <Modal
                className='pages-modal-sale-client-center-risk-setting'
                title={locales.pages.gong.saleClientCenter.list.index.riskAllocation}
                visible={isShowRisk}
                okText={locales.pages.gong.saleClientCenter.list.index.preservation}
                onOk={handlerSave}
                onCancel={() => setIsShowRisk(false)}
            >
                <Form form={riskForm} initialValues={{ timeoutDayCounts: 7, riskDayCounts: 7 }}>
                    <Form.Item
                        label={locales.pages.gong.saleClientCenter.list.index.exceed}
                        rules={[
                            {
                                required: true,
                                message: locales.pages.gong.saleClientCenter.list.index.pleaseEnter,
                            },
                        ]}
                    >
                        <Form.Item name='timeoutDayCounts' noStyle>
                            <InputNumber precision={0} min={1} />
                        </Form.Item>
                        <span style={{ marginLeft: '8px' }} className='udesk-ui-form-text'>
                            {locales.pages.gong.saleClientCenter.list.index.noActivityForDays}
                        </span>
                    </Form.Item>
                    <Form.Item
                        label={locales.pages.gong.saleClientCenter.list.index.lately}
                        rules={[
                            {
                                required: true,
                                message: locales.pages.gong.saleClientCenter.list.index.pleaseEnter,
                            },
                        ]}
                    >
                        <Form.Item name='riskDayCounts' noStyle>
                            <InputNumber precision={0} min={1} />
                        </Form.Item>
                        <span style={{ marginLeft: '8px', marginRight: '16px' }} className='udesk-ui-form-text'>
                            {locales.pages.gong.saleClientCenter.list.index.riskSemanticsWithinDays}
                        </span>
                        <Button onClick={handlerAddTags} icon={<Icon type='ic-add-jia1'></Icon>}>
                            {locales.pages.gong.saleClientCenter.list.index.addRiskSemantics}
                        </Button>
                    </Form.Item>
                    {!!semanticsList.length &&
                        semanticsList.map((item) => {
                            return (
                                <Form.Item key={item.id}>
                                    <div className='semantics-tags-item'>
                                        <div className='semantics-tags-item-content'>
                                            <h3>
                                                {locales.pages.gong.saleClientCenter.list.index.semanticTags}
                                                {item.title}
                                            </h3>
                                            <p>
                                                {locales.pages.gong.saleClientCenter.list.index.standardSentence}
                                                {item.tags?.filter((record) => record.standardFlag === 1)?.[0]?.content}
                                            </p>
                                        </div>
                                        <div
                                            className='semantics-tags-item-icon'
                                            onClick={handlerDelete.bind(null, item.id)}
                                        >
                                            <Icon type='ic-minus-circle-jian'></Icon>
                                        </div>
                                    </div>
                                </Form.Item>
                            );
                        })}
                </Form>
            </Modal>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
