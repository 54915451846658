import { useState } from 'react';

export const useAudio = () => {
    const [playing, setPlaying] = useState(false);
    const [playingUrl, setPlayingUrl] = useState<string>('');
    const [audio] = useState(() => {
        const audio = new Audio();
        // audio.addEventListener('ended')
        // audio.addEventListener('play', () => {});
        audio.addEventListener('pause', () => {
            setPlaying(false);
        });
        return audio;
    });

    const play = (url) => {
        pause();
        Promise.resolve().then(() => {
            setPlayingUrl(url);
            audio.src = url;
            audio.play();
            setPlaying(true);
        });
    };

    const pause = () => {
        audio.src = '';
        setPlayingUrl('');
        audio.pause();
        setPlaying(false);
    };

    return {
        play,
        pause,
        playing,
        playingUrl,
    };
};
