import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { PUBLICKEY, encryptByRSA } from 'src/util/crypt';

export default class LoginComponent extends React.Component {
    //#region state
    state = {
        email: null,
        password: null,
        rememberMe: false,
        errorMsg: '',
        loading: false,
    };
    privates = {
        formInstance: React.createRef(),
        bgImg: '/static/images/login-page-bg.jpg',
    };
    //#endregion

    //#region Computed properties
    static computes = {
        _websiteTitle: [
            'privates.model',
            function ({ props, state, privates, locales }) {
                let websiteTitle;
                const companyName = window.localStorage.getItem('company.name');
                if (companyName) {
                    websiteTitle = companyName;
                } else {
                    websiteTitle = UdeskLocales.current.labels.systemName;
                }
                return websiteTitle;
            },
        ],
        _websiteLogo: [
            'privates.model',
            function ({ props, state, privates, locales }) {
                let websiteLogo;
                const companyLogo = window.localStorage.getItem('company.logo');
                if (companyLogo) {
                    websiteLogo = companyLogo;
                } else {
                    websiteLogo = '/static/images/qa-logo.png';
                }
                return websiteLogo;
            },
        ],
    };
    //#endregion

    //#region Life Cycle
    componentDidMount() {}
    componentWillUnmount() {}
    //#endregion

    //#region actions
    actions = {
        onValueChanged(obj, key, e) {
            let value = e;
            if (key === 'rememberMe') {
                value = e.target.checked;
            } else if (e.target) {
                value = e.target.value;
            }
            obj[key] = value;
            this.actions.update();
        },
        login(e) {
            const email = this.state.email;
            const password = this.state.password;

            if (!email) {
                this.setState({
                    errorMsg: UdeskLocales.current.pages.auth.login.emailCannotBeEmpty,
                });
                return false;
            }

            if (!password) {
                this.setState({
                    errorMsg: UdeskLocales.current.pages.auth.login.passwordCannotByEmpty,
                });
                return false;
            }

            const data = {
                email,
                password,
            };
            const button = e.target;
            button.disabled = true;
            Udesk.ajax
                .post(Udesk.business.apiPath.concatApiPath('/login', this.props.sdkOptions), data)
                .then(
                    (resp) => {
                        button.disabled = false;

                        if (resp.code === Udesk.system.apiCodes.mustChangePassword) {
                            // TODO 目前还没实现修改密码页面，暂时给一个提示信息
                            this.setState({
                                errorMsg: UdeskLocales.current.pages.auth.login.mustChangeLogin,
                            });
                        } else if (resp.code === Udesk.system.apiCodes.disableStatus) {
                            this.setState({
                                errorMsg: UdeskLocales.current.pages.auth.login.forbiddenLogin,
                            });
                        } else if (resp.code === Udesk.system.apiCodes.dimissionStatus) {
                            this.setState({
                                errorMsg: UdeskLocales.current.pages.auth.login.dismissionLogin,
                            });
                        } else {
                            let refer = this.privates.model.refer;
                            let userName = window.localStorage.getItem('userName');
                            if (refer && userName === email) {
                                window.location.href = refer;
                            } else {
                                window.location.href = '/entry-page';
                            }
                        }
                    },
                    (reason) => {
                        button.disabled = false;
                        let errorMsg =
                            reason.errorMsg || UdeskLocales.current.pages.auth.login.loginError;
                        this.setState({
                            errorMsg: errorMsg,
                        });
                        // Udesk.ui.notify.error(errorMsg);
                    }
                );
        },
        loginV2(values) {
            const { email, password } = values;

            if (!email) {
                this.setState({
                    errorMsg: UdeskLocales.current.pages.auth.login.emailCannotBeEmpty,
                });
                return false;
            }

            if (!password) {
                this.setState({
                    errorMsg: UdeskLocales.current.pages.auth.login.passwordCannotByEmpty,
                });
                return false;
            }

            const data = {
                email,
                password: encryptByRSA(PUBLICKEY, password),
            };
            this.setState({
                loading: true,
            });
            Udesk.ajax
                .post(Udesk.business.apiPath.concatApiPath('/login', this.props.sdkOptions), data)
                .then(
                    (resp) => {
                        if (resp.code === Udesk.system.apiCodes.mustChangePassword) {
                            // TODO 目前还没实现修改密码页面，暂时给一个提示信息
                            this.setState({
                                errorMsg: UdeskLocales.current.pages.auth.login.mustChangeLogin,
                            });
                        } else if (resp.code === Udesk.system.apiCodes.disableStatus) {
                            this.setState({
                                errorMsg: UdeskLocales.current.pages.auth.login.forbiddenLogin,
                            });
                        } else if (resp.code === Udesk.system.apiCodes.dimissionStatus) {
                            this.setState({
                                errorMsg: UdeskLocales.current.pages.auth.login.dismissionLogin,
                            });
                        } else {
                            let refer = this.privates.model.refer;
                            let userName = window.localStorage.getItem('userName');
                            if (refer && userName === email) {
                                window.location.href = refer;
                            } else {
                                window.location.href = '/entry-page';
                            }
                        }
                        this.setState({
                            loading: false,
                        });
                    },
                    (reason) => {
                        let errorMsg =
                            reason.errorMsg || UdeskLocales.current.pages.auth.login.loginError;
                        this.setState({
                            loading: false,
                            errorMsg: errorMsg,
                        });

                        // Udesk.ui.notify.error(errorMsg);
                    }
                );
        },
    };
    //#endregion
}
