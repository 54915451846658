import React from 'react';
import InputBaseClass from './input-base-class';
import DateRangePicker from '../date-range-picker/index';
import Udesk from '../../udesk/index';

class DateTimePicker extends InputBaseClass {
    actions = {
        _validate() {
            return true;
        },

        _onInputValueChanged(internalValue) {
            this.actions._onChanged(internalValue);
        },
    };

    static get emberName() {
        return 'udesk/custom-field-inputs/date-time-picker';
    }

    render() {
        let {
            privates: { computes },
        } = this;

        let { internalValue, mergedAttributes } = computes;
        let { disabled } = this.props;

        return (
            <DateRangePicker
                start={internalValue}
                classNames={mergedAttributes.classNames}
                disabled={disabled}
                timePicker={true}
                timePickerSeconds={mergedAttributes.timePickerSeconds}
                singleDatePicker={true}
                format={mergedAttributes.format}
                serverFormat={mergedAttributes.serverFormat}
                placeholder={mergedAttributes.placeholder}
                applyAction={this.actions._onInputValueChanged}
                parentEl={mergedAttributes.parentEl}
            />
        );
    }
}

export default Udesk.react.udeskify(DateTimePicker);
