import React from 'react';
import Udesk from 'Udesk';
import TemplateComponentsMyRoute from './route';
import UdeskTable from 'Component/common/udesk-table';
import { PageHeader, Space, Input, Select, Button } from 'udesk-ui';
import './style.scss';
const { Option } = Select;
export default class TemplateComponentsMyTemplate extends TemplateComponentsMyRoute {
    render() {
        let {
            targetItems,
            tableData,
            paging,
            keywords,
            releaseStatus,
            loading
        } = this.privates;
        let { actions, locales } = this;
        let hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
        return (
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.pages.admin.templateComponent.list.myModule}
                    extra={
                        <If condition={hasFeature('sys:module:my:add')}>
                            <Button type='primary' onClick={actions.goDetailNew}>+ {locales.pages.admin.templateComponent.list.addModule}</Button>
                        </If>
                    }
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root template-components-list-page'>
                        <div className="template-components-list-page-search">
                            <Space>
                                <span>
                                    {locales.pages.admin.templateComponent.list.name}：
                                    <Input.Search
                                        placeholder={locales.pages.admin.templateComponent.list.search}
                                        style={{ width: 200 }}
                                        value={keywords}
                                        onChange={actions.inputChange}
                                        onSearch={actions.inputSearch}
                                    />
                                </span>
                                <span>
                                    {locales.pages.admin.templateComponent.list.appStatus}：
                                    <Select value={releaseStatus} style={{ width: 120 }} onChange={actions.selectChange}>
                                        <Option value={0}>{locales.pages.admin.templateComponent.list.all}</Option>
                                        {
                                            Udesk.enums.templateComponentList.map(item => {
                                                return (
                                                    <Option value={item.id}>{item.name}</Option>
                                                );
                                            })
                                        }
                                    </Select>
                                </span>
                                {/* <span>
                                    更新情况：
                                    <Select defaultValue={1} style={{ width: 120 }}>
                                        <Option value={1}>全部</Option>
                                    </Select>
                                </span> */}
                                <Button type="primary" onClick={actions.search}>
                                    {
                                        locales.components.pages.customerServiceRankList
                                            .select.search
                                    }
                                </Button>
                                <Button onClick={actions.reset}>
                                    {
                                        locales.components.pages.customerServiceRankList
                                            .select.reset
                                    }
                                </Button>
                            </Space>
                        </div>
                        <UdeskTable
                            columns={targetItems}
                            dataSource={tableData}
                            loading={loading}
                            scroll={{ y: 'calc(100vh - 64px - 58px - 32px - 194px)' }}
                            pagination={{
                                onChange: actions.pageNumChange,
                                total: paging.total,
                                pageSize: paging.pageSize,
                                onShowSizeChange: actions.pageSizeChange,
                            }}
                        >
                        </UdeskTable>
                    </div>
                </div>
            </div>
        );
    }
}

