const algorithmRoutesGlobal = [
    {
        path: '/algorithm-platform',
        name: 'algorithmHome',
        routes: [
            {
                path: '/semantic-intelligence',
                authCode: 'semantic:root',
                name: 'algorithmSemanticIntelligence',
                routes: [
                    // 智能词库
                    {
                        path: '/smart-words-library',
                        name: 'algorithmSmartWordsLibrary',
                        authCode: 'semantic:corpora:root',
                        routes: [
                            {
                                path: '/synonym-words',
                                name: 'algorithmSmartWordsLibrarySynonymWords',
                            },
                            {
                                path: '/sensitive-words',
                                name: 'algorithmSmartWordsLibrarySensitiveWords',
                            },
                            {
                                path: '/professional-words',
                                name: 'algorithmSmartWordsLibraryProfessionalWords',
                            },
                            {
                                path: '/attention-words',
                                name: 'algorithmSmartWordsLibraryAttentionWords',
                            },
                            {
                                path: '/ignore-words',
                                name: 'algorithmSmartWordsLibraryIgnoreWords',
                            },
                            {
                                path: '/words-weight',
                                name: 'algorithmSmartWordsLibraryWordsWeight',
                            },
                            {
                                path: '/corpus-data',
                                name: 'algorithmSmartWordsLibraryCorpusData',
                            },
                            {
                                path: '/typo-library',
                                name: 'algorithmSmartWordsLibraryTypoLibrary',
                            },
                        ],
                    },
                    // 概念词
                    {
                        path: '/concept-word',
                        name: 'algorithmConceptWord',
                        authCode: 'semantic:conceptWords:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmConceptWordIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:conceptWords:add',
                                name: 'algorithmConceptWordNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:conceptWords:edit',
                                name: 'algorithmConceptWordEdit',
                            },
                        ],
                    },
                    // 语义标签
                    {
                        path: '/semantic-tags',
                        name: 'algorithmSemanticTags',
                        authCode: 'semantic:semanticTag:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmSemanticTagsIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:semanticTag:add',
                                name: 'algorithmSemanticTagsNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:semanticTag:edit',
                                name: 'algorithmSemanticTagsEdit',
                            },
                        ],
                    },
                    // 信息实体
                    {
                        path: '/information-entities',
                        name: 'algorithmInformationEntities',
                        authCode: 'semantic:imformation:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmInformationEntitiesIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:imformation:add',
                                name: 'algorithmInformationEntitiesNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:imformation:edit',
                                name: 'algorithmInformationEntitiesEdit',
                            },
                        ],
                    },
                    // 交互识别
                    {
                        path: '/interactive-recognition',
                        name: 'algorithmInteractiveRecognition',
                        authCode: 'semantic:interactive:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmInteractiveRecognitionIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:interactive:add',
                                name: 'algorithmInteractiveRecognitionNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:interactive:edit',
                                name: 'algorithmInteractiveRecognitionEdit',
                            },
                        ],
                    },
                    {
                        path: '/knowledge-base',
                        name: 'algorithmKnowledgeBase',
                        authCode: 'semantic:knowledgeData:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmKnowledgeBaseIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:knowledgeData:add',
                                name: 'algorithmKnowledgeBaseNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:knowledgeData:edit',
                                name: 'algorithmKnowledgeBaseEdit',
                            },
                        ],
                    },
                    // 数据预处理
                    {
                        path: '/data-preprocess',
                        name: 'algorithmDataPreprocess',
                        authCode: 'datapre:rule:root',
                        routes: [
                            {
                                path: '/index',
                                authCode: 'datapre:rule:view',
                                name: 'algorithmDataPreprocessIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'datapre:rule:save',
                                name: 'algorithmDataPreprocessNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'datapre:rule:update',
                                name: 'algorithmDataPreprocessEdit',
                            },
                        ],
                    },
                    // 智能抽取
                    {
                        path: '/intelligent-extractors',
                        name: 'algorithmIntelligentExtractors',
                        authCode: 'datapre:rule:root',
                        routes: [
                            {
                                path: '/index',
                                authCode: 'datapre:rule:view',
                                name: 'algorithmIntelligentExtractorsIndex',
                            },
                        ],
                    },
                ],
            },
            // 训练中心
            {
                path: '/training-center',
                name: 'algorithmTrainingCenter',
                authCode: 'training:root',
                routes: [
                    // 快速训练
                    {
                        path: '/fast-training',
                        name: 'algorithmFastTrainingIndex',
                        authCode: 'training:fast:root',
                    },
                    // 训练历史
                    {
                        path: '/training-history',
                        name: 'algorithmTrainingHistoryIndex',
                        authCode: 'training:history:root',
                    },
                    // 分类训练
                    {
                        path: '/classified-training',
                        name: 'algorithmClassifiedTraining',
                        authCode: 'training:categoryTrain:train',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmClassifiedTrainingIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'algorithmClassifiedTrainingDetail',
                            },
                        ],
                    },
                    // 分类训练历史
                    {
                        path: '/classified-history-training',
                        name: 'algorithmClassifiedTrainingHistoryIndex',
                        authCode: 'training:categoryTrain:trainHistory',
                    },
                    // 聚类标注
                    {
                        path: '/clustering-annotation-index',
                        name: 'algorithmClusteringAnnotationIndex',
                        authCode: 'training:clusterMark:root',
                    },
                    // 话题标注
                    {
                        path: '/topic-index',
                        name: 'algorithmTopicList',
                        authCode: 'training:subjectList:root',
                    },
                    // 话题列表
                    {
                        path: '/topic-callout',
                        name: 'algorithmTopicCallout',
                        authCode: 'training:subjectMark:root',
                    },
                    // 7、聚类分析
                    {
                        path: '/clustering-analysis',
                        name: 'algorithmClusteringAnalysis',
                        authCode: 'semantic:cluster:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmClusteringAnalysisIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:cluster:add',
                                name: 'algorithmClusteringAnalysisNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:cluster:edit',
                                name: 'algorithmClusteringAnalysisEdit',
                            },
                            {
                                path: '/detail/:id',
                                authCode: 'semantic:cluster:view',
                                name: 'algorithmClusteringAnalysisDetail',
                            },
                        ],
                    },
                    // 8、话术挖掘
                    {
                        path: '/words-mining',
                        name: 'algorithmWordsMining',
                        authCode: 'semantic:WordsMining:root',
                        routes: [
                            {
                                path: '/dissent',
                                name: 'algorithmDissentWordsMining',
                            },
                            {
                                path: '/dissent/favorite',
                                authCode: 'semantic:WordsFavorite:root',
                                name: 'algorithmDissentFavoriteWordsMining',
                            },
                            {
                                path: '/selling-point',
                                name: 'algorithmSellingPointWordsMining',
                            },
                            {
                                path: '/selling-point/favorite',
                                authCode: 'semantic:WordsFavorite:root',
                                name: 'algorithmSellingPointFavoriteWordsMining',
                            },
                        ],
                    },
                ],
            },
        ],
    },

];

export {
    algorithmRoutesGlobal
};
