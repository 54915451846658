import moment from 'moment';
import { isNumber } from './core';

export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const getDateTimeString = (date = undefined, format = DEFAULT_DATE_TIME_FORMAT) => {
    return moment(date).format(format);
};

export const createDefaultMoment = (date) => {
    if (date) {
        return moment(date, DEFAULT_DATE_TIME_FORMAT);
    }
    return moment();
};

export const beautifulTime = (second) => {
    return isNumber(second, () => {
        let format = 'HH:mm:ss';
        return  moment('000000', 'Hmmss').second(second).format(format);
    });
};