import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import {
    PageHeader,
    Tabs,
    Switch,
    Space,
    Button,
    Popconfirm,
    Modal,
    Spin /*DynamicForm as CreateForm */,
} from 'udesk-ui';
// import { CreateFromProps } from 'udesk-ui';
import AutoSizeTable from 'Component/common/auto-size-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import CreateForm, { CreateFromProps } from 'Component/common/create-form';
import cusConfigMap from '../../../../../common/config/form-config/cus-component-map';
import './style.scss';
const { TabPane } = Tabs;
// const DEFAULT_INIT_VALUES = {
//     pushConf2: PushConfig(Udesk.enums.messagePushType.email.id),
//     pushConf: PushConfig(Udesk.enums.messagePushType.interfacePush.id),
//     status: true,
// };
const getDefaultInitValues = () => {
    return {
        pushConf3: PushConfig(Udesk.enums.messagePushType.weChat.id),
        pushConf2: PushConfig(Udesk.enums.messagePushType.email.id),
        pushConf: PushConfig(Udesk.enums.messagePushType.interfacePush.id),
        status: true,
        target: {
            toOwner: 0,
            toStaffSelf: 0,
            staffIdList: [],
            toCustomerStaff: 0,
        }
    };
};

export default function ConversationSetting(props) {
    const locales = Locales['current'];

    const [loading, setLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [total, setTotal] = useState<number>(0);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState<number>(Udesk.enums.messagePushType.email.id);
    const [initialValues, setInitialValues] = useState<any>(getDefaultInitValues());
    const [needRefersh, setNeedRefersh] = useState<number>(0);
    const onFinish = (values) => {
        let url = Udesk.business.apiPath.concatApiPath(`eventLists`, props.sdkOptions);
        let xhr: Promise<any> | null = null;
        const { description, name, pushConf2, pushConf, pushConf3, status, target } = values;
        let actionDetails, actionType;
        if (pushConf2) {
            actionDetails = { ...pushConf2.actionDetails, pushTargetType: 2 };
            actionType = pushConf2.actionType;
        }
        if (pushConf) {
            actionDetails = pushConf.actionDetails;
            actionType = pushConf.actionType;
        }
        if (pushConf3) {
            actionDetails = { ...pushConf3.actionDetails, ...target };
            actionType = pushConf3.actionType;
        }
        let params = {
            description,
            name,
            autoMetePushAction: actionDetails,
            actionType,
            status: status ? 1 : 0,
        };
        if (initialValues.id) {
            url = Udesk.business.apiPath.concatApiPath(`eventLists/${initialValues.id}`, props.sdkOptions);
            xhr = Udesk.ajax.put(url, params);
        } else {
            xhr = Udesk.ajax.post(url, params);
        }
        xhr &&
            xhr.then(
                (resp) => {
                    Udesk.ui.notify.success(locales.business.info.saveSuccess);
                    setIsModalVisible(false);
                    setNeedRefersh(needRefersh + 1);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
    };
    const showEditModal = (item) => {
        setIsModalVisible(true);
        setLoadingDetail(true);
        let url = Udesk.business.apiPath.concatApiPath(`eventLists/${item.id}`, props.sdkOptions);
        Udesk.ajax.get(url).then(
            (resp) => {
                let newInitValue: any = {};
                if (resp?.data) {
                    // pushConf2: PushConfig(Udesk.enums.messagePushType.email.id),
                    // pushConf: PushConfig(Udesk.enums.messagePushType.interfacePush.id),
                    // status: true,
                    const { autoMetePushAction, ...args } = resp.data;
                    newInitValue = args;
                    if (autoMetePushAction) {
                        newInitValue.pushConf = {
                            actionDetails: autoMetePushAction,
                            actionType: newInitValue.actionType,
                        };
                        newInitValue.pushConf2 = {
                            actionDetails: autoMetePushAction,
                            actionType: newInitValue.actionType,
                        };
                        newInitValue.pushConf3 = {
                            actionDetails: autoMetePushAction,
                            actionType: newInitValue.actionType,
                        };
                        const { toOwner, toStaffSelf, toCustomerStaff, staffIdList } = autoMetePushAction;
                        newInitValue.target = {
                            toOwner,
                            toStaffSelf,
                            toCustomerStaff,
                            staffIdList,
                        };
                    }
                }
                setInitialValues(newInitValue);
            },
            (reason) => {
                setLoadingDetail(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const deleteItem = (item) => {
        let url = Udesk.business.apiPath.concatApiPath(`eventLists/${item.id}`, props.sdkOptions);
        setDeleteLoading(true);
        Udesk.ajax.del(url).then(
            (resp) => {
                setDeleteLoading(false);
                setNeedRefersh(needRefersh + 1);
            },
            (reason) => {
                setDeleteLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const changeState = (item, index, checked) => {
        let url = Udesk.business.apiPath.concatApiPath(
            `eventLists/${item.id}/status/${checked ? 1 : 0}`,
            props.sdkOptions
        );
        Udesk.ajax.put(url).then(
            (resp) => {
                let newDataSource = dataSource.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        item.status = !item.status ? 1 : 0;
                    }
                    return item;
                });
                setDataSource(newDataSource);
                Udesk.ui.notify.success(locales.business.info.editSuccess);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const getColumns = () => {
        // let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
        let columns = [
            {
                key: 'index',
                dataIndex: 'index',
                title: locales.pages.wechatQa.message.table.index,
                width: '10%',
                render: (name, item, index) => {
                    return <div>{(pageNum - 1) * pageSize + index + 1}</div>;
                },
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: locales.pages.wechatQa.message.table.name,
                ellipsis: true,
                width: '15%',
            },
            {
                key: 'description',
                dataIndex: 'description',
                title: locales.pages.wechatQa.message.table.desc,
                ellipsis: true,
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: locales.pages.wechatQa.message.table.status,
                width: '10%',
                render: (name, item, index) => {
                    return (
                        <div>
                            <Switch
                                checked={Boolean(item.status)}
                                onChange={changeState.bind(null, item, index)}
                                size='small'
                            />
                        </div>
                    );
                },
            },
            {
                key: 'actions',
                dataIndex: 'actions',
                title: locales.columns.tasks.manage.template.checkPoint.actions,
                width: '18%',
                align: 'center' as any,
                render: (name, item, index) => {
                    return (
                        <div>
                            <Space>
                                {/* {hasFeature && hasFeature('task:template:point:edit') && ( */}
                                <Button type='link' size='small' onClick={showEditModal.bind(null, item)}>
                                    {locales.labels.edit}
                                </Button>
                                {/* )} */}
                                {/* {hasFeature && hasFeature('task:template:point:delete') && ( */}
                                <Popconfirm
                                    title={locales.labels.deleteContent}
                                    onConfirm={deleteItem.bind(null, item)}
                                    onCancel={(e) => e!.stopPropagation()}
                                    okText={locales.labels.confirm}
                                    okButtonProps={{
                                        loading: deleteLoading,
                                    }}
                                    placement='topRight'
                                    cancelText={locales.labels.cancel}
                                >
                                    <Button danger type='link' size='small'>
                                        {locales.labels.delete}
                                    </Button>
                                </Popconfirm>
                                {/* )} */}
                            </Space>
                        </div>
                    );
                },
            },
        ];
        if (activeKey === Udesk.enums.messagePushType.interfacePush.id) {
            columns.splice(2, 0, {
                key: 'actionType',
                dataIndex: 'actionType',
                title: locales.pages.wechatQa.message.table.type,
                width: '15%',
                render: (name, item, index) => {
                    return <div>{Udesk.enums.messagePushType.getName(item.actionType)}</div>;
                },
            });
        }
        return columns;
    };
    const newPush = () => {
        setIsModalVisible(true);
        setTimeout(() => {
            setInitialValues(getDefaultInitValues());
        }, 100);
    };
    const onValuesChange = (values) => {};
    const config: CreateFromProps = {
        initialValues: initialValues,
        configMap: cusConfigMap,
        onValuesChange: onValuesChange,
        onFinish: onFinish,
        fields: [
            {
                name: 'name',
                label: locales.labels.name,
                colon: false,
                field: {
                    type: 'Input',
                    props: {
                        showCount: true,
                        maxCount: 50,
                        style: {
                            width: 300
                        }
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                    {
                        max: 50,
                        type: 'string',
                    }
                ],
            },
            {
                name: 'description',
                label: locales.labels.describe,
                colon: false,
                field: {
                    type: 'TextArea',
                    props: {
                        autoSize: { minRows: 2, maxRows: 2 },
                        showCount: true,
                        maxCount: 200,
                        style: {
                            width: 300
                        }
                    },
                },
                rules: [
                    {
                        max: 200,
                        type: 'string',
                    }
                ],
            },
            {
                name: 'status',
                label: locales.labels.status,
                colon: false,
                field: {
                    type: 'Switch',
                    props: {
                        size: 'small',
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'pushConf',
                label: locales.labels.pushConfig,
                noStyle: true,
                forceHidden: activeKey !== Udesk.enums.messagePushType.interfacePush.id,
                field: {
                    type: 'InterfacePush',
                    props: {
                        taskType: Udesk.enums.inspectDataSources.wechatDialogue.id,
                        taskId: 0,
                        taskTypeList: [1, 3, 6, 8, 14],
                        pushTypeFilter: [
                            Udesk.enums.sdkPushType.webHook.id,
                            Udesk.enums.sdkPushType.weChat.id,
                            Udesk.enums.sdkPushType.customer.id,
                        ],
                    },
                },
            },
            {
                name: 'pushConf2',
                label: locales.labels.pushConfig,
                noStyle: true,
                forceHidden: activeKey !== Udesk.enums.messagePushType.email.id,
                field: {
                    type: 'OtherPush',
                    props: {
                        taskType: Udesk.enums.inspectDataSources.wechatDialogue.id,
                        taskId: 0,
                        taskTypeList: [1, 3, 6, 8, 14],
                    },
                },
            },
            {
                name: 'pushConf3',
                label: locales.labels.pushConfig,
                noStyle: true,
                forceHidden: activeKey !== Udesk.enums.messagePushType.weChat.id,
                field: {
                    type: 'InterfacePush',
                    props: {
                        taskType: Udesk.enums.inspectDataSources.wechatDialogue.id,
                        taskId: 0,
                        taskTypeList: [1, 3, 6, 8, 14],
                    },
                },
            },
            {
                name: 'target',
                label: locales.labels.pushConfig,
                forceHidden: activeKey !== Udesk.enums.messagePushType.weChat.id,
                field: {
                    type: 'PushTarget',
                    props: {
                        taskType: Udesk.enums.inspectDataSources.wechatDialogue.id,
                        taskId: 0,
                        url: Udesk.business.apiPath.concatApiPath(`employees/search`, props.sdkOptions),
                    },
                },
                required: true,
                rules: [
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value.toStaffSelf || value.toOwner || value.toCustomerStaff) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.labels.pushConfig)));
                        },
                    }),
                ]
            },
        ],
        col: {
            labelCol: { span: 4 },
            wrapperCol: { span: 16 },
        },
        btnsCol: {
            offset: 4,
            span: 16,
        },
        footerRender: false,
    };
    const [wrapForm] = CreateForm.useForm();
    const typeChange = (key) => {
        setPageNum(1);
        setActiveKey(parseInt(key, 10));
    };
    const pageChange = (pageNum, pageSize) => {
        setPageNum(pageNum);
        setPageSize(pageSize);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        wrapForm.submit();
    };

    useEffect(() => {
        setLoading(true);
        let url = Udesk.business.apiPath.concatApiPath(
            `eventLists/type/${activeKey}?pageNum=${pageNum}&pageSize=${pageSize}`,
            props.sdkOptions
        );
        Udesk.ajax.get(url).then(
            (resp) => {
                setLoading(false);
                if (resp.data) {
                    setDataSource(resp.data);
                }
                if (resp.paging) {
                    setPageNum(resp.paging.pageNum);
                    setPageSize(resp.paging.pageSize);
                    setTotal(resp.paging.total);
                }
            },
            (reason) => {
                setLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [props.sdkOptions, pageNum, pageSize, activeKey, needRefersh]);
    useEffect(() => {
        wrapForm.resetFields();
        setLoadingDetail(false);
    }, [initialValues, wrapForm]);
    return (
        <div className='udesk-qa-web-page'>
            <PageHeader className='udesk-qa-web-page-header' title={locales.pages.wechatQa.message.title} />
            <div className='udesk-qa-web-page-body'>
                <div className='udesk-qa-web-page-body-root wechat-qa-tasks-push-setting'>
                    <Modal
                        wrapClassName='wechat-qa-tasks-push-setting-modal'
                        width={800}
                        title={`${
                            initialValues?.id ? locales.labels.edit : locales.labels.create
                        }${Udesk.enums.messagePushType.getName(activeKey)}`}
                        visible={isModalVisible}
                        onOk={handleOk}
                        destroyOnClose
                        onCancel={handleCancel}
                    >
                        <Spin spinning={loadingDetail}>
                            <CreateForm {...config} form={wrapForm}></CreateForm>
                        </Spin>
                    </Modal>
                    <Tabs activeKey={`${activeKey}`} onChange={typeChange}>
                        {Udesk.enums.messagePushType
                            .filter((i) => i.id <= 2 || i.id === 6)
                            .map((type) => {
                                return <TabPane tab={type.name} key={type.id}></TabPane>;
                            })}
                    </Tabs>
                    <div className='wechat-qa-tasks-push-setting-toolsbar'>
                        <div style={{ fontWeight: 600 }}>{locales.pages.wechatQa.message.subTitle}</div>
                        <Button type='primary' onClick={newPush}>
                            {locales.labels.create}
                        </Button>
                    </div>
                    <AutoSizeTable
                        subtractedHeight={180}
                        loading={loading}
                        pagination={false}
                        dataSource={dataSource}
                        columns={getColumns()}
                    />
                    {Boolean(total) && (
                        <UdeskPagination
                            current={pageNum}
                            pageSize={pageSize}
                            // onShowSizeChange={actions.onShowSizeChange}
                            total={total}
                            onChange={pageChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function PushConfig(messagePushType) {
    let config = {
        actionType: messagePushType,
        actionDetails: {
            actionType: messagePushType,
            pushTargetList: [],
            pushUserList: [],
            pushRoleList: [],
            content: '',
        },
    };
    let extra: any = {};
    //接口推送有附加字段
    if (
        messagePushType === Udesk.enums.messagePushType.interfacePush.id ||
        messagePushType === Udesk.enums.messagePushType.weChat.id
    ) {
        extra = {
            additionalDataList: [
                {
                    _id: Udesk.utils.string.uuid(),
                    key: '',
                    value: '',
                },
            ],
            type:
                messagePushType === Udesk.enums.messagePushType.interfacePush.id
                    ? Udesk.enums.sdkPushType.webHook.id
                    : Udesk.enums.sdkPushType.customer.id,
            contentType: 'json',
            pushDataTypes: [1, 2, 3],
            requestMethod: 'post',
            url: '',
        };
        if (messagePushType === Udesk.enums.messagePushType.weChat.id) {
            extra.messageType = Udesk.enums.messageTypes.text.id;
        }
    }
    if (messagePushType === Udesk.enums.messagePushType.email.id) {
        extra = {
            pushTargetType: Udesk.enums.pushObjectTypes.staff.id,
            subject: '',
            targetEmails: '',
        };
    }
    if (messagePushType === Udesk.enums.messagePushType.sdk.id) {
        extra = {
            SDK: '',
        };
    }

    config.actionDetails = { ...config.actionDetails, ...extra };

    return config;
}
