import React, { useState, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import SmartWordsLibraryCommonList from '../smart-words-library-common-list';
import { Modal, Button, FormBuilder, Input, Space, Icon, Tag, Switch, Popconfirm } from 'udesk-ui';
// import './style.scss';
import type { FormProps } from 'udesk-ui/es/form-builder';
import { postTypoDatas } from 'src/api/typoDatas';
import { deleteTypoDatasById, getTypoDatasById, putTypoDatasById } from 'src/api/typoDatas/{id}';
import { TypoDataUpdateRequest } from 'src/api/types';
import { putTypoDatasByIdStatusByStatus } from 'src/api/typoDatas/{id}/status/{status}';


const TagInput = ({ value = [], onChange, placeholder }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const onClose = (tag) => {
        let newTags = value.filter((item, i) => item !== tag);
        onChange?.(newTags);
    };
    const addTag = () => {
        if (!inputValue) return;
        const inputValueList = inputValue.split(/\uff0c|,/).filter(Boolean);        
        onChange?.(Array.from(new Set([...value, ...inputValueList])));
        setInputValue('');
    };    
    return (
        <div>
            <div>
                {value.map((tag, index) => (
                    <Tag closable key={tag} onClose={onClose.bind(null, tag)} style={{ margin: '4px 2px' }}>
                        {tag}
                    </Tag>
                ))}
            </div>
            <Space>
                <Input
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onPressEnter={addTag}
                    style={{ width: 200 }}
                    placeholder={placeholder}
                />
                <Button
                    onClick={addTag}
                    icon={<Icon antdIcon={true} type='PlusCircleOutlined' style={{ color: '#4285ff' }} />}
                    type='text'
                />
            </Space>
        </div>
    );
};

const DEFAULT_VALUES = {
    status: 1,
};

const Template = React.memo((props: any) => {
    const locales = Locales['current'];

    const [addModalShow, setAddModalShow] = useState(false);
    const [columns, setColumns] = useState<any[]>([]);
    const libraryListInstance = useRef<any>(null);
    const commonListInstance = useRef<any>(null);
    const [formInstance] = FormBuilder.useForm();
    const [currentEditValues, setCurrentEditValues] = useState<TypoDataUpdateRequest>(DEFAULT_VALUES);

    const createSynonymWords = () => {
        setCurrentEditValues(DEFAULT_VALUES);
        setAddModalShow(true);
    };
    const save = (values) => {
        const data = {...values, wrongWordList: values.wrongWordList || []};
        const request = currentEditValues.id ? update(data) : create(data);
        request.then((resp) => {
            setAddModalShow(false);
            setCurrentEditValues(DEFAULT_VALUES);
            commonListInstance?.current?.actions?.reloadModel?.();
        });

        function create(values) {
            return postTypoDatas(values, {
                successMsg: locales.business.info.saveSuccess,
            });
        }
        function update(values) {
            return putTypoDatasById(
                {
                    ...currentEditValues,
                    ...values,
                },
                {
                    segments: {
                        id: currentEditValues.id!,
                    },
                    successMsg: locales.business.info.saveSuccess,
                }
            );
        }
    };
    const closeAddModal = () => {
        setAddModalShow(false);
    };
    const deleteItem = (id) => {
        deleteTypoDatasById({
            segments: {
                id
            }
        }).then(resp => {
            commonListInstance?.current?.actions?.reloadModel?.();
        });
    };
    const editItem = (id) => {
        setAddModalShow(true);
        getTypoDatasById({
            segments: {
                id,
            },
        }).then((resp) => {
            setCurrentEditValues({...resp.data, wrongWordList: resp.data!.wrongWordList || []});
        });
    };
    let corporaType = Udesk.enums.smartWordsLibraryMenus.typoLibrary.id;
    useEffect(() => {
        let columns = [
            {
                name: 'correctWord',
                width: '10%',
                caption: locales.components.pages.smartWordsLibrary.typoLibrary.fields.rightWord,
            },

            {
                name: 'wrongWordList',
                width: '20%',
                caption: locales.components.pages.smartWordsLibrary.typoLibrary.fields.wrongWord,
                hasTemplate: true,
                ellipsis: true,
                getYieldContent: (name, item, index) => {
                    return Array.isArray(item.wrongWordList) ? item.wrongWordList.join(',') : '-';
                },
            },

            {
                name: 'wrongWordCount',
                width: '10%',
                caption: locales.components.pages.smartWordsLibrary.typoLibrary.fields.wrongWordCount,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    return Array.isArray(item.wrongWordList) ? item.wrongWordList.length : '-';
                },
            },
            {
                name: 'status',
                width: '10%',
                caption: locales.components.pages.smartWordsLibrary.typoLibrary.fields.status,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    // let targetItem = Udesk.enums.wordsEffectiveness.get(item.status);
                    const onChange = (checked) => {
                        putTypoDatasByIdStatusByStatus({
                            segments: {
                                id: item.id,
                                status: checked ? 1 : 0,
                            },
                            successMsg: locales.business.info.saveSuccess,
                        }).then((resp) => {
                            commonListInstance?.current?.actions?.reloadModel?.();
                        });
                    };
                    return <Switch onChange={onChange} checked={Boolean(item.status)} size='small'></Switch>;
                },
            },

            {
                name: 'actions',
                width: '15%',
                caption: locales.fields.smartWordsLibrary.synonymWords.operation,
                hasTemplate: true,
                align: 'center',
                getYieldContent: (name, item, index) => {
                    return (
                        <Space>
                            {Udesk.data.init.user?.hasFeature?.('semantic:corpora:edit') && (
                                <Button
                                    title={locales.labels.edit}
                                    onClick={editItem.bind(null, item.id)}
                                    type='link'
                                    size='small'
                                >
                                    {locales.labels.edit}
                                </Button>
                            )}
                            {Udesk.data.init.user?.hasFeature?.('semantic:corpora:delete') && (
                                <Popconfirm
                                    title={locales.labels.deleteContent}
                                    onConfirm={deleteItem.bind(null, item.id)}
                                    okText={locales.labels.delete}
                                    placement='topRight'
                                    cancelText={locales.labels.cancel}
                                >
                                    <Button title={locales.labels.delete} type='link' size='small' danger>
                                        {locales.labels.delete}
                                    </Button>
                                </Popconfirm>
                            )}
                        </Space>
                    );
                },
            },
        ];
        setColumns(columns);
    }, []);
    const config: FormProps = {
        initialValues: currentEditValues,
        onFinish: save,
        customComponents: { TagInput: TagInput },
        fields: [
            {
                name: 'correctWord',
                label: locales.components.pages.smartWordsLibrary.typoLibrary.fields.rightWord,
                type: 'Input',
                props: {
                    showCount: true,
                    maxCount: 10,
                },
                required: true,
                rules: [
                    {
                        max: 10,
                        type: 'string',
                    },
                    {
                        min: 2,
                        type: 'string',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (/^[\u4e00-\u9fa5]+$/g.test(value || '')) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(locales.components.pages.smartWordsLibrary.typoLibrary.onlyChinese));
                        },
                    }),
                ],
            },
            {
                name: 'wrongWordList',
                label: locales.components.pages.smartWordsLibrary.typoLibrary.fields.wrongWord,
                type: 'TagInput',
                // required: true,
                rules: [
                    // {
                    //     min: 1,
                    //     type: 'array',
                    // },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if(!value) {
                                return Promise.resolve();
                            }
                            if (value.every(i => /^[\u4e00-\u9fa5]+$/g.test(i || ''))) {
                                if(value.some(i => i.length < 2 || i.length > 10)){
                                    return Promise.reject(new Error(locales.components.pages.smartWordsLibrary.typoLibrary.wrongWordLengthErr));
                                }
                                return Promise.resolve();
                            }
                            
                            return Promise.reject(new Error(locales.components.pages.smartWordsLibrary.typoLibrary.onlyChinese));
                        },
                    }),
                ],
            },
            {
                name: 'status',
                label: locales.components.pages.smartWordsLibrary.typoLibrary.fields.status,
                type: 'Select',
                props: {
                    options: Udesk.enums.wordsEffectiveness.map((i) => ({ label: i.name, value: i.id })),
                    style: {
                        width: 150,
                    },
                },
                rules: [{ required: true }],
            },
        ],
        footerRender: false,
        labelCol: { span: 7 },
        wrapperCol: { span: 13 },
    };
    useEffect(() => {
        addModalShow && formInstance.resetFields();
    }, [addModalShow, currentEditValues]);
    return (
        <div>
            <SmartWordsLibraryCommonList
                ref={commonListInstance}
                columns={columns}
                navItemType={corporaType}
                getlistDataPath={`typoDatas`}
                openAddModal={createSynonymWords}
                uploadModalTitle={locales.components.pages.smartWordsLibrary.upload.uploadTypo}
                onCreated={(instance) => {
                    libraryListInstance.current = instance;
                }}
                boardName={Udesk.enums.smartWordsLibraryBoard.typoLibrary.id}
                buttons={['add', 'import']}
                alertMessage={locales.components.pages.smartWordsLibrary.typoLibrary.tip}
            />

            <Modal
                visible={addModalShow}
                title={locales.components.pages.smartWordsLibrary.typoLibrary[currentEditValues.id ? 'edit' : 'create']}
                // headerClassName='udesk-qa-smart-words-library-modal-header'
                // footerClassName='udesk-qa-smart-words-library-modal-footer'
                okText={locales.labels.save}
                onOk={formInstance.submit}
                onCancel={closeAddModal}
                width={700}
            >
                <FormBuilder {...config} form={formInstance} />
            </Modal>
        </div>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
