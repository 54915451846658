import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Udesk from 'Udesk';
import { Button, Modal, Space, FormBuilder, Popconfirm } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';

import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';

import './style.scss';
import Locales from 'UdeskLocales';

const CustomerField: React.FC<any> = (props) => {
    const locales = Locales['current'];

    const [loading, setLoading] = useState(false);
    const [modalOkLoading, setModalOkLoading] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    //当前编辑的字段的id
    const [currentId, setCurrentId] = useState(null);
    const [refresh, needRefresh] = useState(0);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [fieldDetailVisible, setFieldDetailVisible] = useState(false);
    const [formRef] = FormBuilder.useForm();
    const onFinish = (values) => {
        setModalOkLoading(true);
        if(currentId){
            //编辑
            let url = Udesk.business.apiPath.concatApiPath(`/personaProps/${currentId}`, props.sdkOptions);
            Udesk.ajax.put(url, values).then(
                (resp) => {
                    setModalOkLoading(false);
                    closeFieldDtetail();
                    needRefresh((prev) => prev + 1);
                    setCurrentId(null);
                },
                (reason) => {
                    setModalOkLoading(false);
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );    
        }else{
            //新建
            let url = Udesk.business.apiPath.concatApiPath(`/personaProps`, props.sdkOptions);
            Udesk.ajax.post(url, {...values, propType: Udesk.enums.propTypes.user.id}).then(
                (resp) => {
                    setModalOkLoading(false);
                    closeFieldDtetail();
                    needRefresh((prev) => prev + 1);
                },
                (reason) => {
                    setModalOkLoading(false);
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );    
        }
    };
    const config: FormProps = {
        initialValues: {},
        fields: [
            {
                name: 'propName',
                label: locales.pages.admin.customerField.table.propName,
                type: 'Input',
                props: {
                    showCount: true,
                    maxCount: 40,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'valueType',
                label: locales.pages.admin.customerField.table.valueType,
                type: 'Select',
                forceDisabled: Boolean(currentId),
                rules: [
                    {
                        required: true,
                    },
                ],
                props: {
                    options: Udesk.enums.valueTypes.map((item) => ({ label: item.name, value: item.id })),
                },
            },
            {
                name: 'propDesc',
                label: locales.pages.admin.customerField.table.propDesc,
                type: 'TextArea',
                props: {
                    autoSize: { minRows: 2, maxRows: 2 },
                    showCount: true,
                    maxCount: 255,
                    style: {
                        width: 350,
                    },
                },
            },
        ],
        footerRender: false as any,
        labelCol: { span: 7 },
        wrapperCol: { span: 13 },
        onFinish: onFinish,
    };

    const tableProps = useMemo(() => {
        return {
            columns,
            dataSource,
            cacheKey: false as false,
            subtractedHeight: 136,
        };
    }, [columns, dataSource]);

    const modalButtonProps = useMemo(() => ({ loading: modalOkLoading }), [modalOkLoading]);

    const paginationProps = useMemo(() => {
        return {
            current: pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                setPageNum(page);
                setPageSize(pageSize);
            }
        };
    }, [pageNum, pageSize, total]);

    const openFieldDtetail = useCallback(() => {
        setFieldDetailVisible(true);
    }, []);

    const create = useCallback(() => {
        formRef.resetFields();
        openFieldDtetail();
    }, [formRef]);

    const mainBtn = useMemo(() => <Button onClick={create}>{locales.labels.create}</Button>, [openFieldDtetail]);
    const handleModalOk = useCallback(() => {
        formRef.submit();
    }, [formRef]);

    const closeFieldDtetail = useCallback(() => {
        setFieldDetailVisible(false);
        setCurrentId(null);
    }, []);

    const edit = useCallback(
        (record) => {
            setCurrentId(record.id);
            openFieldDtetail();
            let url = Udesk.business.apiPath.concatApiPath(`/personaProps/${record.id}`, props.sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    formRef.setFieldsValue(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        [formRef]
    );
    const deleteItem = useCallback((record) => {
        let url = Udesk.business.apiPath.concatApiPath(`/personaProps/${record.id}`, props.sdkOptions);
        Udesk.ajax.del(url).then(
            (resp) => {
                needRefresh((prev) => prev + 1);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );

    }, []);

    useEffect(() => {
        setColumns([
            {
                title: locales.pages.admin.customerField.table.propName,
                dataIndex: 'propName',
                key: 'propName',
            },
            {
                title: locales.pages.admin.customerField.table.valueType,
                dataIndex: 'valueType',
                key: 'valueType',
                render: (text) => Udesk.enums.valueTypes.getName(text),
            },
            {
                title: locales.pages.admin.customerField.table.createTime,
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: locales.pages.admin.customerField.table.propDesc,
                dataIndex: 'propDesc',
                key: 'propDesc',
            },
            {
                title: locales.pages.admin.customerField.table.propType,
                dataIndex: 'propType',
                key: 'propType',
                render: (text) => Udesk.enums.propTypes.getName(text),
            },
            {
                title: locales.labels.action,
                dataIndex: 'action',
                key: 'action',
                // align: 'center',
                render: (text, record, index) => {
                    let isSys = record.propType === Udesk.enums.propTypes.system.id;
                    return (
                        <Space>
                            <Button
                                onClick={edit.bind(null, record)}
                                disabled={isSys}
                                style={{ paddingLeft: 0 }}
                                type='link'
                                size='small'
                            >
                                {locales.labels.edit}
                            </Button>
                            <Popconfirm
                                visible={isSys ? false : undefined}
                                onConfirm={deleteItem.bind(null, record)}
                                title={locales.labels.deletePopconfirmContent}
                                okText={locales.labels.delete}
                                cancelText={locales.labels.cancel}
                            >
                                <Button disabled={isSys} type='link' size='small' danger={true}>
                                    {locales.labels.delete}
                                </Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            },
        ]);
    }, []);

    useEffect(() => {
        setLoading(true);
        let url = Udesk.business.apiPath.concatApiPath('/personaProps', props.sdkOptions);
        Udesk.ajax.get(url, {pageNum, pageSize}).then(
            (resp) => {
                setDataSource(resp?.data ?? []);
                if (resp.paging) {
                    const { pageNum, pageSize, total } = resp.paging;
                    setPageNum(pageNum);
                    setPageSize(pageSize);
                    setTotal(total);
                }
                setLoading(false);
            },
            (reason) => {
                setLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [pageSize, pageNum, refresh]);
    return (
        <Page
            pageBodyClassName='client-manage-page-index'
            title={locales.pages.admin.customerField.title}
            padding={true}
        >
            <CommonTablePage
                loading={loading}
                showExport={false}
                showColumsControl={false}
                // table={tableProps}
                // pagination={paginationProps}
                showFilter={false}
                mainButton={mainBtn}
            >
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>
            <Modal
                title={locales.pages.admin.customerField[currentId ? 'edit' : 'new']}
                visible={fieldDetailVisible}
                okButtonProps={modalButtonProps}
                cancelButtonProps={modalButtonProps}
                getContainer={false}
                onOk={handleModalOk}
                onCancel={closeFieldDtetail}
            >
                <FormBuilder {...config} form={formRef} />
            </Modal>
        </Page>
    );
};

const CustomerFieldMemo = React.memo(CustomerField);
class Component extends React.Component {
    render() {
        return <CustomerFieldMemo {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
