// 主界面
import Home from '../../pages/home/index';

// 语义智能-2、语义标签
import SemanticTags from 'Component/pages/semantic-intelligence/semantic-tags';
import SemanticTagsIndex from 'Component/pages/semantic-intelligence/semantic-tags/index';
import SemanticTagsDetail from 'Component/pages/semantic-intelligence/semantic-tags/detail';

// 语义智能-3、信息实体
import InformationEntities from 'Component/pages/semantic-intelligence/information-entities';
import InformationEntitiesIndex from 'Component/pages/semantic-intelligence/information-entities/index';
import InformationEntitiesDetail from 'Component/pages/semantic-intelligence/information-entities/detail';

//语义智能-6、概念词
import ConceptWord from 'Component/pages/semantic-intelligence/concept-word';
import ConceptWordIndex from 'Component/pages/semantic-intelligence/concept-word/index';
import ConceptWordDetail from 'Component/pages/semantic-intelligence/concept-word/detail';

// 语义智能-7、聚类分析
import ClusteringAnalysis from 'Component/pages/semantic-intelligence/clustering-analysis';
import ClusteringAnalysisIndex from 'Component/pages/semantic-intelligence/clustering-analysis/index';
import ClusteringAnalysisCreate from 'Component/pages/semantic-intelligence/clustering-analysis/add';
import ClusteringAnalysisDetail from 'Component/pages/semantic-intelligence/clustering-analysis/detail';

// 语义智能-8、话术挖掘
import WordsMining from 'Component/pages/semantic-intelligence/words-mining';
// import DissentWordsMining from 'Component/pages/semantic-intelligence/words-mining/dissent';
// import FavoriteWordsMining from 'Component/pages/semantic-intelligence/words-mining/favorite';
import DissentFavoriteWordsMining from 'Component/pages/semantic-intelligence/words-mining/favorite-view/dissent';
import SellingPointFavoriteWordsMining from 'Component/pages/semantic-intelligence/words-mining/favorite-view/selling-point';
// 智能分析-智能标签
import IntelligentTags from 'Component/pages/intelligent-analysis/intelligent-tags';

// 系统管理
import AdminEntry from '../../pages/sales-admin-entry';
// 系统设置
import AdminSystemIndex from '../../pages/admin/system/index';
// 语音识别
import SpeechRecognitionIndex from 'Component/pages/speech-recognition/index';

//任务中心
import TaskCenter from 'Component/pages/sales-task-center';
import TaskCenterDownloadList from 'Component/pages/task-center/download-list/index';
import TaskCenterUploadList from 'Component/pages/task-center/upload-list/index';
import TaskCentertaskList from 'Component/pages/task-center/task-list/index';
//计费中心
import BillingCenterIndex from 'Component/pages/billing-center/index';
//系统集成
import SystemIntegration from 'Component/pages/system-integration/index';
import SystemIntegrationCCpass from 'Component/pages/system-integration/cc-pass/index';
//企微对接
import WechatDocking from 'Component/pages/system-integration/wechat-docking/index.tsx';
//淘宝对接
import TaobaoDocking from 'Component/pages/system-integration/taobao/index.tsx';
import WeifengDocking from 'Component/pages/system-integration/weifeng-docking/index.tsx';

//训练中心
import TrainingCenter from 'Component/pages/sales-training-center';
import FastTrainingIndex from 'Component/pages/training-center/fast-training';
import TrainingHistoryIndex from 'Component/pages/training-center/training-history';
//404
import PageNotFound from '../../pages/404';
// 销售赋能-语义智能
import SalesSemanticIntelligence from 'Component/pages/sales-semantic-intelligence';
// 销售赋能-智能分析-智能标签
import SalesIntelligentAnalysis from 'Component/pages/sales-intelligent-analysis';
import GongCallDetail from 'Component/pages/gong/call-detail';
import TopicList from 'Component/pages/gong/topic-list';
import TopicCallout from 'Component/pages/gong/topic-callout';
import SupervisionTest from 'Component/pages/gong/check-point-test';
// 会话分析
import CallAnalysis from 'Component/pages/gong-call-list';
import CallAnalysisList from 'Component/pages/gong/call-list/index';
// gong 首页
import HomePage from 'Component/pages/gong/home-page';
// 消息中心
import MssageCenter from 'Component/pages/gong/msg-center/index';
import AdminMsgCenterReply from 'Component/pages/gong/msg-center/reply';
import AdminMsgCenterAnswer from 'Component/pages/gong/msg-center/answer';
import AdminMsgCenterShare from 'Component/pages/gong/msg-center/share';
import AdminMsgCenterSubscription from 'Component/pages/gong/msg-center/subscription';
import AdminMsgCenterStudy from 'Component/pages/gong/msg-center/study';

// gong 员工管理
import AdminGongUser from 'Component/pages/gong/user-manage';
import AdminGongUserList from 'Component/pages/gong/user-manage/list';
import AdminGongUserEdit from 'Component/pages/gong/user-manage/edit';
import AdminGongUserDetail from 'Component/pages/gong/user-manage/detail';

// gong 角色管理
import AdminGongRole from 'Component/pages/gong/role-manage/index';
import AdminGongRoleList from 'Component/pages/gong/role-manage/list';
import AdminGongRoleEdit from 'Component/pages/gong/role-manage/edit';
// 案例库
import FileCaseBase from 'Component/pages/gong/case-base';
import CaseBaseFolderList from 'Component/pages/gong/case-base';

// 辅导任务
import CoachTask from 'Component/pages/gong/coach-task';
import CoachTaskList from 'Component/pages/gong/coach-task';

// 团队分析
import TeamAnalysis from 'Component/pages/gong/team-analysis';
import MonitoringReport from 'Component/pages/gong/monitoring-report';
import ExecutionAnalysis from 'Component/pages/gong/execution-analysis/index';

// 分组测试
import GroupTest from 'Component/pages/gong/group-test';
import GroupTestList from 'Component/pages/gong/group-test/list';
import GroupTestAnalysis from 'Component/pages/gong/group-test/analysis';
import GroupTestDetail from 'Component/pages/gong/group-test/detail';
import GroupTestDetailEdit from 'Component/pages/gong/group-test/detail';

import AnalysisEnable from 'Component/pages/analysis-enable';
import AnalysisEnableStudy from 'Component/pages/gong/study-analysis';
import AnalysisEnableIntellectTags from 'Component/pages/data-analysis/sale-intelligent-tags-analysis/index';
import AnalysisEnableSentiment from 'Component/pages/intelligent-analysis/sale-sentiment-analysis/index';
import AnalysisEnableBusiness from 'Component/pages/intelligent-analysis/business-analysis.js';
import AnalysisBusinessAnalysisList from 'Component/pages/intelligent-analysis/business-analysis/index.tsx';
import AnalysisBusinessAnalysisDetail from 'Component/pages/intelligent-analysis/business-analysis/detail/index';
import AnalysisBusinessAnalysisCreate from 'Component/pages/intelligent-analysis/business-analysis/detail/index';
//销售赋能客户
import SaleClientCenterEntry from 'Component/pages/sale-client-center';
import SaleClientList from 'Component/pages/gong/sale-client-center/list';
import SaleClientDetail from 'Component/pages/gong/sale-client-center/detail';
// gong知识库
import GongKnowledgeBase from 'Component/pages/gong/knowledge-base.tsx';
import GongKnowledgeBaseList from 'Component/pages/gong/konwledge-base/index';
import GongKnowledgeBaseDetail from 'Component/pages/gong/konwledge-base/detail/index';
import GongKnowledgeBaseCreate from 'Component/pages/gong/konwledge-base/detail/index';

import SalesLearningCenterEntry from 'Component/pages/gong/salesLearningCenter';
import SalesBusinessConfigurationEntry from 'Component/pages/gong/salesBusinessConfigurationEntry';

// 业务配置
// import BusinessConfiguration from 'Component/pages/gong/business-configuration.tsx';
import SalesStageIndex from 'Component/pages/gong/business-configuration/sales-stage/index';
import SalesStageListIndex from 'Component/pages/gong/business-configuration/sales-stage/list/index';
import SalesStageDetail from 'Component/pages/gong/business-configuration/sales-stage/detail/index';
import CommunicationStrategyIndex from 'Component/pages/gong/business-configuration/communication-strategy/index';
import CommunicationStrategyListIndex from 'Component/pages/gong/business-configuration/communication-strategy/list/index';
import CommunicationStrategyDetail from 'Component/pages/gong/business-configuration/communication-strategy/detail/index';
import RiskStatementIndex from 'Component/pages/gong/business-configuration/risk-statement/index';
import RiskStatementListIndex from 'Component/pages/gong/business-configuration/risk-statement/list/index';
import RiskStatementDetail from 'Component/pages/gong/business-configuration/risk-statement/detail/index';

//本地上传
import RecordUploadDetail from 'Component/pages/record-upload/detail';
import RecordUploadIndex from 'Component/pages/record-upload/index';

const salesRouteComponentByRouteNameMappings = {
    // 主界面
    salesHome: {
        component: Home,
    },
    homePage: {
        component: HomePage,
    },
    callAnalysis: {
        component: CallAnalysis,
    },
    callAnalysisList: {
        component: CallAnalysisList,
    },
    gongCallDetail: {
        component: GongCallDetail,
    },
    topicList: {
        component: TopicList,
    },
    topicCallout: {
        component: TopicCallout,
    },
    supervisionTest: {
        component: SupervisionTest,
    },

    // 语义智能-2、语义标签
    salesSemanticIntelligence: {
        component: SalesSemanticIntelligence,
    },
    salesSemanticTags: {
        component: SemanticTags,
    },
    salesSemanticTagsIndex: {
        component: SemanticTagsIndex,
    },
    salesSemanticTagsNew: {
        component: SemanticTagsDetail,
    },
    salesSemanticTagsEdit: {
        component: SemanticTagsDetail,
    },

    // 智能分析-1、智能标签
    salesIntelligentAnalysis: {
        component: SalesIntelligentAnalysis,
    },
    salesIntelligentTags: {
        component: IntelligentTags,
    },
    //语义智能-3、信息实体
    salesInformationEntities: {
        component: InformationEntities,
    },
    salesInformationEntitiesIndex: {
        component: InformationEntitiesIndex,
    },
    salesInformationEntitiesNew: {
        component: InformationEntitiesDetail,
    },
    salesInformationEntitiesEdit: {
        component: InformationEntitiesDetail,
    },
    //语义智能-6、概念词
    salesConceptWord: {
        component: ConceptWord,
    },
    salesConceptWordIndex: {
        component: ConceptWordIndex,
    },
    salesConceptWordNew: {
        component: ConceptWordDetail,
    },
    salesConceptWordEdit: {
        component: ConceptWordDetail,
    },

    salesTrainingCenter: {
        component: TrainingCenter,
    },
    salesFastTrainingIndex: {
        component: FastTrainingIndex,
    },
    salesTrainingHistoryIndex: {
        component: TrainingHistoryIndex,
    },
    // 系统管理
    salesAdminEntry: {
        component: AdminEntry,
    },
    // 系统设置
    salesAdminSystemIndex: {
        component: AdminSystemIndex,
    },
    // 语音识别
    salesSpeechRecognitionIndex: {
        component: SpeechRecognitionIndex,
    },
    // 任务中心
    salesTaskCenter: {
        component: TaskCenter,
    },
    salesTaskCenterDownloadList: {
        component: TaskCenterDownloadList,
    },
    salesTaskCenterUploadList: {
        component: TaskCenterUploadList,
    },
    salesTaskCenterTaskList: {
        component: TaskCentertaskList,
    },
    //计费中心
    salesBillingCenterIndex: {
        component: BillingCenterIndex,
    },
    salesSystemIntegration: {
        component: SystemIntegration,
    },
    salesSystemIntegrationCCpass: {
        component: SystemIntegrationCCpass,
    },
    salesWechatDocking: {
        component: WechatDocking,
    },
    salesTaobaoDocking: {
        component: TaobaoDocking,
    },
    salesWeifengDocking: {
        component: WeifengDocking,
    },
    //本地上传
    salesRecordUploadDetail: {
        component: RecordUploadDetail,
    },
    salesRecordUploadDetailIm: {
        component: RecordUploadDetail,
    },
    salesRecordUploadIndex: {
        component: RecordUploadIndex,
    },
    //语义智能-7、聚类分析
    salesClusteringAnalysis: {
        component: ClusteringAnalysis,
    },
    salesClusteringAnalysisIndex: {
        component: ClusteringAnalysisIndex,
    },
    salesClusteringAnalysisNew: {
        component: ClusteringAnalysisCreate,
    },
    salesClusteringAnalysisEdit: {
        component: ClusteringAnalysisCreate,
    },
    salesClusteringAnalysisDetail: {
        component: ClusteringAnalysisDetail,
    },

    //语义智能-8、话术挖掘
    // salesWordsMining: {
    //     component: WordsMining,
    // },
    // dissentWordsMining: {
    //     component: DissentWordsMining,
    // },
    // favoriteWordsMining: {
    //     component: FavoriteWordsMining
    // },

    salesWordsMiningView: {
        component: WordsMining,
    },
    salesDissentFavoriteWordsMining: {
        component: DissentFavoriteWordsMining
    },
    salesSellingPointFavoriteWordsMining: {
        component: SellingPointFavoriteWordsMining,
    },
    // gong 角色管理
    adminGongRole: {
        component: AdminGongRole,
    },
    adminGongRoleList: {
        component: AdminGongRoleList,
    },
    adminGongRoleEdit: {
        component: AdminGongRoleEdit,
    },
    adminMsgCenterReply: {
        component: AdminMsgCenterReply,
    },
    adminMsgCenterAnswer: {
        component: AdminMsgCenterAnswer,
    },
    adminMsgCenterShare: {
        component: AdminMsgCenterShare,
    },
    adminMsgCenterSubscription: {
        component: AdminMsgCenterSubscription,
    },
    adminMsgCenterStudy: {
        component: AdminMsgCenterStudy,
    },
    //消息中心
    messageCenter: {
        component: MssageCenter,
    },
    // gong 员工管理
    adminGongUser: {
        component: AdminGongUser,
    },
    adminGongUserList: {
        component: AdminGongUserList,
    },
    adminGongUserEdit: {
        component: AdminGongUserEdit,
    },
    adminGongUserDetail: {
        component: AdminGongUserDetail,
    },
    fileCaseBase: {
        component: FileCaseBase,
    },
    caseBaseFolderList: {
        component: CaseBaseFolderList,
    },
    coachTask: {
        component: CoachTask,
    },
    coachTaskList: {
        component: CoachTaskList,
    },
    monitoringReport: {
        component: MonitoringReport,
    },
    teamAnalysis: {
        component: TeamAnalysis,
    },
    groupTest: {
        component: GroupTest,
    },
    groupTestAnalysis: {
        component: GroupTestAnalysis,
    },
    groupTestList: {
        component: GroupTestList,
    },
    groupTestDetail: {
        component: GroupTestDetail,
    },
    groupTestDetailEdit: {
        component: GroupTestDetailEdit,
    },
    analysisEnable: {
        component: AnalysisEnable,
    },
    analysisEnableStudy: {
        component: AnalysisEnableStudy,
    },
    analysisEnableHelp: {
        component: AnalysisEnableStudy,
    },
    analysisEnableReplay: {
        component: AnalysisEnableStudy,
    },
    analysisEnableIntellectTags: {
        component: AnalysisEnableIntellectTags,
    },
    analysisEnableSentiment: {
        component: AnalysisEnableSentiment,
    },
    executionAnalysis: {
        component: ExecutionAnalysis
    },
    analysisEnableBusiness: {
        component: AnalysisEnableBusiness,
    },
    analysisBusinessAnalysisList: {
        component: AnalysisBusinessAnalysisList,
    },
    analysisBusinessAnalysisDetail: {
        component: AnalysisBusinessAnalysisDetail,
    },
    analysisBusinessAnalysisCreate: {
        component: AnalysisBusinessAnalysisCreate,
    },
    saleClientCenterEntry: {
        component: SaleClientCenterEntry,
    },
    gongKnowledgeBase: {
        component: GongKnowledgeBase,
    },
    gongKnowledgeBaseList: {
        component: GongKnowledgeBaseList,
    },
    gongKnowledgeBaseCreate: {
        component: GongKnowledgeBaseCreate,
    },
    gongKnowledgeBaseDetail: {
        component: GongKnowledgeBaseDetail,
    },
    gongKnowledgeBaseView: {
        component: GongKnowledgeBaseDetail,
    },
    saleClientList: {
        component: SaleClientList,
    },
    saleClientDetail: {
        component: SaleClientDetail,
    },
    salesLearningCenter: {
        component: SalesLearningCenterEntry,
    },
    salesBusinessConfigurationEntry: {
        component: SalesBusinessConfigurationEntry,
    },
    // businessConfiguration: {
    //     component: BusinessConfiguration
    // },
    salesStageListIndex: {
        component: SalesStageListIndex
    },
    salesStageDetail: {
        component: SalesStageDetail
    },
    salesStageCreate: {
        component: SalesStageDetail
    },
    salesStageIndex: {
        component: SalesStageIndex
    },
    communicationStrategyListIndex: {
        component: CommunicationStrategyListIndex
    },
    communicationStrategyDetail: {
        component: CommunicationStrategyDetail
    },
    communicationStrategyCreate: {
        component: CommunicationStrategyDetail
    },
    communicationStrategyIndex: {
        component: CommunicationStrategyIndex
    },
    riskStatementListIndex: {
        component: RiskStatementListIndex
    },
    riskStatementDetail: {
        component: RiskStatementDetail
    },
    riskStatementCreate: {
        component: RiskStatementDetail
    },
    riskStatementIndex: {
        component: RiskStatementIndex
    },
    salesPageNotFound: {
        component: PageNotFound,
    },
};

export {
    salesRouteComponentByRouteNameMappings
};