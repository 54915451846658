import { apiPrefix } from 'src/util/settings';
import Udesk from 'Udesk';

function getServerAuthorization({ type }) {
    if (!type) {
        type = 4;
    }
    return new Promise(function (resolve, reject) {
        Udesk.ajax.get(`/${apiPrefix}/oss/tokens/${type}`).then(
            function (resp, textStatus, jqXHR) {
                resolve(resp);
            },
            function (reason) {
                reject(reason);
            }
        );
    });
}

export { getServerAuthorization };
