import Udesk from 'Udesk';
import React, { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useLiveEffect } from 'src/util/hook';
import { Button, Space, Tooltip, Popconfirm, FormBuilder, Modal } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import CustomerTooltip from '../table/components/customer-tooltip';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props:any) => {
    const {
        locales, 
        request, 
        list, 
        className, 
        customerId, 
        handleStageChange, 
        customerSaleFinish, 
        setCustomerSaleFinish,
        handleStageDelete
    } = props;
    const [config, setConfig] = useState<any>();

    const confirm = useCallback((activeIndex) => {
        handleStageChange(activeIndex).then(
            resp => {
                Udesk.ui.notify.success(locales.fix.editSuccess);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [handleStageChange, locales.fix.editSuccess]);

    const isWin = useMemo(() => {
        if (list?.length > 0) {
            return !!list[list.length - 1]['startTime'] && !!list[list.length - 1]['endTime'];
        }
        return false;
    }, [
        list
    ]);

    const isLose = useMemo(() => {
        return !!customerSaleFinish;
    }, [
        customerSaleFinish
    ]);

    const winData = useMemo(() => {
        return list[list.length - 1];
    }, [
        list
    ]);

    const active = useMemo(() => {
        if (isWin) {
            return winData;
        }
        return list.find(item => !item.endTime);
    }, [
        isWin, 
        list, 
        winData
    ]);

    const createLiElement = useCallback((item, index) => {
        const isActive = item === active;
        const span = (isActive || isLose || config?.manualChange !== 1 ) ? <span>{item.stageName}</span> : (
            <Popconfirm 
                title={
                    <div>
                        <div>{/* 变更商机阶段 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.changeOpportunityStage}</div>
                        <div>{/* 当前阶段为【 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.theCurrentStageIs}{active.stageName}{/* 】，确定将其变更为【 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.ConfirmToChangeItTo}{item.stageName}{/* 】吗？ */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.IsIt}</div>
                    </div>
                } 
                placement="bottom" 
                onConfirm={() => confirm(index)}
            >
                <span>{item.stageName}</span>
            </Popconfirm>
        );
        const content = item.startTime && item.endTime 
            ? (
                <Tooltip
                    title={
                        <div className='business-stage-tooltip'>
                            <div>【{item.stageName}{/* 】 阶段共停留 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.StageCoStay}{item.idleDays}{/* 天 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.day}</div>
                            <div>
                                <div>{/* 开始 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.start}：{item.startTime.replace(/:\d+$/, '')}</div>
                                <div>{/* 结束 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.end}：{item.endTime.replace(/:\d+$/, '')}</div>
                            </div>
                        </div>
                    } 
                >
                    {span}
                </Tooltip>
            ) : span;

        return (
            <li className={isActive ? 'active' : ''}>{content}</li> 
        );
    }, [
        active, 
        config?.manualChange, 
        confirm, 
        isLose
    ]);

    useLiveEffect((success) => {
        if (active?.baseInfoId) {
            request(`/saleSettingBaseInfos/${active.baseInfoId}`).then(
                resp => {
                    success(() => {
                        setConfig({
                            manualChange: resp.data.manualChange,
                            btnName: resp.data.loseBusinessDesc || /* 输单 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.orderInput,
                            options: resp.data.stopBusinessReasonList.map(item => {
                                return {
                                    value: item,
                                    label: item,
                                };
                            }),
                        });
                    });
                }
            );
        }
    }, [
        active?.baseInfoId
    ]);

    return (
        <Space className={className}>
            {
                list.length > 0 ? (
                    [
                        <ul className={isLose ? 'lose' : ''}>
                            {
                                list.map(createLiElement)
                            }
                        </ul>,
                        isLose ? (
                            <LoseButton 
                                {...{
                                    onClick: () => {handleStageDelete(customerSaleFinish?.id);},
                                    finishReason: customerSaleFinish?.finishReason
                                }}
                            >{/* 重新启动 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.restart}</LoseButton> 
                        ): (
                            <SubmitButton
                                {...{
                                    locales,
                                    request,
                                    customerId,
                                    baseInfoId: active?.baseInfoId,
                                    options: config?.options,
                                    setCustomerSaleFinish,
                                }}
                            >{config?.btnName}</SubmitButton>
                        )

                    ]
                ) : (
                    <div className='empty'>{/* 请配置销售阶段信息 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.pleaseConfigureTheSalesStageInformation}</div>
                )
            }
        </Space>
    );
});

const LoseButton = React.memo((props: any) => {
    const {children, finishReason, onClick} = props;
    return (
        <CustomerTooltip
            color={'orange'}
            placement={'bottomRight'}
            defaultFixed={true}
            defaultVisible={true}
            title={[
                `${/* 输单原因： */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.reasonForDocumentInput}${finishReason}  `
            ]} 
        >
            <Button onClick={onClick}>{children}</Button>
        </CustomerTooltip>
    );
});

const SubmitButton = React.memo((props: any) => {
    const {children, options, request, customerId, baseInfoId, setCustomerSaleFinish} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formInstance] = FormBuilder.useForm();

    const showModal = () => {
        setIsModalOpen(true);
    };
  
    const handleOk = () => {
        formInstance.submit();
    };
  
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const formConfig: FormProps = {
        onFinish: (values) => {
            request('/review/spectaculars/saleStage/finish', {
                baseInfoId,
                customerId,
                finishReason: values.finishReason
            }, 'post').then(
                resp => {
                    setCustomerSaleFinish(resp.data);
                    Udesk.ui.notify.success(/* 完成输单 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.completeOrderInput);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            ).finally(() => {
                handleCancel();
            });
        },
        fields: [
            {
                label: /* 请选择原因 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.index.pleaseSelectTheReason,
                name: 'finishReason',
                type: 'Select',
                rules: [
                    { required: true }
                ],
                props: {
                    // mode: 'multiple',
                    options,
                },
            },
            
        ],
        footerRender: false,
        labelCol: {
            sm: {
                span: 6,
            },
        },
        wrapperCol: {
            sm: {
                span: 16,
            },
        },
    };

    return (
        <>
            <Modal title={children} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <FormBuilder {...formConfig} form={formInstance} />
            </Modal>
            <Button onClick={showModal}>{children}</Button>
        </>
    );
});