import React, { useState, useEffect, useCallback } from 'react';
import { produce, setAutoFreeze } from 'immer';
import moment from 'moment';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import InspectionDetail from 'Component/pages/components/inspection-detail';
import { getInspectionTestDatasByTaskId } from 'src/api/inspectionTestDatas/{taskId}';
import { postCallTests } from 'src/api/callTests';
import type { CallTestCreateRequest, InspectionDataWrapper, PostCallTestsBody } from 'src/api/types';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Input, Space, Popconfirm } from 'udesk-ui';
import './style.scss';
import { getInspectionTestDatasTestByTaskId } from 'src/api/inspectionTestDatas/test/{taskId}';
import { getInspectionTestDatasProcessByTaskId } from 'src/api/inspectionTestDatas/process/{taskId}';
import { deleteCallTestsByTaskId } from 'src/api/callTests/{taskId}';

setAutoFreeze(false);

let timer: any = null;

const Template = React.memo((props: any) => {
    const locales = Locales['current'];

    const { match, sdkOptions } = props;
    const [detail, setDetail] = useState<InspectionDataWrapper>({});
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [testInput, setTestInput] = useState('');
    const [checkedIds, setCheckedIds] = useState<(number | string)[]>([]);
    const [loading, setLoading] = useState(false);
    // const [popconfirmVisible, setPopconfirmVisible] = useState(false);

    const inspectionDetailRef = React.useRef<any>(null);

    const sendMessage = (sender) => {
        if (!testInput) return;

        const inspectDataSource = sdkOptions?.props?.task?.inspectDataSource ?? 1;

        setDetail(
            produce((draft) => {
                if (!draft.dialogRecords) {
                    draft.dialogRecords = {
                        dialogSentenceList: [],
                    };
                }
                (draft.dialogRecords as any).dialogSentenceList.push(
                    createNewMessage({ sender, content: testInput, inspectDataSource })
                );
            })
        );
        setTestInput('');
    };
    const saveDialogRecords = () => {
        let params: PostCallTestsBody = {
            agentChannel: 0,
            bindingTaskId: match.params.taskId,
            // imLogInfos:
            //     (detail.dialogRecords as any)?.dialogSentenceList.filter(
            //         (i) => !showCheckbox || checkedIds.includes(i.id)
            //     ) ?? [],
            inspectDataSource: sdkOptions.props.task.inspectDataSource,
            type: 1, //* 1 用户保存的文本, 2 直接测试的文本
        };
        if(Udesk.business.task.isTextDialogue(sdkOptions.props.task.inspectDataSource)){
            params.imLogInfos = (detail.dialogRecords as any)?.dialogSentenceList ?? [];
        }else{
            params.sentences = (detail.dialogRecords as any)?.dialogSentenceList ?? [];
        }
        postCallTests(params).then((resp) => {
            Udesk.ui.notify.success(locales.pages.tasks.manage.template.checkPointTest.saveSuccess);
        });
    };
    const onMessageItemCheckboxClick = useCallback((checkedIds) => {
        setCheckedIds(checkedIds);
    }, []);
    const deleteMessageItems = () => {
        if (showCheckbox) {
            setDetail(
                produce((draft) => {
                    if (!draft.dialogRecords) {
                        draft.dialogRecords = {
                            dialogSentenceList: [],
                        };
                    }
                    (draft.dialogRecords as any).dialogSentenceList = (
                        draft.dialogRecords as any
                    ).dialogSentenceList.filter((i) => !checkedIds.includes(i.id));
                })
            );
        } else {
            deleteCallTestsByTaskId({
                segments: {
                    taskId: match.params.taskId,
                },
            }).then(() => {
                Udesk.ui.notify.success(locales.pages.tasks.manage.template.checkPointTest.clearSuccess);
                setDetail(
                    produce((draft) => {
                        if (!draft.dialogRecords) {
                            draft.dialogRecords = {
                                dialogSentenceList: [],
                            };
                        }
                        (draft.dialogRecords as any).dialogSentenceList = [];
                    })
                );
            });
        }
    };

    const requestDetail = () => {
        getInspectionTestDatasByTaskId({
            segments: {
                taskId: match.params.taskId,
            },
        }).then((reps) => {
            if(reps.data) {
                const detail = {...reps.data};
                if((detail.dialogRecords as any)?.dialogSentenceList?.length) {
                    (detail.dialogRecords as any).dialogSentenceList.forEach(item => {
                        if(!item.id) {
                            item.id = Udesk.utils.string.uuid();
                        }
                    });
                }
                setDetail(detail);
            }
        });
    };

    // const handleVisibleChange = (visible) => {
    //     if (!visible) {
    //         setPopconfirmVisible(visible);
    //         return;
    //     }
    //     if (showCheckbox) {
    //         deleteMessageItems(); // next step
    //     } else {
    //         setPopconfirmVisible(visible); // show the popconfirm
    //     }
    // };
    const getInspectionTestDatasProcess = () => {
        getInspectionTestDatasProcessByTaskId({
            segments: {
                taskId: match.params.taskId,
            },
        }).then((resp) => {
            let isRunning = resp.data?.result === 'running';
            setLoading(isRunning);
            if (isRunning) {
                timer = setTimeout(() => {
                    getInspectionTestDatasProcess();
                }, 5000);
            } else {
                requestDetail();
            }
        });
    };

    const startTest = () => {
        let params: CallTestCreateRequest = {
            agentChannel: 0,
            bindingTaskId: match.params.taskId,
            inspectDataSource: sdkOptions.props.task.inspectDataSource,
            type: 2, //* 1 用户保存的文本, 2 直接测试的文本
        };
        if(Udesk.business.task.isTextDialogue(sdkOptions.props.task.inspectDataSource)){
            params.imLogInfos = (detail.dialogRecords as any)?.dialogSentenceList ?? [];
        }else{
            params.sentences = (detail.dialogRecords as any)?.dialogSentenceList ?? [];
        }
        postCallTests(params)
            .then((resp) => {
                return getInspectionTestDatasTestByTaskId({
                    segments: {
                        taskId: match.params.taskId,
                    },
                });
            })
            .then((resp) => {
                getInspectionTestDatasProcess();
            });
    };
    useEffect(() => {
        getInspectionTestDatasProcess();
        // requestDetail();
        return () => {
            timer && clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        // if((detail.dialogRecords as any)?.dialogSentenceList?.length) {
        //     inspectionDetailRef.current?.actions.scrollTo((detail.dialogRecords as any)?.dialogSentenceList?.length - 1);
        // }
        const index = (detail.dialogRecords as any)?.dialogSentenceList?.length - 1;
        scrollTo(index);
        function scrollTo(index) {
            const elements = document.getElementsByClassName('item');
            if (elements?.length) {
                if (elements[index]) {
                    (elements[index] as any)?.scrollIntoViewIfNeeded?.();
                } else {
                    setTimeout(() => {
                        scrollTo(index);
                    }, 200);
                }
            }
        }
    }, [(detail.dialogRecords as any)?.dialogSentenceList?.length]);
    return (
        <Page
            pageBodyClassName='check-point-test-page-index'
            title={locales.pages.tasks.manage.template.checkPointTest.title}
            padding={true}
            loading={loading}
            loadingTip={locales.pages.tasks.manage.template.checkPointTest.running}
            subTitle={/* 规则测试：部分规则不能使用规则测试，如语速音量、随路数据等 */locales.pages.checkPointTest.index.ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData}
        >
            <InspectionDetail
                ref={inspectionDetailRef}
                rerenderOptimization={true}
                id={match.params.id}
                taskId={match.params.taskId}
                detail={detail}
                isTest={true}
                showCheckbox={showCheckbox}
                onMessageItemCheckboxClick={onMessageItemCheckboxClick}
                renderHeader={
                    <div className='audio-call-content-info-control'>
                        <div>{locales.pages.tasks.manage.template.checkPointTest.mock}</div>
                        <Space>
                            <Button type='link' size='small' onClick={saveDialogRecords}>
                                {locales.pages.tasks.manage.template.checkPointTest[showCheckbox ? 'save' : 'saveAll']}
                            </Button>
                            <Button type='link' size='small' onClick={() => setShowCheckbox((prev) => !prev)}>
                                {showCheckbox
                                    ? locales.pages.tasks.manage.template.checkPointTest.close
                                    : locales.labels.edit}
                            </Button>
                            <Popconfirm
                                title={
                                    locales.pages.tasks.manage.template.checkPointTest[
                                        !showCheckbox ? 'request' : 'deletePopconfirmContent'
                                    ]
                                }
                                // visible={popconfirmVisible}
                                // onVisibleChange={handleVisibleChange}
                                onConfirm={deleteMessageItems}
                                // onCancel={() => setPopconfirmVisible(false)}
                                okText={
                                    showCheckbox
                                        ? locales.labels.delete
                                        : locales.pages.tasks.manage.template.checkPointTest.clear
                                }
                                cancelText={locales.labels.cancel}
                            >
                                <Button type='link' size='small'>
                                    {showCheckbox
                                        ? locales.pages.tasks.manage.template.checkPointTest.delete
                                        : locales.pages.tasks.manage.template.checkPointTest.clear}
                                </Button>
                            </Popconfirm>
                        </Space>
                    </div>
                }
            />
            <div className='check-point-test-page-index-foot'>
                <div className='check-point-test-page-index-foot-left'>
                    <Input value={testInput} onChange={(e) => setTestInput(e.target.value)} style={{ flex: 1 }} />
                    <Button onClick={sendMessage.bind(null, 'customer')} disabled={!testInput}>
                        {locales.pages.tasks.manage.template.checkPointTest.customer}
                    </Button>
                    <Button onClick={sendMessage.bind(null, 'agent')} disabled={!testInput}>
                        {locales.pages.tasks.manage.template.checkPointTest.agent}
                    </Button>
                </div>
                <div className='check-point-test-page-index-foot-right'>
                    <Button
                        type='primary'
                        onClick={startTest}
                        disabled={((detail?.dialogRecords as any)?.dialogSentenceList?.length ?? 0) === 0}
                    >
                        {locales.pages.tasks.manage.template.checkPointTest.test}
                    </Button>
                </div>
            </div>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

function createNewMessage({ sender, content, inspectDataSource }) {
    if (inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id) {
        return createImMessage({ sender, content });
    } else {
        return createCallMessage({ sender, content });
    }
}
function createCallMessage({ sender, content }) {
    return {
        averageSpeakSpeed: 0,
        emotionValue: -1,
        endSec: -5.368,
        fromSec: -5.368,
        maskText: null,
        role: sender,
        sentiment: null,
        speechRate: -1,
        text: content,
        volume: null,
        id: Udesk.utils.string.uuid(),
    };
}
function createImMessage({ sender, content }) {
    return {
        content: JSON.stringify({
            type: 'message',
            version: 1,
            platform: 'web',
            data: {
                content,
                filename: '',
                filesize: '',
                fileext: '',
            },
            filename: '',
            filesize: '',
            fileext: '',
            no_need_save: true,
            seq_num: 1,
        }),
        created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        id: Udesk.utils.string.uuid(),
        sender,
    };
}
