import React, { useEffect, useState } from 'react';
import { Table } from 'udesk-ui';
import Udesk from 'Udesk';
import { TableProps as TablePropsOriginal } from 'udesk-ui/lib/table';
import './style.scss';
interface TableProps<RecordType> extends TablePropsOriginal<RecordType> {
    containerDom?: Element | null;
    subtractedHeight?: number | string;
}
const AutoSizeTable: React.FC<TableProps<any>> = (props) => {
    const [containerDom, setContainerDom] = useState<Element>(document.getElementsByTagName('body')[0]);
    const [y, setY] = useState<number>(300);
    const [dataSource, setDataSource] = useState<any>(props.dataSource);
    const [shouldReComputed, setReComputedFlag] = useState<string>(Udesk.utils.string.uuid());

    const needReComputed = () => {
        setReComputedFlag(Udesk.utils.string.uuid());
    };
    useEffect(() => {
        setContainerDom(props.containerDom || document.getElementsByClassName('udesk-qa-web-page-body')[0]);
        window.addEventListener('resize', needReComputed);
        return () => {
            window.removeEventListener('resize', needReComputed);
        };
    }, [props.containerDom]);
    useEffect(() => {
        let height = 300;
        //+2 兼容border宽度
        let tableHeaderHeight = 55 + 2;
        if (props.size === 'middle') {
            tableHeaderHeight = 47 + 2;
        }
        if (props.size === 'small') {
            tableHeaderHeight = 29 + 2;
        }
        containerDom &&
            window.getComputedStyle(containerDom).height &&
            (height = parseInt(window.getComputedStyle(containerDom).height, 10));
        if (typeof props.subtractedHeight === 'number' || typeof props.subtractedHeight === 'string') {
            if (typeof props.subtractedHeight === 'number' && !Number.isNaN(props.subtractedHeight)) {
                height -= props.subtractedHeight + tableHeaderHeight;
            } else if (
                typeof props.subtractedHeight === 'string' &&
                !Number.isNaN(parseInt(props.subtractedHeight, 10))
            ) {
                height -= parseInt(props.subtractedHeight, 10) + tableHeaderHeight + 112; //各种padding和pagination的高度
            }
        }
        setY(height);
    }, [shouldReComputed, props.subtractedHeight, containerDom, props.size]);

    useEffect(() => {
        
        if (
            Array.isArray(props.dataSource) &&
            Array.isArray(dataSource) &&
            dataSource.length !== props.dataSource.length
        ) {
            
            needReComputed();
        }
        setDataSource(props.dataSource);
    }, [props.dataSource, dataSource]);

    const getY = () => {
        let dom = document.getElementsByClassName('udesk-qa-ui-table-tbody')[0];
        return dom &&
            (parseInt(window.getComputedStyle(dom).height, 10) >= y ||
                isNaN(parseInt(window.getComputedStyle(dom).height, 10)))
            ? y
            : undefined;
    };
    return (
        <div className='udesk-qa-auto-size-table-container'>
            <Table
                {...props}
                scroll={
                    props.containerDom || props.subtractedHeight
                        ? props.scroll && props.scroll.x
                            ? { y: getY(), x: props.scroll.x }
                            : { y: getY() }
                        : props.scroll
                            ? props.scroll
                            : undefined
                }
            />
        </div>
    );
};

export default AutoSizeTable;
