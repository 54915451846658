import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';

import Page from 'Component/common/udesk-qa-page-layout';

import './style.scss';
import { CommonPage, StandardizeTable } from 'udesk_gm_ui';
import SupervisionClassificationModal from 'Component/pages/components/gong/supervision-classification-modal';
import { getSupervisionRuleConfigs, postSupervisionRuleConfigs } from 'src/api/supervisionRuleConfigs';
import { deleteSupervisionRuleDetails, getSupervisionRuleDetails } from 'src/api/supervisionRuleDetails';
import { Button, Input, Popconfirm, Space, Icon } from 'udesk-ui';
import { deleteSupervisionRuleConfigsById } from 'src/api/supervisionRuleConfigs/{id}';
import { CommonFilterProps } from 'udesk_gm_ui/es/common-filter';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const DEFAULT_PAGE = {
    current: 1,
    pageSize: 20,
    total: 0,
};

const ClassificationTitle = (props) => {
    const { treeData, setTreeData, setLoading, storePageInfo } = props;
    const locales = Locales['current'];

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{/* 监督项分类 */}{locales.pages.intelligentAnalysis.supervision.index.supervisionItemClassification}</div>
            <Button
                type='link'
                size='small'
                onClick={() => {
                    if (treeData.some((i) => i.key === 'new-class')) return;
                    setTreeData((prev) => [
                        ...prev,
                        {
                            title: (
                                <Input
                                    autoFocus
                                    size='small'
                                    placeholder={/* 按回车新建 */locales.pages.intelligentAnalysis.supervision.index.pressEnterToCreateANew}
                                    onPressEnter={(e) => {
                                        if (!(e.target as any).value) {
                                            Udesk.ui.notify.error(/* 名称不能为空 */locales.pages.intelligentAnalysis.supervision.index.nameCannotBeEmpty);
                                            return;
                                        }
                                        postSupervisionRuleConfigs({
                                            categoryName: (e.target as any).value,
                                            orderNum: 1,
                                        }).then(() => {
                                            getSupervisionRuleConfigs()
                                                .then((resp) => {
                                                    setTreeData(
                                                        (resp.data ?? []).map((i) => ({
                                                            key: i.id,
                                                            title: i.categoryName,
                                                        }))
                                                    );
                                                    if (
                                                        Array.isArray(resp.data) &&
                                                        resp.data.length - 1 >= 0 &&
                                                        resp.data![resp.data.length - 1]
                                                    ) {
                                                        storePageInfo((prev) => ({
                                                            ...DEFAULT_PAGE,
                                                            classificationKey: resp.data![resp.data!.length - 1].id,
                                                        }));
                                                    }
                                                })
                                                .finally(() => {
                                                    setLoading(false);
                                                });
                                        });
                                    }}
                                    addonAfter={
                                        <Button 
                                            type="link" 
                                            icon={<Icon type='CloseOutlined' antdIcon={true} />}
                                            size='small'
                                            onClick={() =>
                                                setTreeData((prev) => prev.filter((i) => i.key !== 'new-class'))
                                            } 
                                        />
                              
                                    }
                                ></Input>
                            ),
                            key: 'new-class',
                        },
                    ]);
                }}
            >{/* 新建 */}{locales.pages.intelligentAnalysis.supervision.index.newlyBuild}</Button>
        </div>
    );
};

const DragHandle = SortableHandle(() => (
    <Icon type='MenuOutlined' antdIcon={true} style={{ float: 'right', cursor: 'grab', color: '#999', marginTop: 6 }} />
));

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />);

function Supervision(props) {
    const locales = Locales['current'];
    const { sdkOptions} = props;
    const { pageInfo = {}, storePageInfo } = CommonPage.usePageStateMemo();
    
    const [pagination, setPagination] = useState(DEFAULT_PAGE);

    const [dataSource, setDataSource] = useState<any[]>([]);
    const [treeData, setTreeData] = useState<any[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [currentClass, setCurrentClass] = useState<any>(null);

    const [showSortDragHandle, setShowSortDragHandle] = useState<boolean>(false);
    const DraggableContainer = (props: SortableContainerProps) => (
        <SortableBody useDragHandle helperClass='row-dragging' onSortEnd={onSortEnd} {...props} />
    );

    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (oldIndex !== newIndex) {
            const data = [...dataSource];
            const row = data[oldIndex];
            data.splice(oldIndex, 1);
            data.splice(newIndex, 0, row);
            setDataSource(data);
        }
    };

    const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
        const key = restProps['data-row-key'];
        const index = dataSource.findIndex((item) => item.id === key);
        return <SortableItem index={index} {...restProps} />;
    };

    const deleteItem = useCallback((ids) => {
        return deleteSupervisionRuleDetails({
            params: {
                ids,
            },
        }).then(() => {
            getSupervisionRuleDetails({
                params: {
                    categoryId: pageInfo.classificationKey,
                    pageNum: pagination.current,
                    pageSize: pagination.pageSize,
                },
            }).then((resp) => {
                setDataSource(resp.data ?? []);
                setPagination((prev) => Object.assign({}, prev, resp.paging));
            });
        });
    }, [pageInfo.classificationKey, pagination]);

    const columns = useMemo(() => {
        return [
            {
                title: /* 名称 */locales.pages.intelligentAnalysis.supervision.index.name,
                key: 'name',
                dataIndex: 'name',
                width: 100,
                ellipsis: true,
            },
            {
                title: /* 说明 */locales.pages.intelligentAnalysis.supervision.index.explain,
                key: 'remark',
                dataIndex: 'remark',
                width: 200,
                ellipsis: true,
            },
            // {
            //     title: '监督项分类',
            //     key: 'class',
            //     dataIndex: 'class',
            // },
            {
                title: /* 有效状态 */locales.pages.intelligentAnalysis.supervision.index.validStatus,
                key: 'status',
                dataIndex: 'status',
                render: (name, item, index) => {
                    return (
                        <div>
                            {item.effectConfig === 2 ? (
                                <div>
                                    {item.effectStartTime} - {item.effectEndTime}
                                </div>
                            ) : item.effectConfig ? (
                                /* 永久有效 */locales.pages.intelligentAnalysis.supervision.index.permanentlyValid
                            ) : (
                                /* 永久无效 */locales.pages.intelligentAnalysis.supervision.index.permanentlyInvalid
                            )}
                        </div>
                    );
                },
            },
            {
                title: /* 操作 */locales.pages.intelligentAnalysis.supervision.index.operation,
                key: 'actions',
                dataIndex: 'actions',
                width: 170,
                render: (name, item, index) => {
                    return (
                        <>
                            <Space>
                                <Button
                                    type='link'
                                    size='small'
                                    onClick={() => {
                                        const routeOptions = {
                                            history: props.history,
                                            routeName: 'supervisionDetail',
                                            pathParams: { id: item.id },
                                        };
                                        Udesk.ui.routing.transitionTo(routeOptions);
                                    }}
                                >{/* 编辑 */}{locales.pages.intelligentAnalysis.supervision.index.edit}</Button>
                                <Popconfirm
                                    title={locales.labels.deleteContent}
                                    onConfirm={deleteItem.bind(null, item.id)}
                                    onCancel={(e) => e!.stopPropagation()}
                                    okText={locales.labels.confirm}
                                    placement='topRight'
                                    cancelText={locales.labels.cancel}
                                >
                                    <Button type='link' size='small'>
                                        {locales.labels.delete}
                                    </Button>
                                </Popconfirm>
                            </Space>
                            {showSortDragHandle && <DragHandle></DragHandle>}
                        </>
                    );
                },
            },
        ];
    }, [deleteItem, props.history, showSortDragHandle, pageInfo.classificationKey, pagination]);

    const batchedActions = [
        {
            label: /* 批量删除 */locales.pages.intelligentAnalysis.supervision.index.batchDelete,
            onClick: (params) => {
                return deleteItem(params.map((i) => i.id).join(','));
            },
            danger: true,
        },
    ];
    const toolBarRightButtons = useMemo(() => {
        return [
            {
                label: showSortDragHandle ? /* 确定 */locales.pages.intelligentAnalysis.supervision.index.determine : /* 排序 */locales.pages.intelligentAnalysis.supervision.index.sort,
                onClick: () => {
                    if (showSortDragHandle) {
                        const {
                            current, pageSize
                        } = pagination;
                        const start = current * pageSize;
                
                        const url = Udesk.business.apiPath.concatApiPath(`supervisionRuleDetails/orders`, sdkOptions);
                        const params = {
                            updateOrderInspectionPointList: dataSource.map((item, index) => {
                                return {
                                    id: item.id,
                                    orders: index + start,
                                };
                            })
                        };
                        Udesk.ajax.post(url, params).then(
                            () => {
                                Udesk.ui.notify.success(/* 排序成功 */locales.pages.intelligentAnalysis.supervision.index.sortSucceeded);
                            },
                            (reason) => {
                                Udesk.ui.notify.error(reason.errorMsg);
                            }
                        );
                    }
                    setShowSortDragHandle((isShow) => !isShow);
                },
            },
            {
                label: /* 新建监督项 */locales.pages.intelligentAnalysis.supervision.index.newSupervisionItem,
                mainBtn: true,
                onClick: () => {
                    const routeOptions = {
                        history: props.history,
                        routeName: 'supervisionCreate',
                        queryParams: {
                            classificationId: pageInfo.classificationKey,
                        },
                    };
                    Udesk.ui.routing.transitionTo(routeOptions);
                },
            },
        ];
    }, [dataSource, pageInfo.classificationKey, pagination, props.history, sdkOptions, showSortDragHandle]);

    const requestClassData = useCallback(() => {
        return new Promise((res, rej) => {
            getSupervisionRuleConfigs()
                .then((resp) => {
                    setTreeData((resp.data ?? []).map((i) => ({ key: i.id, title: i.categoryName })));
                    if (resp.data?.[0] && !pageInfo.classificationKey) {
                        storePageInfo((prev) => ({
                            ...prev,
                            current: 1,
                            classificationKey: resp.data?.[0]?.id,
                        }));
                    }
                    res(resp);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, [pageInfo.classificationKey, storePageInfo]);
    
    useEffect(() => {
        requestClassData();
    }, []);

    const requestList = useCallback((params) => {
        setTableLoading(true);
        getSupervisionRuleDetails({ params })
            .then((resp) => {
                setDataSource(resp.data ?? []);
                setPagination((prev) => Object.assign({}, prev, resp.paging, { current: resp.paging?.pageNum }));
            })
            .finally(() => {
                setTableLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!pageInfo.classificationKey) return;
        requestList({
            categoryId: pageInfo.classificationKey,
            pageNum: pageInfo.current ?? 1,
            pageSize: pagination.pageSize,
            tagName: pageInfo.tagName,
        });
    }, [pageInfo.classificationKey]);

    const filterFields: CommonFilterProps['fields'] = [
        {
            type: 'Input',
            name: 'tagName',
            label: /* 关键词 */locales.pages.intelligentAnalysis.supervision.index.keyWord,
        },
    ];
    const [filterInitialValues] = useState({ tagName: pageInfo.tagName });
    return (
        <Page padding={0} pageBodyClassName='supervision-list-page' backGroundGhost={true}>
            <StandardizeTable
                filter={{
                    fields: filterFields,
                    initialValues: filterInitialValues,
                    onSearch: (v, o) => {
                        storePageInfo((prev) => ({
                            ...prev,
                            tagName: v.tagName,
                        }));
                        requestList({
                            categoryId: pageInfo.classificationKey,
                            pageNum: 1,
                            pageSize: pagination.pageSize,
                            ...v,
                        });
                    },
                }}
                table={{
                    columns,
                    dataSource,
                    loading: tableLoading,
                    pagination: {
                        ...pagination,
                        onChange: (page, pageSize) => {
                            requestList({
                                categoryId: pageInfo.classificationKey,
                                pageNum: page,
                                pageSize: pageSize,
                                tagName: pageInfo.tagName,
                            });
                        },
                    },
                    cacheId: 'aaa',
                    rowSelectionEnhance: {},
                    components: {
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    },
                }}
                batchedActions={batchedActions}
                toolBarRightButtons={toolBarRightButtons}
                classification={{
                    title: <ClassificationTitle {...{ treeData, setTreeData, setLoading, storePageInfo }} />,
                    loading,
                    treeData,
                    selectedKeys: [pageInfo.classificationKey],
                    treeNodeActionMenu: [
                        {
                            label: /* 编辑 */locales.pages.intelligentAnalysis.supervision.index.edit,
                            onClick: (item) => {
                                setCurrentClass(item);
                                setVisible(true);
                            },
                            disabled: (item) => item.itemKey === 'new-class',
                        },
                        {
                            label: /* 删除 */locales.pages.intelligentAnalysis.supervision.index.delete,
                            danger: true,
                            onClick: (item) => {
                                deleteSupervisionRuleConfigsById({
                                    segments: {
                                        id: item.itemKey,
                                    },
                                    successMsg: /* 删除成功 */locales.pages.intelligentAnalysis.supervision.index.deleteSucceeded,
                                }).then(() => {
                                    requestClassData().then((resp: any) => {
                                        storePageInfo((prev) => ({
                                            ...prev,
                                            current: 1,
                                            classificationKey: resp.data?.[0]?.id,
                                        }));
                                        requestList({
                                            categoryId: resp.data?.[0]?.id,
                                            pageNum: pageInfo.current,
                                            pageSize: pagination.pageSize,
                                            tagName: pageInfo.tagName,
                                        });
                                    });
                                });
                            },
                            disabled: (item) => item.itemKey === 'new-class',
                        },
                    ],
                    onSelect: (key, options) => {
                        if (key === 'new-class') return;
                        storePageInfo({
                            ...pageInfo,
                            classificationKey: key,
                        });
                    },
                }}
            />
            <SupervisionClassificationModal
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                    setCurrentClass(null);
                }}
                onSuccess={() => {
                    setVisible(false);
                    setCurrentClass(null);
                    requestClassData();
                }}
                currentClassificationId={currentClass?.itemKey}
            />
        </Page>
    );
}

class Component extends React.Component {
    render() {
        return <Supervision{...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
