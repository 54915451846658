import LocalesManagerClass from 'udesk-web-toolkits/src/udesk/locales/locales-manager';
import udeskWebToolkitsLocales from 'udesk-web-toolkits/src/udesk/locales/index';

import Chinese from './zh-cn.json';
import English from './en-us.json';
import Japanese from './ja.json';
import Vietnam from './vi.json';

import ar from "./ar.json";
import ru from "./ru.json";
import th from "./th.json";
import tr from "./tr.json";
import vi from "./vi.json";
import es from "./es.json";
import fr from "./fr.json";
import pt from "./pt.json";
import zhHk from "./zh-HK.json";
import zhTw from "./zh-TW.json";
import id from './id.json';
import de from './de.json';
import it from './it.json';
import ko from './ko.json';
import pl from './pl.json';
import fi from './fi.json';
import nl from './nl.json';
import cs from './cs.json';
import ms from './ms.json';
import el from './el.json';
import fil from './fil.json';


const localesStore = {
    "ZH-CN": Chinese,
    "EN-US": English,
    "JA": Japanese,
    "JA-JP": Japanese,
    'VI-VN': Vietnam,
    "ZH-CN-SSC": Chinese,
    "EN-US-SSC": English,
    "JA-JP-SSC": Japanese,
    'VI-VN-SSC': Vietnam,
    // add by tangyubin in 2023/11/16
    'AR': ar,
    'RU': ru,
    'TH': th,
    'TR': tr,
    'VI': vi,
    'ES': es,
    'FR': fr,
    'PT': pt,
    'ZH-HK': zhHk,
    'ZH-TW': zhTw,
    'ID': id,
    'DE': de,
    'IT': it,
    'KO': ko,
    'PL': pl,
    'FI': fi,
    'NL': nl,
    'CS': cs,
    "MS": ms,
    "EL": el,
    "FIL": fil
    // end
};

class ReactLocalesManagerClass extends LocalesManagerClass {
    setLanguage(languageCode) {
        super.setLanguage(languageCode);
        udeskWebToolkitsLocales.setLanguage(languageCode);
    }
}

export default new ReactLocalesManagerClass(localesStore, "ZH-CN");