import React, { useCallback, useEffect, useRef, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Col, Row, Space } from "udesk-ui";
import { useLiveEffect, useRequest } from 'src/util/hook';
import { linkTo } from 'src/util/link';
import { isExercise } from '../../../components/util';
import { useChatRecord } from '../../../components/record/text/hook';
import { getDateTimeString } from 'src/util/moment';
import Page from "Component/common/udesk-qa-page-layout";
import CustomerInfo from '../../../components/customer-info';
import ChatRecordPanel from '../../../components/record/text';
import VideoRecord from '../../../components/record/video';
import FlowPanel from '../../../components/flow';
import TaskCue from '../../../components/cue';
import TaskMessageModal, { RefType } from '../../../components/task-message-modal';
import TaskSubmitButton from '../../../components/task-submit-button';
import './index.less';

const Template = React.memo((props: any) => {
    const {history, match} = props;
    const locales = Locales['current'];
    const taskId = match.params.id;
    const [taskDetail, setTaskDetail] = useState<any>(null);
    const request = useRequest();
    const [record, setRecord] = useState<any>(null); // 记录
    const {
        nextMessage,
        flowIndex, flowData,
        globalConfig,
        referenceWords,
        loadingForSendMessage, // 发送消息接口的loading
        loadingForTaskStart, // 任务开始接口的loading
        passThroughParams, // 消息透传参数
        isStartTask, // 是否开始任务
        isLastNode, // 任务是否是最后一个节点
        list, // 消息列表
        startTaskHandle, // 开始任务
        submitMessageHandle, // 提交一通消息
        
        suspendDetail,
        isSuspend, isSuspending, isLoadSuspendInfo, 
        loadingForSuspend, 
        pauseTask, continueTask, restartTask,
    } = useChatRecord({
        request,
        task: taskDetail,
        record, // 记录
        setRecord,
    });

    const [submitMessage, setSubmitMessage] = useState<{handle: Function}>();

    const taskMessageModalRef = useRef<RefType>(null);

    // 获取任务详情信息
    useLiveEffect((success) => {
        request(`/intelligentPartner/${taskId}/pageDetail`).then(
            resp => {
                success(() => {
                    const data = resp.data;
                    if (data) {
                        data.id = parseInt(taskId);
                        setTaskDetail(data);
                    }
                });
            }
        );

    }, [request, taskId]);

    useEffect(() => {
        isLastNode && taskMessageModalRef.current?.open(4);
    }, [isLastNode]);

    const goBack = useCallback(() =>{
        isExercise(
            taskDetail?.taskType,
            () => linkTo(history, 'coachLearningCenterExerciseTask'),
            () => linkTo(history, 'coachLearningCenterTestTask')
        );
    }, [history, taskDetail?.taskType]);

    const goNext = useCallback(() => {
        const isOpen = taskDetail?.enableManualReview === Udesk.enums.enableManualReviewType.open.id;
        linkTo(
            history, 
            isOpen ? 'coachLearningCenterRecord' : 'coachLearningCenterRecordDetail', 
            {id: record?.id}, 
            {
                backRouteParams: [
                    isExercise(
                        taskDetail?.taskType, 'coachLearningCenterExerciseTask', 'coachLearningCenterTestTask'
                    )
                ] 
            }, 
            isOpen ? {taskId} : {}
        );
    }, [history, record?.id, taskDetail?.enableManualReview, taskDetail?.taskType, taskId]);


    const cancelTaskAjax = useCallback(() => {
        request(`/intelligentPartner/${record.id}/cancel`).then(
            resp => {
                goBack();
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [goBack, record?.id, request]);

    const cancelTaskHandle = useCallback(() => {
        if (isStartTask) {
            taskMessageModalRef.current?.open(taskDetail?.taskType);
        } else {
            goBack();
        }
    }, [goBack, isStartTask, taskDetail?.taskType]);

    const submitTaskHandle = useCallback(() => {
        request(`/intelligentPartner/${record.id}/submit`, {
            flowId: passThroughParams?.flowId,
            video: "",
            submitTime: getDateTimeString(),
            isReviewed: taskDetail?.enableManualReview
        }, 'put').then(
            resp => {
                Udesk.ui.notify.success(locales.business.info.saveSuccess);
                goNext();
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [
        goNext, 
        locales.business.info.saveSuccess, 
        passThroughParams?.flowId, 
        record?.id, 
        request, 
        taskDetail?.enableManualReview
    ]);

    const pauseTaskHandle = useCallback((callback) => {
        pauseTask(() => {
            taskMessageModalRef.current?.open(5);
        }, {
            flowId: passThroughParams.flowId,
            suspendTime: getDateTimeString(),
            video: ''
        });
    }, [passThroughParams.flowId, pauseTask]);

    return (
        <Page
            pageClassName='learning-center-exercise'
            pageBodyClassName='learning-center-exercise-body call'
            backGroundGhost={true}
            onBack={cancelTaskHandle}
            title={taskDetail?.taskName}
            extra={
                <TaskSubmitButton 
                    {...{
                        nextMessage,
                        submitMessage: submitMessage?.handle,
                        pauseTask: pauseTaskHandle,
                        isSuspending,
                        loadingForSuspend,
                        taskMessageModalRef,
                        record,
                        taskDetail,
                        isLastNode,
                        isStartTask,
                        submitTaskHandle,
                    }}/>
            }
        >
            <Row gutter={16}>
                {
                    taskDetail?.customerPersona ? (
                        <Col span={5}>
                            <CustomerInfo data={taskDetail?.customerPersona}/>
                        </Col>
                    ) : null
                }
                <Col span={taskDetail?.customerPersona ? 13 : 18}>
                    <ChatRecordPanel {...{
                        setSubmitMessage,
                        nextMessage,
                        task: taskDetail,
                        globalConfig,
                        isStartTask,
                        isLastNode,
                        list,
                        startTaskHandle,
                        submitMessageHandle,
                        loadingForTaskStart,
                        loadingForSendMessage,
                    }} />
                </Col>
                <Col span={6}>
                    <Space direction={'vertical'} className={'other-panel'}>
                        <VideoRecord />
                        <FlowPanel index={flowIndex} list={flowData} isLastNode={isLastNode}/>
                        <TaskCue referenceWords={referenceWords} taskDetail={taskDetail} />
                    </Space>
                </Col>
                <Col flex="0" style={{padding: 0}}>
                    <TaskMessageModal
                        {...{
                            ref: taskMessageModalRef,
                            goBack,
                            cancelTaskAjax,
                            submitTaskAjax: submitTaskHandle,
                            taskDetail,
                            suspendDetail,
                            isSuspend, isLoadSuspendInfo, 
                            loadingForSuspend, 
                            continueTask, restartTask,
                        }}/>
                </Col>
            </Row>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
