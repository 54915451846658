import React, { useState, useEffect, useCallback } from 'react';
import Locales from 'UdeskLocales';
import Udesk from 'Udesk';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import {
    Row,
    Col,
    Form,
    Input,
    Icon,
    Button,
    TreeSelect,
    Select,
    Space,
    Popconfirm,
    Modal,
    // DatePicker,
    InputNumber,
    Radio,
} from 'udesk-ui';
import { getUsers } from 'src/api/users';
import { fire, isArray, isDefined } from 'src/util/core';
import { AGENT, CALL_DURATION, CALL_ID, CALL_TIME, CHANNEL, CUSTOMER_NICK_NAME, KEY_EVENTS, SMART_TAGS, TOPICS, TOPIC_TAGS } from '../call-list';
import { EnumSelect } from '../group-test/util';

const locales = Locales['current'];
const { SHOW_PARENT, /* SHOW_ALL */ } = TreeSelect;

const { Option } = Select;
// const { RangePicker } = DatePicker;
const InputGroup = Input.Group;

/**
    添加过滤器参数 START
*/
interface RequestAddFilterParam {
    name: string;
    status: number;
    templateType: number;
    judgeStrategy: number;
    customJudgeLogic: string;
    conditionList: {
        field: {
            id: string;
            dataSourceType: number;
            label: string;
            fieldName: string;
            fieldType: number;
            optionValueList?: {
                id: number;
                name: string;
                color: string;
            }[];
        };
        operator: string;
        value: string;
    }[];
    keyword: string;
    keywordType: number;
    channelType?: number;
}

/**
    /*review/search-template
    */
interface ResponseAddFilterData {
    code: number;
    message: string;
    visible: boolean;
    data: {
        id: number;
        name: string;
        status: number;
        templateType: number;
        judgeStrategy: number;
        customJudgeLogic: string;
        conditionList: {
            field: {
                id: string;
                dataSourceType: number;
                label: string;
                fieldName: string;
                fieldType: number;
                optionValueList?: {
                    id: number;
                    name: string;
                    color: string;
                }[];
            };
            operator: string;
            value: string;
        }[];
        keyword: string;
        keywordType: number;
    };
}
/**  END */

/**
    /*删除过滤器参数 START
    */
interface ResponseDeleteFilterData {
    code: number;
    message: string;
    visible: boolean;
}
/**  删除过滤器参数 END */

/**
    /*修改过滤器参数 START
    */
interface ResquestEditFilterParam {
    id?: number;
    name: string;
    status: number;
    templateType: number;
    judgeStrategy: number;
    customJudgeLogic: string;
    conditionList: {
        field: {
            id: string;
            dataSourceType: number;
            label: string;
            fieldName: string;
            fieldType: number;
            optionValueList?: {
                id: number;
                name: string;
                color: string;
            }[];
        };
        operator: string;
        value: string;
    }[];
    keyword: string;
    keywordType: number;
}

/**
    /*review/search-template/{id}
    */
interface ResponseEditFilterData {
    code: number;
    message: string;
    visible: boolean;
    data: {
        id: number;
        name: string;
        status: number;
        templateType: number;
        judgeStrategy: number;
        customJudgeLogic: string;
        conditionList: {
            field: {
                id: string;
                dataSourceType: number;
                label: string;
                fieldName: string;
                fieldType: number;
                optionValueList?: {
                    id: number;
                    name: string;
                    color: string;
                }[];
            };
            operator: string;
            value: string;
        }[];
        keyword: string;
        keywordType: number;
    };
}
/**  修改过滤器参数 End */

/** 过滤器列表 START */
interface ResponseFilterListData {
    code: number;
    message: string;
    visible: boolean;
    data: {
        id: number;
        name: string;
        status: number;
        templateType: number;
        judgeStrategy: number;
        customJudgeLogic: string;
        conditionList: {
            field: {
                id: string;
                dataSourceType: number;
                label: string;
                fieldName: string;
                fieldType: number;
                optionValueList?: {
                    id: number;
                    name: string;
                    color: string;
                }[];
            };
            operator: string;
            value: string;
        }[];
        keyword: string;
        keywordType: number;
    };
}
/** 过滤器列表 END */

function FilterCondition(props: any) {
    const [form] = Form.useForm();
    const [formFilterName] = Form.useForm();
    const [addFilterShow, setAddFilterShow] = useState(false);
    const [currentFilter, setCurrentFilter] = useState<any>(null);
    const [filterList, setFilterList] = useState<any>([]);
    const [memberData, setMemberData] = useState<any[]>([]);
    // window.sessionStorage.getItem('monitorTree')) 该操作是假数据，等有接口了直接删除掉就行
    // const [monitorTree,setMonitorTree] = useState<any[]>(JSON.parse(window.sessionStorage.getItem('monitorTree')) ||  []);
    const [sessionLabelData, setSessionLabelData] = useState<any[]>([]);
    const [commentTopic, setCommentTopic] = useState<any[]>([]);
    const [topicTags, setTopicTags] = useState<any[]>([]);
    const [keyEvents, setKeyEvents] = useState<any[]>([]);

    const tProps = {
        treeData: memberData,
        treeCheckable: true,
        placeholder: locales.pages.gong.common.pleaseSelectATemMember,
        showCheckedStrategy: SHOW_PARENT,
        treeNodeFilterProp: 'title',
        style: {
            width: '100%',
        },
    };

    const marks = {
        2: {
            style: {
                fontSize: '12px',
            },
            label: '0' + locales.pages.gong.common.filterScreen.Minutes,
        },
        50: {
            style: {
                fontSize: '12px',
            },
            label: '45' + locales.pages.gong.common.filterScreen.Minutes,
        },
        90: {
            style: {
                fontSize: '12px',
            },
            label: '90' + locales.pages.gong.common.filterScreen.Minutes,
        },
    };
    // const monitorOnChange = (val) =>{
        
    // };
    const handlerFilterChange = (value) => {
        setCurrentFilter(value);
        const record = filterList.find(item => item.id === value);

        const getItemValue = (types: string[] | string, callback?) => {
            if(isArray(types)) {
                const condition = record?.conditionList?.find((item) => types.includes(item.field.name));
                if (condition) {
                    if (callback) {
                        return fire(callback, condition);
                    }
                    return condition.value;
                }
            } else {
                return Reflect.get(record, types as string);
            }
        };

        const call_id = getItemValue(CALL_ID);
        const smart_tags = getItemValue(SMART_TAGS);
        const topics = getItemValue(TOPICS);
        const topic_tags = getItemValue(TOPIC_TAGS);
        const key_events = getItemValue(KEY_EVENTS);
        const channel = getItemValue(CHANNEL);
        const agent =  getItemValue(AGENT);
        const customer_nick_name = getItemValue(CUSTOMER_NICK_NAME);
        const call_time = getItemValue(CALL_TIME);
        const keyword = getItemValue('keyword');
        const keywordType = getItemValue('keywordType');
        const call_duration = getItemValue(CALL_DURATION, (condition) => {
            if (condition?.operator === 'between') {
                return condition.value;
            }
            if (condition?.operator === 'greater_than_eq') {
                return [condition.value];
            }
            if (condition?.operator === 'less_than_eq') {
                return [undefined, condition.value];
            }
            return [undefined, undefined];
        });

        const formValues = {
            agent,
            call_id,
            call_duration,
            call_time: call_time && [call_time[0], call_time[1]],
            customer_nick_name,
            keyword,
            keywordType,
            smart_tags,
            topics,
            topic_tags,
            key_events,
            channel,
        };

        form.resetFields();
        form.setFieldsValue(formValues);
        props.onSearch?.(formValues, value);
    };
    // 搜索
    const handlerSearch = (values, type?) => {
        // props.handlerInputSearch && props.handlerInputSearch(params);
        props.onSearch?.(values, currentFilter);
    };
    // 新增过滤器
    const handlerAddFilter = () => {
        form.validateFields().then((values) => {
            let data = [values];
            let isValue = data.some(
                (item) => 
                    item.agent ||
                    item.call_id ||
                    item.call_duration ||
                    item.customer_nick_name ||
                    item.keyword ||
                    item.call_time?.every(item => isDefined(item)) || 
                    item.key_events?.length > 0 ||
                    item.smart_tags?.length > 0 ||
                    item.topic_tags?.length > 0 ||
                    item.topics?.length > 0
            );
            if (isValue) {
                setAddFilterShow(true);
                formFilterName.resetFields();
            } else {
                Udesk.ui.notify.error(
                    locales.pages.gong.common.filterScreen.theFilterConditionIsEmptyNewFilterIsNotSupported
                );
            }
        });
    };
    // 新增过滤器保存
    const handlerOkFilter = () => {
        form.validateFields().then((values) => {
            formFilterName.validateFields().then((records) => {
                const params: RequestAddFilterParam = {
                    name: records.name,
                    status: 1,
                    templateType: 1,
                    judgeStrategy: 1,
                    keyword: values.keyword,
                    keywordType: values.keywordType,
                    conditionList: props.formatConditions(values, props.defaultFilter),
                    customJudgeLogic: '',
                    channelType: values.channelType,
                };
                let url = Udesk.business.apiPath.concatApiPath(`review/search-template`, props.sdkOptions);
                Udesk.ajax.post(url, params).then(
                    (resp: ResponseAddFilterData) => {
                        Udesk.ui.notify.success(locales.pages.gong.common.filterScreen.filterAddedSuccessfully);
                        // form.resetFields();
                        setAddFilterShow(false);
                        setCurrentFilter(resp.data.id);
                        getFilterList();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg || locales.pages.gong.common.filterScreen.failedToAddFilter
                        );
                    }
                );
            });
        });
    };
    const handlerCancelFilter = () => {
        setAddFilterShow(false);
    };
    // 删除过滤器
    const handlerDelete = () => {
        let url = Udesk.business.apiPath.concatApiPath(`review/search-template/${currentFilter}`, props.sdkOptions);
        Udesk.ajax.del(url, {}).then(
            (resp: ResponseDeleteFilterData) => {
                setCurrentFilter(null);
                form.resetFields();
                getFilterList();
                Udesk.ui.notify.success(locales.pages.gong.common.filterScreen.filterDeletedSuccessfully);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg || locales.pages.gong.common.filterScreen.filterDeletionFailed);
            }
        );
    };
    // 编辑过滤器
    const handlerUpdate = () => {
        form.validateFields()
            .then((values) => {
                formFilterName.validateFields().then((records) => {
                    let name = filterList.filter((item) => currentFilter === item.id)[0].name;
                    const params: ResquestEditFilterParam = {
                        name,
                        status: 1,
                        templateType: 1,
                        judgeStrategy: 1,
                        keyword: values.keyword,
                        customJudgeLogic: '',
                        keywordType: values.keywordType,
                        conditionList: props.formatConditions(values, props.defaultFilter),
                    };
                    let url = Udesk.business.apiPath.concatApiPath(
                        `review/search-template/${currentFilter}`,
                        props.sdkOptions
                    );
                    Udesk.ajax.put(url, params).then(
                        (resp: ResponseEditFilterData) => {
                            Udesk.ui.notify.success(locales.pages.gong.common.filterScreen.filterOverwriteSucceeded);
                            setAddFilterShow(false);
                            // setCurrentFilter(null);
                            // form.resetFields();
                            getFilterList();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg || locales.pages.gong.common.filterScreen.filterOverwriteFailed
                            );
                        }
                    );
                });
            })
            .catch((err) => {});
    };
    // 过滤器列表
    const getFilterList = () => {
        let url = Udesk.business.apiPath.concatApiPath(`review/search-template`, props.sdkOptions);
        Udesk.ajax.get(url, {}).then(
            (resp: ResponseFilterListData) => {
                setFilterList(resp.data);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    // 员工数据列表
    const getUserDataList = () => {
        const params = {
            pageSize: 1000,
        };
        getUsers({ params }).then((res) => {
            let memberData: any = res.data?.map((item) => {
                return {
                    ...item,
                    value: item.agentId,
                    title: item.realname,
                };
            });
            setMemberData(memberData ?? []);
        });
    };
    useEffect(() => {
        getFilterList();
        // 新员工数据获取
        getUserDataList();
        if (props.stateData) {
            form.setFieldsValue(props.stateData?.filterFormValues);
            setCurrentFilter(props.stateData?.currentFilterId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const defaultFilter = props.defaultFilter;

        if (defaultFilter) {
            const getOptions = ([name]: string[]) => {
                return defaultFilter.find(item => item.name === name)?.optionValueList ?? [];
            };
            setSessionLabelData(getOptions(SMART_TAGS));
            setCommentTopic(getOptions(TOPICS));
            setTopicTags(getOptions(TOPIC_TAGS));
            setKeyEvents(getOptions(KEY_EVENTS));
        }

        // 旧员工数据获取
        // let memberData = props.defaultFilter.filter((item) => item.name === 'agent')[0].optionValueList;
        // let newMemberData = JSON.stringify(memberData).replace(/id/g, 'value');
        // setMemberData(JSON.parse(newMemberData.replace(/name/g, 'title')));
        // let channelList = props.defaultFilter?.filter((item) => item.name === 'channel')[0]?.optionValueList ?? [];
        // setChannelList(channelList);

    }, [props.defaultFilter]);

    return (
        <div className='common-call-list-filter-screen-container'>
            <Form
                form={form}
                layout='vertical'
                onFinish={handlerSearch}
                initialValues={{ keywordType: -1, channelType: 0 }}
                style={{ width: '100%' }}
            >
                <Row className='common-call-list-filter-select'>
                    <Col span={24} className='select-filter-text'>
                        {locales.pages.gong.common.existingFilter}
                    </Col>
                    <Col span={20}>
                        <Select
                            showSearch
                            allowClear
                            placeholder={locales.pages.gong.common.pleaseSelect}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            notFoundContent={null}
                            onChange={handlerFilterChange}
                            value={currentFilter}
                            filterOption={(input, option) => {
                                return (
                                    option &&
                                    option.children &&
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                        >
                            {filterList.map((item) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                    <Col span={4}>
                        {currentFilter ? (
                            <Popconfirm
                                title={locales.pages.gong.common.areYouSureToDeleteTheCurrentFilter}
                                okText={locales.labels.confirm}
                                cancelText={locales.labels.cancel}
                                onConfirm={handlerDelete}
                            >
                                <Button type='link' danger>
                                    {locales.labels.delete}
                                </Button>
                            </Popconfirm>
                        ) : null}
                    </Col>
                </Row>
                <div className='filter-condition-content'>
                    <Icon style={{ fontSize: 16 }} type='ic-screen-list-hangshaixuan'></Icon>
                    <p>{locales.pages.gong.common.filterCondition}</p>
                    <Button
                        type='link'
                        onClick={() => {
                            setCurrentFilter(null);
                            form.resetFields();
                        }}
                    >
                        {locales.labels.clear}
                    </Button>
                </div>
                <div style={{ overflowY: 'auto' }}>
                    <div className='filter-scroll'>
                        <Form.Item name='call_time' label={locales.pages.gong.common.sessionDate}>
                            <UdeskDatePicker style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label={/* 会话渠道 */locales.pages.gong.common.filterScreen.conversationChannel} name='channelType'>
                            <EnumSelect 
                                allowClear
                                placeholder={/* 请选择会话渠道 */locales.pages.gong.common.filterScreen.pleaseSelectASessionChannel} 
                                enumKey='callAnalysisChannelTypes' 
                            />
                        </Form.Item>
                        <Form.Item name='agent' label={locales.pages.gong.common.staff}>
                            <TreeSelect {...tProps} />
                        </Form.Item>
                        {/* <Form.Item name='point_id' label='监督项'>
                            <TreeSelect                            
                                treeData={monitorTree}
                                showSearch
                                className='scroll-tree-select'
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder='请输入监督项'
                                allowClear                                
                                onChange={monitorOnChange}
                                treeNodeFilterProp='title'
                                treeCheckable={true}
                                filterTreeNode={(input,node) =>{
                                    if(typeof node.title === 'string'){
                                        if(node.title.indexOf(input) !== -1){
                                            return true;
                                        }else{
                                            return false;
                                        }
                                    }
                                }}
                                showCheckedStrategy={SHOW_ALL}
                            ></TreeSelect>
                        </Form.Item>  */}
                        <Form.Item name='customer_nick_name' label={locales.pages.gong.common.customer}>
                            <Input placeholder={locales.pages.gong.common.pleaseEnterCustomerName}></Input>
                        </Form.Item>                        
                        <Form.Item label={locales.pages.gong.common.keyWord}>
                            <InputGroup compact>
                                <Form.Item noStyle name='keywordType'>
                                    <Select
                                        style={{ width: '25%' }}
                                        options={Udesk.enums.roles.reduce(
                                            (prev, cur) => {
                                                prev.push({ label: cur.name, value: cur.id });
                                                return prev;
                                            },
                                            [{ label: locales.pages.gong.common.filterScreen.whole, value: -1 }]
                                        )}
                                    ></Select>
                                </Form.Item>
                                <Form.Item noStyle name='keyword'>
                                    <Input
                                        style={{ width: '75%' }}
                                        placeholder={locales.pages.gong.common.pleaseEnterSessionKeywords}
                                    />
                                </Form.Item>
                            </InputGroup>
                        </Form.Item>
                        <Form.Item label={/* 通话记录ID */Locales['current'].pages.gong.common.filterScreen.callRecordID}>
                            <Form.Item name='call_id'>
                                <Input placeholder={/* 请填写通话记录ID */Locales['current'].pages.gong.common.filterScreen.pleaseFillInTheCallRecordID} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item name='studyStatue' label={/* 学习状态 */locales.pages.gong.common.filterScreen.learningStatus}>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={/* 请选择学习状态 */locales.pages.gong.common.filterScreen.pleaseSelectLearningStatus}
                                options={[
                                    {label: /* 全部 */locales.pages.gong.common.filterScreen.whole, value: 0},
                                    {label: /* 未学习 */locales.pages.gong.common.filterScreen.notLearned, value: -1},
                                    {label: /* 已学习 */locales.pages.gong.common.filterScreen.learned, value: 1},
                                ]}
                            >
                            </Select>
                        </Form.Item>
                        <Form.Item name='call_duration' label={locales.pages.gong.common.sessionDuration}>
                            <Slider2 range className='slider-filter-screen' marks={marks} min={0} max={90} />
                        </Form.Item>                                               
                        <Form.Item name='smart_tags' label={locales.pages.gong.common.sessionLabel}>
                            <Select
                                showArrow
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={locales.pages.gong.common.pleaseSelectASessionLabel}
                                filterOption={(input, option) => {
                                    return (
                                        option &&
                                        option.children &&
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                            >
                                {sessionLabelData.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name='topics' label={locales.pages.gong.common.commentTopic}>
                            <Select
                                showArrow
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={locales.pages.gong.common.pleaseSelectACommentTopic}
                                filterOption={(input, option) => {
                                    return (
                                        option &&
                                        option.children &&
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                            >
                                {commentTopic.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={locales.pages.gong.common.filterScreen.theme} name='topic_tags'>
                            <Select
                                showArrow
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={locales.pages.gong.common.filterScreen.pleaseSelectATheme}
                                filterOption={(input, option) => {
                                    return (
                                        option &&
                                        option.children &&
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                            >
                                {topicTags.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={locales.pages.gong.common.filterScreen.keyEvents} name='key_events'>
                            <Select
                                showArrow
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={locales.pages.gong.common.filterScreen.pleaseSelectAKeyEvent}
                                filterOption={(input, option) => {
                                    return (
                                        option &&
                                        option.children &&
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                            >
                                {keyEvents.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <Form.Item style={{ paddingTop: '8px', marginBottom: '8px', marginRight: '16px' }}>
                    <Button block htmlType='submit'>
                        {locales.labels.search}
                    </Button>
                </Form.Item>
                <Space direction='vertical' style={{ width: '100%', paddingRight: '16px' }}>
                    {currentFilter ? (
                        <Popconfirm
                            title={locales.pages.gong.common.areYouSureYouWantToOverwriteTheCurrentFilter}
                            okText={locales.labels.confirm}
                            cancelText={locales.labels.cancel}
                            onConfirm={handlerUpdate}
                        >
                            <Button block danger>
                                {locales.pages.gong.common.overlayFilter}
                            </Button>
                        </Popconfirm>
                    ) : null}
                    <Button block type='primary' onClick={handlerAddFilter}>
                        {locales.pages.gong.common.NewFilter}
                    </Button>
                </Space>
            </Form>
            <Modal
                title={locales.pages.gong.common.newFilter}
                visible={addFilterShow}
                onOk={handlerOkFilter}
                onCancel={handlerCancelFilter}
            >
                <Form form={formFilterName}>
                    <Form.Item
                        name='name'
                        label={locales.pages.gong.common.filterName}
                        rules={[{ required: true, message: locales.pages.gong.common.pleaseEnter }]}
                    >
                        <Input placeholder={locales.pages.gong.common.pleaseEnter}></Input>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

class Component extends React.Component {
    render() {
        return <FilterCondition {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

const Slider2 = React.memo<any>((props) => {
    const {value = [], onChange} =props;
    const [unit, setUnit] = useState<number>(0);

    const onChangeHandle = useCallback((index: number, newValue, unit) => {
        const list = [...value];
        list[index] = isDefined(newValue, () => unit === 0 ? newValue * 60 : newValue, () => undefined);
        onChange(list);
    }, [
        onChange, 
        value,
    ]);

    const format = useCallback((value, unit) => {
        if (unit === 0 && isDefined(value)) {
            return value / 60;
        }
        return value;
    }, []);

    return (
        <div style={{position: 'relative'}}>
            <div style={{
                position: 'absolute',
                right: 0,
                top: -35,
            }}>
                <Radio.Group value={unit} size="small" onChange={(e) => setUnit(+e.target.value)}>
                    <Radio.Button value={0}>{/* 分钟 */}{Locales['current'].pages.gong.common.filterScreen.minute}</Radio.Button>
                    <Radio.Button value={1}>{/* 秒 */}{Locales['current'].pages.gong.common.filterScreen.second}</Radio.Button>
                </Radio.Group>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <InputNumber 
                    style={{
                        flex: 1
                    }}
                    value={format(value?.[0], unit)} 
                    min={0} max={format(value?.[1], unit) || 9999999}
                    onChange={(value) => onChangeHandle(0, value, unit)} 
                />
                <span 
                    style={{
                        flex: '0 0 32px', 
                        textAlign: 'center',
                        lineHeight: '30px',
                        color: '#ccc',
                    }}>—</span>
                <InputNumber 
                    style={{
                        flex: 1
                    }}
                    value={format(value?.[1], unit)} 
                    min={format(value?.[0], unit) || 0} max={9999999}
                    onChange={(value) => onChangeHandle(1, value, unit)} 
                />
            </div>
        </div>
    );
});
