import React, { useState, useMemo, useCallback, useImperativeHandle } from 'react';
import Udesk from 'Udesk';
import UdeskList from 'Component/common/udesk-list';
import ConfigDrawer from './configDrawer';
import ExportButton from 'Component/common/export-button';
import RichText from 'Component/pages/components/rich-text-component';
import TagsEdit from '../../../components/pageRightTable/tagsEdit';
import CallDetailModal from '../../../components/callDetailModal';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { Select, Input, Modal, Form, message, Icon, Space } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { fire, getId } from 'src/util/core';
import { remove } from 'lodash/array';
import '../index.scss';
import UdeskLocales from 'UdeskLocales';

const defaultPagination = {
    current: Udesk.config.paging.defaultPageNumber,
    pageSize: Udesk.config.paging.defaultPageSize,
    total: 0,
};

const Words = React.memo((props: { 
    value?: string[]; 
    onChange?: (data: string[]) => void;
    locales: any;
}) => {
    const { onChange, value: data = [''], locales } = props;
    const [keys, setKeys] = useState<any[]>(new Array<any>(data.length).fill(null).map(() => getId()));

    const addItem = useCallback(() => {
        const temp = [
            ...data,
            '',
        ];
        setKeys(keys => [...keys, getId()]);
        onChange?.(temp);
    }, [
        data, 
        onChange
    ]);

    const delItem = useCallback((index: number) => {
        setKeys(keys => remove(keys, (value, i) => i !== index));
        onChange?.(remove(data, (value, i) => i !== index));
    }, [
        data, 
        onChange
    ]);

    const updateItem = useCallback((value, index) => {
        onChange?.(data.map((item, dataIndex) => {
            if (dataIndex === index) {
                return value;
            }
            return item;
        }));
    }, [
        data,
        onChange
    ]);

    return (
        <div className={data && data.length === 1 ? 'hidden-delete-button': ''}>
            <UdeskList addBtnText={/* 新增话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.newScript} addItem={addItem} deleteItem={delItem} addBtnDisabled={data.length > 9999}>
                {data.map((value, index) => (
                    <RichText
                        key={keys?.[index]}
                        isView={false}
                        className={value && value.length > 0 ? 'ok' : ''}                       
                        value={value}
                        onChange={(value) => updateItem(value, index)}/>
                ))}
            </UdeskList>
        </div>
    );
});


const EditModal = React.forwardRef((props: any, ref) => {
    const {locales, scenario, request, clusterId, groupDataSource, onSuccess} = props;
    const [form] = Form.useForm();
    const [title, setTitle] = useState('');
    const [visible, setVisible] = useState(false);
    const [callback, setCallback] = useState();
    const [isDisable, setIsDisable] = useState(false);

    const open = useCallback((item, callback) => {
        console.log('item::', item);
        if (item) {
            item.key = item.id;
            form.setFieldsValue(item);
        } else {
            form.setFieldsValue({
                key: undefined,
                subjectId: undefined,
                clusterId: clusterId,
                wordsList: undefined,
                wordsPointsList: undefined,
            });
        }
        setTitle(item ? /* 编辑话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.editingScript : /* 新建话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.newScript);
        setVisible(true);
        setIsDisable(!!item);
        setCallback(() => callback);
    }, [
        locales,
        form, 
        clusterId
    ]);

    const channelHandle = useCallback(() => setVisible(false), []);

    const submitHandle = useCallback(({key: id, customerWord, ...values}) => {
        const url = `/review/words/accept/sellingPoint/${id ? id : 'save'}`;
        const method = id ? 'put' : 'post';
        const params = {
            id,
            scenario,
            ...values
        };

        request(url, params, method).then(
            resp => {
                onSuccess(method === 'post' ? resp.data : params);
                channelHandle();
                fire(callback, params);
                message.success(/* 保存成功 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.savingSucceeded);
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [
        locales,
        scenario,
        channelHandle, 
        onSuccess,
        request,
        callback
    ]);

    useImperativeHandle(ref, () => ({
        open
    }));

    return (
        <Modal
            wrapClassName="words-mining-favorite-edit-dialog beautiful-scrollbar"
            title={title}
            width={980}
            visible={visible}
            destroyOnClose={true}
            onOk={form.submit}
            onCancel={channelHandle}
        >
            <Form 
                form={form} 
                onFinish={submitHandle}    
                labelCol={{span: 3}}
                wrapperCol={{span: 20}}
            >
                <Form.Item name='key' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='callId' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='clusterWordId' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='dataSource' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='inspectDataSource' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='keyword' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item 
                    name='clusterId'
                    label={/* 关键词 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.favorite.components.pageRightTable.index.keyWord}
                    rules={[
                        { required: true, message: /* 请选择关键词 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.favorite.components.pageRightTable.index.pleaseSelectAKeyword },
                    ]}>
                    <Select 
                        disabled={isDisable}
                        showSearch={true}
                        optionFilterProp="label"
                        options={
                            groupDataSource.map(item => ({
                                label: item.name,
                                value: item.key,
                            }))
                        }
                    />
                </Form.Item>
                <Form.Item
                    name='wordsList'
                    label={/* 话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.speechTechnique}
                    rules={[
                        { required: true, message: /* 请输入话术内容 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.pleaseEnterTheScriptContent },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value) {
                                    for(let item of value) {
                                        if (!item) {
                                            return Promise.reject(/* 请输入话术内容 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.pleaseEnterTheScriptContent);
                                        }
                                    }
                                }
                                return Promise.resolve();
                            },
                        })
                    ]}
                >
                    <Words locales={locales}/>
                </Form.Item>
                <Form.Item 
                    name='wordsPointsList'
                    label={/* 话术要点 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.favorite.components.pageRightTable.index.keyPointsOfScript}
                    rules={[
                        { required: true, message: /* 请输入话术要点 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.favorite.components.pageRightTable.index.pleaseInputTheKeyPointsOfTheScript },
                    ]}>
                    <TagsEdit isEdit={true}/>
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default React.memo((props: any) => {
    const { selectedRowKey: clusterId, scenario, groupDataSource, locales, SmartToolBarRef } = props;
    const [pagination, setPagination] = useState(defaultPagination);
    const [tableData, setTableData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [exportData, setExportData] = useState<any[]>([]);
    const request = useRequest();
    const modalRef = React.useRef<any>();
    const drawerRef = React.useRef<any>();
    const [selectedRow, setSelectedRow] = useState();
    const [visible, setVisible] = useState(false);

    const ajaxTableData=  useCallback((map) => {
        setLoading(true);
        return request(`/review/words/accept/sellingPoint`, map).then(
            (resp) => {
                const { pageNum, pageSize } = map;
                const pagination = {
                    total: resp.paging?.total,
                    current: resp.paging?.pageNum || pageNum,
                    pageSize: resp.paging?.pageSize || pageSize,
                };
                setTableData(resp.data ?? []);
                setPagination(pagination);
            }
        ).finally(() => {
            setLoading(false);
        });
    }, [
        request
    ]);

    const onRequest = useCallback((params) => {
        const {pagination}  = params;
        ajaxTableData({
            clusterId,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
        });
    }, [
        ajaxTableData, 
        clusterId
    ]);

    const openModel = useCallback(item => modalRef.current.open(item, () => {
        ajaxTableData({
            clusterId,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
        });
    }), [
        ajaxTableData, 
        clusterId, 
        pagination
    ]);

    const openDrawer = useCallback(item => drawerRef.current.open(item), []);

    useLiveEffect(() => {
        if (clusterId) {
            const params = {
                pageSize: pagination.pageSize,
                current: 1,
                total: 0,
            };
            setPagination(params);
            ajaxTableData({
                clusterId,
                pageNum: params.current,
                pageSize: params.pageSize,
            });
        }
    }, [
        clusterId
    ]);
    
    const saveSuccessHandle = () => {};

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 采纳话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.adoptScripts,
                key: 'wordsList',
                dataIndex: 'wordsList',
                render(name) {
                    return (
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            {
                                name?.map(item => <RichText isView={true} value={item}/>)
                            }
                        </Space>
                    );
                }
            },
            {
                title: /* 话术要点 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.favorite.components.pageRightTable.index.keyPointsOfScript,
                key: 'wordsPointsList',
                dataIndex: 'wordsPointsList',
                render(name) {
                    return <TagsEdit isEdit={false} value={name || []}/>;
                }
            },
            {
                title: /* 查看次数 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.numberOfViews,
                key: 'checkNum',
                dataIndex: 'checkNum'
            },
            {
                title: /* 有用 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.useful,
                key: 'likeNum',
                dataIndex: 'likeNum',
            },
            {
                title: /* 操作 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.operation,
                key: 'actions',
                renderType: 'actions',
                buttons: [
                    {
                        label: /* 查看详情 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.favorite.components.pageRightTable.index.viewDetails,
                        disabled: record => !record.callId, 
                        onClick (record) {
                            setVisible(true);
                            setSelectedRow(record);
                        },
                    },
                    {
                        label: /* 编辑 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.edit,
                        onClick: openModel,
                    },
                    {
                        label: /* 删除 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.delete,
                        danger: true,
                        onClick: (item) => {
                            request(`/review/words/accept/sellingPoint/${item.id}`, {}, 'del').then(() => {
                                message.success(/* 删除成功 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.deletionSucceeded);
                                let {pageSize, current, total} = pagination;
                                if (current > 1) {
                                    if ((total - 1) / pageSize < current) {
                                        current = current - 1;
                                    }
                                }
                                ajaxTableData({
                                    clusterId,
                                    pageNum: current,
                                    pageSize,
                                });
                            });
                        },
                    },
                ].filter(Boolean),
            },
        ];
        return {
            loading,
            columns,
            dataSource: tableData,
            pagination,
            rowSelectionEnhance: {
                onSelectedRowKeysChange: (keys: any[], items: any[]) => {
                    setExportData(items);
                },
            },
        };
    }, [
        locales,
        loading,
        clusterId,
        openModel,
        tableData, 
        pagination, 
        request,
        ajaxTableData
    ]);

    const exportResult = () => {
        return new Promise((resolve, reject) => {
            if (exportData.length === 0) {
                resolve([]);
                Udesk.ui.notify.error(/* 请选择采纳话术 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.favorite.components.pageRightTable.index.pleaseChooseToAdoptScript);
            } else {
                Modal.confirm({
                    title: Udesk.utils.string.format(
                        locales.labels.exportConfirmText.export,
                        exportData?.length || 0
                    ),
                    icon: <Icon type='ExclamationCircleOutlined' antdIcon={true} />,
                    maskClosable: true,
                    okText: locales.labels.export,
                    onOk() {
                        request(`review/words/accept/export/sellingPoint/${clusterId}?systemModule=${Udesk.enums.sysModuleList.sales.id}`, exportData.map((i) => i.id), 'post').then(
                            resp => {
                                resolve(exportData);
                                ExportButton.exportSuccessNotification(() => {
                                    window.open('/sales/admin/task-center/download-list');
                                });
                            }
                        );
                    },
                    onCancel() {
                        reject();
                    },
                });
            }
        });
    };

    return (
        <div className='words-mining-favorite-page-right'>
            <CallDetailModal
                selectedRow={selectedRow} 
                request={request} 
                visible={visible} 
                setVisible={setVisible}
            />
            <StandardizeTable.RequestTable
                autoRequestOnMount={false}
                onRequest={onRequest}
                table={tableProps}
                toolBarRightButtons={[
                    {
                        label: /* 话术推荐设置 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.scriptRecommendationSettings,
                        icon: <Icon type='SettingOutlined' antdIcon={true} />,
                        onClick: openDrawer,
                        mainBtn: true,
                    },
                    {
                        label: /* 新建话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.newScript,
                        icon: <Icon type='PlusOutlined' antdIcon={true} />,
                        onClick: () => openModel(undefined),
                    },
                ].filter(Boolean)}
                exportBtn={{
                    noSecondConfirmation: true,
                    onClick: exportResult,
                    confirm: {
                        title: /* 是否导出当前分组下所有的数据记录？ */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.doYouWantToExportAllDataRecordsUnderTheCurrentGroup,
                    },
                }}
            />
            <ConfigDrawer 
                ref={drawerRef}
                locales={locales} 
                request={request} 
                scenario={scenario}
                selectedRowKey={clusterId}
                groupDataSource={groupDataSource} 
                SmartToolBarRef={SmartToolBarRef}/>
            <EditModal 
                scenario={scenario}
                ref={modalRef}
                locales={locales} 
                request={request} 
                clusterId={clusterId} 
                groupDataSource={groupDataSource}
                onSuccess={saveSuccessHandle}/>
        </div>
    );
});
