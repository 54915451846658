import React from 'react';
import { InputNumber, Space } from 'udesk-ui';
import Locales from 'UdeskLocales';

const ExamTimesInput = (props) => {
    const locales = Locales['current'];
    const { value, onChange } = props;
    return (
        <Space>
            <InputNumber style={{ width: 80 }} min={1} value={value} onChange={onChange} />
            <span>{locales.pages.gong.coachTaskCenter.edit.times}</span>
        </Space>
    );
};

export default ExamTimesInput;
