import React from 'react';
import Udesk from 'Udesk';
import { Button } from 'udesk-ui';
import { getCurrentRouteName } from 'Udesk/system/subApp';
class RoleIndexComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {
        columns: [],
        pageNum: Udesk.config.paging.defaultPageNumber,
        pageSize: Udesk.config.paging.defaultPageSize,
        total: null,
    };
    privates = {
        roles: [],
        loading: true,
    };

    actions = {
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        createItem() {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName("roleNew"),
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        viewItem(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName("roleView"),
                pathParams: {
                    id: item.id
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        editItem(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName("roleEdit"),
                pathParams: {
                    id: item.id
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        deleteItem(item) {
            let {
                locales
            } = this;
            let onConfirm = () => {
                let {
                    sdkOptions
                } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(`roles/${item.id}`, sdkOptions);
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg || locales.business.notifyMessage.deleteFailed);
                    }
                );
            };

            Udesk.ui.alerts.confirm({
                content: locales.labels.deleteContent,
                onOk: onConfirm
            });
        },
    };

    //#region Life Cycle
    componentDidMount() {
        getColumns(this);
    }
    componentWillUnmount() {}
    //#endregion
}

function getColumns(that) {
    let {
        actions,
        locales
    } = that;
    let columns = [
        {
            dataIndex: "id",
            key: 'id',
            width: "30%",
            title: locales.fields.role.id
        },
        {
            dataIndex: "name",
            key: 'name',
            width: "40%",
            title: locales.fields.role.name
        },
        {
            dataIndex: "actions",
            key: 'actions',
            width: "30%",
            title: locales.fields.role.actions,
            render: (name, item, index) => {
                return (
                    <div className="qa-react-role-page-body-content-table-actions">
                        <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:role:view")}>
                            <Button size='small' type='link' title={locales.labels.view} onClick={actions.viewItem.params(item)}>
                                {locales.labels.view}
                            </Button>
                        </If>
                        <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:role:edit")}>
                            <Button size='small' type='link' title={locales.labels.edit} onClick={actions.editItem.params(item)}>
                                {locales.labels.edit}
                            </Button>
                        </If>
                        <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:role:delete")}>
                            <Button size='small' type='link' danger title={locales.labels.delete} onClick={actions.deleteItem.params(item)}>
                                {locales.labels.delete}
                            </Button>
                        </If>
                    </div>
                );
            }
        }
    ];
    that.setState({
        columns
    });
}

export default RoleIndexComponent;