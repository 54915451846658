import React from 'react';
// import Locales from 'UdeskLocales';
import styled from 'styled-components';
import { Button } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const TitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ScoringRulesTitle = React.memo((props: any) => {
    // const locales = Locales['current'];
    const { totalScoring = 0, onButtonClick } = props;
    return (
        <TitleWrap className="udesk-coach-web-scoring-rules-title">
            <div>{`${/* 得分规则（当前话术总得分为 */UdeskLocales['current'].components.coach.scoringRules.components.title.scoringRulestheTotalScoreOfTheCurrentScriptIs}${totalScoring}${/* 分 ） */UdeskLocales['current'].components.coach.scoringRules.components.title.points}`}</div>
            <Button size="small" type="link" onClick={onButtonClick}>{/* 编辑规则 */}{UdeskLocales['current'].components.coach.scoringRules.components.title.editRules}</Button>
        </TitleWrap>
    );
});
