import React from 'react';
import { Space, Icon, Button, Tooltip, Progress, Tag } from 'udesk-ui';
import './common.scss';
import { CheckCircleTwoTone } from '@ant-design/icons';
import Locales from 'UdeskLocales';

const CallListItem = (props) => {
    const {
        isHome,
        tag,
        chanelType,
        agentName,
        customerName,
        date,
        asrVoiceDuration = 0,
        callId,
        call_duration,
        total_time,
        start_time,
        agent,
        customer_nick_name,
        // smartTags,
        call_time,
        commentCount,
        keyEvents,
        superversion = 0,
        imCount = 0,
        studied,
    } = props;
    const locales = Locales['current'];

    const renderIcon = (type) => {
        // alert(type)
        switch (type) {
            case 2: // 企微通话
            case 0: // 电话
                return (
                    <>
                        <Icon
                            type={type === 0 ? 'tonghuaguanli' : 'a-maikefengshengyinluyinluzhishengyin1'}
                            style={{ fontSize: '30px', color: 'rgba(0, 0, 0, 0.1)' }}
                        ></Icon>
                        {isHome ? (
                            <span className='time-durtion'>
                                {(parseInt((asrVoiceDuration / 1000).toString() ?? 0) || 0) / 60 <= 1
                                    ? parseInt((asrVoiceDuration / 1000).toString() ?? 0) + 's'
                                    : ((parseInt((asrVoiceDuration / 1000).toString() ?? 0) || 0) / 60).toFixed(0) +
                                      'm'}
                            </span>
                        ) : (
                            <span className='time-durtion'>
                                {call_duration
                                    ? parseInt(call_duration ?? 0) / 60 <= 1
                                        ? parseInt(call_duration ?? 0) + 's'
                                        : ((parseInt(call_duration ?? 0) || 0) / 60).toFixed(0) + 'm'
                                    : (parseInt(total_time ?? 0) || 0) / 60 <= 1
                                        ? (parseInt(total_time ?? 0)).toFixed(0) + 's'
                                        : ((parseInt(total_time ?? 0) || 0) / 60).toFixed(0) + 'm'}
                            </span>
                        )}
                    </>
                );
            case 1:
            // 企业
                return (
                    <>
                        <Icon type='xingzhuang' style={{ fontSize: '30px', color: 'rgba(0, 0, 0, 0.1)' }}></Icon>
                        <span className='time-durtion'>{imCount}{/* 条 */}{locales.pages.gong.homePage.common.callListItem.strip}</span>
                    </>
                );

            default:
                return null;
        }
    };
    const smartTagsMoreItem = (result, limit) => {
        return (
            <React.Fragment>
                {result?.length && result?.length > limit ? (
                    <Tooltip
                        placement='bottom'
                        color='#fff'
                        title={
                            <div className='more-list-btn' style={{ marginRight: '-8px', marginBottom: '-4px' }}>
                                {!!result?.length &&
                                    result.slice(limit).map((record) => {
                                        return (
                                            <Tag
                                                color={record.categoryColor + '1a'}
                                                key={record.id}
                                                style={{
                                                    background: record.categoryColor + '1a',
                                                    color: record.categoryColor,
                                                    marginBottom: 4,
                                                }}
                                            >
                                                {record.tagName} <span style={{ margin: '5px' }}>|</span>{' '}
                                                {record.sentencesIndices?.length ?? 0}
                                            </Tag>
                                        );
                                    })}
                            </div>
                        }
                    >
                        <Tag>{`...   +${result?.length - limit}`}</Tag>
                    </Tooltip>
                ) : null}
            </React.Fragment>
        );
    };
    const handlerCallDetail = () => {
        props.handlerCallDetail && props.handlerCallDetail(callId);
    };

    const displayAgentName = isHome ? agentName || '--' : agent || '--';
    const displayCustomerName = isHome
        ? customerName
            ? ' | ' + customerName
            : ''
        : customer_nick_name
            ? ' | ' + customer_nick_name
            : '';
    return (
        <div onClick={handlerCallDetail} className='call-list-item-common-wanan'>
            <div className='item item-flex item-base-info'>
                <div className='all-cal-item-images'>
                    {renderIcon(chanelType)}
                    {Boolean(studied) && (
                        <Button
                            size='small'
                            type='text'
                            style={{ position: 'absolute', top: 0, right: 0 }}
                            icon={<CheckCircleTwoTone twoToneColor='#52c41a' />}
                            tooltip={/* 已学习 */locales.pages.gong.homePage.common.callListItem.learned}
                        />
                    )}
                </div>
                <div className='call-title' style={{ flex: 1, overflow: 'hidden' }}>
                    <div style={{ display: 'flex', overflow: 'hidden' }}>
                        <div
                            title={displayAgentName}
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: displayCustomerName ? '50%' : '100%',
                            }}
                        >
                            {displayAgentName}
                        </div>
                        <div
                            title={displayCustomerName}
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: displayAgentName ? '50%' : '100%',
                            }}
                        >
                            {displayCustomerName}
                        </div>
                    </div>
                    <div className='time-wanna'>
                        <span className='call-time'>{isHome ? date : call_time || start_time}</span>
                        <span className='call-commont'>
                            {/* <img
                                src={require('../../../../../static/img/recommend-blue.svg').default}
                                width={16}
                                alt=''
                            /> */}
                            <Icon type='ic-ask-pinglun' style={{ color: '#1A6EFF', fontSize: '16px' }} />
                            <span>{commentCount}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className='item' style={{ flex: 1, overflowX: 'hidden' }}>
                <div className='title-wanna'>
                    <span>{/* 关键事件 */}{locales.pages.gong.homePage.common.callListItem.keyEvents}</span>
                    <span>|</span>
                    <span>{keyEvents?.length ?? 0}</span>
                </div>
                <div className='content-desc'>
                    {isHome ? (
                        <Space wrap>
                            {!!tag?.length &&
                                tag.map((record) => {
                                    return (
                                        <Button
                                            key={record.id}
                                            style={{
                                                background: record.categoryColor + '1a',
                                                color: record.categoryColor,
                                                border: `1px solid ${record.categoryColor}`,
                                            }}
                                            className='call-icon'
                                            size='small'
                                        >
                                            {record.tagName}
                                        </Button>
                                    );
                                })}
                        </Space>
                    ) : (
                        <div>
                            {!!keyEvents?.length &&
                                keyEvents.slice(0, 3).map((record) => {
                                    return (
                                        <Tag
                                            color={record.categoryColor + '1a'}
                                            key={record.id}
                                            style={{
                                                color: record.categoryColor,
                                                marginBottom: 4,
                                                display: 'inline-flex',
                                                overflow: 'hidden',
                                                maxWidth: '100%',
                                            }}
                                        >
                                            <div
                                                title={record.tagName}
                                                style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            >
                                                {record.tagName}
                                            </div>
                                            <span style={{ margin: '0 5px' }}>|</span>
                                            <div>{record.sentencesIndices?.length ?? 0}</div>
                                        </Tag>
                                    );
                                })}
                            {smartTagsMoreItem(keyEvents, 3)}
                        </div>
                    )}
                </div>
            </div>
            <div className='item'>
                <div className='title-wanna' style={{ textAlign: 'center' }}>
                    <span>{/* 监督项总执行率 */}{locales.pages.gong.homePage.common.callListItem.totalImplementationRateOfSupervisionItems}</span>
                </div>
                <div className='content-desc content-desc-flex'>
                    <Progress width={52} type='circle' percent={superversion} />
                </div>
            </div>
        </div>
    );
};

export default CallListItem;
