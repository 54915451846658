import React, { useState, useEffect } from 'react';
import Locales from 'UdeskLocales';

import { Button, Dropdown, Icon } from 'udesk-ui';

const locales = Locales['current'];

const DownloadMenu = ({ exportTxt }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}>
            {exportTxt && (
                <Button type='text' onClick={exportTxt}>
                    {locales.labels.exportTxt}
                </Button>
            )}
        </div>
    );
};

const DownloadAstText = ({ downUrl: downUrlProps }) => {
    const [downUrl, setDownUrl] = useState('');
    const exportTxt = () => {
        setDownUrl(downUrlProps);
    };
    useEffect(() => {
        setTimeout(() => {
            setDownUrl('');
        }, 200);
    }, [downUrl]);
    return (
        <React.Fragment>
            <Dropdown overlay={<DownloadMenu exportTxt={exportTxt} />} placement='bottomCenter'>
                <Button type='text' style={{paddingRight: 0}} icon={<Icon type='xiazai' />}></Button>
                {/* <i
                    className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazai'
                    style={{ alignSelf: 'center' }}
                ></i> */}
            </Dropdown>
            {downUrl && <iframe src={downUrl} style={{ display: 'none' }} title='url'></iframe>}
        </React.Fragment>
    );
};

export default React.memo(DownloadAstText);
