import Locales from '../locales/index';

let filterTypes = [
    { id: "1", key: "call", name: () => Locales.current.enums.filterTypes.call },
    { id: "2", key: "dialogue", name: () => Locales.current.enums.filterTypes.dialogue },
    { id: "5", key: "wechat", name: () => Locales.current.enums.filterTypes.wechat },
    { id: "6", key: "wechatAll", name: () => Locales.current.enums.filterTypes.wechatAll },
    { id: "10", key: "ticket", name: () => Locales.current.enums.filterTypes.ticket },
    { id: "8", key: "taobao", name: () => Locales.current.enums.filterTypes.taobao },
    { id: "12", key: "wechatRadio", name: () => Locales.current.enums.filterTypes.wechatRadio },
];

export default {
    filterTypes
};
