import React, { useState, useCallback, useRef, useEffect } from 'react';
import Udesk from 'Udesk';
import './style.scss';
import SemanticTagsDetailRoute from './route';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Input, Select, Button, InputNumber, PageHeader, Icon, Space, Popover } from 'udesk-ui';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Card from './Cards';
import update from 'immutability-helper';
import _isEmpty from 'lodash-es/isEmpty';
import _filter from 'lodash-es/filter';
import _find from 'lodash-es/find';
import _isEqual from 'lodash-es/isEqual';
import _findIndex from 'lodash-es/findIndex';
// const ItemLayout = {
//     labelCol: {
//         fixedSpan: 4
//     },
//     wrapperCol: {
//         span: 10
//     }
// };
const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 9 },
};
const DraggableComponent = (props) => {
    const [, drag] = useDrag({
        item: { type: 'cards', name: `${props.name}`, color: `${props.color}`, ...props, },
        end: (item, monitor) => {
            if (!monitor.didDrop()) {
                delete item.index;
            }
        }
    });
    return <div ref={drag} style={{ display: 'inline-block' }}><Button style={{ border: `1px solid ${props.color}`, color: `${props.color}`, marginRight: '4px', marginBottom: '3px' }}>{props.name}</Button></div>;
};
const MyDropTarget = (props) => {
    let [list, setList] = useState(props.customRules);
    let ref = useRef(null);

    let [count, setCount] = useState(0);
    const [, drop] = useDrop({
        accept: 'cards',
        drop: (item, monitor) => {
            if (item.name) {
                delete item.index;
                setCount(count + 1);
                let noneCardIndex = _findIndex(list, item => item.id === 'none');
                list.splice(noneCardIndex, 0, { ...item, id: `${item.id}_${count}` });
                let lists = list.filter(item => item.id !== 'none');
                lists = [
                    ...lists,
                    {
                        id: 'none'
                    }
                ];
                setList(lists);
            }
        },
        collect: monitor => {
            if (!monitor.isOver() && !monitor.didDrop()) {
                let lists = list.filter(item => item.id !== 'none');
                lists = [
                    ...lists,
                    {
                        id: 'none'
                    }
                ];
                setList(lists.map(item => {
                    delete item.index;
                    return item;
                }));
            }
        }
    });
    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = list[dragIndex];
            if(!dragCard) return;
            let noneCard = _findIndex(list, item => item.id === 'none');
            // if ((!dragIndex && !(dragIndex === 0)) || (!dragIndex && hoverIndex === 0)) {
            if ((!dragIndex && !(dragIndex === 0)) || (!dragIndex && hoverIndex === 0)) {
                setList(
                    update(list, {
                        $splice: [
                            [noneCard, 1],
                            [hoverIndex, 0, { id: 'none' }],
                        ],
                    }),
                );
            } else {
                if (dragIndex || dragIndex === 0) {
                    setList(
                        update(list, {
                            $splice: [
                                [dragIndex, 1],
                                [hoverIndex, 0, dragCard],
                            ],
                        }),
                    );
                }
            }
        },
        [list],
    );
    const valueChange = useCallback((value, num, e) => {
        setList(list.map(item => {
            if (item.id === value) {
                if (e) {
                    item.defaultValue[num] = parseInt(e, 10);
                } else {
                    item.defaultValue = item.defaultValue.filter((item, index) => index !== num);
                }

            }
            return item;
        }));
    });
    const delCard = useCallback((value) => {
        setList(_filter(list, current => current.id !== value));
    }, [list]);
    useEffect(() => {
        setList(props.customRules);
    }, [props.customRules]);
    const renderCard = (card, index) => {
        return (
            <Card
                key={card.id}
                index={index}
                id={card.id}
                text={card.name}
                moveCard={moveCard}
                color={card.color}
                fieldType={card.fieldType}
                subType={card.subtype}
                valueChange={valueChange}
                defaultValue={card.defaultValue}
                delValue={delCard}
            />
        );
    };
    let { ruleListObj } = props;
    ruleListObj && ruleListObj(list);
    drop(ref);
    return (
        <div ref={ref} className='my-drop-target' style={{ marginTop: '10px', 'width': '100%', maxWidth: '800px', height: 'calc(100vh - 166px - 15px - 74px)', border: '1px solid #e8e8e8', padding: '8px', overflowY: 'auto' }}>
            {

                list.map((item, index) => {
                    return (
                        renderCard(item, index)
                    );
                })
            }
        </div>
    );
};

const TestBtn = props => {
    const { locales, content, visible, getPopupContainer } = props;
    if(!visible) return null;

    return (
        <Popover getPopupContainer={getPopupContainer} placement="leftTop" content={content} trigger="click">
            <Button type='link' size='small'>{locales.components.pages.semanticTags.detail.test}</Button>
        </Popover>
    );
};

const TestContent = props => {
    const { locales, editEnable, type, actions, testListFields, hitTest } = props;
    return (
        <div className='semantic-tags-detail-page-body-tabs-test'>
            <div className='semantic-tags-detail-page-body-tabs-test-hit'>
                <h2>{locales.components.pages.semanticTags.detail.testOk}</h2>
                <div className='semantic-tags-detail-page-body-tabs-test-hit-limit'>
                    <Input.TextArea onChange={actions.testFieldsChange} style={{ width: 400 }} autoSize={{ minRows: 2, maxRows: 10 }} />
                    <div className='semantic-tags-detail-page-body-tabs-test-hit-limit-button'>
                        <If condition={(editEnable && type === 3) || type !== 3}>
                            <Button onClick={actions.testSaveUpdate}>{locales.components.pages.semanticTags.detail.test}</Button>
                        </If>
                        <div style={{ marginTop: '10px' }}>
                            {
                                !_isEmpty(testListFields) && !_isEmpty(hitTest) && testListFields.map(item => {
                                    return (
                                        <div className='semantic-tags-detail-page-body-tabs-test-hit-limit-button-info'>
                                            <div>
                                                {
                                                    !_isEmpty(hitTest) && _find(hitTest, current => current.matchType === item.value) && _find(hitTest, current => current.matchType === item.value).isHit === 1 ? (
                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yixuanzhong" style={{ marginRight: '3px' }}></i>
                                                    ) : (<i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai" style={{ marginRight: '3px' }}></i>)
                                                }
                                                {
                                                    !_isEmpty(hitTest) && _find(hitTest, current => current.matchType === item.value) && _find(hitTest, current => current.matchType === item.value).isHit === 1 ? (<span>{item.text}</span>) : item.name
                                                }
                                            </div>
                                            <div>
                                                {
                                                    !_isEmpty(hitTest) && _find(hitTest, current => current.matchType === item.value) && _find(hitTest, current => current.matchType === item.value).isHit === 1 &&
                                                    (_isEqual(item.value, 3)
                                                        ? _find(hitTest, current => current.matchType === item.value).recallWords
                                                        : _isEqual(item.value, 2)
                                                            ? _find(hitTest, current => current.matchType === item.value).similarSentences
                                                            && _find(hitTest, current => current.matchType === item.value).similarSentences.map((item, index) => {
                                                                return <div style={{ marginLeft: '10px' }}>{index + 1}.{item}</div>;
                                                            }) : _isEqual(item.value, 1)
                                                                ? _find(hitTest, current => current.matchType === item.value).similarSentences
                                                                && _find(hitTest, current => current.matchType === item.value).similarSentences.map((item, index) => {
                                                                    return <div style={{ marginLeft: '10px' }}>{index + 1}.{item}</div>;
                                                                }) : _isEqual(item.value, 6)
                                                                    ? _find(hitTest, current => current.matchType === item.value).excludeKeyword
                                                                    : null)
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TipIcon = ({tooltip}) => {
    return (
        <Button
            size='small'
            icon={<Icon type='xiazaishibai' style={{color: '#a1bbf9'}} />}
            tooltip={tooltip}
            style={{lineHeight: '40px', height: 40}}
        ></Button>
    );
};

const Label = ({tooltip, title}) => {
    return (
        <div style={{marginRight: 10, display: 'flex', minWidth: 100}}>
            <h2>{title}</h2>
            {tooltip && <TipIcon tooltip={tooltip} />}
        </div>
    );
};

class SemanticTagsDetailTemplate extends SemanticTagsDetailRoute {
    render() {
        let {
            // id,
            activeFlags,
            effectiveness,
            ruleList,
            ruleFields,
            recallKeyWord,
            similaSentences,
            recallRegular,
            excludeRegular,
            highLevelConfig,
            title,
            DegreeData,
            ruleFieldList,
            // transList,
            // searchMaxScore,
            // searchMinScore,
            informationEntityshowlist,
            hitTest,
            testListFields,
            customRules,
            conceptWordList,
            loading,
            editEnable,
            type,
            paddingTop,
            excludeKeyword,
        } = this.privates;
        let {
            actions,
            locales,
            privates
        } = this;
        let { TabPane } = Tabs;
        let { getFieldDecorator } = this.props.form;
        let {
            _errFlag
        } = this.privates.computes;
        return (
            // <div className="semantic-tags-detail-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    onBack={actions.backToIndex}
                    title={privates.isEdit ? locales.components.pages.semanticTags.detail.edit : locales.components.pages.semanticTags.detail.new}
                />
                <div className='udesk-qa-web-page-body' onScroll={actions.scrollListenerHandler}>
                    <div className='udesk-qa-web-page-body-root semantic-tags-detail-page'>
                        <div className="semantic-tags-detail-page-body" id='scrollListenedDomInstance'>
                            <Form {...formItemLayout}>
                                <div style={{maxWidth: 1000}}>
                                    <Form.Item label={locales.components.pages.semanticTags.detail.name}>
                                        {
                                            getFieldDecorator(`title`, {
                                                initialValue: title,
                                                rules: [
                                                    { required: true, message: Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.semanticTags.detail.name), whitespace: true }
                                                ],
                                            })(<Input onChange={actions.changeTitles} />)
                                        }
                                    </Form.Item>
                                    <Form.Item hasFeedback validateStatus={_errFlag ? null : "error"} label={locales.components.pages.semanticTags.detail.standardSentences} className="semantic-tags-detail-page-body-standard-sentences">
                                        {
                                            getFieldDecorator(`standardSentences`, {
                                                initialValue: (!_isEmpty(similaSentences) && _find(similaSentences, current => current.standardFlag === 1) && _find(similaSentences, current => current.standardFlag === 1).content) || '',
                                                rules: [
                                                    {
                                                        whitespace: true
                                                    }
                                                ]
                                            })(<Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} onChange={actions.changeStandardSentences.params(!_isEmpty(similaSentences) && _find(similaSentences, current => current.standardFlag === 1) && _find(similaSentences, current => current.standardFlag === 1).order)} />)
                                        }
                                    </Form.Item>
                                    <Form.Item label={locales.components.pages.semanticTags.detail.effectiveness}>
                                        {
                                            getFieldDecorator(`effectiveness`, {
                                                initialValue: effectiveness ? effectiveness : effectiveness === 0 ? 0 : undefined,
                                                rules: [
                                                    { required: true, message: Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.semanticTags.detail.effectiveness) }
                                                ],
                                            })(<Select placeholder={locales.fields.pleaseChoice} onChange={actions.effectivenessChange} style={{ width: '120px' }}>
                                                {
                                                    !_isEmpty(activeFlags) && activeFlags.map(item => {
                                                        return (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>);
                                                    })
                                                }
                                            </Select>)
                                        }
                                    </Form.Item>
                                </div>
                                <div className='semantic-tags-detail-page-body-tabs-new' ref={privates.tabsRef}>
                                    <Tabs
                                        onChange={actions.onTabsChange}
                                        tabBarExtraContent={
                                            <TestBtn
                                                visible={privates.isEdit}
                                                locales={locales}
                                                getPopupContainer={() => privates.tabsRef.current}
                                                content={
                                                    <TestContent
                                                        locales={locales}
                                                        editEnable={editEnable}
                                                        type={type}
                                                        actions={actions}
                                                        testListFields={testListFields}
                                                        hitTest={hitTest}
                                                    />
                                                }
                                            />}>
                                        <TabPane tab={locales.components.pages.semanticTags.detail.qualify} key="1">
                                            <div className='semantic-tags-detail-page-body-tabs-list'>
                                                <Label
                                                    title={locales.components.pages.semanticTags.detail.recallKeyWord}
                                                    tooltip={(
                                                        <div className='semantic-tags-detail-page-body-list-info-tips'>
                                                            <div>
                                                                <p>{locales.components.pages.semanticTags.detail.keyWordInfo1}</p>
                                                                <p>{locales.components.pages.semanticTags.detail.keyWordInfo2}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                <KeyWord {...{recallKeyWord, _errFlag, getFieldDecorator, locales, actions, formItemKey: 'recallKeyWord'}} />
                                            </div>
                                            <div className='semantic-tags-detail-page-body-tabs-list'>
                                                <Label title={locales.components.pages.semanticTags.detail.recallRegular} />
                                                <Regular {...{_errFlag, getFieldDecorator, recallRegular, locales, actions, formItemKey: 'recallRegular'}}/>
                                            </div>
                                            <div className='semantic-tags-detail-page-body-tabs-list'>
                                                <Label title={locales.components.pages.semanticTags.detail.excludeRegular} />
                                                <Regular {...{_errFlag, getFieldDecorator, recallRegular: excludeRegular, locales, actions, formItemKey: 'excludeRegular'}}/>
                                            </div>
                                            <div className='semantic-tags-detail-page-body-tabs-list'>
                                                <Label
                                                    title={locales.components.pages.semanticTags.detail.excludeKeywords}
                                                />
                                                <KeyWord {...{recallKeyWord: excludeKeyword, _errFlag, getFieldDecorator, locales, actions, formItemKey: 'excludeKeyword'}} />
                                            </div>
                                        </TabPane>
                                        <TabPane tab={locales.fix.grammar} key="2">
                                            <div className='semantic-tags-detail-page-body-list-rule'>
                                                <div id='positonTransLine' className='semantic-tags-detail-page-body-tabs-senior-title'>
                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                    <span>{locales.components.pages.semanticTags.detail.keyWordInfo4}</span>
                                                </div>
                                                <DndProvider backend={HTML5Backend}>
                                                    <div style={{display: 'flex', position: 'relative'}}>
                                                        <div className='semantic-tags-detail-page-body-list-rule-left'>
                                                            <div className='semantic-tags-detail-page-body-list-rule-field'>
                                                                <h2>{locales.components.pages.semanticTags.detail.keyWord}</h2>
                                                                <div className='semantic-tags-detail-page-body-list-rule-field-info-new'>
                                                                    <Form.Item wrapperCol={{span: 24}}>
                                                                        <Input style={{ width: '80%' }} onChange={actions.ruleFieldChange}></Input>
                                                                    </Form.Item>
                                                                    {
                                                                        !_isEmpty(ruleFieldList) && ruleFieldList.map(item => {
                                                                            return <DraggableComponent key={item.id} {...item} name={item.keyWords} />;
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='semantic-tags-detail-page-body-list-rule-field'>
                                                                <h2>{locales.components.pages.semanticTags.detail.entity}</h2>
                                                                <div className='semantic-tags-detail-page-body-list-rule-field-info-new'>
                                                                    {
                                                                        !_isEmpty(informationEntityshowlist) && informationEntityshowlist.map(item => {
                                                                            return <DraggableComponent key={item.id} {...item} name={item.label} />;
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='semantic-tags-detail-page-body-list-rule-field'>
                                                                <h2>{locales.components.pages.semanticTags.detail.ruleField}</h2>
                                                                <div className='semantic-tags-detail-page-body-list-rule-field-info-new'>

                                                                    {
                                                                        !_isEmpty(conceptWordList) && conceptWordList.map(item => {
                                                                            return <DraggableComponent key={item.id} {...item} name={item.label} />;
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                ruleList.map(item => {
                                                                    return (
                                                                        <div key={item.name} className='semantic-tags-detail-page-body-list-rule-field'>
                                                                            <h2>{item.name}</h2>
                                                                            <div className='semantic-tags-detail-page-body-list-rule-field-info-new'>
                                                                                {
                                                                                    _filter(ruleFields, current => current.fieldType === item.type).map(items => {
                                                                                        return <DraggableComponent key={items.id}  {...items} color={item.color} name={items.label} />;
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                        {/* <div className='semantic-tags-detail-page-body-list-rule-right' style={{ position: shouldFixed ? 'fixed' : 'relative', top: shouldFixed ? 126 : 0, right: shouldFixed ? 92 : 0 }}> */}
                                                        <div className='semantic-tags-detail-page-body-list-rule-right' style={{ paddingTop: paddingTop }}>
                                                            <h2>{locales.components.pages.semanticTags.detail.customRule}</h2>
                                                            <div>
                                                                <MyDropTarget ruleListObj={actions.ruleListObj} customRules={customRules} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </DndProvider>

                                            </div>
                                        </TabPane>
                                        <TabPane tab={locales.components.pages.semanticTags.detail.fanhua} key="3">
                                            <div className='semantic-tags-detail-page-body-tabs-list'>
                                                <Label
                                                    title={locales.components.pages.semanticTags.detail.similarSentences}
                                                />
                                                <div>
                                                    {
                                                        !_isEmpty(similaSentences) && _filter(similaSentences, current => current.standardFlag === 0).map(item => {
                                                            let borderStyle = !_isEmpty(DegreeData) && _find(DegreeData, current => current.standard_index === item.order || current.input_index === item.order) && '1px solid #f5222d';
                                                            return (
                                                                <div key={item.order} className='semantic-tags-detail-page-body-tabs-list-limit-generalization'>
                                                                    <div className='semantic-tags-detail-page-body-tabs-list-form'>
                                                                        <Space>
                                                                            <Form.Item >
                                                                                {
                                                                                    getFieldDecorator(`similaSentences-${item.order}-0`, {
                                                                                        initialValue: item.content || undefined,
                                                                                        rules: [
                                                                                            {
                                                                                                whitespace: true
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                    )(<Input style={{ width: '500px', border: `${borderStyle}` }} onChange={actions.similaSentencesChangeFieldTwo.params(item.order)} />)
                                                                                }
                                                                            </Form.Item>
                                                                            <Button icon={<Icon type='jianhao' style={{color: '#1b6dff'}}/>} size='small' shape='circle' onClick={actions.similaSentencesDel.params(item.order)}></Button>
                                                                        </Space>
                                                                    </div>
                                                                    <If condition={item.trainingSource === 1}>
                                                                        <div className="semantic-tags-detail-tabs-form-item-training-info">
                                                                            {`${locales.components.pages.semanticTags.detail.new}『${item.createUserUsername}』 ${item.updateTime}`}
                                                                        </div>
                                                                    </If>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div className='semantic-tags-detail-page-body-list-info'>
                                                        <Button icon={<Icon type='iconfont707' style={{color: '#1b6dff'}}/>} size='small' shape='circle' onClick={actions.similaSentencesAdd.params(0)}></Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='semantic-tags-detail-page-body-tabs-list'>
                                                <Label
                                                    title={locales.components.pages.semanticTags.detail.similaSentences}
                                                    tooltip={locales.components.pages.semanticTags.detail.keyWordInfo3}
                                                />
                                                <div>
                                                    {
                                                        !_isEmpty(similaSentences) && _filter(similaSentences, current => current.standardFlag === 2).map(item => {
                                                            return (
                                                                <div key={item.order} className='semantic-tags-detail-page-body-tabs-list-limit'>
                                                                    <Form.Item>
                                                                        {
                                                                            getFieldDecorator(`similaSentences-${item.order}-0`, {
                                                                                initialValue: item.content || undefined,
                                                                                rules: [
                                                                                    {

                                                                                        whitespace: true
                                                                                    }
                                                                                ]
                                                                            }
                                                                            )(<Input style={{ width: '500px' }} placeholder={locales.fields.pleaseChoice} onChange={actions.similaSentencesChangeField.params(item.order, 0)} />)
                                                                        }
                                                                    </Form.Item>
                                                                    <span className='semantic-tags-detail-page-body-tabs-list-simimlar'>{locales.components.pages.semanticTags.detail.simim}</span>
                                                                    <Form.Item>
                                                                        {
                                                                            getFieldDecorator(`similaSentences-${item.order}-1`, {
                                                                                initialValue: item.similarityDegree || 80,
                                                                                // rules: [{ required: true, message: ' ' }]
                                                                            }
                                                                            )(<InputNumber style={{ width: '60px' }} min={80} max={100} onChange={actions.similaSentencesChangeField.params(item.order, 1)} />)
                                                                        }
                                                                    </Form.Item>
                                                                    <span className='semantic-tags-detail-page-body-tabs-list-simimlar'>%</span>
                                                                    <Button icon={<Icon type='jianhao' style={{color: '#1b6dff'}}/>} size='small' shape='circle' onClick={actions.similaSentencesDel.params(item.order)}></Button>

                                                                    {/* <div className="semantic-tags-detail-tabs-form-item-button" onClick={actions.similaSentencesDel.params(item.order)}>
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i>
                                                                    </div> */}
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                    <div className='semantic-tags-detail-page-body-list-info'>
                                                        <Button icon={<Icon type='iconfont707' style={{color: '#1b6dff'}}/>} size='small' shape='circle' onClick={actions.similaSentencesAdd.params(2)}></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tab={locales.components.pages.semanticTags.detail.senior} key="6">
                                            <div className='semantic-tags-detail-page-body-tabs-senior'>
                                                <div className='semantic-tags-detail-page-body-tabs-senior-title'>
                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                    <span>{locales.components.pages.semanticTags.detail.keyWordInfo5}。</span>
                                                </div>
                                                <div className='semantic-tags-detail-page-body-tabs-senior-list'>
                                                    <h2>{locales.components.pages.semanticTags.detail.seniorList}</h2>
                                                    <div className='semantic-tags-detail-page-body-tabs-senior-list-info'>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <Form.Item>
                                                                {
                                                                    getFieldDecorator(`crossSentence-on`, {
                                                                        initialValue: highLevelConfig.crossSentence.on
                                                                    })(
                                                                        <Select style={{ width: '80px' }} onChange={actions.fieldTestingChange} placeholder={locales.labels.pleaseChoice}>
                                                                            <Select.Option value={1}>{locales.components.pages.semanticTags.detail.up}</Select.Option>
                                                                            <Select.Option value={0}>{locales.components.pages.semanticTags.detail.down}</Select.Option>
                                                                        </Select>
                                                                    )
                                                                }

                                                            </Form.Item>
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo6}</span>
                                                        </div>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo7}</span>
                                                            <Form.Item style={{ width: '65px', marginLeft: '5px' }}>
                                                                {
                                                                    getFieldDecorator(`crossSentence-crossLength`, {
                                                                        initialValue: highLevelConfig.crossSentence.crossLength || 1
                                                                    })(
                                                                        <InputNumber style={{ width: '60px' }} onChange={actions.crossSentenceChange.params('crossLength')} />
                                                                    )
                                                                }

                                                            </Form.Item>
                                                            <span>{locales.components.pages.semanticTags.detail.ju}</span>
                                                        </div>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo8}</span>
                                                            <Form.Item style={{ width: '65px', marginLeft: '5px' }}>
                                                                {
                                                                    getFieldDecorator(`crossSentence-differentRoleLength`, {
                                                                        initialValue: highLevelConfig.crossSentence.differentRoleLength || 0
                                                                    })(
                                                                        <InputNumber style={{ width: '60px' }} onChange={actions.crossSentenceChange.params('differentRoleLength')} />
                                                                    )
                                                                }
                                                            </Form.Item>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo9}</span>
                                                        </div>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo10}</span>
                                                            <Form.Item style={{ width: '65px', marginLeft: '5px' }}>
                                                                {
                                                                    getFieldDecorator(`crossSentence-maxCrossLength`, {
                                                                        initialValue: highLevelConfig.crossSentence.maxCrossLength || 100
                                                                    })(
                                                                        <InputNumber style={{ width: '60px' }} onChange={actions.crossSentenceChange.params('maxCrossLength')} />
                                                                    )
                                                                }
                                                            </Form.Item>
                                                            <span>{locales.fix.afterFixUnit}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='semantic-tags-detail-page-body-tabs-senior-list'>
                                                    <h2>{locales.components.pages.semanticTags.detail.simimNo}</h2>
                                                    <div className='semantic-tags-detail-page-body-tabs-senior-list-info'>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <Form.Item>
                                                                {
                                                                    getFieldDecorator(`duplicate-on`, {
                                                                        initialValue: highLevelConfig.duplicate.on
                                                                    })(
                                                                        <Select style={{ width: '80px' }} onChange={actions.similarityRemovalChange} placeholder={locales.labels.pleaseChoice}>
                                                                            <Select.Option value={1}>{locales.components.pages.semanticTags.detail.up}</Select.Option>
                                                                            <Select.Option value={0}>{locales.components.pages.semanticTags.detail.down}</Select.Option>
                                                                        </Select>
                                                                    )
                                                                }
                                                            </Form.Item>
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo14}</span>
                                                        </div>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo11}</span>
                                                            <Form.Item style={{ width: '65px', marginLeft: '5px' }}>
                                                                {
                                                                    getFieldDecorator(`duplicate-threshold`, {
                                                                        initialValue: highLevelConfig.duplicate.threshold || 80
                                                                    })(
                                                                        <InputNumber style={{ width: '60px' }} min={40} max={100} onChange={actions.duplicateChange.params('threshold')} />
                                                                    )
                                                                }
                                                            </Form.Item>
                                                            <span>%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='semantic-tags-detail-page-body-tabs-senior-list semantic-tags-detail-page-body-tabs-senior-newh2'>
                                                    <h2>{locales.components.pages.semanticTags.detail.seniorField}</h2>
                                                    <div className='semantic-tags-detail-page-body-tabs-senior-list-info'>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo12}</span>
                                                            <Form.Item style={{ width: '65px', marginLeft: '5px' }}>
                                                                {
                                                                    getFieldDecorator(`similarityDegreeThreshold-threshold`, {
                                                                        initialValue: highLevelConfig.similarityDegreeThreshold.threshold || 80
                                                                    })(
                                                                        <InputNumber style={{ width: '60px' }} min={80} max={100} onChange={actions.similarityDegreeThresholdChange.params('threshold')} />
                                                                    )
                                                                }
                                                            </Form.Item>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo13}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                                <If condition={(editEnable && type === 3) || type !== 3}>
                                    <div style={{ marginLeft: '30px' }}>
                                        <Button type='primary' loading={loading} onClick={actions.save.params(false)} style={{ marginRight: '10px' }}>{locales.components.pages.semanticTags.detail.save}</Button>
                                        {
                                            !this.props.match.params.id && <Button type='primary' loading={loading} onClick={actions.saveAndNext.params(false)} style={{ marginRight: '10px' }}>{locales.labels.saveAndNext}</Button>
                                        }
                                        <Button onClick={actions.cancel}>{locales.components.pages.semanticTags.detail.cancel}</Button>
                                    </div>
                                </If>

                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SemanticTagsDetailTemplate;

function Regular({_errFlag, getFieldDecorator, recallRegular, locales, actions, formItemKey}) {
    return <div>
        <div className='semantic-tags-detail-page-body-tabs-list-limit'>
            <Form.Item hasFeedback validateStatus={_errFlag ? null : "error"}>
                {getFieldDecorator(formItemKey, {
                    initialValue: recallRegular || undefined,
                    rules: [
                        {
                            whitespace: true
                        }
                    ]
                }
                )(<Input style={{ width: '500px' }} placeholder={locales.fields.pleaseChoice} onChange={actions.recallRegularChange.params(formItemKey)} />)}
            </Form.Item>
        </div>
    </div>;
}

function KeyWord({recallKeyWord, _errFlag, getFieldDecorator, locales, actions, formItemKey}) {
    return <div>
        {!_isEmpty(recallKeyWord) && recallKeyWord.map(item => {
            return (
                <div key={item.id} className='semantic-tags-detail-page-body-tabs-list-limit'>
                    <Space>
                        <Form.Item hasFeedback validateStatus={_errFlag ? null : "error"}>
                            {getFieldDecorator(`${formItemKey}-${item.id}`, {
                                initialValue: item.text || undefined,
                                rules: [
                                    {
                                        whitespace: true
                                    }
                                ]
                            }
                            )(<Input style={{ width: '500px' }} placeholder={locales.fields.pleaseChoice} onChange={actions.recallKeyWordChangeField.params(formItemKey, item.id)} />)}
                        </Form.Item>
                        <Button icon={<Icon type='jianhao' style={{ color: '#1b6dff' }} />} size='small' shape='circle' onClick={actions.recallKeyWordDel.params(formItemKey, item.id)}></Button>
                    </Space>
                </div>
            );
        })}
        <div className='semantic-tags-detail-page-body-list-info'>
            <Button icon={<Icon type='iconfont707' style={{ color: '#1b6dff' }} />} size='small' shape='circle' onClick={actions.recallKeyWordAdd.params(formItemKey)}></Button>
        </div>
    </div>;
}
