import React from 'react';
import './style.scss';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Radio, Checkbox, Row, Col, Form, Icon, PageHeader } from 'udesk-ui';
import ConceptWordDetailRoute from './route';

export default class ConceptWordDetailTemplate extends ConceptWordDetailRoute {
    render() {
        let {
            actions,
            locales,
            privates
        } = this;
        let { paramsId, conceptWordDescription, isRepeat, isFold, isEmpty, recommendDescriptionList, conceptWordDescriptionFromCheckbox, descriptionList,
            conceptWordNameLength, conceptWordDescriptionLength, loading, editEnable, type } = this.privates;
        return (
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={paramsId ? locales.components.pages.conceptWord.detail.title.edit : locales.components.pages.conceptWord.detail.title.new}
                    onBack={actions.backToIndex}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root concept-word-detail-page'>
                        <div className="concept-word-detail-page-body">
                            <div className="concept-word-detail-page-content">
                                <div className="concept-word-detail-page-content-form">
                                    <Form ref={privates.formInstance} labelCol={{ span: 3 }} wrapperCol={{ span: 18 }} onValuesChange={actions.onFormValuesChange}
                                        initialValues={{
                                            name: privates.conceptWordName,
                                            effectiveness: privates.usrfulRadioValue,
                                        }}>
                                        <div className="concept-word-detail-page-content-form-name-input">
                                            <Form.Item
                                                name='name'
                                                label={locales.components.pages.conceptWord.detail.name}
                                                rules={[{ validator: (_, value) => actions.getStringLengthForInput(value) <= 20 ? Promise.resolve() : Promise.reject(locales.fix.exceedingLength) }, { required: true, message: locales.components.pages.conceptWord.detail.warning }]}
                                                validateTrigger='onBlur'
                                            >
                                                <Input style={{ width: '200px' }}
                                                    // onBlur={actions.conceptWordNameInputFormatter}
                                                    // onChange={actions.conceptWordNameInputChange}
                                                    placeholder={locales.components.pages.conceptWord.detail.namePlaceholder}
                                                    addonAfter={`${conceptWordNameLength}/20`}
                                                />
                                            </Form.Item>
                                            {/* <div className="concept-word-detail-page-content-form-name-input-word-count" style={{ lineHeight: '32px', color: conceptWordNameLength >= 20 ? 'rgba(251,81,39,1)' : 'rgba(0,0,0,0.25)' }}>
                                                {`${conceptWordNameLength}/20`}
                                            </div> */}
                                        </div>
                                        {/* <div style={{display:conceptWordNameLength===0&&conceptWordNameChangeFlag? 'block':'none', color: 'rgba(251,81,39,1)', top: 30, width: 140, position: 'absolute'}}>
                                                {locales.components.pages.conceptWord.detail.warning}
                                            </div> */}
                                        <Form.Item name='effectiveness' label={locales.components.pages.conceptWord.detail.effectiveness} rules={[{ required: true }]}>
                                            <Radio.Group>
                                                <Radio value={1} style={{ marginRight: '16px' }}>{locales.components.pages.conceptWord.detail.effective}</Radio>
                                                <Radio value={0}>{locales.components.pages.conceptWord.detail.invalid}</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <div className="concept-word-detail-page-content-form-bottom">
                                    <div className="concept-word-detail-page-content-form-description">
                                        <div className="concept-word-detail-page-content-form-item-label">
                                            {locales.components.pages.conceptWord.detail.description}
                                        </div>
                                        <div className="concept-word-detail-page-content-form-description-input">
                                            <Input
                                                value={conceptWordDescription}
                                                onPressEnter={actions.addDescription}
                                                onBlur={actions.conceptWordDescriptionInputFormatter}
                                                onChange={actions.conceptWordDescriptionInputChange}
                                                placeholder={locales.components.pages.conceptWord.detail.descriptionPlaceholder}
                                            />
                                            <div className="concept-word-detail-page-content-form-description-input-word-count" style={{ color: conceptWordDescriptionLength >= 20 ? 'rgba(251,81,39,1)' : 'rgba(0,0,0,0.25)' }}>
                                                {`${conceptWordDescriptionLength}/20`}
                                            </div>
                                        </div>
                                        <div className="concept-word-detail-page-content-form-description-submit-btm">
                                            <Button type="primary" onClick={actions.addDescription}>
                                                {locales.components.pages.conceptWord.detail.add}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="concept-word-detail-page-content-form-description-repeat" style={{ display: isRepeat ? 'block' : 'none' }}>
                                        <div className='concept-word-detail-page-icon'>
                                            <Icon type="ic-close-circle-guanbi" style={{ position: 'absolute', top: '-3px', height: '16px' }} />
                                        </div>
                                        {locales.components.pages.conceptWord.detail.repeat}
                                    </div>
                                    <div className="concept-word-detail-page-content-form-description-repeat" style={{ display: isEmpty ? 'block' : 'none' }}>
                                        <div className='concept-word-detail-page-icon'>
                                            <Icon type="ic-close-circle-guanbi" style={{ position: 'absolute', top: '-3px', height: '16px' }} />
                                        </div>
                                        {locales.components.pages.conceptWord.detail.empty}
                                    </div>
                                    <div className="concept-word-detail-page-content-form-recommend">
                                        <div className="concept-word-detail-page-content-form-recommend-head">
                                            <div className="concept-word-detail-page-content-form-recommend-head-left">
                                                <div onClick={actions.foldRecommendList}>
                                                    {locales.components.pages.conceptWord.detail.recommend}
                                                    <div className='concept-word-detail-page-icon'>
                                                        {isFold ?
                                                            <Icon type="ic-arrow-down-s-xiasanjiao" style={{ position: 'absolute', top: '-3px', height: '16px' }} />
                                                            : <Icon type="ic-arrow-up-s-shangsanjiao" style={{ position: 'absolute', top: '-3px', height: '16px' }} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="concept-word-detail-page-content-form-recommend-head-right" style={{ display: !isFold ? 'block' : 'none' }}>
                                                <span onClick={actions.checkboxCancel}>
                                                    {locales.components.pages.conceptWord.detail.cancel}
                                                </span>
                                                <span style={{ color: 'rgba(27,109,255,1)' }} onClick={actions.recommendRefresh}>
                                                    {locales.components.pages.conceptWord.detail.change}
                                                </span>
                                                <span style={{ color: 'rgba(27,109,255,1)' }} onClick={actions.addDescriptionFromCheckbox}>
                                                    {locales.components.pages.conceptWord.detail.add}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="concept-word-detail-page-content-form-recommend-body" style={{ display: !isFold ? 'block' : 'none' }}>
                                            <If condition={recommendDescriptionList && recommendDescriptionList.length !== 0}>
                                                <Checkbox.Group style={{ width: '100%' }} onChange={actions.recommendDescriptionCheck} value={conceptWordDescriptionFromCheckbox}>
                                                    <Row>
                                                        {
                                                            recommendDescriptionList.map(recommendDescription => {
                                                                return (
                                                                    <Col span={6} key={recommendDescription}>
                                                                        <Checkbox value={recommendDescription} key={recommendDescription}>{recommendDescription}</Checkbox>
                                                                    </Col>
                                                                );
                                                            })

                                                        }
                                                    </Row>
                                                </Checkbox.Group>
                                            </If>
                                            <If condition={!recommendDescriptionList || recommendDescriptionList.length === 0}>
                                                {/* else */}
                                                <div style={{ marginBottom: '12px' }}>
                                                    {locales.components.pages.conceptWord.detail.recommendEmpty}
                                                </div>
                                            </If>
                                        </div>
                                    </div>
                                    <div className="concept-word-detail-page-content-form-words-selected-table">
                                        <div className="concept-word-detail-page-content-form-item-label">
                                            {locales.components.pages.conceptWord.detail.descriptionSelected}
                                        </div>
                                        <div className="concept-word-detail-page-content-form-words-selected-table-body">
                                            <div className='udesk-qa-ui-row'>
                                                {
                                                    descriptionList && descriptionList.map(description => {
                                                        return (
                                                            <div key={description} className='concept-word-detail-page-content-form-words-selected-table-body-item'>
                                                                {description}
                                                                <Icon type="ic-minus-circle-jian" onClick={actions.cancelDescriptionSelected.params(description)} />
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <If condition={(editEnable && type === 3) || type !== 3}>
                                <div className="concept-word-detail-page-content-bottom">
                                    <Button type="primary" onClick={actions.submitBtnHandle} loading={loading}>
                                        {locales.components.pages.conceptWord.detail.save}
                                    </Button>
                                    <Button onClick={actions.backToIndex}>
                                        {locales.components.pages.conceptWord.detail.cancel}
                                    </Button>
                                </div>
                            </If>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
