import Udesk from 'Udesk';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Icon, Input, Space, Descriptions } from 'udesk-ui';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const defaultValue = '--';
const { TextArea } = Input;
export default (props: any) => {
    const {
        locales,
        className,
        ownerName,
        userName,
        followUpDays = 1,
        customerInfo,
        handleRemarkConfirm,
    } = props;
    const inputRef = useRef<any>();
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue);

    const formatInputValue = useCallback((visible, value?) => {
        setInputValue(inputValue => {
            if (value === undefined || value === null) {
                value = inputValue;
            }
            if (visible) {
                if (value === defaultValue) {
                    return '';
                }
            } else {
                if (value === '') {
                    return defaultValue;
                }
            }
            return value;
        });
    }, []);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    }, []);

    const showInput = useCallback(() => {
        setInputVisible(true);
        formatInputValue(true);
    }, [
        formatInputValue
    ]);

    const handleInputConfirm = useCallback(() => {
        handleRemarkConfirm(inputValue).then(
            resp => {
                Udesk.ui.notify.success(locales.fix.saveSuccess);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        ).finally(() => {
            setInputVisible(false);
            formatInputValue(false);
        });
    }, [
        formatInputValue, 
        handleRemarkConfirm, 
        inputValue, 
        locales.fix.saveSuccess
    ]);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [
        inputVisible
    ]);

    useEffect(() =>{
        formatInputValue(inputVisible, customerInfo?.remark);
    }, [
        inputVisible,
        formatInputValue, 
        customerInfo?.remark
    ]);

    return (
        <div className={className}>
            <Descriptions layout="vertical" column={4}>
                <Descriptions.Item label={/* 负责人 */UdeskLocales['current'].pages.gong.saleClientCenter.components.userInfo.index.personInCharge}>{ownerName}</Descriptions.Item>
                <Descriptions.Item label={/* 最近跟进人 */UdeskLocales['current'].pages.gong.saleClientCenter.components.userInfo.index.recentFollower}>{userName}</Descriptions.Item>
                <Descriptions.Item label={/* 跟进天数 */UdeskLocales['current'].pages.gong.saleClientCenter.components.userInfo.index.followUpDays}>{followUpDays}{/* 天 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.userInfo.index.day}</Descriptions.Item>
                <Descriptions.Item label={/* 备注 */UdeskLocales['current'].pages.gong.saleClientCenter.components.userInfo.index.remarks}>
                    {inputVisible && (
                        <TextArea
                            ref={inputRef}
                            rows={4}
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputConfirm}
                            onPressEnter={handleInputConfirm}
                        />
                    )}
                    {!inputVisible && (
                        <Space>
                            <span>{inputValue}</span>
                            <Button
                                onClick={showInput}
                                icon={<Icon antdIcon={true} type='EditOutlined' />}
                                type='link' 
                            />
                        </Space>
                    )}

                </Descriptions.Item>
            </Descriptions>
        </div>
    );
};