import React from 'react';
import Udesk from 'Udesk';
import ReactModal from 'udesk-react/src/components/react-modal';
import ReactSelect from 'udesk-react/src/components/react-select';
import ProfessionalWordsComponent from './component';
import SmartWordsLibraryCommonList from '../smart-words-library-common-list';
import classNames from 'classnames';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Icon, Input } from 'udesk-ui';

export default class ProfessionalWordsTemplate extends ProfessionalWordsComponent {
    render() {
        let {
            getFieldDecorator
        } = this.props.form;

        let {
            locales,
            actions
        } = this;

        let {
            navItemType,
            navItemName,
            columns,
            isCommonWordsModalShow,
            commonWordsModalTitle,
            corporaName,
            hotWordReplaceStatus,
            targetWordReplaceStatus,
            targetWords,
            homophonyWords,
            newTargetWords,
            newTargetWordsIsNull,
            pleaseAddTargetWords,
            targetWordsExists,
            newHomophonyWord,
        } = this.privates;

        return (
            <div>
                <SmartWordsLibraryCommonList
                    navItemType={navItemType}
                    boardName={Udesk.enums.smartWordsLibraryBoard.professionalWords.id}
                    columns={columns}
                    uploadModalTitle={locales.components.pages.smartWordsLibrary.upload.uploadProfessionalWords}
                    getlistDataPath={`corpora/terminologyList`}
                    getlistDataMethod={'post'}
                    openAddModal={actions.createCommonWords}
                    onCreated={(instance) => { this.privates.smartWordsLibraryListInstance = instance; }}
                />

                <ReactModal cancelText={locales.labels.cancel}
                    visible={isCommonWordsModalShow}
                    title={commonWordsModalTitle}
                    headerClassName="udesk-qa-smart-words-library-modal-header"
                    footerClassName="udesk-qa-smart-words-library-modal-footer"
                    okText={locales.labels.save}
                    onOk={actions.saveCommonWords}
                    onCancel={actions.closeCommonWordsModal}
                >
                    <Form className="smart-words-library-nav-item-form" layout="horizontal" onSubmit={actions.saveCommonWords.preventDefault()}>

                        <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} label={locales.fields.smartWordsLibrary.professionalWords.professionalWords}>
                            {getFieldDecorator('corporaName', {
                                initialValue: corporaName,
                                rules: [{
                                    required: true,
                                    message: locales.components.pages.smartWordsLibrary[navItemName].pleaseEnter
                                }]
                            })(
                                <Input
                                    placeholder={locales.components.pages.smartWordsLibrary[navItemName].pleaseEnter}
                                />
                            )}
                        </Form.Item>

                        <Form.Item
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            label={locales.fields.smartWordsLibrary.professionalWords.homophonic}
                        >
                            {getFieldDecorator('hotWordReplaceStatus', {
                                initialValue: hotWordReplaceStatus.toString()
                            })(
                                <ReactSelect
                                    classNames="udesk-qa-smart-words-library-react-select"
                                    hasNullSelect={false}
                                    dataScource={Udesk.enums.wordsEffectiveness}
                                    onChanged={actions.changeHotWordReplaceStatus}
                                ></ReactSelect>
                            )}
                        </Form.Item>

                        <Form.Item
                            label={locales.fields.smartWordsLibrary.professionalWords.homophonyWords}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            className="synonym-words-required-form-item synonym-words-list-box"
                        >
                            <div className="udesk-qa-smart-words-library-modal-body-synonym-words-list clearfix">
                                <With existTargetWords={Array.isArray(homophonyWords) && homophonyWords.length > 0}>
                                    <If condition={existTargetWords}>
                                        <div className="udesk-qa-smart-words-library-modal-body-synonym-words-list-item-groups clearfix">
                                            <For of={homophonyWords} each="targetWordsItem" index="index">
                                                <div key={`smart-words-library-synonym-words-modal-item-group-${index}`} className="synonym-words-item-group">
                                                    <label className="control-label synonym-words-item-label">
                                                        {targetWordsItem}
                                                    </label>
                                                    <button type="button" onClick={actions.deleteOneHomophonyWords.params(index)} className="synonym-words-item-button">
                                                        <Icon type="ic-minus-circle-jian" className="synonym-words-icon" />
                                                    </button>
                                                </div>
                                            </For>
                                        </div>
                                    </If>
                                </With>

                                <div className="udesk-qa-smart-words-library-modal-body-synonym-words-list-input-box col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <Input
                                        id='showNewTargetWordsInput'
                                        className={classNames("udesk-qa-smart-words-library-modal-body-synonym-words-list-input col-xs-12 col-sm-10 col-md-10 col-lg-10")}
                                        onChange={actions.changeHomophonyWords}
                                        onPressEnter={actions.pressHomophonyWordsEnter}
                                        value={newHomophonyWord}
                                        // placeholder={locales.components.pages.smartWordsLibrary.professionalWords.addTargetWords}
                                    />
                                    <button type="button" onClick={actions.addOneHomophonyWords} className={classNames("synonym-words-item-button")}>
                                        <Icon type="ic-add-circle-jia" />
                                    </button>
                                </div>
                            </div>
                        </Form.Item>

                        <Form.Item
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            label={locales.fields.smartWordsLibrary.professionalWords.target}
                        >
                            {getFieldDecorator('targetWordReplaceStatus', {
                                initialValue: targetWordReplaceStatus.toString()
                            })(
                                <ReactSelect
                                    classNames="udesk-qa-smart-words-library-react-select"
                                    hasNullSelect={false}
                                    dataScource={Udesk.enums.wordsEffectiveness}
                                    onChanged={actions.changeTargetWordReplaceStatus}
                                ></ReactSelect>
                            )}
                        </Form.Item>

                        <Form.Item
                            label={locales.fields.smartWordsLibrary.professionalWords.targetWords}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            className="synonym-words-required-form-item synonym-words-list-box"
                        >
                            <div className="udesk-qa-smart-words-library-modal-body-synonym-words-list clearfix">
                                <With existTargetWords={targetWords !== null && targetWords !== undefined && targetWords.length > 0}>
                                    <If condition={existTargetWords}>
                                        <div className="udesk-qa-smart-words-library-modal-body-synonym-words-list-item-groups clearfix">
                                            <For of={targetWords} each="targetWordsItem" index="index">
                                                <div key={`smart-words-library-synonym-words-modal-item-group-${index}`} className="synonym-words-item-group">
                                                    <label className="control-label synonym-words-item-label">
                                                        {targetWordsItem}
                                                    </label>
                                                    <button type="button" onClick={actions.deleteOneTargetWords.params(index)} className="synonym-words-item-button">
                                                        <Icon type="ic-minus-circle-jian" className="synonym-words-icon" />
                                                    </button>
                                                </div>
                                            </For>
                                        </div>
                                    </If>
                                </With>

                                <div className="udesk-qa-smart-words-library-modal-body-synonym-words-list-input-box col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <Input
                                        id='showNewTargetWordsInput'
                                        className={classNames("udesk-qa-smart-words-library-modal-body-synonym-words-list-input col-xs-12 col-sm-10 col-md-10 col-lg-10")}
                                        onChange={actions.changeTargetWords}
                                        onPressEnter={actions.pressEnter}
                                        value={newTargetWords}
                                        placeholder={locales.components.pages.smartWordsLibrary.professionalWords.addTargetWords} />
                                    <button type="button" onClick={actions.addOneTargetWords} className={classNames("synonym-words-item-button")}>
                                        <Icon type="ic-add-circle-jia"
                                            className={classNames({ [`no-synonym-words-warning`]: newTargetWordsIsNull || pleaseAddTargetWords })} />
                                    </button>
                                </div>

                                <If condition={newTargetWordsIsNull}>
                                    <span className="udesk-qa-smart-words-library-modal-body-warning-span">{locales.components.pages.smartWordsLibrary.professionalWords.targetWordsCanNotNull}</span>
                                </If>
                                <If condition={pleaseAddTargetWords}>
                                    <span className="udesk-qa-smart-words-library-modal-body-warning-span">{locales.components.pages.smartWordsLibrary.professionalWords.pleaseAddTargetWords}</span>
                                </If>
                                <If condition={targetWordsExists}>
                                    <span className="udesk-qa-smart-words-library-modal-body-warning-span">{this.locales.components.pages.smartWordsLibrary.professionalWords.targetWordsExists}</span>
                                </If>
                            </div>
                        </Form.Item>

                    </Form>

                </ReactModal>

            </div>
        );
    }
}