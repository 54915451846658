import React, { useState, useMemo, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { message, Badge, Space, Button } from 'udesk-ui';
import { postInspectionRuleApprovesSearch } from 'src/api/inspectionRule/approves/search';
import { putInspectionRuleApprovesById } from 'src/api/inspectionRule/approves/{id}';
import RejectButton from '../rejectButton/index';
import './index.scss'; 

const default_pagination = {
    current: 1,
    pageSize: 20,
    total: 0,
};

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const {history} = props;
    const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    const [tableData, setTableData] = useState<any[]>([]);
    const [pagination, setPagination] = useState<StandardizeTableProps['table']['pagination']>(
        props.storages.pagination
    );

    const submit = useCallback((id: number, params: {
        approveStatus: 0 | 1 | 2,
        rejectReason?: string
    }) => {
        return putInspectionRuleApprovesById(params, {
            segments: { 
                id 
            }
        }).then(() => {
            message.success(/* 修改成功 */locales.pages.tasks.approve.components.table.index.modificationSucceeded);
            setTableData(tableData.map((item: any) => {
                if (item.id === id) {
                    return {
                        ...item,
                        ...params
                    };
                }
                return item;
            }));
        });
    }, [locales, tableData]);

    const viewHandle = useCallback((id, taskId, approveStatus) => { 
        const routeOptions = {
            history,
            routeName: 'approveTasksCheckPointIndex',
            pathParams: {
                taskId,
                approveId: id,
                approveStatus,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    }, [history]);

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 任务名称 */locales.pages.tasks.approve.components.table.index.taskName,
                key: 'taskName',
                dataIndex: 'taskName',
            },
            {
                title: /* 数据源 */locales.pages.tasks.approve.components.table.index.dataSource,
                key: 'inspectDataSource',
                dataIndex: 'inspectDataSource',
                render: (name, item, index) => {
                    return Udesk.enums.inspectDataSources.getName(name);
                },
            },
            {
                title: /* 质检类型 */locales.pages.tasks.approve.components.table.index.qualityInspectionType,
                key: 'related',
                dataIndex: 'related',
                render: (name, item, index) => {
                    return Udesk.enums.inspectTaskType.getName(item, '', 'related');
                },
            },
            {
                title: /* 状态 */locales.pages.tasks.approve.components.table.index.state,
                key: 'approveStatus',
                dataIndex: 'approveStatus',
                render: (status, item, index) => {
                    return (
                        <React.Fragment>
                            <Badge status={['success', 'processing', 'success', 'error'][status] as any} />
                            <span>{Udesk.enums.inspectApproveType.getName(item, '', 'approveStatus')}</span>
                        </React.Fragment>
                    );
                },
            },
            {
                title: /* 更新时间 */locales.pages.tasks.approve.components.table.index.updateTime,
                key: 'updateTime',
                dataIndex: 'updateTime',
            },
            {
                title: /* 更新人 */locales.pages.tasks.approve.components.table.index.updatedBy,
                key: 'updateUserName',
                dataIndex: 'updateUserName'
            },
            {
                title: /* 审批人 */locales.pages.tasks.approve.components.table.index.approver,
                key: 'approverUserName',
                dataIndex: 'approverUserName',
            },
            {
                title: /* 驳回原因 */locales.pages.tasks.approve.components.table.index.reasonForRejection,
                key: 'rejectReason',
                dataIndex: 'rejectReason',
                render: (text) => text || '-',
            },
            {
                title: /* 操作 */locales.pages.tasks.approve.components.table.index.operation,
                key: 'actions',
                render({id, approveStatus, taskId}){
                    return (
                        <Space>
                            {
                                [
                                    <Button type="link" onClick={() => viewHandle(id, taskId, approveStatus)}>{/* 详情 */locales.pages.tasks.approve.components.table.index.details}</Button>,
                                    <Button type="link" onClick={() => {
                                        submit(id, {
                                            approveStatus: Udesk.enums.inspectApproveType.resolve.id
                                        });
                                    }}>{/* 通过 */locales.pages.tasks.approve.components.table.index.adopt}</Button>,
                                    <RejectButton onSubmit={(values) => {
                                        return submit(id, {
                                            ...values,
                                            approveStatus: Udesk.enums.inspectApproveType.reject.id
                                        });
                                    }}/>
                                ].filter((btn, index) => {
                                    if (index > 0) {
                                        return hasFeature?.('task:approve') && approveStatus === Udesk.enums.inspectApproveType.resolving.id;
                                    }
                                    return true;
                                })
                            }
                        </Space>
                    );
                }
            },
        ];
        return {
            columns,
            dataSource: tableData,
            pagination,
            rowSelectionEnhance: {},
        };
    }, [tableData, pagination, viewHandle, submit, hasFeature]);

    return (
        <StandardizeTable.RequestTable
            onRequest={(params) => {
                props.saveStorages({
                    ...params.filter,
                    pagination: { ...params.pagination },
                });
                const pagination = {
                    pageNum: params.pagination.current,
                    pageSize: params.pagination.pageSize,
                };
                postInspectionRuleApprovesSearch(
                    params.filter, 
                    {
                        params: pagination
                    }
                ).then((resp) => {
                    setTableData(resp.data ?? []);
                    setPagination({
                        total: resp.paging?.total,
                        current: resp.paging?.pageNum,
                        pageSize: resp.paging?.pageSize,
                    });
                });
            }}
            table={tableProps}
            filter={{
                fields: [
                    {
                        type: 'Input',
                        name: 'taskName',
                        label: /* 任务名称 */locales.pages.tasks.approve.components.table.index.taskName,
                    },
                    {
                        type: 'Select',
                        name: 'inspectDataSource',
                        label: /* 数据源 */locales.pages.tasks.approve.components.table.index.dataSource,
                        props: {
                            optionFilterProp: 'label',
                            showSearch: true,
                            options: [{label: /* 全部 */locales.pages.tasks.approve.components.table.index.whole, value: null}].concat(
                                Udesk.enums.inspectDataSources.map(
                                    item => ({
                                        label: item.name,
                                        value: item.id
                                    })
                                )
                            ),
                        },
                    },
                    {
                        type: 'Select',
                        name: 'taskType',
                        label: /* 质检类型 */locales.pages.tasks.approve.components.table.index.qualityInspectionType,
                        props: {
                            optionFilterProp: 'label',
                            showSearch: true,
                            options: Udesk.enums.inspectTaskType.map(item => ({
                                label: item.name,
                                value: item.id
                            })),
                        },
                    },
                    {
                        type: 'Select',
                        name: 'approveStatus',
                        label: /* 审批状态 */locales.pages.tasks.approve.components.table.index.approvalStatus,
                        props: {
                            optionFilterProp: 'label',
                            showSearch: true,
                            options: Udesk.enums.inspectApproveType.map(item => ({
                                label: item.name,
                                value: item.id
                            })),
                        },
                    },
                ],
                initialValues: { 
                    // taskName: props.storages.taskName,
                    // inspectDataSource: props.storages.inspectDataSource,
                    // taskType: props.storages.taskType,
                    // approveStatus: props.storages.approveStatus, 
                },
            }}
        />
    );
});

class Component extends React.Component {
    privates = {
        storages: {
            pagination: default_pagination,
            taskName: undefined,
            inspectDataSource: null,
            taskType: null,
            approveStatus: null,
        },
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'memory',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/tasks\/approve\/\d+\/template\/check-point\/\d+\/\d+\/index\/*/i,
            ],
        },
    };

    actions = {
        saveStorages(changedParams: any) {
            (this as any).privates.storages = { ...(this as any).privates.storages, ...changedParams };
            (this as any).actions.update();
        },
    };
    render() {
        return <Template {...this.props} storages={this.privates.storages} saveStorages={this.actions.saveStorages} />;
    }
}
export default Udesk.react.udeskify(Component);
