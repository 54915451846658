import React, { useMemo } from 'react';
import { DatePicker, Select, Form, Input, InputNumber } from 'udesk-ui';

const UI = {
    TextArea: Input.TextArea,
    DatePicker,
    Select,
    Input,
    InputNumber
};

const Text = React.memo((props:any) => {
    let {value, empty, options, suffix = ''} = props;
    let text = value ? value + suffix : empty;
    if (options) {
        const item = options.find(item => item.value === value);
        if (item) {
            text = item.label + suffix;
        }
    }
    return (
        <span>{text}</span>
    );
});

export default React.memo((props:any) => {
    const {type, config, fieldProps, isRead, empty = '--'} = props;

    const Component = useMemo(() => UI[type] || Input, [
        type
    ]);

    return (
        <Form.Item {...config}>
            {
                isRead ? <Text empty={empty} {...fieldProps} /> : <Component {...fieldProps}/>
            }
        </Form.Item>
    );
});


