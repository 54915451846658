import type { Moment } from 'moment';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Row, Col, Form, Select, Input, DatePicker, Space, Button } from 'udesk-ui';
import { getReviewCallCustomerMessageFindDetailByCondition } from 'src/api/review/call/customer/message/findDetailByCondition';
import { getUsers } from 'src/api/users';
import { useRequest } from 'src/util/hook';
import Page from 'Component/common/udesk-qa-page-layout';
import Business from '../components/business';
import Table2 from '../components/table';
import BindModal, { RefProps } from '../components/business-stage/modal';
import ContactModal from '../components/contact-Info-modal';
import moment from 'moment';

import './index.scss';
import { CommunicateModal, CommunicateModalRefProps } from '../components/table/components/row/row3';
import { linkTo } from 'src/util/link';

export const playIconContext = React.createContext(true);

const initChartsDataListItem = (chartsDataList, timeStr, callback) => {
    let day = moment(timeStr).format('D');
    let month = moment(timeStr).format('M');
    chartsDataList.forEach((data, index) => {
        if (data.day === day && data.month === month) {
            callback(data, index);
        }
    });
};

const useFinalDate = () => {
    let startDate = moment().endOf('day').subtract(30, 'days');
    let endDate = moment().endOf('day');
    let currentDay = moment().format('D');

    return [startDate, endDate, currentDay] as any[];
};

const locales = Locales['current'];
const { Option } = Select;
const { RangePicker } = DatePicker;
type RangeValue = [Moment | null, Moment | null] | null;

const Template = React.memo((props: any) => {
    let { match, history, location } = props;
    const data = location.state?.data;
    const [startDate, endDate, currentDay] = useFinalDate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [searchCondition, setSearchCondition] = useState({});
    const [companyName, setCompanyName] = useState('');
    const [userName, setUserName] = useState('');
    const [callDataList, setCallDataList] = useState<any[]>([]);
    const [currentCallIdx, setCurrentCallIdx] = useState(0);
    const [callIndex, setCallIndex] = useState(0);
    const [userList, setUserList] = useState<any[]>([]);
    const [listData, setListData] = useState<any[]>([]);
    const [monthData, setMonthData] = useState<any[]>([]);
    const [userNameList, setUserNameList] = useState<any[]>([]);
    const [isAdd, setIsAdd] = useState(true);
    const [dates, setDates] = useState<RangeValue>(null);
    const [hackValue, setHackValue] = useState<RangeValue>(null);
    const [value, setValue] = useState<RangeValue>(null);
    const [dataCallList, setDataCallList] = useState<any[]>([]);
    const bindModalRef = useRef<RefProps>(null);
    const communicateModalRef = useRef<CommunicateModalRefProps>(null);
    const contactModalRef = useRef<any>(null);
    const request = useRequest();
    const [rowTotal, setRowTotal] = useState<any>({});

    const pickerClassName = 'sale-client-center-detail-filter-range-picker';

    useEffect(() => {
        if (!location?.state) {
            handlerBack();
        }
    });

    const handlerBack = useCallback(() => {
        linkTo(history, 'saleClientList', {}, location?.state);
    }, [history, location?.state]);

    const prefixSelector = (
        <Form.Item name='type' noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                {Udesk.enums.customerDetailType.map((item) => {
                    return (
                        <Option key={item.id} value={item.id}>
                            {item.name}
                        </Option>
                    );
                })}
            </Select>
        </Form.Item>
    );
    
    const handlerModal = () => {
        contactModalRef.current?.open();
    };
    const handlerToggle = () => {
        if (!dataCallList.length) return;
        setIsAdd(!isAdd);
    };
    const handlerDateOpenChange = (open) => {
        if (open) {
            setHackValue([null, null]);
            setDates([null, null]);
        } else {
            setHackValue(null);
        }
    };
    const disabledDate = (now) => {
        if (now.isAfter(moment())) {
            return true;
        }
        if (hackValue) {
            const [start] = dates as any;
            let type = '';

            $(`.${pickerClassName}`).find('.udesk-qa-ui-picker-input').each((index, input) => {
                const isActive = $(input).is('.udesk-qa-ui-picker-input-active');
                if (isActive) {

                    type = index ? 'end' : 'start';
                }
            });

            if (type === 'end') {
                if (start) {
                    return Math.abs(now.diff(start, 'days')) > 30;
                }
            }
        }

        return false;
    };
    const handlerSearch = (values) => {
        const params: any = {
            key: values.key,
            userId: values.userId,
            type: values.type,
        };
        if (values.rangeDate && values.rangeDate.length) {
            // let diffDate = moment(values.rangeDate[1]).diff(moment(values.rangeDate[0]), 'months');
            params.from = moment(values.rangeDate[0]).format('YYYY-MM-DD 00:00:00');
            params.to = moment(values.rangeDate[1]).format('YYYY-MM-DD 23:59:59');
        }
        setSearchCondition(params);
        getDetailData(params);
        // getDetailChartRoomData(params);
    };
    const getCustomerTrip = useCallback((values?, chartsDataList?) => {
        const isShowLoading = !chartsDataList;
        values = values || searchCondition;
        chartsDataList = chartsDataList || monthData;

        if (isShowLoading) {
            setLoading(true);
        }

        const params = {
            customerId: match.params.id,
            from: moment(startDate).format('YYYY-MM-DD 00:00:00'),
            to: moment(endDate).format('YYYY-MM-DD 23:59:59'),
            type: Udesk.enums.customerDetailType.all.id,
            ...(values || searchCondition),
        };
        request('/review/customerTrip', {
            customerId: params.customerId,
            startDate: params.from,
            endDate: params.to,
        }).then(
            resp => {
                let {
                    customerRiskList,
                    customerCommunicate,
                } = resp.data;

                customerRiskList = customerRiskList || [];
                customerCommunicate = customerCommunicate || {
                    communicateStrategyList: [],
                    managerCommunicateList: [],
                };

                if (isShowLoading) {
                    chartsDataList.forEach((item) => {
                        item.customerRiskList = undefined;
                        item.communicateStrategyList = undefined;
                        item.managerCommunicateList = undefined;
                    });
                }

                // 风险点
                customerRiskList.forEach(item => {
                    initChartsDataListItem(chartsDataList, item.hitTime, (data) => {
                        data.customerRiskList = data.customerRiskList || [];
                        data.customerRiskList.push(item);
                    });
                });
                // 智能推荐
                customerCommunicate.communicateStrategyList?.forEach(item => {
                    initChartsDataListItem(chartsDataList, item.hitTime, (data) => {
                        data.communicateStrategyList = data.communicateStrategyList || [];
                        data.communicateStrategyList.push(item);
                    });
                });
                // 管理者建议
                customerCommunicate.managerCommunicateList?.forEach(item => {
                    initChartsDataListItem(chartsDataList, item.hitTime, (data) => {
                        data.managerCommunicateList = data.managerCommunicateList || [];
                        data.managerCommunicateList.push(item);
                    });
                });

                setRowTotal(rowTotal => {
                    return {
                        ...rowTotal,
                        customerRisk: customerRiskList.length || 0,
                        communicateStrategy: customerCommunicate.communicateStrategyList?.length || 0,
                        managerCommunicate: customerCommunicate.managerCommunicateList?.length || 0,
                    };
                });

                setMonthData([...chartsDataList]);
            }
        ).finally(() => {
            if (isShowLoading) {
                setLoading(false);
            }
        });
    }, [endDate, match.params.id, monthData, request, searchCondition, startDate]);
    const getDetailData = (values?) => {
        setLoading(true);
        const params = {
            customerId: match.params.id,
            from: moment(startDate).format('YYYY-MM-DD 00:00:00'),
            to: moment(endDate).format('YYYY-MM-DD 23:59:59'),
            type: Udesk.enums.customerDetailType.all.id,
            ...(values || searchCondition),
        };
        let startM = moment(params.from).format('M');
        let endM = moment(params.to).format('M');
        let startD = moment(params.from).format('D');
        let endD = moment(params.to).format('D');
        let totalD = moment(params.from).daysInMonth();
        let chartsDataList: any = [];
        if (startM === endM) {
            for (let i = 1; i <= parseInt(currentDay, 10); i++) {
                chartsDataList.push({
                    day: i.toString(),
                    month: startM,
                    dayText:
                        parseInt(currentDay, 10) === i
                            ? locales.pages.gong.saleClientCenter.detail.index.today
                            : i === 1
                                ? i + '/' + startM
                                : i,
                    data: null,
                    children: [],
                    customData: null,
                });
            }
        } else {
            for (let i = parseInt(startD, 10); i <= totalD; i++) {
                chartsDataList.push({
                    day: i.toString(),
                    month: startM,
                    dayText: i === parseInt(startD, 10) ? i + '/' + startM : i,
                    data: null,
                    children: [],
                    customData: null,
                });
            }
            for (let i = 1; i <= parseInt(endD, 10); i++) {
                chartsDataList.push({
                    day: i.toString(),
                    month: endM,
                    dayText:
                        parseInt(currentDay, 10) === i
                            ? locales.pages.gong.saleClientCenter.detail.index.today
                            : i === 1
                                ? i + '/' + endM
                                : i,
                    data: null,
                    children: [],
                    customData: null,
                });
            }
        }

        request('/review/customerTrip/dynamic', {
            customerId: params.customerId,
            startDate: params.from,
            endDate: params.to,
        }).then(
            resp => {
                let weComCustomerDynamic = {
                    customerDynamicList: resp.data || [],
                    totalCount: resp.paging.total,
                };
                // 客户动态
                weComCustomerDynamic.customerDynamicList?.forEach(item => {
                    initChartsDataListItem(chartsDataList, item.hitTime, (data) => {
                        data.customerDynamicList = data.customerDynamicList || [];
                        data.customerDynamicList.push(item);
                    });
                });
                setRowTotal(rowTotal => {
                    return {
                        ...rowTotal,
                        customerDynamic: weComCustomerDynamic.totalCount || 0,
                    };
                });

                setMonthData([...chartsDataList]);
            }
        );

        getCustomerTrip(values, chartsDataList);

        getReviewCallCustomerMessageFindDetailByCondition({ params }).then((res) => {
            setCompanyName(res?.companyName ?? '');
            setDataCallList(res?.dataCallAnalysisList??[]);
            if (!res?.dataCallAnalysisList?.length) {
                setIsAdd(true);
            }
            setUserName(res?.dataCallAnalysisList?.[0]?.customerName ?? '');
            let result = listDataChange(res?.dataCallAnalysisList ?? []);
            setListData(result);
            let callDataList: any = [];
            if (res?.dataCallAnalysisList && res?.dataCallAnalysisList.length) {
                res?.dataCallAnalysisList.forEach((item) => {
                    if (item.dialogSentences && item.dialogSentences.length) {
                        callDataList.push({
                            callId: item.id,
                            children: item.dialogSentences,
                        });
                    }
                });
            }
            let backState = props.location?.state;
            if (backState) {
                if (backState?.callIndex) {
                    setCallIndex(backState?.callIndex);
                } else {
                    if (result.length) {
                        setCallIndex(result.length - 1);
                    }
                }
                if (backState?.currentCallIdx) {
                    setCurrentCallIdx(backState?.currentCallIdx);
                } else {
                    if (callDataList.length) {
                        setCurrentCallIdx(callDataList.length - 1);
                    }
                }
            } else {
                if (result.length) {
                    setCallIndex(result.length - 1);
                }
                if (callDataList.length) {
                    setCurrentCallIdx(callDataList.length - 1);
                }
            }
            setCallDataList(callDataList);
            res?.dayCompanyMessageList?.forEach((item) => {
                initChartsDataListItem(chartsDataList, item.timeStr, (data) => {
                    data.data = item;
                });
            });
            res?.dataCallAnalysisList?.forEach((item) => {
                initChartsDataListItem(chartsDataList, item.createTime, (data) => {
                    data.dataCallAnalysisList = data.dataCallAnalysisList || [];
                    data.dataCallAnalysisList.push(item);
                });
            });

            let userNameList: any = [];
            res?.agentDayCompanyMessages?.forEach((item, idx) => {
                userNameList.push({
                    userName: item.userName,
                    userId: item.userId,
                });
                item.dayCompanyMessages?.forEach((record) => {
                    initChartsDataListItem(chartsDataList, record.timeStr, (data) => {
                        data.children.push(record.speechDuration);
                    });
                });
            });
            res.customerDurationDetails?.forEach((item) => {
                initChartsDataListItem(chartsDataList, item.timeStr, (data) => {
                    data.customData = item.customerSpeechDuration;
                });
            });
            setUserNameList(userNameList);
            setMonthData([...chartsDataList]);
        }).finally(() => {
            setLoading(false);
        });
    };
    
    const getUserDataList = () => {
        const params = {
            pageSize: 1000,
        };
        getUsers({ params }).then((res) => {
            setUserList(res.data ?? []);
        });
    };
    const listDataChange = (data) => {
        let middle = {};
        let newArr: any = [];
        data.forEach((item) => {
            item.date = item.callTime;
            item.callTime = moment(item.callTime).format('YYYY-MM-DD');
            if (!middle[item.callTime]) {
                middle[item.callTime] = item.callTime;
                newArr.push({
                    callTime: item.callTime,
                    children: [item],
                });
            } else {
                newArr.forEach((record) => {
                    if (middle[record.callTime] === item.callTime) {
                        record.children.push(item);
                    }
                });
            }
        });
        return newArr;
    };

    const handlerCallDetail = (callId, callIndex, currentCallIdx, isModalShow = false) => {
        if (!callId) return;
        const routeOptions = {
            history: props.history,
            routeName: 'gongCallDetail',
            pathParams: { callId },
            state: {
                ...props.location?.state,
                type: 'customer',
                id: match.params.id,
                isModalShow,
                callIndex,
                currentCallIdx,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() => {
        let backState = props.location?.state;
        if (backState && backState.isModalShow) {
            handlerModal();
        }
        getDetailData();
        getUserDataList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page
            pageBodyClassName='pages-gong-sale-client-center-detail-container'
            title={locales.pages.gong.saleClientCenter.detail.index.customerDetails}
            padding={true}
            onBack={handlerBack}
        >
            <Business 
                {...data}
                request={request}
                locales={locales}
                customerId={match.params.id}
                customerName={userName}
                setCustomerName={setUserName}
                callDataList={callDataList}
            />

            <Form
                form={form}
                layout='inline'
                onFinish={handlerSearch}
                style={{ width: '100%' }}
                initialValues={{ type: Udesk.enums.customerDetailType.all.id, rangeDate: [startDate, endDate] }}
            >
                <Row style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 20 }}>
                    <Col span={5}>
                        <Form.Item name='key'>
                            <Input
                                addonBefore={prefixSelector}
                                style={{ width: '100%' }}
                                placeholder={
                                    locales.pages.gong.saleClientCenter.detail.index
                                        .searchKeywordsInCommunicationContent
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name='userId'>
                            <Select
                                showSearch
                                showArrow
                                placeholder={locales.pages.gong.saleClientCenter.detail.index.allMembers}
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {userList.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.realname}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name='rangeDate'>
                            <RangePicker
                                className={pickerClassName}
                                value={hackValue || value} 
                                allowClear={false}
                                onChange={val => setValue(val as RangeValue)}
                                onOpenChange={handlerDateOpenChange} 
                                onCalendarChange={val => setDates(val as RangeValue)} 
                                disabledDate={disabledDate} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item>
                            <Space>
                                <Button onClick={() => form.resetFields()}>
                                    {locales.pages.gong.saleClientCenter.detail.index.reset}
                                </Button>
                                <Button type='primary' htmlType='submit'>
                                    {locales.pages.gong.saleClientCenter.detail.index.query}
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table2 
                {...{
                    bindModal: bindModalRef.current,
                    communicateModal: communicateModalRef.current,
                    locales, 
                    userNameList, 
                    isAdd, 
                    handlerModal, 
                    monthData, 
                    dataCallList,
                    userName,
                    companyName,
                    handlerToggle,
                    rowTotal,
                }}
            />
            <BindModal 
                ref={bindModalRef} 
                {...{
                    loading, 
                    callDataList, 
                    listData, 
                    currentCallIdx, 
                    setCurrentCallIdx, 
                    callIndex, 
                    setCallIndex, 
                    dataCallList,
                    handlerCallDetail,
                    monthData,
                }}
            />
            <ContactModal
                ref={contactModalRef}
                {...{
                    customerId: match.params.id,
                    locales, 
                    userName, 
                    listData, 
                    handlerCallDetail,
                    callDataList,
                }}
            />
            <CommunicateModal 
                ref={communicateModalRef} 
                {...{
                    customerId: match.params.id,
                    locales,
                    request,
                    getCustomerTrip
                }}
            />
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);