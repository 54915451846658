import React from 'react';
import Udesk from 'Udesk';
import IndexManangementDetailRoute from './route';
import { Form } from "@ant-design/compatible";
import { TreeSelect, Select, Input, Button, Radio, InputNumber, PageHeader } from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import './style.scss';
export default class IndexManagementDeatilTemplate extends IndexManangementDetailRoute {
    render() {
        let { sourceList, scoreRadioList, status, otherFactorsStatus, location } = this.props;
        let { actions, locales } = this;
        let {
            initData,
            conditionJsonInfo,
            sysType,
            otherFactorsList,
            calculateType,
            detailData,
            chartFieldsList,
            judgeStrategy,
            customJudgeLogic,
            isFilterVisible,
            judgeStrategyList,
            customJudgeLogicList,
            conditionList,
            loading
        } = this.privates;
        let { getFieldDecorator } = this.props.form;
        const company = Udesk.data.init.company;
        return (
            // <div className='index-management-tags-detail-page'>
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.fields.IndexManagement.title}
                    onBack={actions.back}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root index-management-tags-detail-page'>
                        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
                            <Form.Item label={locales.fields.IndexManagement.list.indicatorName}>
                                {getFieldDecorator('indicatorName', {
                                    initialValue: detailData.indicatorName || '',
                                    rules: [{ required: true, message: locales.fields.IndexManagement.detail.nameEmpty }]
                                })(<Input disabled={sysType === 1 ? true : false} style={{ width: '300px' }} />)}
                            </Form.Item>
                            <Form.Item label={locales.fields.IndexManagement.detail.descript}>
                                {getFieldDecorator('indicatorDesc', {
                                    initialValue: detailData.indicatorDesc || '',
                                    rules: [{ required: true, message: locales.fields.IndexManagement.detail.descriptEmpty }]
                                })(<Input.TextArea rows={4} cols={20} disabled={sysType === 1 ? true : false} />)}
                            </Form.Item>
                            <Form.Item label={locales.fields.IndexManagement.detail.active}>
                                {getFieldDecorator('activity', {
                                    initialValue: detailData.activity || 0,
                                    rules: [{ required: true, message: locales.fields.IndexManagement.detail.activeEmpty }]
                                })(
                                    <Select style={{ width: '120px' }}>
                                        {
                                            !_isEmpty(status) && status.map(item => {
                                                return (
                                                    <Select.Option value={item.value}>{item.text}</Select.Option>
                                                );
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label={locales.fields.IndexManagement.detail.dataSource}>
                                {getFieldDecorator('inspectDataSource', {
                                    initialValue: detailData.inspectDataSource ? detailData.inspectDataSource : detailData.inspectDataSource === 0 ? 0 : 0,
                                    rules: [{ required: true, message:locales.fields.IndexManagement.detail.dataSourceEmpty }]
                                })(
                                    <Select
                                        disabled={sysType === 1 || !(company.enabledCallInspect !== 0 && company.enabledImInspect !== 0)}
                                        style={{ width: '120px' }}
                                        onChange={actions.inspactionChange}>
                                        {
                                            !_isEmpty(sourceList) && sourceList.map(item => {
                                                return (
                                                    <Select.Option value={item.value}>{item.text}</Select.Option>
                                                );
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label={locales.fields.IndexManagement.detail.grounp}>
                                {getFieldDecorator('groupIds', {
                                    initialValue: (detailData.agentGroups && detailData.agentGroups.map(item => item.id)) || undefined,
                                    rules: [{ required: true, message: locales.fields.IndexManagement.detail.grounpEmpty }]
                                })(
                                    <TreeSelect
                                        showSearch
                                        style={{ width: '200px', marginRight: '10px' }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder={locales.fields.IndexManagement.detail.grounpHolder}
                                        treeData={initData}
                                        allowClear
                                        multiple
                                        treeDefaultExpandAll
                                        onChange={actions.onChangeGrounp}
                                        disabled={sysType === 1 ? true : false}
                                    >
                                    </TreeSelect>
                                )}
                            </Form.Item>
                            <Form.Item label={locales.fields.IndexManagement.detail.type}>
                                {getFieldDecorator('calculateType', {
                                    initialValue: detailData.calculateType || 1,
                                    rules: [{ required: true, message: locales.fields.IndexManagement.detail.typeNone }]
                                })(
                                    <Radio.Group onChange={actions.typeChange} disabled={sysType === 1 ? true : false}>
                                        {
                                            !_isEmpty(scoreRadioList) && scoreRadioList.map(item => {
                                                return (
                                                    <Radio value={item.value}>{item.text}</Radio>
                                                );
                                            })
                                        }
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            {
                                (calculateType === 1 || calculateType === 2) && (
                                    <div>
                                        <Form.Item label={locales.fields.IndexManagement.detail.base}>
                                            {getFieldDecorator('baseScore', {
                                                initialValue: detailData.baseScore || 0,
                                                rules: [{ required: true, message: locales.fields.IndexManagement.detail.baseNone }]
                                            })(<InputNumber min={0} disabled={sysType === 1 ? true : false} />)}
                                        </Form.Item>
                                        <Form.Item label={locales.fields.IndexManagement.detail.positive}>
                                            <span>{locales.enums.tasks.calculateScoreTypes.add}</span>
                                            {getFieldDecorator('positiveFactorsScore', {
                                                initialValue: detailData.positiveFactorsScore || 0,
                                                rules: [{ required: true, message: locales.fields.IndexManagement.detail.positiveNone }]
                                            })(<InputNumber min={0} disabled={sysType === 1 ? true : false} style={{ margin: '0 5px' }} />)}
                                            <span>{locales.labels.scoreUnit}</span>
                                            {
                                                _isEmpty(conditionJsonInfo.positiveFactors) && (
                                                    <Button style={{ marginLeft: '10px' }} onClick={actions.editFilter.params('up', 1, '', [])} >{locales.fields.IndexManagement.detail.customAdd}</Button>
                                                )
                                            }
                                        </Form.Item>
                                        {
                                            !_isEmpty(conditionJsonInfo.positiveFactors) && (
                                                <div className='index-management-custom-filter'>
                                                    <div className='index-management-custom-filter-edit'>
                                                        <span onClick={actions.editFilter.params('up', judgeStrategy.positiveFactors, customJudgeLogic.positiveFactors, conditionJsonInfo.positiveFactors)}>{locales.labels.edit}</span>
                                                    </div>
                                                    <div className='index-management-custom-filter-Info'>
                                                        <div>{locales.components.common.customFilter.customFilterCondition}：</div>
                                                        <div>
                                                            {!_isEmpty(conditionJsonInfo.positiveFactors) && this.actions.fieldsFilterChange({ conditionList: conditionJsonInfo.positiveFactors })}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {locales.components.common.customFilter.conditionRule}：
                                                        <span>
                                                            {
                                                                judgeStrategy.positiveFactors !== 3 && Udesk.enums.operatorRuleTypes.find(current => current.id === judgeStrategy.positiveFactors).name
                                                            }
                                                            {
                                                                judgeStrategy.positiveFactors === 3 && customJudgeLogic.positiveFactors
                                                            }
                                                        </span>
                                                    </div>

                                                </div>

                                            )
                                        }
                                        <Form.Item label={locales.fields.IndexManagement.detail.negative}>
                                            <span>{locales.enums.tasks.calculateScoreTypes.sub}</span>
                                            {getFieldDecorator('negativeFactorsScore', {
                                                initialValue: detailData.negativeFactorsScore || 0,
                                                rules: [{ required: true, message: locales.fields.IndexManagement.detail.negativeNone }]
                                            })(<InputNumber min={0} disabled={sysType === 1 ? true : false} style={{ margin: '0 5px' }} />)}
                                            <span>{locales.labels.scoreUnit}</span>
                                            {
                                                _isEmpty(conditionJsonInfo.negativeFactors) && (
                                                    <Button style={{ marginLeft: '10px' }} onClick={actions.editFilter.params('down', 1, '', [])} >{locales.fields.IndexManagement.detail.customAdd}</Button>
                                                )
                                            }
                                        </Form.Item>
                                        {

                                            !_isEmpty(conditionJsonInfo.negativeFactors) && (
                                                <div className='index-management-custom-filter'>
                                                    <div className='index-management-custom-filter-edit'>
                                                        <span onClick={actions.editFilter.params('down', judgeStrategy.negativeFactors, customJudgeLogic.negativeFactors, conditionJsonInfo.negativeFactors)}>{locales.labels.edit}</span>
                                                    </div>
                                                    <div className='index-management-custom-filter-Info'>
                                                        <div>{locales.fields.IndexManagement.detail.customOK}</div>
                                                        <div>
                                                            {!_isEmpty(conditionJsonInfo.negativeFactors) && this.actions.fieldsFilterChange({ conditionList: conditionJsonInfo.negativeFactors })}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {locales.fields.IndexManagement.detail.judgeStrategy}
                                                        <span>
                                                            {
                                                                judgeStrategy.negativeFactors !== 3 && Udesk.enums.operatorRuleTypes.find(current => current.id === parseInt(judgeStrategy.negativeFactors, 10)).name
                                                            }
                                                            {
                                                                judgeStrategy.negativeFactors === 3 && customJudgeLogic.negativeFactors
                                                            }
                                                        </span>
                                                    </div>
                                                </div>

                                            )

                                        }
                                        <Form.Item label={locales.fields.IndexManagement.detail.other}>
                                            <div>
                                                <Button onClick={actions.otherFactorsADD}>{locales.fields.IndexManagement.detail.otherAdd}</Button>
                                            </div>
                                        </Form.Item>
                                        {
                                            !_isEmpty(otherFactorsList) && otherFactorsList.map(item => {
                                                return (
                                                    <div className='index-management-custom-filter' key={item.key}>
                                                        <div>
                                                            <Select disabled={sysType === 1 ? true : false} style={{ width: '120px' }} defaultValue={item.type} onChange={actions.otherSelelctChange.params(item.key)} >
                                                                {
                                                                    !_isEmpty(otherFactorsStatus) && otherFactorsStatus.map(items => {
                                                                        return (
                                                                            <Select.Option value={items.value}>{items.text}</Select.Option>
                                                                        );
                                                                    })
                                                                }
                                                            </Select>
                                                            <InputNumber min={0} defaultValue={item.otherFactorSocre} style={{ margin: '0 5px' }} onChange={actions.otherInputChange.params(item.key)} />
                                                            <span>{locales.labels.scoreUnit}</span>
                                                            {
                                                                _isEmpty(item.otherFactor.conditionList) && (
                                                                    <Button style={{ marginLeft: '10px' }} onClick={actions.editFilterOhter.params('other', item.key, 1, '', [])} >{locales.fields.IndexManagement.detail.customAdd}</Button>
                                                                )
                                                            }
                                                            <div class="customer-filter-driver-circle-del" onClick={actions.otherFocDel.params(item.key)}><i class="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i></div>
                                                        </div>

                                                        {

                                                            !_isEmpty(item.otherFactor.conditionField) && (
                                                                <div className='index-management-custom-filter index-management-custom-filter-width-other'>
                                                                    <div className='index-management-custom-filter-edit'>
                                                                        <span onClick={actions.editFilterOhter.params('other', item.key, item.otherFactor.judgeStrategy, item.otherFactor.customJudgeLogic, item.otherFactor.conditionField)}>{locales.labels.edit}</span>
                                                                    </div>
                                                                    <div className='index-management-custom-filter-Info'>
                                                                        <div>{locales.fields.IndexManagement.detail.customOK}</div>
                                                                        <div>
                                                                            {!_isEmpty(item.otherFactor.conditionField) && actions.fieldsFilterChange({ conditionList: item.otherFactor.conditionField })}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {locales.fields.IndexManagement.detail.judgeStrategy}
                                                                        <span>
                                                                            {
                                                                                item.otherFactor.judgeStrategy !== 3 && Udesk.enums.operatorRuleTypes.find(current => current.id === parseInt(item.otherFactor.judgeStrategy, 10)).name
                                                                            }
                                                                            {
                                                                                item.otherFactor.judgeStrategy === 3 && item.otherFactor.customJudgeLogic
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                );
                                            })
                                        }

                                    </div>

                                )
                            }
                            {
                                calculateType === 3 && (
                                    <div>
                                        <Form.Item label={locales.fields.IndexManagement.detail.agent}>
                                            {getFieldDecorator('agentChartFieldDto', {
                                                initialValue: (detailData.agentChartFieldDto && detailData.agentChartFieldDto.id) || undefined,
                                                rules: [{ required: !(location.pathname && location.pathname.indexOf('edit/') !== -1) ? true : sysType === 1 ? false : true, message: locales.fix.originalDataCannotEmpty }]
                                            })(
                                                <Select placeholder={locales.labels.pleaseChoice} disabled={sysType === 1 ? true : false} style={{ width: '200px' }}>
                                                    {
                                                        !_isEmpty(chartFieldsList) && chartFieldsList.map(item => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.fieldName}</Select.Option>
                                                            );
                                                        })
                                                    }

                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item label={locales.fields.IndexManagement.detail.section}>
                                            {getFieldDecorator('sectionCount', {
                                                initialValue: detailData.sectionCount || 1,
                                                rules: [{ required: true, message: locales.fields.IndexManagement.detail.sectionNone }]
                                            })(<InputNumber min={1} disabled={sysType === 1 ? true : false} />)}
                                        </Form.Item>
                                        <Form.Item label={locales.fields.IndexManagement.detail.step}>
                                            {getFieldDecorator('stepLength', {
                                                initialValue: detailData.stepLength || 0,
                                                rules: [{ required: true, message: locales.fields.IndexManagement.detail.stepNone }]
                                            })(<InputNumber min={0} max={10000} disabled={sysType === 1 ? true : false} />)}
                                        </Form.Item>
                                    </div>

                                )
                            }
                            <div style={{ marginBottom: '20px', marginLeft: '100px' }}>
                                <Button type='primary' loading={loading} onClick={actions.save} style={{ marginRight: '10px' }}>{locales.fields.IndexManagement.detail.save}</Button>
                                <Button onClick={actions.cancel}>{locales.fields.IndexManagement.detail.cancel}</Button>
                            </div>
                        </Form>

                        <CustomFilterModal
                            visible={isFilterVisible}
                            cancel={actions.handleConditionCancel}
                            applyAction={actions.handleConditionApply}
                            onConditionFilterSaved={actions.onConditionFilterSaved}
                            judgeStrategy={judgeStrategyList}
                            customName={this.privates.customName}
                            customId={this.privates.customId}
                            customJudgeLogic={customJudgeLogicList}
                            conditionList={conditionList}
                            type={this.privates.sourceValue}
                        />
                    </div >
                </div >
            </div >
        );
    }
}
