import React, { useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Driver from 'driver.js';
import SubRoute from 'udesk-react/src/components/sub-routes';
import MainMenu from 'Component/pages/components/main-menu';
import SmartToolBar from 'Component/pages/components/smart-tool-bar';
import UdeskMenuBarSdk from 'Component/common/udesk-menu-bar-sdk';
import UdeskMassageSdk from 'Component/pages/massage-sdk';
import { Icon, Layout, Menu, message, Badge, Popover, Space } from 'udesk-ui';
import { Redirect } from 'react-router-dom';
import { getMessageUserNotificationsUnreadCount } from 'src/api/messageUserNotifications/unreadCount';
import { getCallSentenceClusterResultsWarn } from 'src/api/call-sentence-cluster-results/warn';
import { enableSale } from 'Udesk/business';
import { useLiveEffect } from 'src/util/hook';
import { getCurrentRouteName, isAlgorithm, isCoach, isConversationAnalysis, isGeneralSetting, isQaSubApp, isSalesSubApp } from 'Udesk/system/subApp';
import { SubAppEntries } from './components/SubAppEntries';
import { BackToSubSystem } from 'Component/common/BackToSubSystem';
import { hasAllFeature, hasAnyFeature } from 'src/util/permit';
import { getUsersBySystemModuleActiveRecord } from 'src/api/users/{systemModule}/active/record';
import { useRequest } from 'src/util/hook';
import 'driver.js/dist/driver.min.css';
import './style.scss';
import { fire } from 'src/util/core';
import { getReviewStudyRemindNumber } from 'src/api/review/studyRemind/number';
import { isOperator } from 'src/util/isOperator';

export const MsgCenterContext = React.createContext();
export const CallSentenceClusterResultsWarnContext = React.createContext();
export const HistoricalLearningRenderCountContext = React.createContext();
class Home extends React.Component {
    privates = {
        collapsed: true,
        navLists: [],
        SmartToolBarRef: React.createRef(),
        massageSdkRef: React.createRef(),
        userId: null,
        enabledMessage: false,
        showWarning: false,
    };
    state = {
        REPLY: 0,
        AT_ME: 0,
        SHARE: 0,
        SUBSCRIPTION: 0,
        totalNum: 0,
        showSentenceClusterResultsWarn: false,
        threshold: 100,
        historicalLearningRenderCount: 0,
    };

    isRedirect(currentPath, toPath) {
        if (currentPath === toPath.replace(/\/$/g, '')) {
            return true;
        }
        return false;
    }

    actions = {
        updateHistoricalLearningRenderCount(callback) {
            this.setState({
                historicalLearningRenderCount: this.state.historicalLearningRenderCount + 1
            }, () => {
                initNavLists(this);
                fire(callback);
            });
        },
        getUnreadCount(flag) {
            if (flag) {
                this.timer = setTimeout(() => {
                    this.actions.getUnreadCount(true);
                }, 20000);
            }

            return Promise.all([
                getReviewStudyRemindNumber(),
                getMessageUserNotificationsUnreadCount()
            ]).then(
                resp => {
                    const STUDY = resp[0].data || 0;
                    const data = resp[1].data;
                    const state = {
                        STUDY,
                        REPLY: data?.REPLY ?? 0,
                        AT_ME: data?.AT_ME ?? 0,
                        SHARE: data?.SHARE ?? 0,
                        SUBSCRIPTION: data?.SUBSCRIPTION ?? 0,
                        totalNum:
                            STUDY +
                            (data?.REPLY ?? 0) +
                            (data?.AT_ME ?? 0) +
                            (data?.SHARE ?? 0) +
                            (data?.SUBSCRIPTION ?? 0),
                    };
                    this.setState(state);

                    return state;
                },
                () => {
                    clearTimeout(this.timer);
                }
            );
        },
        toggleCollapsed() {
            const { actions, privates } = this;
            privates.collapsed = !privates.collapsed;
            actions.update();
        },
        transitionToPage(item) {
            const { locales, props } = this;
            const navItem = item.item.props._navitem;
            if (!navItem) return;

            if (navItem.justAction) {
                if (navItem.actionName === 'gotoInsight') {
                    const url = Udesk.business.apiPath.concatApiPath('/access/insight', props.sdkOptions);
                    Udesk.ajax.get(url).then(
                        (resp) => {
                            window.open(resp.url, '_blank');
                        },
                        (reason) => {
                            message.error(locales.business.info.getInsightAuthFailed);
                        }
                    );
                }
            } else {
                const routeOptions = {
                    history: this.props.history,
                    routeName: navItem.pathName,
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        },
        getActive(data) {
            const { location } = this.props;
            const defaultSelected = [];
            const addActiveKey = (nav) => {
                if (nav) {
                    if (nav.isActive) {
                        if (nav.isActive(null, location)) {
                            defaultSelected.push(nav.pathName);
                        }
                    }
                }
            };
            if (data && data.length > 0) {
                data.forEach(item => {
                    const { subNavList } = item;
                    if (subNavList) {
                        subNavList.forEach(addActiveKey);
                    } else {
                        addActiveKey(item);
                    }
                });
            }
            return defaultSelected;
        },
        sdkTransitionTo({ routeName, basePath, subPath, pathParams, queryParams, state }) {
            if (basePath === 'tasks.manage.template.check-point.index') {
                const routeOptions = {
                    history: this.props.history,
                    routeName: 'tasksCheckPointIndex',
                    pathParams: { taskId: pathParams[0] },
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            } else if (basePath === 'tasks.manage.workbench.quality-inspection-list.index') {
                const routeOptions = {
                    history: this.props.history,
                    routeName: 'qualityInspectionListIndex',
                    pathParams: { taskId: pathParams[0] },
                    state: state,
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            } else {
                const routeOptions = {
                    history: this.props.history,
                    routeName: routeName,
                    pathParams: pathParams,
                    queryParams: queryParams,
                    state: state,
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        },
        requestSentenceClusterWarn() {
            getCallSentenceClusterResultsWarn().then((resp) => {
                this.setState({ showSentenceClusterResultsWarn: enableSale() ? false : resp.data.isNeedWarn, threshold: resp.data.threshold }, () => {
                    initNavLists(this);
                });
            });
        },
    };
    init() {
        const { props } = this;

        // initNavLists(this);

        this.privates.userId = Udesk.data.init.user.id;
        this.privates.enabledMessage = !!props?.sdkOptions?.props._company.enabledMessage;
        props.sdkOptions.methods.transitionTo = this.actions.sdkTransitionTo;
    }
    // model() {
    //     const { props, locales } = this;
    //     return new Promise((resolve, reject) => {
    //         Udesk.ajax.get(Udesk.business.apiPath.concatApiPath('/isLogin', props.sdkOptions)).then(
    //             (resp) => {
    //                 Udesk.ajax.get(Udesk.business.apiPath.concatApiPath('/init', props.sdkOptions)).then(
    //                     (resp) => {
    //                         resolve(resp);
    //                         window.localStorage.setItem('userName', resp.data.user.email);
    //                         window.uAnalytics.identify(`${resp.data.user.id}`, {
    //                             id: resp.data.user.id,
    //                             admin: resp.data.user.admin,
    //                             nickname: resp.data.user.id,
    //                             email: resp.data.user.email,
    //                             createTime: resp.data.user.createTime,
    //                             callTemplateId: resp.data.user.company.associateCallTemplateId,
    //                             imTemplateId: resp.data.user.company.associateImTemplateId,
    //                             companyCreateTime: resp.data.user.company.createTime,
    //                             companyId: resp.data.user.company.id,
    //                             companyName: resp.data.user.company.name,
    //                             companySysName: resp.data.user.company.sysName,
    //                         });
    //                         //模拟通过SDK嵌入
    //                         // window.udeskQaMassageSdkInstance.createNewLink();
    //                         this.context.props.language = 'EN-US';
    //                     },
    //                     (reason) => {
    //                         Udesk.logger.error(reason);
    //                         Udesk.ui.notify.error({
    //                             message: reason.errorMsg || locales.labels.initSystemError,
    //                             duration: 0,
    //                         });
    //                         reject();
    //                     }
    //                 );
    //             },
    //             (reason) => {
    //                 if (reason.status === Udesk.system.apiCodes.unauthenticated) {
    //                     let referUrl = encodeURIComponent(window.location.pathname + window.location.search);
    //                     window.location.href = `/auth/login?refer=${referUrl}`;
    //                     // 因为要作页面跳转，所以不用reject了，否则会在底层弹错误提示
    //                     // reject();
    //                 } else {
    //                     Udesk.logger.error(reason);
    //                     Udesk.ui.notify.error({
    //                         message: reason.errorMsg || locales.labels.initSystemError,
    //                         duration: 0,
    //                     });
    //                     reject();
    //                 }
    //             }
    //         );
    //     });
    // }
    // onModelResolved(model) {
    //     const { sdkOptions } = this.props;
    //     this.privates.userId = model.data.user.id;
    //     this.privates.enabledMessage = !!model.data.company.enabledMessage;
    //     Udesk.data.buildInitData(model.data);
    //     Udesk.data.buildUser(model.data.user, model.data);
    //     Udesk.data.buildCompany(model.data);

    //     initNavLists(this);
    //     sdkOptions.props.biDomain = Udesk.data.init.biDomain;
    //     sdkOptions.props.biToken = Udesk.data.init.biToken;
    //     sdkOptions.props.biUser = Udesk.data.init.user;
    //     sdkOptions.props.callCustom = Udesk.data.init.callCustom;
    //     sdkOptions.props.imCustom = Udesk.data.init.imCustom;
    //     sdkOptions.props.defaultSearchTemplate = (Udesk.data.init.defaultSearchTemplate || []).map(i => ({...i, id: `templateType-${i.templateType}`}));

    //     sdkOptions.methods.transitionTo = this.actions.sdkTransitionTo;
    // }
    componentDidMount() {
        if (isSalesSubApp()) {
            this.actions.getUnreadCount(true);
        }
        if (isCoach()) {
            getUsersBySystemModuleActiveRecord({
                segments: {
                    systemModule: Udesk.enums.sysModuleList.coach.id
                }
            });
        }

        initNavLists(this);
        this.props.$UdeskContext$.updateProps({
            SmartToolBarRef: this.privates.SmartToolBarRef,
            massageSdkRef: this.privates.massageSdkRef,
        });
        this.actions.update();

        if (this.privates.userId) {
            setTimeout(() => {
                this.privates.massageSdkRef &&
                    this.privates.massageSdkRef.current &&
                    this.privates.massageSdkRef.current.actions.setAgentProperties({
                        userId: this.privates.userId,
                        disable: !this.privates.enabledMessage,
                    });
            }, 1000);
        }

        const url = Udesk.business.apiPath.concatApiPath('/account/charge', this.props.sdkOptions);
        Udesk.ajax.get(url).then((resp) => {
            let enabledImInspect = resp.data.enabledImInspect;
            let enabledCallInspect = resp.data.enabledCallInspect;
            let deadlineSecond = resp.data.deadline.replace(/-/g, '/') + ' 00:00:00';
            deadlineSecond = new Date(deadlineSecond).getTime();
            let surplusSecond = ((deadlineSecond - new Date().getTime()) / 86400000).toFixed(1);
            let callWarning = enabledCallInspect && resp.data.balance * 1 < (resp.data.callReminderHour || 50);
            let ImWarning = enabledImInspect && surplusSecond < (resp.data.imReminderDay || 15);
            this.privates.showWarning = callWarning || ImWarning;
            this.actions.update();
            this.props.$UdeskContext$.updateProps({
                showBillingWarning: this.privates.showWarning,
                accountCharge: {
                    balance: resp.data.balance * 1,
                    deadline: resp.data.deadline,
                    enabledCallInspect,
                    enabledImInspect,
                    callWarning,
                    ImWarning,
                },
            });
        });
        this.actions.requestSentenceClusterWarn();
        if (document.getElementById('udeskQaWebSiteLogo') && !localStorage.getItem('hasShowedUdeskQaWebSiteLogoDriver')) {
            const driver = new Driver({
                allowClose: false,
                closeBtnText: /* 关闭 */UdeskLocales['current'].pages.home.index.close,
                onReset: () => {
                    localStorage.setItem('hasShowedUdeskQaWebSiteLogoDriver', '1');
                }
            });
            driver.highlight({
                element: '#udeskQaWebSiteLogo',
                popover: {
                    title: /* 切换系统应用 */UdeskLocales['current'].pages.home.index.switchingSystemApplications,
                    description: /* 鼠标移动至此处可唤出应用切换菜单，点击菜单项跳转不同应用 */UdeskLocales['current'].pages.home.index.moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications,
                },
            });
        }

    }
    componentDidUpdate() { }
    componentWillUnmount() {
        this.timer = null;
    }

    render() {
        const { actions, privates, props } = this;
        const { routes, route, location, sdkOptions, history } = this.props;

        let allPath = [];
        getAllRoutePath(route, allPath);
        let notFoundThisPage = true;
        // let currentPath = location.pathname.split('/').filter(Boolean);
        let currentPath = location.pathname.split('/');
        if (currentPath[currentPath.length - 1]) {
            currentPath = currentPath.filter(Boolean);
            let targetPathes = allPath.filter((path) => path.length === currentPath.length);
            if (allPath.length) {
                for (let j = 0; j < targetPathes.length; j++) {
                    let targetPath = targetPathes[j];
                    let targetPathEnd = false;
                    for (let i = 0; i < targetPath.length; i++) {
                        if (targetPath[i] && !targetPath[i].includes(':')) {
                            if (targetPath[i] !== currentPath[i]) {
                                break;
                            }
                        }
                        if (i === targetPath.length - 1) {
                            targetPathEnd = true;
                        }
                    }
                    if (targetPathEnd) {
                        notFoundThisPage = false;
                        break;
                    }
                }
            }
        }
        //客服报表、舆情发现页面 bi项目自带路由，需要做特殊处理
        if (notFoundThisPage && !location.pathname.includes('/reports/agent-report') && !location.pathname.includes('/public-sentiment/analysis')) {
            return <Redirect to='/site/404' sdkOptions={sdkOptions} />;
        }
        if (privates.navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: privates.navLists[0].pathName,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <React.Fragment>
                    <CallSentenceClusterResultsWarnContext.Provider
                        value={{
                            showSentenceClusterResultsWarn: this.state.showSentenceClusterResultsWarn,
                            requestSentenceClusterWarn: this.actions.requestSentenceClusterWarn,
                            threshold: this.state.threshold,
                        }}
                    >
                        <HistoricalLearningRenderCountContext.Provider value={this.actions.updateHistoricalLearningRenderCount}>         
                            <Layout className='udesk-qa-web-home-page'>
                                <Layout.Sider
                                    theme={'light'}
                                    className='udesk-qa-web-home-page-sider'
                                    trigger={null}
                                    collapsedWidth={64}
                                    collapsed={privates.collapsed}
                                    width={Udesk.data.init.userLanguage === 'en' ? 300 : 200}
                                >
                                    <Popover overlayClassName='udesk-qa-sub-app-entries-popover' placement="rightTop" content={<SubAppEntries history={history} />} trigger="hover" arrowPointAtCenter={true}>
                                        <div className='title' onClick={() => {
                                            const routeOptions = {
                                                history: props.history,
                                                routeName: 'appEntry',
                                            };
                                            Udesk.ui.routing.transitionTo(routeOptions);
                                        }}>
                                            <div className='logo' id='udeskQaWebSiteLogo'>
                                                <img
                                                    src={
                                                        Udesk.data.init.company.logoType
                                                            ? Udesk.data.init.company.logo ?? '/static/images/qa-logo.png'
                                                            : '/static/images/qa-logo.png'
                                                    }
                                                    alt=''
                                                />
                                            </div>
                                            {isSalesSubApp()
                                                ? !privates.collapsed && (
                                                    <div title={Udesk.data.init.company.name} className='name'>
                                                        {Udesk.data.init.company.name ||
                                                        UdeskLocales.current.labels.salesEnable}
                                                    </div>
                                                )
                                                : !privates.collapsed && (
                                                    <div title={Udesk.data.init.company.name} className='name'>
                                                        {Udesk.data.init.company.name ||
                                                        UdeskLocales.current.labels.websiteName}
                                                    </div>
                                                )}
                                        </div>
                                    </Popover>
                                    <Menu
                                        theme='light'
                                        mode='inline'
                                        selectedKeys={actions.getActive(privates.navLists)}
                                        onClick={actions.transitionToPage}
                                    >
                                        {privates.navLists &&
                                        privates.navLists.length > 0 &&
                                        privates.navLists.map((item, index) => {
                                            return (
                                                item.subNavList ? (
                                                    <Menu.SubMenu title={
                                                        <Badge dot={item.dot} offset={[4, 9]}>
                                                            <Icon type={item.icon} style={{ fontSize: 20, lineHeight: '40px' }} />
                                                            <span className='menu-title'>{item.text}</span>
                                                        </Badge>
                                                    }>
                                                        {
                                                            item.subNavList.map(item => (
                                                                <Menu.Item key={item.pathName || item.dataIndex} _navitem={item}>
                                                                    <span className='menu-title'>{item.text}</span>
                                                                </Menu.Item>
                                                            ))
                                                        }
                                                    </Menu.SubMenu>
                                                ) : (
                                                    <Menu.Item key={item.pathName || item.dataIndex} _navitem={item}>
                                                        <Badge dot={item.dot} offset={[4, 9]}>
                                                            <Icon type={item.icon} style={{ fontSize: 20, lineHeight: '40px' }} />
                                                            <span className='menu-title'>{item.text}</span>
                                                        </Badge>
                                                    </Menu.Item>
                                                )
                                            );
                                        })}
                                    </Menu>
                                </Layout.Sider>
                                <Layout>
                                    <Layout.Header className='udesk-qa-web-home-page-header'>
                                        <Space>
                                            {privates.collapsed ? (
                                                <Icon
                                                    type='ic-menuopen-zhankaicebianlan'
                                                    className='collapsed-icon'
                                                    onClick={actions.toggleCollapsed}
                                                />
                                            ) : (
                                                <Icon
                                                    type='ic-menufold-shouqicebianlan1'
                                                    className='collapsed-icon'
                                                    onClick={actions.toggleCollapsed}
                                                />
                                            )}
                                            {(isAlgorithm() || isGeneralSetting())
                                            && (<BackToSubSystem history={this.props.history} />)}
                                        </Space>
                                        <MainMenu {...this.state} {...this.props} showWarning={privates.showWarning} />
                                    </Layout.Header>
                                    <Layout.Content>
                                        <MsgCenterContext.Provider value={actions.getUnreadCount.bind(this, false)}>
                                            <div style={{ height: '100%' }}>
                                                {routes.map((routeItem, i) => (
                                                    <SubRoute
                                                        key={i}
                                                        route={routeItem}
                                                        SmartToolBarRef={privates.SmartToolBarRef}
                                                    />
                                                ))}
                                            </div>
                                        </MsgCenterContext.Provider>
                                    </Layout.Content>
                                </Layout>
                            </Layout> 
                        </HistoricalLearningRenderCountContext.Provider>
                    </CallSentenceClusterResultsWarnContext.Provider>
                    <SmartToolBar ref={privates.SmartToolBarRef} />
                    {
                        isQaSubApp() && (
                            <React.Fragment>
                                <UdeskMassageSdk ref={privates.massageSdkRef} userId={privates.userId} />
                            </React.Fragment>
                        )
                    }
                    <UdeskMenuBarSdk />
                    {/* <UdeskMassageSdk /> */}
                </React.Fragment >
            );
        }
    }
}

function initNavLists(that) {
    const { locales, privates, props, state, actions } = that;
    const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    const company = Udesk.data.init.company ? Udesk.data.init.company : null;

    const addCommonRoutes = () => {
        if (hasFeature && hasFeature('smart:root')) {
            navLists.push({
                text: locales.menu.tasks.intelligentAnalysis.text,
                icon: 'zhinengfenxi',
                pathName: getCurrentRouteName('intelligentAnalysis'),
                isActive: (match, location) => {
                    return location.pathname.includes('/intelligent-analysis');
                },
            });
        }
        // if (hasFeature && hasFeature('semantic:root')) {
        //     navLists.push({
        //         text: locales.menu.tasks.semanticIntelligence.text,
        //         icon: 'yuyizhineng',
        //         pathName: isQaSubApp() ? 'semanticIntelligence' : 'salesSemanticIntelligence',
        //         isActive: (match, location) => {
        //             return location.pathname.includes('/semantic-intelligence');
        //         },
        //     });
        // }
    };

    const navLists = [];
    isQaSubApp(() => {
        if (hasFeature && hasFeature('task:root') && !(Udesk.data.init.company.id === 309)) {
            navLists.push({
                text: locales.pages.dashboard.title,
                icon: 'ic-home',
                pathName: 'dashboard',
                isActive: (match, location) => {
                    return location.pathname.includes('/dashboard');
                },
            });
        }
        if (hasFeature && hasFeature('task:root') && !(Udesk.data.init.company.id === 309)) {
            navLists.push({
                text: locales.menu.tasks.workbench.text,
                icon: 'gongzuotai',
                pathName: 'tasksIndex',
                isActive: (match, location) => {
                    return (
                        location.pathname.includes('/site/tasks') ||
                        location.pathname.includes('/information-collection') ||
                        location.pathname.includes('/information-collection-create')
                    );
                },
            });
        }
        addCommonRoutes();
        if (hasFeature && hasFeature('semantic:root')) {
            navLists.push({
                text: locales.menu.tasks.semanticIntelligence.text,
                icon: 'yuyizhineng',
                pathName: 'semanticIntelligence',
                isActive: (match, location) => {
                    return location.pathname.includes('/semantic-intelligence');
                },
            });
        }
        if (hasFeature && hasFeature('call:root') && company && company.enabledCallInspect !== 0) {
            navLists.push({
                text: locales.menu.tasks.callManage.text,
                icon: 'tonghuaguanli',
                pathName: 'callManage',
                isActive: (match, location) => {
                    return location.pathname.includes('/call-manage');
                },
            });
        }
        if (hasFeature && hasFeature('im:root') && company && company.enabledImInspect !== 0) {
            navLists.push({
                text: locales.menu.tasks.dialogueManage.text,
                icon: 'duihuaguanli',
                pathName: 'dialogueManage',
                isActive: (match, location) => {
                    return location.pathname.includes('/dialogue-manage');
                },
            });
        }
        if (hasFeature && hasFeature('ticket:root') && company && company.enabledTicket !== 0) {
            navLists.push({
                text: locales.menu.tasks.ticketManage.text,
                icon: 'ic-gongdan',
                pathName: 'ticketManage',
                isActive: (match, location) => {
                    return location.pathname.includes('/ticket-manage');
                },
            });
        }
        if (hasFeature && hasFeature('mallcall:root') && company && company.enabledMallInspect !== 0) {
            navLists.push({
                text: locales.pages.home.index.ecommerceSessionManagement,
                icon: 'duihuakuang',
                pathName: 'eCommerceManage',
                isActive: (match, location) => {
                    return location.pathname.includes('/e-commerce-manage');
                },
            });
        }
        if (hasFeature && hasFeature('data:analysis:root')) {
            navLists.push({
                text: locales.menu.tasks.dataAnalysis.text,
                icon: 'shujufenxi',
                pathName: 'dataAnalysis',
                isActive: (match, location) => {
                    return location.pathname.includes('/data-analysis');
                },
            });
        }
        if (hasFeature && hasFeature('agent:root')) {
            navLists.push({
                text: locales.menu.tasks.customerService.text,
                icon: 'kefuzhongxin',
                pathName: 'customerService',
                isActive: (match, location) => {
                    return location.pathname.includes('/customer-service');
                },
            });
        }
        if (hasFeature && hasFeature('training:root')) {
            navLists.push({
                text: locales.menu.tasks.trainingCenter.text,
                icon: 'xunlianzhongxin',
                pathName: 'trainingCenter',
                isActive: (match, location) => {
                    return location.pathname.includes('/training-center');
                },
                dot: state.showSentenceClusterResultsWarn,
            });
        }
        if (hasFeature && hasFeature('customer:root')) {
            navLists.push({
                text: locales.menu.tasks.clientCenter.text,
                icon: 'ic-business-cardzhongxin1-02',
                pathName: 'clientCenterEntry',
                isActive: (match, location) => {
                    return location.pathname.includes('/client-center');
                },
            });
        }
        if (hasFeature && hasFeature('sys:module:root')) {
            navLists.push({
                text: locales.menu.tasks.templates.text,
                icon: '2021-03-23_mozuguanli',
                pathName: 'templateComponent',
                isActive: (match, location) => {
                    return location.pathname.includes('/template-component');
                },
            });
        }
        if (company && company.enabledWeComInspect !== 0) {
            navLists.push({
                text: locales.menu.tasks.wechatQaTasks.text,
                icon: 'qiyeweixingongzuotai',
                pathName: 'wechatQaTasksIndex',
                isActive: (match, location) => {
                    return (
                        location.pathname.includes('/wechat-qa/tasks') ||
                        location.pathname.includes('/wechat-qa/task/manager')
                    );
                },
            });
        }
        if (company && company.enabledWeComInspect !== 0) {
            navLists.push({
                text: locales.menu.tasks.wechatQaSetting.text,
                icon: 'qiyeweixinzhijianpeizhi',
                pathName: 'wechatQaConversationSetting',
                isActive: (match, location) => {
                    return location.pathname.includes('/wechat-qa/setting');
                },
            });
        }
        if (company && company.enabledWeComInspect !== 0) {
            navLists.push({
                text: locales.menu.tasks.wechatQaChartRecord.text,
                icon: 'qiyeweixinhuihua',
                pathName: 'wechatQaChartRecordDetail',
                isActive: (match, location) => {
                    return location.pathname.includes('/wechat-qa/chart-record');
                },
            });
        }
        if (company && company.enabledWeComInspect !== 0) {
            navLists.push({
                text: locales.menu.tasks.wechatStaffCenter.text,
                icon: 'account-zhanghao',
                pathName: 'wechatQaStaffCenterEntry',
                isActive: (match, location) => {
                    return location.pathname.includes('/wechat-qa/staff-center');
                },
            });
        }
        if (hasFeature && hasFeature('insight:root')) {
            navLists.push({
                text: locales.menu.tasks.insight.text,
                icon: 'Insight',
                justAction: true,
                actionName: 'gotoInsight',
                dataIndex: 'Insight',
                isActive: (match, location) => {
                    return false;
                },
            });
        }
        privates.navLists = navLists;
        actions.update();
    });
    isSalesSubApp(() => {

        // gong 首页
        if (hasFeature && hasFeature('homePage:root')) {
            navLists.push({
                text: locales.menu.tasks.homePage.text,
                icon: 'ic-home',
                pathName: 'homePage',
                isActive: (match, location) => {
                    return location.pathname.includes('/home-page');
                },
            });
        }
        if (hasFeature && hasFeature('analysis:root')) {
            navLists.push({
                text: /* 团队分析 */UdeskLocales['current'].pages.home.index.teamAnalysis,
                icon: 'yonghuzhongxin',
                pathName: 'analysisEnable',
                isActive: (match, location) => {
                    return location.pathname.includes('/analysis-enable');
                },
            });
        }
        if (hasFeature && hasFeature('session:root')) {
            navLists.push({
                text: locales.menu.tasks.callAnalysis.text,
                icon: 'ic-huihuafenxi',
                pathName: 'callAnalysisList',
                isActive: (match, location) => {
                    return (
                        location.pathname.includes('/call-analysis/call-list') ||
                        location.pathname.includes('/call-analysis/call-detail')
                    );
                },
            });
        }
        if (hasFeature && hasFeature('customer:list:root')) {
            navLists.push({
                text: locales.menu.tasks.customer.text,
                icon: 'ic-contacts1',
                pathName: 'saleClientList',
                isActive: (match, location) => {
                    return location.pathname.includes('/sale-client-center');
                },
            });
        }
        addCommonRoutes();
        navLists.push({
            text: /* 学习中心 */UdeskLocales['current'].pages.home.index.learningCenter,
            icon: 'xuexichengji',
            pathName: 'salesLearningCenter',
            isActive: (match, location) => {
                return location.pathname.includes('/learning-center');
            },
        });
        if (hasFeature && hasFeature('help:root')) {
            navLists.push({
                text: locales.menu.tasks.coachTask.text,
                icon: 'ic-fuzhurenwu',
                pathName: 'coachTaskList',
                isActive: (match, location) => {
                    return location.pathname.includes('/coach-task/list');
                },
            });
        }
        navLists.push({
            text: /* 业务配置 */UdeskLocales['current'].pages.home.index.businessConfiguration,
            icon: 'xitongpeizhi2',
            pathName: 'salesBusinessConfigurationEntry',
            isActive: (match, location) => {
                return location.pathname.includes('/business-configuration');
            },
        });
        // if (hasFeature && hasFeature('semantic:root')) {
        //     navLists.push({
        //         text: locales.menu.tasks.semanticIntelligence.text,
        //         icon: 'yuyizhineng',
        //         pathName: 'salesSemanticIntelligence',
        //         isActive: (match, location) => {
        //             return location.pathname.includes('/semantic-intelligence');
        //         },
        //     });
        // }

        // navLists.push({
        //     text: '训练中心',
        //     icon: 'xunlianzhongxin',
        //     pathName: 'salesTrainingCenter',
        //     isActive: (match, location) => {
        //         return location.pathname.includes('/training-center');
        //     },
        // });

        // if (hasFeature && hasFeature('group:root')) {
        //     navLists.push({
        //         text: locales.menu.tasks.teamAnalysis.text,
        //         icon: 'yonghuzhongxin',
        //         pathName: 'teamAnalysis',
        //         isActive: (match, location) => {
        //             return location.pathname.includes('/team-analysis');
        //         },
        //     });
        // }


        privates.navLists = navLists;
        actions.update();
    });
    isAlgorithm(() => {
        navLists.push({
            text: /* 语义智能 */UdeskLocales['current'].pages.home.index.semanticIntelligence,
            icon: 'yuyizhineng',
            pathName: 'algorithmSemanticIntelligence',
            isActive: (match, location) => {
                return location.pathname.includes('/semantic-intelligence');
            },
        });
        if (hasFeature && hasFeature('training:root')) {
            navLists.push({
                text: locales.menu.tasks.trainingCenter.text,
                icon: 'xunlianzhongxin',
                pathName: 'algorithmTrainingCenter',
                isActive: (match, location) => {
                    return location.pathname.includes('/training-center');
                },
                dot: state.showSentenceClusterResultsWarn,
            });
        }
        privates.navLists = navLists;
        actions.update();
    });
    isGeneralSetting(() => {
        navLists.push({
            text: /* 权限管理 */UdeskLocales['current'].pages.home.index.permissionManagement,
            icon: 'jiaosequanxian',
            pathName: 'generalSettingPermissionManagement',
            isActive: (match, location) => {
                return location.pathname.includes('/permission-management');
            },
        });
        navLists.push({
            text: /* 数据对接 */UdeskLocales['current'].pages.home.index.dataConnection,
            icon: 'docking',
            pathName: 'generalSettingDataDocking',
            isActive: (match, location) => {
                return location.pathname.includes('/docking');
            },
        });
        privates.navLists = navLists;
        actions.update();
    });
    isCoach(() => {
        hasAllFeature(['intelligentpartner:homepage:admin:root'], () => {
            navLists.push({
                text: /* 总览 */UdeskLocales['current'].pages.home.index.overview,
                icon: 'ic-home',
                pathName: getCurrentRouteName('dashboard'),
                isActive: (match, location) => {
                    return location.pathname.includes('/dashboard');
                },
            });
        });
        hasAllFeature(['intelligentpartner:homepage:student:root'], () => {
            navLists.push({
                text: /* 个人看板 */UdeskLocales['current'].pages.home.index.personalSignage,
                icon: 'ic-home',
                pathName: getCurrentRouteName('studDashboard'),
                isActive: (match, location) => {
                    return location.pathname.includes('/stud-dashboard');
                },
            });
        });
        hasAllFeature(['intelligentpartner:courselist:root'], () => {
            navLists.push({
                text: /* 课程管理 */UdeskLocales['current'].pages.home.index.courseManagement,
                icon: 'kecheng',
                pathName: 'courseManagementEntry',
                isActive: (match, location) => {
                    return location.pathname.includes('/course-management');
                },
            });
        });
        hasAnyFeature([
            'intelligentpartner:tasklist:root'
        ], () => {
            const subNavList = [];
            hasAllFeature(['intelligentpartner:tasklist:root'], () => {
                subNavList.push({
                    text: /* 任务列表 */UdeskLocales['current'].pages.home.index.taskList,
                    pathName: 'coachTaskCenterList',
                    isActive: (match, location) => {
                        return location.pathname.includes('/task-center/list');
                    }
                });
            });

            subNavList.push({
                text: /* 人工审核 */UdeskLocales['current'].pages.coach.taskCenter.audit.index.manualReview,
                pathName: getCurrentRouteName('taskCenterAudit'),
                isActive: (match, location) => {
                    return location.pathname.includes('/task-center/audit');
                },
            });

            subNavList.push({
                text: /* 人工复核 */UdeskLocales['current'].pages.coach.taskCenter.review.index.manualReview,
                pathName: getCurrentRouteName('taskCenterReview'),
                isActive: (match, location) => {
                    return location.pathname.includes('/task-center/review');
                },
            });

            navLists.push({
                text: UdeskLocales['current'].pages.home.index.taskCenter,
                icon: 'daibanrenwu',
                pathName: 'coachLearningCenter',
                subNavList,
            });
        });
        hasAllFeature(['intelligentpartner:courselist:root'], () => {
            navLists.push({
                text: /* 标签管理 */UdeskLocales['current'].pages.home.index.labelManagement,
                icon: 'biaoqian',
                pathName: 'customerTagsManagement',
                isActive: (match, location) => {
                    return location.pathname.includes('/customer-tags-management');
                },
            });
        });
        hasAllFeature(['intelligentpartner:coursecenter:list:root'], () => {
            navLists.push({
                text: /* 课程中心 */UdeskLocales['current'].pages.home.index.curriculum,
                icon: 'kecheng',
                pathName: 'coachLearningCenterCourse',
                isActive: (match, location) => {
                    return location.pathname.includes('/learning-center/course/view/list');
                },
            });
        });
        hasAnyFeature([
            'intelligentpartner:practicetask:root',
            'intelligentpartner:examtask:root'
        ], () => {
            navLists.push({
                text: /* 我的任务 */UdeskLocales['current'].pages.home.index.task,
                icon: 'daibanrenwu',
                pathName: 'coachLearningCenterTask',
                isActive: (match, location) => {
                    return location.pathname.includes('/learning-center/task');
                },
            });
        });
        hasAnyFeature([
            'intelligentpartner:history:root'
        ], () => {
            const subNavList = [];
            hasAllFeature(['intelligentpartner:history:root'], () => {
                subNavList.push({
                    text: /* 历史记录 */<HistoricalLearning sdkOptions={props.sdkOptions} count={state.historicalLearningRenderCount}/>,
                    pathName: 'coachLearningCenterRecord',
                    isActive: (match, location) => {
                        return location.pathname.includes('/learning-center/record/list');
                    },
                });
            });

            subNavList.push({
                text: /* 错题集 */UdeskLocales['current'].pages.home.index.wrongQuestionSet,
                pathName: getCurrentRouteName('wrongQuestionSetList'),
                isActive: (match, location) => {
                    return location.pathname.includes('/learning-center/wrong-question-set');
                },
            });

            navLists.push({
                text: /* 能力提升 */UdeskLocales['current'].pages.home.index.abilityImprovement,
                icon: 'zhinengfenxi',
                pathName: 'coachLearningCenter',
                subNavList,
            });
        });

        privates.navLists = navLists;
        actions.update();
    });
    isConversationAnalysis(() => {
        if (hasFeature && hasFeature('data:analysis:root')) {
            navLists.push({
                text: locales.menu.tasks.dataAnalysis.text,
                icon: 'shujufenxi',
                pathName: 'conversationAnalysisDataAnalysis',
                isActive: (match, location) => {
                    return location.pathname.includes('/data-analysis');
                },
            });
        }
        if (hasFeature && hasFeature('customer:root')) {
            navLists.push({
                text: locales.menu.tasks.clientCenter.text,
                icon: 'ic-business-cardzhongxin1-02',
                pathName: 'conversationAnalysisClientManage',
                isActive: (match, location) => {
                    return location.pathname.includes('/client-center');
                },
            });
        }
        navLists.push({
            text: /* 语义智能 */UdeskLocales['current'].pages.home.index.semanticIntelligence,
            icon: 'yuyizhineng',
            pathName: 'conversationAnalysisSemanticIntelligence',
            isActive: (match, location) => {
                return location.pathname.includes('/semantic-intelligence');
            },
        });
        if (hasFeature && hasFeature('training:root')) {
            navLists.push({
                text: locales.menu.tasks.trainingCenter.text,
                icon: 'xunlianzhongxin',
                pathName: 'conversationAnalysisTrainingCenter',
                isActive: (match, location) => {
                    return location.pathname.includes('/training-center');
                },
                dot: state.showSentenceClusterResultsWarn,
            });
        }
        if (hasFeature && hasFeature('call:root') && company && company.enabledCallInspect !== 0) {
            navLists.push({
                text: locales.menu.tasks.callManage.text,
                icon: 'tonghuaguanli',
                pathName: 'conversationAnalysisCallManage',
                isActive: (match, location) => {
                    return location.pathname.includes('/call-manage');
                },
            });
        }
        if (hasFeature && hasFeature('im:root') && company && company.enabledImInspect !== 0) {
            navLists.push({
                text: locales.menu.tasks.dialogueManage.text,
                icon: 'duihuaguanli',
                pathName: 'conversationAnalysisDialogueManage',
                isActive: (match, location) => {
                    return location.pathname.includes('/dialogue-manage');
                },
            });
        }
        if (hasFeature && hasFeature('ticket:root') && company && company.enabledTicket !== 0) {
            navLists.push({
                text: locales.menu.tasks.ticketManage.text,
                icon: 'ic-gongdan',
                pathName: 'conversationAnalysisTicketManage',
                isActive: (match, location) => {
                    return location.pathname.includes('/ticket-manage');
                },
            });
        }
        if (hasFeature && hasFeature('mallcall:root') && company && company.enabledMallInspect !== 0) {
            navLists.push({
                text: locales.pages.home.index.ecommerceSessionManagement,
                icon: 'duihuakuang',
                pathName: 'conversationAnalysisECommerceManage',
                isActive: (match, location) => {
                    return location.pathname.includes('/e-commerce-manage');
                },
            });
        }
        if (isOperator()) {
            navLists.push({
                text: /* 视频分析 */UdeskLocales['current'].pages.home.index.videoAnalysis,
                icon: 'duihuakuang',
                pathName: getCurrentRouteName('videoAnalysis'),
                isActive: (match, location) => {
                    return location.pathname.includes('/video-analysis');
                },
            });
        }

        privates.navLists = navLists;
        actions.update();
    });
}
function getAllRoutePath(route, allPath) {
    if (Array.isArray(route)) {
        route.forEach((item) => {
            if (item.path) {
                allPath.push(item.path.split('/').filter(Boolean));
            }
            if (item.routes && Array.isArray(item.routes)) {
                getAllRoutePath(item.routes, allPath);
            }
        });
    } else {
        allPath.push(route.path.split('/').filter(Boolean));
        if (route.routes && Array.isArray(route.routes)) {
            getAllRoutePath(route.routes, allPath);
        }
    }
}

export default Udesk.react.udeskify(Home);


const HistoricalLearning = React.memo((props) => {
    const {count} = props;
    const [hasUpdatedData, setHasUpdatedData] = useState(false);
    const request = useRequest();

    useLiveEffect((success) => {
        request('/intelligentPartnerTaskFinishRecords/hasScoreUpdate').then(
            resp => {
                success(() => {
                    setHasUpdatedData(resp.data);
                });
            }
        );
    }, [count, request]);

    return hasUpdatedData ? (
        <div style={{
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <span>{UdeskLocales['current'].pages.home.index.historicalLearningData}</span>
            <span 
                style={{
                    color: '#fff',
                    background: '#ff4d4f',
                    borderRadius: '12px',
                    padding: '0 10px',
                    fontSize: '12px',
                    display: 'inline-block',
                    lineHeight: '21px',
                    fontWeight: 500,
                }}
            >new</span>
        </div>
    ) : UdeskLocales['current'].pages.home.index.historicalLearningData;
});