import Udesk from 'Udesk';
import SpeechRecognitionComponent from './component';

class SpeechRecognitionRoute extends SpeechRecognitionComponent {
    model() {
        let {
            sdkOptions
        } = this.props;

        let url = Udesk.business.apiPath.concatApiPath('asr-filter-config', sdkOptions);
        let dataPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        url = Udesk.business.apiPath.concatApiPath('/field/data/1?type=associate', sdkOptions);
        let fieldPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        return ({
            data: dataPromise,
            field: fieldPromise,
        });
    }

    parseModel(model) {
        let {
            privates
        } = this;
        model.data.forEach(item => {
            if (item.type === Udesk.enums.speechRecognitionSwitchType.asr.id && item.secondaryType === Udesk.enums.speechRecognitionSecondaryType.onOff.id) {
                privates.asrSwitch = item.activeFlag === 1;
            } else if (item.type === Udesk.enums.speechRecognitionSwitchType.correction.id && item.secondaryType === Udesk.enums.speechRecognitionSecondaryType.onOff.id) {
                privates.correctionSwitch = item.activeFlag === 1;
            } else if (item.type === Udesk.enums.speechRecognitionSwitchType.asr.id && item.secondaryType === Udesk.enums.speechRecognitionSecondaryType.config.id) {
                privates.asrConfigSwitch = item.activeFlag === 1;
                privates.asrConfigId = item.id;
                if (item.filter.conditionList != null) privates.asrConfig.conditionList = item.filter.conditionList;
                if (item.filter.judgeStrategy != null) privates.asrConfig.judgeStrategy = item.filter.judgeStrategy;
                if (item.filter.customJudgeLogic != null) privates.asrConfig.customJudgeLogic = item.filter.customJudgeLogic;
            } else if (item.type === Udesk.enums.speechRecognitionSwitchType.correction.id && item.secondaryType === Udesk.enums.speechRecognitionSecondaryType.config.id) {
                privates.correctionConfigSwitch = item.activeFlag === 1;
                privates.correctionConfigId = item.id;
                if (item.filter.conditionList != null) privates.correctionConfig.conditionList = item.filter.conditionList;
                if (item.filter.judgeStrategy != null) privates.correctionConfig.judgeStrategy = item.filter.judgeStrategy;
                if (item.filter.customJudgeLogic != null) privates.correctionConfig.customJudgeLogic = item.filter.customJudgeLogic;
            } else if (item.type === Udesk.enums.speechRecognitionSwitchType.asr.id && Object.values(this.privates.asrModelMap).includes(item.secondaryType)) {
                Object.entries(this.privates.asrModelMap).some(([key, value]) => {
                    if(value === item.secondaryType){
                        const {conditionList = [], judgeStrategy = 1, customJudgeLogic = ''} = item.filter;
                        this.privates.asrModel[key] = {
                            ...item.filter,
                            conditionList,
                            judgeStrategy,
                            customJudgeLogic,
                        };
                    }
                    return value === item.secondaryType;
                });
            }
        });
        privates.asrModelSwitch = model.data.filter(item => Object.values(this.privates.asrModelMap).includes(item.secondaryType)).some(item => item.activeFlag);
        privates.fieldList = model.field;
        privates.asrBackup.asrConfigSwitch = privates.asrConfigSwitch;
        privates.asrBackup.asrConfig = Udesk.utils.object.deepCopy(privates.asrConfig);
        privates.correctionBackup.correctionConfigSwitch = privates.correctionConfigSwitch;
        privates.correctionBackup.correctionConfig = Udesk.utils.object.deepCopy(privates.correctionConfig);
        return {};
    }
}

// function getDisplayConditionList(conditionList) {
//     return conditionList.map(item => {
//         item.field = item.field.id;
//         return item;
//     });
// }

export default SpeechRecognitionRoute;
