import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { CustomerInfoTest } from '../components/customer-info';
import { Button, Col, Icon, Row } from 'udesk-ui';
// import VideoRecord from '../components/record/video';
import { CustomerPortraitConfig } from 'Component/pages/components/coach/CustomerPortrait';
import { ConfigDrawer } from 'src/pages/coach/course-management/flow/components/ConfigDrawer';
import { Input } from './components/Input';
import { Dialogue, RefType } from 'Component/pages/components/coach/Dialogue';
import { NodeTypes } from '../../course-management/flow/utils/NodeBuilder';
import './index.less';

const Template = React.memo((props: any) => {
    const { match } = props;
    const flowId = match.params?.id;
    const [taskDetail, setTaskDetail] = useState<any>({
        taskType: 1,
        id: Udesk.utils.string.uuid(),
    });
    const [started, setStarted] = useState<boolean>(false);
    const [currentInputType, setCurrentInputType] = useState(1);
    const { initialChatList, start, /* globalConfig, */ referenceWords, send, refresh } = Input.useChatInput(
        flowId,
        taskDetail
    );
    const customerPortraitConfigContext = CustomerPortraitConfig.useCustomerPortraitConfig(
        parseInt(flowId, 10)
    );
    const { onNodeClick, ...nodeConfigDrawerProps } = ConfigDrawer.useConfigDrawer({
        // setNodes,
        flowId,
        // requestFlowData,
    });
    const chatRecordRef = useRef<RefType>(null);
    const inputRef = useRef<any>(null);

    const [list, setList] = useState<any[]>([]);
    const [previewItem, setPreviewItem] = useState<any>(null);

    const dialogueSentenceList = useMemo(() => {
        if (previewItem) {
            return [...list, { ...previewItem, content: previewItem.content || /* 收音中... */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.index.listening }];
        }
        return [...list];
    }, [list, previewItem]);

    const clearChatRecord = useCallback(() => {
        if (!list?.length && !previewItem) return;
        setList([]);
        setPreviewItem(null);
    }, [list?.length, previewItem]);

    const reset = useCallback(() => {
        setStarted(false);
        clearChatRecord();
    }, [clearChatRecord]);
    
    const extra = useCallback((props) => {
        const onClick = () => {
            setList((prev) => {
                return prev.filter((item, index) => {
                    return index < props.index;
                });
            });
            setStarted(true);
            refresh(props);
            setTimeout(() => {
                inputRef.current.setTextAreaValue(props.content);
            }, 0);
        };

        return (
            <RefreshButton onClick={onClick} />
        );
    }, [refresh]);

    useEffect(() => {
        setList(initialChatList);
    }, [initialChatList]);

    useEffect(() => {
        chatRecordRef.current?.scrollTo(list.length);
    }, [list.length]);

    return (
        <Page
            pageClassName="learning-center-exercise"
            pageBodyClassName="learning-center-node-test-body"
            backGroundGhost={true}
            onBack={() => window.history.back()}
            title={/* 画布测试 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.index.canvasTesting}
        >
            <CustomerPortraitConfig.CustomerPortraitConfigProvider
                value={customerPortraitConfigContext}
            >
                <Row gutter={16} style={{ height: '100%' }}>
                    <Col span={8}>
                        <CustomerInfoTest
                            data={taskDetail?.customerPersona ?? {}}
                            onCustomerInfoChange={(customerPersona) => {
                                setTaskDetail((prev) => ({
                                    ...prev,
                                    customerPersona,
                                }));
                                reset();
                            }}
                        />
                    </Col>
                    <Col span={16}>
                        <div className="node-test-page-chat">
                            <Dialogue
                                ref={chatRecordRef}
                                dialogueSentenceList={dialogueSentenceList}
                                itemRender={(props, index) => {
                                    return (
                                        <React.Fragment>
                                            <Dialogue.Item
                                                {...props}
                                                extra={extra}
                                                // {...scoreDetailProps}
                                                enableScorePointDisplay={true}
                                                enableSpeedWarn={true}
                                                enableWordsViolationWarn={true}
                                                enableEmotionDisplay={true}
                                                index={index}
                                                isTest={true}
                                                onNodeConfigClick={() => {
                                                    onNodeClick(null, {
                                                        id: props.traineeWordsResult.nodeId,
                                                        type: NodeTypes.getNode(props.traineeWordsResult.currentProcessNodeType).key,
                                                        data: {
                                                            label: props.traineeWordsResult.nodeName,
                                                            recordId: props.ajaxParams.recordId,
                                                            flowId,
                                                        },
                                                    });
                                                }}
                                                // isRta={false}
                                            />
                                        </React.Fragment>
                                    );
                                }}
                            />
                            {started ? (
                                <Input
                                    ref={inputRef}
                                    currentInputType={currentInputType}
                                    setCurrentInputType={setCurrentInputType}
                                    onChange={(newItem) => {
                                        send(newItem).then(
                                            ({ traineeWordsResult, nextNodeList, ajaxParams }) => {
                                                const { resultAnalysis, ...others } =
                                                    traineeWordsResult || {};
                                                const isLast = !Array.isArray(nextNodeList);
                                                setList((prev) => {
                                                    let ret = prev.concat({
                                                        ...newItem,
                                                        ajaxParams,
                                                        nextNodeList,
                                                        traineeWordsResult: Object.assign(
                                                            others,
                                                            resultAnalysis
                                                        ),
                                                    });
                                                    if (!isLast) {
                                                        return ret.concat(nextNodeList);
                                                    }
                                                    return ret;
                                                });

                                                if (isLast) {
                                                    Udesk.ui.notify.success(/* 流程结束 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.index.endOfProcess);
                                                    setStarted(false);
                                                }
                                                setPreviewItem(null);
                                            }
                                        );
                                    }}
                                    onPreviewChange={(newItem) => {
                                        // setList((prev) => {
                                        //     return prev
                                        //         .filter((i: any) => !i.isPreview)
                                        //         .concat(newItem);
                                        // });
                                        if (newItem.content !== previewItem?.content) {
                                            setPreviewItem(newItem);
                                        }
                                    }}
                                    onAbort={() => {
                                        // setList((prev) => prev.filter((i: any) => !i.isPreview));
                                        setPreviewItem(null);
                                    }}
                                    referenceWords={referenceWords}
                                    extra={
                                        <Button
                                            type="primary"
                                            size="small"
                                            danger
                                            onClick={reset}
                                            icon={<Icon antdIcon={true} type="ReloadOutlined" />}
                                            tooltip={/* 重新开始 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.index.restart}
                                        ></Button>
                                    }
                                />
                            ) : (
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        clearChatRecord();
                                        setStarted(true);
                                        start();
                                    }}
                                >
                                    {list?.length || previewItem ? /* 重新开始 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.index.restart : /* 开始 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.index.start}
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>

                <ConfigDrawer
                    showNodeNameEdit={false}
                    {...nodeConfigDrawerProps}
                    // triggerFlowNodeNameChange={}
                />
            </CustomerPortraitConfig.CustomerPortraitConfigProvider>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);


const RefreshButton = React.memo<any>(props => {
    return (
        <Button 
            onClick={props.onClick}
            tooltip={/* 从当前节点测试 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.index.testFromCurrentNode}
            type='link' 
            icon={<Icon antdIcon={true} type='ReloadOutlined'/>} 
        />
    );
});