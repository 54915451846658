import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { getIntelligentPartnerTaskFinishRecords } from 'src/api/intelligentPartnerTaskFinishRecords';
import { linkTo } from 'src/util/link';
import { Button, Table } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const ScoreDetailList = (props) => {
    const { taskType, subTaskItem } = props;
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any>([]);

    const init = () => {
        setLoading(true);
        getIntelligentPartnerTaskFinishRecords({
            params: { taskId: subTaskItem?.taskId, userId: subTaskItem?.userId },
        })
            .then((resp) => {
                setDataSource(
                    (resp?.data || []).map((item, index) => ({
                        no: `${/* 第 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.section}${index + 1}${/* 次 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.second}${taskType === 1 ? /* 练习 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.practice : /* 考试 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.examination}`,
                        ...item,
                    }))
                );
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const OutComeOptions = useMemo(() => {
        return [
            {
                label: /* 未达标 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.notMeetingStandards,
                value: 0,
            },
            {
                label: /* 达标 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.meetingStandards,
                value: 1,
            },
            {
                label: /* 优秀 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.excellent,
                value: 2,
            },
        ];
    }, []);

    const onShowDetail = (item) => {
        linkTo(props.history, 'coachLearningCenterRecordDetail', { id: item.id });
    };

    const columns = useMemo(() => {
        return [
            {
                key: 'no',
                dataIndex: 'no',
                title: /* 次数 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.frequency,
            },
            {
                key: 'score',
                dataIndex: 'score',
                title: /* 分数 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.fraction,
            },
            {
                key: 'scoreResult',
                dataIndex: 'scoreResult',
                title: /* 结果 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.result,
                render: (text) => OutComeOptions.find((item) => item.value === text)?.label,
            },
            {
                key: 'actions',
                dataIndex: 'actions',
                title: /* 操作 */UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.operation,
                render: (_, item) => (
                    <Button type="link" onClick={onShowDetail.bind(null, item)}>{/* 查看详情 */}{UdeskLocales['current'].pages.coach.taskCenter.detail.scoreDetailList.viewDetails}</Button>
                ),
            },
        ];
    }, []);

    useEffect(() => {
        if (subTaskItem) {
            init();
        }
    }, [subTaskItem]);

    return (
        <Table
            loading={loading}
            // showHeader={false}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
        />
    );
};

export default ScoreDetailList;
