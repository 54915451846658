import React from 'react';
import { FormBuilder, Button as UdeskButton } from 'udesk-ui';
import LoginRoute from './route';
import styled from 'styled-components';
import './style.scss';

const Button = (props) => {
    return <UdeskButton {...props} htmlType="submit"></UdeskButton>;
};

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    /* margin-top: -20%; */
`;
const Logo = styled.div`
    position: absolute;
    top: 40px;
    left: 40px;
    display: flex;
    align-items: center;

    .website-title {
        margin-left: 8px;
        font-size: 20px;
        font-weight: 600;
    }
`;

export default class LoginTemplate extends LoginRoute {
    render() {
        let { state, privates, locales } = this;
        return (
            <div className='udesk-qa-web-login-page'>
                <div className='left'>
                    <Logo>
                        <img className='title-image' src={privates.computes._websiteLogo} alt='' width='30' />
                        <div className='website-title'>
                            {privates.computes._websiteTitle}
                        </div>
                    </Logo>
                    <FormWrap>
                        <div className='udesk-qa-ui-form-builder-container title'>Welcome back</div>
                        <div className='udesk-qa-ui-form-builder-container desc'>{/* 欢迎回来！请输入您的详细信息 */}{locales.pages.login.template.welcomeBackPleaseEnterYourDetails}</div>
                        <FormBuilder
                            customComponents={{ Button }}
                            labelCol={{ span: 0 }}
                            wrapperCol={{ span: 24 }}
                            layout='vertical'
                            ref={privates.formInstance}
                            onFinish={this.actions.loginV2}
                            fields={[
                                {
                                    label: /* 邮箱 */locales.pages.login.template.mailbox,
                                    type: 'Input',
                                    name: 'email',
                                    props: {
                                        size: 'large',
                                    },
                                },
                                {
                                    label: /* 密码 */locales.pages.login.template.password,
                                    type: 'Password',
                                    name: 'password',
                                    validateStatus: state.errorMsg ? 'error' : 'validating',
                                    help: state.errorMsg || undefined,
                                    props: {
                                        size: 'large',
                                    },
                                },
                                {
                                    type: 'Button',
                                    props: {
                                        children: /* 登录 */locales.pages.login.template.signIn,
                                        block: true,
                                        size: 'large',
                                        type: 'primary',
                                        loading: state.loading,
                                        style: {
                                            marginTop: 24,
                                        },
                                    },
                                },
                            ]}
                            footerRender={false}
                        />
                    </FormWrap>
                </div>
                <div className='right' style={{ backgroundImage: `url(${privates.bgImg})` }}></div>
                {/* <div className='center-block w-xxl w-auto-xs p-y-md '>
                    <div className='navbar'>
                        <div className='title'>
                            <div>
                                <h4>
                                    <img
                                        className='title-image'
                                        src={privates.computes._websiteLogo}
                                        alt=''
                                        width='30'
                                    />
                                    {privates.computes._websiteTitle}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className='p-a-md box-color r box-shadow-z1 text-color m-a form-contianer'>
                        <div className='m-b text-sm'>
                            <Choose>
                                <When condition={state.errorMsg}>
                                    <span className='has-error'>{state.errorMsg}</span>
                                </When>
                                <Otherwise>{locales.pages.auth.login.loginPrompt}</Otherwise>
                            </Choose>
                        </div>
                        <form name='form' onSubmit='return false;'>
                            <div className='md-form-group'>
                                <input
                                    type='email'
                                    className='md-input'
                                    value={state.email}
                                    placeholder={locales.pages.auth.login.email}
                                    onChange={actions.onValueChanged.params(state, 'email')}
                                />
                            </div>
                            <div className='md-form-group'>
                                <input
                                    type='password'
                                    value={state.password}
                                    className='md-input'
                                    placeholder={locales.pages.auth.login.password}
                                    onChange={actions.onValueChanged.params(state, 'password')}
                                />
                            </div>
                            <div className='md-form-group'>
                                <label className='md-check'>
                                    <input
                                        type='checkbox'
                                        checked={state.rememberMe}
                                        onChange={actions.onValueChanged.params(state, 'rememberMe')}
                                    />
                                    <i className='primary'></i>
                                    <span className='md-check-label-text'>{locales.pages.auth.login.rememberMe}</span>
                                </label>
                            </div>
                            <button
                                type='button'
                                className='btn primary btn-block p-x-md login-btn'
                                onClick={actions.login}
                            >
                                {locales.pages.auth.login.name}
                            </button>
                        </form>
                    </div>
                </div> */}
            </div>
        );
    }
}
