import React from 'react';

class ApplyTemplatesComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {
        templates: []
    };
    //#endregion

    state = {

    };
    privates = {

    };
    static computes = {

    };
    actions = {
        transitionToTemplate(template) {
            this.trigger("onClickTemplate", template);
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {

    }
    componentDidMount() {
    }
    componentWillUnmount() {

    }
    asyncModel(changedParams) {

    }
    //#endregion
}

export default ApplyTemplatesComponent;