import Udesk from 'Udesk';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Button, Space, Icon, FormBuilder, Modal } from 'udesk-ui';
import { Content } from '../../../business-stage/modal/customerDynamics';
import ColumnLeftTitle from '../columnLeftTitle';
import CustomTooltip, { getPlacement, formatTime } from '../customer-tooltip';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import moment from 'moment';
import UdeskLocales from 'UdeskLocales';

const getDateTime = (item) => {
    const date = moment();
    date.month(+item.month - 1);
    date.date(+item.day);
    return date.format('YYYY-MM-DD HH:mm:ss');
};

export default (props) => {
    const {locales, bindModal, rowTotal, communicateModal} = props;
    const [isClosed, setIsClosed] = useState(false);

    const handlerToggle = useCallback(() => {
        setIsClosed(isClosed => !isClosed);
    }, []);

    const leftTitle3 = useMemo(() => {
        return (
            <ColumnLeftTitle 
                {...{
                    title: /* 跟进建议 */UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row3.followUpSuggestions,
                    className: 'row-title-3',
                    content: (
                        <>
                            <li>{/* 智能推荐 共 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row3.intelligentRecommendation}<span className='num'>{rowTotal.communicateStrategy}</span>{/* 条 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row3.strip}</li>
                            <li>
                                <Button type='link' onClick={() => bindModal?.open('4')}>
                                    {locales.labels.seeDetail}
                                </Button>
                            </li>
                        </>
                    ),
                    isClosed,
                    handlerToggle
                }}
            />
        );
    }, [rowTotal.communicateStrategy, locales.labels.seeDetail, isClosed, handlerToggle, bindModal]);

    const columnRender3 = useCallback((item, key, index, columns) => {
        const {communicateStrategyList: list = [], managerCommunicateList: list2 = []} = item;
        if (isClosed) {
            const total = list.length + list2.length;
            return (
                total > 0 ? total : null // 跟进建议的数量总和
            );
        } else {
            if (list.length > 0) {
                return (
                    <CustomTooltip
                        placement={getPlacement(index, columns)}
                        title={[
                            /* 智能推荐 */UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row3.intelligentRecommendation,
                            list.map(item => {
                                return (
                                    <div className='custom-tooltip-content-item'>
                                        <div className='time'>{formatTime(item.hitTime)}</div>
                                        <Content>{item.pushText}</Content>
                                    </div>
                                );
                            })
                        ]} 
                    >
                        <Space className='row-data-3' align="center" direction="vertical" key={key} size={-4}>
                            <span><Icon type="ic-ask-tiwen"></Icon></span>
                            <span>{list.length}</span>
                        </Space>
                    </CustomTooltip>
                );
            }
        }
    }, [isClosed]);

    const leftTitle4 = useMemo(() => {
        return (
            <ColumnLeftTitle 
                {...{
                    className: 'row-title-4',
                    content: (
                        <li>
                            <span>{/* 管理者建议 共 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row3.managersSuggestATotalOf}<span className='num'>{rowTotal.managerCommunicate}</span>{/* 条 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row3.strip}</span>
                            <Button type='link' onClick={() => bindModal?.open('4')}>
                                {locales.labels.seeDetail}
                            </Button>
                        </li>
                    ),
                    isClosed,
                    handlerToggle
                }}
            />
        );
    }, [rowTotal.managerCommunicate, locales.labels.seeDetail, isClosed, handlerToggle, bindModal]);

    const columnRender4 = useCallback((item, key, index, columns) => {
        const {managerCommunicateList: list} = item;
        if (isClosed) {
            return null;
        } else {
            if (list) {
                return (
                    <CustomTooltip
                        placement={getPlacement(index, columns)}
                        title={[
                            /* 管理者推荐 */UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row3.recommendedByManagers,
                            list.map(item => {
                                return (
                                    <div className='custom-tooltip-content-item'>
                                        <div className='time'>{formatTime(item.hitTime)}</div>
                                        <Content>{item.suggest}</Content>
                                    </div>
                                );
                            })
                        ]} 
                    >
                        <div>
                            <AddButton item={item} communicateModal={communicateModal}>
                                <Space className='show-content row-data-4' align="center" direction="vertical" key={key} size={-4}>
                                    <span><Icon type="ic-ask-tiwen"></Icon></span>
                                    <span>{list.length}</span>
                                </Space>
                            </AddButton>
                        </div>
                    </CustomTooltip>
                );
            } else {
                return <AddButton item={item} communicateModal={communicateModal} />;
            }
        }
    }, [communicateModal, isClosed]);


    return [leftTitle3, columnRender3, leftTitle4, columnRender4];
};

const AddButton = React.memo((props: any) => {
    const {item, communicateModal, children} = props;

    return (
        <div className={`hover-content ${children ? '' : 'empty'}`}>
            {children}
            <div className='hover-show-content'>
                <Button 
                    type='link' 
                    icon={<Icon type="PlusOutlined" antdIcon />} 
                    onClick={(e) => {
                        e.stopPropagation();
                        communicateModal?.open(getDateTime(item));
                    }}
                />
            </div>
        </div>
    );
});

export type CommunicateModalRefProps = {
    open: (date: string) => void;
};

type Props = {
    ref: any;
    locales: any,
    request: any;
    customerId: any;
    getCustomerTrip: any;
  };
  
export const CommunicateModal = forwardRef<CommunicateModalRefProps, Props>((props, ref) => {
    const {locales, request, customerId, getCustomerTrip} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formInstance] = FormBuilder.useForm();

    const showModal = useCallback((datetime: string) => {
        formInstance.setFieldsValue({
            datetime,
            suggest: ''
        });
        setIsModalOpen(true);
    }, []);
  
    useImperativeHandle(
        ref,
        () => ({
            open(date: string) {
                showModal(date);
            },
        }),
        [showModal],
    );


    const handleOk = () => {
        formInstance.submit();
    };
  
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const formConfig: FormProps = {
        onFinish: (values) => {
            request('/review/customerTrip/managerSuggest', {
                customerId,
                ...values,
            }, 'post').then(
                resp => {
                    Udesk.ui.notify.success(locales.business.info.saveSuccess,);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            ).finally(() => {
                handleCancel();
                getCustomerTrip();
            });
        },
        fields: [
            {
                label: /* 创建时间 */UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row3.creationTime,
                name: 'datetime',
                type: 'DatePicker',
                rules: [
                    { required: true }
                ],
                props: {
                    showTime: true,
                },
            },
            {
                label: /* 管理员建议 */UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row3.administratorSuggestions,
                name: 'suggest',
                type: 'TextArea',
                rules: [
                    { required: true }
                ],
                props: {
                    rows: 6,
                },
            },
        ],
        footerRender: false,
        labelCol: {
            sm: {
                span: 6,
            },
        },
        wrapperCol: {
            sm: {
                span: 16,
            },
        },
    };

    return (
        <Modal 
            title={/* 新增管理者建议 */UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row3.suggestionsForNewManagers} 
            width={700} 
            visible={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
        >
            <FormBuilder {...formConfig} form={formInstance} />
        </Modal>
    );
});