let speechRecognitionSwitchType = [
    { id: 1, key: "asr" },
    { id: 2, key: "correction" },
];

let speechRecognitionSecondaryType = [
    { id: 1, key: "onOff" },
    { id: 2, key: "config" },
    { id: 4, key: "asrModel" },
    { id: 5, key: "canAsrModel" },
    { id: 6, key: "enAsrModel" },
];

let nlpModelLanguages =  [
    { id: 0, key: "chinese" },
    { id: 1, key: "english" },
    { id: 2, key: "japanese" },
];

export default {
    speechRecognitionSwitchType,
    speechRecognitionSecondaryType,
    nlpModelLanguages,
};
