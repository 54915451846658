//wdyr分析组件重复渲染原因
// import './wdyr';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { ConfigProvider, Icon, message, notification, Modal } from 'udesk-ui';
import zhCN from 'udesk-ui/es/locale/zh_CN';
import enUS from 'udesk-ui/es/locale/en_US';
import zhTW from 'udesk-ui/es/locale/zh_TW';

import gmUIZhCN from 'udesk_gm_ui/es/locale/zh_CN';
import gmUIEnUS from 'udesk_gm_ui/es/locale/en_US';

import 'moment/locale/zh-cn';

import DomSdkBaseClass from 'udesk-react/src/udesk/sdk/react-dom-sdk-base-class';
import ReactSentry from './lib/react-sentry';

import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import UdeskQaWebRoutesComponent from './routes';
import routesGlobal from './routes/routes-global';

import CustomNotifyPluginClass from './common/ui/custom-notify.js';

import initializers from './initializers';

import 'udesk-react/src/udesk-plugin-installers/utils/web/safe-html';
import 'udesk-react/src/udesk-plugin-installers/ui/notify/iziToast';
import './styles/index.scss';
import './styles/qa-form.css';
import '@alifd/next/lib/form/index.scss';
import '@alifd/next/lib/input/index.scss';
import '@alifd/next/lib/select/index.scss';
// import './vendor/index';
import CustomizeModelError from './components/pages/model-error-page';
// import { func } from 'prop-types';
import { requestInit } from 'src/api/context';
import { ConfigProvider as GmUiConfigProvider } from 'udesk_gm_ui';
import 'requestidlecallback-polyfill';
import { apiPrefix } from './util/settings';

if (process.env.NODE_ENV === 'development') {
    window._UDQ = Udesk;
    window._UDL = Locales;
}

message.config({ prefixCls: 'udesk-qa-ui-message' });
notification.config({ prefixCls: 'udesk-qa-ui-notification' });
Modal.config({ rootPrefixCls: 'udesk-qa-ui' });

const ENABLE_SENTRY = process.env.REACT_APP_ENABLE_SENTRY === 'true';
let SENTRY_INITIALIZED = false;
// const ANTD_LOCALES = {
//     'EN-US': enUS,
//     'ZH-CN': zhCN
// };

const LOCALES_MAP = {
    zh: {
        language: 'ZH-CN',
        antd: zhCN,
        gmUi: gmUIZhCN,
    },
    en: {
        language: 'EN-US',
        antd: enUS,
        gmUi: gmUIEnUS,
    },
    tw: {
        language: 'ZH-TW',
        antd: zhTW,
        gmUi: gmUIZhCN,
    },
};

// let CURRENT_VERSION = null;

let customNotifyPluginInstance = new CustomNotifyPluginClass();
// if (process.env.NODE_ENV !== 'development') {
//     Udesk.ui.notify.plugins = {
//         udeskQAWeb: customNotifyPluginInstance
//     };
//     Udesk.ui.notify.setPlugin('udeskQAWeb');
// }

Udesk.init({});
if (ENABLE_SENTRY) {
    Udesk.on('onModelPromiseCatched', (error) => {
        ReactSentry.captureException(error);
    });
}
if (ENABLE_SENTRY) {
    Udesk.on('onModelPromiseCatched', (error) => {
        ReactSentry.captureException(error);
    });
}
initializers.run();

class UdeskQaSdkClass extends DomSdkBaseClass {
    constructor(options) {
        if (options.methods != null && options.methods.reloadCallback != null) {
            options.reloadCallback = options.methods.reloadCallback;
            delete options.methods.reloadCallback;
        }
        super(options);
        this.rootRouterInstance = React.createRef();
        this.udeskCrmComponentElement = null;
        // Locales.setLanguage(options.props.language);
        Locales.setLanguage('ZH-CN'); // 目前仅支持中文
        this.context = Udesk.react.context.defaults.clone();
        // this.context.props.language = this.options.props.language;
        this.context.props.language = 'ZH-CN'; // 目前仅支持中文
        moment.locale('zh-cn'); // 目前仅支持中文
        this.context.props.sdkOptions = this.options;
        this.context.options.localesManager = Locales;
        this.context.props.showModelError = true;
        this.context.props.modelErrorDisplayMode = Udesk.enums.modelErrorDisplayMode.both;
        this.context.props.customizeModelErrorTemplate = function () {
            const backToTasks = () => {
                const routeOptions = {
                    history: this.props.history,
                    routeName: 'tasksIndex',
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            };

            return (
                <CustomizeModelError componentPrivates={this.privates} backToTasks={backToTasks} />
            );
        };
        this.context.props.animationProps = {
            align: 'center',
            verticalAlign: 'middle',
        };
        customNotifyPluginInstance.updateDefaults(this.context.props.sdkOptions.methods);

        Icon.createFromIconfontCN({
            // scriptUrl: '//at.alicdn.com/t/font_1142533_gwyidv9e80h.js',  // 测试时可以使用线上路径，最终需要下载到本地
            scriptUrl: this.context.props.sdkOptions.props.domain + '/static/iconfont/iconfont.js', // icon图标存储位置: public/static/iconfont
            prefixCls: 'icon-udesk-qa-react-web',
        });

        if (!SENTRY_INITIALIZED && DomSdkBaseClass.enableSentry && ENABLE_SENTRY) {
            SENTRY_INITIALIZED = true;
            ReactSentry.init({
                getUser: function () {
                    let getUserForSentry = DomSdkBaseClass.getUserForSentry;
                    if (getUserForSentry !== null && getUserForSentry === '') {
                        return getUserForSentry();
                    }
                    return {};
                },
                getTagsContext: () => {
                    let getTagsContextForSentry = DomSdkBaseClass.getTagsContextForSentry;
                    if (getTagsContextForSentry !== null) {
                        return getTagsContextForSentry();
                    }
                    return {};
                },
                getShouldSendCallback: () => {
                    let pathname = window.location.pathname;
                    for (let router of routesGlobal) {
                        if (pathname.indexOf(router.path) !== -1) {
                            return true;
                        }
                    }
                    return false;
                },
            });
        }
    }

    context = null;

    static get sdkName() {
        return 'Udesk-QA';
    }

    getVerssionPath() {
        return `${this.options.props.releaseVersionUrl}`;
    }
    getHistory() {
        let rootRouterInstance = this.rootRouterInstance.current;
        return rootRouterInstance.getHistory();
    }
    renderTo(sdkEvents, container) {
        this.isDestroyed = false;
        let udeskQaComponentElement = (this.udeskCrmComponentElement =
            document.createElement('div'));
        udeskQaComponentElement.setAttribute('class', 'udesk-qa-component-element');
        udeskQaComponentElement.style.width = '100%';
        udeskQaComponentElement.style.height = '100%';
        if (container == null) {
            container = document.body;
        }
        container.appendChild(udeskQaComponentElement);

        if (ENABLE_SENTRY) {
            ReactSentry.ready();
        }
        requestInit({
            domain: this.context.props.sdkOptions.props.domain,
            prefix: apiPrefix,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        let udeskUiLocale = zhCN;
        let gmUiLocale = gmUIZhCN;

        if (
            // window.location.pathname.startsWith('/site') ||
            window.location.pathname.startsWith('/entry-page') ||
            // window.location.pathname.startsWith('/sales') ||
            // window.location.pathname.startsWith('/algorithm-platform') ||
            // window.location.pathname.startsWith('/general-setting') ||
            // window.location.pathname.startsWith('/coach')
            Udesk.enums.sysModuleList.some((m) =>
                window.location.pathname.startsWith(`/${m.nameSpace}`)
            )
        ) {
            Udesk.ajax
                .get(
                    Udesk.business.apiPath.concatApiPath('/isLogin', this.context.props.sdkOptions)
                )
                .then(
                    (resp) => {
                        Udesk.ajax
                            .get(
                                Udesk.business.apiPath.concatApiPath(
                                    '/init',
                                    this.context.props.sdkOptions
                                )
                            )
                            .then(
                                (resp) => {
                                    window.localStorage.setItem('userName', resp.data.user.email);
                                    // window.uAnalytics.identify(`${resp.data.user.id}`, {
                                    //     id: resp.data.user.id,
                                    //     admin: resp.data.user.admin,
                                    //     nickname: resp.data.user.id,
                                    //     email: resp.data.user.email,
                                    //     createTime: resp.data.user.createTime,
                                    //     callTemplateId: resp.data.user.company.associateCallTemplateId,
                                    //     imTemplateId: resp.data.user.company.associateImTemplateId,
                                    //     companyCreateTime: resp.data.user.company.createTime,
                                    //     companyId: resp.data.user.company.id,
                                    //     companyName: resp.data.user.company.name,
                                    //     companySysName: resp.data.user.company.sysName,
                                    // });
                                    // resp.data.userLanguage
                                    const langCode = resp.data.userLanguage;
                                    if (
                                        Object.prototype.hasOwnProperty.call(LOCALES_MAP, langCode)
                                    ) {
                                        udeskQaComponentElement.setAttribute('lang', langCode);

                                        Locales.setLanguage(LOCALES_MAP[langCode].language);
                                        this.context.props.language =
                                            LOCALES_MAP[langCode].language;
                                        udeskUiLocale = LOCALES_MAP[langCode].antd;
                                        gmUiLocale = LOCALES_MAP[langCode].gmUi;
                                    }
                                    Udesk.data.buildInitData(resp.data);
                                    Udesk.data.buildUser(resp.data.user, resp.data);
                                    Udesk.data.buildCompany(resp.data);

                                    // 伊利特殊要求
                                    if (Udesk.data.init.company.id === 309) {
                                        Locales.current.pages.recordUpload.detail.result.template.textUnderInspection =
                                            '文本分析中';
                                        Locales.current.components.pages.sentimentAnalysis.title =
                                            '词云分析';
                                        Locales.current.pages.recordUpload.detail.result.template.textQualityInspectionCompleted =
                                            '文本分析完成';
                                        Locales.current.components.pages.recordUpload.steps.start =
                                            '开始分析';
                                        Locales.current.enums.common.intelligentQualityInspection =
                                            '伊利集团';
                                    }

                                    this.context.props.sdkOptions.props.biDomain =
                                        Udesk.data.init.biDomain;
                                    this.context.props.sdkOptions.props.biToken =
                                        Udesk.data.init.biToken;
                                    this.context.props.sdkOptions.props.biUser =
                                        Udesk.data.init.user;
                                    this.context.props.sdkOptions.props.callCustom =
                                        Udesk.data.init.callCustom;
                                    this.context.props.sdkOptions.props.imCustom =
                                        Udesk.data.init.imCustom;
                                    this.context.props.sdkOptions.props.defaultSearchTemplate = (
                                        Udesk.data.init.defaultSearchTemplate || []
                                    ).map((i) => ({ ...i, id: `templateType-${i.templateType}` }));
                                    this.context.props.sdkOptions.props['_company'] =
                                        resp.data.company;
                                    renderQaComponent.call(this);

                                    //预加载bi资源，提升bi页面加载速度，其中逻辑与udesk-react中sdk-loader保持一致，有精简
                                    preloadBiResource(this.context.props.sdkOptions.props.biDomain);
                                },
                                (reason) => {
                                    Udesk.logger.error(reason);
                                    Udesk.ui.notify.error({
                                        message:
                                            reason.errorMsg ||
                                            Locales.current.labels.initSystemError,
                                        duration: 0,
                                    });
                                }
                            );
                    },
                    (reason) => {
                        if (reason.status === Udesk.system.apiCodes.unauthenticated) {
                            let referUrl = encodeURIComponent(
                                window.location.pathname + window.location.search
                            );
                            if ('/auth/login' !== window.location.pathname) {
                                window.location.href = `/auth/login?refer=${referUrl}`;
                            }
                            // 因为要作页面跳转，所以不用reject了，否则会在底层弹错误提示
                            // reject();
                        } else {
                            Udesk.logger.error(reason);
                            Udesk.ui.notify.error({
                                message: reason.errorMsg || Locales.current.labels.initSystemError,
                                duration: 0,
                            });
                        }
                    }
                );
        } else {
            renderQaComponent.call(this);
        }
        function renderQaComponent() {
            render(
                <ConfigProvider locale={udeskUiLocale} prefixCls="udesk-qa-ui">
                    <GmUiConfigProvider locale={gmUiLocale} prefixCls="udesk-qa-ui">
                        <UdeskQaWebRoutesComponent
                            udeskContext={this.context}
                            sdkEvents={sdkEvents}
                            ref={this.rootRouterInstance}
                        />
                    </GmUiConfigProvider>
                </ConfigProvider>,
                udeskQaComponentElement
            );
        }
    }
    destroy() {
        if (this.isDestroyed) {
            return;
        }
        unmountComponentAtNode(this.udeskCrmComponentElement);
        this.udeskCrmComponentElement = null;
        this.isDestroyed = true;
        super.destroy();
    }
}

UdeskQaSdkClass.initGlobalNameSpace(UdeskQaSdkClass, {
    defaultNamespace: 'UdeskQa',
});

export default UdeskQaSdkClass;

function preloadBiResource(biDomain) {
    let releaseVersionUrl = `${biDomain}/qastar/RELEASE-VERSION`;
    $.get(`${releaseVersionUrl}?v=${new Date().getTime()}`).always(function (resp) {
        let version = typeof resp === 'string' ? resp : null;

        if (!version || version.indexOf('<') > -1) {
            version = new Date().getTime();
        }
        let prevSdkCssElement = null;
        let prevSdkJsElement = null;
        {
            let cssUrl = `${biDomain}/qastar/static/css/udesk-qastar-bi.css`;
            prevSdkCssElement = window.$(`link[href^='${cssUrl}']`);
            let styleDom = document.createElement('link');
            styleDom.rel = 'stylesheet';
            styleDom.type = 'text/css';
            styleDom.href = `${cssUrl}?v=${version}`;
            document.getElementsByTagName('head')[0].appendChild(styleDom);
        }

        {
            let jsUrl = `${biDomain}/qastar/static/js/udesk-qastar-bi.js`;
            prevSdkJsElement = window.$(`script[src^='${jsUrl}']`);

            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = `${jsUrl}?v=${version || new Date().getTime()}`;
            script.onload = function () {
                if (prevSdkCssElement != null) {
                    window.$(prevSdkCssElement).remove();
                }
                if (prevSdkJsElement != null) {
                    window.$(prevSdkJsElement).remove();
                }
            };
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    });
}
