// 统一入口
import AppEntry from '../pages/app-entry';
import { coachRouteComponentByRouteNameMappings } from './coach/routes';
import { salesRouteComponentByRouteNameMappings } from './sales/routes';
import { algorithmRouteComponentByRouteNameMappings } from './algorithm/routes';
import { generalSettingRouteComponentByRouteNameMappings } from './setting/routes';
import { qaRouteComponentByRouteNameMappings } from './qa/routes';
import { conversationAnalysisRouteComponentByRouteNameMappings } from './conversationAnalysis/routes';

// 系统登录
import AuthLogin from '../pages/login';
// 系统退出
import AuthLogout from '../pages/logout';
//404
import PageNotFound from '../pages/404';

export const routeComponentByRouteNameMappings = {
    // 统一入口
    appEntry: {
        component: AppEntry
    },
    // 系统登录
    authLogin: {
        component: AuthLogin,
    },
    // 系统退出
    authLogout: {
        component: AuthLogout,
    },
    pageNotFound: {
        component: PageNotFound,
    },
    ...qaRouteComponentByRouteNameMappings,
    ...salesRouteComponentByRouteNameMappings,
    ...algorithmRouteComponentByRouteNameMappings,
    ...generalSettingRouteComponentByRouteNameMappings,
    ...coachRouteComponentByRouteNameMappings,
    ...conversationAnalysisRouteComponentByRouteNameMappings,
};
