import React, { useState, useCallback, useRef } from 'react';
import './style.scss';
import SemanticTagsDetailRoute from './route';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Input, Select, Button, InputNumber, PageHeader } from 'udesk-ui';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Card from './Cards';
import update from 'immutability-helper';
import _isEmpty from 'lodash-es/isEmpty';
import _filter from 'lodash-es/filter';
import _find from 'lodash-es/find';
import _isEqual from 'lodash-es/isEqual';
import _findIndex from 'lodash-es/findIndex';
// const ItemLayout = {
//     labelCol: {
//         fixedSpan: 4
//     },
//     wrapperCol: {
//         span: 10
//     }
// };
const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 9 },
};
const DraggableComponent = (props) => {
    const [, drag] = useDrag({
        item: { type: 'cards', name: `${props.name}`, color: `${props.color}`, ...props, },
        end: (item, monitor) => {
            if (!monitor.didDrop()) {
                delete item.index;
            }
        }
    });
    return <div ref={drag} style={{ display: 'inline-block' }}><Button style={{ border: `1px solid ${props.color}`, color: `${props.color}`, marginRight: '3px', marginBottom: '3px' }}>{props.name}</Button></div>;
};
const MyDropTarget = (props) => {
    let [list, setList] = useState(props.customRules);
    let ref = useRef(null);

    let [count, setCount] = useState(0);
    const [, drop] = useDrop({
        accept: 'cards',
        drop: (item, monitor) => {
            if (item.name) {
                delete item.index;
                setCount(count + 1);
                let noneCardIndex = _findIndex(list, item => item.id === 'none');
                list.splice(noneCardIndex, 0, { ...item, id: `${item.id}_${count}` });
                let lists = list.filter(item => item.id !== 'none');
                lists = [
                    ...lists,
                    {
                        id: 'none'
                    }
                ];
                setList(lists);

            }
        },
        collect: monitor => {
            if (!monitor.isOver() && !monitor.didDrop()) {
                let lists = list.filter(item => item.id !== 'none');
                lists = [
                    ...lists,
                    {
                        id: 'none'
                    }
                ];
                setList(lists.map(item => {
                    delete item.index;
                    return item;
                }));
            }
        }
    });
    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = list[dragIndex];
            let noneCard = _findIndex(list, item => item.id === 'none');
            if ((!dragIndex && !(dragIndex === 0)) || (!dragIndex && hoverIndex === 0)) {
                setList(
                    update(list, {
                        $splice: [
                            [noneCard, 1],
                            [hoverIndex, 0, { id: 'none' }],
                        ],
                    }),
                );
            } else {
                if (dragIndex || dragIndex === 0) {
                    setList(
                        update(list, {
                            $splice: [
                                [dragIndex, 1],
                                [hoverIndex, 0, dragCard],
                            ],
                        }),
                    );
                }
            }
        },
        [list],
    );
    const valueChange = useCallback((value, num, e) => {
        setList(list.map(item => {
            if (item.id === value) {
                if (e) {
                    item.defaultValue[num] = parseInt(e, 10);
                } else {
                    item.defaultValue = item.defaultValue.filter((item, index) => index !== num);
                }

            }
            return item;
        }));
    });
    const delCard = useCallback((value) => {
        setList(_filter(list, current => current.id !== value));
    }, [list]);
    const renderCard = (card, index) => {
        return (
            <Card
                key={card.id}
                index={index}
                id={card.id}
                text={card.name}
                moveCard={moveCard}
                color={card.color}
                fieldType={card.fieldType}
                subType={card.subtype}
                valueChange={valueChange}
                defaultValue={card.defaultValue}
                delValue={delCard}
            />
        );
    };
    let { ruleListObj } = props;
    ruleListObj && ruleListObj(list);
    drop(ref);
    return (
        <div ref={ref} style={{ marginTop: '10px', 'width': '100%', maxWidth: '800px', minHeight: '100px', border: '1px solid #e8e8e8', padding: '5px' }}>
            {

                list.map((item, index) => {
                    return (
                        renderCard(item, index)
                    );
                })
            }
        </div>
    );
};
class SemanticTagsDetailTemplate extends SemanticTagsDetailRoute {
    render() {
        let {
            activeFlags,
            effectiveness,
            ruleList,
            ruleFields,
            recallKeyWord,
            similaSentences,
            recallRegular,
            highLevelConfig,
            title,
            DegreeData,
            ruleFieldList,
            // transList,
            // searchMaxScore,
            // searchMinScore,
            informationEntityshowlist,
            hitTest,
            testListFields,
            customRules,
            conceptWordList,
        } = this.privates;
        let {
            actions,
            locales
        } = this;
        let { TabPane } = Tabs;
        let { getFieldDecorator } = this.props.form;
        let {
            _errFlag
        } = this.privates.computes;
        return (
            // <div className="semantic-tags-detail-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.fix.semanticTags}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root semantic-tags-detail-page'>
                        <div className="semantic-tags-detail-page-body">
                            <Form {...formItemLayout}>
                                <Form.Item label={locales.components.pages.semanticTags.detail.name}>
                                    {
                                        getFieldDecorator(`title`, {
                                            initialValue: title,
                                            rules: [
                                                { required: true, message: ' ', whitespace: true }
                                            ],
                                        })(<Input disabled onChange={actions.changeTitles} />)
                                    }
                                </Form.Item>
                                <Form.Item hasFeedback validateStatus={_errFlag ? null : "error"} label={locales.components.pages.semanticTags.detail.standardSentences} className="semantic-tags-detail-page-body-standard-sentences">
                                    {
                                        getFieldDecorator(`standardSentences`, {
                                            initialValue: (!_isEmpty(similaSentences) && _find(similaSentences, current => current.standardFlag === 1) && _find(similaSentences, current => current.standardFlag === 1).content) || '',
                                            rules: [
                                                {
                                                    whitespace: true
                                                }
                                            ]
                                        })(<Input disabled onChange={actions.changeStandardSentences.params(!_isEmpty(similaSentences) && _find(similaSentences, current => current.standardFlag === 1) && _find(similaSentences, current => current.standardFlag === 1).order)} />)
                                    }
                                </Form.Item>
                                <Form.Item label={locales.components.pages.semanticTags.detail.effectiveness}>
                                    {
                                        getFieldDecorator(`effectiveness`, {
                                            initialValue: effectiveness ? effectiveness : effectiveness === 0 ? 0 : undefined,
                                            rules: [
                                                { required: true, message: ' ' }
                                            ],
                                        })(<Select disabled placeholder={locales.labels.pleaseChoice} onChange={actions.effectivenessChange} style={{ width: '120px' }}>
                                            {
                                                !_isEmpty(activeFlags) && activeFlags.map(item => {
                                                    return (<Select.Option value={item.value}>{item.label}</Select.Option>);
                                                })
                                            }

                                        </Select>)
                                    }
                                </Form.Item>
                                <div className='semantic-tags-detail-page-body-tabs'>
                                    <Tabs >
                                        <TabPane tab={locales.fix.qualify} key="1">
                                            <div className='semantic-tags-detail-page-body-tabs-list'>
                                                <h2>{locales.fix.recallKeyWord}</h2>
                                                <div>
                                                    {
                                                        !_isEmpty(recallKeyWord) && recallKeyWord.map(item => {
                                                            return (
                                                                <div className='semantic-tags-detail-page-body-tabs-list-limit'>
                                                                    <Form.Item hasFeedback validateStatus={_errFlag ? null : "error"}>
                                                                        {
                                                                            getFieldDecorator(`recallKeyWord-${item.id}`, {
                                                                                initialValue: item.text || undefined,
                                                                                rules: [
                                                                                    {
                                                                                        whitespace: true
                                                                                    }
                                                                                ]
                                                                            }
                                                                            )(<Input disabled style={{ width: '500px' }} placeholder={locales.labels.pleaseEnter} onChange={actions.recallKeyWordChangeField.params(item.id)} />)
                                                                        }
                                                                    </Form.Item>
                                                                    {/* <div className="semantic-tags-detail-tabs-form-item-button" onClick={actions.recallKeyWordDel.params(item.id)}>
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i>
                                                                    </div> */}
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div className='semantic-tags-detail-page-body-list-info'>
                                                        {/* <div className="semantic-tags-detail-tabs-form-item-button" onClick={actions.recallKeyWordAdd}>
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"></i>
                                                        </div> */}
                                                        <div className='semantic-tags-detail-page-body-list-info-tips'>
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                            <div>
                                                                <p>{locales.components.pages.semanticTags.detail.keyWordInfo1}</p>
                                                                <p>{locales.components.pages.semanticTags.detail.keyWordInfo2}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='semantic-tags-detail-page-body-tabs-list'>
                                                <h2>{locales.components.pages.semanticTags.detail.recallRegular}</h2>
                                                <div>
                                                    <div className='semantic-tags-detail-page-body-tabs-list-limit'>
                                                        <Form.Item hasFeedback validateStatus={_errFlag ? null : "error"}>
                                                            {
                                                                getFieldDecorator(`recallRegular`, {
                                                                    initialValue: recallRegular || undefined,
                                                                    rules: [
                                                                        {
                                                                            whitespace: true
                                                                        }
                                                                    ]
                                                                }
                                                                )(<Input disabled style={{ width: '500px' }} placeholder={locales.labels.pleaseEnter} onChange={actions.recallRegularChange} />)
                                                            }
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='semantic-tags-detail-page-body-tabs-list'>
                                                <h2>{locales.components.pages.semanticTags.detail.similaSentences}</h2>
                                                <div>
                                                    {
                                                        !_isEmpty(similaSentences) && _filter(similaSentences, current => current.standardFlag === 2).map(item => {
                                                            return (
                                                                <div className='semantic-tags-detail-page-body-tabs-list-limit'>
                                                                    <Form.Item>
                                                                        {
                                                                            getFieldDecorator(`similaSentences-${item.order}-0`, {
                                                                                initialValue: item.content || undefined,
                                                                                rules: [
                                                                                    {

                                                                                        whitespace: true
                                                                                    }
                                                                                ]
                                                                            }
                                                                            )(<Input disabled style={{ width: '500px' }} placeholder={locales.labels.pleaseEnter} onChange={actions.similaSentencesChangeField.params(item.order, 0)} />)
                                                                        }
                                                                    </Form.Item>
                                                                    <span className='semantic-tags-detail-page-body-tabs-list-simimlar'>{locales.components.pages.semanticTags.detail.simim}</span>
                                                                    <Form.Item>
                                                                        {
                                                                            getFieldDecorator(`similaSentences-${item.order}-1`, {
                                                                                initialValue: item.similarityDegree || 80,
                                                                                // rules: [{ required: true, message: ' ' }]
                                                                            }
                                                                            )(<InputNumber disabled style={{ width: '60px' }} min={80} max={100} onChange={actions.similaSentencesChangeField.params(item.order, 1)} />)
                                                                        }
                                                                    </Form.Item>
                                                                    <span className='semantic-tags-detail-page-body-tabs-list-simimlar'>%</span>
                                                                    {/* <div className="semantic-tags-detail-tabs-form-item-button" onClick={actions.similaSentencesDel.params(item.order)}>
                                                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i>
                                                                    </div> */}
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                    <div className='semantic-tags-detail-page-body-list-info'>
                                                        {/* <div className="semantic-tags-detail-tabs-form-item-button" onClick={actions.similaSentencesAdd.params(2)}>
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"></i>
                                                        </div> */}
                                                        <div className='semantic-tags-detail-page-body-list-info-tips'>
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                            <div>
                                                                <p>{locales.components.pages.semanticTags.detail.keyWordInfo3}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </TabPane>
                                        <TabPane tab={/* 规则 */locales.pages.templateTest.semanticTags.template.rule} key="2">
                                            <div className='semantic-tags-detail-page-body-list-rule'>
                                                <div className='semantic-tags-detail-page-body-tabs-senior-title'>
                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                    <span>{locales.components.pages.semanticTags.detail.keyWordInfo4}</span>
                                                </div>
                                                <DndProvider backend={HTML5Backend}>
                                                    <div className='semantic-tags-detail-page-body-list-rule-field'>
                                                        <h2>{locales.components.pages.semanticTags.detail.keyWord}</h2>
                                                        <div className='semantic-tags-detail-page-body-list-rule-field-info'>
                                                            <Form.Item>
                                                                <Input disabled style={{ width: '500px' }} onChange={actions.ruleFieldChange}></Input>
                                                            </Form.Item>
                                                            {
                                                                !_isEmpty(ruleFieldList) && ruleFieldList.map(item => {
                                                                    return <DraggableComponent {...item} name={item.keyWords} />;
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='semantic-tags-detail-page-body-list-rule-field'>
                                                        <div className='semantic-tags-detail-page-body-list-rule-field-info'>
                                                        </div>
                                                    </div>
                                                    <div className='semantic-tags-detail-page-body-list-rule-field'>
                                                        <h2>{locales.components.pages.semanticTags.detail.entity}</h2>
                                                        <div className='semantic-tags-detail-page-body-list-rule-field-info'>

                                                            {
                                                                !_isEmpty(informationEntityshowlist) && informationEntityshowlist.map(item => {
                                                                    return <DraggableComponent {...item} name={item.label} />;
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='semantic-tags-detail-page-body-list-rule-field'>
                                                        <h2>{locales.components.pages.semanticTags.detail.ruleField}</h2>
                                                        <div className='semantic-tags-detail-page-body-list-rule-field-info'>

                                                            {
                                                                !_isEmpty(conceptWordList) && conceptWordList.map(item => {
                                                                    return <DraggableComponent {...item} name={item.label} />;
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        ruleList.map(item => {
                                                            return (
                                                                <div className='semantic-tags-detail-page-body-list-rule-field'>
                                                                    <h2>{item.name}</h2>
                                                                    <div className='semantic-tags-detail-page-body-list-rule-field-info'>

                                                                        {
                                                                            _filter(ruleFields, current => current.fieldType === item.type).map(items => {
                                                                                return <DraggableComponent  {...items} color={item.color} name={items.label} />;
                                                                            })
                                                                        }

                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div>
                                                        <h2>{locales.components.pages.semanticTags.detail.customRule}</h2>
                                                        <div>
                                                            <MyDropTarget ruleListObj={actions.ruleListObj} customRules={customRules} />
                                                        </div>
                                                    </div>
                                                </DndProvider>

                                            </div>
                                        </TabPane>
                                        <TabPane tab={locales.components.pages.semanticTags.detail.fanhua} key="3">
                                            <div className='semantic-tags-detail-page-body-tabs-list'>
                                                <h2>{locales.components.pages.semanticTags.detail.similarSentences}</h2>
                                                <div>
                                                    {
                                                        !_isEmpty(similaSentences) && _filter(similaSentences, current => current.standardFlag === 0).map(item => {
                                                            let borderStyle = !_isEmpty(DegreeData) && _find(DegreeData, current => current.standard_index === item.order || current.input_index === item.order) && '1px solid #f5222d';
                                                            return (
                                                                <div className='semantic-tags-detail-page-body-tabs-list-limit-generalization'>
                                                                    <div className='semantic-tags-detail-page-body-tabs-list-form'>
                                                                        <Form.Item >
                                                                            {
                                                                                getFieldDecorator(`similaSentences-${item.order}-0`, {
                                                                                    initialValue: item.content || undefined,
                                                                                    rules: [
                                                                                        {
                                                                                            whitespace: true
                                                                                        }
                                                                                    ]
                                                                                }
                                                                                )(<Input disabled style={{ width: '500px', border: `${borderStyle}` }} onChange={actions.similaSentencesChangeFieldTwo.params(item.order)} />)
                                                                            }
                                                                        </Form.Item>
                                                                        {/* <div className="semantic-tags-detail-tabs-form-item-button" onClick={actions.similaSentencesDel.params(item.order)}>
                                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i>
                                                                        </div> */}
                                                                    </div>
                                                                    <If condition={item.trainingSource === 1}>
                                                                        <div className="semantic-tags-detail-tabs-form-item-training-info">
                                                                            {`${locales.components.pages.semanticTags.detail.new}『${item.createUserUsername}』 ${item.updateTime}`}
                                                                        </div>
                                                                    </If>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div className='semantic-tags-detail-page-body-list-info'>
                                                        {/* <div className="semantic-tags-detail-tabs-form-item-button" onClick={actions.similaSentencesAdd.params(0)}>
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"></i>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tab={locales.components.pages.semanticTags.detail.test} key="5">
                                            <div className='semantic-tags-detail-page-body-tabs-test'>
                                                <div className='semantic-tags-detail-page-body-tabs-test-hit'>
                                                    <h2>{locales.components.pages.semanticTags.detail.testOk}</h2>
                                                    <div className='semantic-tags-detail-page-body-tabs-test-hit-limit'>
                                                        <Input disabled onChange={actions.testFieldsChange} />
                                                        <div className='semantic-tags-detail-page-body-tabs-test-hit-limit-button'>
                                                            <Button disabled onClick={actions.testSaveUpdate}>{locales.components.pages.semanticTags.detail.test}</Button>
                                                            <div style={{ marginTop: '10px' }}>
                                                                {
                                                                    !_isEmpty(testListFields) && !_isEmpty(hitTest) && testListFields.map(item => {
                                                                        return (
                                                                            <div className='semantic-tags-detail-page-body-tabs-test-hit-limit-button-info'>
                                                                                <div>
                                                                                    {
                                                                                        !_isEmpty(hitTest) && _find(hitTest, current => current.matchType === item.value) && _find(hitTest, current => current.matchType === item.value).isHit === 1 ? (
                                                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yixuanzhong" style={{ marginRight: '3px' }}></i>
                                                                                        ) : (<i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai" style={{ marginRight: '3px' }}></i>)
                                                                                    }
                                                                                    {
                                                                                        !_isEmpty(hitTest) && _find(hitTest, current => current.matchType === item.value) && _find(hitTest, current => current.matchType === item.value).isHit === 1 ? (<span>{item.text}</span>) : item.name
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        !_isEmpty(hitTest) && _find(hitTest, current => current.matchType === item.value) && _find(hitTest, current => current.matchType === item.value).isHit === 1 &&
                                                                                        (_isEqual(item.value, 3)
                                                                                            ? _find(hitTest, current => current.matchType === item.value).recallWords
                                                                                            : _isEqual(item.value, 2)
                                                                                                ? _find(hitTest, current => current.matchType === item.value).similarSentences
                                                                                                && _find(hitTest, current => current.matchType === item.value).similarSentences.map((item, index) => {
                                                                                                    return <div style={{ marginLeft: '10px' }}>{index + 1}.{item}</div>;
                                                                                                }) : _isEqual(item.value, 1)
                                                                                                    ? _find(hitTest, current => current.matchType === item.value).similarSentences
                                                                                                    && _find(hitTest, current => current.matchType === item.value).similarSentences.map((item, index) => {
                                                                                                        return <div style={{ marginLeft: '10px' }}>{index + 1}.{item}</div>;
                                                                                                    }) : null)
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tab={locales.components.pages.semanticTags.detail.senior} key="6">
                                            <div className='semantic-tags-detail-page-body-tabs-senior'>
                                                <div className='semantic-tags-detail-page-body-tabs-senior-title'>
                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                    <span>{locales.components.pages.semanticTags.detail.keyWordInfo5}</span>
                                                </div>
                                                <div className='semantic-tags-detail-page-body-tabs-senior-list'>
                                                    <h2>{locales.components.pages.semanticTags.detail.seniorList}</h2>
                                                    <div className='semantic-tags-detail-page-body-tabs-senior-list-info'>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <Form.Item>
                                                                {
                                                                    getFieldDecorator(`crossSentence-on`, {
                                                                        initialValue: highLevelConfig.crossSentence.on
                                                                    })(
                                                                        <Select disabled style={{ width: '80px' }} onChange={actions.fieldTestingChange} placeholder={locales.labels.pleaseChoice}>
                                                                            <Select.Option value={1}>{locales.components.pages.semanticTags.detail.up}</Select.Option>
                                                                            <Select.Option value={0}>{locales.components.pages.semanticTags.detail.down}</Select.Option>
                                                                        </Select>
                                                                    )
                                                                }

                                                            </Form.Item>
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo6}</span>
                                                        </div>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo7}</span>
                                                            <Form.Item style={{ width: '65px', marginLeft: '5px' }}>
                                                                {
                                                                    getFieldDecorator(`crossSentence-crossLength`, {
                                                                        initialValue: highLevelConfig.crossSentence.crossLength || 1
                                                                    })(
                                                                        <InputNumber disabled style={{ width: '60px' }} onChange={actions.crossSentenceChange.params('crossLength')} />
                                                                    )
                                                                }

                                                            </Form.Item>
                                                            <span>{locales.components.pages.semanticTags.detail.ju}</span>
                                                        </div>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo8}</span>
                                                            <Form.Item style={{ width: '65px', marginLeft: '5px' }}>
                                                                {
                                                                    getFieldDecorator(`crossSentence-differentRoleLength`, {
                                                                        initialValue: highLevelConfig.crossSentence.differentRoleLength || 0
                                                                    })(
                                                                        <InputNumber disabled style={{ width: '60px' }} onChange={actions.crossSentenceChange.params('differentRoleLength')} />
                                                                    )
                                                                }
                                                            </Form.Item>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo9}</span>
                                                        </div>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo10}</span>
                                                            <Form.Item style={{ width: '65px', marginLeft: '5px' }}>
                                                                {
                                                                    getFieldDecorator(`crossSentence-maxCrossLength`, {
                                                                        initialValue: highLevelConfig.crossSentence.maxCrossLength || 100
                                                                    })(
                                                                        <InputNumber disabled style={{ width: '60px' }} onChange={actions.crossSentenceChange.params('maxCrossLength')} />
                                                                    )
                                                                }
                                                            </Form.Item>
                                                            <span>{locales.fix.afterFixUnit}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='semantic-tags-detail-page-body-tabs-senior-list'>
                                                    <h2>{locales.components.pages.semanticTags.detail.simimNo}</h2>
                                                    <div className='semantic-tags-detail-page-body-tabs-senior-list-info'>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <Form.Item>
                                                                {
                                                                    getFieldDecorator(`duplicate-on`, {
                                                                        initialValue: highLevelConfig.duplicate.on
                                                                    })(
                                                                        <Select disabled style={{ width: '80px' }} onChange={actions.similarityRemovalChange} placeholder={locales.labels.pleaseChoice}>
                                                                            <Select.Option value={1}>{locales.components.pages.semanticTags.detail.up}</Select.Option>
                                                                            <Select.Option value={0}>{locales.components.pages.semanticTags.detail.down}</Select.Option>
                                                                        </Select>
                                                                    )
                                                                }
                                                            </Form.Item>
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo14}</span>
                                                        </div>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo11}</span>
                                                            <Form.Item style={{ width: '65px', marginLeft: '5px' }}>
                                                                {
                                                                    getFieldDecorator(`duplicate-threshold`, {
                                                                        initialValue: highLevelConfig.duplicate.threshold || 80
                                                                    })(
                                                                        <InputNumber disabled style={{ width: '60px' }} min={80} max={100} onChange={actions.duplicateChange.params('threshold')} />
                                                                    )
                                                                }
                                                            </Form.Item>
                                                            <span>%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='semantic-tags-detail-page-body-tabs-senior-list semantic-tags-detail-page-body-tabs-senior-newh2'>
                                                    <h2>{locales.components.pages.semanticTags.detail.seniorField}</h2>
                                                    <div className='semantic-tags-detail-page-body-tabs-senior-list-info'>
                                                        <div className='semantic-tags-detail-page-body-tabs-senior-list-info-field'>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo12}</span>
                                                            <Form.Item style={{ width: '65px', marginLeft: '5px' }}>
                                                                {
                                                                    getFieldDecorator(`similarityDegreeThreshold-threshold`, {
                                                                        initialValue: highLevelConfig.similarityDegreeThreshold.threshold || 80
                                                                    })(
                                                                        <InputNumber disabled style={{ width: '60px' }} min={80} max={100} onChange={actions.similarityDegreeThresholdChange.params('threshold')} />
                                                                    )
                                                                }
                                                            </Form.Item>
                                                            <span>{locales.components.pages.semanticTags.detail.keyWordInfo13}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                                {/* <div style={{ marginLeft: '30px' }}>
                                    <Button type='primary' onClick={actions.save.params(false)} style={{ marginRight: '10px' }}>{locales.labels.save}</Button>
                                    <Button type='primary' onClick={actions.cancel}>{locales.labels.cancel}</Button>
                                </div> */}
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SemanticTagsDetailTemplate;