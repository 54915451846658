/*
 * @Author: 唐毓斌
 * @Description: 结果分析
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getIntelligentPartnerTaskInfosResultAnalysisDetailByTaskId } from 'src/api/intelligentPartnerTaskInfos/result/analysis/detail/{taskId}';
import { getIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskId } from 'src/api/intelligentPartnerTaskInfos/result/analysis/overview/{taskId}';
import {
    FinishRecordResultAnalysisDetail,
    FinishRecordResultAnalysisOverview,
} from 'src/api/types';
import { Button, Empty, Radio, Space } from 'udesk-ui';
import { linkTo } from 'src/util/link';
import Echarts from 'echarts';
import CustomCard from '../components/CutomCard';
import useResizeObserver from 'use-resize-observer';
import './ResultAnalysis.less';
import UdeskLocales from 'UdeskLocales';

const Charts = React.memo((props: any) => {
    const {length, options, onClick, content} = props;
    const chartsRef = useRef<any>(null);
    const devRef = useRef<HTMLDivElement>(null);

    useResizeObserver<HTMLDivElement>({
        ref: content,
        onResize() {
            if (chartsRef.current) {
                chartsRef.current.resize({});
            }
        }
    });

    useEffect(() => {
        if (devRef?.current) {
            chartsRef.current = Echarts.init(devRef.current);
        }
        return () => {
            if (chartsRef.current) {
                chartsRef.current.dispose();
            }
        };
    }, []);

    useEffect(() => {
        if (chartsRef.current) {
            chartsRef.current.clear();
            chartsRef.current.setOption(options);
            chartsRef.current.resize({
                height: ((length || 1) * 2 - 1) * 22 + 56
            });
        }
    }, [length, options]);

    useEffect(() => {
        if (chartsRef.current) {
            chartsRef.current.off('click');
            chartsRef.current.on('click', onClick);
        }
    }, [onClick]);

    return (
        <div
            ref={devRef}
            style={{ width: '100%', flex: 0 }}
        />
    );
});


const ResultAnalysis: React.FC<any> = (props) => {
    const { taskId, lessonId, isPPT } = props;
    const [overview, setOverview] = useState<FinishRecordResultAnalysisOverview>();
    const [scoreDetail, setScoreDetail] = useState<FinishRecordResultAnalysisDetail>();
    const [avgScoreData, setAvgScoreData] = useState<FinishRecordResultAnalysisDetail[]>();
    const [point, setPoint] = useState<any>();
    const contentRef = useRef<HTMLDivElement>(null);

    const fetchOverviewData = useCallback(() => {
        getIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskId({
            segments: { taskId: taskId },
        }).then((resp) => {
            setOverview(resp.data);
        });
    }, [taskId]);

    const fetchDetailData = useCallback((sortWayParams?: string) => {
        const sortWay = sortWayParams || 'orderByFlow';
        getIntelligentPartnerTaskInfosResultAnalysisDetailByTaskId({
            segments: { taskId: taskId },
            params: { sortWay }, // orderByScore ：按得分排序 orderByFlow : 按流程排序
        }).then((resp) => {
            const avgScoreData = 'orderByFlow' === sortWay ? resp.data?.reverse() : resp.data;
            const scoreDetail = Array.isArray(avgScoreData) && avgScoreData.length > 0
                ? avgScoreData?.[avgScoreData.length - 1]
                : undefined;

            setAvgScoreData(avgScoreData);
            setScoreDetail(scoreDetail);
            setPoint(scoreDetail?.detail?.[0]);
        });
    }, [taskId]);
    
    const onSortWayChange = (e) => {
        fetchDetailData(e.target.value);
    };

    const onClickAvgScore = useCallback((params) => {
        if (params.componentType === 'yAxis') {
            const dataIndex = params.event.target.anid.replace('label_', '');
            setScoreDetail(avgScoreData?.[dataIndex]);
        } else {
            setScoreDetail(avgScoreData?.[params.dataIndex]);
        }
    }, [avgScoreData]);

    const onClickScoreDetail = useCallback((params) => {
        if (params.componentType === 'yAxis') {
            const dataIndex = params.event.target.anid.replace('label_', '');
            setPoint(scoreDetail?.detail?.[dataIndex]);
        } else {
            setPoint(scoreDetail?.detail?.[params.dataIndex]);
        }
    }, [scoreDetail?.detail]);

    const avgScoreOptions = useMemo(() => {        
        return {
            color: ['#dbdcdf', '#0376FB'],
            grid: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 0,
                containLabel: true,
            },
            xAxis: {
                show: false,
                type: 'value',
                boundaryGap: [0, 0.01],
                max: function (value) {                    
                    return avgScoreData?.reduce((p, item) => Math.max(item.totalScore ?? 0, p), 0);
                },
            },
            yAxis: [
                {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    triggerEvent: true,
                    data: avgScoreData?.map((item) => item.nodeName),
                },
                {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    position: 'right',
                    data: avgScoreData?.map(
                        (item) => `${item.avgScore ?? '-'}/${item.totalScore ?? '-'}`
                    ),
                },
            ],
            series: [
                {
                    name: /* 总分 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.totalScore,
                    type: 'bar',
                    emphasis: { disabled: true },
                    barMaxWidth: 22,
                    barGap: '-100%',
                    data: avgScoreData?.map((item) => item.totalScore),
                },
                {
                    name: /* 平均分 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.average,
                    type: 'bar',
                    emphasis: { disabled: true },
                    barMaxWidth: 22,
                    // barGap: '-100%',
                    data: avgScoreData?.map((item) => item.avgScore), //[5, 6, 7, 8, 9, 10],
                },
            ],
        };

    }, [avgScoreData]);

    const scoreDetailOptions = useMemo(() => {
        return {
            color: ['#dbdcdf', '#0376FB'],
            grid: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 0,
                containLabel: true,
            },
            xAxis: {
                show: false,
                type: 'value',
                boundaryGap: [0, 0.01],
                max: 1,
            },
            yAxis: [
                {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    data: scoreDetail?.detail?.map((item) => {
                        if (item.scoreRuleName) {
                            if (item.scoreRuleName.length > 10) {
                                return item.scoreRuleName.slice(0, 10) + '...';
                            }
                            return item.scoreRuleName;
                        }
                        return '';
                    }),
                },
                {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    position: 'right',
                    data: scoreDetail?.detail?.map(
                        (item) => Number(parseFloat(item.hitRatio || '') * 100).toFixed(2) + '%'
                    ),
                },
            ],
            series: [
                {
                    name: /* 总数 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.total,
                    type: 'bar',
                    emphasis: { disabled: true },
                    barMaxWidth: 22,
                    barGap: '-100%',
                    data: scoreDetail?.detail?.map((item) => 1),
                },
                {
                    name: /* 要点命中次数 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.keyHitCount,
                    type: 'bar',
                    emphasis: { disabled: true },
                    barMaxWidth: 22,
                    data: scoreDetail?.detail?.map((item) => parseFloat(item.hitRatio || '')),
                },
            ],
        };
    }, [scoreDetail?.detail]);

    useEffect(() => {
        fetchOverviewData();
        fetchDetailData();
    }, [fetchDetailData, fetchOverviewData]);

    return (
        <Space className="result-analysis-container" direction="vertical" size={'middle'}>
            <div className="result-analysis-top-wrapper" ref={contentRef}>
                <CustomCard
                    containerStyle={{ marginRight: 16, width: 260 }}
                    title={/* 练习平均分 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.practiceAverageScore}
                    content={
                        <div
                            className="result-card-content-wrapper"
                            style={{ alignSelf: 'center', justifyContent: 'center' }}
                        >
                            <span className="score-content">
                                {parseInt(overview?.avgScore?.avgScore || '0', 10)}
                            </span>
                        </div>
                    }
                />
                <CustomCard
                    containerStyle={{ marginRight: 16, width: 260 }}
                    title={/* 平均练习时长（min） */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.averagePracticeDurationmin}
                    content={
                        <div
                            className="result-card-content-wrapper"
                            style={{ alignSelf: 'center', justifyContent: 'center' }}
                        >
                            <span className="score-content">{overview?.avgCostTime}</span>
                        </div>
                    }
                />
                <CustomCard
                    containerStyle={{ marginRight: 16, width: 260 }}
                    title={/* 最高分Top3 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.topOfTheHighestScore}
                    content={
                        <div className="result-card-content-wrapper">
                            {(overview?.top3HighestScoreUser || [])
                                .slice(0, 3)
                                .map((item, index) => (
                                    <div
                                        key={index}
                                        className="top-list-item"
                                    >
                                        <span>{`Top${index + 1}`}</span>
                                        <span
                                            style={{
                                                marginLeft: 8,
                                                color: '#257aed',
                                                overflow: 'hidden',
                                                flex: 1,
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {item.userName}
                                        </span>
                                        <span
                                            style={{ marginLeft: 18, color: '#257aed' }}
                                        >{`${item.score}${/* 分 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.branch}`}</span>
                                    </div>
                                ))}
                        </div>
                    }
                />
                <CustomCard
                    containerStyle={{ width: 400 }}
                    title={/* 易错项Top5 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.errorProneItemTop}
                    content={
                        <div className="result-card-content-wrapper">
                            {(overview?.top5FallibleWords || []).slice(0, 5).map((item, index) => (
                                <div key={index} className="top-list-item">
                                    <span>{`Top${index + 1}`}</span>
                                    <span
                                        title={item.ruleName}
                                        style={{
                                            marginLeft: '8px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            flex: 1,
                                        }}
                                    >
                                        {item.ruleName}
                                    </span>
                                    <span style={{ marginLeft: 10 }}>{`${/* 命中率 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.hitRate}`}</span>
                                    <span style={{ marginLeft: 0, color: 'red' }}>
                                        {Number(parseFloat(item.hitRatio || '0') * 100).toFixed(0) +
                                            '%'}
                                    </span>
                                </div>
                            ))}
                        </div>
                    }
                />
            </div>
            <div className="result-analysis-content-wrapper">
                <CustomCard
                    containerStyle={{ flex: 1, marginRight: 10 }}
                    title={
                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                            <span style={{ fontWeight: 'bold', lineHeight: '30px' }}>
                                {
                                    isPPT(
                                        () => /* 话术要点命中率 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.hitRateOfKeyPointsInScript,
                                        () => /* 话术平均得分 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.averageScriptScore
                                    )
                                }
                            </span>
 
                            {
                                isPPT(
                                    () => null,
                                    () => (
                                        <>
                                            <div>
                                                <Radio.Group defaultValue="orderByFlow" onChange={onSortWayChange}>
                                                    <Radio.Button value="orderByFlow">{/* 按流程排序 */}{UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.sortByProcess}</Radio.Button>
                                                    <Radio.Button value="orderByScore">{/* 按得分排序 */}{UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.sortByScore}</Radio.Button>
                                                </Radio.Group>
                                            </div>
                                            <div style={{ color: '#00000073', lineHeight: '30px' }}>
                                                {isPPT(
                                                    () => /* 该页平均分/该页总分 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.averageScoreOfThisPagetotalScoreOfThisPage,
                                                    () => /* 该项平均分/该项总分 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.averageScoreOfThisItemtotalScoreOfThisItem
                                                )}
                                            </div>
                                        </>
                                    )
                                )
                            }
                        </div>
                    }
                    content={
                        isPPT(
                            () => scoreDetail?.detail?.length ,
                            () => avgScoreData?.length
                        ) > 0 ? (
                                <Charts 
                                    content={contentRef}
                                    options={
                                        isPPT(
                                            () => scoreDetailOptions,
                                            () => avgScoreOptions
                                        )
                                    }
                                    onClick= {
                                        isPPT(() => onClickScoreDetail, () => onClickAvgScore)
                                    }
                                    length={
                                        isPPT(
                                            () => scoreDetail?.detail?.length,
                                            () => avgScoreData?.length,
                                        )
                                    } 
                                />
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                    }
                />
                <CustomCard
                    containerStyle={{
                        flex: 1,
                        marginLeft: 10,
                    }}
                    title={
                        <span>
                            <span style={{ fontWeight: 'bold', marginRight: 6 }}>
                                {
                                    isPPT(
                                        () => point?.scoreRuleName,
                                        () => scoreDetail?.nodeName
                                    )
                                }
                            </span>
                            <span style={{ color: '#777' }}>{/* 详情 */}{UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.details}</span>
                        </span>
                    }
                    extra={
                        <Button
                            type="link"
                            onClick={() => {
                                linkTo(props.history, 'courseManagementFlow', {
                                    id: lessonId,
                                });
                            }}
                        >{/* 查看规则 */}{UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.viewRules}</Button>
                    }
                    content={
                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            {
                                isPPT(
                                    () => {
                                        if (point) {
                                            return (
                                                <div className="detail-score-standard-words">
                                                    <div>{/* 标准话术 */}{UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.standardScript}</div>
                                                    {point.words}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            );
                                        }
                                    },
                                    () => {
                                        const list = new Array<any>();
                                        if (scoreDetail?.detail?.length) {
                                            list.push(
                                                <Charts 
                                                    content={contentRef}
                                                    options={scoreDetailOptions}
                                                    length={scoreDetail?.detail?.length}
                                                />
                                            );
                                        }
                                        if (scoreDetail?.standardWords) {
                                            list.push(
                                                <div className="detail-score-standard-words">
                                                    <div>{/* 标准话术 */}{UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.standardScript}</div>
                                                    {scoreDetail?.standardWords}
                                                </div>
                                            );
                                        }

                                        if (list.length > 0) {
                                            return list;
                                        }
                                        return (
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        );
                                    },
                                )
                            }
                        </div>
                    }
                />
            </div>
        </Space>
    );
};

export default ResultAnalysis;
