// import { Redirect } from 'react-router-dom';
import React, { useState, useEffect, useMemo } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Tabs } from 'udesk-ui';
import Locales from 'UdeskLocales';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { getCurrentRouteName, isSalesSubApp } from 'Udesk/system/subApp';

const { TabPane } = Tabs;

function IntelligentTags(props) {
    const locales = Locales['current'];
    const company = Udesk.data.init.company ? Udesk.data.init.company : null;
    const ADD_TYPES = [
        {
            pathName: getCurrentRouteName('smartConversationIntelligentTags'),
            key: 'intelligent-tags',
            text: locales.components.pages.intelligentTags.subTitle.common,
            id: 'smart-conversation',
        },
        {
            pathName: getCurrentRouteName('wechatIntelligentTags'),
            key: 'wechat-tags',
            text: locales.components.pages.intelligentTags.subTitle.wecom,
            id: 'wechat',
        },
        {
            pathName: 'sessionLabel',
            key: 'session-label',
            text: locales.components.pages.intelligentTags.sessionLabelTitle,
            id: 'session-label',
        },
        {
            pathName: 'keyEvents',
            key: 'key-events',
            text: locales.components.pages.intelligentTags.keyEventsTitle,
            id: 'key-events',
        },
        {
            pathName: 'supervision',
            key: 'supervision',
            text: /* 监督项 */locales.pages.intelligentAnalysis.intelligentTags.supervisionItem,
            id: 'supervision',
        },
        {
            pathName: 'customerLabel',
            key: 'customer-label',
            text: /* 客户标签 */locales.pages.intelligentAnalysis.intelligentTags.customerLabel,
            id: 'customer-label',
        },
    ].filter((item) => {
        if (isSalesSubApp()) {
            return (
                item.id === 'session-label' ||
                item.id === 'key-events' ||
                item.id === 'supervision' ||
                item.id === 'customer-label'
            );
        } else {
            return (
                (item.id !== 'wechat' ||
                    (company &&
                        company.enabledWeComInspect !== 0 &&
                        Udesk.data.init.user &&
                        Udesk.data.init.user.hasFeature('smart:wecomTag:root'))) &&
                item.id !== 'session-label' &&
                item.id !== 'key-events' &&
                item.id !== 'supervision' &&
                item.id !== 'customer-label'
            );
        }
    });
    const [activeKey, setActiveKey] = useState('intelligent-tags');
    const onAddTypeChange = (activeKey) => {
        setActiveKey(activeKey);
        const tab = ADD_TYPES.find((t) => t.key === activeKey) ?? {};
        const routeOptions = {
            history: props.history,
            routeName: tab.pathName,
            pathParams: { taskId: props.match.params.taskId },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() => {
        const currentPath = ADD_TYPES.find((item) => props.history.location.pathname.indexOf(item.id) >= 0);
        currentPath && setActiveKey(currentPath.key);
    }, [props.history.location]);
    const title = useMemo(() => {
        if (props.history.location.pathname.includes('/smart-conversation/new')) {
            return locales.components.pages.intelligentTags.detail.new;
        } else if (props.history.location.pathname.includes('/smart-conversation/edit')) {
            return locales.components.pages.intelligentTags.detail.edit;
        } else if (props.history.location.pathname.includes('/session-label/new')) {
            return locales.pages.intelligentAnalysis.intelligentTags.newSessionLabel;
        } else if (props.history.location.pathname.includes('/session-label/edit')) {
            return locales.pages.intelligentAnalysis.intelligentTags.editSessionLabel;
        } else if (props.history.location.pathname.includes('/key-events/new')) {
            return locales.pages.intelligentAnalysis.intelligentTags.newKeyEvent;
        } else if (props.history.location.pathname.includes('/key-events/edit')) {
            return locales.pages.intelligentAnalysis.intelligentTags.editKeyEvents;
        } else if (props.history.location.pathname.includes('/supervision/new')) {
            return /* 新建监督项规则 */locales.pages.intelligentAnalysis.intelligentTags.newSupervisionItemRule;
        } else if (props.history.location.pathname.includes('/supervision/edit')) {
            return /* 编辑监督项规则 */locales.pages.intelligentAnalysis.intelligentTags.editSupervisionItemRule;
        } else if (props.history.location.pathname.includes('/customer-label/new')) {
            return /* 新建客户标签 */locales.pages.intelligentAnalysis.intelligentTags.newCustomerLabel;
        } else if (props.history.location.pathname.includes('/customer-label/edit')) {
            return /* 编辑客户标签 */locales.pages.intelligentAnalysis.intelligentTags.editCustomerLabel;
        } else {
            return locales.components.pages.intelligentTags.title;
        }
    }, [props.history.location.pathname]);
    const showFooter = useMemo(() => {
        const hiddenFooterUrl = [
            '/smart-conversation/new',
            '/smart-conversation/edit',
            '/session-label/new',
            '/session-label/edit',
            '/key-events/new',
            '/key-events/edit',
            '/supervision/new',
            '/supervision/edit',
            '/customer-label/new',
            '/customer-label/edit',
        ];
        return !hiddenFooterUrl.some((url) => props.history.location.pathname.includes(url));
    }, [props.history.location.pathname]);
    const backToIndex = () => {
        // window.history.back();
        let routeOptions = {
            history: props.history,
            routeName: 'intelligentTagsIndex',
        };
        const currentRoute = ADD_TYPES.find((i) => props.history.location.pathname.includes(i.id));
        if (currentRoute) {
            routeOptions.routeName = currentRoute.pathName;
        }
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    return (
        <Page
            pageBodyClassName='intelligent-tags-page-index'
            bodyStyle={{ height: '100%' }}
            title={title}
            padding={!props.history.location.pathname.includes('supervision')}
            isRouteEntry={true}
            onBack={!showFooter ? backToIndex : undefined}
            footer={
                showFooter ? (
                    <Tabs className='intelligent-tags-page-index-tabs' activeKey={activeKey} onChange={onAddTypeChange}>
                        {props.routes
                            .filter((r) => ADD_TYPES.find((t) => t.pathName === r.name))
                            .map((item, i) => {
                                const tab = ADD_TYPES.find((t) => t.pathName === item.name) ?? {};
                                return <TabPane tab={tab.text} key={tab.key}></TabPane>;
                            })}
                    </Tabs>
                ) : undefined
            }
        >
            <SubRoutes
                route={props.routes.find((r) => r.name === ADD_TYPES.find((i) => i.key === activeKey)?.pathName)}
            />
        </Page>
    );
}

export default React.memo(IntelligentTags);
