import Home from '../../pages/home/index';

// 数据分析
import DataAnalysis from 'Component/pages/conversation-analysis-data-analysis';
//舆情发现
import SentimentAnalysis from 'Component/pages/intelligent-analysis/sentiment-analysis';
import SentimentAnalysisIndex from 'Component/pages/intelligent-analysis/sentiment-analysis/index';
//业务分析
import BusinessAnalysis from 'Component/pages/intelligent-analysis/business-analysis.js';
import BusinessAnalysisCreate from 'Component/pages/intelligent-analysis/business-analysis/detail/index';
import BusinessAnalysisList from 'Component/pages/intelligent-analysis/business-analysis/index.tsx';
import BusinessAnalysisDetail from 'Component/pages/intelligent-analysis/business-analysis/detail/index';
// 分析报表
import AnalysisReport from 'Component/pages/intelligent-analysis/analysis-report';
// 标签分析
import IntelligentTagsAnalysis from 'Component/pages/data-analysis/intelligent-tags-analysis/index';
//客户中心
import ClientCenterEntry from 'Component/pages/client-center';
import ClientManage from 'Component/pages/client-center/client-manage';
import ClientManageRoot from 'Component/pages/client-center/index';
import ClientManageWechat from 'Component/pages/client-center/wechat-client-manage';
// 客户画像
import CustomerPortrait from 'Component/pages/intelligent-analysis/customer-portrait';
import CustomerPortraitIndex from 'Component/pages/intelligent-analysis/customer-portrait/index';
// 语义智能-1、智能词库
import SmartWordLibrary from 'Component/pages/semantic-intelligence/smart-words-library';
import AttentionWords from 'Component/pages/semantic-intelligence/smart-words-library/attention-words';
import IgnoreWords from 'Component/pages/semantic-intelligence/smart-words-library/ignore-words';
import ProfessionalWords from 'Component/pages/semantic-intelligence/smart-words-library/professional-words';
import SynonymlWords from 'Component/pages/semantic-intelligence/smart-words-library/synonym-words';
import SensitiveWords from 'Component/pages/semantic-intelligence/smart-words-library/sensitive-words';
import WordsWeight from 'Component/pages/semantic-intelligence/smart-words-library/words-weight';
import CorpusData from 'Component/pages/semantic-intelligence/smart-words-library/corpus-data';
import TypoLibrary from 'Component/pages/semantic-intelligence/smart-words-library/typo-library';

// 语义智能-2、语义标签
import SemanticIntelligence from 'Component/pages/conversation-analysis-semantic-intelligence';
import SemanticTags from 'Component/pages/semantic-intelligence/semantic-tags';
import SemanticTagsIndex from 'Component/pages/semantic-intelligence/semantic-tags/index';
import SemanticTagsDetail from 'Component/pages/semantic-intelligence/semantic-tags/detail';

// 智能标签
import IntelligentTags from 'Component/pages/intelligent-analysis/intelligent-tags';
import SmartConversationIntelligentTags from 'Component/pages/intelligent-analysis/smart-conversation-intelligent-tags';
import IntelligentTagsIndex from 'Component/pages/intelligent-analysis/intelligent-tags/index';
import IntelligentTagsDetail from 'Component/pages/intelligent-analysis/intelligent-tags/detail';
import WechatIntelligentTags from 'Component/pages/intelligent-analysis/wechat-intelligent-tags/index.tsx';

// 语义智能-3、信息实体
import InformationEntities from 'Component/pages/semantic-intelligence/information-entities';
import InformationEntitiesIndex from 'Component/pages/semantic-intelligence/information-entities/index';
import InformationEntitiesDetail from 'Component/pages/semantic-intelligence/information-entities/detail';

// 知识库
import KnowledgeBase from 'Component/pages/semantic-intelligence/knowledge-base';
import KnowledgeBaseIndex from 'Component/pages/semantic-intelligence/knowledge-base/index';
import KnowledgeBaseDetail from 'Component/pages/semantic-intelligence/knowledge-base/detail';

//语义智能-6、概念词
import ConceptWord from 'Component/pages/semantic-intelligence/concept-word';
import ConceptWordIndex from 'Component/pages/semantic-intelligence/concept-word/index';
import ConceptWordDetail from 'Component/pages/semantic-intelligence/concept-word/detail';

//训练中心
import TrainingCenter from 'Component/pages/conversation-analysis-training-center';
import FastTrainingIndex from 'Component/pages/training-center/fast-training';
import TrainingHistoryIndex from 'Component/pages/training-center/training-history';
//分类训练
import ClassifiedTraining from 'Component/pages/training-center/classified-training';
import ClassifiedTrainingIndex from 'Component/pages/training-center/classified-training/index';
import ClassifiedTrainingDetail from 'Component/pages/training-center/classified-training/detail/index';
//分类训练-训练历史
import ClassifiedTrainingHistoryIndex from 'Component/pages/training-center/classified-training-history/index';
//聚类标注
import ClusteringAnnotationIndex from 'Component/pages/training-center/clustering-annotation';

// 通话管理
import CallManage from 'Component/pages/call-manage';
import CallManageIndex from 'Component/pages/call-manage/index';
import CallManageView from 'Component/pages/call-manage/detail';

// 对话管理
import DialogueManage from 'Component/pages/dialogue-manage';
import DialogueManageIndex from 'Component/pages/dialogue-manage/index';
import DialogueManageView from 'Component/pages/dialogue-manage/detail';

// 工单管理
import TicketManage from 'Component/pages/ticket-manage';
import TicketManageIndex from 'Component/pages/ticket-manage/index';
import TicketManageView from 'Component/pages/ticket-manage/detail';

// 对话管理
import ECommerceManage from 'Component/pages/e-commerce-manage';
import ECommerceManageIndex from 'Component/pages/e-commerce-manage/index';
import ECommerceManageView from 'Component/pages/e-commerce-manage/detail';

// 系统管理
import AdminEntry from '../../pages/admin-entry';
// 系统设置
import AdminSystemIndex from '../../pages/admin/system/index';
import SessionSetings from 'Component/pages/wechat-qa/setting/conversation-setting/index';

// 用户管理
import AdminUserEntry from '../../pages/admin/user-entry';
import AdminUserIndex from '../../pages/admin/user/index';
import AdminUserView from '../../pages/admin/user/view/index';
import AdminUserNew from '../../pages/admin/user/new/index';
import AdminUserEdit from '../../pages/admin/user/edit/index';
// 用户组管理
import AdminUserGroupsEntry from '../../pages/admin/user-groups-entry';
import AdminUserGroupsIndex from '../../pages/admin/user-groups/index';
import AdminUserGroupsView from '../../pages/admin/user-groups/view/index';
import AdminUserGroupsNew from '../../pages/admin/user-groups/new/index';
import AdminUserGroupsEdit from '../../pages/admin/user-groups/edit/index';
// 语音识别
import SpeechRecognitionIndex from 'Component/pages/speech-recognition/index';
//消息日志
import SystemLogIndex from 'Component/pages/sys-log/index';

// 角色管理
import Role from 'Component/pages/role';
import RoleIndex from 'Component/pages/role/index';
import RoleDetail from 'Component/pages/role/detail';
import RoleDetailBasicInfo from 'Component/pages/role/detail/basic-info';
import RoleDetailTaskPermissions from 'Component/pages/role/detail/task-permissions';
import RoleDetailDataPermissions from 'Component/pages/role/detail/data-permissions';

// 数据迁移
import DataMigrationIndex from 'Component/pages/data-migration/index';

//任务中心
import TaskCenter from 'Component/pages/task-center';
import TaskCenterDownloadList from 'Component/pages/task-center/download-list/index';
import TaskCenterUploadList from 'Component/pages/task-center/upload-list/index';
import TaskCentertaskList from 'Component/pages/task-center/task-list/index';
//计费中心
import BillingCenterIndex from 'Component/pages/billing-center/index';
//系统集成
import SystemIntegration from 'Component/pages/system-integration/index';
import SystemIntegrationCCpass from 'Component/pages/system-integration/cc-pass/index';
//企微对接
import WechatDocking from 'Component/pages/system-integration/wechat-docking/index.tsx';
//淘宝对接
import TaobaoDocking from 'Component/pages/system-integration/taobao/index.tsx';
import WeifengDocking from 'Component/pages/system-integration/weifeng-docking/index.tsx';

//本地上传
import RecordUploadDetail from 'Component/pages/record-upload/detail';
import RecordUploadIndex from 'Component/pages/record-upload/index';
//异常数据
import AnomalousData from '../../pages/anomalous-data';
import AnomalousDataIndex from '../../pages/anomalous-data/index';
//客户字段
import CustomerField from 'Component/pages/customer-field';
// logo设置
import LogoSite from 'Component/pages/logo-site';
// 视频分析
import VideoAnalysis from 'src/pages/conversationAnalysis/videoAnalysis/index';
import VideoAnalysisList from 'src/pages/conversationAnalysis/videoAnalysis/List/index';
import VideoAnalysisDetail from 'src/pages/conversationAnalysis/videoAnalysis/Detail/index';


//404
import PageNotFound from '../../pages/404';

import Udesk from 'Udesk';
import { getRouteNameWithNameSpace } from 'Udesk/system/subApp';

const getRouteNameWithCA = getRouteNameWithNameSpace(Udesk.enums.sysModuleList.conversationAnalysis.nameSpace);

const conversationAnalysisRouteComponentByRouteNameMappings = {
    // 主界面
    [getRouteNameWithCA('home')]: {
        component: Home
    },
    // 数据分析
    [getRouteNameWithCA('dataAnalysis')]: {
        component: DataAnalysis
    },
    // 舆情发现
    [getRouteNameWithCA('sentimentAnalysis')]: {
        component: SentimentAnalysis,
    },
    [getRouteNameWithCA('sentimentAnalysisIndex')]: {
        component: SentimentAnalysisIndex,
    },
    // 业务分析
    [getRouteNameWithCA('businessAnalysis')]: {
        component: BusinessAnalysis,
    },
    [getRouteNameWithCA('businessAnalysisList')]: {
        component: BusinessAnalysisList,
    },
    [getRouteNameWithCA('businessAnalysisCreate')]: {
        component: BusinessAnalysisCreate,
    },
    [getRouteNameWithCA('businessAnalysisDetail')]: {
        component: BusinessAnalysisDetail,
    },
    // 分析报表
    [getRouteNameWithCA('AnalysisReport')]: {
        component: AnalysisReport,
    },
    // 标签分析
    [getRouteNameWithCA('intelligentTagsAnalysis')]: {
        component: IntelligentTagsAnalysis,
    },
    [getRouteNameWithCA('clientCenterEntry')]: {
        component: ClientCenterEntry,
    },
    [getRouteNameWithCA('clientManage')]: {
        component: ClientManage,
    },
    [getRouteNameWithCA('clientManageRoot')]: {
        component: ClientManageRoot,
    },
    [getRouteNameWithCA('clientManageWechat')]: {
        component: ClientManageWechat,
    },
    // 客户画像
    [getRouteNameWithCA('customerPortrait')]: {
        component: CustomerPortrait,
    },
    [getRouteNameWithCA('customerPortraitIndex')]: {
        component: CustomerPortraitIndex,
    },
    // 语义智能-1、智能词库
    [getRouteNameWithCA('smartWordsLibrary')]: {
        component: SmartWordLibrary,
    },
    [getRouteNameWithCA('smartWordsLibraryAttentionWords')]: {
        component: AttentionWords,
    },
    [getRouteNameWithCA('smartWordsLibraryProfessionalWords')]: {
        component: ProfessionalWords,
    },
    [getRouteNameWithCA('smartWordsLibrarySynonymWords')]: {
        component: SynonymlWords,
    },
    [getRouteNameWithCA('smartWordsLibrarySensitiveWords')]: {
        component: SensitiveWords,
    },
    [getRouteNameWithCA('smartWordsLibraryWordsWeight')]: {
        component: WordsWeight,
    },
    [getRouteNameWithCA('smartWordsLibraryIgnoreWords')]: {
        component: IgnoreWords,
    },
    [getRouteNameWithCA('smartWordsLibraryCorpusData')]: {
        component: CorpusData,
    },
    [getRouteNameWithCA('smartWordsLibraryTypoLibrary')]: {
        component: TypoLibrary,
    },

    // 语义智能-2、语义标签
    [getRouteNameWithCA('semanticIntelligence')]: {
        component: SemanticIntelligence,
    },
    [getRouteNameWithCA('semanticTags')]: {
        component: SemanticTags,
    },
    [getRouteNameWithCA('semanticTagsIndex')]: {
        component: SemanticTagsIndex,
    },
    [getRouteNameWithCA('semanticTagsNew')]: {
        component: SemanticTagsDetail,
    },
    [getRouteNameWithCA('semanticTagsEdit')]: {
        component: SemanticTagsDetail,
    },
    // 智能标签
    [getRouteNameWithCA('intelligentTags')]: {
        component: IntelligentTags,
    },
    [getRouteNameWithCA('smartConversationIntelligentTags')]: {
        component: SmartConversationIntelligentTags,
    },
    [getRouteNameWithCA('intelligentTagsIndex')]: {
        component: IntelligentTagsIndex,
    },
    [getRouteNameWithCA('intelligentTagsNew')]: {
        component: IntelligentTagsDetail,
    },
    [getRouteNameWithCA('intelligentTagsEdit')]: {
        component: IntelligentTagsDetail,
    },
    [getRouteNameWithCA('wechatIntelligentTags')]: {
        component: WechatIntelligentTags,
    },
    //语义智能-3、信息实体
    [getRouteNameWithCA('informationEntities')]: {
        component: InformationEntities,
    },
    [getRouteNameWithCA('informationEntitiesIndex')]: {
        component: InformationEntitiesIndex,
    },
    [getRouteNameWithCA('informationEntitiesNew')]: {
        component: InformationEntitiesDetail,
    },
    [getRouteNameWithCA('informationEntitiesEdit')]: {
        component: InformationEntitiesDetail,
    },
    // 知识库
    [getRouteNameWithCA('knowledgeBase')]: {
        component: KnowledgeBase,
    },
    [getRouteNameWithCA('knowledgeBaseIndex')]: {
        component: KnowledgeBaseIndex,
    },
    [getRouteNameWithCA('knowledgeBaseNew')]: {
        component: KnowledgeBaseDetail,
    },
    [getRouteNameWithCA('knowledgeBaseEdit')]: {
        component: KnowledgeBaseDetail,
    },
    //语义智能-6、概念词
    [getRouteNameWithCA('conceptWord')]: {
        component: ConceptWord,
    },
    [getRouteNameWithCA('conceptWordIndex')]: {
        component: ConceptWordIndex,
    },
    [getRouteNameWithCA('conceptWordNew')]: {
        component: ConceptWordDetail,
    },
    [getRouteNameWithCA('conceptWordEdit')]: {
        component: ConceptWordDetail,
    },
    [getRouteNameWithCA('trainingCenter')]: {
        component: TrainingCenter,
    },
    [getRouteNameWithCA('fastTrainingIndex')]: {
        component: FastTrainingIndex,
    },
    [getRouteNameWithCA('trainingHistoryIndex')]: {
        component: TrainingHistoryIndex,
    },
    //分类训练
    [getRouteNameWithCA('classifiedTraining')]: {
        component: ClassifiedTraining,
    },
    [getRouteNameWithCA('classifiedTrainingIndex')]: {
        component: ClassifiedTrainingIndex,
    },
    [getRouteNameWithCA('classifiedTrainingDetail')]: {
        component: ClassifiedTrainingDetail,
    },
    [getRouteNameWithCA('classifiedTrainingHistoryIndex')]: {
        component: ClassifiedTrainingHistoryIndex,
    },
    [getRouteNameWithCA('clusteringAnnotationIndex')]: {
        component: ClusteringAnnotationIndex,
    },
    // 通话管理
    [getRouteNameWithCA('callManage')]: {
        component: CallManage,
    },
    [getRouteNameWithCA('callManageIndex')]: {
        component: CallManageIndex,
    },
    [getRouteNameWithCA('callManageView')]: {
        component: CallManageView,
    },

    // 对话管理
    [getRouteNameWithCA('dialogueManage')]: {
        component: DialogueManage,
    },
    [getRouteNameWithCA('dialogueManageIndex')]: {
        component: DialogueManageIndex,
    },
    [getRouteNameWithCA('dialogueManageView')]: {
        component: DialogueManageView,
    },

    // 工单管理
    [getRouteNameWithCA('ticketManage')]: {
        component: TicketManage,
    },
    [getRouteNameWithCA('ticketManageIndex')]: {
        component: TicketManageIndex,
    },
    [getRouteNameWithCA('ticketManageView')]: {
        component: TicketManageView,
    },
    // 对话管理
    [getRouteNameWithCA('eCommerceManage')]: {
        component: ECommerceManage,
    },
    [getRouteNameWithCA('eCommerceManageIndex')]: {
        component: ECommerceManageIndex,
    },
    [getRouteNameWithCA('eCommerceManageView')]: {
        component: ECommerceManageView,
    },

    // 系统管理
    [getRouteNameWithCA('adminEntry')]: { 
        component: AdminEntry,
    },
    // 会话设置
    [getRouteNameWithCA('sessionSetings')]: { 
        component: SessionSetings,
    },
    // 系统设置
    [getRouteNameWithCA('adminSystemIndex')]: { 
        component: AdminSystemIndex,
    },
    // 用户管理
    [getRouteNameWithCA('adminUserEntry')]: { 
        component: AdminUserEntry,
    },
    [getRouteNameWithCA('adminUserIndex')]: { 
        component: AdminUserIndex,
    },
    [getRouteNameWithCA('adminUserView')]: { 
        component: AdminUserView,
    },
    [getRouteNameWithCA('adminUserNew')]: { 
        component: AdminUserNew,
    },
    [getRouteNameWithCA('adminUserEdit')]: { 
        component: AdminUserEdit,
    },
    // 用户组管理
    [getRouteNameWithCA('adminUserGroupsEntry')]: { 
        component: AdminUserGroupsEntry,
    },
    [getRouteNameWithCA('adminUserGroupsIndex')]: { 
        component: AdminUserGroupsIndex,
    },
    [getRouteNameWithCA('adminUserGroupsView')]: { 
        component: AdminUserGroupsView,
    },
    [getRouteNameWithCA('adminUserGroupsNew')]: { 
        component: AdminUserGroupsNew,
    },
    [getRouteNameWithCA('adminUserGroupsEdit')]: { 
        component: AdminUserGroupsEdit,
    },
    // 语音识别
    [getRouteNameWithCA('speechRecognitionIndex')]: { 
        component: SpeechRecognitionIndex,
    },
    //消息日志
    [getRouteNameWithCA('systemLogIndex')]: { 
        component: SystemLogIndex,
    },
    // 角色管理
    [getRouteNameWithCA('role')]: { 
        component: Role,
    },
    [getRouteNameWithCA('roleIndex')]: { 
        component: RoleIndex,
    },
    [getRouteNameWithCA('roleNew')]: { 
        component: RoleDetail,
    },
    [getRouteNameWithCA('roleNewBasicInfo')]: { 
        component: RoleDetailBasicInfo,
    },
    [getRouteNameWithCA('roleView')]: { 
        component: RoleDetail,
    },
    [getRouteNameWithCA('roleViewBasicInfo')]: { 
        component: RoleDetailBasicInfo,
    },
    [getRouteNameWithCA('roleViewTaskPermissions')]: { 
        component: RoleDetailTaskPermissions,
    },
    [getRouteNameWithCA('roleViewDataPermissions')]: { 
        component: RoleDetailDataPermissions,
    },
    [getRouteNameWithCA('roleEdit')]: { 
        component: RoleDetail,
    },
    [getRouteNameWithCA('roleEditBasicInfo')]: { 
        component: RoleDetailBasicInfo,
    },
    [getRouteNameWithCA('roleEditTaskPermissions')]: { 
        component: RoleDetailTaskPermissions,
    },
    [getRouteNameWithCA('roleEditDataPermissions')]: { 
        component: RoleDetailDataPermissions,
    },
    [getRouteNameWithCA('dataMigration')]: { 
        component: DataMigrationIndex,
    },
    //异常数据
    [getRouteNameWithCA('anomalousData')]: { 
        component: AnomalousData,
    },
    [getRouteNameWithCA('anomalousDataIndex')]: { 
        component: AnomalousDataIndex,
    },
    [getRouteNameWithCA('logoSite')]: { 
        component: LogoSite,
    },
    [getRouteNameWithCA('wechatDocking')]: { 
        component: WechatDocking,
    },
    [getRouteNameWithCA('taobaoDocking')]: { 
        component: TaobaoDocking,
    },
    [getRouteNameWithCA('weifengDocking')]: { 
        component: WeifengDocking,
    },
    [getRouteNameWithCA('taskCenter')]: { 
        component: TaskCenter,
    },
    [getRouteNameWithCA('taskCenterDownloadList')]: { 
        component: TaskCenterDownloadList,
    },
    [getRouteNameWithCA('taskCenterUploadList')]: { 
        component: TaskCenterUploadList,
    },
    [getRouteNameWithCA('taskCenterTaskList')]: { 
        component: TaskCentertaskList,
    },
    [getRouteNameWithCA('customerField')]: { 
        component: CustomerField,
    },
    //计费中心
    [getRouteNameWithCA('billingCenterIndex')]: { 
        component: BillingCenterIndex,
    },
    [getRouteNameWithCA('systemIntegration')]: { 
        component: SystemIntegration,
    },
    [getRouteNameWithCA('systemIntegrationCCpass')]: { 
        component: SystemIntegrationCCpass,
    },

    //本地上传
    [getRouteNameWithCA('recordUploadDetail')]: { 
        component: RecordUploadDetail,
    },
    [getRouteNameWithCA('recordUploadDetailIm')]: { 
        component: RecordUploadDetail,
    },
    [getRouteNameWithCA('recordUploadIndex')]: { 
        component: RecordUploadIndex,
    },

    //视频分析
    [getRouteNameWithCA('videoAnalysis')]: { 
        component: VideoAnalysis,
    },
    [getRouteNameWithCA('videoAnalysisList')]: { 
        component: VideoAnalysisList,
    },
    [getRouteNameWithCA('videoAnalysisDetail')]: { 
        component: VideoAnalysisDetail,
    },

    [getRouteNameWithCA('PageNotFound')]: {
        component: PageNotFound,
    },
};

export {
    conversationAnalysisRouteComponentByRouteNameMappings
};
