import React, { useContext, useEffect, useState } from 'react';
import Udesk from 'Udesk';
import styled from 'styled-components';
import Filter from './Filter';
import { UserContext } from '..';
import { Item } from './Container';
import { getIntelligentPartnerHomePageAdminTaskDetail } from 'src/api/intelligentPartner/homePage/admin/taskDetail/index';
import { Divider, Space } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';



export const AdminDataCards = (props) => {
    const [lessonData, setLessonData] = useState<any>([]);
    const [practiceData, setPracticeData] = useState<any>([]);
    const [examinationData, setExaminationData] = useState<any>([]);
    const [organizationIds] = useContext(UserContext);
    useEffect(() => {
        getIntelligentPartnerHomePageAdminTaskDetail(({
            params: {
                organizationIds
            }
        }) as any).then(({ data }) => {
            if (!data) return;

            setLessonData([
                {
                    count: data.totalCount,
                    desc: (
                        <div>{/* 已发布 */}{UdeskLocales['current'].pages.coach.dashboard.components.adminDataCards.published}<span style={{ color: '#1A6EFF' }}>{data.releasedCount}</span>{' '}{/* 个课程 */}{UdeskLocales['current'].pages.coach.dashboard.components.adminDataCards.courses}</div>
                    ),
                },
            ]);
            const tasks = data.taskDetails ?? [];
            const practise = tasks.find(
                (t) => t.taskType === Udesk.enums.learningTaskType.notStarted.id
            );
            const examination = tasks.find(
                (t) => t.taskType === Udesk.enums.learningTaskType.underWay.id
            );
            setPracticeData(
                practise?.courseDetail?.map((d) => ({
                    ...d,
                    desc: Udesk.enums.lessonType.getName(d.courseType),
                })) ?? []
            );
            setExaminationData(
                examination?.courseDetail?.map((d) => ({
                    ...d,
                    desc: Udesk.enums.lessonType.getName(d.courseType),
                })) ?? []
            );
        });
    }, [
        organizationIds
    ]);
    return (
        <>
            <Item widthRatio={1}>
                <Filter />
            </Item>
            <Item widthRatio={1 / 4}>
                <DataCard title={/* 课程数据 */UdeskLocales['current'].pages.coach.dashboard.components.adminDataCards.courseData} data={lessonData} />
            </Item>
            <Item widthRatio={3 / 8}>
                <DataCard title={/* 练习任务 */UdeskLocales['current'].pages.coach.dashboard.components.adminDataCards.practiceTasks} data={practiceData} />
            </Item>
            <Item widthRatio={3 / 8}>
                <DataCard title={/* 考试任务 */UdeskLocales['current'].pages.coach.dashboard.components.adminDataCards.examTasks} data={examinationData} />
            </Item>
        </>
    );
};

const DataCardWrap = styled.div`
    padding: 12px 16px 20px;
    .udesk-qa-web-coach-dashboard-admin-data-card-title {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;
    }
    .udesk-qa-web-coach-dashboard-admin-data-card-content {
        width: 100%;
        .udesk-qa-ui-divider-vertical {
            height: 24px;
        }
        .udesk-qa-ui-space {
            width: 100%;
            .udesk-qa-ui-space-item {
                flex: 1;
                .udesk-qa-web-coach-dashboard-admin-data-card-content-item {
                    text-align: center;
                    width: 100%;
                    .udesk-qa-web-coach-dashboard-admin-data-card-content-item-count {
                        font-weight: 500;
                        font-size: 40px;
                        line-height: 48px;
                        color: #1a6eff;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
`;

function DataCard(props) {
    const { title, data } = props;

    return (
        <DataCardWrap className="udesk-qa-web-coach-dashboard-admin-data-card">
            <div className="udesk-qa-web-coach-dashboard-admin-data-card-title">{title}</div>
            <div className="udesk-qa-web-coach-dashboard-admin-data-card-content">
                <Space split={<Divider type="vertical" />}>
                    {data.map((d) => {
                        return (
                            <div className="udesk-qa-web-coach-dashboard-admin-data-card-content-item">
                                <div className="udesk-qa-web-coach-dashboard-admin-data-card-content-item-count">
                                    {d.count}
                                </div>
                                <div>{d.desc}</div>
                            </div>
                        );
                    })}
                </Space>
            </div>
        </DataCardWrap>
    );
}
