import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';

export default class LogoutRoute extends React.Component {
    model() {
        return new Promise((resolve, reject) => {
            Udesk.ajax.post(Udesk.business.apiPath.concatApiPath('/logout', this.props.sdkOptions)).then(
                resp => {
                    Udesk.data.clearData();
                    let logoutUrl = resp.data.logoutUrl;
                    if (logoutUrl != null && logoutUrl !== '') {
                        if (logoutUrl.indexOf('http://') === -1 && logoutUrl.indexOf('https://') === -1) {
                            logoutUrl = 'http://' + logoutUrl;
                        }
                        window.location.replace(logoutUrl);
                    } else {
                        window.location.href = '/auth/login';
                    }
                    resolve();
                    // reject();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.pages.auth.logout.logoutError);
                    reject();
                }
            );
        });
    }
}
