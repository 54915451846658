import React from "react";
import TaskBasicInfoRoute from "./route";
import Udesk from 'Udesk';
import { PageHeader, /* Form, Input, Select, */ Button, Row, Col, FormBuilder } from 'udesk-ui';
import './style.scss';
// import AutoComplete from 'udesk-react/src/components/auto-complete';
// import FormBuilder from 'Component/common/create-form';
import cusConfigMap from '../../../../../common/config/form-config/cus-component-map';
import UdeskLocales from 'UdeskLocales';

export default class TaskBasicInfoTemplate extends TaskBasicInfoRoute {
    render() {
        let { actions, locales, props, privates, state } = this;
        let { associateRegulation = {} } = privates;
        const { conditionList = [], ...logic } = associateRegulation??{};
        let { loading } = this.privates;
        const isInfoCollect = ['informationCollectionBasicInfoCreateIndex', 'informationCollectionIndex'].includes(this.props.route.name);
        let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
        const config = {
            initialValues: {
                name: privates.name,
                remark: privates.remark,
                inspectDataSource: privates.inspectDataSource,
                related: privates.related,
                sessionType: privates.sessionType,
                period: privates.period,
                inspectorList: state.inspectorList,
                associateRegulation: {
                    conditionList: conditionList
                },
                logic: logic
            },
            fields: [
                {
                    name: 'name',
                    label: locales.pages.tasks.manage.template.basicInfo.index.taskName,
                    type: 'Input',
                    props: {
                        placeholder: locales.pages.tasks.manage.template.basicInfo.index.taskNamePlaceholder,
                        style: {
                            width: 300,
                        }
                    },
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                {
                    name: 'remark',
                    label: locales.pages.tasks.manage.template.basicInfo.index.taskRemark,
                    type: 'TextArea',
                    props: {
                        placeholder: locales.pages.tasks.manage.template.basicInfo.index.taskRemarkPlaceholder,
                        autoSize: { minRows: 2, maxRows: 2 },
                        style: {
                            width: 350
                        },
                    },
                },
                {
                    name: 'inspectDataSource',
                    label: locales.pages.tasks.manage.template.basicInfo.index.source,

                    type: 'Select',
                    props: {
                        style: {
                            width: 112
                        },
                        options: privates.inspectDataSources.map((i) => ({ label: i.name, value: i.id })),
                    },
                    forceDisabled: privates.isEdit,
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    effect: {
                        value: {
                            [Udesk.enums.inspectDataSources.voiceCall.id]: [{
                                name: 'period',
                                output: 7
                            }, {
                                name: 'logic',
                                output: {
                                    judgeStrategy: Udesk.enums.operatorRuleTypes.all.id
                                }
                            }, {
                                name: ['associateRegulation', 'conditionList'],
                                output: []
                            }],
                            [Udesk.enums.inspectDataSources.textDialogue.id]: [{
                                name: 'period',
                                output: 7
                            }, {
                                name: 'logic',
                                output: {
                                    judgeStrategy: Udesk.enums.operatorRuleTypes.all.id
                                }
                            }, {
                                name: ['associateRegulation', 'conditionList'],
                                output: []
                            }],
                            [Udesk.enums.inspectDataSources.taobao.id]: [{
                                name: 'period',
                                output: 7
                            }, {
                                name: 'logic',
                                output: {
                                    judgeStrategy: Udesk.enums.operatorRuleTypes.all.id
                                }
                            }, {
                                name: ['associateRegulation', 'conditionList'],
                                output: []
                            }, {
                                name: 'related',
                                output: Udesk.enums.inspectType.commonInspect.id
                            }],
                            [Udesk.enums.inspectDataSources.ticket.id]: [{
                                name: 'period',
                                output: 7
                            }, {
                                name: 'logic',
                                output: {
                                    judgeStrategy: Udesk.enums.operatorRuleTypes.all.id
                                }
                            }, {
                                name: ['associateRegulation', 'conditionList'],
                                output: []
                            }, {
                                name: 'related',
                                output: Udesk.enums.inspectType.commonInspect.id
                            }]
                        },
                        disable: {
                            [Udesk.enums.inspectDataSources.taobao.id]: ['related'],
                            [Udesk.enums.inspectDataSources.ticket.id]: ['related'],
                        }
                    },
                },
                {
                    name: 'sessionType',
                    label: /* 会话类型 */UdeskLocales['current'].pages.tasks.manage.template.basicInfo.template.sessionType,
                    type: 'RadioGroup',
                    props: {
                        options: Udesk.enums.chatTypes.map((i) => ({ label: i.name, value: Number(i.id) })),
                    },
                    forceHidden: !privates.isWechatQa,
                    forceDisabled: privates.isEdit,
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                {
                    name: 'inspectorList',
                    label: locales.pages.tasks.manage.template.basicInfo.index.user,
                    type: 'UdAutoComplete',
                    forceHidden: isInfoCollect,
                    props: {
                        style: {
                            width: 200,
                        },
                        url: Udesk.business.apiPath.concatApiPath('/users?pageSize=1000', props.sdkOptions),
                        isMulti: true,
                        method: 'GET',
                        queryParamName: 'keyword',
                        nameField: 'realname',
                        enableEmptySearch: true,
                        placeholder: locales.business.info.pleaseSearchAndSelect,
                    },
                },
                // {
                //     name: 'nlpModel',
                //     label: locales.pages.tasks.manage.template.basicInfo.index.nlpModel,
                //     type: 'Select',
                //     props: {
                //         style: {
                //             width: 112
                //         },
                //         options: privates.inspectDataSources.map((i) => ({ label: i.name, value: i.id })),
                //     },
                //     forceDisabled: privates.isEdit,
                //     rules: [
                //         {
                //             required: true,
                //         },
                //     ],
                // },
                {
                    name: 'related',
                    label: locales.pages.tasks.manage.template.basicInfo.index.inspectType,
                    type: 'RadioGroup',
                    props: {
                        options: Udesk.enums.inspectType.map((i) => ({ label: i.name, value: i.id })),
                    },
                    forceDisabled: privates.isEdit,
                    forceHidden: isInfoCollect,
                    tooltip: {
                        title: (
                            <div>
                                <div>{locales.pages.tasks.manage.template.basicInfo.index.commonTip}</div>
                                <div>{locales.pages.tasks.manage.template.basicInfo.index.associateTip}</div>
                            </div>
                        ),
                    },
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    effect: {
                        visible: {
                            [Udesk.enums.inspectType.associateInspect.id]: [
                                'period',
                                ['associateRegulation', 'conditionList'],
                                'logic'
                            ],
                        },
                        value: {
                            [Udesk.enums.inspectType.associateInspect.id]: [{
                                name: 'period',
                                output: 7
                            }, {
                                name: 'logic',
                                output: {
                                    judgeStrategy: Udesk.enums.operatorRuleTypes.all.id
                                }
                            }],
                        }
                    },
                },
                {
                    name: 'period',
                    label: locales.pages.tasks.manage.template.basicInfo.index.associateCycle,
                    type: 'InputNumber',
                    props: {
                        min: 1,
                        max: 30,
                    },
                    addonAfter: locales.labels.day,
                    tooltip: {
                        title: <div>{locales.pages.tasks.manage.template.basicInfo.index.cycleTip}</div>,
                    },
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                {
                    name: ['associateRegulation', 'conditionList'],
                    label: locales.pages.tasks.manage.template.basicInfo.index.associateRegulation,
                    type: 'AssociateRegulation',
                    props: {
                        sdkOptions: props.sdkOptions,
                        type: [privates.inspectDataSource],
                    },
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                {
                    name: 'logic',
                    label: locales.pages.tasks.manage.template.basicInfo.index.judgeStrategy,
                    type: 'Logic',
                    props: {
                        conditions: privates.conditions,
                    },
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            ],
            footerRender: false,
            labelCol: { span: 8 },
            wrapperCol: { span: 12 },
            customComponents: cusConfigMap,
            onValuesChange: actions.onFormValuesChange,
            onFinish: actions.onFinish,
        };
        return (
            <React.Fragment>
                <div className='udesk-qa-web-page'>
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        onBack={actions.gotoTask}
                        title={locales.pages.tasks.manage.template.basicInfo.index.title}
                    />
                    <div className='udesk-qa-web-page-body'>
                        <div className='udesk-qa-web-page-body-root tasks-manage-template-basic-info-page'>
                            <div className="tasks-manage-template-basic-info-index-form" style={{ backgroundColor: '#fff', height: '100%', padding: 40 }}>
                                <div>
                                    <If
                                        condition={(hasFeature && hasFeature(
                                            "task:template:info:view"
                                        )) || ['tasksBasicInfoCreateIndex', 'wechatQaCreateBaseInfo', 'informationCollectionBasicInfoCreateIndex'].includes(this.props.route.name) || privates.isWechatQa}
                                    >
                                        <FormBuilder {...config} ref={privates.formRef}></FormBuilder>
                                    </If>
                                </div>
                            </div>
                            <div className='udesk-qa-web-page-footer tasks-manage-template-basic-info-index-footer'>
                                <Row>
                                    <Col span={12} offset={8}>
                                        <If condition={privates.isEdit && hasFeature && hasFeature("task:template:info:edit")}>
                                            <Button type="primary" onClick={actions.submit} loading={loading}>
                                                {locales.pages.tasks.manage.template.basicInfo.index.save}
                                            </Button>
                                            <If condition={hasFeature && hasFeature("task:template:info:delete")}>
                                                <Button style={{ marginLeft: 8 }} type="danger" onClick={actions.deleteTask}>
                                                    {locales.pages.tasks.manage.template.basicInfo.index.delete}
                                                </Button>
                                            </If>
                                            <Button style={{ marginLeft: 8 }} onClick={actions.gotoTask}>
                                                {locales.pages.tasks.manage.template.basicInfo.index.cancel}
                                            </Button>
                                        </If>
                                        <If condition={!privates.isEdit}>
                                            <Button type="primary" onClick={actions.submit} loading={loading}>
                                                {locales.pages.tasks.manage.template.basicInfo.index.next}
                                            </Button>
                                            <Button style={{ marginLeft: 8 }} onClick={actions.gotoTask}>
                                                {locales.pages.tasks.manage.template.basicInfo.index.cancel}
                                            </Button>
                                        </If>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
