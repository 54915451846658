import { extend } from 'umi-request';

import {
    onError,
    onSuccess,
    defaultRequestConfig,
    showErrorDefault,
    requireCustomizedErrMsg,
    getLocales,
} from '../request.config';
// @ts-ignore
import type { DefaultRequestConfig } from '../request.config';
import type {
    RequestOptionsInit,
    RequestMethod,
    Extend,
    RequestInterceptor,
    ResponseInterceptor,
    OnionMiddleware,
} from 'umi-request';

let umiRequest: RequestMethod | null = null;
// 默认兜底的错误提示信息，需要自定义错误信息才能支持国际化
const DEFAULT_ERROR_MSG_MAP = {
    get: '加载',
    post: '保存',
    put: '保存',
    delete: '删除',
    error: '失败',
};
//Request实例的初始化方法，需要在init请求前调用
export const requestInit = (options: SdkOptions) => {
    const { domain = '', prefix, ...requestOptions } = options;
    const {
        middlewares = [],
        requestInterceptors = [],
        responseInterceptors = [],
        ...defaultOptions
    } = defaultRequestConfig as DefaultRequestConfig;

    if (!domain) {
        new Error(
            `domain can not be nullable, if do not want to customize, domain should be 'window.location.origin'.`
        );
    }
    umiRequest = extend({
        prefix: `${domain}${prefix ? `/${prefix}` : ''}`,
        ...defaultOptions,
        ...requestOptions,
        headers: Object.assign(
            {
                'Content-Type': 'application/json',
            },
            defaultOptions.headers,
            requestOptions.headers
        ),
    });
    if (umiRequest) {
        // Add user custom middlewares
        const customMiddlewares = middlewares || [];
        customMiddlewares.forEach((mw: OnionMiddleware) => {
            umiRequest!.use(mw);
        });

        // Add user custom interceptors
        requestInterceptors.map((ri: RequestInterceptor) => {
            umiRequest!.interceptors.request.use(ri);
        });
        responseInterceptors.map((ri: ResponseInterceptor) => {
            umiRequest!.interceptors.response.use(ri);
        });
    }
};
const cache = new Map();
const expDuration = 30 * 60 * 1000;

interface SettledCallbackQueueItem {
    type: 'then' | 'catch' | 'finally';
    cb: Promise<any>['then'] | Promise<any>['catch'] | Promise<any>['finally'];
    // timestamp: number;
}
class mockPromise {
    settledCallbackQueue: SettledCallbackQueueItem[] = [];
    _aborted = false;
    constructor(executor: Promise<any>, cacheKey: string) {
        console.log('cache:: ', cache);

        if (cacheKey && cache.has(cacheKey)) {
            Promise.resolve().then(() => {
                console.log('cache.get(cacheKey):: ', cache.get(cacheKey));

                this.runSettledCallback(Promise.resolve(cache.get(cacheKey).data));
            });
        }
        executor
            .then((resp) => {
                if (this._aborted) return;
                this.runSettledCallback(executor);
                const now = new Date().getTime();
                Array.from(cache.entries()).forEach(([key, value]) => {
                    if (value && now - (value.timestamp ?? 0) >= expDuration) {
                        cache.delete(key);
                    }
                });
                cache.set(cacheKey, {
                    data: resp,
                    timestamp: new Date().getTime(),
                });
            })
            .catch((e) => {
                this.runSettledCallback(executor);
            });
    }

    abort() {
        this._aborted = true;
    }
    runSettledCallback(initValue: Promise<any>) {
        this.settledCallbackQueue.reduce((prev, cb, index) => {
            console.log(cb.type);
            return (prev[cb.type] as any)(cb.cb);
        }, initValue);
    }
    push(cb: SettledCallbackQueueItem['cb'], type: SettledCallbackQueueItem['type']) {
        this.settledCallbackQueue.push({
            type,
            cb,
            // timestamp: new Date().getTime(),
        });
    }
    then(cb: SettledCallbackQueueItem['cb']) {
        this.push(cb, 'then');
        return this;
    }
    catch(cb: SettledCallbackQueueItem['cb']) {
        this.push(cb, 'catch');
        return this;
    }
    finally(cb: SettledCallbackQueueItem['cb']) {
        this.push(cb, 'finally');
        return this;
    }
}

export function request<DT, ST, PT extends object>(url: string, options: Options<DT, ST, PT>) {
    const {
        segments = {},
        errorMsg = '',
        successMsg = '',
        showError,
        useCache = false,
        __$requestCalleeName,
        ...umiRequestOptions
    } = options;
    let parsedPath = url;
    let reg1 = new RegExp(/{(.*?)}/g);
    let matchedObj;
    while ((matchedObj = reg1.exec(url))) {
        let reg = new RegExp(matchedObj[0], 'g');
        parsedPath = parsedPath.replace(reg, segments[matchedObj[1]] as string);
    }
    if (!umiRequest) {
        const errorMsg = `umiRequest is not initialized, please call "requestInit" first`;
        throw new Error(errorMsg);
        // return Promise.reject(new Error(errorMsg));
    }
    const res = umiRequest(parsedPath, umiRequestOptions)
        .then((resp) => {
            onSuccess?.(resp, successMsg);
            return resp;
        })
        .catch((reason) => {
            if (
                process.env.NODE_ENV === 'development' &&
                requireCustomizedErrMsg &&
                (showError ?? showErrorDefault)
            ) {
                // @ts-ignore
                if (typeof getLocales(__$requestCalleeName) !== 'string') {
                    throw new Error(
                        `未定义接口报错时的默认提示信息，请在locales文件的api字段中添加${__$requestCalleeName}属性`
                    );
                }
            }
            // @ts-ignore
            const defaultErrorMsg =
                getLocales(__$requestCalleeName) ||
                `${DEFAULT_ERROR_MSG_MAP[umiRequestOptions.method]} ${__$requestCalleeName} ${
                    DEFAULT_ERROR_MSG_MAP.error
                }`;
            onError?.(reason, errorMsg || defaultErrorMsg, showError);
            throw reason;
        });
    return useCache
        ? (new mockPromise(
            res,
            JSON.stringify({
                parsedPath,
                params: umiRequestOptions.params,
                data: umiRequestOptions.data,
            })
        ) as any as Promise<any>)
        : res;
}

type ExtendParams = Parameters<Extend>;
type ExtendParamsOptions = ExtendParams[0];

export interface SdkOptions extends ExtendParamsOptions {
    domain: string;
    prefix?: string;
}

type KnownKeys<T> = {
    [K in keyof T as string extends K ? never : number extends K ? never : K]: T[K];
};

export interface Options<DT, ST, PT extends object>
    extends Omit<KnownKeys<RequestOptionsInit>, 'data' | 'params' | 'url'> {
    segments?: ST;
    params?: PT;
    data?: DT;
    errorMsg?: string;
    successMsg?: string;
    showError?: boolean;
    method: 'get' | 'post' | 'delete' | 'put' | 'head' | 'options' | 'patch';
    __$requestCalleeName: string;
}

export type ApiOptions<ST, PT extends object> = Omit<
    Options<never, ST, PT>,
    'data' | 'method' | '__$requestCalleeName'
>;
